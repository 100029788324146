"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Search/SearchModal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactwaypoint = require('react-waypoint');
var _Tabs = require('web/components/Tabs'); var _Tabs2 = _interopRequireDefault(_Tabs);
var _SearchItem = require('./SearchItem'); var _SearchItem2 = _interopRequireDefault(_SearchItem);
var _SearchInput = require('./SearchInput'); var _SearchInput2 = _interopRequireDefault(_SearchInput);
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _upgrade = require('shared/enum/upgrade');

const MAX_ITEMS_PER_TYPE = 3 // max search items to display when 'all' is selected
const DISPLAY_ITEMS_FILTER_FUNC = (selectedTab, length) => selectedTab === tabTypes.ALL ? (length < MAX_ITEMS_PER_TYPE) : true

const tabTypes = {
  ALL: "ALL",
  CHATS: "CHATS",
  MESSAGES: "MESSAGES",
  FILES: "FILES",
  CONVERSATIONS: "CONVERSATIONS",
}

const typeData = {
  CONVERSATIONS: {
    iconSrc: '/assets/icons/search/chat-purple.svg',
    resultsName: "conversations",
  },
  MESSAGES: {
    iconSrc: '/assets/icons/search/message-purple.svg',
    resultsName: "messages",
  },
  FILES: {
    iconSrc: '/assets/icons/search/file-purple.svg',
    resultsName: "files",
  },
}

const tabOptions = [
  {
    value: tabTypes.ALL,
    label: "All",
    iconName: "all",
  },
  {
    value: tabTypes.CONVERSATIONS,
    label: "Chats",
    iconName: "chat",
  },
  {
    value: tabTypes.MESSAGES,
    label: "Messages",
    iconName: "message",
  },
  {
    value: tabTypes.FILES,
    label: "Files",
    iconName: "file",
  },
]

 class SearchModal extends _react.Component {constructor(...args) { super(...args); SearchModal.prototype.__init.call(this);SearchModal.prototype.__init2.call(this);SearchModal.prototype.__init3.call(this);SearchModal.prototype.__init4.call(this);SearchModal.prototype.__init5.call(this);SearchModal.prototype.__init6.call(this);SearchModal.prototype.__init7.call(this);SearchModal.prototype.__init8.call(this); }
  __init() {this.state = {
    selectedTab: tabTypes.ALL,
    isUpsellModalOpen: false,
    itemPos: 0, // for remembering what item should be focused
  }}

  __init2() {this.openUpsellModal = () => this.setState({ isUpsellModalOpen: true })}
  __init3() {this.closeUpsellModal = () => this.setState({ isUpsellModalOpen: false })}

  componentDidUpdate (prevProps) {
    const { selectedTab } = this.state
    const { latestQuery } = this.props
    const anyResultsForType = selectedTab !== tabTypes.ALL
      && typeData[selectedTab].resultsName
      && typeData[selectedTab].resultsName.length > 0

    if (prevProps.latestQuery !== latestQuery && !anyResultsForType) {
      /* reset search modal */
      this.setState({ selectedTab: tabTypes.ALL })
    }
  }

  __init4() {this.onTabSelected = (k) => {
    this.setState({ selectedTab: tabTypes[k] })
  }}

  __init5() {this.onItemClick = (type, parent, id) => {
    this.props.onItemClick(type, parent, id)
    this.props.onClose()
  }}

  __init6() {this.viewMorePressed = (tabRef) => {
    this.setState({
      selectedTab: tabRef,
    })
  }}

  __init7() {this.onSelectTab = (selectedTab) => this.setState({ selectedTab })}

  __init8() {this.generateSearchItem = (item) => (
    _react2.default.createElement(_SearchItem2.default, { key: item.id,
      onClose: this.props.onClose,
      latestQuery: this.props.latestQuery,
      title: item.title,
      subtitle: item.subtitle,
      timestamp: item.timestamp,
      clickActionProps: item.clickActionProps,
      bKey: item.bKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
    )
  )}

  render () {
    const {
      conversations, messages, files, latestQuery,
      canDoAdvancedSearch, searchDaysLimit,
    } = this.props
    const { selectedTab } = this.state

    const anyItems = messages && messages.results.length > 0
      || (files && files.results.length > 0)
      || (conversations && conversations.results.length > 0)

    let messageContent = null
    let filesContent = null
    let conversationsContent = null

    if (conversations.results.length > 0) {
      conversationsContent = (
        _react2.default.createElement('div', { className: "search-results--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
          ,  selectedTab === tabTypes.ALL ? _react2.default.createElement('div', { className: "search-results--heading", __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}, "Chats") : null 
          , conversations.results.filter((_, i) => DISPLAY_ITEMS_FILTER_FUNC(selectedTab, i)).map(this.generateSearchItem)
          , selectedTab !== tabTypes.ALL ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: () => this.props.onWaypointHit('CONVERSATION'), key: "conversation_waypoint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}} ) : null
          , conversations.results.length > MAX_ITEMS_PER_TYPE && selectedTab === tabTypes.ALL ?
            _react2.default.createElement('div', { className: "search-results--view-more", onClick: () => this.viewMorePressed(tabTypes.CONVERSATIONS), __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
              , _react2.default.createElement('img', { src: typeData.CONVERSATIONS.iconSrc, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} ), "View more chats..."

            ) : null
        )
      )
    } else if (selectedTab === tabTypes.CONVERSATIONS && latestQuery) {
      conversationsContent = (
        _react2.default.createElement('div', { className: "search-results--not-found", __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
          , _react2.default.createElement('div', { className: "search-results--not-found-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}, "No chats found for \""
                , latestQuery, "\""
          )
        )
      )
    }

    if (messages.results.length > 0) {
      messageContent = (
        _react2.default.createElement('div', { className: "search-results--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 152}}
          ,  selectedTab === tabTypes.ALL ? _react2.default.createElement('div', { className: "search-results--heading", __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}, "Messages") : null
          , messages.results.filter((r, i) => DISPLAY_ITEMS_FILTER_FUNC(selectedTab, i)).map((m, i) => this.generateSearchItem(m))
          , selectedTab !== tabTypes.ALL ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: () => this.props.onWaypointHit('MESSAGE'), key: "message_waypoint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}} ) : null
          , messages.results.length > MAX_ITEMS_PER_TYPE && selectedTab === tabTypes.ALL ?
            _react2.default.createElement('div', { className: "search-results--view-more", onClick: () => this.viewMorePressed(tabTypes.MESSAGES), __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
              , _react2.default.createElement('img', { src: typeData.MESSAGES.iconSrc, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}} ), "View more messages..."

            ) : null
        )
      )
    } else if (selectedTab === tabTypes.MESSAGES && latestQuery) {
      messageContent = (
        _react2.default.createElement('div', { className: "search-results--not-found", __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
          , _react2.default.createElement('div', { className: "search-results--not-found-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}, "No messages found for \""
                , latestQuery, "\""
          )
        )
      )
    }

    if (files.results.length > 0) {
      filesContent = (
        _react2.default.createElement('div', { className: "search-results--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
          ,  selectedTab === tabTypes.ALL ? _react2.default.createElement('div', { className: "search-results--heading", __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}, "Files") : null
          , files.results.filter((r, i) => DISPLAY_ITEMS_FILTER_FUNC(selectedTab, i)).map((f, i) => this.generateSearchItem(f))
          , selectedTab !== tabTypes.ALL ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: () => this.props.onWaypointHit('FILE'), key: "file_waypoint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}} ) : null
          , files.results.length > MAX_ITEMS_PER_TYPE && selectedTab === tabTypes.ALL ?
            _react2.default.createElement('div', { className: "search-results--view-more", onClick: () => this.viewMorePressed(tabTypes.FILES), __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
              , _react2.default.createElement('img', { src: typeData.FILES.iconSrc, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}} ), "View more files..."

            ) : null
        )
      )
    } else if (selectedTab === tabTypes.FILES && latestQuery) {
      filesContent = (
        _react2.default.createElement('div', { className: "search-results--not-found", __self: this, __source: {fileName: _jsxFileName, lineNumber: 188}}
          , _react2.default.createElement('div', { className: "search-results--not-found-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}, "No files found for \""
                , latestQuery, "\""
          )
        )
      )
    }
    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
          , _react2.default.createElement(_SearchInput2.default, {
            autoFocus: true,
            inputId: "search",
            value: this.props.searchVal,
            onChange: this.props.onInputChange,
            placeholder: "Search for chats, messages, files...",
            color: "purple",
            searchDaysLimit: searchDaysLimit,
            onSearchLimitChange: this.props.onSearchLimitChange,
            canDoAdvancedSearch: canDoAdvancedSearch,
            canUpgrade: this.props.canUpgrade,
            onRequestFeature: this.openUpsellModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}
          )
          ,  latestQuery ? _react2.default.createElement(_Tabs2.default, { options: tabOptions,
            type: "filter",
            value: this.state.selectedTab,
            onSelectTab: this.onSelectTab, __self: this, __source: {fileName: _jsxFileName, lineNumber: 211}} ) : null 
        )
        ,  latestQuery && (
          _react2.default.createElement(_react2.default.Fragment, null
            ,  searchDaysLimit !== 0 && !canDoAdvancedSearch && (
              _react2.default.createElement(_WarningBar2.default, {
                className: "search-results--plan-warning",
                text: "Your plan only includes access to 2-weeks history."       ,
                linkText: this.props.canUpgrade ? "Upgrade now" : null,
                onLinkClick: this.openUpsellModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}}
              )
            )
            , (selectedTab === tabTypes.ALL || selectedTab === tabTypes.CONVERSATIONS) ? conversationsContent : null
            , (selectedTab === tabTypes.ALL || selectedTab === tabTypes.MESSAGES) ? messageContent : null
            , (selectedTab === tabTypes.ALL || selectedTab === tabTypes.FILES) ? filesContent : null
            , selectedTab === tabTypes.ALL && !anyItems && latestQuery &&
              _react2.default.createElement('div', { className: "search-results--not-found", __self: this, __source: {fileName: _jsxFileName, lineNumber: 230}}
                , _react2.default.createElement('div', { className: "search-results--not-found-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}, "No results found for \""
                      , latestQuery, "\""
                )
              )
          )
        )
        ,  this.props.mySingleOrgKey && (
          _react2.default.createElement(_UpgradeModal2.default, {
            type: _upgrade.SubscriptionItemType.TEAM,
            isOpen: this.state.isUpsellModalOpen,
            onClose: this.closeUpsellModal,
            orgKey: this.props.mySingleOrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
          )
        )
      )
    )
  }
} exports.default = SearchModal;

const searchItemData = _proptypes2.default.shape({
  id: _proptypes2.default.string.isRequired,
  title: _proptypes2.default.string.isRequired,
  subtitle: _proptypes2.default.string.isRequired,
  timestamp: _proptypes2.default.string.isRequired,
  clickActionProps: _proptypes2.default.object.isRequired,
})

SearchModal.propTypes = {
  searchDaysLimit: _proptypes2.default.number.isRequired,
  onSearchLimitChange: _proptypes2.default.func.isRequired,
  searchVal: _proptypes2.default.string,
  latestQuery: _proptypes2.default.string,
  onItemClick: _proptypes2.default.func,
  onInputChange: _proptypes2.default.func,
  onWaypointHit: _proptypes2.default.func,
  messages: _proptypes2.default.shape({
    results: _proptypes2.default.arrayOf(searchItemData),
  }),
  files: _proptypes2.default.shape({
    results: _proptypes2.default.arrayOf(searchItemData),
  }),
  conversations: _proptypes2.default.shape({
    results: _proptypes2.default.arrayOf(searchItemData),
  }),
}
