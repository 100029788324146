"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Mobile/Modal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _SessionsList = require('./SessionsList'); var _SessionsList2 = _interopRequireDefault(_SessionsList);
var _SessionDetails = require('./SessionDetails'); var _SessionDetails2 = _interopRequireDefault(_SessionDetails);

exports. default = ({
  isOpen, onClose,
  isOnHistoryScreen,
  gotoHistory, onSessionPick,
  currentSessionKey, roomKey, roomName,
  loadSessionsList,
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    title: isOnHistoryScreen ? 'Meeting History' : 'Session Details',
    className: "meeting-room--mobile-history", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
  
    ,  isOnHistoryScreen
      ? _react2.default.createElement(_SessionsList2.default, {
          roomKey: roomKey,
          onClose: onClose,
          onSessionPick: onSessionPick,
          loadSessionsList: loadSessionsList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        )
      : _react2.default.createElement(_SessionDetails2.default, {
          roomKey: roomKey,
          roomName: roomName,
          gotoHistory: gotoHistory,
          currentSessionKey: currentSessionKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        )
    
  )
)
