"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/UserItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _org = require('shared/selectors/org');
var _peers = require('shared/selectors/peers');
var _user = require('shared/selectors/user');



var _entities = require('shared/transforms/entities');

var _UserTile = require('./components/UserTile'); var _UserTile2 = _interopRequireDefault(_UserTile);












const UserItem = ({
  userEntity,
  orgKey,
  isMe,
  onEditUser,
  onRemoveUser,
  onApproveUser,
  onRejectUser,
  onResendUserInvite,
  onGotoUser,
}) => {
  if (!userEntity) return null

  return (
    _react2.default.createElement(_UserTile2.default, {
      orgKey: orgKey,
      entity: userEntity,
      isMe: isMe,
      onEdit: onEditUser,
      onRemove: onRemoveUser,
      onApprove: onApproveUser,
      onReject: onRejectUser,
      onResendInvite: onResendUserInvite,
      onGotoUser: onGotoUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
    )
  )
}






const mapState = (state, { orgKey, userKey }) => {
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgsMap = org ? { [orgKey]: org }  : {}
  const user = _peers.selectUserV2ByKey.call(void 0, state, userKey)
  const isMe = _user.selectCurrentUserKey.call(void 0, state) === userKey

  const userEntity = !user
    ? null
    : _entities.mapUserToEntity.call(void 0, 
      orgsMap, _entities.makeSpecificOrgTitleUserDescription.call(void 0, orgKey),
    )(user)

  return {
    userEntity,
    isMe,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(UserItem)
