"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');

var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);





const schema = _joi4.default.object({
  email: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Email')).contactEmail(),
})

 const forgotPasswordResolver = _joi.joiResolver.call(void 0, schema); exports.forgotPasswordResolver = forgotPasswordResolver
