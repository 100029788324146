"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _reactredux = require('react-redux');
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _conversation = require('shared/services/conversation');
var _ConversationBox = require('web/components/Conversation/ConversationBox'); var _ConversationBox2 = _interopRequireDefault(_ConversationBox);




var _blobject = require('shared/selectors/blobject');



var _user = require('shared/selectors/user');


var _conversation3 = require('shared/selectors/conversation');
var _time = require('shared/services/time');
var _lodash = require('lodash');

const EMPTY_CHAT = { messageBatches: [[]] }

const StoreConversationBox = _reactredux.connect.call(void 0, (state, props) => {
  const { conversationKey, blobjectKey, highlightedMessageKey } = props

  const isCurrentUserActive = _user.selectIsCurrentUserActive.call(void 0, state)
  const conversation = _conversation3.selectConversationByKey.call(void 0, state, conversationKey)
  const blobject = _blobject.selectBlobjectByKey.call(void 0, state, blobjectKey)
  const storeMessages = _conversation3.selectMessagesByConversationKey.call(void 0, state, conversationKey)
  const currentUserKey = _user.selectCurrentUserKey.call(void 0, state)

  const latestMessage = storeMessages[storeMessages.length - 1]
  const hasLatestMessage = conversation && latestMessage &&
    conversation.MessageSequence <= latestMessage.Sequence

  if (!storeMessages.length || !blobject || !hasLatestMessage) {
    return EMPTY_CHAT
  }

  const lastSeenTime = _blobject.selectMyBlobjectLastSeenTime.call(void 0, state, blobjectKey)
  const lastSeenMessage = _lodash.findLast.call(void 0, 
    storeMessages,
    m => m.UserKey === currentUserKey || !_time.isAfter.call(void 0, m.SentAt, lastSeenTime),
  ) || storeMessages[0]

  const lastSeenMessageKey = lastSeenMessage.MessageKey
  const totalMessagesCount = conversation.MessageSequence
  const unreadMessagesCount = Math.max(
    totalMessagesCount - lastSeenMessage.Sequence,
    0,
  )

  const relevantMessageKey = highlightedMessageKey || lastSeenMessageKey
  const relevantMessage = _lodash.findLast.call(void 0, 
    storeMessages,
    m => m.MessageKey === relevantMessageKey,
  )

  if (!relevantMessage) return EMPTY_CHAT

  const { relevantBatchIdx, batches: rawBatches } = storeMessages.reduce(
    (acc, m, i, msgs) => {
      const prevMsg = msgs[i - 1] || { Sequence: m.Sequence - 1 }

      // Break batch on Sequence mismatch
      if (
        m.Sequence !== prevMsg.Sequence + 1 &&
        !m.hasFailedPreviously &&
        !prevMsg.hasFailedPreviously
      ) acc.batches.push([])

      const currentBatchIdx = acc.batches.length - 1
      if (m.MessageKey === relevantMessageKey) {
        acc.relevantBatchIdx = currentBatchIdx
      }

      acc.batches[currentBatchIdx].push(m)
      return acc
    },
    { relevantBatchIdx: 0, batches: [[]] },
  )

  const messageBatches = rawBatches.map(b => _conversation.processMessages.call(void 0, b, conversation, blobject))
  const latestBatchIdx = messageBatches.length - 1

  const subscribers = _blobject.selectBlobjectSubscribers.call(void 0, state, blobjectKey)

  return {
    isCurrentUserActive,
    messageBatches,
    relevantMessageKey,
    relevantBatchIdx,
    latestBatchIdx,
    unreadMessagesCount,
    totalMessagesCount,
    currentUserKey,
    conversationKey,
    lastSeenTime,
    lastSeenMessageKey,
    subscribers,
  }
})(_ConversationBox2.default)

StoreConversationBox.propTypes = {
  highlightedMessageKey: _proptypes2.default.string,
  conversationKey: _proptypes2.default.string,
  blobjectKey: _proptypes2.default.string,
  messageKey: _proptypes2.default.string,

  onLoadAbove: _proptypes2.default.func,
  onLoadBelow: _proptypes2.default.func,
}

exports. default = StoreConversationBox
