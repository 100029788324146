"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Link.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const SettingsLink = ({
  withPlusIcon,
  role,
  theme = 'default',
  onClick,
  className,
  children,
}) => (
  _react2.default.createElement('a', {
    role: role,
    className: _classBuilder2.default.call(void 0, 
      "settings--link",
      theme === 'danger' && 'settings--link--danger',
      withPlusIcon && "settings--link--plus",
      className,
    ),
    onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
  
    ,  children 
  )
)

exports. default = SettingsLink
