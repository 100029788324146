"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _types = require('./types');
var _upgrade = require('shared/enum/upgrade');
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);







const activeSubscriptionSteps = [_types.UpgradeStep.PREVIEW, _types.UpgradeStep.BILLING] 
const noSubscriptionSteps = [_types.UpgradeStep.PREVIEW, _types.UpgradeStep.SUMMARY] 

 const useTeamCheckoutFlow = ({
  hasReceipt,
  onCheckout,
  units,
}) => {
  const availableSteps = hasReceipt
    ? activeSubscriptionSteps
    : noSubscriptionSteps

  const [currentStep, setCurrentStep] = _react.useState(availableSteps[0])
  const [isLoadingCheckout, setIsLoadingCheckout] = _react.useState.call(void 0, false)

  _react.useEffect.call(void 0, 
    () => _amplitude2.default.logEvent(`TEAM_COMMUNICATION_UPGRADE_${currentStep.toUpperCase()}`),
    [currentStep],
  )

  const onPrev = _react.useCallback.call(void 0, () => setCurrentStep(availableSteps[0]), [availableSteps])
  const onNext = _react.useCallback.call(void 0, 
    async () => {
      if (currentStep === availableSteps[0]) {
        return setCurrentStep(availableSteps[1])
      }
      if (currentStep === availableSteps[1] && units) {
        setIsLoadingCheckout(true)
        await onCheckout({ Type: _upgrade.SubscriptionItemType.TEAM, Units: units })
        setIsLoadingCheckout(false)
      }
    },
    [availableSteps, currentStep, onCheckout, units],
  )
  const onGotoStep = _react.useCallback.call(void 0, 
    (n) => setCurrentStep(availableSteps[n] || availableSteps[0]),
    [availableSteps],
  )

  return {
    onPrev,
    onNext,
    currentStep,
    onGotoStep,
    isLoadingCheckout,
  }
}; exports.useTeamCheckoutFlow = useTeamCheckoutFlow
