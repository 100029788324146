"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/DirectoryCreateNewModal/CreateNewPatient.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _upgrade = require('shared/enum/upgrade');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _session = require('shared/selectors/session');
var _org = require('shared/selectors/org');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');

var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _Forms = require('web/screens/Directory/Forms');






const CreateNewPatient = ({
  hasFeature,
  orgKey,
  onClose,
}) => {
  const {
    isOpen: isUpsellModalOpen,
    onOpen: onOpenUpsellModal,
    onClose: onCloseUpsellModal,
  } = _useToggle2.default.call(void 0, )

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    async ({ firstName, lastName, dob, email, phone, foreignID }) =>
      orgKey &&
      _actions2.default.createPatient({
        OrgKey: orgKey,
        FirstName: firstName,
        LastName: lastName,
        DateOfBirth: dob,
        Email: email,
        Phone: phone,
        ForeignID: foreignID,
      })
        .then(({ Patients }) => {
          const [patientKey] = Object.keys(Patients) 
          if (!patientKey) return
          _actions2.default.gotoPatientPage(orgKey, patientKey)
          onClose()
        })
        .catch((e) => {
          let msg = 'Failed to create patient'
          if (e.extra && e.extra.Error) {
            msg += ': ' + e.extra.Error
          }
          showWarningBar(msg)
        }),
    [orgKey, onClose, showWarningBar],
  )

  return (
    _react2.default.createElement('div', { className: "directory--create-new--form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
      ,  orgKey && !hasFeature && (
        _react2.default.createElement(_WarningBar2.default, {
          className: "directory--create-new--warning",
          text: "Want to get access to SMS Messaging?"      ,
          linkText: "Request access" ,
          onLinkClick: onOpenUpsellModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        )
      )
      ,  !orgKey && (
        _react2.default.createElement(_WarningBar2.default, {
          className: "directory--create-new--warning",
          text: "You need to join an organization to invite patients."        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
        )
      )
      , _react2.default.createElement(_Forms.PatientForm, {
        onSubmit: onSubmit,
        onCancel: onClose,
        isDisabled: !hasFeature,
        orgKeyContext: orgKey,
        submitBtnLabel: "Add",
        controlsClassName: "invite-contact--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
      )
      ,  orgKey && (
        _react2.default.createElement(_UpgradeModal2.default, {
          orgKey: orgKey,
          type: _upgrade.SubscriptionItemType.SMS,
          isOpen: isUpsellModalOpen,
          onClose: onCloseUpsellModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
        )
      )
    )
  )
}






const mapState = (state, { orgKeyContext }) => {
  const orgKey = orgKeyContext || _session.selectSelectedOrgKey.call(void 0, state)
  return {
    orgKey,
    hasFeature: !!orgKey && _org.selectOrgHasPatientMessagingFeature.call(void 0, state, orgKey),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewPatient)
