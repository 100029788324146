"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Avatar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Avatar = require('web/components/Avatar'); var _Avatar2 = _interopRequireDefault(_Avatar);

exports. default = ({ roomKey, className }) => (
  _react2.default.createElement(_Avatar2.default, {
    icon: "/assets/icons/video/cam-white.svg",
    backgroundColorKey: roomKey,
    className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
  )
)
