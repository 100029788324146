"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _upgrade = require('shared/enum/upgrade');
var _org = require('shared/selectors/org');

var _SMSPlanModal = require('./SMSPlanModal'); var _SMSPlanModal2 = _interopRequireDefault(_SMSPlanModal);
var _TeamPlanModal = require('./TeamPlanModal'); var _TeamPlanModal2 = _interopRequireDefault(_TeamPlanModal);
var _components = require('./components');








const TeamCommunicationModal = ({
  isOpen, onClose, orgName, orgKey, type,
}) => {
  const { [type]: Content } = {
    [_upgrade.SubscriptionItemType.TEAM]: _TeamPlanModal2.default,
    [_upgrade.SubscriptionItemType.SMS]: _SMSPlanModal2.default,
  }

  return (
    _react2.default.createElement(_components.PlanModal, { isOpen: isOpen, onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , _react2.default.createElement(Content, { orgKey: orgKey, orgName: orgName, onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
    )
  )
}





const mapState = (state, { orgKey }) => ({
  orgName: _nullishCoalesce(_optionalChain([_org.selectOrgByKey.call(void 0, orgKey, state), 'optionalAccess', _ => _.Name]), () => ( '')),
})

exports. default = _reactredux.connect.call(void 0, mapState)(TeamCommunicationModal)
