"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reselect = require('shared/store/reselect');

var _common = require('shared/selectors/blobject/common');

 const makeSelectBlobjectSubscriberCounts = () =>
  _reselect.createSelector.call(void 0, 
    _common.selectBlobjectByKey,
    blobject => {
      const {
        TeamSubscribers = {},
        UserSubscribers = {},
        PatientSubscribers = {},
      } = blobject || {}

      const usersCount = Object.keys(UserSubscribers).length
      const teamsCount = Object.keys(TeamSubscribers).length
      const patientsCount = Object.keys(PatientSubscribers).length

      const totalCount = usersCount + patientsCount + teamsCount

      return {
        usersCount,
        teamsCount,
        patientsCount,
        totalCount,
      }
    },
  ); exports.makeSelectBlobjectSubscriberCounts = makeSelectBlobjectSubscriberCounts
