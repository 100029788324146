"use strict";Object.defineProperty(exports, "__esModule", {value: true}); const sortByString = (a, b) => {
  if (a > b) return -1
  else if (b > a) return 1
  else return 0
}; exports.sortByString = sortByString

 const sortByInt = (a, b) => {
  if (a > b) return -1
  else if (b > a) return 1
  else return 0
}; exports.sortByInt = sortByInt
