"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/ResultsList/InviteContactSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _contact = require('shared/enum/contact');

var _InviteOption = require('./InviteOption'); var _InviteOption2 = _interopRequireDefault(_InviteOption);


const ADD_COWORKER_ICON = '/assets/icons/inbox/add-coworker.svg'
const ADD_PATIENT_ICON = '/assets/icons/inbox/add-patient.svg'











const InviteContactSection = ({
  contactType,
  setContactRelType,
  orgName = 'your organization',
  canAddUsers,
  canAddPatients,
  canInviteCoworkers,
  canInviteCollaborators,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    ,  canAddUsers && (canInviteCoworkers || canInviteCollaborators) && contactType !== _contact.CONTACT_TYPES.PHONE && (
      _react2.default.createElement(_react2.default.Fragment, null
        ,  canInviteCoworkers && (
          _react2.default.createElement(_InviteOption2.default, {
            name: contactType ? "It's a coworker" : "Invite a coworker",
            desc: `Invite to ${orgName}`,
            iconSrc: ADD_COWORKER_ICON,
            onChoose: () => setContactRelType(_contact.CONTACT_REL_TYPES.COWORKER), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          )
        )
        ,  canInviteCollaborators && (
          _react2.default.createElement(_InviteOption2.default, {
            name: contactType
              ? "It's an external collaborator"
              : "Invite an external collaborator"
            ,
            desc: "Invite to join BloomText"   ,
            iconSrc: ADD_COWORKER_ICON,
            onChoose: () => setContactRelType(_contact.CONTACT_REL_TYPES.EXTERNAL), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          )
        )
      )
    )
    ,  canAddPatients && (
      _react2.default.createElement(_InviteOption2.default, {
        name: contactType ? "It's a patient" : "Add a patient",
        desc: `Add to ${orgName}`,
        iconSrc: ADD_PATIENT_ICON,
        onChoose: () => setContactRelType(_contact.CONTACT_REL_TYPES.PATIENT), __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      )
    )
  )
)

exports. default = InviteContactSection
