"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');




var _user = require('shared/selectors/user');
var _common = require('./common');
var _time = require('shared/services/time');














const EMPTY_MAP = {}

 const selectBlobjectMetadata = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _user.selectCurrentUserV2,
  (blobject, me) => {
    if (!blobject) return EMPTY_MAP

    const {
      Name: name,
      Description: description,
      IsNamed: isNamed,
      WrappedKey: wrappedKey,
      WrappedType: wrappedType,
      InboxSortTime: lastUpdated,
      UserSubscribers: userSubscribers,
    } = blobject

    const userMetadata = blobject.UserMetadata[me.UserKey] 
    const lastTimeUserSeen = _optionalChain([userMetadata, 'optionalAccess', _ => _.SeenTime]) || _time.EPOCH

    const isUnread = _time.isAfter.call(void 0, lastUpdated, lastTimeUserSeen)
    const amIParticipatingAsUser = !!userSubscribers[me.UserKey]
    const isCompleted = !!_optionalChain([userMetadata, 'optionalAccess', _2 => _2.Completed])

    const isMuted = !!(
      userMetadata && (
        _optionalChain([userMetadata, 'optionalAccess', _3 => _3.MuteForever]) ||
        _time.isAfter.call(void 0, _optionalChain([userMetadata, 'optionalAccess', _4 => _4.MuteUntil]), new Date())
      )
    )

    return {
      wrappedKey,
      wrappedType,
      name,
      isNamed,
      description,
      lastUpdated,
      isUnread,
      isCompleted,
      amIParticipatingAsUser,
      isMuted,
    }
  },
); exports.selectBlobjectMetadata = selectBlobjectMetadata
