"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/InfoSection/EditInfoModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Forms = require('web/screens/Directory/Forms');








const EditInfoModal = ({
  isOpen,
  onClose,
  teamKey,
  onSubmit,
}) => (
  _react2.default.createElement(_Modal2.default, {
    title: "Edit team information"  ,
    size: "xs",
    isOpen: isOpen,
    onClose: onClose,
    className: "team-page--edit-info-modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
  
    , _react2.default.createElement(_Forms.TeamForm, {
      teamKey: teamKey,
      onSubmit: onSubmit,
      onCancel: onClose,
      submitBtnLabel: "Save", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
    )
  )
)

exports. default = EditInfoModal
