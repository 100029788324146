"use strict";Object.defineProperty(exports, "__esModule", {value: true});class AnonymousUser {constructor() { AnonymousUser.prototype.__init.call(this);AnonymousUser.prototype.__init2.call(this);AnonymousUser.prototype.__init3.call(this);AnonymousUser.prototype.__init4.call(this);AnonymousUser.prototype.__init5.call(this);AnonymousUser.prototype.__init6.call(this); }
  __init() {this.getKey = () =>
    localStorage.getItem('anonymousUserKey')}

  __init2() {this.setKey = (key) =>
    localStorage.setItem('anonymousUserKey', key)}

  __init3() {this.getName = () => {
    const nameStr = localStorage.getItem('anonymousUserName')
    if (!nameStr) return nameStr
    try { return JSON.parse(nameStr || '').name } catch (e) { return null }
  }}

  __init4() {this.setName = (name) =>
    localStorage.setItem('anonymousUserName', JSON.stringify({ name }))}

  __init5() {this.getSecret = () =>
    localStorage.getItem('anonymousUserSecret')}

  __init6() {this.setSecret = (secret) =>
    localStorage.setItem('anonymousUserSecret', secret)}
}

exports. default = new AnonymousUser()
