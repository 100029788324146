"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Toasts/AppDownload.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);






const AppDownloadToast = ({ isOnStage, onDownload }) => {
  const [isOpen, setIsOpen] = _react.useState.call(void 0, true)
  const handleClose = () => {
    _amplitude2.default.logEvent("APP_DOWNLOAD_DEFERRED")
    setIsOpen(false)
  }
  const handleDownload = _react.useCallback.call(void 0, 
    () => {
      _amplitude2.default.logEvent("APP_DOWNLOAD_ACCEPTED")
      setIsOpen(false)
      onDownload()
    },
    [onDownload],
  )

  const options = _react.useMemo.call(void 0, 
    () => [
      {
        iconUrl: '/assets/icons/toasts/update.svg',
        label: 'Download now',
        action: handleDownload,
      },
      {
        iconUrl: '/assets/icons/toasts/postpone.svg',
        label: 'Do it later',
        action: handleClose,
      },
    ],
    [handleDownload],
  )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
      "toast toast--app-update",
      !(isOnStage && isOpen) && "hidden",
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      , _react2.default.createElement('img', {
        src: "/assets/icons/toasts/app-update.svg",
        className: "toast--app-update--icon",
        alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
      , _react2.default.createElement('span', { className: "toast--app-update--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "A new version is available."

      )
      , _react2.default.createElement(_Form.SplitButton, {
        options: options,
        onClick: handleDownload,
        className: "toast--app-update--btn",
        splitClassName: "toast--app-update--btn-split", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      
        , _react2.default.createElement('span', { className: "toast--app-update--btn-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}, "Download")
      )
    )
  )
}

exports. default = AppDownloadToast
