"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/SoundAmplitude.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _utils = require('shared/utils');
var _log = require('shared/log');

const clampOnScale = _utils.clamp.call(void 0, 0, 1)

const parseRawLevels = rawLevels => {
  const levelsMatrix = Array(5).fill(null).map((_, i) => {
    const lowerIdx = Math.floor((rawLevels.length / 5) * i)
    const upperIdx = Math.floor((rawLevels.length / 5) * (i + 1))
    return rawLevels.slice(lowerIdx, upperIdx)
  })

  // Used `for` instead of mapping for performance reasons
  const levels = []
  for (let i = 0; i < levelsMatrix.length; i++) {
    let sum = 0
    for (let j = 0; j < levelsMatrix[i].length; j++) { sum += levelsMatrix[i][j] }
    levels[i] = clampOnScale(sum / levelsMatrix[i].length)
  }

  return levels
}

 class SoundAmplitude extends _react2.default.Component {constructor(...args) { super(...args); SoundAmplitude.prototype.__init.call(this);SoundAmplitude.prototype.__init2.call(this);SoundAmplitude.prototype.__init3.call(this);SoundAmplitude.prototype.__init4.call(this);SoundAmplitude.prototype.__init5.call(this);SoundAmplitude.prototype.__init6.call(this);SoundAmplitude.prototype.__init7.call(this);SoundAmplitude.prototype.__init8.call(this);SoundAmplitude.prototype.__init9.call(this);SoundAmplitude.prototype.__init10.call(this);SoundAmplitude.prototype.__init11.call(this);SoundAmplitude.prototype.__init12.call(this);SoundAmplitude.prototype.__init13.call(this);SoundAmplitude.prototype.__init14.call(this);SoundAmplitude.prototype.__init15.call(this); }
  __init() {this.samplingInterval = null}

  __init2() {this.audioCtx = null}
  __init3() {this.analyser = null}
  __init4() {this.dataArray = new Uint8Array()}

  __init5() {this.initSampler = () => {
    const {
      mediaStream: givenStream,
      audioTrack: givenTrack,
    } = this.props

    this.closeAudioCtx()

    const audioTrack = givenTrack ||
      (givenStream && givenStream.getAudioTracks()[0])

    this.audioCtx = new (window.AudioContext || window.webkitAudioContext)()
    if (!this.audioCtx || !audioTrack) return

    const mediaStream = new MediaStream([audioTrack])
    const source = this.audioCtx.createMediaStreamSource(mediaStream)
    this.analyser = this.audioCtx.createAnalyser()

    source.connect(this.analyser)

    this.analyser.fftSize = 32
    const bufferLength = this.analyser.fftSize
    this.dataArray = new Uint8Array(bufferLength)
  }}

  __init6() {this.sample = () => {
    this.analyser.getByteTimeDomainData(this.dataArray)
    const rawLevels = []
    for (let i = 0; i < this.dataArray.length; i++) {
      rawLevels[i] = 10 * Math.abs(
        (this.dataArray[i] - 128) / 128,
      )
    }

    const levels = parseRawLevels(rawLevels)
    this.renderLevels(levels)
  }}

  __init7() {this.renderLevels = levels => this.rootRef &&
    requestAnimationFrame(() =>
      this.rootRef && this.rootRef.childNodes &&
      this.rootRef.childNodes.forEach((n, i) => {
        const t = `scaleY(${Math.max(levels[i], 0.1)})`
        n.style.transform = t
        n.style.webkitTransform = t
      },
      ),
    )}

  __init8() {this.componentDidUpdate = prevProps => {
    const { audioTrack, mediaStream } = this.props

    if (!audioTrack && !mediaStream) return this.stopSampler()
    if (
      prevProps.audioTrack !== audioTrack ||
      prevProps.mediaStream !== mediaStream
    ) {
      this.stopSampler()
      this.initSampler()
      this.startSampler()
    }
  }}

  __init9() {this.startSampler = () => {
    if (this.analyser) {
      this.samplingInterval = setInterval(() => {
        try { this.sample() } catch (e) { this.stopSampler() }
      }, 200)
    }
  }}

  __init10() {this.stopSampler = () => clearInterval(this.samplingInterval)}
  __init11() {this.closeAudioCtx = () => this.audioCtx && this.audioCtx.close()}

  __init12() {this.startRandomSampler = () => {
    this.samplingInterval = setInterval(() => {
      this.renderLevels(Array(5).fill(0).map(Math.random))
    }, 300)
  }}

  componentDidMount () {
    if (this.props.isAlwaysActive) { return this.startRandomSampler() }
    this.initSampler()
    this.startSampler()
  }

  __init13() {this.componentWillUnmount = () => {
    this.closeAudioCtx()
    this.stopSampler()
  }}

  __init14() {this.rootRef = null}
  __init15() {this.setRootRef = r => this.rootRef = r}

  render () {
    const { className } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, "video-call--sound-amp", className)

    return (
      _react2.default.createElement('div', { className: rootClassName, ref: this.setRootRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
        , _react2.default.createElement('div', { className: "video-call--sound-amp-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}} )
        , _react2.default.createElement('div', { className: "video-call--sound-amp-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}} )
        , _react2.default.createElement('div', { className: "video-call--sound-amp-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}} )
        , _react2.default.createElement('div', { className: "video-call--sound-amp-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}} )
        , _react2.default.createElement('div', { className: "video-call--sound-amp-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}} )
      )
    )
  }
} exports.default = SoundAmplitude;

const eitherMediaOrTrackCheck = (props, _, componentName) =>
  !props.audioTrack && !props.mediaStream && _log.log.info(
    `One of 'audioTrack' or 'mediaStream' is required by '${componentName}' component.`,
  )

SoundAmplitude.propTypes = {
  className: _proptypes2.default.string,
  isAlwaysActive: _proptypes2.default.bool,
  audioTrack: eitherMediaOrTrackCheck,
  mediaStream: eitherMediaOrTrackCheck,
}
