"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Search/SearchItem.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _time = require('shared/services/time');
var _InboxItemAvatar = require('web/screens/Inbox/InboxItemAvatar'); var _InboxItemAvatar2 = _interopRequireDefault(_InboxItemAvatar);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

class SearchItem extends _react.Component {constructor(...args) { super(...args); SearchItem.prototype.__init.call(this);SearchItem.prototype.__init2.call(this); }
  __init() {this.highlightQueryText = () => {
    const { subtitle, latestQuery } = this.props
    const index = subtitle.toLowerCase().indexOf(latestQuery.toLowerCase())
    if (index !== -1) {
      const endIndex = index + latestQuery.length
      const startCutOff = Math.max(index - 20, 0)

      return (
        _react2.default.createElement('span', { className: "search-result--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
          , startCutOff > 0 ? '...' : ''
          , subtitle.substring(startCutOff, index)
          , _react2.default.createElement('span', { className: "highlight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, subtitle.substring(index, endIndex))
          , subtitle.substring(endIndex, subtitle.length)
        )
      )
    }
    return (
      _react2.default.createElement('span', { className: "search-result--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, subtitle)
    )
  }}

  __init2() {this.navigate = () => {
    const { clickActionProps } = this.props
    _actions2.default.gotoBlobject(clickActionProps.type, clickActionProps.cKey, clickActionProps.bKey, clickActionProps.mKey)
    this.props.onClose()
  }}

  render () {
    const { title, timestamp, selected, bKey } = this.props
    return (
      _react2.default.createElement('div', { className: "search-result" + (selected ? " selected" : ""), onClick: this.navigate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , _react2.default.createElement('div', { className: "search-result--photo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          , _react2.default.createElement('div', { className: "avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
            , _react2.default.createElement(_InboxItemAvatar2.default, { bkey: bKey, size: 40, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}} )
          )
        )
        , _react2.default.createElement('div', { className: "search-result--middle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
          , _react2.default.createElement('div', { className: "search-result--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, title)
          , _react2.default.createElement('div', { className: "search-result--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, this.highlightQueryText())
        )
        , _react2.default.createElement('div', { className: "search-result--right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , _react2.default.createElement('div', { className: "serach-result--timestamp", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, _time.getDateString.call(void 0, timestamp))
        )
      )
    )
  }
}

SearchItem.propTypes = {
  title: _proptypes2.default.string.isRequired,
  subtitle: _proptypes2.default.string.isRequired,
  timestamp: _proptypes2.default.string.isRequired,
  clickActionProps: _proptypes2.default.object.isRequired,
  bKey: _proptypes2.default.string.isRequired,
  onClose: _proptypes2.default.func.isRequired,
}

exports. default = SearchItem
