"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Participant.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactredux = require('react-redux');
var _videoRoom = require('web/selectors/videoRoom');
var _Participant = require('web/components/VideoCall/OnCall/ParticipantsBoard/Participant'); var _Participant2 = _interopRequireDefault(_Participant);

class ParticipantContainer extends _react2.default.Component {constructor(...args) { super(...args); ParticipantContainer.prototype.__init.call(this); }
  __init() {this.handlePick = () => {
    const { participantKey, onPick } = this.props
    onPick(participantKey)
  }}

  render () {
    const {
      audioTrack, videoTrack,
      isScreenParticipant,
      name, isMe, isActive,
      isLoading,
      problemCode,
    } = this.props

    return (
      _react2.default.createElement(_Participant2.default, {
        audioTrack: audioTrack,
        videoTrack: videoTrack,
        isScreenParticipant: isScreenParticipant,
        isActive: isActive,
        name: name,
        isMe: isMe,
        isLoading: isLoading,
        onParticipantPick: this.handlePick,
        problemCode: problemCode, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      )
    )
  }

  static __initStatic() {this.propTypes = {
    audioTrack: _proptypes2.default.any,
    videoTrack: _proptypes2.default.any,
    name: _proptypes2.default.string.isRequired,
    isActive: _proptypes2.default.bool.isRequired,
    isMe: _proptypes2.default.bool,
    onPick: _proptypes2.default.func.isRequired,
    isLoading: _proptypes2.default.bool,
    isScreenParticipant: _proptypes2.default.bool,
  }}
} ParticipantContainer.__initStatic();

const mapState = (state, { roomKey, roomUserKey }) => {
  const roomUser = _videoRoom.selectVideoRoomUser.call(void 0, roomUserKey, roomKey, state) || {}
  return { problemCode: roomUser.ProblemCode }
}

exports. default = _reactredux.connect.call(void 0, mapState)(ParticipantContainer)
