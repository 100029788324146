"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');

var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);

 const AREA_CODE_LENGTH = 3; exports.AREA_CODE_LENGTH = AREA_CODE_LENGTH






const schema = _joi4.default.object({
  phoneNumber: _joi3.requiredString.call(void 0, ),
  areaCode: _joi3.optionalString.call(void 0, )
    .custom((value, helpers) =>
      value.length === exports.AREA_CODE_LENGTH && /^\d+$/.test(value)
        ? value
        : helpers.error('code.invalid'),
    )
    .messages({
      ..._joi3.makeStrErrors.call(void 0, 'Area code'),
      'code.invalid': 'Please enter a valid 3-digit area code.',
    }),
})

 const newTeamPhoneResolver = _joi.joiResolver.call(void 0, schema); exports.newTeamPhoneResolver = newTeamPhoneResolver
