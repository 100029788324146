"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/FileMessage/FileImage/ModalView.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Header = require('web/components/RightSide/Header');








const FileImageModalView = ({
  imageSrc,
  imageCaption,
  onClose,
  onDownload,
}) => {
  const [isZoomedIn, setIsZoomedIn] = _react.useState.call(void 0, false)
  const toggleZoom = () => setIsZoomedIn(x => !x)

  const imgClassName = _classBuilder2.default.call(void 0, 'file-image--preview', { 'zoomed-in': isZoomedIn })

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "blm-carousel--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        , _react2.default.createElement('div', { className: "blm-carousel--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}},  imageCaption )
        , _react2.default.createElement('div', { className: "blm-carousel--actions", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          , _react2.default.createElement(_Header.HeaderItem, { iconName: "download", onClick: onDownload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
          , _react2.default.createElement('div', { className: "blm-carousel--separator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
          , _react2.default.createElement(_Header.HeaderItem, { iconName: "close", onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )
        )
      )
      , _react2.default.createElement('div', { className: "file-image--preview-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        , _react2.default.createElement('div', { className: "file-image--preview-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
          , _react2.default.createElement('img', {
            onClick: toggleZoom,
            className: imgClassName,
            src: imageSrc,
            alt: imageCaption, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          )
        )
      )
    )
  )
}

exports. default = FileImageModalView
