"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');


var _uuid = require('shared/services/uuid');
var _user = require('shared/selectors/user');
var _org = require('shared/selectors/org');










 const selectMyRelationshipGroups = _toolkit.createSelector.call(void 0, 
  _user.selectCurrentUser, _org.selectOrgs,
  (me, orgs) => {
    const relsList = Object.values(me.Relationships || {})
    const [pendingOrgs, adminOrgs, memberOrgs] = relsList
      .reduce((acc, rel) => {
        const org = orgs[rel.OrgKey]
        const IsAdmin = rel.Type === 'admin'
        if (!org || rel.OrgKey === _uuid.EMPTY_BLUID) return acc

        const relItem = {
          OrgKey: org.OrgKey,
          OrgName: org.Name,
          IsAdmin,
          Title: rel.Title,
          Pending: rel.Pending,
          PendingUsersCount: _nullishCoalesce(_optionalChain([org, 'access', _ => _.PendingUsers, 'optionalAccess', _2 => _2.length]), () => ( 0)),
        }

        if (rel.Pending) acc[0].push(relItem)
        else if (IsAdmin) acc[1].push(relItem)
        if (!rel.Pending) acc[2].push(relItem)

        return acc
      },
        [
          [] ,
          [] ,
          [] ,
        ] ,
      )

    const sortByOrgName = (a, b) =>
      _optionalChain([a, 'access', _3 => _3.OrgName, 'optionalAccess', _4 => _4.localeCompare, 'call', _5 => _5(b.OrgName)])

    return {
      pendingOrgs: pendingOrgs.sort(sortByOrgName),
      adminOrgs: adminOrgs.sort(sortByOrgName),
      memberOrgs: memberOrgs.sort(sortByOrgName),
    }
  }); exports.selectMyRelationshipGroups = selectMyRelationshipGroups
