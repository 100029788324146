"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');
var _reactredux = require('react-redux');
var _lodash = require('lodash');

var _conversation = require('shared/selectors/conversation');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

 const useNotifications = ({ blobjectKey, onSuccess, onFailure }, useSelector) => {
  const useSelect = useSelector || _reactredux.useSelector
  const activeSettings = useSelect(s => _conversation.selectChatNotificationSettings.call(void 0, s, blobjectKey))

  const [isMutedForever, setIsMutedForever] = _react.useState.call(void 0, activeSettings.isMutedForever)
  const [mutedUntil, setMutedUntil] = _react.useState.call(void 0, activeSettings.mutedUntil)
  const [isVIP, setIsVIP] = _react.useState.call(void 0, activeSettings.isVIP)
  const [notificationSound, setNotificationSound] = _react.useState.call(void 0, activeSettings.notificationSound)

  _react.useEffect.call(void 0, 
    () => {
      const localSettings =
        { isMutedForever, mutedUntil, isVIP, notificationSound }
      if (_lodash.isMatch.call(void 0, localSettings, activeSettings)) return

      _actions2.default.updateChatNotifications({
        BKey: blobjectKey,
        MuteForever: isMutedForever,
        MuteUntil: mutedUntil,
        VIP: isVIP,
        Sound: notificationSound,
      })
        .then(onSuccess)
        .catch(e => {
          setIsMutedForever(activeSettings.isMutedForever)
          setMutedUntil(activeSettings.mutedUntil)
          setIsVIP(activeSettings.isVIP)
          setNotificationSound(activeSettings.notificationSound)
          onFailure(e)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      blobjectKey,
      isMutedForever,
      mutedUntil,
      isVIP,
      notificationSound,
      onSuccess,
      onFailure,
    ],
  )

  const onMuteUntil = _react.useCallback.call(void 0, 
    timestamp => {
      const newDate = new Date(
        timestamp > Date.now() ? timestamp : 0,
      ).toISOString()

      setMutedUntil(newDate)
    },
    [setMutedUntil],
  )

  const onMuteForever = _react.useCallback.call(void 0, 
    () => setIsMutedForever(true),
    [setIsMutedForever],
  )

  const onUnmute = _react.useCallback.call(void 0, 
    () => {
      setIsMutedForever(false)
      setMutedUntil(new Date(0).toISOString())
    },
    [setMutedUntil, setIsMutedForever],
  )

  return {
    isMutedForever,
    mutedUntil,
    isVIP,
    notificationSound,
    onMuteUntil,
    onMuteForever,
    onUnmute,
    setIsVIP,
    setNotificationSound,
  }
}; exports.useNotifications = useNotifications
