"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/ApproveMember/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





var _noticeBar = require('web/services/context/noticeBar');
var _Modal = require('web/components/Modal');
var _OrgLimitNotice = require('web/components/Upgrade/OrgLimitNotice'); var _OrgLimitNotice2 = _interopRequireDefault(_OrgLimitNotice);










const ApproveMemberForm = ({
  onClose, entity, onProceed, orgKey, orgName,
}) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = () => {
    setIsLoading(true)
    return onProceed()
      .then(onClose)
      .then(() => showSuccessBar('User approved'))
      .catch(() => {
        showWarningBar('Failed to approve user')
        setIsLoading(false)
      })
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('span', { className: "settings--org-members--remove-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, "You are about to approve "
             , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}},  entity.name ), " to join "   , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}},  orgName ), "."
      )
      , _react2.default.createElement(_OrgLimitNotice2.default, {
        orgKey: orgKey,
        addedUsersCount: 1,
        className: "settings--org-members--approve--limit-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Approve",
        isPrimaryLoading: isLoading,
        onPrimaryBtnClick: onSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      )
    )
  )
}

exports. default = ApproveMemberForm
