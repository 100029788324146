"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }


var _types = require('./types');

var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _map = require('shared/services/map');
var _array = require('shared/services/array');

 const LOAD_LIMIT = 30; exports.LOAD_LIMIT = LOAD_LIMIT
 const EMPTY_BLOBJECT_MAP = {}; exports.EMPTY_BLOBJECT_MAP = EMPTY_BLOBJECT_MAP
 const keyExtractor = (b) => b.Key; exports.keyExtractor = keyExtractor

 const resetActions = [
  _actionCreators2.default.setTeamInboxSettingsActions.SUCCESS.type,
  _actionCreators2.default.createConversationActions.SUCCESS.type,
  _actionCreators2.default.createSmsConversationActions.SUCCESS.type,
  _actionCreators2.default.setBlobjectCompletedForTeamsActions.SUCCESS.type,
]; exports.resetActions = resetActions
 const updateActions = [_actionCreators2.default.updateNotificationAction.type]; exports.updateActions = updateActions













 const filterRelevantBlobjects = ({
  action,
  activeFilter,
  myUserKey,
  myInboxableTeamKeys,
  selectedTeamKey,
}) => {
  const { Blobjects = exports.EMPTY_BLOBJECT_MAP } = action.payload

  const [relevantBlobjectsList, rejectedBlobjectList] = _array.splitBy.call(void 0, 
    Object.values(Blobjects),
    b => {
      const userSubscriber = b.UserSubscribers[myUserKey]
      const userMetadata = b.UserMetadata[myUserKey] 

      if (!userSubscriber || selectedTeamKey) {
        const teamsList = Object.values(b.TeamSubscribers)
        if (
          activeFilter === _types.BlobjectFilter.ACTIVE && teamsList.every(t => t.Completed)
          || activeFilter === _types.BlobjectFilter.COMPLETED && teamsList.every(t => !t.Completed)
        ) return false
      }

      if (userMetadata) {
        if (
          activeFilter === _types.BlobjectFilter.ACTIVE && userMetadata.Completed
          || activeFilter === _types.BlobjectFilter.COMPLETED && !userMetadata.Completed
        ) return false
      }

      if (selectedTeamKey) return !!b.TeamSubscribers[selectedTeamKey]
      return !!(userSubscriber || myInboxableTeamKeys.some(tk => b.TeamSubscribers[tk]))
    },
  )

  return [
    _map.listToMap.call(void 0, relevantBlobjectsList, exports.keyExtractor),
    rejectedBlobjectList,
  ]
}; exports.filterRelevantBlobjects = filterRelevantBlobjects

 const compareBlobjectDates = (a, b) =>
  +new Date(b.InboxSortTime) - +new Date(a.InboxSortTime); exports.compareBlobjectDates = compareBlobjectDates

 const getEarliestBlobjectTime = (bs, baseTime) =>
  bs.reduce(
    (acc, b) => {
      const d = new Date(b.InboxSortTime)
      return d < acc ? d : acc
    },
    baseTime,
  ); exports.getEarliestBlobjectTime = getEarliestBlobjectTime
