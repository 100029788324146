"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _log = require('shared/log');

const getNewestOrg = (r) => {
  const orgsList = Object.values(r.Orgs)
  const latestCreatedOrg = orgsList
    .reduce(
      (acc, o) => o.CreatedAt > (_nullishCoalesce(_optionalChain([acc, 'optionalAccess', _ => _.CreatedAt]), () => ( 0))) ? o : acc,
      orgsList[0],
    )

  if (!latestCreatedOrg) {
    _log.log.info('No orgs returned from `CreateAndJoinOrg`')
    throw new Error()
  }

  return latestCreatedOrg
}

 const joinOrg = ({ org, title }) =>
  typeof org === 'string'
    ? _actions2.default.createAndJoinOrg({ OrgName: org, RelTitle: title }).then(getNewestOrg)
    : _actions2.default.requestJoinOrg({ OrgKey: org.OrgKey  }).then(() => null); exports.joinOrg = joinOrg
