"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');


var _inviteContact = require('shared/services/inviteContact');

const useInviteContact = (
  searchQuery,
  contactType,
) => {
  const [contactRelType, setContactRelType] = _react.useState(null)
  const [defaultContactCredentials, setDefaultContactCredentials] =
    _react.useState({})

  const onOpenInviteContactModal = _react.useCallback.call(void 0, 
    (contactRelType) => {
      setContactRelType(contactRelType)
      setDefaultContactCredentials(_inviteContact.parseSearchQuery.call(void 0, searchQuery, contactType))
    },
    [searchQuery, contactType],
  )
  const onCloseInviteContactModal = _react.useCallback.call(void 0, 
    () => {
      setContactRelType(null)
      setDefaultContactCredentials({})
    },
    [],
  )

  return {
    contactRelType,
    defaultContactCredentials,
    onOpenInviteContactModal,
    onCloseInviteContactModal,
  }
}

exports. default = useInviteContact
