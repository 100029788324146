"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Modals/Settings.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _lodash = require('lodash');

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _ui = require('web/services/ui');
var _AudioSetupGroup = require('web/screens/VideoCall/segments/AudioSetupGroup'); var _AudioSetupGroup2 = _interopRequireDefault(_AudioSetupGroup);
var _VideoSetupGroup = require('web/screens/VideoCall/segments/VideoSetupGroup'); var _VideoSetupGroup2 = _interopRequireDefault(_VideoSetupGroup);

const mobileTabs = {
  AUDIO: 'MOBILE_SETTINGS_AUDIO_TAB',
  VIDEO: 'MOBILE_SETTINGS_VIDEO_TAB',
}

 class SettingsModal extends _react2.default.Component {constructor(...args) { super(...args); SettingsModal.prototype.__init.call(this);SettingsModal.prototype.__init2.call(this);SettingsModal.prototype.__init3.call(this);SettingsModal.prototype.__init4.call(this);SettingsModal.prototype.__init5.call(this);SettingsModal.prototype.__init6.call(this);SettingsModal.prototype.__init7.call(this);SettingsModal.prototype.__init8.call(this); }
  __init() {this.state = {
    activeMobileTab: mobileTabs.VIDEO,
    isMobileActive: _ui.isMobileScreen.call(void 0, ),
  }}

  __init2() {this.setVideoTabActive = () => this.setState({ activeMobileTab: mobileTabs.VIDEO })}
  __init3() {this.setAudioTabActive = () => this.setState({ activeMobileTab: mobileTabs.AUDIO })}

  __init4() {this.watchResize = _lodash.throttle.call(void 0, 
    () => this.setState({ isMobileActive: _ui.isMobileScreen.call(void 0, ) })
    , 100,
  )}

  __init5() {this.componentDidMount = () => window.addEventListener('resize', this.watchResize)}
  __init6() {this.componentWillUnmount = () => window.removeEventListener('resize', this.watchResize)}

  __init7() {this.renderMobileScreen = () => {
    const { onUserProblemPublish, onClose } = this.props
    const { activeMobileTab } = this.state

    const isAudioTabActive = activeMobileTab === mobileTabs.AUDIO
    const isVideoTabActive = activeMobileTab === mobileTabs.VIDEO

    const buildTabToggleClassName = active =>
      _classBuilder2.default.call(void 0, "meeting--settings-mobile--tab-toggle", { active })
    const videoTabToggleClassName = buildTabToggleClassName(isVideoTabActive)
    const audioTabToggleClassName = buildTabToggleClassName(isAudioTabActive)

    return (
      _react2.default.createElement('div', { className: "meeting--settings-modal--mobile", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        , _react2.default.createElement('div', { className: "meeting--settings-modal--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , _react2.default.createElement('div', { className: "meeting--settings-mobile--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
            , _react2.default.createElement('div', { className: "meeting--settings-mobile--tabbar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
              , _react2.default.createElement('div', {
                onClick: this.setVideoTabActive,
                className: videoTabToggleClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
              
                , _react2.default.createElement('img', {
                  alt: "video",
                  src: isVideoTabActive
                    ? '/assets/icons/video/camera-purple.svg'
                    : '/assets/icons/video/camera-grey.svg'
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
                )
                , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}, "Video")
              )
              , _react2.default.createElement('div', {
                onClick: this.setAudioTabActive,
                className: audioTabToggleClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
              
                , _react2.default.createElement('img', {
                  alt: "audio",
                  src: isAudioTabActive
                    ? '/assets/icons/video/loudspeaker-purple.svg'
                    : '/assets/icons/video/loudspeaker-grey.svg'
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
                )
                , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}, "Audio")
              )
            )
            , _react2.default.createElement('div', { className: "meeting--settings-mobile--content--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
              ,  isVideoTabActive
                ? _react2.default.createElement(_VideoSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
                : _react2.default.createElement(_AudioSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
              
            )
          )
          , _react2.default.createElement('a', {
            onClick: onClose,
            className: "blmh-button meeting--btn primary purple highlight meeting--settings-mobile--proceed"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , "OK"

          )
        )
      )
    )
  }}

  __init8() {this.renderDesktopScreen = () => {
    const { onUserProblemPublish, onClose } = this.props
    return (
      _react2.default.createElement('div', { className: "meeting--settings-modal--desktop", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        , _react2.default.createElement('div', { className: "meeting--settings-modal--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , _react2.default.createElement('div', { className: "meeting--settings-desktop--grid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
            , _react2.default.createElement(_VideoSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
            , _react2.default.createElement(_AudioSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
          )
          , _react2.default.createElement('a', {
            onClick: onClose,
            className: "blmh-button meeting--btn primary purple highlight meeting--settings-desktop--proceed"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , "Done"

          )
        )
      )
    )
  }}

  render () {
    const { isOpen, onClose } = this.props
    const { isMobileActive } = this.state

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: isOpen,
        onClose: onClose,
        className: "meeting--settings-modal",
        title: "Settings", __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
      
        ,  isMobileActive
          ? this.renderMobileScreen()
          : this.renderDesktopScreen()
        
      )
    )
  }

  static __initStatic() {this.propTypes = {
    isOpen: _proptypes2.default.bool.isRequired,
    onClose: _proptypes2.default.func.isRequired,
  }}
} SettingsModal.__initStatic(); exports.default = SettingsModal;
