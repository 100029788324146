"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');








 const useSender = ({ defaultSenderKey, myEntitiesMap }) => {
  const [senderKey, setSenderKey] = _react.useState.call(void 0, defaultSenderKey)
  const senderEntity = senderKey && myEntitiesMap[senderKey] || null
  const senderOrgKey = _optionalChain([senderEntity, 'optionalAccess', _ => _.wrappedEntity])
    && 'OrgKey' in senderEntity.wrappedEntity
    && senderEntity.wrappedEntity.OrgKey
    || null

  _react.useEffect.call(void 0, 
    () => setSenderKey(defaultSenderKey),
    [setSenderKey, defaultSenderKey],
  )

  return {
    senderKey,
    setSenderKey,
    senderEntity,
    senderOrgKey,
  }
}; exports.useSender = useSender
