"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/Header.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);






const Header = ({ title, subtitle }) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement('h2', { className: "plan-modal--summary--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}},  title )
    ,  typeof subtitle === 'string'
      ? _react2.default.createElement('h4', { className: "plan-modal--summary--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}},  subtitle )
      : subtitle
    
  )
)

exports. default = Header
