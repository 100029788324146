"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Modal/Modal/View.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);











const stopPropagation = e => e.stopPropagation()

const Modal = ({
  isOpen,
  onClose,
  title,
  className,
  bodyClassName,
  size,
  fullScreen,
  children,
}) => {
  _react.useEffect.call(void 0, 
    () => {
      const handleEsc = (e) => {
        if (e.keyCode !== 27 || !isOpen) return
        _optionalChain([onClose, 'optionalCall', _ => _()])
        e.stopPropagation()
      }
      window.addEventListener('keyup', handleEsc)
      return () => window.removeEventListener('keyup', handleEsc)
    },
    [isOpen, onClose],
  )

  const backdropRef = _react.useRef.call(void 0, null)
  const isMouseDownOverBackdrop = _react.useRef.call(void 0, false)
  const handleBackdropClick = _react.useCallback.call(void 0, 
    (e) => {
      if (
        e.target === backdropRef.current
        && isMouseDownOverBackdrop.current
      ) _optionalChain([onClose, 'optionalCall', _2 => _2()])

      isMouseDownOverBackdrop.current = false
    },
    [onClose],
  )

  return (
    _react2.default.createElement('div', {
      ref: backdropRef,
      onMouseDown: () => isMouseDownOverBackdrop.current = true,
      onClick: handleBackdropClick,
      className: _classBuilder2.default.call(void 0, 
        'blm-modal--backdrop',
        !isOpen && "blm-modal--backdrop--hidden",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
    
      , _react2.default.createElement('div', {
        role: "dialog",
        'aria-hidden': !isOpen,
        onMouseDown: stopPropagation,
        className: _classBuilder2.default.call(void 0, 
          "blm-modal",
          size && `blm-modal--${size}`,
          fullScreen && 'blm-modal--fullscreen',
          className,
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      
        ,  title && (
          _react2.default.createElement('div', { className: "blm-modal--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
            , _react2.default.createElement('h3', { className: "blm-modal--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}},  title )
            ,  onClose && (
              _react2.default.createElement('div', {
                role: "button",
                onClick: onClose,
                className: "blm-modal--close-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
              )
            )
          )
        )
        , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "blm-modal--body", bodyClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
          ,  children 
        )
      )
    )
  )
}

exports. default = Modal
