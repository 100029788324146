"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _helpers = require('./helpers');
var _utils = require('shared/store/utils');
var _error = require('shared/services/error');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);



const patientImportActions = _utils.createAsyncActions('PatientImport')
const superPatientImportActions =
  _utils.createAsyncActions('SuperPatientImport')

const withImportFlow = (cmd) =>
  new Promise((resolve, reject) => {
    const ttl = 5 * 60 * 1000 // 5 minutes
    const timeout =
      setTimeout(() => reject(new Error('Patient import has timed out')), ttl)

    const handleComplete = (action) => {
      clearTimeout(timeout)
      const payload = action.payload 
      if (payload.RowsSuccess > 0) {
        return resolve(payload)
      } else {
        reject(new Error('Found no patients to import'))
      }
    }

    _bus2.default.once(_actionCreators2.default.patientImportCompleteNotificationAction.type, handleComplete)

    cmd.catch(_error.passError.call(void 0, () => clearTimeout(timeout)))
  })

const patientImport = (req) =>
  withImportFlow(_helpers.wsCommand('PatientImport', patientImportActions, req, null))

const superPatientImport = (req) =>
  withImportFlow(
    _helpers.wsCommand('SuperPatientImport', superPatientImportActions, req, null),
  )

exports. default = {
  patientImport,
  superPatientImport,
}
