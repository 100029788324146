"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }



 const selectAllUsers = (state) =>
  _optionalChain([state, 'optionalAccess', _ => _.dataV2, 'optionalAccess', _2 => _2.users]) || state.users; exports.selectAllUsers = selectAllUsers

 const selectAllV1Users = (state) => state.users; exports.selectAllV1Users = selectAllV1Users
 const selectAllV2Users = (state) => state.dataV2.users; exports.selectAllV2Users = selectAllV2Users

 const selectAllV1Patients = (state) => state.patients; exports.selectAllV1Patients = selectAllV1Patients
 const selectAllV2Patients = (state) => state.dataV2.patients; exports.selectAllV2Patients = selectAllV2Patients

 const selectAllCombinedUsers =
  (state) => ({
    ...exports.selectAllV1Users.call(void 0, state),
    ...exports.selectAllV2Users.call(void 0, state),
  }); exports.selectAllCombinedUsers = selectAllCombinedUsers

 const selectAllPatients = (state) =>
  _optionalChain([state, 'optionalAccess', _3 => _3.dataV2, 'optionalAccess', _4 => _4.patients]) || state.patients; exports.selectAllPatients = selectAllPatients

 const selectPatientByKey = (state, key) =>
  _optionalChain([state, 'optionalAccess', _5 => _5.dataV2, 'optionalAccess', _6 => _6.patients, 'optionalAccess', _7 => _7[key]]) || _optionalChain([state, 'optionalAccess', _8 => _8.patients, 'optionalAccess', _9 => _9[key]]) || null; exports.selectPatientByKey = selectPatientByKey

 const selectAllCombinedPatients =
  (state) => ({
    ...exports.selectAllV1Patients.call(void 0, state),
    ...exports.selectAllV2Patients.call(void 0, state),
  }); exports.selectAllCombinedPatients = selectAllCombinedPatients

 const selectUserByKey = (state, userKey) =>
  _optionalChain([state, 'optionalAccess', _10 => _10.dataV2, 'optionalAccess', _11 => _11.users, 'optionalAccess', _12 => _12[userKey]]) || _optionalChain([state, 'access', _13 => _13.users, 'optionalAccess', _14 => _14[userKey]]) || null; exports.selectUserByKey = selectUserByKey

 const selectV1UserByKey = (state, userKey) => _optionalChain([state, 'optionalAccess', _15 => _15.users, 'optionalAccess', _16 => _16[userKey]]); exports.selectV1UserByKey = selectV1UserByKey
 const selectUserV2ByKey = (state, userKey) => _optionalChain([state, 'optionalAccess', _17 => _17.dataV2, 'optionalAccess', _18 => _18.users, 'optionalAccess', _19 => _19[userKey]]); exports.selectUserV2ByKey = selectUserV2ByKey
