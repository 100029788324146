"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Feedback/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _videoRoom = require('web/selectors/videoRoom');

var _VideoCall = require('web/components/VideoCall');
var _RoomHeader = require('web/components/VideoCall/RoomHeader'); var _RoomHeader2 = _interopRequireDefault(_RoomHeader);

const FeedbackScreen = ({
  gotoCall,
  roomName,
  roomLink,
  onRoomLinkCopy,
  openMobileMoreOptionsModal,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_RoomHeader2.default, {
      roomName: roomName,
      roomLink: roomLink,
      onRoomLinkCopy: onRoomLinkCopy,
      openMobileMoreOptionsModal: openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    )
    , _react2.default.createElement(_VideoCall.Feedback, {
      roomName: roomName,
      roomLink: roomLink,
      gotoCall: gotoCall, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    )
  )
)

const mapState = state => ({
  roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
  roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(FeedbackScreen)
