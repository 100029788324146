"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/SMSPlanModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _upgrade = require('shared/enum/upgrade');
var _supportUrls = require('shared/data/supportUrls');
var _cost = require('shared/services/cost');







var _hooks = require('../hooks');
var _upgradePlans = require('shared/constants/upgradePlans');

var _components = require('../components');
var _TierSelector = require('./TierSelector'); var _TierSelector2 = _interopRequireDefault(_TierSelector);







const SMSPlanModal = ({
  orgName,
  orgKey,
  onClose,
}) => {
  const { isLoading, error, items, hasSubscription, savedCardData } = _hooks.usePlan.call(void 0, { orgKey })
  const onCheckout =
    _hooks.useCheckout.call(void 0, { orgKey, hasSubscription, onEarlyCheckout: onClose })

  const {
    smsItemsList, teamItem, smsItem, phoneNumberItem, enabledSmsItem,
    smsItemMinCost, setSelectedSmsTier,
  } = _hooks.useRelevantItems.call(void 0, { items })

  const {
    discount,
  } = _hooks.usePlan.call(void 0, { orgKey })

  const isSmsEnabled = !!enabledSmsItem

  const plansReceipt = _hooks.usePlansReceipt.call(void 0, {
    smsItem,
    teamItem,
    phoneNumberItem,
    requestedItemType: _upgrade.SubscriptionItemType.SMS,
  })

  const hasReceipt = !!savedCardData
  const { onPrev, onNext, isLoadingCheckout, currentStep } =
    _hooks.useSmsCheckoutFlow.call(void 0, { onCheckout, smsItem, isSmsEnabled, hasReceipt })

  if (error || isLoading) {
    return _react2.default.createElement(_components.PlanLoader, { isLoading: isLoading, error: error, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
  }

  if (!smsItem) return null
  const baseCost = _optionalChain([smsItem, 'optionalAccess', _ => _.BaseCost]) || 0 
  const displayBaseCost = _cost.formatDisplayCost.call(void 0, baseCost)
  const displayPricePerContact = _cost.formatDisplayCost.call(void 0, baseCost / smsItem.Units )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  currentStep === _hooks.UpgradeStep.PREVIEW && (
        _react2.default.createElement(_components.PlanPreview, {
          title: "SMS Messaging" ,
          subtitle: "Engage your patients, clients, and staff, directly from their phone."         ,
          plan: _upgradePlans.SMSMessagingPlan,
          baseCost: smsItemMinCost,
          pricePrefix: "starting at" ,
          priceDesc: "month",
          demoVideoUrl: _supportUrls.smsMessagingDemoVideoUrl,
          onContinue: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        )
      )
      ,  currentStep === _hooks.UpgradeStep.SUMMARY && (
        _react2.default.createElement(_components.PlanSummary, {
          title: "Select a tier"  ,
          subtitle: smsItem && (
            _react2.default.createElement(_TierSelector2.default, {
              selectedItem: smsItem,
              smsItemsList: smsItemsList,
              onChange: setSelectedSmsTier, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
            )
          ),
          isNextLoading: isLoadingCheckout,
          onBack: onPrev,
          onNext: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        
          , _react2.default.createElement(_components.PlanInfo, {
            orgName: orgName,
            planName: _upgradePlans.SMSMessagingPlan.name,
            planIconSrc: _upgradePlans.SMSMessagingPlan.iconSrc,
            desc: `${smsItem.Units.toLocaleString()} contacts ${displayPricePerContact} per contact`,
            priceDesc: `${displayBaseCost} per month`,
            billingFrequency: "billed monthly" ,
            totalPrice: baseCost, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
          )
        )
      )
      ,  currentStep === _hooks.UpgradeStep.BILLING && (
        _react2.default.createElement(_components.PlansReceipt, {
          discount: discount,
          savedCardData: savedCardData,
          planPreviews: plansReceipt,
          isNextLoading: isLoadingCheckout,
          onBack: onPrev,
          onNext: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
        )
      )
    )
  )
}

exports. default = SMSPlanModal
