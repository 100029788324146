"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/EnterEmail/RolePrompt/RoleOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







const RoleOption = ({
  iconSrc,
  title,
  onPick,
}) => (
  _react2.default.createElement('div', { className: "signup--role-option", role: "button", onClick: onPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
    , _react2.default.createElement('img', { src: iconSrc, className: "signup--role-option--icon", alt: title, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} )
    , _react2.default.createElement('div', { className: "signup--role-option--title-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      , _react2.default.createElement('span', { className: "signup--role-option--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}},  title )
    )
  )
)

exports. default = RoleOption
