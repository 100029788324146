"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/NotificationPreferenceModal/Form/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _chat = require('shared/hooks/chat');
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _VIPSection = require('./VIPSection'); var _VIPSection2 = _interopRequireDefault(_VIPSection);
var _MuteSection = require('./MuteSection'); var _MuteSection2 = _interopRequireDefault(_MuteSection);
var _NotificationSoundSection = require('./NotificationSoundSection'); var _NotificationSoundSection2 = _interopRequireDefault(_NotificationSoundSection);

const NotificationPreferenceModal = ({ blobjectKey, onClose }) => {
  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSuccess = _react.useCallback.call(void 0, 
    () => showSuccessBar("Successfully updated notification preferences"),
    [showSuccessBar],
  )
  const onFailure = _react.useCallback.call(void 0, 
    () => showWarningBar("Error while updating notification settings"),
    [showWarningBar],
  )

  const {
    isMutedForever,
    mutedUntil,
    onMuteUntil,
    onMuteForever,
    onUnmute,
    isVIP,
    setIsVIP,
    notificationSound,
    setNotificationSound,
  } = _chat.useNotifications.call(void 0, { blobjectKey, onSuccess, onFailure })

  const isMuted = isMutedForever || new Date(mutedUntil).getTime() > Date.now()

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_VIPSection2.default, {
        isMuted: isMuted,
        isVIP: isVIP,
        onChangeVip: setIsVIP, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      )

      , _react2.default.createElement(_MuteSection2.default, {
        isMutedForever: isMutedForever,
        mutedUntil: mutedUntil,
        isVIP: isVIP,
        onMuteUntil: onMuteUntil,
        onMuteForever: onMuteForever,
        onUnmute: onUnmute, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )

      , _react2.default.createElement(_NotificationSoundSection2.default, {
        selectedSound: notificationSound,
        onSubmit: setNotificationSound, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      )

      , _react2.default.createElement(_Modal.ModalControls, {
        className: "chat--notification-settings--controls",
        secondaryBtnLabel: "Done",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
      )
    )
  )
}

exports. default = NotificationPreferenceModal
