"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _actions = require('web/actions');
var _videoCall = require('web/actions/videoCall');

const initialState = {
  permsState: _videoCall.permsStates.DENIED,
  isBrowserCompatible: true,

  isOnCall: false,

  isMicOn: true,
  isCamOn: true,
  isScreenSharingOn: false,
  areMicIssuesPresent: false,
  areCamIssuesPresent: false,

  videoInDevices: [],
  audioInDevices: [],
  currentMicDeviceId: '',
  currentCamDeviceId: '',
}

exports. default = (state = initialState, action) => {
  const { type, payload } = action
  switch (type) {
    case _actions.types.SET_PERMS_STATE:
      return { ...state, permsState: payload.state }
    case _actions.types.SET_BROWSER_COMPATIBILITY:
      return { ...state, isBrowserCompatible: payload.isCompatible }
    case _actions.types.SET_MIC_STATUS:
      return { ...state, isMicOn: payload.isOn }
    case _actions.types.SET_CAM_STATUS:
      return { ...state, isCamOn: payload.isOn }
    case _actions.types.SET_SCREEN_SHARING_STATUS:
      return { ...state, isScreenSharingOn: payload.isOn }
    case _actions.types.SET_CURRENT_ROOM_DETAILS:
      return {
        ...state,
        currentRoom: {
          name: payload.name,
          link: payload.link,
        },
      }
    case _actions.types.SET_DEVICES_LIST: {
      const currentMicDeviceId = state.currentMicDeviceId ||
        payload.audioIn[0] && payload.audioIn[0].deviceId
      const currentCamDeviceId = state.currentCamDeviceId ||
        payload.videoIn[0] && payload.videoIn[0].deviceId

      return {
        ...state,
        audioInDevices: payload.audioIn,
        videoInDevices: payload.videoIn,

        areMicIssuesPresent: payload.audioIn.length ? state.areMicIssuesPresent : true,
        areCamIssuesPresent: payload.videoIn.length ? state.areCamIssuesPresent : true,

        currentMicDeviceId,
        currentCamDeviceId,
      }
    }
    case _actions.types.SET_CURRENT_MIC_DEVICE_ID:
      return { ...state, currentMicDeviceId: payload.deviceId }
    case _actions.types.SET_CURRENT_CAM_DEVICE_ID:
      return { ...state, currentCamDeviceId: payload.deviceId }
    case _actions.types.TOGGLE_MIC:
      return { ...state, isMicOn: !state.isMicOn }
    case _actions.types.TOGGLE_CAM:
      return { ...state, isCamOn: !state.isCamOn }
    case _actions.types.CYCLE_CAM: {
      const { videoInDevices, currentCamDeviceId } = state
      const isCamCycleAvailable = videoInDevices.length > 0

      if (!isCamCycleAvailable) return

      const currentCamIdx = videoInDevices
        .findIndex(x => x.deviceId === currentCamDeviceId)
      const nextCamIdx = (currentCamIdx + 1) % videoInDevices.length

      return {
        ...state,
        currentCamDeviceId: videoInDevices[nextCamIdx].deviceId,
      }
    }
    case _actions.types.SET_MIC_ISSUES_STATE:
      return {
        ...state,
        areMicIssuesPresent: payload.areIssuesPresent,
      }
    case _actions.types.SET_CAM_ISSUES_STATE:
      return {
        ...state,
        areCamIssuesPresent: payload.areIssuesPresent,
      }

    case _actions.types.SET_IS_ON_CALL:
      return { ...state, isOnCall: payload }
  }
  return state
}
