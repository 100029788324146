"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/SMSMessaging.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);


var _upgradePlans = require('shared/constants/upgradePlans');
var _cost = require('shared/services/cost');

var _components = require('./components');
var _Form = require('web/components/Form');













const SMSMessaging = ({
  isActive,
  isEditable,
  contactsCount,
  maxContactsCount,
  renewsOn,
  baseCost,
  onCheckout,
  onAssignPhoneNumber,
  hasUnclaimedPhoneNumbers,
}) => (
  _react2.default.createElement(_components.FeatureItem, {
    title: _upgradePlans.SMSMessagingPlan.name,
    desc: "Engage your patients, clients, and staff, directly from their phone."         ,
    extra: hasUnclaimedPhoneNumbers && (
      _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: onAssignPhoneNumber, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, "Select a phone number"   )
    ),
    iconSrc: _upgradePlans.SMSMessagingPlan.iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
  
    ,  isActive
      ? _react2.default.createElement(_components.FeatureUsage, {
          title: `${maxContactsCount.toLocaleString()} contacts/month`,
          subtitle: `${contactsCount.toLocaleString()} of ${maxContactsCount.toLocaleString()}`,
          desc: renewsOn ?
            `Count resets on ${_moment2.default.call(void 0, renewsOn).format('MMMM Do')}`
            : `Message up to ${maxContactsCount.toLocaleString()} contacts`,
          progress: contactsCount / maxContactsCount,
          isEditable: isEditable,
          checkoutBtnLabel: "Change tier" ,
          onCheckout: onCheckout, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
      : _react2.default.createElement(_components.UpgradePrompt, {
          label: `Starts at ${_cost.formatDisplayCost.call(void 0, baseCost)}/month.`,
          onCheckout: isEditable ? onCheckout : undefined, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        )
    
  )
)

exports. default = SMSMessaging
