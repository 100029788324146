"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/RightSide/Header/HeaderMobileBackButton.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);



const HeaderMobileBackButton = ({ className, ...props }) => {
  const rootClassName = _classBuilder2.default.call(void 0, 
    'blmh-desktop-hide',
    'right-side-header--back-button',
    className,
  )

  return (
    _react2.default.createElement('div', { className: rootClassName, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , _react2.default.createElement('img', { src: "/assets/icons/header/arrow-back.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} )
    )
  )
}

exports. default = HeaderMobileBackButton
