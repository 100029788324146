"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _store = require('shared/store');
var _redux = require('redux');
var _utils = require('shared/store/utils');

const setOneSignalTokenAction = _utils.createAction('SET_ONE_SIGNAL_TOKEN')

 const actionCreators = {
  setOneSignalTokenAction,
}; exports.actionCreators = actionCreators

const setOneSignalToken = _redux.bindActionCreators.call(void 0, setOneSignalTokenAction, _store.dispatch)

exports. default = {
  setOneSignalToken,
}
