"use strict";Object.defineProperty(exports, "__esModule", {value: true}); const defaultAvatars = {
  bear: "assets/avatars/bear.png",
  beaver: "assets/avatars/beaver.png",
  bull: "assets/avatars/bull.png",
  camel: "assets/avatars/camel.png",
  cat: "assets/avatars/cat.png",
  chicken: "assets/avatars/chicken.png",
  crocodile: "assets/avatars/crocodile.png",
  deer: "assets/avatars/deer.png",
  dog: "assets/avatars/dog.png",
  giraffe: "assets/avatars/giraffe.png",
  goat: "assets/avatars/goat.png",
  koala: "assets/avatars/koala.png",
  lion: "assets/avatars/lion.png",
  monkey: "assets/avatars/monkey.png",
  mouse: "assets/avatars/mouse.png",
  ostrich: "assets/avatars/ostrich.png",
  panda: "assets/avatars/panda.png",
  penguin: "assets/avatars/penguin.png",
  pig: "assets/avatars/pig.png",
  rabbit: "assets/avatars/rabbit.png",
  raccoon: "assets/avatars/raccoon.png",
  rhino: "assets/avatars/rhino.png",
  sealion: "assets/avatars/sealion.png",
  shark: "assets/avatars/shark.png",
  sheep: "assets/avatars/sheep.png",
  squirrel: "assets/avatars/squirrel.png",
  wolf: "assets/avatars/wolf.png",
  zebra: "assets/avatars/zebra.png",
  tiger: "assets/avatars/tiger.png",
  brownbear: "assets/avatars/brownbear.png",
  tinypenguin: "assets/avatars/cutepenguin.png",
}; exports.defaultAvatars = defaultAvatars
