"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/SuccessModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _upgrade = require('shared/enum/upgrade');
var _org = require('shared/selectors/org');

var _components = require('./components');
var _Form = require('web/components/Form');
var _reactrouterdom = require('react-router-dom');









const itemDisplayNames = {
  [_upgrade.SubscriptionItemType.TEAM]: 'Team Chat',
  [_upgrade.SubscriptionItemType.SMS]: 'SMS Messaging',
  [_upgrade.SubscriptionItemType.VIDEO]: 'Video & Telehealth',
}

const SuccessModal = ({
  isOpen,
  onClose,
  orgName,
  orgKey,
  planType,
  actionType,
}) => {
  const planSettingsURL =
    _routes.makeRoute.call(void 0, _routes2.default.SETTINGS.ORG_PAGE, { tabName: 'plan', orgKey })

  const renderMsg = () => {
    if (actionType === _upgrade.CheckoutActionType.UPGRADE) {
      return _react2.default.createElement(_react2.default.Fragment, null,  orgName , " has been upgraded to "     ,  itemDisplayNames[planType] )
    }
    if (actionType === _upgrade.CheckoutActionType.CHANGE_TIER) {
      return _react2.default.createElement(_react2.default.Fragment, null, "Your " , itemDisplayNames[planType], " tier has been updated"    )
    }
    return null
  }

  return (
    _react2.default.createElement(_components.PlanModal, {
      isOpen: isOpen,
      onClose: onClose,
      className: "plan-modal--success", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
    
      , _react2.default.createElement('div', { className: "plan-modal--success--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )
      , _react2.default.createElement('h1', { className: "plan-modal--success--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        ,  renderMsg() 
      )
      , _react2.default.createElement('span', { className: "plan-modal--success--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "You can manage your plan in your "
               , ' '
        , _react2.default.createElement(_reactrouterdom.Link, { to: planSettingsURL, onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "settings"), "."
      )
      , _react2.default.createElement(_Form.Button, { onClick: onClose, className: "plan-modal--success--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "OK"

      )
    )
  )
}





const mapState = (state, { orgKey }) => ({
  orgName: _nullishCoalesce(_optionalChain([_org.selectOrgByKey.call(void 0, orgKey, state), 'optionalAccess', _ => _.Name]), () => ( 'Your org')),
})

exports. default = _reactredux.connect.call(void 0, mapState)(SuccessModal)
