"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Security/QuickLoginSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');




var _quickLogin = require('shared/validation/user/quickLogin');
var _noticeBar = require('web/services/context/noticeBar');

var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const QuickLoginSection = ({
  isQuickLoginEnabled, pinLength, onSubmit,
}) => {
  const { control, reset, setValue, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _quickLogin.quickLoginResolver,
      defaultValues: { isEnabled: isQuickLoginEnabled },
    })
  const { isValid, isDirty, isSubmitting } = formState
  const isEnabled = _reacthookform.useWatch.call(void 0, { control, name: 'isEnabled' })
  _react.useEffect.call(void 0, () => { !isEnabled && setValue('pin', '') }, [isEnabled, setValue])

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const submit = _react.useCallback.call(void 0, 
    data => onSubmit(data)
      .then(() => reset(data))
      .then(() => showSuccessBar(`Successfully ${data.isEnabled ? 'enabled' : 'disabled'} Quick Login`))
      .catch(() => showWarningBar(`Failed to ${data.isEnabled ? 'enable' : 'disable'} Quick Login`)),
    [onSubmit, showWarningBar, showSuccessBar, reset],
  )

  return (
    _react2.default.createElement('form', { className: "settings--login--setup", onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      , _react2.default.createElement('label', { className: "settings--login--pin-checkbox--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        , _react2.default.createElement(_Form.CheckboxController, {
          name: "isEnabled",
          control: control,
          className: "settings--login--pin-checkbox", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        )
        , _react2.default.createElement('div', { className: "settings--login--pin-checkbox--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
          , _react2.default.createElement('span', { className: "settings--login--pin-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Use Quick Login"  )
          , _react2.default.createElement('span', { className: "settings--login--pin-checkbox--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "Quick Login lets you log in to BloomText using a "
                      ,  pinLength , "-digit PIN code on devices you trust, making it easier to log in and switch users on shared machines at your practice."
          )
        )
      )
      , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
        "settings--login--pin-section",
        !isEnabled && "settings--login--pin-section--disabled",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        , _react2.default.createElement('span', { className: "settings--login--pin-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}, "Enter a PIN"  )
        , _react2.default.createElement('div', { className: "settings--login--pin-form-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          , _react2.default.createElement(_Form.PinInputController, {
            control: control,
            name: "pin",
            pinLength: pinLength,
            isDisabled: !isEnabled,
            enableFakePrefill: isEnabled,
            className: "settings--login--pin-form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
          )
          , _react2.default.createElement('span', { className: "settings--login--pin-hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "Enter a "
              , pinLength, "-digit PIN code."
          )
        )
      )
      , _react2.default.createElement(_Form.Button, {
        isSubmit: true,
        isLoading: isSubmitting,
        isDisabled: !isValid || !isDirty,
        className: "settings--login--save-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
      , "Save"

      )
    )
  )
}

exports. default = QuickLoginSection
