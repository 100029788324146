"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/ResultsList/ResultsSection/EntityItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Avatar = require('web/components/Avatar');






const EntityItem = ({ entity, onSelectEntity }) => (
  _react2.default.createElement('div', {
    role: "button",
    className: "participants-picker--result",
    onClick: () => onSelectEntity(entity), __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
  
    , _react2.default.createElement('div', { className: "participants-picker--result--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , _react2.default.createElement(_Avatar.EntityAvatar, { size: 40, entity: entity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} )
    )
    , _react2.default.createElement('div', { className: "participants-picker--result--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , _react2.default.createElement('span', { className: "participants-picker--result--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        ,  entity.name 
      )
      , _react2.default.createElement('span', { className: "participants-picker--result--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        ,  entity.description 
      )
    )
  )
)

exports. default = EntityItem
