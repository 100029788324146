"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _useSafeSwitch = require('shared/hooks/useSafeSwitch'); var _useSafeSwitch2 = _interopRequireDefault(_useSafeSwitch);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

const useAutoReplyToggle = (teamKey, isAutoReplyOn) => {
  const onToggleAutoReply = _react.useCallback.call(void 0, 
    async (isOn) => _actions2.default.updateTeam({
      TeamKey: teamKey,
      AutoReply: isOn,
    }),
    [teamKey],
  )
  return _useSafeSwitch2.default.call(void 0, isAutoReplyOn, onToggleAutoReply)
}

exports. default = useAutoReplyToggle
