"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/PaginatedTable.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');







var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _broadcast = require('shared/selectors/broadcast');
var _useBus = require('shared/hooks/useBus'); var _useBus2 = _interopRequireDefault(_useBus);

var _Table = require('./components/Table'); var _Table2 = _interopRequireDefault(_Table);
var _Row = require('./Row'); var _Row2 = _interopRequireDefault(_Row);
var _Pagination = require('web/components/Pagination'); var _Pagination2 = _interopRequireDefault(_Pagination);

const LOAD_LIMIT = 12
const keyExtractor = (c) => c.PatientKey











const PaginatedTable = ({
  campaignKey,
  keyActionTypes,
  selectedRowsMap,
  onSetRowSelection,
  onSetRowsBatchSelection,
  areAnyRowsSelected,
  totalPatientsCount,
  onAddPatients,
}) => {
  const [itemKeysList, setItemKeysList] = _react.useState([])
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [currentPage, setCurrentPage] = _react.useState.call(void 0, 0)

  const loadItemsList = _react.useCallback.call(void 0, 
    async (page = 0) => {
      setIsLoading(true)
      setItemKeysList([])
      await _actions2.default
        .findCampaignMessages(campaignKey, page * LOAD_LIMIT, LOAD_LIMIT)
        .then(r => r.Messages)
        .then(ms => setItemKeysList(ms.map(keyExtractor)))
      setIsLoading(false)
    },
    [campaignKey, setItemKeysList, setIsLoading],
  )

  const gotoPage = _react.useCallback.call(void 0, 
    page => {
      setCurrentPage(page)
      loadItemsList(page)
    },
    [loadItemsList, setCurrentPage],
  )
  const onReset = _react.useCallback.call(void 0, () => gotoPage(0), [gotoPage])
  _useBus2.default.call(void 0, keyActionTypes, onReset)
  _react.useEffect.call(void 0, () => { onReset() }, [onReset])

  const onSetAllRowsSelection = _react.useCallback.call(void 0, 
    isSelected => onSetRowsBatchSelection(itemKeysList, isSelected),
    [itemKeysList, onSetRowsBatchSelection],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Table2.default, {
        onSetAllRowsSelection: onSetAllRowsSelection,
        areAnyRowsSelected: areAnyRowsSelected,
        onAddPatients: onAddPatients,
        isLoading: isLoading,
        hasItems: itemKeysList.length > 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      
        ,  itemKeysList.map(patientKey => (
          _react2.default.createElement(_Row2.default, {
            key: patientKey,
            patientKey: patientKey,
            isSelected: !!selectedRowsMap[patientKey],
            onToggleSelection: b => onSetRowSelection(patientKey, b), __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
          )
        ))
      )
      ,  totalPatientsCount > 0 && totalPatientsCount > LOAD_LIMIT && (
        _react2.default.createElement(_Pagination2.default, {
          className: "campaign--table--pagination",
          minPage: 0,
          currentPage: currentPage,
          maxPage: Math.ceil(totalPatientsCount / LOAD_LIMIT) - 1,
          gotoPage: gotoPage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        )
      )
    )
  )
}





const mapState = (state, { campaignKey }) => ({
  totalPatientsCount: _broadcast.selectCampaignTotalPatientsCount.call(void 0, state, campaignKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(PaginatedTable)
