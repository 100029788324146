"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Modals/Netstats.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const mapNetworkQualityToStatus = quality =>
  quality >= 85 && 'good' ||
  quality >= 60 && 'decent' ||
  quality >= 40 && 'low' || 'very low'

exports. default = ({
  isOpen,
  onClose,
  quality,
  bpsUp,
  bpsDown,
  lossUp, worstLossUp,
  lossDown, worstLossDown,
  videoTracksStats,
}) => {
  const networkStatus = mapNetworkQualityToStatus(quality)
  const networkStatusClassName = _classBuilder2.default.call(void 0, 
    "meeting--netstats--score",
    `meeting--netstats--score--${networkStatus.replace(' ', '-')}`,
  )

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "Network Stats" ,
      className: "meeting--netstats-modal",
      bodyClassName: "meeting--netstats-modal--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    
      , _react2.default.createElement('div', { className: "meeting--netstats-modal--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        ,  quality &&
          _react2.default.createElement('h4', { className: "meeting--netstats--notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}, "Your network quality is"

            , _react2.default.createElement('span', { className: networkStatusClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}},  networkStatus ), "("
            ,  quality , "%)"
          )
        
        , _react2.default.createElement('div', { className: "meeting--netstats--speed-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
          , _react2.default.createElement('div', { className: "meeting--netstats--speed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
            , _react2.default.createElement('img', {
              className: "meeting--netstats--speed-icon meeting--netstats--speed-icon-up" ,
              src: "/assets/icons/video/download-purple.svg",
              alt: "⬆", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
            )
            , _react2.default.createElement('span', { className: "meeting--netstats--speed-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Up: " , bpsUp, "/s")
          )
          , _react2.default.createElement('div', { className: "meeting--netstats--speed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
            , _react2.default.createElement('img', {
              className: "meeting--netstats--speed-icon",
              src: "/assets/icons/video/download-purple.svg",
              alt: "⬇", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
            )
            , _react2.default.createElement('span', { className: "meeting--netstats--speed-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "Down: " , bpsDown, "/s")
          )
        )
        , _react2.default.createElement('div', { className: "meeting--netstats--details-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          , _react2.default.createElement('div', { className: "meeting--netstats--loss-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
            , _react2.default.createElement('h4', { className: "meeting--netstats--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "Video packet loss"  )
            , _react2.default.createElement('div', { className: "meeting--netstats--loss-entry", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              , _react2.default.createElement('span', { className: "meeting--netstats--loss-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}, "Send: " , lossUp, "%")
              , _react2.default.createElement('span', { className: "meeting--netstats--loss-worst", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "(worst: " , worstLossUp, "%)")
            )
            , _react2.default.createElement('div', { className: "meeting--netstats--loss-entry", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
              , _react2.default.createElement('span', { className: "meeting--netstats--loss-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Receive: " , lossDown, "%")
              , _react2.default.createElement('span', { className: "meeting--netstats--loss-worst", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "(worst: " , worstLossDown, "%)")
            )
          )
          ,  !videoTracksStats.length ? null :
            _react2.default.createElement('div', { className: "meeting--netstats--video-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
              , _react2.default.createElement('h4', { className: "meeting--netstats--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}, "Video stream quality"  )
              ,  videoTracksStats.map((t, i) =>
                  _react2.default.createElement('span', { key: i, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
                    , t.name, ": " , t.width, "x", t.height, "@", (t.frameRate || 0).toFixed(2), "FPS"
                  ),
              )
              
            )
          
        )
        , _react2.default.createElement('a', {
          onClick: onClose,
          className: "blmh-button meeting--btn primary purple highlight meeting--netstats--proceed"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
        , "Done"

        )
      )
    )
  )
}
