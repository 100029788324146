"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/RenameChatModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _blobject = require('shared/selectors/blobject');
var _chatName = require('shared/validation/chat/chatName');
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _Form = require('web/components/Form');







const RenameInboxItemModal = ({
  onClose, blobjectKey, name,
}) => {
  const { reset, register, handleSubmit, formState } = _reacthookform.useForm.call(void 0, {
    resolver: _chatName.chatNameResolver,
    mode: 'onChange',
    defaultValues: { name },
  })
  const { isValid, isSubmitting, isDirty, errors } = formState

  _react.useEffect.call(void 0, () => reset({ name }), [reset, name])

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    ({ name }) => _actions2.default.renameChat({ BKey: blobjectKey, Name: name })
      .then(onClose)
      .then(() => showSuccessBar('Chat has been renamed'))
      .catch(() => showWarningBar('Failed to rename chat')),
    [blobjectKey, onClose, showSuccessBar, showWarningBar],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "Chat Name" ,
        placeholder: "Enter a new chat name"    ,
        inputProps: register('name'),
        errorMessage: _optionalChain([errors, 'access', _ => _.name, 'optionalAccess', _2 => _2.message]),
        className: "inbox--rename-chat-modal--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Save",
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        onPrimaryBtnClick: handleSubmit(onSubmit),
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      )
    )
  )
}





const mapState = (state, { blobjectKey }) => {
  const blobject = _blobject.selectBlobjectByKey.call(void 0, state, blobjectKey)

  return {
    name: _optionalChain([blobject, 'optionalAccess', _3 => _3.IsNamed]) && _optionalChain([blobject, 'optionalAccess', _4 => _4.Name]) || '',
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(RenameInboxItemModal)
