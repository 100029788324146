"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Section/InputSectionWrapper.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);


var _Tooltip = require('web/components/Tooltip');














const InputSectionWrapper = ({
  className,
  titleClassName,
  isDisabled,
  isOptional,
  title,
  desc,
  hint,
  children,
  errorMessage,
  shouldCaptureFocus = true,
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, "blm-input-section", className)

  const content = (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  title && (
        _react2.default.createElement('span', {
          className: _classBuilder2.default.call(void 0, 
            "blm-input-section--title",
            isDisabled && "blm-input-section--title--disabled",
            titleClassName,
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        
          ,  title 
          ,  isOptional && (
            _react2.default.createElement('span', { className: "blm-input-section--title--suffix", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, "(Optional)")
          )
          ,  hint
            ? (
                _react2.default.createElement(_Tooltip.HoverTooltip, {
                  type: "light",
                  place: "right",
                  className: "blm-input-section--info-toggle-wrapper",
                  content: 
                    _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
                      , _react2.default.createElement(_Tooltip.TooltipTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}},  title )
                      , _react2.default.createElement(_Tooltip.TooltipHint, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}},  hint )
                    )
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
                
                  , _react2.default.createElement('span', { className: "blm-input-section--info-toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} )
                )
              )
            : null
          
        )
      )
      ,  children 
      ,  (errorMessage || desc) && (
        _react2.default.createElement('div', { className: "blm-input-section--extra", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          ,  errorMessage
            ? _react2.default.createElement('span', { className: "blm-input-section--error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}},  errorMessage )
            : _react2.default.createElement('span', { className: "blm-input-section--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}},  desc )
          
        )
      )
    )
  )

  return shouldCaptureFocus
    ? _react2.default.createElement('label', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}},  content )
    : _react2.default.createElement('div', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}},  content )
}

exports. default = InputSectionWrapper
