"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);



var _time = require('shared/services/time');
var _useSafeSwitch = require('shared/hooks/useSafeSwitch'); var _useSafeSwitch2 = _interopRequireDefault(_useSafeSwitch);




 const DEFAULT_DND_START = '17:00:00'; exports.DEFAULT_DND_START = DEFAULT_DND_START
 const DEFAULT_DND_END = '09:00:00'; exports.DEFAULT_DND_END = DEFAULT_DND_END










 const useDND = ({
  initialStart,
  initialEnd,
  initialTimezone,
  onSubmit,
  onDisable,
  shouldSubmitOnChange,
}) => {
  const initialDNDRange = _react.useMemo.call(void 0, 
    () => [
      _moment2.default.call(void 0, initialStart || exports.DEFAULT_DND_START, _moment.HTML5_FMT.TIME_SECONDS),
      _moment2.default.call(void 0, initialEnd || exports.DEFAULT_DND_END, _moment.HTML5_FMT.TIME_SECONDS),
    ],
    [initialStart, initialEnd],
  )

  const [dndRange, setDndRange] = _react.useState(initialDNDRange)
  const [timezone, setTimezone] = _react.useState(initialTimezone || _time.userTZ )

  const onSaveDND = ([start, end] = dndRange, tz = timezone) => onSubmit({
    StartTime: start.format(_moment.HTML5_FMT.TIME_SECONDS),
    EndTime: end.format(_moment.HTML5_FMT.TIME_SECONDS),
    TimeZone: tz,
  })

  const onToggleDND = (isOn) => isOn ? onSaveDND() : onDisable()

  const [isDndEnabled, onChangeDNDStatus] =
    _useSafeSwitch2.default.call(void 0, !!(initialStart && initialEnd), onToggleDND)

  const onChangeTime = async (picker, m) => {
    const newRange = [
      picker === 'start' ? m : dndRange[0],
      picker === 'end' ? m : dndRange[1],
    ]
    setDndRange(newRange)
    if (shouldSubmitOnChange) return onSaveDND(newRange)
  }
  const onChangeStartTime = (m) => onChangeTime('start', m)
  const onChangeEndTime = (m) => onChangeTime('end', m)
  const onChangeTimezone = (tz) => {
    setTimezone(tz)
    return onSaveDND(dndRange, tz)
  }

  return {
    dndRange,
    timezone,
    isDndEnabled,
    onChangeDNDStatus,
    onChangeStartTime,
    onChangeEndTime,
    onChangeTimezone,
    onSaveDND,
  }
}; exports.useDND = useDND
