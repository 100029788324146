"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Modal/components/ModalControls.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);













const ModalControls = ({
  primaryBtnLabel,
  primaryBtnTheme,
  isPrimaryEnabled = true,
  isPrimaryLoading,
  isPrimarySubmit,
  onPrimaryBtnClick,
  secondaryBtnLabel,
  onSecondaryBtnClick,
  className,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 'blm-modal--buttons-wrapper', className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
    ,  onSecondaryBtnClick && (
      _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onSecondaryBtnClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        ,  secondaryBtnLabel 
      )
    )
    ,  (onPrimaryBtnClick || isPrimarySubmit) && (
      _react2.default.createElement(_Form.Button, {
        isDanger: primaryBtnTheme === 'danger',
        isSubmit: isPrimarySubmit,
        isLoading: isPrimaryLoading,
        onClick: onPrimaryBtnClick,
        isDisabled: !isPrimaryEnabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      
        ,  primaryBtnLabel 
      )
    )
  )
)

exports. default = ModalControls
