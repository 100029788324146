"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }



 const selectBlobjects = (state) => _optionalChain([state, 'optionalAccess', _ => _.dataV2, 'optionalAccess', _2 => _2.blobjects]) || {}; exports.selectBlobjects = selectBlobjects
 const selectBlobjectByKey = (state, k) =>
  state.dataV2.blobjects[k] || null; exports.selectBlobjectByKey = selectBlobjectByKey

 const selectBlobjectWrappedKey =
  (state, blobjectKey) =>
    _optionalChain([exports.selectBlobjectByKey.call(void 0, state, blobjectKey), 'optionalAccess', _3 => _3.WrappedKey]) || null; exports.selectBlobjectWrappedKey = selectBlobjectWrappedKey

 const selectBlobjectWrappedType =
  (state, blobjectKey) =>
    _optionalChain([exports.selectBlobjectByKey.call(void 0, state, blobjectKey), 'optionalAccess', _4 => _4.WrappedType]) || null; exports.selectBlobjectWrappedType = selectBlobjectWrappedType
