"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/RejectMember/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _GenericUserAction = require('../GenericUserAction'); var _GenericUserAction2 = _interopRequireDefault(_GenericUserAction);
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);

const RejectMemberModal = props => (
  _react2.default.createElement(_GenericUserAction2.default, {
    isOpen: props.isOpen,
    onClose: props.onClose,
    title: "Reject member" ,
    entity: props.entity,
    orgKey: props.orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
  
    , _react2.default.createElement(_Form2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}} )
  )
)


exports. default = RejectMemberModal
