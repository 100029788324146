"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');
var _uuid = require('shared/services/uuid');
var _store = require('shared/store');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);











const loadPatientConversationByCodeActions =
  _utils.createAsyncActions('LoadPatientConversationByCode')
const markPatientConversationSeenActions =
  _utils.createAsyncActions('MarkPatientConversationSeen')
const getPatientConversationMessagesActions =
  _utils.createAsyncActions('GetPatientConversationMessages')
const sendPatientConversationMessageActions =
  _utils.createAsyncActions('SendPatientConversationMessage')
const sendPatientConversationFileActions =
  _utils.createAsyncActions('SendPatientConversationMessage (File)')
const uploadPatientFileActions = _utils.createAsyncActions('HTTP patient file upload')

 const actionCreators = {
  loadPatientConversationByCodeActions,
  markPatientConversationSeenActions,
  getPatientConversationMessagesActions,
  sendPatientConversationMessageActions,
  sendPatientConversationFileActions,
  uploadPatientFileActions,
}; exports.actionCreators = actionCreators

const loadPatientConversationByCode = (req) =>
  _helpers.wsCommand(
    'LoadPatientConversationByCode',
    loadPatientConversationByCodeActions,
    req,
    null,
  )

const markPatientConversationSeen = (req) =>
  _helpers.wsCommand(
    'MarkPatientConversationSeen',
    markPatientConversationSeenActions,
    req,
    null,
  )

const getPatientConversationMessages = (req) =>
  _helpers.wsCommand(
    'GetPatientConversationMessages',
    getPatientConversationMessagesActions,
    req,
    null,
  )

const sendPatientConversationMessage = (req) =>
  _helpers.wsCommand(
    'SendPatientConversationMessage',
    sendPatientConversationMessageActions,
    req,
    { lid: _uuid.uuidv4.call(void 0, ) },
  )










const uploadPatientFile = async (
  req,
  meta,
) => {
  _store.dispatch.call(void 0, uploadPatientFileActions.REQUEST(req, meta))
  return _client2.default.uploadFile(req.file).then((r) => {
    _store.dispatch.call(void 0, uploadPatientFileActions.SUCCESS(r, meta))
    return r
  }).catch((e) => {
    _store.dispatch.call(void 0, uploadPatientFileActions.FAILURE(e, meta))
    throw e
  })
}

const sendPatientConversationFile = async (req) => {
  const meta = { lid: _uuid.uuidv4.call(void 0, ), ConversationKey: req.ConversationKey }
  return uploadPatientFile(req, meta)
    .then(Metadata => {
      _helpers.wsCommand(
        'SendPatientConversationMessage',
        sendPatientConversationFileActions,
        { ConversationKey: req.ConversationKey, FileKey: Metadata.FileKey },
        { ...meta, Metadata },
      )
    })
}

exports. default = {
  loadPatientConversationByCode,
  markPatientConversationSeen,
  getPatientConversationMessages,
  sendPatientConversationMessage,
  sendPatientConversationFile,
}
