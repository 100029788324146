"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/components/Usage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _utils = require('shared/utils');

var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);











const clampProgress = _utils.clamp.call(void 0, 0, 1)
const FeatureUsage = ({
  title,
  subtitle,
  desc,
  progress,
  isEditable,
  checkoutBtnLabel = 'Upgrade',
  onCheckout,
}) => {
  const isOverLimit = progress > 1

  return (
    _react2.default.createElement('div', { className: "settings--org-subs--feat--usage", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      , _react2.default.createElement('div', { className: "settings--org-subs--feat--usage-title-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        , _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, 
          "settings--org-subs--feat--usage-title",
          isOverLimit && "settings--org-subs--feat--usage-title-warning",
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          ,  title 
        )
        , _react2.default.createElement('span', { className: "settings--org-subs--feat--usage-count", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}},  subtitle )
      )
      , _react2.default.createElement('div', { className: "settings--org-subs--feat--usage-progress-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , _react2.default.createElement('div', {
          style: { flex: clampProgress(progress) },
          className: _classBuilder2.default.call(void 0, 
            "settings--org-subs--feat--usage-progress-value",
            isOverLimit && "settings--org-subs--feat--usage-progress-value-warning",
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        )
      )
      , _react2.default.createElement('div', { className: "settings--org-subs--feat--usage-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        ,  desc && (
          _react2.default.createElement('span', { className: "settings--org-subs--feat--usage-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}},  desc )
        )
        ,  onCheckout && (
          _react2.default.createElement(_Form.Button, {
            isInverted: true,
            isDisabled: !isEditable,
            onClick: onCheckout,
            className: "settings--org-subs--feat--usage-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
          
            ,  checkoutBtnLabel 
          )
        )
      )
    )
  )
}

exports. default = FeatureUsage
