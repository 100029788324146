"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/components/InfoBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const InfoBtn = ({
  label,
  iconSrc,
  theme,
  onClick,
  className,
}) => (
  _react2.default.createElement('div', {
    role: "button",
    onClick: onClick,
    className: _classBuilder2.default.call(void 0, 
      "team-page--info-btn",
      theme === 'danger' && "team-page--info-btn--danger",
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
  
    , _react2.default.createElement('div', { className: "team-page--info-btn--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , _react2.default.createElement('img', { src: iconSrc, alt: label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
    )
    ,  label 
  )
)

exports. default = InfoBtn
