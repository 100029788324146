"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _sort = require('shared/services/sort');
var _uuid = require('shared/services/uuid');
var _message = require('shared/enum/message');

const initialState = {}

const VERY_BIG_NUMBER = 1000000000000

/* when messages come in they come in as a map of  messageKey -> message so we leverage that
 */
function combineMessages (currentMessagesArr = [], newMessagesMap = {}) {
  const currentMessagesMap = currentMessagesArr.reduce((acc, m) => {
    acc[m.MessageKey] = m
    return acc
  }, {})

  Object.values(newMessagesMap).forEach(m => {
    currentMessagesMap[m.MessageKey] = m
  })

  return Object.values(currentMessagesMap).sort((a, b) => _sort.sortByString.call(void 0, b.Sequence, a.Sequence))
}

 function messageReducer (state = initialState, action, globalState) {
  const currentUser = globalState.currentUser
  /* variables we redefine later on */
  let Conversations, ConversationKey, MessageKey, FileKey, message, messages, currentHighestSequence

  switch (action.type) {
    case _actionCreators2.default.updateNotificationAction.type:
    case _actionCreators2.default.loadConversationActions.SUCCESS.type:
    case _actionCreators2.default.loadConversationAroundTimeActions.SUCCESS.type:
    case _actionCreators2.default.loadConversationAroundMessageActions.SUCCESS.type:
      Conversations = action.payload.Conversations /* map of conversationKey -> conversation */

      return Object.values(Conversations || {}).reduce((state, c) => {
        const newMessagesMap = c.Messages
        const currentMessagesArr = state[c.ConversationKey] ? state[c.ConversationKey] : []

        state[c.ConversationKey] = combineMessages(currentMessagesArr, newMessagesMap)
        return state
      }, state)

    case _actionCreators2.default.sendMessageToConversationActions.REQUEST.type: {
      const { ConversationKey, MessageKey, TextContent } = action.payload
      const { isRetrying } = action.meta

      messages = state[ConversationKey] || []
      currentHighestSequence = messages[messages.length - 1]
        ? messages[messages.length - 1].Sequence
        : VERY_BIG_NUMBER

      const newMessage = {
        Sequence: currentHighestSequence + 1,
        SentAt: new Date().toISOString(),
        MessageKey: MessageKey,
        SenderType: "user",
        MessageType: "text",
        UserKey: currentUser.UserKey,
        PatientKey: _uuid.EMPTY_BLUID,
        TeamKey: _uuid.EMPTY_BLUID,
        MessageText: TextContent,
        deliveryStatus: _message.deliveryStatus.SENT,
        // These are the "view" fields from the server
        DisplayName: currentUser.Name,
        Avatar: currentUser.ProfilePicture,
      }

      const conversation = isRetrying
        ? messages.map(m => m.MessageKey === MessageKey
          ? { ...m, ...newMessage, Sequence: m.Sequence }
          : m,
        )
        : messages.concat(newMessage)

      return { [ConversationKey]: conversation }
    }

    case _actionCreators2.default.sendMessageToConversationActions.FAILURE.type: {
      const { ConversationKey, MessageKey } = action.meta
      const messages = state[ConversationKey] || []

      return {
        [ConversationKey]: messages.map(
          m => m.MessageKey === MessageKey
            ? {
                ...m,
                deliveryStatus: _message.deliveryStatus.FAILED,
                hasFailedPreviously: true, // Only used to ignore its invalid Sequence
              }
            : m,
        ),
      }
    }

    case _actionCreators2.default.deleteMessageFromConversationActions.SUCCESS.type: {
      const { ConversationKey } = action.meta
      const { MessageKey } = action.payload
      const messages = state[ConversationKey] || []

      return {
        [ConversationKey]: messages.map(m =>
          m.MessageKey !== MessageKey ? m : { ...m, Deleted: true },
        ),
      }
    }

    case _actionCreators2.default.uploadFileActions.REQUEST.type: {
      const { ConversationKey, file } = action.payload
      const { MessageKey, isRetrying } = action.meta
      const messages = state[ConversationKey] || []
      const currentHighestSequence = messages[messages.length - 1]
        ? messages[messages.length - 1].Sequence
        : VERY_BIG_NUMBER

      const FileInfo = {
        Size: file.size,
        PreviewUrl: file.preview,
      }

      const newMessage = {
        Sequence: currentHighestSequence + 1,
        SentAt: new Date().toISOString(),
        MessageKey: MessageKey,
        SenderType: "user",
        MessageType: "file",
        UserKey: currentUser.UserKey,
        PatientKey: _uuid.EMPTY_BLUID,
        TeamKey: _uuid.EMPTY_BLUID,
        FileName: file.name,
        FileInfo,
        FileReference: null,
        Uploading: true,
        deliveryStatus: _message.deliveryStatus.SENT,
      }

      const conversation = isRetrying
        ? messages.map(m => m.MessageKey === MessageKey
          ? { ...newMessage, Sequence: m.Sequence }
          : m,
        )
        : messages.concat(newMessage)

      return { [ConversationKey]: conversation }
    }

    case _actionCreators2.default.sendFileToConversationActions.REQUEST.type: {
      ConversationKey = action.payload.ConversationKey
      FileKey = action.payload.FileKey
      MessageKey = action.meta.MessageKey
      messages = state[ConversationKey] || []
      message = messages.find(m => m.MessageKey === MessageKey)
      if (!message) return state

      const copiedMessage = { ...message }

      copiedMessage.Uploading = false
      copiedMessage.FileReference = FileKey

      state[ConversationKey] = combineMessages(messages, { [MessageKey]: copiedMessage })
      return state
    }

    case _actionCreators2.default.sendFileToConversationActions.FAILURE: {
      const { ConversationKey, MessageKey, isAborted } = action.meta
      const messages = state[ConversationKey] || []

      const newMessages = messages.map(
        m => m.MessageKey === MessageKey
          ? {
              ...m,
              deliveryStatus: isAborted
                ? _message.deliveryStatus.CANCELED
                : _message.deliveryStatus.FAILED,
            }
          : m,
      )

      return { [ConversationKey]: newMessages }
    }

    case _actionCreators2.default.pruneLocalMessageAction.type: {
      const { ConversationKey, MessageKey } = action.payload
      const messages = state[ConversationKey] || []

      return {
        [ConversationKey]: messages.filter(m => m.MessageKey !== MessageKey),
      }
    }
  }

  return state
} exports.default = messageReducer;
