"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _errors = require('shared/errors');

 const ALERTS_URL = "https://s3.amazonaws.com/bloomtext-alerts/alert.json"; exports.ALERTS_URL = ALERTS_URL

 const fetchAlerts = () =>
  fetch(exports.ALERTS_URL, { cache: "no-cache" }) // most up to date
    .then(r => r.status >= 200 && r.status <= 300
      ? r.json()
      : Promise.reject(new (0, _errors.RestError)("GET", exports.ALERTS_URL, r)),
    )
    .then(r => r.alert); exports.fetchAlerts = fetchAlerts
