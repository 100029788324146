"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/Quick/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _settings = require('shared/selectors/settings');

var _components = require('web/screens/Login/components');
var _UserPicker = require('./UserPicker'); var _UserPicker2 = _interopRequireDefault(_UserPicker);
var _PinForm = require('./PinForm'); var _PinForm2 = _interopRequireDefault(_PinForm);








const QuickLogin = ({
  gotoUsernameLogin,
  onRemoveUser,
  onQuickLogin,
  quickLoginUsersList,
}) => {
  const [selectedUserKey, setSelectedUserKey] = _react.useState(null)
  const onSelect = (selectedUserKey) => selectedUserKey
    ? setSelectedUserKey(selectedUserKey)
    : gotoUsernameLogin()

  const gotoUsersList = () => setSelectedUserKey(null)

  return (
    _react2.default.createElement(_components.FormWrapper, { title: "Log in" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      ,  !selectedUserKey
        ? _react2.default.createElement(_UserPicker2.default, {
            quickLoginUsersList: quickLoginUsersList,
            onPick: onSelect,
            onRemove: onRemoveUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
          )
        : _react2.default.createElement(_PinForm2.default, {
            userKey: selectedUserKey,
            onSubmit: onQuickLogin,
            gotoUsernameLogin: gotoUsernameLogin,
            gotoUsersList: gotoUsersList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          )
      
    )
  )
}





const mapState = (state) => ({
  quickLoginUsersList: _settings.selectQuickLoginUsersList.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(QuickLogin)
