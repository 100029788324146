"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/InvalidRoomLink/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

exports. default = () => (
  _react2.default.createElement('div', { className: "meeting--invalid-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 4}}
    , _react2.default.createElement('div', { className: "meeting--invalid-link--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
      , _react2.default.createElement('div', { className: "meeting--invalid-link--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}} )
      , _react2.default.createElement('h3', { className: "meeting--invalid-link--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}, "Your video meeting link is broken."     )
      , _react2.default.createElement('span', { className: "meeting--invalid-link--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}, "Please make sure you have a correct link or request a new one."

      )
    )
  )
)
