"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/FileInput.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactdropzone = require('react-dropzone'); var _reactdropzone2 = _interopRequireDefault(_reactdropzone);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _Spinner = require('../Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);

const FileUploadStages = {
  UPLOADING: "UPLOADING",
  DONE: "DONE",
  FAILED: "FAILED",
}

const FileItem = (props) => {
  const { name, type, stage, onRemove, colorBackground } = props

  /* choose icon image based on MIME type */

  let fileIcon = _react2.default.createElement(_Spinner2.default, { size: "30px", color: "#9B9B9B", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}})
  if (stage === FileUploadStages.DONE) {
    switch (type) {
      case 'image/gif':
      case 'image/jpeg':
      case 'image/png':
        fileIcon = _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, "photo")
        break
      default:
        fileIcon = _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "insert_drive_file")
    }
  }

  let progressBarAdditionalClassName = ''
  switch (stage) {
    case FileUploadStages.UPLOADING:
      progressBarAdditionalClassName = 'uploading'
      break
    case FileUploadStages.DONE:
      progressBarAdditionalClassName = 'done'
      break
    case FileUploadStages.FAILED:
      progressBarAdditionalClassName = 'failed'
      break
  }

  return (
    _react2.default.createElement('div', { className: 'file-input--item' + (colorBackground ? ' file-input--bg' : ''), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      /* file icon */
      , _react2.default.createElement('div', { className: "file-input--item--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , fileIcon
      )

      /* name and progress bar */
      , _react2.default.createElement('div', { className: "file-input--item--description", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        , _react2.default.createElement('div', { className: 'file-input--item--name ' + (stage === FileUploadStages.DONE ? 'file-input--item--name-done' : ''), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, name)
        , _react2.default.createElement('div', { className: 'file-input--item--progress ' + progressBarAdditionalClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}})
      )

      /* remove button */
      , _react2.default.createElement('div', { className: "file-input--item--remove", onClick: onRemove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
        , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}, "close")
      )
    )
  )
}

FileItem.propTypes = {
  name: _proptypes2.default.string.isRequired,
  type: _proptypes2.default.string.isRequired,
  stage: _proptypes2.default.oneOf(Object.values(FileUploadStages)).isRequired,
  onRemove: _proptypes2.default.func.isRequired,
}

 class FileInput extends _react.Component {
  constructor (props) {
    super(props);FileInput.prototype.__init.call(this);FileInput.prototype.__init2.call(this);FileInput.prototype.__init3.call(this);
    this.state = {
      files: {},
    }
  }

  __init() {this.onChange = (files) => {
    const uploadedFiles = Object.values(files)
      .filter((f) => f.stage === FileUploadStages.DONE)
      .map((f) => f.FileKey)
    this.props.onChange(this.props.inputId, null, uploadedFiles)
  }}

  __init2() {this.onUpload = (accepted) => {
    let newFiles = {}
    accepted.forEach((f) => {
      const blob = f.preview.substr(f.preview.length - 36, 36) /* the blob UUID is 36 characters long */

      newFiles[blob] = {
        blob: blob,
        name: f.name,
        size: f.size,
        type: f.type,
        stage: FileUploadStages.UPLOADING,
      }

      this.setState({
        files: {
          ...this.state.files,
          ...newFiles,
        },
      })
      _client2.default.uploadFile(f)
        .then((res) => {
          if (!this.state.files[blob]) { /* file removed while uploading */
            return
          }

          newFiles = {
            ...newFiles,
            [blob]: {
              ...newFiles[blob],
              FileKey: res.FileKey,
              stage: FileUploadStages.DONE,
            },
          }

          /* compute new state with uploaded file */
          const newFilesObject = {
            ...this.state.files,
            ...newFiles,
          }
          this.setState({ files: newFilesObject })
          this.onChange(newFilesObject)
        })
        .catch((err) => {
          this.setState({
            ...this.state.files,
            ...newFiles,
            [blob]: {
              ...newFiles[blob],
              stage: FileUploadStages.FAILED,
            },
          })
        })
    })
  }}

  __init3() {this.onRemoveFile = (blob) => {
    const newFilesState = Object.assign({}, this.state.files)
    delete newFilesState[blob]
    this.setState({ files: newFilesState })
    this.onChange(newFilesState)
  }}

  render () {
    const { label, disabled, subtitle, description, maxFiles } = this.props
    const showDropzone = Object.keys(this.state.files).length < maxFiles

    return (
      _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "file-input", { disabled }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
        , label ? _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}, label) : null
        ,  subtitle ? _react2.default.createElement('div', { className: "file-input--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}, subtitle) : null 
        , _react2.default.createElement('div', { className: "file-input--file-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
          , Object.values(this.state.files).map((f) =>
            _react2.default.createElement(FileItem, {
              ...f,
              onRemove: () => this.onRemoveFile(f.blob), key: f.blob,
              colorBackground: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}}
            ))
        )
        , showDropzone ? _react2.default.createElement(_reactdropzone2.default, { className: "file-input--dropzone", onDrop: this.onUpload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}, description || "Add files (click or drag)", "    " , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}, "attach_file"))
        ) : null
      )
    )
  }
} exports.default = FileInput;

FileInput.propTypes = {
  onChange: _proptypes2.default.func.isRequired,
  inputId: _proptypes2.default.string.isRequired,
  label: _proptypes2.default.string,
  subtitle: _proptypes2.default.string,
  description: _proptypes2.default.string,
  maxFiles: _proptypes2.default.number,
  colorItems: _proptypes2.default.bool, // should we give file items a background color?
}

FileInput.defaultProps = {
  maxFiles: 10,
}
