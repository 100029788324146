"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/MembersSection/TeamMembersList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');




var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _error = require('shared/services/error');
var _usePagination = require('shared/hooks/usePagination'); var _usePagination2 = _interopRequireDefault(_usePagination);
var _directory = require('shared/hooks/directory');
var _noticeBar = require('web/services/context/noticeBar');

var _MemberItem = require('./MemberItem'); var _MemberItem2 = _interopRequireDefault(_MemberItem);
var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);

const MEMBER_LOAD_LIMIT = 30
const keyExtractor = (u) => u.UserKey








const TeamMembersList = ({
  canEditParticipants, orgKey, teamKey, className,
}) => {
  const [openedOptionsUserKey, setOpenedOptionsUserKey] = _react.useState(null)
  const onCloseUserOptions = _react.useCallback.call(void 0, 
    () => setOpenedOptionsUserKey(null),
    [setOpenedOptionsUserKey],
  )

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onRemoveMember = _react.useCallback.call(void 0, 
    UserKey => {
      onCloseUserOptions()
      _actions2.default.removeUserFromTeam({ TeamKey: teamKey, UserKey })
        .catch(() => showWarningBar('Failed to remove member'))
    },
    [onCloseUserOptions, teamKey, showWarningBar],
  )

  const loadItemsList = _react.useCallback.call(void 0, 
    (_query, Offset, Limit) => _actions2.default
      .findUsersInTeam({ TeamKey: teamKey, Offset, Limit })
      .then(r => r.Users)
      .catch(_error.passError.call(void 0, () => showWarningBar('Failed to load team members'))),
    [teamKey, showWarningBar],
  )

  const { isLoading, hasDrained, itemKeysList, onLoadMore, onReset } =
    _usePagination2.default.call(void 0, {
      loadItemsList,
      keyExtractor,
      limit: MEMBER_LOAD_LIMIT,
    })
  _directory.useTeamMembersListListener.call(void 0, onReset)

  return (
    _react2.default.createElement('div', { className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      ,  itemKeysList.map(userKey => (
        _react2.default.createElement(_MemberItem2.default, {
          key: userKey,
          userKey: userKey,
          orgKey: orgKey,
          onRemoveMember: onRemoveMember,
          openedOptionsUserKey: openedOptionsUserKey,
          onOpenOptions: setOpenedOptionsUserKey,
          onCloseOptions: onCloseUserOptions,
          canEditParticipants: canEditParticipants, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
        )
      ))
      ,  itemKeysList.length && !hasDrained
        ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: onLoadMore, bottomOffset: -100, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}} )
        : null
      
      , _react2.default.createElement(_ListResultsStatus2.default, {
        isLoading: isLoading,
        resultsCount: itemKeysList.length,
        noResultsLabel: "No members." , __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
    )
  )
}

exports. default = TeamMembersList
