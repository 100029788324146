"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/AvatarPicker/Form/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _noticeBar = require('web/services/context/noticeBar');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);

var _DefaultAvatarsList = require('./DefaultAvatarsList'); var _DefaultAvatarsList2 = _interopRequireDefault(_DefaultAvatarsList);
var _AvatarUpload = require('./AvatarUpload'); var _AvatarUpload2 = _interopRequireDefault(_AvatarUpload);
var _AvatarCropper = require('./AvatarCropper'); var _AvatarCropper2 = _interopRequireDefault(_AvatarCropper);
var _user = require('shared/selectors/user');





const AvatarPickerModal = ({ myUserKey, onClose }) => {
  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const [uploadedFile, setUploadedFile] = _react.useState(null)
  const [isSubmitting, setIsSubmitting] = _react.useState.call(void 0, false)
  const dropzoneRef = _react.useRef(null)
  const onUploadCustom = _react.useCallback.call(void 0, () => _optionalChain([dropzoneRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.open, 'call', _3 => _3()]), [])

  const onSubmit = (r) => _actions2.default.setAvatar(r, myUserKey)
    .then(onClose)
    .then(() => showSuccessBar('Avatar has been updated'))
    .catch(() => showWarningBar('Failed to update avatar'))

  const onSubmitCustom = async (f) => {
    setIsSubmitting(true)
    return _client2.default.uploadAvatar(f)
      .then(r => r.AvatarURL)
      .then(onSubmit)
      .catch(() => showWarningBar('Failed to upload avatar'))
      .finally(() => setIsSubmitting(false))
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_AvatarUpload2.default, { ref: dropzoneRef, onChange: setUploadedFile, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} )
      ,  uploadedFile
        ? _react2.default.createElement(_AvatarCropper2.default, {
            file: uploadedFile,
            onSubmit: onSubmitCustom,
            onClose: onClose,
            onReupload: onUploadCustom,
            isSubmitting: isSubmitting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
          )
        : _react2.default.createElement(_DefaultAvatarsList2.default, {
            onSubmit: onSubmit,
            onUploadCustom: onUploadCustom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          )
      
    )
  )
}





const mapState = (state) => ({
  myUserKey: _user.selectCurrentUserKey.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(AvatarPickerModal)
