"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/segments/AudioSetupGroup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }// Audio setup section, used to tweak user's audio settings.
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);




var _videoCall = require('web/selectors/videoCall');

var _videoCall3 = require('web/services/videoCall');
var _Audio = require('web/components/VideoCall/Setup/SetupGroups/Audio'); var _Audio2 = _interopRequireDefault(_Audio);

class AudioSetupGroupContainer extends _react2.default.Component {constructor(...args) { super(...args); AudioSetupGroupContainer.prototype.__init.call(this);AudioSetupGroupContainer.prototype.__init2.call(this);AudioSetupGroupContainer.prototype.__init3.call(this);AudioSetupGroupContainer.prototype.__init4.call(this);AudioSetupGroupContainer.prototype.__init5.call(this);AudioSetupGroupContainer.prototype.__init6.call(this); }
  __init() {this.state = { stream: null }}
  __init2() {this.streamManager = null}
  __init3() {this.updateStream = s => this.setState({ stream: s })}

  __init4() {this.componentDidUpdate = prevProps => {
    const {
      onUserProblemPublish,
      currentMicDeviceId: micDeviceId,
    } = this.props

    if (prevProps.currentMicDeviceId !== micDeviceId) {
      this.streamManager
        .update({ micDeviceId })
        .then(this.updateStream)
        .catch(onUserProblemPublish)
    }
  }}

  __init5() {this.componentDidMount = () => {
    const {
      onUserProblemPublish,
      currentMicDeviceId: micDeviceId,
    } = this.props

    this.streamManager = new (0, _videoCall3.StreamManager)({ micDeviceId, useMic: true })
    this.streamManager.update()
      .then(this.updateStream)
      .catch(onUserProblemPublish)
  }}

  __init6() {this.componentWillUnmount = () => this.streamManager.stop()}

  render () {
    const {
      audioInDevices,
      currentMicDeviceId,
      areMicIssuesPresent,
    } = this.props
    const { stream } = this.state

    return (
      _react2.default.createElement(_Audio2.default, {
        audioStream: stream,
        audioInDevices: audioInDevices,
        currentMicDeviceId: currentMicDeviceId,
        areMicIssuesPresent: areMicIssuesPresent,
        setCurrentMicDeviceId: _actions2.default.setCurrentMicDeviceId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )
    )
  }
}

const mapState = state => ({
  audioInDevices: _videoCall.selectAudioInDevices.call(void 0, state),
  areMicIssuesPresent: _videoCall.selectAreMicIssuesPresent.call(void 0, state),
  currentMicDeviceId: _videoCall.selectCurrentMicDeviceId.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(AudioSetupGroupContainer)
