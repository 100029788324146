"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/components/TableHeader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Checkbox = require('./Checkbox'); var _Checkbox2 = _interopRequireDefault(_Checkbox);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const TableHeader = ({
  areAnyRowsSelected,
  onSetAllRowsSelection,
  isSticky,
}) => (
  _react2.default.createElement('thead', {
    className: _classBuilder2.default.call(void 0, 
      "campaign--table--head",
      isSticky && "campaign--table--head--sticky",
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
  
    , _react2.default.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      , _react2.default.createElement('th', { className: "campaign--table--row-checkbox", 'aria-label': "Toggle selection" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        , _react2.default.createElement(_Checkbox2.default, {
          isChecked: areAnyRowsSelected,
          onChange: onSetAllRowsSelection, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        )
      )
      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Patient")
      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "Date of Birth"  )
      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, "Contact")
      , _react2.default.createElement('th', { className: "campaign--table--row-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, "Status")
      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, "Sent At" )
    )
  )
)

exports. default = TableHeader
