"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







var _noticeBar = require('web/services/context/noticeBar');

var _reactredux = require('react-redux');

var _team = require('shared/selectors/team');
var _AutoReplyToggleSection = require('./AutoReplyToggleSection'); var _AutoReplyToggleSection2 = _interopRequireDefault(_AutoReplyToggleSection);
var _BusyReplyToggleSection = require('./BusyReplyToggleSection'); var _BusyReplyToggleSection2 = _interopRequireDefault(_BusyReplyToggleSection);
var _MessageSection = require('./MessageSection'); var _MessageSection2 = _interopRequireDefault(_MessageSection);
var _ScheduleSection = require('./ScheduleSection'); var _ScheduleSection2 = _interopRequireDefault(_ScheduleSection);















const ScheduleTab = ({
  teamKey,
  isAutoReplyEnabled,
  autoReplyMessage,
  areTeamHoursSet,
  onToggleAutoReply,
  onUpdateAutoReplyInfo,
  isBusyReplyEnabled,
  busyReplyMessage,
  onToggleBusyReply,
  onUpdateBusyReplyInfo,
}) => {
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const [handleToggleAutoReply, onChangeAutoReplyMessage] = _react.useMemo.call(void 0, 
    () => [
      async (isOn) => onToggleAutoReply(isOn)
        .then(() => showSuccessBar(`Auto-reply has been ${isOn ? 'enabled' : 'disabled'}`))
        .catch(() => showWarningBar(`Failed to ${isOn ? 'enable' : 'disable'} auto-reply`)),

      async (autoReplyMessage) => onUpdateAutoReplyInfo({ autoReplyMessage })
        .then(() => showSuccessBar('Auto-reply message has been updated'))
        .catch(() => showWarningBar(`Failed to update auto-reply message`)),
    ],
    [onUpdateAutoReplyInfo, onToggleAutoReply, showWarningBar, showSuccessBar],
  )
  const [handleToggleBusyReply, onChangeBusyReplyMessage] = _react.useMemo.call(void 0, 
    () => [
      async (isOn) => onToggleBusyReply(isOn)
        .then(() => showSuccessBar(`Busy Reply has been ${isOn ? 'enabled' : 'disabled'}`))
        .catch(() => showWarningBar(`Failed to ${isOn ? 'enable' : 'disable'} Busy reply`)),

      async (BusyReplyMessage) => onUpdateBusyReplyInfo({ BusyReplyMessage })
        .then(() => showSuccessBar('Busy message has been updated'))
        .catch(() => showWarningBar(`Failed to update busy reply message`)),
    ],
    [onUpdateBusyReplyInfo, onToggleBusyReply, showWarningBar, showSuccessBar],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { 'data-testid': "team-page--out-of-office-message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        , _react2.default.createElement(_AutoReplyToggleSection2.default, {
          isEnabled: isAutoReplyEnabled,
          onChange: handleToggleAutoReply, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        )
        , _react2.default.createElement(_MessageSection2.default, {
          teamKey: teamKey,
          label: "Edit message" ,
          replyMessage: autoReplyMessage,
          isDisabled: !isAutoReplyEnabled,
          onChange: onChangeAutoReplyMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}

        )
      )
      , _react2.default.createElement(_ScheduleSection2.default, {
        teamKey: teamKey,
        isDisabled: false,
        onUpdateInfo: onUpdateAutoReplyInfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
      )
      , isAutoReplyEnabled && areTeamHoursSet && (
        _react2.default.createElement('div', { 'data-testid': "team-page--in-office-message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
          , _react2.default.createElement(_BusyReplyToggleSection2.default, {
            isEnabled: isBusyReplyEnabled,
            onChange: handleToggleBusyReply, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
          )
          , _react2.default.createElement(_MessageSection2.default, {
            teamKey: teamKey,
            label: "Edit message" ,
            replyMessage: busyReplyMessage,
            isDisabled: !isBusyReplyEnabled,
            onChange: onChangeBusyReplyMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
          )
        )
      )
    )
  )
}







const mapState = (state, { teamKey }) => ({
  autoReplyMessage: _team.selectTeamAutoReplyMessage.call(void 0, state, teamKey),
  busyReplyMessage: _team.selectBusyReplyMessage.call(void 0, state, teamKey),
  areTeamHoursSet: _team.selectAreTeamHoursSet.call(void 0, state, teamKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(ScheduleTab)
