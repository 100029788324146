"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _store = require('shared/store');
var _redux = require('redux');
var _utils = require('shared/store/utils');




const setSelectedOrgAction = _utils.createAction('Set selected org')
const setSelectedTeamAction = _utils.createAction('Set selected team')

 const actionCreators = {
  setSelectedOrgAction,
  setSelectedTeamAction,
}; exports.actionCreators = actionCreators

exports. default = _redux.bindActionCreators.call(void 0, {
  setSelectedOrg: setSelectedOrgAction,
  setSelectedTeam: setSelectedTeamAction,
}, _store.dispatch)
