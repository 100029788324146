"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/ExtraPhoneNumber/PhonePickerModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _components = require('web/components/Upgrade/UpgradeModal/components');
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);








const PhonePickerModal = ({
  isOpen,
  onClose,
  orgKey,
  onReloadSubscription,
}) => (
  _react2.default.createElement(_components.PlanModal, { isOpen: isOpen, onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    , _react2.default.createElement(_Form2.default, {
      orgKey: orgKey,
      onClose: onClose,
      onReloadSubscription: onReloadSubscription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    )
  )
)

exports. default = PhonePickerModal
