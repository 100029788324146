"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');






var _broadcast = require('shared/enum/broadcast');

const DEFAULT_STATS = {
  not_scheduled: 0,
  queued: 0,
  processing: 0,
  failed: 0,
  completed: 0,
}

 const selectCampaignByKey =
  (state, campaignKey) =>
    state.broadcasts.current[campaignKey] || null; exports.selectCampaignByKey = selectCampaignByKey

 const selectBroadcastMessageByKey =
  (state, patientKey) =>
    state.broadcastMessages.current[patientKey] || null; exports.selectBroadcastMessageByKey = selectBroadcastMessageByKey

 const selectCampaignName =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _nullishCoalesce(_optionalChain([c, 'optionalAccess', _ => _.Name]), () => ( null))); exports.selectCampaignName = selectCampaignName

 const selectCampaignOrgKey =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _nullishCoalesce(_optionalChain([c, 'optionalAccess', _2 => _2.OrgKey]), () => ( null))); exports.selectCampaignOrgKey = selectCampaignOrgKey

 const selectCampaignTeamKey =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _nullishCoalesce(_optionalChain([c, 'optionalAccess', _3 => _3.TeamKey]), () => ( null))); exports.selectCampaignTeamKey = selectCampaignTeamKey

 const selectIsCampaignActive =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _nullishCoalesce(_optionalChain([c, 'optionalAccess', _4 => _4.Active]), () => ( false))); exports.selectIsCampaignActive = selectIsCampaignActive

 const selectIsCampaignArchived =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _nullishCoalesce(_optionalChain([c, 'optionalAccess', _5 => _5.Archived]), () => ( false))); exports.selectIsCampaignArchived = selectIsCampaignArchived

 const selectCampaignMessageDelay =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _optionalChain([c, 'optionalAccess', _6 => _6.MessageDelay]) || null); exports.selectCampaignMessageDelay = selectCampaignMessageDelay

 const selectCampaignStats =
  _reselect.createSelector.call(void 0, exports.selectCampaignByKey, c => _optionalChain([c, 'optionalAccess', _7 => _7.Stats]) || DEFAULT_STATS); exports.selectCampaignStats = selectCampaignStats

 const selectCampaignNotScheduledCount =
  _reselect.createSelector.call(void 0, exports.selectCampaignStats, s => _nullishCoalesce(_optionalChain([s, 'optionalAccess', _8 => _8.not_scheduled]), () => ( 0))); exports.selectCampaignNotScheduledCount = selectCampaignNotScheduledCount

 const selectCampaignHasNotScheduled =
  _reselect.createSelector.call(void 0, exports.selectCampaignNotScheduledCount, n => n > 0); exports.selectCampaignHasNotScheduled = selectCampaignHasNotScheduled

 const selectCampaignQueuedCount =
  _reselect.createSelector.call(void 0, exports.selectCampaignStats, s => _nullishCoalesce(_optionalChain([s, 'optionalAccess', _9 => _9.queued]), () => ( 0))); exports.selectCampaignQueuedCount = selectCampaignQueuedCount

 const selectCampaignHasQueued =
  _reselect.createSelector.call(void 0, exports.selectCampaignQueuedCount, n => n > 0); exports.selectCampaignHasQueued = selectCampaignHasQueued

 const selectCampaignTotalPatientsCount = _reselect.createSelector.call(void 0, 
  exports.selectCampaignStats,
  s => s.not_scheduled + s.queued + s.processing + s.failed + s.completed,
); exports.selectCampaignTotalPatientsCount = selectCampaignTotalPatientsCount

 const selectCampaignProgress = _reselect.createSelector.call(void 0, 
  exports.selectCampaignStats,
  exports.selectCampaignTotalPatientsCount,
  (stats, totalCount) => totalCount ? stats.completed / totalCount : 0,
); exports.selectCampaignProgress = selectCampaignProgress

 const selectCampaignHasAnyPatientsToMessage = _reselect.createSelector.call(void 0, 
  exports.selectCampaignStats,
  s => !!(s.not_scheduled || s.queued || s.processing),
); exports.selectCampaignHasAnyPatientsToMessage = selectCampaignHasAnyPatientsToMessage

 const selectSelectedPatientsActions = _reselect.createSelector.call(void 0, 
  (state, patientKeys) => patientKeys
    .map(k => exports.selectBroadcastMessageByKey.call(void 0, state, k)),
  messagesList => {
    const canAct = messagesList.length > 0
    return messagesList.reduce(
      (acc, m) => {
        acc.canRemove = acc.canRemove &&
          _optionalChain([m, 'optionalAccess', _10 => _10.Status]) !== _broadcast.broadcastMsgStatus.PROCESSING &&
          _optionalChain([m, 'optionalAccess', _11 => _11.Status]) !== _broadcast.broadcastMsgStatus.COMPLETED &&
          _optionalChain([m, 'optionalAccess', _12 => _12.Status]) !== _broadcast.broadcastMsgStatus.FAILED
        acc.canRun = acc.canRun && _optionalChain([m, 'optionalAccess', _13 => _13.Status]) === _broadcast.broadcastMsgStatus.NOT_SCHEDULED
        acc.canDequeue = acc.canDequeue && _optionalChain([m, 'optionalAccess', _14 => _14.Status]) === _broadcast.broadcastMsgStatus.QUEUED
        return acc
      },
      { canRemove: canAct, canRun: canAct, canDequeue: canAct },
    )
  },
); exports.selectSelectedPatientsActions = selectSelectedPatientsActions

 const selectBroadcastMessageKeysByState = _reselect.createSelector.call(void 0, 
  (state) => state.broadcastMessages.current,
  (_s, filter) => filter,
  (messagesMap, filter) => Object
    .values(messagesMap)
    .reduce(
      (acc, m) => {
        if (m.Status === filter) acc.push(m.PatientKey)
        return acc
      },
      [] ,
    ),
); exports.selectBroadcastMessageKeysByState = selectBroadcastMessageKeysByState
