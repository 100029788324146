"use strict";Object.defineProperty(exports, "__esModule", {value: true});

 class CancellablePromise {
  
  

  constructor (fn) {
    this._canceled = false

    this.wrapPromise = new Promise((resolve, reject) => {
      const p = new Promise(fn)
      p.then(res => {
        if (!this._canceled) {
          return resolve(res )
        }
      }).catch(err => {
        if (!this._canceled) {
          return reject(err)
        }
      })
    })

    this.then = this.then.bind(this)
    this.cancel = this.cancel.bind(this)
  }

  // eslint-disable-next-line
  then (onFulfill, onReject) {
    return this.wrapPromise.then(onFulfill, onReject)
  }

  catch (onReject) {
    return this.wrapPromise.catch(onReject)
  }

  cancel () {
    this._canceled = true
  }
} exports.CancellablePromise = CancellablePromise;
