"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/RightSide/Header/MoreToggle.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _Tooltip = require('web/components/Tooltip');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





const MoreToggle = ({ children, className }) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      isDismissibleByClickElsewhere: true,
      place: "bottom-left",
      isOpen: isOpen,
      onClose: onClose,
      content: 
        _react2.default.createElement(_Tooltip.TooltipContent, {
          className: "right-side-header--more-toggle--popup",
          onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        
          ,  children 
        )
      ,
      className: "right-side-header--more-toggle--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    
      , _react2.default.createElement('div', {
        role: "button",
        'aria-label': "More settings" ,
        onClick: onOpen,
        className: _classBuilder2.default.call(void 0, 
          "right-side-header--more-toggle",
          isOpen && "right-side-header--more-toggle--active",
          className,
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      
        , _react2.default.createElement('img', { src: "/assets/icons/more-hor-black.svg", alt: "⚙", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
      )
    )
  )
}

exports. default = MoreToggle
