"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Sidebar/Icon.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _TitleTooltip = require('./TitleTooltip'); var _TitleTooltip2 = _interopRequireDefault(_TitleTooltip);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);














const normalizeBadgeCount = (n) => n > 9 ? '9+' : n

const SidebarIcon = ({
  title,
  shortTitle = title,
  iconName,
  shouldHideTitleTooltip,
  isActive,
  isHighlighted,
  onClick,
  hasBadge,
  badgeCount = 0,
}) => (
  _react2.default.createElement('div', {
    role: "button",
    'aria-current': isActive ? 'location' : false,
    onClick: onClick,
    className: "blm-sidebar--icon--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
  
    , _react2.default.createElement('div', {
      className: _classBuilder2.default.call(void 0, 
        "blm-sidebar--icon--adornment",
        !isActive && "blm-sidebar--icon--adornment--hidden",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
    )
    , _react2.default.createElement(_TitleTooltip2.default, { title: shouldHideTitleTooltip ? '' : title, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      , _react2.default.createElement('div', {
        className: _classBuilder2.default.call(void 0, 
          "blm-sidebar--icon",
          isActive && "blm-sidebar--icon--active",
          isHighlighted && "blm-sidebar--icon--highlighted",
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      
        , _react2.default.createElement('div', {
          className: _classBuilder2.default.call(void 0, 
            'blm-sidebar--icon--img',
            `blm-sidebar--icon--img-${iconName}`,
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        )
        , _react2.default.createElement('span', { className: "blm-sidebar--icon--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}},  shortTitle )
        ,  hasBadge && !badgeCount && (
          _react2.default.createElement('div', { className: "blm-sidebar--icon--badge", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
        )
        ,  badgeCount > 0 && (
          _react2.default.createElement('div', { className: "blm-sidebar--icon--badge blm-sidebar--icon--badge-count" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            ,  normalizeBadgeCount(badgeCount) 
          )
        )
      )
    )
  )
)

exports. default = SidebarIcon
