"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/NotificationSoundPicker/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _notifications = require('shared/services/notifications');
var _usePlaySoundEffect = require('web/services/hooks/usePlaySoundEffect'); var _usePlaySoundEffect2 = _interopRequireDefault(_usePlaySoundEffect);
var _Modal = require('web/components/Modal');









const NotificationSoundPickerForm = ({
  defaultChosenSound,
  noneLabel,
  onSubmit,
  onClose,
}) => {
  const [chosenSound, setChosenSound] = _react.useState.call(void 0, defaultChosenSound)
  const playSound = _usePlaySoundEffect2.default.call(void 0, )

  const handleSelectSound = _react.useCallback.call(void 0, 
    sound => {
      if (sound) playSound(sound)
      setChosenSound(sound)
    },
    [playSound, setChosenSound],
  )

  const handleSubmit = _react.useCallback.call(void 0, 
    () => {
      onSubmit(chosenSound)
      onClose()
    },
    [chosenSound, onSubmit, onClose],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "notification-sound-picker--sound-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        , _react2.default.createElement('div', {
          className: "notification-sound-picker--sound-list-item",
          onClick: () => handleSelectSound(null), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        
          , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
            "notification-sound-picker--sound-list-check",
            !chosenSound && "notification-sound-picker--sound-list-check--active",
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          )
          , _react2.default.createElement('span', { className: "notification-sound-picker--sound-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, noneLabel)
        )
        ,  _notifications.notificationSounds.map(({ name, fileName }) => (
          _react2.default.createElement('div', {
            key: fileName,
            className: "notification-sound-picker--sound-list-item",
            onClick: () => handleSelectSound(fileName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          
            , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
              "notification-sound-picker--sound-list-check",
              fileName === chosenSound &&
                "notification-sound-picker--sound-list-check--active",
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
            )
            , _react2.default.createElement('span', { className: "notification-sound-picker--sound-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
              ,  name 
            )
          )
        )) 
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        className: "notification-sound-picker--controls",
        primaryBtnLabel: "Save",
        onPrimaryBtnClick: handleSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
      )
    )
  )
}

exports. default = NotificationSoundPickerForm
