"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Notice/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');







const Notice = ({ isAppOutdated = false, onUpdate, className }) => {
  if (!isAppOutdated) return null

  return (
    _react2.default.createElement('div', { className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , _react2.default.createElement('span', { className: "settings--nav-notice--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, "New update available"  )
      , _react2.default.createElement('span', { className: "settings--nav-notice--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}, "Please update your app to get the latest features."

      )
      , _react2.default.createElement(_Form.Button, { onClick: onUpdate, className: "settings--nav-notice--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, "Update now"

      )
    )
  )
}

exports. default = Notice
