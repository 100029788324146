"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Attachment/Error.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





const AttachmentError = ({ FileName }) => (
  _react2.default.createElement('div', { className: "filecontainer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, "insert_drive_file")
    , _react2.default.createElement('div', { className: "filecontainer--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , _react2.default.createElement('div', { className: "filecontainer--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}},  FileName )
    )
    , _react2.default.createElement('div', { className: "filecontainer--right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, "Error fetching file."

    )
  )
)

exports. default = AttachmentError
