"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/RoomBody.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _reactredux = require('react-redux');





var _videoRoom = require('web/selectors/videoRoom');
var _user = require('shared/selectors/user');

var _Room = require('web/components/VideoRooms/Room'); var _Room2 = _interopRequireDefault(_Room);

class RoomBody extends _react2.default.PureComponent {constructor(...args) { super(...args); RoomBody.prototype.__init.call(this); }
  __init() {this.handleRemoveAdmin = adminKey => {
    const { roomKey: RoomKey, roomAdminsLists } = this.props
    const isAdminATeam = roomAdminsLists.TeamAdminKeys.includes(adminKey)
    if (isAdminATeam) { return _actions2.default.removeVideoRoomAdminTeam({ RoomKey, TeamKey: adminKey }) }
    return _actions2.default.removeVideoRoomAdminUser({ RoomKey, UserKey: adminKey })
  }}

  render () {
    const {
      onRoomShare, onRoomLinkCopy,
      isRoomLoaded, isRoomPrivate, roomAdminsLists, roomKey, roomLink,
      currentUserKey,
    } = this.props

    if (!isRoomLoaded) return null

    return (
      _react2.default.createElement(_Room2.default, {
        currentUserKey: currentUserKey,
        roomAdminsLists: roomAdminsLists,
        onRemoveAdmin: this.handleRemoveAdmin,
        roomKey: roomKey,
        roomLink: roomLink,
        isRoomPrivate: isRoomPrivate,
        onRoomShare: onRoomShare,
        onRoomLinkCopy: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      )
    )
  }
}

const mapState = (state, { roomKey }) => ({
  roomLink: _videoRoom.selectVideoRoomLink.call(void 0, state, roomKey),
  isRoomLoaded: !!_videoRoom.selectVideoRoomByKey.call(void 0, state, roomKey),
  isRoomPrivate: _videoRoom.selectVideoRoomIsApprovalRequired.call(void 0, state, roomKey),
  roomAdminsLists: _videoRoom.selectVideoRoomAdminsLists.call(void 0, state, roomKey),
  currentUserKey: _user.selectCurrentUserKey.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(RoomBody)
