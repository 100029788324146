"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/Finish/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _useStatefulPromise = require('shared/hooks/useStatefulPromise');


var _components = require('web/screens/Signup/components');





const FinishSection = ({ onFinish }) => {
  const { isLoading, run } = _useStatefulPromise.useStatefulPromise.call(void 0, onFinish)
  if (isLoading) return _react2.default.createElement(_components.SignupStepLoader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}} )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "email",
        title: "Welcome to BloomText!"  ,
        subtitle: 
          _react2.default.createElement(_react2.default.Fragment, null, "You're all set and ready to start using BloomText."
                    , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} ), "We'll guide you on your very first experience with the app."
                      , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} ), "Let's go!"

          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      )
      , _react2.default.createElement(_components.SignupStepControls, { onNext: run, nextBtnLabel: "Finish", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
    )
  )
}

exports. default = FinishSection
