"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/List/EmptyRoomsList.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _NewRoom = require('web/screens/VideoRooms/Modals/NewRoom'); var _NewRoom2 = _interopRequireDefault(_NewRoom);

 class EmptyRoomsList extends _react2.default.Component {constructor(...args) { super(...args); EmptyRoomsList.prototype.__init.call(this);EmptyRoomsList.prototype.__init2.call(this);EmptyRoomsList.prototype.__init3.call(this); }
  __init() {this.state = { isNewRoomModalOpen: false }}
  __init2() {this.openNewRoomModal = () => this.setState({ isNewRoomModalOpen: true })}
  __init3() {this.closeNewRoomModal = () => this.setState({ isNewRoomModalOpen: false })}

  render () {
    const { isNewRoomModalOpen } = this.state

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "blmh-desktop-hide meeting-rooms--empty-list" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
          , _react2.default.createElement('div', { className: "meeting-rooms--empty-list--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
            , _react2.default.createElement('img', {
              className: "meeting-rooms--empty-list--icon",
              src: "/assets/icons/video/camera-lightgrey.svg",
              alt: "+", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
            )
            , _react2.default.createElement('h3', { className: "meeting-rooms--empty-list--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "No Meeting Rooms"  )
            , _react2.default.createElement('span', { className: "meeting-rooms--empty-list--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "Meeting rooms let you jump on a video call with coworkers and patients."

            )
            , _react2.default.createElement('a', {
              className: "meeting--btn meeting-rooms--empty-list--button" ,
              onClick: this.openNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
            , "Create meeting room"

            )
          )
        )
        , _react2.default.createElement('span', { className: "blmh-mobile-hide meeting-rooms--empty-list-note" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, "No meetings found."

        )
        , _react2.default.createElement(_NewRoom2.default, {
          isMobile: true,
          isOpen: isNewRoomModalOpen,
          onClose: this.closeNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        )
      )
    )
  }
} exports.default = EmptyRoomsList;
