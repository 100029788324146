"use strict";Object.defineProperty(exports, "__esModule", {value: true});function asyncDebounce (
  func,
  wait = 0,
  {
    leading = false,
    cancelObj = 'canceled',
  } = {},
) {
  let timerId, latestResolve, shouldCancel

  return function (...args) {
    if (!latestResolve) { // The first call since last invocation.
      return new Promise((resolve, reject) => {
        latestResolve = resolve
        if (leading) {
          invokeAtLeading.apply(this, [args, resolve, reject])
        } else {
          timerId = setTimeout(invokeAtTrailing.bind(this, args, resolve, reject), wait)
        }
      })
    }

    shouldCancel = true
    return new Promise((resolve, reject) => {
      latestResolve = resolve
      timerId = setTimeout(invokeAtTrailing.bind(this, args, resolve, reject), wait)
    })
  }

  function invokeAtLeading (args, resolve, reject) {
    func.apply(this, args).then(resolve).catch(reject)
    shouldCancel = false
  }

  function invokeAtTrailing (args, resolve, reject) {
    if (shouldCancel && resolve !== latestResolve) {
      reject(cancelObj)
    } else {
      func.apply(this, args).then(resolve).catch(reject)
      shouldCancel = false
      clearTimeout(timerId)
      timerId = latestResolve = null
    }
  }
}

exports. default = asyncDebounce
