"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/OrgPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _org = require('shared/hooks/org');

var _Tooltip = require('web/components/Tooltip');
var _Avatar = require('web/components/Avatar');
var _Form = require('web/components/Form');
var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _Option = require('./Option'); var _Option2 = _interopRequireDefault(_Option);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);











const tooltipOffset = { top: 5 }
const stopPropagation = e => e.stopPropagation()
const preventDefault = e => e.preventDefault()

const OrgPicker = ({
  value,
  onChange,
  autoFocus,
  hasError,
  minQueryLength = 4,
  isDisabled,
  className,
}) => {
  const isOrgSelected = typeof value !== 'string'
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, autoFocus)
  const { lookupOrgs, isLoading, hasMatchingOrg } = _org.useOrgLookup.call(void 0, 
    isOrgSelected ? '' : value,
    minQueryLength,
  )

  const handleSelectOrg = (e) => { onClose(); onChange(e) }

  const content = (
    _react2.default.createElement(_Tooltip.TooltipContent, { className: "org-picker--tooltip", onClick: preventDefault, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      ,  lookupOrgs.map(o => (
        _react2.default.createElement(_Option2.default, {
          key: o.OrgKey,
          onSelect: () => handleSelectOrg(o),
          org: o, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        )
      ))
      ,  !lookupOrgs.length && !isOrgSelected && !hasError && (
          _react2.default.createElement(_ListResultsStatus2.default, {
            isLoading: isLoading,
            resultsCount: lookupOrgs.length,
            className: "org-picker--results-status",
            noResultsLabel: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          )
      )
      ,  !isOrgSelected && value && !hasMatchingOrg && (
        _react2.default.createElement(_Option2.default, {
          onSelect: onClose,
          title: _react2.default.createElement(_react2.default.Fragment, null, "Create " , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}},  value )), __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
        )
      )
    )
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      hideArrow: true,
      fullContentWidth: true,
      isDismissibleByClickElsewhere: true,
      isOpen: isOpen && !hasError,
      offset: tooltipOffset,
      place: "bottom-left",
      className: _classBuilder2.default.call(void 0, "org-picker--tooltip", className),
      onClose: onClose,
      content: value ? content : null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
    
      , _react2.default.createElement('div', { onClick: stopPropagation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        , _react2.default.createElement('div', { className: "org-picker--toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          ,  isOrgSelected && (
            _react2.default.createElement('div', { className: "org-picker--entity-preview", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
              , _react2.default.createElement(_Avatar.EntityAvatar, {
                size: 40,
                entity: value,
                className: "sender-select--option--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
              )
              , _react2.default.createElement('div', { className: "sender-select--option--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
                , _react2.default.createElement('span', { className: "sender-select--option--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
                  ,  value.Name 
                )
              )
              ,  !isDisabled && (
                _react2.default.createElement('div', {
                  role: "button",
                  onClick: () => onChange(''),
                  className: "org-picker--clear-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
                )
              )
            )
          )
          ,  !isOrgSelected && (
            _react2.default.createElement(_Form.TextInput, {
              type: "text",
              autoFocus: autoFocus,
              placeholder: "Search for organizations..."  ,
              className: "org-picker--searchbox",
              value: value,
              onChange: e => onChange(e.target.value),
              onFocus: onOpen,
              hasError: !!hasError, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
            )
          )
        )
      )
    )
  )
}

exports. default = OrgPicker
