"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/ProgressBar/FilterSelect/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _Form = require('web/components/Form');
var _Option = require('./Option'); var _Option2 = _interopRequireDefault(_Option);








const FilterSelect = ({
  value,
  onChange,
  options,
  className,
}) => {
  const filterOptions = ['ALL', ...options]
  const handleChange = (v) => onChange(v === 'ALL' ? null : v)

  return (
    _react2.default.createElement(_Form.GenericSelect, {
      className: className,
      onChange: handleChange,
      options: filterOptions,
      selectedOption: value || 'ALL',
      OptionComponent: _Option2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    )
  )
}

exports. default = FilterSelect
