"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/ExpandingInput.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _EmojiPicker = require('./EmojiPicker'); var _EmojiPicker2 = _interopRequireDefault(_EmojiPicker);
var _Tooltip = require('../Tooltip');

 const THUMBS_UP_EMOJI = "👍"; exports.THUMBS_UP_EMOJI = THUMBS_UP_EMOJI

const containerStyle = { pointerEvents: "none", opacity: "0.4" }
const noop = () => undefined

const processEmojis = (text) => {
  const reg = /:(\S+?):/g
  const matches = text.match(reg)
  if (!matches) return text

  let newText = text
  matches.forEach(match => {
    const query = match.split(':')[1]
    if (!query) return
    const emoji = _EmojiPicker.lookupEmoji.call(void 0, query)
    if (!emoji) return
    newText = text.replace(match, emoji)
  })

  return newText
}
















/* the expanding text input uses the techniques in this tutorial https://alistapart.com/article/expanding-text-areas-made-elegant. Basically,
* We maintain a <span> "mirror" which contains the content of the textarea. We then query the mirror to render the textarea dimensions. */
 class ExpandingInput extends _react.Component {constructor(...args) { super(...args); ExpandingInput.prototype.__init.call(this);ExpandingInput.prototype.__init2.call(this);ExpandingInput.prototype.__init3.call(this);ExpandingInput.prototype.__init4.call(this);ExpandingInput.prototype.__init5.call(this);ExpandingInput.prototype.__init6.call(this);ExpandingInput.prototype.__init7.call(this);ExpandingInput.prototype.__init8.call(this);ExpandingInput.prototype.__init9.call(this);ExpandingInput.prototype.__init10.call(this);ExpandingInput.prototype.__init11.call(this);ExpandingInput.prototype.__init12.call(this);ExpandingInput.prototype.__init13.call(this);ExpandingInput.prototype.__init14.call(this);ExpandingInput.prototype.__init15.call(this);ExpandingInput.prototype.__init16.call(this);ExpandingInput.prototype.__init17.call(this);ExpandingInput.prototype.__init18.call(this);ExpandingInput.prototype.__init19.call(this);ExpandingInput.prototype.__init20.call(this); }
  __init() {this.state = { showEmojiMart: false }}

  __init2() {this.container = null}
  __init3() {this.mirror = null}
  __init4() {this.input = null}
  __init5() {this.emojiToggle = null}

  __init6() {this.handleEsc = (event) => {
    if (event.keyCode === 27 && this.state.showEmojiMart) {
      this.setState({ showEmojiMart: false })
      this.focus()
    }
  }}

  componentDidMount () {
    window.addEventListener('keyup', this.handleEsc)
  }

  __init7() {this.componentDidUpdate = (prevProps) => {
    const { value } = this.props
    if (prevProps.value === value || !this.mirror) return
    this.mirror.innerText = this.props.value || ''
  }}

  componentWillUnmount () {
    window.removeEventListener('keyup', this.handleEsc)
  }

  __init8() {this.onSend = () => {
    this.props.onSend()
    this.clear()
  }}

  __init9() {this.sendIfEnter = (e) => {
    if (e.key === 'Enter' && !e.nativeEvent.shiftKey) {
      e.stopPropagation()
      e.preventDefault()

      if ((e.target ).value !== '') {
        this.onSend()
      }
    }
  }}

  focus () {
    if (this.input) this.input.focus()
  }

  __init10() {this.pasteImage = (e) => {
    const files = e.clipboardData.files
    const items = e.clipboardData.items

    if (items && files && items.length > 0 && files[0]) {
      this.props.onSendFile(files[0])
    }
  }}

  clear () {
    this.updateValue('')
  }

  __init11() {this.updateValue = (text) => {
    const { onChange } = this.props

    const processedText = processEmojis(text)
    const delta = text.length - processedText.length

    if (delta && this.input && this.input === document.activeElement) {
      const start = this.input.selectionStart - delta
      const end = this.input.selectionEnd - delta
      requestAnimationFrame(() => _optionalChain([this, 'access', _4 => _4.input, 'optionalAccess', _5 => _5.setSelectionRange, 'optionalCall', _6 => _6(start, end)]))
    }

    onChange(null, processedText)
  }}

  __init12() {this.onInput = (e) => {
    e.preventDefault()
    if (!this.mirror || !this.container || !this.input) return
    this.updateValue((e.target ).value)
  }}

  __init13() {this.sendThumbsUp = () => {
    if (this.props.value) return

    this.updateValue(exports.THUMBS_UP_EMOJI)
    requestAnimationFrame(this.onSend)
  }}

  __init14() {this.openEmojiMart = () => this.setState({ showEmojiMart: true })}
  __init15() {this.closeEmojiMart = () => this.setState({ showEmojiMart: false })}

  __init16() {this.emojiSelect = (emoji) => {
    const { value = '' } = this.props
    const { selectionStart: cursorPosition = value.length } = this.input || {}

    const newValue =
      value.slice(0, cursorPosition) +
      emoji +
      value.slice(cursorPosition)

    this.closeEmojiMart()
    this.updateValue(newValue)

    requestAnimationFrame(() => {
      if (!this.input) return
      this.input.focus()
      const newCursorPosition = newValue.length - value.length + cursorPosition
      this.input.setSelectionRange(newCursorPosition, newCursorPosition)
    })
  }}

  __init17() {this.setContainerRef = ref => this.container = ref}
  __init18() {this.setMirrorRef = ref => this.mirror = ref}
  __init19() {this.setInputRef = ref => this.input = ref}
  __init20() {this.setEmojiToggleRef = ref => this.emojiToggle = ref}

  render () {
    const { disabled, value, className, maxLength } = this.props
    const { showEmojiMart } = this.state

    const showThumbsUp = !value

    return (
      _react2.default.createElement('div', {
        ref: this.setContainerRef,
        style: disabled ? containerStyle : undefined,
        className: _classBuilder2.default.call(void 0, "chat--createMessageBox--inputarea", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}
      
        , _react2.default.createElement('pre', { className: "chat--createMessageBox--mirror-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
          , _react2.default.createElement('span', {
            ref: this.setMirrorRef,
            className: "chat--createMessageBox--mirror", __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
          )
          , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 181}})
        )
        , _react2.default.createElement('textarea', {
          id: "main-input",
          className: 'chat--createMessageBox--input',
          'data-gramm': false,
          value: value,
          onChange: noop,
          onInput: this.onInput,
          placeholder: "Type your message..."  ,
          maxLength: maxLength,
          ref: this.setInputRef,
          onKeyPress: this.sendIfEnter,
          onPaste: this.pasteImage,
          autoFocus: true,
          spellCheck: true,
          rows: 1,
          disabled: disabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
        )
        , _react2.default.createElement('div', {
          className: "chat--createMessageBox--inputarea--attach",
          onClick: disabled ? undefined : this.props.onAttachmentClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
        
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 204}}, "attach_file")
        )
        ,  this.emojiToggle && (
          _react2.default.createElement(_Tooltip.ControlledTooltip, {
            isOpen: showEmojiMart,
            place: "top",
            onClose: this.closeEmojiMart,
            domTarget: this.emojiToggle,
            content: _react2.default.createElement(_EmojiPicker2.default, { onSelect: disabled ? null : this.emojiSelect, __self: this, __source: {fileName: _jsxFileName, lineNumber: 212}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 207}}
          )
        )
        , _react2.default.createElement('div', {
          ref: this.setEmojiToggleRef,
          className: "chat--createMessageBox--inputarea--emoji",
          onClick: disabled ? undefined : this.openEmojiMart, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}
        
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 220}}, "insert_emoticon")
        )
        , _react2.default.createElement('div', {
          className: 'chat--createMessageBox--inputarea--send-button ' + (showThumbsUp ? "" : "hidden"),
          onClick: disabled ? undefined : this.sendThumbsUp, __self: this, __source: {fileName: _jsxFileName, lineNumber: 222}}
        
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 226}}, "thumb_up")
        )
        , _react2.default.createElement('div', {
          className: 'chat--createMessageBox--inputarea--send-button ' + (showThumbsUp ? "hidden" : ""),
          onClick: disabled ? undefined : this.onSend, __self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
        
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}, "send")
        )
      )
    )
  }
} exports.default = ExpandingInput;
