"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _contact = require('shared/enum/contact');








 const parseSearchQuery = (
  query,
  contactType,
) => {
  if (!query) return {}

  if (contactType) {
    const phone = contactType === _contact.CONTACT_TYPES.PHONE ? query : ''
    const email = contactType === _contact.CONTACT_TYPES.EMAIL ? query : ''
    return { phone, email }
  }

  const tokens = query.split(' ')
  if (tokens.length === 1) return { firstName: tokens[0] }
  if (tokens.length === 2) {
    const [firstName, lastName] = tokens
    return { firstName, lastName }
  }
  if (tokens.length > 2) {
    const firstName = tokens.slice(0, tokens.length - 1).join(' ')
    const lastName = tokens[tokens.length - 1]
    return { firstName, lastName }
  }
  return {}
}; exports.parseSearchQuery = parseSearchQuery
