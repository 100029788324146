"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Modals/NamePrompt/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');

var _anonymousName = require('shared/validation/videoRoom/anonymousName');
var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');








const NamePromptForm = ({
  userName: initialUserName,
  onProceed,
}) => {
  const { register, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _anonymousName.anonymousNameResolver,
      defaultValues: { name: initialUserName || '' },
    })
  const { errors, isValid, isDirty } = formState

  const submit = (data, e) => {
    _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])
    onProceed(data.name)
  }

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, "Tell your name to other participants so they know who you are."

      )
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "",
        placeholder: "E.g.: John Steve"  ,
        inputProps: register('name'),
        errorMessage: _optionalChain([errors, 'access', _3 => _3.name, 'optionalAccess', _4 => _4.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        isPrimarySubmit: true,
        isPrimaryEnabled: isDirty && isValid,
        primaryBtnLabel: "Continue", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )
    )
  )
}

exports. default = NamePromptForm
