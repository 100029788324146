"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _store = require('shared/store');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _errors = require('shared/errors');

/**
 * @deprecated
 * Use `wsCommand` instead
 */
const wsSend = (
  wsRequestType,
  actionTypes,
  payload = {},
  context = null,
) => {
  _store.dispatch.call(void 0, { type: actionTypes.REQUEST, payload, context })

  const handleError = (reject) => (err) => {
    _store.dispatch.call(void 0, { type: actionTypes.FAILURE, payload: err, context })
    reject(new (0, _errors.CommandError)(wsRequestType, err))
  }

  return new Promise((resolve, reject) => {
    _client2.default.send(wsRequestType, payload)
      .then(
        payload => {
          _store.dispatch.call(void 0, { type: actionTypes.SUCCESS, payload, context })
          resolve(payload )
        },
        handleError(reject),
      )
      .catch(handleError(reject))
  })
}

exports. default = wsSend
