"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/SenderSelect/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _Tooltip = require('web/components/Tooltip');
var _Avatar = require('web/components/Avatar');
var _Section = require('./Section'); var _Section2 = _interopRequireDefault(_Section);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const tooltipOffset = { top: 5 }











const SenderSelect = ({
  myOrgEntitiesList = [],
  myTeamEntitiesList = [],
  senderEntity,
  onSelectSenderKey,
  entityClassName,
  shouldShowEntityDesc,
  shouldShowSectionLabels = true,
}) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )

  const handleSelectSenderKey = _react.useCallback.call(void 0, 
    senderKey => {
      onSelectSenderKey(senderKey)
      onClose()
    },
    [onSelectSenderKey, onClose],
  )

  if (!senderEntity) return null

  const content = (
    _react2.default.createElement(_Tooltip.TooltipContent, { className: "sender-select--tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      , _react2.default.createElement(_Section2.default, {
        label: shouldShowSectionLabels ? "Participate as..." : null,
        optionEntities: myOrgEntitiesList,
        selectedOptionKey: senderEntity.entityKey,
        onSelectOption: handleSelectSenderKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      )
      ,  myOrgEntitiesList.length && myTeamEntitiesList.length
        ? _react2.default.createElement('div', { className: "sender-select--tooltip--divider", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
        : null
      
      , _react2.default.createElement(_Section2.default, {
        label: shouldShowSectionLabels ? "Participate on behalf of..." : null,
        optionEntities: myTeamEntitiesList,
        selectedOptionKey: senderEntity.entityKey,
        onSelectOption: handleSelectSenderKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )
    )
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      isOpen: isOpen,
      fullContentWidth: true,
      hideArrow: true,
      offset: tooltipOffset,
      place: "bottom-left",
      onClose: onClose,
      content: content, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
    
      , _react2.default.createElement('div', {
        role: "button",
        onClick: onOpen,
        className: _classBuilder2.default.call(void 0, 
          "sender-select--entity",
          entityClassName,
          isOpen && 'sender-select--entity--open',
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
      
        , _react2.default.createElement(_Avatar.EntityAvatar, {
          entity: senderEntity,
          className: "sender-select--entity--avatar",
          size: shouldShowEntityDesc ? 36 : 28, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        )
        , _react2.default.createElement('div', { className: "sender-select--entity--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
          , _react2.default.createElement('span', { className: "sender-select--entity--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
            ,  senderEntity.name 
          )
          ,  shouldShowEntityDesc && (
            _react2.default.createElement('span', { className: "sender-select--entity--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
              ,  senderEntity.description 
            )
          )
        )
        , _react2.default.createElement('div', { className: "sender-select--entity--toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} )
      )
    )
  )
}

exports. default = SenderSelect
