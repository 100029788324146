"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/OrgPicker/Option.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Avatar = require('web/components/Avatar');







const Option = ({ org, title, onSelect }) => (
  _react2.default.createElement('div', { role: "button", onClick: onSelect, className: "org-picker--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    ,  org
      ? (
          _react2.default.createElement(_Avatar.EntityAvatar, {
            entity: org,
            size: 30,
            className: "org-picker--option--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
          )
        )
      : _react2.default.createElement('div', { className: "org-picker--option--avatar org-picker--option--avatar-empty" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} )
    
    , _react2.default.createElement('div', { className: "org-picker--option--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      ,  _optionalChain([org, 'optionalAccess', _ => _.Name]) || title 
    )
  )
)

exports. default = Option
