"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ArchiveModal/Form/TeamGroup.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





const TeamGroup = ({ orgName, children }) => (
  _react2.default.createElement('div', { className: "archive-modal--team-group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    , _react2.default.createElement('h3', { className: "archive-modal--team-group--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}},  orgName )
    , _react2.default.createElement('ul', { className: "archive-modal--team-group--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      ,  children 
    )
  )
)

exports. default = TeamGroup
