"use strict";Object.defineProperty(exports, "__esModule", {value: true});var SubscriptionItemType; (function (SubscriptionItemType) {
  const TEAM = 'team'; SubscriptionItemType["TEAM"] = TEAM;
  const SMS = 'messaging'; SubscriptionItemType["SMS"] = SMS;
  const VIDEO = 'video'; SubscriptionItemType["VIDEO"] = VIDEO;
  const PHONE_NUMBER = 'phone_number'; SubscriptionItemType["PHONE_NUMBER"] = PHONE_NUMBER;
})(SubscriptionItemType || (exports.SubscriptionItemType = SubscriptionItemType = {}));

var CheckoutActionType; (function (CheckoutActionType) {
  const UPGRADE = 'upgrade'; CheckoutActionType["UPGRADE"] = UPGRADE;
  const DOWNGRADE = 'downgrade'; CheckoutActionType["DOWNGRADE"] = DOWNGRADE;
  const CANCEL = 'cancel'; CheckoutActionType["CANCEL"] = CANCEL;
  const CHANGE_TIER = 'change_tier'; CheckoutActionType["CHANGE_TIER"] = CHANGE_TIER;
})(CheckoutActionType || (exports.CheckoutActionType = CheckoutActionType = {}));
