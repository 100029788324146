"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _actions = require('web/actions');

const initialState = {
  interactablesQueue: [],
}

 const selectActiveInteractable = state =>
  state.firstTimeTutorial.interactablesQueue[0]; exports.selectActiveInteractable = selectActiveInteractable

exports. default = (state = initialState, action) => {
  switch (action.type) {
    case _actions.types.PUSH_INTERACTABLES_QUEUE:
      return {
        ...state,
        interactablesQueue: action.payload.concat(state.interactablesQueue),
      }
    case _actions.types.DISMISS_INTERACTABLE_BY_ID:
      return {
        ...state,
        interactablesQueue: state.interactablesQueue.filter(
          x => x.id !== action.payload.id,
        ),
      }
    case _actions.types.SET_INTERACTABLE_PERMISSION:
      return {
        ...state,
        interactablesQueue: state.interactablesQueue.map(x =>
          x.id === action.payload.id
            ? { ...x, isPermitted: action.payload.isPermitted }
            : x,
        ),
      }
  }
  return state
}
