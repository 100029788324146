"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/ResetPassword/ErrorMessage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Form = require('web/components/Form');

const errorsMap = {
  "Token Previously Used": "For security purposes we unfortunately cannot accept a link that has already been used.",
  "Token Expired": "For security purposes we unfortunately cannot accept links over a few days old.",
  "Invalid Token": "Unfortunately it looks like your link is invalid.",
}



const humanizeErrorMessage = (err) =>
  errorsMap[err] || errorsMap["Invalid Token"]






const ResetPasswordErrorMessage = ({
  errorCode,
  onRequestLink,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement('span', { className: "login--restore-pwd--error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      ,  humanizeErrorMessage(errorCode) 
    )
    , _react2.default.createElement(_Form.Button, {
      onClick: onRequestLink,
      className: "login--restore-pwd--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
    , "Request a new link"

    )
  )
)

exports. default = ResetPasswordErrorMessage
