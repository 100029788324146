"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

var _videoCall = require('./videoCall'); var _videoCall2 = _interopRequireDefault(_videoCall);
var _videoRoom = require('./videoRoom'); var _videoRoom2 = _interopRequireDefault(_videoRoom);

var _navigation = require('web/actions/navigation'); var _navigation2 = _interopRequireDefault(_navigation);
var _snackbar = require('./snackbar'); var _snackbar2 = _interopRequireDefault(_snackbar);
var _firstTimeTutorial = require('./firstTimeTutorial'); var _firstTimeTutorial2 = _interopRequireDefault(_firstTimeTutorial);

 const types = {
  ..._videoCall.types,
  ..._videoRoom.types,
  ..._snackbar.types,
  ..._firstTimeTutorial.types,
}; exports.types = types

exports. default = {
  ..._actions2.default,

  // Web-specific actions
  ..._navigation2.default,
  ..._videoCall2.default,
  ..._videoRoom2.default,
  ..._snackbar2.default,
  ..._firstTimeTutorial2.default,
}
