"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');



 const useRequestedOrgRel = (welcomeUser) => {
  const [requestedOrgRels, setRequestedOrgRels] = _react.useState([])

  _react.useEffect.call(void 0, 
    () => {
      if (!_optionalChain([welcomeUser, 'optionalAccess', _ => _.Relationships])) return
      setRequestedOrgRels(
        welcomeUser.Relationships.map(rel => ({
          Title: _optionalChain([welcomeUser, 'optionalAccess', _2 => _2.Title]) || '',
          SelectedKey: rel.OrgKey,
          SelectedName: rel.Name,
        })),
      )
    },
    [_optionalChain([welcomeUser, 'optionalAccess', _3 => _3.Relationships]), _optionalChain([welcomeUser, 'optionalAccess', _4 => _4.Title])],
  )

  return [requestedOrgRels, setRequestedOrgRels] 
}; exports.useRequestedOrgRel = useRequestedOrgRel
