"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');
var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);






const schema = _joi4.default.object({
  phone: _joi3.optionalString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Phone')).contactPhone(),
  email: _joi3.optionalString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Email')).contactEmail(),
}).or('phone', 'email')

 const videoChatResolver = _joi.joiResolver.call(void 0, schema); exports.videoChatResolver = videoChatResolver
