"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/components/SignupStepError.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _SignupStepHeader = require('./SignupStepHeader'); var _SignupStepHeader2 = _interopRequireDefault(_SignupStepHeader);
var _SignupStepControls = require('./SignupStepControls'); var _SignupStepControls2 = _interopRequireDefault(_SignupStepControls);








const SignupStepError = ({
  title,
  desc,
  btnLabel,
  onBtnClick,
}) => (
  _react2.default.createElement('div', { className: "signup-step--error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    , _react2.default.createElement(_SignupStepHeader2.default, { iconType: "error", title: title, subtitle: desc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
    , _react2.default.createElement(_SignupStepControls2.default, {
      className: "signup-step--error--controls",
      nextBtnLabel: btnLabel,
      onNext: onBtnClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    )
  )
)

exports. default = SignupStepError
