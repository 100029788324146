"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _lodash = require('lodash');




var _time = require('shared/services/time');

const MSG_VIEW_DEBOUNCE_MS = 1000

const EPOCH_START = new Date(0)
const useViewMessage = (
  onMarkMessageSeen,
  currentUserKey,
) => {
  const latestViewedMessage = _react.useRef.call(void 0, { SentAt: EPOCH_START.toString() } )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onDebouncedViewMessage = _react.useCallback.call(void 0, 
    _lodash.debounce.call(void 0, 
      () => onMarkMessageSeen(latestViewedMessage.current),
      MSG_VIEW_DEBOUNCE_MS,
      { leading: true },
    ),
    [],
  )

  const handleViewMessage = _react.useCallback.call(void 0, 
    msg => {
      if (
        !msg ||
        msg.UserKey === currentUserKey ||
        !_time.isAfter.call(void 0, msg.SentAt, latestViewedMessage.current.SentAt)
      ) return

      latestViewedMessage.current = msg
      onDebouncedViewMessage()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUserKey],
  )

  return handleViewMessage
}

exports. default = useViewMessage
