"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/TextInput.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const TextInput = ({
  hasError,
  className,
  wrapperClassName,
  isDisabled,
  type = 'text',
  ...props
}, ref) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 'blm-text-input--wrapper', wrapperClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    , _react2.default.createElement('input', {
      ref: ref,
      type: type,
      disabled: isDisabled,
      'aria-invalid': hasError,
      className: _classBuilder2.default.call(void 0, 
        'blm-text-input',
        hasError && 'blm-text-input--has-error',
        className,
      ),
      ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    )
    ,  hasError && (
      _react2.default.createElement('div', { className: "blm-text-input--error-icon--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        , _react2.default.createElement('div', { className: "blm-text-input--error-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
      )
    )
  )
)

const ForwardedRefTextInput = _react2.default.forwardRef(TextInput)

exports. default = ForwardedRefTextInput
