"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tooltip/TooltipElements.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const makeTooltipElement = (classNameSuffix) =>
  ({ className = '', children, ...props }) => {
    const rootClassName = _classBuilder2.default.call(void 0, 
      `blm-tooltip--${classNameSuffix}`,
      className,
    )

    return (
      _react2.default.createElement('div', { className: rootClassName, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, children)
    )
  }

 const TooltipHint = makeTooltipElement('hint'); exports.TooltipHint = TooltipHint
 const TooltipInfo = makeTooltipElement('info'); exports.TooltipInfo = TooltipInfo
 const TooltipTitle = makeTooltipElement('title'); exports.TooltipTitle = TooltipTitle
 const TooltipContent = makeTooltipElement('content'); exports.TooltipContent = TooltipContent









 const TooltipItem = ({
  children, className, disabled,
  mobileOnly, mobileHide, selected,
  ...props
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, 
    className,
    'blm-tooltip--item',
    {
      selected,
      disabled,
      'mobile-only': mobileOnly,
      'mobile-hide': mobileHide,
    },
  )

  return (
    _react2.default.createElement('div', { ...props, role: "button", className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}},  children )
  )
}; exports.TooltipItem = TooltipItem












 const TooltipMenuItem = ({
  className,
  disabled,
  isDestructive,
  iconSrc,
  iconClassName,
  label,
  desc,
  onClick,
}) => (
  _react2.default.createElement('div', {
    role: "button",
    onClick: onClick,
    className: _classBuilder2.default.call(void 0, 
      "blm-tooltip--menu-item",
      disabled && "blm-tooltip--menu-item--disabled",
      isDestructive && "blm-tooltip--menu-item--destructive",
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
  
    , _react2.default.createElement('img', {
      src: iconSrc,
      className: _classBuilder2.default.call(void 0, "blm-tooltip--menu-item--icon", iconClassName),
      alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
    )
    , _react2.default.createElement('div', { className: "blm-tooltip--menu-item--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      , _react2.default.createElement('span', { className: "blm-tooltip--menu-item--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}},  label )
      ,  desc && _react2.default.createElement('span', { className: "blm-tooltip--menu-item--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}},  desc ) 
    )
  )
); exports.TooltipMenuItem = TooltipMenuItem
