"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/MetaMessage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







const MetaMessage = ({ text, messageKey }) => (
  _react2.default.createElement('div', { className: "meta-message", id: messageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}},  text )
)

exports. default = MetaMessage
