"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Super/SuperTeamTable.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _SuperTableTR = require('web/components/Super/SuperTableTR'); var _SuperTableTR2 = _interopRequireDefault(_SuperTableTR);
var _Form = require('web/components/Form');
var _reactredux = require('react-redux');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');

const IsDev = SOCKET_SERVER_HOST !== 'api.bloomtext.com'
const BloomOrgKey = IsDev ? "4befef7d-a7be-4ade-a567-48465ae130e8" : "943a2605-102f-4c18-af86-e9bb0126f098"

class SuperTeamTable extends _react.Component {constructor(...args2) { super(...args2); SuperTeamTable.prototype.__init.call(this); }
  __init() {this.startConversationWithTeam = (teamKey) => {
    const args = {
      OrgKey: BloomOrgKey,
      UserKeys: [this.props.currentUser.UserKey],
      TeamKeys: [teamKey],
    }
    _client2.default.send("SUPER_CreateConversation", args).then(res => {
      const { BlobjectKey, ConversationKey, Type } = res
      _actions2.default.gotoBlobject(Type, ConversationKey, BlobjectKey)
    })
      .catch(log.error)
  }}

  render () {
    const teams = Object.values(this.props.teams).map((t) => {
      return (
        _react2.default.createElement(_SuperTableTR2.default, { key: t.TeamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
          , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, _react2.default.createElement('a', { href: `#/super/teams/${t.TeamKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, t.TeamKey))
          , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, t.Name)
          , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, t.Deleted ? 'true' : 'false')
          , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, t.CreatedAt)
          , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, t.UpdatedAt)
          , _react2.default.createElement('td', { className: "numeric", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, Object.keys(t.Members).length)
          , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
            , _react2.default.createElement(_Form.Button, { onClick: () => this.startConversationWithTeam(t.TeamKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}, "Chat")
          )
        )
      )
    })

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
            , _react2.default.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, "Team Key" )
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, "Name")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Deleted")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "CreatedAt")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}, "UpdatedAt")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Members")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, ' ')
            )
          )
          , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            , teams
          )
        )
        , _react2.default.createElement('button', { onClick: this.props.onButton, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, this.props.buttonText)
      )
    )
  }
}

const mapState = state => ({ currentUser: _user.selectCurrentUser.call(void 0, state) })
exports. default = _reactredux.connect.call(void 0, mapState)(SuperTeamTable)
