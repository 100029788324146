"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/FileMessage/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);








var _errors = require('shared/errors');
var _RichMessageWrapper = require('web/components/Conversation/Message/RichMessageWrapper'); var _RichMessageWrapper2 = _interopRequireDefault(_RichMessageWrapper);
var _fetcher = require('web/services/fetcher'); var _fetcher2 = _interopRequireDefault(_fetcher);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _utils = require('./utils');
var _message = require('shared/enum/message');

var _FilePlain = require('./FilePlain'); var _FilePlain2 = _interopRequireDefault(_FilePlain);
var _FileImage = require('./FileImage'); var _FileImage2 = _interopRequireDefault(_FileImage);
var _CanceledNotice = require('./CanceledNotice'); var _CanceledNotice2 = _interopRequireDefault(_CanceledNotice);





























const FileMessage = ({
  fileReference,
  fileName,
  fileSize,
  filePreview,
  onRetry,
  onAbort,
  ...baseMessageProps
}) => {
  const [fileLink, setFileLink] = _react.useState.call(void 0, filePreview)

  const {
    deleted,
    uploading,
    deliveryStatus,
    senderKey,
    currentUserKey,
    messageKey,
  } = baseMessageProps
  const isFromMe = senderKey === currentUserKey
  const isFromThem = !isFromMe

  const getFileLink = () => fileReference && !fileLink &&
    _fetcher2.default.call(void 0, '/get_file_link?key=' + fileReference, 'GET', null)
      .then(({ Link }) => setFileLink(Link))
      .catch(e => new (0, _errors.BlmError)(
        'Failed to get attachment url',
        { fileReference },
        e,
      ))

  const handleDownload = _react.useCallback.call(void 0, () => _utils.downloadFile.call(void 0, fileLink), [fileLink])
  const handleRetry = _react.useCallback.call(void 0, 
    () => onRetry(messageKey, fileReference),
    [messageKey, fileReference, onRetry],
  )
  const handleAbort = _react.useCallback.call(void 0, 
    () => onAbort(messageKey, { pruneTimeout: 1200 }),
    [messageKey, onAbort],
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  _react.useEffect.call(void 0, () => { if (!uploading) getFileLink() }, [fileReference])

  const isImage = _react.useMemo.call(void 0, () => _utils.isFileImage.call(void 0, fileName), [fileName])
  const isCanceled = deliveryStatus === _message.deliveryStatus.CANCELED

  const rootClassName = _react.useMemo.call(void 0, 
    () => deleted
      ? _classBuilder2.default.call(void 0, 
        "text-message",
        isFromMe && "text-message--me",
        isFromThem && "text-message--them",
      )
      : _classBuilder2.default.call(void 0, 
        "file-message",
        isFromMe && "file-message--me",
        isFromThem && "file-message--them",
        isCanceled && "file-message--canceled",
      )
    , [deleted, isFromMe, isFromThem, isCanceled],
  )

  return (
    _react2.default.createElement(_RichMessageWrapper2.default, { ...baseMessageProps, onRetry: handleRetry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
      , _react2.default.createElement('div', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        ,  deleted
          ? _react2.default.createElement('div', { className: "message--deleted", __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}, "This file was recalled"   )
          : isImage
            ? _react2.default.createElement(_FileImage2.default, {
                fileName: fileName,
                fileLink: fileLink,
                onDownload: handleDownload,
                onRetry: handleRetry,
                onAbort: handleAbort,
                deliveryStatus: deliveryStatus, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
              )
            : _react2.default.createElement(_FilePlain2.default, {
                fileName: fileName,
                fileSize: fileSize,
                onDownload: handleDownload,
                onRetry: handleRetry,
                onAbort: handleAbort,
                deliveryStatus: deliveryStatus, __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
              )
        
        ,  isCanceled && _react2.default.createElement(_CanceledNotice2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 135}} ) 
      )
    )
  )
}

exports. default = FileMessage
