"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxRightSide/CreateNewChat/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactredux = require('react-redux');

var _chat = require('shared/enum/chat');
var _session = require('shared/selectors/session');
var _myRelations = require('shared/selectors/myRelations');
var _createNew = require('shared/hooks/createNew');
var _hooks = require('./hooks');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _RightSide = require('web/components/RightSide');
var _Header = require('web/components/RightSide/Header');
var _SenderSelect = require('web/components/SenderSelect'); var _SenderSelect2 = _interopRequireDefault(_SenderSelect);
var _navigation = require('web/services/navigation');
var _Header3 = require('./Header'); var _Header4 = _interopRequireDefault(_Header3);
var _ChatboxInput = require('./Body/ChatboxInput'); var _ChatboxInput2 = _interopRequireDefault(_ChatboxInput);

const CreateNewChat = ({
  chatType,
  chatName,
  orgKeyContext,
  myOrgEntitiesList,
  myTeamEntitiesList,
  myEntitiesMap,
  defaultSenderKey,
  gotoConversation,
  onCloseCreateNewChat,
  isVisible,
}) => {
  const {
    selectedEntitiesMap,
    selectedEntitiesList,
    onSelectEntity,
    onRemoveEntity,
    onResetEntities,
  } = _createNew.useSelectedEntities.call(void 0, )

  const {
    senderKey,
    setSenderKey,
    senderEntity,
    senderOrgKey,
  } = _createNew.useSender.call(void 0, { defaultSenderKey, myEntitiesMap })

  const onSend = _createNew.useSend.call(void 0, {
    chatMethod: chatType === _chat.CHAT_TYPE.REGULAR_PATIENT
      ? _chat.DELIVERY_METHOD.SMS
      : _chat.DELIVERY_METHOD.SECURE,
    chatName,
    senderEntity,
    selectedEntitiesList,
    gotoConversation,
    gotoInbox: _actions2.default.gotoInbox,
  })

  const participantQuotas = _hooks.useParticipantQuotaSwitch.call(void 0, chatType)

  const excludedEntitiesMap =
    _createNew.useExcludedEntities.call(void 0, { selectedEntitiesMap, senderEntity })

  _react.useEffect.call(void 0, onResetEntities, [senderKey, chatType, onResetEntities])
  _react.useEffect.call(void 0, () => _amplitude2.default.logEvent("Open Create Chat", { chatType }), [chatType])

  const [messageText, setMessageText] = _react.useState.call(void 0, '')
  _navigation.usePreventNavigatingAway.call(void 0, {
    allowedRoute: '/inbox',
    shouldBlock: messageText,
    warningMessage: 'Are you sure you want to leave? Your new message will be discarded.',
  })

  if (!isVisible) return null

  return (
    _react2.default.createElement(_RightSide.RightSide, { inView: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      , _react2.default.createElement(_Header.RightSideHeader, { className: "right-side-header--create-new", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        , _react2.default.createElement(_Header.HeaderMobileBackButton, {
          onClick: onCloseCreateNewChat,
          className: "right-side-header--back-button--create-new", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        )
        , _react2.default.createElement(_Header4.default, {
          key: chatType,
          excludedEntitiesMap: excludedEntitiesMap,
          selectedEntitiesList: selectedEntitiesList,
          onSelectEntity: onSelectEntity,
          onRemoveEntity: onRemoveEntity,
          orgKeyContext: senderOrgKey || orgKeyContext,
          participantQuotas: participantQuotas,
          senderSelect: 
            senderEntity && (
              _react2.default.createElement(_SenderSelect2.default, {
                senderEntity: senderEntity,
                onSelectSenderKey: setSenderKey,
                myOrgEntitiesList: myOrgEntitiesList,
                myTeamEntitiesList: myTeamEntitiesList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
              )
            )
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        )
      )
      , _react2.default.createElement(_RightSide.RightSideBody, { className: "right-side-body--create-new", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
        , _react2.default.createElement(_ChatboxInput2.default, {
          onSend: onSend,
          onChange: setMessageText,
          value: messageText, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
        )
      )
    )
  )
}

CreateNewChat.propTypes = {
  chatName: _proptypes2.default.string,
  chatType: _proptypes2.default.oneOf(Object.values(_chat.CHAT_TYPE)).isRequired,
  gotoConversation: _proptypes2.default.func.isRequired,
  onCloseCreateNewChat: _proptypes2.default.func.isRequired,
}

const mapState = (state, { chatType }) => {
  const {
    orgsList: myOrgEntitiesList = [],
    teamsList: myTeamEntitiesList = [],
    entitiesMap: myEntitiesMap = {},
  } = chatType === _chat.CHAT_TYPE.REGULAR_PATIENT
    ? _myRelations.selectMyPhoneTeamEntities.call(void 0, state)
    : _myRelations.selectMyEntities.call(void 0, state)

  const selectedOrgKey = _session.selectSelectedOrgKey.call(void 0, state)
  const selectedTeamKey = _session.selectSelectedTeamKey.call(void 0, state)

  const defaultSenderKey =
    myEntitiesMap[selectedTeamKey] && selectedTeamKey ||
    myEntitiesMap[selectedOrgKey] && selectedOrgKey ||
    _optionalChain([myOrgEntitiesList, 'access', _ => _[0], 'optionalAccess', _2 => _2.entityKey]) ||
    _optionalChain([myTeamEntitiesList, 'access', _3 => _3[0], 'optionalAccess', _4 => _4.entityKey])

  return {
    orgKeyContext: _session.selectSelectedOrgKey.call(void 0, state),
    myOrgEntitiesList,
    myTeamEntitiesList,
    myEntitiesMap,
    defaultSenderKey,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewChat)
