"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _lodash = require('lodash');

var _ui = require('web/services/ui');
var _MobileSetup = require('./MobileSetup'); var _MobileSetup2 = _interopRequireDefault(_MobileSetup);
var _DesktopSetup = require('./DesktopSetup'); var _DesktopSetup2 = _interopRequireDefault(_DesktopSetup);

class Setup extends _react2.default.Component {constructor(...args) { super(...args); Setup.prototype.__init.call(this);Setup.prototype.__init2.call(this);Setup.prototype.__init3.call(this);Setup.prototype.__init4.call(this); }
  __init() {this.state = {
    isMobileActive: _ui.isMobileScreen.call(void 0, ),
  }}

  __init2() {this.watchResize = _lodash.throttle.call(void 0, 
    () => this.setState({ isMobileActive: _ui.isMobileScreen.call(void 0, ) })
    , 100,
  )}

  __init3() {this.componentDidMount = () => window.addEventListener('resize', this.watchResize)}
  __init4() {this.componentWillUnmount = () => window.removeEventListener('resize', this.watchResize)}
  render () {
    const {
      onCycleCam,
      isCamCycleAvailable,
      gotoPreCall,
      goBack,
      currentMicDeviceId,
      setCurrentMicDeviceId,
      audioInDevices,
      areCamIssuesPresent,
      onUserProblemPublish,
    } = this.props
    const { isMobileActive } = this.state

    return (
      _react2.default.createElement('div', { className: "meeting--setup", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        ,  isMobileActive
          ? (
            _react2.default.createElement(_MobileSetup2.default, {
              onCycleCam: onCycleCam,
              goBack: goBack,
              gotoPreCall: gotoPreCall,
              isCamCycleAvailable: isCamCycleAvailable,
              audioInDevices: audioInDevices,
              currentMicDeviceId: currentMicDeviceId,
              setCurrentMicDeviceId: setCurrentMicDeviceId,
              areCamIssuesPresent: areCamIssuesPresent,
              onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
            )
            ) : (
            _react2.default.createElement(_DesktopSetup2.default, {
              gotoPreCall: gotoPreCall,
              onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            )
            )
        
      )
    )
  }
}

Setup.propTypes = {
  onCycleCam: _proptypes2.default.func.isRequired,
  isCamCycleAvailable: _proptypes2.default.bool.isRequired,
  gotoPreCall: _proptypes2.default.func.isRequired,
  audioInDevices: _proptypes2.default.array.isRequired,
  areCamIssuesPresent: _proptypes2.default.bool.isRequired,
  currentMicDeviceId: _proptypes2.default.string,
  currentCamDeviceId: _proptypes2.default.string,
  setCurrentMicDeviceId: _proptypes2.default.func.isRequired,
  goBack: _proptypes2.default.func.isRequired,
  onUserProblemPublish: _proptypes2.default.func.isRequired,
}

exports. default = Setup
