"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

 class CommandError extends Error {
  

  constructor (cmdName, extra = {}) {
    super(`${cmdName} failed`);CommandError.prototype.__init.call(this);
    this.extra = extra
  }

  __init() {this.addExtra = (extra) => this.extra = { ...this.extra, ...extra }}

  get Error () {
    return _optionalChain([this, 'access', _ => _.extra, 'optionalAccess', _2 => _2.Error])
  }
} exports.CommandError = CommandError;
