"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/TokenLoader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _types = require('web/screens/Signup/types');
var _useStatefulPromise = require('shared/hooks/useStatefulPromise');
var _noticeBar = require('web/services/context/noticeBar');

var _components = require('web/screens/Signup/components');
var _TokenError = require('./TokenError'); var _TokenError2 = _interopRequireDefault(_TokenError);
var _SetupFlow = require('./SetupFlow'); var _SetupFlow2 = _interopRequireDefault(_SetupFlow);







const isTokenInvalid = (status) =>
  status === _types.WelcomeUserInvalidStatus.SERVER_ERROR
    || status === _types.WelcomeUserInvalidStatus.TOKEN_USED
    || status === _types.WelcomeUserInvalidStatus.ACTIVE
    || status === _types.WelcomeUserInvalidStatus.INVALID_TOKEN
    || status === _types.WelcomeUserInvalidStatus.TOKEN_EXPIRED
    || status === _types.WelcomeUserInvalidStatus.UNVERIFIED_EMAIL

const TokenLoader = ({ token, onValidateToken, onSubmitSetup }) => {
  const [welcomeUser, setWelcomeUser] = _react.useState(null)
  const loadWelcomeUser = _react.useCallback.call(void 0, 
    async () => {
      if (token) return onValidateToken(token).then(setWelcomeUser)
    },
    [token, onValidateToken],
  )
  const { isLoading, hasFailed, run } = _useStatefulPromise.useStatefulPromise.call(void 0, loadWelcomeUser)
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  _react.useEffect.call(void 0, 
    () => {
      run().catch(() => showWarningBar('Failed to validate token'))
    },
    [run, showWarningBar],
  )

  if (isLoading) {
    return (
      _react2.default.createElement(_components.SignupStepWrapper, { title: "Sign up" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , _react2.default.createElement(_components.SignupStepLoader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} )
      )
    )
  }

  if (hasFailed || !token || !welcomeUser || isTokenInvalid(welcomeUser.Status)) {
    const tokenStatus = _optionalChain([welcomeUser, 'optionalAccess', _ => _.Status]) 
      || _types.WelcomeUserInvalidStatus.SERVER_ERROR

    return (
      _react2.default.createElement(_components.SignupStepWrapper, { title: "Error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        , _react2.default.createElement(_TokenError2.default, { tokenStatus: tokenStatus, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
      )
    )
  }

  return (
    _react2.default.createElement(_SetupFlow2.default, {
      token: token,
      welcomeUser: welcomeUser,
      onSubmitSetup: onSubmitSetup, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
    )
  )
}

exports. default = TokenLoader
