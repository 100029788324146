"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');












const setGlobalNotificationDNDActions =
  _utils.createAsyncActions('SetGlobalNotificationDND')
const unsetGlobalNotificationDNDActions =
  _utils.createAsyncActions('UnsetGlobalNotificationDND')
const setGlobalNotificationSoundActions =
  _utils.createAsyncActions('SetGlobalNotificationSound')
const setUserMuteUntilActions =
  _utils.createAsyncActions('SetUserMuteUntil')
const enableNotificationSMSActions =
  _utils.createAsyncActions('EnableNotificationSMS')
const disableNotificationSMSActions =
  _utils.createAsyncActions('DisableNotificationSMS')
const enableNotificationEmailsActions =
  _utils.createAsyncActions('EnableNotificationEmails')
const disableNotificationEmailsActions =
  _utils.createAsyncActions('DisableNotificationEmails')

 const actionCreators = {
  setGlobalNotificationDNDActions,
  unsetGlobalNotificationDNDActions,
  setGlobalNotificationSoundActions,
  setUserMuteUntilActions,
  enableNotificationSMSActions,
  disableNotificationSMSActions,
  enableNotificationEmailsActions,
  disableNotificationEmailsActions,
}; exports.actionCreators = actionCreators

const setGlobalNotificationDND = (req) =>
  _helpers.wsCommand(
    'SetGlobalNotificationDND',
    setGlobalNotificationDNDActions,
    req,
    null,
  )

const unsetGlobalNotificationDND = () =>
  _helpers.wsCommand(
    'UnsetGlobalNotificationDND',
    unsetGlobalNotificationDNDActions,
    {},
    null,
  )

const setGlobalNotificationSound = (sound) =>
  _helpers.wsCommand(
    'SetGlobalNotificationSound',
    setGlobalNotificationSoundActions,
    { Notification_Sound: sound },
    null,
  )

const setUserMuteUntil = (req) =>
  _helpers.wsCommand(
    'SetUserMuteUntil',
    setUserMuteUntilActions,
    req,
    null,
  )

const enableNotificationSMS = () => _helpers.wsCommand(
  'EnableNotificationSMS',
  enableNotificationSMSActions,
  {},
  null,
)

const disableNotificationSMS = () => _helpers.wsCommand(
  'DisableNotificationSMS',
  disableNotificationSMSActions,
  {},
  null,
)

const enableNotificationEmails = () => _helpers.wsCommand(
  'EnableNotificationEmails',
  enableNotificationEmailsActions,
  {},
  null,
)

const disableNotificationEmails = () => _helpers.wsCommand(
  'DisableNotificationEmails',
  disableNotificationEmailsActions,
  {},
  null,
)

exports. default = {
  setGlobalNotificationDND,
  unsetGlobalNotificationDND,
  setGlobalNotificationSound,
  setUserMuteUntil,
  enableNotificationSMS,
  disableNotificationSMS,
  enableNotificationEmails,
  disableNotificationEmails,
}
