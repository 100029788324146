"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Name/Subsection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');


var _Form = require('web/components/Form');
var _GenericSection = require('../GenericSection'); var _GenericSection2 = _interopRequireDefault(_GenericSection);








const Subsection = ({ name, label, control, placeholder }) => (
  _react2.default.createElement(_GenericSection2.default, { classNameSuffix: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
    , _react2.default.createElement('span', { className: "settings--profile--section-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}},  label )
    , _react2.default.createElement(_reacthookform.Controller, {
      control: control,
      name: name,
      render: ({ field, fieldState }) => (
        _react2.default.createElement(_Form.InputSectionWrapper, {
          errorMessage: _optionalChain([fieldState, 'access', _ => _.error, 'optionalAccess', _2 => _2.message]),
          className: "settings--profile--section-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        
          , _react2.default.createElement(_Form.TextInput, {
            'aria-label': label,
            value: field.value,
            onChange: field.onChange,
            placeholder: placeholder,
            hasError: !!fieldState.error, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
          )
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    )
  )
)

exports. default = Subsection
