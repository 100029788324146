"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/UpsellCampaignPage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _upgrade = require('shared/enum/upgrade');
var _session = require('shared/selectors/session');
var _user = require('shared/selectors/user');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _Broadcasts = require('web/components/Upsells/Broadcasts'); var _Broadcasts2 = _interopRequireDefault(_Broadcasts);

const UpsellCampaignPage = ({ orgKey, amIAdmin }) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )

  if (!orgKey) return null
  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_UpgradeModal2.default, {
        orgKey: orgKey,
        type: _upgrade.SubscriptionItemType.SMS,
        isOpen: isOpen,
        onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      )
      , _react2.default.createElement(_Broadcasts2.default, { onRequest: amIAdmin ? onOpen : null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
    )
  )
}






const mapState = (state) => {
  const orgKey = _session.selectSelectedOrgKey.call(void 0, state)
  return {
    orgKey,
    amIAdmin: !!orgKey && _user.selectAmIAdminInOrg.call(void 0, state, orgKey),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(UpsellCampaignPage)
