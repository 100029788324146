"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tooltip/HoverTooltip.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





var _common = require('web/components/Tooltip/common');
var _ClickTooltip = require('web/components/Tooltip/ClickTooltip'); var _ClickTooltip2 = _interopRequireDefault(_ClickTooltip);

var _responsive = require('web/services/responsive');

class HoverTooltip extends _react2.default.PureComponent {constructor(...args) { super(...args); HoverTooltip.prototype.__init.call(this);HoverTooltip.prototype.__init2.call(this);HoverTooltip.prototype.__init3.call(this);HoverTooltip.prototype.__init4.call(this);HoverTooltip.prototype.__init5.call(this);HoverTooltip.prototype.__init6.call(this);HoverTooltip.prototype.__init7.call(this);HoverTooltip.prototype.__init8.call(this); }
  __init() {this.mouseEnterEventHandlers = {}}
  __init2() {this.mouseLeaveEventHandlers = {}}
  __init3() {this.state = {
    open: false,
    hasListeners: false,
    tooltipPosition: {},
    tooltipArrowPosition: {},
  }}

  __init4() {this.componentDidUpdate = prevProps =>
    this.props.place !== prevProps.place && this.hideTooltip()}

  __init5() {this.showTooltip = (ref) => {
    const { onOpen, fixedPosition, offset, hideArrow, place } = this.props

    const tooltipPosition = fixedPosition || _common.getTooltipPosition.call(void 0, 
      this.refs[ref],
      this.tooltip,
      place,
      hideArrow,
      offset,
    )

    const tooltipArrowPosition = _common.getToolTipArrowPosition.call(void 0, 
      this.target,
      tooltipPosition,
      place,
    )

    this.setState({
      open: true, tooltipPosition, tooltipArrowPosition,
    }, onOpen)
  }}

  __init6() {this.hideTooltip = () => {
    const { onClose } = this.props

    this.setState({ open: false, tooltipPosition: {} }, onClose)
  }}

  componentDidMount () {
    Object.keys(this.refs).forEach(ref => {
      this.mouseEnterEventHandlers[ref] = e => this.showTooltip(ref)
      this.mouseLeaveEventHandlers[ref] = e => this.hideTooltip(ref)
      this.refs[ref].addEventListener('mouseenter', this.mouseEnterEventHandlers[ref])
      this.refs[ref].addEventListener('mouseleave', this.mouseLeaveEventHandlers[ref])
    })
  }

  componentWillUnmount () {
    Object.keys(this.refs).forEach(ref => {
      this.refs[ref].removeEventListener('mouseenter', this.mouseEnterEventHandlers[ref])
      this.refs[ref].removeEventListener('mouseleave', this.mouseLeaveEventHandlers[ref])
    })
  }

  __init7() {this.setTooltipRef = r => this.tooltip = r}
  __init8() {this.setTargetRef = r => this.target = r}

  render () {
    const { children, content, className, hideArrow } = this.props
    const { open, tooltipPosition, tooltipArrowPosition } = this.state
    const childrenWithRefs = _react2.default.Children.map(
      children,
      (child, i) => _react2.default.cloneElement(child, { ref: `ref-${i}` }),
    )

    const tooltipClassName = _classBuilder2.default.call(void 0, 
      'blm-tooltip',
      'blm-tooltip--hover',
      _common.getTooltipTypeClass.call(void 0, this.props),
      { hidden: !open },
    )

    const containerClassName = _classBuilder2.default.call(void 0, 
      'blm-tooltip--container', className,
    )

    return (
      _react2.default.createElement('div', { className: containerClassName, ref: this.setTargetRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        , childrenWithRefs
        , _react2.default.createElement('div', {
          className: tooltipClassName,
          style: tooltipPosition,
          ref: this.setTooltipRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        
          ,  hideArrow
            ? null
            : _react2.default.createElement('div', { className: "blm-tooltip--arrow", style: tooltipArrowPosition, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}})
          
          , content
        )
      )
    )
  }
}

const HoverTooltipWrapper = ({ children, mobileShow = false, ...props }) => (
  window.innerWidth > _responsive.MobileWidth
    ? _react2.default.createElement(HoverTooltip, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}, children)
    : mobileShow
      ? _react2.default.createElement(_ClickTooltip2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}, children)
      : children
)

HoverTooltipWrapper.propTypes = { ..._common.propTypes, mobileShow: _proptypes2.default.bool }
HoverTooltipWrapper.defaultProps = _common.defaultProps

exports. default = HoverTooltipWrapper
