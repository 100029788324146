"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/TeamPlanModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _upgrade = require('shared/enum/upgrade');







var _hooks = require('../hooks');
var _upgradePlans = require('shared/constants/upgradePlans');
var _cost = require('shared/services/cost');



var _components = require('../components');







const zero = 0 

const TeamPlanModal = ({
  orgName,
  orgKey,
  onClose,
}) => {
  const { isLoading, error, items, hasSubscription, savedCardData, discount } =
    _hooks.usePlan.call(void 0, { orgKey })
  const onCheckout = _hooks.useCheckout.call(void 0, { orgKey, hasSubscription, onEarlyCheckout: onClose })
  const { teamItem, phoneNumberItem, enabledSmsItem } = _hooks.useRelevantItems.call(void 0, { items })

  const plansReceipt = _hooks.usePlansReceipt.call(void 0, {
    teamItem,
    phoneNumberItem,
    smsItem: enabledSmsItem,
    requestedItemType: _upgrade.SubscriptionItemType.TEAM,
  })

  const hasReceipt = !!savedCardData
  const { onPrev, onNext, isLoadingCheckout, currentStep } =
    _hooks.useTeamCheckoutFlow.call(void 0, { onCheckout, hasReceipt, units: _optionalChain([teamItem, 'optionalAccess', _ => _.UnitsUsed]) })

  if (error || isLoading) {
    return _react2.default.createElement(_components.PlanLoader, { isLoading: isLoading, error: error, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
  }

  const unitCost = _optionalChain([teamItem, 'optionalAccess', _2 => _2.UnitCost]) || zero

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  currentStep === _hooks.UpgradeStep.PREVIEW && (
        _react2.default.createElement(_components.PlanPreview, {
          title: "Team Chat" ,
          subtitle: "Chat with more than 10 members, and unlock powerful features."         ,
          baseCost: unitCost,
          priceDesc: "user/month",
          plan: _upgradePlans.teamCommunicationPlan,
          onContinue: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
        )
      )
      ,  currentStep === _hooks.UpgradeStep.SUMMARY && (
        _react2.default.createElement(_components.PlanSummary, {
          title: "Review your plan"  ,
          subtitle: "Charges are based on the number of users in your organization and are adjusted automatically once a month."                 ,
          isNextLoading: isLoadingCheckout,
          onBack: onPrev,
          onNext: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        
          , _react2.default.createElement(_components.PlanInfo, {
            orgName: orgName,
            planName: _upgradePlans.teamCommunicationPlan.name,
            planIconSrc: _upgradePlans.teamCommunicationPlan.iconSrc,
            desc: _optionalChain([teamItem, 'optionalAccess', _3 => _3.UnitsUsed]) === 1 ? '1 user' : `${_optionalChain([teamItem, 'optionalAccess', _4 => _4.UnitsUsed])} users`,
            priceDesc: `${_cost.formatDisplayCost.call(void 0, unitCost)} per user/month`,
            priceHint: "Only coworkers within your organization are counted."      ,
            billingFrequency: "billed monthly" ,
            totalPrice: unitCost * (_optionalChain([teamItem, 'optionalAccess', _5 => _5.UnitsUsed]) || 0) , __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          )
        )
      )
      ,  currentStep === _hooks.UpgradeStep.BILLING && (
        _react2.default.createElement(_components.PlansReceipt, {
          discount: discount,
          savedCardData: savedCardData,
          planPreviews: plansReceipt,
          isNextLoading: isLoadingCheckout,
          onBack: onPrev,
          onNext: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        )
      )
    )
  )
}

exports. default = TeamPlanModal
