"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/AddPhone/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _contact = require('shared/validation/user/contact');
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _Form = require('web/components/Form');







const AddPhoneForm = ({
  onClose,
  onSubmit,
}) => {
  const { register, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _contact.phoneResolver,
      defaultValues: { phone: '' },
    })
  const { isValid, isDirty, isSubmitting, errors } = formState

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const submit = _react.useCallback.call(void 0, 
    ({ phone }) => onSubmit(phone)
      .then(() => {
        showSuccessBar('Phone number has been added.')
        onClose()
      })
      .catch(e => showWarningBar(_optionalChain([e, 'optionalAccess', _ => _.Error]) || 'Failed to add phone number.')),
    [onSubmit, onClose, showWarningBar, showSuccessBar],
  )

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, "Please add a home or work mobile phone number. This phone needs to be able to receive text messages for BloomText to verify it."

        , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} ), _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} ), "Once verified you can use this phone number to log into BloomText or to receive notifications."

      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { errorMessage: _optionalChain([errors, 'access', _2 => _2.phone, 'optionalAccess', _3 => _3.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , _react2.default.createElement(_Form.TextInput, {
          autoFocus: true,
          type: "tel",
          placeholder: "E.g. (415) 903-1200"  ,
          className: "settings--profile--contact-modal--input",
          hasError: !!errors.phone,
          ...register('phone'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        )
      )
      , _react2.default.createElement('div', { className: "settings--modal-nav", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}, "Close")
        , _react2.default.createElement(_Form.Button, {
          isSubmit: true,
          isLoading: isSubmitting,
          isDisabled: !isValid || !isDirty, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        , "Save"

        )
      )
    )
  )
}

exports. default = AddPhoneForm
