"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _entity = require('shared/enum/entity');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _entity3 = require('shared/services/entity');

const xorEntities = (list, map) => list.reduce(
  (acc, e) => {
    if (!map[e.entityKey]) acc.push(e)
    return acc
  },
  [] ,
)

 const balanceChatParticipants = async (
  conversationKey,
  initialEntitiesMap,
  selectedEntitiesMap,
  selectedEntitiesList,
) => {
  const initialEntitiesList = Object.values(initialEntitiesMap)
  const newEntities = xorEntities(selectedEntitiesList, initialEntitiesMap)
  const oldEntities = xorEntities(initialEntitiesList, selectedEntitiesMap)

  const add = async () => {
    if (!newEntities.length) return Promise.resolve()

    const {
      [_entity.ENTITY_TYPES.USER]: users = [],
      [_entity.ENTITY_TYPES.TEAM]: teams = [],
      [_entity.ENTITY_TYPES.PATIENT]: patients = [],
    } = _entity3.groupEntities.call(void 0, newEntities)

    return _actions2.default.addParticipantsToChat({
      ConversationKey: conversationKey,
      UserKeys: users.map(e => e.wrappedEntity.UserKey),
      TeamKeys: teams.map(e => e.wrappedEntity.TeamKey),
      PatientKeys: patients.map(e => e.wrappedEntity.PatientKey),
    })
  }

  const remove = async () => {
    if (!oldEntities.length) return Promise.resolve()

    const {
      [_entity.ENTITY_TYPES.USER]: users = [],
      [_entity.ENTITY_TYPES.TEAM]: teams = [],
      [_entity.ENTITY_TYPES.PATIENT]: patients = [],
    } = _entity3.groupEntities.call(void 0, oldEntities)

    return _actions2.default.removeParticipantsFromChat({
      ConversationKey: conversationKey,
      UserKeys: users.map(e => e.wrappedEntity.UserKey),
      TeamKeys: teams.map(e => e.wrappedEntity.TeamKey),
      PatientKeys: patients.map(e => e.wrappedEntity.PatientKey),
    })
  }

  return Promise.all([add(), remove()])
}; exports.balanceChatParticipants = balanceChatParticipants
