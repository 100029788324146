"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/CampaignForm/Form/SenderPicker/SenderItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _peers = require('shared/selectors/peers');


var _entities = require('shared/transforms/entities');

var _Avatar = require('web/components/Avatar');
var _Form = require('web/components/Form');






const SenderAvatar = ({ option, ...props }) => (
  _react2.default.createElement(_Avatar.UserAvatar, { userKey: option, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
)

const SenderItem = ({ userEntity, ...props }) => {
  if (!userEntity && props.isPreviewOption) {
    return (
      _react2.default.createElement(_Form.GenericEntityOption, { isEmpty: true, onSelect: props.onSelect, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Pick a sender"

      )
    )
  }

  if (!userEntity) return null

  return (
    _react2.default.createElement(_Form.GenericEntityOption, {
      Avatar: SenderAvatar,
      name: userEntity.name,
      description: userEntity.description,
      ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
    )
  )
}





const mapState = (state, { option, orgKey }) => {
  const user = option && _peers.selectUserByKey.call(void 0, state, option)
  const userEntity = user
    ? _entities.mapUserToEntity.call(void 0, {}, _entities.makeSpecificOrgTitleUserDescription.call(void 0, orgKey))(user)
    : null

  return { userEntity }
}

exports. default = _reactredux.connect.call(void 0, mapState)(SenderItem)
