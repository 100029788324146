"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Attachment/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _log = require('shared/log');

var _fileSize = require('shared/utils/fileSize');

var _fetcher = require('web/services/fetcher'); var _fetcher2 = _interopRequireDefault(_fetcher);

var _Error = require('./Error'); var _Error2 = _interopRequireDefault(_Error);
var _File = require('./File'); var _File2 = _interopRequireDefault(_File);
var _Image = require('./Image'); var _Image2 = _interopRequireDefault(_Image);














 class Attachment extends _react2.default.Component {
  __init() {this._isMounted = false}

  constructor (props) {
    super(props);Attachment.prototype.__init.call(this);Attachment.prototype.__init2.call(this);Attachment.prototype.__init3.call(this);Attachment.prototype.__init4.call(this);Attachment.prototype.__init5.call(this);
    this.state = {
      fileLink: null,
      showingLightBox: false,
    }
  }

  __init2() {this.toggleLightBox = () => {
    this.setState({ showingLightBox: !this.state.showingLightBox })
  }}

  __init3() {this.componentDidMount = () => {
    this._isMounted = true
    const { FileKey } = this.props
    if (!FileKey) return

    _fetcher2.default.call(void 0, '/get_file_link?key=' + FileKey, 'GET', null)
      .then(fileInfo => {
        // check if the component still exists before setting state
        // this is not a good practice but works for solving this specific problem
        // a better way to solve this would be to use cancellable promises
        if (this._isMounted) this.setState({ fileLink: fileInfo.Link })
      })
      .catch(_log.log.info)
  }}

  __init4() {this.componentWillUnmount = () => this._isMounted = false}

  __init5() {this.downloadFile = () => this.state.fileLink && window.open(this.state.fileLink)}

  render () {
    if (this.props.Error && this.props.Error != null) {
      return _react2.default.createElement(_Error2.default, { FileName: this.props.FileName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}})
    }

    const attachmentClasses = { "Image": [".jpg", ".png", ".gif", ".jpeg"] }
    const isImage = !!attachmentClasses.Image
      .find((ex) => this.props.FileName.toLowerCase().includes(ex))

    const fileName = this.props.FileName
    const size = this.props.Size

    return isImage
      ? (
          _react2.default.createElement(_Image2.default, {
            onDownload: (e) => { e && e.stopPropagation(); this.downloadFile() },
            onView: this.toggleLightBox,
            onClose: this.toggleLightBox,
            fileLink: this.state.fileLink,
            fileName: fileName,
            showingLightBox: this.state.showingLightBox,
            small: this.props.small, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
          )
        )
      : (
          _react2.default.createElement(_File2.default, {
            onClick: this.downloadFile,
            fileName: fileName,
            fileSize: _fileSize.getDisplaySize.call(void 0, size),
            small: this.props.small, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          )
        )
  }
} exports.default = Attachment;
