"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Permissions/MobileSafariGuide.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ImgSrcSet = require('web/components/ImgSrcSet'); var _ImgSrcSet2 = _interopRequireDefault(_ImgSrcSet);

exports. default = () => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_ImgSrcSet2.default, { x2: true, x3: true,
      className: "meeting--perms-guide--safari-screenshot",
      src: "/assets/images/video/safari-perms-guide.png", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
    )
    , _react2.default.createElement('div', { className: "meeting--perms-guide--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, "Please tap the "
           , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "ᴀA"), " icon in your browser's address bar. Choose "        , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Website Settings" ), " and then "   , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Allow"), " access for "   , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Camera"), " and "  , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Microphone"), "."
      )
    )
  )
)
