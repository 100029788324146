"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/TeamAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _team = require('shared/selectors/team');

var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);
 const FALLBACK_ICON_ALT_TEXT = 'User'; exports.FALLBACK_ICON_ALT_TEXT = FALLBACK_ICON_ALT_TEXT











const TeamAvatar = ({
  teamKey,
  teamAvatar,
  isOnline,
  noOnlineIndicator,
  participantNumber,
  className,
  size,
  borderColor,
  style,
}) => (
  _react2.default.createElement(_BaseAvatar2.default, {
    profilePicture: teamAvatar,
    icon: "/assets/icons/avatar/icon-team.svg",
    iconAltText: exports.FALLBACK_ICON_ALT_TEXT,
    className: className,
    participantNumber: participantNumber,
    backgroundColorKey: teamKey,
    isOnline: !noOnlineIndicator && isOnline,
    borderColor: borderColor,
    size: size,
    style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
  )
)






const makeMapState = () => {
  const selectIsTeamOnline = _team.makeSelectIsTeamOnline.call(void 0, )

  return (state, { teamKey }) => ({
    teamAvatar: _optionalChain([_team.selectTeamByKey.call(void 0, state, teamKey), 'optionalAccess', _ => _.Avatar]),
    isOnline: selectIsTeamOnline(state, teamKey),
  })
}

exports. default = _reactredux.connect.call(void 0, makeMapState)(TeamAvatar)
