"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/ScheduleSection/EditScheduleModal/TimeTable/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





var _utils = require('../../utils');

var _DayOfWeekRow = require('./DayOfWeekRow'); var _DayOfWeekRow2 = _interopRequireDefault(_DayOfWeekRow);





const TimeTable = ({ control }) => {
  const [openTimePickerName, setOpenTimePickerName] =
    _react.useState(null)

  const onOpenTimePicker = _react.useCallback.call(void 0, 
    (n) => setOpenTimePickerName(n),
    [],
  )
  const onCloseTimePicker = _react.useCallback.call(void 0, () => setOpenTimePickerName(null), [])

  return (
    _react2.default.createElement('div', { className: "team-page--edit-time-table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      ,  _utils.daysList.map((_d, i) => (
        _react2.default.createElement(_DayOfWeekRow2.default, {
          key: i,
          displayDayName: _utils.dayNames[i],
          control: control,
          name: `autoReplyTeamHours.${i}`,
          openTimePickerName: openTimePickerName,
          onOpen: onOpenTimePicker,
          onClose: onCloseTimePicker, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        )
      ))
    )
  )
}

exports. default = TimeTable
