"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

const initialState = {}

 function conversationReducer (state = initialState, action) {
  switch (action.type) {
    case _actionCreators2.default.loadConversationActions.SUCCESS.type:
    case _actionCreators2.default.loadConversationAroundTimeActions.SUCCESS.type:
    case _actionCreators2.default.loadConversationAroundMessageActions.SUCCESS.type:
    case _actionCreators2.default.updateNotificationAction.type: {
      const { Conversations } = action.payload

      return Object.values(Conversations || {}).map(c => {
        const conversation = { ...c }
        delete conversation.Messages
        return conversation
      }).reduce((acc, c) => {
        acc[c.ConversationKey] = {
          ...state[c.ConversationKey],
          ...c,
        }
        return acc
      }, state)
    }
  }
  return state
} exports.default = conversationReducer;
