"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/PlanInfo.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _cost = require('shared/services/cost');

var _HintToggle = require('web/components/HintToggle'); var _HintToggle2 = _interopRequireDefault(_HintToggle);












const PlanInfo = ({
  planName,
  orgName,
  planIconSrc,
  desc,
  priceDesc,
  priceHint,
  totalPrice,
  billingFrequency,
}) => (
  _react2.default.createElement('div', { className: "plan-modal--summary--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    , _react2.default.createElement('img', { className: "plan-modal--summary--info--icon", src: planIconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
    , _react2.default.createElement('div', { className: "plan-modal--summary--info--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      , _react2.default.createElement('div', { className: "plan-modal--summary--info--title-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        , _react2.default.createElement('h2', { className: "plan-modal--summary--info--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}},  planName )
        , _react2.default.createElement('div', { className: "plan-modal--summary--info--name-spacer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
        , _react2.default.createElement('span', { className: "plan-modal--summary--info--org", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}},  orgName )
      )
      , _react2.default.createElement('span', { className: "plan-modal--summary--info--members-count", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        ,  desc 
        ,  priceHint && (
          _react2.default.createElement(_HintToggle2.default, {
            place: "right",
            hint: priceHint,
            className: "plan-modal--summary--info--desc-price--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          )
        )
      )
      , _react2.default.createElement('span', { className: "plan-modal--summary--info--desc-price", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        ,  priceDesc 
      )
    )
    , _react2.default.createElement('div', { className: "plan-modal--summary--info--price", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      , _react2.default.createElement('span', { className: "plan-modal--summary--info--price-tag", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        ,  _cost.formatDisplayCost.call(void 0, totalPrice) 
      )
      , _react2.default.createElement('span', { className: "plan-modal--summary--info--price-freq", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}},  billingFrequency )
    )
  )
)

exports. default = PlanInfo
