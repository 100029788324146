"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');









const useResultsListState = ({
  searchQuery,
  clearSearchQuery,
  isInitiallyOpen,
  canAddParticipants,
  shouldRefocusOnPick,
}) => {
  const [isResultsListOpen, setIsResultsListOpen] = _react.useState.call(void 0, isInitiallyOpen)
  const openResultsList = _react.useCallback.call(void 0, 
    () => setIsResultsListOpen(true),
    [setIsResultsListOpen],
  )
  const closeResultsList = _react.useCallback.call(void 0, 
    () => {
      setIsResultsListOpen(false)
      clearSearchQuery()
    },
    [setIsResultsListOpen, clearSearchQuery],
  )

  const searchInputRef = _react.useRef(null)
  const focusInput = _react.useCallback.call(void 0, () => _optionalChain([searchInputRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.focus, 'call', _3 => _3()]), [])
  const blurInput = _react.useCallback.call(void 0, () => _optionalChain([searchInputRef, 'access', _4 => _4.current, 'optionalAccess', _5 => _5.blur, 'call', _6 => _6()]), [])

  _react.useEffect.call(void 0, 
    () => { isInitiallyOpen && focusInput() },
    [focusInput], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const onEntitySelected = _react.useCallback.call(void 0, 
    () => {
      clearSearchQuery()
      if (shouldRefocusOnPick) {
        focusInput()
      } else {
        blurInput()
        setIsResultsListOpen(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [focusInput, clearSearchQuery, shouldRefocusOnPick, setIsResultsListOpen],
  )

  return {
    isResultsListOpen: canAddParticipants && !!(isResultsListOpen || searchQuery),
    openResultsList,
    closeResultsList,
    searchInputRef,
    onEntitySelected,
  }
}

exports. default = useResultsListState
