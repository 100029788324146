"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/Toggle/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _chat = require('shared/enum/chat');





var _user = require('shared/selectors/user');
var _myRelations = require('shared/selectors/myRelations');
var _useStage = require('shared/hooks/useStage'); var _useStage2 = _interopRequireDefault(_useStage);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _Add = require('web/components/Add'); var _Add2 = _interopRequireDefault(_Add);
var _CreateNew = require('web/screens/CreateNew'); var _CreateNew2 = _interopRequireDefault(_CreateNew);
var _Tooltip = require('web/components/Tooltip');
var _Option = require('./Option'); var _Option2 = _interopRequireDefault(_Option);
var _Divider = require('./Divider'); var _Divider2 = _interopRequireDefault(_Divider);





const CreateNewToggle = ({
  onCreateNewChat,
  hasCreateNew,
  hasPatientMessaging,
  hasVideoChats,
  hasPhoneTeams,
  hasAnyActiveOrgs,
}) => {
  const { isOpen, onClose, onOpen } = _useToggle2.default.call(void 0, )
  const {
    item: activeCreateNewModalTab,
    isOpen: isCreateNewModalOpen,
    onOpen: onOpenCreateNewModal,
    onClose: onCloseCreateNewModal,
  } = _useStage2.default()

  const onCreateLegacyChat = () => {
    onOpenCreateNewModal(_CreateNew.CreateNewTypes.CHAT)
    onClose()
  }
  const onCreateChat = () => {
    onCreateNewChat(_chat.CHAT_TYPE.REGULAR)
    onClose()
  }
  const onCreatePatientChat = () => {
    onCreateNewChat(_chat.CHAT_TYPE.REGULAR_PATIENT)
    onClose()
  }
  const onCreateSecurePatientChat = () => {
    onCreateNewChat(_chat.CHAT_TYPE.SECURE_PATIENT)
    onClose()
  }

  const onCreateChatRoom = () => {
    onCreateNewChat(_chat.CHAT_TYPE.REGULAR, true)
    onClose()
  }

  const onCreateVideoChat = () => {
    onOpenCreateNewModal(_CreateNew.CreateNewTypes.VIDEO)
    onClose()
  }

  const content = (
    _react2.default.createElement(_Tooltip.TooltipContent, { className: "create-new--tooltip-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      ,  !hasCreateNew && (
        _react2.default.createElement(_Option2.default, {
          iconSrc: "assets/icons/inbox/send-message.svg",
          label: "Create a chat"  ,
          onClick: onCreateLegacyChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        )
      )
      ,  hasCreateNew && (
        _react2.default.createElement(_Option2.default, {
          iconSrc: "assets/icons/inbox/send-message.svg",
          label: "Send a message"  ,
          desc: "Chat with coworkers and external collaborators"     ,
          onClick: onCreateChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
        )
      )
      ,  hasCreateNew && hasPatientMessaging && (
        _react2.default.createElement(_Option2.default, {
          iconSrc: "assets/icons/inbox/send-message.svg",
          label: "Send a message to a patient"     ,
          desc: "Patients will receive a link to a secure chat"        ,
          onClick: onCreateSecurePatientChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
        )
      )
      ,  hasCreateNew && hasPatientMessaging && hasPhoneTeams && (
        _react2.default.createElement(_Option2.default, {
          iconSrc: "assets/icons/inbox/send-message.svg",
          label: "Send a text message to a patient"      ,
          desc: "Patients can read and reply to text messages directly"        ,
          onClick: onCreatePatientChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        )
      )
      , _react2.default.createElement(_Divider2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} )
      ,  hasCreateNew && (
        _react2.default.createElement(_Option2.default, {
          iconSrc: "assets/icons/inbox/chat-room.svg",
          label: "New chat room"  ,
          onClick: onCreateChatRoom, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        )
      )
      ,  hasVideoChats && (
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement(_Divider2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} )
          , _react2.default.createElement(_Option2.default, {
            iconSrc: "assets/icons/inbox/video.svg",
            label: "Start a video chat"   ,
            onClick: onCreateVideoChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
          )
        )
      )
    )
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Tooltip.ControlledTooltip, {
        isOpen: isOpen,
        onClose: onClose,
        isDismissableByClickElsewhere: true,
        place: "bottom-left",
        className: "create-new--tooltip",
        content: content, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
      
        , _react2.default.createElement(_Add2.default, { disabled: !hasAnyActiveOrgs, onClick: onOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}} )
      )
      , _react2.default.createElement(_CreateNew2.default, {
        open: isCreateNewModalOpen,
        selectedTab: activeCreateNewModalTab,
        onClose: onCloseCreateNewModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
      )
    )
  )
}









const mapState = (state) => ({
  hasAnyActiveOrgs: _user.selectHasAnyActiveOrgs.call(void 0, state),
  hasPatientMessaging: _user.selectCurrentUserHasPatientMessagingFeature.call(void 0, state),
  hasVideoChats: _user.selectCurrentUserHasVideoV1Feature.call(void 0, state),
  hasCreateNew: _user.selectCurrentUserHasCreateNewFeature.call(void 0, state),
  hasPhoneTeams: !!_optionalChain([_myRelations.selectMyPhoneTeamsList.call(void 0, state), 'optionalAccess', _ => _.length]),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewToggle)
