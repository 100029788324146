"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/List/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _List = require('web/screens/App/List');
var _Add = require('web/components/Add'); var _Add2 = _interopRequireDefault(_Add);
var _RoomsList = require('web/screens/VideoRooms/List/RoomsList'); var _RoomsList2 = _interopRequireDefault(_RoomsList);
var _ListMenuNotice = require('web/components/Upsells/ListMenuNotice'); var _ListMenuNotice2 = _interopRequireDefault(_ListMenuNotice);

 class VideoRoomsList extends _react2.default.PureComponent {constructor(...args) { super(...args); VideoRoomsList.prototype.__init.call(this); }
  __init() {this.renderAddBtn = () => (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Add2.default, {
        className: "blmh-desktop-hide meeting-rooms--add-btn" ,
        onClick: this.props.openMobileNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      )
      , _react2.default.createElement(_Add2.default, {
        className: "blmh-mobile-hide meeting-rooms--add-btn" ,
        onClick: this.props.openDesktopNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      )
    )
  )}

  render () {
    const {
      gotoRoom, currentRoomKey,
      hasVideoFeature, onRequestVideoFeature,
    } = this.props

    return (
      _react2.default.createElement(_List.List, { className: "meeting-rooms--list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        , _react2.default.createElement(_List.ListHeaderWrapper, { className: "meeting-rooms--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          , _react2.default.createElement(_List.ListHeaderWithSlotAddButton, { AddBtn: this.renderAddBtn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            , _react2.default.createElement(_List.ListHeaderTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Video Meetings" )
          )
        )
        , _react2.default.createElement(_List.ListItemsWrapper, { className: "meeting-rooms--list-items", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        ,  hasVideoFeature
          ? (
            _react2.default.createElement(_RoomsList2.default, {
              gotoRoom: gotoRoom,
              currentRoomKey: currentRoomKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
            )
            ) : (
            _react2.default.createElement(_ListMenuNotice2.default, {
              onRequest: onRequestVideoFeature,
              desc: "Upgrade for video calls with patients and coworkers."       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
            )
            )
        
        )
      )
    )
  }
} exports.default = VideoRoomsList;
