"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/JumpBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Tooltip = require('web/components/Tooltip');
var _ImgSrcSet = require('web/components/ImgSrcSet'); var _ImgSrcSet2 = _interopRequireDefault(_ImgSrcSet);







const JumpBtn = ({ style, onClick, unreadMessagesCount }) => {
  const label = unreadMessagesCount === 1
    ? '1 new message'
    : `${unreadMessagesCount} new messages`

  const tooltipContent = (
    _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , _react2.default.createElement('span', { className: "conversation-box--jump-btn--tooltip-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        ,  label 
      )
    )
  )

  const content = (
    _react2.default.createElement('div', {
      role: "button",
      className: "conversation-box--jump-btn",
      onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    
      , _react2.default.createElement(_ImgSrcSet2.default, { x2: true, x3: true, src: "/assets/icons/inbox/jump-to-bottom-white.png", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
    )
  )

  return (
    _react2.default.createElement('div', { className: "conversation-box--jump-btn--wrapper", style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      ,  unreadMessagesCount
        ? (
            _react2.default.createElement(_Tooltip.HoverTooltip, {
              place: "top",
              type: "dark",
              content: tooltipContent,
              className: "conversation-box--jump-btn--tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
            
              ,  content 
            )
          )
        : content
      
    )
  )
}

exports. default = JumpBtn
