"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/MembersSection/AddMembersModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _createNew = require('shared/hooks/createNew');
var _chatParticipantQuotas = require('shared/constants/chatParticipantQuotas');
var _array = require('shared/services/array');

var _Modal = require('web/components/Modal');
var _ParticipantsPicker = require('web/components/ParticipantsPicker'); var _ParticipantsPicker2 = _interopRequireDefault(_ParticipantsPicker);
var _Form = require('web/components/Form');










const AddMembersModal = ({
  onClose,
  orgKey,
  onLookupMembers,
  onSubmit,
}) => {
  const {
    selectedEntitiesList,
    onSelectEntity,
    onRemoveEntity,
  } = _createNew.useSelectedEntities.call(void 0, )

  const [isSubmitting, setIsSubmitting] = _react.useState.call(void 0, false)
  const handleSubmit = _react.useCallback.call(void 0, 
    () => {
      setIsSubmitting(true)
      const userKeys = selectedEntitiesList
        .map(e => 'UserKey' in e.wrappedEntity && e.wrappedEntity.UserKey)
        .filter(_array.truthy)

      return onSubmit(userKeys)
        .then(onClose)
        .catch(() => setIsSubmitting(false))
    },
    [onSubmit, onClose, selectedEntitiesList],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        shouldCaptureFocus: false,
        title: "",
        className: "directory-page--team--add-members-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      
        , _react2.default.createElement(_ParticipantsPicker2.default, {
          orgKeyContext: orgKey,
          selectedEntitiesList: selectedEntitiesList,
          onSelectEntity: onSelectEntity,
          onRemoveEntity: onRemoveEntity,
          participantQuotas: _chatParticipantQuotas.USERS_ONLY_CHAT_QUOTA,
          onLookup: onLookupMembers,
          canInviteCollaborators: false,
          className: "participants-picker--field",
          inputClassName: "participants-picker--field--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Add",
        onPrimaryBtnClick: handleSubmit,
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: selectedEntitiesList.length > 0 && !isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      )
    )
  )
}

exports. default = AddMembersModal
