"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/components/Item.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);








const FeatureItem = ({
  title,
  desc,
  iconSrc,
  extra,
  children,
}) => (
  _react2.default.createElement('div', { className: "settings--org-subs--feat", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    , _react2.default.createElement('img', { className: "settings--org-subs--feat--icon", src: iconSrc, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} )
    , _react2.default.createElement('div', { className: "settings--org-subs--feat--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      , _react2.default.createElement('span', { className: "settings--org-subs--feat--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}},  title )
      , _react2.default.createElement('span', { className: "settings--org-subs--feat--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}},  desc )
      ,  extra && (
        _react2.default.createElement('div', { className: "settings--org-subs--feat--extra", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
          ,  extra 
        )
      )
       /* <a href="#" className="settings--org-subs--feat--link">Learn More</a> */ 
    )
    ,  children 
  )
)

exports. default = FeatureItem
