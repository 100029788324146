"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Welcome/WelcomeBox.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');
var _app = require('web/services/app');

const WelcomeBox = (props) => {
  const headerLink = _app.getAppMode.call(void 0, ) === _app.ELECTRON_CHAT_MODE ? "/login" : "/"
  return (
    _react2.default.createElement('div', { className: 'welcome--root ' + props.className || '', __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
      , _react2.default.createElement('div', { className: "welcome--box-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
        , _react2.default.createElement(_reactrouterdom.Link, { to: headerLink, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
          , _react2.default.createElement('img', { className: "welcome--box-header-logo", src: "/assets/images/bloomtext-logo.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} )
        )
      )
      , _react2.default.createElement('div', { className: "welcome--box", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
        , props.children
      )
    )
  )
}

exports. default = WelcomeBox
