"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/OrgsList/OrgsControlsSection/JoinOrgModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');


var _Modal = require('web/components/Modal');
var _OrgPicker = require('web/components/OrgPicker'); var _OrgPicker2 = _interopRequireDefault(_OrgPicker);
var _Form = require('web/components/Form');





var _org = require('shared/validation/org/org');
var _event = require('shared/services/event');







const JoinOrgForm = ({ onClose, onSubmit }) => {
  const { register, control, watch, handleSubmit, formState } =
    _reacthookform.useForm({
      resolver: _org.orgResolver,
      mode: 'onChange',
      defaultValues: {
        org: '',
        title: '',
      },
    })
  const { isValid, isDirty, isSubmitting, errors } = formState

  const submit = handleSubmit(data => onSubmit(data).then(onClose).catch(() => null))

  const org = watch('org')
  const isNewOrg = typeof org === 'string'

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_reacthookform.Controller, {
        name: "org",
        control: control,
        render: ({ field }) => (
          _react2.default.createElement(_Form.InputSectionWrapper, {
            title: "Organization",
            className: "settings--join-org--modal--org-picker",
            errorMessage: errors.org && 'message' in errors.org
              ? errors.org.message
              : ''
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          
            , _react2.default.createElement(_OrgPicker2.default, {
              autoFocus: true,
              value: field.value ,
              onChange: (v) => field.onChange(_event.makeChangeEvent.call(void 0, v)),
              minQueryLength: _org.MIN_ORG_NAME_SEARCH_LENGTH,
              hasError: !!errors.org, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
      , _react2.default.createElement(_Form.InputSection, {
        title: "Your Title" ,
        placeholder: "e.g. Head Nurse"  ,
        inputProps: register('title'),
        errorMessage: _optionalChain([errors, 'access', _ => _.title, 'optionalAccess', _2 => _2.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: isNewOrg ? 'Create' : 'Request to join',
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        isPrimaryLoading: isSubmitting,
        onPrimaryBtnClick: submit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      )
    )
  )
}

exports. default = JoinOrgForm
