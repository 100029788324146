"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/ScheduleSection/EditScheduleModal/TimeTable/DayOfWeekRow.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);
var _reacthookform = require('react-hook-form');



var _Form = require('web/components/Form');













const DayOfWeekRow = ({
  openTimePickerName,
  onOpen,
  onClose,
  control,
  name,
  displayDayName,
}) => {
  const renderPicker = (
    fieldPath,
    field,
  ) => {
    const specificName = `${name}.${fieldPath}` 
    const value = field.value[fieldPath]
      ? _moment2.default.call(void 0, field.value[fieldPath], 'hh:mm:ss')
      : undefined

    const onChange = (m) => field.onChange({
      ...field.value,
      [fieldPath]: m
        ? _moment2.default.call(void 0, m).second(0).format('HH:mm:ss')
        : '',
    })

    return (
      _react2.default.createElement(_Form.TimeInput, {
        isOpen: specificName === openTimePickerName,
        onOpen: () => onOpen(specificName),
        onClose: onClose,
        value: value,
        onChange: onChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      )
    )
  }

  return (
    _react2.default.createElement(_reacthookform.Controller, {
      control: control,
      name: name,
      render: ({ field, fieldState }) => (
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement('div', { className: "team-page--edit-time-table--row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
            , _react2.default.createElement('div', { className: "team-page--edit-time-table--day-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
              ,  displayDayName 
            )
            , _react2.default.createElement('div', { className: "team-page--edit-time-table--range", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
              ,  renderPicker('availableFrom', field) 
              , _react2.default.createElement('span', { className: "team-page--edit-time-table--divider", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}, "to")
              ,  renderPicker('availableUntil', field) 
            )
          )
          ,  fieldState.error &&
              _react2.default.createElement('div', { className: "team-page--edit-time-table--error-msg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
                ,  _optionalChain([fieldState, 'access', _ => _.error, 'optionalAccess', _2 => _2.message]) 
              )
          
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
    )
  )
}

exports. default = DayOfWeekRow
