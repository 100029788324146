"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { newObj[key] = obj[key]; } } } newObj.default = obj; return newObj; } } function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }
var _react = require('@sentry/react'); var Sentry = _interopRequireWildcard(_react);


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _authorization = require('shared/services/authorization');

var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);

 const telemetryMiddleware = () => () => next => action => {
  if (_actionCreators2.default.loadPatientConversationByCodeActions.SUCCESS.match(action)) {
    const { Patients: patients } = action.payload
    const [patient] = Object.values(patients || {})
    if (patient) _amplitude2.default.setUserId(patient.PatientKey)
    return next(action)
  }

  if (_actionCreators2.default.loginSuccessAction.match(action)) {
    const { User: user, Orgs: orgs } = action.payload
    _amplitude2.default.setUserId(user.UserKey)

    const userOrgNames = []
    let isBloom = false
    const orgKeys = Object.keys(user.Relationships || {}) 
    orgKeys.forEach(k => {
      const orgName = _optionalChain([orgs, 'access', _ => _[k], 'optionalAccess', _2 => _2.Name]) || ''
      userOrgNames.push(orgName)
      isBloom = isBloom || !!_authorization.superUserOrgKeys[k]
    })
    _amplitude2.default.setOrgNames(userOrgNames, isBloom)
    return next(action)
  }

  if (
    action.type.startsWith('SUCCESS')
      || action.type.startsWith('FAILURE')
  ) _amplitude2.default.logEvent(action.type)

  Sentry.addBreadcrumb({
    category: 'action',
    message: action.type,
    level: 'info',
  })

  return next(action)
}; exports.telemetryMiddleware = telemetryMiddleware
