"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _utils = require('shared/store/utils');





const initialState = { active: true }

exports. default = _utils.createReducer.call(void 0, 
  initialState,
  builder => {
    builder
      .addCase(_actionCreators2.default.userActive, s => { s.active = true })
      .addCase(_actionCreators2.default.userInactive, s => { s.active = false })
      .addCase(_actionCreators2.default.logoutAction, () => initialState)
      .addDefaultCase(s => s)
  },
)
