"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _reacthookform = require('react-hook-form');




var _broadcastCampaign = require('shared/validation/broadcastCampaign');
var _utils = require('./utils');











const useBroadcastForm = ({
  name,
  teamKey,
  senderKey,
  messageType,
  messageDelay,
  messageTemplate,
}) => {
  const { register, reset, resetField, control, handleSubmit, formState } =
    _reacthookform.useForm({
      resolver: _broadcastCampaign.campaignResolver,
      mode: 'onChange',
      defaultValues: _utils.makeDefaultValues.call(void 0, {
        name, teamKey, senderKey, messageType, messageDelay, messageTemplate,
      }),
    })

  _react.useEffect.call(void 0, 
    () => {
      register('teamKey')
      register('senderKey')
      register('messageType')
    },
    [register],
  )

  _react.useEffect.call(void 0, 
    () => reset(
      _utils.makeDefaultValues.call(void 0, {
        name, teamKey, senderKey, messageType, messageDelay, messageTemplate,
      }),
    ),
    [reset, name, teamKey, senderKey, messageType, messageDelay, messageTemplate],
  )

  return { register, resetField, control, handleSubmit, formState }
}

exports. default = useBroadcastForm
