"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

const noop = () => null

















 const NoticeBarContext =
  _react2.default.createContext({
    showNoticeBar: noop ,
    showSuccessBar: noop ,
    showWarningBar: noop ,
  }); exports.NoticeBarContext = NoticeBarContext

 const useNoticeBar = () => _react.useContext.call(void 0, exports.NoticeBarContext); exports.useNoticeBar = useNoticeBar
