"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Header/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);



var _conversation = require('shared/selectors/conversation');
var _user = require('shared/selectors/user');

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');

var _FirstTimeTooltips = require('web/components/FirstTimeTooltips');
var _TeamPicker = require('./TeamPicker'); var _TeamPicker2 = _interopRequireDefault(_TeamPicker);
var _Toggle = require('web/screens/CreateNew/Toggle'); var _Toggle2 = _interopRequireDefault(_Toggle);
var _Header = require('web/components/RightSide/Header');
var _SettingsModal = require('./SettingsModal'); var _SettingsModal2 = _interopRequireDefault(_SettingsModal);







const InboxHeader = ({
  selectedTeam,
  onSelectTeam,
  onCreateNewChat,
  inboxSettings,
  hasAnyActiveOrgs,
}) => {
  const {
    isOpen: isSettingsModalOpen,
    onOpen: onOpenSettingsModal,
    onClose: onCloseSettingsModal,
  } = _useToggle2.default.call(void 0, )

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  const onToggleInbox = _react.useCallback.call(void 0, 
    async (TeamKey, OnOrOff) =>
      _actions2.default.setTeamInboxSettings({ TeamKey, OnOrOff })
        .then(() => showSuccessBar('Inbox settings updated'))
        .catch(() => showWarningBar('An error occurred. Please try again latter.')),
    [showSuccessBar, showWarningBar],
  )

  return (
    _react2.default.createElement('div', { className: "inbox-list-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      , _react2.default.createElement(_TeamPicker2.default, { selectedTeam: selectedTeam, onSelectTeam: onSelectTeam, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
      , _react2.default.createElement(_Header.HeaderItem, {
        iconName: "manage",
        'aria-label': "Inbox settings" ,
        className: "inbox-list-header--options",
        disabled: !hasAnyActiveOrgs,
        onClick: onOpenSettingsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      )
      , _react2.default.createElement(_FirstTimeTooltips.WithCreateNewButtonTooltip, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , _react2.default.createElement(_Toggle2.default, { onCreateNewChat: onCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}} )
      )
      , _react2.default.createElement(_SettingsModal2.default, {
        isOpen: isSettingsModalOpen,
        onClose: onCloseSettingsModal,
        inboxSettings: inboxSettings,
        onToggle: onToggleInbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      )
    )
  )
}






const mapState = (state) => ({
  inboxSettings: _conversation.selectInboxSettings.call(void 0, state),
  hasAnyActiveOrgs: _user.selectHasAnyActiveOrgs.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(InboxHeader)
