"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Modals/ApproveCallParticipant.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _videoCallSoundEffects = require('web/services/videoCallSoundEffects');

 class ApproveCallParticipantModal extends _react2.default.Component {constructor(...args) { super(...args); ApproveCallParticipantModal.prototype.__init.call(this); }
  __init() {this.componentDidMount = _videoCallSoundEffects.playAskToJoin}

  render () {
    const {
      isOpen,
      participantName,
      onAllow,
      onDeny,
    } = this.props

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: isOpen,
        title: "Someone wants to join"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      
        , _react2.default.createElement(_Modal.ModalDesc, { className: "meeting--remove-participant-modal--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
          ,  participantName ? _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, participantName) : 'Someone' 
          , ' ', "wants to join the meeting room."

        )
        , _react2.default.createElement(_Modal.ModalControls, {
          primaryBtnLabel: "Allow",
          isPrimaryEnabled: true,
          onPrimaryBtnClick: onAllow,
          secondaryBtnLabel: "Deny",
          onSecondaryBtnClick: onDeny, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        )
      )
    )
  }
} exports.default = ApproveCallParticipantModal;
