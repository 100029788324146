"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Toggle.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);












const Toggle = ({
  isChecked,
  onChange,
  isDisabled,
  hint,
  label,
  warning,
  className,
  shouldCaptureFocus = true,
}) => {
  const contentClassName = _classBuilder2.default.call(void 0, 
    "blm-toggle--label",
    isDisabled && "blm-toggle--disabled",
    className,
  )

  const content = (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('label', {
        className: _classBuilder2.default.call(void 0, 
          "blm-toggle--wrapper",
          isChecked && "blm-toggle--wrapper--active",
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      
        , _react2.default.createElement('input', {
          type: "checkbox",
          checked: isChecked,
          onChange: e => onChange(e.target.checked),
          className: "blm-toggle--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        )
        , _react2.default.createElement('div', { className: "blm-toggle--track", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )
        , _react2.default.createElement('div', { className: "blm-toggle--handle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
      )
      ,  label 
    )
  )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
      'blm-toggle',
      isDisabled && 'blm-toggle--disabled',
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      ,  shouldCaptureFocus
        ? _react2.default.createElement('label', { className: contentClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}},  content )
        : _react2.default.createElement('span', { className: contentClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}},  content )
      
      ,  hint && !warning && (
        _react2.default.createElement('span', { className: "blm-toggle--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}},  hint )
      )
      ,  warning && (
        _react2.default.createElement(_WarningBar2.default, {
          className: "blm-toggle--warning",
          iconSrc: "/assets/icons/alert-orange.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
        
          ,  warning 
        )
      )
    )
  )
}

exports. default = Toggle
