"use strict";Object.defineProperty(exports, "__esModule", {value: true});















const renderOneToOneChatAvatar = ({
  currentUserKey,
  layoutProps,
  userSubscribersList,
  renderUserAvatar,
  renderFallbackAvatar,
}) => {
  const otherUser = userSubscribersList.find(u => u.UserKey !== currentUserKey)
  if (otherUser) return renderUserAvatar({ userKey: otherUser.UserKey, ...layoutProps })

  return userSubscribersList.length === 1
    ? renderUserAvatar({ userKey: currentUserKey, ...layoutProps })
    : renderFallbackAvatar(layoutProps)
}

exports. default = renderOneToOneChatAvatar
