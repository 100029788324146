"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');
var _reactrouterdom = require('react-router-dom');


var _store = require('web/store');







const pagesDeepMap = (fn) => (ps) => ps.map(p => ({
  ...fn(p),
  ...(p.subroutes && { subroutes: p.subroutes.map(fn) }),
}))

const withPageRouting = (baseRoute) => (p) => ({
  ...p,
  fullRoute: `/${baseRoute}/${p.route}`,
  gotoRoute: () => _store.history.push(`/${baseRoute}/${p.route}`),
  subroutes: _optionalChain([p, 'access', _ => _.subroutes, 'optionalAccess', _2 => _2.map, 'call', _3 => _3(withPageRouting(`${baseRoute}/${p.route}`))]),
})

const markActive = (pathname) => (p) =>
  _reactrouterdom.matchPath.call(void 0, pathname, { path: p.fullRoute }) ? { ...p, isActive: true } : p

 const useMenu = ({ memberOrgs, pathname }) => _react.useMemo.call(void 0, 
  () => {
    const cfg = [
      { label: 'Profile', route: 'profile', icon: 'profile' },
      { label: 'Security', route: 'security', icon: 'login' },
      {
        label: 'Organizations',
        route: 'orgs',
        icon: 'org',
        subroutes: memberOrgs
          .map(r => ({
            label: r.OrgName,
            route: r.OrgKey,
            hasWarning: r.IsAdmin && r.PendingUsersCount > 0,
            warningCount: r.IsAdmin ? r.PendingUsersCount : 0,
          })),
      },
      { label: 'Notifications', route: 'notifications', icon: 'notifications' },
    ]

    const fullPagesCfg = cfg.map(withPageRouting('settings'))
    return pagesDeepMap(markActive(pathname))(fullPagesCfg)
  },
  [memberOrgs, pathname],
); exports.useMenu = useMenu
