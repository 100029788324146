"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');


var _common = require('./common');

 const selectBlobjectByWrappedKey = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjects,
  (_state, WrappedKey) => WrappedKey,
  (blobjects, WrappedKey) =>
    Object.values(blobjects).find(b => b.WrappedKey === WrappedKey) || null,
); exports.selectBlobjectByWrappedKey = selectBlobjectByWrappedKey
