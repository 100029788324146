"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Modals/Settings.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _videoCall = require('web/selectors/videoCall');
var _videoRoom = require('web/selectors/videoRoom');
var _Modals = require('web/components/VideoCall/Modals');
var _videoRoom3 = require('web/services/videoRoom'); var _videoRoom4 = _interopRequireDefault(_videoRoom3);

class SettingsModalContainer extends _react2.default.Component {constructor(...args) { super(...args); SettingsModalContainer.prototype.__init.call(this);SettingsModalContainer.prototype.__init2.call(this); }
  __init() {this.handleUserProblemPublish = e => {
    const { roomKey } = this.props
    const session = _videoRoom4.default.findByRoomKey(roomKey)
    session && session.publishProblem(e.origin)
  }}

  __init2() {this.handleClose = () => {
    const { onClose, context } = this.props
    if (context && context.cb) context.cb()
    onClose()
  }}

  render () {
    const { isOpen } = this.props
    if (!isOpen) return null

    return (
      _react2.default.createElement(_Modals.SettingsModal, {
        isOpen: isOpen,
        onClose: this.handleClose,
        onUserProblemPublish: this.handleUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
    )
  }
}

const mapState = state => ({
  roomKey: _videoRoom.selectCurrentVideoRoomKey.call(void 0, state),
  currentMicDeviceId: _videoCall.selectCurrentMicDeviceId.call(void 0, state),
  currentCamDeviceId: _videoCall.selectCurrentCamDeviceId.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(SettingsModalContainer)
