"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }require('regenerator-runtime/runtime'); // Needs to be imported before any generator code gets imported




var _redux = require('redux');
var _reducers = require('shared/reducers'); var _reducers2 = _interopRequireDefault(_reducers);
var _history = require('history');

var _reduxlogger = require('redux-logger'); var _reduxlogger2 = _interopRequireDefault(_reduxlogger);
var _telemetrymiddleware = require('./telemetrymiddleware');
var _bus = require('shared/services/bus');

 const history = _history.createHashHistory.call(void 0, ); exports.history = history

const telemetrymiddleware = _telemetrymiddleware.telemetryMiddleware.call(void 0, )
const allMiddleware = [telemetrymiddleware, _bus.busMiddleware]

 const configureStore = (
  rootReducer = _reducers2.default.call(void 0, ),
  initialState,
) => {
  if (LOG_LEVEL === 'trace') allMiddleware.push(_reduxlogger2.default)

  return _redux.createStore.call(void 0, rootReducer, initialState, _redux.compose.call(void 0, 
    _redux.applyMiddleware.call(void 0, ...allMiddleware),
    window.__REDUX_DEVTOOLS_EXTENSION__
      ? window.__REDUX_DEVTOOLS_EXTENSION__()
      : (f) => f,
  ))
}; exports.configureStore = configureStore
