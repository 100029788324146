"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/AudioFeed.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _log = require('shared/log');

 class AudioFeed extends _react2.default.Component {constructor(...args) { super(...args); AudioFeed.prototype.__init.call(this);AudioFeed.prototype.__init2.call(this);AudioFeed.prototype.__init3.call(this);AudioFeed.prototype.__init4.call(this);AudioFeed.prototype.__init5.call(this); }
  __init() {this.updateStreamSrc = () => {
    const { stream } = this.props
    this.cleanup()
    if (this.audioDOM && stream) {
      this.audioDOM.srcObject = stream
      const played = this.audioDOM.play()
      if (played !== undefined) {
        played.catch(e => _log.log.info("Couldn't play audio", e))
      }
    }
  }}

  __init2() {this.initStreamPlayback = r => {
    this.audioDOM = r
    this.updateStreamSrc()
  }}

  __init3() {this.componentDidUpdate = prevProps =>
    prevProps.stream !== this.props.stream &&
      this.updateStreamSrc()}

  __init4() {this.cleanup = () => {
    if (!this.audioDOM) return
    this.audioDOM.src = ''
    this.audioDOM.load()
  }}

  __init5() {this.componentWillUnmount = this.cleanup}

  render () {
    const { className } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, 
      className, 'meeting--audio-feed',
    )

    return (
      _react2.default.createElement('audio', {
        playsInline: true,
        autoPlay: true,
        className: rootClassName,
        ref: this.initStreamPlayback, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )
    )
  }

  static __initStatic() {this.defaultProps = { stream: null }}
  static __initStatic2() {this.propTypes = { stream: _proptypes2.default.any }}
} AudioFeed.__initStatic(); AudioFeed.__initStatic2(); exports.default = AudioFeed;
