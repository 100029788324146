"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/ConfirmDeleteModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);







const ConfirmDeleteModal = ({ isOpen, onClose, onProceed }) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    title: "Recall message" ,
    className: "msg-confirm-delete-modal",
    size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
  
    , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, "Are you sure you want to recall your message?"

    )
    , _react2.default.createElement(_Modal.ModalDesc, { className: "msg-confirm-delete-modal--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Once recalled, your message is removed from all recipients, but can still be recovered for auditing."

    )
    , _react2.default.createElement(_Modal.ModalControls, {
      primaryBtnTheme: "danger",
      primaryBtnLabel: "Recall",
      secondaryBtnLabel: "Cancel",
      onPrimaryBtnClick: onProceed,
      onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    )
  )
)

exports. default = ConfirmDeleteModal
