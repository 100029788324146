"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _lodash = require('lodash');
var _redux = require('redux');




var _CallControls = require('web/components/VideoCall/CallControls');



var _uiUtils = require('web/components/VideoCall/OnCall/uiUtils');
var _videoCall = require('web/services/videoCall');
var _DesktopMoreOptionsModal = require('./DesktopMoreOptionsModal'); var _DesktopMoreOptionsModal2 = _interopRequireDefault(_DesktopMoreOptionsModal);
var _ShareOverlay = require('./ShareOverlay'); var _ShareOverlay2 = _interopRequireDefault(_ShareOverlay);
var _SpeakerNameBar = require('./SpeakerNameBar'); var _SpeakerNameBar2 = _interopRequireDefault(_SpeakerNameBar);
var _ParticipantsBoard = require('./ParticipantsBoard'); var _ParticipantsBoard2 = _interopRequireDefault(_ParticipantsBoard);
var _CallControls3 = require('./CallControls'); var _CallControls4 = _interopRequireDefault(_CallControls3);
var _CallContent = require('./CallContent'); var _CallContent2 = _interopRequireDefault(_CallContent);

const tooltipWrapperClassName = "meeting--on-call--tooltip"
const PinActiveSpeakerBtnWithTooltip = _redux.compose.call(void 0, 
  _uiUtils.withOnCallTooltip.call(void 0, 'bottom', 'Pin video', tooltipWrapperClassName),
  _uiUtils.withTouchInterception,
)(_CallControls.PinActiveSpeakerBtn)
const MinimizeCallBtnWithTooltip = _redux.compose.call(void 0, 
  _uiUtils.withOnCallTooltip.call(void 0, 'bottom', 'Minimize call', tooltipWrapperClassName),
  _uiUtils.withTouchInterception,
)(_CallControls.MinimizeCallBtn)
const RemoveParticipantBtnWithTooltip = _redux.compose.call(void 0, 
  _uiUtils.withOnCallTooltip.call(void 0, 'top', 'Remove participant', tooltipWrapperClassName),
  _uiUtils.withTouchInterception,
)(_CallControls.GenericBtn)
const DesktopMoreToggleWithTooltip = _redux.compose.call(void 0, 
  _uiUtils.withOnCallTooltip.call(void 0, 'top', 'More options', tooltipWrapperClassName),
  _uiUtils.withTouchInterception,
)(_CallControls.GenericBtn)
const EnhancedMobileMoreToggle = _uiUtils.withTouchInterception.call(void 0, _CallControls.GenericBtn)

const ignoredUIEvents = ['mousemove', 'touchstart']

 class OnCall extends _react2.default.Component {constructor(...args) { super(...args); OnCall.prototype.__init.call(this);OnCall.prototype.__init2.call(this);OnCall.prototype.__init3.call(this);OnCall.prototype.__init4.call(this);OnCall.prototype.__init5.call(this);OnCall.prototype.__init6.call(this);OnCall.prototype.__init7.call(this);OnCall.prototype.__init8.call(this);OnCall.prototype.__init9.call(this);OnCall.prototype.__init10.call(this);OnCall.prototype.__init11.call(this);OnCall.prototype.__init12.call(this);OnCall.prototype.__init13.call(this);OnCall.prototype.__init14.call(this);OnCall.prototype.__init15.call(this);OnCall.prototype.__init16.call(this);OnCall.prototype.__init17.call(this); }
  __init() {this.activeSpeakerVideoStream = new MediaStream()}
  __init2() {this.state = {
    areCallControlsActive: true,
    // warning: { participantName: 'Richard', message: 'has no audio enabled.' },
    isDesktopMoreModalOpen: false,
  }}

  __init3() {this.closeDesktopMoreModal = () => this.setState({ isDesktopMoreModalOpen: false })}

  __init4() {this.hideControls = () => this.setState({ areCallControlsActive: false })}
  __init5() {this.showControls = () => this.setState({ areCallControlsActive: true })}

  __init6() {this.componentDidUpdate = (prevProps, prevState) => {
    const {
      activeSpeaker,
      activeSpeakerId,
      activeSpeakerVideoTrackId,
    } = this.props

    if (
      prevProps.activeSpeakerId === activeSpeakerId &&
      prevProps.activeSpeakerVideoTrackId === activeSpeakerVideoTrackId
    ) return

    if (activeSpeaker.videoTrack) {
      this.activeSpeakerVideoStream
        .getTracks()
        .forEach(t => this.activeSpeakerVideoStream.removeTrack(t))
      this.activeSpeakerVideoStream = new MediaStream([activeSpeaker.videoTrack])
      this.forceUpdate()
    }
  }}

  __init7() {this.handleToggleDesktopMoreModal = () => this.setState(s => ({
    isDesktopMoreModalOpen: !s.isDesktopMoreModalOpen,
  }))}

  __init8() {this.handleRemoveCurrentParticipant = () => {
    const { onRemoveParticipant, activeSpeakerId } = this.props
    return onRemoveParticipant(activeSpeakerId)
  }}

  __init9() {this.handleOverlayTouch = () => {
    const { areCallControlsActive, isDesktopMoreModalOpen } = this.state

    if (isDesktopMoreModalOpen) this.closeDesktopMoreModal()
    else this.setState({ areCallControlsActive: !areCallControlsActive })
  }}

  __init10() {this.handleOverlayClick = () =>
    this.state.isDesktopMoreModalOpen && this.closeDesktopMoreModal()}

  __init11() {this.hideControlsTimer = null}
  __init12() {this.handleOverlayMouseMove = _lodash.throttle.call(void 0, () => {
    const { isDesktopMoreModalOpen, areCallControlsActive } = this.state
    if (isDesktopMoreModalOpen) return
    if (!areCallControlsActive) this.showControls()
    clearTimeout(this.hideControlsTimer)
    this.hideControlsTimer = setTimeout(this.hideControls, 1900)
  }, 100)}

  __init13() {this.overlayRef = null}
  __init14() {this.preventDefault = e => e.preventDefault()}
  __init15() {this.setOverlayRef = r =>
    this.overlayRef = r}

  __init16() {this.componentDidMount = () =>
    // Prevents onMouseMove from firing on touch screens
    this.overlayRef &&
    this.overlayRef.addEventListener('touchstart', this.preventDefault)}

  __init17() {this.componentWillUnmount = () =>
    this.overlayRef &&
    this.overlayRef.removeEventListener('touchstart', this.preventDefault)}

  render () {
    const {
      meetingState, meetingStates,
      isCamOn, isMicOn, isCamCycleAvailable,
      onCycleCam, onHangup, onToggleCam, onToggleMic,
      isScreenSharingAvailable, isScreenSharingOn,
      stopScreenSharing, onToggleScreenSharing,
      participants, isRoomEmpty,
      roomKey, roomLink, onRoomLinkCopy,
      onRoomShare, isNativeSharingAvailable,
      openSettingsModal,
      openNetstatsModal,
      openMobileMoreOptionsModal,
      isMinimizable, isMinimized, onMinimize,
      canRemoveActiveSpeaker,
      activeSpeakerId, isActiveSpeakerPinned,
      onActiveSpeakerManualChange,
      onActiveSpeakerPinModeToggle,
      activeSpeaker,
    } = this.props
    const { isDesktopMoreModalOpen } = this.state

    const isJoining = meetingState === meetingStates.JOINING
    const isJoined = meetingState === meetingStates.JOINED
    const isPendingApproval = meetingState === meetingStates.PENDING_APPROVAL
    const isShareOverlayVisible = isRoomEmpty && !isScreenSharingOn && isJoined
    const areCallControlsActive = this.state.areCallControlsActive ||
      isShareOverlayVisible

    const isActiveSpeakerMe = activeSpeaker.local
    const isActiveSpeakerVideoOn = !!activeSpeaker.videoTrack
    const amISharingScreen = !!(isActiveSpeakerMe && activeSpeaker.isScreenParticipant)
    const activeSpeakerAudioTrack = activeSpeaker.audioTrack
    const shouldMirrorActiveSpeakerFeed = !!(
      isActiveSpeakerMe &&
      isActiveSpeakerVideoOn &&
      _videoCall.shouldMirrorCamFeed.call(void 0, this.activeSpeakerVideoStream)
    )

    const rootClassName = _classBuilder2.default.call(void 0, 
      "video-call--on-call-room blm--noselect",
      { 'grey-gradient': amISharingScreen },
    )
    const toggleMoreBtnClassName = _classBuilder2.default.call(void 0, 
      "video-call--on-call--toggle-more-btn",
      { hidden: !areCallControlsActive },
    )
    const desktopMoreToggleClassName = _classBuilder2.default.call(void 0, 
      "meeting--on-call--desktop-more-toggle",
      { hidden: !areCallControlsActive, active: isDesktopMoreModalOpen },
    )
    const viewControllsClassName = _classBuilder2.default.call(void 0, 
      "meeting--on-call--view-controls",
      { hidden: !areCallControlsActive },
    )
    const overlayClassName = _classBuilder2.default.call(void 0, 
      "video-call--overlay",
      { opaque: isShareOverlayVisible },
      { faded: areCallControlsActive },
    )

    return (
      _react2.default.createElement('div', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
        , _react2.default.createElement('div', { className: overlayClassName,
          ref: this.setOverlayRef,
          onTouchStart: this.handleOverlayTouch,
          onClick: this.handleOverlayClick,
          onMouseMove: this.handleOverlayMouseMove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
        
          , _react2.default.createElement('div', { className: viewControllsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
            ,  isMinimizable &&
              _react2.default.createElement(MinimizeCallBtnWithTooltip, { onClick: onMinimize, __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}})
            
            ,  !isRoomEmpty &&
              _react2.default.createElement(PinActiveSpeakerBtnWithTooltip, {
                isActive: isActiveSpeakerPinned,
                onClick: onActiveSpeakerPinModeToggle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 194}}
              )
            
          )
          , _react2.default.createElement('div', { className: "video-call--top-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 200}}
            ,  activeSpeakerId && !isRoomEmpty &&
              _react2.default.createElement(_SpeakerNameBar2.default, {
                name: activeSpeaker.name,
                audioTrack: activeSpeakerAudioTrack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 202}}
              )
            
          )
          , _react2.default.createElement(EnhancedMobileMoreToggle, {
            className: toggleMoreBtnClassName,
            onClick: openMobileMoreOptionsModal,
            iconSrc: "/assets/icons/video/more-white.svg",
            iconAlt: "⋮", __self: this, __source: {fileName: _jsxFileName, lineNumber: 208}}
          )
          /* <WarningBar warning={warning} /> */
          ,  isShareOverlayVisible &&
            _react2.default.createElement(_ShareOverlay2.default, {
              roomLink: roomLink,
              onRoomLinkCopy: onRoomLinkCopy,
              onRoomShare: onRoomShare,
              ignoredUIEvents: ignoredUIEvents,
              isNativeSharingAvailable: isNativeSharingAvailable, __self: this, __source: {fileName: _jsxFileName, lineNumber: 216}}
            )
          
          , _react2.default.createElement(_CallControls4.default, {
            areOpen: areCallControlsActive,
            isCamCycleAvailable: isCamCycleAvailable,
            isScreenSharingAvailable: isScreenSharingAvailable,
            isCamOn: isCamOn,
            isMicOn: isMicOn,
            isScreenSharingOn: isScreenSharingOn,
            onCycleCam: onCycleCam,
            onToggleCam: onToggleCam,
            onToggleMic: onToggleMic,
            onHangup: onHangup,
            onToggleScreenSharing: onToggleScreenSharing,
            ignoredUIEvents: ignoredUIEvents, __self: this, __source: {fileName: _jsxFileName, lineNumber: 224}}
          )
          , _react2.default.createElement(_DesktopMoreOptionsModal2.default, {
            isOpen: isDesktopMoreModalOpen,
            onClose: this.closeDesktopMoreModal,
            ignoredUIEvents: ignoredUIEvents,
            onRoomLinkCopy: onRoomLinkCopy,
            onOpenSettings: openSettingsModal,
            onOpenNetstats: openNetstatsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 238}}
          )
          ,  !isRoomEmpty && isJoined &&
            _react2.default.createElement(_ParticipantsBoard2.default, {
              isHidden: !areCallControlsActive,
              ignoredUIEvents: ignoredUIEvents,
              roomKey: roomKey,
              participants: participants,
              activeParticipantId: activeSpeakerId,
              onParticipantPick: onActiveSpeakerManualChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}
            )
          
          , _react2.default.createElement('div', { className: "meeting--on-call--desktop-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 256}}
            ,  canRemoveActiveSpeaker &&
              _react2.default.createElement(RemoveParticipantBtnWithTooltip, {
                onClick: this.handleRemoveCurrentParticipant,
                className: "meeting--on-call--remove-participant-btn",
                iconSrc: "/assets/icons/video/remove-participant-white.svg",
                iconAlt: "X", __self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
              )
            
            , _react2.default.createElement(DesktopMoreToggleWithTooltip, {
              onClick: this.handleToggleDesktopMoreModal,
              className: desktopMoreToggleClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
            )
          )
        )
        , _react2.default.createElement(_CallContent2.default, {
          isPendingApproval: isPendingApproval,
          isJoining: isJoining,
          areCallControlsActive: areCallControlsActive,
          isCallMinimized: isMinimized,
          isActiveSpeakerFeedOn: isActiveSpeakerVideoOn,
          shouldMirrorActiveSpeakerFeed: shouldMirrorActiveSpeakerFeed,
          amISharingScreen: amISharingScreen,
          stopScreenSharing: stopScreenSharing,
          activeSpeakerVideoStream: this.activeSpeakerVideoStream, __self: this, __source: {fileName: _jsxFileName, lineNumber: 271}}
        )
      )
    )
  }

  static __initStatic() {this.defaultProps = {
    isMinimizable: false,
    isMinimized: false,
    onMinimize: () => {},
  }}

  static __initStatic2() {this.propTypes = {
    roomKey: _proptypes2.default.string.isRequired,
    roomLink: _proptypes2.default.string.isRequired,
    openMobileMoreOptionsModal: _proptypes2.default.func.isRequired,
    onCycleCam: _proptypes2.default.func.isRequired,
    onToggleCam: _proptypes2.default.func.isRequired,
    onToggleMic: _proptypes2.default.func.isRequired,
    onToggleScreenSharing: _proptypes2.default.func.isRequired,
    isCamOn: _proptypes2.default.bool.isRequired,
    isMicOn: _proptypes2.default.bool.isRequired,
    isScreenSharingAvailable: _proptypes2.default.bool.isRequired,
    isScreenSharingOn: _proptypes2.default.bool.isRequired,
    isCamCycleAvailable: _proptypes2.default.bool.isRequired,
    meetingStates: _proptypes2.default.object.isRequired,
    participants: _proptypes2.default.object.isRequired,
    isRoomEmpty: _proptypes2.default.bool.isRequired,
    onHangup: _proptypes2.default.func.isRequired,
    currentMicDeviceId: _proptypes2.default.string.isRequired,
    currentCamDeviceId: _proptypes2.default.string.isRequired,
    isMinimized: _proptypes2.default.bool,
    isMinimizable: _proptypes2.default.bool,
    onMinimize: _proptypes2.default.func,
    canRemoveActiveSpeaker: _proptypes2.default.bool.isRequired,
    onRemoveParticipant: _proptypes2.default.func.isRequired,
    activeSpeakerId: _proptypes2.default.string.isRequired,
    onActiveSpeakerManualChange: _proptypes2.default.func.isRequired,
    isActiveSpeakerPinned: _proptypes2.default.bool.isRequired,
    onActiveSpeakerPinModeToggle: _proptypes2.default.func.isRequired,
  }}
} OnCall.__initStatic(); OnCall.__initStatic2(); exports.default = OnCall;
