"use strict";Object.defineProperty(exports, "__esModule", {value: true});const publicBaseUrl = 'https://www.bloomtext.com'

 const homepageUrl = publicBaseUrl; exports.homepageUrl = homepageUrl
 const pricingUrl = `${publicBaseUrl}/pricing/`; exports.pricingUrl = pricingUrl

 const supportUrl = `${publicBaseUrl}/support/`; exports.supportUrl = supportUrl
 const broadcastSupportUrl = `${publicBaseUrl}/support/broadcast-messaging/`; exports.broadcastSupportUrl = broadcastSupportUrl

 const scheduleMeetingUrl = 'https://calendly.com/tyler-bloom/bloomtext-support'; exports.scheduleMeetingUrl = scheduleMeetingUrl

 const smsMessagingDemoVideoUrl = 'https://player.vimeo.com/video/698401914?h=9d91347818&autoplay=1'; exports.smsMessagingDemoVideoUrl = smsMessagingDemoVideoUrl
