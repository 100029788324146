"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/PlanLoader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _hooks = require('../hooks');
var _Spinner = require('web/components/Spinner');






const errorLabels = {
  [_hooks.PlanError.GET_PLAN_FAILED]: 'Failed to load plan',
  [_hooks.PlanError.CHECKOUT_FAILED]: 'Failed to initiate checkout',
  [_hooks.PlanError.LOCKED]: 'Can\'t manage subscription',
}

const renderErrorMsg = (err) => {
  const supportLink =
    _react2.default.createElement('a', { href: "mailto:support@bloomtext.com", target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}, "support@bloomtext.com")

  return err === _hooks.PlanError.LOCKED
    ? _react2.default.createElement(_react2.default.Fragment, null, "Please contact "  ,  supportLink , " to make changes to this organization's plan."       )
    : _react2.default.createElement(_react2.default.Fragment, null, "Please try again or contact "     ,  supportLink , ".")
}

const PlanLoader = ({ isLoading, error }) => (
  _react2.default.createElement('div', { className: "plan-modal--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
    ,  isLoading && _react2.default.createElement(_Spinner.SimpleSpinner, { className: "plan-modal--loader--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} ) 
    ,  !!error && (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('h2', { className: "plan-modal--loader--error-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
          ,  errorLabels[error] 
        )
        , _react2.default.createElement('span', { className: "plan-modal--loader--error-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          ,  renderErrorMsg(error) 
        )
      )
    )
  )
)

exports. default = PlanLoader
