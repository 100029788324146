"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Sidebar/SettingsIcon/PendingUsersWarning.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

const PendingUsersWarning = () => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement('h3', { className: "tooltip--upgrade-warning--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
      , _react2.default.createElement('img', { src: "/assets/icons/alert-orange.svg", alt: "⚠", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}} ), "Join Requests"

    )
    , _react2.default.createElement('span', { className: "tooltip--upgrade-warning--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, "Accept or reject requests to join your organizations."

    )
  )
)

exports. default = PendingUsersWarning
