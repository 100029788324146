"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');

















const createAndJoinOrgActions = _utils.createAsyncActions('CreateAndJoinOrg')
const requestJoinOrgActions = _utils.createAsyncActions('RequestJoinOrg')
const resendOrgUserInviteActions = _utils.createAsyncActions('ResendOrgUserInvite')
const changeUserOrgTitleActions = _utils.createAsyncActions('ChangeUserOrgTitle')
const changeUserOrgRoleActions = _utils.createAsyncActions('ChangeUserOrgRole')
const updateOrgInfoActions = _utils.createAsyncActions('UpdateOrgInfo')
const cancelJoinOrgRequestActions = _utils.createAsyncActions('CancelJoinOrgRequest')
const approvePendingUserActions = _utils.createAsyncActions('ApprovePendingUser')
const rejectPendingUserActions = _utils.createAsyncActions('RejectPendingUser')
const removeUserFromOrgActions = _utils.createAsyncActions('RemoveUserFromOrg')
const leaveOrgActions = _utils.createAsyncActions('LeaveOrg')
const createOrgWithNewAdminActions = _utils.createAsyncActions('CreateOrgWithNewAdmin')
const addUsersToOrgActions = _utils.createAsyncActions('AddUsersToOrg')

 const actionCreators = {
  createAndJoinOrgActions,
  requestJoinOrgActions,
  resendOrgUserInviteActions,
  changeUserOrgTitleActions,
  changeUserOrgRoleActions,
  updateOrgInfoActions,
  cancelJoinOrgRequestActions,
  approvePendingUserActions,
  rejectPendingUserActions,
  removeUserFromOrgActions,
  leaveOrgActions,
  createOrgWithNewAdminActions,
  addUsersToOrgActions,
}; exports.actionCreators = actionCreators

const createAndJoinOrg = (req) =>
  _helpers.wsCommand('CreateAndJoinOrg', createAndJoinOrgActions, req, null)

const requestJoinOrg = (req) =>
  _helpers.wsCommand('RequestJoinOrg', requestJoinOrgActions, req, null)

const resendOrgUserInvite = (req) =>
  _helpers.wsCommand('ResendOrgUserInvite', resendOrgUserInviteActions, req, null)

const changeUserOrgTitle = (req) =>
  _helpers.wsCommand('ChangeUserOrgTitle', changeUserOrgTitleActions, req, null)

const changeUserOrgRole = (req) =>
  _helpers.wsCommand('ChangeUserOrgRole', changeUserOrgRoleActions, req, null)

const updateOrgInfo = (req) =>
  _helpers.wsCommand('UpdateOrgInfo', updateOrgInfoActions, req, null)

const cancelJoinOrgRequest = (req) =>
  _helpers.wsCommand('CancelJoinOrgRequest', cancelJoinOrgRequestActions, req, null)

const approvePendingUser = (req) =>
  _helpers.wsCommand('ApprovePendingUser', approvePendingUserActions, req, null)

const rejectPendingUser = (req) =>
  _helpers.wsCommand('RejectPendingUser', rejectPendingUserActions, req, null)

const removeUserFromOrg = (req) =>
  _helpers.wsCommand('RemoveUserFromOrg', removeUserFromOrgActions, req, null)

const leaveOrg = (req) =>
  _helpers.wsCommand('LeaveOrg', leaveOrgActions, req, null)

const createOrgWithNewAdmin = (req) =>
  _helpers.wsCommand(
    'CreateOrgWithNewAdmin',
    createOrgWithNewAdminActions,
    req,
    null,
  )

const addUsersToOrg = async (req) =>
  req.UserKeys.length
    ? _helpers.wsCommand('AddUsersToOrg', addUsersToOrgActions, req, null)
    : { Users: {}, Orgs: {} }

exports. default = {
  createAndJoinOrg,
  requestJoinOrg,
  resendOrgUserInvite,
  changeUserOrgTitle,
  changeUserOrgRole,
  updateOrgInfo,
  cancelJoinOrgRequest,
  approvePendingUser,
  rejectPendingUser,
  removeUserFromOrg,
  leaveOrg,
  createOrgWithNewAdmin,
  addUsersToOrg,
}
