"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/DirectoryCreateNewModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _types = require('web/screens/Directory/types');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _CreateNewPatient = require('./CreateNewPatient'); var _CreateNewPatient2 = _interopRequireDefault(_CreateNewPatient);
var _CreateNewTeam = require('./CreateNewTeam'); var _CreateNewTeam2 = _interopRequireDefault(_CreateNewTeam);
var _CreateNewCoworker = require('./CreateNewCoworker'); var _CreateNewCoworker2 = _interopRequireDefault(_CreateNewCoworker);








const resolveModalTitle = (type) => {
  const titles = {
    [_types.DirectoryTab.PATIENTS]: 'Add a patient',
    [_types.DirectoryTab.COWORKERS]: 'Invite a coworker',
    [_types.DirectoryTab.TEAMS]: 'Create a team',
  }

  return titles[type] || 'Add a contact'
}

const DirectoryCreateNewModal = ({
  directoryTypeHint,
  isOpen,
  onClose,
  orgKeyContext,
}) => {
  const renderContent = () => {
    const props = { onClose, orgKeyContext }

    switch (directoryTypeHint) {
      case _types.DirectoryTab.COWORKERS: return _react2.default.createElement(_CreateNewCoworker2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}} )
      case _types.DirectoryTab.TEAMS: return _react2.default.createElement(_CreateNewTeam2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
      case _types.DirectoryTab.PATIENTS: return (
        _react2.default.createElement(_CreateNewPatient2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
      )
      default: return null
    }
  }

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: resolveModalTitle(directoryTypeHint),
      className: "directory--create-new--modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
    
      ,  renderContent() 
    )
  )
}

exports. default = DirectoryCreateNewModal
