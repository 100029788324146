"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _store = require('web/store');



const gotoOrgTab = (orgKey, tabName) =>
  _store.history.push(_routes.makeRoute.call(void 0, _routes2.default.SETTINGS.ORG_PAGE, { orgKey, tabName }))

const gotoSettingsRoot = () => _store.history.push(_routes2.default.SETTINGS.ROOT)
const gotoOrgsList = () => _store.history.push(_routes2.default.SETTINGS.ORGS)

const gotoOrgInfo = (orgKey) => gotoOrgTab(orgKey, 'info')
const gotoOrgMembers = (orgKey) => gotoOrgTab(orgKey, 'members')
const gotoOrgMembership = (orgKey) => gotoOrgTab(orgKey, 'membership')
const gotoOrgSubscriptions = (orgKey) => gotoOrgTab(orgKey, 'plan')

 const useNav = () => ({
  gotoSettingsRoot,
  gotoOrgsList,
  gotoOrgInfo,
  gotoOrgMembers,
  gotoOrgMembership,
  gotoOrgSubscriptions,
}); exports.useNav = useNav
