"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/Patients/Item.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');



var _entities = require('shared/transforms/entities');

var _GenericItem = require('web/screens/Directory/Sidebar/List/GenericItem'); var _GenericItem2 = _interopRequireDefault(_GenericItem);







const PatientItem = ({ patientEntity, orgKey, isViewed }) => (
  _react2.default.createElement(_GenericItem2.default, {
    entity: patientEntity,
    orgKey: orgKey,
    isViewed: isViewed, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
  )
)





const mapState = (state, { patientKey, orgKey }) => {
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgsMap = (org ? { [orgKey]: org } : {}) 
  const patient = _peers.selectPatientByKey.call(void 0, state, patientKey)

  return {
    patientEntity: patient
      ? _entities.mapPatientToEntity.call(void 0, orgsMap, (0, _entities.makeContactPatientDescription))(patient)
      : null,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(PatientItem)
