"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/CampaignForm/Form/MessageDelayController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');





var _broadcastCampaign = require('shared/validation/broadcastCampaign');
var _utils = require('./helpers/utils');
var _event = require('shared/services/event');

var _Form = require('web/components/Form');

const MessageDelayController = ({
  name,
  control,
}) => (
  _react2.default.createElement(_reacthookform.Controller, {
    control: control,
    name: name,
    render: ({ field }) => {
      const roundedValue = _utils.roundMessageDelay.call(void 0, +field.value) / 1000
      const valueLabel = roundedValue === 1
        ? 'second'
        : `${roundedValue} seconds`

      return (
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement(_Form.RangeInput, {
            min: _broadcastCampaign.MIN_MESSAGE_DELAY_MS,
            max: _broadcastCampaign.MAX_MESSAGE_DELAY_MS,
            value: field.value,
            onChange: v => field.onChange(_event.makeChangeEvent.call(void 0, v)),
            className: "broadcast--message-delay--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
          )
          , _react2.default.createElement('span', { className: "broadcast--campaign-form--field-hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "Your messages will be sent every "
                  ,  valueLabel , "."
          )
        )
      )
    }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
  )
)

exports. default = MessageDelayController
