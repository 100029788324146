"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/ExtraPhoneNumber/PhoneNumbersSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');





const PhoneNumbersSection = ({
  onPickPhone,
}) => (
  _react2.default.createElement('div', { className: "settings--org-subs--feat--sms-extra", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
    , _react2.default.createElement(_Form.Button, {
      isMinimalistic: true,
      onClick: onPickPhone,
      className: "settings--org-subs--feat--sms-extra-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    , "Select an additional phone number"

    )
  )
)

exports. default = PhoneNumbersSection
