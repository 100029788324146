"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Header/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _LinkBar = require('web/components/VideoRooms/Room/LinkBar'); var _LinkBar2 = _interopRequireDefault(_LinkBar);
var _DesktopMoreTooltip = require('./DesktopMoreTooltip'); var _DesktopMoreTooltip2 = _interopRequireDefault(_DesktopMoreTooltip);
var _MenuPicker = require('web/components/VideoRooms/Room/MenuPicker');
var _RenameRoomModal = require('./RenameRoomModal'); var _RenameRoomModal2 = _interopRequireDefault(_RenameRoomModal);
var _ArchiveRoomModal = require('./ArchiveRoomModal'); var _ArchiveRoomModal2 = _interopRequireDefault(_ArchiveRoomModal);
var _Modal = require('web/screens/VideoRooms/History/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

 class RoomBodyHeader extends _react2.default.Component {constructor(...args) { super(...args); RoomBodyHeader.prototype.__init.call(this);RoomBodyHeader.prototype.__init2.call(this);RoomBodyHeader.prototype.__init3.call(this);RoomBodyHeader.prototype.__init4.call(this);RoomBodyHeader.prototype.__init5.call(this);RoomBodyHeader.prototype.__init6.call(this);RoomBodyHeader.prototype.__init7.call(this);RoomBodyHeader.prototype.__init8.call(this);RoomBodyHeader.prototype.__init9.call(this);RoomBodyHeader.prototype.__init10.call(this);RoomBodyHeader.prototype.__init11.call(this);RoomBodyHeader.prototype.__init12.call(this);RoomBodyHeader.prototype.__init13.call(this);RoomBodyHeader.prototype.__init14.call(this); }
  __init() {this.state = {
    isMobileMoreMenuOpen: false,
    isRenameRoomModalOpen: false,
    isArchiveRoomModalOpen: false,
    isDesktopHistoryModalOpen: false,
    isMobileHistoryModalOpen: false,
  }}

  __init2() {this.openMobileMoreMenu = () => this.setState({ isMobileMoreMenuOpen: true })}
  __init3() {this.closeMobileMoreMenu = () => this.setState({ isMobileMoreMenuOpen: false })}
  __init4() {this.openRenameRoomModal = () => this.setState({ isRenameRoomModalOpen: true })}
  __init5() {this.closeRenameRoomModal = () => this.setState({ isRenameRoomModalOpen: false })}
  __init6() {this.openArchiveRoomModal = () => this.setState({ isArchiveRoomModalOpen: true })}
  __init7() {this.closeArchiveRoomModal = () => this.setState({ isArchiveRoomModalOpen: false })}
  __init8() {this.openMobileHistoryModal = () => this.setState({ isMobileHistoryModalOpen: true })}
  __init9() {this.closeMobileHistoryModal = () => this.setState({ isMobileHistoryModalOpen: false })}
  __init10() {this.openDesktopHistoryModal = () => this.setState({ isDesktopHistoryModalOpen: true })}
  __init11() {this.closeDesktopHistoryModal = () => this.setState({ isDesktopHistoryModalOpen: false })}

  __init12() {this.handleRoomRename = name => {
    this.closeRenameRoomModal()
    this.props.onRoomRename(name)
  }}

  __init13() {this.handleRoomArchive = () => {
    const { onBack, onRoomArchive } = this.props
    this.closeArchiveRoomModal()
    onRoomArchive().then(onBack)
  }}

  __init14() {this.getMoreMenuOptions = () => {
    const { isRoomArchived, onRoomUnarchive } = this.props
    return [
      {
        label: 'Rename meeting',
        onPick: this.openRenameRoomModal,
        iconSrc: 'assets/icons/video/edit-black.svg',
      },
      isRoomArchived
        ? {
            label: 'Unarchive meeting',
            onPick: onRoomUnarchive,
            iconSrc: 'assets/icons/video/retry-black.svg',
          }
        : {
            label: 'Archive meeting',
            onPick: this.openArchiveRoomModal,
            iconSrc: 'assets/icons/video/delete-black.svg',
          },
    ]
  }}

  render () {
    const {
      onBack,
      gotoMeeting,
      onRoomShare,
      onRoomLinkCopy,
      roomName,
      roomLink,
      activeParticipantsCount,
      isRoomArchived,
      onRoomUnarchive,
    } = this.props
    const {
      isMobileMoreMenuOpen,
      isRenameRoomModalOpen,
      isArchiveRoomModalOpen,
      isMobileHistoryModalOpen,
      isDesktopHistoryModalOpen,
    } = this.state

    const onlineStatusClassName = _classBuilder2.default.call(void 0, 
      "meeting-room--header--online-status",
      { active: activeParticipantsCount > 0 },
    )

    const activeParticipantsLabel = activeParticipantsCount > 0
      ? activeParticipantsCount === 1
        ? '1 participant on call'
        : `${activeParticipantsCount} participants on call`
      : 'No current call'

    return (
      _react2.default.createElement('div', { className: "meeting-room--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        , _react2.default.createElement('div', { className: "meeting-room--header--mobile-back-btn", onClick: onBack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}} )
        , _react2.default.createElement('div', { className: "meeting-room--header--mobile-more-btn", onClick: this.openMobileMoreMenu, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} )
        , _react2.default.createElement('div', { className: "meeting-room--header--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
          , _react2.default.createElement('h3', { className: "meeting-room--header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}, roomName)
          , _react2.default.createElement('span', { className: onlineStatusClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}, activeParticipantsLabel)
          , _react2.default.createElement(_LinkBar2.default, {
            link: roomLink,
            className: "meeting-room--header--link-bar",
            onShare: onRoomShare,
            onCopy: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
          )
        )
        , _react2.default.createElement('div', { className: "meeting-room--header--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
          , _react2.default.createElement('div', { className: "meeting-room--header--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
            , _react2.default.createElement('a', {
              onClick: gotoMeeting,
              className: "meeting--btn meeting-room--header--btn-primary" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
            
              , _react2.default.createElement('img', { src: "/assets/icons/video/out-white.svg", alt: "->", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} ), "Go to meeting"

            )
            , _react2.default.createElement('a', {
              onClick: this.openDesktopHistoryModal,
              className: "blmh-mobile-hide meeting--btn meeting--btn--secondary meeting-room--header--btn-secondary"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
            
              , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, "View history" )
            )
            , _react2.default.createElement('a', {
              onClick: this.openMobileHistoryModal,
              className: "blmh-desktop-hide meeting--btn meeting--btn--secondary meeting-room--header--btn-secondary"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
            
              , _react2.default.createElement('img', { src: "assets/icons/video/history-black.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} )
              , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}, "History")
            )
            , _react2.default.createElement(_DesktopMoreTooltip2.default, {
              openDesktopHistoryModal: this.openDesktopHistoryModal,
              onRoomRename: this.openRenameRoomModal,
              isRoomArchived: isRoomArchived,
              onRoomArchive: this.openArchiveRoomModal,
              onRoomUnarchive: onRoomUnarchive,
              onUnarchive: onRoomUnarchive, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
            
              , _react2.default.createElement('div', { className: "meeting-room--header--desktop-more-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}} )
            )
          )
        )
        , _react2.default.createElement(_MenuPicker.MenuPicker, {
          title: "More...",
          isOpen: isMobileMoreMenuOpen,
          onClose: this.closeMobileMoreMenu,
          options: this.getMoreMenuOptions(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
        )
        , _react2.default.createElement(_RenameRoomModal2.default, {
          roomName: roomName,
          isOpen: isRenameRoomModalOpen,
          onClose: this.closeRenameRoomModal,
          onProceed: this.handleRoomRename, __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
        )
        , _react2.default.createElement(_ArchiveRoomModal2.default, {
          isOpen: isArchiveRoomModalOpen,
          onClose: this.closeArchiveRoomModal,
          onProceed: this.handleRoomArchive, __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
        )
        ,  isDesktopHistoryModalOpen &&
          _react2.default.createElement(_Modal2.default, { onClose: this.closeDesktopHistoryModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 160}} )
        
        ,  isMobileHistoryModalOpen &&
          _react2.default.createElement(_Modal2.default, { isMobile: true, onClose: this.closeMobileHistoryModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}} )
        
      )
    )
  }
} exports.default = RoomBodyHeader;
