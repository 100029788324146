"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');

var _common = require('shared/selectors/blobject/common');
var _user = require('shared/selectors/user');
var _team = require('shared/selectors/team');

const baseSettings = {
  isMutedForever: false,
  mutedUntil: new Date(0).toISOString(),
  isVIP: false,
  notificationSound: null,
}

 const selectChatNotificationSettings = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _user.selectCurrentUserKey,
  _team.selectAllTeams,
  (blobject, myUserKey, allTeams) => {
    if (!blobject) return baseSettings

    const userOverrides = blobject.UserMetadata[myUserKey]

    if (userOverrides) {
      const {
        MuteForever, MuteUntil, VipNotifications, NotificationSound,
      } = userOverrides

      return {
        isMutedForever: MuteForever,
        mutedUntil: MuteUntil,
        isVIP: VipNotifications,
        notificationSound: NotificationSound,
      }
    }

    const teamPreferences = Object.keys(blobject.TeamSubscribers)
      .reduce((acc, teamKey) => {
        const me = _optionalChain([allTeams, 'access', _ => _[teamKey], 'optionalAccess', _2 => _2.Members, 'optionalAccess', _3 => _3[myUserKey]])
        if (me) acc.push(me)
        return acc
      }, [])

    if (!teamPreferences.length) return baseSettings

    const isMutedForever = teamPreferences.every(tp => tp.MuteForever)
    const isVIP = !!teamPreferences.find(tp => tp.VipNotifications)

    const mutedUntilTimestamps = teamPreferences
      .map(pref => new Date(pref.MuteUntil).getTime())
    const minMutedUntilTimestamp = Math.min(...mutedUntilTimestamps)
    const mutedUntil = new Date(minMutedUntilTimestamp).toISOString()

    const notificationSounds = teamPreferences
      .reduce(
        (acc, pref) => {
          const sound = pref.NotificationSound
          if (sound) acc.push(sound)
          return acc
        },
        [],
      )
      .sort()
    const notificationSound = notificationSounds[0] || null

    return { isMutedForever, mutedUntil, isVIP, notificationSound }
  },
); exports.selectChatNotificationSettings = selectChatNotificationSettings
