"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');




var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);












const personalInfoSchema = _joi4.default.object({
  firstName: _joi3.requiredString.call(void 0, ).max(100).messages(_joi3.makeStrErrors.call(void 0, 'First name')),
  lastName: _joi3.requiredString.call(void 0, ).max(100).messages(_joi3.makeStrErrors.call(void 0, 'Last name')),
  phone: _joi3.optionalString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Phone')).contactPhone(),
  email: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Email')).contactEmail(),
})

const emailSchema = _joi4.default.object({
  email: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Email')).contactEmail(),
})

 const personalInfoResolver = _joi.joiResolver.call(void 0, personalInfoSchema); exports.personalInfoResolver = personalInfoResolver
 const emailResolver = _joi.joiResolver.call(void 0, emailSchema); exports.emailResolver = emailResolver
