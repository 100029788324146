"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Security/PasswordForm.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _Form = require('web/components/Form');
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);
var _PasswordSection = require('./PasswordSection'); var _PasswordSection2 = _interopRequireDefault(_PasswordSection);
var _app = require('shared/constants/app');

 class PasswordForm extends _react2.default.Component {constructor(...args) { super(...args); PasswordForm.prototype.__init.call(this);PasswordForm.prototype.__init2.call(this);PasswordForm.prototype.__init3.call(this);PasswordForm.prototype.__init4.call(this);PasswordForm.prototype.__init5.call(this); }
  __init() {this.getInitialState = () => ({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
    isPasswordStrong: false,
  })}

  __init2() {this.state = this.getInitialState()}

  __init3() {this.handleSubmit = async e => {
    e.preventDefault()
    this.props.onSubmit(this.state)
    this.setState(this.getInitialState())
  }}

  __init4() {this.handleStrengthChange = isPasswordStrong => this.setState({ isPasswordStrong })}
  __init5() {this.handleChange = (inputId, value) => this.setState({ [inputId]: value })}

  render () {
    const {
      isPasswordStrong,
      currentPassword, newPassword, newPasswordConfirmation,
    } = this.state

    const isFormFilled =
      currentPassword && newPassword && newPasswordConfirmation

    const isConfirmationCorrect = newPassword === newPasswordConfirmation
    const shouldShowMismatchWarning =
      newPassword &&
      newPasswordConfirmation &&
      !isConfirmationCorrect

    return (
      _react2.default.createElement('form', { onSubmit: this.handleSubmit, className: "settings--account--form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , _react2.default.createElement(_PasswordSection2.default, {
          title: "Current password" ,
          inputId: "currentPassword",
          value: currentPassword,
          onChange: this.handleChange,
          placeholder: "Enter your current password"   ,
          autoComplete: "current-password", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        )
        , _react2.default.createElement(_PasswordSection2.default, {
          title: "New password" ,
          inputId: "newPassword",
          value: newPassword,
          onChange: this.handleChange,
          placeholder: "Enter a new password"   ,
          autoComplete: "new-password",
          onValidated: this.handleStrengthChange,
          maxLength: _app.MAX_PASSWORD_LENGTH, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        )
        , _react2.default.createElement(_PasswordSection2.default, {
          title: "Confirm password" ,
          inputId: "newPasswordConfirmation",
          inputClassName: 
            shouldShowMismatchWarning ? 'settings--account--input-invalid' : null
          ,
          value: newPasswordConfirmation,
          onChange: this.handleChange,
          autoComplete: "new-password",
          placeholder: "Confirm your new password"   ,
          maxLength: _app.MAX_PASSWORD_LENGTH, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        )
        ,  shouldShowMismatchWarning && (
          _react2.default.createElement(_WarningBar2.default, {
            className: "settings--account--warning",
            textClassName: "settings--account--warning-text",
            theme: "warning",
            text: "Your passwords don't match. Make sure you entered your new password correctly."           , __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          )
        )
        , _react2.default.createElement(_Form.Button, {
          isSubmit: true,
          isDisabled: !isFormFilled || !isPasswordStrong,
          className: "settings--account--submit-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
        , "Save"

        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    onSubmit: _proptypes2.default.func.isRequired,
  }}
} PasswordForm.__initStatic(); exports.default = PasswordForm;
