"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _reselect = require('reselect');

var _features = require('shared/enum/features'); var _features2 = _interopRequireDefault(_features);
var _common = require('./common');

 const makeSelectHasFeature = (featureName) =>
  _reselect.createSelector.call(void 0, 
    _common.selectCurrentUser,
    me => me && me.EnabledFeatures.some(f => f === featureName),
  ); exports.makeSelectHasFeature = makeSelectHasFeature

 const selectCurrentUserHasPatientMessagingFeature =
  exports.makeSelectHasFeature.call(void 0, _features2.default.PATIENT_MESSAGING); exports.selectCurrentUserHasPatientMessagingFeature = selectCurrentUserHasPatientMessagingFeature
 const selectCurrentUserHasVideoV1Feature =
  exports.makeSelectHasFeature.call(void 0, _features2.default.VIDEO_CHAT); exports.selectCurrentUserHasVideoV1Feature = selectCurrentUserHasVideoV1Feature
 const selectCurrentUserHasVideoV2Feature =
  exports.makeSelectHasFeature.call(void 0, _features2.default.BLOOM_VIDEO_V2); exports.selectCurrentUserHasVideoV2Feature = selectCurrentUserHasVideoV2Feature
 const selectCurrentUserHasAdvancedSearchFeature =
  exports.makeSelectHasFeature.call(void 0, _features2.default.ADVANCED_SEARCH); exports.selectCurrentUserHasAdvancedSearchFeature = selectCurrentUserHasAdvancedSearchFeature
 const selectCurrentUserHasTestSearchIteratorsFeature =
  exports.makeSelectHasFeature.call(void 0, _features2.default.TEST_SEARCH_ITERATORS); exports.selectCurrentUserHasTestSearchIteratorsFeature = selectCurrentUserHasTestSearchIteratorsFeature
 const selectCurrentUserHasCreateNewFeature =
  exports.makeSelectHasFeature.call(void 0, _features2.default.CREATE_NEW); exports.selectCurrentUserHasCreateNewFeature = selectCurrentUserHasCreateNewFeature

 const selectCanCurrentUserDoAdvancedSearch =
  _reselect.createSelector.call(void 0, 
    exports.selectCurrentUserHasAdvancedSearchFeature,
    exports.selectCurrentUserHasPatientMessagingFeature,
    (hasAdvancedSearch, hasPatientMessaging) =>
      hasAdvancedSearch || hasPatientMessaging,
  ); exports.selectCanCurrentUserDoAdvancedSearch = selectCanCurrentUserDoAdvancedSearch
