"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/CallContent.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _VideoFeed = require('web/components/VideoCall/VideoFeed'); var _VideoFeed2 = _interopRequireDefault(_VideoFeed);

const CallContent = ({
  isJoining,
  isPendingApproval,
  activeSpeakerVideoStream,
  isActiveSpeakerFeedOn,
  amISharingScreen,
  stopScreenSharing,
  areCallControlsActive,
  isCallMinimized,
  shouldMirrorActiveSpeakerFeed,
}) => {
  if (isJoining || isPendingApproval) {
    return (
      _react2.default.createElement('div', { className: "video-call--call-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , _react2.default.createElement('div', { className: "video-call--call-status-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
        )
        ,  isPendingApproval
          ? "Waiting for admin to join..."
          : "Joining the video call..."
        
      )
    )
  }

  if (amISharingScreen) {
    return (
      _react2.default.createElement('div', { className: "video-call--sharing-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        , _react2.default.createElement('img', {
          src: "/assets/icons/video/screen-share-white.svg",
          className: "video-call--sharing-notice--icon",
          alt: "🖵", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        )
        , _react2.default.createElement('span', { className: "video-call--sharing-notice--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, "You are screen sharing."

        )
        , _react2.default.createElement('a', {
          onClick: stopScreenSharing,
          className: "video-call--sharing-notice--stop-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , "End Screen Sharing"

        )
      )
    )
  }

  if (isActiveSpeakerFeedOn) {
    return (
      _react2.default.createElement(_VideoFeed2.default, {
        shouldMirror: shouldMirrorActiveSpeakerFeed,
        stream: activeSpeakerVideoStream,
        className: "video-call--call-feed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      )
    )
  }

  const stubIconWrapperClassName = _classBuilder2.default.call(void 0, 
    "video-call--call-feed-stub--wrapper",
    { shifted: areCallControlsActive },
    { mini: isCallMinimized },
  )
  return (
    _react2.default.createElement('div', { className: stubIconWrapperClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      , _react2.default.createElement('img', {
        className: "video-call--call-feed-stub",
        src: "/assets/icons/video/coworker-grey.svg",
        alt: "Ǵ", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      )
    )
  )
}

CallContent.propTypes = {
  isJoining: _proptypes2.default.bool.isRequired,
  isPendingApproval: _proptypes2.default.bool.isRequired,
  isActiveSpeakerFeedOn: _proptypes2.default.bool.isRequired,
  amISharingScreen: _proptypes2.default.bool.isRequired,
  activeSpeakerVideoStream: _proptypes2.default.any,
  stopScreenSharing: _proptypes2.default.func.isRequired,
  areCallControlsActive: _proptypes2.default.bool.isRequired,
  isCallMinimized: _proptypes2.default.bool.isRequired,
  shouldMirrorActiveSpeakerFeed: _proptypes2.default.bool.isRequired,
}

exports. default = CallContent
