"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/History/Modal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _reactredux = require('react-redux');




var _videoRoom = require('web/selectors/videoRoom');
var _Modal = require('web/components/VideoRooms/Room/History/Desktop/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Modal3 = require('web/components/VideoRooms/Room/History/Mobile/Modal'); var _Modal4 = _interopRequireDefault(_Modal3);

const modalScreens = {
  HISTORY: 'MODAL_SCREEN_HISTORY',
  DETAILS: 'MODAL_SCREEN_DETAILS',
}

class HistoryModal extends _react2.default.Component {constructor(...args) { super(...args); HistoryModal.prototype.__init.call(this);HistoryModal.prototype.__init2.call(this);HistoryModal.prototype.__init3.call(this);HistoryModal.prototype.__init4.call(this); }
  __init() {this.state = {
    currentScreen: modalScreens.HISTORY,
    currentSessionKey: null,
  }}

  __init2() {this.handleSessionPick = sessionKey =>
    this.setState({ currentSessionKey: sessionKey }, this.gotoDetails)}

  __init3() {this.gotoHistory = () => this.setState({ currentScreen: modalScreens.HISTORY })}
  __init4() {this.gotoDetails = () => this.setState({ currentScreen: modalScreens.DETAILS })}

  render () {
    const { currentScreen, currentSessionKey } = this.state
    const { isMobile, onClose, roomKey, roomName } = this.props

    const Modal = isMobile ? _Modal4.default : _Modal2.default

    return (
      _react2.default.createElement(Modal, {
        isOpen: true,
        onClose: onClose,
        roomKey: roomKey,
        roomName: roomName,
        loadSessionsList: _actions2.default.loadVideoRoomSessionsList,
        onSessionPick: this.handleSessionPick,
        isOnHistoryScreen: currentScreen === modalScreens.HISTORY,
        gotoHistory: this.gotoHistory,
        gotoDetails: this.gotoDetails,
        currentSessionKey: currentSessionKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )
    )
  }
}

const mapState = state => ({
  roomKey: _videoRoom.selectCurrentVideoRoomKey.call(void 0, state),
  roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(HistoryModal)
