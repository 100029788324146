"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }





var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _contact = require('shared/enum/contact');

const makeError = (displayMsg) => () => {
  throw new Error(displayMsg)
}

const invitePatient = async (data, OrgKey) => {
  const res = await _actions2.default.createPatient({
    OrgKey,
    FirstName: data.firstName,
    LastName: data.lastName,
    DateOfBirth: data.dob,
    Phone: data.phone,
    Email: data.email,
    ForeignID: data.foreignID,
  })

  const [patient] = Object.values(res.Patients)
  if (!patient) throw new Error("No Patient received from backend")

  return patient
}

 const inviteCoworker = async (data, OrgKey) => {
  const res = await _actions2.default.inviteUserToMyOrg({
    OrgKey,
    Email: data.email,
    FirstName: data.firstName,
    LastName: data.lastName,
    OrgTitle: data.title,
    Role: data.isAdmin ? 'admin' : 'user',
  })

  const [user] = Object.values(res.Users)
  if (!user) throw new Error("No user received from backend")

  return user
}; exports.inviteCoworker = inviteCoworker

 const inviteExternal = async (data) => {
  const res = await _actions2.default.inviteUserNoOrg({
    Email: data.email,
  })

  const [user] = Object.values(res.Users)
  if (!user) throw new Error("No user received from backend")

  return user
}; exports.inviteExternal = inviteExternal

 const inviteContact = (
  data,
  relType,
  orgKey,
) => {
  switch (relType) {
    case _contact.CONTACT_REL_TYPES.PATIENT:
      return invitePatient(data , orgKey)
        .catch(makeError("Cannot invite patient"))
    case _contact.CONTACT_REL_TYPES.COWORKER:
      return exports.inviteCoworker.call(void 0, data , orgKey)
        .catch(makeError("Cannot invite coworker"))
    case _contact.CONTACT_REL_TYPES.EXTERNAL:
      return exports.inviteExternal.call(void 0, data )
        .catch(makeError("Cannot invite collaborator"))
  }
}; exports.inviteContact = inviteContact
