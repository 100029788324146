"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Feedback/Modals/Comment.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

 class CommentModal extends _react2.default.Component {constructor(...args) { super(...args); CommentModal.prototype.__init.call(this);CommentModal.prototype.__init2.call(this);CommentModal.prototype.__init3.call(this);CommentModal.prototype.__init4.call(this); }
  __init() {this.state = {
    commentText: '',
  }}

  __init2() {this.handleSubmit = () => {
    const { onSubmit, onClose } = this.props
    const { commentText } = this.state

    onSubmit(commentText)
    onClose()
  }}

  __init3() {this.handleClose = () => {
    const { onSubmit, onClose } = this.props
    onSubmit(null)
    onClose()
  }}

  __init4() {this.handleCommentChange = e => this.setState({ commentText: e.target.value })}

  render () {
    const { isOpen } = this.props
    const { commentText } = this.state

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: isOpen,
        onClose: this.handleClose,
        title: "Tell us more about it"    ,
        className: "video-call--feedback--comment-modal-wrapper",
        size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      
        ,  isOpen &&
          _react2.default.createElement('textarea', {
            autoFocus: true,
            className: "video-call--feedback--comment-input",
            placeholder: "What issues did you experience?"    ,
            value: commentText,
            onChange: this.handleCommentChange,
            maxLength: 500, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          )
        
        , _react2.default.createElement('div', { className: "video-call--feedback--comment-buttons-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , _react2.default.createElement('a', {
            onClick: this.handleClose,
            className: "meeting--btn secondary video-call--feedback--comment-button video-call--feedback--comment--back-btn"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , "Skip"

          )
          , _react2.default.createElement('button', {
            className: "meeting--btn video-call--feedback--comment-button" ,
            onClick: this.handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
          , "Submit"

          )
        )
      )
    )
  }
} exports.default = CommentModal;
