"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Sidebar/CampaignsList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');
var _reactwaypoint = require('react-waypoint');



var _types = require('./types');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _usePagination = require('shared/hooks/usePagination'); var _usePagination2 = _interopRequireDefault(_usePagination);
var _useBus = require('shared/hooks/useBus'); var _useBus2 = _interopRequireDefault(_useBus);

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _CampaignItem = require('./CampaignItem'); var _CampaignItem2 = _interopRequireDefault(_CampaignItem);
var _Tabs = require('web/components/Tabs'); var _Tabs2 = _interopRequireDefault(_Tabs);

const CAMPAIGN_LOAD_LIMIT = 30
const keyExtractor = (c) => c.CampaignKey
const keyActionTypes = [
  _actionCreators2.default.createCampaignActions.SUCCESS.type,
  _actionCreators2.default.archiveCampaignActions.SUCCESS.type,
  _actionCreators2.default.unarchiveCampaignActions.SUCCESS.type,
]

const filterOptions = [
  { value: _types.Filter.active, label: "Active", iconName: "active"  },
  { value: _types.Filter.archived, label: "Archived", iconName: "completed"  },
]





const CampaignsList = ({ teamKey }) => {
  const [activeFilter, setActiveFilter] = _react.useState(_types.Filter.active)
  const loadItemsList = _react.useCallback.call(void 0, 
    (_query, Offset, Limit) => _actions2.default
      .findCampaigns({
        TeamKey: teamKey,
        Archived: activeFilter === _types.Filter.archived,
        Offset,
        Limit,
      })
      .then(r => r.Campaigns),
    [teamKey, activeFilter],
  )

  const { isLoading, hasDrained, itemKeysList, onLoadMore, onReset } =
    _usePagination2.default.call(void 0, {
      loadItemsList,
      keyExtractor,
      limit: CAMPAIGN_LOAD_LIMIT,
    })
  _useBus2.default.call(void 0, keyActionTypes, onReset)

  const match = _reactrouterdom.useRouteMatch(_routes2.default.BROADCAST.CAMPAIGN_PAGE)
  const viewedCampaignKey = _optionalChain([match, 'optionalAccess', _ => _.params, 'optionalAccess', _2 => _2.campaignKey])

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Tabs2.default, {
        type: "filter",
        options: filterOptions,
        value: activeFilter,
        onSelectTab: setActiveFilter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      )
      , _react2.default.createElement('div', { className: "broadcast-list--results", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        ,  itemKeysList.map(campaignKey => (
          _react2.default.createElement(_CampaignItem2.default, {
            key: campaignKey,
            activeFilter: activeFilter,
            campaignKey: campaignKey,
            onClick: () => _actions2.default.gotoCampaignPage(campaignKey),
            onArchive: _actions2.default.archiveCampaign,
            onUnarchive: _actions2.default.unarchiveCampaign,
            isViewed: viewedCampaignKey === campaignKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          )
        ))
        ,  itemKeysList.length && !hasDrained
          ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: onLoadMore, bottomOffset: -100, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )
          : null
        
        , _react2.default.createElement(_ListResultsStatus2.default, {
          isLoading: isLoading,
          resultsCount: itemKeysList.length,
          noResultsLabel: "No campaigns found."  ,
          className: "broadcast-list--results-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        )
      )
    )
  )
}

exports. default = CampaignsList
