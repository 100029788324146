"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/ToggleController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _hookForm = require('shared/services/validation/hookForm');
var _event = require('shared/services/event');

var _Toggle = require('web/components/Form/Toggle'); var _Toggle2 = _interopRequireDefault(_Toggle);






const ToggleController = (
  { name, control, ...props },
) => (
  _react2.default.createElement(_hookForm.Controller, {
    name: name,
    control: control,
    render: ({ field }) => (
      _react2.default.createElement(_Toggle2.default, {
        ...props,
        isChecked: !!field.value,
        onChange: v => field.onChange(_event.makeChangeEvent.call(void 0, v)), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  )
  )

exports. default = ToggleController
