"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _utils = require('shared/store/utils');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);







const initialState = {
  authed: false,
  connected: false,
  clientOpen: false,
}

exports. default = _utils.createReducer.call(void 0, 
  initialState,
  builder => {
    builder
      .addCase(_actionCreators2.default.loginSuccessAction, s => { s.authed = true })
      .addCase(_actionCreators2.default.logoutAction, () => initialState)
      .addCase('SESSION_EXPIRED', () => ({ ...initialState, authed: 'session_expired' }))
      .addCase('AUTH_ERROR_DISMISSED', s => { s.authed = false })
      .addCase('CLIENT_OPENED', s => { s.clientOpen = true })
      .addCase('CLIENT_CLOSED', s => {
        s.clientOpen = false
        s.connected = false
      })

    ;[
      'SERVER_ACK_SOCKET_CONNECTED',
      'OPTIMISTIC_INITIAL_SOCKET_CONNECTED',
      'SOCKET_CONNECTED',
    ].forEach(t => builder.addCase(t, s => { s.connected = true }))

    ;[
      "NO_NETWORK_CONNECTION",
      "SOCKET_DISCONNECTED",
      "FAILED_OPTIMISTIC_SOCKET_CONNECTED",
    ].forEach(t => builder.addCase(t, s => { s.connected = false }))

    builder.addDefaultCase(s => s)
  },
)
