"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/index.tsx"; function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { newObj[key] = obj[key]; } } } newObj.default = obj; return newObj; } } function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }require('@babel/polyfill');
require('intersection-observer');
require('whatwg-fetch');

var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactdom = require('react-dom'); var _reactdom2 = _interopRequireDefault(_reactdom);
var _reactredux = require('react-redux');

var _react3 = require('@sentry/react'); var Sentry = _interopRequireWildcard(_react3);

var _store = require('web/store');
var _store3 = require('shared/store');

var _chat = require('web/chat'); var _chat2 = _interopRequireDefault(_chat);
var _patientResponse = require('web/patientResponse'); var _patientResponse2 = _interopRequireDefault(_patientResponse);

require('../styles/index.scss');

var _chat3 = require('web/store/rootReducers/chat'); var _chat4 = _interopRequireDefault(_chat3);
var _patientResponse3 = require('web/store/rootReducers/patientResponse'); var _patientResponse4 = _interopRequireDefault(_patientResponse3);








var _app = require('web/services/app');

log.setLevel(LOG_LEVEL)

if (SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: ENVIRONMENT,
    release: BUILD,
    beforeBreadcrumb (breadcrumb) {
      return breadcrumb.category === "action" ? breadcrumb : null
    },
  })
}





const AppWrapper = ({ store, children }) => (
  _react2.default.createElement(_reactredux.Provider, { store: store, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}},  children )
)

// Transforming this to a functional Component messes up Redux ¯\_(0.0)_/¯
class Root extends _react2.default.Component {
  render () {
    switch (_app.getAppMode.call(void 0, )) {
      case _app.MWEB_CHAT_MODE:
      case _app.WEB_CHAT_MODE:
      case _app.ELECTRON_CHAT_MODE:
      case _app.LOU_VIDEO_MEETING_MODE: {
        const store = _store.configureStore(_chat4.default)
        _store3.setStore(store)
        return (
          _react2.default.createElement(AppWrapper, { store: store, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            , _react2.default.createElement(_chat2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
          )
        )
      }
      case _app.PATIENT_RESPONSE_MODE: {
        const store = _store.configureStore(_patientResponse4.default)
        _store3.setStore(store)
        return (
          _react2.default.createElement(AppWrapper, { store: store, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
            , _react2.default.createElement(_patientResponse2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} )
          )
        )
      }
      default: return null
    }
  }
}

_reactdom2.default.render(_react2.default.createElement(Root, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} ), document.getElementById('root'))
