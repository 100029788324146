"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/BannerAlertScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _BannerAlert = require('web/components/BannerAlert'); var _BannerAlert2 = _interopRequireDefault(_BannerAlert);
var _alertReducer = require('shared/reducers/alertReducer');
var _videoRoom = require('web/selectors/videoRoom');
var _user = require('shared/selectors/user');

class BannerAlertScreen extends _react.Component {
  constructor (props) {
    super(props);BannerAlertScreen.prototype.__init.call(this);BannerAlertScreen.prototype.__init2.call(this);BannerAlertScreen.prototype.__init3.call(this);
    this.state = { serviceAlert: null }
  }

  componentDidUpdate (prevProps, prevState) {
    // infrom the user they just joined a new org
    const newOrg = prevProps.relOrgs
      .filter(ro => ro.rel.Pending)
      .find(relOrg => this.props.relOrgs.filter(ro => !ro.rel.Pending).find(ro => ro.org.OrgKey === relOrg.org.OrgKey))

    if (newOrg) {
      _actions2.default.pushAlert("Approved", `Your request to join ${newOrg.org.Name} has been accepted.`, _alertReducer.AlertTypes.SUCCESS)
    }
  }

  componentDidMount () {
    _client2.default.restFetchAlerts()
      .then(result => this.setState({ serviceAlert: result }))
      .catch(() => _client2.default.log({ message: 'Failed to load pending alerts' }))
      .catch(() => null)
  }

  __init() {this.approve = (org, user) => {
    _actions2.default.approvePendingUser(org.OrgKey, user.UserKey)
      .then(() => _actions2.default.pushAlert("User approved", `${user.Name} has been added to ${org.Name}.`, _alertReducer.AlertTypes.SUCCESS))
      .catch(log.error)
  }}

  __init2() {this.reject = (org, user) => {
    _actions2.default.rejectPendingUser(org.OrgKey, user.UserKey)
      .then(() => _actions2.default.pushAlert("User rejected", `${user.Name} has not been added to ${org.Name}.`, _alertReducer.AlertTypes.ERROR))
      .catch(log.error)
  }}

  __init3() {this.cancelRequest = (org) => {
    _actions2.default.cancelJoinOrgRequest({ OrgKey: org.OrgKey })
      .then(() => _actions2.default.pushAlert("Success", `Your request to join ${org.Name} has been canceled.`, _alertReducer.AlertTypes.SUCCESS))
      .catch(() => _actions2.default.pushAlert('Error', 'Failed to cancel your join request', _alertReducer.AlertTypes.ERROR))
  }}

  render () {
    const { userAlerts, call, videoRoomPendingUsersList } = this.props

    const pendingRequestItems = userAlerts.length > 0 ? userAlerts.map(org => ({
      message: `Your request to join ${org.Name} is pending`,
      actions: [
        { text: 'Cancel request', func: () => this.cancelRequest(org) },
      ],
    })) : []

    const callAlert = call.callMinimized && call.callOngoing ? [{
      message: `Call ongoing`,
      actions: [
        { text: 'Return to call', func: () => _actions2.default.returnToCall() },
        // { text: 'End call', func: () => actions.endCall()},
      ],
    }] : []

    const videoRoomAuthorizationAlert = videoRoomPendingUsersList.map(x => {
      const respond = Approved => _actions2.default.respondVideoRoomJoinRequest({
        Approved,
        RoomKey: x.RoomKey,
        RoomAuthorizationKey: x.RoomAuthorizationKey,
      })
      return {
        message: `${x.RoomAuthorizationUserName || 'Someone'} is requesting to join ${x.RoomName}`,
        actions: [
          { text: 'Approve', func: () => respond(true) },
          { text: 'Reject', func: () => respond(false) },
        ],
      }
    })

    const serviceAlert = this.state.serviceAlert != null ? [{
      message: this.state.serviceAlert,
      actions: [
        { text: 'Details', func: () => window.open('https://bloomtext.statuspage.io/') },
      ],
    }] : []

    const allAlerts = [
      ...pendingRequestItems,
      ...callAlert,
      ...serviceAlert,
      ...videoRoomAuthorizationAlert,
    ]

    if (allAlerts.length === 0) {
      return null
    }

    return (
      _react2.default.createElement(_BannerAlert2.default, {
        ...allAlerts[0],
        breathing: callAlert.length > 0 && serviceAlert.length < 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      )
    )
  }
}

BannerAlertScreen.propTypes = {
  alerts: _proptypes2.default.arrayOf(_proptypes2.default.shape({
    org: _proptypes2.default.Object,
    user: _proptypes2.default.Object,
  })),
}

const mapState = state => {
  const currentUser = _user.selectCurrentUser.call(void 0, state)
  const currentUserV2 = _user.selectCurrentUserV2.call(void 0, state)
  // inform the user their request is pending if in pending state
  const userAlerts = Object.keys(currentUser.Relationships)
    .map(k => currentUser.Relationships[k])
    .filter(r => r.Pending)
    .map(r => r.OrgKey)
    .map(k => state.orgs[k])

  const relationshipsWithOrgs = Object.keys(currentUser.Relationships)
    .map(k => currentUser.Relationships[k])
    .map(r => ({ org: state.orgs[r.OrgKey], rel: r }))

  return {
    userAlerts: userAlerts || [],
    relOrgs: relationshipsWithOrgs,
    call: state.call,
    currentUser: currentUserV2,
    videoRoomPendingUsersList: _videoRoom.selectVideoRoomPendingUsersList.call(void 0, state),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(BannerAlertScreen)
