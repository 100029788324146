"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/AddParticipantsModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _createNew = require('shared/hooks/createNew');



var _blobject = require('shared/selectors/blobject');
var _conversation = require('shared/selectors/conversation');



var _chatParticipants = require('shared/hooks/chatParticipants');
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _ParticipantsPicker = require('web/components/ParticipantsPicker'); var _ParticipantsPicker2 = _interopRequireDefault(_ParticipantsPicker);
var _Form = require('web/components/Form');

const SUCCESS_MSG = 'Participants were added.'
const FAILURE_MSG = 'Failed to add participants.'







const AddParticipantsForm = ({
  conversationKey,
  orgKeyContext,
  onClose,
  conversationType,
  currentPatientsCount,
}) => {
  const [isSubmitting, setIsSubmitting] = _react.useState.call(void 0, false)
  const {
    selectedEntitiesMap,
    selectedEntitiesList,
    onSelectEntity,
    onRemoveEntity,
  } = _createNew.useSelectedEntities.call(void 0, )

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleSubmit = _react.useCallback.call(void 0, 
    async () => {
      if (!conversationKey) return
      setIsSubmitting(true)

      await _chatParticipants.balanceChatParticipants.call(void 0, 
        conversationKey,
        {},
        selectedEntitiesMap,
        selectedEntitiesList,
      )
        .then(() => {
          showSuccessBar(SUCCESS_MSG)
          setIsSubmitting(false)
          onClose()
        })
        .catch(() => {
          showWarningBar(FAILURE_MSG)
          setIsSubmitting(false)
        })
    },
    [
      conversationKey, onClose,
      selectedEntitiesList, selectedEntitiesMap, setIsSubmitting,
      showWarningBar, showSuccessBar,
    ],
  )

  _react.useEffect.call(void 0, () => _amplitude2.default.logEvent("Open Add Participants Modal"), [])
  const participantQuotas =
    _chatParticipants.useAddParticipantsQuotas.call(void 0, { conversationType, currentPatientsCount })

  if (!orgKeyContext) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        shouldCaptureFocus: false,
        className: "inbox--add-participants-modal--picker-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      
        , _react2.default.createElement(_ParticipantsPicker2.default, {
          orgKeyContext: orgKeyContext,
          selectedEntitiesList: selectedEntitiesList,
          onSelectEntity: onSelectEntity,
          onRemoveEntity: onRemoveEntity,
          participantQuotas: participantQuotas,
          className: "participants-picker--field",
          inputClassName: "participants-picker--field--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Save",
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: selectedEntitiesList.length > 0,
        onPrimaryBtnClick: handleSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
      )
    )
  )
}








const makeMapState = () => {
  const selectSubscriberCounts = _blobject.makeSelectBlobjectSubscriberCounts.call(void 0, )
  return (state, { blobjectKey }) => {
    const orgKeyContext = _conversation.selectMyOrgKeyInConvo.call(void 0, state, blobjectKey)
    const blobject = _blobject.selectBlobjectByKey.call(void 0, state, blobjectKey)
    const { patientsCount } = selectSubscriberCounts(state, blobjectKey)

    return {
      orgKeyContext,
      conversationKey: _optionalChain([blobject, 'optionalAccess', _ => _.WrappedKey]),
      conversationType: _optionalChain([blobject, 'optionalAccess', _2 => _2.WrappedType]),
      currentPatientsCount: patientsCount,
    }
  }
}

exports. default = _reactredux.connect.call(void 0, makeMapState)(AddParticipantsForm)
