"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');










const getSubscriptionActions = _utils.createAsyncActions('GetSubscription')
const getBillingURLActions = _utils.createAsyncActions('GetBillingURL')
const checkoutActions = _utils.createAsyncActions('Checkout')
const getCheckoutURLActions = _utils.createAsyncActions('GetCheckoutURL')
const getAvailablePhoneNumbersActions = _utils.createAsyncActions('GetAvailablePhoneNumbers')
const registerPhoneActions = _utils.createAsyncActions('RegisterPhone')

 const actionCreators = {
  getSubscriptionActions,
  getBillingURLActions,
  checkoutActions,
  getCheckoutURLActions,
  getAvailablePhoneNumbersActions,
  registerPhoneActions,
}; exports.actionCreators = actionCreators

const getSubscription = (req) =>
  _helpers.wsCommand('GetSubscription', getSubscriptionActions, req, null)

const getBillingURL = (req) =>
  _helpers.wsCommand('GetBillingURL', getBillingURLActions, req, null)

const checkout = (req) =>
  _helpers.wsCommand('Checkout', checkoutActions, req, null)

const getCheckoutURL = (req) =>
  _helpers.wsCommand('GetCheckoutURL', getCheckoutURLActions, req, null)

const getAvailablePhoneNumbers = (req) =>
  _helpers.wsCommand(
    'GetAvailablePhoneNumbers',
    getAvailablePhoneNumbersActions,
    req,
    null,
  )

const registerPhone = (req) =>
  _helpers.wsCommand('RegisterPhone', registerPhoneActions, req, null)

exports. default = {
  getSubscription,
  getBillingURL,
  checkout,
  getCheckoutURL,
  getAvailablePhoneNumbers,
  registerPhone,
}
