"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

const initialState = {}

 function blobjectReducer (state = initialState, action, globalStateV2) {
  let blobjectKey = null
  let newState = null

  switch (action.type) {
    case _actionCreators2.default.getInboxActions.SUCCESS.type:
    case _actionCreators2.default.getInboxForUserActions.SUCCESS.type:
    case _actionCreators2.default.getInboxForPatientActions.SUCCESS.type:
    case _actionCreators2.default.updateNotificationAction.type: {
      if (!action.payload.Blobjects) return state
      return { ...state, ...action.payload.Blobjects }
    }

    case _actionCreators2.default.markBlobjectSeenActions.REQUEST.type: {
      if (!action.payload.BKey) {
        return state
      }

      const stateBlobject = state[action.payload.BKey] || {}
      const stateUserMetadata = stateBlobject.UserMetadata || {}
      return {
        ...state,
        [action.payload.BKey]: {
          ...stateBlobject,
          UserMetadata: {
            ...stateUserMetadata,
            [globalStateV2.currentUser.UserKey]: {
              ...stateUserMetadata[globalStateV2.currentUser.UserKey],
              SeenTime: action.payload.SeenTime || new Date().toISOString(),
            },
          },
        },
      }
    }
    /* TODO: a REFRESH message here (needs backend) as opposed to an update */
    case _actionCreators2.default.getBlobjectDeepActions.SUCCESS.type:
      return {
        ...state,
        ...(action.payload.Blobjects || {}),
      }
    case _actionCreators2.default.renameChatActions.REQUEST.type: {
      const name = action.payload.Name
      blobjectKey = action.payload.BKey

      return {
        ...state,
        [blobjectKey]: {
          ...state[blobjectKey],
          Name: name,
        },
      }
    }
    case _actionCreators2.default.purgeNotificationAction.type:
      if (!action.payload.Blobjects) return state

      newState = { ...state }
      action.payload.Blobjects.forEach((b) => {
        delete newState[b]
      })

      return newState
    case _actionCreators2.default.logoutAction.type:
      return initialState
  }
  return state
} exports.default = blobjectReducer;
