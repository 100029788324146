"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');

















var _blobject3 = require('shared/selectors/blobject');
var _org = require('shared/selectors/org');
var _peers = require('shared/selectors/peers');
var _team = require('shared/selectors/team');
var _array = require('shared/services/array');

const makeDisplayUser =
  (allOrgs, allUsers) => (us) => {
    const user = allUsers[us.UserKey]
    return user && {
      userKey: user.UserKey,
      name: user.Name,
      orgName: _optionalChain([allOrgs, 'access', _ => _[us.OrgKey], 'optionalAccess', _2 => _2.Name]) || '',
      title: _optionalChain([user, 'access', _3 => _3.Relationships, 'access', _4 => _4[us.OrgKey], 'optionalAccess', _5 => _5.Title]) || 'Member',
      orgKey: us.OrgKey,
    }
  }

const makeDisplayTeam =
  (allOrgs, allTeams) => (ts) => {
    const team = allTeams[ts.TeamKey]
    return team && {
      teamKey: team.TeamKey,
      name: team.Name,
      orgName: _nullishCoalesce(_optionalChain([allOrgs, 'access', _6 => _6[team.OrgKey], 'optionalAccess', _7 => _7.Name]), () => ( '')),
      orgKey: team.OrgKey,
    }
  }

const makeDisplayPatient =
  (allOrgs, allPatients) => (ps) => {
    const patient = allPatients[ps.PatientKey]
    return patient && {
      name: _nullishCoalesce(patient.Name, () => ( '')),
      orgName: patient && _optionalChain([allOrgs, 'access', _8 => _8[patient.OrgKey], 'optionalAccess', _9 => _9.Name]) || '',
      orgKey: patient.OrgKey,
    }
  }

 const selectDisplayChatParticipants = _reselect.createSelector.call(void 0, 
  _blobject3.selectBlobjectByKey,
  _org.selectOrgs,
  _peers.selectAllUsers,
  _peers.selectAllPatients,
  _team.selectAllTeams,
  (blobject, allOrgs, allUsers, allPatients, allTeams) => {
    if (!blobject) {
      return {
        users: [],
        teams: [],
        patients: [],
      }
    }
    const userSubs = Object.values(blobject.UserSubscribers)
    const teamSubs = Object.values(blobject.TeamSubscribers)
    const patientSubs = Object.values(blobject.PatientSubscribers)

    const users = _array.safeMapReduce(
      userSubs,
      makeDisplayUser(allOrgs, allUsers),
    )

    const teams = _array.safeMapReduce(
      teamSubs,
      makeDisplayTeam(allOrgs, allTeams),
    )

    const patients = _array.safeMapReduce(
      patientSubs,
      makeDisplayPatient(allOrgs, allPatients),
    )

    return { users, teams, patients }
  },
); exports.selectDisplayChatParticipants = selectDisplayChatParticipants
