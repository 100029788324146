"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxRightSide/Chat.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _error = require('shared/services/error');
var _noticeBar = require('web/services/context/noticeBar');



var _blobject = require('shared/selectors/blobject');

var _Header = require('web/components/RightSide/Header');
var _RightSide = require('web/components/RightSide');
var _ChatHeader = require('../ChatHeader'); var _ChatHeader2 = _interopRequireDefault(_ChatHeader);
var _ConversationScreen = require('web/screens/ConversationScreen'); var _ConversationScreen2 = _interopRequireDefault(_ConversationScreen);
var _ChatParticipantsList = require('web/screens/Inbox/ChatParticipantsList'); var _ChatParticipantsList2 = _interopRequireDefault(_ChatParticipantsList);





const Chat = ({
  blobjectKey,
  conversationKey,
  conversationType,
  isBlobjectLoaded,
  subscribersCount,
}) => {
  const [isRightPaneOpen, setIsRightPaneOpen] = _react.useState.call(void 0, false)
  const handleToggleRightPane = _react.useCallback.call(void 0, 
    () => { setIsRightPaneOpen(x => !x) },
    [setIsRightPaneOpen],
  )

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  _react.useEffect.call(void 0, 
    () => {
      if (!blobjectKey) return
      _actions2.default.getBlobjectDeep({ BKey: blobjectKey })
        .catch(e => showWarningBar(
          _error.isUnauthorizedError.call(void 0, e)
            ? "You don't have access to this chat"
            : 'Failed to load conversation. Please try again.',
        ))
    },
    [blobjectKey, showWarningBar],
  )

  _react.useEffect.call(void 0, 
    () => {
      // TODO: is this right?
      if (isBlobjectLoaded && !subscribersCount) _actions2.default.resumeLatestChat()
    },
    [isBlobjectLoaded, subscribersCount],
  )

  if (
    !blobjectKey ||
    !conversationType ||
    !conversationKey ||
    !isBlobjectLoaded
  ) return null

  return (
    _react2.default.createElement(_RightSide.RightSide, { inView: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      , _react2.default.createElement(_Header.RightSideHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        , _react2.default.createElement(_ChatHeader2.default, {
          blobjectKey: blobjectKey,
          rightPaneOpen: isRightPaneOpen,
          onToggleRightPane: handleToggleRightPane, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        )
      )
      , _react2.default.createElement(_RightSide.RightSideBody, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
        , _react2.default.createElement(_ConversationScreen2.default, {
          blobjectKey: blobjectKey,
          conversationKey: conversationKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        )
        , _react2.default.createElement(_RightSide.RightSidePane, { open: isRightPaneOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
          , _react2.default.createElement(_Header.RightSideHeader, { className: "blmh-desktop-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
            , _react2.default.createElement(_Header.HeaderMobileBackButton, { onClick: handleToggleRightPane, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )
            , _react2.default.createElement(_Header.RightSideHeaderHeading, { title: "Participants", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} )
          )
          , _react2.default.createElement(_ChatParticipantsList2.default, { blobjectKey: blobjectKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} )
        )
      )
    )
  )
}








const makeMapState = () => {
  const selectBlobjectSubscriberCounts = _blobject.makeSelectBlobjectSubscriberCounts.call(void 0, )

  return (state, { blobjectKey }) => {
    const { totalCount } = selectBlobjectSubscriberCounts(state, blobjectKey)
    const blobject = _blobject.selectBlobjectByKey.call(void 0, state, blobjectKey)

    return {
      subscribersCount: totalCount,
      isBlobjectLoaded: !!blobject,
      conversationKey: _optionalChain([blobject, 'optionalAccess', _ => _.WrappedKey]),
      conversationType: _optionalChain([blobject, 'optionalAccess', _2 => _2.WrappedType]),
    }
  }
}

exports. default = _reactredux.connect.call(void 0, makeMapState)(Chat)
