"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Desktop/SessionDetails.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _helpers = require('web/components/VideoRooms/Room/History/helpers');
var _LoadingSpinner = require('web/components/VideoRooms/Room/History/LoadingSpinner'); var _LoadingSpinner2 = _interopRequireDefault(_LoadingSpinner);
var _ParticipantDetails = require('./ParticipantDetails'); var _ParticipantDetails2 = _interopRequireDefault(_ParticipantDetails);

 class DesktopSessionDetails extends _react2.default.Component {constructor(...args) { super(...args); DesktopSessionDetails.prototype.__init.call(this);DesktopSessionDetails.prototype.__init2.call(this);DesktopSessionDetails.prototype.__init3.call(this); }
  __init() {this.tableFilters = {
    PARTICIPANT: 'participant',
    TIME: 'time',
    DURATION: 'duration',
    FEEDBACK: 'feedback',
  }}

  __init2() {this.state = {
    currentFilter: null,
    session: null,
  }}

  componentDidMount () {
    const { currentSessionKey: RoomSessionKey, roomKey: RoomKey } = this.props
    _actions2.default.fetchVideoRoomSession({ RoomKey, RoomSessionKey }).then(session =>
      this.setState({ session }),
    )
  }

  __init3() {this.toggleFilter = f => this.setState({
    currentFilter: this.state.currentFilter === f ? null : f,
  })}

  render () {
    const { gotoHistory } = this.props
    const { session } = this.state

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "meeting-room--history--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          , _react2.default.createElement('span', {
            onClick: gotoHistory,
            className: "meeting-room--history--header-back", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          , "Back to all"

          )
        )
        ,  session
          ? this.renderHistoryBody()
          : (
            _react2.default.createElement('div', { className: "meeting-room--history--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
              , _react2.default.createElement(_LoadingSpinner2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
            )
            )
        
      )
    )
  }

  renderHistoryBody () {
    const { roomName } = this.props
    const { currentFilter, session } = this.state

    const buildHeaderColClassName = (filter, className) => _classBuilder2.default.call(void 0, 
      "meeting--table-col",
      className,
      currentFilter === filter && 'active',
    )

    if (!session) return null

    const { startedAt, duration } =
      _helpers.formatTimingLabels.call(void 0, session.StartedAt, session.EndedAt)

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "meeting-room--history--summary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          , _react2.default.createElement('div', { className: "meeting-room--history--summary-row row-title" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , _react2.default.createElement('h4', { className: "meeting-room--history--summary-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}, "Meeting Title" )
            , _react2.default.createElement('span', { className: "meeting-room--history--summary-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
              ,  roomName 
            )
          )
          , _react2.default.createElement('div', { className: "meeting-room--history--summary-row row-time" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
            , _react2.default.createElement('h4', { className: "meeting-room--history--summary-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}, "Started")
            , _react2.default.createElement('span', { className: "meeting-room--history--summary-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, startedAt)
          )
          , _react2.default.createElement('div', { className: "meeting-room--history--summary-row row-duration" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            , _react2.default.createElement('h4', { className: "meeting-room--history--summary-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, "Total Duration" )
            , _react2.default.createElement('span', { className: "meeting-room--history--summary-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
              ,  duration 
            )
          )
        )
        , _react2.default.createElement('div', { className: "meeting--table meeting-room--history--details-table" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
          , _react2.default.createElement('div', { className: "meeting--table-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
            , _react2.default.createElement('div', { className: "meeting--table-row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
              , _react2.default.createElement('div', {
                onClick: () => this.toggleFilter(this.tableFilters.PARTICIPANT),
                className: buildHeaderColClassName(this.tableFilters.PARTICIPANT, "participant-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
              , "Participant")
              , _react2.default.createElement('div', {
                onClick: () => this.toggleFilter(this.tableFilters.TIME),
                className: buildHeaderColClassName(this.tableFilters.TIME, "time-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
              , "Joined")
              , _react2.default.createElement('div', {
                onClick: () => this.toggleFilter(this.tableFilters.DURATION),
                className: buildHeaderColClassName(this.tableFilters.DURATION, "duration-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
              , "Total Time" )
              , _react2.default.createElement('div', {
                onClick: () => this.toggleFilter(this.tableFilters.FEEDBACK),
                className: buildHeaderColClassName(this.tableFilters.FEEDBACK, "feedback-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
              , "Call Feedback" )
            )
          )
          , _react2.default.createElement('div', { className: "meeting--table-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
            , 
              session.RoomUsers.map((u, i) => {
                const { startedAt, duration } = _helpers.formatTimingLabels.call(void 0, u.InitialJoinedAt, u.LastDisconnectedAt)
                return (
                  _react2.default.createElement(_ParticipantDetails2.default, {
                    key: i,
                    participantName: u.RoomUserName,
                    startedAt: startedAt,
                    duration: duration,
                    rating: u.Rating, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
                  )
                )
              })
            
          )
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    gotoHistory: _proptypes2.default.func.isRequired,
  }}
} DesktopSessionDetails.__initStatic(); exports.default = DesktopSessionDetails;
