"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');








var _org = require('shared/selectors/org');
var _selectBlobjectSubscribersMap = require('./selectBlobjectSubscribersMap');
var _selectPopulatedBlobjectSubscribers = require('./selectPopulatedBlobjectSubscribers');
var _selectMyOrgKeyInConvo = require('shared/selectors/conversation/selectMyOrgKeyInConvo');

var _array = require('shared/services/array');
var _entity = require('shared/services/entity');







var _entities = require('shared/transforms/entities');

const makeTitleDesc = (orgName = '', title = '') => orgName && title
  ? `${orgName}, ${title}`
  : orgName

 const makeUserDescription = (userSubscribersMap) => (
  u,
  orgsMap = {},
) => {
  const orgKey = _optionalChain([userSubscribersMap, 'access', _ => _[u.UserKey], 'optionalAccess', _2 => _2.OrgKey])
  const orgName = orgKey && _optionalChain([orgsMap, 'access', _3 => _3[orgKey], 'optionalAccess', _4 => _4.Name]) || ''
  const title = orgKey && _optionalChain([u, 'access', _5 => _5.Relationships, 'access', _6 => _6[orgKey], 'optionalAccess', _7 => _7.Title]) || ''

  return makeTitleDesc(orgName, title) ||
    _entities.makeOrgTitleUserDescription.call(void 0, u, orgsMap)
}; exports.makeUserDescription = makeUserDescription

 const makeSelectChatParticipants = () => _toolkit.createSelector.call(void 0, 
  _selectPopulatedBlobjectSubscribers.makeSelectPopulatedBlobjectSubscribers.call(void 0, ),
  _selectBlobjectSubscribersMap.selectBlobjectSubscribersMap,
  _org.selectOrgs,
  _org.selectOrgsV2,
  _selectMyOrgKeyInConvo.selectMyOrgKeyInConvo,
  (
    allSubscribersList,
    allSubscribersMap,
    allOrgsV1,
    allOrgsV2,
    myOrgKey,
  ) => {
    const { users: userSubscribersMap } = allSubscribersMap
    const {
      userSubscribersList,
      teamSubscribersList,
      patientSubscribersList,
    } = allSubscribersList

    const allOrgs = { ...allOrgsV1, ...allOrgsV2 }

    const userEntitiesList = userSubscribersList
      .map(_entities.mapUserToEntity.call(void 0, allOrgs, exports.makeUserDescription.call(void 0, userSubscribersMap)))
      .sort(_entity.compareDisplayEntities)

    const teamsList = teamSubscribersList
      .map(_entities.mapTeamToEntity.call(void 0, allOrgs, _entities.makeOrgNameTeamDescription))
      .sort(_entity.compareDisplayEntities)

    const patientsList = patientSubscribersList
      .map(_entities.mapPatientToEntity.call(void 0, 
        allOrgs,
        _entities.makeOrgNamePatientDescription,
      ))
      .sort(_entity.compareDisplayEntities)

    const [internalUsersList, externalUsersList] =
      _array.splitBy.call(void 0, userEntitiesList, e => !!myOrgKey && _entity.isEntityInternal.call(void 0, e, myOrgKey))

    return {
      internalUsersList,
      externalUsersList,
      teamsList,
      patientsList,
    }
  },
); exports.makeSelectChatParticipants = makeSelectChatParticipants






const EMPTY_ALL_PARTICIPANTS = []
 const makeSelectAllChatParticipantsList = () => _toolkit.createSelector.call(void 0, 
  exports.makeSelectChatParticipants.call(void 0, ),
  ({
    internalUsersList,
    externalUsersList,
    teamsList,
    patientsList,
  }) => EMPTY_ALL_PARTICIPANTS
    .concat(internalUsersList)
    .concat(externalUsersList)
    .concat(teamsList)
    .concat(patientsList),
); exports.makeSelectAllChatParticipantsList = makeSelectAllChatParticipantsList
