"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _toolkit = require('@reduxjs/toolkit');


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);


const initialState = {}

exports. default = _toolkit.createReducer.call(void 0, 
  initialState,
  builder => {
    [
      _actionCreators2.default.updateNotificationAction,
      _actionCreators2.default.getBlobjectDeepActions.SUCCESS,
      _actionCreators2.default.addUsersToTeamActions.SUCCESS,
      _actionCreators2.default.createTeamActions.SUCCESS,
      _actionCreators2.default.updateTeamActions.SUCCESS,
      _actionCreators2.default.getTeamsActions.SUCCESS,
      _actionCreators2.default.getInboxActions.SUCCESS,
    ].forEach(t => builder.addCase(t, (s, a) => Object.assign(s, a.payload.Teams)))

    builder.addCase(_actionCreators2.default.removeTeamActions.SUCCESS, (s, a) => {
      delete s[a.meta.TeamKey]
    })

    builder.addCase(_actionCreators2.default.removeUserFromTeamActions.REQUEST, (s, a) => {
      const team = s[a.payload.TeamKey]
      if (team) delete team.Members[a.payload.UserKey]
    })

    builder.addCase(_actionCreators2.default.findTeamsInOrgActions.SUCCESS, (s, a) => {
      a.payload.Teams.forEach(t => s[t.TeamKey] = t)
    })

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)
  },
)
