"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Add.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





const AddBtn = ({ size = 34, className, ...props }) => (
  _react2.default.createElement('button', {
    ...props,
    'aria-label': "Add",
    className: _classBuilder2.default.call(void 0, "add-button", className),
    style: { width: size, height: size }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
  
    , _react2.default.createElement('img', { src: "/assets/icons/icon-add.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} )
  )
)

exports. default = AddBtn
