"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/PersonalInfo/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');





var _signup = require('shared/validation/signup');
var _Form = require('web/components/Form');
var _components = require('web/screens/Signup/components');








const makeDefaultValues = (welcomeUser) => ({
  firstName: _optionalChain([welcomeUser, 'optionalAccess', _ => _.FirstName]) || '',
  lastName: _optionalChain([welcomeUser, 'optionalAccess', _2 => _2.LastName]) || '',
  email: _optionalChain([welcomeUser, 'optionalAccess', _3 => _3.Email]) || '',
  phone: _optionalChain([welcomeUser, 'optionalAccess', _4 => _4.PhoneNumber]) || '',
})

const PersonalInfoSection = ({
  wasInvitedByPhone,
  onSubmit,
  welcomeUser,
  personalInfo,
}) => {
  const defaultValues = _react.useMemo.call(void 0, 
    () => personalInfo || makeDefaultValues(welcomeUser),
    [personalInfo, welcomeUser],
  )

  const { register, handleSubmit, formState, reset } = _reacthookform.useForm.call(void 0, {
    resolver: _signup.personalInfoResolver,
    mode: 'onChange',
    defaultValues,
  })
  const { isValid, errors } = formState

  _react.useEffect.call(void 0, () => reset(defaultValues), [reset, defaultValues])

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(onSubmit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "doctor",
        title: "Tell us a bit about yourself"     ,
        subtitle: "Enter your name and phone contact."     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        , _react2.default.createElement(_Form.InputSection, {
          autoFocus: true,
          autoComplete: "given-name",
          title: "First Name" ,
          placeholder: "E.g. John" ,
          inputProps: register('firstName'),
          errorMessage: _optionalChain([errors, 'access', _5 => _5.firstName, 'optionalAccess', _6 => _6.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        )
        , _react2.default.createElement(_Form.InputSection, {
          autoComplete: "family-name",
          title: "Last Name" ,
          placeholder: "E.g. Parker" ,
          inputProps: register('lastName'),
          errorMessage: _optionalChain([errors, 'access', _7 => _7.lastName, 'optionalAccess', _8 => _8.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        )
      )
      ,  wasInvitedByPhone
        ? _react2.default.createElement(_Form.InputSection, {
            title: "Email",
            autoComplete: "email",
            placeholder: "E.g. john@email.com" ,
            inputProps: register('email'),
            errorMessage: _optionalChain([errors, 'access', _9 => _9.email, 'optionalAccess', _10 => _10.message]),
            className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
          )
        : _react2.default.createElement(_Form.InputSection, {
            title: "Phone",
            desc: "We keep your number safe and just need it so your co-workers are able to message you through BloomText."                  ,
            autoComplete: "tel",
            placeholder: "E.g. 415-903-1200" ,
            inputProps: register('phone'),
            errorMessage: _optionalChain([errors, 'access', _11 => _11.phone, 'optionalAccess', _12 => _12.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          )
      
      , _react2.default.createElement(_components.SignupStepControls, { isNextDisabled: !isValid, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} )
    )
  )
}

exports. default = PersonalInfoSection
