"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Button/SplitMenu.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Tooltip = require('web/components/Tooltip');












const SplitMenu = ({ options, onClose }) => (
  _react2.default.createElement(_Tooltip.TooltipContent, {
    onClick: onClose,
    className: "tooltip-menu blm-btn--split-menu" ,
    role: "menu", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  
    ,  options.map((o, i) => (
      _react2.default.createElement('span', {
        key: i,
        role: "menuitem",
        onClick: o.action,
        className: "tooltip-menu--option blm-btn--split-menu--option" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      
        , _react2.default.createElement('img', { src: o.iconUrl, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
        ,  o.label 
      )
    ))
  )
)

exports. default = SplitMenu
