"use strict";Object.defineProperty(exports, "__esModule", {value: true});// This file is meant for managing state of everything that is related to a
// videocall between 2 users.
var _store = require('shared/store');

 const permsStates = {
  GRANTED: 'PERMS_GRANTED',
  DENIED: 'PERMS_DENIED',
  BLOCKED: 'PERMS_BLOCKED',
}; exports.permsStates = permsStates

 const types = {
  SET_IS_ON_CALL: 'SET_IS_ON_CALL',
  SET_PERMS_STATE: 'SET_PERMS_STATE',
  SET_BROWSER_COMPATIBILITY: 'SET_BROWSER_COMPATIBILITY',

  SET_CAM_STATUS: 'SET_CAM_STATUS',
  SET_MIC_STATUS: 'SET_MIC_STATUS',
  SET_SCREEN_SHARING_STATUS: 'SET_SCREEN_SHARING_STATUS',
  SET_CAM_ISSUES_STATE: 'SET_CAM_ISSUES_STATE',
  SET_MIC_ISSUES_STATE: 'SET_MIC_ISSUES_STATE',

  SET_DEVICES_LIST: 'SET_DEVICES_LIST',
  SET_CURRENT_CAM_DEVICE_ID: 'SET_CURRENT_CAM_DEVICE_ID',
  SET_CURRENT_MIC_DEVICE_ID: 'SET_CURRENT_MIC_DEVICE_ID',
  TOGGLE_MIC: 'TOGGLE_MIC',
  TOGGLE_CAM: 'TOGGLE_CAM',
  CYCLE_CAM: 'CYCLE_CAM',
}; exports.types = types

const setPermsGranted = () => _store.dispatch.call(void 0, {
  type: exports.types.SET_PERMS_STATE,
  payload: { state: exports.permsStates.GRANTED },
})

const setPermsDenied = () => _store.dispatch.call(void 0, {
  type: exports.types.SET_PERMS_STATE,
  payload: { state: exports.permsStates.DENIED },
})

const setPermsBlocked = () => _store.dispatch.call(void 0, {
  type: exports.types.SET_PERMS_STATE,
  payload: { state: exports.permsStates.BLOCKED },
})

const setBrowserCompatibility = isCompatible => _store.dispatch.call(void 0, {
  type: exports.types.SET_BROWSER_COMPATIBILITY,
  payload: { isCompatible },
})

const setCamStatus = ({ isOn }) => _store.dispatch.call(void 0, {
  type: exports.types.SET_CAM_STATUS,
  payload: { isOn },
})

const setMicStatus = ({ isOn }) => _store.dispatch.call(void 0, {
  type: exports.types.SET_MIC_STATUS,
  payload: { isOn },
})

const setScreenSharingStatus = ({ isOn }) => _store.dispatch.call(void 0, {
  type: exports.types.SET_SCREEN_SHARING_STATUS,
  payload: { isOn },
})

const setCurrentCamDeviceId = deviceId => _store.dispatch.call(void 0, {
  type: exports.types.SET_CURRENT_CAM_DEVICE_ID,
  payload: { deviceId },
})

const setCurrentMicDeviceId = deviceId => _store.dispatch.call(void 0, {
  type: exports.types.SET_CURRENT_MIC_DEVICE_ID,
  payload: { deviceId },
})

const setDevicesList = ({ audioIn, videoIn }) => _store.dispatch.call(void 0, {
  type: exports.types.SET_DEVICES_LIST,
  payload: {
    ...(audioIn && { audioIn }),
    ...(videoIn && { videoIn }),
  },
})

const setCamIssuesState = areIssuesPresent => _store.dispatch.call(void 0, {
  type: exports.types.SET_CAM_ISSUES_STATE,
  payload: { areIssuesPresent },
})

const setMicIssuesState = areIssuesPresent => _store.dispatch.call(void 0, {
  type: exports.types.SET_MIC_ISSUES_STATE,
  payload: { areIssuesPresent },
})

const cycleCam = () => _store.dispatch.call(void 0, { type: exports.types.CYCLE_CAM })
const toggleMic = () => _store.dispatch.call(void 0, { type: exports.types.TOGGLE_MIC })
const toggleCam = () => _store.dispatch.call(void 0, { type: exports.types.TOGGLE_CAM })

const setIsOnCall = isOnCall => _store.dispatch.call(void 0, {
  type: exports.types.SET_IS_ON_CALL, payload: isOnCall,
})

exports. default = {
  setPermsGranted,
  setPermsDenied,
  setPermsBlocked,
  setBrowserCompatibility,
  setCamStatus,
  setMicStatus,
  setScreenSharingStatus,
  setCurrentCamDeviceId,
  setCurrentMicDeviceId,
  setDevicesList,
  setMicIssuesState,
  setCamIssuesState,
  cycleCam,
  toggleMic,
  toggleCam,
  setIsOnCall,
}
