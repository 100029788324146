"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/BeginningMarker.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

const BeginningMarker = () => (
  _react2.default.createElement('div', { className: "conversation-box--beginning-marker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 4}}
    , _react2.default.createElement('img', {
      src: "/assets/icons/inbox/beginning-tick-grey.svg",
      className: "conversation-box--beginning-marker--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
    ), "This is the beginning of your chat."

  )
)

exports. default = BeginningMarker
