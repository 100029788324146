"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/DropdownParticipantsPicker/EntityAvatar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Props = require('web/components/Form/Props');






var _Avatar = require('web/components/Avatar'); var _Avatar2 = _interopRequireDefault(_Avatar);

const EntityAvatar = ({ avatar, entityType, size }) => {
  const render = Avatar => _react2.default.createElement(Avatar, { ...avatar, size: size, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} )

  if (!entityType) {
    // Let's take a guess...
    if (avatar.userKey) return render(_Avatar.UserAvatar)
    if (avatar.teamKey) return render(_Avatar.TeamAvatar)
    if (avatar.patientKey) return render(_Avatar.PatientAvatar)
    if (avatar.orgKey) return render(_Avatar.OrgAvatar)
    if (avatar.isAdd) return render(_Avatar.AddAvatar)
    return render(_Avatar2.default)
  }

  const entityAvatarsMap = {
    [_Props.ENTITY_TYPES.USER]: _Avatar.UserAvatar,
    [_Props.ENTITY_TYPES.NEWUSER]: _Avatar.UserAvatar,
    [_Props.ENTITY_TYPES.TEAM]: _Avatar.TeamAvatar,
    [_Props.ENTITY_TYPES.ORG]: _Avatar.OrgAvatar,
    [_Props.ENTITY_TYPES.PATIENT]: _Avatar.PatientAvatar,
    [_Props.ENTITY_TYPES.NEWPATIENT]: _Avatar.PatientAvatar,
    [_Props.ENTITY_TYPES.ADD]: _Avatar.AddAvatar,
  }

  return render(entityAvatarsMap[entityType] || _Avatar2.default)
}

exports. default = EntityAvatar
