"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _utils = require('shared/store/utils');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);






const initialState = {
  quickLoginUsers: {},
  oneSignalToken: null,
}

exports. default = _utils.createReducer.call(void 0, 
  initialState,
  builder => {
    builder
      .addCase(
        _actionCreators2.default.setOneSignalTokenAction,
        (s, a) => { s.oneSignalToken = a.payload.token },
      )
      .addCase(
        _actionCreators2.default.getQuickLoginUsersActions.SUCCESS,
        (s, a) => {
          const { Users } = a.payload
          if (Users) s.quickLoginUsers = Users
        },
      )

      .addCase(
        _actionCreators2.default.approveQuickLoginDeviceActions.SUCCESS,
        (state, _a, globalState) => {
          const me = globalState.currentUser
          const newUsers = state.quickLoginUsers
          if (me) newUsers[me.UserKey] = me
          state.quickLoginUsers = newUsers
        },
      )

      .addCase(
        _actionCreators2.default.disapproveQuickLoginDeviceActions.SUCCESS,
        (state, _a, globalState) => {
          const me = globalState.currentUser
          if (me) delete state.quickLoginUsers[me.UserKey]
        },
      )

      .addDefaultCase(s => s)
  })
