"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/PatientPage/InfoSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _time = require('shared/services/time');
var _phone = require('shared/services/phone');

var _Avatar = require('web/components/Avatar');
var _components = require('web/screens/Directory/components');












const PatientInformationSection = ({
  patientKey,
  patientName,
  orgName,
  dob,
  phone,
  email,
  foreignID,
  onEdit,
}) => (
  _react2.default.createElement('div', { className: "directory-page--info-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
    , _react2.default.createElement('div', { className: "directory-page--info-section--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      ,  patientName && (
        _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Name")
          , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, patientName)
        )
      )
      , _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, "Organization")
        , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, orgName)
      )
      ,  !_time.isDateNull.call(void 0, dob) &&
        _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
          , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "Date of Birth"  )
          , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, _time.formatDisplayDate.call(void 0, dob))
        )
      
      ,  phone &&
        _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "Phone")
          , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}, _phone.formatPhoneNumber.call(void 0, phone))
        )
      
      ,  email &&
        _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "Email")
          , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, email)
        )
      
      ,  foreignID && (
        _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
          , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "MRN")
          , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}},  foreignID )
        )
      )
      , _react2.default.createElement(_components.InfoBtn, {
        label: "Edit details" ,
        onClick: onEdit,
        iconSrc: "/assets/icons/icon-edit-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
    )
    , _react2.default.createElement(_Avatar.PatientAvatar, { size: 100, patientKey: patientKey, className: "directory-page--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}})
  )
)

exports. default = PatientInformationSection
