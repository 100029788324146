"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Name/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');

var _name = require('shared/validation/user/name');

var _Form = require('web/components/Form');
var _Subsection = require('./Subsection'); var _Subsection2 = _interopRequireDefault(_Subsection);












const NameSection = ({
  firstName = '',
  lastName = '',
  nameSuffix = '',
  onSubmit,
}) => {
  const defaultValues = _react.useMemo.call(void 0, 
    () => ({ firstName, lastName, suffix: nameSuffix }),
    [firstName, lastName, nameSuffix],
  )
  const { control, handleSubmit, formState, reset } =
    _reacthookform.useForm({
      resolver: _name.userNameResolver, mode: 'onChange', defaultValues,
    })

  _react.useEffect.call(void 0, () => { reset(defaultValues) }, [reset, defaultValues])

  const { isValid, isDirty, isSubmitting } = formState

  const submit = _react.useCallback.call(void 0, 
    async (data, e) => {
      _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])

      return onSubmit({
        firstName: data.firstName,
        lastName: data.lastName,
        nameSuffix: data.suffix,
      })
    },
    [onSubmit],
  )

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      , _react2.default.createElement(_Subsection2.default, {
        name: "firstName",
        label: "First Name" ,
        control: control,
        placeholder: "E.g. John" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      )
      , _react2.default.createElement(_Subsection2.default, {
        name: "lastName",
        label: "Last Name" ,
        control: control,
        placeholder: "E.g. Parker" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      )
      , _react2.default.createElement(_Subsection2.default, {
        name: "suffix",
        label: "Name Suffix" ,
        control: control,
        placeholder: "E.g. MD" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
      )
      , _react2.default.createElement(_Form.Button, {
        isSubmit: true,
        isLoading: isSubmitting,
        isDisabled: !isValid || !isDirty,
        className: "settings--profile--save-name-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
      , "Save"

      )
    )
  )
}

exports. default = NameSection
