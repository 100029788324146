"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/MessageSection/EditMessageModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');





var _message = require('shared/validation/team/autoReply/message');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');








const EditMessageModal = ({
  isOpen,
  onClose,
  defaultMessage,
  onSubmit,
}) => {
  const { control, formState, handleSubmit } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _message.autoReplyMessageResolver,
      defaultValues: { autoReplyMessage: defaultMessage },
    })
  const { isValid, isDirty, isSubmitting, errors } = formState

  const submit = _react.useCallback.call(void 0, 
    (data) => onSubmit(data.autoReplyMessage).then(onClose),
    [onSubmit, onClose],
  )

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "Edit message" ,
      className: "team-page--schedule--msg-modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
    
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        errorMessage: _optionalChain([errors, 'access', _ => _.autoReplyMessage, 'optionalAccess', _2 => _2.message]),
        className: "team-page--schedule--msg-modal--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      
        , _react2.default.createElement(_Form.SimpleTextAreaController, {
          autoFocus: true,
          name: "autoReplyMessage",
          control: control,
          maxLength: _message.MAX_AUTOREPLY_MSG_LENGTH, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Save",
        onPrimaryBtnClick: handleSubmit(submit),
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
      )
    )
  )
}

exports. default = EditMessageModal
