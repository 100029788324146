"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/EntityAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);










var _entity = require('shared/services/entity');

var _UserAvatar = require('./UserAvatar'); var _UserAvatar2 = _interopRequireDefault(_UserAvatar);
var _TeamAvatar = require('./TeamAvatar'); var _TeamAvatar2 = _interopRequireDefault(_TeamAvatar);
var _PatientAvatar = require('./PatientAvatar'); var _PatientAvatar2 = _interopRequireDefault(_PatientAvatar);
var _OrgAvatar = require('./OrgAvatar'); var _OrgAvatar2 = _interopRequireDefault(_OrgAvatar);

var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);























const isLegacyEntity = (e) =>
  !('wrappedEntity' in e)

const getUserKey = (e) => {
  if (isLegacyEntity(e)) return e.userKey || e.UserKey
  if (_entity.isEntityUser.call(void 0, e) || _entity.isEntityRelationship.call(void 0, e)) return e.wrappedEntity.UserKey
}
const getTeamKey = (e) => {
  if (isLegacyEntity(e)) return e.teamKey || e.TeamKey
  if (_entity.isEntityTeam.call(void 0, e)) return e.wrappedEntity.TeamKey
}
const getPatientKey = (e) => {
  if (isLegacyEntity(e)) return e.patientKey || e.PatientKey
  if (_entity.isEntityPatient.call(void 0, e)) return e.wrappedEntity.PatientKey
}
const getOrgKey = (e) => {
  if (isLegacyEntity(e)) return e.orgKey || e.OrgKey || e.OrganizationKey
  if (_entity.isEntityOrg.call(void 0, e)) return e.wrappedEntity.OrgKey
}

const EntityAvatar = ({
  entity: e,
  className,
  borderColor,
  size,
}) => {
  const commonProps = { className, borderColor, size }

  if (!e) return _react2.default.createElement(_BaseAvatar2.default, { ...commonProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} )

  const userKey = getUserKey(e)
  if (userKey) return _react2.default.createElement(_UserAvatar2.default, { userKey: userKey, ...commonProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}} )

  const teamKey = getTeamKey(e)
  if (teamKey) return _react2.default.createElement(_TeamAvatar2.default, { teamKey: teamKey, ...commonProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}} )

  const patientKey = getPatientKey(e)
  if (patientKey) return _react2.default.createElement(_PatientAvatar2.default, { patientKey: patientKey, ...commonProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} )

  const orgKey = getOrgKey(e)
  if (orgKey) return _react2.default.createElement(_OrgAvatar2.default, { orgKey: orgKey, ...commonProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )

  return _react2.default.createElement(_BaseAvatar2.default, { ...commonProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
}

exports. default = EntityAvatar
