"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Sidebar/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _myRelations = require('shared/selectors/myRelations');
var _session = require('shared/selectors/session');

var _List = require('web/screens/App/List');
var _BroadcastListHeader = require('./BroadcastListHeader'); var _BroadcastListHeader2 = _interopRequireDefault(_BroadcastListHeader);
var _CampaignsList = require('./CampaignsList'); var _CampaignsList2 = _interopRequireDefault(_CampaignsList);
var _ListMenuNotice = require('web/components/Upsells/ListMenuNotice'); var _ListMenuNotice2 = _interopRequireDefault(_ListMenuNotice);





const onCreateNewCampaign = (data) => _actions2.default.createCampaign(data)
  .then(({ CampaignKey }) => _actions2.default.gotoCampaignPage(CampaignKey))

const BroadcastSidebar = ({
  selectedTeamKey,
  doIHaveAnyPhoneTeams,
  hasFeature,
}) => (
  _react2.default.createElement(_List.List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    , _react2.default.createElement(_BroadcastListHeader2.default, {
      selectedTeamKey: selectedTeamKey,
      onSelectTeam: _actions2.default.setSelectedTeam,
      onCreateNewCampaign: onCreateNewCampaign,
      doIHaveAnyPhoneTeams: doIHaveAnyPhoneTeams,
      hasFeature: hasFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
    )
    ,  hasFeature
      ? _react2.default.createElement(_CampaignsList2.default, { teamKey: selectedTeamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}} )
      : _react2.default.createElement(_ListMenuNotice2.default, {
          desc: "Upgrade to send broadcast messages."    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        )
    
  )
)






const mapState = (state) => ({
  doIHaveAnyPhoneTeams: _myRelations.selectDoIHaveAnyPhoneTeams.call(void 0, state),
  selectedTeamKey: _session.selectSelectedTeamKey.call(void 0, state) || null,
})

exports. default = _reactredux.connect.call(void 0, mapState)(BroadcastSidebar)
