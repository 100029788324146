"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _redux = require('redux');
var _utils = require('shared/store/utils');
var _store = require('shared/store');
var _uuid = require('shared/services/uuid');

 const types = {
  CLOSE_SNACKBAR_BY_ID: 'CLOSE_SNACKBAR_BY_ID',
  PUSH_SNACKBAR: 'PUSH_SNACKBAR',
}; exports.types = types






 const pushSnackbar = _utils.createAction.call(void 0, 
  'PUSH_SNACKBAR',
  (type) => ({
    payload: { type, id: _uuid.uuidv4.call(void 0, ) },
  }),
); exports.pushSnackbar = pushSnackbar

 const closeSnackbarById = _utils.createAction.call(void 0, 
  'CLOSE_SNACKBAR_BY_ID',
  (id) => ({
    type: exports.types.CLOSE_SNACKBAR_BY_ID,
    payload: { id },
  }),
); exports.closeSnackbarById = closeSnackbarById

exports. default = _redux.bindActionCreators.call(void 0, 
  { pushSnackbar: exports.pushSnackbar, closeSnackbarById: exports.closeSnackbarById },
  _store.dispatch,
)
