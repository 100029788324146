"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Header/SettingsModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _TeamItem = require('./TeamItem'); var _TeamItem2 = _interopRequireDefault(_TeamItem);








const MAX_TEAMS_PER_ORG = 3



const SettingsModal = ({
  isOpen,
  onClose,
  inboxSettings,
  onToggle,
}) => {
  const [expandedOrgs, setExpandedOrgs] = _react.useState({})
  const onToggleExpansion = _react.useCallback.call(void 0, 
    (orgKey, isOpen) =>
      setExpandedOrgs(os => ({ ...os, [orgKey]: isOpen })),
    [],
  )

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "Manage My Inbox"  ,
      size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
    
      , _react2.default.createElement('span', { className: "inbox-modal--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, "Show chats from..."  )
      ,  inboxSettings.map(org => {
        const isLongList = org.teams.length > MAX_TEAMS_PER_ORG
        const isExpanded = isLongList && !!expandedOrgs[org.orgKey]

        const teams = isExpanded
          ? org.teams
          : org.teams.slice(0, MAX_TEAMS_PER_ORG)

        return (
          _react2.default.createElement('div', { key: org.orgKey, className: "inbox-modal--team-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , _react2.default.createElement('span', { className: "inbox-modal--team-section--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}},  org.orgName )
            ,  teams.map(t => (
              _react2.default.createElement(_TeamItem2.default, {
                key: t.teamKey,
                teamName: t.teamName,
                isInboxable: t.isInboxable,
                onToggle: (isOn) => onToggle(t.teamKey, isOn), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
              )
            ))
            ,  isLongList && (
              _react2.default.createElement('div', {
                role: "button",
                className: "inbox-modal--team-section--view-toggle",
                onClick: () => onToggleExpansion(org.orgKey, !isExpanded), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
              
                ,  isExpanded ? 'Show less' : 'Show more' 
              )
            )
          )
        )
      })
    )
  )
}

exports. default = SettingsModal
