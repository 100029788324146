"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Popup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

class Popup extends _react.Component {constructor(...args) { super(...args); Popup.prototype.__init.call(this);Popup.prototype.__init2.call(this);Popup.prototype.__init3.call(this);Popup.prototype.__init4.call(this); }
  componentDidMount () {
    window.addEventListener('click', this.handleWindowClick)
  }

  componentWillUnmount () {
    window.removeEventListener('click', this.handleWindowClick)
  }

  __init() {this.handleWindowClick = (evt) => {
    this.props.onClose()
  }}

  __init2() {this.preventClose = (evt) => {
    evt.stopPropagation()
  }}

  __init3() {this.generateBtnClass = (btnOption) => {
    return _classBuilder2.default.call(void 0, 'popup--button', {
      'popup--button-enabled': !btnOption.disabled,
      'popup--button-disabled': btnOption.disabled,
    })
  }}

  __init4() {this.onClick = (btnOption) => {
    if (!btnOption.disabled) {
      this.props.onClick(btnOption.id)
    }
  }}

  render () {
    if (!this.props.open) {
      return null
    }

    return (
      _react2.default.createElement('div', { className: "blmh-popup--screen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , _react2.default.createElement('div', { className: "blmh-popup--container", style: { top: this.props.top, left: this.props.left }, onClick: this.preventClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          , this.props.title && _react2.default.createElement('div', { className: "popup--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, this.props.title)

          , this.props.children

          /* Tyler jesus christ why not just pass that as a child */
          , this.props.buttonOptions.map(b =>
            (_react2.default.createElement('div', { key: b.id, className: this.generateBtnClass(b), onClick: () => this.onClick(b), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
              , _react2.default.createElement('div', { className: "popup--button-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, b.text)
            )))

        )
      )
    )
  }
}

Popup.propTypes = {
  open: _proptypes2.default.bool.isRequired,
  left: _proptypes2.default.number.isRequired,
  top: _proptypes2.default.number.isRequired,
  onClose: _proptypes2.default.func.isRequired,

  title: _proptypes2.default.string,

  /* Tyler jesus christ why not just pass that as a child */
  buttonOptions: _proptypes2.default.arrayOf(_proptypes2.default.shape({
    id: _proptypes2.default.string.isRequired,
    text: _proptypes2.default.string.isRequired,
    disabled: _proptypes2.default.bool,
  })),
  onClick: _proptypes2.default.func,
}

Popup.defaultProps = {
  buttonOptions: [],
}

exports. default = Popup
