"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/Controls.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');











const Controls = ({
  primaryLabel,
  secondaryLabel,
  secondaryIcon,
  onPrimaryClick,
  isPrimaryLoading,
  isPrimaryDisabled,
  onSecondaryClick,
}) => (
  _react2.default.createElement('div', { className: "plan-modal--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    ,  onSecondaryClick && (
      _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onSecondaryClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}},  secondaryIcon , " " ,  secondaryLabel )
    )
    , _react2.default.createElement(_Form.Button, {
      isLoading: isPrimaryLoading,
      isDisabled: isPrimaryDisabled,
      onClick: onPrimaryClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    
      ,  primaryLabel 
    )
  )
)

exports. default = Controls
