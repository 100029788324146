"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');


var _common = require('shared/selectors/blobject/common');
var _user = require('shared/selectors/user');

const EMPTY_LIST = []
 const selectMyBlobjectTeamSubscribers = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _user.selectCurrentUserV2,
  (blobject, me) => blobject
    ? Object.values(blobject.TeamSubscribers).filter(ts => me.Teams[ts.TeamKey])
    : EMPTY_LIST,
); exports.selectMyBlobjectTeamSubscribers = selectMyBlobjectTeamSubscribers
