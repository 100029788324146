"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/InfoTab/Form/Section.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Form = require('web/components/Form');












const FormSection = ({
  title,
  autoFocus,
  isEditable,
  defaultValue,
  placeholder,
  inputProps,
  inputType = 'text',
  errorMessage,
}) => isEditable
  ? _react2.default.createElement(_Form.InputSection, {
      autoFocus: autoFocus,
      title: title,
      placeholder: placeholder,
      inputProps: inputProps,
      inputType: inputType,
      errorMessage: errorMessage,
      className: "settings--org-info--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    )
  : _react2.default.createElement(_Form.InputSectionWrapper, {
      title: title,
      className: "settings--org-info--section--readonly", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
    
      , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}},  defaultValue || '-' )
    )

exports. default = FormSection
