"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/ToolBar/SearchBar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _types = require('../../types');








const SearchBar = ({
  searchQuery,
  setSearchQuery,
  pendingFilter,
  setPendingFilter,
}) => (
  _react2.default.createElement('div', { className: "settings--org-members--search-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    ,  pendingFilter === _types.PendingFilter.PENDING && (
      _react2.default.createElement('div', { className: "settings--org-members--filter-pill", role: "note", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, "Pending"

        , _react2.default.createElement('div', {
          role: "button",
          className: "settings--org-members--filter-pill-icon",
          onClick: () => setPendingFilter(_types.PendingFilter.ALL), __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        )
      )
    )
    , _react2.default.createElement('div', { className: "settings--org-members--search-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
    , _react2.default.createElement('input', {
      value: searchQuery,
      onChange: e => setSearchQuery(e.target.value),
      placeholder: "Search for members"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
    )
  )
)

exports. default = SearchBar
