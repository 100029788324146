"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoMeeting/VideoCallOverlay.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _lodash = require('lodash');
var _utils = require('shared/utils');

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _OnCall = require('web/screens/VideoCall/OnCall'); var _OnCall2 = _interopRequireDefault(_OnCall);

// Quadrants map: 1 | 2
//                --+--
//                3 | 4

 class VideoCallOverlay extends _react2.default.Component {constructor(...args) { super(...args); VideoCallOverlay.prototype.__init.call(this);VideoCallOverlay.prototype.__init2.call(this);VideoCallOverlay.prototype.__init3.call(this);VideoCallOverlay.prototype.__init4.call(this);VideoCallOverlay.prototype.__init5.call(this);VideoCallOverlay.prototype.__init6.call(this);VideoCallOverlay.prototype.__init7.call(this);VideoCallOverlay.prototype.__init8.call(this);VideoCallOverlay.prototype.__init9.call(this);VideoCallOverlay.prototype.__init10.call(this);VideoCallOverlay.prototype.__init11.call(this);VideoCallOverlay.prototype.__init12.call(this);VideoCallOverlay.prototype.__init13.call(this);VideoCallOverlay.prototype.__init14.call(this);VideoCallOverlay.prototype.__init15.call(this); }
  __init() {this.state = {
    isDragging: false,
    floatingPosition: {},
    snappedQuadrant: 4,
  }}

  __init2() {this.handleMinimize = () => this.props.handleMinimization(true)}
  __init3() {this.handleMaximize = () => {
    const { isMinimized, handleMinimization } = this.props
    if (isMinimized && !this.state.isDragging) handleMinimization(false)
  }}

  __init4() {this.wrapperRef = null}
  __init5() {this.setWrapperRef = r => this.wrapperRef = r}
  __init6() {this.wrapperBounds = null}
  __init7() {this.setWrapperBounds = () => this.wrapperBounds =
    this.wrapperRef && this.wrapperRef.getBoundingClientRect()}

  __init8() {this.getWrapperWidth = () => !this.wrapperBounds
    ? 0
    : this.wrapperBounds.right - this.wrapperBounds.left}

  __init9() {this.getWrapperHeight = () => !this.wrapperBounds
    ? 0
    : this.wrapperBounds.bottom - this.wrapperBounds.top}

  __init10() {this.startDragging = e => {
    e.persist()
    this.setWrapperBounds()
    setTimeout(() => {
      if (!this.props.isMinimized) return
      this.setState({ isDragging: true })
      this.updatePosition(e)
    }, 300)
  }}

  __init11() {this.updatePosition = ({ clientX, clientY }) => {
    if (!this.wrapperRef) return

    const wrapperWidth = this.getWrapperWidth()
    const wrapperHeight = this.getWrapperHeight()
    const { innerWidth, innerHeight } = window

    const maxLeft = innerWidth - wrapperWidth - 10
    const maxTop = innerHeight - wrapperHeight - 10

    const floatingPosition = {
      left: _utils.clamp.call(void 0, 10, maxLeft)(clientX - wrapperWidth / 2),
      top: _utils.clamp.call(void 0, 10, maxTop)(clientY - wrapperHeight / 2),
    }
    this.setState({ floatingPosition })
  }}

  __init12() {this.drag = _lodash.throttle.call(void 0, this.updatePosition, 50)}

  __init13() {this.releaseDrag = () => {
    const { floatingPosition } = this.state
    const { innerWidth, innerHeight } = window
    const wrapperWidth = this.getWrapperWidth()
    const wrapperHeight = this.getWrapperHeight()

    let snappedQuadrant = 1

    if (floatingPosition.left + wrapperWidth / 2 < innerWidth / 2) {
      if (floatingPosition.top + wrapperHeight / 2 < innerHeight / 2) { snappedQuadrant = 1 } else snappedQuadrant = 3
    } else {
      if (floatingPosition.top + wrapperHeight / 2 < innerHeight / 2) { snappedQuadrant = 2 } else snappedQuadrant = 4
    }

    setTimeout(() =>
      this.setState({
        isDragging: false,
        floatingPosition: {},
        snappedQuadrant,
      })
    , 50)
  }}

  __init14() {this.componentDidUpdate = (prevProps, prevState) => {
    const { isDragging } = this.state
    const { isMinimized } = this.props
    if (
      prevState.isDragging === isDragging &&
      prevProps.isMinimized === isMinimized
    ) return

    if (isDragging) {
      document.addEventListener('mousemove', this.drag)
      document.addEventListener('mouseup', this.releaseDrag)
    } else {
      document.removeEventListener('mousemove', this.drag)
      document.removeEventListener('mouseup', this.releaseDrag)
    }
  }}

  __init15() {this.componentWillUnmount = () => {
    document.removeEventListener('mousemove', this.drag)
    document.removeEventListener('mouseup', this.releaseDrag)
  }}

  render () {
    const { isDragging, floatingPosition, snappedQuadrant } = this.state
    const {
      showNoticeBar,
      openMobileMoreOptionsModal,
      openSettingsModal,
      isNetstatsModalOpen,
      openNetstatsModal,
      closeNetstatsModal,
      onRoomLinkCopy,
      onRoomShare,
      isMinimizable,
      isMinimized,
      onCallJoinFailed,
      onGracefulExit,
      onForcefulExit,
    } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, 
      "video-call--floating-wrapper",
      { mini: isMinimized, dragging: isDragging },
      isMinimized && !isDragging && {
        'quadrant--top-left': snappedQuadrant === 1,
        'quadrant--top-right': snappedQuadrant === 2,
        'quadrant--bottom-left': snappedQuadrant === 3,
        'quadrant--bottom-right': snappedQuadrant === 4,
      },
    )

    const minimizedProps = isMinimized &&
      { onMouseDown: this.startDragging }
    const floatingProps = isMinimized && isDragging
      ? { style: floatingPosition }
      : { onClick: this.handleMaximize }

    return (
      _react2.default.createElement('div', {
        ref: this.setWrapperRef,
        className: rootClassName,
        ...floatingProps,
        ...minimizedProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
      
        , _react2.default.createElement(_OnCall2.default, {
          showNoticeBar: showNoticeBar,
          openMobileMoreOptionsModal: openMobileMoreOptionsModal,
          openSettingsModal: openSettingsModal,
          isNetstatsModalOpen: isNetstatsModalOpen,
          openNetstatsModal: openNetstatsModal,
          closeNetstatsModal: closeNetstatsModal,
          onRoomLinkCopy: onRoomLinkCopy,
          onRoomShare: onRoomShare,
          isMinimized: isMinimized,
          isMinimizable: isMinimizable,
          onMinimize: this.handleMinimize,
          onCallJoinFailed: onCallJoinFailed,
          onGracefulExit: onGracefulExit,
          onForcefulExit: onForcefulExit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
        )
      )
    )
  }
} exports.default = VideoCallOverlay;
