"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Sidebar/BroadcastListHeader/TeamPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _team = require('shared/selectors/team');

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _Tooltip = require('web/components/Tooltip');
var _Content = require('./Content'); var _Content2 = _interopRequireDefault(_Content);







const offset = { top: 5 }

const TeamPicker = ({
  selectedTeamKey,
  teamName,
  onSelectTeam,
  doIHaveAnyPhoneTeams,
}) => {
  const {
    isOpen: isTeamPickerOpen,
    onOpen: onOpenTeamPicker,
    onClose: onCloseTeamPicker,
  } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      hideArrow: true,
      isDismissibleByClick: true,
      isDismissibleByClickElsewhere: true,
      place: "bottom-left",
      offset: offset,
      isOpen: isTeamPickerOpen,
      onClose: onCloseTeamPicker,
      content: 
        _react2.default.createElement(_Content2.default, {
          selectedTeamKey: selectedTeamKey,
          onSelectTeam: onSelectTeam, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
    
      , _react2.default.createElement('div', {
        onClick: doIHaveAnyPhoneTeams ? onOpenTeamPicker : undefined,
        className: "broadcast--list-header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      
        , _react2.default.createElement('div', { className: "broadcast--list-header--logo-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
          , _react2.default.createElement('div', { className: "broadcast--list-header--logo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
            ,  selectedTeamKey
              ? _react2.default.createElement('img', { src: "/assets/icons/inbox/team.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
              : _react2.default.createElement('img', { src: "/assets/icons/broadcast/broadcast-purple.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )
            
          )
        )
        , _react2.default.createElement('span', { role: "button", className: "broadcast--list-header--title-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          ,  teamName || 'All Campaigns' 
        )
      )
    )
  )
}



const mapState = (
  state,
  { selectedTeamKey },
) => ({
  teamName: selectedTeamKey
    ? _nullishCoalesce(_optionalChain([_team.selectTeamByKey.call(void 0, state, selectedTeamKey), 'optionalAccess', _ => _.Name]), () => ( ''))
    : '',
})

exports. default = _reactredux.connect.call(void 0, mapState)(TeamPicker)
