"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/PageLayout.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Header = require('web/components/RightSide/Header');
var _RightSide = require('web/components/RightSide');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const PageLayout = ({
  title, children, bodyClassName,
  onBack, isBackBtnVisibleOnDesktop,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_Header.RightSideHeader, { className: "settings-page--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      ,  onBack &&
        _react2.default.createElement('div', {
          className: _classBuilder2.default.call(void 0, 
            "settings-page--back-btn",
            !isBackBtnVisibleOnDesktop && "blmh-desktop-hide",
          ),
          onClick: onBack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        )
      
      ,  title 
    )
    , _react2.default.createElement(_RightSide.RightSideBody, { className: _classBuilder2.default.call(void 0, "settings-page--body", bodyClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      ,  children 
    )
  )
)

exports. default = PageLayout
