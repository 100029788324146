"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');


var _team = require('shared/selectors/team');
var _user = require('shared/selectors/user');
var _common = require('shared/selectors/blobject/common');

 const selectMyChatSubscriber = _toolkit.createSelector.call(void 0, 
  _user.selectCurrentUserV2, _common.selectBlobjectByKey, _team.selectTeams,
  (currentUser, blobject, teams) => {
    if (!blobject) return null

    // Am I one of the UserSubscribers?
    const userSubscribers = blobject.UserSubscribers || {}
    const myUserSubscriber = userSubscribers[currentUser.UserKey]
    if (myUserSubscriber) return myUserSubscriber

    // ... or one of the members of TeamSubscribers?
    const teamSubscribers = blobject.TeamSubscribers || {}
    const { Teams: myTeams } = currentUser
    const teamKeys = Object.keys(teamSubscribers) 
    const myTeamKey = teamKeys.find(tk => myTeams[tk])
    if (!myTeamKey) return null
    const myTeamSubscriber = teams[myTeamKey]
    if (myTeamSubscriber) return myTeamSubscriber

    return null // ¯\_(.-.)_/¯
  },
); exports.selectMyChatSubscriber = selectMyChatSubscriber
