"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; } class RestError extends Error {
  constructor (method, url, response) {
    super(`${method} ${url} failed`)
    this.method = method
    this.url = url
    this.response = response
  }
} exports.RestError = RestError;

 class BlmError extends Error {
  constructor (msg, extra, err) {
    if (err instanceof Error) super(msg, { cause: err })
    else super(msg)

    this.extra = { ..._optionalChain([err, 'optionalAccess', _ => _.extra]), ...extra }
  }
} exports.BlmError = BlmError;

 class CommandError extends BlmError {
  constructor (cmdName, err) {
    const msg = `${cmdName} rejected`
    if (err instanceof Error) super(msg, {}, err)
    else super(msg, err)
  }
} exports.CommandError = CommandError;
