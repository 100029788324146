"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/AvatarPicker/Form/DefaultAvatarsList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _const = require('./const');






const DefaultAvatarsList = ({
  onSubmit,
  onUploadCustom,
}) => (
  _react2.default.createElement('div', { className: "avatar-picker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
    , _react2.default.createElement('div', {
      role: "button",
      onClick: onUploadCustom,
      className: "avatar-picker--option avatar-picker--option--custom" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    
      , _react2.default.createElement('img', {
        src: "/assets/icons/ext-link-grey.svg",
        className: "avatar-picker--option--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      )
      , _react2.default.createElement('span', { className: "avatar-picker--option--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "Upload")
    )
    ,  Object.keys(_const.defaultAvatars)
      .map(k => {
        const ref = _const.defaultAvatars[k ]
        return (
          _react2.default.createElement('div', {
            role: "button",
            key: k,
            className: "avatar-picker--option",
            onClick: () => onSubmit(ref), __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          
            , _react2.default.createElement('img', {
              src: ref,
              className: "avatar-picker--preview",
              alt: k, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
            )
          )
        )
      })
    
  )
)

exports. default = DefaultAvatarsList
