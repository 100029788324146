"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembershipTab/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _reactredux = require('react-redux');






var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');
var _org = require('shared/selectors/org');

var _Org = require('web/components/Settings/Org');
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);






const MembershipTab = ({
  orgKey,
  orgName,
  rel,
  myUserKey,
  gotoOrgInfo,
}) => {
  const onLeave = _react.useCallback.call(void 0, 
    () => _actions2.default.leaveOrg({ OrgKey: orgKey })
      .then(() => gotoOrgInfo(orgKey)),
    [orgKey, gotoOrgInfo],
  )
  const onSubmit = _react.useCallback.call(void 0, 
    data => _actions2.default.changeUserOrgTitle({
      UserKey: myUserKey,
      OrgKey: orgKey,
      NewTitle: data.title,
    }),
    [orgKey, myUserKey],
  )

  if (!rel) return null
  if (_optionalChain([rel, 'optionalAccess', _ => _.Pending])) return _react2.default.createElement(_react2.default.Fragment, null, "Your request to join "    ,  orgName , " is pending."  )

  return (
    _react2.default.createElement(_Org.TabWrapper, { classNameSuffix: "membership", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , _react2.default.createElement(_Form2.default, {
        relTitle: rel.Title,
        onSubmit: onSubmit,
        onLeave: onLeave,
        orgName: orgName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
    )
  )
}







const mapState = (state, { orgKey }) => ({
  rel: _user.selectMyRelationshipByKey.call(void 0, state, orgKey),
  orgName: _nullishCoalesce(_optionalChain([_org.selectOrgByKey.call(void 0, orgKey, state), 'optionalAccess', _2 => _2.Name]), () => ( '')),
  myUserKey: _user.selectCurrentUserKey.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(MembershipTab)
