"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');
var _common = require('shared/selectors/blobject/common');
var _peers = require('shared/selectors/peers');
var _team = require('shared/selectors/team');






const EMPTY_SUBSCRIBERS_MAP = {
  users: {} ,
  teams: {} ,
  patients: {} ,
}

 const selectBlobjectSubscribersMap = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _peers.selectAllUsers,
  _team.selectAllTeams,
  (blobject, allUsers, allTeams) => {
    if (!blobject) return EMPTY_SUBSCRIBERS_MAP
    const {
      UserSubscribers: users = {},
      TeamSubscribers: teams = {},
      PatientSubscribers: patients = {},
    } = blobject

    const teamsList = Object.values(teams)
    if (!teamsList.length) return { users, teams, patients }

    const allUsersList = Object.values(allUsers)

    const teamUsersMap = teamsList.reduce(
      (acc, t) => {
        if (!allTeams[t.TeamKey]) return acc
        allUsersList.forEach(
          u => {
            if (!_optionalChain([u, 'optionalAccess', _ => _.Teams, 'optionalAccess', _2 => _2[t.TeamKey]]) || acc[u.UserKey]) return
            acc[u.UserKey] = {
              UserKey: u.UserKey,
              OrgKey: allTeams[t.TeamKey].OrgKey,
              UpdatedAt: t.UpdatedAt,
            }
          },
        )
        return acc
      },
      {} ,
    )

    return { users: { ...users, ...teamUsersMap }, teams, patients }
  },
); exports.selectBlobjectSubscribersMap = selectBlobjectSubscribersMap
