"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Modals/RemoveCallParticipant.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

exports. default = ({
  isOpen,
  onClose,
  participantName,
  onProceed,
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    title: "Remove Participant" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
  
    , _react2.default.createElement(_Modal.ModalDesc, { className: "meeting--remove-participant-modal--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, "Are you sure you want to remove "
             , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, participantName), "?"
    )
    , _react2.default.createElement(_Modal.ModalControls, {
      primaryBtnLabel: "Remove",
      isPrimaryEnabled: true,
      onPrimaryBtnClick: onProceed,
      secondaryBtnLabel: "Cancel",
      onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    )
  )
)
