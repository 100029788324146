"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/UserAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _peers = require('shared/selectors/peers');
var _user = require('shared/selectors/user');



var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);
 const FALLBACK_ICON_ALT_TEXT = 'User'; exports.FALLBACK_ICON_ALT_TEXT = FALLBACK_ICON_ALT_TEXT











const UserAvatar = ({
  userKey,
  profilePicture,
  isOnline,
  noOnlineIndicator,
  participantNumber,
  className,
  size,
  borderColor,
  style,
}) => (
  _react2.default.createElement(_BaseAvatar2.default, {
    profilePicture: profilePicture,
    icon: "/assets/icons/avatar/icon-doctor.svg",
    iconAltText: exports.FALLBACK_ICON_ALT_TEXT,
    className: className,
    participantNumber: participantNumber,
    backgroundColorKey: userKey,
    isOnline: !noOnlineIndicator && isOnline,
    borderColor: borderColor,
    size: size,
    style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
  )
)






const mapState = (state, { userKey }) => {
  const user = _peers.selectUserByKey.call(void 0, state, userKey)
  if (!user) return { profilePicture: null, isOnline: false }

  const myUserKey = _user.selectCurrentUserKey.call(void 0, state)

  return {
    profilePicture: 'ProfilePicture' in user
      ? user.ProfilePicture
      : user.ProfilePictureRef,
    isOnline: user.UserKey !== myUserKey && user.Online,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(UserAvatar)
