"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');





var _common = require('shared/selectors/blobject/common');
var _peers = require('shared/selectors/peers');







const EMPTY_MAP = {}

 const selectBlobjectSubscribers = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _peers.selectAllUsers,
  _peers.selectAllPatients,
  (blobject, allUsers, allPatients) => {
    if (!blobject) return EMPTY_MAP
    const userKeys = Object.keys(blobject.UserSubscribers)
    const patientKeys = Object.keys(blobject.PatientSubscribers)
    const teamSubscribers = blobject.TeamSubscribers

    const subscribers = {}
    userKeys.forEach(userKey => {
      const uk = userKey 
      if (!allUsers[uk]) return
      subscribers[uk] = { SubscriberKey: uk, ...allUsers[uk] }
    })
    patientKeys.forEach(patientKey => {
      const pk = patientKey 
      if (!allPatients[pk]) return
      subscribers[pk] = { SubscriberKey: pk, ...allPatients[pk] }
    })

    const teamSubscribersList = Object.values(teamSubscribers)

    if (teamSubscribersList.length) {
      const allUsersList = Object.values(allUsers)
      teamSubscribersList.forEach(t =>
        allUsersList.forEach(u => {
          if (!_optionalChain([u, 'optionalAccess', _ => _.Teams, 'optionalAccess', _2 => _2[t.TeamKey]])) return
          subscribers[u.UserKey] = { SubscriberKey: u.UserKey, ...u }
        }),
      )
    }

    return subscribers
  },
); exports.selectBlobjectSubscribers = selectBlobjectSubscribers
