"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/RightSide/Header/RightSideHeaderHeading.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const RightSideHeaderHeading = ({
  className,
  title,
  subtitle,
  titleBadge,
  ...props
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, "right-side-header--heading", className)

  return (
    _react2.default.createElement('div', { className: rootClassName, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      ,  title &&
        _react2.default.createElement('div', { className: "right-side-header--heading--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          ,  title 
          ,  titleBadge 
        )
      ,  subtitle &&
        _react2.default.createElement('div', { className: "right-side-header--heading--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
          ,  subtitle 
        )
    )
  )
}

exports. default = RightSideHeaderHeading
