"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');





var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useEntityPreload = require('shared/hooks/useEntityPreload'); var _useEntityPreload2 = _interopRequireDefault(_useEntityPreload);
var _subscriptionRedirect = require('web/services/subscriptionRedirect');
var _noticeBar = require('web/services/context/noticeBar');

 const usePlanDetails = (orgKey) => {
  const [planDetails, setPlanDetails] = _react.useState(null)
  const loadPlanDetails = _react.useCallback.call(void 0, 
    () => _actions2.default.getSubscription({ OrgKey: orgKey }).then(setPlanDetails),
    [orgKey],
  )
  const hasLoadFailed = _useEntityPreload2.default.call(void 0, loadPlanDetails)
  const itemsMap = _react.useMemo.call(void 0, 
    () => (_optionalChain([planDetails, 'optionalAccess', _ => _.Items]) || []).reduce(
      (acc, f) => {
        if (!acc[f.Type] || f.Enabled) acc[f.Type] = f
        return acc
      },
      {} ,
    ),
    [planDetails],
  )

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onManage = _react.useCallback.call(void 0, 
    async () => _actions2.default.getBillingURL({
      OrgKey: orgKey,
      ReturnURL: _subscriptionRedirect.getCurrentURL.call(void 0, ),
    })
      .then(r => { window.location.href = r.URL })
      .catch(() => showWarningBar('Something went wrong')),
    [orgKey, showWarningBar],
  )

  return {
    isLoading: !planDetails && !hasLoadFailed,
    renewsOn: _optionalChain([planDetails, 'optionalAccess', _2 => _2.RenewsOn]),
    discount: _optionalChain([planDetails, 'optionalAccess', _3 => _3.Discount]),
    hasLoadFailed,
    itemsMap,
    isLocked: !!_optionalChain([planDetails, 'optionalAccess', _4 => _4.Locked]),
    cancelAt: _optionalChain([planDetails, 'optionalAccess', _5 => _5.CancelAt]),
    hasSubscription: !!_optionalChain([planDetails, 'optionalAccess', _6 => _6.Card]),
    onReloadSubscription: loadPlanDetails,
    onManage,
  }
}; exports.usePlanDetails = usePlanDetails
