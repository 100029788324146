"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');
var _lodash = require('lodash');



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _asyncDebounce = require('shared/services/asyncDebounce'); var _asyncDebounce2 = _interopRequireDefault(_asyncDebounce);
var _string = require('shared/services/string');

const SEARCH_DEBOUNCE_MS = 250

const runSearch = _asyncDebounce2.default.call(void 0, 
  _actions2.default.searchOrgs,
  SEARCH_DEBOUNCE_MS,
  { cancelObj: null },
)

 const useOrgLookup = (searchQuery, minQueryLength = 4) => {
  const [lookupOrgs, setLookupOrgs] = _react.useState([])
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const prevOrgsCount = _react.useRef.call(void 0, 0)
  const hasMatchingOrg = _react.useMemo.call(void 0, 
    () => !!lookupOrgs.find(
      o => o.Name.match(new RegExp(_lodash.escapeRegExp.call(void 0, _string.sanitizeStr.call(void 0, searchQuery)), 'i')),
    ),
    [searchQuery, lookupOrgs],
  )

  _react.useLayoutEffect.call(void 0, 
    () => {
      setLookupOrgs([])
      if (searchQuery.length < minQueryLength) return
      setIsLoading(true)

      runSearch(searchQuery)
        .then((rawOrgs) => {
          setIsLoading(false)
          if (!rawOrgs) return
          const orgs = rawOrgs
            .map(o => ({ OrgKey: o.OrganizationKey, Name: o.Name }))
          setLookupOrgs(orgs)
          prevOrgsCount.current = orgs.length
        })
        .catch(e => e && setIsLoading(false))
    },
    [searchQuery, minQueryLength],
  )

  return {
    lookupOrgs,
    hasMatchingOrg,
    isLoading,
    prevOrgsCount: prevOrgsCount.current,
  }
}; exports.useOrgLookup = useOrgLookup

exports. default = exports.useOrgLookup
