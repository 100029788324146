"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');




var _string = require('shared/services/string');





var _entities = require('shared/transforms/entities');
var _entity = require('shared/services/entity');

var _user = require('shared/selectors/user');
var _team = require('shared/selectors/team');
var _org = require('shared/selectors/org');
var _array = require('shared/services/array');

const EMPTY_TEAM_ENTITY_LIST = []
const EMPTY_TEAM_LIST = []

 const selectHasCurrentUserAnyTeams = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  me => Object.values(_optionalChain([me, 'optionalAccess', _ => _.Teams]) || {}).length > 0,
); exports.selectHasCurrentUserAnyTeams = selectHasCurrentUserAnyTeams

 const selectAmIOrgless = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  me => !Object.values(me.Relationships || {}).length,
); exports.selectAmIOrgless = selectAmIOrgless

 const selectMyTeamKeys = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  me => Object.keys(me.Teams) ,
); exports.selectMyTeamKeys = selectMyTeamKeys

const selectMyPopulatedTeamsList = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  _team.selectTeams,
  _org.selectOrgs,
  (me, allTeams, allOrgs) => !me || !allTeams
    ? EMPTY_TEAM_LIST
    : Object.values(me.Teams)
      .map(t => allTeams[t.TeamKey])
      .filter(_array.truthy)
      .sort((a, b) =>
        _string.compareStr.call(void 0, _optionalChain([allOrgs, 'access', _2 => _2[a.OrgKey], 'optionalAccess', _3 => _3.Name]), _optionalChain([allOrgs, 'access', _4 => _4[b.OrgKey], 'optionalAccess', _5 => _5.Name])) ||
        _string.compareStr.call(void 0, a.Name, b.Name),
      ),
)

 const selectMyPhoneTeamsList = _reselect.createSelector.call(void 0, 
  selectMyPopulatedTeamsList,
  teams => teams.filter(t => t.PhoneNumber),
); exports.selectMyPhoneTeamsList = selectMyPhoneTeamsList

 const selectDoIHaveAnyPhoneTeams =
  _reselect.createSelector.call(void 0, exports.selectMyPhoneTeamsList, ts => ts.length > 0); exports.selectDoIHaveAnyPhoneTeams = selectDoIHaveAnyPhoneTeams







const formatTeamGroupsList = (teams, allOrgs) => {
  const groupsMap = teams.reduce(
    (acc, t) => {
      const orgKey = t.OrgKey
      if (!acc[orgKey]) {
        acc[orgKey] = {
          orgKey,
          orgName: _nullishCoalesce(_optionalChain([allOrgs, 'access', _6 => _6[orgKey], 'optionalAccess', _7 => _7.Name]), () => ( '')),
          teamsList: [],
        }
      }
      acc[orgKey].teamsList.push(t)
      return acc
    },
    {} ,
  )
  const groupsList = Object
    .values(groupsMap)
    .sort((a, b) => _string.compareStr.call(void 0, a.orgName, b.orgName))

  groupsList
    .forEach(g => g.teamsList.sort((a, b) => _string.compareStr.call(void 0, a.Name, b.Name)))

  return groupsList
}

 const selectMyTeamGroupsList =
  _reselect.createSelector.call(void 0, selectMyPopulatedTeamsList, _org.selectOrgs, formatTeamGroupsList); exports.selectMyTeamGroupsList = selectMyTeamGroupsList

 const selectMyPhoneTeamGroupsList =
  _reselect.createSelector.call(void 0, exports.selectMyPhoneTeamsList, _org.selectOrgs, formatTeamGroupsList); exports.selectMyPhoneTeamGroupsList = selectMyPhoneTeamGroupsList

const mapTeamsToEntities = (
  teams,
  allOrgs,
  makeDescription,
) =>
  !teams.length || !allOrgs
    ? EMPTY_TEAM_ENTITY_LIST
    : teams.map(_entities.mapTeamToEntity.call(void 0, allOrgs, makeDescription))

 const selectMyTeamEntitiesList = _reselect.createSelector.call(void 0, 
  selectMyPopulatedTeamsList,
  _org.selectOrgs,
  (teams, allOrgs) => mapTeamsToEntities(teams, allOrgs, _entities.makeOrgNameTeamDescription),
); exports.selectMyTeamEntitiesList = selectMyTeamEntitiesList

 const selectMyPhoneTeamEntities = _reselect.createSelector.call(void 0, 
  exports.selectMyPhoneTeamsList,
  _org.selectOrgs,
  (teams, allOrgs) => {
    const teamsList = mapTeamsToEntities(teams, allOrgs, _entities.makeContactTeamDescription)
    return {
      teamsList,
      entitiesMap: _entity.entityListToMap.call(void 0, teamsList),
    }
  },
); exports.selectMyPhoneTeamEntities = selectMyPhoneTeamEntities
