"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');

var _selectBlobjectSubscribersMap = require('./selectBlobjectSubscribersMap');
var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');

var _utils = require('./utils');
var _entity = require('shared/services/entity');





var _entities = require('shared/transforms/entities');

 const makeSelectReadReceiptSubscribers = () => _toolkit.createSelector.call(void 0, 
  _selectBlobjectSubscribersMap.selectBlobjectSubscribersMap,
  _peers.selectAllUsers,
  _peers.selectAllPatients,
  _org.selectOrgs,
  ({ users, patients }, allUsers, allPatients, allOrgs) => {
    const populatedUsers = _utils.populateSubscribers.call(void 0, users, allUsers)
    const populatedPatients = _utils.populateSubscribers.call(void 0, patients, allPatients)

    const userEntitiesList = populatedUsers.map(u =>
      _entities.mapUserToEntity.call(void 0, 
        allOrgs,
        _entities.makeFullSpecificOrgTitleUserDescription.call(void 0, users[u.UserKey].OrgKey),
      )(u),
    )

    const patientEntitiesList = populatedPatients.map(
      _entities.mapPatientToEntity.call(void 0, allOrgs, _entities.makeOrgNamePatientDescription),
    )

    const allEntitiesList = patientEntitiesList.concat(userEntitiesList)

    return _entity.entityListToMap.call(void 0, allEntitiesList)
  },
); exports.makeSelectReadReceiptSubscribers = makeSelectReadReceiptSubscribers
