"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/ResultsList/ResultsStatus.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Spinner = require('web/components/Spinner');







const ResultsStatus = ({
  hasAnyResults,
  isLoading,
  searchQuery,
}) => {
  if (!searchQuery) return null

  return (
    _react2.default.createElement('div', { className: "participants-picker--results-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      ,  isLoading && (
        _react2.default.createElement(_Spinner.SimpleSpinner, {
          className: "participants-picker--results-status--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        )
      )
      ,  !isLoading && !hasAnyResults && (
        _react2.default.createElement('span', { className: "participants-picker--results-status--no-results", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "No results for "
             , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, searchQuery)
        )
      )
    )
  )
}

exports. default = ResultsStatus
