"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');



var _time = require('shared/services/time');
var _noticeBar = require('web/services/context/noticeBar');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);



const EXPORT_STARTED_MSG = "We'll start your download when the export is complete."
const FAILURE_MSG = "Sorry - Something went wrong exporting your chat. Please contact support@bloomtext.com."





const useExportChat = ({
  conversationKey,
}) => {
  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  return _react.useCallback.call(void 0, 
    async (dateRange, shouldIncludeFiles) => {
      if (!conversationKey) {
        showWarningBar('Something went wrong')
        throw new Error()
      }

      showSuccessBar(EXPORT_STARTED_MSG)

      const [startDate, endDate] = dateRange
      return _actions2.default.exportChat({
        ChatKey: conversationKey,
        Begin: startDate,
        End: endDate,
        IncludeFiles: shouldIncludeFiles,
        TimeZone: _time.userTZ ,
      })
        .then(url => { window.open(url, '_self') })
        .catch(() => showWarningBar(FAILURE_MSG))
    },
    [showWarningBar, showSuccessBar, conversationKey],
  )
}

exports. default = useExportChat
