"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Header/TeamPicker/Title.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _team = require('shared/selectors/team');

var _Icon = require('web/components/Icon'); var _Icon2 = _interopRequireDefault(_Icon);
var _org = require('shared/selectors/org');





const Title = ({
  orgName,
  teamName,
  hasTeamSelected,
}) => (
  _react2.default.createElement('div', { className: "list-header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    , _react2.default.createElement('div', { className: "list-header--title--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      ,  teamName || 'My Inbox' , ' '
      , _react2.default.createElement(_Icon2.default, { name: "dropdown", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
    )
    ,  hasTeamSelected && (
      _react2.default.createElement('div', { className: "list-header--title--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}},  orgName )
    )
  )
)







const mapState = (state, { teamKey }) => {
  const team = teamKey && _team.selectTeamByKey.call(void 0, state, teamKey)
  const orgKey = _optionalChain([team, 'optionalAccess', _ => _.OrgKey])
  const org = orgKey && _org.selectOrgByKey.call(void 0, orgKey, state)

  if (!teamKey || !team || !org) return { hasTeamSelected: false }

  return {
    hasTeamSelected: true,
    teamName: team.Name,
    orgName: org.Name,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(Title)
