"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/chat/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { newObj[key] = obj[key]; } } } newObj.default = obj; return newObj; } } function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactrouterdom = require('react-router-dom');
var _react3 = require('@sentry/react'); var Sentry = _interopRequireWildcard(_react3);
var _ServiceScreen = require('web/screens/ServiceScreen'); var _ServiceScreen2 = _interopRequireDefault(_ServiceScreen);



var _subscriptionRedirect = require('web/services/subscriptionRedirect');

var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _ConfirmEmailScreen = require('web/screens/ConfirmEmailScreen'); var _ConfirmEmailScreen2 = _interopRequireDefault(_ConfirmEmailScreen);
var _UnsubscribeEmailNotifsScreen = require('web/screens/UnsubscribeEmailNotifsScreen'); var _UnsubscribeEmailNotifsScreen2 = _interopRequireDefault(_UnsubscribeEmailNotifsScreen);
var _CodeScreen = require('web/screens/CodeScreen'); var _CodeScreen2 = _interopRequireDefault(_CodeScreen);

var _Notifications = require('web/components/Notifications'); var _Notifications2 = _interopRequireDefault(_Notifications);
var _Video = require('web/screens/Video'); var _Video2 = _interopRequireDefault(_Video);

var _Login = require('web/screens/Login'); var _Login2 = _interopRequireDefault(_Login);
var _FirstTimeInteractables = require('web/screens/FirstTimeInteractables'); var _FirstTimeInteractables2 = _interopRequireDefault(_FirstTimeInteractables);

var _Setup = require('web/screens/Signup/Setup'); var _Setup2 = _interopRequireDefault(_Setup);

var _Settings = require('web/screens/Settings'); var _Settings2 = _interopRequireDefault(_Settings);
var _AlertsScreen = require('web/screens/AlertsScreen'); var _AlertsScreen2 = _interopRequireDefault(_AlertsScreen);
var _BannerAlertScreen = require('web/screens/BannerAlertScreen'); var _BannerAlertScreen2 = _interopRequireDefault(_BannerAlertScreen);
var _ToastsScreen = require('web/screens/ToastsScreen'); var _ToastsScreen2 = _interopRequireDefault(_ToastsScreen);
var _Super = require('web/screens/Super'); var _Super2 = _interopRequireDefault(_Super);

var _EnterEmail = require('web/screens/Signup/EnterEmail'); var _EnterEmail2 = _interopRequireDefault(_EnterEmail);
var _Inbox = require('web/screens/Inbox'); var _Inbox2 = _interopRequireDefault(_Inbox);
var _Search = require('web/screens/Search'); var _Search2 = _interopRequireDefault(_Search);
var _store = require('web/store');
var _Broadcast = require('web/screens/Broadcast'); var _Broadcast2 = _interopRequireDefault(_Broadcast);
var _Directory = require('web/screens/Directory'); var _Directory2 = _interopRequireDefault(_Directory);
var _Sidebar = require('web/components/Sidebar'); var _Sidebar2 = _interopRequireDefault(_Sidebar);

var _VideoMeeting = require('web/screens/VideoMeeting'); var _VideoMeeting2 = _interopRequireDefault(_VideoMeeting);
var _Modals = require('web/components/VideoCall/Modals');
var _SuccessModal = require('web/components/Upgrade/UpgradeModal/SuccessModal'); var _SuccessModal2 = _interopRequireDefault(_SuccessModal);
var _PageNotFoundScreen = require('web/screens/PageNotFoundScreen'); var _PageNotFoundScreen2 = _interopRequireDefault(_PageNotFoundScreen);
var _ErrorModal = require('web/screens/ErrorModal'); var _ErrorModal2 = _interopRequireDefault(_ErrorModal);

var _routes = require('./routes'); var _routes2 = _interopRequireDefault(_routes);
var _noticeBar = require('web/services/context/noticeBar');
var _app = require('shared/selectors/app');
var _user = require('shared/selectors/user');

class Application extends _react.Component {
  constructor (props) {
    super(props);Application.prototype.__init.call(this);Application.prototype.__init2.call(this);Application.prototype.__init3.call(this);Application.prototype.__init4.call(this);Application.prototype.__init5.call(this);Application.prototype.__init6.call(this);Application.prototype.__init7.call(this);Application.prototype.__init8.call(this);Application.prototype.__init9.call(this);Application.prototype.__init10.call(this);Application.prototype.__init11.call(this);
    const qs = _subscriptionRedirect.parseSubscriptionSuccessURL.call(void 0, )

    this.state = {
      searchModalOpen: false,
      isJoinCallModalOpen: false,
      isDesktopNewRoomModalOpen: false,
      isMobileNewRoomModalOpen: false,
      subscriptionSuccessActionType: _optionalChain([qs, 'optionalAccess', _ => _.actionType]),
      subscriptionSuccessPlan: _optionalChain([qs, 'optionalAccess', _2 => _2.planType]),
      subscriptionSuccessOrgKey: _optionalChain([qs, 'optionalAccess', _3 => _3.orgKey]),
      isSubscriptionSuccessModalOpen: !!_optionalChain([qs, 'optionalAccess', _4 => _4.planType]),
    }
  }

  __init() {this.closeSubscriptionSuccessModal = () => {
    this.setState({ isSubscriptionSuccessModalOpen: false })
    _subscriptionRedirect.clearQS.call(void 0, )
  }}

  getUserAgentSpecificStyle () {
    const userAgent = window.navigator.userAgent
    if ((userAgent.match(/iPhone/i) || userAgent.match(/Android/i) || userAgent.match(/iPad/i)) && window.innerWidth < 561) {
      return {
        height: window.innerHeight,
        width: window.innerWidth,
      }
    } else {
      return {}
    }
  }

  componentDidMount () {
    const itunesMeta = document.createElement('meta')
    itunesMeta.setAttribute('name', 'apple-itunes-app')
    itunesMeta.setAttribute('content', 'app-id=1294498284')
    document.head.appendChild(itunesMeta)

    document.addEventListener("keydown", this.handleGlobalShortcuts, false)
  }

  componentWillUnmount () {
    document.removeEventListener("keydown", this.handleGlobalShortcuts, false)
  }

  __init2() {this.handleGlobalShortcuts = (event) => {
    // ctrl+f for search.  TODO:  remove flighting check when fully shipped
    if (event.keyCode === 70 && event.metaKey && event.shiftKey) {
      event.preventDefault()
      event.stopPropagation()
      this.openSearchModal()
    }
  }}

  __init3() {this.openSearchModal = () => this.setState({ searchModalOpen: true })}

  __init4() {this.closeSearchModal = () => {
    this.setState({ searchModalOpen: false })
  }}

  __init5() {this.openJoinCallModal = () => this.setState({ isJoinCallModalOpen: true })}
  __init6() {this.closeJoinCallModal = () => this.setState({ isJoinCallModalOpen: false })}
  __init7() {this.openMobileNewRoomModal = () => this.setState({ isMobileNewRoomModalOpen: true })}
  __init8() {this.openDesktopNewRoomModal = () => this.setState({ isDesktopNewRoomModalOpen: true })}
  __init9() {this.closeNewRoomModal = () => this.setState({
    isMobileNewRoomModalOpen: false,
    isDesktopNewRoomModalOpen: false,
  })}

  __init10() {this.renderInbox = () => _react2.default.createElement(_Inbox2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} )}
  __init11() {this.renderSettings = () => _react2.default.createElement(_Settings2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} )}

  render () {
    const userAgentStyles = this.getUserAgentSpecificStyle()

    const {
      isJoinCallModalOpen,
      isDesktopNewRoomModalOpen, isMobileNewRoomModalOpen,
      isSubscriptionSuccessModalOpen,
      subscriptionSuccessPlan,
      subscriptionSuccessOrgKey,
      subscriptionSuccessActionType,
    } = this.state
    const { showNoticeBar, amISuperuser } = this.props

    return (
      _react2.default.createElement('div', { className: "app", style: userAgentStyles, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
        , _react2.default.createElement('div', { className: "app-top", __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
          , _react2.default.createElement(_BannerAlertScreen2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 140}} )
        )
        , _react2.default.createElement('div', { className: "app-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
          , _react2.default.createElement(_ServiceScreen2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 143}} )
          , _react2.default.createElement(_Sidebar2.default, { onSearch: this.openSearchModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}} )
          , _react2.default.createElement('div', { className: "app--screen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
            /* We make the search "live" inside the same frame as most screens for saner object
              * modal and to allow for consistent mobile rendering (where we don't have "fixed"
              * positioning)
              */
            , _react2.default.createElement(_Search2.default, { isOpen: this.state.searchModalOpen, onClose: this.closeSearchModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}} )
            , _react2.default.createElement(_ToastsScreen2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 151}} )
            , _react2.default.createElement(_FirstTimeInteractables2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 152}} )
            ,  subscriptionSuccessPlan && (
              _react2.default.createElement(_SuccessModal2.default, {
                isOpen: isSubscriptionSuccessModalOpen,
                onClose: this.closeSubscriptionSuccessModal,
                planType: subscriptionSuccessPlan,
                actionType: subscriptionSuccessActionType,
                orgKey: subscriptionSuccessOrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
              )
            )
            , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}

              /* inbox screens */
              , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.INBOX.NEW, render: this.renderInbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}} )
              , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.INBOX.CHAT, render: this.renderInbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}} )

              , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.BROADCAST.ROOT, component: _Broadcast2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}} )
              , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.DIRECTORY.ENTITY, component: _Directory2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}} )
              , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.SETTINGS.ROOT, render: this.renderSettings, __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}} )

              ,  amISuperuser
                ? _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.SUPER, component: _Super2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}} )
                : null
              
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true,
                path: _routes2.default.ROOT,
                render: () => _react2.default.createElement(_reactrouterdom.Redirect, { to: _routes2.default.INBOX.ROOT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
              )
              , _react2.default.createElement(_reactrouterdom.Route, { component: _PageNotFoundScreen2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}} )
            )
            , _react2.default.createElement(_VideoMeeting2.default, {
              isLoggedIn: true,
              showNoticeBar: showNoticeBar,
              openJoinCallModal: this.openJoinCallModal,
              closeJoinCallModal: this.closeJoinCallModal,
              isJoinCallModalOpen: isJoinCallModalOpen,
              openDesktopNewRoomModal: this.openDesktopNewRoomModal,
              openMobileNewRoomModal: this.openMobileNewRoomModal,
              closeNewRoomModal: this.closeNewRoomModal,
              isMobileNewRoomModalOpen: isMobileNewRoomModalOpen,
              isDesktopNewRoomModalOpen: isDesktopNewRoomModalOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
            )
          )
        )
      )
    )
  }
}

class AuthenticatedWebsiteRouter extends _react.Component {
  constructor (props) {
    super(props);AuthenticatedWebsiteRouter.prototype.__init12.call(this);AuthenticatedWebsiteRouter.prototype.__init13.call(this);AuthenticatedWebsiteRouter.prototype.__init14.call(this);
    this.state = {
      csrfCheckComplete: false,
      postLoginRoute: _routes2.default.ROOT,
    }
  }

  componentDidMount () {
    _client2.default.csrf()
      .then(_actions2.default.loginSuccess)
      .catch(() => {
        const pathname = _optionalChain([_store.history, 'access', _5 => _5.location, 'optionalAccess', _6 => _6.pathname]) || ''
        if (pathname.includes(_routes2.default.LOGIN.ROOT)) return
        this.setState({ postLoginRoute: _store.history.location })
      })
      .finally(() => this.setState({ csrfCheckComplete: true }))
  }

  __init12() {this.renderVideoMeeting = props => (
    _react2.default.createElement(_VideoMeeting2.default, { ...props, showNoticeBar: this.props.showNoticeBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}} )
  )}

  __init13() {this.onLoginSuccess = () => {
    _store.history.push(this.state.postLoginRoute)
    this.setState({ postLoginRoute: _routes2.default.ROOT })
  }}

  __init14() {this.renderLogin = props => (
    _react2.default.createElement(_Login2.default, {
      ...props,
      showNoticeBar: this.props.showNoticeBar,
      onLoginSuccess: this.onLoginSuccess, __self: this, __source: {fileName: _jsxFileName, lineNumber: 231}}
    )
  )}

  render () {
    /* we haven't checked csrf so do nothin' */
    if (!this.state.csrfCheckComplete) return null

    const { authed, clientOpen, showNoticeBar, amISuperuser } = this.props

    /* if usrrs are not authenticated, send 'em to the login screen */
    if (!authed || !clientOpen) {
      return (
        _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 247}}
          , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.VIDEO.V2, render: this.renderVideoMeeting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 248}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.LOGIN.EMAIL_LOGIN, render: this.renderLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 249}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.LOGIN.ROOT, render: this.renderLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 250}} )
          , _react2.default.createElement(_reactrouterdom.Redirect, { to: _routes2.default.LOGIN.ROOT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}} )
        )
      )
    }

    /* finally, render application appropriate components */
    return (
      _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 258}}
        , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.LOGIN.ROOT, render: this.renderLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 259}} )
        , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: "/chats", render: () => _react2.default.createElement(_reactrouterdom.Redirect, { to: _routes2.default.INBOX.ROOT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}} )
        , _react2.default.createElement(Application, { showNoticeBar: showNoticeBar, amISuperuser: amISuperuser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 261}} )
      )
    )
  }
}

const mapState = state => ({
  authed: _app.selectIsAuthed.call(void 0, state),
  clientOpen: _app.selectIsClientOpen.call(void 0, state),
  amISuperuser: _user.selectAmISuperUser.call(void 0, state),
})

const AuthenticatedWebsite = _reactrouterdom.withRouter.call(void 0, _reactredux.connect.call(void 0, mapState)(AuthenticatedWebsiteRouter))

class Chat extends _react.Component {constructor(...args) { super(...args); Chat.prototype.__init15.call(this);Chat.prototype.__init16.call(this);Chat.prototype.__init17.call(this);Chat.prototype.__init18.call(this); }
  __init15() {this.state = {
    noticeBar: {
      isOpen: false,
      type: 'success',
      text: '',
    },
  }}

  __init16() {this.showNoticeBar = ({ type = 'success', text }) => this.setState({
    noticeBar: { text, type, isOpen: true },
  })}

  __init17() {this.hideNoticeBar = () => this.setState({
    noticeBar: { ...this.state.noticeBar, isOpen: false },
  })}

  componentDidMount () {
    require('shared/listeners/websocket')
    require('web/listeners')
  }

  __init18() {this.noticeBarContext = {
    showNoticeBar: this.showNoticeBar,
    showSuccessBar: text => this.showNoticeBar({ type: 'success', text }),
    showWarningBar: text => this.showNoticeBar({ type: 'warning', text }),
  }}

  render () {
    const { noticeBar } = this.state

    return (
      _react2.default.createElement(_react.Fragment, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 307}}
        , _react2.default.createElement(_Notifications2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 308}} )
        , _react2.default.createElement(_AlertsScreen2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 309}} )
        , _react2.default.createElement(_Modals.NoticeBar, {
          isOpen: noticeBar.isOpen,
          text: noticeBar.text,
          type: noticeBar.type,
          onClose: this.hideNoticeBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 310}}
        )
        , _react2.default.createElement(_noticeBar.NoticeBarContext.Provider, { value: this.noticeBarContext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 316}}
          , _react2.default.createElement(_reactrouterdom.Router, { history: _store.history, __self: this, __source: {fileName: _jsxFileName, lineNumber: 317}}
            , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 318}}
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.VIDEO.V1, component: _Video2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 319}} )
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.CONFIRM_EMAIL, component: _ConfirmEmailScreen2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 320}} )
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.UNSUBSCRIBE.EMAIL, component: _UnsubscribeEmailNotifsScreen2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 321}} )
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.CODE, component: _CodeScreen2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 322}} )
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.WELCOME, component: _EnterEmail2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 323}} )
              , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.CREATE_ACCOUNT, component: _Setup2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 324}} )
              , _react2.default.createElement(AuthenticatedWebsite, { showNoticeBar: this.showNoticeBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 325}} )
            )
          )
        )
      )
    )
  }
}

exports. default = Sentry.withErrorBoundary(Chat, { fallback: _react2.default.createElement(_ErrorModal2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 334}} ) })
