"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Feedback/RatingMeter/MeterItem.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

 class MeterItem extends _react2.default.Component {constructor(...args) { super(...args); MeterItem.prototype.__init.call(this);MeterItem.prototype.__init2.call(this); }
  __init() {this.handleRatingUpdate = () => this.props.onRatingUpdate(this.props.idx + 1)}
  __init2() {this.handleRatingSubmit = () => {
    const { isSubmitted, onRatingSubmit, idx } = this.props
    if (isSubmitted) return
    this.handleRatingUpdate()
    onRatingSubmit(idx + 1)
  }}

  render () {
    const { isActive, isSubmitted, className } = this.props
    const rootClassName = _classBuilder2.default.call(void 0, 
      className,
      { active: isActive, disabled: isSubmitted },
    )

    return (
      _react2.default.createElement('div', {
        className: rootClassName,
        onMouseOver: this.handleRatingUpdate,
        onTouchEnd: this.handleRatingSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      )
    )
  }

  static __initStatic() {this.propTypes = {
    idx: _proptypes2.default.number.isRequired,
    isActive: _proptypes2.default.bool.isRequired,
    onRatingUpdate: _proptypes2.default.func.isRequired,
    className: _proptypes2.default.string,
  }}
} MeterItem.__initStatic(); exports.default = MeterItem;
