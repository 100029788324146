"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Video.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _VideoChat = require('web/components/VideoChat'); var _VideoChat2 = _interopRequireDefault(_VideoChat);

 class Video extends _react.Component {
  constructor (props) {
    super(props)
    this.state = {
      audioOnly: false,
    }
  }

  getUserAgentSpecificStyle () {
    const userAgent = window.navigator.userAgent
    if ((userAgent.match(/iPhone/i) || userAgent.match(/Android/i) || userAgent.match(/iPad/i)) && window.innerWidth < 561) {
      return {
        height: window.innerHeight,
        width: window.innerWidth,
      }
    } else {
      return {}
    }
  }

  render () {
    // This is a mess - we can't really detect when the publisher component can't publish video on android due to an out of date browser, so long as the browser also supports audio fallback
    const ua = navigator.userAgent.toLowerCase()
    if (ua.indexOf("android") > -1) {
      const chromeMatch = ua.match(/chrom(e|ium)\/([0-9]+)\./)
      const samsungMatch = ua.match(/samsungbrowser/)
      const majorVer = chromeMatch && !samsungMatch ? parseInt(chromeMatch[2], 10) : 0

      if (majorVer < 65 && !this.state.audioOnly) {
        let errorText = (
          _react2.default.createElement('div', { className: "video-chat-error-page-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, "We're sorry - BloomText video isn't supported by your browser. Try opening the link in a recently updated version of Google's Chrome browser. You can also choose to continue, but you'll be limited to voice-only calls."

          )
        )
        let chromeOption = (
          _react2.default.createElement('a', { style: { display: "block" }, href: "googlechrome://navigate?url=" + encodeURI(window.location.href + "?retry"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
            , _react2.default.createElement('div', { className: "video-chat-error-page-option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
              , _react2.default.createElement('img', { className: "video-chat-error-page-option-img", src: "/assets/images/video/chrome.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}} ), "Open Google Chrome"

            )
          )
        )

        // If it's a retry, it means we already tried to open in chrome from whatever other browser
        const isRetry = this.props.location.search.indexOf("retry") > -1
        if (isRetry) {
          errorText = (
            _react2.default.createElement('div', { className: "video-chat-error-page-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "Your current Chrome version ("
                  , majorVer, ") is less than the minimum required version 65. Please update Chrome to use BloomText video, or continue with audio only."
            )
          )
          chromeOption = (
            _react2.default.createElement('a', { style: { display: "block" }, href: "https://play.google.com/store/apps/details?id=com.android.chrome", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
              , _react2.default.createElement('div', { className: "video-chat-error-page-option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
                , _react2.default.createElement('img', { className: "video-chat-error-page-option-img", src: "/assets/images/video/chrome.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} ), "Download Google Chrome"

              )
            )
          )
        }
        return (
          _react2.default.createElement('div', { className: "video-chat-error-page", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            , _react2.default.createElement('img', { className: "video-chat-error-page-img", src: "/assets/images/video/error.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
            ,  errorText 
            , _react2.default.createElement('div', { className: "video-chat-error-page-option-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
              ,  chromeOption 
              , _react2.default.createElement('div', { className: "video-chat-error-page-option", onClick: () => this.setState({ audioOnly: true }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                , _react2.default.createElement('img', { className: "video-chat-error-page-option-img", src: "/assets/images/video/microphone.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} ), "Continue Audio Only"

              )
            )
          )
        )
      }
    }

    return (
      _react2.default.createElement('div', { className: "video-chat-page", style: this.getUserAgentSpecificStyle(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        , _react2.default.createElement(_VideoChat2.default, { videoKey: this.props.match.params.videoKey, userKey: this.props.match.params.userKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} )
      )
    )
  }
} exports.default = Video;
