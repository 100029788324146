"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/TokenError.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _store = require('web/store');
var _components = require('../components');
var _types = require('../types');

const restartSignupFlow = () => _store.history.push(_routes2.default.WELCOME)
const gotoLogin = () => _store.history.push(_routes2.default.LOGIN.ROOT)





const TokenError = ({ tokenStatus }) => {
  const supportLink = _react2.default.createElement('a', { href: "mailto:support@bloomapi.com", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, "support@bloomapi.com")

  if (tokenStatus === _types.WelcomeUserInvalidStatus.SERVER_ERROR) {
    return (
      _react2.default.createElement(_components.SignupStepError, {
        title: "Something went wrong"  ,
        desc: _react2.default.createElement(_react2.default.Fragment, null, "Please try again or contact "     ,  supportLink ),
        btnLabel: "Try signing up again"   ,
        onBtnClick: restartSignupFlow, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      )
    )
  }

  if (tokenStatus === _types.WelcomeUserInvalidStatus.TOKEN_USED) {
    return (
      _react2.default.createElement(_components.SignupStepError, {
        title: "Link already used"  ,
        desc: "This link has already been used. You likely have already signed up to BloomText."             ,
        btnLabel: "Log in" ,
        onBtnClick: gotoLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      )
    )
  }

  return (
    _react2.default.createElement(_components.SignupStepError, {
      title: "Invalid link" ,
      desc: "This link is no longer valid. This might have occurred because a link expired, or the link you used was incorrect."                    ,
      btnLabel: "Try signing up again"   ,
      onBtnClick: restartSignupFlow, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
    )
  )
}

exports. default = TokenError
