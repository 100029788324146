"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _SuperOrganizationsScreen = require('web/screens/Super/SuperOrganizationsScreen'); var _SuperOrganizationsScreen2 = _interopRequireDefault(_SuperOrganizationsScreen);
var _SuperOrganizationScreen = require('web/screens/Super/SuperOrganizationScreen'); var _SuperOrganizationScreen2 = _interopRequireDefault(_SuperOrganizationScreen);
var _SuperEditOrganizationScreen = require('web/screens/Super/SuperEditOrganizationScreen'); var _SuperEditOrganizationScreen2 = _interopRequireDefault(_SuperEditOrganizationScreen);
var _SuperUsersScreen = require('web/screens/Super/SuperUsersScreen'); var _SuperUsersScreen2 = _interopRequireDefault(_SuperUsersScreen);
var _SuperUserScreen = require('web/screens/Super/SuperUserScreen'); var _SuperUserScreen2 = _interopRequireDefault(_SuperUserScreen);
var _SuperNerdScreen = require('web/screens/Super/SuperNerdScreen'); var _SuperNerdScreen2 = _interopRequireDefault(_SuperNerdScreen);
var _SuperTeamScreen = require('web/screens/Super/SuperTeamScreen'); var _SuperTeamScreen2 = _interopRequireDefault(_SuperTeamScreen);
var _store = require('web/store');

const SuperPageLink = ({ selected, children, onClick }) => {
  return (
    _react2.default.createElement('div', { className: "super--page-link " + (selected ? "selected" : ""), onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , children
    )
  )
}

 class Super extends _react.Component {
  render () {
    const usersSelected = _store.history.location.pathname.indexOf('/super/users') > -1
    const orgsSelected = _store.history.location.pathname.indexOf('/super/orgs') > -1
    const subsSelected = _store.history.location.pathname.indexOf('/super/subscriptions') > -1
    const devSelected = _store.history.location.pathname.indexOf('/super/nerds') > -1
    const teamSelected = _store.history.location.pathname.indexOf('/super/teams') > -1

    const tabs = (
      _react2.default.createElement('div', { className: "super--page-link--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , _react2.default.createElement(SuperPageLink, { onClick: () => _store.history.replace('/super/users'),
                         selected: usersSelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "Users"

        )
        , _react2.default.createElement(SuperPageLink, { onClick: () => _store.history.replace('/super/orgs'),
                         selected: orgsSelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Organizations"

        )
        /* <SuperPageLink onClick={() => history.replace('/super/subscriptions')}
                         selected={subsSelected}>
          Subscriptions
        </SuperPageLink> */
        , _react2.default.createElement(SuperPageLink, { onClick: () => _store.history.replace('/super/nerds'), selected: devSelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, "Nerds"

        )
      )
    )
    return (
      _react2.default.createElement('div', { className: "super--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , usersSelected || orgsSelected || subsSelected || devSelected || teamSelected ? tabs : null
        , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/orgs/:orgKey/edit", component: _SuperEditOrganizationScreen2.default, key: "orgEdit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/orgs/:orgKey", render: ps => _react2.default.createElement(_SuperOrganizationScreen2.default, { key: _optionalChain([ps, 'access', _ => _.match, 'optionalAccess', _2 => _2.params, 'optionalAccess', _3 => _3.orgKey]), ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} ), key: "org", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/orgs", component: _SuperOrganizationsScreen2.default, key: "orgs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/users/:userKey", component: _SuperUserScreen2.default, key: "user", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/users", component: _SuperUsersScreen2.default, key: "users", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
          /* <Route path='/super/subscriptions' component={SuperSubscriptions} key="subscriptions" /> */
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/nerds", component: _SuperNerdScreen2.default, key: "nerds", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
          , _react2.default.createElement(_reactrouterdom.Route, { path: "/super/teams/:teamKey", component: _SuperTeamScreen2.default, key: "team", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
          , _react2.default.createElement(_reactrouterdom.Redirect, { to: "/super/users/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}})
        )
      )
    )
  }
} exports.default = Super;
