"use strict";Object.defineProperty(exports, "__esModule", {value: true});



const APPROVE_SUCCESS = ["success", "You can now login using your PIN on this device."]
const DISAPPROVE_SUCCESS = ["success", "You can no longer log into this device with your PIN."]
const DISABLE_LOGIN_SUCCESS = ["success", "We've successfully deactivated Quick Login for your account. You'll no longer be able to login with your PIN."]
const ENABLE_LOGIN_SUCCESS = ["success", "Your new PIN has been saved."]
const GENERIC_FAILURE = ["warning", "We're sorry - something went wrong. Please try again in a few minutes or contact BloomText support."]

 const quickLoginMsgs = {
  APPROVE_SUCCESS,
  DISAPPROVE_SUCCESS,
  DISABLE_LOGIN_SUCCESS,
  ENABLE_LOGIN_SUCCESS,
  GENERIC_FAILURE,
}; exports.quickLoginMsgs = quickLoginMsgs

const INVALID = ["warning", "Please enter your current password and a new password."]
const WEAK = ["warning", "Please choose a stronger password: Include a mix of upper- and lowercase letters, numbers, punctuation, and avoid names (including 'bloom') and dates."]
const MISMATCH = ["warning", "Your new passwords don't match."]
const ERROR = ["warning", "We're sorry - something went wrong. Please try again in a few minutes or contact BloomText support."]
const WRONG_PASS = ["warning", "Please check your current password and try again."]
const SUCCESS = ["success", "Your password has been successfully changed."]

 const passwordMsgs = {
  INVALID,
  WEAK,
  MISMATCH,
  ERROR,
  WRONG_PASS,
  SUCCESS,
}; exports.passwordMsgs = passwordMsgs
