"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/NewChatRoomNamePrompt.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');

var _chatName = require('shared/validation/chat/chatName');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);







const NewChatRoomNamePrompt = ({ isOpen, onClose, onProceed }) => {
  const { register, handleSubmit, formState } = _reacthookform.useForm({
    resolver: _chatName.chatNameResolver,
    mode: 'onChange',
    defaultValues: { name: '' },
  })
  const { isValid } = formState

  const onSubmit = handleSubmit(({ name }) => onProceed(name))

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "New chat room"  ,
      className: "create-new--chat-room", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    
      , _react2.default.createElement('form', { onSubmit: onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        , _react2.default.createElement('label', { className: "create-new--chat-room--field", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, "Room name"

          , _react2.default.createElement('div', { className: "blmh-input--container no-padding" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            , _react2.default.createElement('input', {
              autoFocus: true,
              placeholder: "E.g. Medication, Appointments..."  ,
              className: "create-new--chat-room--input",
              ...register('name'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
            )
          )
        )
        , _react2.default.createElement(_Modal.ModalDesc, { className: "create-new--chat-room--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, "Chat rooms can be used for ongoing discussions about specific topics relevant to your team."

        )
        , _react2.default.createElement(_Modal.ModalControls, {
          isPrimarySubmit: true,
          isPrimaryEnabled: isValid,
          primaryBtnLabel: "Continue",
          onPrimaryBtnClick: onSubmit,
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        )
      )
    )
  )
}

exports. default = NewChatRoomNamePrompt
