"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/ChatNotice/View.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Button = require('web/components/Form/Button');

const orgOverLimitNotice = {
  title: 'You\'ve reached your free member limit',
  text: 'You must upgrade your plan to continue chatting with your team. You may continue chatting with members in other organizations.',
}

const patientChatNotice = {
  title: 'You no longer have access to SMS Messaging.',
  text: 'You must upgrade your plan to continue texting with patients.',
}







const ChatNoticeView = ({
  amIAdmin,
  isPatientNotice,
  onProceed,
}) => {
  const notice = isPatientNotice ? patientChatNotice : orgOverLimitNotice

  return (
    _react2.default.createElement('div', { className: "chat-upgrade--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , _react2.default.createElement('div', { className: "chat-upgrade--separator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        , _react2.default.createElement('div', { className: "chat-upgrade--separator-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
          , _react2.default.createElement('img', {
            className: "chat-upgrade--separator-icon",
            src: "/assets/icons/padlock.svg",
            alt: "⚠", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
          ), "Your plan is currently limited"

        )
      )

      , _react2.default.createElement('div', { className: "chat-upgrade--main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , _react2.default.createElement('img', {
          className: "chat-upgrade--icon",
          src: "/assets/icons/alert-orange.svg",
          alt: "⚠", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        )
        , _react2.default.createElement('div', { className: "chat-upgrade--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , _react2.default.createElement('h3', { className: "chat-upgrade--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}},  notice.title )
          , _react2.default.createElement('span', { className: "chat-upgrade--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}},  notice.text )
        )
        , _react2.default.createElement('div', { className: "chat-upgrade--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          ,  amIAdmin && (
            _react2.default.createElement(_Button.Button, { onClick: onProceed, className: "chat-upgrade--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Upgrade"

            )
          )
        )
      )
    )
  )
}

exports. default = ChatNoticeView
