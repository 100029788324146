"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Setup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);







var _videoCall = require('web/selectors/videoCall');
var _VideoCall = require('web/components/VideoCall');

const SetupScreen = ({
  isCamCycleAvailable,
  areCamIssuesPresent,
  videoInDevices,
  audioInDevices,
  currentMicDeviceId,
  currentCamDeviceId,

  goBack,
  gotoPreCall,
  onUserProblemPublish,
}) => (
  _react2.default.createElement(_VideoCall.Setup, {
    onCycleCam: _actions2.default.cycleCam,
    isCamCycleAvailable: isCamCycleAvailable,
    goBack: goBack,
    gotoPreCall: gotoPreCall,
    currentMicDeviceId: currentMicDeviceId,
    currentCamDeviceId: currentCamDeviceId,
    areCamIssuesPresent: areCamIssuesPresent,
    setCurrentMicDeviceId: _actions2.default.setCurrentMicDeviceId,
    videoInDevices: videoInDevices,
    audioInDevices: audioInDevices,
    onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
  )
)

const mapState = state => ({
  currentMicDeviceId: _videoCall.selectCurrentMicDeviceId.call(void 0, state),
  currentCamDeviceId: _videoCall.selectCurrentCamDeviceId.call(void 0, state),
  areCamIssuesPresent: _videoCall.selectAreCamIssuesPresent.call(void 0, state),
  isCamCycleAvailable: _videoCall.selectIsCamCycleAvailable.call(void 0, state),
  videoInDevices: _videoCall.selectVideoInDevices.call(void 0, state),
  audioInDevices: _videoCall.selectAudioInDevices.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(SetupScreen)
