"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

const initialState = {
  start: null,
  end: null,
  callOngoing: false,
  callMinimized: false,
  key: null,
}

 function callReducer (state = initialState, action) {
  switch (action.type) {
    case _actionCreators2.default.startCallAction.type:
      return {
        ...state,
        callOngoing: true,
        callMinimized: false,
        key: action.payload.key,
      }
    case _actionCreators2.default.endCallAction.type:
      return {
        ...state,
        callOngoing: false,
        callMinimized: false,
      }
    case _actionCreators2.default.minimizeCallAction.type:
      return {
        ...state,
        callMinimized: true,
      }
    case _actionCreators2.default.returnToCallCallAction.type:
      return {
        ...state,
        callMinimized: false,
      }
  }

  return state
} exports.default = callReducer;
