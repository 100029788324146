"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _blobject = require('shared/services/blobject');







 const useChatDesc = ({
  displayParticipants,
  myUserKey,
  renderChatDesc,
}) => _react.useMemo.call(void 0, 
  () => {
    const { users, teams, patients } = displayParticipants

    if (patients.length === 1) {
      const [patient] = patients
      return patient
        ? renderChatDesc(teams, `${patient.name}, ${patient.orgName}`)
        : null
    }

    if (users.length === 2) {
      const otherUser = users.find(u => u.userKey !== myUserKey)
      return otherUser
        ? renderChatDesc(teams, `${otherUser.name}, ${otherUser.title}`)
        : null
    }

    const usersCount = users.length
    const teamsCount = teams.length
    const patientsCount = patients.length
    const desc = _blobject.getDescriptionStringFromSubscriberCounts.call(void 0, 
      usersCount, teamsCount, patientsCount,
    )

    return renderChatDesc(teams, desc)
  },
  [displayParticipants, myUserKey, renderChatDesc],
); exports.useChatDesc = useChatDesc
