"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');





var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _errors = require('shared/errors');

const normalizeOrgName = (n) => n.toLowerCase()





var INVITE_ORG_ERROR; (function (INVITE_ORG_ERROR) {
  const orgAlreadyExists = 0; INVITE_ORG_ERROR[INVITE_ORG_ERROR["orgAlreadyExists"] = orgAlreadyExists] = "orgAlreadyExists";
  const adminAlreadyExists = orgAlreadyExists + 1; INVITE_ORG_ERROR[INVITE_ORG_ERROR["adminAlreadyExists"] = adminAlreadyExists] = "adminAlreadyExists";
})(INVITE_ORG_ERROR || (exports.INVITE_ORG_ERROR = INVITE_ORG_ERROR = {}));






const useInviteOrg = ({ teamEntitiesMap }) =>
  _react.useCallback.call(void 0, 
    async ({ orgName, adminEmail, myTeamKey, chatName }) => {
      const myOrgKey = _optionalChain([teamEntitiesMap, 'access', _ => _[myTeamKey ], 'optionalAccess', _2 => _2.wrappedEntity, 'optionalAccess', _3 => _3.OrgKey])

      const normalizedOrgName = normalizeOrgName(orgName)
      const orgCandidates = await _actions2.default.searchOrgs(orgName).catch(() => [])
      const existingOrg = orgCandidates
        .find((o) => normalizeOrgName(o.Name) === normalizedOrgName)

      if (!myOrgKey) return
      if (existingOrg) {
        const message = `Organization "${existingOrg.Name}" already exists.`
        throw new (0, _errors.BlmError)(
          message,
          { type: INVITE_ORG_ERROR.orgAlreadyExists, message },
        )
      }

      const res = await _actions2.default.createOrgWithNewAdmin({
        OrgKey: myOrgKey,
        OrgName: orgName,
        AdminEmail: adminEmail,
      })
        .catch(e => {
          if (e instanceof _errors.BlmError) {
            const extra = {
              type: INVITE_ORG_ERROR.adminAlreadyExists,
              message: e.extra.Error || `Failed to invite ${adminEmail}`,
            }
            throw new (0, _errors.BlmError)(e.extra.Error, extra, e)
          }
          throw e
        })

      return _actions2.default.createConversation({
        OrgKey: myOrgKey,
        ChatName: chatName,
        UserKeys: [],
        TeamKeys: [
          res.NewOrg.DefaultTeamKey,
          myTeamKey ,
        ],
        PatientKeys: [],
      })
    },
    [teamEntitiesMap],
  )

exports. default = useInviteOrg
