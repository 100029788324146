"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/List/DesktopMeetingsFilterTooltip.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

var _Tooltip = require('web/components/Tooltip');

const DesktopFilterTooltipWrapper = ({
  isOpen, onClose, domTarget,
  onChange, children, inputId,
  activeOption, options,
}) => (
  _react2.default.createElement(_Tooltip.ControlledTooltip, {
    isOpen: isOpen,
    onClose: onClose,
    type: "light",
    place: "bottom",
    domTarget: domTarget,
    content: 
      _react2.default.createElement(_Tooltip.TooltipContent, { className: "meetings-list--filter-tooltip--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , 
          options.map(({ value, label }) => (
            _react2.default.createElement('span', {
              key: value,
              onClick: () => { onChange(inputId, value); onClose() },
              className: _classBuilder2.default.call(void 0, 
                "meetings-list--filter-tooltip--option",
                { active: activeOption === value },
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
            
              ,  label 
            )
          ))
        
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
  )
)

DesktopFilterTooltipWrapper.propTypes = {
  isOpen: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  onChange: _proptypes2.default.func.isRequired,
  inputId: _proptypes2.default.string.isRequired,
  options: _proptypes2.default.array.isRequired,
  activeOption: _proptypes2.default.string,
}

exports. default = DesktopFilterTooltipWrapper
