"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Desktop/Modal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _SessionsList = require('./SessionsList'); var _SessionsList2 = _interopRequireDefault(_SessionsList);
var _SessionDetails = require('./SessionDetails'); var _SessionDetails2 = _interopRequireDefault(_SessionDetails);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

exports. default = ({
  isOpen, onClose,
  isOnHistoryScreen,
  gotoHistory, onSessionPick,
  currentSessionKey, roomKey, roomName,
  loadSessionsList,
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    className: "meeting-room--history-modal",
    title: isOnHistoryScreen ? "Meeting History" : "Session Details", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
  
    ,  isOnHistoryScreen
      ? _react2.default.createElement(_SessionsList2.default, {
          roomKey: roomKey,
          loadSessionsList: loadSessionsList,
          onSessionPick: onSessionPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        )
      : (
        _react2.default.createElement(_SessionDetails2.default, {
          roomKey: roomKey,
          roomName: roomName,
          gotoHistory: gotoHistory,
          currentSessionKey: currentSessionKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        )
        )
    
    , _react2.default.createElement('div', { className: "meeting-room--history--modal-ctrls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      ,  !isOnHistoryScreen &&
        _react2.default.createElement('a', {
          onClick: gotoHistory,
          className: "meeting--btn secondary meeting-room--history--modal-ctrl-back"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        , "Back")
      
      , _react2.default.createElement('a', {
        onClick: onClose,
        className: "meeting--btn secondary meeting-room--history--modal-ctrl-close"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , "Close")
    )
  )
)
