"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');























const getMeActions = _utils.createAsyncActions('GetMe')
const getInboxActions = _utils.createAsyncActions('GetInbox')
const getInboxForUserActions = _utils.createAsyncActions('GetInboxForUser')
const getInboxForPatientActions = _utils.createAsyncActions('GetInboxForPatient')
const setTeamInboxSettingsActions = _utils.createAsyncActions('SetInboxableSettingsOnTeam')
const renameChatActions = _utils.createAsyncActions('ChangeBlobjectName')
const getBlobjectDeepActions = _utils.createAsyncActions('GetBlobjectByKeyDeep')
const updateChatNotificationsActions = _utils.createAsyncActions('SetNotificationSettingsOnBlobject')
const createConversationActions = _utils.createAsyncActions('CreateConversation')
const createSmsConversationActions = _utils.createAsyncActions('CreateSmsConversation')
const markBlobjectSeenActions = _utils.createAsyncActions('MarkBlobjectSeen')
const completeBlobjectActions = _utils.createAsyncActions('CompleteBlobject')
const uncompleteBlobjectActions = _utils.createAsyncActions('UnCompleteBlobject')
const setBlobjectCompletedForTeamsActions = _utils.createAsyncActions('SetBlobjectCompletedForTeams')
const addParticipantsToChatActions = _utils.createAsyncActions('AddParticipantsToChat')
const removeParticipantsFromChatActions = _utils.createAsyncActions('RemoveParticipantsFromConversation')
const getBlobjectByWrappedKeyActions = _utils.createAsyncActions('GetBlobjectByWrappedKey')
const findReusableConversationActions = _utils.createAsyncActions('FindReusableConversation')

 const actionCreators = {
  getInboxActions,
  getInboxForUserActions,
  getInboxForPatientActions,
  setTeamInboxSettingsActions,
  renameChatActions,
  getMeActions,
  getBlobjectDeepActions,
  updateChatNotificationsActions,
  createConversationActions,
  markBlobjectSeenActions,
  completeBlobjectActions,
  uncompleteBlobjectActions,
  setBlobjectCompletedForTeamsActions,
  createSmsConversationActions,
  addParticipantsToChatActions,
  removeParticipantsFromChatActions,
  getBlobjectByWrappedKeyActions,
  findReusableConversationActions,
}; exports.actionCreators = actionCreators

const getInbox = (req) =>
  _helpers.wsCommand('GetInbox', getInboxActions, req, null)

const setTeamInboxSettings = (req) =>
  _helpers.wsCommand(
    'SetInboxableSettingsOnTeam',
    setTeamInboxSettingsActions,
    req,
    null,
  )

// TODO: get rid of this mess.
const parseInboxResponse = (offset) =>
  (r) => {
    const blobjectsList = Object.values(r.Blobjects || {})
    return offset
      ? blobjectsList
      : blobjectsList
        .sort((a, b) =>
          (+new Date(b.InboxSortTime)) - (+new Date(a.InboxSortTime)),
        )
  }

const getInboxForUser = (
  { OrgKey, UserKey, Offset = 0, Limit = 30 },
) =>
  _helpers.wsCommand(
    'GetInboxForUser',
    getInboxForUserActions,
    { OrgKey, UserKey, Offset, Limit },
    null,
  )
    .then(parseInboxResponse(Offset))

const getInboxForPatient = (
  { PatientKey, Offset = 0, Limit = 30 },
) =>
  _helpers.wsCommand(
    'GetInboxForPatient',
    getInboxForPatientActions,
    { PatientKey, Offset, Limit },
    null,
  )
    .then(parseInboxResponse(Offset))

const renameChat = (req) =>
  _helpers.wsCommand('ChangeBlobjectName', renameChatActions, req, null)

const getMe = () => _helpers.wsCommand('GetMe', getMeActions, {}, null)

const getBlobjectDeep = (req) =>
  _helpers.wsCommand(
    'GetBlobjectByKeyDeep',
    getBlobjectDeepActions,
    req,
    null,
  )

const updateChatNotifications = (req) =>
  _helpers.wsCommand(
    "SetNotificationSettingsOnBlobject",
    updateChatNotificationsActions,
    req,
    null,
  )

const createConversation = (req) =>
  _helpers.wsCommand(
    "CreateConversation",
    createConversationActions,
    req,
    null,
  )

const createSmsConversation = (req) =>
  _helpers.wsCommand(
    'CreateSmsConversation',
    createSmsConversationActions,
    req,
    null,
  )

const markBlobjectSeen = (req) =>
  _helpers.wsCommand(
    'MarkBlobjectSeen',
    markBlobjectSeenActions,
    { BKey: req.BKey, SeenTime: req.SeenTime || new Date() },
    null,
  )

const completeBlobject = (BKey) =>
  _helpers.wsCommand(
    'CompleteBlobject',
    completeBlobjectActions,
    { BKey, UserKey: '' },
    { BKey },
  )

const uncompleteBlobject = (BKey) =>
  _helpers.wsCommand(
    'UnCompleteBlobject',
    uncompleteBlobjectActions,
    { BKey, UserKey: '' },
    { BKey },
  )

const setBlobjectCompletedForTeams = (req) =>
  _helpers.wsCommand(
    'SetBlobjectCompletedForTeams',
    setBlobjectCompletedForTeamsActions,
    req,
    null,
  )

const addParticipantsToChat = (req) =>
  _helpers.wsCommand(
    "AddParticipantsToConversation",
    addParticipantsToChatActions,
    req,
    null,
  )

const removeParticipantsFromChat = (req) =>
  _helpers.wsCommand(
    "RemoveParticipantsFromConversation",
    removeParticipantsFromChatActions,
    req,
    null,
  )

const getBlobjectByWrappedKey = (req) =>
  _helpers.wsCommand(
    'GetBlobjectByWrappedKey',
    getBlobjectByWrappedKeyActions,
    req,
    null,
  )

const findReusableConversation = (req) =>
  _helpers.wsCommand(
    'FindReusableConversation',
    findReusableConversationActions,
    req,
    null,
  )

exports. default = {
  getInbox,
  getInboxForUser,
  getInboxForPatient,
  setTeamInboxSettings,
  renameChat,
  getMe,
  getBlobjectDeep,
  updateChatNotifications,
  createConversation,
  markBlobjectSeen,
  completeBlobject,
  uncompleteBlobject,
  setBlobjectCompletedForTeams,
  createSmsConversation,
  addParticipantsToChat,
  removeParticipantsFromChat,
  getBlobjectByWrappedKey,
  findReusableConversation,
}
