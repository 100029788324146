"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Button/Button.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Spinner = require('web/components/Spinner');

 const btnClassName = "blm-btn"; exports.btnClassName = btnClassName
 const secondaryBtnClassName = "blm-btn--secondary"; exports.secondaryBtnClassName = secondaryBtnClassName














const Button = ({
  isSecondary, isSubmit,
  isDanger, isOutlined,
  isInverted, isMinimalistic,
  isLoading, isDisabled,
  className = '', onClick, children,
}) => (
  _react2.default.createElement('button', {
    type: isSubmit ? 'submit' : 'button',
    className: _classBuilder2.default.call(void 0, 
      exports.btnClassName,
      !isSecondary && !isDanger && !isOutlined &&
      !isInverted && !isMinimalistic && 'blm-btn--primary',
      isSecondary && exports.secondaryBtnClassName,
      isDanger && "blm-btn--danger",
      isOutlined && "blm-btn--outlined",
      isInverted && "blm-btn--inverted",
      isMinimalistic && "blm-btn--minimalistic",
      className,
    ),
    onClick: onClick,
    disabled: isDisabled || isLoading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
  
    ,  isLoading && _react2.default.createElement(_Spinner.SimpleSpinner, { className: "blm-btn--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} ) 
    ,  children 
  )
)

exports. default = Button
