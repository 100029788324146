"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');



var _upgrade = require('shared/enum/upgrade');




var _upgradePlans = require('shared/constants/upgradePlans');
var _array = require('shared/services/array');
var _cost = require('shared/services/cost');















const zero = 0 

const makeTeamDesc = (
  teamItem,
  isRequested,
) => {
  if (!teamItem || !(isRequested || teamItem.Enabled)) return null
  const units = isRequested ? teamItem.UnitsUsed : teamItem.Units
  const { UnitCost = zero } = teamItem

  return {
    name: _upgradePlans.teamCommunicationPlan.name,
    desc: `${units} users ${_cost.formatDisplayCost.call(void 0, UnitCost)} per user/month`,
    iconSrc: _upgradePlans.teamCommunicationPlan.iconSrc,
    totalCost: (teamItem.UnitsUsed * UnitCost) ,
  }
}

const makeSmsDesc = (smsItem) => {
  if (!smsItem) return null

  return {
    name: _upgradePlans.SMSMessagingPlan.name,
    desc: `${smsItem.Units} contacts`,
    iconSrc: _upgradePlans.SMSMessagingPlan.iconSrc,
    totalCost: smsItem.BaseCost || zero,
  }
}

const makeExtraPhonesDesc = (phoneNumberItem) => {
  if (!phoneNumberItem) return null

  const { UnitCost, Units, FreeUnits } = phoneNumberItem
  const unitCost = UnitCost || zero
  const billableUnitsCount = Units - (FreeUnits || 0)

  if (billableUnitsCount < 1) return null

  return {
    name: _upgradePlans.extraPhoneNumberPlan.name,
    desc: `${billableUnitsCount} phone number(s)`,
    iconSrc: _upgradePlans.extraPhoneNumberPlan.iconSrc,
    totalCost: unitCost * billableUnitsCount ,
  }
}

 const usePlansReceipt = ({
  smsItem,
  teamItem,
  phoneNumberItem,
  requestedItemType,
}) => _react.useMemo.call(void 0, 
  () => {
    const teamDesc = makeTeamDesc(teamItem, requestedItemType === _upgrade.SubscriptionItemType.TEAM)
    const smsDesc = makeSmsDesc(smsItem)
    const extraPhonesDesc = makeExtraPhonesDesc(phoneNumberItem)

    return [teamDesc, smsDesc, extraPhonesDesc].filter(_array.truthy)
  },
  [smsItem, teamItem, phoneNumberItem, requestedItemType],
); exports.usePlansReceipt = usePlansReceipt
