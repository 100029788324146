"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _qs = require('qs'); var _qs2 = _interopRequireDefault(_qs);



 const getCurrentURL = () => window.location.href; exports.getCurrentURL = getCurrentURL







 const buildSubscriptionSuccessURL = (
  orgKey,
  planType,
  actionType,
) => {
  const { origin, hash } = window.location
  const params = {
    sub_orgkey: orgKey,
    sub_plan: planType,
    sub_action: actionType,
  }
  const query = _qs2.default.stringify(params)

  return `${origin}?${query}${hash}`
}; exports.buildSubscriptionSuccessURL = buildSubscriptionSuccessURL

 const parseSubscriptionSuccessURL = () => {
  const params = _qs2.default.parse(window.location.search.slice(1)) 
  const {
    sub_orgkey: orgKey,
    sub_plan: planType,
    sub_action: actionType,
  } = params

  return orgKey && planType && actionType
    ? { orgKey, planType, actionType }
    : null
}; exports.parseSubscriptionSuccessURL = parseSubscriptionSuccessURL

 const clearQS = () =>
  history.pushState({}, '', window.location.origin + window.location.hash); exports.clearQS = clearQS
