"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _useStage = require('shared/hooks/useStage'); var _useStage2 = _interopRequireDefault(_useStage);
var _noticeBar = require('web/services/context/noticeBar');

var _PageLayout = require('web/components/Settings/PageLayout'); var _PageLayout2 = _interopRequireDefault(_PageLayout);
var _Sections = require('./Sections');
var _Modals = require('./Modals');
var _Headings = require('web/components/Settings/Headings');
var _Divider = require('web/components/Settings/Divider'); var _Divider2 = _interopRequireDefault(_Divider);





const ProfilePage = ({
  me,
  gotoSettingsRoot,
}) => {
  const {
    isOpen: isAvatarPickerModalOpen,
    onOpen: onOpenAvatarPickerModal,
    onClose: onCloseAvatarPickerModal,
  } = _useToggle2.default.call(void 0, )
  const {
    isOpen: isAddEmailModalOpen,
    onOpen: onOpenAddEmailModal,
    onClose: onCloseAddEmailModal,
  } = _useToggle2.default.call(void 0, )
  const {
    isOpen: isAddPhoneModalOpen,
    onOpen: onOpenAddPhoneModal,
    onClose: onCloseAddPhoneModal,
  } = _useToggle2.default.call(void 0, )
  const {
    item: phoneNumberToVerify,
    isOpen: isVerifyPhoneModalOpen,
    onOpen: onOpenVerifyPhoneModal,
    onClose: onCloseVerifyPhoneModal,
  } = _useStage2.default()

  const myUserKey = me.UserKey
  const isCurrentUserLoading = me.NumberOfLogins === -1

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleNameSubmit = _react.useCallback.call(void 0, 
    async ({ firstName, lastName, nameSuffix }) =>
      _actions2.default.updateUserInfo({
        FirstName: firstName,
        LastName: lastName,
        PostNominals: nameSuffix,
      })
        .then(() => showSuccessBar('Name updated'))
        .catch(() => showWarningBar('Failed to update your name')),
    [showSuccessBar, showWarningBar],
  )

  return (
    _react2.default.createElement(_PageLayout2.default, { title: "Profile", onBack: gotoSettingsRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "Personal Information" )
      , _react2.default.createElement(_Sections.AvatarSection, {
        myUserKey: myUserKey,
        onOpenAvatarPickerModal: onOpenAvatarPickerModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
      , _react2.default.createElement(_Sections.NameSection, {
        key: isCurrentUserLoading ? 'loading' : myUserKey,
        firstName: me.FirstName,
        lastName: me.LastName,
        nameSuffix: me.PostNominals,
        onSubmit: handleNameSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      )
      , _react2.default.createElement(_Divider2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}} )
      , _react2.default.createElement(_Sections.EmailsSection, {
        emailsList: Object.values(me.Emails),
        onRemoveEmail: _actions2.default.removeEmail,
        onMakeEmailPrimary: _actions2.default.setPrimaryEmail,
        onVerifyEmail: _actions2.default.resendEmailConfirmation,
        onAddEmail: onOpenAddEmailModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
      , _react2.default.createElement(_Sections.PhonesSection, {
        phonesList: Object.values(me.Phones),
        onRemovePhone: _actions2.default.removePhone,
        onMakePhonePrimary: _actions2.default.setPrimaryPhone,
        onVerifyPhone: onOpenVerifyPhoneModal,
        onAddPhone: onOpenAddPhoneModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      )
      , _react2.default.createElement(_Modals.AvatarPickerModal, {
        isOpen: isAvatarPickerModalOpen,
        onClose: onCloseAvatarPickerModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
      )
      , _react2.default.createElement(_Modals.AddPhoneModal, {
        isOpen: isAddPhoneModalOpen,
        onClose: onCloseAddPhoneModal,
        onSubmit: _actions2.default.addPhone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
      )
      , _react2.default.createElement(_Modals.AddEmailModal, {
        isOpen: isAddEmailModalOpen,
        onClose: onCloseAddEmailModal,
        onSubmit: _actions2.default.addEmail, __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
      )
      , _react2.default.createElement(_Modals.VerifyPhoneModal, {
        isOpen: isVerifyPhoneModalOpen,
        onClose: onCloseVerifyPhoneModal,
        phoneNumber: phoneNumberToVerify,
        onRequestVerification: _actions2.default.resendPhoneConfirmation,
        onSubmit: _actions2.default.verifyPhone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
      )
    )
  )
}





const mapState = (state) => ({ me: _user.selectCurrentUser.call(void 0, state) })

exports. default = _reactredux.connect.call(void 0, mapState)(ProfilePage)
