"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _reduxoptimisticui = require('redux-optimistic-ui');


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _lodash = require('lodash');
var _utils = require('shared/store/utils');

var _broadcast = require('shared/enum/broadcast');





const initialState = {}

const reducer = _utils.createReducer.call(void 0, 
  initialState,
  builder => {
    builder.addCase(_actionCreators2.default.findCampaignMessagesActions.REQUEST, () => initialState)

    builder.addCase(
      _actionCreators2.default.findCampaignMessagesActions.SUCCESS,
      (s, a) => { a.payload.Messages.forEach(m => s[m.PatientKey] = m) },
    )

    builder.addCase(
      _actionCreators2.default.removePatientsFromCampaignActions.REQUEST,
      (s, a) => _lodash.omit.call(void 0, s, a.payload.PatientKeys),
    )

    builder.addCase(
      _actionCreators2.default.dequeuePatientsInCampaignActions.REQUEST,
      (s, a) => {
        a.payload.PatientKeys.forEach(patientKey => {
          const msg = s[patientKey]
          if (!msg) return
          msg.Status = _broadcast.broadcastMsgStatus.NOT_SCHEDULED
        })
      },
    )

    builder.addCase(
      _actionCreators2.default.campaignProgressNotificationAction,
      (s, a) => { a.payload.Messages.forEach(m => s[m.PatientKey] = m) },
    )

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)

    builder.addDefaultCase(s => s)
  },
)

exports. default = _reduxoptimisticui.optimistic.call(void 0, reducer)
