"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upsells/VideoRooms.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Page = require('web/components/Upsells/Page'); var _Page2 = _interopRequireDefault(_Page);

const VideoRoomsUpsells = () => (
  _react2.default.createElement(_Page2.default, {
    className: "upsells--video",
    title: "Make video calls with your patients & coworkers"       ,
    desc: "Conduct both inpatient and outpatient telehealth consults. No software install needed for patients or clients."              ,
    hint: "Want to get full access to video calls?"       ,
    imgUrl: "assets/images/upsells/videocalls-illustration.png",
    onRequest: null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
  )
)

exports. default = VideoRoomsUpsells
