"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Forms/ExternalCollaborator.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);




var _externalCollaborator = require('shared/validation/invite/externalCollaborator');

var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');









const ExternalCollaboratorForm = ({
  submitBtnLabel,
  onCancel,
  onSubmit,
  controlsClassName,
  defaultEmail,
}) => {
  const { register, handleSubmit, formState } =
    _reacthookform.useForm({
      resolver: _externalCollaborator.externalCollaboratorResolver,
      mode: 'onChange',
      defaultValues: { email: defaultEmail },
    })
  const { isValid, isSubmitting, errors } = formState

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('span', { className: "invite-contact--form-note", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, "Your contact will be invited to join BloomText so they can chat with you. We'll let you know when they join."

      )
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "Email",
        placeholder: "E.g. john@email.com" ,
        inputProps: register('email'),
        errorMessage: _optionalChain([errors, 'access', _ => _.email, 'optionalAccess', _2 => _2.message]),
        className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: submitBtnLabel,
        isPrimaryEnabled: isValid && !isSubmitting,
        onPrimaryBtnClick: handleSubmit(onSubmit),
        isPrimaryLoading: isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onCancel,
        className: controlsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      )
    )
  )
}

ExternalCollaboratorForm.propTypes = {
  submitBtnLabel: _proptypes2.default.string.isRequired,
  onCancel: _proptypes2.default.func.isRequired,
  onSubmit: _proptypes2.default.func.isRequired,
}

exports. default = ExternalCollaboratorForm
