"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/OrgSurvey/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _types = require('web/screens/Signup/types');



var _orgSurvey = require('shared/validation/org/orgSurvey');

var _OrgTypeForm = require('./OrgTypeForm'); var _OrgTypeForm2 = _interopRequireDefault(_OrgTypeForm);
var _ReferrerForm = require('./ReferrerForm'); var _ReferrerForm2 = _interopRequireDefault(_ReferrerForm);
var _FeaturesForm = require('./FeaturesForm'); var _FeaturesForm2 = _interopRequireDefault(_FeaturesForm);













const OrgSurveySection = ({ onBack, onSubmit, currentStep, gotoStep }) => {
  const [orgType, setOrgType] = _react.useState(null)
  const [referrer, setReferrer] = _react.useState(null)

  const onSaveOrgType = (data) => {
    setOrgType(data)
    gotoStep(_types.SetupSteps.ORG_SURVEY_REFERRER)
  }

  const onSaveReferrer = (data) => {
    setReferrer(data)
    gotoStep(_types.SetupSteps.ORG_SURVEY_FEATURES)
  }

  const onSaveFeatures = async (features) => {
    if (!orgType || !referrer) return

    const parsedOrgType = _orgSurvey.parseResponse.call(void 0, orgType, 'orgType')
    const parsedReferrer = _orgSurvey.parseResponse.call(void 0, referrer, 'referrer')

    return onSubmit({
      orgType: parsedOrgType,
      referrer: parsedReferrer,
      features,
    })
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  currentStep === _types.SetupSteps.ORG_SURVEY_TYPE && (
        _react2.default.createElement(_OrgTypeForm2.default, {
          defaultValues: orgType,
          onBack: onBack,
          onSubmit: onSaveOrgType, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        )
      )
      ,  currentStep === _types.SetupSteps.ORG_SURVEY_REFERRER && (
        _react2.default.createElement(_ReferrerForm2.default, {
          defaultValues: referrer,
          onBack: onBack,
          onSubmit: onSaveReferrer, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        )
      )
      ,  currentStep === _types.SetupSteps.ORG_SURVEY_FEATURES && (
        _react2.default.createElement(_FeaturesForm2.default, {
          onBack: onBack,
          onSubmit: onSaveFeatures, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        )
      )
    )
  )
}

exports. default = OrgSurveySection
