"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');








 const useEntityChat = () => {
  const [newChatEntitiesList, setNewChatEntitiesList] = _react.useState([])
  const onCreateNewChat =
    _react.useCallback.call(void 0, (x) => setNewChatEntitiesList([x]), [setNewChatEntitiesList])
  const onCloseCreateNewChat =
    _react.useCallback.call(void 0, () => setNewChatEntitiesList([]), [setNewChatEntitiesList])

  return { newChatEntitiesList, onCreateNewChat, onCloseCreateNewChat }
}; exports.useEntityChat = useEntityChat
