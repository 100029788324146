"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/ScheduleSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');









var _team = require('shared/selectors/team');
var _time = require('shared/services/time');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');

var _EditScheduleModal = require('./EditScheduleModal'); var _EditScheduleModal2 = _interopRequireDefault(_EditScheduleModal);
var _components = require('web/screens/Directory/TeamPage/components');
var _components3 = require('web/screens/Directory/components');
var _components5 = require('../components');







const ScheduleSection = ({
  autoReplyTimeZone,
  areTeamHoursSet,
  teamHoursList,
  onUpdateInfo,
  isDisabled,
  teamKey,
}) => {
  const {
    isOpen: isEditModalOpen,
    onOpen: onOpenEditModal,
    onClose: onCloseEditModal,
  } = _useToggle2.default.call(void 0, )

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleSubmit = _react.useCallback.call(void 0, 
    (data) => onUpdateInfo(data)
      .then(onCloseEditModal)
      .then(() => showSuccessBar('Successfully updated hours'))
      .catch(() => showWarningBar('Failed to update hours')),
    [onUpdateInfo, onCloseEditModal, showSuccessBar, showWarningBar],
  )

  return (
    _react2.default.createElement(_components.GenericSection, { title: "Business Hours" , isDisabled: isDisabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      , _react2.default.createElement(_components5.ScheduleInfoItem, { title: "Time Zone" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        ,  autoReplyTimeZone
          ? _react2.default.createElement('span', { className: "team-page--schedule--value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
              ,  _time.timezoneNames[autoReplyTimeZone] 
            )
          : _react2.default.createElement('span', { className: "team-page--schedule--empty-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "No time zone set"

            )
        
      )
      , _react2.default.createElement(_components5.ScheduleInfoItem, { title: "Hours", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        ,  !areTeamHoursSet && (
          _react2.default.createElement('span', { className: "team-page--schedule--empty-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}, "No hours set"

          )
        )
        ,  areTeamHoursSet && teamHoursList.map((d, i) => {
          const from = d && _time.formatDisplayTime.call(void 0, d.AvailableFrom, 'HH:mm:ss')
          const until = d && _time.formatDisplayTime.call(void 0, d.AvailableUntil, 'HH:mm:ss')
          return (
            _react2.default.createElement(_components.InfoItem, {
              key: i,
              name: _nullishCoalesce(_time.dayNames[i], () => ( '')),
              value: d ? `${from} - ${until}` : 'Closed',
              className: "team-page--schedule--schedule-table",
              titleClassName: "team-page--schedule--day-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
            )
          )
        })
        , _react2.default.createElement(_components3.InfoBtn, {
          label: "Edit hours" ,
          onClick: onOpenEditModal,
          iconSrc: "/assets/icons/icon-edit-purple.svg",
          className: "team-page--schedule--edit-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        )
      )
      , _react2.default.createElement(_EditScheduleModal2.default, {
        isOpen: isEditModalOpen,
        onClose: onCloseEditModal,
        teamKey: teamKey,
        onSubmit: handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      )
    )
  )
}







const mapState = (state, { teamKey }) => ({
  autoReplyTimeZone: _nullishCoalesce(_optionalChain([_team.selectTeamByKey.call(void 0, state, teamKey), 'optionalAccess', _ => _.AutoReplyTimeZone]), () => ( null)),
  teamHoursList: _team.selectTeamHoursList.call(void 0, state, teamKey),
  areTeamHoursSet: _team.selectAreTeamHoursSet.call(void 0, state, teamKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(ScheduleSection)
