"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _entity = require('shared/enum/entity');







 const noRelsTitle = "Member, BloomText Network"; exports.noRelsTitle = noRelsTitle
const noop = () => ''

 const makeSpecificOrgTitleUserDescription =
  orgKey => user => _optionalChain([user, 'access', _ => _.Relationships, 'access', _2 => _2[orgKey], 'optionalAccess', _3 => _3.Title]) || 'Member'; exports.makeSpecificOrgTitleUserDescription = makeSpecificOrgTitleUserDescription

 const makeFullSpecificOrgTitleUserDescription =
  orgKey => (user, orgsMap) => {
    const org = _optionalChain([orgsMap, 'optionalAccess', _4 => _4[orgKey]])
    const orgName = _optionalChain([org, 'optionalAccess', _5 => _5.Name])
    const title = exports.makeSpecificOrgTitleUserDescription.call(void 0, orgKey)(user)
    return orgName ? `${title}, ${orgName}` : title
  }; exports.makeFullSpecificOrgTitleUserDescription = makeFullSpecificOrgTitleUserDescription

 const makeOrgTitleUserDescription = (user, orgsMap) => {
  const rels = user.Relationships || {}
  const relsList = Object.values(rels)
  const relsCount = relsList.length

  if (!relsCount) return exports.noRelsTitle
  if (relsCount === 1) {
    const { Title: title, OrgKey: orgKey } = relsList[0]
    const org = _optionalChain([orgsMap, 'optionalAccess', _6 => _6[orgKey]])

    if (!title) return _optionalChain([org, 'optionalAccess', _7 => _7.Name]) ? `Member, ${org.Name}` : exports.noRelsTitle

    return _optionalChain([org, 'optionalAccess', _8 => _8.Name]) ? `${title}, ${org.Name}` : title
  }
  if (relsCount === 2) {
    return relsList
      .reduce((acc, r) => {
        const name = _optionalChain([orgsMap, 'optionalAccess', _9 => _9[r.OrgKey], 'optionalAccess', _10 => _10.Name])
        if (name) acc.push(name)
        return acc
      }, [])
      .join(', ')
  }

  return `Member of ${relsCount} organizations`
}; exports.makeOrgTitleUserDescription = makeOrgTitleUserDescription

 const makeContactUserDescription = u => u.Email; exports.makeContactUserDescription = makeContactUserDescription




 const mapUserToEntity =
  (orgsMap, makeDescription = noop) =>
    u => ({
      entityKey: u.UserKey,
      type: _entity.ENTITY_TYPES.USER,
      name: u.Name,
      description: makeDescription(u, orgsMap),
      wrappedEntity: u,
    }); exports.mapUserToEntity = mapUserToEntity
