"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/GenericItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');
var _entity = require('shared/services/entity');

var _Avatar = require('web/components/Avatar');
var _NameBadge = require('web/components/NameBadge'); var _NameBadge2 = _interopRequireDefault(_NameBadge);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const ListItem = ({ entity, orgKey, isViewed }) => {
  if (!entity) return null

  return (
    _react2.default.createElement('div', {
      role: "button",
      onClick: () => _actions2.default.gotoEntityPage(entity, orgKey),
      className: _classBuilder2.default.call(void 0, "list-item", isViewed && 'active'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    
      , _react2.default.createElement(_Avatar.EntityAvatar, { entity: entity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
      , _react2.default.createElement('div', { className: "list-item--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , _react2.default.createElement('div', { className: "list-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          , _react2.default.createElement('span', { className: "list-item--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
            ,  entity.name 
            ,  _entity.isEntityUser.call(void 0, entity) &&
              _user.isUserAdminInOrg.call(void 0, entity.wrappedEntity, orgKey) && (
              _react2.default.createElement(_NameBadge2.default, { label: "Admin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} )
            )
          )
        )
        , _react2.default.createElement('div', { className: "list-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          , _react2.default.createElement('span', { className: "list-item--detail", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}},  entity.description )
        )
      )
    )
  )
}

exports. default = ListItem
