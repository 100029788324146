"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/PreCall.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);





var _videoRoom = require('web/selectors/videoRoom');








var _videoCall = require('web/selectors/videoCall');
var _videoCall3 = require('web/services/videoCall');

var _VideoCall = require('web/components/VideoCall');
var _RoomHeader = require('web/components/VideoCall/RoomHeader'); var _RoomHeader2 = _interopRequireDefault(_RoomHeader);
var _Modals = require('web/components/VideoCall/Modals');

class PreCallScreen extends _react2.default.Component {constructor(...args) { super(...args); PreCallScreen.prototype.__init.call(this);PreCallScreen.prototype.__init2.call(this);PreCallScreen.prototype.__init3.call(this);PreCallScreen.prototype.__init4.call(this);PreCallScreen.prototype.__init5.call(this);PreCallScreen.prototype.__init6.call(this);PreCallScreen.prototype.__init7.call(this);PreCallScreen.prototype.__init8.call(this);PreCallScreen.prototype.__init9.call(this); }
  __init() {this.state = {
    preCallStream: null,
    isNamePromptModalOpen: false,
  }}

  __init2() {this.openNamePromptModal = () => this.setState({ isNamePromptModalOpen: true })}
  __init3() {this.closeNamePromptModal = () => this.setState({ isNamePromptModalOpen: false })}
  __init4() {this.streamManager = null}

  __init5() {this.updateStream = s => this.setState({ preCallStream: s })}

  __init6() {this.componentDidUpdate = prevProps => {
    const { currentCamDeviceId, isCamOn } = this.props

    if (
      prevProps.currentCamDeviceId !== currentCamDeviceId ||
      prevProps.isCamOn !== isCamOn
    ) {
      this.streamManager.update({
        isCamOn,
        camDeviceId: currentCamDeviceId,
        useCam: true,
      })
        .then(this.updateStream)
        .catch(() => this.updateStream(null))
    }
  }}

  __init7() {this.componentDidMount = () => {
    const { currentCamDeviceId, isCamOn } = this.props

    this.streamManager = new (0, _videoCall3.StreamManager)({
      camDeviceId: currentCamDeviceId,
      useCam: true,
    })

    this.streamManager
      .update({ isCamOn })
      .then(this.updateStream)
      .catch(() => this.updateStream(null))
  }}

  __init8() {this.componentWillUnmount = () => this.streamManager.stop()}

  __init9() {this.handleUserNameChange = userName => {
    this.closeNamePromptModal()
    _actions2.default.updateAnonymousUser({ userName })
    _actions2.default.updateVideoRoomUserName({
      RoomUserKey: this.props.currentRoomUserKey,
      RoomUserName: userName,
    })
  }}

  render () {
    const {
      isMicOn, isCamOn, isCamCycleAvailable,
      areCamIssuesPresent, areMicIssuesPresent,
      gotoCallSetup, gotoCall, anonymousUserName,
      currentRoomUserKey,
      roomName, roomLink, onRoomLinkCopy,
      openMobileMoreOptionsModal,
    } = this.props

    const { preCallStream, isNamePromptModalOpen } = this.state

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_RoomHeader2.default, {
          roomName: roomName,
          roomLink: roomLink,
          onRoomLinkCopy: onRoomLinkCopy,
          openMobileMoreOptionsModal: openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        )
        , _react2.default.createElement(_VideoCall.PreCall, {
          roomName: roomName,
          roomLink: roomLink,
          userName: anonymousUserName,
          currentRoomUserKey: currentRoomUserKey,
          onRoomLinkCopy: onRoomLinkCopy,
          openMobileMoreOptionsModal: openMobileMoreOptionsModal,
          openNamePromptModal: this.openNamePromptModal,
          isMicOn: isMicOn,
          isCamOn: isCamOn,
          isCamCycleAvailable: isCamCycleAvailable,
          onCycleCam: _actions2.default.cycleCam,
          onToggleMic: _actions2.default.toggleMic,
          onToggleCam: _actions2.default.toggleCam,
          gotoCall: gotoCall,
          gotoCallSetup: gotoCallSetup,
          localStream: preCallStream,
          areMicIssuesPresent: areMicIssuesPresent,
          areCamIssuesPresent: areCamIssuesPresent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        )
        , _react2.default.createElement(_Modals.NamePromptModal, {
          isOpen: isNamePromptModalOpen,
          userName: anonymousUserName,
          onProceed: this.handleUserNameChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
        )
      )
    )
  }
}

const mapState = state => {
  const roomUser = _videoRoom.selectCurrentVideoRoomUser.call(void 0, state)
  return {
    roomKey: _videoRoom.selectCurrentVideoRoomKey.call(void 0, state),
    roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
    roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
    currentMicDeviceId: _videoCall.selectCurrentMicDeviceId.call(void 0, state),
    currentCamDeviceId: _videoCall.selectCurrentCamDeviceId.call(void 0, state),
    isCamOn: _videoCall.selectIsCamOn.call(void 0, state),
    isMicOn: _videoCall.selectIsMicOn.call(void 0, state),
    areMicIssuesPresent: _videoCall.selectAreMicIssuesPresent.call(void 0, state),
    areCamIssuesPresent: _videoCall.selectAreCamIssuesPresent.call(void 0, state),
    isCamCycleAvailable: _videoCall.selectIsCamCycleAvailable.call(void 0, state),
    anonymousUserName: _videoRoom.selectAnonymousUserName.call(void 0, state),
    currentRoomUserKey: roomUser ? roomUser.RoomUserKey : null,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(PreCallScreen)
