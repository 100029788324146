"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tooltip/ControlledTooltip.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





var _common = require('web/components/Tooltip/common');

 class ControlledTooltip extends _react2.default.Component {constructor(...args) { super(...args); ControlledTooltip.prototype.__init.call(this);ControlledTooltip.prototype.__init2.call(this);ControlledTooltip.prototype.__init3.call(this);ControlledTooltip.prototype.__init4.call(this);ControlledTooltip.prototype.__init5.call(this);ControlledTooltip.prototype.__init6.call(this);ControlledTooltip.prototype.__init7.call(this);ControlledTooltip.prototype.__init8.call(this);ControlledTooltip.prototype.__init9.call(this);ControlledTooltip.prototype.__init10.call(this); }
  __init() {this.state = {
    tooltipPosition: {},
    tooltipArrowPosition: {},
  }}

  __init2() {this.handleTooltipClick = e =>
    !this.props.isDismissibleByClick && e.stopPropagation()}

  __init3() {this.handleClickElsewhere = () => this.props.onClose()}

  // To prevent it from triggering by
  // events that are happening at the
  // very moment component is mounted.
  __init4() {this.addEventListener = () => setTimeout(
    () => window.addEventListener('click', this.handleClickElsewhere), 0,
  )}

  __init5() {this.removeEventListener = () =>
    window.removeEventListener('click', this.handleClickElsewhere)}

  __init6() {this.componentDidUpdate = prevProps => {
    const {
      isOpen, domTarget, content, isDismissibleByClickElsewhere,
    } = this.props
    if (isDismissibleByClickElsewhere) {
      if (prevProps.isOpen && !isOpen) this.removeEventListener()
      if (!prevProps.isOpen && isOpen) this.addEventListener()
    }
    if (
      prevProps.domTarget !== domTarget ||
      prevProps.content !== content ||
      !prevProps.isOpen && isOpen
    ) { this.updateTooltipPosition() }
  }}

  __init7() {this.updateTooltipPosition = () => {
    const { hideArrow, place, offset, fixedPosition, fullContentWidth } = this.props

    const domTarget = this.props.domTarget || this.target

    if (!domTarget) return

    const tooltipPosition = fixedPosition ||
      _common.getTooltipPosition.call(void 0, domTarget, this.tooltip, place, hideArrow, offset)
    if (fullContentWidth) {
      this.setState({ domTargetRect: domTarget.getBoundingClientRect() })
    }

    const tooltipArrowPosition = _common.getToolTipArrowPosition.call(void 0, 
      domTarget, tooltipPosition, place,
    )

    this.setState({ tooltipPosition, tooltipArrowPosition })
  }}

  componentDidMount () {
    const { isOpen, isDismissibleByClickElsewhere } = this.props
    if (isOpen) {
      isDismissibleByClickElsewhere && this.addEventListener()
      this.updateTooltipPosition()
    }
  }

  __init8() {this.componentWillUnmount = () => this.removeEventListener()}

  __init9() {this.setTooltipRef = r => this.tooltip = r}
  __init10() {this.setTargetRef = r => this.target = r}

  render () {
    const {
      children, isOpen, className, tooltipClassName,
      content, isAnimated, hasCloseButton, onClose,
      hideArrow,
      fullContentWidth,
    } = this.props
    const { tooltipPosition, tooltipArrowPosition, domTargetRect } = this.state

    const rootClassName = _classBuilder2.default.call(void 0, 'blm-tooltip--container', className)
    const tooltipBodyClassName = _classBuilder2.default.call(void 0, 
      'blm-tooltip',
      _common.getTooltipTypeClass.call(void 0, this.props),
      { hidden: !isOpen, animated: isAnimated },
      tooltipClassName,
    )

    const bodyStyle = fullContentWidth
      ? { ...tooltipPosition, width: _optionalChain([domTargetRect, 'optionalAccess', _ => _.width]) }
      : tooltipPosition

    return (
      _react2.default.createElement('div', {
        'aria-live': "assertive",
        className: rootClassName,
        ref: this.setTargetRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
      
        , children
        ,  content && (_react2.default.createElement('div', {
          'aria-hidden': !isOpen,
          role: "alertdialog",
          className: tooltipBodyClassName,
          style: bodyStyle,
          ref: this.setTooltipRef,
          onClick: this.handleTooltipClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
        
          ,  hasCloseButton && (
            _react2.default.createElement('div', {
              'aria-label': "Close",
              role: "button",
              className: "blm-tooltip--close-btn",
              onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
            )
          )
          ,  !hideArrow && (
            _react2.default.createElement('div', { className: "blm-tooltip--arrow", style: tooltipArrowPosition, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}} )
          )
          , content
        ))
        
      )
    )
  }

  static __initStatic() {this.propTypes = {
    ..._common.propTypes,
    isOpen: _proptypes2.default.bool,
    isDismissibleByClick: _proptypes2.default.bool,
    isDismissibleByClickElsewhere: _proptypes2.default.bool,
    isAnimated: _proptypes2.default.bool,
    hasCloseButton: _proptypes2.default.bool,
    domTarget: _proptypes2.default.instanceOf(Element),
  }}

  static __initStatic2() {this.defaultProps = {
    ..._common.defaultProps,
    isOpen: false,
    isDismissibleByClick: false,
    isDismissibleByClickElsewhere: true,
    isAnimated: false,
    hasCloseButton: false,
  }}
} ControlledTooltip.__initStatic(); ControlledTooltip.__initStatic2(); exports.default = ControlledTooltip;
