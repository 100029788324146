"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/TemplateEntityScreen.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







const TemplateEntityScreen = ({ iconSrc, title, desc }) => (
  _react2.default.createElement('div', { className: "template-entity", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    , _react2.default.createElement('div', { className: "template-entity--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      , _react2.default.createElement('div', { className: "template-entity--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , _react2.default.createElement('img', { src: iconSrc, alt: title, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}} )
      )
      ,  title && _react2.default.createElement('h1', { className: "template-entity--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}},  title ) 
      ,  desc && _react2.default.createElement('span', { className: "template-entity--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}},  desc ) 
    )
  )
)

exports. default = TemplateEntityScreen
