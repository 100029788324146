"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/List/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _EmptyRoomsList = require('web/components/VideoRooms/List/EmptyRoomsList'); var _EmptyRoomsList2 = _interopRequireDefault(_EmptyRoomsList);
var _RoomPreview = require('web/screens/VideoRooms/List/RoomPreview'); var _RoomPreview2 = _interopRequireDefault(_RoomPreview);

exports. default = ({
  gotoRoom,
  roomsList,
  currentRoomKey,
  onArchive, onUnarchive,
}) => {
  if (roomsList.length === 0) { return _react2.default.createElement(_EmptyRoomsList2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} ) }

  return (
    _react2.default.createElement('div', { className: "meeting-rooms--rooms-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , 
        roomsList.map(r => {
          const isActive = currentRoomKey === r.RoomKey
          return (
            _react2.default.createElement(_RoomPreview2.default, {
              key: r.RoomKey,
              roomKey: r.RoomKey,
              gotoRoom: gotoRoom,
              onPick: () => !isActive && gotoRoom(r.RoomKey),
              onToggleArchivation: () => r.Archived
                ? onUnarchive(r.RoomKey)
                : onArchive(r.RoomKey)
              ,
              isActive: isActive,
              isArchived: r.Archived,
              roomName: r.DisplayName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
            )
          )
        })
      
    )
  )
}
