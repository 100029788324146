"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _redux = require('redux');
var _store = require('../store');

/* call.js
 *
 * actions to encapsulate our a frontend ongoing "call". This is currently a purely frontend
 * idea but is designed to link cleanly into a backend representation.
 */

const startCallAction = _utils.createAction.call(void 0, 'START_CALL', (key) => ({ payload: { key } }))
const endCallAction = _utils.createAction.call(void 0, 'END_CALL')
const minimizeCallAction = _utils.createAction.call(void 0, 'MINIMIZE_CALL')
const returnToCallCallAction = _utils.createAction.call(void 0, 'RETURN_TO_CALL')

 const actionCreators = {
  startCallAction,
  endCallAction,
  minimizeCallAction,
  returnToCallCallAction,
}; exports.actionCreators = actionCreators

exports. default = _redux.bindActionCreators.call(void 0, 
  {
    startCall: startCallAction,
    endCall: endCallAction,
    minimizeCall: minimizeCallAction,
    returnToCall: returnToCallCallAction,
  },
  _store.dispatch,
)
