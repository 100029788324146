"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }



var _entity = require('shared/enum/entity');
var _phone = require('shared/services/phone');



const noop = () => ''
 const makeRawMembersCountTeamDescription = team => {
  const membersCount = Object.values(team.Members).length
  if (membersCount === 1) return '1 Member'
  return `${membersCount} Members`
}; exports.makeRawMembersCountTeamDescription = makeRawMembersCountTeamDescription

 const makeMembersCountTeamDescription = (team, orgsMap) => {
  const org = _optionalChain([orgsMap, 'optionalAccess', _ => _[team.OrgKey]])
  if (!_optionalChain([org, 'optionalAccess', _2 => _2.Name])) return ''

  const membersCount = Object.values(team.Members).length

  if (!membersCount) return org.Name
  if (membersCount === 1) return `${org.Name} - 1 Member`
  return `${org.Name} - ${membersCount} Members`
}; exports.makeMembersCountTeamDescription = makeMembersCountTeamDescription

 const makeContactTeamDescription = team =>
  _phone.formatPhoneNumber.call(void 0, team.PhoneNumber) || ''; exports.makeContactTeamDescription = makeContactTeamDescription

 const makeOrgNameTeamDescription = (team, orgsMap) =>
  _optionalChain([orgsMap, 'optionalAccess', _3 => _3[team.OrgKey], 'optionalAccess', _4 => _4.Name]) || ''; exports.makeOrgNameTeamDescription = makeOrgNameTeamDescription





 const mapTeamToEntity =
  (orgsMap, makeDescription = noop) =>
    team => ({
      entityKey: team.TeamKey,
      type: _entity.ENTITY_TYPES.TEAM,
      name: team.Name,
      description: makeDescription(team, orgsMap),
      wrappedEntity: team,
    }); exports.mapTeamToEntity = mapTeamToEntity
