"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/ProgressBar/FilterSelect/Option.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _broadcast = require('shared/enum/broadcast');
var _Form = require('web/components/Form');

const labelsMap = {
  ALL: 'All',
  [_broadcast.broadcastMsgStatus.NOT_SCHEDULED]: 'Not scheduled',
  [_broadcast.broadcastMsgStatus.QUEUED]: 'Queued',
  [_broadcast.broadcastMsgStatus.PROCESSING]: 'Processing',
  [_broadcast.broadcastMsgStatus.COMPLETED]: 'Completed',
  [_broadcast.broadcastMsgStatus.FAILED]: 'Failed',
}

const Avatar = () => null

const Option = props => (
  _react2.default.createElement(_Form.GenericEntityOption, {
    className: props.isPreviewOption
      ? 'broadcast--progress-bar--filter-preview-option'
      : ''
    ,
    name: labelsMap[props.option],
    Avatar: Avatar,
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
  )
)

exports. default = Option
