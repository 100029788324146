"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/NewChatListItemPlaceholder.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const NewChatListItemPlaceholder = ({
  isSelected,
  onClick,
  onCancel,
  createdChatName,
}) => {
  const handleCancel = e => {
    e.stopPropagation()
    onCancel()
  }

  return (
    _react2.default.createElement('div', {
      role: "button",
      onClick: onClick,
      className: _classBuilder2.default.call(void 0, 
        "list-item",
        "inbox-list--new-chat-placeholder",
        isSelected && "inbox-list--new-chat-placeholder--selected",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    
      , _react2.default.createElement('div', { className: "inbox-list--new-chat-placeholder--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        , _react2.default.createElement('img', { src: "/assets/icons/icon-edit-white.svg", alt: "Edit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
      )
      , _react2.default.createElement('span', { className: "inbox-list--new-chat-placeholder--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        ,  createdChatName || 'New message' 
      )
      , _react2.default.createElement('div', {
        role: "button",
        onClick: handleCancel,
        className: "inbox-list--new-chat-placeholder--action", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      
        , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}, "highlight_off")
        , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "Cancel")
      )
    )
  )
}

exports. default = NewChatListItemPlaceholder
