"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/EmptyRoom.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

// This screen *shouldn't* be available for mobile.
// Thus, we avoid choosing between mobile/desktop versions of a modal.
exports. default = ({
  areRoomsAvailable,
  openDesktopNewRoomModal,
}) => (
  _react2.default.createElement('div', { className: "meeting-room--empty-room", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
    , _react2.default.createElement('div', { className: "meeting-room--empty-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , _react2.default.createElement('div', { className: "meeting-room--empty-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , _react2.default.createElement('img', { src: "/assets/icons/video/camera-lightgrey.svg", alt: "+", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} )
      )
      , _react2.default.createElement('h1', { className: "meeting-room--empty-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, "Video Meetings" )
      , _react2.default.createElement('span', { className: "meeting-room--empty-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        ,  areRoomsAvailable
          ? 'All your meetings are listed on the left.\nChoose one to see more details.'
          : 'Create and share a meeting to let your coworkers and patients join high-quality video calls with you.'
        
      )
      , !areRoomsAvailable &&
        _react2.default.createElement('a', {
          onClick: openDesktopNewRoomModal,
          className: "meeting--btn meeting-room--empty-create-btn" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        
          , _react2.default.createElement('img', { src: "/assets/icons/video/cam-white.svg", alt: "+", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} ), "New Meeting"

        )
      
    )
  )
)
