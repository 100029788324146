"use strict";Object.defineProperty(exports, "__esModule", {value: true});

 const findLast = (arr, pred) => {
  for (let i = arr.length - 1; i > 0; i--) {
    if (pred(arr[i], i, arr)) return arr[i]
  }
  return null
}; exports.findLast = findLast

 const splitBy = (arr, pred) =>
  arr && arr.reduce(
    (acc, x) => {
      if (pred(x)) acc[0].push(x)
      else acc[1].push(x)
      return acc
    },
    [
      [] ,
      [] ,
    ] ,
  ); exports.splitBy = splitBy


 const groupBy = (arr, classify) =>
  arr && arr.reduce(
    (acc, x) => {
      const groupName = classify(x)
      if (!groupName) return acc

      if (!acc[groupName]) acc[groupName] = []
      acc[groupName].push(x)
      return acc
    },
    {} ,
  ); exports.groupBy = groupBy


 const truthy = (x) => !!x; exports.truthy = truthy

 const safeMapReduce = (
  arr,
  map,
) => arr.reduce(
    (acc, x) => {
      const y = map(x)
      if (y) acc.push(y)
      return acc
    },
  [] ,
  ); exports.safeMapReduce = safeMapReduce
