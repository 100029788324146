"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ChatParticipantsList/Section.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);






var _Tooltip = require('web/components/Tooltip');
var _Avatar = require('web/components/Avatar');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);











const Section = ({
  title,
  entitiesList,
  options,
  openedOptionsEntityKey,
  onGotoEntityPage,
  onOpenOptions,
  onCloseOptions,
}) => {
  if (!entitiesList.length) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "inbox-participants--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}},  title )
      ,  entitiesList.map(e => {
        const content = (
          _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
            ,  options.map(([title, action], i) => (
              _react2.default.createElement(_Tooltip.TooltipItem, {
                key: i,
                onClick: () => { action(e); onCloseOptions() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
              
                ,  title 
              )
            ))
          )
        )

        return (
          _react2.default.createElement('div', {
            key: e.entityKey,
            role: onGotoEntityPage ? "button" : 'none',
            onClick: () => _optionalChain([onGotoEntityPage, 'optionalCall', _ => _(e)]),
            className: _classBuilder2.default.call(void 0, 
              "inbox-participants--item",
              onGotoEntityPage && "inbox-participants--item--clickable",
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          
            , _react2.default.createElement(_Avatar.EntityAvatar, { entity: e, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
            , _react2.default.createElement('div', { className: "inbox-participants--item--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
              , _react2.default.createElement('div', { className: "inbox-participants--item--metadata--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
                , e.name
              )
              , _react2.default.createElement('div', {
                title: e.description,
                className: "inbox-participants--item--metadata--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
              
                , e.description
              )
            )
            ,  options.length > 0 && (
              _react2.default.createElement(_Tooltip.ControlledTooltip, {
                hideArrow: true,
                isDismissibleByClickElsewhere: true,
                onClose: onCloseOptions,
                isOpen: e.entityKey === openedOptionsEntityKey,
                place: "bottom",
                content: content,
                className: "inbox-participants--options", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
              
                , _react2.default.createElement('img', {
                  onClick: evt => {
                    evt.stopPropagation()
                    onOpenOptions(e.entityKey)
                  },
                  src: "/assets/icons/header/ellipsis-dark.svg",
                  alt: "...", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
                )
              )
            )
          )
        )
      })
    )
  )
}

exports. default = Section
