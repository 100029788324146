"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/NewRoom/OwnerOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _entity = require('shared/services/entity');

var _Form = require('web/components/Form');
var _Avatar = require('web/components/Avatar');





const OwnerAvatar = ({ option, ...props }) => {
  const e = option 
  return _entity.isEntityTeam.call(void 0, e)
    ? _react2.default.createElement(_Avatar.TeamAvatar, { teamKey: e.wrappedEntity.TeamKey, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} )
    : _react2.default.createElement(_Avatar.UserAvatar, { userKey: e.wrappedEntity.UserKey, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
}

const OwnerOption =
  props => (
    _react2.default.createElement(_Form.GenericEntityOption, {
      Avatar: OwnerAvatar,
      name: props.option.name,
      description: props.option.description,
      ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    )
  )

exports. default = OwnerOption
