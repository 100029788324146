"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/Modals/NewRoom.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');






var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _myRelations = require('shared/selectors/myRelations');
var _session = require('shared/selectors/session');
var _user = require('shared/selectors/user');
var _newRoom = require('shared/validation/videoRoom/newRoom');

var _NewRoom = require('web/components/VideoRooms/NewRoom');
var _noticeBar = require('web/services/context/noticeBar');
var _entity = require('shared/services/entity');







const NewRoomModalContainer = ({
  isOpen,
  onClose,
  isMobile,
  defaultOwnerKey,
  orgsList,
  teamsList,
  entitiesMap,
  hasVideoFeature,
}) => {
  const { control, resetField, handleSubmit } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _newRoom.newVideoRoomResolver,
      defaultValues: { roomName: '' },
    })

  _react.useEffect.call(void 0, 
    () => { resetField('ownerKey', { defaultValue: defaultOwnerKey }) },
    [resetField, defaultOwnerKey],
  )

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const submit = _react.useCallback.call(void 0, 
    async data => {
      const ownerEntity = entitiesMap[data.ownerKey ] 
      if (!ownerEntity) throw new Error('Invalid Video Room owner')

      const AdminUsers = _entity.isEntityTeam.call(void 0, ownerEntity)
        ? []
        : [{
            UserKey: ownerEntity.wrappedEntity.UserKey,
            OrgKey: ownerEntity.wrappedEntity.OrgKey,
          }]

      const AdminTeamKeys = _entity.isEntityTeam.call(void 0, ownerEntity)
        ? [ownerEntity.wrappedEntity.TeamKey]
        : []

      return _actions2.default.createVideoRoom({
        AdminUsers,
        AdminTeamKeys,
        RoomListable: true,
        OrgKey: ownerEntity.wrappedEntity.OrgKey,
        RoomName: data.roomName,
        RequireGuestApproval: true,
      })
        .then(onClose)
        .catch(e => {
          showWarningBar('Failed to create a video room')
          throw e
        })
    },
    [entitiesMap, showWarningBar, onClose],
  )

  const entitiesList = _react.useMemo.call(void 0, 
    () => (orgsList ).concat(teamsList),
    [orgsList, teamsList],
  )

  if (!isOpen) return null
  const NewRoomModal = isMobile ? _NewRoom.MobileModal : _NewRoom.DesktopModal

  return (
    _react2.default.createElement(NewRoomModal, {
      isOpen: isOpen,
      onClose: onClose,
      control: control,
      onSubmit: handleSubmit(submit),
      entitiesList: entitiesList,
      entitiesMap: entitiesMap,
      hasVideoFeature: hasVideoFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
    )
  )
}









const mapState = (state) => {
  const { orgsList, teamsList, entitiesMap } = _myRelations.selectMyEntities.call(void 0, state)
  const selectedTeamKey = _session.selectSelectedTeamKey.call(void 0, state)

  const defaultOwnerKey = selectedTeamKey
    || _optionalChain([orgsList, 'access', _ => _[0], 'optionalAccess', _2 => _2.wrappedEntity, 'access', _3 => _3.OrgKey])
    || _optionalChain([teamsList, 'access', _4 => _4[0], 'optionalAccess', _5 => _5.wrappedEntity, 'access', _6 => _6.TeamKey])

  const hasVideoFeature = _user.selectCurrentUserHasVideoV2Feature.call(void 0, state)

  return {
    defaultOwnerKey,
    teamsList,
    orgsList,
    entitiesMap,
    hasVideoFeature,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(NewRoomModalContainer)
