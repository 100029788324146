"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _errors = require('shared/errors');









 class Fetch {
  
  constructor (baseURL) {;Fetch.prototype.__init.call(this);
    this.baseURL = baseURL
  }

   __init() {this.fetch = async (
    url,
    method = "GET",
    data,
    headerDispatch,
    signal,
  ) => {
    let body
    if (data) {
      body = new FormData()
      for (const name in data) body.append(name, data[name] )
    }

    return fetch(`${this.baseURL}${url}`, {
      credentials: 'include',
      method,
      signal,
      body,
    }).then(response => {
      if (headerDispatch && response.headers.get('Set-Cookie')) {
        /* Note to posterity: this code isn't really designed to run anywhere that isn't
         * an advanced browser or react native environment, and doesn't really matter outside of
         * react-native for android since this is the only place where reducer-stored cookies
         * are significant.
         */
        headerDispatch({
          type: "SET_LOGIN_COOKIES",
          setCookieHeader: response.headers.get("Set-Cookie") || '',
          url: response.url,
        })
      }

      if (response.status === 204) return { Error: false }
      if (response.status === 429) return { Error: true, ServerError: false, Transient: true }
      if (response.status >= 200 && response.status < 300) return response.json()
      if (_optionalChain([response, 'access', _6 => _6.headers, 'access', _7 => _7.get, 'call', _8 => _8("content-type"), 'optionalAccess', _9 => _9.includes, 'call', _10 => _10("application/json")])) {
        return response.json()
      }

      return Promise.reject(new (0, _errors.RestError)(method, url, response))
    })
      .then(data => data.Error || data.ServerError
        ? Promise.reject(new (0, _errors.RestError)(method, url, data))
        : data,
      )
      .catch((e) => {
        if (!(e instanceof _errors.RestError)) { // 'Network request failed'
          e = new (0, _errors.RestError)(method, url, { Error: true, Transient: true })
        }
        return Promise.reject(e)
      })
  }}
} exports.Fetch = Fetch;
