"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperOrganizationScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _alertReducer = require('shared/reducers/alertReducer');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _SuperTableTR = require('web/components/Super/SuperTableTR'); var _SuperTableTR2 = _interopRequireDefault(_SuperTableTR);
var _SuperMoveUserToAnotherOrgModal = require('web/screens/Super/SuperMoveUserToAnotherOrgModal'); var _SuperMoveUserToAnotherOrgModal2 = _interopRequireDefault(_SuperMoveUserToAnotherOrgModal);



var _SuperSubscriptions = require('web/screens/Super/SuperSubscriptions');
var _SuperTeamModal = require('./SuperTeamModal'); var _SuperTeamModal2 = _interopRequireDefault(_SuperTeamModal);
var _SuperTeamTable = require('web/components/Super/SuperTeamTable'); var _SuperTeamTable2 = _interopRequireDefault(_SuperTeamTable);
var _uuid = require('shared/services/uuid');
var _log = require('shared/log');

const UserResult = (props) => {
  const relatedOrg = Object.values(props.user.Relationships).find((rel) => rel.OrgKey === props.orgKey)
  return (
      _react2.default.createElement(_SuperTableTR2.default, { to: `/super/users/${props.user.UserKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, props.user.UserKey)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, props.user.FirstName)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, props.user.LastName)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, relatedOrg.Title)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, relatedOrg.Type)
        , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, relatedOrg.Pending ? 'yes' : 'no')
        , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, props.user.Activated ? 'yes' : 'no')
        , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, props.user.Deleted ? 'yes' : 'no')
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}})
      )
  )
}

const PatientResult = (props) => {
  return (
      _react2.default.createElement(_SuperTableTR2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}, props.patient.PatientKey)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, props.patient.ForeignID)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, props.patient.FirstName)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, props.patient.LastName)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, props.patient.DateOfBirth)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}, props.patient.Phone)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, props.patient.Email)
        , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
          , _react2.default.createElement('button', { onClick: () => props.delete(), className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}, "Delete")
        )
      )
  )
}

const RelationshipResult = (props) => {
  return (
    _react2.default.createElement(_SuperTableTR2.default, { to: `/super/orgs/${props.relationship.TrustedOrgKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, props.relationship.TrustedOrgKey)
    )
  )
}

class SuperPhoneChooser extends _react.Component {
  constructor (props) {
    super(props);SuperPhoneChooser.prototype.__init.call(this);SuperPhoneChooser.prototype.__init2.call(this);SuperPhoneChooser.prototype.__init3.call(this);SuperPhoneChooser.prototype.__init4.call(this);SuperPhoneChooser.prototype.__init5.call(this);
    this.state = {
      numbers: [],
      selectedNumber: null,
    }
  }

  __init() {this.handleClose = () => {
    if (this.props.onClose) {
      this.props.onClose()
    }
  }}

  __init2() {this.changeSearchText = (evt) => this.searchText = evt.target.value}

  __init3() {this.search = () => {
    _client2.default.send('GetAvailablePhoneNumbers', { AreaCode: this.searchText })
      .then((results) => {
        this.setState({
          numbers: results.AvailableNumbers.slice(0, 10),
          selectedNumber: null,
        })
      }).catch(_log.log.error)
  }}

  __init4() {this.radioChecked = (evt) => {
    this.setState({
      selectedNumber: evt.target.value,
    })
  }}

  __init5() {this.handleSubmit = () => {
    _client2.default.send("SUPER_BuyPhoneForOrg", {
      OrgKey: this.props.orgKey,
      PhoneNumber: this.state.selectedNumber,
    }).then(() => {
      this.handleClose()
    }).catch(_log.log.error)
  }}

  render () {
    const numbers = this.state.numbers.map((number) => (
        _react2.default.createElement('div', { key: number.PhoneNumber, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          , _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
            , _react2.default.createElement('input', { type: "radio", name: "phone", value: number.PhoneNumber, onChange: this.radioChecked,
                   checked: this.state.selectedNumber === number.PhoneNumber, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}), " " , number.PhoneNumber
          )
        )
    ))

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.open,
        onClose: this.handleClose,
        title: "Issue Number" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
      
        , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
          , _react2.default.createElement('input', {
            type: "text",
            placeholder: "Area code" ,
            onChange: this.changeSearchText, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
          )
          , _react2.default.createElement('input', {
            type: "submit",
            value: "Search",
            onClick: this.search, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
          )
        )
        , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
          , numbers
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.handleClose,
          primaryBtnLabel: "Submit",
          isPrimaryEnabled: this.state.selectedNumber,
          onPrimaryBtnClick: this.handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
        )
      )
    )
  }
}

class SuperTeamPhoneAssigned extends _react.Component {
  constructor (props) {
    super(props);SuperTeamPhoneAssigned.prototype.__init6.call(this);SuperTeamPhoneAssigned.prototype.__init7.call(this);SuperTeamPhoneAssigned.prototype.__init8.call(this);
    this.state = {
      selectedTeam: props.teamKey,
    }
  }

  __init6() {this.handleClose = () => {
    this.props.onClose()
  }}

  __init7() {this.radioChecked = (evt) => {
    this.setState({
      selectedTeam: evt.target.value,
    })
  }}

  __init8() {this.handleSubmit = () => {
    _client2.default.send("SUPER_AssignPhoneToTeam", {
      TeamKey: this.state.selectedTeam,
      PhoneKey: this.props.phoneKey,
    }).then(() => {
      this.handleClose()
      this.props.onSuccess()
    }).catch(_log.log.error)
  }}

  render () {
    const teams = Object.values(this.props.orgTeams).map((team) => (
        _react2.default.createElement('div', { key: team.TeamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
          , _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
            , _react2.default.createElement('input', { type: "radio", name: "team", value: team.TeamKey, onChange: this.radioChecked,
                   checked: this.state.selectedTeam === team.TeamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}), " " , team.Name
          )
        )
    ))

    teams.push(
        _react2.default.createElement('div', { key: 'hello_yotam', __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
          , _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
            , _react2.default.createElement('input', {
                type: "radio",
                name: "team",
                value: _uuid.EMPTY_BLUID,
                onChange: this.radioChecked,
                checked: this.state.selectedTeam === _uuid.EMPTY_BLUID, __self: this, __source: {fileName: _jsxFileName, lineNumber: 185}}
            ), "*** NONE ***"

          )
        ),
    )

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.isOpen,
        onClose: this.handleClose,
        title: "Assign Number" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}}
      
        ,  teams 
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.handleClose,
          primaryBtnLabel: "Submit",
          isPrimaryEnabled: !!this.state.selectedTeam,
          onPrimaryBtnClick: this.handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 204}}
        )
      )
    )
  }
}

 class SuperOrganizationScreen extends _react.Component {
  constructor (props) {
    super(props);SuperOrganizationScreen.prototype.__init9.call(this);SuperOrganizationScreen.prototype.__init10.call(this);SuperOrganizationScreen.prototype.__init11.call(this);SuperOrganizationScreen.prototype.__init12.call(this);SuperOrganizationScreen.prototype.__init13.call(this);SuperOrganizationScreen.prototype.__init14.call(this);SuperOrganizationScreen.prototype.__init15.call(this);SuperOrganizationScreen.prototype.__init16.call(this);SuperOrganizationScreen.prototype.__init17.call(this);SuperOrganizationScreen.prototype.__init18.call(this);SuperOrganizationScreen.prototype.__init19.call(this);SuperOrganizationScreen.prototype.__init20.call(this);SuperOrganizationScreen.prototype.__init21.call(this);SuperOrganizationScreen.prototype.__init22.call(this);SuperOrganizationScreen.prototype.__init23.call(this);SuperOrganizationScreen.prototype.__init24.call(this);SuperOrganizationScreen.prototype.__init25.call(this);SuperOrganizationScreen.prototype.__init26.call(this);SuperOrganizationScreen.prototype.__init27.call(this);SuperOrganizationScreen.prototype.__init28.call(this);SuperOrganizationScreen.prototype.__init29.call(this);SuperOrganizationScreen.prototype.__init30.call(this);SuperOrganizationScreen.prototype.__init31.call(this);SuperOrganizationScreen.prototype.__init32.call(this);SuperOrganizationScreen.prototype.__init33.call(this);SuperOrganizationScreen.prototype.__init34.call(this);SuperOrganizationScreen.prototype.__init35.call(this);SuperOrganizationScreen.prototype.__init36.call(this);SuperOrganizationScreen.prototype.__init37.call(this);SuperOrganizationScreen.prototype.__init38.call(this);SuperOrganizationScreen.prototype.__init39.call(this);SuperOrganizationScreen.prototype.__init40.call(this);SuperOrganizationScreen.prototype.__init41.call(this);
    this.state = {
      teamPhoneAssignerOpen: false,
      teamPhoneAssignerNumber: null,
      phoneChooserOpen: false,
      updateSubscriptionModalOpen: false,
      attachOrgToSubscriptionModalOpen: false,
      subscriptionOrgSettingsModalOpen: false,
      createTeamModalOpen: false,
      deletePatientModalOpen: false,
      moveUserModalOpen: false,
      patientToDelete: null,
      subscription: null,
      users: [],
      features: [],
      patients: [],
      teams: {},
      phones: {},
      relationships: [],
    }
  }

  load () {
    this.loadTeams()
    this.loadOrg()
    this.loadFeatures()
    this.loadUsers()
    this.loadPatients()
    this.loadRelationships()
    this.loadSubscription()
  }

  componentDidMount () {
    this.load()
  }

  __init9() {this.updateInviteBy = (evt) => this.invitedBy = evt.target.value}
  __init10() {this.updateUserList = (evt) => this.userList = evt.target.files[0]}
  __init11() {this.updatePatientList = (evt) => this.patientList = evt.target.files[0]}
  __init12() {this.updateImportedBy = (evt) => this.patientImportedBy = evt.target.value}

  __init13() {this.bulkInvite = (evt) => {
    evt.preventDefault()
    _client2.default.uploadFile(this.userList)
      .then((Metadata) => {
        _client2.default.send('SuperBulkInvite', {
          OrgKey: this.props.match.params.orgKey,
          UserKey: this.invitedBy,
          FileKey: Metadata.FileKey,
        }).then((response) => {
          _actions2.default.pushAlert("Success", "Successfully invited users", _alertReducer.AlertTypes.SUCCESS)
        }).catch(_log.log.error)
      })
  }}

  __init14() {this.bulkPatientImport = (evt) => {
    evt.preventDefault()
    _actions2.default.pushAlert("Uploading file", "Please wait", _alertReducer.AlertTypes.INFORMATION)
    _client2.default.uploadFile(this.patientList)
      .then((Metadata) => {
        _actions2.default.pushAlert("Beginning Import", "Beginning patient import. Please wait for notification complete", _alertReducer.AlertTypes.INFORMATION)
        return _actions2.default.superPatientImport(this.props.match.params.orgKey, this.patientImportedBy, Metadata.FileKey)
          .then((response) => {
            _log.log.info("PATIENT IMPORT STATS", response)
            if (response.TaskCompletedFully) {
              _actions2.default.pushAlert("Job Completed", `Rows success: ${response.RowsSuccess}. Rows failure: ${response.RowsFailure}.`, _alertReducer.AlertTypes.INFORMATION)
            } else {
              _actions2.default.pushAlert("Error", `Job did not complete. Please view console for error report`, _alertReducer.AlertTypes.ERROR)
            }
          })
      })
      .catch(_log.log.error)
  }}

  __init15() {this.openPhoneChooser = () => this.setState({ phoneChooserOpen: true })}
  __init16() {this.closePhoneChooser = () => {
    this.setState({ phoneChooserOpen: false })
    this.load()
  }}

  __init17() {this.removePhone = (phoneKey) => {
    _client2.default.send("SUPER_DeleteOrgPhone", {
      PhoneKey: phoneKey,
    }).then(() => {
      this.load()
    }).catch(_log.log.error)
  }}

  __init18() {this.openCreateTeamModal = () => {
    this.setState({ createTeamModalOpen: true })
  }}

  __init19() {this.closeCreateTeamModal = () => {
    this.setState({ createTeamModalOpen: false })
  }}

  __init20() {this.createTeam = (teamName) => {
    return _client2.default.send('CreateTeamAtOrg', { OrgKey: this.props.match.params.orgKey, TeamName: teamName }).then(() => {
      this.loadTeams()
    }).catch(_log.log.error)
  }}

  __init21() {this.loadTeams = () => {
    _client2.default.send('SUPER_GetTeamsForOrg', { OrgKey: this.props.match.params.orgKey }).then((t) => {
      this.setState({
        teams: t.Teams || {}, // Some orgs don't have teams
      })
    }).catch(_log.log.error)
  }}

  __init22() {this.loadOrg = () => {
    _client2.default.send('SUPER_GetOrg', { OrgKey: this.props.match.params.orgKey })
      .then((results) => {
        this.setState({
          org: results.Org,
          phones: results.Phones,
        })
      }).catch(_log.log.error)
  }}

  __init23() {this.loadUsers = () => {
    _client2.default.send('SuperUsersByOrg', { OrgKey: this.props.match.params.orgKey })
      .then((results) => {
        this.setState({
          users: Object.values(results),
        })
      }).catch(_log.log.error)
  }}

  __init24() {this.loadPatients = () => {
    _client2.default.send('FindPatientsInOrg', { OrgKey: this.props.match.params.orgKey, Limit: 100 })
      .then((results) => {
        this.setState({
          patients: Object.values(results.Patients),
        })
      }).catch(_log.log.error)
  }}

  __init25() {this.loadRelationships = () => {
    _client2.default.send('SUPER_GetOrgRelationships', { OrgKey: this.props.match.params.orgKey })
      .then((results) => {
        this.setState({
          relationships: Object.values(results.OrgRelationships).filter((rel) => !rel.Deleted),
        })
      }).catch(_log.log.error)
  }}

  __init26() {this.loadFeatures = () => {
    _client2.default.send('SuperListFeatures', {})
      .then((results) => {
        this.setState({
          features: results,
        })
      }).catch(_log.log.error)
  }}

  __init27() {this.loadSubscription = () => {
    _client2.default.send('SUPER_GetSubscription', { OrgKey: this.props.match.params.orgKey })
      .then((result) => {
        if (Object.keys(result).length > 0) {
          this.setState({
            subscription: Object.keys(result).map(k => result[k])[0],
          })
        }
      }).catch(err => _log.log.error(err))
  }}

  __init28() {this.deletePatient = () => {
    return _client2.default.send('SUPER_DeletePatient', {
      PatientKey: this.state.patientToDelete,
    }).then(() => {
      _actions2.default.pushAlert("Success", "Patient deleted", _alertReducer.AlertTypes.SUCCESS)
      this.setState({ patientToDelete: null, deletePatientModalOpen: false })
      this.load()
    }).catch(err => {
      _actions2.default.pushAlert("Failure", "Failed to delete patient")
      _log.log.error(err)
    })
  }}

  __init29() {this.updateSubscription = (subscriptionKey, stripeCustomerID, stripeSubscriptionID) => {
    _client2.default.send('SUPER_UpdateStripeDetailsForSubscription', {
      SubscriptionKey: subscriptionKey,
      StripeCustomerID: stripeCustomerID,
      StripeSubscriptionID: stripeSubscriptionID,
    }).then((result) => {
      _actions2.default.pushAlert("Success", "Stripe Subscription Updated", _alertReducer.AlertTypes.SUCCESS)
      this.setState({ subscription: Object.keys(result).map(k => result[k])[0] })
      this.closeUpdateSubscriptionModal()
    }).catch(err => {
      _actions2.default.pushAlert("Failure", "Failed to update subscription")
      _log.log.error(err)
    })
  }}

  __init30() {this.attachOrgToNewSubscription = (orgToAttach, orgOwner) => {
    _client2.default.send('SUPER_AttachOrgToExistingSubscription', {
      OrgToAttach: orgToAttach,
      OrgOwner: orgOwner,
    }).then((result) => {
      _actions2.default.pushAlert("Success", "Org attached to new owner", _alertReducer.AlertTypes.SUCCESS)
      this.setState({ subscription: Object.keys(result).map(k => result[k])[0] })
      this.closeAttachOrgToSubscriptionModal()
    }).catch(err => {
      _actions2.default.pushAlert("Failure", "Failed to attach to new org")
      _log.log.error(err)
    })
  }}

  __init31() {this.updateSubscriptionOrgSettings = (subscriptionKey, orgKey, professional, patientMessaging) => {
    _client2.default.send('SUPER_UpdateSubscriptionOrgSettings', {
      SubscriptionKey: subscriptionKey,
      OrgKey: orgKey,
      BloomTextPro: professional,
      PatientMessaging: patientMessaging,
    }).then((result) => {
      _actions2.default.pushAlert("Success", "Org settings updated", _alertReducer.AlertTypes.SUCCESS)
      this.setState({ subscription: Object.keys(result).map(k => result[k])[0] })
      this.closeSubscriptionOrgSettingsModal()
    }).catch(err => {
      _actions2.default.pushAlert("Failure", "Failed to attach to new org")
      _log.log.error(err)
    })
  }}

  __init32() {this.openUpdateSubscriptionModal = () => {
    this.setState({ updateSubscriptionModalOpen: true })
  }}

  __init33() {this.closeUpdateSubscriptionModal = () => {
    this.setState({ updateSubscriptionModalOpen: false })
  }}

  __init34() {this.openAttachOrgToSubscriptionModal = () => {
    this.setState({ attachOrgToSubscriptionModalOpen: true })
  }}

  __init35() {this.openSubscriptionOrgSettingsModal = () => {
    this.setState({ subscriptionOrgSettingsModalOpen: true })
  }}

  __init36() {this.closeAttachOrgToSubscriptionModal = () => {
    this.setState({ attachOrgToSubscriptionModalOpen: false })
  }}

  __init37() {this.closeSubscriptionOrgSettingsModal = () => {
    this.setState({ subscriptionOrgSettingsModalOpen: false })
  }}

  __init38() {this.openDeletePatient = (patientkey) => {
    this.setState({ patientToDelete: patientkey, deletePatientModalOpen: true })
  }}

  __init39() {this.closeDeletePatient = () => {
    this.setState({ patientToDelete: null, deletePatientModalOpen: false })
  }}

  __init40() {this.openMoveUserModal = () => {
    this.setState({ moveUserModalOpen: true })
  }}

  __init41() {this.closeMoveUserModal = () => {
    this.setState({ moveUserModalOpen: false })
  }}

  render () {
    if (this.state.org) {
      const subscription = this.state.subscription
      const users = this.state.users.map((user) => _react2.default.createElement(UserResult, { user: user, orgKey: this.state.org.OrgKey,
                                                             key: user.UserKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 486}}))
      const patients = this.state.patients.map((patient) => _react2.default.createElement(PatientResult, {
          delete: () => this.openDeletePatient(patient.PatientKey), patient: patient, key: patient.PatientKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 488}}))
      const relationships = this.state.relationships.map((rel) => _react2.default.createElement(RelationshipResult, { relationship: rel, key: rel.TrustedOrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 490}}))
      const phones = Object.values(this.state.phones).map(p => {
        const teamName = p.AssignedToKey in this.state.teams ? this.state.teams[p.AssignedToKey].Name : "Nothing"
        return (
          _react2.default.createElement(_SuperTableTR2.default, { key: p.PhoneKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 494}}
            , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 495}}, p.Number)
            , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 496}}, teamName)
            , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 497}}
              , _react2.default.createElement(SuperTeamPhoneAssigned, {
                isOpen: this.state.teamPhoneAssignerOpen && this.state.teamPhoneAssignerNumber === p.PhoneKey,
                orgKey: this.state.org.OrgKey,
                orgTeams: this.state.teams,
                phoneKey: p.PhoneKey,
                teamKey: p.AssignedToKey,
                onClose: () => this.setState({ teamPhoneAssignerOpen: false }),
                onSuccess: this.loadOrg, __self: this, __source: {fileName: _jsxFileName, lineNumber: 498}}
              )
              , _react2.default.createElement('button', { className: "blmh-button", onClick: () => this.setState({
                teamPhoneAssignerOpen: true,
                teamPhoneAssignerNumber: p.PhoneKey,
              }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 507}}, "Assign")
              , " "
              , _react2.default.createElement('button', { className: "blmh-button red" , onClick: () => this.removePhone(p.PhoneKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 512}}, "Release")
            )
          )
        )
      })

      return (
          _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 519}}
            , _react2.default.createElement(_Modal2.default, {
              isOpen: this.state.deletePatientModalOpen,
              onClose: this.closeDeletePatient,
              title: "DELETE PATIENT FOR REAL"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 520}}
            
              , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 525}}, "Are you sure you want to delete this patient?"

              )
              , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 528}}, "You have to manually fix it in the database to undo it."

              )
              , _react2.default.createElement(_Modal.ModalControls, {
                secondaryBtnLabel: "Cancel",
                onSecondaryBtnClick: this.closeDeletePatient,
                primaryBtnLabel: "Delete",
                primaryBtnTheme: "danger",
                onPrimaryBtnClick: this.deletePatient, __self: this, __source: {fileName: _jsxFileName, lineNumber: 531}}
              )
            )
            , _react2.default.createElement(_SuperTeamModal2.default, {
                open: this.state.createTeamModalOpen,
                onClose: this.closeCreateTeamModal,
                onSubmit: this.createTeam, __self: this, __source: {fileName: _jsxFileName, lineNumber: 539}})
            , _react2.default.createElement(_SuperMoveUserToAnotherOrgModal2.default, {
                currentOrgKey: this.state.org.OrgKey,
                users: this.state.users,
                open: this.state.moveUserModalOpen,
                onClose: this.closeMoveUserModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 543}}
            )

            , subscription ? (_react2.default.createElement(_react2.default.Fragment, null
              , _react2.default.createElement(_SuperSubscriptions.SuperUpdateSubscriptionModal, {
                  currentOrgKey: this.state.org.OrgKey,
                  open: this.state.updateSubscriptionModalOpen,
                  onClose: this.closeUpdateSubscriptionModal,
                  subscription: subscription,
                  onSubmit: this.updateSubscription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 551}}
              )
              , _react2.default.createElement(_SuperSubscriptions.SuperAttachOrgToOtherSubscriptionModal, {
                  currentOrgKey: this.state.org.OrgKey,
                  open: this.state.attachOrgToSubscriptionModalOpen,
                  onClose: this.closeAttachOrgToSubscriptionModal,
                  onSubmit: this.attachOrgToNewSubscription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 558}}
              )
              , _react2.default.createElement(_SuperSubscriptions.SuperUpdateSubscriptionOrgSettingsModal, {
                  orgKey: this.state.org.OrgKey,
                  subscription: subscription,
                  open: this.state.subscriptionOrgSettingsModalOpen,
                  onClose: this.closeSubscriptionOrgSettingsModal,
                  onSubmit: this.updateSubscriptionOrgSettings, __self: this, __source: {fileName: _jsxFileName, lineNumber: 564}}
              )
            )) : null

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 573}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 574}}, "Properties")
              , _react2.default.createElement('dl', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 575}}
                , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 576}}, "Org Key" )
                , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 577}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 577}}, this.state.org.OrgKey))
                , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 578}}, "Name")
                , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 579}}, this.state.org.Name)
                , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 580}}, "Verified")
                , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 581}}, this.state.org.Verified ? 'yes' : 'no')
                , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 582}}, "Features")
                , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 583}}
                  ,  this.state.org.EnabledFeatures.length > 0
                    ? _react2.default.createElement('ul', { className: "superuser--feature-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 585}},  this.state.org.EnabledFeatures.map((feature) => _react2.default.createElement('li', { key: feature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 585}}, feature)) )
                    : _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 586}}, "None")
                  
                )
              )
              , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 590}}, _react2.default.createElement('a', { href: "/#/super/orgs/" + this.state.org.OrgKey + "/edit", className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 590}}, "Edit"))
            )

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 593}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 594}}, "Phone Numbers" )
              ,  Object.keys(this.state.phones).length > 0 ?
                _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 596}}
                  , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 597}}
                  , _react2.default.createElement(_SuperTableTR2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 598}}
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 599}}, "Number")
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 600}}, "Assigned to" )
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 601}}, " ")
                  )
                  )
                  , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 604}}
                  , phones
                  )
                )
                :
                _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 609}}, "None")
              
              , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 611}}, _react2.default.createElement('button', { onClick: this.openPhoneChooser, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 611}}, "Issue Number" ))
              ,  this.state.phoneChooserOpen ? _react2.default.createElement(SuperPhoneChooser, { orgKey: this.props.match.params.orgKey, open: this.state.phoneChooserOpen, onClose: this.closePhoneChooser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 612}} ) : null 
            )

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 615}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 616}}, "Members")
              ,  this.state.users.length > 0 ?
                _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 618}}
                  , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 619}}
                  , _react2.default.createElement(_SuperTableTR2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 620}}
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 621}}, "User Key" )
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 622}}, "First Name" )
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 623}}, "Last Name" )
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 624}}, "Title")
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 625}}, "Role")
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 626}}, "Pending")
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 627}}, "Activated")
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 628}}, "Deleted")
                  )
                  )
                  , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 631}}
                  , users
                  )
                )
                :
                _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 636}}, "None")
              
              , _react2.default.createElement('form', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 638}}
                , _react2.default.createElement('legend', { className: "superuser--legend", __self: this, __source: {fileName: _jsxFileName, lineNumber: 639}}, "Bulk Invite Users"  )
                , _react2.default.createElement('input', { type: "file", id: "userList", onChange: this.updateUserList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 640}} ), "Invited by "
                  , _react2.default.createElement('input', { type: "text", id: "by", onChange: this.updateInviteBy, placeholder: "UserKey", __self: this, __source: {fileName: _jsxFileName, lineNumber: 641}} ), ' '
                , _react2.default.createElement('button', { onClick: this.bulkInvite, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 642}}, "Invite")
              )
              , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 644}}
                , _react2.default.createElement('button', { onClick: this.openMoveUserModal, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 645}}, "Move Users" )
              )
            )

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 649}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 650}}, "Patients")
              ,  this.state.patients.length > 0 ?
                _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 652}}
                  , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 653}}
                    , _react2.default.createElement(_SuperTableTR2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 654}}
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 655}}, "Patient Key" )
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 656}}, "MRN")
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 657}}, "First Name" )
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 658}}, "Last Name" )
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 659}}, "Date of Birth"  )
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 660}}, "Phone")
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 661}}, "Email")
                      , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 662}}, ' ')
                    )
                  )
                  , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 665}}
                    , patients
                  )
                )
                :
                _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 670}}, "None")
              
              , _react2.default.createElement('form', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 672}}
                , _react2.default.createElement('legend', { className: "superuser--legend", __self: this, __source: {fileName: _jsxFileName, lineNumber: 673}}, "Bulk Import Patients (first_name, last_name, mrn, email, phone)"       )
                , _react2.default.createElement('input', { type: "file", id: "patientList", onChange: this.updatePatientList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 674}}), "Invited by "
                  , _react2.default.createElement('input', { type: "text", id: "imported_by", onChange: this.updateImportedBy, placeholder: "UserKey", __self: this, __source: {fileName: _jsxFileName, lineNumber: 675}}), ' '
                , _react2.default.createElement('button', { onClick: this.bulkPatientImport, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 676}}, "Invite")
              )
            )

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 680}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 681}}, "Teams")
              , _react2.default.createElement(_SuperTeamTable2.default, { teams: this.state.teams, buttonText: "Create Team" , onButton: this.openCreateTeamModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 682}})
            )

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 685}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 686}}, "Relationships")
              ,  this.state.relationships.length > 0 ?
                _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 688}}
                  , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 689}}
                  , _react2.default.createElement(_SuperTableTR2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 690}}
                    , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 691}}, "Trusted Org" )
                  )
                  )
                  , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 694}}
                  , relationships
                  )
                )
                :
                _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 699}}, "None")
              
            )

            , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 703}}
              , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 704}}, "Subscriptions")
              ,  subscription ?
                _react2.default.createElement(_react2.default.Fragment, null
                  , _react2.default.createElement('dl', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 707}}
                    , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 708}}, "Subscription Key" )
                    , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 709}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 709}}, subscription.SubscriptionKey))
                    , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 710}}, "Subscription Type" )
                    , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 711}}, subscription.SubscriptionType)
                    , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 712}}, "Org Owner" )
                    , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 713}}, subscription.SubscriptionOrgSettings[subscription.OrgOwnerKey].Name)
                    , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 714}}, "Attached Orgs" )
                    ,  Object.values(subscription.SubscriptionOrgSettings).map(s =>
                        _react2.default.createElement('dd', { key: s.OrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 716}}, s.Name, " - "  , s.BloomTextProfessional ? "Team Chat" : "Starter", " " , s.PatientMessaging && "plus SMS Messaging"))
                    
                    ,  subscription.StripeSubscriptionID ? _react2.default.createElement(_react2.default.Fragment, null
                        , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 719}}, "Stripe SubscriptionID" )
                        , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 720}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 720}}, subscription.StripeSubscriptionID))
                      ) : null
                    
                    ,  subscription.StripeCustomerID ? _react2.default.createElement(_react2.default.Fragment, null
                        , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 724}}, "Stripe CustomerID" )
                        , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 725}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 725}}, subscription.StripeCustomerID))
                      ) : null
                    
                  )
                  , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 729}}
                    , _react2.default.createElement('button', { onClick: this.openUpdateSubscriptionModal, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 730}}, "Update Subscription Details"  ), ' '
                    , _react2.default.createElement('button', { onClick: this.openAttachOrgToSubscriptionModal, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 731}}, "Merge Subscriptions" ), ' '
                    , _react2.default.createElement('button', { onClick: this.openSubscriptionOrgSettingsModal, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 732}}, "Subscription Org Settings"  )
                  )
                )
                :
                _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 736}}, "None")
              
            )
          )
      )
    } else {
      return null
    }
  }
} exports.default = SuperOrganizationScreen;
