"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/SearchInput/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Pill = require('./Pill'); var _Pill2 = _interopRequireDefault(_Pill);













const SearchInput = ({
  onFocus,
  onBlur,
  className,
  searchQuery,
  setSearchQuery,
  selectedEntitiesList,
  onRemoveSelectedEntity,
  senderSelect,
  canAddParticipants,
}, ref) => {
  const handleChange = (e) =>
    setSearchQuery((e.target ).value)
  const handleKeyDown = (e) => {
    if ((e.target ).value || e.keyCode !== 8) return
    const lastEntity = selectedEntitiesList[selectedEntitiesList.length - 1]
    if (lastEntity) onRemoveSelectedEntity(lastEntity.entityKey)
  }

  return (
    _react2.default.createElement('div', { className: "participants-picker--input-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      ,  senderSelect 
      ,  selectedEntitiesList.map(entity => (
        _react2.default.createElement(_Pill2.default, {
          key: entity.entityKey,
          entity: entity,
          onRemove: onRemoveSelectedEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
      ))
      ,  canAddParticipants && (
        _react2.default.createElement('input', {
          type: "text",
          ref: ref,
          value: searchQuery,
          onChange: handleChange,
          onKeyDown: handleKeyDown,
          onFocus: onFocus,
          onBlur: onBlur,
          className: _classBuilder2.default.call(void 0, 'participants-picker--input', className),
          placeholder: "Enter a name, email or phone number"      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        )
      )
    )
  )
}

const SearchInputForwardedRef = _react2.default.forwardRef(SearchInput)

exports. default = SearchInputForwardedRef
