"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Sidebar/SettingsIcon/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _persistor = require('web/services/persistor');
var _myRelations = require('shared/selectors/myRelations');
var _user = require('shared/selectors/user');
var _app = require('shared/selectors/app');

var _Tooltip = require('web/components/Tooltip');
var _PendingUsersWarning = require('./PendingUsersWarning'); var _PendingUsersWarning2 = _interopRequireDefault(_PendingUsersWarning);
var _Icon = require('../Icon'); var _Icon2 = _interopRequireDefault(_Icon);





const WARNING_DELAY = 1600
const PENDING_USERS_WARNING_KEY = 'wasPendingUsersWarningSeen'
const tooltipOffsetHor = { left: -12 }
const tooltipOffsetVer = { bottom: 10 }

const SettingsIcon = ({
  tooltipPlace,
  myUserKey,
  pendingUsers,
  isAppOutdated,
  title,
  shortTitle,
  iconName,
  isActive,
  onClick,
}) => {
  const [isWarningActive, setIsWarningActive] = _react.useState(false)
  const warningTimeout = _react.useRef(null)
  const showWarning = () => {
    setIsWarningActive(false)
    if (warningTimeout.current) clearTimeout(warningTimeout.current)
    warningTimeout.current =
      setTimeout(() => setIsWarningActive(true), WARNING_DELAY)
  }
  const dismissWarning = () => {
    _persistor.persistValue.call(void 0, myUserKey, PENDING_USERS_WARNING_KEY, true)
    setIsWarningActive(false)
  }

  const isPendingUsersWarningSeen =
    _persistor.getPersistedValue.call(void 0, myUserKey, PENDING_USERS_WARNING_KEY)

  const pendingUsersCount = pendingUsers.length
  _react.useEffect.call(void 0, 
    () => { if (!isPendingUsersWarningSeen && pendingUsersCount > 0) showWarning() },
    [isPendingUsersWarningSeen, pendingUsersCount],
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      isOpen: isWarningActive,
      type: "light",
      place: tooltipPlace,
      onClose: dismissWarning,
      isAnimated: true,
      hasCloseButton: true,
      offset: tooltipPlace === 'right' ? tooltipOffsetHor : tooltipOffsetVer,
      content: isWarningActive ? _react2.default.createElement(_PendingUsersWarning2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} ) : null,
      tooltipClassName: "tooltip--upgrade-warning", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
    
      , _react2.default.createElement(_Icon2.default, {
        shouldHideTitleTooltip: isWarningActive,
        title: title,
        shortTitle: shortTitle,
        iconName: iconName,
        hasBadge: isAppOutdated,
        badgeCount: pendingUsersCount,
        onClick: onClick,
        isActive: isActive, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      )
    )
  )
}







const mapState = (state) => ({
  myUserKey: _user.selectCurrentUserKey.call(void 0, state),
  pendingUsers: _myRelations.selectAllPendingUsers.call(void 0, state),
  isAppOutdated: _app.selectIsAppOutdated.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(SettingsIcon)
