"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/ExportRelationships/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Modal = require('web/components/Modal');








const ExportRelationshipsForm = ({
  onClose,
  onSubmit,
  orgName,
}) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)

  const handleSubmit = () => {
    setIsLoading(true)
    _optionalChain([onSubmit, 'optionalCall', _ => _()
, 'access', _2 => _2.then, 'call', _3 => _3(onClose)
, 'access', _4 => _4.catch, 'call', _5 => _5(() => setIsLoading(false))])
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('span', { className: "settings--org-members--remove-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Request a CSV file containing a complete membership history for "
                  , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, orgName), "?"
      )
      ,  !onSubmit && (
        _react2.default.createElement('span', { className: "settings--org-members--upgrade-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, "You must upgrade to a paid plan to export membership history."

        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        isPrimaryEnabled: !!onSubmit && !isLoading,
        isPrimaryLoading: isLoading,
        onPrimaryBtnClick: handleSubmit,
        primaryBtnLabel: "Export",
        secondaryBtnLabel: "Close",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )
    )
  )
}

exports. default = ExportRelationshipsForm
