"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/EnterEmail/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _types = require('./types');
var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);

var _RolePrompt = require('./RolePrompt'); var _RolePrompt2 = _interopRequireDefault(_RolePrompt);
var _EnterEmail = require('./EnterEmail'); var _EnterEmail2 = _interopRequireDefault(_EnterEmail);
var _PatientWarning = require('./PatientWarning'); var _PatientWarning2 = _interopRequireDefault(_PatientWarning);
var _components = require('web/screens/Signup/components');

const SignupScreen = () => {
  const [role, setRole] = _react.useState(null)

  _react.useEffect.call(void 0, 
    () => { role === _types.Role.PATIENT && _amplitude2.default.logEvent("SIGN_UP_PATIENT") },
    [role],
  )

  return (
    _react2.default.createElement(_components.SignupStepWrapper, { title: "Sign up" , size: role ? 'md' : 'sm', __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      ,  !role && _react2.default.createElement(_RolePrompt2.default, { setRole: setRole, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
      ,  role === _types.Role.USER && _react2.default.createElement(_EnterEmail2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} ) 
      ,  role === _types.Role.PATIENT && _react2.default.createElement(_PatientWarning2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} ) 
      ,  role !== _types.Role.USER && (
        _react2.default.createElement(_components.SignupStepNotice, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, "Already have an account? "
              , _react2.default.createElement(_reactrouterdom.Link, { to: _routes2.default.LOGIN.ROOT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Log in" )
        )
      )
    )
  )
}

exports. default = SignupScreen
