"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _videoRoom = require('web/selectors/videoRoom');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);



var _videoCall = require('web/selectors/videoCall');
var _anonymousVideoRoomUserCache = require('web/services/anonymousVideoRoomUserCache'); var _anonymousVideoRoomUserCache2 = _interopRequireDefault(_anonymousVideoRoomUserCache);
var _videoRoom3 = require('web/services/videoRoom'); var _videoRoom4 = _interopRequireDefault(_videoRoom3);

var _IncompatibleBrowser = require('web/screens/VideoCall/IncompatibleBrowser'); var _IncompatibleBrowser2 = _interopRequireDefault(_IncompatibleBrowser);
var _Permissions = require('web/screens/VideoCall/Permissions'); var _Permissions2 = _interopRequireDefault(_Permissions);
var _OnCall = require('web/screens/VideoCall/OnCall'); var _OnCall2 = _interopRequireDefault(_OnCall);
var _Setup = require('web/screens/VideoCall/Setup'); var _Setup2 = _interopRequireDefault(_Setup);
var _PreCall = require('web/screens/VideoCall/PreCall'); var _PreCall2 = _interopRequireDefault(_PreCall);
var _Feedback = require('web/screens/VideoCall/Feedback'); var _Feedback2 = _interopRequireDefault(_Feedback);
var _InvalidRoomLink = require('web/components/VideoCall/InvalidRoomLink'); var _InvalidRoomLink2 = _interopRequireDefault(_InvalidRoomLink);
var _RoomSidebar = require('web/screens/VideoCall/segments/RoomSidebar'); var _RoomSidebar2 = _interopRequireDefault(_RoomSidebar);
var _Modals = require('web/components/VideoCall/Modals');

const ROUTING = {
  SETUP: 'ROUTE_SETUP',
  ONCALL: 'ROUTE_ONCALL',
  PRECALL: 'ROUTE_PRECALL',
  FEEDBACK: 'ROUTE_FEEDBACK',
}

const roomLoadStatuses = {
  LOADING: 'ROOM_LOADING',
  FAILED: 'ROOM_LOAD_FAILED',
  LOADED: 'ROOM_LOADED',
}

class VideoCallRoot extends _react2.default.Component {constructor(...args) { super(...args); VideoCallRoot.prototype.__init.call(this);VideoCallRoot.prototype.__init2.call(this);VideoCallRoot.prototype.__init3.call(this);VideoCallRoot.prototype.__init4.call(this);VideoCallRoot.prototype.__init5.call(this);VideoCallRoot.prototype.__init6.call(this);VideoCallRoot.prototype.__init7.call(this);VideoCallRoot.prototype.__init8.call(this);VideoCallRoot.prototype.__init9.call(this);VideoCallRoot.prototype.__init10.call(this);VideoCallRoot.prototype.__init11.call(this);VideoCallRoot.prototype.__init12.call(this);VideoCallRoot.prototype.__init13.call(this);VideoCallRoot.prototype.__init14.call(this);VideoCallRoot.prototype.__init15.call(this);VideoCallRoot.prototype.__init16.call(this);VideoCallRoot.prototype.__init17.call(this);VideoCallRoot.prototype.__init18.call(this);VideoCallRoot.prototype.__init19.call(this);VideoCallRoot.prototype.__init20.call(this);VideoCallRoot.prototype.__init21.call(this);VideoCallRoot.prototype.__init22.call(this);VideoCallRoot.prototype.__init23.call(this);VideoCallRoot.prototype.__init24.call(this);VideoCallRoot.prototype.__init25.call(this); }
  __init() {this.state = {
    currentRoute: ROUTING.SETUP,
    prevRoute: ROUTING.PRECALL,

    anonymousUserName: _anonymousVideoRoomUserCache2.default.getName(),
    isNamePromptModalOpen: false,
    roomLoadStatus: roomLoadStatuses.LOADING,
  }}

  __init2() {this.videoRoomSession = new (0, _videoRoom4.default)(this.props.roomKey)}

  __init3() {this.openNamePromptModal = () => this.setState({ isNamePromptModalOpen: true })}
  __init4() {this.closeNamePromptModal = () => this.setState({ isNamePromptModalOpen: false })}

  __init5() {this.handleGracefulExit = () => this.gotoFeedback()}
  __init6() {this.handleForcefulExit = () => this.gotoPreCall()}

  __init7() {this.navGoto = route => this.setState(s => ({
    currentRoute: route,
    prevRoute: s.currentRoute,
  }))}

  __init8() {this.gotoCallSetup = () => this.navGoto(ROUTING.SETUP)}
  __init9() {this.gotoPreCall = () => this.navGoto(ROUTING.PRECALL)}
  __init10() {this.gotoCall = () => this.navGoto(ROUTING.ONCALL)}
  __init11() {this.gotoFeedback = () => this.navGoto(ROUTING.FEEDBACK)}
  __init12() {this.goBack = () => this.navGoto(this.state.prevRoute)}

  __init13() {this.renderIncompatibleBrowserScreen = () => (
    _react2.default.createElement(_IncompatibleBrowser2.default, {
      onRoomLinkCopy: this.props.onRoomLinkCopy,
      openMobileMoreOptionsModal: this.props.openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
    )
  )}

  __init14() {this.renderPermissionsScreen = () => (
    _react2.default.createElement(_Permissions2.default, {
      onRoomLinkCopy: this.props.onRoomLinkCopy,
      openMobileMoreOptionsModal: this.props.openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
    )
  )}

  __init15() {this.renderSetupScreen = () => (
    _react2.default.createElement(_Setup2.default, {
      gotoPreCall: this.gotoPreCall,
      goBack: this.goBack,
      onUserProblemPublish: this.videoRoomSession.publishProblem, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
    )
  )}

  __init16() {this.renderOnCallScreen = () => (
    _react2.default.createElement(_OnCall2.default, {
      onGracefulExit: this.handleGracefulExit,
      onForcefulExit: this.handleForcefulExit,
      onCallJoinFailed: this.props.onCallJoinFailed,
      showNoticeBar: this.props.showNoticeBar,
      openMobileMoreOptionsModal: this.props.openMobileMoreOptionsModal,
      openSettingsModal: this.props.openSettingsModal,
      onRoomLinkCopy: this.props.onRoomLinkCopy,
      onRoomShare: this.props.onRoomShare,
      isNetstatsModalOpen: this.props.isNetstatsModalOpen,
      openNetstatsModal: this.props.openNetstatsModal,
      closeNetstatsModal: this.props.closeNetstatsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
    )
  )}

  __init17() {this.renderPreCallScreen = () => (
    _react2.default.createElement(_PreCall2.default, {
      gotoCall: this.gotoCall,
      gotoCallSetup: this.gotoCallSetup,
      onRoomLinkCopy: this.props.onRoomLinkCopy,
      openNamePromptModal: this.openNamePromptModal,
      openMobileMoreOptionsModal: this.props.openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
    )
  )}

  __init18() {this.renderFeedbackScreen = () => (
    _react2.default.createElement(_Feedback2.default, {
      gotoCall: this.gotoCall,
      onRoomLinkCopy: this.props.onRoomLinkCopy,
      openMobileMoreOptionsModal: this.props.openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
    )
  )}

  __init19() {this.renderInvalidRoomLinkScreen = () => (
    _react2.default.createElement(_InvalidRoomLink2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} )
  )}

  __init20() {this.renderRouting = () => {
    const { currentRoute } = this.state
    const { PRECALL, SETUP, ONCALL, FEEDBACK } = ROUTING

    switch (currentRoute) {
      case PRECALL: return this.renderPreCallScreen()
      case SETUP: return this.renderSetupScreen()
      case ONCALL: return this.renderOnCallScreen()
      case FEEDBACK: return this.renderFeedbackScreen()
    }

    return null
  }}

  __init21() {this.isRouteActive = route => this.state.currentRoute === route}

  __init22() {this.startRoomSetup = () => {
    const { anonymousUserName: userName } = this.state
    _actions2.default.updateAnonymousUser({ userName })
    this.videoRoomSession.startSetup({ RoomUserName: userName })
  }}

  __init23() {this.handleUserNameSubmit = anonymousUserName => {
    this.closeNamePromptModal()
    this.setState({ anonymousUserName }, this.startRoomSetup)
  }}

  __init24() {this.componentDidMount = () => {
    const { anonymousUserName } = this.state
    const { roomKey } = this.props

    _actions2.default.setCurrentVideoRoomKey(roomKey)
    _actions2.default.fetchVideoRoom(roomKey)
      .then(() => this.setState({ roomLoadStatus: roomLoadStatuses.LOADED }))
      .catch(() => this.setState({ roomLoadStatus: roomLoadStatuses.FAILED }))

    if (!anonymousUserName && anonymousUserName !== '') { return this.openNamePromptModal() }

    this.startRoomSetup()
  }}

  __init25() {this.renderRoomBody = () => {
    const { anonymousUserName, roomLoadStatus } = this.state
    const { isBrowserCompatible, arePermsGranted } = this.props

    if (!anonymousUserName && anonymousUserName !== '') return null
    if (!isBrowserCompatible) { return this.renderIncompatibleBrowserScreen() }

    const isRoomLoaded = roomLoadStatus === roomLoadStatuses.LOADED
    const hasRoomLoadFailed = roomLoadStatus === roomLoadStatuses.FAILED
    if (!isRoomLoaded) {
      return hasRoomLoadFailed
        ? this.renderInvalidRoomLinkScreen()
        : null
    }
    if (!arePermsGranted) { return this.renderPermissionsScreen() }

    return this.renderRouting()
  }}

  render () {
    const { onRoomLinkCopy } = this.props
    const { isNamePromptModalOpen, anonymousUserName } = this.state
    const isOnCall = this.isRouteActive(ROUTING.ONCALL)

    return (
      _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "video-call--room", isOnCall && "video-call--wrapper-on-call"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
        ,  !isOnCall &&
          _react2.default.createElement(_RoomSidebar2.default, { onRoomLinkCopy: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}} )
        
        , _react2.default.createElement('div', { className: "video-call--room-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
          ,  this.renderRoomBody() 
          , _react2.default.createElement(_Modals.NamePromptModal, {
            key: anonymousUserName,
            isOpen: isNamePromptModalOpen,
            userName: anonymousUserName,
            onProceed: this.handleUserNameSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
          )
        )
      )
    )
  }
}

const mapState = state => ({
  roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
  arePermsGranted: _videoCall.selectArePermsGranted.call(void 0, state),
  isBrowserCompatible: _videoCall.selectIsBrowserCompatible.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(VideoCallRoot)
