"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

var _uuid = require('shared/services/uuid');
var _utils = require('shared/store/utils');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

var AlertTypes; (function (AlertTypes) {
  const SUCCESS = 'SUCCESS'; AlertTypes["SUCCESS"] = SUCCESS;
  const DANGER = 'DANGER'; AlertTypes["DANGER"] = DANGER;
  const INFORMATION = 'INFORMATION'; AlertTypes["INFORMATION"] = INFORMATION;
  const ERROR = 'ERROR'; AlertTypes["ERROR"] = ERROR;
})(AlertTypes || (exports.AlertTypes = AlertTypes = {}));










const initialState = []

exports. default = _utils.createReducer.call(void 0, 
  initialState,
  builder => {
    builder
      .addCase(
        _actionCreators2.default.pushAlertAction,
        (s, a) => [{ ...a.payload, key: _uuid.uuidv4.call(void 0, ) }, ...s],
      )
      .addCase(_actionCreators2.default.popAlertAction, s => { s.splice(1) })

    const defaultCase = () => initialState;
    [_actionCreators2.default.logoutAction, _actionCreators2.default.clearAlertsAction]
      .forEach(a => builder.addCase(a, defaultCase))

    builder.addDefaultCase(defaultCase)
  },
)

 const selectAlerts = (state) => state.alerts; exports.selectAlerts = selectAlerts
