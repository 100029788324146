"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ChatHeader/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');







var _user = require('shared/selectors/user');
var _conversation = require('shared/selectors/conversation');
var _blobject = require('shared/selectors/blobject');
var _chat = require('shared/enum/chat');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _chat3 = require('shared/hooks/chat');
var _hooks = require('../hooks');

var _renderChatDesc = require('./renderChatDesc');
var _RenameChatModal = require('../RenameChatModal'); var _RenameChatModal2 = _interopRequireDefault(_RenameChatModal);
var _ExportChatModal = require('../ExportChatModal'); var _ExportChatModal2 = _interopRequireDefault(_ExportChatModal);
var _ArchiveModal = require('../ArchiveModal'); var _ArchiveModal2 = _interopRequireDefault(_ArchiveModal);
var _Tooltip = require('web/components/Tooltip');





var _Header = require('web/components/RightSide/Header');
var _NotificationPreferenceModal = require('../NotificationPreferenceModal'); var _NotificationPreferenceModal2 = _interopRequireDefault(_NotificationPreferenceModal);
var _InboxItemAvatar = require('../InboxItemAvatar'); var _InboxItemAvatar2 = _interopRequireDefault(_InboxItemAvatar);







const ChatHeader = ({
  blobjectKey,
  chatName,
  hasMyTeams,
  myUserKey,
  displayParticipants,
  conversationKey,
  conversationType,
  rightPaneOpen,
  onToggleRightPane,
}) => {
  const {
    isOpen: isNotificationsModalOpen,
    onOpen: onOpenNotificationsModal,
    onClose: onCloseNotificationsModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isRenameModalOpen,
    onOpen: onOpenRenameModal,
    onClose: onCloseRenameModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isExportModalOpen,
    onOpen: onOpenExportModal,
    onClose: onCloseExportModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isArchiveModalOpen,
    onOpen: onOpenArchiveModal,
    onClose: onCloseArchiveModal,
  } = _useToggle2.default.call(void 0, )

  const chatDesc =
    _chat3.useChatDesc.call(void 0, { displayParticipants, myUserKey, renderChatDesc: _renderChatDesc.renderChatDesc })
  const onExport = _hooks.useExportChat.call(void 0, { conversationKey })

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_NotificationPreferenceModal2.default, {
        isOpen: isNotificationsModalOpen,
        onClose: onCloseNotificationsModal,
        blobjectKey: blobjectKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      )
      , _react2.default.createElement(_RenameChatModal2.default, {
        isOpen: isRenameModalOpen,
        blobjectKey: blobjectKey,
        onClose: onCloseRenameModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
      )
      , _react2.default.createElement(_ExportChatModal2.default, {
        isOpen: isExportModalOpen,
        onClose: onCloseExportModal,
        onProceed: onExport, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      )
      , _react2.default.createElement(_ArchiveModal2.default, {
        blobjectKey: blobjectKey,
        isOpen: isArchiveModalOpen,
        onClose: onCloseArchiveModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
      )

      , _react2.default.createElement(_Header.HeaderMobileBackButton, { onClick: _actions2.default.gotoInbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}} )
      , _react2.default.createElement(_InboxItemAvatar2.default, { bkey: blobjectKey, size: 46, borderColor: "#fff", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} )
      , _react2.default.createElement(_Header.RightSideHeaderHeading, { title: chatName, subtitle: chatDesc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} )

      , _react2.default.createElement(_Header.HeaderItem, {
        iconName: "inbox-sidebar",
        className: "blmh-mobile-hide",
        onClick: onToggleRightPane,
        selected: rightPaneOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      )

      , _react2.default.createElement(_Header.HeaderMoreToggle, { className: "inbox-header--more-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
        , _react2.default.createElement(_Tooltip.TooltipMenuItem, {
          iconSrc: "/assets/icons/header/bell-dark.svg",
          label: "Manage notifications" ,
          onClick: onOpenNotificationsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
        )
        ,  conversationType !== _chat.CHAT_TYPE.REGULAR_PATIENT && (
          _react2.default.createElement(_Tooltip.TooltipMenuItem, {
            iconSrc: "/assets/icons/header/edit-dark.svg",
            label: "Rename this chat"  ,
            onClick: onOpenRenameModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
          )
        )
        , _react2.default.createElement(_Tooltip.TooltipMenuItem, {
          className: "blmh-desktop-hide",
          iconSrc: "/assets/icons/header/inbox-sidebar-dark.svg",
          label: "View participants" ,
          onClick: onToggleRightPane, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
        )
        , _react2.default.createElement(_Tooltip.TooltipMenuItem, {
          iconSrc: "/assets/icons/icon-download-dark-grey.svg",
          label: "Export this chat"  ,
          onClick: onOpenExportModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
        )
        ,  hasMyTeams && (
          _react2.default.createElement(_Tooltip.TooltipMenuItem, {
            iconSrc: "/assets/icons/icon-completed-dark-grey.svg",
            label: "Archive for..." ,
            iconClassName: "inbox-header--complete-icon",
            onClick: onOpenArchiveModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
          )
        )
      )
    )
  )
}










const mapState = (state, { blobjectKey }) => {
  const blobject = _blobject.selectBlobjectByKey.call(void 0, state, blobjectKey)

  return {
    chatName: _nullishCoalesce(_optionalChain([blobject, 'optionalAccess', _ => _.Name]), () => ( '')),
    conversationKey: _optionalChain([blobject, 'optionalAccess', _2 => _2.WrappedKey]),
    conversationType: _optionalChain([blobject, 'optionalAccess', _3 => _3.WrappedType]),
    myUserKey: _user.selectCurrentUserKey.call(void 0, state),
    displayParticipants: _conversation.selectDisplayChatParticipants.call(void 0, state, blobjectKey),
    hasMyTeams: _blobject.selectMyBlobjectTeamSubscribers.call(void 0, state, blobjectKey).length > 0,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(ChatHeader)
