"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/RightSide/Header/HeaderItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const HeaderItem = ({
  selected,
  disabled,
  iconName,
  className,
  ...props
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, 
    "right-side-header--item",
    selected && 'selected',
    disabled && 'disabled',
    className,
  )

  const iconSrc = `/assets/icons/header/${iconName}-${selected ? 'light' : 'dark'}.svg`

  return (
    _react2.default.createElement('button', {
      ...props,
      className: rootClassName,
      type: "button",
      disabled: disabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    
      , _react2.default.createElement('img', { src: iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}})
    )
  )
}

exports. default = HeaderItem
