"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');













const useLastSeenMessageCache = ({
  lastSeenMessageKey,
  cachedLastSeenMessageKey,
  setCachedLastSeenMessageKey,
  isCurrentUserActive,
  unreadMessagesCount,
  hasSettledScroll,
  isAtBottom,
}) => {
  const updateCachedLastSeenMessageKey = () => {
    if (
      !lastSeenMessageKey || cachedLastSeenMessageKey ||
      !unreadMessagesCount || !hasSettledScroll || !isCurrentUserActive
    ) return
    setCachedLastSeenMessageKey(lastSeenMessageKey)
  }

  // User enters the chat
  // eslint-disable-next-line react-hooks/exhaustive-deps
  _react.useEffect.call(void 0, () => updateCachedLastSeenMessageKey(), [hasSettledScroll])

  // There's a new message in an open chat
  _react.useEffect.call(void 0, 
    () => { !isAtBottom && updateCachedLastSeenMessageKey() },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [lastSeenMessageKey, unreadMessagesCount],
  )

  // User goes INACTIVE
  _react.useEffect.call(void 0, 
    () => {
      if (
        isCurrentUserActive ||
        !unreadMessagesCount ||
        cachedLastSeenMessageKey
      ) return

      setCachedLastSeenMessageKey(lastSeenMessageKey)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCurrentUserActive, unreadMessagesCount],
  )

  return { cachedLastSeenMessageKey }
}

exports. default = useLastSeenMessageCache
