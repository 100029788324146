"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _base64 = require('base-64');

 const dataURLToFile = (base64, filename) => {
  const arr = base64.split(',')
  const mime = _optionalChain([arr, 'access', _ => _[0], 'optionalAccess', _2 => _2.match, 'call', _3 => _3(/:(.*?);/), 'optionalAccess', _4 => _4[1]]) || ''
  const bstr = _base64.decode.call(void 0, _optionalChain([arr, 'optionalAccess', _5 => _5[1]]) || '')

  let n = bstr.length
  const u8arr = new Uint8Array(n)

  while (n--) u8arr[n] = bstr.charCodeAt(n)

  return new File([u8arr], filename, { type: mime })
}; exports.dataURLToFile = dataURLToFile
