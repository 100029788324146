"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/CampaignForm/Form/SenderPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');





var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _usePagination = require('shared/hooks/usePagination'); var _usePagination2 = _interopRequireDefault(_usePagination);

var _Form = require('web/components/Form');
var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _SenderItem = require('./SenderItem'); var _SenderItem2 = _interopRequireDefault(_SenderItem);

const MEMBER_LOAD_LIMIT = 30
const keyExtractor = (u) => u.UserKey


const makeRenderSenderOption = orgKey => props => (
  _react2.default.createElement(_SenderItem2.default, {
    key: props.option,
    orgKey: orgKey,
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
  )
)








const SenderPicker = ({
  teamKey,
  orgKey,
  senderKey,
  onSelectSender,
}) => {
  const loadItemsList = _react.useCallback.call(void 0, 
    (_query, Offset, Limit) => teamKey
      ? _actions2.default
        .findUsersInTeam({ TeamKey: teamKey, Offset, Limit })
        .then(r => r.Users)
      : Promise.reject(),
    [teamKey],
  )

  const { isLoading, hasDrained, itemKeysList, onLoadMore } =
    _usePagination2.default.call(void 0, {
      loadItemsList,
      keyExtractor,
      limit: MEMBER_LOAD_LIMIT,
    })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const Option = _react.useCallback.call(void 0, makeRenderSenderOption(orgKey), [orgKey])

  if (!orgKey) return null

  const contentFooter = (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  itemKeysList.length && !hasDrained
        ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: onLoadMore, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
        : null
      
      ,  !hasDrained && (
        _react2.default.createElement(_ListResultsStatus2.default, {
          isLoading: isLoading,
          resultsCount: itemKeysList.length,
          noResultsLabel: "No users found."  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        )
      )
    )
  )

  return (
    _react2.default.createElement(_Form.GenericSelect, {
      options: itemKeysList,
      selectedOption: senderKey,
      onChange: onSelectSender,
      OptionComponent: Option,
      contentFooter: contentFooter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
    )
  )
}

exports. default = SenderPicker
