"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/DropdownParticipantsPicker/ChipInputWithSelect.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _ChipInput = require('./ChipInput'); var _ChipInput2 = _interopRequireDefault(_ChipInput);
var _SuggestionBox = require('./SuggestionBox'); var _SuggestionBox2 = _interopRequireDefault(_SuggestionBox);

class ChipInputWithSelect extends _react.Component {
  constructor (props) {
    super(props);ChipInputWithSelect.prototype.__init.call(this);ChipInputWithSelect.prototype.__init2.call(this);ChipInputWithSelect.prototype.__init3.call(this);ChipInputWithSelect.prototype.__init4.call(this);ChipInputWithSelect.prototype.__init5.call(this);ChipInputWithSelect.prototype.__init6.call(this);ChipInputWithSelect.prototype.__init7.call(this);ChipInputWithSelect.prototype.__init8.call(this);ChipInputWithSelect.prototype.__init9.call(this);
    this.state = {
      isHoveringOverBox: false,
      isBoxOpened: false,
      isInputFocused: false,
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { options } = this.props
    const { isBoxOpened, isInputFocused } = this.state

    if (this.getLengthOfAllOptions() > 0 && !isBoxOpened && isInputFocused) {
      this.setState({ isBoxOpened: true })
    }
    if (!options || this.getLengthOfAllOptions() === 0 && isBoxOpened === true) {
      this.setState({ isBoxOpened: false })
    }
    if (!this.state.isBoxOpened && !this.state.isInputFocused && this.props.value.length > 0) {
      /* clear input on defocus */
      this.props.onInputChange(this.props.inputId, "")
    }
  }

  __init() {this.onInputFocus = (e) => {
    this.setState({ isInputFocused: true })
  }}

  __init2() {this.onInputBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
    this.setState({ isInputFocused: false })
  }}

  __init3() {this.getLengthOfAllOptions = () => {
    if (!this.props.options) {
      return 0
    }
    let count = 0
    Object.values(this.props.options).map(option => option.items).forEach((items, i) => {
      if (!items) {
        return
      }
      items.forEach((item, i) => {
        /* ActionPropType and SectionPropType count as 1 option */
        count = item.entities ? count + item.entities.length : count + 1
      })
    })
    return count
  }}

  __init4() {this.closeSuggestionBox = () => {
    this.setState({ isBoxOpened: false })
  }}

  __init5() {this.onOptionSelected = (option) => {
    this.inputRef.focus()
    if (this.props.onInputChange) {
      this.props.onInputChange(this.props.inputId, "")
    }
    if (this.props.onOptionSelected) {
      this.props.onOptionSelected(option)
    }
  }}

  __init6() {this.onOptionRemoved = (id) => {
    this.inputRef.focus()
    if (this.props.onOptionRemoved) {
      this.props.onOptionRemoved(id)
    }
  }}

  __init7() {this.onActionSelected = (id) => {
    this.props.onActionSelected(id)
  }}

  __init8() {this.blur = () => {
    if (this.inputRef) {
      this.inputRef.blur()
    }
  }}

  __init9() {this.focus = () => {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }}

  render () {
    const { inputId, autoFocus, onInputChange, value, placeholder, options, selectedOptions } = this.props

    let suggestionBoxStyle = {}
    if (this.state.isBoxOpened && this.inputRef) {
      const { top, left, height } = this.inputRef.getBoundingClientRect()

      // If there isn't enough room to render below the input box then move it above instead
      if (window.innerHeight - top < 200) {
        suggestionBoxStyle = {
          left: left,
          bottom: window.innerHeight - top + 5,
          maxHeight: top - 5,
        }
      } else {
        suggestionBoxStyle = {
          left: left,
          top: top + height + 5,
          maxHeight: window.innerHeight - top - 70,
        }
      }
    }

    const showSuggestionBox = (this.state.isBoxOpened || this.state.isInputFocused) && this.getLengthOfAllOptions() > 0

    return (
      _react2.default.createElement(_react.Fragment, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
        , _react2.default.createElement(_ChipInput2.default, {
          autoFocus: autoFocus,
          className: this.props.inputClassName || "",
          ref: ref => this.inputRef = ref,
          onFocus: this.onInputFocus,
          onBlur: this.onInputBlur,
          inputId: inputId,
          value: value,
          placeholder: placeholder,
          onChipClose: this.onOptionRemoved,
          chips: selectedOptions,
          onChange: onInputChange,
          onChipAdded: this.props.onChipAdded, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
          )
        , _react2.default.createElement(_SuggestionBox2.default, {
          showTabs: true,
          options: options,
          style: suggestionBoxStyle,
          className: this.props.boxClassName || "",
          open: showSuggestionBox,
          onOptionSelected: this.onOptionSelected,
          onActionSelected: this.onActionSelected,
          onClickedOutsideBox: this.closeSuggestionBox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
          )
      )
    )
  }
}

exports. default = ChipInputWithSelect
