"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _msgs = require('./msgs');






const useQuickLoginDeviceApproval = ({
  shouldDeviceBeApproved,
  displayMsg,
}) => {
  const [isDeviceApproved, setIsDeviceApproved] = _react.useState.call(void 0, shouldDeviceBeApproved)

  _react.useEffect.call(void 0, 
    () => setIsDeviceApproved(shouldDeviceBeApproved),
    [setIsDeviceApproved, shouldDeviceBeApproved],
  )

  const handleToggleDeviceApproval = _react.useCallback.call(void 0, 
    async () => {
      setIsDeviceApproved(x => !x)
      const handleError = (e) => {
        log.error(e)
        displayMsg(_msgs.quickLoginMsgs.GENERIC_FAILURE)
        setIsDeviceApproved(isDeviceApproved)
      }
      if (isDeviceApproved) {
        await _actions2.default.disapproveQuickLoginDevice()
          .then(() => displayMsg(_msgs.quickLoginMsgs.DISAPPROVE_SUCCESS))
          .catch(handleError)
      } else {
        await _actions2.default.approveQuickLoginDevice()
          .then(() => displayMsg(_msgs.quickLoginMsgs.APPROVE_SUCCESS))
          .catch(handleError)
      }
    },
    [isDeviceApproved, setIsDeviceApproved, displayMsg],
  )

  return { isDeviceApproved, handleToggleDeviceApproval }
}

exports. default = useQuickLoginDeviceApproval
