"use strict";Object.defineProperty(exports, "__esModule", {value: true});











let listeners = {}

const getListenersByType = (type) => {
  const wildcardListeners = listeners['*']
  const specificListeners = listeners[type]
  if (!wildcardListeners) return specificListeners
  if (!specificListeners) return wildcardListeners
  return specificListeners.concat(wildcardListeners)
}

 const busMiddleware = store => next => action => {
  const type = action.type

  // run reducers before bus middleware
  next(action)

  const handlers = getListenersByType(type)
  if (handlers) {
    const state = store.getState()
    handlers.forEach((obj) => obj.handler(action, state))

    // lol this is terrible but I don't want to learn more about js iterators to fix it the "right way"
    let allKilled = false
    while (!allKilled) {
      allKilled = true
      for (let i = 0; i < handlers.length; i++) {
        const obj = handlers[i]
        if (obj.howManyTimes === 'once') {
          allKilled = false
          bus.off(type, obj.handler)
          break
        } // End of if
      } // End of for
    } // End of while
  }
}; exports.busMiddleware = busMiddleware

const bus = {
  on: (name, handler, type = 'on') => {
    if (listeners[name] == null) listeners[name] = []
    listeners[name].push({ handler, howManyTimes: type })
  },

  once: (name, handler) => bus.on(name, handler, 'once'),

  off: (name, handler) => {
    if (name == null) {
      listeners = {}
      return
    }

    if (handler == null) {
      delete listeners[name]
      return
    }

    const relevantListeners = listeners[name]
    if (relevantListeners) {
      let index = 0
      for (let i = 0; i < relevantListeners.length; i++) {
        if (handler === relevantListeners[i].handler) {
          index = i
          break
        }
      }
      if (index >= 0) relevantListeners.splice(index, 1)
    }
  },
}


 const trigger = () => null; exports.trigger = trigger

exports. default = bus
