"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _store = require('web/store');






 const useRouting = ({
  switchToUsernameLogin,
  switchToQuickLogin,
}) => {
  const gotoUsernameLogin = () => { _store.history.push(_routes2.default.LOGIN.ROOT); switchToUsernameLogin() }
  const gotoQuickLogin = () => { _store.history.push(_routes2.default.LOGIN.ROOT); switchToQuickLogin() }
  const gotoForgotPassword = () => _store.history.push(_routes2.default.LOGIN.FORGOT_PASSWORD)
  const gotoExpiredPassword = () => _store.history.push(_routes2.default.LOGIN.EXPIRED_PASSWORD)

  return {
    gotoUsernameLogin,
    gotoQuickLogin,
    gotoForgotPassword,
    gotoExpiredPassword,
  }
}; exports.useRouting = useRouting
