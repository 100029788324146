"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }



var _entity = require('shared/enum/entity');
var _time = require('shared/services/time');
var _phone = require('shared/services/phone');
var _array = require('shared/services/array');



const noop = () => ''

 const makeDOBPatientDescription = p => p.DateOfBirth
  ? _time.formatDisplayDate.call(void 0, _time.apiDateStrToDate.call(void 0, p.DateOfBirth))
  : ''; exports.makeDOBPatientDescription = makeDOBPatientDescription

 const makeContactPatientDescription = p => {
  const displayDOB = _time.formatDisplayDate.call(void 0, _time.apiDateStrToDate.call(void 0, p.DateOfBirth))
  const descs = p.FirstName || p.LastName
    ? [p.Email, _phone.formatPhoneNumber.call(void 0, p.Phone), displayDOB, p.ForeignID]
    : [displayDOB, p.ForeignID]
  return descs.find(_array.truthy) || ''
}; exports.makeContactPatientDescription = makeContactPatientDescription

 const makeOrgNamePatientDescription = (p, orgsMap) =>
  _optionalChain([orgsMap, 'optionalAccess', _ => _[p.OrgKey], 'optionalAccess', _2 => _2.Name]) || ''; exports.makeOrgNamePatientDescription = makeOrgNamePatientDescription

 const makeDisplayPatientName = (p) => p.Name; exports.makeDisplayPatientName = makeDisplayPatientName






 const mapPatientToEntity = (
  orgsMap,
  makeDescription = noop,
) => p => ({
  entityKey: p.PatientKey,
  type: _entity.ENTITY_TYPES.PATIENT,
  name: exports.makeDisplayPatientName.call(void 0, p),
  description: makeDescription(p, orgsMap),
  wrappedEntity: p,
}); exports.mapPatientToEntity = mapPatientToEntity
