"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');





var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _noticeBar = require('web/services/context/noticeBar');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _useConfirm = require('./useConfirm'); var _useConfirm2 = _interopRequireDefault(_useConfirm);

const noop = async () => null

const useMemberActions = (orgKey) => {
  const [selectedUser, setSelectedUser] = _react.useState(null)
  const userKey = _optionalChain([selectedUser, 'optionalAccess', _ => _.wrappedEntity, 'access', _2 => _2.UserKey])

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handlers = _react.useMemo.call(void 0, 
    () => ({
      onRemoveUser: async () => userKey &&
        _actions2.default.removeUserFromOrg({ OrgKey: orgKey, UserKey: userKey }),
      onApproveUser: async (uk) => {
        const UserKey = uk || userKey
        return UserKey && _actions2.default.approvePendingUser({ OrgKey: orgKey, UserKey })
      },
      onRejectUser: async (uk) => {
        const UserKey = uk || userKey
        return UserKey && _actions2.default.rejectPendingUser({ OrgKey: orgKey, UserKey })
      },
      onEditUser: (UserKey, NewTitle, NewRole) =>
        Promise.all([
          _actions2.default.changeUserOrgTitle({ UserKey, OrgKey: orgKey, NewTitle }),
          _actions2.default.changeUserOrgRole({ UserKey, OrgKey: orgKey, NewRole }),
        ])
          .then(() => showSuccessBar("User details updated"))
          .catch(() => showWarningBar("Failed to update user details")),
      onResendInvite: (UserKey) =>
        _actions2.default.resendOrgUserInvite({ OrgKey: orgKey, UserKey })
          .then(() => showSuccessBar("Email invite resent successfully"))
          .catch(() => showWarningBar("Failed to resend email invite")),
      onAddMembers: (UserKeys) => _actions2.default.addUsersToOrg({ OrgKey: orgKey, UserKeys }),
      onExportRelationships: () =>
        _actions2.default.getOrgRelationshipsLogsURL({ OrgKey: orgKey })
          .then(url => { window.open(url, "_self") })
          .catch(() => showWarningBar('Failed to export relationship logs')),
    }),
    [orgKey, userKey, showSuccessBar, showWarningBar],
  )

  const gotoUserPage = _react.useCallback.call(void 0, 
    (userKey) => _actions2.default.gotoUserPage(orgKey, userKey),
    [orgKey],
  )

  const {
    isConfirmModalOpen: isRemoveModalOpen,
    onCloseConfirmModal: onCloseRemoveModal,
    onRequest: onRemoveUser,
    onConfirm: onConfirmRemoveUser,
  } = _useConfirm2.default.call(void 0, setSelectedUser, handlers.onRemoveUser)

  const {
    isConfirmModalOpen: isApproveModalOpen,
    onCloseConfirmModal: onCloseApproveModal,
    onRequest: onApproveUser,
    onConfirm: onConfirmApproveUser,
  } = _useConfirm2.default.call(void 0, setSelectedUser, handlers.onApproveUser)

  const {
    isConfirmModalOpen: isRejectModalOpen,
    onCloseConfirmModal: onCloseRejectModal,
    onRequest: onRejectUser,
    onConfirm: onConfirmRejectUser,
  } = _useConfirm2.default.call(void 0, setSelectedUser, handlers.onRejectUser)

  const {
    isConfirmModalOpen: isEditModalOpen,
    onCloseConfirmModal: onCloseEditModal,
    onRequest: onEditUser,
  } = _useConfirm2.default.call(void 0, setSelectedUser, noop)

  const {
    isOpen: isAddMembersModalOpen,
    onOpen: onOpenAddMembersModal,
    onClose: onCloseAddMembersModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isExportRelationshipsModalOpen,
    onOpen: onOpenExportRelationshipsModal,
    onClose: onCloseExportRelationshipsModal,
  } = _useToggle2.default.call(void 0, )

  return {
    selectedUser,

    isRemoveModalOpen,
    onCloseRemoveModal,
    onRemoveUser,
    onConfirmRemoveUser,

    isApproveModalOpen,
    onCloseApproveModal,
    onApproveUser,
    onConfirmApproveUser,

    isRejectModalOpen,
    onCloseRejectModal,
    onRejectUser,
    onConfirmRejectUser,

    isEditModalOpen,
    onCloseEditModal,
    onEditUser,
    onSubmitEditedUser: handlers.onEditUser,

    onResendUserInvite: handlers.onResendInvite,
    gotoUserPage,

    isAddMembersModalOpen,
    onOpenAddMembersModal,
    onCloseAddMembersModal,
    onAddMembers: handlers.onAddMembers,

    isExportRelationshipsModalOpen,
    onOpenExportRelationshipsModal,
    onCloseExportRelationshipsModal,
    onExportRelationships: handlers.onExportRelationships,
  }
}

exports. default = useMemberActions
