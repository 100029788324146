"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/FileMessage/FileImage/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _message = require('shared/enum/message');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _ModalView = require('./ModalView'); var _ModalView2 = _interopRequireDefault(_ModalView);










const FileImage = ({
  fileName, fileLink,
  deliveryStatus,
  onDownload, onAbort, onRetry,
}) => {
  const [showingLightbox, setShowingLightbox] = _react.useState.call(void 0, false)
  const openLightBox = () => setShowingLightbox(true)
  const closeLightBox = () => setShowingLightbox(false)

  const isIframed = (window.location !== window.parent.location)
  const modalGateway = isIframed
    ? null
    : (
        _react2.default.createElement(_Modal2.default, {
          isOpen: showingLightbox && !!fileLink,
          fullScreen: true,
          onClose: closeLightBox,
          className: "file-image--preview-modal",
          bodyClassName: "file-image--preview-modal--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        
          , _react2.default.createElement(_ModalView2.default, {
            imageSrc: fileLink,
            imageCaption: fileName,
            onClose: closeLightBox,
            onDownload: onDownload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
          )
        )
      )

  const previewStyle = _react.useMemo.call(void 0, () => ({
    background: `no-repeat center / cover url('${fileLink || ''}')`,
  }), [fileLink])

  const isLoading = deliveryStatus === _message.deliveryStatus.SENT
  const hasFailed = deliveryStatus === _message.deliveryStatus.FAILED
  const isDelivered = deliveryStatus === _message.deliveryStatus.DELIVERED

  const [overlayClassName, controlsClassName] = _react.useMemo.call(void 0, 
    () => [
      _classBuilder2.default.call(void 0, 
        "file-message--image--overlay",
        (isLoading || hasFailed) && "file-message--image--overlay-blurred",
      ),
      _classBuilder2.default.call(void 0, 
        "file-message--image--controls",
        (isLoading || hasFailed) && "file-message--image--controls-active",
      ),
    ],
    [isLoading, hasFailed],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  modalGateway 
      , _react2.default.createElement('div', {
        onClick: isDelivered ? openLightBox : undefined,
        className: "file-message--image",
        style: previewStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      
        , _react2.default.createElement('div', { className: overlayClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}} )
        ,  isLoading && (
          _react2.default.createElement('div', { onClick: onAbort, role: "button", className: controlsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
            , _react2.default.createElement('div', { className: "file-message--btn file-message--btn-loading" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}} )
          )
        )
        ,  hasFailed && (
          _react2.default.createElement('div', { onClick: onRetry, role: "button", className: controlsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
            , _react2.default.createElement('div', { className: "file-message--btn file-message--btn-retry" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} )
          )
        )
      )
    )
  )
}

exports. default = FileImage
