"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Toasts/NetInfo.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);





const NetInfoToast = ({ isOnStage }) => (
  _react2.default.createElement('div', {
    className: _classBuilder2.default.call(void 0, 'netinfo-toast', !isOnStage && 'hidden'),
    'aria-hidden': !isOnStage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
  
    , _react2.default.createElement(_Spinner2.default, { size: "18px", className: "netinfo-toast--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} )
    , _react2.default.createElement('span', { className: "netinfo-toast--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, "Connecting..."

    )
  )
)

exports. default = NetInfoToast
