"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _entity = require('shared/enum/entity');









 const makeRelationshipDescription = (rel, allOrgs) => {
  const org = _optionalChain([allOrgs, 'optionalAccess', _ => _[rel.OrgKey]])

  return rel.Title
    ? `${rel.Title} - ${_optionalChain([org, 'optionalAccess', _2 => _2.Name])}`
    : _optionalChain([org, 'optionalAccess', _3 => _3.Name]) || ''
}; exports.makeRelationshipDescription = makeRelationshipDescription

const noop = () => ''
 const mapRelationshipToEntity =
  (
    allOrgs,
    makeDescription = noop,
    user,
  ) =>
    (rel) => ({
      entityKey: rel.OrgKey,
      type: _entity.ENTITY_TYPES.RELATIONSHIP,
      name: user.Name,
      description: makeDescription(rel, allOrgs),
      wrappedEntity: rel,
    }); exports.mapRelationshipToEntity = mapRelationshipToEntity
