"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/SpeakerNameBar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _SoundAmplitude = require('web/components/VideoCall/SoundAmplitude'); var _SoundAmplitude2 = _interopRequireDefault(_SoundAmplitude);

const SpeakerNameBar = ({ audioTrack, name }) => (
  _react2.default.createElement('div', { className: "video-call--caller-name-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    , _react2.default.createElement('span', { className: "video-call--caller-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}},  name )
    /* nameSuffix &&
      <span className="video-call--caller-name-prefix">MD</span>
    */
    ,  audioTrack
      ? _react2.default.createElement(_SoundAmplitude2.default, {
          className: "video-call--caller-sound-amp",
          audioTrack: audioTrack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        )
      : _react2.default.createElement('div', { className: "video-call--caller-mic-warn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
          , _react2.default.createElement('img', {
            src: "/assets/icons/video/mic-off-white-no-outline.svg",
            alt: "🔇", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
          )
        )
    
  )
)

SpeakerNameBar.propTypes = {
  name: _proptypes2.default.string,
  audioTrack: _proptypes2.default.object,
}

exports. default = SpeakerNameBar
