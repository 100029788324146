"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');



var VIEW_MODE; (function (VIEW_MODE) {
  const relevant = 'VIEW_MODE_RELEVANT'; VIEW_MODE["relevant"] = relevant;
  const latest = 'VIEW_MODE_LATEST'; VIEW_MODE["latest"] = latest;
})(VIEW_MODE || (VIEW_MODE = {}));

const useBatchSwitch = (
  messageBatches,
  relevantBatchIdx,
  latestBatchIdx,
) => {
  const [mode, setMode] = _react.useState.call(void 0, VIEW_MODE.relevant)
  const setLatestBatch = _react.useCallback.call(void 0, () => setMode(VIEW_MODE.latest), [])

  const messages = _optionalChain([messageBatches, 'optionalAccess', _ => _[
    mode === VIEW_MODE.relevant
      ? relevantBatchIdx
      : latestBatchIdx
  ]]) || []

  const isSingleBatch = relevantBatchIdx === latestBatchIdx

  const isOnLatestBatch = isSingleBatch || mode === VIEW_MODE.latest
  const isOnRelevantBatch = isSingleBatch || mode === VIEW_MODE.relevant

  return {
    messages,
    setLatestBatch,
    isOnLatestBatch,
    isOnRelevantBatch,
  }
}

exports. default = useBatchSwitch
