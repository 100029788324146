"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');


var _common = require('shared/selectors/blobject/common');
var _team = require('shared/selectors/team');
var _peers = require('shared/selectors/peers');

var _utils = require('./utils');

 const makeSelectPopulatedBlobjectSubscribers = () => _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _team.selectAllTeams,
  _peers.selectAllCombinedUsers,
  _peers.selectAllCombinedPatients,
  (blobject, allTeams, allUsers, allPatients) => {
    if (!blobject) return _utils.EMPTY_SUBSCRIBERS_LIST

    const {
      TeamSubscribers,
      UserSubscribers,
      PatientSubscribers,
    } = blobject

    const userSubscribersList = _utils.populateSubscribers.call(void 0, UserSubscribers, allUsers)
    const teamSubscribersList = _utils.populateSubscribers.call(void 0, TeamSubscribers, allTeams)
    const patientSubscribersList = _utils.populateSubscribers.call(void 0, PatientSubscribers, allPatients)

    const populatedSubscribers = {
      userSubscribersList,
      teamSubscribersList,
      patientSubscribersList,
    }

    return populatedSubscribers
  },
); exports.makeSelectPopulatedBlobjectSubscribers = makeSelectPopulatedBlobjectSubscribers
