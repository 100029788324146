"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/SMSPlanModal/TierSelector.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Form = require('web/components/Form');
var _HintToggle = require('web/components/HintToggle'); var _HintToggle2 = _interopRequireDefault(_HintToggle);







const TierSelector = ({
  selectedItem,
  smsItemsList,
  onChange,
}) => (
  _react2.default.createElement('div', { className: "plan-modal--summary--tier-picker-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    , _react2.default.createElement(_Form.SimpleSelect, {
      className: "plan-modal--summary--tier-picker",
      value: selectedItem.Units.toString(),
      onChange: onChange,
      options: smsItemsList.map(i => ({
        value: i.Units.toString(),
        label: `${i.Units.toLocaleString()} contacts/month`,
      })), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    )
    , _react2.default.createElement(_HintToggle2.default, {
      place: "right",
      hint: "A contact is only counted if they receive at least one message during the billing month."               ,
      className: "plan-modal--summary--tier-picker--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    )
  )
)

exports. default = TierSelector
