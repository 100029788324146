"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _errors = require('shared/errors');

const getBaseUrl = () => {
  const secure = SOCKET_SERVER_SECURE
  const host = SOCKET_SERVER_HOST
  const port = SOCKET_SERVER_PORT

  return `${secure ? 'https' : 'http'}://${host}:${port}`
}

/**
  * @deprecated - Use `shared/services/client`
  */
const BloomFetch = async (
  path,
  verb,
  data,
) => {
  let body
  if (data) {
    body = new FormData()
    for (const name in data) {
      body.append(name, data[name])
    }
  }

  return fetch(`${getBaseUrl()}${path}`, {
    credentials: 'include',
    method: verb,
    body: body,
  }).then((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response.json()
    } else {
      return Promise.reject(new (0, _errors.RestError)(verb, path, response))
    }
  })
}

exports. default = BloomFetch
