"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/WarningBar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);












const WarningBar = ({
  className,
  textClassName,
  theme = 'default',
  text,
  iconSrc,
  linkText,
  onLinkClick,
  linkHref,
  children,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
    'warning-bar',
    theme === 'warning' && 'warning-bar--warning',
    className,
  ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
    , _react2.default.createElement('img', {
      className: "warning-bar--icon",
      src: iconSrc || (theme === 'warning'
        ? '/assets/icons/alert-orange.svg'
        : '/assets/icons/alert-grey.svg'
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
    )
    , _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, 
      'warning-bar--text',
      textClassName,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      ,  text || children 
    )
    ,  linkText &&
      _react2.default.createElement('a', {
        className: "warning-bar--link",
        ...(linkHref
          ? { href: linkHref, target: '_blank' }
          : { onClick: onLinkClick }
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      , linkText)
    
  )
)

exports. default = WarningBar
