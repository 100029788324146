"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _time = require('./time');
var _Message = require('shared/components/Message');

function sameDate (a, b) {
  const d1 = new Date(a)
  const d2 = new Date(b)

  return d1.getFullYear() === d2.getFullYear() &&
         d1.getMonth() === d2.getMonth() &&
         d1.getDate() === d2.getDate()
}

const THIRTY_MINUTES = 60 * 30

const isSimilarTime = (a, b, delta = THIRTY_MINUTES) => {
  const actualDeltaInSeconds = Math.abs(new Date(a) - new Date(b)) / 1000
  return actualDeltaInSeconds < delta
}

/* Parameters:
 * messages: a sorted array of backend message objects (for struct def visit: conversation_view.go)
 * conversation: a backend conversation object (for struct def visit: conversation_view.go)
 * blobject: a backend blobject object (for struct def visit: blobject_view.go)
 */
const EMPTY_ARR = []
 function processMessages (messages = EMPTY_ARR, conversation, blobject) {
  /* TODO: is proper conversation */
  if (!messages.length || !conversation) return EMPTY_ARR

  /* helper object for latest time seen for each user */
  const seenTimes = {}

  if (blobject) {
    const pSubscribers = blobject.PatientSubscribers
      ? Object.values(blobject.PatientSubscribers)
      : EMPTY_ARR
    const uSubscribers = blobject.UserMetadata
      ? Object.values(blobject.UserMetadata)
      : EMPTY_ARR

    pSubscribers.concat(uSubscribers).forEach((subscriber) => {
      const subscriberKey = subscriber.UserKey || subscriber.PatientKey
      seenTimes[subscriberKey] = subscriber.SeenTime
    })
  }

  const feedItems = []
  messages.forEach((message, i) => {
    const nextMessage = messages[i + 1]
    const previousMessage = messages[i - 1]
    const isLastMessage = !nextMessage
    const isFirstMessage = !previousMessage

    const SeenBy = {}
    Object.keys(seenTimes).forEach(uk => {
      const latestSeenTime = seenTimes[uk]
      if (latestSeenTime >= message.SentAt) {
        const isLatestSeen = i === messages.length - 1 || (!isLastMessage && nextMessage.SentAt > latestSeenTime)
        const seenAtObject = {
          SeenTime: latestSeenTime, /* in the future, when user actually saw */
          SubscriberKey: uk,
          IsLatestMessage: isLatestSeen,
        }
        SeenBy[uk] = seenAtObject
      }
    })

    const SameSenderAsNextMessage = nextMessage &&
                                    nextMessage.UserKey === message.UserKey &&
                                    nextMessage.TeamKey === message.TeamKey &&
                                    nextMessage.PatientKey === message.PatientKey &&
                                    isSimilarTime(nextMessage.SentAt, message.SentAt, THIRTY_MINUTES)

    const SameSenderAsPreviousMessage = previousMessage &&
                                        previousMessage.UserKey === message.UserKey &&
                                        previousMessage.TeamKey === message.TeamKey &&
                                        previousMessage.PatientKey === message.PatientKey &&
                                        isSimilarTime(previousMessage.SentAt, message.SentAt, THIRTY_MINUTES)

    if (isFirstMessage || !sameDate(previousMessage.SentAt, message.SentAt)) {
      feedItems.unshift(generateDateMessage(message.SentAt))
    }

    feedItems.unshift({ ...message, SeenBy, SameSenderAsNextMessage, SameSenderAsPreviousMessage })
  })

  return feedItems
} exports.processMessages = processMessages;

/* get chat-ready date string from  */
function generateDateMessage (dateISOString) {
  const date = new Date(dateISOString)
  date.setHours(0, 0, 0, 0)
  const isToday = sameDate(date, new Date())
  const midnightString = date.toISOString()
  return {
    MessageType: _Message.MessageTypes.DATE,
    MessageKey: midnightString,
    MessageText: isToday ? "Today" : _time.getDateString.call(void 0, date),
    SentAt: midnightString,
    Sequence: -1,
    SenderType: _Message.SenderTypes.NONE,
  }
}
