"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');

var _types = require('web/screens/Login/types');

 const useLoginMethod = () => {
  const [currentLoginMethod, setCurrentLoginMethod] = _react.useState(null)

  const switchToQuickLogin =
    _react.useCallback.call(void 0, () => setCurrentLoginMethod(_types.LoginMethod.QUICK), [])
  const switchToUsernameLogin =
    _react.useCallback.call(void 0, () => setCurrentLoginMethod(_types.LoginMethod.USERNAME), [])

  return {
    currentLoginMethod,
    switchToQuickLogin,
    switchToUsernameLogin,
  }
}; exports.useLoginMethod = useLoginMethod
