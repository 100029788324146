"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');








var _session = require('shared/selectors/session');
var _myRelations = require('shared/selectors/myRelations');
var _entity = require('shared/services/entity');

 const selectNewChatSenderEnv = _reselect.createSelector.call(void 0, 
  _session.selectSelectedOrgKey,
  _session.selectSelectedTeamKey,
  _myRelations.selectMyEntities,
  (selectedOrgKey, selectedTeamKey, myEntities) => {
    const {
      orgsList: myOrgEntitiesList,
      teamsList: myTeamEntitiesList,
      entitiesMap: myEntitiesMap,
    } = myEntities

    const selectedEntity = (
      selectedTeamKey && myEntitiesMap[selectedTeamKey] ||
      selectedOrgKey && myEntitiesMap[selectedOrgKey] ||
      null
    ) 

    const selectedSenderKey = selectedEntity && (
      _entity.isEntityTeam.call(void 0, selectedEntity)
        ? selectedEntity.wrappedEntity.TeamKey
        : selectedEntity.wrappedEntity.OrgKey
    )

    const defaultSenderKey = selectedSenderKey
      || _optionalChain([myOrgEntitiesList, 'access', _ => _[0], 'optionalAccess', _2 => _2.entityKey])
      || _optionalChain([myTeamEntitiesList, 'access', _3 => _3[0], 'optionalAccess', _4 => _4.entityKey])

    return {
      orgKeyContext: selectedOrgKey,
      myOrgEntitiesList,
      myTeamEntitiesList,
      myEntitiesMap,
      defaultSenderKey,
    }
  },
); exports.selectNewChatSenderEnv = selectNewChatSenderEnv
