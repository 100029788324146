"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/Quick/UserPreview.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');

var _log = require('shared/log');


var _settings = require('shared/selectors/settings');

var _Avatar = require('web/components/Avatar');
var _Tooltip = require('web/components/Tooltip');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const removeTooltipContent = _react2.default.createElement(_Tooltip.TooltipContent, { role: "tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, "Remove")







const UserPreview = ({
  userKey, onPick, onRemove, name, titles,
}) => {
  const handlePick = () => _optionalChain([onPick, 'optionalCall', _ => _(userKey)])
  const handleRemove = e => {
    e.stopPropagation()
    if (userKey) _optionalChain([onRemove, 'optionalCall', _2 => _2(userKey), 'access', _3 => _3.catch, 'call', _4 => _4(_log.log.info)])
  }

  return (
    _react2.default.createElement('div', {
      onClick: handlePick,
      role: onPick ? 'button' : 'presentation',
      className: _classBuilder2.default.call(void 0, 
        "login--quick--user",
        onPick && "login--quick--user--selectable",
        !userKey && "login--quick--user--other",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
    
      ,  userKey
        ? _react2.default.createElement(_Avatar.UserAvatar, {
            className: "login--quick--user-avatar",
            userKey: userKey,
            noOnlineIndicator: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          )
        : _react2.default.createElement('div', { className: "login--quick--user-avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )
      
      , _react2.default.createElement('div', { className: "login--quick--user-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , _react2.default.createElement('span', { className: "login--quick--user-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}},  name )
        ,  titles.map((title, i) => (
          _react2.default.createElement('span', { key: i, className: "login--quick--user-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}},  title )
        ))
      )
      ,  onRemove && (
        _react2.default.createElement('div', { className: "login--quick--user-controls", role: "presentation", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
          , _react2.default.createElement(_Tooltip.HoverTooltip, {
            type: "dark",
            place: "top",
            content: removeTooltipContent,
            className: "login--quick--user-remove-btn--tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          
            , _react2.default.createElement('button', {
              onClick: handleRemove,
              'aria-label': "Remove",
              className: "login--quick--user-remove-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
            )
          )
        )
      )
    )
  )
}






const mapState = (state, { userKey }) => {
  const user = userKey && _settings.selectPopulatedQuickLoginUserByKey.call(void 0, state, userKey)

  return {
    name: _optionalChain([user, 'optionalAccess', _5 => _5.Name]) || "Someone else",
    titles: Object
      .values(_optionalChain([user, 'optionalAccess', _6 => _6.Relationships]) || {})
      .map(r => `${r.Title ? r.Title + ' · ' : ''}${r.OrgName}`),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(UserPreview)
