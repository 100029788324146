"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Super/SuperTableTR.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _store = require('web/store');

const clickDelay = 200

 class SuperTableTR extends _react.Component {constructor(...args) { super(...args); SuperTableTR.prototype.__init.call(this);SuperTableTR.prototype.__init2.call(this); }
  __init() {this.onMouseUp = () => {
    if (!this.props.to) {
      return
    }

    if (Date.now() - this.mouseDownTime < clickDelay) {
      _store.history.push(this.props.to)
    }
  }}

  __init2() {this.onMouseDown = () => {
    this.mouseDownTime = Date.now()
  }}

  render () {
    const { children, ...props } = this.props
    return (
      _react2.default.createElement('tr', { onMouseDown: this.onMouseDown,
          onMouseUp: this.onMouseUp,
          ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        , children
      )
    )
  }
} exports.default = SuperTableTR;

SuperTableTR.propTypes = {
  to: _proptypes2.default.string,
}
