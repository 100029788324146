"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _createNamedExportFrom(obj, localName, importedName) { Object.defineProperty(exports, localName, {enumerable: true, get: () => obj[importedName]}); }var _joi = require('joi'); var _joi2 = _interopRequireDefault(_joi);


var _extensions = require('./extensions');











const extJoi = _joi2.default.extend(
  _extensions.sanitizeExtension,
  _extensions.contactPhoneExtension,
  _extensions.contactEmailExtension,
)

 const requiredString = () => extJoi.string().sanitize().required(); exports.requiredString = requiredString
 const optionalString = () => extJoi.string().sanitize().empty(''); exports.optionalString = optionalString

var _errorsMessages = require('./errorsMessages'); _createNamedExportFrom(_errorsMessages, 'makeStrErrors', 'makeStrErrors');

exports. default = extJoi
