"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _videoCall = require('web/actions/videoCall');

 const selectIsOnCall = state => state.videoCall.isOnCall; exports.selectIsOnCall = selectIsOnCall

// selectRoomLink: state => state.currentRoom.link,
// selectRoomName: state => state.currentRoom.name,

 const selectArePermsGranted = state => state.videoCall.permsState === _videoCall.permsStates.GRANTED; exports.selectArePermsGranted = selectArePermsGranted
 const selectArePermsDenied = state => state.videoCall.permsState === _videoCall.permsStates.DENIED; exports.selectArePermsDenied = selectArePermsDenied
 const selectArePermsBlocked = state => state.videoCall.permsState === _videoCall.permsStates.BLOCKED; exports.selectArePermsBlocked = selectArePermsBlocked
 const selectIsBrowserCompatible = state => state.videoCall.isBrowserCompatible; exports.selectIsBrowserCompatible = selectIsBrowserCompatible
 const selectIsMicOn = state => state.videoCall.isMicOn; exports.selectIsMicOn = selectIsMicOn
 const selectIsCamOn = state => state.videoCall.isCamOn; exports.selectIsCamOn = selectIsCamOn
 const selectIsScreenSharingOn = state => state.videoCall.isScreenSharingOn; exports.selectIsScreenSharingOn = selectIsScreenSharingOn
 const selectAreMicIssuesPresent = state => state.videoCall.areMicIssuesPresent; exports.selectAreMicIssuesPresent = selectAreMicIssuesPresent
 const selectAreCamIssuesPresent = state => state.videoCall.areCamIssuesPresent; exports.selectAreCamIssuesPresent = selectAreCamIssuesPresent
 const selectIsCamCycleAvailable = state => state.videoCall.videoInDevices.length > 1; exports.selectIsCamCycleAvailable = selectIsCamCycleAvailable
 const selectAudioInDevices = state => state.videoCall.audioInDevices; exports.selectAudioInDevices = selectAudioInDevices
 const selectCurrentMicDeviceId = state => state.videoCall.currentMicDeviceId; exports.selectCurrentMicDeviceId = selectCurrentMicDeviceId
 const selectCurrentCamDeviceId = state => state.videoCall.currentCamDeviceId; exports.selectCurrentCamDeviceId = selectCurrentCamDeviceId
 const selectVideoInDevices = state => state.videoCall.videoInDevices; exports.selectVideoInDevices = selectVideoInDevices
