"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoChat/VideoSettingsModal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _log = require('shared/log');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');

 class VideoSettingsModal extends _react.Component {constructor(...args) { super(...args); VideoSettingsModal.prototype.__init.call(this);VideoSettingsModal.prototype.__init2.call(this); }
  __init() {this.changeVideo = (v) => {
    v = parseInt(v)
    if (v === this.props.currentCamera) {
      _log.log.info("Camera did not change")
      return
    }

    _log.log.info("Changing Video From Modal: " + this.props.currentCamera + " to " + v)
    this.props.onChangeVideo(v)
    this.props.onClose()
  }}

  __init2() {this.changeAudio = (v) => {
    v = parseInt(v)
    if (v === this.props.currentMic) {
      _log.log.info("Mic did not change")
      return
    }

    _log.log.info("Changing Audio From Modal: " + this.props.currentMic + " to " + v)
    this.props.onChangeAudio(v)
    this.props.onClose()
  }}

  render () {
    const micOptions = this.props.mics.map((m, i) => {
      return { label: m.name || ("Microphone " + i), value: i }
    })
    const videoOptions = this.props.cameras.map((c, i) => {
      return { label: c.name || ("Camera " + i), value: i }
    })
    return (
      _react2.default.createElement(_Modal2.default, {
        className: "video-chat--settings-modal",
        isOpen: this.props.open,
        onClose: this.props.onClose,
        title: "Video Settings" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      
        , _react2.default.createElement('div', { className: "video-chat--settings-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , _react2.default.createElement('div', { className: "video-chat--settings-section-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, "videocam")
          )
          ,  videoOptions.length > 0
            ? (
                _react2.default.createElement(_Form.InputSectionWrapper, { title: "Video Source" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
                  , _react2.default.createElement(_Form.SimpleSelect, {
                    options: videoOptions,
                    value: this.props.currentCamera.toString(),
                    onChange: this.changeVideo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
                  )
                )
              )
            : null
          
        )
        , _react2.default.createElement('div', { className: "video-chat--settings-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
          , _react2.default.createElement('div', { className: "video-chat--settings-section-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "mic")
          )
          ,  micOptions.length > 0
            ? (
              _react2.default.createElement(_Form.InputSectionWrapper, { title: "Audio Source" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
                , _react2.default.createElement(_Form.SimpleSelect, {
                  options: micOptions,
                  value: this.props.currentMic.toString(),
                  onChange: this.changeAudio, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
                )
              )
              )
            : null
          
        )
      )
    )
  }
} exports.default = VideoSettingsModal;

VideoSettingsModal.propTypes = {
  open: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  cameras: _proptypes2.default.array.isRequired,
  currentCamera: _proptypes2.default.number.isRequired,
  mics: _proptypes2.default.array.isRequired,
  currentMic: _proptypes2.default.number.isRequired,
}
