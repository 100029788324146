"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/ExpiredPasswordNotice/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _components = require('web/screens/Login/components');





const ExpiredPasswordNotice = ({ gotoUsernameLogin }) => {
  return (
    _react2.default.createElement(_components.FormWrapper, { title: "Expired Password" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, "Your password has expired and needs to be reset. We have sent you an email with instructions for resetting your password. "                     )
      , _react2.default.createElement('div', { className: "login--quick--back-link-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , _react2.default.createElement('span', { role: "button", className: "login--quick--back-link", onClick: gotoUsernameLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, "Back to login"

        )
      )
    )
  )
}

exports. default = ExpiredPasswordNotice
