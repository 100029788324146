"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/AvatarPicker/Form/AvatarCropper.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactcropper = require('react-cropper'); var _reactcropper2 = _interopRequireDefault(_reactcropper);
require('cropperjs/dist/cropper.css');

var _file = require('shared/services/file');
var _Modal = require('web/components/Modal');
var _uuid = require('shared/services/uuid');
var _Form = require('web/components/Form');

const CROPPED_AVATAR_SIZE = 128









const AvatarCropper = ({
  file,
  onClose,
  onReupload,
  onSubmit,
  isSubmitting,
}) => {
  const previewContainerID = _react.useMemo.call(void 0, () => `preview-container-${_uuid.uuidv4.call(void 0, )}`, [])
  const fileURL = _react.useMemo.call(void 0, () => URL.createObjectURL(file), [file])
  const cropperRef = _react.useRef(null)

  const handleSubmit = _react.useCallback.call(void 0, 
    () => {
      const imageElement = _optionalChain([cropperRef, 'optionalAccess', _ => _.current])
      const cropper = _optionalChain([imageElement, 'optionalAccess', _2 => _2.cropper])
      const dataURL = _optionalChain([cropper, 'optionalAccess', _3 => _3.getCroppedCanvas, 'call', _4 => _4({
        width: CROPPED_AVATAR_SIZE,
        height: CROPPED_AVATAR_SIZE,
      }), 'optionalAccess', _5 => _5.toDataURL, 'call', _6 => _6()])

      if (!dataURL) return
      const file = _file.dataURLToFile.call(void 0, dataURL, 'avatar')

      onSubmit(file)
    },
    [onSubmit],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_reactcropper2.default, {
        src: fileURL,
        ref: cropperRef,
        preview: `#${previewContainerID}`,
        guides: false,
        center: false,
        zoomable: false,
        dragMode: "none",
        aspectRatio: 1,
        minCropBoxWidth: 50,
        minCropBoxHeight: 50,
        className: "avatar-cropper--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      )
      , _react2.default.createElement('div', { className: "avatar-cropper--preview-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        , _react2.default.createElement('div', { id: previewContainerID, className: "avatar-cropper--preview-box", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
        , _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: onReupload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}, "Upload a different image"   )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        isPrimaryLoading: isSubmitting,
        primaryBtnLabel: "Save",
        onPrimaryBtnClick: handleSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
    )
  )
}

exports. default = AvatarCropper
