"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');

var _asyncDebounce = require('shared/services/asyncDebounce'); var _asyncDebounce2 = _interopRequireDefault(_asyncDebounce);

const SEARCH_DEBOUNCE_MS = 200











const usePagination = ({
  loadItemsList,
  keyExtractor,
  searchQuery = '',
  limit,
}) => {
  const [itemKeysList, setItemKeysList] = _react.useState([])
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [hasDrained, setHasDrained] = _react.useState.call(void 0, false)

  const debouncedLoadItemsList = _react.useMemo.call(void 0, 
    () => _asyncDebounce2.default.call(void 0, 
      loadItemsList,
      SEARCH_DEBOUNCE_MS,
      { cancelObj: null, leading: true },
    ),
    [loadItemsList],
  )

  const load = _react.useCallback.call(void 0, 
    async (shouldMerge = false, isDiscreet = false) => {
      if (searchQuery.length && searchQuery.length < 2) return
      if (!isDiscreet) setIsLoading(true)

      const currentItemKeysList = shouldMerge ? itemKeysList : []
      if (!isDiscreet) setItemKeysList(currentItemKeysList)

      await debouncedLoadItemsList(
        searchQuery,
        currentItemKeysList.length,
        limit,
      )
        .then(itemsList => {
          const itemKeysMap = currentItemKeysList
            .concat(itemsList.map(keyExtractor))
            .reduce(
              (acc, itemKey) => { acc[itemKey] = true; return acc },
              {} ,
            )
          setItemKeysList(Object.keys(itemKeysMap) )
          setHasDrained(itemsList.length < limit)
          setIsLoading(false)
        })
        .catch(e => {
          if (!e) return
          setIsLoading(false)
        })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedLoadItemsList, searchQuery, itemKeysList, limit],
  )

  const onLoadMore = _react.useCallback.call(void 0, () => load(true), [load])
  const onReset = _react.useCallback.call(void 0, () => load(false, true), [load])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  _react.useLayoutEffect.call(void 0, () => { load(false) }, [loadItemsList, searchQuery])

  return {
    isLoading,
    hasDrained,
    onLoadMore,
    onReset,
    itemKeysList,
  }
}

exports. default = usePagination
