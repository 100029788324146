"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperSubscriptions.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _log = require('shared/log');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');

 class SuperUpdateSubscriptionModal extends _react.Component {
  constructor (props) {
    super(props);SuperUpdateSubscriptionModal.prototype.__init.call(this);SuperUpdateSubscriptionModal.prototype.__init2.call(this);SuperUpdateSubscriptionModal.prototype.__init3.call(this);
    this.state = {
      customerID: this.props.subscription.StripeCustomerID,
      subscriptionID: this.props.subscription.StripeSubscriptionID,
    }
  }

  __init() {this.canSend = () => !!(this.state.customerID && this.state.subscriptionID)}

  __init2() {this.onChange = (id, _err, val) => {
    this.setState({
      [id]: val,
    })
  }}

  __init3() {this.onSubmit = () => {
    const { onSubmit, subscription } = this.props
    const { customerID, subscriptionID } = this.state

    onSubmit(subscription.SubscriptionKey, customerID, subscriptionID)
  }}

  render () {
    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.open,
        onClose: this.props.onClose,
        title: "Update Stripe Details"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      
        , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, "Add a new subscription to an organizaiton"      )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Stripe Customer ID"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          , _react2.default.createElement(_Form.TextInput, {
            placeholder: "cus_IXGjlZfsRPyLt7",
            value: this.state.customerID,
            onChange: e => this.onChange('customerID', null, e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          )
        )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Stripe Subscription ID"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , _react2.default.createElement(_Form.TextInput, {
            placeholder: "sub_IXGj7sbqEOSYSJ",
            value: this.state.subscriptionID,
            onChange: e => this.onChange('subscriptionID', null, e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
          )
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          primaryBtnLabel: "Submit",
          isPrimaryEnabled: this.canSend(),
          onPrimaryBtnClick: this.onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        )
      )
    )
  }
} exports.SuperUpdateSubscriptionModal = SuperUpdateSubscriptionModal;

SuperUpdateSubscriptionModal.propTypes = {
  currentOrgKey: _proptypes2.default.string.isRequired,
  subscription: _proptypes2.default.object.isRequired,
  open: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  onSubmit: _proptypes2.default.func.isRequired,
}

 class SuperAttachOrgToOtherSubscriptionModal extends _react.Component {
  constructor (props) {
    super(props);SuperAttachOrgToOtherSubscriptionModal.prototype.__init4.call(this);SuperAttachOrgToOtherSubscriptionModal.prototype.__init5.call(this);SuperAttachOrgToOtherSubscriptionModal.prototype.__init6.call(this);SuperAttachOrgToOtherSubscriptionModal.prototype.__init7.call(this);SuperAttachOrgToOtherSubscriptionModal.prototype.__init8.call(this);
    this.state = {
      submittedPressed: false,
      newOrgOwnerKey: "",
      fromOrg: {},
      toOrg: {},
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.open && !this.props.open) {
      this.resetState()
    }
  }

  __init4() {this.resetState = () => {
    this.setState({
      fromOrg: {},
      toOrg: {},
      submitPressed: false,
    })
  }}

  __init5() {this.onSubmit = () => {
    _actions2.default.getOrgs({ OrgKeys: [this.state.newOrgOwnerKey, this.props.currentOrgKey] })
      .then(res => {
        this.setState({
          fromOrg: res.Orgs[this.props.currentOrgKey],
          toOrg: res.Orgs[this.state.newOrgOwnerKey],
          submitPressed: true,
        })
      })
      .catch(err => {
        _log.log.info(err)
        _actions2.default.pushAlert("Failure", "Something went wrong while retrieving orgs. Check the console.")
        this.resetState()
      })
  }}

  __init6() {this.onChange = (id, _err, val) => {
    this.setState({
      [id]: val,
    })
  }}

  __init7() {this.onConfirm = () => {
    this.props.onSubmit(this.props.currentOrgKey, this.state.newOrgOwnerKey)
  }}

  __init8() {this.canSend = () => this.state.newOrgOwnerKey !== ""}

  render () {
    const formContent = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "OrgOwnerKey of subscription"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          , _react2.default.createElement(_Form.TextInput, {
            value: this.state.stripeCustomerID,
            placeholder: "7821f54a-4a5b-4b6e-9c36-e75c0b6fc8e2",
            onChange: e => this.onChange('newOrgOwnerKey', null, e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
          )
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          primaryBtnLabel: "Submit",
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          onPrimaryBtnClick: this.onSubmit,
          isPrimaryEnabled: !this.canSend(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
        )
      )
    )

    const confirmMoveDialog = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}, "Are you sure you want to attach this org"
                  , ' '
          , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 152}},  this.state.fromOrg.Name ), ' ', "to the subscription owned by this org"
                , ' '
          , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}, this.state.toOrg.Name), "?"
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          primaryBtnLabel: "Confirm",
          onPrimaryBtnClick: this.onConfirm, __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}
        )
      )
    )

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.open,
        onClose: this.props.onClose,
        title: "Move Org to another subscription"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
      
        ,  this.state.submitPressed ? confirmMoveDialog : formContent 
      )
    )
  }
} exports.SuperAttachOrgToOtherSubscriptionModal = SuperAttachOrgToOtherSubscriptionModal;

SuperAttachOrgToOtherSubscriptionModal.propTypes = {
  currentOrgKey: _proptypes2.default.string.isRequired,
  open: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  onSubmit: _proptypes2.default.func.isRequired,
}

 class SuperUpdateSubscriptionOrgSettingsModal extends _react.Component {
  constructor (props) {
    super(props);SuperUpdateSubscriptionOrgSettingsModal.prototype.__init9.call(this);SuperUpdateSubscriptionOrgSettingsModal.prototype.__init10.call(this);
    const setting = this.props.subscription.SubscriptionOrgSettings[this.props.orgKey]
    this.state = {
      bloomTextPro: setting.BloomTextProfessional,
      patientMessaging: setting.PatientMessaging,
    }
  }

  __init9() {this.onChange = (id, _err, val) => {
    this.setState({
      [id]: val,
    })
  }}

  __init10() {this.onSubmit = () => {
    const subscriptionKey = this.props.subscription.SubscriptionKey
    this.props.onSubmit(subscriptionKey, this.props.orgKey, this.state.bloomTextPro, this.state.patientMessaging)
  }}

  render () {
    const orgName = this.props.subscription.SubscriptionOrgSettings[this.props.orgKey].Name

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.open,
        onClose: this.props.onClose,
        title: "Update Subscription Org Settings"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 209}}
      
        , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 214}}, "Update subscription settings for "    ,  orgName )
        , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}
          , _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 216}}
            , _react2.default.createElement('input', {
              type: "checkbox",
              name: "BloomText Professional" ,
              id: "bloomTextPro",
              onChange: (e) => { this.onChange(e.target.id, '', e.target.checked) },
              checked: this.state.bloomTextPro, __self: this, __source: {fileName: _jsxFileName, lineNumber: 217}}
            ), " BloomText Professional"

          )
        )
        , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 227}}
          , _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 228}}
            , _react2.default.createElement('input', {
              type: "checkbox",
              name: "SMS Messaging" ,
              id: "patientMessaging",
              onChange: (e) => { this.onChange(e.target.id, '', e.target.checked) },
              checked: this.state.patientMessaging, __self: this, __source: {fileName: _jsxFileName, lineNumber: 229}}
            ), " SMS Messaging"

          )
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          primaryBtnLabel: "Submit",
          onPrimaryBtnClick: this.onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 239}}
        )
      )
    )
  }
} exports.SuperUpdateSubscriptionOrgSettingsModal = SuperUpdateSubscriptionOrgSettingsModal;

SuperUpdateSubscriptionOrgSettingsModal.propTypes = {
  orgKey: _proptypes2.default.string.isRequired,
  subscription: _proptypes2.default.object.isRequired,
  open: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  onSubmit: _proptypes2.default.func.isRequired,
}
