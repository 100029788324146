"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/components/FormWrapper.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);






const FormWrapper = ({ title, children, className }) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "login--main-form", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    , _react2.default.createElement('h1', { className: "login--main-form--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}},  title )
    , _react2.default.createElement('div', { className: "login--main-form--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      ,  children 
    )
  )
)

exports. default = FormWrapper
