"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');

const useSafeSwitch = (
  initialValue,
  onChange,
) => {
  const [currentValue, setCurrentValue] = _react.useState.call(void 0, initialValue)
  const prevValue = _react.useRef.call(void 0, initialValue)

  _react.useEffect.call(void 0, () => setCurrentValue(initialValue), [initialValue])

  const handleChange = _react.useCallback.call(void 0, 
    async (newValue) => {
      prevValue.current = currentValue
      setCurrentValue(newValue)

      return onChange(newValue)
        .catch(e => {
          setCurrentValue(prevValue.current)
          throw e
        })
    },
    [currentValue, onChange],
  )

  return [currentValue, handleChange]
}

exports. default = useSafeSwitch
