"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);





var _uuid = require('shared/services/uuid');
var _blobject = require('shared/services/blobject');
var _entity = require('shared/services/entity');

 const removeChatParticipant = async (
  entity,
  blobjectKey,
  conversationKey,
  myChatSubscriberKey,
) => {
  const userKeys = _entity.isEntityUser.call(void 0, entity)
    ? [entity.wrappedEntity.UserKey]
    : []

  const teamKeys = _entity.isEntityTeam.call(void 0, entity)
    ? [entity.wrappedEntity.TeamKey]
    : []

  const patientKeys = _entity.isEntityPatient.call(void 0, entity)
    ? [entity.wrappedEntity.PatientKey]
    : []

  const removedEntityUserKey = 'UserKey' in entity.wrappedEntity
    ? entity.wrappedEntity.UserKey
    : null
  const removedEntityTeamKey = 'TeamKey' in entity.wrappedEntity
    ? entity.wrappedEntity.TeamKey
    : null

  return _actions2.default.removeParticipantsFromChat({
    ConversationKey: conversationKey,
    UserKeys: userKeys,
    TeamKeys: teamKeys,
    PatientKeys: patientKeys,
  })
    .then(() => (
      removedEntityUserKey === myChatSubscriberKey ||
      removedEntityTeamKey === myChatSubscriberKey
    ) && _actions2.default.resumeLatestChat())
}; exports.removeChatParticipant = removeChatParticipant

 const gotoParticipantPage = (subscribersMap) =>
  (entity) =>
    _actions2.default.gotoEntityPage(
      entity,
      _blobject.getSubscriberEntityOrgKey.call(void 0, subscribersMap, entity) || _uuid.EMPTY_BLUID,
    ); exports.gotoParticipantPage = gotoParticipantPage
