"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Feedback/withFeedbackController.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _reactredux = require('react-redux');



var _videoRoom = require('web/selectors/videoRoom');

exports. default = Component => {
  class FeedbackControllerHOC extends _react2.default.Component {constructor(...args) { super(...args); FeedbackControllerHOC.prototype.__init.call(this);FeedbackControllerHOC.prototype.__init2.call(this);FeedbackControllerHOC.prototype.__init3.call(this);FeedbackControllerHOC.prototype.__init4.call(this);FeedbackControllerHOC.prototype.__init5.call(this);FeedbackControllerHOC.prototype.__init6.call(this);FeedbackControllerHOC.prototype.__init7.call(this); }
    __init() {this.lowRatingReasons = [
      { value: "bad_video", label: "Video quality was bad" },
      { value: "bad_audio", label: "Audio quality was bad" },
      { value: "no_video", label: "There was no video" },
      { value: "no_audio", label: "I couldn't hear others" },
      { value: "no_my_audio", label: "Others couldn't hear me" },
      { value: "other", label: "Other issue", isExplanationNeeded: true },
    ]}

    __init2() {this.state = {
      isRatingSubmitted: false,
      isSubmittedRatingLow: false,
      submittedRating: 0,
    }}

    __init3() {this.handleFeedbackSubmit = ({ rating, reason, comment }) => {
      const { RoomKey, RoomUserKey } = this.props

      _actions2.default.videoRoomRate({
        RoomKey,
        RoomUserKey,
        StarRating: rating,
        StarRatingCode: reason,
        StarRatingDescription: comment,
      })
    }}

    __init4() {this.submitRating = rating => new Promise((resolve, reject) => {
      if (this.state.isRatingSubmitted) return resolve()
      const isSubmittedRatingLow = rating <= 3

      this.setState({
        isRatingSubmitted: true,
        submittedRating: rating,
        isSubmittedRatingLow,
      })

      if (!isSubmittedRatingLow) {
        this.handleFeedbackSubmit({ rating })
        return resolve()
      }

      return reject()
    })}

    __init5() {this.submitWithRating = extra =>
      this.handleFeedbackSubmit({ ...extra, rating: this.state.submittedRating })}

    __init6() {this.submitLowRatingReason = reason => this.submitWithRating({ reason })}
    __init7() {this.submitLowRatingComment = comment => this.submitWithRating({ comment })}

    render () {
      const { isRatingSubmitted, isSubmittedRatingLow } = this.state
      return (
        _react2.default.createElement(Component, {
          ...this.props,
          submitRating: this.submitRating,
          isRatingSubmitted: isRatingSubmitted,
          isSubmittedRatingLow: isSubmittedRatingLow,
          lowRatingReasons: this.lowRatingReasons,
          submitLowRatingReason: this.submitLowRatingReason,
          submitLowRatingComment: this.submitLowRatingComment, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
      )
    }
  }

  const mapState = state => {
    const currentUser = _videoRoom.selectCurrentVideoRoomUser.call(void 0, state) || {}
    return {
      RoomKey: currentUser.RoomKey,
      RoomUserKey: currentUser.RoomUserKey,
      sessionDuration: _videoRoom.selectCurrentVideoRoomSessionDuration.call(void 0, state),
    }
  }
  return _reactredux.connect.call(void 0, mapState)(FeedbackControllerHOC)
}
