"use strict";Object.defineProperty(exports, "__esModule", {value: true});


var _number = require('shared/services/number');



var _broadcastCampaign = require('shared/validation/broadcastCampaign');

 const roundMessageDelay = (d) => _number.round.call(void 0, d, 1000); exports.roundMessageDelay = roundMessageDelay










 const makeDefaultValues = ({
  name, teamKey, senderKey, messageType, messageDelay, messageTemplate,
}) => ({
  name: name || '',
  teamKey: (teamKey ) || '',
  senderKey: (senderKey ) || '',
  messageType: messageType || _broadcastCampaign.MessageType.SecureSMS,
  messageDelay: messageDelay || _broadcastCampaign.DEFAULT_MESSAGE_DELAY_MS,
  messageTemplate: messageTemplate || '',
}); exports.makeDefaultValues = makeDefaultValues
