"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/InviteContactModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _contact = require('shared/enum/contact');
var _inviteContact = require('shared/services/inviteContact');
var _entities = require('shared/transforms/entities');
var _org = require('shared/selectors/org');

var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _noticeBar = require('web/services/context/noticeBar');




var _Forms = require('web/screens/Directory/Forms');
var _OrgLimitNotice = require('web/components/Upgrade/OrgLimitNotice'); var _OrgLimitNotice2 = _interopRequireDefault(_OrgLimitNotice);













const InviteContactForm = ({
  contactRelType,
  defaultEmail: initialDefaultEmail = '',
  defaultPhone: initialDefaultPhone = '',
  defaultFirstName: initialDefaultFirstName = '',
  defaultLastName: initialDefaultLastName = '',
  onEntityInvited,
  onClose,
  orgKeyContext,
  allOrgs,
}) => {
  const [defaultEmail] = _react.useState.call(void 0, initialDefaultEmail)
  const [defaultPhone] = _react.useState.call(void 0, initialDefaultPhone)
  const [defaultFirstName] = _react.useState.call(void 0, initialDefaultFirstName)
  const [defaultLastName] = _react.useState.call(void 0, initialDefaultLastName)

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleSubmit = _react.useCallback.call(void 0, 
    async data => {
      if (!contactRelType) return Promise.reject()
      _amplitude2.default.logEvent("Invite Contact", { contactRelType })
      return _inviteContact.inviteContact.call(void 0, data, contactRelType, orgKeyContext)
        .then(invitee => {
          if (contactRelType === _contact.CONTACT_REL_TYPES.PATIENT) {
            const entity = _entities.mapPatientToEntity.call(void 0, allOrgs)(invitee )
            onEntityInvited(entity)
          }

          if (contactRelType === _contact.CONTACT_REL_TYPES.COWORKER) {
            const entity = _entities.mapUserToEntity.call(void 0, allOrgs)(invitee )
            onEntityInvited(entity)
          }

          if (contactRelType === _contact.CONTACT_REL_TYPES.EXTERNAL) {
            const entity = _entities.mapUserToEntity.call(void 0, allOrgs)(invitee )
            onEntityInvited(entity)
          }

          onClose()
        })
        .catch(() => showWarningBar('Failed to invite contact'))
    },
    [contactRelType, orgKeyContext, showWarningBar, allOrgs, onClose, onEntityInvited],
  )

  _react.useEffect.call(void 0, 
    () => _amplitude2.default.logEvent("Open Invite Contact Modal", { contactRelType }),
    [contactRelType],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  contactRelType === _contact.CONTACT_REL_TYPES.EXTERNAL && (
        _react2.default.createElement(_Forms.ExternalCollaboratorForm, {
          defaultEmail: defaultEmail,
          onSubmit: handleSubmit,
          onCancel: onClose,
          submitBtnLabel: "Invite",
          controlsClassName: "invite-contact--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
        )
      )
      ,  contactRelType === _contact.CONTACT_REL_TYPES.COWORKER && (
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement(_OrgLimitNotice2.default, { orgKey: orgKeyContext, addedUsersCount: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}} )
          , _react2.default.createElement(_Forms.CoworkerForm, {
            defaultEmail: defaultEmail,
            defaultFirstName: defaultFirstName,
            defaultLastName: defaultLastName,
            onSubmit: handleSubmit,
            onCancel: onClose,
            submitBtnLabel: "Invite",
            orgKeyContext: orgKeyContext,
            controlsClassName: "invite-contact--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          )
        )
      )
      ,  contactRelType === _contact.CONTACT_REL_TYPES.PATIENT && (
        _react2.default.createElement(_Forms.PatientForm, {
          defaultEmail: defaultEmail,
          defaultPhone: defaultPhone,
          defaultFirstName: defaultFirstName,
          defaultLastName: defaultLastName,
          onSubmit: handleSubmit,
          onCancel: onClose,
          submitBtnLabel: "Add",
          orgKeyContext: orgKeyContext,
          controlsClassName: "invite-contact--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
        )
      )
    )
  )
}





const mapState = (state) => ({ allOrgs: _org.selectOrgsV2.call(void 0, state) })
exports. default = _reactredux.connect.call(void 0, mapState)(InviteContactForm)
