"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');





var _map = require('shared/services/map');
var _string = require('shared/services/string');
var _org = require('shared/selectors/org');

 const selectSettings = (state) => state.settings; exports.selectSettings = selectSettings

 const selectQuickLoginUsersList = _toolkit.createSelector.call(void 0, 
  exports.selectSettings,
  settings => Object.values(settings.quickLoginUsers),
); exports.selectQuickLoginUsersList = selectQuickLoginUsersList

const selectQuickLoginUserByKey = (state, userKey) =>
  state.settings.quickLoginUsers[userKey] || null





 const selectPopulatedQuickLoginUserByKey = _toolkit.createSelector.call(void 0, 
  selectQuickLoginUserByKey,
  _org.selectOrgs,
  (user, orgsMap) => user && ({
    ...user,
    Relationships:
      _map.listToMap.call(void 0, 
        Object.values(user.Relationships)
          .filter(rel => !rel.Pending)
          .map(rel => ({ ...rel, OrgName: _nullishCoalesce(_optionalChain([orgsMap, 'access', _ => _[rel.OrgKey], 'optionalAccess', _2 => _2.Name]), () => ( '')) }))
          .sort((a, b) => _string.compareStr.call(void 0, a.OrgName, b.OrgName)),
        r => r.OrgKey,
      ),
  }),
); exports.selectPopulatedQuickLoginUserByKey = selectPopulatedQuickLoginUserByKey
