"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/AddBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const AddBtn = ({ label, onClick, className }) => (
  _react2.default.createElement('div', {
    role: "button",
    onClick: onClick,
    className: _classBuilder2.default.call(void 0, "directory-page--add-btn", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
  
    , _react2.default.createElement('div', { className: "directory-page--add-btn--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
    ,  label 
  )
)

exports. default = AddBtn
