"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/AddEmail/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');

var _contact = require('shared/validation/user/contact');
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _Form = require('web/components/Form');







const AddEmailForm = ({
  onClose,
  onSubmit,
}) => {
  const { register, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _contact.emailResolver,
      defaultValues: { email: '' },
    })
  const { isValid, isDirty, isSubmitting, errors } = formState

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const submit = _react.useCallback.call(void 0, 
    ({ email }) => onSubmit(email)
      .then(onClose)
      .then(() => showSuccessBar('Email has been added'))
      .catch(() => showWarningBar('Failed to add email')),
    [onSubmit, onClose, showWarningBar, showSuccessBar],
  )

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, "Allow your contacts to reach you via your new email and use it to log in to BloomText."

      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { errorMessage: _optionalChain([errors, 'access', _ => _.email, 'optionalAccess', _2 => _2.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , _react2.default.createElement(_Form.TextInput, {
          autoFocus: true,
          type: "email",
          className: "settings--profile--contact-modal--input",
          hasError: !!errors.email,
          placeholder: "E.g. john@email.com" ,
          ...register('email'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
      )
      , _react2.default.createElement('div', { className: "settings--modal-nav", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Close")
        , _react2.default.createElement(_Form.Button, {
          isSubmit: true,
          isLoading: isSubmitting,
          isDisabled: !isValid || !isDirty, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        , "Save"

        )
      )
    )
  )
}

exports. default = AddEmailForm
