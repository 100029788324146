"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');
var _asyncDebounce = require('shared/services/asyncDebounce'); var _asyncDebounce2 = _interopRequireDefault(_asyncDebounce);




var _contact = require('shared/enum/contact');
var _uuid = require('shared/services/uuid');

var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _string = require('shared/services/string');
var _phoneVerifier = require('shared/services/phoneVerifier');
var _emailVerifier = require('shared/services/emailVerifier');
var _mapSearchResultsToEntities = require('./mapSearchResultsToEntities'); var _mapSearchResultsToEntities2 = _interopRequireDefault(_mapSearchResultsToEntities);
var _useSearchQuery = require('shared/hooks/useSearchQuery');

 const SEARCH_DEBOUNCE_MS = 200; exports.SEARCH_DEBOUNCE_MS = SEARCH_DEBOUNCE_MS

const EMPTY_LIST = []
 const EMPTY_SEARCH_RESULTS = {
  internalEntitiesList: EMPTY_LIST,
  externalEntitiesList: EMPTY_LIST,
  patientEntitiesList: EMPTY_LIST,
}; exports.EMPTY_SEARCH_RESULTS = EMPTY_SEARCH_RESULTS












 const useSearch = ({
  orgKeyContext,
  listLimit = 5,
  canAddUsers,
  canAddTeams,
  canAddPatients,
  excludedEntitiesMap = {},
  onLookup,
  excludeOrgKey,
}) => {
  const [searchQuery, setSearchQuery] = _useSearchQuery.useSearchQuery.call(void 0, )
  const [isLoadingResults, setIsLoadingResults] = _react.useState.call(void 0, false)
  const [searchResults, setSearchResults] = _react.useState.call(void 0, exports.EMPTY_SEARCH_RESULTS)
  const [searchContactType, setSearchContactType] = _react.useState(null)
  const sanitizedSearchQuery = _string.sanitizeStr.call(void 0, searchQuery)

  const clearSearchQuery =
    _react.useCallback.call(void 0, () => setSearchQuery(''), [setSearchQuery])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const runSearch = _react.useCallback.call(void 0, 
    _asyncDebounce2.default.call(void 0, 
      async (Query, PatientOrgKey) => {
        if (!Query || PatientOrgKey === _uuid.EMPTY_BLUID) return Promise.resolve(null)

        if (!onLookup) {
          return PatientOrgKey
            ? _actions2.default.directoryLookup({
              Query,
              PatientOrgKey,
              Limit: listLimit,
              ExcludeOrgKey: excludeOrgKey,
            })
            : { Teams: {}, Users: {}, Patients: {}, Orgs: {} }
        }

        return onLookup(Query, listLimit)
      },
      exports.SEARCH_DEBOUNCE_MS,
      { cancelObj: null },
    ),
    [listLimit, orgKeyContext, onLookup, excludeOrgKey],
  )

  _react.useEffect.call(void 0, 
    () => {
      if (_emailVerifier.isEmailValid.call(void 0, searchQuery)) setSearchContactType(_contact.CONTACT_TYPES.EMAIL)
      else if (_phoneVerifier.isPhoneValid.call(void 0, searchQuery)) setSearchContactType(_contact.CONTACT_TYPES.PHONE)
      else setSearchContactType(null)

      setSearchResults(exports.EMPTY_SEARCH_RESULTS)

      setIsLoadingResults(true)
      runSearch(sanitizedSearchQuery, orgKeyContext)
        .then(res => {
          setIsLoadingResults(false)

          if (!res) return

          const searchResults = _mapSearchResultsToEntities2.default.call(void 0, 
            {
              ...res,
              Users: canAddUsers ? res.Users : {},
              Teams: canAddTeams ? res.Teams : {},
              Patients: canAddPatients ? res.Patients : {},
            },
            excludedEntitiesMap,
            orgKeyContext,
          )

          setSearchResults(searchResults)
        }).catch(() => null)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sanitizedSearchQuery, setIsLoadingResults, runSearch],
  )

  return {
    searchQuery,
    sanitizedSearchQuery,
    setSearchQuery,
    clearSearchQuery,
    searchContactType,
    isLoadingResults,
    internalEntitiesList: searchResults.internalEntitiesList,
    externalEntitiesList: searchResults.externalEntitiesList,
    patientEntitiesList: searchResults.patientEntitiesList,
  }
}; exports.useSearch = useSearch
