"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');

var _chat = require('shared/enum/chat');
var _features = require('shared/enum/features'); var _features2 = _interopRequireDefault(_features);
var _common = require('shared/selectors/blobject/common');
var _org = require('shared/selectors/org');
var _selectMyOrgKeyInConvo = require('./selectMyOrgKeyInConvo');

 const selectShouldShowPatientUpgradeNotice = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey, _org.selectOrgs, _selectMyOrgKeyInConvo.selectMyOrgKeyInConvo,
  (blobject, orgs, myOrgKey) => {
    const isPatientConvo = _optionalChain([blobject, 'optionalAccess', _ => _.WrappedType]) === _chat.CHAT_TYPE.REGULAR_PATIENT || _optionalChain([blobject, 'optionalAccess', _2 => _2.WrappedType]) === _chat.CHAT_TYPE.SECURE_PATIENT
    if (!isPatientConvo) return false

    const myOrg = myOrgKey && orgs[myOrgKey]
    if (!myOrg) return false

    return !myOrg.EnabledFeatures.includes(_features2.default.PATIENT_MESSAGING)
  },
); exports.selectShouldShowPatientUpgradeNotice = selectShouldShowPatientUpgradeNotice
