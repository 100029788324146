"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ExportChatModal/Form/DateRange.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');







var _Form = require('web/components/Form');







const rangeOptions = [
  { label: 'All time', value: 'allTime' },
  { label: 'Custom range', value: 'customRange' },
]

const startDateRules = { deps: ['dateRangeType' , 'endDate' ] }
const endDateRules = { deps: ['dateRangeType' , 'startDate' ] }

const DateRange = ({
  control,
  startDateErrorMessage,
  endDateErrorMessage,
}) => {
  const rangeType =
    _reacthookform.useWatch({ control, name: 'dateRangeType' })

  const dateSectionClassName = rangeType === 'allTime'
    ? 'chat-export--date-section--hidden'
    : ''

  return (
    _react2.default.createElement(_Form.InputSectionGroup, { className: "chat-export--date-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Date range" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        , _react2.default.createElement(_Form.SimpleSelectController, {
          control: control,
          name: "dateRangeType",
          options: rangeOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "From",
        className: dateSectionClassName,
        errorMessage: startDateErrorMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      
        , _react2.default.createElement(_Form.DateInputController, {
          isRequired: true,
          name: "startDate",
          control: control,
          rules: startDateRules,
          inputClassName: "chat-export--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "To",
        className: dateSectionClassName,
        errorMessage: endDateErrorMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      
        , _react2.default.createElement(_Form.DateInputController, {
          isRequired: true,
          name: "endDate",
          control: control,
          rules: endDateRules,
          inputClassName: "chat-export--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
      )
    )
  )
}

exports. default = DateRange
