"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/segments/VideoSetupGroup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }// Video setup section, used to tweak user's video settings.
var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);




var _videoCall = require('web/selectors/videoCall');
var _videoCall3 = require('web/services/videoCall');

var _Video = require('web/components/VideoCall/Setup/SetupGroups/Video'); var _Video2 = _interopRequireDefault(_Video);

class VideoSetupGroupContainer extends _react2.default.Component {constructor(...args) { super(...args); VideoSetupGroupContainer.prototype.__init.call(this);VideoSetupGroupContainer.prototype.__init2.call(this);VideoSetupGroupContainer.prototype.__init3.call(this);VideoSetupGroupContainer.prototype.__init4.call(this);VideoSetupGroupContainer.prototype.__init5.call(this);VideoSetupGroupContainer.prototype.__init6.call(this); }
  __init() {this.state = { stream: null }}
  __init2() {this.streamManager = null}
  __init3() {this.updateStream = s => this.setState({ stream: s })}

  __init4() {this.componentDidUpdate = prevProps => {
    const {
      onUserProblemPublish,
      currentCamDeviceId: camDeviceId,
    } = this.props

    if (prevProps.currentCamDeviceId !== camDeviceId) {
      this.streamManager
        .update({ camDeviceId })
        .then(this.updateStream)
        .catch(onUserProblemPublish)
    }
  }}

  __init5() {this.componentDidMount = () => {
    const {
      onUserProblemPublish,
      currentCamDeviceId: camDeviceId,
    } = this.props

    this.streamManager = new (0, _videoCall3.StreamManager)({ camDeviceId, useCam: true })
    this.streamManager.update()
      .then(this.updateStream)
      .catch(onUserProblemPublish)
  }}

  __init6() {this.componentWillUnmount = () => this.streamManager.stop()}

  render () {
    const { videoInDevices, currentCamDeviceId } = this.props
    const { stream } = this.state

    return (
      _react2.default.createElement(_Video2.default, {
        videoStream: stream,
        videoInDevices: videoInDevices,
        currentCamDeviceId: currentCamDeviceId,
        setCurrentCamDeviceId: _actions2.default.setCurrentCamDeviceId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      )
    )
  }
}

const mapState = state => ({
  videoInDevices: _videoCall.selectVideoInDevices.call(void 0, state),
  currentCamDeviceId: _videoCall.selectCurrentCamDeviceId.call(void 0, state),
  areCamIssuesPresent: _videoCall.selectAreCamIssuesPresent.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(VideoSetupGroupContainer)
