"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/RoomHeader.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _reactredux = require('react-redux');



var _videoRoom = require('web/selectors/videoRoom');

var _Header = require('web/components/VideoRooms/Room/Header'); var _Header2 = _interopRequireDefault(_Header);

class RoomHeader extends _react2.default.Component {constructor(...args) { super(...args); RoomHeader.prototype.__init.call(this);RoomHeader.prototype.__init2.call(this);RoomHeader.prototype.__init3.call(this); }
  __init() {this.handleRoomRename = RoomName =>
    _actions2.default.renameVideoRoom({ RoomKey: this.props.roomKey, RoomName })
      .then(() => this.props.showNoticeBar({ text: 'Meeting room renamed.' }))
      .catch(() => this.props.showNoticeBar({ type: 'warning', text: 'Failed to rename meeting room.' }))}

  __init2() {this.handleRoomArchive = () =>
    _actions2.default.archiveVideoRoom({ RoomKey: this.props.roomKey })
      .then(() => this.props.showNoticeBar({ text: 'Meeting room was archived.' }))
      .catch(() => this.props.showNoticeBar({ type: 'warning', text: 'Failed to archive meeting room.' }))}

  __init3() {this.handleRoomUnarchive = () =>
    _actions2.default.unarchiveVideoRoom({ RoomKey: this.props.roomKey })
      .then(this.props.showNoticeBar({ text: 'Meeting room was unarchived.' }))
      .catch(() => this.props.showNoticeBar({ type: 'warning', text: 'Failed to unarchive meeting room.' }))}

  render () {
    const {
      onBack,
      onRoomShare,
      onRoomLinkCopy,
      gotoMeeting,
      videoRoom,
      activeParticipantsCount,
    } = this.props

    if (!videoRoom) return null

    return (
      _react2.default.createElement(_Header2.default, {
        key: videoRoom.RoomKey,
        onBack: onBack,
        onRoomShare: onRoomShare,
        onRoomLinkCopy: onRoomLinkCopy,
        gotoMeeting: gotoMeeting,
        roomName: videoRoom.DisplayName,
        roomLink: videoRoom.RoomLink,
        isRoomArchived: videoRoom.Archived,
        activeParticipantsCount: activeParticipantsCount,
        onRoomRename: this.handleRoomRename,
        onRoomArchive: this.handleRoomArchive,
        onRoomUnarchive: this.handleRoomUnarchive, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      )
    )
  }
}

const mapState = (state, ownProps) => ({
  videoRoom: _videoRoom.selectVideoRoomByKey.call(void 0, state, ownProps.roomKey),
  activeParticipantsCount:
    _videoRoom.selectVideoRoomActiveParticipantsCount.call(void 0, state, ownProps.roomKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(RoomHeader)
