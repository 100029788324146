"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');







var _common = require('shared/selectors/org/common');
var _common3 = require('shared/selectors/user/common');
var _string = require('shared/services/string');
var _array = require('shared/services/array');

 const selectMyRelationships = (state) => {
  const { Relationships } = _common3.selectCurrentUserV2.call(void 0, state) || {}
  return Relationships
}; exports.selectMyRelationships = selectMyRelationships

 const selectMyRelationshipByKey = (state, orgKey) =>
  _optionalChain([exports.selectMyRelationships.call(void 0, state), 'optionalAccess', _ => _[orgKey]]) || null; exports.selectMyRelationshipByKey = selectMyRelationshipByKey

 const selectMyActiveRelationshipsList = _toolkit.createSelector.call(void 0, 
  exports.selectMyRelationships,
  _common.selectOrgs,
  (rels, orgs) => Object.values(rels)
    .filter(r => !r.Pending && orgs[r.OrgKey])
    .sort((a, b) => _string.compareStr.call(void 0, _optionalChain([orgs, 'access', _2 => _2[a.OrgKey], 'optionalAccess', _3 => _3.Name]), _optionalChain([orgs, 'access', _4 => _4[b.OrgKey], 'optionalAccess', _5 => _5.Name]))),
); exports.selectMyActiveRelationshipsList = selectMyActiveRelationshipsList

 const selectMyActiveOrgsList = _toolkit.createSelector.call(void 0, 
  exports.selectMyActiveRelationshipsList,
  _common.selectOrgs,
  _common.selectOrgsV2,
  (rels, orgsV1, orgsV2) => rels
    .map(r => {
      const orgV2 = orgsV2[r.OrgKey]
      if (!orgV2) return null
      const orgRel = {
        ...orgV2,
        Title: r.Title,
        // TODO: Make sure r can only be of type MyRelationship
        IsAdmin: r.RelType === 'admin' ||
          (r ).Type === 'admin',
        PendingUsersCount: _nullishCoalesce(_optionalChain([orgsV1, 'access', _6 => _6[r.OrgKey], 'optionalAccess', _7 => _7.PendingUsers, 'optionalAccess', _8 => _8.length]), () => ( 0)),
      }
      return orgRel
    })
    .filter(_array.truthy)
    .sort((a, b) => _string.compareStr.call(void 0, a.Name, b.Name)),
); exports.selectMyActiveOrgsList = selectMyActiveOrgsList

 const selectMyActiveOrgsMap = _toolkit.createSelector.call(void 0, 
  exports.selectMyActiveOrgsList,
  orgsList => orgsList.reduce((acc, org) => {
    acc[org.OrgKey] = org
    return acc
  }, {} ),
); exports.selectMyActiveOrgsMap = selectMyActiveOrgsMap

 const selectHasAnyActiveOrgs = _toolkit.createSelector.call(void 0, 
  exports.selectMyActiveOrgsList,
  os => !!os.length,
); exports.selectHasAnyActiveOrgs = selectHasAnyActiveOrgs

 const selectMyActiveOrgKeysList = _toolkit.createSelector.call(void 0, 
  exports.selectMyActiveOrgsList,
  os => os.map(o => o.OrgKey),
); exports.selectMyActiveOrgKeysList = selectMyActiveOrgKeysList

 const isUserAdminInOrg = (user, orgKey) => {
  const { Relationships } = user || {}

  const orgRel = _optionalChain([Relationships, 'optionalAccess', _9 => _9[orgKey]])
  const orgRelType = orgRel && (
    ('Type' in orgRel && orgRel.Type) ||
    ('RelType' in orgRel && orgRel.RelType)
  )
  return orgRelType === 'admin'
}; exports.isUserAdminInOrg = isUserAdminInOrg

 const selectAmIAdminInOrg = (state, orgKey) => {
  const me = _common3.selectCurrentUser.call(void 0, state)
  return exports.isUserAdminInOrg.call(void 0, me, orgKey)
}; exports.selectAmIAdminInOrg = selectAmIAdminInOrg
