"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/EmptyPage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _TemplateEntityScreen = require('web/screens/TemplateEntityScreen'); var _TemplateEntityScreen2 = _interopRequireDefault(_TemplateEntityScreen);

const EmptyPage = () => (
  _react2.default.createElement(_TemplateEntityScreen2.default, {
    iconSrc: "/assets/icons/directory-lightgrey.svg",
    title: "Directory",
    desc: 
      _react2.default.createElement(_react2.default.Fragment, null, "Your contacts are listed on the left. "
               , _react2.default.createElement('wbr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}} ), "Choose one to see more details."

      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
  )
)

exports. default = EmptyPage
