"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/ChatNotice/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _upgrade = require('shared/enum/upgrade');





var _conversation = require('shared/selectors/conversation');
var _user = require('shared/selectors/user');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _View = require('./View'); var _View2 = _interopRequireDefault(_View);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);





const ChatUpgradeNotice = ({
  isOverInternalChatLimit,
  isOverPatientChatLimit,
  orgKey,
  amIAdmin,
}) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )

  if (!orgKey || !isOverInternalChatLimit && !isOverPatientChatLimit) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_View2.default, {
        amIAdmin: amIAdmin,
        isPatientNotice: isOverPatientChatLimit,
        onProceed: onOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )
      , _react2.default.createElement(_UpgradeModal2.default, {
        isOpen: isOpen,
        onClose: onClose,
        orgKey: orgKey,
        type: isOverPatientChatLimit
          ? _upgrade.SubscriptionItemType.SMS
          : _upgrade.SubscriptionItemType.TEAM
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
    )
  )
}








const mapState = (state, { blobjectKey }) => {
  if (!blobjectKey) return {}

  const orgKey = _conversation.selectMyOrgKeyInConvo.call(void 0, state, blobjectKey)

  return {
    orgKey,
    isOverInternalChatLimit: _conversation.selectShouldShowUpgradeNotice.call(void 0, state, blobjectKey),
    isOverPatientChatLimit: _conversation.selectShouldShowPatientUpgradeNotice.call(void 0, state, blobjectKey),
    amIAdmin: !!orgKey && _user.selectAmIAdminInOrg.call(void 0, state, orgKey),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(_react.memo.call(void 0, ChatUpgradeNotice))
