"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Header/RenameRoomModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');




var _roomName = require('shared/validation/videoRoom/roomName');
var _Form = require('web/components/Form');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);








const RenameRoomModal = ({
  isOpen,
  onClose,
  roomName = '',
  onProceed,
}) => {
  const { resetField, handleSubmit, control, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _roomName.videoRoomNameResolver,
      defaultValues: { roomName },
    })
  const { isValid, isDirty, isSubmitting } = formState

  _react.useEffect.call(void 0, 
    () => { resetField('roomName', { defaultValue: roomName }) },
    [resetField, roomName],
  )

  const submit =
    _react.useCallback.call(void 0, data => onProceed(data.roomName), [onProceed])

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "Rename meeting" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
    
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, "Enter a name for your meeting room."      )
      , _react2.default.createElement(_reacthookform.Controller, {
        control: control,
        name: "roomName",
        render: ({ field, fieldState }) => (
          _react2.default.createElement(_Form.InputSectionWrapper, { errorMessage: _optionalChain([fieldState, 'access', _ => _.error, 'optionalAccess', _2 => _2.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
            , _react2.default.createElement(_Form.TextInput, {
              autoFocus: true,
              value: field.value,
              onChange: field.onChange,
              hasError: !!fieldState.error,
              placeholder: "E.g. Remote Visits"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Done",
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        isPrimaryLoading: isSubmitting,
        onPrimaryBtnClick: handleSubmit(submit),
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      )
    )
  )
}

exports. default = RenameRoomModal
