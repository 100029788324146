"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Org/TabWrapper.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





const TabWrapper = ({ children, classNameSuffix }) => (
  _react2.default.createElement('div', { className: "settings--org--tab-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    , _react2.default.createElement('div', { className: `settings--org--tab settings--org--tab-${classNameSuffix}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
      ,  children 
    )
  )
)

exports. default = TabWrapper
