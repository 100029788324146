"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');



 const useStatefulPromise = (fn) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [hasFailed, setHasFailed] = _react.useState.call(void 0, false)

  const run = _react.useCallback.call(void 0, 
    async () => {
      if (!fn) return
      setIsLoading(true)
      return fn()
        .then(() => setIsLoading(false))
        .catch(e => {
          setIsLoading(false)
          setHasFailed(true)
          throw e
        })
    },
    [fn],
  )

  return {
    isLoading,
    hasFailed,
    run,
  }
}; exports.useStatefulPromise = useStatefulPromise
