"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/SimpleSelectController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _hookForm = require('shared/services/validation/hookForm');

var _SimpleSelect = require('web/components/Form/SimpleSelect'); var _SimpleSelect2 = _interopRequireDefault(_SimpleSelect);






const SimpleSelectController = ({
  name,
  control,
  ...props
}) => (
  _react2.default.createElement(_hookForm.Controller, {
    name: name,
    control: control,
    render: ({ field }) => (
      _react2.default.createElement(_SimpleSelect2.default, {
        value: field.value ,
        onChange: field.onChange,
        ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
  )
  )

exports. default = SimpleSelectController
