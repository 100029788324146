"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Permissions.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _videoRoom = require('web/selectors/videoRoom');
var _videoCall = require('web/selectors/videoCall');
var _videoCall3 = require('web/services/videoCall');
var _ui = require('web/services/ui');
var _VideoCall = require('web/components/VideoCall');
var _RoomHeader = require('web/components/VideoCall/RoomHeader'); var _RoomHeader2 = _interopRequireDefault(_RoomHeader);

class PermissionsScreen extends _react2.default.Component {constructor(...args) { super(...args); PermissionsScreen.prototype.__init.call(this);PermissionsScreen.prototype.__init2.call(this);PermissionsScreen.prototype.__init3.call(this);PermissionsScreen.prototype.__init4.call(this);PermissionsScreen.prototype.__init5.call(this);PermissionsScreen.prototype.__init6.call(this);PermissionsScreen.prototype.__init7.call(this);PermissionsScreen.prototype.__init8.call(this); }
  __init() {this.probingTimeout = null}
  __init2() {this.state = {
    arePermsProbed: false,
    isMobileScreen: _ui.isMobileScreen.call(void 0, ),
  }}

  __init3() {this.isChrome = navigator.userAgent.includes("Chrome")}
  __init4() {this.isSafari = navigator.userAgent.includes("Safari") && !this.isChrome}

  __init5() {this.probePerms = async () => {
    this.probingTimeout = setTimeout(() =>
      this.setState({ arePermsProbed: true })
    , 1500)

    const manager = new (0, _videoCall3.StreamManager)({
      useMic: true,
      useCam: true,
      permsProbing: true,
    })
    await manager.update().catch(() => null)

    manager.stop()
  }}

  __init6() {this.handleOpenMobileMoreOptionsModal = () =>
    this.props.openMobileMoreOptionsModal({ isSettingsLinkEnabled: false })}

  __init7() {this.componentDidMount = () =>
    !this.state.isMobileScreen && this.probePerms()}

  __init8() {this.componentWillUnmount = () => clearTimeout(this.probingTimeout)}

  render () {
    const {
      arePermsBlocked,
      roomName, roomLink, onRoomLinkCopy,
    } = this.props
    const { arePermsProbed, isMobileScreen } = this.state

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_RoomHeader2.default, {
          roomName: roomName,
          roomLink: roomLink,
          onRoomLinkCopy: onRoomLinkCopy,
          openMobileMoreOptionsModal: this.handleOpenMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        )
        , _react2.default.createElement(_VideoCall.Permissions, {
          triggerPermsPrompt: this.probePerms,
          arePermsBlocked: arePermsBlocked,
          arePermsProbed: arePermsProbed,
          isMobileScreen: isMobileScreen,
          isSafari: this.isSafari,
          isChrome: this.isChrome, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        )
      )
    )
  }
}

const mapState = state => ({
  roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
  roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
  arePermsBlocked: _videoCall.selectArePermsBlocked.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(PermissionsScreen)
