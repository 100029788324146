"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }/* eslint-disable @typescript-eslint/no-explicit-any, @typescript-eslint/ban-types, @typescript-eslint/ban-ts-comment */

var _immer = require('immer'); var _immer2 = _interopRequireDefault(_immer);



var _mapBuilders = require('./mapBuilders');

/**
 * Helper type. Passes T out again, but boxes it in a way that it cannot
 * "widen" the type by accident if it is a generic that should be inferred
 * from elsewhere.
 *
 * @internal
 */


































































function isStateFunction (x) {
  return typeof x === 'function'
}








































































































































 function createReducer (
  initialState,
  mapOrBuilderCallback

,
  actionMatchers = [],
  defaultCaseReducer,
) {
  const [actionsMap, finalActionMatchers, finalDefaultCaseReducer] =
    typeof mapOrBuilderCallback === 'function'
      ? _mapBuilders.executeReducerBuilderCallback.call(void 0, mapOrBuilderCallback)
      : [mapOrBuilderCallback, actionMatchers, defaultCaseReducer]

  // Ensure the initial state gets frozen either way
  let getInitialState
  if (isStateFunction(initialState)) {
    getInitialState = () => _immer2.default.call(void 0, initialState(), () => undefined)
  } else {
    const frozenInitialState = _immer2.default.call(void 0, initialState, () => undefined)
    getInitialState = () => frozenInitialState
  }

  function reducer (state = getInitialState(), action, globalState) {
    let caseReducers = [
      actionsMap[action.type],
      ...finalActionMatchers
        .filter(({ matcher }) => matcher(action))
        .map(({ reducer }) => reducer),
    ]
    if (caseReducers.filter((cr) => !!cr).length === 0) {
      caseReducers = [finalDefaultCaseReducer]
    }

    return caseReducers.reduce((previousState, caseReducer) => {
      if (caseReducer) {
        if (_immer.isDraft.call(void 0, previousState)) {
          // If it's already a draft, we must already be inside a `createNextState` call,
          // likely because this is being wrapped in `createReducer`, `createSlice`, or nested
          // inside an existing draft. It's safe to just pass the draft to the mutator.
          const draft = previousState  // We can assume this is already a draft
          const result = caseReducer(draft, action, globalState)

          if (typeof result === 'undefined') {
            return previousState
          }

          return result 
        } else if (!_immer.isDraftable.call(void 0, previousState)) {
          // If state is not draftable (ex: a primitive, such as 0), we want to directly
          // return the caseReducer func and not wrap it with produce.
          const result = caseReducer(previousState , action, globalState)

          if (typeof result === 'undefined') {
            if (previousState === null) {
              return previousState
            }
            throw Error(
              'A case reducer on a non-draftable value must not return undefined',
            )
          }

          return result 
        } else {
          // @ts-ignore createNextState() produces an Immutable<Draft<S>> rather
          // than an Immutable<S>, and TypeScript cannot find out how to reconcile
          // these two types.
          return _immer2.default.call(void 0, previousState, (draft) => {
            return caseReducer(draft, action, globalState)
          })
        }
      }

      return previousState
    }, state)
  }

  reducer.getInitialState = getInitialState

  return reducer 
} exports.createReducer = createReducer;
