"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/components/Wrapper.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _useStatefulPromise = require('shared/hooks/useStatefulPromise');
var _time = require('shared/services/time');

var _Form = require('web/components/Form');
var _Spinner = require('web/components/Spinner');
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);












const Wrapper = ({
  children,
  isLoading,
  hasLoadFailed,
  supportUrl,
  canManage,
  onManage,
  isLocked,
  cancelAt,
}) => {
  const {
    isLoading: isManageLoading,
    run: handleManage,
  } = _useStatefulPromise.useStatefulPromise.call(void 0, onManage)

  if (isLocked && !cancelAt) {
    return (
        _react2.default.createElement('div', { className: "settings--org-subs--preloader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , _react2.default.createElement('span', { className: "settings--org-subs--error-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}, "Please contact support to make changes to this organization's plan."

          )
        )
    )
  }

  const renderContent = () => {
    if (isLoading) {
      return (
        _react2.default.createElement('div', { className: "settings--org-subs--preloader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
          , _react2.default.createElement(_Spinner.SimpleSpinner, { className: "settings--org-subs--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
        )
      )
    }

    if (hasLoadFailed) {
      return (
        _react2.default.createElement('div', { className: "settings--org-subs--preloader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          , _react2.default.createElement('h1', { className: "settings--org-subs--error-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "Failed to load your plans"    )
          , _react2.default.createElement('span', { className: "settings--org-subs--error-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "Try again later or contact "
                 , ' '
            , _react2.default.createElement('a', { href: "mailto:support@bloomtext.com", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "support@bloomtext.com")
          )
        )
      )
    }

    return children
  }

  return (
    _react2.default.createElement('div', { className: "settings--org-subs--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      ,  cancelAt && (
        _react2.default.createElement(_WarningBar2.default, {
          theme: "warning",
          text: `This plan will be canceled on ${_time.formatReadableDisplayDate.call(void 0, cancelAt)}`,
          className: "settings--org-subs--cancel-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        )
      )
      , _react2.default.createElement('div', { className: "settings--org-subs--title-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
        , _react2.default.createElement('h2', { className: "settings--org-subs--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}, "Features")
        ,  canManage && (
          _react2.default.createElement(_Form.Button, {
            isInverted: true,
            isLoading: isManageLoading,
            onClick: handleManage,
            className: "settings--org-subs--manage-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
          
            ,  !isManageLoading && _react2.default.createElement('img', { src: "/assets/icons/gear-outlined-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} ) , "Manage billing"

          )
        )
      )
      , _react2.default.createElement('div', { className: "settings--org-subs--features", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        ,  renderContent() 
      )
      , _react2.default.createElement('div', { className: "settings--org-subs--footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        , _react2.default.createElement('span', { className: "settings--org-subs--footer--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, "Interested in learning more?"

        )
        , _react2.default.createElement('a', { href: supportUrl, target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
          , _react2.default.createElement(_Form.Button, { isInverted: true, className: "settings--org-subs--footer--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}, "Talk to an expert"

          )
        )
      )
    )
  )
}

exports. default = Wrapper
