"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Name.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _user = require('shared/selectors/user');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

var _NameBadge = require('web/components/NameBadge'); var _NameBadge2 = _interopRequireDefault(_NameBadge);








const makeUserName = (u) => {
  if (u.FirstName && u.LastName) return `${u.FirstName} ${u.LastName}`
  return u.FirstName || u.LastName
}

/* An object to standardize how names are represented. Takes in a basic user object (from store) */
const Name = ({
  shouldShowRoleBadge,
  orgKey,
  className,
  user,
}) => {
  return (
    _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, 'blm-name', className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      ,  makeUserName(user) , ' '
      , _react2.default.createElement('span', { className: "blm-name--postnominals", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        ,  'PostNominals' in user && user.PostNominals 
      )
      ,  shouldShowRoleBadge && _user.isUserAdminInOrg.call(void 0, user, orgKey) && (
        _react2.default.createElement(_NameBadge2.default, { label: "Admin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
      )
    )
  )
}

exports. default = Name
