"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reactrouterdom = require('react-router-dom');

const ROUTES = {
  ROOT: '/',
  LOGIN: {
    ROOT: '/login',
    EMAIL_LOGIN: '/login/email/:email',
    FORGOT_PASSWORD: '/login/reset-password',
    RESET_PASSWORD: '/login/reset-password/:token?',
    EXPIRED_PASSWORD: '/login/expired-password',
  },
  INBOX: {
    ROOT: '/inbox',
    NEW: '/inbox/new/:createdChatType?',
    CHAT: '/inbox/:blobjectKey?/:wrappedTypePrefix?/:wrappedKey?',
  },
  BROADCAST: {
    ROOT: '/broadcast',
    CAMPAIGN_PAGE: '/broadcast/:campaignKey',
  },
  DIRECTORY: {
    ROOT: '/directory',
    ENTITY: '/directory/:typePrefix?/:orgKey?/:entityKey?',
    TEAM_PAGE: '/directory/t/:orgKey/:teamKey',
    USER_PAGE: '/directory/u/:orgKey/:userKey',
    PATIENT_PAGE: '/directory/p/:orgKey/:patientKey',
  },
  VIDEO: {
    ROOT: '/vd',
    V1: '/video/:videoKey/:userKey?',
    V2: '/vd/:blobjectKey?',
  },
  CONFIRM_EMAIL: '/confirm_email/:token',
  UNSUBSCRIBE: {
    EMAIL: '/unsubscribe_notifs/:email/:token',
  },
  CODE: '/code',
  WELCOME: '/welcome',
  CREATE_ACCOUNT: '/welcome/:token?',
  SETTINGS: {
    ROOT: '/settings',
    PROFILE: '/settings/profile',
    SECURITY: '/settings/security',
    NOTIFICATIONS: '/settings/notifications',
    ORGS: '/settings/orgs',
    ORG_PAGE: '/settings/orgs/:orgKey/:tabName',
    ORG_PAGE_NO_TAB: '/settings/orgs/:orgKey',
    ORG_PAGE_DEFAULT_TAB: '/settings/orgs/:orgKey/members',
  },
  SUPER: '/super',
}

 const makeRoute = _reactrouterdom.generatePath; exports.makeRoute = makeRoute

exports. default = ROUTES
