"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');
var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);





 const MAX_AUTOREPLY_MSG_LENGTH = 320; exports.MAX_AUTOREPLY_MSG_LENGTH = MAX_AUTOREPLY_MSG_LENGTH

const schema = _joi4.default.object({
  autoReplyMessage: _joi3.requiredString.call(void 0, )
    .max(exports.MAX_AUTOREPLY_MSG_LENGTH)
    .messages(_joi3.makeStrErrors.call(void 0, 'Message')),
})

 const autoReplyMessageResolver = _joi.joiResolver.call(void 0, schema); exports.autoReplyMessageResolver = autoReplyMessageResolver
