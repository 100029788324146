"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');



const useActivePicker = (onSubmit) => {
  const [activeTimePicker, setActiveTimePicker] = _react.useState(null)
  const onCloseTimePicker = () => {
    setActiveTimePicker(null)
    onSubmit()
  }
  const onOpenStartTimePicker = () => setActiveTimePicker('start')
  const onOpenEndTimePicker = () => setActiveTimePicker('end')

  return {
    activeTimePicker,
    onOpenStartTimePicker,
    onOpenEndTimePicker,
    onCloseTimePicker,
  }
}

exports. default = useActivePicker
