"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Header/DesktopMoreTooltip.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _Tooltip = require('web/components/Tooltip');

const DesktopMoreTooltipWrapper = ({
  openDesktopHistoryModal,
  onRoomRename,
  isRoomArchived,
  onRoomArchive,
  onRoomUnarchive,
  children,
}) => (
  _react2.default.createElement(_Tooltip.ClickTooltip, {
    type: "light",
    place: "bottom",
    content: 
      _react2.default.createElement(_Tooltip.TooltipContent, { className: "meeting-room--header--more-tooltip--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , _react2.default.createElement('span', {
          onClick: openDesktopHistoryModal,
          className: "blmh-over-grid-hide meeting-room--header--more-tooltip--option" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        
          , _react2.default.createElement('img', { src: "assets/icons/video/history-black.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} ), "View history"

        )
        , _react2.default.createElement('span', {
          onClick: onRoomRename,
          className: "meeting-room--header--more-tooltip--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        
          , _react2.default.createElement('img', { src: "assets/icons/video/edit-black.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} ), "Rename meeting"

        )
        , _react2.default.createElement('span', {
          onClick: isRoomArchived ? onRoomUnarchive : onRoomArchive,
          className: "meeting-room--header--more-tooltip--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        
          , _react2.default.createElement('img', {
            src: 
              isRoomArchived
                ? "assets/icons/video/retry-black.svg"
                : "assets/icons/video/delete-black.svg"
            ,
            alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
          )
          ,  isRoomArchived
            ? 'Unarchive meeting'
            : 'Archive meeting'
          
        )
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
  
    , children
  )
)

DesktopMoreTooltipWrapper.propTypes = {
  openDesktopHistoryModal: _proptypes2.default.func.isRequired,
  onRoomRename: _proptypes2.default.func.isRequired,
  onRoomArchive: _proptypes2.default.func.isRequired,
}

exports. default = DesktopMoreTooltipWrapper
