"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/CampaignForm/Form/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');








var _myRelations = require('shared/selectors/myRelations');
var _session = require('shared/selectors/session');
var _broadcast = require('shared/selectors/broadcast');






var _broadcastCampaign = require('shared/validation/broadcastCampaign');
var _useBroadcastForm = require('./helpers/useBroadcastForm'); var _useBroadcastForm2 = _interopRequireDefault(_useBroadcastForm);
var _const = require('./helpers/const');
var _utils = require('./helpers/utils');
var _noticeBar = require('web/services/context/noticeBar');





var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');
var _SenderPickerController = require('./SenderPickerController'); var _SenderPickerController2 = _interopRequireDefault(_SenderPickerController);
var _MessageTypeController = require('./MessageTypeController'); var _MessageTypeController2 = _interopRequireDefault(_MessageTypeController);
var _MessageDelayController = require('./MessageDelayController'); var _MessageDelayController2 = _interopRequireDefault(_MessageDelayController);












const CampaignFormModal = ({
  submitLabel,
  onClose,
  onSubmit,
  teamsList,
  teamEntitiesMap,
  campaignKey,
  defaultCampaignName,
  defaultTeamKey,
  defaultSenderKey,
  defaultMessageType,
  defaultMessageDelay,
  defaultMessageTemplate,
}) => {
  const { register, resetField, control, handleSubmit, formState } =
    _useBroadcastForm2.default.call(void 0, {
      name: defaultCampaignName,
      teamKey: defaultTeamKey || '',
      senderKey: defaultSenderKey || '',
      messageType: defaultMessageType,
      messageDelay: defaultMessageDelay,
      messageTemplate: defaultMessageTemplate,
    })

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  const onProceed = _react.useCallback.call(void 0, 
    data =>
      onSubmit({
        Name: data.name,
        TeamKey: data.teamKey,
        SenderKey: data.senderKey,
        MessageType: data.messageType,
        MessageDelay: _utils.roundMessageDelay.call(void 0, data.messageDelay),
        MessageTemplate: data.messageTemplate,
      })
        .then(() => showSuccessBar(
          `${data.name} has been ${campaignKey ? 'updated' : 'created'}`,
        ))
        .then(onClose)
        .catch(() => showWarningBar(
          `Couldn't ${campaignKey ? 'update' : 'create'} a campaign`,
        )),
    [onSubmit, onClose, campaignKey, showWarningBar, showSuccessBar],
  )

  const { isValid, isSubmitting, isDirty, errors } = formState

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.InputSection, {
        title: "Campaign Name" ,
        autoFocus: true,
        placeholder: "E.g. Appointment Reminders"  ,
        inputProps: register('name'),
        errorMessage: _optionalChain([errors, 'access', _ => _.name, 'optionalAccess', _2 => _2.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
      )
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Team", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
          , _react2.default.createElement(_Form.TeamPickerController, {
            name: "teamKey",
            control: control,
            teamsList: teamsList,
            teamEntitiesMap: teamEntitiesMap, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
          )
        )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Sender", __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
          , _react2.default.createElement(_SenderPickerController2.default, {
            name: "senderKey",
            teamKeyName: "teamKey",
            control: control,
            resetField: resetField,
            teamEntitiesMap: teamEntitiesMap, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
          )
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Message Type" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
        , _react2.default.createElement(_MessageTypeController2.default, {
          name: "messageType",
          control: control,
          options: _const.messageTypeOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Time between messages"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
        , _react2.default.createElement(_MessageDelayController2.default, {
          name: "messageDelay",
          control: control, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Message", errorMessage: _optionalChain([errors, 'access', _3 => _3.messageTemplate, 'optionalAccess', _4 => _4.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
        , _react2.default.createElement(_Form.SimpleTextAreaController, {
          control: control,
          name: "messageTemplate",
          maxLength: _broadcastCampaign.MAX_BROADCAST_MESSAGE_LENGTH,
          placeholder: "Type your message..."  ,
          className: "broadcast--campaign-form--message-field",
          hasError: !!errors.messageTemplate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: submitLabel,
        isPrimaryEnabled: !isSubmitting && isValid && isDirty,
        isPrimaryLoading: isSubmitting,
        onPrimaryBtnClick: handleSubmit(onProceed),
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
      )
    )
  )
}












const mapState = (state, { campaignKey }) => {
  const {
    teamsList,
    entitiesMap: teamEntitiesMap,
  } = _myRelations.selectMyPhoneTeamEntities.call(void 0, state) 



  const selectedTeamKey = _session.selectSelectedTeamKey.call(void 0, state)

  const campaign = campaignKey && (_broadcast.selectCampaignByKey.call(void 0, state, campaignKey) )
  const defaultTeamKey = _optionalChain([campaign, 'optionalAccess', _5 => _5.TeamKey])
    || selectedTeamKey
    || _optionalChain([teamsList, 'access', _6 => _6[0], 'optionalAccess', _7 => _7.wrappedEntity, 'optionalAccess', _8 => _8.TeamKey])
    || null

  const defaultCampaignName = _nullishCoalesce(_optionalChain([campaign, 'optionalAccess', _9 => _9.Name]), () => ( ''))
  const defaultSenderKey = _nullishCoalesce(_optionalChain([campaign, 'optionalAccess', _10 => _10.SenderKey]), () => ( null))
  const defaultMessageType = _nullishCoalesce((_optionalChain([campaign, 'optionalAccess', _11 => _11.MessageType]) ), () => ( _broadcastCampaign.MessageType.SecureSMS))
  const defaultMessageDelay = _nullishCoalesce(_optionalChain([campaign, 'optionalAccess', _12 => _12.MessageDelay]), () => ( _broadcastCampaign.DEFAULT_MESSAGE_DELAY_MS))
  const defaultMessageTemplate = _nullishCoalesce(_optionalChain([campaign, 'optionalAccess', _13 => _13.MessageTemplate]), () => ( ''))

  return {
    teamsList,
    teamEntitiesMap,
    defaultCampaignName,
    defaultTeamKey,
    defaultSenderKey,
    defaultMessageType,
    defaultMessageDelay,
    defaultMessageTemplate,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(CampaignFormModal)
