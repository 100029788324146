"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _createNamedExportFrom(obj, localName, importedName) { Object.defineProperty(exports, localName, {enumerable: true, get: () => obj[importedName]}); }var _BaseAvatar = require('./BaseAvatar'); _createNamedExportFrom(_BaseAvatar, 'default', 'default');












var _UserAvatar = require('./UserAvatar'); _createNamedExportFrom(_UserAvatar, 'UserAvatar', 'default');
var _TeamAvatar = require('./TeamAvatar'); _createNamedExportFrom(_TeamAvatar, 'TeamAvatar', 'default');
var _PatientAvatar = require('./PatientAvatar'); _createNamedExportFrom(_PatientAvatar, 'PatientAvatar', 'default');
var _OrgAvatar = require('./OrgAvatar'); _createNamedExportFrom(_OrgAvatar, 'OrgAvatar', 'default');
var _MessageAvatar = require('./MessageAvatar'); _createNamedExportFrom(_MessageAvatar, 'MessageAvatar', 'default');
var _GroupAvatar = require('./GroupAvatar'); _createNamedExportFrom(_GroupAvatar, 'GroupAvatar', 'default');
var _CampaignAvatar = require('./CampaignAvatar'); _createNamedExportFrom(_CampaignAvatar, 'CampaignAvatar', 'default');
var _AddAvatar = require('./AddAvatar'); _createNamedExportFrom(_AddAvatar, 'AddAvatar', 'default');
var _EntityAvatar = require('./EntityAvatar'); _createNamedExportFrom(_EntityAvatar, 'EntityAvatar', 'default');
