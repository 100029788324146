"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/components/SignupStepLoader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Spinner = require('web/components/Spinner');

const SignupStepLoader = () => (
  _react2.default.createElement('div', { className: "signup-step--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
    , _react2.default.createElement('div', { className: "signup-step--loader--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
      , _react2.default.createElement(_Spinner.SimpleSpinner, { className: "signup-step--loader--indicator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}} )
      , _react2.default.createElement('span', { className: "signup-step--loader--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, "Loading...")
    )
  )
)

exports. default = SignupStepLoader
