"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/ItemsList.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');




var _Item = require('./Item'); var _Item2 = _interopRequireDefault(_Item);










const List = ({
  activeFilter,
  hasMore,
  onLoadMore,
  blobjectKeys,
  isBlobjectBeingArchived,
  onToggleBlobjectArchived,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    ,  blobjectKeys.map(
      (blobjectKey, idx) => (
        _react2.default.createElement(_Item2.default, {
          key: blobjectKey,
          blobjectKey: blobjectKey,
          hasTooltip: idx === 0,
          activeFilter: activeFilter,
          isBeingArchived: isBlobjectBeingArchived(blobjectKey),
          onToggleArchived: onToggleBlobjectArchived, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        )
      ))
    
    ,  hasMore && (
      _react2.default.createElement(_reactwaypoint.Waypoint, {
        bottomOffset: -100,
        onEnter: () => onLoadMore(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      )
    )
  )
)

exports. default = List
