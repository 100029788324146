"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/App/List/ListHeaderWithSlotAddButton.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





const ListHeaderWithSlotAddButton = ({
  className,
  children,
  AddBtn,
  ...props
}) => (
  _react2.default.createElement('div', {
    className: _classBuilder2.default.call(void 0, "list-header", className),
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
  
    , _react2.default.createElement('div', { className: "list-header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, children)
    , _react2.default.createElement('div', { className: "list-header--add", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      , _react2.default.createElement(AddBtn, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
    )
  )
)

exports. default = ListHeaderWithSlotAddButton
