"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/OrgsList/InviteOrgModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');




var _myRelations = require('shared/selectors/myRelations');
var _session = require('shared/selectors/session');



var _invitedOrg = require('shared/validation/org/invitedOrg');
var _noticeBar = require('web/services/context/noticeBar');
var _useInviteOrg = require('shared/hooks/useInviteOrg'); var _useInviteOrg2 = _interopRequireDefault(_useInviteOrg);

var _Modal = require('web/components/Modal');





var _Form = require('web/components/Form');





const InviteOrgModal = ({
  teamsList,
  teamEntitiesMap,
  defaultTeamKey,
  onClose,
}) => {
  const defaultValues = _react.useMemo.call(void 0, 
    () => ({
      orgName: '',
      adminEmail: '',
      myTeamKey: defaultTeamKey || '',
      chatName: '',
    }),
    [defaultTeamKey],
  )
  const { register, setError, control, handleSubmit, formState } =
    _reacthookform.useForm({
      resolver: _invitedOrg.invitedOrgResolver,
      mode: 'onChange',
      defaultValues,
    })

  const { isValid, isSubmitting, isDirty, errors } = formState

  const onInviteOrg = _useInviteOrg2.default.call(void 0, { teamEntitiesMap })
  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    data => onInviteOrg(data)
      .then(onClose)
      .then(() => showSuccessBar(
        `${data.adminEmail} from ${data.orgName} has been invited to join BloomText.`,
      ))
      .catch(e => {
        if (!e.extra) return showWarningBar(`Failed to invite ${data.orgName}`)

        const { type, message } = e.extra
        if (type === _useInviteOrg.INVITE_ORG_ERROR.orgAlreadyExists) {
          setError('orgName', { type: 'manual', message })
        }
        if (type === _useInviteOrg.INVITE_ORG_ERROR.adminAlreadyExists) {
          setError('adminEmail', { type: 'manual', message })
        }
      }),
    [onClose, onInviteOrg, setError, showSuccessBar, showWarningBar],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        , _react2.default.createElement(_Form.InputSection, {
          autoFocus: true,
          title: "Organization Name" ,
          inputProps: register('orgName'),
          placeholder: "E.g. Central Hospital"  ,
          errorMessage: _optionalChain([errors, 'access', _ => _.orgName, 'optionalAccess', _2 => _2.message]),
          className: "settings--invite-org--top-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        )
        , _react2.default.createElement(_Form.InputSection, {
          title: "Admin Email" ,
          inputProps: register('adminEmail'),
          placeholder: "E.g. john@email.com" ,
          errorMessage: _optionalChain([errors, 'access', _3 => _3.adminEmail, 'optionalAccess', _4 => _4.message]),
          className: "settings--invite-org--top-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Your Team" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        , _react2.default.createElement('span', { className: "settings--invite-org--modal--field-hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, "We'll set up a chat with your team and everyone at the new organization."

        )
        , _react2.default.createElement(_Form.TeamPickerController, {
          name: "myTeamKey",
          control: control,
          teamsList: teamsList,
          teamEntitiesMap: teamEntitiesMap, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
        )
      )
      , _react2.default.createElement(_Form.InputSection, {
        isOptional: true,
        title: "Chat Name" ,
        inputProps: register('chatName'),
        placeholder: "E.g. Medication, Appointments..."  ,
        errorMessage: _optionalChain([errors, 'access', _5 => _5.chatName, 'optionalAccess', _6 => _6.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Invite",
        onPrimaryBtnClick: handleSubmit(onSubmit),
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        isPrimaryLoading: isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
      )
    )
  )
}







const mapState = (state) => {
  const {
    teamsList,
    entitiesMap: teamEntitiesMap = {},
  } = _myRelations.selectMyEntities.call(void 0, state)

  const selectedTeamKey = _session.selectSelectedTeamKey.call(void 0, state)
  const defaultTeamKey = selectedTeamKey || _optionalChain([teamsList, 'access', _7 => _7[0], 'optionalAccess', _8 => _8.wrappedEntity, 'optionalAccess', _9 => _9.TeamKey])

  return {
    teamsList,
    teamEntitiesMap: teamEntitiesMap ,
    defaultTeamKey,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(InviteOrgModal)
