"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/Toggle/Option.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Tooltip = require('web/components/Tooltip');








const Option = ({ label, desc, iconSrc, onClick }) => {
  const option = (
    _react2.default.createElement('div', { role: "button", onClick: onClick, className: "create-new--tooltip--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , _react2.default.createElement('div', { className: "create-new--tooltip--option-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , _react2.default.createElement('img', { src: iconSrc, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
      )
      , _react2.default.createElement('span', { className: "create-new--tooltip--option-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}},  label )
    )
  )

  return desc
    ? _react2.default.createElement(_Tooltip.HoverTooltip, {
        place: "right",
        type: "dark",
        className: "create-new--tooltip--option-desc",
        content: 
          _react2.default.createElement(_Tooltip.TooltipContent, { className: "create-new--tooltip--option-desc-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
            ,  desc 
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      
        ,  option 
      )
    : option
}

exports. default = Option
