"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/IncompatibleBrowser/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

exports. default = () => (
  _react2.default.createElement('div', { className: "video-call--bad-browser", __self: this, __source: {fileName: _jsxFileName, lineNumber: 4}}
    , _react2.default.createElement('div', { className: "video-call--bad-browser--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
      , _react2.default.createElement('div', { className: "video-call--bad-browser--heading", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
        , _react2.default.createElement('img', {
          className: "video-call--bad-browser--icon",
          src: "/assets/icons/alert-grey.svg",
          alt: "!", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
        )
        , _react2.default.createElement('h2', { className: "video-call--bad-browser--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "Incompatible Browser"

        )
      )
      , _react2.default.createElement('div', { className: "video-call--bad-browser--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
        , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}, "Your current browser does not support video calls. Please try to upgrade it or access this page on a different device."


        )
      )
    )
  )
)
