"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Permissions/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

var _PermissionsRequest = require('./PermissionsRequest'); var _PermissionsRequest2 = _interopRequireDefault(_PermissionsRequest);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _MobileGenericGuide = require('./MobileGenericGuide'); var _MobileGenericGuide2 = _interopRequireDefault(_MobileGenericGuide);
var _MobileChromeGuide = require('./MobileChromeGuide'); var _MobileChromeGuide2 = _interopRequireDefault(_MobileChromeGuide);
var _MobileSafariGuide = require('./MobileSafariGuide'); var _MobileSafariGuide2 = _interopRequireDefault(_MobileSafariGuide);

const Permissions = ({
  triggerPermsPrompt,
  arePermsBlocked,
  arePermsProbed,
  isMobileScreen,
  isSafari,
  isChrome,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "meeting--perms", !arePermsProbed && "blmh-desktop-hide"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    , _react2.default.createElement(_PermissionsRequest2.default, {
      triggerPermsPrompt: triggerPermsPrompt,
      arePermsBlocked: arePermsBlocked, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    )
    ,  isMobileScreen &&
      _react2.default.createElement(_Modal2.default, {
        isOpen: arePermsBlocked,
        title: "Allow Microphone & Camera Access"    ,
        className: "meeting--perms-guide--modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      
        , _react2.default.createElement('div', { className: "meeting--perms-guide--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          ,  isChrome
            ? _react2.default.createElement(_MobileChromeGuide2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
            : isSafari
              ? _react2.default.createElement(_MobileSafariGuide2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
              : _react2.default.createElement(_MobileGenericGuide2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} )
          
        )
      )
    
  )
)

Permissions.propTypes = {
  triggerPermsPrompt: _proptypes2.default.func.isRequired,
  arePermsBlocked: _proptypes2.default.bool.isRequired,
  arePermsProbed: _proptypes2.default.bool.isRequired,
  isMobileScreen: _proptypes2.default.bool.isRequired,
  isSafari: _proptypes2.default.bool.isRequired,
  isChrome: _proptypes2.default.bool.isRequired,
}

exports. default = Permissions
