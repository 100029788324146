"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/OrgLimitNotice.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _upgrade = require('shared/enum/upgrade');
var _upgradeNotices = require('shared/constants/upgradeNotices');
var _user = require('shared/selectors/user');
var _org = require('shared/selectors/org');

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const OrgLimitNotice = ({
  orgKey,
  className,
  amIAdmin,
  addedUsersCount = 0,
  availableSeats,
}) => {
  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = _useToggle2.default.call(void 0, )

  const canReachLimit = addedUsersCount > 0
    && availableSeats >= 0
    && addedUsersCount > availableSeats
  if (!amIAdmin || !canReachLimit) return null

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "org-limit-warning", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , _react2.default.createElement('div', { className: "org-limit-warning--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , _react2.default.createElement('span', { className: "org-limit-warning--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
          ,  _upgradeNotices.soonToHitLimitNotice 
        )
      )
      , _react2.default.createElement(_Form.Button, {
        isSecondary: true,
        onClick: onOpenUpgradeModal,
        className: "org-limit-warning--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , "Upgrade"

      )
        , _react2.default.createElement(_UpgradeModal2.default, {
          isOpen: isUpgradeModalOpen,
          onClose: onCloseUpgradeModal,
          type: _upgrade.SubscriptionItemType.TEAM,
          orgKey: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        )
    )
  )
}






const mapState = (state, { orgKey }) => ({
  amIAdmin: _user.selectAmIAdminInOrg.call(void 0, state, orgKey),
  availableSeats: _org.selectAvailableMemberSeatsInOrg.call(void 0, state, orgKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(OrgLimitNotice)
