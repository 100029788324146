"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/components/StatusPill.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _broadcast = require('shared/enum/broadcast');

var _Tooltip = require('web/components/Tooltip');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const displayStatusMap = {
  [_broadcast.broadcastMsgStatus.NOT_SCHEDULED]: 'Not scheduled',
  [_broadcast.broadcastMsgStatus.QUEUED]: 'Queued',
  [_broadcast.broadcastMsgStatus.PROCESSING]: 'Processing',
  [_broadcast.broadcastMsgStatus.FAILED]: 'Failed',
  [_broadcast.broadcastMsgStatus.COMPLETED]: 'Completed',
}
const classNameSuffixMap = {
  [_broadcast.broadcastMsgStatus.NOT_SCHEDULED]: 'not-scheduled',
  [_broadcast.broadcastMsgStatus.QUEUED]: 'queued',
  [_broadcast.broadcastMsgStatus.PROCESSING]: 'processing',
  [_broadcast.broadcastMsgStatus.FAILED]: 'failed',
  [_broadcast.broadcastMsgStatus.COMPLETED]: 'completed',
}






const StatusPill = ({ status, desc }) => {
  const label = (
    _react2.default.createElement('span', { className: "campaign--table--status-pill--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      ,  displayStatusMap[status] 
    )
  )

  const content = desc
    ? (
        _react2.default.createElement(_Tooltip.HoverTooltip, {
          type: "dark",
          place: "top",
          content: _react2.default.createElement('span', { className: "campaign--table--tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}},  desc ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        
          ,  label 
        )
      )
    : label

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
      "campaign--table--status-pill",
      `campaign--table--status-pill-${classNameSuffixMap[status]}`,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      ,  content 
    )
  )
}

exports. default = StatusPill
