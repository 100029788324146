"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');

var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _entity = require('shared/enum/entity');
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);

const resolveRelevantActionTypes = (listType) => {
  if (listType === _entity.ENTITY_TYPES.USER) {
    return [
      _actionCreators2.default.inviteUserToMyOrgActions.SUCCESS.type,
      _actionCreators2.default.removeUserFromOrgActions.SUCCESS.type,
    ]
  }

  if (listType === _entity.ENTITY_TYPES.TEAM) {
    return [
      _actionCreators2.default.createTeamActions.SUCCESS.type,
      _actionCreators2.default.removeTeamActions.SUCCESS.type,
    ]
  }

  if (listType === _entity.ENTITY_TYPES.PATIENT) {
    return [_actionCreators2.default.createPatientActions.SUCCESS.type]
  }

  return []
}

 const useDirectoryListListener = (listType, listener) => {
  _react.useEffect.call(void 0, 
    () => {
      const actionTypes = resolveRelevantActionTypes(listType)

      actionTypes.forEach(t => _bus2.default.on(t, listener))
      return () => actionTypes.forEach(t => _bus2.default.off(t, listener))
    },
    [listType, listener],
  )
}; exports.useDirectoryListListener = useDirectoryListListener
