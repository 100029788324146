"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reselect = require('reselect');






var _common = require('shared/selectors/blobject/common');
var _team = require('shared/selectors/team');
var _peers = require('shared/selectors/peers');

var _utils = require('./utils');

const makeFallbackUserSubscriber = (UserKey) => ({ UserKey })
const makeFallbackTeamSubscriber = (TeamKey) => ({ TeamKey })
const makeFallbackPatientSubscriber = (PatientKey) => ({ PatientKey })

 const makeSelectUnsafePopulatedBlobjectSubscribers = () =>
  _reselect.createSelector.call(void 0, 
    _common.selectBlobjectByKey,
    _team.selectAllTeams,
    _peers.selectAllUsers,
    _peers.selectAllPatients,
    (blobject, allTeams, allUsers, allPatients) => {
      if (!blobject) return _utils.EMPTY_SUBSCRIBERS_LIST

      const {
        TeamSubscribers,
        UserSubscribers,
        PatientSubscribers,
      } = blobject

      const userSubscribersList =
        _utils.unsafePopulateSubscribers.call(void 0, UserSubscribers, allUsers, makeFallbackUserSubscriber)
      const teamSubscribersList =
        _utils.unsafePopulateSubscribers.call(void 0, TeamSubscribers, allTeams, makeFallbackTeamSubscriber)
      const patientSubscribersList =
        _utils.unsafePopulateSubscribers.call(void 0, 
          PatientSubscribers,
          allPatients,
          makeFallbackPatientSubscriber,
        )

      return {
        userSubscribersList,
        teamSubscribersList,
        patientSubscribersList,
      } 
    },
  ); exports.makeSelectUnsafePopulatedBlobjectSubscribers = makeSelectUnsafePopulatedBlobjectSubscribers
