"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/FirstTimeInteractables/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _firstTimeTutorial = require('web/reducers/firstTimeTutorial');
var _notification = require('web/services/notification');
var _wizard = require('web/services/wizard');
var _FirstTimeModal = require('web/components/Welcome/FirstTimeModal'); var _FirstTimeModal2 = _interopRequireDefault(_FirstTimeModal);
var _EnableQuickLoginPopup = require('./EnableQuickLoginPopup'); var _EnableQuickLoginPopup2 = _interopRequireDefault(_EnableQuickLoginPopup);
var _DirectoryCreateNewModal = require('web/screens/Directory/DirectoryCreateNewModal'); var _DirectoryCreateNewModal2 = _interopRequireDefault(_DirectoryCreateNewModal);
var _Snackbar = require('web/components/Snackbar'); var _Snackbar2 = _interopRequireDefault(_Snackbar);
var _Idle = require('web/components/Idle'); var _Idle2 = _interopRequireDefault(_Idle);
var _user = require('shared/selectors/user');
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _upgrade = require('shared/enum/upgrade');

class FirstTimeInteractablesScreen extends _react2.default.Component {
  constructor (props) {
    super(props);FirstTimeInteractablesScreen.prototype.__init.call(this);FirstTimeInteractablesScreen.prototype.__init2.call(this);FirstTimeInteractablesScreen.prototype.__init3.call(this);
    this.allSnackbars = {
      ENABLE_NOTIFICATIONS_SUGGESTION: {
        iconSrc: '/assets/icons/snackbars/bell.svg',
        text: 'Get alerts for new messages.',
        linkText: 'Enable notifications',
        onLinkClick: _notification.askForPermissionFunction,
      },
      ENABLE_QUICK_LOGIN_SUGGESTION: {
        iconSrc: '/assets/icons/snackbars/lock.svg',
        text: 'Quickly log in to BloomText using a 4-digit PIN code.',
        linkText: 'Enable Quick Login',
        onLinkClick: () => _actions2.default.pushInteractablesQueue({ type: 'POPUP', id: 'ENABLE_QUICK_LOGIN_POPUP' }),
        onAnyAction: () => _wizard.addUserToAwareOfQuickLoginList.call(void 0, props.userKey),
      },
    }
  }

  __init() {this.addCoworkers = () =>
    _actions2.default.pushInteractablesQueue({ type: 'POPUP', id: 'ADD_PEOPLE_POPUP' })}

  __init2() {this.dismissInteractable = () =>
    _actions2.default.dismissInteractableById(this.props.interactable.id)}

  __init3() {this.permitCurrentInteractable = () =>
    _actions2.default.permitInteractable(this.props.interactable.id)}

  render () {
    const {
      interactable, displayUserName, orgKey,
    } = this.props

    const activeSnackbar = interactable.type === 'SNACKBAR' &&
      this.allSnackbars[interactable.id]

    const isTooltip = interactable.type === 'TOOLTIP'

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        ,  activeSnackbar &&
          _react2.default.createElement(_Snackbar2.default, {
            key: interactable.id,
            iconSrc: activeSnackbar.iconSrc,
            text: activeSnackbar.text,
            linkText: activeSnackbar.linkText,
            onClose: this.dismissInteractable,
            onLinkClick: activeSnackbar.onLinkClick,
            onAnyAction: activeSnackbar.onAnyAction, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          )
        
        , _react2.default.createElement(_FirstTimeModal2.default, {
          isOpen: interactable.id === 'FIRST_TIME_POPUP',
          onAddPeople: this.addCoworkers,
          onClose: this.dismissInteractable,
          displayUserName: displayUserName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        )
        , _react2.default.createElement(_UpgradeModal2.default, {
          type: _upgrade.SubscriptionItemType.SMS,
          orgKey: orgKey,
          isOpen: interactable.id === 'SMS_MESSAGING_POPUP',
          onClose: this.dismissInteractable, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        )
        , _react2.default.createElement(_EnableQuickLoginPopup2.default, {
          isOpen: interactable.id === 'ENABLE_QUICK_LOGIN_POPUP',
          onClose: this.dismissInteractable,
          orgKey: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        )
        ,  interactable.id === 'ADD_PEOPLE_POPUP' &&
          _react2.default.createElement(_DirectoryCreateNewModal2.default, {
            isOpen: true,
            onClose: this.dismissInteractable,
            directoryTypeHint: "COWORKERS",
            orgHint: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
          )
        ,  isTooltip && !interactable.isPermitted &&
          _react2.default.createElement(_Idle2.default, {
            id: interactable.id,
            timeout: 2000,
            onIdle: this.permitCurrentInteractable, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
          )
        
        ,  isTooltip && interactable.isPermitted &&
          _react2.default.createElement(_Idle2.default, {
            id: interactable.id,
            timeout: 8000,
            onIdle: this.dismissInteractable, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
          )
        
      )
    )
  }

  static __initStatic() {this.defaultProps = { interactable: {} }}
  static __initStatic2() {this.propTypes = { interactable: _proptypes2.default.object.isRequired }}
} FirstTimeInteractablesScreen.__initStatic(); FirstTimeInteractablesScreen.__initStatic2();

const mapState = state => {
  const me = _user.selectCurrentUser.call(void 0, state)
  return {
    interactable: _firstTimeTutorial.selectActiveInteractable.call(void 0, state),
    userKey: me.UserKey,
    orgKey: Object.values(me.Relationships || {})[0].OrgKey,
    displayUserName: me.FirstName || me.Name,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(FirstTimeInteractablesScreen)
