"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/MenuPicker.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

 const MenuPickerOption = ({
  label, desc, iconSrc, onPick, isRich,
}) => {
  const buildRichClassName = x => _classBuilder2.default.call(void 0, x, { rich: isRich })

  return (
    _react2.default.createElement('div', {
      onClick: onPick,
      className: buildRichClassName("menu-picker--modal--option"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    
      ,  iconSrc
        ? _react2.default.createElement('img', {
              className: buildRichClassName("menu-picker--modal--option--icon"),
              src: iconSrc,
              alt: label, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
            )
        : _react2.default.createElement('div', { className: "menu-picker--modal--option--icon-stub", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
      
      , _react2.default.createElement('div', { className: "menu-picker--modal--option--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , _react2.default.createElement('span', {
          className: buildRichClassName("menu-picker--modal--option--label"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        , label)
        ,  desc &&
          _react2.default.createElement('span', {
            className: buildRichClassName("menu-picker--modal--option--desc-text"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          , desc)
        
      )
    )
  )
}; exports.MenuPickerOption = MenuPickerOption

 const MenuWrapper = ({
  title, isOpen,
  onClose, children,
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    title: title,
    className: "menu-picker--modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
  
    , _react2.default.createElement('div', { className: "menu-picker--modal--options-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}},  children )
  )
); exports.MenuWrapper = MenuWrapper

 const MenuSelect = ({
  isOpen, title, onClose, onChange,
  options, value,
}) => {
  const parsedOptions = options.map(o => ({
    ...o,
    onPick: () => onChange(o.value),
    iconSrc: o.value === value
      ? "assets/icons/video/tick-grey.svg"
      : null,
  }))

  return (
    _react2.default.createElement(exports.MenuPicker, {
      title: title,
      isOpen: isOpen,
      onClose: onClose,
      options: parsedOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
    )
  )
}; exports.MenuSelect = MenuSelect

 const MenuPicker = ({
  title, isOpen,
  onClose, options,
}) => (
  _react2.default.createElement(exports.MenuWrapper, {
    title: title,
    isOpen: isOpen,
    onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
  
    , 
      options.map(({ label, onPick, iconSrc }, i) => (
        _react2.default.createElement(exports.MenuPickerOption, {
          key: i,
          label: label,
          iconSrc: iconSrc,
          onPick: () => { onPick(); onClose() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        )
      ))
    
  )
); exports.MenuPicker = MenuPicker

 const RichMenuPicker = ({
  title, isOpen,
  onClose, options,
}) => (
  _react2.default.createElement(exports.MenuWrapper, {
    title: title,
    isOpen: isOpen,
    onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
  
    , 
      options.map(({ label, desc, onPick, iconSrc }, i) => (
        _react2.default.createElement(exports.MenuPickerOption, {
          key: i,
          isRich: true,
          label: label,
          desc: desc,
          iconSrc: iconSrc,
          onPick: () => { onPick(); onClose() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
        )
      ))
    
  )
); exports.RichMenuPicker = RichMenuPicker
