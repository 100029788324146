"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoMeeting/RoomModalsScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Modals = require('web/components/VideoCall/Modals');
var _Modals3 = require('web/screens/VideoCall/Modals');
var _Modals5 = require('web/components/VideoCall/Feedback/Modals');
var _Modals7 = require('web/screens/VideoRooms/Modals');

 class RoomModalsScreen extends _react2.default.Component {
  render () {
    const {
      onRoomLinkCopy,
      onRoomShare,
      isMobileMoreOptionsModalOpen,
      isMoreOptionsSettingsEnabled,
      isMoreOptionsNetstatsEnabled,
      closeMobileMoreOptionsModal,

      isSettingsModalOpen, closeSettingsModal, openSettingsModal,
      settingsModalContext,

      openNetstatsModal,

      isJoinCallModalOpen,
      openJoinCallModal,
      closeJoinCallModal,
      gotoMeeting,

      isDesktopNewRoomModalOpen,
      isMobileNewRoomModalOpen,
      closeNewRoomModal,

      isFeedbackRatingModalOpen,
      closeFeedbackRatingModal,

      showNoticeBar,
    } = this.props

    return (
      _react2.default.createElement('div', { className: "video-call--modals-screen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        , _react2.default.createElement(_Modal2.default, {
          isOpen: isMobileMoreOptionsModalOpen,
          onClose: closeMobileMoreOptionsModal,
          className: "video-call--more",
          bodyClassName: "video-call--more--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        
          , _react2.default.createElement(_Modals.MoreOptionsMenu, {
            onClose: closeMobileMoreOptionsModal,
            onRoomLinkCopy: onRoomLinkCopy,
            onOpenSettings: openSettingsModal,
            onOpenNetstats: openNetstatsModal,
            isSettingsLinkEnabled: isMoreOptionsSettingsEnabled,
            isNetstatsLinkEnabled: isMoreOptionsNetstatsEnabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          )
        )
        ,  isJoinCallModalOpen && // Stream mngr inits on mount
          _react2.default.createElement(_Modals7.JoinCallModal, {
            isOpen: isJoinCallModalOpen,
            onClose: closeJoinCallModal,
            openJoinCallModal: openJoinCallModal,
            gotoSettings: openSettingsModal,
            gotoMeeting: gotoMeeting,
            onRoomShare: onRoomShare,
            onRoomLinkCopy: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          )
        
        ,  isSettingsModalOpen && // Stream mngr inits on mount
          _react2.default.createElement(_Modals3.SettingsModal, {
            isOpen: isSettingsModalOpen,
            onClose: closeSettingsModal,
            context: settingsModalContext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          )
        
        ,  (isMobileNewRoomModalOpen || isDesktopNewRoomModalOpen) &&
          _react2.default.createElement(_Modals7.NewRoomModal, {
            isOpen: true,
            showNoticeBar: showNoticeBar,
            isMobile: isMobileNewRoomModalOpen,
            onClose: closeNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
          )
        
        ,  isFeedbackRatingModalOpen &&
          _react2.default.createElement(_Modals5.RatingModal, {
            isOpen: isFeedbackRatingModalOpen,
            onClose: closeFeedbackRatingModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
          )
        
      )
    )
  }
} exports.default = RoomModalsScreen;
