"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxRightSide/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





var _NoChat = require('./NoChat'); var _NoChat2 = _interopRequireDefault(_NoChat);
var _CreateNewChat = require('./CreateNewChat'); var _CreateNewChat2 = _interopRequireDefault(_CreateNewChat);
var _Chat = require('./Chat'); var _Chat2 = _interopRequireDefault(_Chat);









const InboxRightSide = ({
  blobjectKey,
  createdChatType,
  createdChatName,
  gotoConversation,
  onCloseCreateNewChat,
}) => {
  if (!blobjectKey && !createdChatType) return _react2.default.createElement(_NoChat2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  blobjectKey && _react2.default.createElement(_Chat2.default, { blobjectKey: blobjectKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} ) 
      ,  createdChatType && (
        _react2.default.createElement(_CreateNewChat2.default, {
          isVisible: !blobjectKey,
          chatName: createdChatName,
          chatType: createdChatType,
          gotoConversation: gotoConversation,
          onCloseCreateNewChat: onCloseCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        )
      )
    )
  )
}

exports. default = InboxRightSide
