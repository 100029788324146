"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

var _time = require('shared/services/time');







 const tzOptions = Object.entries(_time.timezoneNames)
  .map(([value, label]) => ({ value, label })); exports.tzOptions = tzOptions

 const daysList = Array(7).fill(0).map((_d, i) => i); exports.daysList = daysList
 const dayNames = exports.daysList.map(i => _moment2.default.call(void 0, ).day(i).format('dddd')); exports.dayNames = dayNames

const nullTeamHours = exports.daysList.map(i => ({
  dayOfWeek: i,
  availableFrom: '',
  availableUntil: '',
}))

 const makeDefaultValues = (
  defaultTimezone,
  defaultTeamHours,
) => {
  const teamHoursMap = defaultTeamHours
    .reduce((acc, th) => {
      if (th) {
        acc[th.DayOfWeek] = {
          dayOfWeek: th.DayOfWeek,
          availableFrom: th.AvailableFrom,
          availableUntil: th.AvailableUntil,
        }
      }
      return acc
    }, {} )

  const autoReplyTeamHours = nullTeamHours
    .map(th => teamHoursMap[th.dayOfWeek] || th)

  return {
    autoReplyTimezone: defaultTimezone || (_time.userTZ ),
    autoReplyTeamHours,
  }
}; exports.makeDefaultValues = makeDefaultValues
