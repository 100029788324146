"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/SearchBar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _types = require('web/screens/Directory/types');
var _Form = require('web/components/Form');







const placeholders = {
  [_types.DirectoryTab.COWORKERS]: 'Search for coworkers',
  [_types.DirectoryTab.PATIENTS]: 'Search for patients',
  [_types.DirectoryTab.TEAMS]: 'Search for teams',
}

const SearchBar = ({ value, onChange, selectedTab }) => {
  const handleChange = _react.useCallback.call(void 0, e => onChange(e.target.value), [onChange])

  return (
    _react2.default.createElement('div', { className: "directory-list--search-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      , _react2.default.createElement('i', { className: "material-icons search" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "search")
      , _react2.default.createElement(_Form.TextInput, {
        onChange: handleChange,
        placeholder: placeholders[selectedTab],
        value: value,
        className: "directory-list--search-bar--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      )
      ,  value && (
        _react2.default.createElement('i', { className: "material-icons clear" , onClick: () => onChange(""), __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "clear"

        )
      )
    )
  )
}

exports. default = SearchBar
