"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/DropdownParticipantsPicker/ChipInput.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _Props = require('web/components/Form/Props');
var _EntityAvatar = require('./EntityAvatar'); var _EntityAvatar2 = _interopRequireDefault(_EntityAvatar);

/* --------------------------------
   [private] Chip Sub-Component
----------------------------------- */
class Chip extends _react.Component {
  constructor (props) {
    super(props);Chip.prototype.__init.call(this);Chip.prototype.__init2.call(this);Chip.prototype.__init3.call(this);
    this.state = {
      raised: false,
    }
  }

  __init() {this.raise = () => {
    this.setState({ raised: true })
  }}

  __init2() {this.lower = () => {
    this.setState({ raised: false })
  }}

  componentWillUnmount () {
    this.props.onUnmount()
  }

  __init3() {this.getBoundingClientRect = () => {
    if (this.myRef) {
      return this.myRef.getBoundingClientRect()
    } else {
      log.error(`failed to get dimensions of rect. Ref undefined`)
    }
  }}

  render () {
    const { entity, onClose } = this.props
    let avatar = entity.avatar
    if (entity.type === _Props.ENTITY_TYPES.NEWUSER) {
      avatar = { userKey: entity.id }
    } else if (entity.type === _Props.ENTITY_TYPES.NEWPATIENT) {
      avatar = { patientKey: entity.id }
    }

    return (
      _react2.default.createElement('div', { className: `blmh-chip${this.state.raised ? ' blmh-chip--raised' : ''}`, ref: ref => this.myRef = ref, onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        , _react2.default.createElement('div', { className: "blmh-chip--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , _react2.default.createElement(_EntityAvatar2.default, { avatar: avatar, size: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}})
        )
        , _react2.default.createElement('div', { className: "blmh-chip--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, entity.title)
        , _react2.default.createElement('div', { className: "blmh-chip--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "close")
        )
      )
    )
  }
}

Chip.propTypes = {
  entity: _Props.EntityPropType.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  onUnmount: _proptypes2.default.func,
  raise: _proptypes2.default.func,
  lower: _proptypes2.default.func,
}

/* --------------------------------
    [public] Input Component
----------------------------------- */
class ChipInput extends _react.Component {
  constructor (props) {
    super(props);ChipInput.prototype.__init4.call(this);ChipInput.prototype.__init5.call(this);ChipInput.prototype.__init6.call(this);ChipInput.prototype.__init7.call(this);ChipInput.prototype.__init8.call(this);ChipInput.prototype.__init9.call(this);ChipInput.prototype.__init10.call(this);ChipInput.prototype.__init11.call(this);ChipInput.prototype.__init12.call(this);ChipInput.prototype.__init13.call(this);
    this.state = {
      focused: false,
    }
    this.chipRefs = {}
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.chips
    && prevProps.chips
    && this.props.chips.length > prevProps.chips.length
    && this.props.onChipAdded) {
      const latestChip = this.props.chips[this.props.chips.length - 1]
      const chipRef = this.chipRefs[latestChip.id]
      this.props.onChipAdded(chipRef)
    }
  }

  __init4() {this.getChipRef = (chipId) => {
    if (!this.chipRefs[chipId]) {
      this.chipRefs[chipId] = _react2.default.createRef()
    }
    return this.chipRefs[chipId]
  }}

  __init5() {this.focus = () => {
    this.inputRef.focus()
  }}

  __init6() {this.blur = () => {
    this.inputRef.blur()
  }}

  __init7() {this.onChange = (evt) => {
    const v = evt.target.value
    this.props.onChange(this.props.inputId, v)
  }}

  __init8() {this.onChipClose = (id) => {
    if (this.props.onChipClose) {
      this.props.onChipClose(id)
    }
  }}

  __init9() {this.onKeyDown = (e) => {
    if (e.key === "Backspace" && this.props.value === "" && this.props.chips && this.props.chips.length > 0) {
      if (this.props.onChipClose) {
        const lastChip = this.props.chips[this.props.chips.length - 1]
        this.props.onChipClose(lastChip.id)
      }
    }
  }}

  __init10() {this.onFocus = (e) => {
    this.setState({
      focused: true,
    })
    if (this.props.onFocus) {
      this.props.onFocus()
    }
  }}

  __init11() {this.onBlur = (e) => {
    if (this.props.onBlur) {
      this.props.onBlur(e)
    }
    this.setState({
      focused: false,
    })
  }}

  __init12() {this.onChipUnmount = (id) => {
    delete this.chipRefs[id]
  }}

  __init13() {this.getBoundingClientRect = () => {
    if (this.myRef) {
      return this.myRef.getBoundingClientRect()
    } else {
      log.error(`failed to get dimensions of rect. Ref undefined`)
    }
  }}

  render () {
    const { chips, autoFocus, placeholder, value } = this.props
    const hasChips = chips && chips.length > 0
    const inputClasses = []
    if (hasChips) {
      inputClasses.push('hide-placeholder')
    }
    if (this.state.focused) {
      inputClasses.push('blmh-input--focus')
    }
    return (
      _react2.default.createElement('div', { className: "blmh-input " + (this.props.className ? this.props.className : ""), ref: ref => this.myRef = ref, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
        , _react2.default.createElement('div', { className: `blmh-input--container ${inputClasses.join(' ')}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
          , hasChips ? chips.map(c => (
            _react2.default.createElement(Chip, { ref: this.getChipRef(c.id),
              onUnmount: () => this.onChipUnmount(c.id),
              key: c.id,
              entity: c,
              onClose: () => this.onChipClose(c.id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 170}}))) : null
          , _react2.default.createElement('input', { type: "text", className: hasChips ? 'hide-placeholder' : '',
            autoFocus: autoFocus,
            ref: ref => this.inputRef = ref,
            onFocus: this.onFocus,
            onBlur: this.onBlur,
            onKeyDown: this.onKeyDown,
            placeholder: placeholder || '',
            onChange: this.onChange,
            value: value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}})
        )
      )
    )
  }
}

exports. default = ChipInput

ChipInput.propTypes = {
  inputId: _proptypes2.default.string.isRequired,
  onChange: _proptypes2.default.func.isRequired, /* (inputId, value) */
  value: _proptypes2.default.string.isRequired,
  placeholder: _proptypes2.default.string,

  chips: _proptypes2.default.arrayOf(_Props.EntityPropType),
  onChipClose: _proptypes2.default.func,
  onChipAdded: _proptypes2.default.func, /* (chipRef)  */

  onFocus: _proptypes2.default.func,
  onBlur: _proptypes2.default.func,
}
