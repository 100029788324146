"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Search/SearchInput.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _Form = require('web/components/Form');
var _Icon = require('web/components/Icon'); var _Icon2 = _interopRequireDefault(_Icon);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _responsive = require('web/services/responsive');

 class SearchInput extends _react.Component {
  constructor (props) {
    super(props);SearchInput.prototype.__init.call(this);SearchInput.prototype.__init2.call(this);SearchInput.prototype.__init3.call(this);SearchInput.prototype.__init4.call(this);SearchInput.prototype.__init5.call(this);SearchInput.prototype.__init6.call(this);SearchInput.prototype.__init7.call(this);SearchInput.prototype.__init8.call(this);
    this.state = {
      tooltipOpen: false,
      inputFocused: false,
    }
  }

  __init() {this.focus = () => this.inputRef && this.inputRef.focus()}

  __init2() {this.buttonPress = (evt) => {
    evt.stopPropagation()
    /* only pop the the search upsell if user doesn't have advanced search */
    this.state.tooltipOpen ? this.closeTooltip() : this.openTooltip()
  }}

  __init3() {this.openTooltip = () => this.setState({ tooltipOpen: true })}
  __init4() {this.closeTooltip = () => this.setState({ tooltipOpen: false })}
  __init5() {this.onInputFocus = () => this.setState({ inputFocused: true })}
  __init6() {this.onInputBlur = () => this.setState({ inputFocused: false })}

  __init7() {this.fourteenDayLimitSelected = () => {
    if (this.props.searchDaysLimit !== 14) {
      this.props.onSearchLimitChange(14)
      this.closeTooltip()
    }
  }}

  __init8() {this.entireHistorySelected = () => {
    const { canDoAdvancedSearch, onSearchLimitChange } = this.props
    if (!canDoAdvancedSearch) return // can't select this option, no feature

    onSearchLimitChange(0)
    this.closeTooltip()
  }}

  render () {
    const { canDoAdvancedSearch, canUpgrade, searchDaysLimit } = this.props

    const twoWeekOption = _classBuilder2.default.call(void 0, 
      'search-upsell--option',
      searchDaysLimit === 14 && 'selected',
    )

    const entireHistoryOption = _classBuilder2.default.call(void 0, 
      'search-upsell--option',
      this.props.searchDaysLimit === 0 && 'selected',
      !canDoAdvancedSearch && 'disabled',
    )

    const isMobile = window.innerWidth < _responsive.MobileWidth

    const tooltipContent = (
      _react2.default.createElement('div', { className: "search-upsell--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , _react2.default.createElement('div', { className: twoWeekOption, onClick: this.fourteenDayLimitSelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          , _react2.default.createElement('div', { className: "search-upsell--title-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "Last 2 weeks"  )
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}, "check")
        )
        , _react2.default.createElement('div', { className: entireHistoryOption, onClick: this.entireHistorySelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          , _react2.default.createElement('div', { className: "search-upsell--title-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}, "All time" )
          ,  canDoAdvancedSearch
            ? _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}, "check")
            : _react2.default.createElement('div', { className: "search-upsell--title-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, "Unavailable")
          
        )
        , !canDoAdvancedSearch && _react2.default.createElement('div', { className: "search-upsell--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
          , _react2.default.createElement('div', { className: "search-upsell--body-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}, "Upgrade your plan"  )
          , _react2.default.createElement('div', { className: "search-upsell--body-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}, "Upgrade to a paid plan to search your entire history."

          )
          ,  canUpgrade && (
            _react2.default.createElement('button', {
              className: "blmh-button purple" ,
              onClick: this.props.onRequestFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            , "Upgrade"

            )
          )
        )
      )
    )

    const focusedColor = 'purple'
    const inputContainerClasses = _classBuilder2.default.call(void 0, 
      'search-input',
      'blmh-input--container',
      'blmh-input--container-rounded',
      this.props.className,
      focusedColor, {
        [`focus-${focusedColor}`]: this.state.inputFocused,
      },
    )

    const buttonClasses = _classBuilder2.default.call(void 0, 'blmh-button', 'search-input--button', {
      'white': !this.state.tooltipOpen,
      'purple': this.state.tooltipOpen,
    })

    /* figure out popup position */
    let tooltipPos = { x: 335, y: 238 }
    if (this.buttonRef) {
      const yOffset = 5
      const rect = this.buttonRef.getBoundingClientRect()
      tooltipPos = { x: rect.left, y: rect.bottom + yOffset }
    }

    const upsellText = this.props.searchDaysLimit === 0 ? "All time" : "Last 2 weeks"

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: inputContainerClasses, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
          , _react2.default.createElement('i', { className: "material-icons search" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}, "search")
          , _react2.default.createElement('input', {
            ref: ref => this.inputRef = ref,
            autoFocus: this.props.autoFocus,
            onFocus: this.onInputFocus,
            onBlur: this.onInputBlur,
            onChange: e => this.props.onChange(this.props.inputId, e.target.value),
            placeholder: this.props.placeholder ? this.props.placeholder : "",
            className: "search-input--field",
            value: this.props.value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
          )
          , _react2.default.createElement('button', { className: buttonClasses, onClick: this.buttonPress, ref: ref => this.buttonRef = ref, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
            , _react2.default.createElement(_Icon2.default, { name: this.state.tooltipOpen ? "calendar-white" : "calendar-purple", __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}})
            , !isMobile && _react2.default.createElement('div', { className: "search-input--button-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}, upsellText)
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}, "keyboard_arrow_down")
          )
        )
        , _react2.default.createElement(_Form.Popup, {
          left: tooltipPos.x,
          top: tooltipPos.y,
          open: this.state.tooltipOpen,
          onClose: this.closeTooltip,
          position: tooltipPos,
          anchor: "topRight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
          , tooltipContent
        )
      )
    )
  }
} exports.default = SearchInput;

SearchInput.propTypes = {
  searchDaysLimit: _proptypes2.default.number.isRequired,
  onSearchLimitChange: _proptypes2.default.func.isRequired,
  inputId: _proptypes2.default.string.isRequired,
  onChange: _proptypes2.default.func.isRequired, /* (inputId, value) */
  value: _proptypes2.default.string.isRequired,
  placeholder: _proptypes2.default.string,
  className: _proptypes2.default.string,
}
