"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _store = require('web/store');











var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _entity = require('shared/services/entity');

const gotoPage = (url) => {
  if (_store.history.location.pathname !== url) _store.history.push(url)
}

 const gotoInbox = () => gotoPage(_routes2.default.INBOX.ROOT); exports.gotoInbox = gotoInbox
 const gotoLogin = () => gotoPage(_routes2.default.LOGIN.ROOT); exports.gotoLogin = gotoLogin
 const gotoEmailLogin = (email) =>
  gotoPage(_routes.makeRoute.call(void 0, _routes2.default.LOGIN.EMAIL_LOGIN, { email })); exports.gotoEmailLogin = gotoEmailLogin
 const gotoCreateNew = (createdChatType) =>
  gotoPage(_routes.makeRoute.call(void 0, _routes2.default.INBOX.NEW, { createdChatType })); exports.gotoCreateNew = gotoCreateNew

 const resumeLatestChat = exports.gotoInbox; exports.resumeLatestChat = resumeLatestChat // TODO: latest chat

const CHAT_TYPE_PREFIX = {
  REGULAR: 'c',
  PATIENT: 'pc',
  WIDGET: 'w',
}







 const buildBlobjectRoute = ({
  conversationType,
  conversationKey,
  blobjectKey,
  messageKey,
}) => {
  if (!conversationType || !blobjectKey || !conversationKey) return ''

  switch (conversationType) {
    case "chat": {
      const chatPath = _routes.makeRoute.call(void 0, _routes2.default.INBOX.CHAT, {
        blobjectKey,
        wrappedKey: conversationKey,
        wrappedTypePrefix: CHAT_TYPE_PREFIX.REGULAR,
      })

      return messageKey ? chatPath + `?messageKey=${messageKey}` : chatPath
    }
    case "patient_conversation":
    case "sms_patient_conversation": {
      const chatPath = _routes.makeRoute.call(void 0, _routes2.default.INBOX.CHAT, {
        blobjectKey,
        wrappedKey: conversationKey,
        wrappedTypePrefix: CHAT_TYPE_PREFIX.PATIENT,
      })

      return messageKey ? chatPath + `?messageKey=${messageKey}` : chatPath
    }
  }

  return ''
}; exports.buildBlobjectRoute = buildBlobjectRoute

 const makeFullURL = (route) => `${window.location.origin}/#${route}`; exports.makeFullURL = makeFullURL

 function gotoBlobject (
  conversationType,
  conversationKey,
  blobjectKey,
  messageKey,
) {
  const newPath = exports.buildBlobjectRoute.call(void 0, {
    conversationKey, conversationType, blobjectKey, messageKey,
  })

  if (newPath) gotoPage(newPath)
} exports.gotoBlobject = gotoBlobject;

 const makePatientPageRoute = (orgKey, patientKey) =>
  _routes.makeRoute.call(void 0, _routes2.default.DIRECTORY.PATIENT_PAGE, { orgKey, patientKey }); exports.makePatientPageRoute = makePatientPageRoute

 const gotoDirectory = () => gotoPage(_routes2.default.DIRECTORY.ROOT); exports.gotoDirectory = gotoDirectory

 const gotoTeamPage = (orgKey, teamKey) =>
  gotoPage(_routes.makeRoute.call(void 0, _routes2.default.DIRECTORY.TEAM_PAGE, { orgKey, teamKey })); exports.gotoTeamPage = gotoTeamPage
 const gotoPatientPage = (orgKey, patientKey) =>
  gotoPage(exports.makePatientPageRoute.call(void 0, orgKey, patientKey)); exports.gotoPatientPage = gotoPatientPage
 const gotoUserPage = (orgKey, userKey) =>
  gotoPage(_routes.makeRoute.call(void 0, _routes2.default.DIRECTORY.USER_PAGE, { orgKey, userKey })); exports.gotoUserPage = gotoUserPage

 const gotoEntityPage = (e, orgKey) => {
  if (_entity.isEntityUser.call(void 0, e)) return exports.gotoUserPage.call(void 0, orgKey, _optionalChain([e, 'access', _ => _.wrappedEntity, 'optionalAccess', _2 => _2.UserKey]))
  if (_entity.isEntityTeam.call(void 0, e)) return exports.gotoTeamPage.call(void 0, orgKey, _optionalChain([e, 'access', _3 => _3.wrappedEntity, 'optionalAccess', _4 => _4.TeamKey]))
  if (_entity.isEntityPatient.call(void 0, e)) return exports.gotoPatientPage.call(void 0, orgKey, _optionalChain([e, 'access', _5 => _5.wrappedEntity, 'optionalAccess', _6 => _6.PatientKey]))
}; exports.gotoEntityPage = gotoEntityPage

 const gotoVideoRooms = () => gotoPage(_routes.makeRoute.call(void 0, _routes2.default.VIDEO.ROOT)); exports.gotoVideoRooms = gotoVideoRooms
 const gotoCampaigns = () => gotoPage(_routes.makeRoute.call(void 0, _routes2.default.BROADCAST.ROOT)); exports.gotoCampaigns = gotoCampaigns
 const gotoCampaignPage = (campaignKey) =>
  gotoPage(_routes.makeRoute.call(void 0, _routes2.default.BROADCAST.CAMPAIGN_PAGE, { campaignKey })); exports.gotoCampaignPage = gotoCampaignPage
 const gotoSuperConsole = () => gotoPage(_routes.makeRoute.call(void 0, _routes2.default.SUPER)); exports.gotoSuperConsole = gotoSuperConsole
 const gotoSettings = () => gotoPage(_routes.makeRoute.call(void 0, _routes2.default.SETTINGS.ROOT)); exports.gotoSettings = gotoSettings
 const gotoProfileSettings = () => gotoPage(_routes.makeRoute.call(void 0, _routes2.default.SETTINGS.PROFILE)); exports.gotoProfileSettings = gotoProfileSettings

const actions = {
  resumeLatestChat: exports.resumeLatestChat,
  gotoInbox: exports.gotoInbox,
  gotoLogin: exports.gotoLogin,
  gotoEmailLogin: exports.gotoEmailLogin,
  gotoTeamPage: exports.gotoTeamPage,
  gotoPatientPage: exports.gotoPatientPage,
  gotoUserPage: exports.gotoUserPage,
  gotoBlobject,
  gotoDirectory: exports.gotoDirectory,
  gotoEntityPage: exports.gotoEntityPage,
  gotoVideoRooms: exports.gotoVideoRooms,
  gotoSuperConsole: exports.gotoSuperConsole,
  gotoCampaigns: exports.gotoCampaigns,
  gotoCampaignPage: exports.gotoCampaignPage,
  gotoSettings: exports.gotoSettings,
  gotoProfileSettings: exports.gotoProfileSettings,
  gotoCreateNew: exports.gotoCreateNew,
}

exports. default = actions
