"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');

const useToggle = (isOpenInitially = false) => {
  const [isOpen, setIsOpen] = _react.useState.call(void 0, isOpenInitially)

  const onOpen = _react.useCallback.call(void 0, () => setIsOpen(true), [])
  const onClose = _react.useCallback.call(void 0, () => setIsOpen(false), [])

  return { isOpen, onOpen, onClose }
}

exports. default = useToggle
