"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ChatHeader/renderChatDesc.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


 const renderChatDesc = (teams, label) => (
  _react2.default.createElement('div', { className: "list-item--team-line", __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
    ,  teams.map(t =>
      _react2.default.createElement('span', { className: "list-item--team", key: t.teamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}},  t.name ),
    )
    , _react2.default.createElement('span', { className: "list-item--team-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}},  label )
  )
); exports.renderChatDesc = renderChatDesc
