"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Checkbox.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const Checkbox = ({
  isChecked,
  onChange,
  className,
  checkClassName,
}) => (
  _react2.default.createElement('label', { className: _classBuilder2.default.call(void 0, "blmh-checkbox", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    , _react2.default.createElement('input', {
      type: "checkbox",
      checked: isChecked,
      onChange: () => onChange(!isChecked), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    )
    , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "blmh-checkbox--check", checkClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
  )
)

exports. default = Checkbox
