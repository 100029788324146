"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperTeamModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');

var _teamInfo = require('shared/validation/team/teamInfo');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');







const SuperTeamModal = ({ open, onClose, onSubmit }) => {
  const { register, handleSubmit, formState } = _reacthookform.useForm({
    resolver: _teamInfo.teamResolver,
    mode: 'onChange',
  })

  const { isValid, isDirty, isSubmitting } = formState

  const submit = (data, e) => {
    _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])
    return onSubmit(data.name).then(onClose)
  }

  return (
    _react2.default.createElement(_Modal2.default, {
      title: "Create a team"  ,
      isOpen: open,
      onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
    
      , _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        , _react2.default.createElement(_Form.InputSection, {
          title: "Team name" ,
          inputProps: register('name'),
          placeholder: "E.g. The Fellowship"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          isPrimarySubmit: true,
          isPrimaryLoading: isSubmitting,
          isPrimaryEnabled: isValid && isDirty && !isSubmitting,
          onSecondaryBtnClick: onClose,
          secondaryBtnLabel: "Cancel",
          primaryBtnLabel: "Create", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        )
      )
    )
  )
}

exports. default = SuperTeamModal
