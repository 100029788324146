"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');
var _lodash = require('lodash');

 const copyToClipboard = (text) => new Promise(
  (resolve, reject) => {
    const textArea = document.createElement("textarea")
    textArea.value = text
    textArea.setAttribute('style', 'position: fixed; left: -100%')
    document.body.appendChild(textArea)
    textArea.focus()
    textArea.select()

    try {
      document.execCommand('copy') ? resolve() : reject()
    } catch (e) { reject(e) }

    document.body.removeChild(textArea)
  }); exports.copyToClipboard = copyToClipboard

 const selectTextNode = (node) => new Promise(
  (resolve, reject) => {
    if (!node || !window.getSelection) reject()

    const selection = window.getSelection()
    const range = document.createRange()
    range.selectNodeContents(node)
    _optionalChain([selection, 'optionalAccess', _ => _.removeAllRanges, 'call', _2 => _2()])
    _optionalChain([selection, 'optionalAccess', _3 => _3.addRange, 'call', _4 => _4(range)])
    resolve()
  }); exports.selectTextNode = selectTextNode

 const isWindowVisible = () => (_nullishCoalesce(document.visibilityState, () => ( 'visible'))) === 'visible'; exports.isWindowVisible = isWindowVisible

 const isNativeSharingAvailable = !!window.navigator.share; exports.isNativeSharingAvailable = isNativeSharingAvailable
 const nativeShare = ({ url = '', title = '', text = '' }) => exports.isNativeSharingAvailable
  ? window.navigator.share({ url, title, text })
  : Promise.reject(); exports.nativeShare = nativeShare

 const MAX_MOBILE_SCREEN_WIDTH = 780; exports.MAX_MOBILE_SCREEN_WIDTH = MAX_MOBILE_SCREEN_WIDTH
 const isMobileScreen = () => window.innerWidth < exports.MAX_MOBILE_SCREEN_WIDTH; exports.isMobileScreen = isMobileScreen
 const useScreenType = () => {
  const { windowWidth } = exports.useWindowSize.call(void 0, )
  return windowWidth < exports.MAX_MOBILE_SCREEN_WIDTH ? 'mobile' : 'desktop'
}; exports.useScreenType = useScreenType

 const slideDown = (elem) => elem.style.height = `${elem.scrollHeight}px`; exports.slideDown = slideDown
 const slideUp = (elem) => elem.style.height = '0px'; exports.slideUp = slideUp


 const scrollElem = (elem, options = {}) => {
  if (!elem || !options.top) return

  if (elem.scroll) elem.scroll(options)
  else elem.scrollTop = options.top
}; exports.scrollElem = scrollElem

 const useWindowSize = () => {
  const [width, setWidth] = _react.useState.call(void 0, window.innerWidth)
  const [height, setHeight] = _react.useState.call(void 0, window.innerHeight)

  _react.useEffect.call(void 0, 
    () => {
      const onResize = _lodash.debounce.call(void 0, 
        () => {
          setWidth(window.innerWidth)
          setHeight(window.innerHeight)
        },
        100,
      )
      window.addEventListener('resize', onResize)
      return () => window.removeEventListener('resize', onResize)
    },
    [],
  )

  return {
    windowWidth: width,
    windowHeight: height,
  }
}; exports.useWindowSize = useWindowSize

 const useScrollLockOnContentChange = (getElem) => {
  const offset = _react.useRef.call(void 0, 0)

  const lockScroll = _react.useCallback.call(void 0, () => {
    const elem = getElem()
    if (!elem || elem.scrollTop || !elem.scrollBy) return

    offset.current = -1
    elem.scrollBy({ top: offset.current })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const unlockScroll = _react.useCallback.call(void 0, () => {
    const elem = getElem()
    if (!elem || !elem.scrollBy) return
    elem.scrollBy({ top: -offset.current })
    offset.current = 0
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return { lockScroll, unlockScroll }
}; exports.useScrollLockOnContentChange = useScrollLockOnContentChange
