"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Spinner/Spinner.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const Spinner = ({ size = "40px", color = "#6257c4", className }) => {
  const barStyle = className ? {} : { borderTopColor: color }

  return (
    _react2.default.createElement('div', {
      role: "status",
      className: _classBuilder2.default.call(void 0, "lds-ring", "blmspinner", className),
      style: { fontSize: size }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
    
      , _react2.default.createElement('div', { style: barStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
      , _react2.default.createElement('div', { style: barStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
      , _react2.default.createElement('div', { style: barStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
      , _react2.default.createElement('div', { style: barStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} )
    )
  )
}

exports. default = Spinner
