"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _qs = require('qs'); var _qs2 = _interopRequireDefault(_qs);
var _errors = require('shared/errors');






var _enum = require('./enum');
var _uuid = require('shared/services/uuid');
var _log = require('shared/log');
var _fetch = require('./fetch');
var _EventEmitter = require('shared/services/EventEmitter'); var _EventEmitter2 = _interopRequireDefault(_EventEmitter);
var _utils = require('./utils');

 class BloomClient extends _fetch.FetchCommands {
  
  
  
  
  

  
  
  
  
  

   __init() {this.connectionAttempt = 0}
   __init2() {this.unsentQueue = []}
   __init3() {this.sentQueue = []}
   __init4() {this.ws = null}
   __init5() {this.disposing = false}
   __init6() {this.connectionTimeout = null}
   __init7() {this.disconnectTimeout = null}
   __init8() {this.pingTimeout = null}

  
  
  
  __init9() {this.restFetchAlerts = _fetch.fetchAlerts}

  constructor (props) {
    super(_utils.makeRestBaseURL.call(void 0, props.connection));BloomClient.prototype.__init.call(this);BloomClient.prototype.__init2.call(this);BloomClient.prototype.__init3.call(this);BloomClient.prototype.__init4.call(this);BloomClient.prototype.__init5.call(this);BloomClient.prototype.__init6.call(this);BloomClient.prototype.__init7.call(this);BloomClient.prototype.__init8.call(this);BloomClient.prototype.__init9.call(this);BloomClient.prototype.__init10.call(this);BloomClient.prototype.__init11.call(this);BloomClient.prototype.__init12.call(this);BloomClient.prototype.__init13.call(this);BloomClient.prototype.__init14.call(this);BloomClient.prototype.__init15.call(this);BloomClient.prototype.__init16.call(this);BloomClient.prototype.__init17.call(this);BloomClient.prototype.__init18.call(this);BloomClient.prototype.__init19.call(this);BloomClient.prototype.__init20.call(this);BloomClient.prototype.__init21.call(this);BloomClient.prototype.__init22.call(this);BloomClient.prototype.__init23.call(this);BloomClient.prototype.__init24.call(this);BloomClient.prototype.__init25.call(this);BloomClient.prototype.__init26.call(this);BloomClient.prototype.__init27.call(this);BloomClient.prototype.__init28.call(this);BloomClient.prototype.__init29.call(this);BloomClient.prototype.__init30.call(this);BloomClient.prototype.__init31.call(this);BloomClient.prototype.__init32.call(this);
    const { host, port, secure, version, bundle } = props.connection
    const {
      DEFAULT_MSG_TIMEOUT_MS,
      CMD_RETRY_CONST_DELAY_MS,
      MAX_CMD_RETRIES,
      PING_INTERVAL_MS,
      PONG_TIMEOUT_MS,
    } = props.config

    this.host = host
    this.port = port
    this.secure = secure
    this.version = version
    this.bundle = bundle

    this.DEFAULT_MSG_TIMEOUT_MS = DEFAULT_MSG_TIMEOUT_MS
    this.CMD_RETRY_CONST_DELAY_MS = CMD_RETRY_CONST_DELAY_MS
    this.MAX_CMD_RETRIES = MAX_CMD_RETRIES
    this.PING_INTERVAL_MS = PING_INTERVAL_MS
    this.PONG_TIMEOUT_MS = PONG_TIMEOUT_MS

    this.connectionAttempt = 0 // Number of connection attempts before success
    this.unsentQueue = []
    this.sentQueue = []
    this.disposing = false

    const { on, off, emit } = new (0, _EventEmitter2.default)()
    this.on = on
    this.off = off
    this.emit = emit
  }

  __init10() {this.isConnected = () => _optionalChain([this, 'access', _8 => _8.ws, 'optionalAccess', _9 => _9.readyState]) === _enum.WS_STATE.OPEN}

  __init11() {this.open = (cfg = {}) => {
    // Note, client singleton may be stored in browser BFCache.
    // This can lead to messages getting resent even after a log out if queues
    // and other variables which are expected to be empty to not be.
    // Reset all queues on open to ensure BFCached local vars aren't reused.
    this.connectionAttempt = 0
    this.unsentQueue = []
    this.sentQueue = []

    this.disposing = false
    this.fetchTokenAndOpen(cfg)
  }}

  __init12() {this.close = () => {
    this.disposing = true
    this.closeWS()
  }}

   __init13() {this.closeWS = () => {
    if (this.ws) {
      // Ensure ws can be garbage collected https://www.w3.org/TR/websockets/#garbage-collection
      this.ws.onopen = null
      this.ws.onmessage = null
      this.ws.onclose = null
      this.ws.onerror = null
      this.ws.close()
      this.ws = null
    }

    this.stopPinging()
    this.handleWSClose()
  }}

   __init14() {this.makeMsgOut = (
    Command,
    Data = {},
  ) => ({ Command, Data, Echo: _uuid.uuidv4.call(void 0, ) })}

   __init15() {this.handleSend = (
    Command,
    Data,
    ttl = this.DEFAULT_MSG_TIMEOUT_MS,
    attempt = 0,
  ) => {
    const body = this.makeMsgOut(Command, Data)
    const timeoutMsg = {
      Command: body.Command,
      Echo: body.Echo,
      Status: 'timeout',
      Data: {},
    }

    return new Promise((resolve, reject) => {
      const cmdTimeout = setTimeout(() => this.processWSMessage(timeoutMsg), ttl)

      


      const callback = (err, res, attempt = 0) => {
        clearTimeout(cmdTimeout)

        if (_optionalChain([err, 'optionalAccess', _10 => _10.Status]) !== 'service_unavailable' || attempt > this.MAX_CMD_RETRIES) {
          err ? reject(err) : resolve(res)
          return
        }

        const delay = this.CMD_RETRY_CONST_DELAY_MS + _utils.makeExponentialDelay.call(void 0, attempt)

        _log.log.info(
          `${Command} failed: service unavailable.\n` +
          `Scheduling a retry (${attempt}/${this.MAX_CMD_RETRIES}) ` +
          `in ~${Math.round(delay / 1000)}s...`,
        )

        return setTimeout(
          () => this.handleSend(Command, Data, ttl, attempt)
            .then(resolve)
            .catch(reject),
          delay,
        )
      }

      this.queueSend(body, callback, attempt)
    })
  }}

  __init16() {this.send = (
    Command,
    Data,
    ttl,
  ) => this.handleSend(Command, Data, ttl)}

  __init17() {this.startPinging = () => {
    this.pingTimeout = setTimeout(this.runPing, this.PING_INTERVAL_MS)
  }}

  __init18() {this.stopPinging = () => {
    if (this.pingTimeout) clearTimeout(this.pingTimeout)
    if (this.disconnectTimeout) clearTimeout(this.disconnectTimeout)
  }}

   __init19() {this.runPing = () => {
    if (_optionalChain([this, 'access', _11 => _11.ws, 'optionalAccess', _12 => _12.readyState]) !== _enum.WS_STATE.OPEN) return

    const body = this.makeMsgOut(_enum.ServiceCommand.PING, {})
    this.ws.send(JSON.stringify(body))
    this.disconnectTimeout = setTimeout(this.closeWS, this.PONG_TIMEOUT_MS)
  }}

   __init20() {this.handlePong = () => {
    this.stopPinging()
    this.startPinging()
  }}

   __init21() {this.delayedOpenSocket = (cfg = {}) => {
    if (this.connectionTimeout || this.ws) return

    const delayMs = _utils.makeExponentialDelay.call(void 0, this.connectionAttempt)
    this.connectionTimeout =
      setTimeout(() => this.fetchTokenAndOpen(cfg), delayMs)
  }}

   __init22() {this.clearConnectTimeouts = () => {
    if (this.connectionTimeout) clearTimeout(this.connectionTimeout)
    this.connectionTimeout = null
  }}

   __init23() {this.fetchTokenAndOpen = (cfg) => {
    // We just triggered, so let's clear out the timer
    this.clearConnectTimeouts()

    if (this.ws) return _log.log.info("`fetchTokenAndOpen()` but ws already exists!")
    this.connectionAttempt += 1

    if (cfg.token || cfg.auth) return this.openWithToken(cfg)

    this.csrf()
      .then(r => this.openWithToken({ token: r.CSRF }))
      .catch(e => {
        if (e instanceof _errors.RestError && e.response.ServerError === false) {
          // Login session timed out or something else changed
          this.emit(_enum.ClientEvent.SESSION_EXPIRED)
        } else {
          // Sever error, try again
          _log.log.info('Unexpected failure getting CSRF, possibly offline')
          this.delayedOpenSocket()
        }
      })
  }}

   __init24() {this.openWithToken = ({ token: csrftoken, auth }) => {
    if (this.ws) return _log.log.info("`openWithToken()` but ws already exists!")

    try {
      const { secure, host, port, version, bundle } = this
      const protocol = secure ? 'wss' : 'ws'
      const endpoint = auth ? 'ws2' : 'ws'
      const queryPayload = auth ? { version, bundle } : { csrftoken, version, bundle }
      const query = _qs2.default.stringify(queryPayload)
      const url = `${protocol}://${host}:${port}/${endpoint}?${query}`
      const authProtocol = auth ? _utils.buildAuthProtocol.call(void 0, auth) : undefined

      this.ws = new WebSocket(url, authProtocol)
      this.ws.onopen = this.handleWSOpen.bind(this)
      this.ws.onmessage = this.handleWSMessage.bind(this)
      this.ws.onclose = this.handleWSClose.bind(this)
    } catch (err) {
      // Failure starting up websocket, restart attempt to connect
      _log.log.error(err )

      // Null out ws in case it broke before onclose bound
      this.ws = null
      this.delayedOpenSocket()
    }
  }}

   __init25() {this.handleWSOpen = () => {
    this.connectionAttempt = 0
    this.emit(_enum.ClientEvent.OPEN)

    this.startPinging()
    this.processQueue()
  }}

   __init26() {this.handleWSClose = () => {
    this.ws = null
    this.connectionAttempt = 0

    // Queue sent but not confirmed messages to be re-sent
    let item
    while ((item = this.sentQueue.shift())) {
      const [msg, callback, attempt] = item
      this.unsentQueue.unshift([msg, callback, attempt])
    }

    if (!this.disposing) {
      this.emit(_enum.ClientEvent.UNEXPECTED_CLOSED)
      this.delayedOpenSocket()
    } else this.emit(_enum.ClientEvent.CLOSED)
  }}

   __init27() {this.processWSMessage = (msg) => {
    const sentIdx = this.sentQueue.findIndex(i => i[0].Echo === msg.Echo)
    if (sentIdx > -1) {
      const [item] = this.sentQueue.splice(sentIdx, 1)
      const [, callback, attempt] = item

      if (msg.Status === 'success') callback(null, msg.Data, attempt)
      else callback({ ...msg.Data , Status: msg.Status }, null, attempt)

      this.processQueue()
    }
  }}

   __init28() {this.handleWSMessage = (event) => {
    let msg
    try {
      msg = JSON.parse(event.data) 
    } catch (e) {
      _log.log.info('Discarding a malformed event', e)
      return
    }

    switch (msg.Command) {
      case _enum.ServiceCommand.PING: return this.handlePong()
      case _enum.ServiceCommand.AUTH_ERROR: return this.emit(_enum.ClientEvent.AUTH_ERROR, msg.Data)
      case _enum.ServiceCommand.AUTH_SUCCESS: return this.emit(_enum.ClientEvent.AUTH_SUCCESS, msg.Data)
    }
    if (!msg.Echo) return this.emit(_enum.ClientEvent.INBOUND, msg)

    this.processWSMessage(msg)
  }}

   __init29() {this.queueSend = (body, callback, attempt = 0) => {
    // Queue message if present, then empty queue in order if connection is ready
    if (body) this.unsentQueue.push([body, callback, attempt])

    this.processQueue()
  }}

   __init30() {this.processQueue = () => {
    if (_optionalChain([this, 'access', _13 => _13.ws, 'optionalAccess', _14 => _14.readyState]) !== _enum.WS_STATE.OPEN) return

    let item
    while ((item = this.unsentQueue.shift())) {
      if (!item[2]) item[2] = 0
      item[2] = item[2] + 1

      const [msg] = item

      this.sentQueue.push(item)
      this.ws.send(JSON.stringify(msg))
    }
  }}

  __init31() {this.uploadFile = (file, signal) =>
    this.send('GetCsrfToken')
      .then(r => this.uploadFileWithCSRF(r.Token, file, signal))}

  __init32() {this.uploadAvatar = (file, signal) =>
    this.send('GetCsrfToken')
      .then(r => this.uploadAvatarWithCSRF(r.Token, file, signal))}
} exports.default = BloomClient;
