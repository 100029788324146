"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Org/TabsHeader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const TabItem = ({ isActive, label, onClick }) => (
  _react2.default.createElement('div', {
    role: "tab",
    'aria-selected': isActive,
    onClick: onClick,
    className: _classBuilder2.default.call(void 0, 
      "settings--org--tab-item",
      isActive && "settings--org--tab-item--active",
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
  
    ,  label 
  )
)













const TabsHeader = ({
  activeTab, currentOrgKey, numberOfMembers,
  gotoOrgInfo, gotoOrgMembers,
  gotoOrgMembership, gotoOrgSubscriptions,
}) => (
  _react2.default.createElement('div', { className: "settings--org--tabs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
    , _react2.default.createElement(TabItem, {
      isActive: activeTab === 'info',
      label: "Details",
      onClick: () => gotoOrgInfo(currentOrgKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
    )
    ,  gotoOrgMembers && (
      _react2.default.createElement(TabItem, {
        isActive: activeTab === 'members',
        label: `Members (${numberOfMembers})`,
        onClick: () => gotoOrgMembers(currentOrgKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      )
    )
    ,  gotoOrgMembership && (
      _react2.default.createElement(TabItem, {
        isActive: activeTab === 'membership',
        label: "Membership",
        onClick: () => gotoOrgMembership(currentOrgKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
      )
    )
    ,  gotoOrgSubscriptions && (
      _react2.default.createElement(TabItem, {
        isActive: activeTab === 'plan',
        label: "Plan",
        onClick: () => gotoOrgSubscriptions(currentOrgKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      )
    )
  )
)

exports. default = TabsHeader
