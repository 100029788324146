"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Feedback/Modals/LowRatingReason.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _RadioInput = require('web/components/Form/RadioInput'); var _RadioInput2 = _interopRequireDefault(_RadioInput);
var _Comment = require('./Comment'); var _Comment2 = _interopRequireDefault(_Comment);

 class LowRatingReasonModal extends _react2.default.PureComponent {constructor(...args) { super(...args); LowRatingReasonModal.prototype.__init.call(this);LowRatingReasonModal.prototype.__init2.call(this);LowRatingReasonModal.prototype.__init3.call(this);LowRatingReasonModal.prototype.__init4.call(this);LowRatingReasonModal.prototype.__init5.call(this);LowRatingReasonModal.prototype.__init6.call(this);LowRatingReasonModal.prototype.__init7.call(this); }
  __init() {this.state = {
    currentReason: this.props.lowRatingReasons[0].value,
    isLowRatingCommentModalOpen: false,
  }}

  __init2() {this.onReasonChange = (id, _, val) => this.setState({ [id]: val })}

  __init3() {this.onProceed = () => {
    const { lowRatingReasons, onClose, onReasonSubmit } = this.props
    const { currentReason } = this.state

    const { isExplanationNeeded } =
      lowRatingReasons.find(r => r.value === currentReason) || {}

    onClose()
    if (isExplanationNeeded) this.openLowRatingCommentModal()
    else onReasonSubmit(currentReason)
  }}

  __init4() {this.handleCommentSubmit = r => {
    const { onClose, onCommentSubmit } = this.props

    onClose()
    onCommentSubmit(r)
  }}

  __init5() {this.handleClose = () => {
    const { onReasonSubmit, onClose } = this.props
    onClose()
    onReasonSubmit(null)
  }}

  __init6() {this.openLowRatingCommentModal = () => this.setState({
    isLowRatingCommentModalOpen: true,
  })}

  __init7() {this.closeLowRatingCommentModal = () => this.setState({
    isLowRatingCommentModalOpen: false,
  })}

  render () {
    const { currentReason, isLowRatingCommentModalOpen } = this.state
    const { isOpen, lowRatingReasons } = this.props

    const proceedBtnClassName = _classBuilder2.default.call(void 0, 
      "meeting--btn", "meeting--feedback--low-rating-reason--proceed-btn",
    )

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Modal2.default, {
          onClose: this.handleClose,
          isOpen: isOpen && !isLowRatingCommentModalOpen,
          className: "meeting--feedback--low-rating-reason-modal",
          title: "Sorry about your call"   ,
          size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        
          , _react2.default.createElement('span', { className: "meeting--feedback--low-rating-reason--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, "What went wrong?"

          )
          , _react2.default.createElement('div', { className: "meeting--feedback--low-rating-reason--list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            , _react2.default.createElement(_RadioInput2.default, {
              className: "meeting--feedback--low-rating-reason-group",
              inputId: "currentReason",
              options: lowRatingReasons,
              onChange: this.onReasonChange,
              value: currentReason, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
            )
          )
          , _react2.default.createElement('a', {
            onClick: this.onProceed,
            className: proceedBtnClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          , "Submit"

          )
        )
        , _react2.default.createElement(_Comment2.default, {
          isOpen: isLowRatingCommentModalOpen,
          onClose: this.closeLowRatingCommentModal,
          onSubmit: this.handleCommentSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    lowRatingReasons: _proptypes2.default.array.isRequired,
    isOpen: _proptypes2.default.bool.isRequired,
    onClose: _proptypes2.default.func.isRequired,
    onReasonSubmit: _proptypes2.default.func.isRequired,
    onCommentSubmit: _proptypes2.default.func.isRequired,
  }}
} LowRatingReasonModal.__initStatic(); exports.default = LowRatingReasonModal;
