"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/DateInput/Input.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);






const Input = ({
  className, onClear, hasError, ...ps
}, ref) => (
  _react2.default.createElement('div', { className: "blm-date-picker--input-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
    , _react2.default.createElement('input', {
      readOnly: true,
      ref: ref,
      className: _classBuilder2.default.call(void 0, 
        'blm-date-picker--input',
        'blm-text-input',
        hasError && 'blm-text-input--has-error',
        className,
      ),
      ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    )
    ,  onClear && !ps.disabled && ps.value &&
      _react2.default.createElement('div', {
        role: "button",
        onClick: e => { e.preventDefault(); onClear() },
        className: "blm-date-picker--input-clear-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      )
    
  )
)

exports. default = _react2.default.forwardRef(Input)
