"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _regex = require('./regex');
/* validators.js
 *
 * various sync and async validators for use in inputs
 */

 async function exampleAsyncValidator (val) {
  await new Promise((res) => setTimeout(res, 1000))

  return null
} exports.exampleAsyncValidator = exampleAsyncValidator;

 function phoneValidator (val) {
  if (val.length < 1) {
    return "Value too short"
  }

  if (/[a-z]/i.test(val)) {
    return "Phone number cannot contain alphanumeric characters "
  }

  return null
} exports.phoneValidator = phoneValidator;

 function emailValidator (val) {
  if (val.length < val) {
    return "Value is too short"
  }

  if (!_regex.EmailRegex.test(val)) {
    return "Email invalid"
  }

  return null
} exports.emailValidator = emailValidator;
