"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _useSelector = require('web/services/hooks/useSelector'); var _useSelector2 = _interopRequireDefault(_useSelector);






var _chatParticipantQuotas = require('shared/constants/chatParticipantQuotas');
var _contact = require('shared/enum/contact');
var _uuid = require('shared/services/uuid');
var _upgrade = require('shared/enum/upgrade');
var _entity = require('shared/services/entity');
var _org = require('shared/selectors/org');
var _createNew = require('shared/hooks/createNew');
var _hooks = require('./hooks');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _SearchInput = require('./SearchInput'); var _SearchInput2 = _interopRequireDefault(_SearchInput);
var _ResultsList = require('./ResultsList'); var _ResultsList2 = _interopRequireDefault(_ResultsList);
var _InviteContactModal = require('web/screens/InviteContactModal'); var _InviteContactModal2 = _interopRequireDefault(_InviteContactModal);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);



















const ParticipantsPicker = ({
  orgKeyContext,
  excludedEntitiesMap,
  excludeOrgKey,
  selectedEntitiesList,
  onSelectEntity,
  onRemoveEntity,
  className,
  inputClassName,
  isInitiallyOpen = false,
  shouldRefocusOnPick = false,
  canInviteCoworkers = true,
  canInviteCollaborators = true,
  participantQuotas = _chatParticipantQuotas.NULL_QUOTA,
  senderSelect,
  onLookup,
}) => {
  const orgName = _useSelector2.default.call(void 0, s => _nullishCoalesce(_optionalChain([_org.selectOrgByKey.call(void 0, orgKeyContext, s), 'optionalAccess', _ => _.Name]), () => ( '')))
  const isOrgValid = orgKeyContext && orgKeyContext !== _uuid.EMPTY_BLUID
  const hasPatientMessaging =
    _useSelector2.default.call(void 0, s => _org.selectOrgHasPatientMessagingFeature.call(void 0, s, orgKeyContext))

  const {
    canAddUsers,
    canAddTeams,
    canAddPatients,
    canAddParticipants,
  } = _createNew.useParticipantQuotas.call(void 0, selectedEntitiesList, participantQuotas)

  const {
    searchQuery,
    sanitizedSearchQuery,
    setSearchQuery,
    clearSearchQuery,
    searchContactType,
    isLoadingResults,
    internalEntitiesList,
    externalEntitiesList,
    patientEntitiesList,
  } = _createNew.useSearch.call(void 0, {
    listLimit: 4,
    orgKeyContext,
    canAddUsers,
    canAddTeams,
    canAddPatients,
    excludedEntitiesMap,
    onLookup,
    excludeOrgKey,
  })

  const {
    isResultsListOpen,
    searchInputRef,
    openResultsList,
    closeResultsList,
    onEntitySelected,
  } = _hooks.useResultsListState.call(void 0, {
    searchQuery: sanitizedSearchQuery,
    clearSearchQuery,
    isInitiallyOpen,
    shouldRefocusOnPick,
    canAddParticipants,
  })

  const {
    contactRelType,
    defaultContactCredentials,
    onOpenInviteContactModal,
    onCloseInviteContactModal,
  } = _hooks.useInviteContact.call(void 0, searchQuery, searchContactType)

  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
  } = _useToggle2.default.call(void 0, )

  const handleSetContactType = _react.useCallback.call(void 0, 
    (t) =>
      t === _contact.CONTACT_REL_TYPES.PATIENT && !hasPatientMessaging
        ? onOpenUpgradeModal()
        : onOpenInviteContactModal(t),
    [hasPatientMessaging, onOpenInviteContactModal, onOpenUpgradeModal],
  )
  const handleSelectEntity = _react.useCallback.call(void 0, 
    e => {
      if (_entity.isEntityPatient.call(void 0, e) && !hasPatientMessaging) return onOpenUpgradeModal()
      onSelectEntity(e)
      onEntitySelected()
    },
    [onSelectEntity, onEntitySelected, hasPatientMessaging, onOpenUpgradeModal],
  )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "participants-picker", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}
      , _react2.default.createElement(_SearchInput2.default, {
        ref: searchInputRef,
        className: inputClassName,
        onFocus: openResultsList,
        onBlur: closeResultsList,
        searchQuery: searchQuery,
        setSearchQuery: setSearchQuery,
        selectedEntitiesList: selectedEntitiesList,
        onRemoveSelectedEntity: onRemoveEntity,
        senderSelect: senderSelect,
        canAddParticipants: canAddParticipants, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
      )
      , _react2.default.createElement(_ResultsList2.default, {
        isOpen: isResultsListOpen,
        internalEntitiesList: internalEntitiesList,
        externalEntitiesList: externalEntitiesList,
        patientEntitiesList: patientEntitiesList,
        onSelectEntity: handleSelectEntity,
        searchQuery: searchQuery,
        searchContactType: searchContactType,
        isLoadingResults: isLoadingResults,
        orgName: orgName,
        setContactRelType: handleSetContactType,
        canAddUsers: canAddUsers,
        canInviteCoworkers: canInviteCoworkers && isOrgValid,
        canInviteCollaborators: canInviteCollaborators,
        canAddPatients: canAddPatients && isOrgValid,
        searchInputRef: searchInputRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
      )
      , _react2.default.createElement(_InviteContactModal2.default, {
        isOpen: !!contactRelType,
        onClose: onCloseInviteContactModal,
        defaultPhone: defaultContactCredentials.phone,
        defaultEmail: defaultContactCredentials.email,
        defaultFirstName: defaultContactCredentials.firstName,
        defaultLastName: defaultContactCredentials.lastName,
        contactRelType: contactRelType,
        orgKeyContext: orgKeyContext,
        onEntityInvited: handleSelectEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
      )
      , _react2.default.createElement(_UpgradeModal2.default, {
        type: _upgrade.SubscriptionItemType.SMS,
        orgKey: orgKeyContext,
        isOpen: isUpgradeModalOpen,
        onClose: onCloseUpgradeModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
      )
    )
  )
}

exports. default = ParticipantsPicker
