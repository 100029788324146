"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/AnonymousPage/ActionResult.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _Form = require('web/components/Form');










const ActionResult = ({
  title,
  subtitle,
  action,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement('h2', { className: "anonymous-page--result-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}},  title )
    , _react2.default.createElement('p', { className: "anonymous-page--result-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}},  subtitle )
    ,  action && (
      'redirectUrl' in action
        ? (
            _react2.default.createElement(_reactrouterdom.Link, {
              to: action.redirectUrl,
              className: "anonymous-page--result-action", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
            
              , _react2.default.createElement(_Form.Button, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}},  action.label )
            )
          )
        : _react2.default.createElement(_Form.Button, { onClick: action.onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}},  action.label )
    )
  )
)

exports. default = ActionResult
