"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Header/ArchiveRoomModal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

const ArchiveRoomModal = ({
  isOpen, onClose, onProceed,
}) => (
  _react2.default.createElement(_Modal2.default, { isOpen: isOpen, onClose: onClose, title: "Archive Meeting" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}, "Are you sure you want to archive this video meeting?"

    )
    , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, "Anyone with the current meeting link will not be able to access it again."

    )
    , _react2.default.createElement(_Modal.ModalControls, {
      primaryBtnLabel: "Archive",
      primaryBtnTheme: "danger",
      onPrimaryBtnClick: onProceed,
      secondaryBtnLabel: "Cancel",
      onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
    )
  )
)

exports. default = ArchiveRoomModal
