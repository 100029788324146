"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Header/TeamPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _Tooltip = require('web/components/Tooltip');
var _PickerContent = require('./PickerContent'); var _PickerContent2 = _interopRequireDefault(_PickerContent);
var _Title = require('./Title'); var _Title2 = _interopRequireDefault(_Title);
var _FirstTimeTooltips = require('web/components/FirstTimeTooltips');






const TeamPicker = ({ selectedTeam, onSelectTeam }) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )

  const handleSelectTeam = _react.useCallback.call(void 0, 
    (t) => { onSelectTeam(t); onClose() },
    [onSelectTeam, onClose],
  )

  const content = (
    _react2.default.createElement(_PickerContent2.default, {
      selectedTeam: selectedTeam,
      onSelectTeam: handleSelectTeam, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    )
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      isDismissableByClickElsewhere: true,
      place: "bottom-left",
      isOpen: isOpen,
      onClose: onClose,
      content: content,
      className: "inbox-list-header--picker-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
    
      , _react2.default.createElement('div', {
        className: "inbox-list-header--picker",
        role: "button",
        onClick: onOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      
        , _react2.default.createElement(_FirstTimeTooltips.WithSwitchInboxTooltip, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          , _react2.default.createElement('div', { className: "inbox-list-header--logo--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
            , _react2.default.createElement('div', { className: "inbox-list-header--logo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
              ,  selectedTeam
                ? _react2.default.createElement('img', { src: "/assets/icons/inbox/team.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
                : _react2.default.createElement('img', { src: "/assets/icons/inbox/inbox.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
              
            )
          )
        )
        , _react2.default.createElement(_Title2.default, { teamKey: selectedTeam, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )
      )
    )
  )
}

exports. default = TeamPicker
