"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Mobile/SessionDetails.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _ParticipantDetails = require('./ParticipantDetails'); var _ParticipantDetails2 = _interopRequireDefault(_ParticipantDetails);
var _helpers = require('web/components/VideoRooms/Room/History/helpers');
var _LoadingSpinner = require('web/components/VideoRooms/Room/History/LoadingSpinner'); var _LoadingSpinner2 = _interopRequireDefault(_LoadingSpinner);

 class MobileSessionDetails extends _react2.default.Component {constructor(...args) { super(...args); MobileSessionDetails.prototype.__init.call(this); }
  __init() {this.state = {}}

  componentDidMount () {
    const { currentSessionKey: RoomSessionKey, roomKey: RoomKey } = this.props
    _actions2.default.fetchVideoRoomSession({ RoomKey, RoomSessionKey })
      .then(session => this.setState({ session }))
  }

  render () {
    const { session } = this.state

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        ,  session
          ? this.renderHistoryBody()
          : (
            _react2.default.createElement('div', { className: "meeting-room--mobile-history--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
              , _react2.default.createElement(_LoadingSpinner2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
            )
            )
        
      )
    )
  }

  renderHistoryBody () {
    const { gotoHistory, roomName } = this.props
    const { session } = this.state

    if (!session) return null
    const { startedAt, duration } = _helpers.formatTimingLabels.call(void 0, session.StartedAt, session.EndedAt)

    return (
      _react2.default.createElement('div', { className: "meeting-room--mobile-history--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , _react2.default.createElement('span', {
          onClick: gotoHistory,
          className: "meeting-room--history--header-back", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , "Back to all"

        )
        , _react2.default.createElement('div', { className: "meeting-room--mobile-history--meeting-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , _react2.default.createElement('span', { className: "meeting-room--mobile-history--meeting-desc-time", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}},  startedAt )
          , _react2.default.createElement('span', { className: "meeting-room--mobile-history--meeting-desc-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}},  roomName )
          , _react2.default.createElement('span', { className: "meeting-room--mobile-history--meeting-desc-duration", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}},  duration )
        )
        , _react2.default.createElement('div', { className: "meeting-room--mobile-history--sessions-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
          , _react2.default.createElement('h5', { className: "meeting-room--mobile-history--details-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, "Participant Details" )
          , 
            session.RoomUsers.map((u, i) => {
              const { startedAt, duration } = _helpers.formatTimingLabels.call(void 0, u.InitialJoinedAt, u.LastDisconnectedAt)
              return (
                _react2.default.createElement(_ParticipantDetails2.default, {
                  key: i,
                  participantName: u.RoomUserName,
                  startedAt: startedAt,
                  duration: duration,
                  rating: u.Rating || 0, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
                )
              )
            })
          
        )
      )
    )
  }
} exports.default = MobileSessionDetails;
