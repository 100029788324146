"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/withParticipantsLookup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactredux = require('react-redux');
var _Props = require('web/components/Form/Props');







var _helpers = require('./helpers');
var _uuid = require('shared/services/uuid');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _alertReducer = require('shared/reducers/alertReducer');
var _user = require('shared/selectors/user');
var _blobject = require('shared/selectors/blobject');
var _team = require('shared/selectors/team');
var _peers = require('shared/selectors/peers');

const SEARCH_DELAY_TIME = 500

const CreateNewChatActionTypes = {
  CREATE_CONTACT: "CREATE_CONTACT",
}

exports. default = ViewComponent => {
  class ParticipantsPicker extends _react2.default.Component {
    constructor (props) {
      super(props);ParticipantsPicker.prototype.__init.call(this);ParticipantsPicker.prototype.__init2.call(this);ParticipantsPicker.prototype.__init3.call(this);ParticipantsPicker.prototype.__init4.call(this);ParticipantsPicker.prototype.__init5.call(this);ParticipantsPicker.prototype.__init6.call(this);ParticipantsPicker.prototype.__init7.call(this);ParticipantsPicker.prototype.__init8.call(this);ParticipantsPicker.prototype.__init9.call(this);ParticipantsPicker.prototype.__init10.call(this);
      this.state = {
        searchResults: { users: [], teams: [], patients: [] },
        searching: false,
        searchCompleted: false,
      }
    }

    __init() {this.clearSearchTimeout = () =>
      this.searchTimeout && clearTimeout(this.searchTimeout)}

    __init2() {this.componentWillUnmount = this.clearSearchTimeout}

    componentDidUpdate (prevProps, prevState) {
      const { inputValue } = this.props
      const isEmpty = x => x.length === 0

      if (isEmpty(inputValue) && !isEmpty(prevProps.inputValue)) { this.clearState() } else if (!isEmpty(inputValue) && inputValue !== prevProps.inputValue) {
        this.clearSearchTimeout()
        this.searchTimeout = setTimeout(this.runSearch, SEARCH_DELAY_TIME)
      }
    }

    __init3() {this.runSearch = () => {
      const { orgKeyContext, inputValue, currentUserOrgKeys } = this.props

      const searchValue = _helpers.isThisAPhoneNumber.call(void 0, inputValue)
        ? _helpers.stripSpecialCharactersFromValue.call(void 0, inputValue)
        : inputValue
      if (searchValue.length < 2) return this.clearState()

      this.setState({
        searching: true,
        searchCompleted: false,
      })

      _actions2.default.directoryLookup({ Query: searchValue, PatientOrgKey: orgKeyContext })
        .then(res => {
          this.setState({
            searching: false,
            searchCompleted: true,
            searchResults: {
              users: _helpers.mapUserResultsToEntities.call(void 0, res, currentUserOrgKeys)
                .filter(this.isNotCurrentlySelected)
                .splice(0, 10),
              teams: _helpers.mapTeamResultsToEntities.call(void 0, res)
                .filter(this.isNotCurrentlySelected)
                .splice(0, 10),
              patients: _helpers.mapPatientResultsToEntities.call(void 0, res)
                .filter(this.isNotCurrentlySelected)
                .splice(0, 10),
            },
          })
        })
        .catch(err => {
          this.setState({ searching: false })
          log.error(err)
        })
    }}

    __init4() {this.isNotCurrentlySelected = entity => {
      const { selectedOptions, existingEntities } = this.props

      return !selectedOptions
        .concat(existingEntities)
        .find(e => e.id === entity.id)
    }}

    __init5() {this.onActionSelected = id => {
      if (!CreateNewChatActionTypes[id]) { return log.error(`Warning: Unknown action specified CreateNewChat from ChipInput: ${id}`) }

      const { inputValue } = this.props

      if (id === CreateNewChatActionTypes.CREATE_CONTACT) {
        if (!this.isCandidateValid(inputValue)) return

        this.onOptionSelected({
          avatar: { text: '?' },
          id: _uuid.uuidv4.call(void 0, ),
          title: inputValue,
          type: _Props.ENTITY_TYPES.UNKNOWN,
        })
      }
    }}

    __init6() {this.updateCandidateStatus = (candidateKey, newStatus) => {
      const {
        orgKeyContext,
        selectedOptions,
        onOptionUpdated,
        onOptionRemoved,
      } = this.props

      const affectedEntity = selectedOptions.find(e => e.id === candidateKey)
      if (!affectedEntity) return

      /* todo: replace with create user backend call */
      if (newStatus === 'user') {
        return onOptionUpdated({
          ...affectedEntity,
          type: _Props.ENTITY_TYPES.NEWUSER,
        })
      }

      if (newStatus === 'patient') {
        const phone = _helpers.isThisAPhoneNumber.call(void 0, affectedEntity.title)
          ? affectedEntity.title : ''
        const email = _helpers.isThisAnEmail.call(void 0, affectedEntity.title)
          ? affectedEntity.title : ''

        if (!phone && !email) { return log.error(`Can't create patient. Invalid email or phone number ${affectedEntity.title}`) }

        if (!orgKeyContext) { return log.error("How did we get here? User shouldn't have been able to select patient since there is no orgKeyContext") }

        _actions2.default.createPatient({ OrgKey: orgKeyContext, Email: email, Phone: phone })
          .then(res => {
            const newEntity = _helpers.mapPatientResultsToEntities.call(void 0, { Orgs: res.orgs, Patients: res.patients })[0]
            /* Remember we are using different keys here
               * (a fake id was generated for the new patient
               * before it was sent to the backend)
              */
            onOptionRemoved(affectedEntity.id)
            this.onOptionSelected(newEntity)
          })
          .catch(err => {
            _actions2.default.pushAlert("Error", err.Error, _alertReducer.AlertTypes.ERROR)
            log.error(err)
          })
      }
    }}

    __init7() {this.isPersonACandidate = person =>
      person.type === _Props.ENTITY_TYPES.UNKNOWN}

    __init8() {this.isCandidateValid = c => [_helpers.isThisAnEmail, _helpers.isThisAPhoneNumber].some(f => f(c))}

    __init9() {this.clearState = () => {
      this.setState({
        searchCompleted: false,
        searchResults: { users: [], teams: [], patients: [] },
      })
    }}

    __init10() {this.onOptionSelected = entity => {
      this.props.onOptionSelected(entity)
      this.clearState()
    }}

    render () {
      const { searchResults } = this.state

      const {
        selectedOptions, onOptionUpdated, onOptionRemoved,
        inputId, inputValue, onInputChange,
        orgKeyContext,
      } = this.props

      const shouldInviteUser = this.isCandidateValid(inputValue)
      const inviteUserActionId = shouldInviteUser
        ? CreateNewChatActionTypes.CREATE_CONTACT
        : null

      const { teams, patients, users } = searchResults

      return (
        _react2.default.createElement(ViewComponent, {
          onInputChange: onInputChange,
          inputValue: inputValue,
          inputId: inputId,
          selectedOptions: selectedOptions,
          users: users,
          teams: teams,
          patients: patients,
          inviteUserActionId: inviteUserActionId,
          orgKeyContext: orgKeyContext,
          onOptionSelected: this.onOptionSelected,
          isCandidateValid: this.isCandidateValid,
          onActionSelected: this.onActionSelected,
          isPersonACandidate: this.isPersonACandidate,
          updateCandidateStatus: this.updateCandidateStatus,
          onOptionUpdated: onOptionUpdated,
          onOptionRemoved: onOptionRemoved, __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
        )
      )
    }
  }

  ParticipantsPicker.propTypes = {
    inputId: _proptypes2.default.string.isRequired,
    onInputChange: _proptypes2.default.func.isRequired,
    inputValue: _proptypes2.default.string.isRequired,
    onOptionSelected: _proptypes2.default.func.isRequired,
    onOptionUpdated: _proptypes2.default.func.isRequired,
    onOptionRemoved: _proptypes2.default.func.isRequired,
    selectedOptions: _proptypes2.default.arrayOf(_proptypes2.default.object).isRequired, /* TODO: be more specific */
    orgKeyContext: _proptypes2.default.string, /* required if you want to search for patients */
    blobjectKeyContext: _proptypes2.default.string, /* use to ignore existing participants of a blobject when searching */
  }

  return _reactredux.connect.call(void 0, (state, props) => {
    const currentUser = _user.selectCurrentUserV2.call(void 0, state)

    const userOrgKeys = Object.values(currentUser.Relationships)
      .filter(r => !r.Pending)
      .map(r => r.OrgKey)

    let existingEntities = []
    if (props.blobjectKeyContext) {
      const blobject = _blobject.selectBlobjectByKey.call(void 0, state, props.blobjectKeyContext)

      const blobjectTeams = Object.keys(blobject.TeamSubscribers)
        .map(tk => _team.selectTeamByKey.call(void 0, state, tk))
        .filter(tk => !!tk)
      const blobjectUsers = Object.keys(blobject.UserSubscribers)
        .map(uk => _peers.selectUserV2ByKey.call(void 0, state, uk))
        .filter(uk => !!uk)

      // The getBlobjectDeep call hasn't returned yet - we'll get there eventually
      if (blobjectTeams.concat(blobjectUsers).find(x => !x)) return {}

      const userKeys = blobjectUsers.map(u => ({ id: u.UserKey }))
      const teamKeys = blobjectTeams.map(t => ({ id: t.TeamKey }))

      existingEntities = userKeys.concat(teamKeys)
    }

    return {
      currentUserOrgKeys: userOrgKeys,
      existingEntities: existingEntities,
    }
  })(ParticipantsPicker)
}
