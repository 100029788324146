"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

 const problemCodes = {
  CAMERA: 'ERROR_ORIGIN_CAMERA',
  MICROPHONE: 'ERROR_ORIGIN_MICROPHONE',
  HARDWARE: 'ERROR_ORIGIN_HARDWARE',
  PERMISSIONS: 'ERROR_ORIGIN_PERMISSIONS',
  NETWORK: 'ERROR_ORIGIN_NETWORK',
  NETWORK_RESTORED: 'NETWORK_RESTORED',
  UNKNOWN: 'ERROR_ORIGIN_UNKNOWN',
}; exports.problemCodes = problemCodes

 const formatDisplayErrorMessage = (name, problemCode, isMe) => {
  const codeLabels = {
    [exports.problemCodes.CAMERA]: 'camera',
    [exports.problemCodes.MICROPHONE]: 'microphone',
    [exports.problemCodes.PERMISSIONS]: 'camera permission',
    [exports.problemCodes.HARDWARE]: 'hardware',
    [exports.problemCodes.NETWORK]: 'network',
    [exports.problemCodes.UNKNOWN]: 'setup',
  }

  const origin = codeLabels[problemCode] || codeLabels.UNKNOWN
  const address = isMe ? "You are" : `${name || 'Someone'} is`

  return `${address} having ${origin} issues`
}; exports.formatDisplayErrorMessage = formatDisplayErrorMessage

const handleBrowserIncompatibilityError = () =>
  _actions2.default.setBrowserCompatibility(false)

const throwMediaError = (e, isMicIssue, isCamIssue) => {
  const isPermsError = ['NotAllowedError', 'SecurityError'].includes(e && e.name)

  if (e) log.error(e)
  if (isPermsError) _actions2.default.setPermsBlocked()
  if (isMicIssue) _actions2.default.setMicIssuesState(true)
  if (isCamIssue) _actions2.default.setCamIssuesState(true)

  let origin = exports.problemCodes.UNKNOWN
  if (isMicIssue && isCamIssue) origin = exports.problemCodes.HARDWARE
  else if (isMicIssue) origin = exports.problemCodes.MICROPHONE
  else if (isCamIssue) origin = exports.problemCodes.CAMERA
  if (isPermsError) origin = exports.problemCodes.PERMISSIONS

  throw new Error(`${origin} - ${e && e.name}`)
}

const forAllStreamTracks = (s, f) => s && s.getTracks().forEach(f)
const stopTracks = s => s && forAllStreamTracks(s, t => t.stop())
const removeTracks = s => s && forAllStreamTracks(s, t => s.removeTrack(t))

class MediaTrackCache {
  __init() {this.deviceId = null}
  __init2() {this.referenceCount = 0}
  __init3() {this.removalTimeout = null}
  __init4() {this.isManagedExternally = false}

  constructor (type) {;MediaTrackCache.prototype.__init.call(this);MediaTrackCache.prototype.__init2.call(this);MediaTrackCache.prototype.__init3.call(this);MediaTrackCache.prototype.__init4.call(this);MediaTrackCache.prototype.__init5.call(this);MediaTrackCache.prototype.__init6.call(this);MediaTrackCache.prototype.__init7.call(this);MediaTrackCache.prototype.__init8.call(this);MediaTrackCache.prototype.__init9.call(this); this.type = type }

  __init5() {this.update = (track, deviceId, isManagedExternally = false) => {
    if (isManagedExternally) clearTimeout(this.removalTimeout)
    this.track = track
    this.deviceId = deviceId
    this.isManagedExternally = isManagedExternally
  }}

  __init6() {this.isAvailable = deviceId => {
    try {
      const trackDeviceId = this.track.getSettings().deviceId
      return (
        this.track &&
        this.track.readyState !== 'ended' &&
        this.track.enabled && (
          deviceId === this.deviceId ||
          deviceId === trackDeviceId ||
          !deviceId && !this.deviceId ||
          !deviceId && !trackDeviceId
        )
      )
    } catch (e) { return false }
  }}

  __init7() {this.resolve = deviceId => {
    if (!this.isAvailable(deviceId)) return null
    clearTimeout(this.removalTimeout)

    this.referenceCount++
    return this.track
  }}

  __init8() {this.remove = () => {
    this.referenceCount = Math.max(this.referenceCount - 1, 0)
    if (!this.track || !this.track.enabled) return null
    if (this.referenceCount === 0) {
      this.removalTimeout = setTimeout(() =>
        !this.referenceCount &&
        !this.isManagedExternally &&
        this.kill()
      , 2000)
    }
  }}

  __init9() {this.kill = () => {
    if (!this.track) return
    this.track.stop()
    this.track = null
  }}
}

 const audioTrackCache = new MediaTrackCache('audio'); exports.audioTrackCache = audioTrackCache
 const videoTrackCache = new MediaTrackCache('video'); exports.videoTrackCache = videoTrackCache

// [deviceId]: [facingMode]
const facingModesMap = {}

 class StreamManager {
  __init10() {this._stream = null}
  __init11() {this.camDeviceId = null}
  __init12() {this.micDeviceId = null}

  constructor ({ camDeviceId, micDeviceId, useMic, useCam, permsProbing } = {}) {;StreamManager.prototype.__init10.call(this);StreamManager.prototype.__init11.call(this);StreamManager.prototype.__init12.call(this);StreamManager.prototype.__init13.call(this);StreamManager.prototype.__init14.call(this);StreamManager.prototype.__init15.call(this);
    this.camDeviceId = camDeviceId
    this.micDeviceId = micDeviceId
    this.useMic = useMic || false
    this.useCam = useCam || false
    this.permsProbing = permsProbing || false
  }

  __init13() {this.start = async () => {
    if (!navigator.mediaDevices) {
      handleBrowserIncompatibilityError()
      return null
    }

    const { micDeviceId, camDeviceId, useMic, useCam, permsProbing } = this

    const isAudioCacheAvailable = exports.audioTrackCache.isAvailable(micDeviceId)
    const isVideoCacheAvailable = exports.videoTrackCache.isAvailable(camDeviceId)

    if (
      (useMic ? isAudioCacheAvailable : true) &&
      (useCam ? isVideoCacheAvailable : true)
    ) {
      const stream = new MediaStream()

      if (useMic) stream.addTrack(exports.audioTrackCache.resolve(micDeviceId))
      if (useCam) stream.addTrack(exports.videoTrackCache.resolve(camDeviceId))

      this._stream = stream
      return stream
    }
    if (useMic && !isAudioCacheAvailable) exports.audioTrackCache.remove()
    if (useCam && !isVideoCacheAvailable) exports.videoTrackCache.remove()

    const allDevices = await navigator.mediaDevices
      .enumerateDevices()
      .catch(throwMediaError)

    const mics = allDevices.filter(x => x.kind.includes('audioinput'))
    const cams = allDevices.filter(x => x.kind.includes('videoinput'))

    const areAnyCams = cams.length > 0
    const areAnyMics = mics.length > 0

    const audio = useMic && mics.find(x => x.deviceId === micDeviceId)
      ? { deviceId: micDeviceId } : false

    let video = useCam
    if (facingModesMap[camDeviceId]) { video = { facingMode: facingModesMap[camDeviceId] } } else if (cams.find(x => x.deviceId === camDeviceId)) { video = { deviceId: camDeviceId } }

    const isMicUnavailable = useMic && !areAnyMics
    const isCamUnavailable = useCam && !areAnyCams
    if ((isMicUnavailable || isCamUnavailable) && !permsProbing) { throwMediaError(null, isMicUnavailable, isCamUnavailable) }

    this._stream = await navigator.mediaDevices.getUserMedia({ audio, video })
      .then(s => {
        exports.refreshDevicesList.call(void 0, )
        _actions2.default.setPermsGranted()
        return s
      })
      .catch(e => {
        const bail = e => throwMediaError(e, useMic && !audio, useCam && !video)

        if (useCam && areAnyCams || useMic && areAnyMics) {
          return navigator.mediaDevices.getUserMedia({
            audio: !!(useMic && areAnyMics),
            video: !!(useCam && areAnyCams),
          }).catch(bail)
        }
        bail(e)
      })
      .then(s => {
        if (s && this._isStopped) { stopTracks(s); return null }

        const stream = new MediaStream()
        const audioTrack = s.getAudioTracks()[0]
        const videoTrack = s.getVideoTracks()[0]

        if (useMic) _actions2.default.setMicIssuesState(!audioTrack)
        if (useCam) _actions2.default.setCamIssuesState(!videoTrack)

        if (audioTrack) {
          exports.audioTrackCache.update(audioTrack, micDeviceId)
          stream.addTrack(exports.audioTrackCache.resolve(micDeviceId))
        }
        if (videoTrack) {
          const trackSettings = videoTrack.getSettings()
          facingModesMap[trackSettings.deviceId] = trackSettings.facingMode
          exports.videoTrackCache.update(videoTrack, camDeviceId)
          stream.addTrack(exports.videoTrackCache.resolve(camDeviceId))
        }

        removeTracks(s)

        return stream
      })
  }}

  __init14() {this.stop = () => {
    this._isStopped = true
    if (!this._stream) return
    forAllStreamTracks(this._stream, t => {
      this._stream.removeTrack(t)
      if (t.kind === 'audio') exports.audioTrackCache.remove()
      if (t.kind === 'video') exports.videoTrackCache.remove()
    })
  }}

  __init15() {this.update = async ({
    camDeviceId = null,
    micDeviceId = null,
    isCamOn = true,
  } = {}) => {
    if (!this._stream
      || this.camDeviceId !== camDeviceId
      || this.micDeviceId !== micDeviceId
      || (!this.isCamOn && isCamOn)
    ) {
      const hasCamIdChanged =
        this.camDeviceId !== camDeviceId && (this.camDeviceId || camDeviceId)
      const wasCamToggled = isCamOn !== this.isCamOn && this.isCamOn !== undefined

      if (this._stream && hasCamIdChanged || wasCamToggled) { exports.videoTrackCache.remove() }

      this.camDeviceId = camDeviceId || this.camDeviceId
      this.micDeviceId = micDeviceId || this.micDeviceId

      await this.start()
    }

    if (this.isCamOn && !isCamOn) exports.videoTrackCache.remove()

    this.isCamOn = isCamOn

    return this._isStopped
      ? Promise.reject()
      : this._stream
  }}
} exports.StreamManager = StreamManager;

 const refreshDevicesList = async () => {
  if (!navigator.mediaDevices) { return handleBrowserIncompatibilityError() }

  const mediaDevices = await navigator
    .mediaDevices.enumerateDevices()
    .catch(() => null)

  if (!mediaDevices) return

  const audioIn = mediaDevices.filter(x => x.kind === 'audioinput')
  const videoIn = mediaDevices.filter(x => x.kind === 'videoinput')

  _actions2.default.setDevicesList({ audioIn, videoIn })
}; exports.refreshDevicesList = refreshDevicesList

 const bindDevicesListListener = () => {
  if (!navigator.mediaDevices) { return handleBrowserIncompatibilityError() }

  navigator.mediaDevices
    .addEventListener('devicechange', exports.refreshDevicesList)
}; exports.bindDevicesListListener = bindDevicesListListener

 const unbindDevicesListListener = () => {
  if (!navigator.mediaDevices) { return handleBrowserIncompatibilityError() }

  navigator.mediaDevices
    .removeEventListener('devicechange', exports.refreshDevicesList)
}; exports.unbindDevicesListListener = unbindDevicesListListener

const shouldMirrorVideoTrack = track => {
  if (!track) return false
  const settings = track.getSettings() || {}
  return settings.facingMode !== 'environment'
}

 const shouldMirrorCamFeed = feed => {
  if (!feed) return
  if (feed instanceof MediaStream) { return shouldMirrorVideoTrack(feed.getVideoTracks()[0]) }
  if (feed instanceof MediaStreamTrack) { return shouldMirrorVideoTrack(feed) }
  return false
}; exports.shouldMirrorCamFeed = shouldMirrorCamFeed

 const isScreenSharingAvailable = !!(
  window.navigator.mediaDevices &&
  window.navigator.mediaDevices.getDisplayMedia
); exports.isScreenSharingAvailable = isScreenSharingAvailable
