"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Security/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');
var _settings = require('shared/selectors/settings');
var _hooks = require('./hooks');
var _noticeBar = require('web/services/context/noticeBar');

var _PageLayout = require('web/components/Settings/PageLayout'); var _PageLayout2 = _interopRequireDefault(_PageLayout);
var _Headings = require('web/components/Settings/Headings');




var _Security = require('web/components/Settings/Security');
var _Divider = require('web/components/Settings/Divider'); var _Divider2 = _interopRequireDefault(_Divider);





const onSubmitQuickLogin = async (data) => data.isEnabled
  ? _actions2.default.activateQuickLogin({ Pin: data.pin })
  : _actions2.default.deactivateQuickLogin()

const PIN_LENGTH = 4

const SecurityPage = ({
  shouldQuickLoginBeOn,
  shouldDeviceBeApproved,
  gotoSettingsRoot,
}) => {
  const { showNoticeBar } = _noticeBar.useNoticeBar.call(void 0, )
  const displayMsg =
    _react.useCallback.call(void 0, ([type, text]) => showNoticeBar({ type, text }), [showNoticeBar])

  const { isDeviceApproved, handleToggleDeviceApproval } =
    _hooks.useQuickLoginDeviceApproval.call(void 0, { displayMsg, shouldDeviceBeApproved })

  const handleSubmitPassword = _hooks.usePassword.call(void 0, displayMsg)

  return (
    _react2.default.createElement(_PageLayout2.default, { title: "Security", onBack: gotoSettingsRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, "Password")
      , _react2.default.createElement(_Security.PasswordForm, { onSubmit: handleSubmitPassword, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
      , _react2.default.createElement(_Divider2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
      , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Set up Quick Login"   )
      , _react2.default.createElement(_Security.QuickLoginSection, {
        isQuickLoginEnabled: shouldQuickLoginBeOn,
        pinLength: PIN_LENGTH,
        onSubmit: onSubmitQuickLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      )
      , _react2.default.createElement(_Divider2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
      , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "Devices")
      , _react2.default.createElement(_Security.DeviceSection, {
        isDeviceApproved: isDeviceApproved,
        onToggleDeviceApproval: handleToggleDeviceApproval, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
      )
    )
  )
}






const mapState = (state) => {
  const me = _user.selectCurrentUser.call(void 0, state)
  const settings = _settings.selectSettings.call(void 0, state)

  return {
    shouldQuickLoginBeOn: me.QuickLogin,
    shouldDeviceBeApproved: !!settings.quickLoginUsers[me.UserKey],
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(SecurityPage)
