"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/InfoSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);






var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');
var _phone = require('shared/services/phone');
var _time = require('shared/services/time');



var _components = require('web/screens/Directory/TeamPage/components');
var _components3 = require('web/screens/Directory/components');
var _ExportPhoneLogsModal = require('web/screens/Directory/TeamPage/ExportPhoneLogsModal'); var _ExportPhoneLogsModal2 = _interopRequireDefault(_ExportPhoneLogsModal);
var _EditInfoModal = require('./EditInfoModal'); var _EditInfoModal2 = _interopRequireDefault(_EditInfoModal);












const InfoSection = ({
  teamKey,
  teamName,
  phoneNumber,
  orgKey,
  orgName,
  canEditTeam,
  canExportPhoneLogs,
  onUpdateInfo,
}) => {
  const {
    isOpen: isEditModalOpen,
    onOpen: onEdit,
    onClose: onCloseEditModal,
  } = _useToggle2.default.call(void 0, )
  const {
    isOpen: isExportPhoneLogsModalOpen,
    onOpen: onOpenExportPhoneLogsModal,
    onClose: onCloseExportPhoneLogsModal,
  } = _useToggle2.default.call(void 0, )

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  const onExportPhoneLogs = _react.useCallback.call(void 0, 
    async (FromDate, ToDate) => {
      showSuccessBar('Export has been requested')
      return _actions2.default.exportPhoneLogs({
        OrgKey: orgKey,
        FromNumber: phoneNumber || '',
        FromDate,
        ToDate,
        TimeZone: _time.userTZ ,
      })
        .then(link => window.open(link, "_self"))
        .catch(() => showWarningBar('Failed to export phone logs'))
    },
    [orgKey, phoneNumber, showWarningBar, showSuccessBar],
  )

  const handleSubmit = _react.useCallback.call(void 0, 
    (data) => onUpdateInfo(data)
      .then(onCloseEditModal)
      .then(() => showSuccessBar('Successfully updated team information'))
      .catch(() => showWarningBar('Failed to update team information')),
    [onUpdateInfo, onCloseEditModal, showWarningBar, showSuccessBar],
  )

  return (
    _react2.default.createElement(_components.GenericSection, { title: "General Information" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      , _react2.default.createElement(_components.SectionCol, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        , _react2.default.createElement(_components.InfoItem, { name: "Name", value: teamName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} )
        , _react2.default.createElement(_components.InfoItem, { name: "Organization", value: orgName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )
        ,  phoneNumber && (
          _react2.default.createElement(_components.InfoItem, { name: "Phone Number" , value: _phone.formatPhoneNumber.call(void 0, phoneNumber), __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
        )
      )
      ,  (canEditTeam || canExportPhoneLogs) && (
        _react2.default.createElement(_components.SectionControls, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
          ,  canEditTeam && (
            _react2.default.createElement(_components3.InfoBtn, {
              label: "Edit details" ,
              iconSrc: "/assets/icons/icon-edit-purple.svg",
              onClick: onEdit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
            )
          )
          ,  canExportPhoneLogs && (
            _react2.default.createElement(_components3.InfoBtn, {
              label: "Export SMS logs"  ,
              iconSrc: "/assets/icons/icon-download-purple.svg",
              onClick: onOpenExportPhoneLogsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
            )
          )
        )
      )
      , _react2.default.createElement(_EditInfoModal2.default, {
        teamKey: teamKey,
        isOpen: isEditModalOpen,
        onClose: onCloseEditModal,
        onSubmit: handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
      )
      , _react2.default.createElement(_ExportPhoneLogsModal2.default, {
        isOpen: isExportPhoneLogsModalOpen,
        onClose: onCloseExportPhoneLogsModal,
        onProceed: onExportPhoneLogs, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
      )
    )
  )
}

exports. default = InfoSection
