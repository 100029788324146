"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/components/ScheduleInfoItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





const ScheduleInfoItem = ({ title, children }) => (
  _react2.default.createElement('div', { className: "team-page--schedule-info-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    , _react2.default.createElement('h4', { className: "team-page--schedule-info-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}},  title )
    , _react2.default.createElement('div', { className: "team-page--schedule-info-item--value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}},  children )
  )
)

exports. default = ScheduleInfoItem
