"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/TeamPickerController/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');




var _event = require('shared/services/event');

var _GenericSelect = require('web/components/Form/GenericSelect');
var _TeamOption = require('./TeamOption'); var _TeamOption2 = _interopRequireDefault(_TeamOption);








const keyExtractor = (e) => e.wrappedEntity.TeamKey

const TeamPickerController = ({
  name,
  control,
  teamsList,
  teamEntitiesMap,
}) => (
  _react2.default.createElement(_reacthookform.Controller, {
    name: name,
    control: control,
    render: 
      ({ field }) => (
        _react2.default.createElement(_GenericSelect.GenericSelect, {
          keyExtractor: keyExtractor,
          selectedOption: teamEntitiesMap[field.value ],
          options: teamsList,
          OptionComponent: _TeamOption2.default,
          onChange: s => field.onChange(_event.makeChangeEvent.call(void 0, keyExtractor(s))), __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        )
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
  )
  )

exports. default = TeamPickerController
