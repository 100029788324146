"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Mobile/SessionsList.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _reactwaypoint = require('react-waypoint');
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _SessionsListSection = require('./SessionsListSection'); var _SessionsListSection2 = _interopRequireDefault(_SessionsListSection);
var _MenuPicker = require('web/components/VideoRooms/Room/MenuPicker');

 class MobileHistoryList extends _react2.default.Component {
  __init() {this.timespanOptions = [
    { value: "month", label: "Last 30 days" },
    { value: "quarter", label: "Last 3 months" },
    { value: "year", label: "Last 12 months" },
    { value: "ever", label: "All time" },
  ]}

  constructor (props) {
    super(props);MobileHistoryList.prototype.__init.call(this);MobileHistoryList.prototype.__init2.call(this);MobileHistoryList.prototype.__init3.call(this);MobileHistoryList.prototype.__init4.call(this);MobileHistoryList.prototype.__init5.call(this);MobileHistoryList.prototype.__init6.call(this);MobileHistoryList.prototype.__init7.call(this);MobileHistoryList.prototype.__init8.call(this);
    this.state = {
      todaySessions: [],
      yesterdaySessions: [],
      olderSessions: [],
      hasSessionsListDrained: false,
      isLoading: false,
      currentTimespanOption: this.timespanOptions[0].value,
      isTimespanMenuOpen: false,
    }
  }

  __init2() {this.openTimespanMenu = () => this.setState({ isTimespanMenuOpen: true })}
  __init3() {this.closeTimespanMenu = () => this.setState({ isTimespanMenuOpen: false })}

  __init4() {this.setTimespanOption = currentTimespanOption => {
    this.setState({ currentTimespanOption }, this.resetSessions)
  }}

  __init5() {this.calcTotalSessionsCount = () => {
    const { todaySessions, yesterdaySessions, olderSessions } = this.state
    return todaySessions.length + yesterdaySessions.length + olderSessions.length
  }}

  __init6() {this.resetSessions = () =>
    this.setState({
      todaySessions: [], yesterdaySessions: [], olderSessions: [],
    }, this.loadSessions)}

  __init7() {this.loadSessions = async () => {
    const { roomKey: RoomKey, loadSessionsList } = this.props
    const {
      todaySessions, yesterdaySessions, olderSessions,
      isLoading, currentTimespanOption,
    } = this.state

    if (isLoading) return
    const totalSessionsCount = this.calcTotalSessionsCount()

    await new Promise(r => this.setState({ isLoading: true }, r))

    const timespanLimits = {
      'month': _moment2.default.call(void 0, ).subtract(30, 'days').format(),
      'quarter': _moment2.default.call(void 0, ).subtract(3, 'months').format(),
      'year': _moment2.default.call(void 0, ).subtract(12, 'months').format(),
    }

    const StartedAfter = timespanLimits[currentTimespanOption] || null

    const todayMoment = _moment2.default.call(void 0, ).startOf('day').format()
    const yesterdayMoment = _moment2.default.call(void 0, ).subtract(1, 'days').startOf('day').format()

    loadSessionsList({
      RoomKey,
      Skip: totalSessionsCount,
      Take: 10,
      StartedAfter,
    }).then(({ RoomSessions } = {}) => {
      if (!RoomSessions) return
      const [
        newTodaySessions, newYesterdaySessions, newOlderSessions,
      ] = RoomSessions.reduce((acc, s) => {
        if (new Date(s.StartedAt) > new Date(todayMoment)) { return [acc[0].concat(s), acc[1], acc[2]] }
        if (new Date(s.StartedAt) > new Date(yesterdayMoment)) { return [acc[0], acc[1].concat(s), acc[2]] }
        return [acc[0], acc[1], acc[2].concat(s)]
      }, [[], [], []])

      this.setState({
        todaySessions: todaySessions.concat(newTodaySessions),
        yesterdaySessions: yesterdaySessions.concat(newYesterdaySessions),
        olderSessions: olderSessions.concat(newOlderSessions),
        hasSessionsListDrained: RoomSessions.length === 0,
        isLoading: false,
      })
    })
  }}

  __init8() {this.componentDidMount = () => this.loadSessions()}

  render () {
    const { onSessionPick } = this.props
    const {
      isLoading,
      currentTimespanOption,
      isTimespanMenuOpen,
      todaySessions, yesterdaySessions, olderSessions,
      hasSessionsListDrained,
    } = this.state

    const currentTimespanOptionLabel = (this.timespanOptions.find(o =>
      o.value === currentTimespanOption,
    ) || {}).label

    const totalSessionsCount = this.calcTotalSessionsCount()

    const shouldRenderWaypoint = totalSessionsCount > 0
      && !hasSessionsListDrained

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "meeting-room--mobile-history--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}
          , _react2.default.createElement('span', {
            onClick: this.openTimespanMenu,
            className: "meeting-room--mobile-history--headerbar-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
          
            ,  currentTimespanOptionLabel 
          )
          , _react2.default.createElement(_SessionsListSection2.default, {
            title: "Today",
            sessionsList: todaySessions,
            onSessionPick: onSessionPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
          )
          , _react2.default.createElement(_SessionsListSection2.default, {
            title: "Yesterday",
            sessionsList: yesterdaySessions,
            onSessionPick: onSessionPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          )
          , _react2.default.createElement(_SessionsListSection2.default, {
            title: "Older",
            sessionsList: olderSessions,
            onSessionPick: onSessionPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
          )
          ,  shouldRenderWaypoint &&
            _react2.default.createElement(_reactwaypoint.Waypoint, { key: totalSessionsCount, onEnter: this.loadSessions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}} )
          
          ,  isLoading
            ? (
              _react2.default.createElement('div', { className: "meeting-room--mobile-history--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
                , _react2.default.createElement(_Spinner2.default, { color: "#6257c4", __self: this, __source: {fileName: _jsxFileName, lineNumber: 147}} )
              )
              )
            : (
              _react2.default.createElement('span', { className: "meeting-room--mobile-history--meetings-count", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
                ,  totalSessionsCount === 1
                  ? '1 session'
                  : `${totalSessionsCount} sessions`
                
              )
              )
          
          , _react2.default.createElement(_MenuPicker.MenuSelect, {
            title: "View history" ,
            isOpen: isTimespanMenuOpen,
            options: this.timespanOptions,
            onChange: this.setTimespanOption,
            onClose: this.closeTimespanMenu,
            value: currentTimespanOption, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}
          )
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    onClose: _proptypes2.default.func.isRequired,
    loadSessionsList: _proptypes2.default.func.isRequired,
  }}
} MobileHistoryList.__initStatic(); exports.default = MobileHistoryList;
