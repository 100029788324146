"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/CampaignForm/Form/SenderPickerController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _reacthookform = require('react-hook-form');





var _event = require('shared/services/event');
var _SenderPicker = require('./SenderPicker'); var _SenderPicker2 = _interopRequireDefault(_SenderPicker);







const SenderPickerController = ({
  name,
  teamKeyName,
  control,
  resetField,
  teamEntitiesMap,
}) => {
  const teamKey = _reacthookform.useWatch.call(void 0, { control, name: teamKeyName }) 

  const teamEntity = teamEntitiesMap[teamKey]

  _react.useEffect.call(void 0, 
    () => { resetField(name, { defaultValue: '' }) },
    [resetField, name, teamKey],
  )

  if (!teamEntity) return null
  return (
    _react2.default.createElement(_reacthookform.Controller, {
      name: name,
      control: control,
      render: 
        ({ field }) => (
          _react2.default.createElement(_SenderPicker2.default, {
            senderKey: field.value ,
            onSelectSender: k => field.onChange(_event.makeChangeEvent.call(void 0, k)),
            teamKey: teamKey,
            orgKey: teamEntity.wrappedEntity.OrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
          )
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
    )
  )
}

exports. default = SenderPickerController
