"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembershipTab/ConfirmLeaveModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _useStatefulPromise = require('shared/hooks/useStatefulPromise');
var _noticeBar = require('web/services/context/noticeBar');




var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);








const ConfirmLeaveModal = ({
  isOpen,
  onClose,
  orgName,
  onProceed,
}) => {
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleLeave = _react.useCallback.call(void 0, 
    () => onProceed()
      .then(onClose)
      .then(() => showSuccessBar(`Successfully left ${orgName}`))
      .catch(() => showWarningBar(`Failed to leave ${orgName}. Please, try again`)),
    [onProceed, onClose, showSuccessBar, showWarningBar, orgName],
  )

  const { run, isLoading } = _useStatefulPromise.useStatefulPromise.call(void 0, handleLeave)

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: `Leave ${orgName}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
    
      , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, "Are you sure you want to leave "
               , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 42}},  orgName ), "?"
      )
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}, "You will permanently lose access to all chats you participated in on behalf of "
                      , orgName, "."
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Leave",
        primaryBtnTheme: "danger",
        isPrimaryLoading: isLoading,
        onPrimaryBtnClick: run,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      )
    )
  )
}

exports. default = ConfirmLeaveModal
