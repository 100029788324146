"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/DateInput/YearMonthForm.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


const currentYear = new Date().getFullYear()

const fromMonth = new Date(currentYear - 100, 11)
const toMonth = new Date(currentYear, 0)

const fromYear = fromMonth.getFullYear()
const toYear = toMonth.getFullYear()

const years = []
for (let i = fromYear; i <= toYear; i++) years.push(i)









const YearMonthForm = ({
  date,
  localeUtils,
  onChange,
  onPrevMonth,
  onNextMonth,
}) => {
  const months = localeUtils.getMonths()

  const selectedMonth = date.getMonth()
  const selectedYear = date.getFullYear()

  const handleChangeMonth =
    e => onChange(new Date(selectedYear, +e.target.value))
  const handleChangeYear =
    e => onChange(new Date(+e.target.value, selectedMonth))

  return (
    _react2.default.createElement('div', { className: "DayPicker-Caption", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      , _react2.default.createElement('div', { className: "blm-date-picker--caption", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , _react2.default.createElement('select', {
          name: "month",
          className: "blm-date-picker--month-select",
          onChange: handleChangeMonth,
          value: selectedMonth, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        
          ,  months.map((m, i) => _react2.default.createElement('option', { key: m, value: i, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}},  m )) 
        )
        , _react2.default.createElement('select', {
          name: "year",
          className: "blm-date-picker--year-select",
          onChange: handleChangeYear,
          value: selectedYear, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        
          ,  years.map(y => (_react2.default.createElement('option', { key: y, value: y, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}},  y ))) 
        )
        , _react2.default.createElement('div', { className: "blm-date-picker--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          , _react2.default.createElement('div', {
            role: "button",
            onClick: onPrevMonth,
            className: "blm-date-picker--prev-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
          )
          , _react2.default.createElement('div', {
            role: "button",
            onClick: onNextMonth,
            className: "blm-date-picker--next-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
          )
        )
      )
    )
  )
}

exports. default = YearMonthForm
