"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/EnterEmail/PatientWarning.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _components = require('../components');

const PatientWarning = () => (
  _react2.default.createElement(_components.SignupStepHeader, {
    iconType: "error",
    title: "You're in the wrong place"    ,
    subtitle: 
      _react2.default.createElement(_react2.default.Fragment, null, "BloomText signup is only for healthcare professionals."
              , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 10}} ), "Contact your provider for instructions on how to set up communication."

      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
  )
)

exports. default = PatientWarning
