"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');
var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);





const emailSchema =
  _joi4.default.object({ email: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Email')).contactEmail() })
const phoneSchema =
  _joi4.default.object({ phone: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Phone')).contactPhone() })
const phoneVerificationSchema =
  _joi4.default.object({
    verificationCode: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Verification code')).length(8).pattern(/^[0-9]+$/, 'digits'),
  })

 const emailResolver = _joi.joiResolver.call(void 0, emailSchema); exports.emailResolver = emailResolver
 const phoneResolver = _joi.joiResolver.call(void 0, phoneSchema); exports.phoneResolver = phoneResolver
 const phoneVerificationResolver = _joi.joiResolver.call(void 0, phoneVerificationSchema); exports.phoneVerificationResolver = phoneVerificationResolver
