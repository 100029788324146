"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/SetupGroups/Audio.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _videoCallSoundEffects = require('web/services/videoCallSoundEffects');

 class AudioSetupGroup extends _react2.default.Component {constructor(...args) { super(...args); AudioSetupGroup.prototype.__init.call(this); }
  __init() {this.handleMicChange = e => this.props.setCurrentMicDeviceId(e.target.value)}

  render () {
    const {
      audioStream,
      audioInDevices,
      currentMicDeviceId,
    } = this.props

    const areAnyDevicesPresent = audioInDevices && audioInDevices.length

    return (
      _react2.default.createElement('div', { className: "meeting--setup--group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , _react2.default.createElement('div', { className: "meeting--setup--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          , _react2.default.createElement('h3', { className: "meeting--setup--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Microphone")
          , _react2.default.createElement('div', { className: "meeting--setup--section-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
            , _react2.default.createElement('select', {
              value: currentMicDeviceId,
              onChange: this.handleMicChange,
              className: "meeting--setup--select",
              disabled: !areAnyDevicesPresent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            
              ,  areAnyDevicesPresent
                ? audioInDevices.map(({ deviceId, label }, i) => (
                    _react2.default.createElement('option', {
                      key: deviceId,
                      value: deviceId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
                    
                      ,  label || `Mic #${i + 1}` 
                    )
                ))
                : _react2.default.createElement('option', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, "N/A")
              
            )
            ,  audioStream &&
              _react2.default.createElement('div', { className: "meeting--setup--audio--mic-check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}} )
            
          )
          ,  !audioStream && (
            _react2.default.createElement('div', { className: "meeting--setup--issues-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
              , _react2.default.createElement('span', { className: "meeting--issue--warn-sign", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, "No audio detected."  )
            )
          ) 
        )

        , _react2.default.createElement('div', { className: "meeting--setup--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
          , _react2.default.createElement('h3', { className: "meeting--setup--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Speakers")
          , _react2.default.createElement('div', {
            onClick: _videoCallSoundEffects.playSoundCheck,
            className: "meeting--setup-audio--sound-test-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
          
            , _react2.default.createElement('img', { src: "/assets/icons/video/speaker-purple.svg", alt: "🔊", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
            , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "Test audio" )
          )
          , _react2.default.createElement('span', { className: "meeting--setup-audio--sound-test-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "You can change your audio output settings in your system preferences."

          )
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    currentMicDeviceId: _proptypes2.default.string.isRequired,
    areMicIssuesPresent: _proptypes2.default.bool.isRequired,
    setCurrentMicDeviceId: _proptypes2.default.func.isRequired,
    audioInDevices: _proptypes2.default.array.isRequired,
    audioStream: _proptypes2.default.any,
  }}
} AudioSetupGroup.__initStatic(); exports.default = AudioSetupGroup;
