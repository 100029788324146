"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Mobile/SessionsListSection.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _helpers = require('web/components/VideoRooms/Room/History/helpers');

const SessionsListSection = ({ title, sessionsList, onSessionPick }) => !sessionsList.length
  ? null
  : (
    _react2.default.createElement('div', { className: "meeting-room--mobile-history--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
      , _react2.default.createElement('h5', { className: "meeting-room--mobile-history--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, title)
      , 
        sessionsList.map(s => {
          const { startedAt, duration } = _helpers.formatTimingLabels.call(void 0, s.StartedAt, s.EndedAt)
          const roomUsersCount = s.RoomUsers && s.RoomUsers.length || 0
          const roomUsersLabel = roomUsersCount === 1
            ? '1 participant'
            : `${roomUsersCount} participants`

          return (
            _react2.default.createElement('div', {
              key: s.RoomSessionKey,
              onClick: () => onSessionPick(s.RoomSessionKey),
              className: "meeting-room--mobile-history--section-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
            
              , _react2.default.createElement('div', { className: "meeting-room--mobile-history--item-main-col", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
                , _react2.default.createElement('span', { className: "meeting-room--mobile-history--meeting-participants", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
                  ,  roomUsersLabel 
                )
                , _react2.default.createElement('span', { className: "meeting-room--mobile-history--meeting-duration", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
                  ,  duration 
                )
              )
              , _react2.default.createElement('div', { className: "meeting-room--mobile-history--item-desc-col", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
                , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}},  startedAt )
              )
              , _react2.default.createElement('div', { className: "meeting-room--mobile-history--item-more-col", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
                , _react2.default.createElement('img', { src: "/assets/icons/video/forward-grey.svg", alt: ">", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
              )
            )
          )
        })
      
    )
    )

SessionsListSection.propTypes = {
  title: _proptypes2.default.string.isRequired,
  sessionsList: _proptypes2.default.array.isRequired,
  onSessionPick: _proptypes2.default.func.isRequired,
}

exports. default = SessionsListSection
