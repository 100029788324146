"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperUserScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _Name = require('web/components/Name'); var _Name2 = _interopRequireDefault(_Name);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _alertReducer = require('shared/reducers/alertReducer');
var _SuperTableTR = require('web/components/Super/SuperTableTR'); var _SuperTableTR2 = _interopRequireDefault(_SuperTableTR);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _SuperUserTeamsTable = require('web/components/Super/SuperUserTeamsTable'); var _SuperUserTeamsTable2 = _interopRequireDefault(_SuperUserTeamsTable);

const RelationshipRow = (props) => {
  return (
    _react2.default.createElement(_SuperTableTR2.default, { to: `/super/orgs/${props.org.OrgKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, props.org.OrgKey)
      , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, props.org.Name)
      , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, props.relationship.Title)
      , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, props.relationship.Type)
      , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}, props.relationship.Pending ? 'yes' : 'no')
    )
  )
}

 class SuperUserScreen extends _react.Component {
  constructor (props) {
    super(props);SuperUserScreen.prototype.__init.call(this);SuperUserScreen.prototype.__init2.call(this);SuperUserScreen.prototype.__init3.call(this);SuperUserScreen.prototype.__init4.call(this);
    this.state = {
      deleteModalOpen: false,
    }
  }

  componentDidMount () {
    this.reload()
  }

  __init() {this.reload = () => {
    _client2.default.send('SuperUserProfile', { UserKey: this.props.match.params.userKey })
      .then((results) => {
        this.setState({
          user: results.Users[this.props.match.params.userKey],
          orgs: results.Orgs,
        })
      }).catch(log.error)

    // Use this newer command to get teams for non-deleted users
    _client2.default.send('SUPER_GetUsers_V5', { UserKeys: [this.props.match.params.userKey] }).then((r) => {
      if (r.Users) {
        this.setState({
          v5User: r.Users[this.props.match.params.userKey],
        })
      }
    }).catch(log.error)
  }}

  __init2() {this.deleteUser = (userKey) => {
    _client2.default.send('SuperDeleteUser', { UserKey: userKey })
      .then((results) => {
        _actions2.default.pushAlert("Success", "Successfully deleted user", _alertReducer.AlertTypes.SUCCESS)
        this.setState({ deleteModalOpen: false })
        this.reload()
      }).catch(log.error)
  }}

  __init3() {this.openDeleteUser = () => {
    this.setState({ deleteModalOpen: true })
  }}

  __init4() {this.closeDeleteUser = () => {
    this.setState({ deleteModalOpen: false })
  }}

  render () {
    if (this.state.user) {
      const relationships = Object.values(this.state.user.Relationships || {}).map((rel) => _react2.default.createElement(RelationshipRow, { relationship: rel, org: this.state.orgs[rel.OrgKey] || {}, key: rel.OrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}} ))

      let phones = ""
      let emails = ""

      if (this.state.user.Phones) {
        phones = Object.keys(this.state.user.Phones).reduce((acc, phone, i) => {
          if (i === 0) {
            return phone
          }
          return phone + ", " + acc
        }, "")
      }

      if (this.state.user.Emails) {
        emails = Object.keys(this.state.user.Emails).reduce((acc, email, i) => {
          if (i === 0) {
            return email
          }
          return email + ", " + acc
        }, "")
      }
      return (
        _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}

          , _react2.default.createElement(_Modal2.default, {
            isOpen: this.state.deleteModalOpen,
            onClose: this.closeDeleteUser,
            title: "Delete user" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
          
            , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}, "Are you sure you want to mark this user as deleted?"
                        , ' ', "They will be removed from any organizations they belong to."

            )
            , _react2.default.createElement(_Modal.ModalControls, {
              secondaryBtnLabel: "Cancel",
              onSecondaryBtnClick: this.closeDeleteUser,
              primaryBtnTheme: "danger",
              primaryBtnLabel: "Delete",
              onPrimaryBtnClick: () => this.deleteUser(this.state.user.UserKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
            )
          )

          , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
            , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}, "Properties")
            , _react2.default.createElement('dl', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
              , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}, "User Key" )
              , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, this.state.user.UserKey))
              , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}, "Name")
              , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, _react2.default.createElement(_Name2.default, { user: this.state.user, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}} ))
              ,  emails ? _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}, "Email Addresses" ) : null 
              ,  emails ? _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, emails) : null 
              ,  phones ? _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}, "Phone Numbers" ) : null 
              ,  phones ? _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, phones) : null
              , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}, "Activated")
              , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}, this.state.user.Activated ? 'yes' : 'no')
              , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}, "Deleted")
              , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}, this.state.user.Deleted ? 'yes' : 'no')
              ,  this.state.user.InvitedByOrgAdminKey ? _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}, "Invited By" ) : null 
              ,  this.state.user.InvitedByOrgAdminKey ? _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}, this.state.user.InvitedByOrgAdminKey)) : null 
            )
            , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
              , _react2.default.createElement('button', { onClick: () => this.openDeleteUser(), disabled: !!this.state.user.Deleted, className: "blmh-button red" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}, "Delete")
            )
          )

          , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
            , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}, "Organizations")
            , _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}
              , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
                , _react2.default.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
                  , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}, "Org Key" )
                  , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}, "Name")
                  , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}, "Title")
                  , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}, "Role")
                  , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 147}}, "Pending")
                )
              )
              , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
                , relationships
              )
            )
          )

          , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}
            , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}, "Teams")
            ,  this.state.v5User ? _react2.default.createElement(_SuperUserTeamsTable2.default, { teams: this.state.v5User.Teams, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}} ) : _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}, "None") 
          )

        )
      )
    } else {
      return null
    }
  }
} exports.default = SuperUserScreen;
