"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/shared/components/Linkify.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _linkifyit = require('linkify-it'); var _linkifyit2 = _interopRequireDefault(_linkifyit);

var _regex = require('shared/services/regex');
var _app = require('shared/constants/app');

const linkify = new (0, _linkifyit2.default)()
linkify.add(`${_app.appSchema}:`, 'https:')

// Need to inject useMemo as a dependency because React is unhappy
// about using different versions of react and react-dom for
// rendering Hook'ed components.

const Linkify = ({
  useMemo = _react.useMemo,
  text,
  LinkComponent,
  TextComponent,
}) => {
  const content = useMemo(() => {
    if (!text || typeof text !== 'string') return null
    const res = []
    const linkMatches = linkify.match(text) || []

    let match
    while ((match = _regex.FullTextUSPhoneRegex.exec(text)) != null) {
      linkMatches.push({
        index: match.index,
        text: match[0],
        type: 'phone',
        url: 'tel://' + match[0],
      })
    }
    linkMatches.sort((left, right) => left.index - right.index)

    let textString = text
    for (let i = 0; i < linkMatches.length; i++) {
      const match = linkMatches[i]
      const beforeText = textString.substring(0, match.index)
      res.push(
        _react2.default.createElement(TextComponent, { key: `before-${i}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          ,  beforeText 
        ),
      )
      res.push(
        _react2.default.createElement(LinkComponent, {
          key: `match-${i}`,
          url: match.url,
          type: match.type || 'url', __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        
          ,  match.text 
        ),
      )

      const afterTextStartIndex = beforeText.length + match.text.length
      if (i === linkMatches.length - 1) {
        res.push(
          _react2.default.createElement(TextComponent, { key: `after-${i}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
            ,  textString.substring(afterTextStartIndex) 
          ),
        )
      }

      textString = textString.substring(afterTextStartIndex)

      for (let j = linkMatches.length - 1; j > i; j--) {
        const matchToAdjust = linkMatches[j]
        matchToAdjust.index = matchToAdjust.index -
          (beforeText.length + match.text.length)
      }
    }

    if (!res.length) res.push(text)

    return res
  }, [text])

  return _react2.default.createElement(_react2.default.Fragment, null,  content )
}

exports. default = Linkify
