"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/InfoTab/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

var _Org = require('web/components/Settings/Org');
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);






const OrgInfoTab = ({ orgKey, isEditable }) => {
  const onSubmit = _react.useCallback.call(void 0, 
    (d) =>
      _actions2.default.updateOrgInfo({
        OrgKey: orgKey,
        Name: d.name,
        Address: d.address,
        Email: d.email,
        Phone: d.phone,
      }),
    [orgKey],
  )

  return (
    _react2.default.createElement(_Org.TabWrapper, { classNameSuffix: "info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , _react2.default.createElement(_Form2.default, {
        orgKey: orgKey,
        onSubmit: onSubmit,
        isEditable: isEditable, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      )
    )
  )
}

exports. default = OrgInfoTab
