"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Sidebar/BroadcastListHeader/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _TeamPicker = require('./TeamPicker'); var _TeamPicker2 = _interopRequireDefault(_TeamPicker);
var _Modals = require('web/screens/Broadcast/Modals');


var _List = require('web/screens/App/List');









const BroadcastListHeader = ({
  selectedTeamKey,
  onSelectTeam,
  onCreateNewCampaign,
  doIHaveAnyPhoneTeams,
  hasFeature,
}) => {
  const {
    isOpen: isCreateNewModalOpen,
    onOpen: onOpenCreateNewModal,
    onClose: onCloseCreateNewModal,
  } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_List.ListHeaderWrapper, { className: "broadcast-list--header-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      , _react2.default.createElement(_List.ListHeaderWithAddButton, {
        role: "menu",
        className: "broadcast-list--header",
        isAddDisabled: !doIHaveAnyPhoneTeams || !hasFeature,
        onAddPress: onOpenCreateNewModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      
        , _react2.default.createElement(_TeamPicker2.default, {
          selectedTeamKey: selectedTeamKey,
          onSelectTeam: onSelectTeam,
          doIHaveAnyPhoneTeams: doIHaveAnyPhoneTeams, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
      )
      , _react2.default.createElement(_Modals.CampaignFormModal, {
        title: "Create a campaign"  ,
        submitLabel: "Create",
        isOpen: isCreateNewModalOpen,
        onClose: onCloseCreateNewModal,
        onSubmit: onCreateNewCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      )
    )
  )
}

exports. default = BroadcastListHeader
