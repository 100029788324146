"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/DropdownParticipantsPicker/SuggestionBox.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _EntityAvatar = require('./EntityAvatar'); var _EntityAvatar2 = _interopRequireDefault(_EntityAvatar);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

/* --------------------------------
   [private] SelectionBox Sub-Component
----------------------------------- */
const SelectionBoxItem = ({ entity, onOptionSelected }) => {
  return (
    _react2.default.createElement('div', { className: "suggestion-box--item", onClick: () => onOptionSelected(entity), __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , _react2.default.createElement('div', { className: "suggestion-box--item-leftside", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , _react2.default.createElement(_EntityAvatar2.default, {
          entityType: entity.type,
          avatar: entity.avatar,
          size: 36, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
          )
      )
      , _react2.default.createElement('div', { className: "suggestion-box--item-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , _react2.default.createElement('div', { className: "suggestion-box--item-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}, entity.title)
        , _react2.default.createElement('div', { className: "suggestion-box--item-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}, entity.subtitle)
      )
      , entity && entity.icon ? (_react2.default.createElement('div', { className: "suggestion-box--item-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "check")
      )) : null
    )
  )
}

/* --------------------------------
   [private] SelectionBox action
----------------------------------- */
const SelectionBoxAction = ({ text, onClick }) => {
  return (
    _react2.default.createElement('div', { className: "suggestion-box--item", onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      , _react2.default.createElement('div', { className: "suggestion-box--item-leftside", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        , _react2.default.createElement('div', { className: "suggestion-box--action", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          , _react2.default.createElement('img', { src: "/assets/icons/icon-add-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}})
        )
      )
      , _react2.default.createElement('div', { className: "suggestion-box--item-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        , _react2.default.createElement('div', { className: "suggestion-box--item-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, text)
      )
    )
  )
}

/* --------------------------------
    [public] SuggestionBox Component
----------------------------------- */

const defaultTabType = "ALL"
class SuggestionBox extends _react.Component {
  constructor (props) {
    super(props);SuggestionBox.prototype.__init.call(this);SuggestionBox.prototype.__init2.call(this);SuggestionBox.prototype.__init3.call(this);SuggestionBox.prototype.__init4.call(this);SuggestionBox.prototype.__init5.call(this);SuggestionBox.prototype.__init6.call(this);SuggestionBox.prototype.__init7.call(this);
    this.state = {
      selectedType: defaultTabType,
    }
  }

  componentDidMount () {
    document.addEventListener('click', this.handleWindowClick)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleWindowClick)
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.open && !this.props.open) {
      this.setState({ selectedType: defaultTabType })
    }
  }

  __init() {this.handleWindowClick = (evt) => {
    if (!this.props.onClickedOutsideBox) {
      return
    }

    if (!this.boxRef) {
      return
    }

    if (this.boxRef.contains(evt.target)) {
      return
    }

    this.props.onClickedOutsideBox(evt)
  }}

  __init2() {this.onOptionSelected = (entity) => {
    this.props.onOptionSelected(entity)
  }}

  __init3() {this.preventClose = (evt) => {
    evt.stopPropagation()
  }}

  __init4() {this.mouseEnter = (evt) => {
    if (this.props.onMouseEnter) {
      this.props.onMouseEnter()
    }
  }}

  __init5() {this.mouseLeave = (evt) => {
    if (this.props.onMouseLeave) {
      this.props.onMouseLeave()
    }
  }}

  __init6() {this.onActionSelected = (id) => {
    this.props.onActionSelected(id)
  }}

  __init7() {this.tabSelect = (tabId) => {
    this.setState({ selectedType: tabId })
  }}

  render () {
    const { selectedType } = this.state

    const selectedOption = this.props.options[selectedType]
    if (!selectedOption) {
      return null
    }

    const style = {
      ...this.props.style,
    }

    /* We use this class to induce a "display: none;" and maintain the prop */
    const classes = ["suggestion-box"]
    if (!this.props.open) {
      classes.push("suggestion-box--closed")
    }

    if (this.props.className) {
      classes.push(this.props.className)
    }

    const items = selectedOption.items.map((item, i) => {
      if (item.text) {
        /* this is an aciton */
        return (
          _react2.default.createElement(SelectionBoxAction, { key: i, text: item.text, onClick: () => this.onActionSelected(item.id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}})
        )
      }

      if (!item.entities) { /* not a section, continue */
        return (
          _react2.default.createElement(SelectionBoxItem, {
            key: i,
            entity: item,
            onOptionSelected: this.onOptionSelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}})
        )
      }
      if (item.entities.length < 1) {
        return null
      }

      return (
        _react2.default.createElement('div', { key: i, __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
          , item.title ? _react2.default.createElement('div', { className: "suggestion-box--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}, item.title) : null 
          , item.entities.map((e, k) => _react2.default.createElement(SelectionBoxItem, { key: k, entity: e, onOptionSelected: this.onOptionSelected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}} ))
        )
      )
    })

    return (
      _react2.default.createElement('div', { className: classes.join(" "),
        style: style,
        ref: ref => this.boxRef = ref,
        onClick: this.preventClose,
        onMouseEnter: this.mouseEnter,
        onMouseLeave: this.mouseLeave, __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
        , this.props.showTabs && _react2.default.createElement('div', { className: "suggestion-box--tabs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
          , Object.values(this.props.options).map((option, i) => (
            _react2.default.createElement('div', { key: i,
              className: _classBuilder2.default.call(void 0, "suggestion-box--tab-item", { 'selected': selectedType === option.id }),
              onClick: () => this.tabSelect(option.id), __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
              , option.tabName
            )
          ))
        )
        , _react2.default.createElement('div', { className: "suggestion-box--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
          , items
          , !items || items.length === 0 && (
            _react2.default.createElement('div', { className: "suggestion-box--message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}, "No Results found"

            )
          )
        )
      )
    )
  }
}

SuggestionBox.defaultProps = {
  showTabs: false,
}

exports. default = SuggestionBox
