"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/SenderSelectController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');


var _event = require('shared/services/event');

var _SenderSelect = require('web/components/SenderSelect'); var _SenderSelect2 = _interopRequireDefault(_SenderSelect);







const SenderSelectController = ({
  name,
  control,
  myEntitiesMap,
  ...props
}) => (
  _react2.default.createElement(_reacthookform.Controller, {
    name: name,
    control: control,
    render: ({ field }) => (
      _react2.default.createElement(_SenderSelect2.default, {
        senderEntity: myEntitiesMap[field.value ],
        onSelectSenderKey: k => field.onChange(_event.makeChangeEvent.call(void 0, k)),
        ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
  )
  )

exports. default = SenderSelectController
