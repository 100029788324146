"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/NewRoom/DesktopModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');





var _event = require('shared/services/event');

var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);
var _OwnerOption = require('./OwnerOption'); var _OwnerOption2 = _interopRequireDefault(_OwnerOption);

const keyExtractor = (e) => e.entityKey

const DesktopModal = ({
  isOpen,
  onClose,
  onSubmit,
  control,
  entitiesList,
  entitiesMap,
  hasVideoFeature,
}) => {
  const { isValid, isDirty, isSubmitting } = _reacthookform.useFormState.call(void 0, { control })

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "Set up a meeting"   ,
      className: "new-room--desktop-modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
    
      ,  !hasVideoFeature && (
        _react2.default.createElement(_WarningBar2.default, {
          className: "directory--create-new--warning",
          text: "Request Video Telehealth feature to get full access to video features"          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Host", isDisabled: !hasVideoFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        , _react2.default.createElement(_reacthookform.Controller, {
          control: control,
          name: "ownerKey",
          render: ({ field }) => {
            const selectedOption = entitiesMap[field.value ]
            if (!selectedOption) return _react2.default.createElement(_react2.default.Fragment, null)
            return (
              _react2.default.createElement(_Form.GenericSelect, {
                selectedOption: selectedOption,
                options: entitiesList,
                keyExtractor: keyExtractor,
                onChange: o => field.onChange(_event.makeChangeEvent.call(void 0, o.entityKey)),
                OptionComponent: _OwnerOption2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
              )
            )
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        )
      )
      , _react2.default.createElement(_reacthookform.Controller, {
        control: control,
        name: "roomName",
        render: ({ field, fieldState }) => (
          _react2.default.createElement(_Form.InputSectionWrapper, {
            title: "Meeting Name" ,
            isDisabled: !hasVideoFeature,
            errorMessage: _optionalChain([fieldState, 'access', _ => _.error, 'optionalAccess', _2 => _2.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
          
            , _react2.default.createElement(_Form.TextInput, {
              value: field.value,
              onChange: field.onChange,
              placeholder: "E.g. Remote Visits"  ,
              isDisabled: !hasVideoFeature,
              hasError: !!fieldState.error, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
            )
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Create",
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: hasVideoFeature && isValid && isDirty && !isSubmitting,
        onPrimaryBtnClick: onSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose,
        className: "new-room--desktop--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
    )
  )
}

exports. default = DesktopModal
