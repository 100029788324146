"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/ExtraPhoneNumber/PhonePickerModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');






var _newTeamPhone = require('shared/validation/newTeamPhone');
var _asyncDebounce = require('shared/services/asyncDebounce'); var _asyncDebounce2 = _interopRequireDefault(_asyncDebounce);
var _phone = require('shared/services/phone');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _noticeBar = require('web/services/context/noticeBar');

var _Form = require('web/components/Form');
var _components = require('web/components/Upgrade/UpgradeModal/components');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const cancelObj = {}
const getAvailablePhoneNumbers = _asyncDebounce2.default.call(void 0, 
  (AreaCode) => _actions2.default.getAvailablePhoneNumbers({ AreaCode }),
  200,
  { cancelObj },
)

const PhonePickerForm = ({
  orgKey,
  onClose,
  onReloadSubscription,
}) => {
  const { register, formState, watch, setValue, handleSubmit } =
    _reacthookform.useForm({ mode: 'onChange', resolver: _newTeamPhone.newTeamPhoneResolver })
  const { errors, isValid, isDirty } = formState

  const setPhoneNumber = _react.useCallback.call(void 0, 
    (p) => setValue('phoneNumber', p, { shouldValidate: true }),
    [setValue],
  )
  const [availableNumbers, setAvailableNumbers] = _react.useState([])
  const phoneNumber = watch('phoneNumber')
  const areaCode = watch('areaCode')
  const hasAreaCodeError = !!errors.areaCode
  const isAreaCodeValid = !hasAreaCodeError && _optionalChain([areaCode, 'optionalAccess', _ => _.length]) === _newTeamPhone.AREA_CODE_LENGTH

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const refreshPhoneNumbers = _react.useCallback.call(void 0, 
    () => {
      setPhoneNumber('')
      setAvailableNumbers([])
      if (!isAreaCodeValid) return
      getAvailablePhoneNumbers(areaCode)
        .then(r => setAvailableNumbers(r.AvailableNumbers.map(x => x.PhoneNumber)))
        .catch(e => {
          if (e === cancelObj) return
          showWarningBar('Failed to fetch available phone numbers')
        })
    },
    [isAreaCodeValid, areaCode, setPhoneNumber, showWarningBar],
  )
  _react.useEffect.call(void 0, refreshPhoneNumbers, [refreshPhoneNumbers])

  const onSubmit = _react.useCallback.call(void 0, 
    data => _actions2.default.registerPhone({ OrgKey: orgKey, PhoneNumber: data.phoneNumber })
      .then(() => {
        onClose()
        onReloadSubscription()
      })
      .then(() => showSuccessBar(`${_phone.formatPhoneNumber.call(void 0, data.phoneNumber)} has been assigned to a team`))
      .catch(() => showWarningBar('Failed to assign phone number')),
    [orgKey, showSuccessBar, showWarningBar, onClose, onReloadSubscription],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_components.Header, { title: "Select a phone number"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )
      , _react2.default.createElement('div', { className: "pick-phone--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        , _react2.default.createElement('span', { className: "pick-phone--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}, "Type an area code to view available phone numbers."

        )
        , _react2.default.createElement(_Form.InputSectionWrapper, {
          title: "Enter area code"  ,
          errorMessage: _optionalChain([errors, 'access', _2 => _2.areaCode, 'optionalAccess', _3 => _3.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        
          , _react2.default.createElement('div', { className: "pick-phone--form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
            , _react2.default.createElement(_Form.TextInput, {
              autoFocus: true,
              placeholder: "E.g. 415" ,
              hasError: hasAreaCodeError,
              maxLength: _newTeamPhone.AREA_CODE_LENGTH,
              wrapperClassName: "pick-phone--form-input",
              ...register('areaCode'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
            )
            , _react2.default.createElement(_Form.Button, {
              isDisabled: !isAreaCodeValid,
              onClick: refreshPhoneNumbers,
              className: "pick-phone--form-refresh-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
            
              , _react2.default.createElement('img', { src: "/assets/icons/refresh-white.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}} )
            )
          )
        )
        , _react2.default.createElement('div', { className: "pick-phone--results-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
          ,  availableNumbers.map(p => (
            _react2.default.createElement('div', {
              key: p,
              role: "button",
              onClick: () => setPhoneNumber(p),
              className: _classBuilder2.default.call(void 0, 
                'pick-phone--result',
                p === phoneNumber && 'pick-phone--result--selected',
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
            
              ,  _phone.formatPhoneNumber.call(void 0, p) 
            )
          ))
        )
      )
      , _react2.default.createElement(_components.Controls, {
        onSecondaryClick: onClose,
        secondaryLabel: "Back",
        isPrimaryDisabled: !isValid || !isDirty,
        primaryLabel: "Continue",
        onPrimaryClick: handleSubmit(onSubmit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
      )
    )
  )
}

exports. default = PhonePickerForm
