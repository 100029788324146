"use strict"; function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _store = require('shared/store');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _alertReducer = require('shared/reducers/alertReducer');
var _upgrade = require('shared/services/upgrade');
var _broadcast = require('shared/selectors/broadcast');
var _time = require('shared/services/time');

function handleInbound (message) {
  try {
    switch (message.Command) {
      case 'Update': _actions2.default.handleUpdateNotification(message.Data); break
      case 'Purge': _actions2.default.handlePurgeNotification(message.Data); break
      case 'Toast': _actions2.default.handleToastNotification(message.Data); break
      case 'ClientUpgradeNotification':
        return message.Data && _upgrade.handleUpgrade.call(void 0, message.Data)
      case 'ExportChatCompleteNotification':
        return _actions2.default.handleExportChatCompleteNotification(message.Data)
      case 'ExportPhoneLogsCompleteNotification':
        return _actions2.default.handleExportPhoneLogsCompleteNotification(message.Data)
      case 'PatientImportCompleteNotification':
        return _actions2.default.handlePatientImportCompleteNotification(message.Data)
      case 'UpdateOrgUsers':
        return _actions2.default.handleUpdateOrgUsersNotification(message.Data)
      case 'OrgFeaturesChangedNotification':
        return _actions2.default.handleOrgFeaturesChangedNotification(message.Data)
      case 'CampaignProgressNotification': {
        const state = _store.getState.call(void 0, )
        const c = _broadcast.selectCampaignByKey.call(void 0, state, message.Data.CampaignKey)
        if (!c || _time.isAfter.call(void 0, c.RefreshedAt, message.Data.RefreshedAt)) return
        return _actions2.default.handleCampaignProgressNotification(message.Data)
      }
      case 'Connected': {
        const state = _store.getState.call(void 0, )
        return _store.dispatch.call(void 0, {
          type: 'SERVER_ACK_SOCKET_CONNECTED',
          userInfo: state.currentUser,
          buildConfig: _upgrade.getActiveBuildConfig.call(void 0, message.Data.BuildConfig),
          currentVersion: VERSION,
          mobilePushToken: message.Data.MobilePushToken,
        })
      }
      case 'IncomingMessageNotification': // only updates `state.chats`
      case 'IncomingChatReadNotification': // updates some ancient state slices
      case 'ChatNameNotification': // is handled by `Update`
      case 'ChatStatusChangeNotification': // some more fossil
      case 'UserUpdatedNotification': // not triggered?
      case 'PatientConversationCompletedChange': // not triggered?
      case 'GenericClientNotification': // let's see if anyone notices this is gone
      case 'IncomingPatientMessage':
        return
      default:
        if (
          message.Command !== 'AuthInfo' &&
          !message.Command.startsWith('VideoRoom')
        ) log.error(`Handling of command '${message.Command}' not yet implemented`)
    }
  } catch (e) { log.error(e) }
}

function handleSessionExpired () {
  // No need to close client as this event implies its already been closed
  _store.dispatch.call(void 0, { type: 'SESSION_EXPIRED' })
  _actions2.default.logout()
}

function handleSocketConnected (m) {
  _store.dispatch.call(void 0, { type: 'SOCKET_CONNECTED' })
}

function handleSocketDisconnected (m) {
  _store.dispatch.call(void 0, { type: 'SOCKET_DISCONNECTED' })
}

function handleSocketClosed (m) {
  _store.dispatch.call(void 0, { type: 'SOCKET_DISCONNECTED' })
  _store.dispatch.call(void 0, { type: 'CLIENT_CLOSED' })
}

function handleClientFreakout () {
  if (location) {
    location.reload()
  }
}

function setClientEventHandlers () {
  _client2.default.off()
  _client2.default.on('inbound', handleInbound)
  _client2.default.on('session-expired', handleSessionExpired)
  _client2.default.on('open', handleSocketConnected)
  _client2.default.on('closed', handleSocketClosed)
  _client2.default.on('unexpected-closed', handleSocketDisconnected)
  _client2.default.on('freakout', handleClientFreakout)

  _actions2.default.assumeGoodInitialConnectionMS(2000)
}

_bus2.default.on(_actionCreators2.default.loginSuccessAction.type, action => {
  _client2.default.open({ token: action.payload.CSRF })

  _store.dispatch.call(void 0, { type: 'CLIENT_OPENED' })

  setClientEventHandlers()
})

_bus2.default.on(_actionCreators2.default.logoutAction.type, () => {
  _client2.default.off()
  _client2.default.close()
  _client2.default.logout().catch(
    () => _actions2.default.pushAlert('Error', "Couldn't log out", _alertReducer.AlertTypes.ERROR),
  )
})

_bus2.default.on(_actionCreators2.default.unAuthenticatedConnectAction.type, (action) => {
  _client2.default.open({ patientCode: action.payload.patientCode })

  setClientEventHandlers()
})

_client2.default.on('inbound', handleInbound)
