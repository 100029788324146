"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/DateInputController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _event = require('shared/services/event');

var _DateInput = require('web/components/Form/DateInput'); var _DateInput2 = _interopRequireDefault(_DateInput);





const DateInputController = ({
  name, control, rules, ...props
}) => (
  _react2.default.createElement(_reacthookform.Controller, {
    name: name,
    control: control,
    rules: rules,
    render: ({ field, fieldState }) => (
      _react2.default.createElement(_DateInput2.default, {
        ...props,
        value: field.value ,
        onChange: v => field.onChange(_event.makeChangeEvent.call(void 0, v)),
        hasError: !!fieldState.error, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
  )
  )

exports. default = DateInputController
