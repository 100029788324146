"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Message = require('shared/components/Message'); var _Message2 = _interopRequireDefault(_Message);
var _message = require('shared/enum/message');
var _TextMessage = require('./TextMessage'); var _TextMessage2 = _interopRequireDefault(_TextMessage);
var _DateMessage = require('./DateMessage'); var _DateMessage2 = _interopRequireDefault(_DateMessage);
var _MetaMessage = require('./MetaMessage'); var _MetaMessage2 = _interopRequireDefault(_MetaMessage);
var _FileMessage = require('./FileMessage'); var _FileMessage2 = _interopRequireDefault(_FileMessage);

const noop = () => ''

 class Message extends _react.Component {constructor(...args) { super(...args); Message.prototype.__init.call(this);Message.prototype.__init2.call(this);Message.prototype.__init3.call(this);Message.prototype.__init4.call(this); }
  __init() {this._renderFileMessage = (props) => {
    const {
      fileReference,
      fileName,
      fileSize,
      filePreview,
      senderAvatar,
      senderType,
      senderName,
      senderKey,
      sentAt,
      seenBy,
      showAvatar,
      showName,
      uploading,
      sendFailed,
      messageKey,
      highlighted,
      subscribers,
      currentUserKey,
      onDelete,
      conversationKey,
      deleted,
      deliveryStatus,
    } = props

    const {
      onRetrySendFile, onAbortSendFile,
      onCopyMessageLink,
      isLastMessage,
    } = this.props

    return (
      _react2.default.createElement(_FileMessage2.default, {
        highlighted: highlighted,
        messageKey: messageKey,
        fileReference: fileReference,
        fileName: fileName,
        fileSize: fileSize,
        filePreview: filePreview,
        senderAvatar: senderAvatar,
        senderType: senderType,
        senderName: senderName,
        senderKey: senderKey,
        sentAt: sentAt,
        seenBy: seenBy,
        showAvatar: showAvatar,
        showName: showName,
        sendFailed: sendFailed,
        uploading: uploading,
        subscribers: subscribers,
        currentUserKey: currentUserKey,
        onDelete: onDelete,
        onCopyMessageLink: onCopyMessageLink,
        onRetry: onRetrySendFile,
        onAbort: onAbortSendFile,
        conversationKey: conversationKey,
        deleted: deleted,
        deliveryStatus: deliveryStatus,
        isLastMessage: isLastMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
      )
    )
  }}

  __init2() {this._renderTextMessage = (props) => {
    const {
      text,
      senderAvatar,
      senderType,
      senderName,
      senderKey,
      sentAt,
      seenBy,
      showAvatar,
      showName,
      sendFailed,
      messageKey,
      highlighted,
      subscribers,
      currentUserKey,
      onDelete,
      conversationKey,
      deleted,
      deliveryStatus,
    } = props

    const { onCopyMessageLink, onRetrySendMessage, isLastMessage } = this.props

    return (
      _react2.default.createElement(_TextMessage2.default, {
        highlighted: highlighted,
        messageKey: messageKey,
        text: text,
        senderAvatar: senderAvatar,
        senderType: senderType,
        senderName: senderName,
        senderKey: senderKey,
        sentAt: sentAt,
        seenBy: seenBy,
        showAvatar: showAvatar,
        showName: showName,
        sendFailed: sendFailed,
        subscribers: subscribers,
        currentUserKey: currentUserKey,
        onDelete: onDelete,
        onCopyMessageLink: onCopyMessageLink,
        onRetry: onRetrySendMessage,
        conversationKey: conversationKey,
        deleted: deleted,
        deliveryStatus: deliveryStatus,
        isLastMessage: isLastMessage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
      )
    )
  }}

  __init3() {this._renderMetaMessage = ({ text, messageKey }) => (
    _react2.default.createElement(_MetaMessage2.default, { text: text, messageKey: messageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}} )
  )}

  __init4() {this._renderDateMarker = ({ text, messageKey }) => (
    _react2.default.createElement(_DateMessage2.default, { text: text, messageKey: messageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}} )
  )}

  render () {
    const {
      Avatar,
      ConversationKey,
      DisplayName,
      FileName,
      FileInfo,
      FileReference,
      MessageKey,
      MessageText,
      MessageType,
      PatientKey,
      SenderType,
      SentAt,
      Sequence,
      TeamKey,
      UserKey,
      SeenBy,
      SameSenderAsNextMessage,
      SameSenderAsPreviousMessage,
      Uploading,
      SendFailed,
      currentUserKey,
      highlighted,
      onDelete,
      onCopyMessageLink,
      Deleted,
      deliveryStatus,
    } = this.props

    const subscribers = this.props.subscribers || {}

    return (
      _react2.default.createElement(_Message2.default, {
        highlighted: highlighted,
        Avatar: Avatar,
        ConversationKey: ConversationKey,
        DisplayName: DisplayName,
        FileName: FileName,
        FileInfo: FileInfo,
        FileReference: FileReference,
        MessageKey: MessageKey,
        MessageText: MessageText,
        MessageType: MessageType,
        PatientKey: PatientKey,
        SenderType: SenderType,
        SentAt: SentAt,
        Sequence: Sequence,
        TeamKey: TeamKey,
        UserKey: UserKey,
        SeenBy: SeenBy,
        SendFailed: SendFailed,
        Uploading: Uploading,
        SameSenderAsNextMessage: SameSenderAsNextMessage,
        SameSenderAsPreviousMessage: SameSenderAsPreviousMessage,
        CurrentUserKey: currentUserKey,
        Subscribers: subscribers,

        renderTextMessage: this._renderTextMessage,
        renderFileMessage: this._renderFileMessage,
        renderMetaMessage: this._renderMetaMessage,
        renderDateMarker: this._renderDateMarker,
        renderConversationMarker: noop,

        onDelete: onDelete,
        onCopyMessageLink: onCopyMessageLink,
        deleted: Deleted,
        deliveryStatus: deliveryStatus || _message.deliveryStatus.DELIVERED, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
      )
    )
  }
} exports.default = Message;
