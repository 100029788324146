"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/Controls/AddPatients.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);






const AddPatients = ({
  isDisabled,
  onAddPatients,
}) => (
  _react2.default.createElement('button', {
    disabled: isDisabled,
    className: "campaign-controls--add-patients",
    onClick: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
  , "Add patients"

  )
)

exports. default = AddPatients
