"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }/* reducers/V2/index.js
 *
 * Reducers to complement the new Blobject-oriented data architecture used starting in 2019
 *
 */

var _blobjectReducer = require('./blobjectReducer'); var _blobjectReducer2 = _interopRequireDefault(_blobjectReducer);
var _usersReducer = require('./usersReducer'); var _usersReducer2 = _interopRequireDefault(_usersReducer);
var _teamsReducer = require('./teamsReducer'); var _teamsReducer2 = _interopRequireDefault(_teamsReducer);
var _currentUserReducer = require('./currentUserReducer'); var _currentUserReducer2 = _interopRequireDefault(_currentUserReducer);
var _orgsReducer = require('./orgsReducer'); var _orgsReducer2 = _interopRequireDefault(_orgsReducer);
var _messageReducer = require('./messageReducer'); var _messageReducer2 = _interopRequireDefault(_messageReducer);
var _conversationReducer = require('./conversationReducer'); var _conversationReducer2 = _interopRequireDefault(_conversationReducer);
var _patientsReducer = require('./patientsReducer'); var _patientsReducer2 = _interopRequireDefault(_patientsReducer);
var _sessionReducer = require('./sessionReducer'); var _sessionReducer2 = _interopRequireDefault(_sessionReducer);

var _inducedValuesReducer = require('./inducedValuesReducer'); var _inducedValuesReducer2 = _interopRequireDefault(_inducedValuesReducer);

function combineReducers (reducers) {
  return (state = {}, action) => {
    const intermediateState = { ...state }
    for (const key in reducers) {
      intermediateState[key] = reducers[key](state[key], action, state)
    }

    /* we always want to run this last */
    intermediateState.inducedValues = _inducedValuesReducer2.default.call(void 0, state.inducedValues, action, intermediateState)

    return intermediateState
  }
}

exports. default = combineReducers({
  /* NOTE: induced values defined in the combine method */

  blobjects: _blobjectReducer2.default,
  currentUser: _currentUserReducer2.default,
  users: _usersReducer2.default,
  teams: _teamsReducer2.default,
  orgs: _orgsReducer2.default,
  messages: _messageReducer2.default,
  conversations: _conversationReducer2.default,
  patients: _patientsReducer2.default,
  session: _sessionReducer2.default,
})
