"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');



var _user = require('shared/selectors/user');
var _team = require('shared/selectors/team');
var _org = require('shared/selectors/org');
var _string = require('shared/services/string');













 const selectInboxSettings = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  _team.selectTeams,
  _org.selectOrgs,
  (me, allTeams, allOrgs) => {
    const settingsMap = Object.values(me.Teams).reduce(
      (acc, t) => {
        const team = allTeams[t.TeamKey]
        if (!team) return acc

        const orgSettings = acc[team.OrgKey] || {
          orgKey: team.OrgKey,
          orgName: _optionalChain([allOrgs, 'access', _ => _[team.OrgKey], 'optionalAccess', _2 => _2.Name]) || '',
          teams: [],
        }

        const newTeam = {
          teamKey: team.TeamKey,
          teamName: team.Name,
          isInboxable: t.Inboxable,
        }

        orgSettings.teams.push(newTeam)

        acc[team.OrgKey] = orgSettings
        return acc
      },
      {} ,
    )

    const settingsList = Object.values(settingsMap)
    settingsList
      .sort((a, b) => _string.compareStr.call(void 0, a.orgName, b.orgName))
      .forEach(o => o.teams.sort((a, b) => _string.compareStr.call(void 0, a.teamName, b.teamName)))

    return settingsList
  },
); exports.selectInboxSettings = selectInboxSettings
