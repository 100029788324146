"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/PasswordInput/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Spinner = require('web/components/Spinner');
var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _usePasswordValidation = require('shared/hooks/usePasswordValidation'); var _usePasswordValidation2 = _interopRequireDefault(_usePasswordValidation);

const strengthBarClassNames = [
  'password-input--strength-bar--low',
  'password-input--strength-bar--middle',
  'password-input--strength-bar--high',
]















const PasswordInput = ({
  inputId,
  value = '',
  onChange,
  autoFocus,
  autoComplete,
  placeholder,
  className,
  inputClassName,
  onValidated,
  hasError,
  maxLength,
}) => {
  const [isVisible, setIsVisible] = _react.useState.call(void 0, false)
  const toggleVisibility = () => setIsVisible(x => !x)
  const shouldValidate = !!onValidated
  const inputRef = _react.useRef(null)

  const {
    note, strength, isStrengthLoading,
    validatePassword,
  } = _usePasswordValidation2.default.call(void 0, onValidated, { useState: _react.useState, useCallback: _react.useCallback, useRef: _react.useRef })

  const hasValue = !!value.length
  const handleChange = _react.useCallback.call(void 0, 
    () => {
      const value = _optionalChain([inputRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.value]) || ''
      onChange(inputId, value)
      if (shouldValidate) validatePassword(value)
    },
    [inputId, onChange, shouldValidate, validatePassword],
  )

  _react.useEffect.call(void 0, 
    () => {
      const value = _optionalChain([inputRef, 'access', _3 => _3.current, 'optionalAccess', _4 => _4.value]) || ''
      if (value && shouldValidate) validatePassword(value)
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "password-input", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      , _react2.default.createElement('div', { className: "password-input--input-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
        , _react2.default.createElement(_Form.TextInput, {
          ref: inputRef,
          autoFocus: autoFocus,
          autoComplete: autoComplete || 'off',
          autoCorrect: "off",
          autoCapitalize: "off",
          spellCheck: "false",
          className: _classBuilder2.default.call(void 0, 'password-input--input', inputClassName),
          type: isVisible ? 'text' : 'password',
          onChange: handleChange,
          value: value,
          placeholder: placeholder,
          hasError: hasError,
          maxLength: maxLength, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        )
        ,  hasValue && (
          _react2.default.createElement('div', {
            className: _classBuilder2.default.call(void 0, 
              "password-input--view-btn",
              isVisible && "password-input--view-btn--active",
            ),
            role: "button",
            onClick: toggleVisibility, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
          )
        )
      )
      ,  hasValue && shouldValidate && (
        _react2.default.createElement('div', {
          className: "password-input--strength",
          role: "progressbar",
          'aria-valuemin': 0,
          'aria-valuenow': strength,
          'aria-valuemax': 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        
          , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
            "password-input--strength-bar",
            strengthBarClassNames[strength],
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
          , _react2.default.createElement('span', { className: "password-input--strength-note", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
            ,  isStrengthLoading
              ? _react2.default.createElement(_Spinner.SimpleSpinner, { className: "password-input--strength-spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}} )
              : note
            
          )
        )
      )
    )
  )
}

exports. default = PasswordInput
