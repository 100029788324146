"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _toolkit = require('@reduxjs/toolkit');
var _reduxoptimisticui = require('redux-optimistic-ui');


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);





const initialState = {}

const reducer = _toolkit.createReducer.call(void 0, 
  initialState,
  builder => {
    [
      _actionCreators2.default.createCampaignActions.SUCCESS,
      _actionCreators2.default.updateCampaignActions.SUCCESS,
      _actionCreators2.default.getCampaignActions.SUCCESS,
      _actionCreators2.default.addPatientsToCampaignActions.SUCCESS,
      _actionCreators2.default.removePatientsFromCampaignActions.SUCCESS,
      _actionCreators2.default.dequeuePatientsInCampaignActions.SUCCESS,
      _actionCreators2.default.startCampaignActions.SUCCESS,
      _actionCreators2.default.pauseCampaignActions.SUCCESS,
      _actionCreators2.default.archiveCampaignActions.SUCCESS,
      _actionCreators2.default.unarchiveCampaignActions.SUCCESS,
    ].forEach(t =>
      builder.addCase(t, (s, a) => { s[a.payload.CampaignKey] = a.payload }),
    )

    builder.addCase(
      _actionCreators2.default.findCampaignsActions.SUCCESS,
      (s, a) => { a.payload.Campaigns.forEach(c => s[c.CampaignKey] = c) },
    )

    builder.addCase(
      _actionCreators2.default.archiveCampaignActions.REQUEST,
      (s, a) => { if (s[a.payload.CampaignKey]) s[a.payload.CampaignKey].Archived = true },
    )
    builder.addCase(
      _actionCreators2.default.unarchiveCampaignActions.REQUEST,
      (s, a) => { if (s[a.payload.CampaignKey]) s[a.payload.CampaignKey].Archived = false },
    )

    builder.addCase(
      _actionCreators2.default.startCampaignActions.REQUEST,
      (s, a) => { if (s[a.payload.CampaignKey]) s[a.payload.CampaignKey].Active = true },
    )

    builder.addCase(
      _actionCreators2.default.pauseCampaignActions.REQUEST,
      (s, a) => { if (s[a.payload.CampaignKey]) s[a.payload.CampaignKey].Active = false },
    )

    builder.addCase(
      _actionCreators2.default.campaignProgressNotificationAction,
      (s, a) => {
        const storedCampaign = s[a.payload.CampaignKey]
        if (!storedCampaign) return

        s[a.payload.CampaignKey].Active = a.payload.Active
        s[a.payload.CampaignKey].Stats = a.payload.Stats
        s[a.payload.CampaignKey].RefreshedAt = a.payload.RefreshedAt
      },
    )

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)

    builder.addDefaultCase(s => s)
  },
)

exports. default = _reduxoptimisticui.optimistic.call(void 0, reducer)
