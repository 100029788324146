"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _store = require('shared/store');
var _utils = require('shared/store/utils');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _redux = require('redux');

const userActive = _utils.createAction.call(void 0, 'Mark user active')
const userInactive = _utils.createAction.call(void 0, 'Mark user inactive')

const boundActions = _redux.bindActionCreators.call(void 0, { userActive, userInactive }, _store.dispatch)

 const actionCreators = { userActive, userInactive }; exports.actionCreators = actionCreators

exports. default = {
  userActive: () => {
    const state = _store.getState.call(void 0, )

    if (!_optionalChain([state, 'optionalAccess', _ => _.activity, 'optionalAccess', _2 => _2.active])) {
      _client2.default.startPinging()
      boundActions.userActive()
    }
  },
  userInactive: () => {
    const state = _store.getState.call(void 0, )

    if (_optionalChain([state, 'optionalAccess', _3 => _3.activity, 'optionalAccess', _4 => _4.active])) {
      _client2.default.stopPinging()
      _client2.default.send("ClientInactive", {})
        .catch(() => null)
      boundActions.userInactive()
    }
  },
}
