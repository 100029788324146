"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tabs/Item.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);














const TabItem = ({
  label, iconName, isSelected,
  className, onClick,
}) => {
  const iconFileName = `${iconName}-${isSelected ? 'purple' : 'grey'}.svg`

  return (
    _react2.default.createElement('div', {
      role: "tab",
      'aria-selected': isSelected,
      onClick: onClick,
      className: _classBuilder2.default.call(void 0, className, isSelected && 'selected'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    
      ,  iconName && _react2.default.createElement('img', { src: `/assets/icons/tabs/${iconFileName}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} ) 
      ,  label && _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}},  label ) 
    )
  )
}

exports. default = TabItem
