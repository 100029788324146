"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/DropdownParticipantsPicker/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _utils = require('shared/utils');
var _ChipInputWithSelect = require('./ChipInputWithSelect'); var _ChipInputWithSelect2 = _interopRequireDefault(_ChipInputWithSelect);
var _Form = require('web/components/Form');

const formatDisplayOrgRelationship = user => rel => ({
  ...user,
  subtitle: `${rel.title || "Member"}, ${rel.orgName}`,
  orgKey: rel.orgKey,
})

/**
 * @deprecated Use *web/components/ParticipantPicker* instead.
 */
 class DropdownParticipantsPicker extends _react2.default.PureComponent {constructor(...args) { super(...args); DropdownParticipantsPicker.prototype.__init.call(this);DropdownParticipantsPicker.prototype.__init2.call(this);DropdownParticipantsPicker.prototype.__init3.call(this);DropdownParticipantsPicker.prototype.__init4.call(this);DropdownParticipantsPicker.prototype.__init5.call(this);DropdownParticipantsPicker.prototype.__init6.call(this);DropdownParticipantsPicker.prototype.__init7.call(this); }
  __init() {this.state = {
    isPopupOpen: false,
    popupLeftCoord: 0,
    popupTopCoord: 0,
    currentCandidateKey: '',
  }}

  __init2() {this.onChipAdded = ref => {
    if (!ref.current) { return log.error("Error. Don't have access to ref. Can't display tooltip") }

    const { isPersonACandidate, isCandidateValid } = this.props
    const { entity } = ref.current.props // What? Like... Really?

    if (!isPersonACandidate(entity) || !isCandidateValid(entity.title)) return

    const popupHeight = 130
    const chipRect = ref.current.getBoundingClientRect()
    const top = (chipRect.bottom - popupHeight - (chipRect.height * 2) + 10)
    const left = (chipRect.right - chipRect.width)

    this.setState({
      currentCandidateKey: entity.id,
      isPopupOpen: true,
      popupLeftCoord: left,
      popupTopCoord: top,
    })
  }}

  __init3() {this.onPopupDismiss = () => {
    const { selectedOptions, isPersonACandidate, onOptionRemoved } = this.props

    /* remove temp entities that were never created */
    selectedOptions
      .filter(isPersonACandidate)
      .map(o => o.id)
      .forEach(onOptionRemoved)

    this.closePopup()
  }}

  __init4() {this.closePopup = () =>
    this.setState({
      currentCandidateKey: '',
      isPopupOpen: false,
      popupLeftCoord: 0,
      popupTopCoord: 0,
    })}

  __init5() {this.onPopupActionClicked = action => {
    this.props.updateCandidateStatus(this.state.currentCandidateKey, action)
    this.closePopup()
  }}

  __init6() {this.onActionSelected = id => {
    this.chipInputRef.focus()
    this.props.onActionSelected(id)
  }}

  __init7() {this.setChipInputRef = r => this.chipInputRef = r}

  render () {
    const {
      inputId,
      inputValue,
      onInputChange,
      users, teams, patients,
      orgKeyContext,
      inviteUserActionId,
      shouldSplatUsers,
      selectedOptions,
      onOptionRemoved,
      onOptionSelected,
    } = this.props

    const { isPopupOpen, popupLeftCoord, popupTopCoord } = this.state

    const inviteUserActionOption = inviteUserActionId
      ? [{ id: inviteUserActionId, text: `Invite ${inputValue}` }]
      : []

    const displayUsers = _utils.flatten.call(void 0, shouldSplatUsers
      ? users
      : users.map(u =>
        u.entityOrgRelationships.map(formatDisplayOrgRelationship(u)),
      ),
    )

    const displayItems = {
      ALL: {
        id: "ALL",
        tabName: "All",
        items: displayUsers.concat(teams, patients, inviteUserActionOption),
      },
      USERS: {
        id: "USERS",
        tabName: "Coworkers",
        items: displayUsers.concat(inviteUserActionOption),
      },
      TEAMS: { id: "TEAMS", tabName: "Teams", items: teams },
      PATIENTS: { id: "PATIENTS", tabName: "Patients", items: patients },
    }

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Form.Popup, {
          left: popupLeftCoord,
          top: popupTopCoord,
          open: isPopupOpen,
          title: "Who's this contact?"  ,
          buttonOptions: [
            { id: 'patient', text: "Patient", disabled: !orgKeyContext },
            { id: 'user', text: "Collaborator", disabled: users.length > 0 },
          ],
          onClick: this.onPopupActionClicked,
          onClose: this.onPopupDismiss, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
        )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}
          , _react2.default.createElement(_ChipInputWithSelect2.default, {
            ref: this.setChipInputRef,
            inputId: inputId,
            onInputChange: onInputChange,
            value: inputValue,
            placeholder: "Enter a name, email, or phone number..."      ,
            options: displayItems,
            selectedOptions: selectedOptions,
            onOptionSelected: onOptionSelected,
            onOptionRemoved: onOptionRemoved,
            onActionSelected: this.onActionSelected,
            onChipAdded: this.onChipAdded, __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
          )
        )
      )
    )
  }
} exports.default = DropdownParticipantsPicker;
