"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CodeScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _WelcomeBox = require('web/components/Welcome/WelcomeBox'); var _WelcomeBox2 = _interopRequireDefault(_WelcomeBox);
var _Form = require('web/components/Form');

 class CodeScreen extends _react.Component {constructor(...args) { super(...args); CodeScreen.prototype.__init.call(this);CodeScreen.prototype.__init2.call(this);CodeScreen.prototype.__init3.call(this); }
  __init() {this.state = {
    codeVal: "",
    hasError: false,
  }}

  __init2() {this.updateVal = codeVal => {
    this.setState({ codeVal })
    const hasError = !['v', 'w'].some(x => x === codeVal[0])
    this.setState({ hasError })
  }}

  __init3() {this.submit = () => {
    // Keep this in sync with socket-server/webserver/care_server.go
    const firstVal = this.state.codeVal[0]

    if (firstVal === 'v') {
      location.hash = "video/" + this.state.codeVal
    } else if (firstVal === 'w') {
      location.hash = "welcome/" + this.state.codeVal.substring(1)
    } else this.setState({ hasError: true })
  }}

  render () {
    const { hasError, codeVal } = this.state

    return (
      _react2.default.createElement(_WelcomeBox2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        , _react2.default.createElement('div', { className: "welcome--box-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          , _react2.default.createElement('div', { className: "welcome--box-full", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Welcome to BloomText"  )
            , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "Please enter your code below"    )
            , _react2.default.createElement(_Form.InputSectionWrapper, {
              title: "Code",
              errorMessage: hasError
                ? 'That looks like an invalid code - please try again.'
                : ''
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
            
              , _react2.default.createElement(_Form.TextInput, {
                value: codeVal,
                onChange: e => this.updateVal(e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
              )
            )
            , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
            , _react2.default.createElement(_Form.Button, { onClick: this.submit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Go")
          )
          , _react2.default.createElement('div', { className: "welcome--box-row-bottom welcome--box-back-to-bloom" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
            , _react2.default.createElement('a', { href: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, "Back to BloomText"  )
          )
        )
      )
    )
  }
} exports.default = CodeScreen;
