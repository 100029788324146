"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperNerdScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _Form = require('web/components/Form');

 class SuperNerdScreen extends _react.Component {
  constructor (props) {
    super(props);SuperNerdScreen.prototype.__init.call(this);SuperNerdScreen.prototype.__init2.call(this);
    this.state = {
      command: 'GetInbox',
      parameters: '{}',
      result: {},
      error: {},
    }
  }

  __init() {this.onChange = (id, _err, val) => {
    this.setState({
      [id]: val,
    })
  }}

  __init2() {this.submit = () => {
    this.setState({
      size: 0,
      time: 0,
      result: {},
      error: {},
    })

    let data
    try {
      data = JSON.parse(this.state.parameters)
    } catch (e) {
      log.error("Superuser doesn't know how to JSON")
      this.setState({
        time: 0,
        size: 0,
        result: {},
        error: e.message,
      })
      return
    }

    const start = new Date()
    _client2.default.send(this.state.command, data).then((r) => {
      const end = new Date()
      this.setState({
        time: end - start,
        size: JSON.stringify(r).length,
        result: r,
        error: {},
      })
    }).catch((e) => {
      log.error(e)
      const end = new Date()
      this.setState({
        time: end - start,
        size: 0,
        result: {},
        error: e,
      })
    })
  }}

  render () {
    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Command")
          , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            , _react2.default.createElement(_Form.TextInput, {
              placeholder: "GetInbox",
              value: this.state.command,
              onChange: e => this.onChange('command', null, e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
            )
          )
          , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Parameters", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , _react2.default.createElement(_Form.TextInput, {
              placeholder: "{}",
              value: this.state.parameters,
              onChange: e => this.onChange('parameters', null, e.target.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
            )
          )
          , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} )
          , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
          , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, _react2.default.createElement('button', { onClick: this.submit, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, "Go"))
        )
        , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "Time")
          , _react2.default.createElement('pre', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}, this.state.time, " ms" )
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}, "Size")
          , _react2.default.createElement('pre', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, this.state.size, " chars" )
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}, "Result")
          , _react2.default.createElement('pre', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}, JSON.stringify(this.state.result, null, 2))
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, "Error")
          , _react2.default.createElement('pre', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}, JSON.stringify(this.state.error, null, 2))
        )
      )
    )
  }
} exports.default = SuperNerdScreen;
