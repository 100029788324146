"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/BulkControls/RemoveConfirmationModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);








const RemoveConfirmationModal = ({
  onConfirm,
  isOpen,
  onClose,
  patientsCount,
}) => {
  const handleConfirm = () => { onConfirm(); onClose() }

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: patientsCount === 1 ? "Remove patient" : "Remove patients", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    
      , _react2.default.createElement(_Modal.ModalSubtitle, { className: "campaign--table--remove-modal--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "You are about to remove "
             ,  patientsCount , " " ,  patientsCount === 1 ? 'patient' : 'patients', " from this campaign."
      )
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Patients that are removed can no longer be messaged within this campaign."

      )
      , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, "Are you sure you want to continue?"      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Confirm and remove"  ,
        primaryBtnTheme: "danger",
        onPrimaryBtnClick: handleConfirm,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      )
    )
  )
}

exports. default = RemoveConfirmationModal
