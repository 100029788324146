"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/List/RoomsList.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactwaypoint = require('react-waypoint');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);



var _videoRoom = require('web/selectors/videoRoom');

var _MeetingsFilter = require('web/components/VideoRooms/List/MeetingsFilter'); var _MeetingsFilter2 = _interopRequireDefault(_MeetingsFilter);
var _List = require('web/components/VideoRooms/List'); var _List2 = _interopRequireDefault(_List);
var _SectionHeader = require('web/components/VideoRooms/List/SectionHeader'); var _SectionHeader2 = _interopRequireDefault(_SectionHeader);

class RoomsList extends _react2.default.PureComponent {
  constructor (props) {
    super(props);RoomsList.prototype.__init.call(this);RoomsList.prototype.__init2.call(this);RoomsList.prototype.__init3.call(this);RoomsList.prototype.__init4.call(this);RoomsList.prototype.__init5.call(this);RoomsList.prototype.__init6.call(this);RoomsList.prototype.__init7.call(this);
    this.filterMenuOptions = [
      { label: 'Active meetings', value: 'active' },
      { label: 'Archived meetings', value: 'archived' },
    ]
    this.state = {
      currentFilter: this.filterMenuOptions[0].value,
      searchQuery: '',
    }
  }

  __init() {this.handleFilterUpdate = f => this.setState(
    { currentFilter: f }, this.loadVideoRooms,
  )}

  __init2() {this.searchTimeout = null}
  __init3() {this.handleSearchQueryUpdate = q => {
    clearTimeout(this.searchTimeout)
    this.setState({ searchQuery: q }, () => {
      this.searchTimeout = setTimeout(this.loadVideoRooms, 500)
    })
  }}

  __init4() {this.componentDidMount = () => this.loadVideoRooms()}

  __init5() {this.loadVideoRooms = () => {
    const { currentFilter, searchQuery } = this.state
    const { roomsList, currentSearchContext } = this.props

    const areArchived = currentFilter === 'archived'

    const {
      areArchived: prevAreArchived,
      query: prevSearchQuery,
    } = currentSearchContext

    const loadedVideoRoomsCount = roomsList.length

    const shouldAppend =
      areArchived === prevAreArchived && prevSearchQuery === searchQuery

    clearTimeout(this.searchTimeout)
    _actions2.default.loadVideoRooms({
      Archived: areArchived,
      Query: searchQuery,
      Skip: shouldAppend ? loadedVideoRoomsCount : 0,
      Take: 10,
    })
  }}

  __init6() {this.handleArchiving = RoomKey => _actions2.default.archiveVideoRoom({ RoomKey })}
  __init7() {this.handleUnarchiving = RoomKey => _actions2.default.unarchiveVideoRoom({ RoomKey })}

  render () {
    const { searchQuery, currentFilter } = this.state
    const {
      gotoRoom, roomsList, currentRoomKey, currentSearchContext,
    } = this.props

    const shouldRenderWaypoint =
      roomsList.length > 0 && !currentSearchContext.hasDrained

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_MeetingsFilter2.default, {
          searchQuery: searchQuery,
          onSearchQueryUpdate: this.handleSearchQueryUpdate,
          currentFilter: currentFilter,
          filterMenuOptions: this.filterMenuOptions,
          onFilterUpdate: this.handleFilterUpdate, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        )
        , _react2.default.createElement(_SectionHeader2.default, {
          label: currentFilter === 'archived'
            ? 'Archived meetings'
            : 'Active meetings'
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
        )
        , _react2.default.createElement(_List2.default, {
          gotoRoom: gotoRoom,
          onArchive: this.handleArchiving,
          onUnarchive: this.handleUnarchiving,
          roomsList: roomsList,
          currentRoomKey: currentRoomKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        )
        ,  shouldRenderWaypoint &&
          _react2.default.createElement(_reactwaypoint.Waypoint, {
            key: roomsList.length,
            onEnter: this.loadVideoRooms, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
          )
        
      )
    )
  }
}

const mapState = state => ({
  roomsList: _videoRoom.selectRelevantRoomsList.call(void 0, state),
  currentSearchContext: _videoRoom.selectCurrentSearchContext.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(RoomsList)
