"use strict";Object.defineProperty(exports, "__esModule", {value: true}); const listToMap = (
  xs,
  keyExtractor,
) => xs.reduce(
    (acc, x) => {
      if (x) acc[keyExtractor(x)] = x
      return acc
    },
    {} ,
  ); exports.listToMap = listToMap
