"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _pushjs = require('push.js'); var _pushjs2 = _interopRequireDefault(_pushjs);
var _cookies = require('web/services/cookies');

// Return values - This is for the BROWSER PERMISSION
// NULL - We haven't asked yet
// FALSE - We asked and were declined
// TRUE - We asked and were accepted
 const hasBrowserPermission = () => {
  const perms = _pushjs2.default.Permission.get()

  if (perms === _pushjs2.default.Permission.DEFAULT) {
    return null
  } else if (perms === _pushjs2.default.Permission.GRANTED) {
    return true
  } else { // Push.Permission.DENIED
    return false
  }
}; exports.hasBrowserPermission = hasBrowserPermission

// Return Values - This is for our LOCAL USER SETTINGS
// NULL - We haven't asked yet
// FALSE - We asked and were declined
// TRUE - We asked and were accepted
 const getCookiePermission = (userKey) => {
  const cookie = JSON.parse(_cookies.readCookie.call(void 0, "pushNotifSettings"))

  if (cookie == null) {
    return null
  }

  const userPerms = cookie[userKey]
  if (userPerms === undefined) {
    return null
  }
  return userPerms
}; exports.getCookiePermission = getCookiePermission

 const addUserCookiePermission = (userKey) => {
  let cookie = JSON.parse(_cookies.readCookie.call(void 0, "pushNotifSettings"))
  if (cookie == null) {
    cookie = {}
  }
  cookie[userKey] = true
  _cookies.createCookie.call(void 0, "pushNotifSettings", JSON.stringify(cookie), { years: 1 })
}; exports.addUserCookiePermission = addUserCookiePermission

 const removeUserCookiePermission = (userKey) => {
  const cookie = JSON.parse(_cookies.readCookie.call(void 0, "pushNotifSettings")) || {}
  cookie[userKey] = false
  _cookies.createCookie.call(void 0, "pushNotifSettings", JSON.stringify(cookie), { years: 1 })
}; exports.removeUserCookiePermission = removeUserCookiePermission

 const askForPermissionFunction = (success, fail) => {
  return _pushjs2.default.Permission.request(success, fail)
}; exports.askForPermissionFunction = askForPermissionFunction
