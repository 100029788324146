"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/MobileSetup/Audio.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _AudioSetupGroup = require('web/screens/VideoCall/segments/AudioSetupGroup'); var _AudioSetupGroup2 = _interopRequireDefault(_AudioSetupGroup);

const AudioSetup = ({ gotoNextStep, onUserProblemPublish }) => (
  _react2.default.createElement('div', { className: "meeting--setup-audio", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    , _react2.default.createElement('h2', { className: "meeting--setup--mobile-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}, "Verify your audio"  )
    , _react2.default.createElement('span', { className: "meeting--setup--mobile-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, "Make sure your audio is working"

    )
    , _react2.default.createElement('div', { className: "meeting--setup--mobile-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      , _react2.default.createElement(_AudioSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}} )
    )
    , _react2.default.createElement('a', {
      onClick: gotoNextStep,
      className: "blmh-button meeting--btn primary purple highlight meeting--setup-audio--proceed-btn"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    , "Continue"

    )
  )
)

AudioSetup.propTypes = {
  gotoNextStep: _proptypes2.default.func.isRequired,
  onUserProblemPublish: _proptypes2.default.func.isRequired,
}

exports. default = AudioSetup
