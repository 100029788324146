"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/RoomHeader.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

const RoomHeader = ({
  roomName, roomLink, onRoomLinkCopy, openMobileMoreOptionsModal,
}) => (
  _react2.default.createElement('div', { className: "video-call--room-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    , _react2.default.createElement('div', { className: "video-call--room-header--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
      , _react2.default.createElement('h3', { className: "video-call--room-header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}},  roomName )
      , _react2.default.createElement('a', { className: "video-call--room-header--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
        , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 11}},  roomLink )
      )
    )
    , _react2.default.createElement('div', { className: "video-call--room-header--controls-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , _react2.default.createElement('div', { className: "video-call--room-header--controls-icon", onClick: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , _react2.default.createElement('img', { src: "/assets/icons/video/link-black.svg", alt: "🔗", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
      )
      , _react2.default.createElement('div', {
        className: "video-call--room-header--controls-icon",
        onClick: openMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      
        , _react2.default.createElement('img', { src: "/assets/icons/video/more-black.svg", alt: "⋮", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} )
      )
    )
  )
)

RoomHeader.propTypes = {
  roomLink: _proptypes2.default.string.isRequired,
  roomName: _proptypes2.default.string.isRequired,
  onRoomLinkCopy: _proptypes2.default.func.isRequired,
  openMobileMoreOptionsModal: _proptypes2.default.func.isRequired,
}

exports. default = RoomHeader
