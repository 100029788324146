"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');

const useStage = (initialItem = null) => {
  const [item, setItem] = _react.useState.call(void 0, initialItem)
  const [isOpen, setIsOpen] = _react.useState.call(void 0, !!initialItem)

  const onClose = _react.useCallback.call(void 0, () => setIsOpen(false), [])
  const onOpen = _react.useCallback.call(void 0, (x) => { setItem(x); setIsOpen(true) }, [])

  return {
    item,
    isOpen,
    onOpen,
    onClose,
  }
}

exports. default = useStage
