"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Search/SearchScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _SearchModal = require('./SearchModal'); var _SearchModal2 = _interopRequireDefault(_SearchModal);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _log = require('shared/log');
var _actions3 = require('web/actions'); var _actions4 = _interopRequireDefault(_actions3);
var _reactredux = require('react-redux');
var _transforms = require('./transforms');





var _user = require('shared/selectors/user');
var _useSearchQuery = require('shared/hooks/useSearchQuery');
var _myRelations = require('shared/selectors/myRelations');

const MAX_SEARCH_SIZE = 10 // total results to fetch per entity per search request

const INITIAL_ENTITY_STATE = {
  results: [],
  totalHits: 0,
  page: 0,
}

const SEARCH_TYPES = {
  MESSAGE: {
    stateName: 'messages',
    entityName: 'message',
    queryName: 'text',
  },
  FILE: {
    stateName: 'files',
    entityName: 'file',
    queryName: 'file',
  },
  CONVERSATION: {
    stateName: 'conversations',
    entityName: 'conversation',
    queryName: 'conversation',
  },
}

class SearchScreen extends _react.Component {
  constructor (props) {
    super(props);SearchScreen.prototype.__init.call(this);SearchScreen.prototype.__init2.call(this);SearchScreen.prototype.__init3.call(this);SearchScreen.prototype.__init4.call(this);SearchScreen.prototype.__init5.call(this);SearchScreen.prototype.__init6.call(this);SearchScreen.prototype.__init7.call(this);SearchScreen.prototype.__init8.call(this);SearchScreen.prototype.__init9.call(this);
    this.state = {
      searchDaysLimit: props.canDoAdvancedSearch ? 0 : 14,
      searchVal: '',
      latestQuery: '',
      files: INITIAL_ENTITY_STATE,
      messages: INITIAL_ENTITY_STATE,
      conversations: INITIAL_ENTITY_STATE,
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevState.searchVal !== this.state.searchVal) {
      if (this.state.searchVal) {
        // user typed in something new, start a new query
        if (this.searchTimeout) {
          window.clearTimeout(this.searchTimeout)
        }
        this.searchTimeout = setTimeout(() => this.newSearch(), 500)
      } else {
        // user cleared search input, reset search box
        this.clearSearch()
      }
    }
  }

  __init() {this.newSearch = () => {
    const searchTypes = Object.values(SEARCH_TYPES) // TODO ADMIN_SEARCH_TYPES if isSuperuser()?
    this.searchQuery(searchTypes, true)
  }}

  __init2() {this.loadMore = (searchType) => {
    const stateType = this.state[searchType.stateName]
    if (((stateType.page + 1) * MAX_SEARCH_SIZE) < stateType.totalHits) {
      this.searchQuery([searchType], false)
    }
  }}

  __init3() {this.clearSearch = () => {
    this.setState({
      searchVal: '',
      latestQuery: '',
      files: INITIAL_ENTITY_STATE,
      messages: INITIAL_ENTITY_STATE,
      conversations: INITIAL_ENTITY_STATE,
    })
  }}

  __init4() {this.onInputChange = (_id, val) => {
    this.setState({ searchVal: _useSearchQuery.sanitizeSearchQuery.call(void 0, val) })
  }}

  __init5() {this.onItemClick = (type, parentId, id = "") => {
    switch (type) {
      case "message":
      case "file":
      case "conversation":
        _actions2.default.getBlobjectByWrappedKey({ WrappedKey: parentId })
          .then(res => {
            const blobject = Object.values(res.Blobjects)[0]
            _actions4.default.gotoBlobject(blobject.WrappedType, blobject.WrappedKey, blobject.Key, id)
          })
          .catch(_log.log.error)
        break
      default:
        break
    }
  }}

  __init6() {this.navToConversation = (blobKey, convKey, wrappedType, msgKey) => {
    _actions4.default.gotoBlobject(wrappedType, blobKey, blobKey, msgKey)
  }}

  __init7() {this.onWaypointHit = (searchTypeKey) => {
    const searchType = SEARCH_TYPES[searchTypeKey] // TODO ADMIN_SEARCH_TYPES if isSuperuser()?
    this.loadMore(searchType)
  }}

  /* retrieve new search results from the server and add to/replace the current items in state */
  __init8() {this.searchQuery = (searchTypes, reset) => {
    const queryParams = searchTypes.map(searchType => {
      const skip = reset ? 0 : ((this.state[searchType.stateName].page + 1) * MAX_SEARCH_SIZE)
      return ({ SearchType: searchType.queryName, Take: MAX_SEARCH_SIZE, Skip: skip })
    })
    const searchVal = reset ? this.state.searchVal : this.state.latestQuery

    const { currentUser, useTestAlias } = this.props

    _actions2.default.search(searchVal, queryParams, this.state.searchDaysLimit, useTestAlias).then(res => {
      const newState = {}
      searchTypes.forEach(searchType => {
        let results, totalHits
        switch (searchType.entityName) {
          case SEARCH_TYPES.MESSAGE.entityName:
            results = _transforms.transformConversationItem.call(void 0, res.Messages.Results, currentUser, this.navToConversation)
            totalHits = res.Messages.TotalHits
            break
          case SEARCH_TYPES.FILE.entityName:
            results = _transforms.transformConversationItem.call(void 0, res.Files.Results, currentUser, this.navToConversation)
            totalHits = res.Files.TotalHits
            break
          case SEARCH_TYPES.CONVERSATION.entityName:
            results = _transforms.transformConversation.call(void 0, res.Conversations.Results, currentUser, this.navToConversation)
            totalHits = res.Conversations.TotalHits
            break
          default:
            _log.log.info("no search type found", searchType.entityName)
            break
        }
        if (!reset) {
          results = [...this.state[searchType.stateName].results, ...results]
        }
        newState[searchType.stateName] = {
          results: results,
          totalHits: totalHits,
          page: reset ? 0 : this.state[searchType.stateName].page + 1,
        }
      })
      this.setState({
        ...newState,
        latestQuery: searchVal,
      })
    })
      .catch(() => null)
  }}

  __init9() {this.onSearchLimitChange = (num) => {
    this.setState({
      searchDaysLimit: num,
    }, () => this.newSearch())
  }}

  render () {
    const { canDoAdvancedSearch, onClose, canUpgrade, mySingleOrgKey } = this.props
    const { searchVal, messages, conversations, files, latestQuery, searchDaysLimit } = this.state

    return (
      _react2.default.createElement(_SearchModal2.default, {
        canDoAdvancedSearch: canDoAdvancedSearch,
        searchDaysLimit: searchDaysLimit,
        onSearchLimitChange: this.onSearchLimitChange,
        latestQuery: latestQuery,
        onWaypointHit: this.onWaypointHit,
        onItemClick: this.onItemClick,
        messages: messages,
        files: files,
        conversations: conversations,
        searchVal: searchVal,
        onInputChange: this.onInputChange,
        mySingleOrgKey: mySingleOrgKey,
        canUpgrade: canUpgrade,
        onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}} )
    )
  }
}

SearchScreen.propTypes = {
  onClose: _proptypes2.default.func,
}

const mapState = state => {
  const mySingleOrgKey = _myRelations.selectMySingleOrgKey.call(void 0, state)
  return {
    currentUser: _user.selectCurrentUser.call(void 0, state),
    canDoAdvancedSearch: _user.selectCanCurrentUserDoAdvancedSearch.call(void 0, state),
    mySingleOrgKey: _myRelations.selectMySingleOrgKey.call(void 0, state),
    canUpgrade: !!mySingleOrgKey && _user.selectAmIAdminInOrg.call(void 0, state, mySingleOrgKey),
    useTestAlias: _user.selectCurrentUserHasTestSearchIteratorsFeature.call(void 0, state),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(SearchScreen)
