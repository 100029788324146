"use strict";Object.defineProperty(exports, "__esModule", {value: true});var CHAT_TYPE; (function (CHAT_TYPE) {
  const REGULAR = 'chat'; CHAT_TYPE["REGULAR"] = REGULAR;
  const REGULAR_PATIENT = 'sms_patient_conversation'; CHAT_TYPE["REGULAR_PATIENT"] = REGULAR_PATIENT;
  const SECURE_PATIENT = 'patient_conversation'; CHAT_TYPE["SECURE_PATIENT"] = SECURE_PATIENT;
})(CHAT_TYPE || (exports.CHAT_TYPE = CHAT_TYPE = {}));

var DELIVERY_METHOD; (function (DELIVERY_METHOD) {
  const SMS = 'SMS'; DELIVERY_METHOD["SMS"] = SMS;
  const SECURE = 'SECURE'; DELIVERY_METHOD["SECURE"] = SECURE;
})(DELIVERY_METHOD || (exports.DELIVERY_METHOD = DELIVERY_METHOD = {}));
