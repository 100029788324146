"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/OrgRels/OrgRow/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _reacthookform = require('react-hook-form');
var _org = require('shared/validation/org/org');

var _Form = require('web/components/Form');
var _OrgPicker = require('web/components/OrgPicker'); var _OrgPicker2 = _interopRequireDefault(_OrgPicker);








const OrgRow = ({ idx, control, trigger, canEditOrg }) => (
  _react2.default.createElement('div', { className: "signup--org-row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    , _react2.default.createElement(_reacthookform.Controller, {
      control: control,
      name: `orgs.${idx}.org`,
      render: ({ field }) => (
        _react2.default.createElement(_Form.InputSectionWrapper, { title: "Organization", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
          , _react2.default.createElement(_OrgPicker2.default, {
            value: field.value ,
            onChange: v => { field.onChange(v); trigger(`orgs.${idx}.org`) },
            minQueryLength: _org.MIN_ORG_NAME_SEARCH_LENGTH,
            className: "organization-row--org-picker",
            isDisabled: !canEditOrg, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
          )
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    )
    , _react2.default.createElement(_reacthookform.Controller, {
      control: control,
      name: `orgs.${idx}.title`,
      render: ({ field, fieldState }) => (
        _react2.default.createElement(_Form.InputSectionWrapper, { title: "Your title" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , _react2.default.createElement(_Form.TextInput, {
            value: field.value,
            placeholder: "E.g. Head Nurse"  ,
            onChange: v => { field.onChange(v); trigger(`orgs.${idx}.title`) },
            hasError: !!fieldState.error, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
          )
        )
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
    )
  )
)

exports. default = OrgRow
