"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _avatar = require('shared/services/avatar');
var _utils = require('../../utils');
var _utils3 = require('shared/utils');
















const clampBadgeBorderWidth = _utils3.clamp.call(void 0, 0, 3)

 const useStlyles = ({
  backgroundColorKey,
  size,
  borderColor,
  profilePicture,
  style,
}) => _react.useMemo.call(void 0, 
  () => {
    const backgroundColor = backgroundColorKey
      ? _avatar.getAvatarColorFromString.call(void 0, backgroundColorKey)
      : _utils.PALE_PURPLE
    const borderWidth = borderColor ? Math.round(size / 20) : 0

    const avatarStyle = {
      width: size,
      height: size,
      minWidth: size,
      minHeight: size,
    }

    const wrapperStyle = {
      fontSize: size * 0.57,
      backgroundColor: profilePicture ? 'transparent' : backgroundColor,
      borderWidth,
      borderColor,
      ...style,
    }

    const badgeStyle =
      { borderWidth: clampBadgeBorderWidth(size / 30) }

    const participantNumberStyle = { borderWidth, borderColor }

    return {
      avatarStyle,
      wrapperStyle,
      badgeStyle,
      participantNumberStyle,
    }
  },
  [backgroundColorKey, size, borderColor, profilePicture, style],
); exports.useStlyles = useStlyles
