"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/QueueMessages/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _number = require('shared/services/number');

var _Modal = require('web/components/Modal');


var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const isValueValid = (v, max) => {
  if (isNaN(+v)) return false
  const numberValue = parseInt(v.toString())
  return numberValue > 0 && numberValue <= max
}

const QueueMessagesForm = ({
  isActive, messageDelay, notScheduledCount,
  onClose, onStartCampaign,
}) => {
  const [value, setValue] = _react.useState(notScheduledCount)
  const isValid = isValueValid(value, notScheduledCount)

  const handleChange =
    e => setValue(e.target.value)

  const handleStart = () => {
    onStartCampaign(parseInt(value.toString()))
    onClose()
  }

  _react.useEffect.call(void 0, 
    () => { value > notScheduledCount && setValue(notScheduledCount) },
    [notScheduledCount], // eslint-disable-line react-hooks/exhaustive-deps
  )

  const displayDelay = _number.round.call(void 0, (_nullishCoalesce(messageDelay, () => ( 0))) / 1000, 0.5)

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
        "campaign-controls--batch--form",
        isActive && "campaign-controls--batch--form--active",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , _react2.default.createElement(_Form.InputSectionWrapper, {
          className: "campaign-controls--batch--size--wrapper",
          title: "Number of messages to add to the queue"       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
        
          , _react2.default.createElement('div', { className: "campaign-controls--batch--size", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            , _react2.default.createElement(_Form.RangeInput, {
              min: 1,
              max: notScheduledCount,
              value: value,
              onChange: setValue,
              className: "campaign-controls--batch--size-range", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
            )
            , _react2.default.createElement(_Form.TextInput, {
              value: value,
              onChange: handleChange,
              hasError: !isValid,
              className: "campaign-controls--batch--size-input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            )
          )
        )
        ,  messageDelay && (
          _react2.default.createElement('span', { className: "campaign-controls--batch--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}, "A message with be sent every"
                 , ' '
            ,  displayDelay , ' '
            ,  displayDelay === 1 ? 'second' : 'seconds' , "."
          )
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: isActive ? "Queue" : "Send",
        onPrimaryBtnClick: handleStart,
        isPrimaryEnabled: isValid,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
      )
    )
  )
}

exports. default = QueueMessagesForm
