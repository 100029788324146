"use strict";Object.defineProperty(exports, "__esModule", {value: true});/* Context: Initially the webChat directory served as a means to serve a web experience that ran
 * both on browsers and electron ( a mini-browser ). It was not mobile-responsive and was inherently
 * monolithic. Webchat has since grown to encompass a number of other js-based "apps". E.g.
 * patient-side responses. These apps share some, but are often quite
 * different. This file is meant to provide easy-to-use methods to draw those distinctions.
 */

var _responsive = require('web/services/responsive');
var _store = require('web/store');
var _log = require('shared/log');

 const WEB_CHAT_MODE = "web_chat"; exports.WEB_CHAT_MODE = WEB_CHAT_MODE
 const ELECTRON_CHAT_MODE = "electron_chat"; exports.ELECTRON_CHAT_MODE = ELECTRON_CHAT_MODE
 const PATIENT_RESPONSE_MODE = "patient_response"; exports.PATIENT_RESPONSE_MODE = PATIENT_RESPONSE_MODE
 const MWEB_CHAT_MODE = "mweb_chat"; exports.MWEB_CHAT_MODE = MWEB_CHAT_MODE
 const LOU_VIDEO_MEETING_MODE = "lou_video_meeting_mode"; exports.LOU_VIDEO_MEETING_MODE = LOU_VIDEO_MEETING_MODE

 function getAppMode () {
  if (!window) {
    _log.log.error("No window object")
  }

  const isElectron = !!window.ELECTRON
  if (isElectron) return exports.ELECTRON_CHAT_MODE

  const isPatientConvo = /conversation\/([a-zA-Z0-9]{12})/.test(_store.history.location.pathname)
  const isLOUMeeting = /vd/.test(_store.history.location.pathname)

  if (isPatientConvo) {
    return exports.PATIENT_RESPONSE_MODE
  } else if (isLOUMeeting) {
    return exports.LOU_VIDEO_MEETING_MODE
  } else if (window.innerWidth <= _responsive.MobileWidth) {
    return exports.MWEB_CHAT_MODE
  } else {
    return exports.WEB_CHAT_MODE
  }
} exports.getAppMode = getAppMode;

 const downloadApp = () => {
  const extension = window.navigator.platform.indexOf('Mac') === -1 ? 'exe' : 'dmg'
  const downloadUrl = `https://bloomtext-electron-binaries.s3.amazonaws.com/BloomText-${DESKTOP_APP_VERSION}.${extension}`
  _log.log.info(`Downloading app from ${downloadUrl}...`)
  window.location = downloadUrl
}; exports.downloadApp = downloadApp

 const updateApp = () => {
  _log.log.info("Reloading...")
  window.location = '/'
}; exports.updateApp = updateApp
