"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/App/List/ListHeaderWithAddButton.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Add = require('web/components/Add'); var _Add2 = _interopRequireDefault(_Add);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const ListHeaderWithAddButton = ({
  className = '',
  titleClassName = '',
  children,
  onAddPress,
  isAddDisabled,
  ...props
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "list-header", className), ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "list-header--title", titleClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      ,  children 
    )
    ,  onAddPress && (
      _react2.default.createElement('div', { className: "list-header--add", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        , _react2.default.createElement(_Add2.default, { disabled: isAddDisabled, onClick: onAddPress, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
      )
    )
  )
)

exports. default = ListHeaderWithAddButton
