"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/NotificationPreferenceModal/Form/NotificationSoundSection.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _notifications = require('shared/services/notifications');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _hooks = require('web/services/hooks');
var _NotificationSoundPicker = require('web/components/NotificationSoundPicker'); var _NotificationSoundPicker2 = _interopRequireDefault(_NotificationSoundPicker);

const NotificationSoundSection = ({ selectedSound, onSubmit }) => {
  const playSound = _hooks.usePlaySoundEffect.call(void 0, )
  const {
    isOpen: isSoundPickerOpen,
    onOpen: onOpenSoundPicker,
    onClose: onCloseSoundPicker,
  } = _useToggle2.default.call(void 0, )

  const onPlayCurrentSound = () => selectedSound && playSound(selectedSound)

  return (
    _react2.default.createElement('div', { className: "chat--notification-settings--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , _react2.default.createElement('span', { className: "chat--notification-settings--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Notification Sound" )
      , _react2.default.createElement('div', { className: "chat--notification-settings--sound-picker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        , _react2.default.createElement('div', {
          className: "chat--notification-settings--sound-btn",
          onClick: onPlayCurrentSound, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        
          , _react2.default.createElement('span', { className: "chat--notification-settings--sound-play-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "play_arrow")
          )
          , _react2.default.createElement('span', { className: "chat--notification-settings--sound-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
            ,  _notifications.getSoundName.call(void 0, selectedSound, "Default") 
          )
        )
        , _react2.default.createElement('button', {
          className: "chat--notification-settings--change-sound-btn",
          onClick: onOpenSoundPicker, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        , "Change sound"

        )
      )
      , _react2.default.createElement(_NotificationSoundPicker2.default, {
        isOpen: isSoundPickerOpen,
        onClose: onCloseSoundPicker,
        defaultChosenSound: selectedSound,
        noneLabel: "Default",
        onSubmit: onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
    )
  )
}

NotificationSoundSection.propTypes = {
  selectedSound: _proptypes2.default.string,
  onSubmit: _proptypes2.default.func.isRequired,
}

exports. default = NotificationSoundSection
