"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');









const useSelection = () => {
  const [selectedRowsMap, setSelectedRowsMap] = _react.useState({})

  const onSetRowsBatchSelection = _react.useCallback.call(void 0, 
    (patientKeysList, isSelected) => setSelectedRowsMap(
      isSelected
        ? patientKeysList.reduce((acc, k) => { acc[k] = k; return acc }, {} )
        : {},
    ),
    [setSelectedRowsMap],
  )

  const onClearAllRowsSelection = _react.useCallback.call(void 0, 
    () => setSelectedRowsMap({}),
    [setSelectedRowsMap],
  )

  const selectedRowsList = _react.useMemo.call(void 0, 
    () => Object.values(selectedRowsMap).filter(x => x),
    [selectedRowsMap],
  )

  const onSetRowSelection = _react.useCallback.call(void 0, 
    (patientKey, isSelected) => setSelectedRowsMap(m => ({
      ...m,
      [patientKey]: isSelected ? patientKey : null,
    })),
    [setSelectedRowsMap],
  )

  return {
    selectedRowsMap,
    onSetRowSelection,
    onSetRowsBatchSelection,
    onClearAllRowsSelection,
    selectedRowsList,
  }
}

exports. default = useSelection
