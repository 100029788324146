"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Sidebar/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactrouterdom = require('react-router-dom');



var _types = require('./types');
var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);





var _user = require('shared/selectors/user');
var _conversation = require('shared/selectors/conversation');

var _ui = require('web/services/ui');
var _Icon = require('./Icon'); var _Icon2 = _interopRequireDefault(_Icon);
var _ProfileIcon = require('./ProfileIcon'); var _ProfileIcon2 = _interopRequireDefault(_ProfileIcon);
var _SettingsIcon = require('./SettingsIcon'); var _SettingsIcon2 = _interopRequireDefault(_SettingsIcon);





const Sidebar = ({
  onSearch,
  myUserKey,
  hasInboxBadge,
  hasVideo,
  hasSuperConsole,
}) => {
  const screenType = _ui.useScreenType.call(void 0, )
  const tooltipPlace = screenType === 'mobile' ? 'top' : 'right'
  const gotoSettings = screenType === 'mobile'
    ? _actions2.default.gotoSettings
    : _actions2.default.gotoProfileSettings

  const location = _reactrouterdom.useLocation.call(void 0, )
  const { pathname } = location

  const activeRoute = _react.useMemo.call(void 0, 
    () => {
      if (pathname.startsWith(_routes2.default.INBOX.ROOT)) return _types.RouteName.inbox
      if (pathname.startsWith(_routes2.default.DIRECTORY.ROOT)) return _types.RouteName.directory
      if (pathname.startsWith(_routes2.default.BROADCAST.ROOT)) return _types.RouteName.broadcast
      if (pathname.startsWith(_routes2.default.SETTINGS.ROOT)) return _types.RouteName.settings
      if (pathname.startsWith(_routes2.default.VIDEO.ROOT)) return _types.RouteName.video
      if (pathname.startsWith(_routes2.default.SUPER)) return _types.RouteName.super
      return null
    },
    [pathname],
  )

  return (
    _react2.default.createElement('div', { className: "blm-sidebar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
      , _react2.default.createElement(_ProfileIcon2.default, { userKey: myUserKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}} )
      , _react2.default.createElement('div', { className: "blm-sidebar--separator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} )
      , _react2.default.createElement(_Icon2.default, {
        title: "Search",
        iconName: _types.RouteName.search,
        onClick: onSearch,
        isHighlighted: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      )
      , _react2.default.createElement('div', { className: "blm-sidebar--separator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}} )
      , _react2.default.createElement(_Icon2.default, {
        title: "Inbox",
        iconName: _types.RouteName.inbox,
        hasBadge: hasInboxBadge,
        isActive: activeRoute === _types.RouteName.inbox,
        onClick: _actions2.default.gotoInbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
      , _react2.default.createElement(_Icon2.default, {
        title: "Directory",
        iconName: _types.RouteName.directory,
        isActive: activeRoute === _types.RouteName.directory,
        onClick: _actions2.default.gotoDirectory, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      )
      , _react2.default.createElement(_Icon2.default, {
        title: "Broadcast Campaigns" ,
        shortTitle: "Broadcast",
        iconName: _types.RouteName.broadcast,
        isActive: activeRoute === _types.RouteName.broadcast,
        onClick: _actions2.default.gotoCampaigns, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      )
      ,  hasVideo && (
        _react2.default.createElement(_Icon2.default, {
          title: "Video Meetings" ,
          shortTitle: "Video",
          iconName: _types.RouteName.video,
          isActive: activeRoute === _types.RouteName.video,
          onClick: _actions2.default.gotoVideoRooms, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
        )
      )
      ,  hasSuperConsole && (
        _react2.default.createElement(_Icon2.default, {
          title: "Superuser Console" ,
          shortTitle: "Super",
          iconName: _types.RouteName.super,
          isActive: activeRoute === _types.RouteName.super,
          onClick: _actions2.default.gotoSuperConsole, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
        )
      )
      , _react2.default.createElement('div', { className: "blm-sidebar--settings-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
        , _react2.default.createElement('div', { className: "blm-sidebar--separator", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
        , _react2.default.createElement(_SettingsIcon2.default, {
          title: "Settings",
          iconName: _types.RouteName.settings,
          isActive: activeRoute === _types.RouteName.settings,
          onClick: gotoSettings,
          tooltipPlace: tooltipPlace, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
        )
      )
    )
  )
}








const mapState = (state) => ({
  myUserKey: _user.selectCurrentUserKey.call(void 0, state),
  hasInboxBadge: _conversation.selectInboxHasAlert.call(void 0, state),
  hasVideo: _user.selectCurrentUserHasVideoV1Feature.call(void 0, state) ||
            _user.selectCurrentUserHasVideoV2Feature.call(void 0, state),
  hasSuperConsole: _user.selectAmISuperUser.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(Sidebar)
