"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ExportChatModal/Form/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

var _chatExport = require('shared/validation/chat/chatExport');

var _Form = require('web/components/Form');
var _DateRange = require('./DateRange'); var _DateRange2 = _interopRequireDefault(_DateRange);










const DEFAULT_START = _moment2.default.call(void 0, ).subtract(7, 'days').format(_moment2.default.HTML5_FMT.DATE)
const DEFAULT_END = _moment2.default.call(void 0, ).format(_moment2.default.HTML5_FMT.DATE)

const ExportInboxItemForm = ({ onClose, onProceed }) => {
  const { control, handleSubmit, formState } = _reacthookform.useForm({
    mode: 'onChange',
    resolver: _chatExport.chatExportResolver,
    defaultValues: {
      dateRangeType: 'allTime',
      shouldIncludeFiles: true,
      startDate: DEFAULT_START,
      endDate: DEFAULT_END,
    },
  })
  const { isValid, isSubmitting, errors } = formState

  const submit = _react.useCallback.call(void 0, 
    data => {
      onClose()
      onProceed(
        data.dateRangeType === 'allTime'
          ? [null, null]
          : [
              _moment2.default.call(void 0, data.startDate, _moment2.default.HTML5_FMT.DATE).utc().toISOString(),
              _moment2.default.call(void 0, data.endDate, _moment2.default.HTML5_FMT.DATE).utc().add(1, 'days').toISOString(),
            ],
        data.shouldIncludeFiles,
      )
        .catch(() => null)
    },
    [onProceed, onClose],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "chat-export--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        , _react2.default.createElement(_DateRange2.default, {
          control: control,
          startDateErrorMessage: _optionalChain([errors, 'access', _ => _.startDate, 'optionalAccess', _2 => _2.message]),
          endDateErrorMessage: _optionalChain([errors, 'access', _3 => _3.endDate, 'optionalAccess', _4 => _4.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Include files" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , _react2.default.createElement(_Form.ToggleController, {
          control: control,
          name: "shouldIncludeFiles",
          label: "Include files shared by participants in the export"       , __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        )
      )
      , _react2.default.createElement('div', { className: "chat-export--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Cancel")
        , _react2.default.createElement(_Form.Button, {
          isDisabled: !isValid || isSubmitting,
          onClick: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        , "Export chat"

        )
      )
    )
  )
}

exports. default = ExportInboxItemForm
