"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _supportUrls = require('shared/data/supportUrls');
var _hooks = require('./hooks');

var _Features = require('./Features'); var _Features2 = _interopRequireDefault(_Features);
var _components = require('./components');





const SubscriptionsTab = ({ orgKey }) => {
  const {
    renewsOn,
    isLoading,
    hasLoadFailed,
    itemsMap,
    hasSubscription,
    onManage,
    isLocked,
    cancelAt,
    onReloadSubscription,
  } = _hooks.usePlanDetails.call(void 0, orgKey)

  return (
    _react2.default.createElement(_components.Wrapper, {
      isLoading: isLoading,
      hasLoadFailed: hasLoadFailed,
      supportUrl: _supportUrls.scheduleMeetingUrl,
      isLocked: isLocked,
      cancelAt: cancelAt,
      canManage: hasSubscription,
      onManage: onManage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    
      , _react2.default.createElement(_Features2.default, {
        orgKey: orgKey,
        isEditable: !cancelAt,
        renewsOn: renewsOn,
        itemsMap: itemsMap,
        onReloadSubscription: onReloadSubscription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      )
    )
  )
}

exports. default = SubscriptionsTab
