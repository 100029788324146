"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/AddAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);

const AddAvatar = props => (
  _react2.default.createElement(_BaseAvatar2.default, { profilePicture: "/assets/avatars/avatar-more.svg", ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}} )
)


exports. default = AddAvatar
