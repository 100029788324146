"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');




var _common = require('shared/selectors/blobject/common');
var _user = require('shared/selectors/user');
var _team = require('shared/selectors/team');

const EMPTY_TEAM_LIST = []
const populateSubscribers = (
  subscribers,
  allEntitiesMap,
) =>
  Object.keys(subscribers)
    .map(key => allEntitiesMap[key ])
    .filter(e => !!e)

 const selectMyPopulatedBlobjectTeams = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _user.selectCurrentUserKey,
  _team.selectAllTeams,
  (blobject, myUserKey, allTeams) => {
    if (!blobject) return EMPTY_TEAM_LIST
    const { TeamSubscribers } = blobject

    const teamsList = populateSubscribers(TeamSubscribers, allTeams)
    return teamsList.filter(t => _optionalChain([t, 'optionalAccess', _ => _.Members, 'access', _2 => _2[myUserKey]])) 
  },
); exports.selectMyPopulatedBlobjectTeams = selectMyPopulatedBlobjectTeams
