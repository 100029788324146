"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');

const useEntityPreload = (loadEntity) => {
  const [hasLoadFailed, setHasLoadFailed] = _react.useState.call(void 0, false)

  _react.useEffect.call(void 0, 
    () => {
      loadEntity()
        .then(() => setHasLoadFailed(false))
        .catch(() => setHasLoadFailed(true))
    },
    [loadEntity],
  )

  return hasLoadFailed
}

exports. default = useEntityPreload
