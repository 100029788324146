"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Phones/PhoneEntry.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _phone = require('shared/services/phone');
var _noticeBar = require('web/services/context/noticeBar');

var _Form = require('web/components/Form');











const PhoneEntry = ({
  phone,
  onRemovePhone,
  onVerifyPhone,
  onMakePhonePrimary,
  isPrimary,
  isVerified,
  canRemove,
}) => {
  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  const handleRemovePhone = () => onRemovePhone(phone)
    .then(() => showSuccessBar(`${_phone.formatPhoneNumber.call(void 0, phone)} has been removed.`))
    .catch(() => showWarningBar('Failed to remove your phone.'))
  const handleVerifyPhone = () => onVerifyPhone(phone)

  const handleMakePhonePrimary = () => onMakePhonePrimary(phone)
    .then(() => showSuccessBar(`${_phone.formatPhoneNumber.call(void 0, phone)} has been made primary.`))
    .catch(() => showWarningBar('Failed to make your phone primary.'))

  return (
    _react2.default.createElement('div', { className: "settings--profile--contact-entry", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      , _react2.default.createElement('img', {
        src: "/assets/icons/settings/phones.svg",
        className: "settings--profile--contact-icon",
        alt: "@", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      )
      , _react2.default.createElement('div', { className: "settings--profile--contact-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , _react2.default.createElement('span', { className: "settings--profile--contact-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          ,  _phone.formatPhoneNumber.call(void 0, phone) 
        )
        ,  isPrimary &&
          _react2.default.createElement('span', { className: "settings--profile--contact-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
            , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
              , _react2.default.createElement('img', { src: "/assets/icons/settings/star.svg", alt: "★", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} ), "Primary"

            )
            , _react2.default.createElement('span', { className: "blmh-under-grid-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}, " - "), "Notifications will be sent to this phone."

          )
        
        ,  !isVerified &&
          _react2.default.createElement('span', { className: "settings--profile--contact-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}, "Pending verification"

          )
        
      )
      , _react2.default.createElement('div', { className: "settings--profile--contact-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        ,  !isPrimary && isVerified && (
          _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: handleMakePhonePrimary, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, "Set as primary"

          )
        )
        ,  !isVerified && (
          _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: handleVerifyPhone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}, "Verify phone"

          )
        )
        ,  canRemove && (
          _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: handleRemovePhone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}, "Remove"

          )
        )
      )
    )
  )
}

exports. default = PhoneEntry
