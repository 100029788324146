"use strict";Object.defineProperty(exports, "__esModule", {value: true});// Client-side message states:

var deliveryStatus; (function (deliveryStatus) {
  const SENT = 'message_pending'; deliveryStatus["SENT"] = SENT; // an attempt to send the message has been made
  const CANCELED = 'message_canceled'; deliveryStatus["CANCELED"] = CANCELED; // a file upload has been cancelled before the message could be sent
  const DELIVERED = 'message_delivered'; deliveryStatus["DELIVERED"] = DELIVERED; // API acknowledged that it recieved the message
  const FAILED = 'message_failed'; deliveryStatus["FAILED"] = FAILED; // API never received the message
})(deliveryStatus || (exports.deliveryStatus = deliveryStatus = {}));

exports. default = deliveryStatus
