"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _redux = require('redux');
var _store = require('shared/store');
var _utils = require('shared/store/utils');


const popAlertAction = _utils.createAction.call(void 0, 'POP_ALERT')
const clearAlertsAction = _utils.createAction.call(void 0, 'CLEAR_ALERTS')
const pushAlertAction = _utils.createAction.call(void 0, 
  'PUSH_ALERT',
  (title, body, type) => ({
    payload: { title, body, type },
  }),
)

 const actionCreators = {
  popAlertAction,
  clearAlertsAction,
  pushAlertAction,
}; exports.actionCreators = actionCreators

const popAlert = _redux.bindActionCreators.call(void 0, popAlertAction, _store.dispatch)
const clearAlerts = _redux.bindActionCreators.call(void 0, clearAlertsAction, _store.dispatch)
const pushAlert = _redux.bindActionCreators.call(void 0, pushAlertAction, _store.dispatch)

exports. default = { popAlert, clearAlerts, pushAlert }
