"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _lodash = require('lodash');

var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _promise = require('shared/services/promise');
var _asyncDebounce = require('shared/services/asyncDebounce'); var _asyncDebounce2 = _interopRequireDefault(_asyncDebounce);

 const BAD_PASSWORD_STRENGTH = 0; exports.BAD_PASSWORD_STRENGTH = BAD_PASSWORD_STRENGTH
 const OK_PASSWORD_STRENGTH = 1; exports.OK_PASSWORD_STRENGTH = OK_PASSWORD_STRENGTH
 const GREAT_PASSWORD_STRENGTH = 2; exports.GREAT_PASSWORD_STRENGTH = GREAT_PASSWORD_STRENGTH

 const DEBOUNCE_DELAY_MS = 300; exports.DEBOUNCE_DELAY_MS = DEBOUNCE_DELAY_MS
const MIN_STRENGTH_THRESHOLD = exports.OK_PASSWORD_STRENGTH

 const passwordNotes = {
  great: 'Great password!',
  ok: 'OK.',
  weak: 'Too weak!',
  wayTooShort: 'Too short.',
  tooShort: 'Try a longer password.',
  hasBloom: 'Don\'t include "bloom".',
  hasDate: 'Don\'t include any dates.',
  singleCase: 'Try using mixed case.',
  noSpecialChars: 'Try adding one or more special characters.',
}; exports.passwordNotes = passwordNotes

const noop = () => null
const isPasswordStrong = _asyncDebounce2.default.call(void 0, 
  pwd => _client2.default.checkPassword(pwd)
    .then(r => _lodash.clamp.call(void 0, 
      r.Strength,
      exports.BAD_PASSWORD_STRENGTH,
      exports.GREAT_PASSWORD_STRENGTH,
    ))
    .catch(() => exports.BAD_PASSWORD_STRENGTH),
  exports.DEBOUNCE_DELAY_MS,
)

const fourDigitsRegExp = /\d{4}/g
const specialCharsRegExp = /\W|_/g

const getPasswordImprovementNote = password => {
  if (password.length < 6) return exports.passwordNotes.wayTooShort
  if (
    password.toLowerCase().indexOf('bloom') > -1
  ) return exports.passwordNotes.hasBloom

  const possibleDates = password.match(fourDigitsRegExp)
  if (
    possibleDates &&
    possibleDates.find(d => parseInt(d) >= 1900 && parseInt(d) <= 2100)
  ) return exports.passwordNotes.hasDate

  if (
    password.toLowerCase() === password ||
    password.toUpperCase() === password
  ) return exports.passwordNotes.singleCase

  if (!password.match(specialCharsRegExp)) return exports.passwordNotes.noSpecialChars

  if (password.length < 12) return exports.passwordNotes.tooShort

  return exports.passwordNotes.weak
}

const useValidation = (onValidated, { useState, useRef, useCallback }) => {
  const [strength, setStrength] = useState(exports.BAD_PASSWORD_STRENGTH)
  const [note, setNote] = useState(exports.passwordNotes.tooShort)
  const [isStrengthLoading, setIsStrengthLoading] = useState(false)

  const validationCall = useRef(null)

  const validatePassword = useCallback(
    pwd => new Promise(resolve => {
      _optionalChain([validationCall, 'access', _ => _.current, 'optionalAccess', _2 => _2.cancel, 'optionalCall', _3 => _3()])

      if (!onValidated) return [exports.BAD_PASSWORD_STRENGTH, '']

      setIsStrengthLoading(true)
      validationCall.current = new (0, _promise.CancellablePromise)(
        resolveValidationCall => isPasswordStrong(pwd)
          .catch(() => strength)
          .then(s => {
            setIsStrengthLoading(false)
            resolveValidationCall(s)
          }),
      )

      validationCall.current
        .then(newStrength => {
          const newNote = [
            getPasswordImprovementNote(pwd),
            exports.passwordNotes.ok,
            exports.passwordNotes.great,
          ][newStrength]

          return resolve([newStrength, newNote])
        })

      return validationCall.current
    }).then(([newStrength, newNote]) => {
      setStrength(newStrength)
      setNote(newNote)
      _optionalChain([onValidated, 'optionalCall', _4 => _4(newStrength >= MIN_STRENGTH_THRESHOLD)])
    }),
    [strength, onValidated],
  )

  return {
    note,
    strength,
    isStrengthLoading,
    validatePassword: onValidated ? validatePassword : noop,
  }
}

exports. default = useValidation
