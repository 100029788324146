"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _toolkit = require('@reduxjs/toolkit');


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);



const initialState = {}

exports. default = _toolkit.createReducer.call(void 0, 
  initialState,
  builder => {
    [
      _actionCreators2.default.loginSuccessAction,
      _actionCreators2.default.updateOrgUsersNotificationAction,
      _actionCreators2.default.getQuickLoginUsersActions.SUCCESS,
      _actionCreators2.default.updateOrgInfoActions.SUCCESS,
      _actionCreators2.default.createPatientActions.SUCCESS,
      _actionCreators2.default.updatePatientActions.SUCCESS,
      _actionCreators2.default.requestJoinOrgActions.SUCCESS,
      _actionCreators2.default.cancelJoinOrgRequestActions.SUCCESS,
      _actionCreators2.default.approvePendingUserActions.SUCCESS,
      _actionCreators2.default.rejectPendingUserActions.SUCCESS,
      _actionCreators2.default.removeUserFromOrgActions.SUCCESS,
      _actionCreators2.default.inviteUserToMyOrgActions.SUCCESS,
      _actionCreators2.default.createAndJoinOrgActions.SUCCESS,
      _actionCreators2.default.loadPatientConversationByCodeActions.SUCCESS,
      _actionCreators2.default.getPatientConversationMessagesActions.SUCCESS,
    ].forEach(t => builder.addCase(t, (s, a) => ({ ...s, ...a.payload.Orgs })))

    builder.addCase(
      _actionCreators2.default.orgFeaturesChangedNotificationAction,
      (s, a) => {
        const org = s[a.payload.OrgKey]
        if (org) org.EnabledFeatures = a.payload.EnabledFeatures
      },
    )

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)
    builder.addDefaultCase(s => s)
  },
)
