"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

const msInMinute = 60 * 1000
 const msInHour = 60 * msInMinute; exports.msInHour = msInHour
const msInDay = 24 * exports.msInHour
 const getTZMinsOffset = () => new Date().getTimezoneOffset(); exports.getTZMinsOffset = getTZMinsOffset
const getTZMsOffset = () => exports.getTZMinsOffset.call(void 0, ) * msInMinute

const NULL_DATE_STR = '0001-01-01'
 const NULL_DATE = new Date(NULL_DATE_STR); exports.NULL_DATE = NULL_DATE

 const TODAY = new Date(); exports.TODAY = TODAY
 const formatDisplayTime = (t, format) => _moment2.default.call(void 0, t, format).format("LT"); exports.formatDisplayTime = formatDisplayTime
 const formatLongDisplayTime = t => _moment2.default.call(void 0, t).format("MMM Do, LT"); exports.formatLongDisplayTime = formatLongDisplayTime
 const formatReadableDisplayDate = t => _moment2.default.call(void 0, t).format("MMM Do"); exports.formatReadableDisplayDate = formatReadableDisplayDate
 const formatDisplayDate = d => exports.isDateNull.call(void 0, d)
  ? ''
  : _moment2.default.call(void 0, d).format('MM/DD/YYYY'); exports.formatDisplayDate = formatDisplayDate
 const formatLongDisplayDate = d => exports.isDateNull.call(void 0, d)
  ? ''
  : _moment2.default.call(void 0, d).format('llll'); exports.formatLongDisplayDate = formatLongDisplayDate

 const getMilliSecondsFromMidnight = m => {
  const hour = m.get('hour')
  const minute = m.get('minute')
  const offset = m.utcOffset()

  const total =
    hour * exports.msInHour +
    minute * msInMinute +
    offset * -msInMinute

  return total >= msInDay ? total - msInDay : total
}; exports.getMilliSecondsFromMidnight = getMilliSecondsFromMidnight

 const getTimeStringFromMillisecondValue = (val) => {
  const rawMsFromMidnight = val - getTZMsOffset()
  // If our timezone adjustment brings us below zero, then re-add 24 hours to it
  const msFromMidnight = rawMsFromMidnight < 0
    ? rawMsFromMidnight + (24 * exports.msInHour)
    : rawMsFromMidnight

  const hours = Math.floor(msFromMidnight / exports.msInHour)
  const minutes = Math.floor((msFromMidnight - hours * exports.msInHour) / msInMinute)

  const hoursStr = hours.toString().padStart(2, '0')
  const minutesStr = minutes.toString().padStart(2, '0')

  return `${hoursStr}:${minutesStr}`
}; exports.getTimeStringFromMillisecondValue = getTimeStringFromMillisecondValue

 const getTimeString = dateStr => {
  const date = _moment2.default.call(void 0, dateStr)
  const now = _moment2.default.call(void 0, )
  const daysDiff = now.diff(date, "days")

  if (!daysDiff) return date.format("LT") /* 4:55 PM */
  if (daysDiff < 7) return _moment2.default.call(void 0, date).format("ddd") // Fri
  if (daysDiff < 365) return _moment2.default.call(void 0, date).format("MMM D") // Aug 2
  return _moment2.default.call(void 0, date).format("ll") // Aug 2 1985
}; exports.getTimeString = getTimeString

/* a modified version of the getTimeString function above.
currently used for displaying dates/times on search items. */
 function getDateString (dateStr) {
  const date = _moment2.default.call(void 0, dateStr)
  const now = _moment2.default.call(void 0, )
  const daysDiff = now.diff(date, "days")

  if (daysDiff < 1) return date.format("LT") /* 4:55 PM */
  if (daysDiff < 2) return "Yesterday"
  if (daysDiff < 8) return _moment2.default.call(void 0, date).format("dddd") /* Sunday */
  return _moment2.default.call(void 0, date).format("MM/DD/YYYY")
} exports.getDateString = getDateString;

 const getDayTimeString = dateStr => _moment2.default.call(void 0, dateStr).format("LT"); exports.getDayTimeString = getDayTimeString

 const formatTimeForAPI = t => _moment2.default.call(void 0, t, _moment2.default.HTML5_FMT.TIME); exports.formatTimeForAPI = formatTimeForAPI

 const BEFORE_OLDEST_PERSON_ON_EARTH_WAS_BORN = new Date('1900-01-01'); exports.BEFORE_OLDEST_PERSON_ON_EARTH_WAS_BORN = BEFORE_OLDEST_PERSON_ON_EARTH_WAS_BORN

 const EPOCH_MILLISECONDS = 0; exports.EPOCH_MILLISECONDS = EPOCH_MILLISECONDS
 const EPOCH = new Date(exports.EPOCH_MILLISECONDS).toISOString(); exports.EPOCH = EPOCH

 const apiDateStrToDate = dateStr => {
  if (exports.isDateNull.call(void 0, dateStr)) return exports.NULL_DATE
  const utc = new Date(dateStr)
  return new Date(utc.getUTCFullYear(), utc.getUTCMonth(), utc.getUTCDate())
}; exports.apiDateStrToDate = apiDateStrToDate

 const areDatesEqual = (a, b) => {
  if (a === b) return true
  if (!a || !b) return false
  return a.getFullYear() === b.getFullYear() &&
    a.getMonth() === b.getMonth() &&
    a.getDate() === b.getDate()
}; exports.areDatesEqual = areDatesEqual

 const isDateNull = d =>
  !d || d === NULL_DATE_STR || exports.areDatesEqual.call(void 0, asDate(d), exports.NULL_DATE); exports.isDateNull = isDateNull

 const isAfter = (a, b) => asDate(a) > asDate(b); exports.isAfter = isAfter

const asDate = (d) => {
  if (!d) return null
  else if (typeof d.getTime === 'function') return d
  else return new Date(d)
}

 const TODAY_DISPLAY_DATE = exports.formatDisplayDate.call(void 0, exports.TODAY); exports.TODAY_DISPLAY_DATE = TODAY_DISPLAY_DATE

 const timezoneNames = {
  'America/Puerto_Rico': 'Atlantic Standard Time (AST)',
  'America/New_York': 'Eastern Time (ET)',
  'America/Chicago': 'Central Time (CT)',
  'America/Denver': 'Mountain Time (MT)',
  'America/Phoenix': 'Mountain Standard Time (MST)',
  'America/Los_Angeles': 'Pacific Time (PT)',
  'America/Anchorage': 'Alaskan Time (AKT)',
  'America/Adak': 'Hawaii-Aleutian Time (HT)',
  'Pacific/Honolulu': 'Hawaii-Aleutian Standard Time (HST)',
}; exports.timezoneNames = timezoneNames

 const getDisplayTZName = tz => exports.timezoneNames[tz]; exports.getDisplayTZName = getDisplayTZName

const getUserTZ = () => {
  try {
    const tz = window.Intl.DateTimeFormat().resolvedOptions().timeZone
    if (exports.timezoneNames[tz]) return tz
  } catch (e) {}

  return 'America/New_York'
}

 const userTZ = getUserTZ(); exports.userTZ = userTZ

 const dayNames =
  ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"]; exports.dayNames = dayNames
