"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/EntityNotFoundScreen.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _TemplateEntityScreen = require('web/screens/TemplateEntityScreen'); var _TemplateEntityScreen2 = _interopRequireDefault(_TemplateEntityScreen);





const EntityNotFoundScreen = ({ desc }) => (
  _react2.default.createElement(_TemplateEntityScreen2.default, {
    iconSrc: "/assets/icons/broken-link-pale-purple.svg",
    desc: desc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
  )
)

exports. default = EntityNotFoundScreen
