"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/App/NavigationMenu/NavigationMenu.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





 const NavigationMenu = ({ children, open, className }) => (
  _react2.default.createElement('div', {
    className: _classBuilder2.default.call(void 0, 
      "app--navigation-menu",
      open && "app--navigation-menu--in-view",
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
  
    ,  children 
  )
); exports.NavigationMenu = NavigationMenu

exports. default = exports.NavigationMenu
