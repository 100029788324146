"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ChatScreen/ChatBox/ChatBox.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _log = require('shared/log');

var _Components = require('./Components');

 class ChatBox extends _react2.default.Component {
  constructor (props) {
    super(props);ChatBox.prototype.__init.call(this);ChatBox.prototype.__init2.call(this);ChatBox.prototype.__init3.call(this);ChatBox.prototype.__init4.call(this);ChatBox.prototype.__init5.call(this);ChatBox.prototype.__init6.call(this);
    this.state = {
      at_bottom: true,
      at_top: false,
    }

    this.hasFocusedOnMessage = false
  }

  componentDidMount () {
    this._scrollToBottom()
    window.addEventListener('resize', this._windowResized)
  }

  componentWillUnmount () {
    clearTimeout(this.windowResizeTimeout)
    window.removeEventListener('resize', this._windowResized)
  }

  componentDidUpdate (prevProps, prevState) {
    const prevMessages = prevProps.messages
    const messages = this.props.messages
    const prevChatBoxKey = prevProps.chatBoxKey
    const chatBoxKey = this.props.chatBoxKey
    const highlightMessageKey = this.props.highlightMessageKey

    // There aren't new messages, so just don't do anything
    if (!messages || messages.length === 0) {
      return
    }

    if (!this.hasFocusedOnMessage && highlightMessageKey) {
      this._focusOnMessage(highlightMessageKey)
      this.hasFocusedOnMessage = true
    }

    if (prevProps.containerHeight !== this.props.containerHeight && this.state.at_bottom) {
      this._scrollToBottom()
    }

    // If we're in a new chat, immediately scroll to the bottom and clear _nextScroll
    if (prevChatBoxKey !== chatBoxKey) {
      // log.info("Scroller: Chat keys are not equal - scrolling to bottom and resetting _nextScroll")
      this._scrollToBottom()
      return
    }

    // If the first message has a null key, we've just sent one and pre-committed it before getting a server response. Scroll to the bottom.
    const newMessageSent = messages[0].MessageKey.includes("temp")
    if (newMessageSent) {
      this._scrollToBottom()
      return
    }

    // Just loaded first messages, so scroll to bottom
    if (prevMessages.length === 0) {
      this._scrollToBottom()
      return
    }

    if (messages.length !== prevMessages.length) {
      // if we just loaded messages above, maintain proper focus
      if (prevMessages[prevMessages.length - 1].MessageKey !== messages[messages.length - 1].MessageKey) {
        this._focusOnMessage(prevMessages[prevMessages.length - 1].MessageKey, true)
        return
      }

      // if we received one new message and we are at bottom, jump to latest message
      if (prevMessages[0].MessageKey !== messages[0].MessageKey && this.state.at_bottom && prevMessages.length + 1 === messages.length) {
        this._scrollToBottom()
        return
      }
    }

    // If read sequences have changed, and previously at bottom, go back to bottom
    if (!this._sameReadSequences(prevProps.readSequence, this.props.readSequence) && this.state.at_bottom) {
      this._scrollToBottom()
    }
  }

  __init() {this._sameReadSequences = (previous, current) => {
    return Object.keys(current).every((k) => current[k] === previous[k])
  }}

  __init2() {this._windowResized = () => {
    // Throttle callback handling as window resize event fires very rapidly
    if (this.currentlyResizing !== true) {
      const cachedAtBottom = this.state.at_bottom
      this.currentlyResizing = true
      this.windowResizeTimeout = setTimeout(() => {
        if (cachedAtBottom) {
          this._scrollToBottom()
        }

        this.currentlyResizing = false
      }, 70)
    }
  }}

  _scrollToBottom () {
    this.messagesEnd.scrollIntoView({ behavior: "instant" })
  }

  __init3() {this._topWaypointEnter = (e) => {
    if (this.props.topWaypointEnter) {
      this.props.topWaypointEnter()
    }
    this.setState({ at_top: true })
  }}

  __init4() {this._topWaypointLeave = (e) => {
    this.setState({ at_top: false })
  }}

  __init5() {this._bottomWaypointEnter = (e) => {
    if (this.props.bottomWaypointEnter) {
      this.props.bottomWaypointEnter()
    }
    this.setState({ at_bottom: true })
  }}

  __init6() {this._bottomWaypointLeave = (e) => {
    this.setState({ at_bottom: false })
  }}

  sameDate (a, b) {
    const d1 = new Date(a)
    const d2 = new Date(b)

    return d1.getFullYear() === d2.getFullYear() &&
      d1.getMonth() === d2.getMonth() &&
      d1.getDate() === d2.getDate()
  }

  _focusOnMessage (key, alignToTop = false) {
    const message = document.getElementById(key)
    if (!message) {
      _log.log.info(`error, can't find message with key ${key}`)
      return
    }
    alignToTop
      ? message.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'instant' })
      : message.scrollIntoView({ behavior: 'instant' })
  }

  renderMessages () {
    const feedItems = []
    let currentPersonGroup = []
    let currentDayGroup = [currentPersonGroup]
    let lastMessage

    /* The base chatbox properties. */
    const sharedProps = {
      participants: this.props.participants,
      readSequence: this.props.readSequence,
      currentUserKey: this.props.currentUserKey,
    }

    // Go in reverse through messages to know when to insert dates
    this.props.messages.forEach((message, index) => {
      // add highlighted property
      message.highlight = message.MessageKey === this.props.highlightMessageKey
      if (this.props.messages.length === 1) {
        feedItems.unshift(_react2.default.createElement(_Components.MessageDayGroup, { messages: [[message]], key: message.MessageKey, ...sharedProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 173}} ))
        return
      }

      // First message in set
      if (lastMessage == null) {
        currentPersonGroup.unshift(message)
        // If day changed
      } else if (!this.sameDate(message.SentTimestamp, lastMessage.SentTimestamp)) {
        const key = currentDayGroup[0].MessageKey || currentDayGroup[0][0].MessageKey || index
        feedItems.unshift(_react2.default.createElement(_Components.MessageDayGroup, { messages: currentDayGroup, key: key, ...sharedProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}} ))
        currentPersonGroup = [message]
        currentDayGroup = [currentPersonGroup]
        // If different person or file sent
      } else if (message.FromUserKey !== lastMessage.FromUserKey || lastMessage.FileInfo != null || message.FileInfo != null) {
        currentPersonGroup = [message]
        currentDayGroup.unshift(currentPersonGroup)
        // Message from same person as last message
      } else {
        currentPersonGroup.unshift(message)
      }

      // Last message
      if (index === this.props.messages.length - 1) {
        const key = currentDayGroup[0].MessageKey || currentDayGroup[0][0].MessageKey || index
        feedItems.unshift(_react2.default.createElement(_Components.MessageDayGroup, { messages: currentDayGroup, key: key, ...sharedProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 198}} ))
        return
      }

      lastMessage = message
    })

    return feedItems
  }

  render () {
    return (
      _react2.default.createElement('div', { className: "chatbox", __self: this, __source: {fileName: _jsxFileName, lineNumber: 210}}
        , _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: this._topWaypointEnter, onLeave: this._topWaypointLeave, __self: this, __source: {fileName: _jsxFileName, lineNumber: 211}} )
        , this.renderMessages()
        , _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: this._bottomWaypointEnter, onLeave: this._bottomWaypointLeave, __self: this, __source: {fileName: _jsxFileName, lineNumber: 213}} )
        , _react2.default.createElement('div', { id: "messagesEnd", ref: (el) => this.messagesEnd = el, __self: this, __source: {fileName: _jsxFileName, lineNumber: 214}} )
      )
    )
  }
} exports.ChatBox = ChatBox;

/* We define all the data we need to actually render the chatbox. This is useful not just
 * for having a clean interface, but since the component can then be plugged into any part of the
 * app (e.g. unauthenticated patient chats) with a simple interface to the backend.
*/
ChatBox.propTypes = {
  /* List of all members of the chat. Each key corresponds to a user object of some sort. Should have
   * a "FirstName" and "LastName", "Name", and options "showTextAvatar"
   */
  participants: _proptypes2.default.object.isRequired,

  /* Using the same keys as the participants object, where each map to the latest message that
   * participant has read.
   */
  readSequence: _proptypes2.default.object.isRequired,

  /* The list of all messages, ordered by latest timestamp last. Messages must also have a sequence
   * number associated with them.
   */
  messages: _proptypes2.default.arrayOf(_proptypes2.default.shape({
    sequenceNumber: _proptypes2.default.number.isRequired,
    TextContent: _proptypes2.default.oneOfType([_proptypes2.default.string, _proptypes2.default.element]),
    SentTimestamp: _proptypes2.default.oneOfType([_proptypes2.default.string, _proptypes2.default.number]).isRequired, /* unix timestamp or standardized time string */
    MessageKey: _proptypes2.default.string,
    Type: _proptypes2.default.string,
    FromUserKey: _proptypes2.default.string, /* note this doesn't have to be a user, just an object identifiable in the participants object */
  })).isRequired,

  /* A primary key used to identify the chatbox. Used to decide re-renders */
  chatBoxKey: _proptypes2.default.string.isRequired,

  /* Optional, but useful for authenticated chat. Identify the current user. */
  currentUserKey: _proptypes2.default.string,

  /* Intercept the top and bottom entry */
  topWaypointEnter: _proptypes2.default.func,
  bottomWaypointEnter: _proptypes2.default.func,

  /* Get hints of changes in container height. If we're already scrolled to bottom
     and container height changes, we should continue to be 'locked' to bottom */
  containerHeight: _proptypes2.default.number,

  highlightMessageKey: _proptypes2.default.string,
}
