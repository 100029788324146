"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/DirectoryCreateNewModal/CreateNewCoworker.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _invite = require('shared/services/inviteContact/invite');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _session = require('shared/selectors/session');
var _noticeBar = require('web/services/context/noticeBar');

var _Forms = require('web/screens/Directory/Forms');
var _OrgLimitNotice = require('web/components/Upgrade/OrgLimitNotice'); var _OrgLimitNotice2 = _interopRequireDefault(_OrgLimitNotice);






const CreateNewCoworker = ({ orgKey, onClose }) => {
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    async data => {
      const withNotice = (p) => p
        .then(() => showSuccessBar('Invite sent'))
        .catch(() => showWarningBar('Could not send invitation'))

      if (!orgKey) {
        return withNotice(
          _actions2.default.inviteUserNoOrg({ Email: data.email }).then(onClose),
        )
      }

      return withNotice(
        _invite.inviteCoworker.call(void 0, data, orgKey)
          .then(({ UserKey }) => {
            onClose()
            _actions2.default.gotoUserPage(orgKey, UserKey)
          }),
      )
    },
    [onClose, orgKey, showWarningBar, showSuccessBar],
  )

  if (!orgKey) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_OrgLimitNotice2.default, { orgKey: orgKey, addedUsersCount: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
      , _react2.default.createElement(_Forms.CoworkerForm, {
        submitBtnLabel: "Invite",
        orgKeyContext: orgKey,
        onCancel: onClose,
        onSubmit: onSubmit,
        controlsClassName: "invite-contact--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      )
    )
  )
}





const mapState = (state, { orgKeyContext }) => ({
  orgKey: orgKeyContext || _session.selectSelectedOrgKey.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewCoworker)
