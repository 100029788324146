"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _IncompatibleBrowser = require('web/components/VideoCall/IncompatibleBrowser'); var _IncompatibleBrowser2 = _interopRequireDefault(_IncompatibleBrowser);
var _Permissions = require('web/components/VideoCall/Permissions'); var _Permissions2 = _interopRequireDefault(_Permissions);
var _PreCall = require('web/components/VideoCall/PreCall'); var _PreCall2 = _interopRequireDefault(_PreCall);
var _OnCall = require('web/components/VideoCall/OnCall'); var _OnCall2 = _interopRequireDefault(_OnCall);
var _Feedback = require('web/components/VideoCall/Feedback'); var _Feedback2 = _interopRequireDefault(_Feedback);
var _Setup = require('web/components/VideoCall/Setup'); var _Setup2 = _interopRequireDefault(_Setup);








exports.IncompatibleBrowser = _IncompatibleBrowser2.default; exports.Permissions = _Permissions2.default; exports.PreCall = _PreCall2.default; exports.OnCall = _OnCall2.default; exports.Feedback = _Feedback2.default; exports.Setup = _Setup2.default;
