"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _helpers = require('./helpers');
var _redux = require('redux');
var _store = require('shared/store');
var _utils = require('shared/store/utils');

















var _uuid = require('shared/services/uuid');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);














const markConversationVisibleAction = _utils.createAction('Mark conversation visible')
const saveConversationDraftAction = _utils.createAction('Save conversation draft')
const pruneLocalMessageAction = _utils.createAction('Prune local message')

const startVideoChatActions = _utils.createAsyncActions('StartVideoChat')
const inviteVideoChatSmsActions = _utils.createAsyncActions('InviteVideoChatSms')
const inviteVideoChatEmailActions = _utils.createAsyncActions('InviteVideoChatEmail')
const loadConversationActions = _utils.createAsyncActions('LoadConversation')
const loadConversationAroundTimeActions =
  _utils.createAsyncActions('LoadConversationAroundTime')
const loadConversationAroundMessageActions =
  _utils.createAsyncActions('LoadConversationAroundMessage')
const sendMessageToConversationActions =
  _utils.createAsyncActions('SendMessageToConversation')
const sendFileToConversationActions =
  _utils.createAsyncActions('SendMessageToConversation (File)')
const deleteMessageFromConversationActions =
  _utils.createAsyncActions('DeleteMessageFromConversation')
const addUserToConversationActions =
  _utils.createAsyncActions('AddUserToConversation')
const addTeamToConversationActions =
  _utils.createAsyncActions('AddTeamToConversation')
const uploadFileActions = _utils.createAsyncActions('HTTP file upload')

 const actionCreators = {
  markConversationVisibleAction,
  saveConversationDraftAction,
  pruneLocalMessageAction,
  startVideoChatActions,
  inviteVideoChatSmsActions,
  inviteVideoChatEmailActions,
  loadConversationActions,
  loadConversationAroundTimeActions,
  loadConversationAroundMessageActions,
  sendMessageToConversationActions,
  sendFileToConversationActions,
  deleteMessageFromConversationActions,
  addUserToConversationActions,
  addTeamToConversationActions,
  uploadFileActions,
}; exports.actionCreators = actionCreators

const startVideoChat = () =>
  _helpers.wsCommand('StartVideoChat', startVideoChatActions, {}, null)

const inviteVideoChatSms = (req) =>
  _helpers.wsCommand('InviteVideoChatSms', inviteVideoChatSmsActions, req, null)

const inviteVideoChatEmail = (req) =>
  _helpers.wsCommand('InviteVideoChatEmail', inviteVideoChatEmailActions, req, null)

const loadConversation = (req) =>
  _helpers.wsCommand('LoadConversation', loadConversationActions, req, null)

const loadConversationAroundTime = (req) =>
  _helpers.wsCommand(
    'LoadConversationAroundTime',
    loadConversationAroundTimeActions,
    req,
    null,
  )

const loadConversationAroundMessage = (req) =>
  _helpers.wsCommand(
    'LoadConversationAroundMessage',
    loadConversationAroundMessageActions,
    req,
    null,
  )

const sendConversationMessage = (
  ConversationKey,
  TextContent,
  FileKey,
  messageKey,
) => {
  const isRetrying = !!messageKey
  const MessageKey = (messageKey || _uuid.uuidv4.call(void 0, )) 

  return _helpers.wsCommand(
    'SendMessageToConversation',
    sendMessageToConversationActions,
    { ConversationKey, MessageKey, TextContent, FileKey },
    { ConversationKey, MessageKey, isRetrying },
  )
}

const deleteMessageFromConversation = (MessageKey, ConversationKey) =>
  _helpers.wsCommand(
    'DeleteMessageFromConversation',
    deleteMessageFromConversationActions,
    { MessageKey },
    { ConversationKey },
  )

const rejoinConversation = (
  req,
) => 'TeamKey' in req
  ? _helpers.wsCommand('AddTeamToConversation', addTeamToConversationActions, req, null)
  : _helpers.wsCommand('AddUserToConversation', addUserToConversationActions, req, null)

const boundActions = _redux.bindActionCreators.call(void 0, {
  markConversationVisible: markConversationVisibleAction,
  saveConversationDraft: saveConversationDraftAction,
  pruneLocalMessage: pruneLocalMessageAction,
}, _store.dispatch)

const sentFiles = {}
const uploadAbortControllers = {}

const uploadFile = async (
  file,
  MessageKey,
  ConversationKey,
  isRetrying,
) => {
  let controller
  const meta = { MessageKey, isRetrying }

  _store.dispatch.call(void 0, uploadFileActions.REQUEST({ file, ConversationKey }, meta))

  try {
    controller = new AbortController()
  } catch (e) { controller = { signal: {} , abort: () => null } }

  uploadAbortControllers[MessageKey] = controller

  const r = await _client2.default.uploadFile(file, controller.signal) 
  _store.dispatch.call(void 0, uploadFileActions.SUCCESS(r, meta))

  return r
}

const sendConversationFile = async (
  ConversationKey,
  rawFile,
  messageKey,
  fileKey,
) => {
  const isRetrying = !!messageKey
  const MessageKey = (messageKey || _uuid.uuidv4.call(void 0, )) 
  const file = isRetrying ? sentFiles[MessageKey] : rawFile

  sentFiles[MessageKey] = file

  const sendUploadedFile = ({ FileKey }) =>
    _helpers.wsCommand(
      'SendMessageToConversation',
      sendFileToConversationActions,
      { ConversationKey, MessageKey, FileKey },
      { ConversationKey, MessageKey },
    )
      .then(() => {
        sentFiles[MessageKey] = null
        uploadAbortControllers[MessageKey] = null
      })

  if (fileKey) return sendUploadedFile({ FileKey: fileKey })

  return uploadFile(file, MessageKey, ConversationKey, isRetrying)
    .then(sendUploadedFile)
    .catch((err) => _store.dispatch.call(void 0, 
      sendFileToConversationActions.FAILURE(
        err,
        { MessageKey, ConversationKey, isAborted: err.name === 'AbortError' },
      ),
    ))
}

const abortSendConversationFile = (messageKey) => {
  const ctrl = uploadAbortControllers[messageKey]
  if (ctrl) ctrl.abort()
  uploadAbortControllers[messageKey] = null
}

exports. default = {
  ...boundActions,
  startVideoChat,
  inviteVideoChatSms,
  inviteVideoChatEmail,
  loadConversation,
  loadConversationAroundTime,
  loadConversationAroundMessage,
  sendConversationMessage,
  deleteMessageFromConversation,
  rejoinConversation,
  sendConversationFile,
  abortSendConversationFile,
}
