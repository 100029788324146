"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/PatientAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);






const PatientAvatar = ({
  patientKey,
  noBadge,
  ...props
}) => (
  _react2.default.createElement(_BaseAvatar2.default, {
    isPatient: !noBadge,
    backgroundColorKey: patientKey,
    icon: "/assets/icons/avatar/icon-person.svg",
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  )
)

exports. default = PatientAvatar
