"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/OrgsList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');








var _myRelations = require('shared/selectors/myRelations');

var _noticeBar = require('web/services/context/noticeBar');
var _joinOrg = require('shared/hooks/org/joinOrg');
var _error = require('shared/services/error');
var _PageLayout = require('web/components/Settings/PageLayout'); var _PageLayout2 = _interopRequireDefault(_PageLayout);



var _OrgsList = require('web/components/Settings/OrgsList');







const OrgsListPage = ({
  memberOrgs,
  gotoOrg,
  gotoSettingsRoot,
  hasCurrentUserAnyTeams,
}) => {
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleJoinOrCreateOrg = _react.useCallback.call(void 0, 
    data => _joinOrg.joinOrg.call(void 0, data)
      .then(org => {
        if (!org) return showSuccessBar('Requested to join organization')
        showSuccessBar(`Successfully created Organization: ${org.Name}`)
        gotoOrg(org.OrgKey)
      })
      .catch(_error.passError.call(void 0, () => showWarningBar(
        typeof data.org === 'string'
          ? 'Failed to create organization'
          : 'Failed to join organization',
      ))),
    [gotoOrg, showWarningBar, showSuccessBar],
  )

  return (
    _react2.default.createElement(_PageLayout2.default, { title: "Organizations", onBack: gotoSettingsRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      , _react2.default.createElement(_OrgsList.OrgsListSection, { orgsList: memberOrgs, gotoOrg: gotoOrg, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
      , _react2.default.createElement(_OrgsList.OrgsControlsSection, {
        onJoinOrCreateOrg: handleJoinOrCreateOrg,
        hasCurrentUserAnyTeams: hasCurrentUserAnyTeams, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      )
    )
  )
}





const mapState = (state) => ({
  hasCurrentUserAnyTeams: _myRelations.selectHasCurrentUserAnyTeams.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(OrgsListPage)
