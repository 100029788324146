"use strict";Object.defineProperty(exports, "__esModule", {value: true});




















const patientChatAvatar = ({
  patientKey,

  userSubscribersList,
  teamSubscribersList,

  layoutProps,
  renderUserAvatar,
  renderTeamAvatar,
  renderPatientAvatar,
  renderGroupAvatar,
}) => {
  const usersCount = userSubscribersList.length
  const teamsCount = teamSubscribersList.length

  if (usersCount < 2 && !teamsCount) {
    return renderPatientAvatar({ patientKey, ...layoutProps })
  }

  const participantNumber = teamsCount + usersCount

  const getBackAvatar = () => {
    if (teamsCount) {
      return teamSubscribersList[0]
        ? renderTeamAvatar({
          teamKey: teamSubscribersList[0].TeamKey,
          participantNumber,
        })
        : null
    }
    return userSubscribersList[0]
      ? renderUserAvatar({
        userKey: userSubscribersList[0].UserKey,
        participantNumber,
      })
      : null
  }

  return renderGroupAvatar({
    frontAvatar: renderPatientAvatar({ patientKey }),
    backAvatar: getBackAvatar(),
    ...layoutProps,
  })
}

exports. default = patientChatAvatar
