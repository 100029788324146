"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/JoinMeetingBtnContent.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

const JoinMeetingBtnContent = ({ isCamOn, isMicOn }) => {
  if (isCamOn) {
    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('img', { src: "/assets/icons/video/cam-white.svg", alt: ">", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}} ), "Join with video"

      )
    )
  }

  if (isMicOn) {
    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('img', { src: "/assets/icons/video/phone-white.svg", alt: ">", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}} ), "Join with audio only"

      )
    )
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('img', { src: "/assets/icons/video/out-white.svg", alt: ">", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} ), "Join the meeting"

    )
  )
}

JoinMeetingBtnContent.propTypes = {
  isCamOn: _proptypes2.default.bool.isRequired,
  isMicOn: _proptypes2.default.bool.isRequired,
}

exports. default = JoinMeetingBtnContent
