"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/InviteContactModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _contact = require('shared/enum/contact');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);

const titles = {
  [_contact.CONTACT_REL_TYPES.COWORKER]: `Invite a coworker`,
  [_contact.CONTACT_REL_TYPES.EXTERNAL]: 'Invite an external collaborator',
  [_contact.CONTACT_REL_TYPES.PATIENT]: `Add a patient`,
}

const InviteContactModal = (props) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: props.isOpen,
    title: props.contactRelType ? titles[props.contactRelType] : '',
    className: "invite-contact--modal",
    onClose: props.onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
  
    , _react2.default.createElement(_Form2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
  )
)

exports. default = InviteContactModal
