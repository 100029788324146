"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/FirstTimeInteractables/EnableQuickLoginPopup/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');




var _quickLogin = require('shared/validation/user/quickLogin');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

var _Modal = require('web/components/Modal');
var _Form = require('web/components/Form');
var _noticeBar = require('web/services/context/noticeBar');





 const PRIMARY_MSG = `Enter a ${_quickLogin.PIN_LENGTH}-digit PIN code.`; exports.PRIMARY_MSG = PRIMARY_MSG
 const REPEAT_MSG = 'Repeat your PIN'; exports.REPEAT_MSG = REPEAT_MSG
 const MISMATCH_MSG = 'PINs don\'t match. Please try again'; exports.MISMATCH_MSG = MISMATCH_MSG

const EnableQuickLoginForm = ({ onClose }) => {
  const { control, watch, formState, setValue, handleSubmit } =
    _reacthookform.useForm({
      resolver: _quickLogin.quickLoginWithConfirmationResolver,
      mode: 'onChange',
      defaultValues: { isEnabled: true },
    })
  const { isValid, isSubmitting } = formState
  const [pin, pinConfirmation] = watch(['pin', 'pinConfirmation'])

  const [warningMsg, setWarningMsg] = _react.useState.call(void 0, exports.PRIMARY_MSG)
  _react.useLayoutEffect.call(void 0, 
    () => {
      if (_optionalChain([pin, 'optionalAccess', _ => _.length]) === _quickLogin.PIN_LENGTH) setWarningMsg(exports.REPEAT_MSG)
      if (_optionalChain([pinConfirmation, 'optionalAccess', _2 => _2.length]) === _quickLogin.PIN_LENGTH && pin !== pinConfirmation) {
        setValue('pin', '')
        setValue('pinConfirmation', '')
        setWarningMsg(exports.MISMATCH_MSG)
      }
    },
    [pin, pinConfirmation, setValue],
  )

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit =
    async ({ pin, isEnabled }) => {
      const activate = () => _actions2.default.activateQuickLogin({ Pin: pin })
      const approve = () => _actions2.default.approveQuickLoginDevice()

      return Promise.all(isEnabled ? [activate(), approve()] : [activate()])
        .then(onClose)
        .then(() => showSuccessBar('Quick Login has been enabled'))
        .catch(() => showWarningBar('Failed to enable Quick Login'))
    }

  const activePin = _optionalChain([pin, 'optionalAccess', _3 => _3.length]) === _quickLogin.PIN_LENGTH ? 'pinConfirmation' : 'pin'

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('img', {
        className: "enable-quick-login-modal--icon",
        src: "/assets/icons/lock-purple.svg",
        alt: "Lock", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
      )
      , _react2.default.createElement('h3', { className: "enable-quick-login-modal--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "Enable Quick Login"

      )
      , _react2.default.createElement('span', { className: "enable-quick-login-modal--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Quick Login lets you log in to BloomText using a "
                  , _quickLogin.PIN_LENGTH, "-digit", ' ', "PIN code on devices you trust, making it easier to log in and switch"
                     , ' ', "users on shared machines at your practice."

      )
      , _react2.default.createElement('div', { className: "enable-quick-login-modal--pin-form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        , _react2.default.createElement('span', { className: "enable-quick-login-modal--pin-form--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
          ,  warningMsg 
        )
        , _react2.default.createElement(_Form.PinInputController, {
          autoFocus: true,
          key: activePin,
          pinLength: _quickLogin.PIN_LENGTH,
          control: control,
          name: activePin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        )
        , _react2.default.createElement('label', { className: "enable-quick-login-modal--pin-form--trust-checkbox", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          , _react2.default.createElement(_Form.CheckboxController, { name: "isEnabled", control: control, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} )
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, "Use Quick Login on the current device."      )
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose,
        primaryBtnLabel: "Turn on Quick Login"   ,
        isPrimaryEnabled: isValid,
        isPrimaryLoading: isSubmitting,
        onPrimaryBtnClick: handleSubmit(onSubmit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      )
      , _react2.default.createElement('span', { className: "enable-quick-login-modal--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}, "You can change this later in your BloomText Settings."

      )
    )
  )
}

exports. default = EnableQuickLoginForm
