"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/Username/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactrouterdom = require('react-router-dom');

var _event = require('shared/services/event');
var _login = require('shared/validation/login');

var _Form = require('web/components/Form');
var _PasswordInput = require('web/components/PasswordInput'); var _PasswordInput2 = _interopRequireDefault(_PasswordInput);
var _components = require('web/screens/Login/components');












const humanizeErrorMessage = (err) => {
  const errorsMap = {
    "Pending Org": "Sorry, you won't be able to log in until your site administrator approves your account.",
    "Unverified Email": "Your email isn't verified yet. Check your inbox for a message that will let you verify it and try logging in again.",
  }
  const defaultErrMessage = "Invalid email or password."
  return err && errorsMap[err] || defaultErrMessage
}

const UsernameLogin = ({
  onUsernameLogin,
  forgotPasswordRoute,
  isQuickLoginAvailable,
  gotoQuickLogin,
  gotoExpiredPassword,
  prefilledEmail,
}) => {
  const { control, register, setError, handleSubmit, formState } =
    _reacthookform.useForm({
      resolver: _login.loginResolver,
      mode: 'onChange',
      defaultValues: { email: prefilledEmail },
    })
  const { errors, isValid, isSubmitting } = formState

  const submit = async (data, e) => {
    _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])
    return onUsernameLogin(data.email, data.password)
      .catch(e => {
        if (_optionalChain([e, 'access', _3 => _3.response, 'optionalAccess', _4 => _4.Error]) === 'Expired Password') {
          return gotoExpiredPassword()
        }
        setError(
          'password',
          { message: humanizeErrorMessage(_optionalChain([e, 'access', _5 => _5.response, 'optionalAccess', _6 => _6.Error])) },
        )
      })
  }

  return (
    _react2.default.createElement(_components.FormWrapper, { title: "Log in" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      , _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
        , _react2.default.createElement(_Form.InputSection, {
          title: "Email",
          inputType: "email",
          autoFocus: true,
          autoComplete: "username email" ,
          placeholder: "Email",
          className: "login--username--section",
          errorMessage: _optionalChain([errors, 'access', _7 => _7.email, 'optionalAccess', _8 => _8.message]),
          inputProps: register('email'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
        , _react2.default.createElement(_Form.InputSectionWrapper, {
          title: "Password",
          errorMessage: _optionalChain([errors, 'access', _9 => _9.password, 'optionalAccess', _10 => _10.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        
          , _react2.default.createElement(_reacthookform.Controller, {
            name: "password",
            control: control,
            render: ({ field, fieldState }) => (
              _react2.default.createElement(_PasswordInput2.default, {
                inputId: "password",
                autoComplete: "current-password",
                placeholder: "Password",
                hasError: !!fieldState.error,
                value: field.value,
                onChange: (_id, value) => field.onChange(_event.makeChangeEvent.call(void 0, value)), __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
              )
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          )
        )
        , _react2.default.createElement(_Form.Button, {
          isSubmit: true,
          isDisabled: !isValid,
          isLoading: isSubmitting,
          className: "login--username-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        , "Log in"

        )
        , _react2.default.createElement('div', { className: "login--username--links-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
          , _react2.default.createElement(_reactrouterdom.Link, { to: forgotPasswordRoute, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}, "Forgot password?" )
          ,  isQuickLoginAvailable &&
            _react2.default.createElement('span', { role: "button", onClick: gotoQuickLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}, "Log in with PIN code"    )
          
        )
      )
    )
  )
}

exports. default = UsernameLogin
