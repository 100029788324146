"use strict";Object.defineProperty(exports, "__esModule", {value: true});var CONTACT_TYPES; (function (CONTACT_TYPES) {
  const EMAIL = 'email'; CONTACT_TYPES["EMAIL"] = EMAIL;
  const PHONE = 'phone'; CONTACT_TYPES["PHONE"] = PHONE;
})(CONTACT_TYPES || (exports.CONTACT_TYPES = CONTACT_TYPES = {}));

var CONTACT_REL_TYPES; (function (CONTACT_REL_TYPES) {
  const COWORKER = 'contact_type_coworker'; CONTACT_REL_TYPES["COWORKER"] = COWORKER;
  const EXTERNAL = 'contact_type_external'; CONTACT_REL_TYPES["EXTERNAL"] = EXTERNAL;
  const PATIENT = 'contact_type_patient'; CONTACT_REL_TYPES["PATIENT"] = PATIENT;
})(CONTACT_REL_TYPES || (exports.CONTACT_REL_TYPES = CONTACT_REL_TYPES = {}));
