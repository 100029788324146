"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/EmojiPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _noticeBar = require('web/services/context/noticeBar');

var _emojimart = require('emoji-mart');










let emojiData = null
const loadEmojis = async () => {
  if (emojiData) return emojiData

  const res = await fetch('https://cdn.jsdelivr.net/npm/@emoji-mart/data')
    .then(r => r.json())

  emojiData = res
  return emojiData
}

 const lookupEmoji = (x) => {
  if (!emojiData) return null
  const { emojis, aliases } = emojiData
  const e = emojis[x] || aliases[x] && emojis[aliases[x]]

  return e ? _optionalChain([e, 'access', _ => _.skins, 'access', _2 => _2[0], 'optionalAccess', _3 => _3.native]) : null
}; exports.lookupEmoji = lookupEmoji

const EmojiPicker = ({ onSelect }) => {
  const ref = _react.useRef(null)
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  _react.useEffect.call(void 0, 
    () => {
      loadEmojis()
        .then(data => new (0, _emojimart.Picker)({
          data,
          ref,
          onEmojiSelect: (e) => _optionalChain([onSelect, 'optionalCall', _4 => _4(e.native)]),
        }))
        .catch(() => showWarningBar('Failed to load emojis'))
    },
    [onSelect, showWarningBar],
  )

  return _react2.default.createElement('div', { ref: ref, onClick: e => e.stopPropagation(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
}

exports. default = EmojiPicker
