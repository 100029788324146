"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/AdminPreview.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _AdminPreview = require('web/components/VideoRooms/Room/AdminPreview'); var _AdminPreview2 = _interopRequireDefault(_AdminPreview);
var _user = require('shared/selectors/user');
var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');
var _team = require('shared/selectors/team');

const AdminUserContainer = ({ user, orgName, isMe, onRemove }) => !user
  ? null
  : (
    _react2.default.createElement(_AdminPreview2.default, {
      adminEntity: user,
      isMe: isMe,
      orgName: orgName,
      onRemove: onRemove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
    )
    )

const AdminTeamContainer = ({ onRemove, team, orgName }) => !team
  ? null
  : (
    _react2.default.createElement(_AdminPreview2.default, {
      adminEntity: team,
      orgName: orgName,
      onRemove: onRemove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    )
    )

const mapUserState = (state, { userKey, orgKey }) => {
  const user = _peers.selectUserByKey.call(void 0, state, userKey) || {}
  const org = _org.selectOrgByKey.call(void 0, orgKey, state) || {}
  const orgName = org.Name
  return {
    user,
    orgName,
    isMe: _user.selectCurrentUserKey.call(void 0, state) === userKey,
  }
}

const mapTeamState = (state, { teamKey }) => {
  const team = _team.selectTeamByKey.call(void 0, state, teamKey) || {}
  const org = _org.selectOrgByKey.call(void 0, team.OrgKey, state) || {}
  const orgName = org.Name

  return {
    team: _team.selectTeamByKey.call(void 0, state, teamKey),
    orgName,
  }
}

 const AdminUserPreview = _reactredux.connect.call(void 0, mapUserState)(AdminUserContainer); exports.AdminUserPreview = AdminUserPreview
 const AdminTeamPreview = _reactredux.connect.call(void 0, mapTeamState)(AdminTeamContainer); exports.AdminTeamPreview = AdminTeamPreview
