"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/PatientPage/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');







var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);



var _org = require('shared/selectors/org');
var _peers = require('shared/selectors/peers');
var _user = require('shared/selectors/user');



var _entities = require('shared/transforms/entities');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _useEntityPreload = require('shared/hooks/useEntityPreload'); var _useEntityPreload2 = _interopRequireDefault(_useEntityPreload);
var _noticeBar = require('web/services/context/noticeBar');



var _Header = require('web/components/RightSide/Header');
var _RightSide = require('web/components/RightSide');
var _DirectoryPage = require('web/screens/Directory/DirectoryPage');
var _EntityNotFoundScreen = require('web/screens/EntityNotFoundScreen'); var _EntityNotFoundScreen2 = _interopRequireDefault(_EntityNotFoundScreen);
var _Avatar = require('web/components/Avatar');
var _InfoSection = require('./InfoSection'); var _InfoSection2 = _interopRequireDefault(_InfoSection);
var _RecentChatsSection = require('web/screens/Directory/RecentChatsSection'); var _RecentChatsSection2 = _interopRequireDefault(_RecentChatsSection);
var _Forms = require('web/screens/Directory/Forms');








const PatientPage = ({
  patientKey,
  patientEntity,
  orgName,
  onStartChat,
  hasPatientMessaging,
  onRequestPatientsFeature,
  amIAdmin,
}) => {
  const {
    isOpen: isEditFormOpen,
    onOpen: onOpenEditForm,
    onClose: onCloseEditForm,
  } = _useToggle2.default.call(void 0, )

  const loadPatient =
    _react.useCallback.call(void 0, () => _actions2.default.getPatient({ PatientKey: patientKey }), [patientKey])

  const loadRecentChats = _react.useCallback.call(void 0, 
    (_query, Offset, Limit) => _actions2.default
      .getInboxForPatient({ PatientKey: patientKey, Offset, Limit }),
    [patientKey],
  )

  const hasLoadFailed = _useEntityPreload2.default.call(void 0, loadPatient)

  const onNewChat = _react.useMemo.call(void 0, 
    () => {
      if (!hasPatientMessaging && !amIAdmin) return null
      return hasPatientMessaging
        ? patientEntity && (() => onStartChat(patientEntity))
        : onRequestPatientsFeature
    },
    [
      hasPatientMessaging, amIAdmin,
      onRequestPatientsFeature,
      onStartChat, patientEntity,
    ],
  )

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    ({ firstName, lastName, dob, email, phone, foreignID }) =>
      _actions2.default.updatePatient({
        PatientKey: patientKey,
        FirstName: firstName,
        LastName: lastName,
        DateOfBirth: dob,
        Email: email,
        Phone: phone,
        ForeignID: foreignID,
      })
        .then(onCloseEditForm)
        .then(() => showSuccessBar('Patient information updated'))
        .catch(() => showWarningBar('Failed to update patient information')),
    [patientKey, onCloseEditForm, showSuccessBar, showWarningBar],
  )

  if (hasLoadFailed) return _react2.default.createElement(_EntityNotFoundScreen2.default, { desc: "Failed to load patient"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}} )
  if (!patientEntity) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, { key: patientKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      , _react2.default.createElement(_Header.RightSideHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
        , _react2.default.createElement(_Header.HeaderMobileBackButton, { onClick: _actions2.default.gotoDirectory, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}} )
        , _react2.default.createElement(_Avatar.PatientAvatar, { patientKey: patientKey, size: 46, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}} )
        , _react2.default.createElement(_Header.RightSideHeaderHeading, {
          title: patientEntity.name || patientEntity.description,
          subtitle: orgName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
        )
        ,  onNewChat && (
          _react2.default.createElement(_Header.HeaderItem, {
            iconName: "chat",
            className: "blmh-mobile-hide",
            onClick: onNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
          )
        )
      )
      , _react2.default.createElement(_RightSide.RightSideBody, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
        , _react2.default.createElement(_DirectoryPage.DirectoryPage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
          , _react2.default.createElement('div', { className: "directory-page--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "General Information"

          )
          , _react2.default.createElement('div', { className: "directory-page--section directory--patient--section" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
            ,  isEditFormOpen
              ? (
                  _react2.default.createElement(_Forms.PatientForm, {
                    patientKey: patientKey,
                    onSubmit: onSubmit,
                    onCancel: onCloseEditForm,
                    submitBtnLabel: "Save",
                    controlsClassName: "directory-page--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
                  )
                )
              : (
                  _react2.default.createElement(_InfoSection2.default, {
                    patientKey: patientKey,
                    patientName: patientEntity.name,
                    orgName: orgName,
                    dob: _optionalChain([patientEntity, 'access', _ => _.wrappedEntity, 'optionalAccess', _2 => _2.DateOfBirth]),
                    phone: _optionalChain([patientEntity, 'access', _3 => _3.wrappedEntity, 'optionalAccess', _4 => _4.Phone]),
                    email: _optionalChain([patientEntity, 'access', _5 => _5.wrappedEntity, 'optionalAccess', _6 => _6.Email]),
                    foreignID: _optionalChain([patientEntity, 'access', _7 => _7.wrappedEntity, 'optionalAccess', _8 => _8.ForeignID]),
                    onEdit: onOpenEditForm, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
                  )
                )
            
          )
          , _react2.default.createElement(_RecentChatsSection2.default, {
            loadItemsList: loadRecentChats,
            onNewChat: onNewChat,
            entityName: patientEntity.name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
          )
        )
      )
    )
  )
}








const mapState = (state, { patientKey, orgKey }) => {
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgName = _optionalChain([org, 'optionalAccess', _9 => _9.Name]) || ''
  const patient = _peers.selectPatientByKey.call(void 0, state, patientKey)
  const patientEntity = patient
    ? _entities.mapPatientToEntity.call(void 0, {}, (0, _entities.makeContactPatientDescription))(patient)
    : null
  const hasPatientMessaging = _org.selectOrgHasPatientMessagingFeature.call(void 0, state, orgKey)
  const amIAdmin = _user.selectAmIAdminInOrg.call(void 0, state, orgKey)

  return { hasPatientMessaging, amIAdmin, orgName, patientEntity }
}

exports. default = _reactredux.connect.call(void 0, mapState)(PatientPage)
