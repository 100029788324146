"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/MembersSection/AddMembersModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);

const AddMembersModal = (props) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: props.isOpen,
    onClose: props.onClose,
    title: `Add members to ${props.teamName}`,
    className: "directory-page--team--add-members-modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
  
    , _react2.default.createElement(_Form2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}} )
  )
)

exports. default = AddMembersModal
