"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/OrgRels/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _utils = require('web/screens/Signup/utils');

var _OrgRow = require('./OrgRow'); var _OrgRow2 = _interopRequireDefault(_OrgRow);
var _reacthookform = require('react-hook-form');
var _org = require('shared/validation/org/org');
var _components = require('web/screens/Signup/components');







const OrgRelsSection = ({
  requestedOrgRels,
  onBack,
  onSubmit,
}) => {
  const { control, reset, formState, handleSubmit, trigger } =
    _reacthookform.useForm({ resolver: _org.orgListResolver })
  const { fields } = _reacthookform.useFieldArray.call(void 0, { control, name: 'orgs' })
  const { isValid } = formState

  const requestedOrgRelsMap = _react.useMemo.call(void 0, 
    () => requestedOrgRels.reduce(
      (acc, r) => {
        if (_utils.isExistingOrgRel.call(void 0, r)) acc[r.SelectedKey] = r
        return acc
      },
      {} ,
    ),
    [requestedOrgRels],
  )

  _react.useEffect.call(void 0, 
    () => {
      const presetOrgs = requestedOrgRels
        .map(r => ({
          title: r.Title,
          org: _utils.isExistingOrgRel.call(void 0, r)
            ? { OrgKey: r.SelectedKey, Name: r.SelectedName }
            : r.NewOrgName,
        }))

      reset({
        orgs: presetOrgs.length ? presetOrgs : [{ org: '', title: '' }],
      })
    },
    [reset, requestedOrgRels],
  )

  const submit = (data, e) => {
    _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])
    return onSubmit(data.orgs
      .map(rel => ({
        Title: rel.title,
        ...(typeof rel.org === 'string'
          ? { NewOrgName: rel.org }
          : {
              SelectedKey: rel.org.OrgKey ,
              SelectedName: rel.org.Name,
            }
        ),
      })),
    )
  }

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "org",
        title: "Your Organization" ,
        subtitle: "Let's assign you to an organization."     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      )
      ,  fields.map(({ org }, idx) => (
        _react2.default.createElement(_OrgRow2.default, {
          key: idx,
          idx: idx,
          control: control,
          trigger: trigger,
          canEditOrg: typeof org === 'string' || !requestedOrgRelsMap[org.OrgKey ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        )
      ))
      , _react2.default.createElement(_components.SignupStepControls, { onBack: onBack, isNextDisabled: !isValid, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} )
    )
  )
}

exports. default = OrgRelsSection
