"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Desktop/ParticipantDetails.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

exports. default = ({
  participantName,
  startedAt,
  duration,
  rating,
}) => (
  _react2.default.createElement('div', { className: "meeting--table-row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    , _react2.default.createElement('div', { className: "meeting--table-col participant-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      ,  participantName || 'Unknown' 
    )
    , _react2.default.createElement('div', { className: "meeting--table-col time-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, startedAt)
    , _react2.default.createElement('div', { className: "meeting--table-col duration-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, duration)
    , _react2.default.createElement('div', { className: "meeting--table-col feedback-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      ,  Array(5).fill(0).map((_, i) => (
          _react2.default.createElement('div', {
            key: i,
            className: _classBuilder2.default.call(void 0, 
              "meeting-room--history--details-feedback-point",
              { active: i + 1 <= rating },
            ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
          )
      ))
      
    )
  )
)
