"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/EnterEmail/RolePrompt/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _types = require('../types');
var _RoleOption = require('./RoleOption'); var _RoleOption2 = _interopRequireDefault(_RoleOption);
var _components = require('web/screens/Signup/components');





const RolePrompt = ({ setRole }) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_components.SignupStepHeader, {
      iconType: "doctor",
      title: "Let's get started!"  ,
      subtitle: "What best describes you?"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    )
    , _react2.default.createElement('div', { className: "signup--role-options", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , _react2.default.createElement(_RoleOption2.default, {
        iconSrc: "/assets/icons/user-purple.svg",
        title: "I'm a healthcare professional"   ,
        onPick: () => setRole(_types.Role.USER), __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      )
      , _react2.default.createElement(_RoleOption2.default, {
        iconSrc: "/assets/icons/patient-purple.svg",
        title: "I'm a patient"  ,
        onPick: () => setRole(_types.Role.PATIENT), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      )
    )
  )
)

exports. default = RolePrompt
