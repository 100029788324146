"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/PageNotFoundScreen.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _Form = require('web/components/Form');

var _TemplateEntityScreen = require('./TemplateEntityScreen'); var _TemplateEntityScreen2 = _interopRequireDefault(_TemplateEntityScreen);

const PageNotFoundScreen = () => {
  _react.useEffect.call(void 0, () => {
    _client2.default.log({ message: `404: ${window.location.href}` })
      .catch(() => null)
  }, [])

  return (
    _react2.default.createElement(_TemplateEntityScreen2.default, {
      iconSrc: "/assets/icons/broken-link-pale-purple.svg",
      title: "404",
      desc: 
        _react2.default.createElement('div', { className: "not-found--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "The requested page doesn't exist."    )
          , _react2.default.createElement(_reactrouterdom.Link, { to: _routes2.default.ROOT, className: "not-found--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            , _react2.default.createElement(_Form.Button, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "Go back" )
          )
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    )
  )
}

exports. default = PageNotFoundScreen
