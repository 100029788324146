"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/MembersSection/TeamMembersList/MemberItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');



var _entities = require('shared/transforms/entities');





var _Tooltip = require('web/components/Tooltip');
var _Name = require('web/components/Name'); var _Name2 = _interopRequireDefault(_Name);
var _Avatar = require('web/components/Avatar');











const MemberItem = ({
  userKey,
  userEntity,
  orgKey,
  onRemoveMember,
  openedOptionsUserKey,
  onOpenOptions,
  onCloseOptions,
  canEditParticipants,
}) => {
  if (!userEntity) return null

  const tooltipContent = (
    _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      , _react2.default.createElement(_Tooltip.TooltipItem, {
        'aria-label': `Remove ${userEntity.name} from team`,
        onClick: () => onRemoveMember(userKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      , "Remove from team"

      )
    )
  )

  return (
    _react2.default.createElement('div', {
      role: "button",
      onClick: () => _actions2.default.gotoUserPage(orgKey, userKey),
      className: "directory-page--user-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
    
      , _react2.default.createElement(_Avatar.UserAvatar, { userKey: userKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
      , _react2.default.createElement('div', { className: "directory-page--user-item--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        , _react2.default.createElement(_Name2.default, {
          orgKey: orgKey,
          className: "directory-page--user-item--name",
          user: userEntity.wrappedEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        )
        , _react2.default.createElement('div', { className: "directory-page--user-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
          ,  userEntity.description 
        )
      )
      ,  canEditParticipants && (
        _react2.default.createElement(_Tooltip.ControlledTooltip, {
          isDismissibleByClickElsewhere: true,
          onClose: onCloseOptions,
          isOpen: userKey === openedOptionsUserKey,
          place: "bottom",
          content: tooltipContent,
          className: "inbox-participants--options", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
        
          , _react2.default.createElement('img', {
            onClick: evt => {
              evt.stopPropagation()
              onOpenOptions(userKey)
            },
            role: "button",
            src: "/assets/icons/more-hor-black.svg",
            alt: "Member options" ,
            'aria-label': `${userEntity.name} options`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
          )
        )
      )
    )
  )
}





const mapState = (
  state,
  { userKey, orgKey },
) => {
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgsMap = org ? { [orgKey]: org }  : {}
  const user = _peers.selectUserByKey.call(void 0, state, userKey)

  return {
    userEntity: user && _entities.mapUserToEntity.call(void 0, 
      orgsMap,
      _entities.makeSpecificOrgTitleUserDescription.call(void 0, orgKey),
    )(user),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(MemberItem)
