"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ToastsScreen/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _user = require('shared/selectors/user');
var _app = require('shared/selectors/app');
var _app3 = require('web/services/app');
var _Toasts = require('web/components/Toasts');
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);

const TOASTS = {
  netInfo: 'NET_INFO_TOAST',
  appDownload: 'APP_DOWNLOAD_TOAST',
  appUpdate: 'APP_UPDATE_TOAST',
}

const ToastsScreen = ({ isOnline, isLoggedIn, isDownloadAvailable, isAppOutdated }) => {
  const activeToast = _react.useMemo.call(void 0, 
    () => {
      if (isLoggedIn && !isOnline) return TOASTS.netInfo
      if (isDownloadAvailable) return TOASTS.appDownload
      if (isAppOutdated) return TOASTS.appUpdate
    },
    [isLoggedIn, isOnline, isDownloadAvailable, isAppOutdated],
  )

  _react.useEffect.call(void 0, 
    () => {
      if (activeToast === TOASTS.appDownload) _amplitude2.default.logEvent(TOASTS.appDownload)
      else if (activeToast === TOASTS.appUpdate) _amplitude2.default.logEvent(TOASTS.appUpdate)
    },
    [activeToast],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Toasts.NetInfoToast, {
        isOnStage: activeToast === TOASTS.netInfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      )
      , _react2.default.createElement(_Toasts.AppDownloadToast, {
        isOnStage: activeToast === TOASTS.appDownload,
        onDownload: _app3.downloadApp, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      )
      , _react2.default.createElement(_Toasts.AppUpdateToast, {
        isOnStage: activeToast === TOASTS.appUpdate,
        onUpdate: _app3.updateApp, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      )
    )
  )
}

const mapState = state => ({
  isOnline: _app.selectIsAppOnline.call(void 0, state),
  isLoggedIn: _user.selectIsUserLoggedIn.call(void 0, state),
  isAppOutdated: _app.selectIsAppOutdated.call(void 0, state),
  isDownloadAvailable: _app.selectIsDownloadAvailable.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(ToastsScreen)
