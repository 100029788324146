"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }const WEB_NOTIFICATION_SOUND_LOCATION = '/assets/sounds/notifications/'

/* normalization function: allows us to ensure we'll have a proper file path regardless of old values or where the filename was entered */
 const webSoundFileLocation = (fileName) =>
  fileName.indexOf(WEB_NOTIFICATION_SOUND_LOCATION) > -1
    ? fileName
    : WEB_NOTIFICATION_SOUND_LOCATION + fileName; exports.webSoundFileLocation = webSoundFileLocation

 const getFileNameFromPath = (path) => {
  if (!path) return null
  const parts = path.split('/')
  return parts[parts.length - 1]
}; exports.getFileNameFromPath = getFileNameFromPath

 const getSoundName = (fileName, defaultName) => {
  const fn = exports.getFileNameFromPath.call(void 0, fileName)
  const soundObj = exports.notificationSounds.find((s) => s.fileName === fn)

  return _optionalChain([soundObj, 'optionalAccess', _ => _.name]) || defaultName
}; exports.getSoundName = getSoundName

/*
 * This object contains the list of files we expect will exist in web, and every version of the mobile app.
 * Various UI components hook up into this object.
 */
 const notificationSounds = [
  {
    name: "Alarmed",
    fileName: "alarmed.wav",
  },
  {
    name: "Alert",
    fileName: "alert.wav",
  },
  {
    name: "Beeper",
    fileName: "beeper.wav",
  },
  {
    name: "Belligerent",
    fileName: "belligerent.wav",
  },
  {
    name: "Calm",
    fileName: "calm.wav",
  },
  {
    name: "Chord",
    fileName: "chord.wav",
  },
  {
    name: "Chord2",
    fileName: "chord2.wav",
  },
  {
    name: "Chord2 Rev",
    fileName: "chord2_rev.wav",
  },
  {
    name: "Cloud",
    fileName: "cloud.wav",
  },
  {
    name: "Enharpment",
    fileName: "enharpment.wav",
  },
  {
    name: "Glass",
    fileName: "glass.wav",
  },
  {
    name: "Glisten",
    fileName: "glisten.wav",
  },
  {
    name: "Jinja",
    fileName: "jinja.wav",
  },
  {
    name: "Jinja2",
    fileName: "jinja2.wav",
  },
  {
    name: "Loud Phone",
    fileName: "loudphone.wav",
  },
  {
    name: "Ping",
    fileName: "ping.wav",
  },
  {
    name: "Polite",
    fileName: "polite.wav",
  },
  {
    name: "Reverie",
    fileName: "reverie.wav",
  },
  {
    name: "Sharp",
    fileName: "sharp.wav",
  },
  {
    name: "Whistleronic",
    fileName: "whistleronic.wav",
  },
  {
    name: "Whistleronic Down",
    fileName: "whistleronic_down.wav",
  },
]; exports.notificationSounds = notificationSounds
