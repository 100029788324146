"use strict";Object.defineProperty(exports, "__esModule", {value: true});





 const persistValue = (userKey, k, v) =>
  localStorage.setItem(`${userKey}:${k}`, JSON.stringify(v)); exports.persistValue = persistValue

 const getPersistedValue = (userKey, key) =>
  JSON.parse(localStorage.getItem(`${userKey}:${key}`) || 'null'); exports.getPersistedValue = getPersistedValue
