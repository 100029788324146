"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/MobileSetup/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _Audio = require('web/components/VideoCall/Setup/MobileSetup/Audio'); var _Audio2 = _interopRequireDefault(_Audio);
var _MobileCameraSetup = require('web/screens/VideoCall/segments/MobileCameraSetup'); var _MobileCameraSetup2 = _interopRequireDefault(_MobileCameraSetup);

 class MobileSetup extends _react2.default.Component {constructor(...args) { super(...args); MobileSetup.prototype.__init.call(this);MobileSetup.prototype.__init2.call(this);MobileSetup.prototype.__init3.call(this);MobileSetup.prototype.__init4.call(this); }
  __init() {this.state = { currentStepIdx: 1 }}
  __init2() {this.gotoCamSetup = () => this.setState({ currentStepIdx: 1 })}
  __init3() {this.gotoAudioSetup = () => this.setState({ currentStepIdx: 2 })}

  __init4() {this.gotoPrevStep = () =>
    this.state.currentStepIdx === 1
      ? this.props.goBack()
      : this.gotoCamSetup()}

  render () {
    const {
      onCycleCam, isCamCycleAvailable,
      areCamIssuesPresent,
      gotoPreCall,
      onUserProblemPublish,
    } = this.props

    const { currentStepIdx } = this.state

    return (
      _react2.default.createElement('div', { className: "meeting--setup--mobile", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        , _react2.default.createElement('div', { className: "meeting--setup--mobile-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
          , _react2.default.createElement('div', {
            onClick: this.gotoPrevStep,
            className: "meeting--setup--mobile-header--back-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          
            , _react2.default.createElement('img', { src: "/assets/icons/video/back.svg", alt: "<", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
          )
          , _react2.default.createElement('h4', { className: "meeting--setup--mobile-header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "Call Setup" )
          , _react2.default.createElement('span', { className: "meeting--setup--mobile-header--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}, "Step "
             ,  currentStepIdx , " of 2"
          )
        )
        , _react2.default.createElement('div', { className: "meeting--setup--mobile-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          ,  currentStepIdx === 1 &&
            _react2.default.createElement(_MobileCameraSetup2.default, {
              onCycleCam: onCycleCam,
              isCamCycleAvailable: isCamCycleAvailable,
              areCamIssuesPresent: areCamIssuesPresent,
              gotoNextStep: this.gotoAudioSetup,
              onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
            )
          
          ,  currentStepIdx === 2 &&
            _react2.default.createElement(_Audio2.default, {
              gotoNextStep: gotoPreCall,
              onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
            )
          
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    onCycleCam: _proptypes2.default.func.isRequired,
    isCamCycleAvailable: _proptypes2.default.bool.isRequired,
    areCamIssuesPresent: _proptypes2.default.bool.isRequired,
    gotoPreCall: _proptypes2.default.func.isRequired,
  }}
} MobileSetup.__initStatic(); exports.default = MobileSetup;
