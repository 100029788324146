"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _upgrade = require('shared/enum/upgrade');

var _upgradeNotices = require('shared/constants/upgradeNotices');

var _TeamCommunication = require('./TeamCommunication'); var _TeamCommunication2 = _interopRequireDefault(_TeamCommunication);
var _SMSMessaging = require('./SMSMessaging'); var _SMSMessaging2 = _interopRequireDefault(_SMSMessaging);
var _VideoTelehealth = require('./VideoTelehealth'); var _VideoTelehealth2 = _interopRequireDefault(_VideoTelehealth);
var _ExtraPhoneNumber = require('./ExtraPhoneNumber'); var _ExtraPhoneNumber2 = _interopRequireDefault(_ExtraPhoneNumber);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _useStage = require('shared/hooks/useStage'); var _useStage2 = _interopRequireDefault(_useStage);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _PhonePickerModal = require('./ExtraPhoneNumber/PhonePickerModal'); var _PhonePickerModal2 = _interopRequireDefault(_PhonePickerModal);









const zero = 0 

const Features = ({
  orgKey,
  isEditable,
  renewsOn,
  itemsMap,
  onReloadSubscription,
}) => {
  const {
    isOpen: isPhonePickerModalOpen,
    onOpen: onOpenPhonePickerModal,
    onClose: onClosePhonePickerModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isUpgradeModalOpen,
    onOpen: onOpenUpgradeModal,
    onClose: onCloseUpgradeModal,
    item: upgradeModalType,
  } = _useStage2.default(null)

  const teamItem = itemsMap[_upgrade.SubscriptionItemType.TEAM]
  const smsItem = itemsMap[_upgrade.SubscriptionItemType.SMS]
  const phoneNumbersItem = itemsMap[_upgrade.SubscriptionItemType.PHONE_NUMBER]
  const videoItem = itemsMap[_upgrade.SubscriptionItemType.VIDEO]
  const freePhoneNumbers = _optionalChain([phoneNumbersItem, 'optionalAccess', _ => _.FreeUnits]) || 0
  const hasUnclaimedPhoneNumbers = phoneNumbersItem && phoneNumbersItem.UnitsUsed < freePhoneNumbers
  const hasExtraPhoneNumbers = phoneNumbersItem && phoneNumbersItem.Units > freePhoneNumbers
  const hasUnclaimedExtraPhoneNumbers = hasExtraPhoneNumbers && phoneNumbersItem.UnitsUsed < phoneNumbersItem.Units

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_TeamCommunication2.default, {
        isActive: teamItem.Enabled,
        isEditable: isEditable,
        membersCount: teamItem.UnitsUsed,
        maxMembersCount: _upgradeNotices.OVER_LIMIT_MEMBERS_THRESHOLD,
        baseCost: teamItem.BaseCost || zero,
        onCheckout: () => onOpenUpgradeModal(_upgrade.SubscriptionItemType.TEAM), __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      )
      , _react2.default.createElement(_SMSMessaging2.default, {
        isActive: smsItem.Enabled,
        isEditable: isEditable,
        contactsCount: smsItem.UnitsUsed,
        maxContactsCount: smsItem.Units,
        renewsOn: renewsOn,
        baseCost: smsItem.BaseCost || zero,
        hasUnclaimedPhoneNumbers: hasUnclaimedPhoneNumbers,
        onAssignPhoneNumber: onOpenPhonePickerModal,
        onCheckout: () => onOpenUpgradeModal(_upgrade.SubscriptionItemType.SMS), __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      )
      ,  hasExtraPhoneNumbers && (
          _react2.default.createElement(_ExtraPhoneNumber2.default, {
            isActive: phoneNumbersItem.Enabled,
            phoneNumbersCount: phoneNumbersItem.Units - freePhoneNumbers,
            hasUnclaimedPhoneNumbers: hasUnclaimedExtraPhoneNumbers,
            baseCost: phoneNumbersItem.UnitCost || zero,
            onAssignPhoneNumber: onOpenPhonePickerModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
          )
      )
      ,  !!videoItem.Enabled && (
        _react2.default.createElement(_VideoTelehealth2.default, {
          isActive: !!videoItem.Enabled,
          baseCost: videoItem.UnitCost || zero,
          seatCount: videoItem.Units, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        )
      )
      ,  upgradeModalType && (
        _react2.default.createElement(_UpgradeModal2.default, {
          orgKey: orgKey,
          type: upgradeModalType,
          isOpen: isUpgradeModalOpen,
          onClose: onCloseUpgradeModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        )
      )
      , _react2.default.createElement(_PhonePickerModal2.default, {
        orgKey: orgKey,
        isOpen: isPhonePickerModalOpen,
        onClose: onClosePhonePickerModal,
        onReloadSubscription: onReloadSubscription, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
      )
    )
  )
}

exports. default = Features
