"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/SetupFlow.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _types = require('web/screens/Signup/types');
var _components = require('web/screens/Signup/components');
var _hooks = require('./hooks');







var _Sections = require('./Sections');







const sizes = {
  [_types.SetupSteps.PERSONAL_INFO]: 'md',
  [_types.SetupSteps.PASSWORD]: 'sm',
  [_types.SetupSteps.ORG_INFO]: 'lg',
  [_types.SetupSteps.ORG_SURVEY_TYPE]: 'sm',
  [_types.SetupSteps.ORG_SURVEY_REFERRER]: 'sm',
  [_types.SetupSteps.ORG_SURVEY_FEATURES]: 'lg',
  [_types.SetupSteps.FINISH]: 'lg',
}

const SetupFlow = ({ token, welcomeUser, onSubmitSetup }) => {
  const wasInvitedByPhone = !!(welcomeUser.PhoneNumber && !welcomeUser.Email)
  const { currentStep, gotoStep, goBack } = _hooks.useStep.call(void 0, )
  const { values, handlers } = _hooks.useIntermediateValues.call(void 0, {
    token,
    welcomeUser,
    wasInvitedByPhone,
    gotoStep,
    onSubmitSetup,
  })
  const { personalInfo, password, requestedOrgRels } = values
  const {
    onSavePersonalInfo, onSavePassword, onSaveOrgRels, onSaveOrgSurvey, onFinish,
  } = handlers

  const renderContent = () => {
    switch (currentStep) {
      case _types.SetupSteps.PERSONAL_INFO: return (
        _react2.default.createElement(_Sections.PersonalInfoSection, {
          welcomeUser: welcomeUser,
          personalInfo: personalInfo,
          onSubmit: onSavePersonalInfo,
          wasInvitedByPhone: wasInvitedByPhone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        )
      )
      case _types.SetupSteps.PASSWORD: return (
        _react2.default.createElement(_Sections.PasswordSection, {
          onBack: goBack,
          onSubmit: onSavePassword,
          password: password, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        )
      )
      case _types.SetupSteps.ORG_INFO: return (
        _react2.default.createElement(_Sections.OrgRelsSection, {
          onBack: goBack,
          requestedOrgRels: requestedOrgRels,
          onSubmit: onSaveOrgRels, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
        )
      )
      case _types.SetupSteps.ORG_SURVEY_TYPE:
      case _types.SetupSteps.ORG_SURVEY_REFERRER:
      case _types.SetupSteps.ORG_SURVEY_FEATURES: return (
        _react2.default.createElement(_Sections.OrgSurveySection, {
          onBack: goBack,
          gotoStep: gotoStep,
          currentStep: currentStep,
          onSubmit: onSaveOrgSurvey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        )
      )
      case _types.SetupSteps.FINISH: return _react2.default.createElement(_Sections.FinishSection, { onFinish: onFinish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} )
    }
  }

  return (
    _react2.default.createElement(_components.SignupStepWrapper, {
      title: "Sign up" ,
      gotoStep: currentStep < _types.SetupSteps.FINISH ? gotoStep : null,
      currentStep: currentStep,
      size: sizes[currentStep], __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
    
      ,  renderContent() 
    )
  )
}

exports. default = SetupFlow
