"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upsells/ListMenuNotice.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);






const ListMenuUpsellNotice = ({ desc, onRequest }) => (
  _react2.default.createElement('div', { className: "list-menu--upsells", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    , _react2.default.createElement(_WarningBar2.default, {
      className: "list-menu--upsells--warning",
      text: "This feature is not included in your current plan."        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    )
    , _react2.default.createElement('span', { className: "list-menu--upsells--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}},  desc )
    ,  onRequest && (
      _react2.default.createElement('span', {
        role: "button",
        className: "list-menu--upsells--access-link",
        onClick: onRequest, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , "Request access"

      )
    )
  )
)

exports. default = ListMenuUpsellNotice
