"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/components/Loader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _components = require('web/screens/Login/components');
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);

const Loader = () => (
  _react2.default.createElement(_components.FormWrapper, { title: "Log in" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
    , _react2.default.createElement('div', { className: "login--loader--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
      , _react2.default.createElement(_Spinner2.default, { className: "login--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}} )
    )
  )
)

exports. default = Loader
