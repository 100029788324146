"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Emails/EmailEntry.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _noticeBar = require('web/services/context/noticeBar');
var _Form = require('web/components/Form');










const EmailEntry = ({
  email,
  onRemoveEmail,
  onVerifyEmail,
  onMakeEmailPrimary,
  isPrimary,
  isVerified,
}) => {
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  const handleRemoveEmail = () => onRemoveEmail(email)
    .then(() => showSuccessBar(`${email} has been removed`))
    .catch(() => showWarningBar('Failed to remove your email'))

  const handleVerifyEmail = () => onVerifyEmail(email)
    .then(() => showSuccessBar('A verification email has been sent'))
    .catch(() => showWarningBar('Failed to verify your email'))

  const handleMakeEmailPrimary = () => onMakeEmailPrimary(email)
    .then(() => showSuccessBar(`${email} has been made primary`))
    .catch(() => showWarningBar('Failed to make your email primary'))

  return (
    _react2.default.createElement('div', { className: "settings--profile--contact-entry", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      , _react2.default.createElement('img', {
        src: "/assets/icons/settings/account.svg",
        className: "settings--profile--contact-icon",
        alt: "@", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      )
      , _react2.default.createElement('div', { className: "settings--profile--contact-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , _react2.default.createElement('span', { className: "settings--profile--contact-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}},  email )
        ,  isPrimary &&
          _react2.default.createElement('span', { className: "settings--profile--contact-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
            , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
              , _react2.default.createElement('img', { src: "/assets/icons/settings/star.svg", alt: "★", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} ), "Primary"

            )
            , _react2.default.createElement('span', { className: "blmh-under-grid-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}, " - "), "Notifications will be sent to this address."

          )
        
        ,  !isVerified &&
          _react2.default.createElement('span', { className: "settings--profile--contact-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "Pending verification"

          )
        
      )
      , _react2.default.createElement('div', { className: "settings--profile--contact-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        ,  !isPrimary && isVerified &&
          _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: handleMakeEmailPrimary, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "Set as primary"

          )
        
        ,  !isVerified &&
          _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: handleVerifyEmail, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Verify email"

          )
        
        ,  (!isPrimary || !isVerified) &&
          _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: handleRemoveEmail, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}, "Remove"

          )
        
      )
    )
  )
}

exports. default = EmailEntry
