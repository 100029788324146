"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/UnsubscribeEmailNotifsScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _errors = require('shared/errors');
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _WelcomeBox = require('web/components/Welcome/WelcomeBox'); var _WelcomeBox2 = _interopRequireDefault(_WelcomeBox);

 class UnsubscribeEmailNotifsScreen extends _react.Component {
  constructor (props) {
    super(props)

    this.state = {
      stage: 'loading',
      response: null,
    }
  }

  componentDidMount () {
    const token = this.props.match.params.token
    const email = this.props.match.params.email
    _client2.default.unsubscribeEmailNotifs(email, token).then((result) => {
      this.setState({
        stage: 'success',
      })
    }).catch((err) => {
      if (err instanceof _errors.RestError) {
        err = err.response
      }
      switch (err.Error) {
        case 'Invalid Token':
          this.setState({ stage: 'invalid_token' })
          break
        case 'Server Error':
          this.setState({ stage: 'server_error' })
          break
        default:
          this.setState({ stage: 'server_error' })
          break
      }
    })
  }

  render () {
    let body = null
    switch (this.state.stage) {
      case 'loading':
        body = (
          _react2.default.createElement('div', { className: "welcome--box-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
            , _react2.default.createElement('div', { className: "welcome--box-full", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, "BloomText is working..."

              , _react2.default.createElement('div', { className: "welcome--box-row-bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
                , _react2.default.createElement(_Spinner2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}})
              )
            )
          )
        )
        break
      case 'success':
        body = (
          _react2.default.createElement('div', { className: "welcome--box-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
            , _react2.default.createElement('img', { src: "assets/images/confirm_email/confirmed.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} )
            , _react2.default.createElement('div', { className: "welcome--box-full", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
              , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "Success! BloomText has unsubscribed you from email notifications."       )
              , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "To reactivate email notifications, turn them on in your notification settings."          )
            )
            , _react2.default.createElement('div', { className: "welcome--box-row-bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
              , _react2.default.createElement(_reactrouterdom.Link, { className: "welcome--box-button welcome--box-button-green" , to: "/login", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}, "Log in" )
            )
          )
        )
        break
      case 'server_error':
        body = (
          _react2.default.createElement('div', { className: "welcome--box-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
            , _react2.default.createElement('div', { className: "welcome--box-full", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}, "Sorry, BloomText has encountered an error. Please try again later or contact support@bloomtext.com."

            )
            , _react2.default.createElement('div', { className: "welcome--box-row-bottom welcome--box-back-to-bloom" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
              , _react2.default.createElement('a', { href: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}, "Back to BloomText"  )
            )
          )
        )
        break
      case 'invalid_token':
        body = (
          _react2.default.createElement('div', { className: "welcome--box-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
            , _react2.default.createElement('div', { className: "welcome--box-full", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, "Sorry, this link is invalid. Please try again later or contact support@bloomtext.com."

            )
            , _react2.default.createElement('div', { className: "welcome--box-row-bottom welcome--box-back-to-bloom" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
              , _react2.default.createElement('a', { href: "/", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}, "Back to BloomText"  )
            )
          )
        )
        break
    }

    return (
      _react2.default.createElement(_WelcomeBox2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        ,  body 
      )
    )
  }
} exports.default = UnsubscribeEmailNotifsScreen;
