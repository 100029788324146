"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/Modals/ApproveCallParticipant.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _videoRoom = require('web/selectors/videoRoom');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

var _ = require('web/components/VideoRooms/Room/Modals/');

const ApproveCallParticipant = ({ currentPendingUser }) => {
  if (!currentPendingUser) return null
  const {
    RoomKey, RoomAuthorizationKey, RoomAuthorizationUserName,
  } = currentPendingUser

  const respond = Approved => _actions2.default.respondVideoRoomJoinRequest({ RoomKey, RoomAuthorizationKey, Approved })

  return (
    _react2.default.createElement(_.ApproveCallParticipantModal, {
      isOpen: true,
      key: RoomAuthorizationKey,
      participantName: RoomAuthorizationUserName,
      onDeny: () => respond(false),
      onAllow: () => respond(true), __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    )
  )
}

const mapState = state => ({
  currentPendingUser: _videoRoom.selectVideoRoomPendingUsersList.call(void 0, state).slice(-1)[0],
})

exports. default = _reactredux.connect.call(void 0, mapState)(ApproveCallParticipant)
