"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);



var _subscriptionRedirect = require('web/services/subscriptionRedirect');
var _gotoURL = require('web/services/gotoURL');
var _upgrade = require('shared/enum/upgrade');













 const useCheckout = ({
  orgKey: OrgKey,
  hasSubscription,
  onEarlyCheckout,
}) => _react.useCallback.call(void 0, 
  async (item, { action = _upgrade.CheckoutActionType.UPGRADE } = {}) => {
    const Items = [item]
    const SuccessURL = _subscriptionRedirect.buildSubscriptionSuccessURL.call(void 0, OrgKey, item.Type, action)
    const CancelURL = _subscriptionRedirect.getCurrentURL.call(void 0, )

    const redirectURL = hasSubscription
      ? await _actions2.default.checkout({ OrgKey, Items })
        .then(onEarlyCheckout)
        .then(() => SuccessURL)
      : await _actions2.default.getCheckoutURL({ OrgKey, Items, SuccessURL, CancelURL })
        .then(r => r.URL)

    _gotoURL.gotoURL.call(void 0, redirectURL)
  },
  [OrgKey, hasSubscription, onEarlyCheckout],
); exports.useCheckout = useCheckout
