"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactwaypoint = require('react-waypoint');




var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _session = require('shared/selectors/session');
var _blobject = require('shared/selectors/blobject');
var _user = require('shared/selectors/user');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _inbox = require('shared/hooks/inbox');
var _noticeBar = require('web/services/context/noticeBar');

var _List = require('web/screens/App/List');
var _ItemsList = require('./ItemsList'); var _ItemsList2 = _interopRequireDefault(_ItemsList);
var _NewChatListItemPlaceholder = require('./NewChatListItemPlaceholder'); var _NewChatListItemPlaceholder2 = _interopRequireDefault(_NewChatListItemPlaceholder);
var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _Tabs = require('web/components/Tabs'); var _Tabs2 = _interopRequireDefault(_Tabs);
var _Header = require('./Header'); var _Header2 = _interopRequireDefault(_Header);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const blobjectFilterOptions = [
  { value: _inbox.BlobjectFilter.ACTIVE, label: "Active", iconName: "active"  },
  { value: _inbox.BlobjectFilter.COMPLETED, label: "Archived", iconName: "completed"  },
]

const InboxList = ({
  onCreateNewChat,
  isCreatingNewChat,
  onCloseCreateNewChat,
  isViewingCreateNewChat,
  createdChatName,
  myUserKey,
  selectedTeamKey,
  myInboxableTeamKeys,
}) => {
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onError =
    _react.useCallback.call(void 0, () => showWarningBar('Failed to load Inbox'), [showWarningBar])

  const {
    activeFilter,
    setActiveFilter,
    blobjectKeysList,
    isLoading,
    hasDrained,
    onLoadMore,
    onSilentReset: onReset,
  } = _inbox.useInbox.call(void 0, { selectedTeamKey, myInboxableTeamKeys, myUserKey, onError })

  const {
    isBlobjectBeingArchived,
    onToggleBlobjectArchived,
  } = _inbox.useArchiveTransaction.call(void 0, { activeFilter, onReset, showSuccessBar, showWarningBar })

  const {
    isOpen: isFilterVisible,
    onOpen: onShowFilter,
    onClose: onHideFilter,
  } = _useToggle2.default.call(void 0, true)

  return (
    _react2.default.createElement(_List.List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
      , _react2.default.createElement(_Header2.default, {
        selectedTeam: selectedTeamKey,
        onSelectTeam: _actions2.default.setSelectedTeam,
        onCreateNewChat: onCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      )
      , _react2.default.createElement(_List.ListItemsWrapper, { className: "inbox-list-items", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
        , _react2.default.createElement(_reactwaypoint.Waypoint, { topOffset: -20, onEnter: onShowFilter, onLeave: onHideFilter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}} )
        , _react2.default.createElement(_Tabs2.default, {
          className: _classBuilder2.default.call(void 0, !isFilterVisible && "invisible"),
          type: "filter",
          options: blobjectFilterOptions,
          value: activeFilter,
          onSelectTab: setActiveFilter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        )
        ,  isCreatingNewChat && (
          _react2.default.createElement(_NewChatListItemPlaceholder2.default, {
            onClick: onCreateNewChat,
            onCancel: onCloseCreateNewChat,
            isSelected: isViewingCreateNewChat,
            createdChatName: createdChatName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
          )
        )
        , _react2.default.createElement(_ItemsList2.default, {
          blobjectKeys: blobjectKeysList,
          activeFilter: activeFilter,
          onLoadMore: onLoadMore,
          hasMore: !!blobjectKeysList.length && !hasDrained,
          isBlobjectBeingArchived: isBlobjectBeingArchived,
          onToggleBlobjectArchived: onToggleBlobjectArchived, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        )
        , _react2.default.createElement(_ListResultsStatus2.default, {
          isLoading: isLoading,
          resultsCount: blobjectKeysList.length,
          noResultsLabel: "No chats found"  ,
          className: "inbox-list--result", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        )
      )
    )
  )
}







const mapState = (state) => ({
  selectedTeamKey: _session.selectSelectedTeamKey.call(void 0, state),
  myUserKey: _user.selectCurrentUserKey.call(void 0, state),
  myInboxableTeamKeys: _blobject.selectMyInboxableTeamKeys.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(InboxList)
