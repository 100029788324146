"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');



var _types = require('./types');
var _upgrade = require('shared/enum/upgrade');
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);








const activeSubscriptionSteps = [_types.UpgradeStep.PREVIEW, _types.UpgradeStep.SUMMARY, _types.UpgradeStep.BILLING] 
const noSubscriptionSteps = [_types.UpgradeStep.PREVIEW, _types.UpgradeStep.SUMMARY] 

 const useSmsCheckoutFlow = ({
  hasReceipt,
  onCheckout,
  smsItem,
  isSmsEnabled,
}) => {
  const availableSteps = hasReceipt
    ? activeSubscriptionSteps
    : noSubscriptionSteps

  const [currentStep, setCurrentStep] = _react.useState(availableSteps[0])
  const [isLoadingCheckout, setIsLoadingCheckout] = _react.useState.call(void 0, false)

  _react.useEffect.call(void 0, 
    () => _amplitude2.default.logEvent(`SMS_UPGRADE_${currentStep.toUpperCase()}`),
    [currentStep],
  )

  _react.useLayoutEffect.call(void 0, 
    () => {
      isSmsEnabled && setCurrentStep(availableSteps[1])
    },
    [isSmsEnabled, availableSteps],
  )

  const onPrev = _react.useCallback.call(void 0, 
    () => {
      const currentStepIdx = availableSteps.findIndex(x => x === currentStep)
      setCurrentStep(availableSteps[currentStepIdx - 1] || availableSteps[0])
    },
    [availableSteps, currentStep],
  )
  const onNext = _react.useCallback.call(void 0, 
    async () => {
      if (currentStep === availableSteps[0]) {
        return setCurrentStep(availableSteps[1])
      }
      if (currentStep === availableSteps[1] && availableSteps[2]) {
        return setCurrentStep(availableSteps[2])
      }
      if (_optionalChain([smsItem, 'optionalAccess', _ => _.Units])) {
        setIsLoadingCheckout(true)
        await onCheckout(
          { Type: _upgrade.SubscriptionItemType.SMS, Units: smsItem.Units },
          {
            action: isSmsEnabled
              ? _upgrade.CheckoutActionType.CHANGE_TIER
              : _upgrade.CheckoutActionType.UPGRADE,
          },
        )
        setIsLoadingCheckout(false)
      }
    },
    [availableSteps, currentStep, onCheckout, smsItem, isSmsEnabled],
  )

  return {
    onPrev,
    onNext,
    currentStep,
    isLoadingCheckout,
  }
}; exports.useSmsCheckoutFlow = useSmsCheckoutFlow
