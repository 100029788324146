"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }// Web-specific reducers. These will be merged with the shared ones.


var _videoCall = require('./videoCall'); var _videoCall2 = _interopRequireDefault(_videoCall);
var _videoRoom = require('./videoRoom'); var _videoRoom2 = _interopRequireDefault(_videoRoom);
var _snackbars = require('./snackbars'); var _snackbars2 = _interopRequireDefault(_snackbars);
var _firstTimeTutorial = require('./firstTimeTutorial'); var _firstTimeTutorial2 = _interopRequireDefault(_firstTimeTutorial);








const webReducers = {
  videoCall: _videoCall2.default,
  videoRoom: _videoRoom2.default,
  snackbars: _snackbars2.default,
  firstTimeTutorial: _firstTimeTutorial2.default,
}

exports. default = webReducers
