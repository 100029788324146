"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/CreateNewChat/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reacthookform = require('react-hook-form');









var _chat = require('shared/enum/chat');
var _createNew = require('shared/selectors/createNew');





var _createNew3 = require('shared/hooks/createNew');
var _chat3 = require('shared/validation/createNew/chat');
var _noticeBar = require('web/services/context/noticeBar');

var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');
var _ParticipantsPicker = require('web/components/ParticipantsPicker'); var _ParticipantsPicker2 = _interopRequireDefault(_ParticipantsPicker);
var _ChatTypeToggle = require('./ChatTypeToggle'); var _ChatTypeToggle2 = _interopRequireDefault(_ChatTypeToggle);







const CreateNewChat = ({
  onClose,
  orgKeyContext,
  defaultSenderKey,
  myEntitiesMap,
  myOrgEntitiesList,
  myTeamEntitiesList,
  initialEntitiesList,
}) => {
  const defaultValues = _react.useMemo.call(void 0, 
    () => ({
      senderKey: defaultSenderKey,
      deliveryMethod: _chat.DELIVERY_METHOD.SECURE,
    }),
    [defaultSenderKey],
  )

  const { control, register, reset, watch, setValue, formState, handleSubmit } =
    _reacthookform.useForm({
      mode: 'onChange', resolver: _chat3.newChatResolver, defaultValues,
    })
  const { isValid, isSubmitting, errors } = formState
  const chatMethod = watch('deliveryMethod')
  const chatName = watch('name')
  const senderKey = watch('senderKey') 
  const senderEntity = myEntitiesMap[senderKey]
  const senderOrgKey = _optionalChain([senderEntity, 'optionalAccess', _ => _.wrappedEntity, 'optionalAccess', _2 => _2.OrgKey])
  if (!senderOrgKey) return (_react2.default.createElement('em', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "You must be an (approved) member of an organization."        ))
  _react.useEffect.call(void 0, () => reset(defaultValues), [reset, defaultValues])

  const {
    selectedEntitiesMap,
    selectedEntitiesList,
    onSelectEntity,
    onRemoveEntity,
    onResetEntities,
  } = _createNew3.useSelectedEntities({ initialEntitiesList })

  const { suggestedChat, isLoading: isLoadingSuggestedChat } =
    _createNew3.useSuggestedChat.call(void 0, { selectedEntitiesList, senderEntity, chatName, chatMethod })

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    data => _createNew3.onCreateChat.call(void 0, {
      suggestedChat,
      senderEntity,
      selectedEntitiesList,
      gotoConversation: _actions2.default.gotoBlobject,
      gotoInbox: _actions2.default.gotoInbox,
      chatMethod: data.deliveryMethod,
      chatName: data.name,
    })
      .then(onClose)
      .catch(() => showWarningBar('Failed to create conversation')),
    [senderEntity, selectedEntitiesList, onClose, showWarningBar, suggestedChat],
  )

  const excludedEntitiesMap =
    _createNew3.useExcludedEntities.call(void 0, { selectedEntitiesMap, senderEntity })

  // eslint-disable-next-line react-hooks/exhaustive-deps
  _react.useLayoutEffect.call(void 0, onResetEntities, [senderOrgKey])
  _react.useEffect.call(void 0, () => _amplitude2.default.logEvent("Open Create Chat Modal"), [])

  return (
    _react2.default.createElement('div', { className: "create-chat", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
      ,  senderEntity && (
        _react2.default.createElement(_Form.InputSectionWrapper, { title: "From", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          , _react2.default.createElement(_Form.SenderSelectController, {
            name: "senderKey",
            control: control,
            myEntitiesMap: myEntitiesMap,
            shouldShowEntityDesc: true,
            myOrgEntitiesList: myOrgEntitiesList,
            myTeamEntitiesList: myTeamEntitiesList,
            entityClassName: "create-new--modal--sender-entity", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
          )
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "To", shouldCaptureFocus: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
        , _react2.default.createElement(_ParticipantsPicker2.default, {
          orgKeyContext: senderOrgKey || orgKeyContext,
          excludedEntitiesMap: excludedEntitiesMap,
          selectedEntitiesList: selectedEntitiesList,
          onSelectEntity: onSelectEntity,
          onRemoveEntity: onRemoveEntity,
          className: "participants-picker--field",
          inputClassName: "participants-picker--field--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
        )
      )
      , _react2.default.createElement(_ChatTypeToggle2.default, {
        control: control,
        setValue: setValue,
        senderEntity: senderEntity,
        selectedEntitiesList: selectedEntitiesList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}}
      )
      , _react2.default.createElement(_Form.InputSection, {
        isDisabled: chatMethod === _chat.DELIVERY_METHOD.SMS,
        isOptional: true,
        title: "Chat Title" ,
        className: "create-new--modal--chat-name--wrapper",
        inputProps: register('name'),
        placeholder: "e.g. 'Stroke Case'"  ,
        errorMessage: _optionalChain([errors, 'access', _3 => _3.name, 'optionalAccess', _4 => _4.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        className: "create-new--modal--new-chat-controls",
        primaryBtnLabel: suggestedChat ? 'Open' : 'Create',
        secondaryBtnLabel: "Cancel",
        isPrimaryLoading: isSubmitting || isLoadingSuggestedChat,
        isPrimaryEnabled: isValid,
        onPrimaryBtnClick: handleSubmit(onSubmit),
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
      )
    )
  )
}









const mapState = (state, { orgKeyContext }) => {
  const env = _createNew.selectNewChatSenderEnv.call(void 0, state)
  return {
    ...env,
    orgKeyContext: env.orgKeyContext || orgKeyContext,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewChat)
