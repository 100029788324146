"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/Quick/PinForm.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _UserPreview = require('web/screens/Login/Quick/UserPreview'); var _UserPreview2 = _interopRequireDefault(_UserPreview);
var _Form = require('web/components/Form');
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);

const PIN_LENGTH = 4








const PinForm = ({
  userKey, onSubmit, gotoUsernameLogin, gotoUsersList,
}) => {
  const [pin, setPin] = _react.useState.call(void 0, '')
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [errorMsg, setErrorMsg] = _react.useState.call(void 0, '')

  const handleSubmit = async (pin) => {
    setIsLoading(true)
    setErrorMsg('')

    await onSubmit(userKey, pin)
      .catch((e) => {
        setIsLoading(false)
        setPin('')
        setErrorMsg(_optionalChain([e, 'optionalAccess', _ => _.response, 'optionalAccess', _2 => _2.Error])
          ? e.response.Error
          : "An unknown error occurred. Please try again later.",
        )
      })
  }

  const onChange = (pin) => {
    setPin(pin)
    if (pin.length === PIN_LENGTH) handleSubmit(pin)
  }

  return (
    _react2.default.createElement('form', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      , _react2.default.createElement(_UserPreview2.default, { userKey: userKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
      , _react2.default.createElement('label', { className: "login--quick--pin-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, "PIN Code"

        , _react2.default.createElement('div', { className: "login--quick--pin-form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          , _react2.default.createElement(_Form.PinInput, {
            autoFocus: true,
            isDisabled: isLoading,
            pinLength: PIN_LENGTH,
            value: pin,
            className: "login--quick--pin-input-form",
            onChange: onChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          )
          , _react2.default.createElement('span', {
            role: "button",
            onClick: gotoUsernameLogin,
            className: "login--quick--pin-forgot-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          , "Forgot PIN?"

          )
        )
      )
      , _react2.default.createElement('span', { className: "login--error-msg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}},  errorMsg )
      ,  isLoading &&
        _react2.default.createElement('div', { className: "login--loader--wrapper login--quick--loader" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          , _react2.default.createElement(_Spinner2.default, { className: "login--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} )
        )
      
      , _react2.default.createElement('div', { className: "login--quick--back-link-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        , _react2.default.createElement('span', { role: "button", className: "login--quick--back-link", onClick: gotoUsersList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}, "Log in as someone else"

        )
      )
    )
  )
}

exports. default = PinForm
