"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/PinInputController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');

var _PinInput = require('web/components/Form/PinInput'); var _PinInput2 = _interopRequireDefault(_PinInput);






const PinInputController = ({
  control,
  name,
  ...props
}) => (
  _react2.default.createElement(_reacthookform.Controller, {
    control: control,
    name: name,
    render: ({ field }) => (
      _react2.default.createElement(_PinInput2.default, {
        ...props,
        value: field.value  || '',
        onChange: field.onChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  )
  )

exports. default = PinInputController
