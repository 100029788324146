"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxRightSide/CreateNewChat/Header/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _ParticipantsPicker = require('web/components/ParticipantsPicker'); var _ParticipantsPicker2 = _interopRequireDefault(_ParticipantsPicker);

const CreateNewHeader = ({
  orgKeyContext,
  excludedEntitiesMap,
  selectedEntitiesList,
  onSelectEntity,
  onRemoveEntity,
  senderSelect,
  participantQuotas,
}) => (
  _react2.default.createElement('div', { className: "create-new-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    , _react2.default.createElement('img', {
      src: "/assets/icons/header/inbox-sidebar-dark.svg",
      className: "create-new-header--title",
      alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
    )
    ,  orgKeyContext && (
      _react2.default.createElement(_ParticipantsPicker2.default, {
        isInitiallyOpen: true,
        shouldRefocusOnPick: true,
        orgKeyContext: orgKeyContext,
        excludedEntitiesMap: excludedEntitiesMap,
        selectedEntitiesList: selectedEntitiesList,
        onSelectEntity: onSelectEntity,
        onRemoveEntity: onRemoveEntity,
        senderSelect: senderSelect,
        participantQuotas: participantQuotas,
        inputClassName: "create-new-header--picker-input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      )
    )
  )
)

CreateNewHeader.propTypes = {
  excludedEntitiesMap: _proptypes2.default.object.isRequired,
  selectedEntitiesList: _proptypes2.default.array.isRequired,
  onSelectEntity: _proptypes2.default.func.isRequired,
  onRemoveEntity: _proptypes2.default.func.isRequired,
  participantQuotas: _proptypes2.default.object.isRequired,
  senderSelect: _proptypes2.default.node,
}

exports. default = CreateNewHeader
