"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _ui = require('web/services/ui');

const MSG_SPACING = 4





const useScrollToMessage = ({ getContainerDOM }) => _react.useCallback.call(void 0, 
  (msgKey, options = {}) => new Promise(resolve => {
    const msg = document.getElementById(msgKey)
    const containerDOM = getContainerDOM()
    if (!msg || !containerDOM) return resolve()

    const { bottom: msgBottom } = msg.getBoundingClientRect()
    const { bottom: containerBottom } = containerDOM.getBoundingClientRect()
    const delta = msgBottom - containerBottom
    const { scrollTop, clientHeight } = containerDOM

    const { offsetPercent = 0, offsetPX = 0 } = options
    const offset = offsetPX + (offsetPercent * clientHeight)

    _ui.scrollElem.call(void 0, containerDOM, { top: scrollTop + delta + MSG_SPACING + offset })
    setTimeout(resolve, 0)
  }),
  [], // eslint-disable-line react-hooks/exhaustive-deps
)

exports. default = useScrollToMessage
