"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/ToolBar/Filter.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _types = require('web/screens/Settings/Org/MembersTab/types');

var _Tooltip = require('web/components/Tooltip');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);






const filterConfig = [
  [_types.PendingFilter.PENDING, "Show only pending members"],
  [_types.PendingFilter.ALL, "Show all members"],
]

const Filter = ({ pendingFilter, setPendingFilter }) => (
  _react2.default.createElement(_Tooltip.ClickTooltip, {
    type: "light",
    place: "bottom",
    className: "settings--org-members--filter-toggle",
    content: 
      _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        ,  filterConfig.map(([filter, label]) => (
          _react2.default.createElement(_Tooltip.TooltipMenuItem, {
            key: filter,
            iconSrc: "/assets/icons/icon-step-done.svg",
            iconClassName: _classBuilder2.default.call(void 0, 
              "settings--org-members--filter-item-icon",
              pendingFilter === filter && "settings--org-members--filter-item-icon--active",
            ),
            label: label,
            onClick: () => setPendingFilter(filter), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
          )
        ))
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
  
    , _react2.default.createElement('div', { role: "button", className: "settings--org-members--filter-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
  )
)

exports. default = Filter
