"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _toolkit = require('@reduxjs/toolkit');


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);


const initialState = {}

exports. default = _toolkit.createReducer.call(void 0, 
  initialState,
  builder => {
    [
      _actionCreators2.default.loginSuccessAction,
      _actionCreators2.default.createPatientActions.SUCCESS,
      _actionCreators2.default.updatePatientActions.SUCCESS,
      _actionCreators2.default.loadPatientConversationByCodeActions.SUCCESS,
      _actionCreators2.default.getPatientConversationMessagesActions.SUCCESS,
    ].forEach(t =>
      builder.addCase(t, (s, a) => ({ ...s, ...a.payload.Patients })),
    )

    ;[
      _actionCreators2.default.findPatientsInOrgActions.SUCCESS,
      _actionCreators2.default.findCampaignMessagesActions.SUCCESS,
    ].forEach(t => builder.addCase(t, (s, a) => {
      Object.values(a.payload.Patients)
        .forEach(p => s[p.PatientKey] = p )
    }))

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)
  },
)
