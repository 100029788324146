"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/TeamCommunication.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _cost = require('shared/services/cost');
var _upgradePlans = require('shared/constants/upgradePlans');
var _components = require('./components');










const TeamCommunication = ({
  isActive,
  isEditable,
  membersCount,
  maxMembersCount,
  baseCost,
  onCheckout,
}) => (
  _react2.default.createElement(_components.FeatureItem, {
    title: _upgradePlans.teamCommunicationPlan.name,
    desc: "Chat with more than 10 members, and unlock powerful features."         ,
    iconSrc: _upgradePlans.teamCommunicationPlan.iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
  
    ,  isActive
      ? _react2.default.createElement(_components.PaymentReport, {
          usageCount: membersCount,
          title: "users",
          subtitle: `${_cost.formatDisplayCost.call(void 0, baseCost)}/user/month`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        )
      : _react2.default.createElement(_components.FeatureUsage, {
          title: "Free Tier" ,
          isEditable: isEditable,
          subtitle: `${membersCount} of ${maxMembersCount} users`,
          desc: membersCount > maxMembersCount
            ? 'Upgrade to continue chatting with coworkers.'
            : `Chat with up to ${maxMembersCount} coworkers.`
          ,
          progress: membersCount / maxMembersCount,
          onCheckout: onCheckout, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        )
    
  )
)

exports. default = TeamCommunication
