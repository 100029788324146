"use strict";Object.defineProperty(exports, "__esModule", {value: true});const defaultVariants = ['REQUEST', 'SUCCESS', 'FAILURE'] 



const AsyncAction = (
  domain,
  action,
  variants = defaultVariants,
) =>
  variants.reduce(
    (acc, v) => {
      acc[v] = `${domain}/${action}/${v}`
      return acc
    },
    {} ,
  )

exports. default = AsyncAction
