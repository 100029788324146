"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/ToolBar/ExportRelationshipsBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





const ExportRelationshipsBtn = ({ onClick }) => (
  _react2.default.createElement('button', {
    onClick: onClick,
    className: "settings--org-members--export-relationships-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
  
    , _react2.default.createElement('img', { src: "/assets/icons/icon-download-purple.svg", alt: "\\u2B73", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} )
  )
)

exports. default = ExportRelationshipsBtn
