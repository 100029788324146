"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperMoveUserToAnotherOrgModal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);

 class SuperMoveUserToAnotherOrgModal extends _react.Component {
  constructor (props) {
    super(props);SuperMoveUserToAnotherOrgModal.prototype.__init.call(this);SuperMoveUserToAnotherOrgModal.prototype.__init2.call(this);SuperMoveUserToAnotherOrgModal.prototype.__init3.call(this);SuperMoveUserToAnotherOrgModal.prototype.__init4.call(this);SuperMoveUserToAnotherOrgModal.prototype.__init5.call(this);SuperMoveUserToAnotherOrgModal.prototype.__init6.call(this);
    this.state = {
      userKeys: "",
      user: {},
      fromOrgKey: "",
      fromOrg: {},
      toOrgKey: "",
      toOrg: {},

      loadingUserInfo: false,
      hasUserInfo: false,
      errorMessage: null,
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.open && !prevProps.open) {
      /* when modal opens, fill in default org key */
      this.setState({ fromOrgKey: this.props.currentOrgKey })
    }

    /* reset form if closed */
    if (!this.props.open && prevProps.open) {
      this.resetForm()
    }
  }

  __init() {this.getUserKeysArray = () => {
    /* convert our textfield into an array of user keys */
    return this.state.userKeys.split(',').map(v => v.trim()).filter(v => v !== '')
  }}

  __init2() {this.resetForm = () => {
    this.setState({
      fromOrgKey: this.props.currentOrgKey,
      toOrgKey: "",
      userKeys: "",
      loadingUserInfo: false,
      hasUserInfo: false,
      errorMessage: null,
    })
  }}

  __init3() {this.onSubmit = () => {
    this.setState({ loadingUserInfo: true })
    Promise.all([
      _actions2.default.getOrgs({ OrgKeys: [this.state.fromOrgKey, this.state.toOrgKey] }),
      _actions2.default.getUsers({ UserKeys: this.getUserKeysArray() }),
    ]).then(res => {
      this.setState({ hasUserInfo: true })
      const orgsResponse = res[0]
      const userResponse = res[1]

      if (!orgsResponse.Orgs || !userResponse.Users) {
        throw new Error("Bad Keys used")
      }

      const fromOrg = orgsResponse.Orgs[this.state.fromOrgKey]
      const toOrg = orgsResponse.Orgs[this.state.toOrgKey]

      if (!fromOrg || !toOrg) {
        throw new Error("Missing People")
      }

      this.setState({ fromOrg, toOrg })
    }).catch(err => {
      log.error(err)
      this.setState({ errorMessage: err.toString() })
    })
  }}

  __init4() {this.onConfirm = () => {
    _client2.default.send('SuperMoveUsers', {
      FromOrgKey: this.state.fromOrgKey,
      ToOrgKey: this.state.toOrgKey,
      UserKeys: this.getUserKeysArray(),
      MoveConversations: true,
    }).then(() => {
      this.props.onClose()
    }).catch(err => {
      log.error(err)
      this.setState({ errorMessage: err })
    })
  }}

  __init5() {this.onChange = (id, val) => {
    if (this.state.loadingUserInfo) {
      return
    }

    this.setState({
      [id]: val,
    })
  }}

  __init6() {this.loadAllUsers = () => {
    const userKeysArr = this.props.users.map(u => u.UserKey).join(', ')
    this.setState({ userKeys: userKeysArr })
  }}

  render () {
    const numUsers = this.getUserKeysArray().length
    const enterKeysDialog = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "UserKey(s), separated by a comma"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 115}},  numUsers > 0 && `${numUsers} loaded` )
          , _react2.default.createElement(_Form.TextInput, {
            value: this.state.userKeys,
            onChange: e => this.onChange('userKeys', e.target.value),
            placeholder: "Enter userKey (e.g. ab16e2ce-5e07-4178-915f-19182bf7eeee)"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
          )
        )
        , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} ), _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 122}} )
        , _react2.default.createElement(_Form.Button, { onClick: this.loadAllUsers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "Load all userKeys"  )

        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Current Organization Key"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
          , _react2.default.createElement(_Form.TextInput, {
            value: this.state.fromOrgKey,
            onChange: e => this.onChange('fromOrgKey', e.target.value),
            placeholder: "Enter current user org key (e.g. f4a6719d-856e-48c3-8a4a-5e05de6ed085)"      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
          )
        )

        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Target Organization Key"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
          , _react2.default.createElement(_Form.TextInput, {
            value: this.state.toOrgKey,
            onChange: e => this.onChange('toOrgKey', e.target.value),
            placeholder: "Enter desired org key (e.g. defd6a4f-5f64-4eb5-a665-7276b6719871)"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
          )
        )

        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          primaryBtnLabel: "Move User(s)" ,
          isPrimaryLoading: this.state.loadingUserInfo,
          onPrimaryBtnClick: this.onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}}
        )
      )
    )

    const confirmMoveDialog = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}, "Are you sure you want to move users from"
                  , ' '
          , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}, this.state.fromOrg.Name), " to" , ' '
          , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}, this.state.toOrg.Name), "?"
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          primaryBtnLabel: "Confirm",
          onPrimaryBtnClick: this.onConfirm, __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}
        )
      )
    )

    const errorDialog = (
      _react2.default.createElement('div', { style: { color: 'red' }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}},  this.state.errorMessage )
    )

    let content = null
    if (this.state.errorMessage !== null) content = errorDialog
    else if (this.state.hasUserInfo) content = confirmMoveDialog
    else content = enterKeysDialog

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.open,
        onClose: this.props.onClose,
        title: "Move user to another organization"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 177}}
      
        ,  content 
      )
    )
  }
} exports.default = SuperMoveUserToAnotherOrgModal;

SuperMoveUserToAnotherOrgModal.propTypes = {
  open: _proptypes2.default.bool,
  onClose: _proptypes2.default.func,
  users: _proptypes2.default.arrayOf(_proptypes2.default.any),
  currentOrgKey: _proptypes2.default.string,
}
