"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/SimpleTextArea.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);










const SimpleTextArea =
  ({ className, hasError, ...props }, ref) => (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
      'blm-simple-textarea',
      hasError && 'blm-simple-textarea--has-error',
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , _react2.default.createElement('textarea', { ref: ref, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
      ,  props.maxLength && (
        _react2.default.createElement('span', { className: "blm-simple-textarea--limit", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          ,  props.maxLength - props.value.length , " left"
        )
      )
    )
  )

exports. default = _react2.default.forwardRef(SimpleTextArea)
