"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _activity = require('./activity');
var _auth = require('./auth');
var _inbox = require('./inbox');
var _directory = require('./directory');
var _broadcast = require('./broadcast');
var _settings = require('./settings');
var _alert = require('./alert');
var _app = require('./app');
var _upgrade = require('./upgrade');
var _notifications = require('./notifications');
var _session = require('./session');
var _chat = require('./chat');
var _patientConversation = require('./patientConversation');
var _call = require('./call');

const actionCreators = {
  ..._settings.actionCreators,
  ..._activity.actionCreators,
  ..._app.actionCreators,
  ..._auth.actionCreators,
  ..._alert.actionCreators,
  ..._inbox.actionCreators,
  ..._directory.actionCreators,
  ..._broadcast.actionCreators,
  ..._upgrade.actionCreators,
  ..._notifications.actionCreators,
  ..._session.actionCreators,
  ..._chat.actionCreators,
  ..._patientConversation.actionCreators,
  ..._call.actionCreators,
}

exports. default = actionCreators
