"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _amplitudejs = require('amplitude-js'); var _amplitudejs2 = _interopRequireDefault(_amplitudejs);

const stub = () => ({
  setUserId: () => null,
  setOrgNames: () => null,
  logEvent: () => null,
})

const init = (apiKey) => {
  const isElectron = !!window.ELECTRON

  _amplitudejs2.default.getInstance().init(apiKey, null, {
    transport: "beacon",
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    logLevel: "INFO",
  })

  _amplitudejs2.default.getInstance().setVersionName(_optionalChain([window, 'access', _ => _.ELECTRON, 'optionalAccess', _2 => _2.version]) || BUILD)
  _amplitudejs2.default.getInstance().identify(new _amplitudejs2.default.Identify()
    .set('development', SOCKET_SERVER_HOST !== 'api.bloomtext.com')
    .set('electron', isElectron))

  return {
    setUserId: (userId) => {
      _amplitudejs2.default.getInstance().setUserId(userId)
    },
    setOrgNames: (orgNames, isBloom) => {
      _amplitudejs2.default.getInstance().identify(
        new _amplitudejs2.default.Identify()
          .set('orgs', orgNames)
          .set('bloom', isBloom),
      )
    },
    logEvent: (eventType, eventProperties) => { // eslint-disable-line
      _amplitudejs2.default.getInstance().logEvent(eventType, eventProperties)
    },
  }
}

exports. default = AMPLITUDE_API_KEY ? init(AMPLITUDE_API_KEY) : stub()
