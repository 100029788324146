"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _snackbar = require('web/actions/snackbar');



const initialState = []

exports. default = _utils.createReducer.call(void 0, 
  initialState,
  builder => builder
    .addCase(_snackbar.pushSnackbar, (state, action) => [action.payload].concat(state))
    .addCase(_snackbar.closeSnackbarById, (state, action) =>
      state.filter(snackbar => snackbar.id !== action.payload.id),
    )
    .addDefaultCase(s => s),
)
