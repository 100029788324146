"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Sidebar/ProfileIcon.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _store = require('web/store');

var _FirstTimeTooltips = require('web/components/FirstTimeTooltips');
var _Avatar = require('web/components/Avatar');
var _TitleTooltip = require('./TitleTooltip'); var _TitleTooltip2 = _interopRequireDefault(_TitleTooltip);





const ProfileIcon = ({ userKey }) => (
  _react2.default.createElement('div', { role: "button", className: "blm-sidebar--profile-icon--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    , _react2.default.createElement(_FirstTimeTooltips.WithProfileTooltip, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      , _react2.default.createElement(_TitleTooltip2.default, { title: "Your Profile" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
        , _react2.default.createElement('div', {
          className: "blm-sidebar--profile-icon",
          onClick: () => _store.history.push('/settings/profile'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        
          , _react2.default.createElement(_Avatar.UserAvatar, { size: 40, noOnlineIndicator: true, userKey: userKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}} )
        )
      )
    )
  )
)

exports. default = ProfileIcon
