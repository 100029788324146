"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');



var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

var _types = require('./types');
var _useMemberActions = require('./hooks/useMemberActions'); var _useMemberActions2 = _interopRequireDefault(_useMemberActions);
var _usePagination = require('shared/hooks/usePagination'); var _usePagination2 = _interopRequireDefault(_usePagination);
var _useBus = require('shared/hooks/useBus'); var _useBus2 = _interopRequireDefault(_useBus);
var _useSearchQuery = require('shared/hooks/useSearchQuery');

var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _Org = require('web/components/Settings/Org');
var _UserItem = require('./UserItem'); var _UserItem2 = _interopRequireDefault(_UserItem);







var _Modals = require('./components/Modals');
var _ToolBar = require('./components/ToolBar'); var _ToolBar2 = _interopRequireDefault(_ToolBar);








const USER_LOAD_LIMIT = 30
const keyExtractor = (u) => u.UserKey
const keyActionTypes = [
  _actionCreators2.default.changeUserOrgRoleActions.SUCCESS.type,
  _actionCreators2.default.changeUserOrgTitleActions.SUCCESS.type,
  _actionCreators2.default.addUsersToOrgActions.SUCCESS.type,
  _actionCreators2.default.removeUserFromOrgActions.SUCCESS.type,
  _actionCreators2.default.approvePendingUserActions.SUCCESS.type,
  _actionCreators2.default.rejectPendingUserActions.SUCCESS.type,
]

const OrgMembersTab = ({
  orgKey,
  orgName,
  hasPendingUsers,
  canExportRelationships,
}) => {
  const [searchQuery, setSearchQuery] = _useSearchQuery.useSearchQuery.call(void 0, )
  const [pendingFilter, setPendingFilter] = _react.useState(
    hasPendingUsers ? _types.PendingFilter.PENDING : _types.PendingFilter.ALL,
  )

  const {
    selectedUser,
    onResendUserInvite,
    isRemoveModalOpen, onCloseRemoveModal, onRemoveUser, onConfirmRemoveUser,
    isApproveModalOpen, onCloseApproveModal, onApproveUser, onConfirmApproveUser,
    isRejectModalOpen, onCloseRejectModal, onRejectUser, onConfirmRejectUser,
    isEditModalOpen, onCloseEditModal, onEditUser, onSubmitEditedUser,
    isAddMembersModalOpen, onOpenAddMembersModal, onCloseAddMembersModal, onAddMembers,
    onExportRelationships, isExportRelationshipsModalOpen, onOpenExportRelationshipsModal,
    onCloseExportRelationshipsModal,
    gotoUserPage,
  } = _useMemberActions2.default.call(void 0, orgKey)

  const loadItemsList = _react.useCallback.call(void 0, 
    (Query, Offset, Limit) => _actions2.default
      .findUsersInOrg({
        OrgKey: orgKey,
        Pending: pendingFilter,
        Query,
        Offset,
        Limit,
      })
      .then(r => r.Users),
    [orgKey, pendingFilter],
  )

  const { isLoading, hasDrained, itemKeysList, onLoadMore, onReset } =
    _usePagination2.default.call(void 0, {
      loadItemsList,
      keyExtractor,
      searchQuery,
      limit: USER_LOAD_LIMIT,
    })
  _useBus2.default.call(void 0, keyActionTypes, onReset)

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "settings--org-members--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        , _react2.default.createElement(_ToolBar2.default, {
          searchQuery: searchQuery,
          setSearchQuery: setSearchQuery,
          pendingFilter: pendingFilter,
          setPendingFilter: setPendingFilter,
          onAddMembers: onOpenAddMembersModal,
          onExportRelationships: onOpenExportRelationshipsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        )
      )
      , _react2.default.createElement(_Org.TabWrapper, { classNameSuffix: "members", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
        , _react2.default.createElement('div', { className: "settings--org-members--list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
          ,  itemKeysList.map((userKey) => (
            _react2.default.createElement(_UserItem2.default, {
              key: userKey,
              userKey: userKey,
              orgKey: orgKey,
              onEditUser: onEditUser,
              onRemoveUser: onRemoveUser,
              onApproveUser: onApproveUser,
              onRejectUser: onRejectUser,
              onResendUserInvite: onResendUserInvite,
              onGotoUser: gotoUserPage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
            )
          ))
          ,  itemKeysList.length && !hasDrained
            ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: onLoadMore, bottomOffset: -100, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}} )
            : null
          
        )
        , _react2.default.createElement(_ListResultsStatus2.default, {
          isLoading: isLoading,
          resultsCount: itemKeysList.length,
          noResultsLabel: searchQuery ? `No results for "${searchQuery}"` : 'No results',
          className: "settings--org-members--results-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
        )
      )
      , _react2.default.createElement(_Modals.AddMembersModal, {
        isOpen: isAddMembersModalOpen,
        onClose: onCloseAddMembersModal,
        orgKey: orgKey,
        orgName: orgName,
        onSubmit: onAddMembers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
      )
      , _react2.default.createElement(_Modals.ExportRelationshipsModal, {
        isOpen: isExportRelationshipsModalOpen,
        onClose: onCloseExportRelationshipsModal,
        orgName: orgName,
        onSubmit: canExportRelationships ? onExportRelationships : null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}
      )
      ,  selectedUser && (
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement(_Modals.ManageMemberModal, {
            key: `manage-${selectedUser.entityKey}`,
            isOpen: isEditModalOpen,
            onClose: onCloseEditModal,
            entity: selectedUser,
            orgKey: orgKey,
            onSubmit: onSubmitEditedUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
          )
          , _react2.default.createElement(_Modals.ApproveMemberModal, {
            key: `approve-${selectedUser.entityKey}`,
            isOpen: isApproveModalOpen,
            onClose: onCloseApproveModal,
            entity: selectedUser,
            orgKey: orgKey,
            orgName: orgName,
            onProceed: onConfirmApproveUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}
          )
          , _react2.default.createElement(_Modals.RejectMemberModal, {
            key: `reject-${selectedUser.entityKey}`,
            isOpen: isRejectModalOpen,
            onClose: onCloseRejectModal,
            entity: selectedUser,
            orgKey: orgKey,
            orgName: orgName,
            onProceed: onConfirmRejectUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
          )
          , _react2.default.createElement(_Modals.RemoveMemberModal, {
            key: `remove-${selectedUser.entityKey}`,
            isOpen: isRemoveModalOpen,
            onClose: onCloseRemoveModal,
            entity: selectedUser,
            orgKey: orgKey,
            orgName: orgName,
            onProceed: onConfirmRemoveUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
          )
        )
      )
    )
  )
}

exports. default = OrgMembersTab
