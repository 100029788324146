"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/EnterEmail/EnterEmail.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactrouterdom = require('react-router-dom');

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _signup = require('shared/validation/signup');
var _errors = require('shared/errors');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _noticeBar = require('web/services/context/noticeBar');
var _Form = require('web/components/Form');


var _components = require('web/screens/Signup/components');





const activeEmailMsg = "Email is already being used by an existing BloomText user"
const genericErrorMsg = "Something went wrong - try again or try contacting support"

const EnterEmail = () => {
  _react.useEffect.call(void 0, () => _amplitude2.default.logEvent("SIGN_UP_ENTER_EMAIL"), [])
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  const [, defaultEmail = ''] = window.location.href.split('?email=') || []
  const { register, resetField, handleSubmit, formState } = _reacthookform.useForm.call(void 0, {
    resolver: _signup.emailResolver,
    mode: 'onChange',
    defaultValues: { email: defaultEmail },
  })
  const { isSubmitting, isSubmitSuccessful, isValid, isDirty, errors } = formState

  _react.useEffect.call(void 0, 
    () => resetField('email', { defaultValue: defaultEmail }),
    [defaultEmail, resetField],
  )

  const onSubmit = async ({ email }) => {
    const path = window.location.href.split('?').length > 1
      && window.location.href.split('?')[1]
      || ''

    return _client2.default.checkWelcomeCredential(email, path)
      .then(r => {
        if (r.Status === "Server Error") throw new (0, _errors.BlmError)('Server Error')
        if (r.Status !== "Active") return

        _actions2.default.gotoEmailLogin(email)
        throw new Error(activeEmailMsg)
      })
      .catch(e => {
        const message = _optionalChain([e, 'optionalAccess', _ => _.message]) === activeEmailMsg ? activeEmailMsg : genericErrorMsg
        if (_optionalChain([e, 'optionalAccess', _2 => _2.message]) !== activeEmailMsg) log.error(e)

        showWarningBar(message)
      })
  }

  if (isSubmitSuccessful && isValid) {
    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_components.SignupStepHeader, {
          iconType: "email",
          title: "Check your email"  ,
          subtitle: "We've sent a link to your email so you can validate your account and continue signing up."                , __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        )
        , _react2.default.createElement(_components.SignupStepNotice, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, "Note: If you don't see any message in your inbox, try checking your spam folder."

        )
      )
    )
  }

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(onSubmit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "doctor",
        title: "Let's get started"  ,
        subtitle: "Enter your work email to get started."      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      )
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "Work Email" ,
        placeholder: "E.g. john@yourclinic.com" ,
        errorMessage: _optionalChain([errors, 'access', _3 => _3.email, 'optionalAccess', _4 => _4.message]),
        inputProps: register('email'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
      )
      , _react2.default.createElement(_components.SignupStepControls, {
        isNextLoading: isSubmitting,
        isNextDisabled: !isValid || !isDirty, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
      )
      , _react2.default.createElement(_components.SignupStepNotice, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}, "Already have an account? "
            , _react2.default.createElement(_reactrouterdom.Link, { to: _routes2.default.LOGIN.ROOT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}, "Log in" )
      )
    )
  )
}

exports. default = EnterEmail
