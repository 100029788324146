"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reselect = require('reselect');





var _entity = require('shared/services/entity');

var _orgs = require('./orgs');
var _teams = require('./teams');

 const selectMyEntities = _reselect.createSelector.call(void 0, 
  _orgs.selectMyOrgEntitiesList,
  _teams.selectMyTeamEntitiesList,
  (orgsList, teamsList) => ({
    orgsList,
    teamsList,
    entitiesMap:
      _entity.entityListToMap.call(void 0, (orgsList ).concat(teamsList)),
  }),
); exports.selectMyEntities = selectMyEntities
