"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/RichMessageWrapper/ContextMenu.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Tooltip = require('web/components/Tooltip');








const ContextMenu = ({ onClose, onCopy, onCopyMessageLink, onDelete }) => (
  _react2.default.createElement(_Tooltip.TooltipContent, {
    onClick: onClose,
    className: "rich-message--menu--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
  
    ,  onCopy && (
      _react2.default.createElement('span', { onClick: onCopy, className: "rich-message--menu--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , _react2.default.createElement('img', { src: "assets/icons/video/copy-black.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} ), "Copy message"

      )
    )
    ,  onDelete && (
      _react2.default.createElement('span', {
        onClick: onDelete,
        className: "rich-message--menu--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      
        , _react2.default.createElement('img', { src: "assets/icons/inbox/delete-black.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} ), "Recall message"

      )
    )
    , _react2.default.createElement('span', {
      onClick: onCopyMessageLink,
      className: "rich-message--menu--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
    
      , _react2.default.createElement('img', { src: "assets/icons/inbox/link-black.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} ), "Copy link"

    )
  )
)

exports. default = ContextMenu
