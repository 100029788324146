"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _reactredux = require('react-redux');
var _conversation = require('shared/selectors/conversation');

class Notifications extends _react.PureComponent {
  _updateHref (id, href) {
    document.getElementById(id).href = href
  }

  setAttention () {
    this._updateHref('apple-touch-icon', 'assets/images/favicons/alert-apple-touch-icon.png')
    this._updateHref('favicon-32x32', 'assets/images/favicons/alert-favicon-32x32.png')
    this._updateHref('favicon-16x16', 'assets/images/favicons/alert-favicon-16x16.png')
    this._updateHref('favicon', 'assets/images/favicons/alert-favicon.ico')
    this._updateHref('favicon-manifest', 'assets/images/favicons/alert-manifest.json')
  }

  unsetAttention () {
    this._updateHref('apple-touch-icon', 'assets/images/favicons/apple-touch-icon.png')
    this._updateHref('favicon-32x32', 'assets/images/favicons/favicon-32x32.png')
    this._updateHref('favicon-16x16', 'assets/images/favicons/favicon-16x16.png')
    this._updateHref('favicon', 'assets/images/favicons/favicon.ico')
    this._updateHref('favicon-manifest', 'assets/images/favicons/manifest.json')
  }

  componentDidUpdate () {
    if (this.props.attention) this.setAttention()
    else this.unsetAttention()
  }

  render () {
    return null
  }
}

const mapState = state => ({ attention: _conversation.selectInboxHasAlert.call(void 0, state) })
exports. default = _reactredux.connect.call(void 0, mapState)(Notifications)
