"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/ConversationBox/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');






var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _time = require('shared/services/time');

var _ChatNotice = require('web/components/Upgrade/ChatNotice'); var _ChatNotice2 = _interopRequireDefault(_ChatNotice);
var _BeginningMarker = require('web/components/Conversation/BeginningMarker'); var _BeginningMarker2 = _interopRequireDefault(_BeginningMarker);
var _LoadingIndicator = require('web/components/Conversation/LoadingIndicator'); var _LoadingIndicator2 = _interopRequireDefault(_LoadingIndicator);
var _NewMessagesMarker = require('web/components/Conversation/NewMessagesMarker'); var _NewMessagesMarker2 = _interopRequireDefault(_NewMessagesMarker);
var _Message = require('web/components/Conversation/Message'); var _Message2 = _interopRequireDefault(_Message);
var _JumpBtn = require('web/components/Conversation/JumpBtn'); var _JumpBtn2 = _interopRequireDefault(_JumpBtn);





var _hooks = require('./hooks');

const containerStyles = { flexDirection: 'column-reverse' }
const topSpacerStyles = { flexShrink: 0 }
const makeScrollbarAwareStyle = (containerDOM) => containerDOM
  ? { paddingRight: containerDOM.offsetWidth - containerDOM.clientWidth }
  : undefined






























const ConversationBox = ({
  onLoadBelow,
  onLoadAbove,
  isLoadingBelow,
  isLoadingAbove,
  messageBatches,
  relevantBatchIdx,
  latestBatchIdx,
  relevantMessageKey,
  highlightedMessageKey,
  currentUserKey,
  isCurrentUserActive,
  subscribers,
  onDelete,
  onCopyMessageLink,
  onAbortSendFile,
  onRetrySendMessage,
  onRetrySendFile,
  conversationKey,
  onMarkMessageSeen,
  onMarkConversationVisible,
  lastSeenMessageKey,
  lastSeenTime,
  unreadMessagesCount = 0,
  totalMessagesCount,
  blobjectKey,
  className,
}) => {
  const [cachedLastSeenMessageKey, setCachedLastSeenMessageKey] = _react.useState(null)
  const { messages, setLatestBatch, isOnLatestBatch } =
    _hooks.useBatchSwitch.call(void 0, messageBatches, relevantBatchIdx, latestBatchIdx)

  const handleViewMessage = _hooks.useViewMessage.call(void 0, onMarkMessageSeen, currentUserKey)
  const messagesCount = messages.length
  const hasMessages = !!messagesCount

  const containerRef = _react.useRef.call(void 0, null)
  const getContainerDOM = () => containerRef.current

  const scrollbarAwareStyle = makeScrollbarAwareStyle(getContainerDOM())
  const scrollToBottom = _hooks.useScrollToBottom.call(void 0, { setLatestBatch, getContainerDOM })
  const scrollToMessage = _hooks.useScrollToMessage.call(void 0, { getContainerDOM })

  const hasSettledScroll = _hooks.useInitialScroll.call(void 0, {
    getContainerDOM,
    hasMessages,
    relevantMessageKey,
    scrollToMessage,
  })

  const {
    renderTopBoundaryWaypoint,
    renderBottomBoundaryWaypoint,
  } = _hooks.useBoundaryHitbox.call(void 0, {
    messages,
    onLoadBelow,
    onLoadAbove,
    hasSettledScroll,
    getContainerDOM,
  })

  const latestMessage =
    ((_nullishCoalesce(_optionalChain([messages, 'optionalAccess', _ => _[0], 'optionalAccess', _2 => _2.Sequence]), () => ( 0))) >= totalMessagesCount && _optionalChain([messages, 'optionalAccess', _3 => _3[0]])) || {} 
  const isLatestMessageFromMe = latestMessage.UserKey === currentUserKey
  const maxMessageSequence = Math.max(
    totalMessagesCount,
    latestMessage.Sequence || 0,
  )
  const { isAtBottom, renderStickyBottomWaypoint } = _hooks.useStickyBottom.call(void 0, {
    maxMessageSequence,
    isLatestMessageFromMe,
    isCurrentUserActive,
    cachedLastSeenMessageKey,
    onMarkConversationVisible,
    scrollToBottom,
    getContainerDOM,
  })

  _hooks.useLastSeenMessageCache.call(void 0, {
    lastSeenMessageKey,
    cachedLastSeenMessageKey,
    setCachedLastSeenMessageKey,
    isCurrentUserActive,
    unreadMessagesCount,
    hasSettledScroll,
    isAtBottom,
  })

  return (
    _react2.default.createElement('div', { className: "conversation-box--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}
      , _react2.default.createElement('div', {
        ref: containerRef,
        style: containerStyles,
        className: _classBuilder2.default.call(void 0, "conversation-box", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 150}}
      
        , _react2.default.createElement(_ChatNotice2.default, { blobjectKey: blobjectKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}} )
        ,  renderStickyBottomWaypoint(hasMessages && isOnLatestBatch) 
        ,  renderBottomBoundaryWaypoint() 
        ,  isLoadingBelow
          ? _react2.default.createElement(_LoadingIndicator2.default, { isBottom: true, style: scrollbarAwareStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}} )
          : null
        
        ,  messages.map((m, i) => (
          _react2.default.createElement(_react2.default.Fragment, { key: m.MessageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 163}}
            ,  hasSettledScroll &&
              _time.isAfter.call(void 0, m.SentAt, lastSeenTime) &&
              isCurrentUserActive
              ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: () => handleViewMessage(m), __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}} )
              : null
            
            ,  m.MessageKey === cachedLastSeenMessageKey && i !== 0
              ? _react2.default.createElement(_NewMessagesMarker2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 171}} )
              : null
            
            , _react2.default.createElement(_Message2.default, {
              currentUserKey: currentUserKey,
              subscribers: subscribers,
              highlighted: m.MessageKey === highlightedMessageKey,
              onDelete: onDelete,
              onCopyMessageLink: onCopyMessageLink,
              onRetrySendMessage: onRetrySendMessage,
              onRetrySendFile: onRetrySendFile,
              onAbortSendFile: onAbortSendFile,
              conversationKey: conversationKey,
              isLastMessage: i === 0,
              ...m, __self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}
            )
            ,  m.Sequence === 1 ? _react2.default.createElement(_BeginningMarker2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 187}} ) : null 
          )
        ))
        ,  isLoadingAbove ? _react2.default.createElement(_LoadingIndicator2.default, { isTop: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}} ) : null 
        ,  renderTopBoundaryWaypoint() 
        , _react2.default.createElement('div', { className: "conversation-box--spacer", style: topSpacerStyles, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}} )
      )
      ,  !isAtBottom && hasSettledScroll &&
        (unreadMessagesCount || highlightedMessageKey)
        ? (
            _react2.default.createElement(_JumpBtn2.default, {
              style: scrollbarAwareStyle,
              onClick: () => scrollToBottom({ shouldAnimate: true }),
              unreadMessagesCount: unreadMessagesCount, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
            )
          )
        : null
      
    )
  )
}

exports. default = ConversationBox
