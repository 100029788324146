"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');





var _user = require('shared/selectors/user');
var _org = require('shared/selectors/org');
var _peers = require('shared/selectors/peers');

 const selectAllPendingUsers = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  _org.selectOrgs,
  _peers.selectAllV1Users,
  (me, orgs, users) => Object.values(me.Relationships)
    .reduce(
      (acc, rel) => {
        const org = orgs[rel.OrgKey]
        if (rel.RelType === 'admin' && _optionalChain([org, 'optionalAccess', _ => _.PendingUsers])) {
          org.PendingUsers.forEach(uk => acc.push({
            userKey: uk,
            userName: _nullishCoalesce(_optionalChain([users, 'access', _2 => _2[uk], 'optionalAccess', _3 => _3.Name]), () => ( '')),
            userContact: _nullishCoalesce(_optionalChain([users, 'access', _4 => _4[uk], 'optionalAccess', _5 => _5.Email]), () => ( '')),
            orgKey: rel.OrgKey,
            orgName: _optionalChain([org, 'optionalAccess', _6 => _6.Name]),
          }))
        }
        return acc
      },
      [] ,
    ),
); exports.selectAllPendingUsers = selectAllPendingUsers

 const selectPendingUsersInOrg = _reselect.createSelector.call(void 0, 
  exports.selectAllPendingUsers,
  (_s, orgKey) => orgKey,
  (pendingUsers, orgKey) => pendingUsers.filter(u => u.orgKey === orgKey),
); exports.selectPendingUsersInOrg = selectPendingUsersInOrg
