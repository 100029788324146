"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ListResultsStatus.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Spinner = require('web/components/Spinner');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const ListResultsStatus = ({
  isLoading,
  resultsCount,
  noResultsLabel = 'Nothing found.',
  className,
  labelClassName,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "list-results-status", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    ,  isLoading && (
      _react2.default.createElement(_Spinner.SimpleSpinner, { className: "list-results-status--loading-spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
    )
    ,  !isLoading && !resultsCount && (
      _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "list-results-status--no-results-label", labelClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        ,  noResultsLabel 
      )
    )
  )
)

exports. default = ListResultsStatus
