"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/SenderSelect/Section.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Option = require('./Option'); var _Option2 = _interopRequireDefault(_Option);








const Section = ({
  optionEntities,
  label,
  onSelectOption,
  selectedOptionKey,
}) => {
  if (!optionEntities.length) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  label && _react2.default.createElement('span', { className: "sender-select--tooltip--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}},  label ) 
      ,  optionEntities
        .map(e => (
          _react2.default.createElement(_Option2.default, {
            key: e.entityKey,
            entity: e,
            isSelected: e.entityKey === selectedOptionKey,
            onSelect: () => onSelectOption(e.entityKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
          )
        ))
      
    )
  )
}

exports. default = Section
