"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/SenderSelect/Option.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Avatar = require('web/components/Avatar');







const Option = ({ entity, onSelect, isSelected }) => (
  _react2.default.createElement('div', {
    role: "button",
    onClick: onSelect,
    className: "sender-select--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
  
    , _react2.default.createElement(_Avatar.EntityAvatar, {
      entity: entity,
      size: 30,
      className: "sender-select--option--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    )
    , _react2.default.createElement('div', { className: "sender-select--option--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
      , _react2.default.createElement('span', { className: "sender-select--option--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}},  entity.name )
      , _react2.default.createElement('span', { className: "sender-select--option--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
        ,  entity.description 
      )
    )
    ,  isSelected && (
      _react2.default.createElement('div', { className: "sender-select--option--selected-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
    )
  )
)

exports. default = Option
