"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/RoomSidebar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _ui = require('web/services/ui');

const currentYear = (new Date()).getFullYear()

 class RoomSidebar extends _react2.default.Component {constructor(...args) { super(...args); RoomSidebar.prototype.__init.call(this);RoomSidebar.prototype.__init2.call(this);RoomSidebar.prototype.__init3.call(this); }
  __init() {this.linkTextRef = null}
  __init2() {this.setLinkTextRef = r => this.linkTextRef = r}

  __init3() {this.handleRoomLinkClick = async () => {
    await this.props.onRoomLinkCopy()
    return _ui.selectTextNode.call(void 0, this.linkTextRef)
  }}

  render () {
    const { roomName, roomLink, onRoomLinkCopy } = this.props

    return (
      _react2.default.createElement('div', { className: "video-call--room-sidebar--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        , _react2.default.createElement('div', { className: "video-call--room-sidebar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
          , _react2.default.createElement('div', { className: "video-call--room-sidebar--logo-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
            , _react2.default.createElement('img', {
              className: "video-call--room-sidebar--logo",
              src: "/assets/icons/video/bloom-logo-white.svg",
              alt: "BloomText", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            )
          )
          , _react2.default.createElement('div', { className: "video-call--room-sidebar--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
            , _react2.default.createElement('h3', { className: "video-call--room-sidebar--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Video Meeting"

            )
            , _react2.default.createElement('h1', { className: "video-call--room-sidebar--room-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
              ,  roomName 
            )
            ,  roomLink &&
              _react2.default.createElement(_react2.default.Fragment, null
                , _react2.default.createElement('a', {
                  ref: this.setLinkTextRef,
                  onClick: this.handleRoomLinkClick,
                  className: "video-call--room-sidebar--room-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
                
                  , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 43}},  roomLink )
                )
                , _react2.default.createElement('div', { className: "video-call--room-sidebar--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
                  , _react2.default.createElement('a', { className: "video-call--room-sidebar--btn", onClick: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
                    , _react2.default.createElement('img', { src: "/assets/icons/video/copy-white.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} ), "Copy link"

                  )
                )
              )
            
          )
          , _react2.default.createElement('div', { className: "video-call--room-sidebar--footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
            , _react2.default.createElement('span', { className: "video-call--room-sidebar--footer-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}, "© "
               ,  currentYear , " BloomAPI Inc."
            )
          )
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    roomName: _proptypes2.default.string.isRequired,
    roomLink: _proptypes2.default.string.isRequired,
    onRoomLinkCopy: _proptypes2.default.func.isRequired,
  }}
} RoomSidebar.__initStatic(); exports.default = RoomSidebar;
