"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _array = require('shared/services/array');


var _entity = require('shared/enum/entity');








const defaultRestrictions = {
  canAddUsers: true,
  canAddTeams: true,
  canAddPatients: true,
  canAddParticipants: true,
}

const restrictUsers = (rs) => ({ ...rs, canAddUsers: false })
const restrictTeams = (rs) => ({ ...rs, canAddTeams: false })
const restrictPatients = (rs) => ({ ...rs, canAddPatients: false })

const useParticipantQuotas = (
  entitiesList,
  {
    maxUsersCount = Infinity,
    maxTeamsCount = Infinity,
    maxPatientsCount = Infinity,
  },
) => _react.useMemo.call(void 0, 
  () => {
    const {
      users = [],
      teams = [],
      patients = [],
    } = _array.groupBy.call(void 0, entitiesList, e => {
      if (e.type === _entity.ENTITY_TYPES.USER) return 'users'
      if (e.type === _entity.ENTITY_TYPES.TEAM) return 'teams'
      if (e.type === _entity.ENTITY_TYPES.PATIENT) return 'patients'
      return null
    })

    const restrictions = [
      users.length >= maxUsersCount && restrictUsers,
      teams.length >= maxTeamsCount && restrictTeams,
      patients.length >= maxPatientsCount && restrictPatients,
    ].reduce(
      (acc, restrict) => restrict ? restrict(acc) : acc,
      defaultRestrictions,
    )

    const canAddParticipants =
      restrictions.canAddUsers ||
      restrictions.canAddTeams ||
      restrictions.canAddPatients

    return { ...restrictions, canAddParticipants }
  },
  [entitiesList, maxUsersCount, maxTeamsCount, maxPatientsCount],
)

exports. default = useParticipantQuotas
