"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _joi = require('shared/services/validation/joi'); var _joi2 = _interopRequireDefault(_joi);

var _joi3 = require('@hookform/resolvers/joi');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);









const schema = _joi2.default.object({
  dateRangeType: _joi.requiredString.call(void 0, ),
  startDate: _joi.requiredString.call(void 0, )
    .custom((value, helpers) =>
      _optionalChain([helpers, 'access', _ => _.state, 'access', _2 => _2.ancestors, 'optionalAccess', _3 => _3[0], 'optionalAccess', _4 => _4.dateRangeType]) === 'allTime' ||
      _moment2.default.call(void 0, value, _moment2.default.HTML5_FMT.DATE).isBefore()
        ? value
        : helpers.error('date.min'),
    )
    .messages({ 'date.min': 'Please select a past date' }),
  endDate: _joi.requiredString.call(void 0, )
    .custom((value, helpers) => {
      const dateRangeType = _optionalChain([helpers, 'access', _5 => _5.state, 'access', _6 => _6.ancestors, 'optionalAccess', _7 => _7[0], 'optionalAccess', _8 => _8.dateRangeType])
      if (dateRangeType === 'allTime') return value

      const startDate = _optionalChain([helpers, 'access', _9 => _9.state, 'access', _10 => _10.ancestors, 'optionalAccess', _11 => _11[0], 'optionalAccess', _12 => _12.startDate])
      const startMoment = _moment2.default.call(void 0, startDate, _moment2.default.HTML5_FMT.DATE)
      const endMoment = _moment2.default.call(void 0, value, _moment2.default.HTML5_FMT.DATE)
      const diffDays = endMoment.diff(startMoment, 'days')

      if (diffDays < 0) return helpers.error('date.invalid')
      return value
    })
    .messages({ 'date.invalid': 'Please enter a valid date range' }),
  shouldIncludeFiles: _joi2.default.bool(),
})

 const chatExportResolver = _joi3.joiResolver.call(void 0, schema); exports.chatExportResolver = chatExportResolver
