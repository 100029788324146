"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ExportPhoneLogsModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);




var _teamPhoneLogsExport = require('shared/validation/team/teamPhoneLogsExport');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');

const EARLIEST_START_DATE = _moment2.default.call(void 0, ).subtract(180, 'days').toDate()
const DEFAULT_START_DATE = _moment2.default.call(void 0, ).subtract(7, 'days').format(_moment2.default.HTML5_FMT.DATE)
const DEFAULT_END_DATE = _moment2.default.call(void 0, ).format(_moment2.default.HTML5_FMT.DATE)
const startDateRules = { deps: ['endDate' ] }







const ExportPhoneLogsModal = ({ isOpen, onClose, onProceed }) => {
  const { control, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _teamPhoneLogsExport.teamPhoneLogsExportResolver,
      defaultValues: {
        startDate: DEFAULT_START_DATE,
        endDate: DEFAULT_END_DATE,
      },
    })
  const { isValid, errors } = formState

  const submit = _react.useCallback.call(void 0, 
    ({ startDate, endDate }) => {
      const startTime = _moment2.default.call(void 0, startDate, _moment2.default.HTML5_FMT.DATE).utc().toISOString()
      const endTime = _moment2.default.call(void 0, endDate, _moment2.default.HTML5_FMT.DATE).utc()
        .add(1, 'days')
        .toISOString()

      onClose()
      onProceed(startTime, endTime)
    },
    [onProceed, onClose],
  )

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      title: "Export phone logs"  ,
      className: "directory--export-logs--modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
    
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "From",
        errorMessage: _optionalChain([errors, 'access', _ => _.startDate, 'optionalAccess', _2 => _2.message]),
        className: "directory--export-logs--start-section",
        shouldCaptureFocus: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      
        , _react2.default.createElement(_Form.DateInputController, {
          isRequired: true,
          rules: startDateRules,
          disabledDays: { before: EARLIEST_START_DATE },
          name: "startDate",
          control: control, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "To (Inclusive)" ,
        errorMessage: _optionalChain([errors, 'access', _3 => _3.endDate, 'optionalAccess', _4 => _4.message]),
        shouldCaptureFocus: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      
        , _react2.default.createElement(_Form.DateInputController, {
          isRequired: true,
          name: "endDate",
          control: control, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Export",
        secondaryBtnLabel: "Cancel",
        isPrimaryEnabled: isValid,
        onPrimaryBtnClick: handleSubmit(submit),
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
      )
    )
  )
}

exports. default = ExportPhoneLogsModal
