"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');
var _reactredux = require('react-redux');







var _upgrade = require('shared/enum/upgrade');
var _user = require('shared/selectors/user');



var _session = require('shared/selectors/session');

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _hooks = require('./hooks');
var _RightSide = require('web/components/RightSide');
var _App = require('web/screens/App');
var _CreateNew = require('web/screens/CreateNew'); var _CreateNew2 = _interopRequireDefault(_CreateNew);
var _CreateNewChat = require('web/screens/CreateNew/CreateNewChat'); var _CreateNewChat2 = _interopRequireDefault(_CreateNewChat);
var _UpgradeModal = require('web/components/Upgrade/UpgradeModal'); var _UpgradeModal2 = _interopRequireDefault(_UpgradeModal);
var _TeamPage = require('./TeamPage'); var _TeamPage2 = _interopRequireDefault(_TeamPage);
var _UserPage = require('./UserPage'); var _UserPage2 = _interopRequireDefault(_UserPage);
var _PatientPage = require('./PatientPage'); var _PatientPage2 = _interopRequireDefault(_PatientPage);
var _EmptyPage = require('./EmptyPage'); var _EmptyPage2 = _interopRequireDefault(_EmptyPage);
var _Sidebar = require('./Sidebar'); var _Sidebar2 = _interopRequireDefault(_Sidebar);

const Directory = ({
  selectedOrgKey,
  myActiveOrgsMap,
  explicitlySelectedOrgKey,
}) => {
  const {
    routeOrgKey,
    entityKey,
    virtualOrgKey,
    selectedTab,
    setSelectedTab,
    isInPage,
  } = _hooks.useRouting.call(void 0, { myActiveOrgsMap, selectedOrgKey, explicitlySelectedOrgKey })

  const {
    newChatEntitiesList,
    onCreateNewChat,
    onCloseCreateNewChat,
  } = _hooks.useEntityChat.call(void 0, )

  const {
    isOpen: isUpsellModalOpen,
    onOpen: onOpenUpsellModal,
    onClose: onCloseUpsellModal,
  } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_App.AppVertical, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
      , _react2.default.createElement(_App.NavigationMenu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        , _react2.default.createElement(_Sidebar2.default, {
          orgKey: virtualOrgKey,
          onRequestPatientsFeature: onOpenUpsellModal,
          selectedTab: selectedTab,
          onSelectTab: setSelectedTab, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        )
      )
      , _react2.default.createElement(_RightSide.RightSide, { className: "directory--right-side", inView: isInPage, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
          , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.DIRECTORY.USER_PAGE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            ,  entityKey && routeOrgKey && (
              _react2.default.createElement(_UserPage2.default, {
                orgKey: routeOrgKey,
                userKey: entityKey ,
                onStartChat: onCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
              )
            )
          )
          , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.DIRECTORY.PATIENT_PAGE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
            ,  entityKey && routeOrgKey && (
              _react2.default.createElement(_PatientPage2.default, {
                orgKey: routeOrgKey,
                patientKey: entityKey ,
                onRequestPatientsFeature: onOpenUpsellModal,
                onStartChat: onCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
              )
            )
          )
          , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.DIRECTORY.TEAM_PAGE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
            ,  entityKey && routeOrgKey && (
              _react2.default.createElement(_TeamPage2.default, {
                key: entityKey,
                orgKey: routeOrgKey,
                teamKey: entityKey ,
                onStartChat: onCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
              )
            )
          )
          , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.DIRECTORY.ROOT, component: _EmptyPage2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}} )
        )
        ,  selectedOrgKey && (
          _react2.default.createElement(_UpgradeModal2.default, {
            orgKey: selectedOrgKey,
            type: _upgrade.SubscriptionItemType.SMS,
            isOpen: isUpsellModalOpen,
            onClose: onCloseUpsellModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
          )
        )
        ,  !!newChatEntitiesList.length && (
          _react2.default.createElement(_CreateNew2.default, {
            open: true,
            onClose: onCloseCreateNewChat,
            selectedTab: null,
            title: "Create a chat"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          
            , _react2.default.createElement(_CreateNewChat2.default, {
              onClose: onCloseCreateNewChat,
              initialEntitiesList: newChatEntitiesList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
            )
          )
        )
      )
    )
  )
}







const mapState = (state) => {
  const selectedOrgKey = _session.selectSelectedOrgKey.call(void 0, state)
  const explicitlySelectedOrgKey = _session.selectExplicitlySelectedOrgKey.call(void 0, state)
  const myActiveOrgsMap = _user.selectMyActiveOrgsMap.call(void 0, state)

  return { selectedOrgKey, explicitlySelectedOrgKey, myActiveOrgsMap }
}

exports. default = _reactredux.connect.call(void 0, mapState)(Directory)
