"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/OrgsList/OrgsControlsSection/JoinOrgModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);

const JoinOrgModal = props => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: props.isOpen,
    onClose: props.onClose,
    title: "Join or create an organization"    ,
    className: "settings--join-org--modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
  
    , _react2.default.createElement(_Form2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}} )
  )
)

exports. default = JoinOrgModal
