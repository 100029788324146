"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _msgs = require('./msgs');










const usePassword = (displayMsg) => _react.useCallback.call(void 0, 
  ({
    currentPassword, newPassword, newPasswordConfirmation, isPasswordStrong,
  }) => {
    if (!currentPassword.length || !newPassword.length) {
      return displayMsg(_msgs.passwordMsgs.INVALID)
    }
    if (!isPasswordStrong) {
      return displayMsg(_msgs.passwordMsgs.WEAK)
    }
    if (newPassword !== newPasswordConfirmation) {
      return displayMsg(_msgs.passwordMsgs.MISMATCH)
    }

    return _actions2.default.setPassword({
      CurrentPassword: currentPassword,
      NewPassword: newPassword,
    })
      .then(() => displayMsg(_msgs.passwordMsgs.SUCCESS))
      .catch(err => {
        if (_optionalChain([err, 'optionalAccess', _ => _.Error]) === "incorrect password") {
          return displayMsg(_msgs.passwordMsgs.WRONG_PASS)
        }
        if (_optionalChain([err, 'optionalAccess', _2 => _2.Error]) === "weak password") {
          return displayMsg(_msgs.passwordMsgs.WEAK)
        }
        return displayMsg(_msgs.passwordMsgs.ERROR)
      })
  },
  [displayMsg],
)

exports. default = usePassword
