"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }



var _joi = require('shared/services/validation/joi'); var _joi2 = _interopRequireDefault(_joi);
var _hookForm = require('shared/services/validation/hookForm');








const schema = _joi2.default.object({
  name: _joi.requiredString.call(void 0, ).max(100).messages(_joi.makeStrErrors.call(void 0, 'Organization name')),
  address: _joi.optionalString.call(void 0, ).max(100).messages(_joi.makeStrErrors.call(void 0, 'Address')),
  email: _joi.optionalString.call(void 0, ).messages(_joi.makeStrErrors.call(void 0, 'Email')).contactEmail(),
  phone: _joi.optionalString.call(void 0, ).messages(_joi.makeStrErrors.call(void 0, 'Phone')).contactPhone(),
})

 const existingOrgResolver = _hookForm.joiResolver.call(void 0, schema); exports.existingOrgResolver = existingOrgResolver
