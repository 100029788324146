"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/components/SignupStepHeader.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);









const iconSrcMap = {
  error: '/assets/images/welcome/x.svg',
  org: '/assets/images/welcome/signup-orgs-icon.svg',
  lock: '/assets/images/welcome/signup-access-icon.svg',
  email: '/assets/images/welcome/signup-email-icon.svg',
  doctor: '/assets/images/welcome/signup-doctor-icon.svg',
}

const SignupStepHeader = ({ title, subtitle, iconType }) => (
  _react2.default.createElement('div', { className: "signup-step--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    ,  iconType && (
      _react2.default.createElement('div', { className: "signup-step--header--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        , _react2.default.createElement('img', { src: iconSrcMap[iconType], alt: iconType, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
      )
    )
    ,  title && _react2.default.createElement('div', { className: "signup-step--header--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}},  title ) 
    ,  subtitle && _react2.default.createElement('div', { className: "signup-step--header--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}},  subtitle ) 
  )
)

exports. default = SignupStepHeader
