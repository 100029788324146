"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _HoverTooltip = require('web/components/Tooltip/HoverTooltip'); var _HoverTooltip2 = _interopRequireDefault(_HoverTooltip);
var _ClickTooltip = require('web/components/Tooltip/ClickTooltip'); var _ClickTooltip2 = _interopRequireDefault(_ClickTooltip);
var _ControlledTooltip = require('web/components/Tooltip/ControlledTooltip'); var _ControlledTooltip2 = _interopRequireDefault(_ControlledTooltip);







var _TooltipElements = require('web/components/Tooltip/TooltipElements');

// TODO: Investigate. Seems to be unused.
Element.prototype.isInTooltip = function () {
  let node = this
  while (node !== document) { /* detect if click is on tooltip to prevent the two from interacting */
    if (node.classList.contains('blm-tooltip--container')) {
      return true
    }
    node = node.parentNode
  }
  return false
}












exports.TooltipItem = _TooltipElements.TooltipItem; exports.TooltipHint = _TooltipElements.TooltipHint; exports.TooltipInfo = _TooltipElements.TooltipInfo; exports.TooltipTitle = _TooltipElements.TooltipTitle; exports.TooltipContent = _TooltipElements.TooltipContent; exports.TooltipMenuItem = _TooltipElements.TooltipMenuItem; exports.ClickTooltip = _ClickTooltip2.default; exports.HoverTooltip = _HoverTooltip2.default; exports.ControlledTooltip = _ControlledTooltip2.default;
