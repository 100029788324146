"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ConversationScreen/EmptyConversation.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

const EmptyConversation = () => (
  _react2.default.createElement('div', { className: "right-side-body--empty--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 4}}
    , _react2.default.createElement('div', { className: "right-side-body--empty--main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 5}}
      , _react2.default.createElement('div', { className: "right-side-body--empty--main-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
        , _react2.default.createElement('img', {
          className: "right-side-body--empty--icon",
          src: "/assets/icons/inbox/inbox-empty.svg",
          alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
        )
        , _react2.default.createElement('h3', { className: "right-side-body--empty--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, "This is your inbox"   )
        , _react2.default.createElement('span', { className: "right-side-body--empty--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, "All your chats are listed on the left."       )
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, "Click on a chat to open it."      )
        )
      )
    )
    , _react2.default.createElement('div', { className: "right-side-body--empty--sub", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      , _react2.default.createElement('div', { className: "right-side-body--empty--sub-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        , _react2.default.createElement('span', { className: "right-side-body--empty--sub-hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Did you know you can filter chats by team?"        )
      )
    )
  )
)

exports. default = EmptyConversation
