"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/TextMessage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);









var _RichMessageWrapper = require('./RichMessageWrapper'); var _RichMessageWrapper2 = _interopRequireDefault(_RichMessageWrapper);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _ui = require('web/services/ui');
var _Linkify = require('shared/components/Linkify'); var _Linkify2 = _interopRequireDefault(_Linkify);

const NonLinkText = ({ children }) => _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, children)






const Link = ({ children, url, type }) => (
  _react2.default.createElement('a', { href: url, rel: "noreferrer", target: type === 'url' ? '_blank' : '_self', __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    ,  children 
  )
)























const TextMessage = ({ text, onRetry, ...baseMessageProps }) => {
  const { deleted, senderKey, currentUserKey, messageKey } = baseMessageProps
  const isFromOtherUser = senderKey !== currentUserKey

  const className = _classBuilder2.default.call(void 0, 
    "text-message", {
      "text-message--me": !isFromOtherUser,
      "text-message--them": isFromOtherUser,
    },
  )

  const msgText = deleted ? '' : text

  const handleCopy = _react.useCallback.call(void 0, () => _ui.copyToClipboard.call(void 0, msgText), [msgText])
  const handleRetry = _react.useCallback.call(void 0, 
    () => onRetry(messageKey, msgText),
    [messageKey, msgText, onRetry],
  )
  const msgProps = { ...baseMessageProps, onCopy: deleted ? null : handleCopy }

  return (
    _react2.default.createElement(_RichMessageWrapper2.default, { ...msgProps, onRetry: handleRetry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
      , _react2.default.createElement('div', { className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        ,  deleted
          ? _react2.default.createElement('div', { className: "message--deleted", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}, "This message was recalled"   )
          : _react2.default.createElement('div', { className: "text-message--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
              , _react2.default.createElement(_Linkify2.default, {
                useMemo: _react.useMemo,
                text: msgText,
                LinkComponent: Link,
                TextComponent: NonLinkText, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
              )
            )
        
      )
    )
  )
}

exports. default = TextMessage
