"use strict";Object.defineProperty(exports, "__esModule", {value: true});














var _helpers = require('shared/actions/helpers');
var _utils = require('shared/store/utils');

const setAvatarActions = _utils.createAsyncActions('SetAvatar')
const addEmailActions = _utils.createAsyncActions('AddEmail')
const addPhoneActions = _utils.createAsyncActions('AddPhone')
const removeEmailActions = _utils.createAsyncActions('RemoveEmail')
const setPrimaryEmailActions = _utils.createAsyncActions('SetPrimaryEmail')
const setPrimaryPhoneActions = _utils.createAsyncActions('SetPrimaryPhone')
const resendEmailConfirmationActions = _utils.createAsyncActions('ResendEmailConfirmation')
const resendPhoneConfirmationActions = _utils.createAsyncActions('ResendPhoneConfirmation')
const verifyPhoneActions = _utils.createAsyncActions('VerifyPhone')
const removePhoneActions = _utils.createAsyncActions('RemovePhone')
const updateUserInfoActions = _utils.createAsyncActions('UpdateUserInfo')

 const actionCreators = {
  setAvatarActions,
  addEmailActions,
  addPhoneActions,
  removeEmailActions,
  setPrimaryEmailActions,
  setPrimaryPhoneActions,
  resendEmailConfirmationActions,
  resendPhoneConfirmationActions,
  verifyPhoneActions,
  removePhoneActions,
  updateUserInfoActions,
}; exports.actionCreators = actionCreators

const setAvatar = (Ref, UserKey) =>
  _helpers.wsCommand('SetAvatar', setAvatarActions, { Ref }, { Ref, UserKey })

const addEmail = (Email) =>
  _helpers.wsCommand('AddEmail', addEmailActions, { Email }, null)

const addPhone = (PhoneNumber) =>
  _helpers.wsCommand('AddPhone', addPhoneActions, { PhoneNumber }, null)

const removeEmail = (Email) =>
  _helpers.wsCommand('RemoveEmail', removeEmailActions, { Email }, null)

const setPrimaryEmail = (Email) => _helpers.wsCommand(
  'SetPrimaryEmail',
  setPrimaryEmailActions,
  { Email },
  null,
)

const setPrimaryPhone = (PhoneNumber) => _helpers.wsCommand(
  'SetPrimaryPhone',
  setPrimaryPhoneActions,
  { PhoneNumber },
  null,
)

const resendEmailConfirmation = (Email) =>
  _helpers.wsCommand(
    'ResendEmailConfirmation',
    resendEmailConfirmationActions,
    { Email },
    null,
  )

const resendPhoneConfirmation = (PhoneNumber) =>
  _helpers.wsCommand(
    'ResendPhoneConfirmation',
    resendPhoneConfirmationActions,
    { PhoneNumber },
    null,
  )

const verifyPhone = (PhoneNumber, Code) =>
  _helpers.wsCommand(
    'VerifyPhone',
    verifyPhoneActions,
    { PhoneNumber, Code },
    null,
  )

const removePhone = (PhoneNumber) =>
  _helpers.wsCommand('RemovePhone', removePhoneActions, { PhoneNumber }, null)

const updateUserInfo = (req) =>
  _helpers.wsCommand('UpdateUserInfo', updateUserInfoActions, req, null)

exports. default = {
  setAvatar,
  addEmail,
  addPhone,
  removeEmail,
  setPrimaryEmail,
  setPrimaryPhone,
  resendEmailConfirmation,
  resendPhoneConfirmation,
  verifyPhone,
  removePhone,
  updateUserInfo,
}
