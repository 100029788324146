"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reselect = require('reselect');






var _entities = require('shared/transforms/entities');




var _user = require('shared/selectors/user');
var _org = require('shared/selectors/org');

const EMPTY_ORG_ENTITY_LIST = []

 const selectMyOrgEntitiesList = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  _user.selectMyActiveRelationshipsList,
  _org.selectOrgs,
  (me, myRels, allOrgs) => {
    if (!me || !myRels || !allOrgs) return EMPTY_ORG_ENTITY_LIST

    return myRels
      .map(_entities.mapRelationshipToEntity.call(void 0, allOrgs, _entities.makeRelationshipDescription, me))
  },
); exports.selectMyOrgEntitiesList = selectMyOrgEntitiesList

 const selectMySingleOrgKey = _reselect.createSelector.call(void 0, 
  _user.selectMyActiveRelationshipsList,
  myRels => myRels.length === 1
    ? myRels[0].OrgKey
    : null,
); exports.selectMySingleOrgKey = selectMySingleOrgKey
