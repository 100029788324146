"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Navigation/NavItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _NavSubItem = require('./NavSubItem'); var _NavSubItem2 = _interopRequireDefault(_NavSubItem);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const NavItem = ({
  label,
  isActive,
  gotoRoute,
  subroutes,
  iconClassName,
}) => {
  const [isAnyChildActive, warningCount] = _react.useMemo.call(void 0, 
    () => [
      subroutes && subroutes.some(s => s.isActive),
      subroutes && subroutes.reduce((acc, s) => acc + (s.warningCount || 0), 0),
    ] ,
    [subroutes],
  )
  const activityClassNames = {
    'active': isActive && !isAnyChildActive,
    'child-active': isAnyChildActive,
  }

  const routeWrapperClassName = _classBuilder2.default.call(void 0, 
    "settings--nav-item--wrapper",
    activityClassNames,
  )
  const subroutesWrapperClassName = _classBuilder2.default.call(void 0, 
    "settings--nav-subitems-wrapper",
    { 'open': isActive },
  )

  return (
    _react2.default.createElement('div', {
      role: "menuitem",
      'aria-selected': isActive,
      className: routeWrapperClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
    
      , _react2.default.createElement('div', { role: "button", onClick: gotoRoute, className: "settings--nav-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        , _react2.default.createElement('div', {
          className: _classBuilder2.default.call(void 0, 
            "settings--nav-item--icon",
            activityClassNames,
            iconClassName && `icon-${iconClassName}`,
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        )
        ,  label 
        ,  warningCount > 0 && (
          _react2.default.createElement('div', { className: "settings--nav-item--warning-icon", role: "suggestion", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
            ,  warningCount 
          )
        )
      )
      ,  subroutes &&
        _react2.default.createElement('div', { className: subroutesWrapperClassName, role: "group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
          ,  subroutes.map(s => (
            _react2.default.createElement(_NavSubItem2.default, {
              key: s.route,
              label: s.label,
              isActive: !!s.isActive,
              gotoRoute: s.gotoRoute,
              warningCount: s.warningCount, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
            )
          ))
        )
      
    )
  )
}

exports. default = NavItem
