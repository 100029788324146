"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _createStarExport(obj) { Object.keys(obj) .filter((key) => key !== "default" && key !== "__esModule") .forEach((key) => { if (exports.hasOwnProperty(key)) { return; } Object.defineProperty(exports, key, {enumerable: true, get: () => obj[key]}); }); } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }


var _fetch = require('./fetch');


var _alerts = require('./alerts'); _createStarExport(_alerts);

 class FetchCommands extends _fetch.Fetch {constructor(...args) { super(...args); FetchCommands.prototype.__init.call(this);FetchCommands.prototype.__init2.call(this);FetchCommands.prototype.__init3.call(this);FetchCommands.prototype.__init4.call(this);FetchCommands.prototype.__init5.call(this);FetchCommands.prototype.__init6.call(this);FetchCommands.prototype.__init7.call(this);FetchCommands.prototype.__init8.call(this);FetchCommands.prototype.__init9.call(this);FetchCommands.prototype.__init10.call(this);FetchCommands.prototype.__init11.call(this);FetchCommands.prototype.__init12.call(this);FetchCommands.prototype.__init13.call(this);FetchCommands.prototype.__init14.call(this);FetchCommands.prototype.__init15.call(this);FetchCommands.prototype.__init16.call(this);FetchCommands.prototype.__init17.call(this);FetchCommands.prototype.__init18.call(this);FetchCommands.prototype.__init19.call(this);FetchCommands.prototype.__init20.call(this);FetchCommands.prototype.__init21.call(this);FetchCommands.prototype.__init22.call(this);FetchCommands.prototype.__init23.call(this); }
  __init() {this.csrf = (signal) =>
    this.fetch('/csrf', 'GET', null, null, signal)}

  __init2() {this.login = (email, password, longlived = false, headerDispatch) =>
    this.fetch('/login', 'POST', { email, password, longlived }, headerDispatch)}

  __init3() {this.getFileLink = (key) => this.fetch(`/get_file_link?key=${key}`)}
  __init4() {this.pinLogin = (email, pin) => this.fetch('/quick_login', 'POST', { email, pin })}
  __init5() {this.logout = () => this.fetch('/logout', 'POST')}
  __init6() {this.checkResetToken = (token) => this.fetch('/check_token', 'POST', { token })}
  __init7() {this.setPassword = (password, token) =>
    this.fetch('/set_password', 'POST', { password, reset_token: token })}

  __init8() {this.sendPasswordResetEmail = (email) => this.fetch('/send_reset_email', 'POST', { email })}
  __init9() {this.checkWelcomeCredential = (cred, path) =>
    this.fetch('/check_creds', 'POST', { cred, path })}

  __init10() {this.checkPhone = (phone) => this.fetch('/check_phone', 'POST', { phone })}
  __init11() {this.checkWelcomeToken = (token) => this.fetch('/check_welcome_token', 'POST', { token })}
  __init12() {this.checkPassword = (password) => this.fetch('/check_password', 'POST', { password })}
  __init13() {this.confirmEmail = (token) => this.fetch('/confirm_email', 'POST', { token })}
  __init14() {this.unsubscribeEmailNotifs = (email, token) =>
    this.fetch('/unsubscribe_email_notifs', 'POST', { email: email, token: token })}

  __init15() {this.searchForOrgs = (q) =>
    this.fetch('/search_for_org', 'POST', { org_name: q })}

  __init16() {this.getQuickLoginUsers = () => this.fetch('/get_quick_login_users')}
  __init17() {this.removeQuickLoginUser = (userKey) => this.fetch(`/quick_login_users/${userKey}`, 'DELETE')}

  __init18() {this.log = (data) => this.fetch('/log', 'POST', data)}

  __init19() {this.submitSetup = (ps








) => this.fetch('/setup_welcome', 'POST', {
    Token: ps.token,
    First_name: ps.firstName,
    Last_name: ps.lastName,
    Email: ps.email,
    Phone: ps.phone,
    Password: ps.password,
    Orgs: JSON.stringify(ps.orgs),
    ...(ps.orgSurvey && {
      Organization_type: _optionalChain([ps, 'access', _3 => _3.orgSurvey, 'optionalAccess', _4 => _4.orgType]),
      Referrer: ps.orgSurvey.referrer,
      Feature: ps.orgSurvey.features,
    }),
    Titles: [],
  })}

  __init20() {this.joinVideoChat = (videoKey, userKey) =>
    this.fetch('/join_video_chat', 'POST', { videoKey, userKey })}

  __init21() {this.checkVersion = (version) =>
    this.fetch(`/version?version=${version}`)}

   __init22() {this.uploadFileWithCSRF = (csrf, upload, signal) =>
    this.fetch('/upload_file', 'POST', { upload, csrf }, null, signal)}

   __init23() {this.uploadAvatarWithCSRF = (csrf, upload, signal) =>
    this.fetch('/upload_avatar', 'POST', { upload, csrf }, null, signal)}
} exports.FetchCommands = FetchCommands;
