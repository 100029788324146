"use strict";Object.defineProperty(exports, "__esModule", {value: true});// https://developer.mozilla.org/en-US/docs/Web/API/WebSocket#Ready_state_constants
var WS_STATE; (function (WS_STATE) { const CONNECTING = 0; WS_STATE[WS_STATE["CONNECTING"] = CONNECTING] = "CONNECTING"; const OPEN = CONNECTING + 1; WS_STATE[WS_STATE["OPEN"] = OPEN] = "OPEN"; const CLOSING = OPEN + 1; WS_STATE[WS_STATE["CLOSING"] = CLOSING] = "CLOSING"; const CLOSED = CLOSING + 1; WS_STATE[WS_STATE["CLOSED"] = CLOSED] = "CLOSED"; })(WS_STATE || (exports.WS_STATE = WS_STATE = {}));

var ClientEvent; (function (ClientEvent) {
  const OPEN = 'open'; ClientEvent["OPEN"] = OPEN;
  const SESSION_EXPIRED = 'session-expired'; ClientEvent["SESSION_EXPIRED"] = SESSION_EXPIRED;
  const UNEXPECTED_CLOSED = 'unexpected-closed'; ClientEvent["UNEXPECTED_CLOSED"] = UNEXPECTED_CLOSED;
  const CLOSED = 'closed'; ClientEvent["CLOSED"] = CLOSED;
  const INBOUND = 'inbound'; ClientEvent["INBOUND"] = INBOUND;
  const AUTH_SUCCESS = 'authentication-success'; ClientEvent["AUTH_SUCCESS"] = AUTH_SUCCESS;
  const AUTH_ERROR = 'authentication-error'; ClientEvent["AUTH_ERROR"] = AUTH_ERROR;
})(ClientEvent || (exports.ClientEvent = ClientEvent = {}));

var ServiceCommand; (function (ServiceCommand) {
  const PING = 'PingActivity'; ServiceCommand["PING"] = PING;
  const AUTH_SUCCESS = 'AuthSuccess'; ServiceCommand["AUTH_SUCCESS"] = AUTH_SUCCESS;
  const AUTH_ERROR = 'AuthError'; ServiceCommand["AUTH_ERROR"] = AUTH_ERROR;
})(ServiceCommand || (exports.ServiceCommand = ServiceCommand = {}));
