"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/Modals/JoinCall.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);




var _videoRoom = require('web/selectors/videoRoom');







var _videoCall = require('web/selectors/videoCall');

var _videoCall3 = require('web/services/videoCall');
var _videoRoom3 = require('web/services/videoRoom'); var _videoRoom4 = _interopRequireDefault(_videoRoom3);
var _JoinCall = require('web/components/VideoRooms/Room/Modals/JoinCall'); var _JoinCall2 = _interopRequireDefault(_JoinCall);

class JoinCallModalContainer extends _react2.default.Component {constructor(...args) { super(...args); JoinCallModalContainer.prototype.__init.call(this);JoinCallModalContainer.prototype.__init2.call(this);JoinCallModalContainer.prototype.__init3.call(this);JoinCallModalContainer.prototype.__init4.call(this);JoinCallModalContainer.prototype.__init5.call(this);JoinCallModalContainer.prototype.__init6.call(this);JoinCallModalContainer.prototype.__init7.call(this);JoinCallModalContainer.prototype.__init8.call(this);JoinCallModalContainer.prototype.__init9.call(this);JoinCallModalContainer.prototype.__init10.call(this); }
  __init() {this.state = { stream: null }}
  __init2() {this.streamManager = null}
  __init3() {this.videoRoomSession = null}

  __init4() {this.updateStream = s => this.setState({ stream: s })}

  __init5() {this.componentDidUpdate = prevProps => {
    const { currentCamDeviceId, isCamOn } = this.props

    if (
      prevProps.currentCamDeviceId !== currentCamDeviceId ||
      prevProps.isCamOn !== isCamOn
    ) {
      this.streamManager.update({ isCamOn, camDeviceId: currentCamDeviceId })
        .then(this.updateStream)
        .catch(() => this.updateStream(null))
    }
  }}

  __init6() {this.componentDidMount = () => {
    const {
      currentCamDeviceId, isCamOn,
      roomKey: RoomKey,
    } = this.props

    this.streamManager = new (0, _videoCall3.StreamManager)({
      camDeviceId: currentCamDeviceId,
      useCam: true,
    })

    this.streamManager
      .update({ isCamOn })
      .then(this.updateStream)
      .catch(() => this.updateStream(null))

    const existingSession = _videoRoom4.default.findByRoomKey(RoomKey)
    if (existingSession) { return this.videoRoomSession = existingSession }

    this.videoRoomSession = new (0, _videoRoom4.default)(RoomKey)
    this.videoRoomSession.startSetup()
  }}

  __init7() {this.handleClose = () => {
    this.videoRoomSession.leave()
    this.props.onClose()
  }}

  __init8() {this.gotoMeeting = () => {
    this.props.onClose()
    this.props.gotoMeeting()
  }}

  __init9() {this.gotoSettings = () => {
    this.props.onClose()
    this.props.gotoSettings(this.props.openJoinCallModal)
  }}

  __init10() {this.componentWillUnmount = () => this.streamManager.stop()}

  render () {
    const { stream } = this.state
    const {
      isOpen, onRoomShare, onRoomLinkCopy,
      roomName, roomLink,
      isMicOn, isCamOn,
      isCamCycleAvailable,
      isBrowserCompatible,
      arePermsGranted,
    } = this.props

    return (
      _react2.default.createElement(_JoinCall2.default, {
        isOpen: isOpen,
        onClose: this.handleClose,
        roomName: roomName,
        roomLink: roomLink,
        onRoomShare: onRoomShare,
        onRoomLinkCopy: onRoomLinkCopy,
        isMicOn: isMicOn,
        isCamOn: isCamOn,
        isCamCycleAvailable: isCamCycleAvailable,
        onCycleCam: _actions2.default.cycleCam,
        onToggleMic: _actions2.default.toggleMic,
        onToggleCam: _actions2.default.toggleCam,
        stream: stream,
        gotoMeeting: this.gotoMeeting,
        gotoSettings: this.gotoSettings,
        isBrowserCompatible: isBrowserCompatible,
        arePermsGranted: arePermsGranted, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
      )
    )
  }
}

const mapState = state => ({
  roomKey: _videoRoom.selectCurrentVideoRoomKey.call(void 0, state),
  roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
  roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
  isCamOn: _videoCall.selectIsCamOn.call(void 0, state),
  isMicOn: _videoCall.selectIsMicOn.call(void 0, state),
  isCamCycleAvailable: _videoCall.selectIsCamCycleAvailable.call(void 0, state),
  currentCamDeviceId: _videoCall.selectCurrentCamDeviceId.call(void 0, state),
  isBrowserCompatible: _videoCall.selectIsBrowserCompatible.call(void 0, state),
  arePermsGranted: _videoCall.selectArePermsGranted.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(JoinCallModalContainer)
