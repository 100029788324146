"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');

var _types = require('web/screens/Signup/types');
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);

 const useStep = () => {
  const [currentStep, setCurrentStep] = _react.useState.call(void 0, _types.SetupSteps.PERSONAL_INFO)

  const goBack = _react.useCallback.call(void 0, 
    () => setCurrentStep(s => Math.max(s - 1, _types.SetupSteps.PERSONAL_INFO)),
    [],
  )

  _react.useEffect.call(void 0, 
    () => { _amplitude2.default.logEvent(`Signup step: ${_types.SetupSteps[currentStep]}`) },
    [currentStep],
  )

  return {
    currentStep,
    goBack,
    gotoStep: setCurrentStep,
  }
}; exports.useStep = useStep
