"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/List/MeetingsFilter.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Form = require('web/components/Form');
var _MenuPicker = require('web/components/VideoRooms/Room/MenuPicker');
var _DesktopMeetingsFilterTooltip = require('./DesktopMeetingsFilterTooltip'); var _DesktopMeetingsFilterTooltip2 = _interopRequireDefault(_DesktopMeetingsFilterTooltip);

 class MeetingsFilter extends _react2.default.Component {constructor(...args) { super(...args); MeetingsFilter.prototype.__init.call(this);MeetingsFilter.prototype.__init2.call(this);MeetingsFilter.prototype.__init3.call(this);MeetingsFilter.prototype.__init4.call(this);MeetingsFilter.prototype.__init5.call(this);MeetingsFilter.prototype.__init6.call(this);MeetingsFilter.prototype.__init7.call(this);MeetingsFilter.prototype.__init8.call(this); }
  __init() {this.state = {
    isMobileFilterMenuOpen: false,
    isDesktopFilterMenuOpen: false,
    filterToggleRef: null,
  }}

  __init2() {this.setFilterToggleRef = r => this.setState({ filterToggleRef: r })}

  __init3() {this.onFilterUpdate = (_, f) => this.props.onFilterUpdate(f)}
  __init4() {this.onSearchQueryUpdate = (_, q) => this.props.onSearchQueryUpdate(q)}

  __init5() {this.openMobileFilterMenu = () => this.setState({ isMobileFilterMenuOpen: true })}
  __init6() {this.closeMobileFilterMenu = () => this.setState({ isMobileFilterMenuOpen: false })}

  __init7() {this.openDesktopFilterMenu = () => this.setState({ isDesktopFilterMenuOpen: true })}
  __init8() {this.closeDesktopFilterMenu = () => this.setState({ isDesktopFilterMenuOpen: false })}

  render () {
    const {
      isMobileFilterMenuOpen,
      isDesktopFilterMenuOpen,
      filterToggleRef,
    } = this.state
    const {
      filterMenuOptions,
      currentFilter,
      searchQuery,
      onFilterUpdate,
      onSearchQueryUpdate,
    } = this.props

    const desktopFilterToggleClassName = _classBuilder2.default.call(void 0, 
      "blmh-mobile-hide meeting-rooms--list--filter-toggle-icon",
      { active: isDesktopFilterMenuOpen },
    )

    return (
      _react2.default.createElement('div', { className: "meeting-rooms--list--filter", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , _react2.default.createElement('div', { className: "meeting-rooms--list--filter-input-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          , _react2.default.createElement(_Form.TextInput, {
            value: searchQuery,
            onChange: e => onSearchQueryUpdate(e.target.value),
            placeholder: "Search for meetings..."  ,
            className: "meeting-rooms--list--filter-input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          )
          , _react2.default.createElement('div', { className: "meeting-rooms--list--filter-search-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
          , _react2.default.createElement('div', {
            onClick: this.openMobileFilterMenu,
            className: "blmh-desktop-hide meeting-rooms--list--filter-toggle-icon" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
          )
          , _react2.default.createElement('div', {
            ref: this.setFilterToggleRef,
            onClick: this.openDesktopFilterMenu,
            className: desktopFilterToggleClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          )
        )
        , _react2.default.createElement(_MenuPicker.MenuSelect, {
          title: "View...",
          inputId: "currentFilter",
          isOpen: isMobileFilterMenuOpen,
          onClose: this.closeMobileFilterMenu,
          options: filterMenuOptions,
          onChange: onFilterUpdate,
          value: currentFilter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
        )
        , _react2.default.createElement(_DesktopMeetingsFilterTooltip2.default, {
          isOpen: isDesktopFilterMenuOpen,
          onClose: this.closeDesktopFilterMenu,
          domTarget: filterToggleRef,
          inputId: "currentFilter",
          onChange: this.onFilterUpdate,
          options: filterMenuOptions,
          activeOption: currentFilter, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        )
      )
    )
  }
} exports.default = MeetingsFilter;
