"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/MessageSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);


var _components = require('web/screens/Directory/TeamPage/ScheduleTab/components');
var _components3 = require('web/screens/Directory/TeamPage/components');
var _components5 = require('web/screens/Directory/components');
var _EditMessageModal = require('./EditMessageModal'); var _EditMessageModal2 = _interopRequireDefault(_EditMessageModal);









const MessageSection = ({
  label,
  replyMessage,
  isDisabled,
  onChange,
}) => {
  const {
    isOpen: isEditMessageModalOpen,
    onOpen: onOpenEditMessageModal,
    onClose: onCloseEditMessageModal,
  } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_components3.GenericSection, { isDisabled: isDisabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      , _react2.default.createElement(_components.ScheduleInfoItem, { title: "Message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        ,  replyMessage
          ? _react2.default.createElement('span', { className: "team-page--schedule--msg-preview", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}},  replyMessage )
          : _react2.default.createElement('span', { className: "team-page--schedule--empty-value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "No message set"  )
        
        , _react2.default.createElement(_components5.InfoBtn, {
          label: label,
          onClick: onOpenEditMessageModal,
          iconSrc: "/assets/icons/icon-edit-purple.svg",
          className: "team-page--schedule--edit-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        )
      )
      , _react2.default.createElement(_EditMessageModal2.default, {
        isOpen: isEditMessageModalOpen,
        onClose: onCloseEditMessageModal,
        defaultMessage: replyMessage,
        onSubmit: onChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )
    )
  )
}

exports. default = MessageSection
