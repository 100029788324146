"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/PlansReceipt.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _cost = require('shared/services/cost');

var _Header = require('./Header'); var _Header2 = _interopRequireDefault(_Header);
var _Controls = require('./Controls'); var _Controls2 = _interopRequireDefault(_Controls);










const resolveCardIcon = (type) => {
  const iconNames = {
    visa: 'visa',
    mastercard: 'mastercard',
    generic: 'generic-card',
  }

  const fileName = iconNames[type.toLowerCase()] || iconNames.generic
  return `/assets/icons/credit-card/${fileName}.svg`
}

const PlansReceipt = ({
  planPreviews,
  savedCardData,
  onBack,
  onNext,
  discount,
  isNextLoading,
}) => {
  const discountCost = discount 
  const subtotal = planPreviews.reduce((acc, p) => acc + p.totalCost, 0) 
  const finalCost = Math.max(subtotal - discountCost, 0) 

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Header2.default, {
        title: "Confirm plan" ,
        subtitle: "Your existing plan will be updated."     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
      )
      , _react2.default.createElement('div', { className: "plan-modal--billing", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        ,  planPreviews.map((p, i) => (
          _react2.default.createElement('div', { key: i, className: "plan-modal--billing--row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , _react2.default.createElement('img', { className: "plan-modal--billing--icon", src: p.iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}} )
            , _react2.default.createElement('div', { className: "plan-modal--billing--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
              , _react2.default.createElement('span', { className: "plan-modal--billing--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}},  p.name )
              , _react2.default.createElement('span', { className: "plan-modal--billing--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}},  p.desc )
            )
            , _react2.default.createElement('span', { className: "plan-modal--billing--price", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
              ,  _cost.formatDisplayCost.call(void 0, p.totalCost) 
            )
          )
        ))
        , discount > 0 ? (_react2.default.createElement('div', { className: "plan-modal--billing--row plan-modal--billing--row--summary" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
          , _react2.default.createElement('span', { className: "plan-modal--billing--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "Discount")
          , _react2.default.createElement('span', { className: "plan-modal--billing--price plan-modal--billing--price--discount" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "-"
            ,  _cost.formatDisplayCost.call(void 0, discountCost) 
          )
        )) : null
        , _react2.default.createElement('div', { className: "plan-modal--billing--row plan-modal--billing--row--summary" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
          , _react2.default.createElement('span', { className: "plan-modal--billing--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "New monthly cost"  )
          , _react2.default.createElement('span', { className: "plan-modal--billing--price plan-modal--billing--price--summary" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            ,  _cost.formatDisplayCost.call(void 0, finalCost) 
          )
        )
        ,  savedCardData && (
          _react2.default.createElement('div', { className: "plan-modal--billing--row plan-modal--billing--row--summary" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
            , _react2.default.createElement('span', { className: "plan-modal--billing--name plan-modal--billing--card-preview" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}, "Paid with"

              , _react2.default.createElement('img', {
                src: resolveCardIcon(savedCardData.Type),
                alt: "Credit Card" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
              )
              , _react2.default.createElement('span', { className: "plan-modal--billing--card-number", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
                ,  savedCardData.Last4 
              )
            )
          )
        )
      )
      , _react2.default.createElement(_Controls2.default, {
        secondaryLabel: "Previous",
        primaryLabel: "Confirm",
        onSecondaryClick: onBack,
        onPrimaryClick: onNext,
        isPrimaryLoading: isNextLoading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      )
    )
  )
}

exports. default = PlansReceipt
