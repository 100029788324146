"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/SetupGroups/Video.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _VideoFeed = require('web/components/VideoCall/VideoFeed'); var _VideoFeed2 = _interopRequireDefault(_VideoFeed);
var _videoCall = require('web/services/videoCall');

 class VideoSetupGroup extends _react2.default.Component {constructor(...args) { super(...args); VideoSetupGroup.prototype.__init.call(this); }
  __init() {this.handleCamChange = e =>
    this.props.setCurrentCamDeviceId(e.target.value)}

  render () {
    const {
      videoStream,
      videoInDevices,
      currentCamDeviceId,
    } = this.props

    const areAnyDevicesPresent = videoInDevices && videoInDevices.length

    return (
      _react2.default.createElement('div', { className: "meeting--setup--group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        , _react2.default.createElement('div', { className: "meeting--setup--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          , _react2.default.createElement('h3', { className: "meeting--setup--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "Camera")
          , _react2.default.createElement('div', { className: "meeting--setup--section-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
            , _react2.default.createElement('select', {
              value: currentCamDeviceId,
              onChange: this.handleCamChange,
              className: "meeting--setup--select",
              disabled: !areAnyDevicesPresent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
            
              ,  areAnyDevicesPresent
                ? videoInDevices.map(({ deviceId, label }, i) => (
                    _react2.default.createElement('option', {
                      key: deviceId,
                      value: deviceId, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
                    
                      ,  label || `Camera #${i + 1}` 
                    )
                ))
                : _react2.default.createElement('option', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}, "N/A")
              
            )
          )
          , _react2.default.createElement('div', { className: "meeting--setup--feed-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
            , _react2.default.createElement(_VideoFeed2.default, {
              shouldMirror: _videoCall.shouldMirrorCamFeed.call(void 0, videoStream),
              stream: videoStream,
              className: "meeting--setup--feed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
            )
          )
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    currentCamDeviceId: _proptypes2.default.string.isRequired,
    setCurrentCamDeviceId: _proptypes2.default.func.isRequired,
    videoInDevices: _proptypes2.default.array.isRequired,
    videoStream: _proptypes2.default.any,
  }}
} VideoSetupGroup.__initStatic(); exports.default = VideoSetupGroup;
