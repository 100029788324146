"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }var _BloomClient = require('./BloomClient'); var _BloomClient2 = _interopRequireDefault(_BloomClient);

const host = SOCKET_SERVER_HOST || 'api.bloomtext.com'
const secure = _nullishCoalesce(SOCKET_SERVER_SECURE, () => ( true))
const port = _nullishCoalesce(SOCKET_SERVER_PORT, () => ( (secure ? 443 : 80)))
const version = VERSION || 'unknown_client_version'
const bundle = BUNDLE || 'unknown_client_bundle'

const client = new (0, _BloomClient2.default)({
  connection: { host, secure, port, version, bundle },
  config: {
    DEFAULT_MSG_TIMEOUT_MS: 15000,
    CMD_RETRY_CONST_DELAY_MS: 2000,
    MAX_CMD_RETRIES: 1,
    PING_INTERVAL_MS: 7000,
    PONG_TIMEOUT_MS: 2000,
  },
})

exports. default = client
 const mockClient = () => null; exports.mockClient = mockClient
