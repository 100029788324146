"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _types = require('./types');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useBus = require('shared/hooks/useBus'); var _useBus2 = _interopRequireDefault(_useBus);









var _utils = require('./utils');








 const useInbox = ({
  myUserKey,
  selectedTeamKey,
  myInboxableTeamKeys,
  onError,
}) => {
  const [activeFilter, setActiveFilter] = _react.useState(_types.BlobjectFilter.ACTIVE)
  const [timestampPointer, setTimestampPointer] = _react.useState.call(void 0, new Date())

  const [blobjects, setBlobjects] = _react.useState.call(void 0, _utils.EMPTY_BLOBJECT_MAP)
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [hasDrained, setHasDrained] = _react.useState.call(void 0, false)

  const blobjectKeysList = _react.useMemo.call(void 0, 
    () => Object.values(blobjects)
      .sort(_utils.compareBlobjectDates)
      .map(_utils.keyExtractor),
    [blobjects],
  )

  const onLoad = _react.useCallback.call(void 0, 
    async ({ TeamKey, filter, before = new Date(), isSilent }) => {
      if (!isSilent) setIsLoading(true)

      const Blobjects = await _actions2.default.getInbox({
        TeamKey,
        Completed: filter === _types.BlobjectFilter.COMPLETED,
        Before: before.toISOString(),
        Limit: _utils.LOAD_LIMIT,
      })
        .then(r => r.Blobjects || _utils.EMPTY_BLOBJECT_MAP)
        .catch(() => {
          onError()
          return _utils.EMPTY_BLOBJECT_MAP
        })

      if (isSilent) setBlobjects(Blobjects)
      else setBlobjects(ks => ({ ...ks, ...Blobjects }))

      const blobjectsList = Object.values(Blobjects)
      setTimestampPointer(_utils.getEarliestBlobjectTime.call(void 0, blobjectsList, before))

      setHasDrained(blobjectsList.length < _utils.LOAD_LIMIT)
      setIsLoading(false)
    },
    [onError],
  )

  const onLoadMore = _react.useCallback.call(void 0, 
    () => onLoad({
      TeamKey: selectedTeamKey,
      filter: activeFilter,
      before: timestampPointer,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [activeFilter, selectedTeamKey, timestampPointer],
  )

  const handleUpdate = _react.useCallback.call(void 0, 
    action => {
      const [relevantBlobjectsMap, rejectedBlobjectsList] =
        _utils.filterRelevantBlobjects.call(void 0, {
          action,
          activeFilter,
          selectedTeamKey,
          myInboxableTeamKeys,
          myUserKey,
        })
      setBlobjects(ks => {
        const newMap = { ...ks, ...relevantBlobjectsMap }
        rejectedBlobjectsList.forEach(b => delete newMap[b.Key])
        return newMap
      })
    },
    [selectedTeamKey, activeFilter, myInboxableTeamKeys, myUserKey],
  )

  const onReset = _react.useCallback.call(void 0, 
    (isSilent) => {
      setHasDrained(false)
      if (!isSilent) setBlobjects(_utils.EMPTY_BLOBJECT_MAP)
      return onLoad({ TeamKey: selectedTeamKey, filter: activeFilter, isSilent })
    },
    [onLoad, selectedTeamKey, activeFilter],
  )
  const onSilentReset = _react.useCallback.call(void 0, () => onReset(true), [onReset])

  _useBus2.default.call(void 0, _utils.resetActions, onSilentReset)
  _useBus2.default.call(void 0, _utils.updateActions, handleUpdate)
  _react.useLayoutEffect.call(void 0, () => { onReset() }, [onReset])

  return {
    activeFilter,
    setActiveFilter,
    isLoading,
    blobjectKeysList,
    onLoadMore,
    onReset,
    onSilentReset,
    hasDrained,
    setSelectedTeamKey: _actions2.default.setSelectedTeam,
  }
}; exports.useInbox = useInbox
