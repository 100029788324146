"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/RichMessageWrapper/DeliveryStatus.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _message = require('shared/enum/message');
var _Avatar = require('web/components/Avatar');
var _Tooltip = require('web/components/Tooltip');









const DeliveryStatus = ({
  status = _message.deliveryStatus.DELIVERED,
  shouldShowDeliveredIndicator,
  seenBySubscribers,
  onRetry,
  isLastMessage,
}) => {
  const isSent = status === _message.deliveryStatus.SENT
  const isDelivered = status === _message.deliveryStatus.DELIVERED
  const hasFailed = status === _message.deliveryStatus.FAILED

  const hasBeenSeen = isDelivered && !!seenBySubscribers.length

  if (!hasBeenSeen && !shouldShowDeliveredIndicator) {
    return isLastMessage
      ? _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
        "rich-message--delivery-status",
        "rich-message--delivery-status--last",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
      : null
  }

  const seenByTooltipContent = (
    _react2.default.createElement(_Tooltip.TooltipContent, { className: "delivery-status-tooltip--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      , _react2.default.createElement('span', { className: "delivery-status-tooltip--hint-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}, "Seen by" )
      ,  seenBySubscribers.map(s => (
        _react2.default.createElement('span', {
          key: s.SubscriberKey,
          className: "delivery-status-tooltip--hint-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        
          ,  s.Name 
        )
      ))
    )
  )

  const sentByTooltipContent = (
    _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      , _react2.default.createElement('span', { className: "delivery-status-tooltip--hint-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "Sent by "
          , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "You")
      )
    )
  )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
      "rich-message--delivery-status",
      isLastMessage && "rich-message--delivery-status--last",
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      ,  isSent && _react2.default.createElement('div', { className: "rich-message--delivery-status--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} ) 
      ,  shouldShowDeliveredIndicator &&
        isDelivered &&
        !hasBeenSeen && (
          _react2.default.createElement(_Tooltip.HoverTooltip, {
            place: "top",
            type: "dark",
            content: sentByTooltipContent,
            className: "delivery-status-tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
          
             , _react2.default.createElement('div', { className: "rich-message--delivery-status--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}} )
          )
      )
      ,  hasFailed && (
        _react2.default.createElement('div', { className: "rich-message--delivery-status--error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
          , _react2.default.createElement('img', { src: "/assets/icons/alert-orange.svg", alt: "!", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}, "Could not be sent ·"    )
          , _react2.default.createElement('a', { role: "button", onClick: onRetry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, "Retry")
        )
      )
      ,  hasBeenSeen && (
        _react2.default.createElement(_Tooltip.HoverTooltip, {
          place: "top",
          type: "dark",
          content: seenByTooltipContent,
          className: "delivery-status-tooltip delivery-status-tooltip--seen-by" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
        
          , _react2.default.createElement('div', { className: "rich-message--delivery-status--seen-by", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
            ,  seenBySubscribers.map(s => (
              _react2.default.createElement(_Avatar.UserAvatar, {
                noOnlineIndicator: true,
                key: s.SubscriberKey,
                userKey: s.SubscriberKey ,
                borderColor: "#FBFCFD",
                size: 20, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
              )
            ))
          )
        )
      )
    )
  )
}

exports. default = DeliveryStatus
