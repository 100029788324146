"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/List/RoomPreview.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Avatar = require('web/components/VideoRooms/Avatar'); var _Avatar2 = _interopRequireDefault(_Avatar);

exports. default = ({
  roomKey,
  roomName, onPick,
  isActive, activeParticipantsCount,
  isArchived, onToggleArchivation,
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, 
    'meeting-rooms--list-room',
    { active: isActive },
  )

  const activeParticipantsLabel = activeParticipantsCount > 0
    ? activeParticipantsCount === 1
      ? '1 participant on call'
      : `${activeParticipantsCount} participants on call`
    : 'No current call'

  return (
    _react2.default.createElement('div', { onClick: onPick, className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
      , _react2.default.createElement(_Avatar2.default, {
        roomKey: roomKey,
        className: "meeting-rooms--list-room--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      )
      , _react2.default.createElement('div', { className: "meeting-rooms--list-room--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , _react2.default.createElement('span', { className: "meeting-rooms--list-room--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, roomName)
        , _react2.default.createElement('span', { className: "meeting-rooms--list-room--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
          ,  activeParticipantsLabel 
        )
      )
      , _react2.default.createElement('div', { className: 
        _classBuilder2.default.call(void 0, 
          'meeting-rooms--list-room--action',
          isArchived ? 'sticky' : 'mobile-hide',
        ),
        onClick: e => { e.stopPropagation(); onToggleArchivation() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      
        , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}, "check_circle")
        , _react2.default.createElement('span', { className: "meeting-rooms--list-room--action-description", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}},  isArchived ? "Unarchive" : "Archive" )
      )
    )
  )
}
