"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/ConversationBox/hooks/scroll/useStickyBottom.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');
var _ui = require('web/services/ui');



const BOTTOM_STICKY_AREA = '-20%'
const NEW_MSGS_MARKER_OFFSET_PX = 100











const useStickyBottom = ({
  maxMessageSequence, isLatestMessageFromMe, cachedLastSeenMessageKey,
  isCurrentUserActive, onMarkConversationVisible,
  scrollToBottom, getContainerDOM,
}) => {
  const [isAtBottom, setIsAtBottom] = _react.useState.call(void 0, false)
  const onBottomEnter = _react.useCallback.call(void 0, 
    () => {
      onMarkConversationVisible(true)
      setIsAtBottom(true)
    },
    [onMarkConversationVisible],
  )
  const onBottomLeave = _react.useCallback.call(void 0, 
    () => {
      onMarkConversationVisible(false)
      setIsAtBottom(false)
    },
    [onMarkConversationVisible],
  )

  const { lockScroll, unlockScroll } =
    _ui.useScrollLockOnContentChange.call(void 0, getContainerDOM)

  _react.useEffect.call(void 0, 
    () => {
      if (!maxMessageSequence || !isCurrentUserActive) return

      if (isAtBottom) {
        scrollToBottom({ shouldAnimate: !isLatestMessageFromMe })
        return
      }
      if (isLatestMessageFromMe) {
        scrollToBottom({ shouldAnimate: true })
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [maxMessageSequence, isLatestMessageFromMe, scrollToBottom],
  )

  const scrollNewMessagesMarkerInView = _react.useCallback.call(void 0, 
    () => {
      const containerDOM = getContainerDOM()
      if (!containerDOM) return
      containerDOM.scrollBy({ top: NEW_MSGS_MARKER_OFFSET_PX })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  )

  const lastSeenActiveMsgKey = _react.useRef.call(void 0, cachedLastSeenMessageKey)
  _react.useEffect.call(void 0, () => {
    if (!isCurrentUserActive) {
      lockScroll()
      lastSeenActiveMsgKey.current = cachedLastSeenMessageKey
    } else {
      unlockScroll()

      if (lastSeenActiveMsgKey.current !== cachedLastSeenMessageKey) {
        scrollNewMessagesMarkerInView()
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCurrentUserActive, lockScroll, unlockScroll])

  const renderStickyBottomWaypoint = (isVisible) => isVisible && isCurrentUserActive
    ? (
        _react2.default.createElement(_reactwaypoint.Waypoint, {
          bottomOffset: BOTTOM_STICKY_AREA,
          onEnter: onBottomEnter,
          onLeave: onBottomLeave, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
        )
      )
    : null

  return {
    renderStickyBottomWaypoint,
    isAtBottom,
  }
}

exports. default = useStickyBottom
