"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Pagination/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _PageNumber = require('./PageNumber'); var _PageNumber2 = _interopRequireDefault(_PageNumber);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const template = Array(5).fill(0)
const resolvePages = (minPage, currentPage, maxPage) => {
  if (currentPage - minPage < 5) {
    return template.reduce((acc, _, i) => {
      const x = i + minPage + 1
      if (x < maxPage) acc.push(x)
      return acc
    }, [])
  }

  if (maxPage - currentPage < 5) {
    return template.reduce((acc, _, i) => {
      const x = maxPage - 5 + i
      if (x > minPage) acc.push(x)
      return acc
    }, [])
  }

  return template.map((_, i) => currentPage - 2 + i)
}









const Pagination = ({
  minPage = 0,
  maxPage,
  currentPage,
  gotoPage,
  className,
}) => {
  const pagesList = resolvePages(minPage, currentPage, maxPage)
  const pagesListHead = [minPage].concat(pagesList)
  const pagesListTail = minPage < maxPage ? [maxPage] : []
  const fullPagesList = pagesListHead.concat(pagesListTail)

  return (
    _react2.default.createElement('div', {
      role: "navigation",
      'aria-label': "Pagination Navigation" ,
      className: _classBuilder2.default.call(void 0, "blm-pagination", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
    
      , _react2.default.createElement('button', {
        'aria-label': "Go to previous page"   ,
        className: "blm-pagination--btn",
        onClick: () => gotoPage(Math.max(currentPage - 1, minPage)), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      , "<"

      )
      ,  fullPagesList.map((n, i) => (
        _react2.default.createElement(_PageNumber2.default, {
          key: i,
          number: n,
          currentPage: currentPage,
          onClick: () => gotoPage(n),
          className: "blm-pagination--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        )
      ))
      , _react2.default.createElement('button', {
        'aria-label': "Go to next page"   ,
        className: "blm-pagination--btn",
        onClick: () => gotoPage(Math.min(currentPage + 1, maxPage)), __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      , ">"

      )
    )
  )
}

exports. default = _react2.default.memo(Pagination)
