"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperEditOrganizationScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _store = require('web/store');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

 class SuperEditOrganizationScreen extends _react.Component {
  constructor (props) {
    super(props);SuperEditOrganizationScreen.prototype.__init.call(this);SuperEditOrganizationScreen.prototype.__init2.call(this);SuperEditOrganizationScreen.prototype.__init3.call(this);SuperEditOrganizationScreen.prototype.__init4.call(this);SuperEditOrganizationScreen.prototype.__init5.call(this);SuperEditOrganizationScreen.prototype.__init6.call(this);SuperEditOrganizationScreen.prototype.__init7.call(this);
    this.state = {
      deleteModalOpen: false,
    }
  }

  componentDidMount () {
    Promise.all([
      _client2.default.send('SuperOrgProfile', { OrgKey: this.props.match.params.orgKey }),
      _client2.default.send('SuperListFeatures', {}),
    ]).then((results) => {
      const org = results[0].Org

      this.setState({
        orgKey: org.OrgKey,
        name: org.Name,
        verified: org.Verified,
        enabledFeatures: org.EnabledFeatures,
        features: results[1],
      })
    }).catch(log.error)
  }

  __init() {this.updateName = (evt) => this.setState({ name: evt.target.value })}
  __init2() {this.updateVerified = (evt) => this.setState({ verified: evt.target.checked })}
  __init3() {this.showDeleteModal = (evt) => this.setState({ deleteModalOpen: true })}
  __init4() {this.hideDeleteModal = (evt) => this.setState({ deleteModalOpen: false })}

  __init5() {this.handleDelete = (evt) => {
    _client2.default.send('SUPER_RemoveOrg', { OrgKey: this.props.match.params.orgKey })
      .then(() => {
        _store.history.replace(`/super/orgs`)
      }).catch(err => {
        log.error(err)
        this.hideDeleteModal()
      })
  }}

  __init6() {this.featureChanged = (evt) => {
    if (evt.target.checked) {
      if (this.state.enabledFeatures.indexOf(evt.target.id) === -1) {
        this.setState({ enabledFeatures: [...this.state.enabledFeatures, evt.target.id] })
      }
    } else {
      const index = this.state.enabledFeatures.indexOf(evt.target.id)
      if (index > -1) {
        // clone features (cause react) array and remove item if it exists
        const enabledFeatures = this.state.enabledFeatures.slice(0)
        enabledFeatures.splice(index, 1)
        this.setState({ enabledFeatures: enabledFeatures })
      }
    }
  }}

  __init7() {this.submit = (evt) => {
    evt.preventDefault()
    _client2.default.send('SuperUpdateOrgProfile', {
      OrgKey: this.state.orgKey,
      Name: this.state.name,
      Verified: this.state.verified,
      EnabledFeatures: this.state.enabledFeatures,
    }).then(() => {
      _store.history.replace(`/super/orgs/${this.state.orgKey}`)
    }).catch(log.error)
  }}

  render () {
    if (this.state.name) {
      const features = this.state.features.map((feature) => {
        const enabled = this.state.enabledFeatures.some((enabled) => enabled === feature)
        return (
          _react2.default.createElement('div', { key: feature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
            , _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
              , _react2.default.createElement('input', { id: feature, type: "checkbox", checked: enabled, onChange: this.featureChanged, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} ), " " , feature
            )
          )
        )
      })

      const deleteModal = (
        _react2.default.createElement(_Modal2.default, {
          isOpen: this.state.deleteModalOpen,
          onClose: this.hideDeleteModal,
          title: "Delete Organization" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
        
          , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}, "Are you sure you want to mark the organization \""
                     , this.state.name, "\" as deleted?"  , ' '
          )
          , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}, "This will remove all members from this organization, and delete any patients associated with this organization."

          )
          , _react2.default.createElement(_Modal.ModalControls, {
            secondaryBtnLabel: "Cancel",
            onSecondaryBtnClick: this.hideDeleteModal,
            primaryBtnTheme: "danger",
            primaryBtnLabel: "Delete",
            onPrimaryBtnClick: this.handleDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          )
        )
      )

      return (
        _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          , deleteModal
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}, "Properties")
          , _react2.default.createElement('dl', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}, "OrgKey")
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}, this.state.orgKey))
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}, "Name")
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}, _react2.default.createElement('input', { id: "name", type: "text", value: this.state.name, onChange: this.updateName, style: { width: '300px' }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}))
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}, "Verified")
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, _react2.default.createElement('input', { id: "verified", type: "checkbox", checked: this.state.verified, onChange: this.updateVerified, __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}} ))
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}, "Features")
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}, features)
          )
          , _react2.default.createElement('a', { className: "blmh-button secondary" , href: "/#/super/orgs/" + this.state.orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}, "Cancel"), " "
          , _react2.default.createElement('button', { className: "blmh-button", onClick: this.submit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}, "Update"), " "
          , _react2.default.createElement('button', { className: "blmh-button red" , onClick: this.showDeleteModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}, "Delete")
        )
      )
    } else {
      return null
    }
  }
} exports.default = SuperEditOrganizationScreen;
