"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Avatar/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _GenericSection = require('../GenericSection'); var _GenericSection2 = _interopRequireDefault(_GenericSection);
var _Avatar = require('web/components/Avatar');
var _Form = require('web/components/Form');






const AvatarSection = ({
  myUserKey,
  onOpenAvatarPickerModal,
}) => (
  _react2.default.createElement(_GenericSection2.default, { classNameSuffix: "avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    , _react2.default.createElement('div', { className: "settings--profile--section settings--profile--section-avatar" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , _react2.default.createElement('span', { className: "settings--profile--section-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}, "Profile Avatar"

      )
      , _react2.default.createElement('div', { className: "settings--profile--section-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        , _react2.default.createElement(_Avatar.UserAvatar, {
          noOnlineIndicator: true,
          userKey: myUserKey,
          className: "settings--profile--avatar",
          size: 80, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        )
        , _react2.default.createElement(_Form.Button, {
          isMinimalistic: true,
          onClick: onOpenAvatarPickerModal,
          className: "settings--link settings--profile--set-avatar-link" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , "Change profile avatar"

        )
      )
    )
  )
)

exports. default = AvatarSection
