"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Desktop/SessionsList.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _helpers = require('web/components/VideoRooms/Room/History/helpers');
var _utils = require('shared/utils');

 class DesktopHistoryList extends _react2.default.Component {
  __init() {this.timespanOptions = [
    { value: "month", label: "Last 30 days" },
    { value: "quarter", label: "Last 3 months" },
    { value: "year", label: "Last 12 months" },
    { value: "ever", label: "All time" },
  ]}

  constructor (props) {
    super(props);DesktopHistoryList.prototype.__init.call(this);DesktopHistoryList.prototype.__init2.call(this);DesktopHistoryList.prototype.__init3.call(this);DesktopHistoryList.prototype.__init4.call(this);DesktopHistoryList.prototype.__init5.call(this);DesktopHistoryList.prototype.__init6.call(this);DesktopHistoryList.prototype.__init7.call(this);DesktopHistoryList.prototype.__init8.call(this);DesktopHistoryList.prototype.__init9.call(this);DesktopHistoryList.prototype.__init10.call(this);DesktopHistoryList.prototype.__init11.call(this);DesktopHistoryList.prototype.__init12.call(this);
    this.sessionsPerPage = 5
    this.state = {
      currentTimespanOption: this.timespanOptions[0].value,
      currentPage: 0,
      totalSessionsCount: 0,
      currentSessions: [],
      isLoading: false,
    }
  }

  __init2() {this.loadSessions = async () => {
    const { roomKey: RoomKey, loadSessionsList } = this.props
    const { currentPage, currentTimespanOption } = this.state

    const timespanLimits = {
      'month': _moment2.default.call(void 0, ).subtract(30, 'days').format(),
      'quarter': _moment2.default.call(void 0, ).subtract(3, 'months').format(),
      'year': _moment2.default.call(void 0, ).subtract(12, 'months').format(),
    }

    const StartedAfter = timespanLimits[currentTimespanOption] || null

    this.setState({ isLoading: true })
    loadSessionsList({
      RoomKey,
      Skip: currentPage * this.sessionsPerPage,
      Take: this.sessionsPerPage,
      StartedAfter,
    }).then(({ RoomSessions, RoomSessionsCount }) =>
      this.setState({
        currentSessions: RoomSessions,
        totalSessionsCount: RoomSessionsCount,
        isLoading: false,
      }),
    )
  }}

  __init3() {this.componentDidMount = () => {
    this.loadSessions()
  }}

  __init4() {this.handleTimespanChange = e => this.setState({
    currentTimespanOption: e.target.value,
  }, this.loadSessions)}

  __init5() {this.gotoPrevPage = () => this.gotoPage(this.state.currentPage - 1)}
  __init6() {this.gotoNextPage = () => this.gotoPage(this.state.currentPage + 1)}

  __init7() {this.gotoPage = idx => {
    const pagesCount = this.calcPagesCount()

    if (idx < 0 || idx >= pagesCount) return
    this.setState({ currentPage: idx }, this.loadSessions)
  }}

  __init8() {this.calcPagesCount = () => Math.ceil(this.state.totalSessionsCount / this.sessionsPerPage)}

  __init9() {this.padDuration = x => x.toString().padStart(2, '0')}

  __init10() {this.buildHeaderColClassName = x => _classBuilder2.default.call(void 0, 'meeting--table-col', x)}

  __init11() {this.renderTimespanPicker = () => (
    _react2.default.createElement('select', {
      value: this.state.currentTimespanOption,
      onChange: this.handleTimespanChange,
      className: "meeting--select meeting-room--history--timespan-select" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
    
      , 
        this.timespanOptions.map(({ value, label }) => (
          _react2.default.createElement('option', { key: value, value: value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
            ,  label 
          )
        ))
      
    )
  )}

  __init12() {this.renderEmptySessionListWarning = () => (
    _react2.default.createElement('div', { className: "meeting-room--history--header meeting-room--history--header--empty" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
      ,  this.renderTimespanPicker() 
      , _react2.default.createElement('h2', { className: "meeting-room--history--empty-warning", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}, "No sessions" )
    )
  )}

  render () {
    const {
      currentPage,
      isLoading,
      currentSessions,
      totalSessionsCount,
    } = this.state

    if (totalSessionsCount === 0 && !isLoading) { return this.renderEmptySessionListWarning() }

    const { onSessionPick } = this.props
    const pagesCount = this.calcPagesCount()
    const listedPagesCount = Math.min(pagesCount, 3)

    const pageMinIdx = Math.max(1, currentPage * this.sessionsPerPage)
    const pageMaxIdx = Math.min(
      currentPage * this.sessionsPerPage + this.sessionsPerPage,
      totalSessionsCount,
    )

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "meeting-room--history--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 125}}
          ,  this.renderTimespanPicker() 
        )
        ,  isLoading
          ? _react2.default.createElement('div', { className: "meeting--table meeting-room--history--sessions-loader" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
              , _react2.default.createElement(_Spinner2.default, { color: "#6257c4", __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}} )
            )
          : (
            _react2.default.createElement('div', { className: "meeting--table meeting-room--history--sessions-table" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
              , _react2.default.createElement('div', { className: "meeting--table-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
                , _react2.default.createElement('div', { className: "meeting--table-row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
                  , _react2.default.createElement('div', { className: this.buildHeaderColClassName("start-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}, "Started")
                  , _react2.default.createElement('div', { className: this.buildHeaderColClassName("participants-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}, "Participants")
                  , _react2.default.createElement('div', { className: this.buildHeaderColClassName("duration-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}, "Session Time" )
                  , _react2.default.createElement('div', { className: this.buildHeaderColClassName("meeting--table-col more-col"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}} )
                )
              )
              , _react2.default.createElement('div', { className: "meeting--table-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
                ,  currentSessions.map(s => {
                  const { startedAt, duration } = _helpers.formatTimingLabels.call(void 0, s.StartedAt, s.EndedAt)
                  const roomUsersCount = s.RoomUsers ? s.RoomUsers.length : 0
                  const roomUsersLabel = roomUsersCount === 1
                    ? '1 participant'
                    : `${roomUsersCount} participants`

                  return (
                      _react2.default.createElement('div', {
                        key: s.RoomSessionKey,
                        onClick: () => onSessionPick(s.RoomSessionKey),
                        className: "meeting--table-row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
                      
                        , _react2.default.createElement('div', { className: "meeting--table-col start-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}, startedAt)
                        , _react2.default.createElement('div', { className: "meeting--table-col participants-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}, roomUsersLabel)
                        , _react2.default.createElement('div', { className: "meeting--table-col duration-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 158}}, duration)
                        , _react2.default.createElement('div', { className: "meeting--table-col more-col" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 159}}, "Details")
                      )
                  )
                })
                
              )
              , _react2.default.createElement('div', { className: "meeting-room--history-paging", __self: this, __source: {fileName: _jsxFileName, lineNumber: 165}}
                , _react2.default.createElement('div', { className: "meeting-room--history-paging--nav", __self: this, __source: {fileName: _jsxFileName, lineNumber: 166}}
                  , _react2.default.createElement('div', {
                    onClick: this.gotoPrevPage,
                    className: "meeting-room--history-paging--arrow prev" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 167}}
                  )
                  , 
                    Array(listedPagesCount).fill(0).map((_, i) => {
                      const idx = _utils.clamp.call(void 0, 
                        0, pagesCount - (listedPagesCount - i),
                      )(currentPage + i)

                      return (
                        _react2.default.createElement('span', {
                          key: idx,
                          onClick: () => this.gotoPage(idx),
                          className: _classBuilder2.default.call(void 0, 
                            "meeting-room--history-paging--page-idx",
                            idx === currentPage && 'active',
                          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 178}}
                        
                          ,  idx + 1 
                        )
                      )
                    })
                  
                  , _react2.default.createElement('div', {
                    onClick: this.gotoNextPage,
                    className: "meeting-room--history-paging--arrow next" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 191}}
                  )
                  , _react2.default.createElement('span', { className: "metting-room--history-paging--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 195}}
                    ,  pageMinIdx , " - "  ,  pageMaxIdx , " out of "   ,  totalSessionsCount , " sessions"
                  )
                )
              )
            )
            )
        
      )
    )
  }

  static __initStatic() {this.propTypes = {
    onSessionPick: _proptypes2.default.func.isRequired,
  }}
} DesktopHistoryList.__initStatic(); exports.default = DesktopHistoryList;
