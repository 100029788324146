"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/Quick/UserPicker.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _UserPreview = require('web/screens/Login/Quick/UserPreview'); var _UserPreview2 = _interopRequireDefault(_UserPreview);







const UserPicker = ({ quickLoginUsersList, onPick, onRemove }) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement('span', { className: "login--quick--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, "Choose an account"  )
    , _react2.default.createElement('div', { className: "login--quick--users--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      ,  quickLoginUsersList.map(u => (
        _react2.default.createElement(_UserPreview2.default, {
          key: u.UserKey,
          userKey: u.UserKey,
          onPick: onPick,
          onRemove: onRemove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
        )
      ))
      , _react2.default.createElement(_UserPreview2.default, { userKey: null, onPick: onPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
    )
  )
)

exports. default = UserPicker
