"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tabs/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Item = require('./Item'); var _Item2 = _interopRequireDefault(_Item);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);















const Tabs = ({
  type, value, options, onSelectTab, className,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
    type === 'filter' ? "filter-tabs--container" : "tabs--container",
    className,
  ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    ,  options.map(o => (
      _react2.default.createElement(_Item2.default, {
        key: o.value,
        onClick: () => onSelectTab(o.value),
        isSelected: o.value === value,
        iconName: o.iconName,
        label: o.label,
        className: type === "tabs" ? "tabs--item" : "filter-tabs--item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      )
    ))
  )
  )

exports. default = Tabs
