"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/BannerAlert.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

 class BannerAlert extends _react.Component {
  constructor (props) {
    super(props);BannerAlert.prototype.__init.call(this);
    this.state = {
      hidden: false,
    }
  }

  __init() {this.hide = () => {
    this.setState({ hidden: true })
  }}

  render () {
    const { message, icon, actions } = this.props
    const { hidden } = this.state

    const buttons = actions.map((a, i) => (
      _react2.default.createElement('button', { key: i, className: "banner-alert--button", onClick: a.func, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        , a.text
      )
    ))

    const className = "banner-alert " +
                      (this.props.breathing ? "breathing " : "") +
                      (this.props.warning ? "warning " : "")

    return (
      _react2.default.createElement('div', { className: className, hidden: hidden, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        , _react2.default.createElement('div', { className: "banner-alert-left", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, icon)
          , message
        )
        , _react2.default.createElement('div', { className: "banner-alert--actions", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          , buttons
        )
      )
    )
  }
} exports.default = BannerAlert;

BannerAlert.propTypes = {
  icon: _proptypes2.default.string,
  breathing: _proptypes2.default.bool,
  message: _proptypes2.default.string.isRequired,
  actions: _proptypes2.default.arrayOf(_proptypes2.default.shape({
    text: _proptypes2.default.string.isRequired,
    func: _proptypes2.default.func.isRequired,
  })),
  warning: _proptypes2.default.bool,
}

BannerAlert.defaultProps = {
  icon: "notification_important",
  message: "",
}
