"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ConfirmEmailScreen.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);

var _AnonymousPage = require('web/components/AnonymousPage');








const errorMsgs = {
  'Invalid Token': 'Sorry, this link has expired. We just sent you a new email confirmation link. Please allow a few minutes for it to arrive.',
  'Cannot steal primary email': 'Sorry, this email address is already being used as the primary email address on another account. Please log in to that account and remove the email before attempting to add it to this account.',
  'generic': 'Sorry, BloomText has encountered an error. Please try again later or contact support@bloomtext.com.',
}

const failureAction = { label: 'Back to BloomText', redirectUrl: _routes2.default.ROOT }
const successAction = { label: 'Back to BloomText', redirectUrl: _routes2.default.INBOX.ROOT }

const ConfirmEmailScreen = () => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, true)
  const [errorMsg, setErrorMsg] = _react.useState.call(void 0, '')

  const match = _reactrouterdom.useRouteMatch(_routes2.default.CONFIRM_EMAIL)
  const token = _optionalChain([match, 'optionalAccess', _ => _.params, 'optionalAccess', _2 => _2.token]) || ''

  _react.useEffect.call(void 0, 
    () => {
      _client2.default.confirmEmail(token)
        .catch((e) => setErrorMsg(
          errorMsgs[_optionalChain([e, 'optionalAccess', _3 => _3.response, 'optionalAccess', _4 => _4.Error])] || errorMsgs.generic,
        ))
        .then(() => setIsLoading(false))
    },
    [token],
  )

  return (
    _react2.default.createElement(_AnonymousPage.PageWrapper, { title: "Confirm email address"  , className: "confirm-email", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      ,  isLoading && _react2.default.createElement(_AnonymousPage.Loader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} ) 
      ,  errorMsg && (
        _react2.default.createElement(_AnonymousPage.ActionResult, {
          title: "Failed to confirm email address"    ,
          subtitle: errorMsg,
          action: failureAction, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        )
      )
      ,  !isLoading && !errorMsg && (
        _react2.default.createElement(_AnonymousPage.ActionResult, {
          title: "Success!",
          subtitle: "BloomText has confirmed your email address."     ,
          action: successAction, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        )
      )
    )
  )
}

exports. default = ConfirmEmailScreen
