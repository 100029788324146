"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');
var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);

 const MIN_MESSAGE_DELAY_MS = 1000; exports.MIN_MESSAGE_DELAY_MS = MIN_MESSAGE_DELAY_MS
 const DEFAULT_MESSAGE_DELAY_MS = 3000; exports.DEFAULT_MESSAGE_DELAY_MS = DEFAULT_MESSAGE_DELAY_MS
 const MAX_MESSAGE_DELAY_MS = 30000; exports.MAX_MESSAGE_DELAY_MS = MAX_MESSAGE_DELAY_MS

 const MAX_BROADCAST_MESSAGE_LENGTH = 1600; exports.MAX_BROADCAST_MESSAGE_LENGTH = MAX_BROADCAST_MESSAGE_LENGTH

var MessageType; (function (MessageType) {
  const SecureSMS = 'secure_sms'; MessageType["SecureSMS"] = SecureSMS;
  const InsecureSMS = 'insecure_sms'; MessageType["InsecureSMS"] = InsecureSMS;
})(MessageType || (exports.MessageType = MessageType = {}));










const schema = _joi4.default.object({
  name: _joi3.requiredString.call(void 0, ).max(100).messages(_joi3.makeStrErrors.call(void 0, 'Campaign name')),
  teamKey: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Team')),
  senderKey: _joi3.requiredString.call(void 0, ).messages(_joi3.makeStrErrors.call(void 0, 'Sender')),
  messageType: _joi3.requiredString.call(void 0, ),
  messageDelay: _joi4.default.number()
    .min(exports.MIN_MESSAGE_DELAY_MS)
    .max(exports.MAX_MESSAGE_DELAY_MS),
  messageTemplate: _joi3.requiredString.call(void 0, )
    .max(exports.MAX_BROADCAST_MESSAGE_LENGTH)
    .messages(_joi3.makeStrErrors.call(void 0, 'Message')),
})

 const campaignResolver = _joi.joiResolver.call(void 0, schema); exports.campaignResolver = campaignResolver
