"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');




var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);





var PlanError; (function (PlanError) {
  const GET_PLAN_FAILED = 'GET_PLAN_FAILED'; PlanError["GET_PLAN_FAILED"] = GET_PLAN_FAILED;
  const CHECKOUT_FAILED = 'CHECKOUT_FAILED'; PlanError["CHECKOUT_FAILED"] = CHECKOUT_FAILED;
  const LOCKED = 'PLAN_LOCKED'; PlanError["LOCKED"] = LOCKED;
})(PlanError || (exports.PlanError = PlanError = {}));

const zero = 0 

 const usePlan = ({ orgKey }) => {
  const [savedCardData, setSavedCardData] = _react.useState(null)
  const [discount, setDiscount] = _react.useState(zero)
  const [items, setItems] = _react.useState([])
  const [error, setError] = _react.useState(null)

  const hasSubscription = !!savedCardData

  _react.useEffect.call(void 0, 
    () => {
      _actions2.default.getSubscription({ OrgKey: orgKey })
        .then(r => {
          if (r.Locked) return setError(PlanError.LOCKED)
          if (r.Discount) setDiscount(r.Discount )
          if (r.Card) setSavedCardData(r.Card)
          if (r.Items) setItems(r.Items)
        })
        .catch(() => setError(PlanError.GET_PLAN_FAILED))
    },
    [orgKey],
  )

  return {
    isLoading: !items.length && !error,
    error,
    items,
    hasSubscription,
    savedCardData,
    discount,
  }
}; exports.usePlan = usePlan
