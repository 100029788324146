"use strict";Object.defineProperty(exports, "__esModule", {value: true}); // eslint-disable-line

 class EventEmitter {constructor() { EventEmitter.prototype.__init.call(this);EventEmitter.prototype.__init2.call(this);EventEmitter.prototype.__init3.call(this);EventEmitter.prototype.__init4.call(this);EventEmitter.prototype.__init5.call(this);EventEmitter.prototype.__init6.call(this); }
   __init() {this.listeners = {}}
   __init2() {this.getListeners = (name) => {
    if (!this.listeners[name]) this.listeners[name] = []
    return this.listeners[name]
  }}

  __init3() {this.on = (eventName, callback) => {
    const eventListeners = this.getListeners(eventName)
    eventListeners.push(callback)
  }}

  __init4() {this.once = (eventName, callback) => {
    const eventListeners = this.getListeners(eventName)
    const onceCallback = (...args) => {
      callback(...args)
      this.off(eventName, onceCallback)
    }

    eventListeners.push(onceCallback)
  }}

  __init5() {this.off = (eventName, callback) => {
    if (!eventName) {
      this.listeners = {}
      return
    }

    const eventListeners = this.getListeners(eventName)
    if (!callback) delete this.listeners[eventName]

    const idx = callback ? eventListeners.indexOf(callback) : -1
    if (idx > -1) eventListeners.splice(idx, 1)
  }}

  __init6() {this.emit = (eventName, args) =>
    this.getListeners(eventName)
      .forEach(l => {
        if (Array.isArray(args)) l.apply(this, args)
        else l.call(this, args)
      })}
} exports.default = EventEmitter;
