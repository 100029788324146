"use strict";Object.defineProperty(exports, "__esModule", {value: true});


var _reactredux = require('react-redux');



const useSelector = _reactredux.useSelector

exports. default = useSelector
