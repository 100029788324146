"use strict";Object.defineProperty(exports, "__esModule", {value: true}); const isObjectEmpty = obj =>
  !obj || Object.keys(obj).length === 0; exports.isObjectEmpty = isObjectEmpty

 const flatten = xs => xs.reduce((acc, x) => acc.concat(x), []); exports.flatten = flatten

 const clamp = (min, max) => x => Math.max(min, Math.min(x, max)); exports.clamp = clamp

 const safePromise = promiseCall => params =>
  promiseCall(params)
    .then(x => [x, undefined])
    .catch(e => Promise.resolve([undefined, e])); exports.safePromise = safePromise

 class Deferred {
  __init() {this.isResolved = false}
  __init2() {this.isRejected = false}

  get isSettled () { return this.isResolved || this.isRejected }
  get isPending () { return !this.isSettled }

  constructor () {;Deferred.prototype.__init.call(this);Deferred.prototype.__init2.call(this);
    this.promise = new Promise((resolve, reject) => {
      this.resolve = (r) => {
        this.isResolved = true
        resolve(r)
      }
      this.reject = e => {
        this.isRejected = true
        reject(e)
      }
    })
  }

  replace (deferred) {
    // TODO: reward myself with a drink if this thing doesn't leak memory...
    this.promise = deferred.promise.then(this.resolve).catch(this.reject)
  }
} exports.Deferred = Deferred;
