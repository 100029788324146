"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }


var _map = require('shared/services/map');








var _entities = require('shared/transforms/entities');

var _array = require('shared/services/array');

const mapSearchResultsToEntities = (
  { Patients = {}, Teams = {}, Users = {}, Orgs = {} },
  excludedMap,
  myOrgKey,
) => {
  const isEntityAllowed = (x) => !excludedMap[x.entityKey]

  const allOrgs = (Array.isArray(Orgs) ? _map.listToMap.call(void 0, Orgs, o => o.OrgKey) : Orgs) 

  const userEntitiesList = Object.values(Users)
    .map(_entities.mapUserToEntity.call(void 0, allOrgs, _entities.makeOrgTitleUserDescription))
    .filter(isEntityAllowed)

  const teamEntitiesList = Object.values(Teams)
    .map(_entities.mapTeamToEntity.call(void 0, allOrgs, _entities.makeMembersCountTeamDescription))
    .filter(isEntityAllowed)

  const baseEntities = []
  const [internalEntitiesList, externalEntitiesList] = _array.splitBy.call(void 0, 
    baseEntities.concat(userEntitiesList).concat(teamEntitiesList),
    e => e.wrappedEntity && !!myOrgKey && (
      'OrgKey' in e.wrappedEntity && e.wrappedEntity.OrgKey === myOrgKey ||
      !!('Relationships' in e.wrappedEntity && _optionalChain([e, 'access', _ => _.wrappedEntity, 'access', _2 => _2.Relationships, 'optionalAccess', _3 => _3[myOrgKey]]))
    ),
  )

  const patientEntitiesList = Object.values(Patients)
    .map(_entities.mapPatientToEntity.call(void 0, 
      allOrgs,
      _entities.makeOrgNamePatientDescription,
    ))
    .filter(isEntityAllowed)

  return {
    internalEntitiesList,
    externalEntitiesList,
    patientEntitiesList,
  }
}

exports. default = mapSearchResultsToEntities
