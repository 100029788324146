"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }









var _array = require('shared/services/array');















const getAvatar = (
  entities,
  renders,
  participantNumber = 0,
) => {
  const {
    myUserKey,
    otherUsersList,
    myTeamsList,
    otherTeamsList,
    patientsList,
  } = entities

  const {
    renderPatientAvatar,
    renderTeamAvatar,
    renderUserAvatar,
  } = renders

  const patientKey = _optionalChain([patientsList, 'access', _ => _[0], 'optionalAccess', _2 => _2.PatientKey])
  if (patientKey) {
    return [
      renderPatientAvatar({ patientKey, participantNumber }),
      { ...entities, patientsList: patientsList.slice(1) },
    ]
  }

  const otherTeamKey = _optionalChain([otherTeamsList, 'access', _3 => _3[0], 'optionalAccess', _4 => _4.TeamKey])
  if (otherTeamKey) {
    return [
      renderTeamAvatar({ teamKey: otherTeamKey, participantNumber }),
      { ...entities, otherTeamsList: otherTeamsList.slice(1) },
    ]
  }

  const myTeamKey = _optionalChain([myTeamsList, 'access', _5 => _5[0], 'optionalAccess', _6 => _6.TeamKey])
  if (myTeamKey) {
    return [
      renderTeamAvatar({ teamKey: myTeamKey, participantNumber }),
      { ...entities, myTeamsList: myTeamsList.slice(1), myUserKey: null },
    ]
  }

  const otherUserKey = _optionalChain([otherUsersList, 'access', _7 => _7[0], 'optionalAccess', _8 => _8.UserKey])
  if (otherUserKey) {
    return [
      renderUserAvatar({ userKey: otherUserKey, participantNumber }),
      { ...entities, otherUsersList: otherUsersList.slice(1) },
    ]
  }

  if (!myUserKey) {
    log.error('Failed to resolve an inbox avatar')
    return [null, entities]
  }

  return [
    renderUserAvatar({ userKey: myUserKey, participantNumber }),
    { ...entities, myUserKey: null },
  ]
}














const renderCompositeChatAvatar = ({
  patientSubscribersList,
  userSubscribersList,
  teamSubscribersList,

  currentUserKey,
  otherUserSubscribersList,

  layoutProps,
  renderPatientAvatar,
  renderUserAvatar,
  renderTeamAvatar,
  renderGroupAvatar,
}) => {
  const participantNumber =
    userSubscribersList.length +
    teamSubscribersList.length +
    patientSubscribersList.length - 1

  const [myTeamsList, otherTeamsList] =
    _array.splitBy.call(void 0, 
      teamSubscribersList,
      t => !!('Members' in t && t.Members[currentUserKey]),
    )

  const renders = {
    renderPatientAvatar,
    renderUserAvatar,
    renderTeamAvatar,
  }

  const [frontAvatar, backAvatarEntities] = getAvatar({
    myTeamsList,
    otherTeamsList,
    myUserKey: currentUserKey,
    otherUsersList: otherUserSubscribersList,
    patientsList: patientSubscribersList,
  }, renders)

  const [backAvatar] = getAvatar(backAvatarEntities, renders, participantNumber)

  return frontAvatar && backAvatar
    ? renderGroupAvatar({ frontAvatar, backAvatar, ...layoutProps })
    : (frontAvatar || backAvatar || null)
}

exports. default = renderCompositeChatAvatar
