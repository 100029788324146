"use strict";Object.defineProperty(exports, "__esModule", {value: true});const pad = x => x.toString().padStart(2, '0')

 const formatSessionDuration = duration => {
  if (!duration) return null

  const min = Math.floor(duration / 60)
  const sec = Math.floor(duration % 60)

  const secLabel = sec === 1 ? 'sec' : 'secs'

  return isNaN(min) || isNaN(sec)
    ? null
    : `${pad(min)} min ${pad(sec)} ${secLabel}`
}; exports.formatSessionDuration = formatSessionDuration
