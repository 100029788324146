"use strict";Object.defineProperty(exports, "__esModule", {value: true});







 const buildClassName = (...args) => {
  const classes = []

  for (let i = 0; i < args.length; i++) {
    const arg = args[i]
    if (!arg) continue

    const argType = typeof arg

    if (argType === 'string' || argType === 'number') {
      classes.push(arg)
    } else if (Array.isArray(arg) && arg.length) {
      const inner = exports.buildClassName.call(void 0, ...arg)
      if (inner) {
        classes.push(inner)
      }
    } else if (argType === 'object') {
      const objArg = (arg )
      for (const key in objArg) {
        if ({}.hasOwnProperty.call(arg, key) && objArg[key]) {
          classes.push(key)
        }
      }
    }
  }

  return classes.join(' ')
}; exports.buildClassName = buildClassName

exports. default = exports.buildClassName
