"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _cookies = require('web/services/cookies');

 const hasBeenSeenOnThisMachineBefore = (userKey) => {
  const cookie = JSON.parse(_cookies.readCookie.call(void 0, "previousUsers"))

  if (cookie == null) {
    return false
  }

  return cookie.indexOf(userKey) > -1
}; exports.hasBeenSeenOnThisMachineBefore = hasBeenSeenOnThisMachineBefore

 const addUserToPreviousLoginsList = (userKey) => {
  let cookie = JSON.parse(_cookies.readCookie.call(void 0, "previousUsers"))

  if (cookie == null) {
    cookie = []
  }

  if (cookie.indexOf(userKey) === -1) {
    cookie.push(userKey)
  }

  _cookies.createCookie.call(void 0, "previousUsers", JSON.stringify(cookie), { years: 1 })
}; exports.addUserToPreviousLoginsList = addUserToPreviousLoginsList

 const isUserAwareOfQuickLogin = userKey => {
  const usersFromCookies = JSON.parse(_cookies.readCookie.call(void 0, 'usersAwareOfQuickLogin'))
  return usersFromCookies && usersFromCookies.indexOf(userKey) > -1
}; exports.isUserAwareOfQuickLogin = isUserAwareOfQuickLogin

 const addUserToAwareOfQuickLoginList = userKey => {
  const usersList = JSON.parse(_cookies.readCookie.call(void 0, 'usersAwareOfQuickLogin')) || []
  const updatedUsersList = usersList.indexOf(userKey) > -1
    ? usersList
    : usersList.concat(userKey)

  _cookies.createCookie.call(void 0, 'usersAwareOfQuickLogin', JSON.stringify(updatedUsersList), { years: 1 })
}; exports.addUserToAwareOfQuickLoginList = addUserToAwareOfQuickLoginList
