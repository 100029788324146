"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/OrgsList/OrgsListSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _Headings = require('web/components/Settings/Headings');
var _OrgBtn = require('web/components/Settings/OrgsList/OrgBtn'); var _OrgBtn2 = _interopRequireDefault(_OrgBtn);






const OrgsListSection = ({ orgsList, gotoOrg }) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, "Manage Organizations" )
    , _react2.default.createElement('span', { className: "settings--orgs-list--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}, "Choose an organization to view or edit its details and manage members or policies."

    )
    ,  orgsList.map(({
      OrgKey,
      OrgName,
      IsAdmin,
      PendingUsersCount,
    }) => (
      _react2.default.createElement(_OrgBtn2.default, {
        key: OrgKey,
        shouldRenderForwardIcon: true,
        IsAdmin: IsAdmin,
        PendingUsersCount: PendingUsersCount,
        iconSrc: "/assets/icons/settings/org-active.svg",
        className: "settings--orgs-list--org",
        label: OrgName,
        onClick: () => gotoOrg(OrgKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
    )) 
  )
)

exports. default = OrgsListSection
