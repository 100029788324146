"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');






var _chat = require('shared/validation/createNew/chat');
var _chat3 = require('shared/enum/chat');
var _entity = require('shared/enum/entity');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _asyncDebounce = require('shared/services/asyncDebounce'); var _asyncDebounce2 = _interopRequireDefault(_asyncDebounce);
var _entity3 = require('shared/services/entity');
var _uuid = require('shared/services/uuid');








const cancelObj = {}
const debouncedFindConversation =
  _asyncDebounce2.default.call(void 0, _actions2.default.findReusableConversation, 250, { cancelObj })

 const useSuggestedChat = ({
  senderEntity,
  selectedEntitiesList,
  chatMethod,
  chatName = '',
}) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [suggestedChat, setSuggestedChat] = _react.useState(null)

  _react.useLayoutEffect.call(void 0, 
    () => {
      if (!senderEntity || chatName.length > _chat.MAX_NAME_LENGTH) return setSuggestedChat(null)

      const allEntities = selectedEntitiesList.concat(senderEntity)
      const groups = _entity3.groupEntities.call(void 0, allEntities)

      const OrgKey = senderEntity.wrappedEntity.OrgKey
      const UserKeys = _optionalChain([groups, 'access', _ => _[_entity.ENTITY_TYPES.USER], 'optionalAccess', _2 => _2.map, 'call', _3 => _3(e => e.wrappedEntity.UserKey)]) || []
      const RelUserKeys = _optionalChain([groups, 'access', _4 => _4[_entity.ENTITY_TYPES.RELATIONSHIP], 'optionalAccess', _5 => _5.map, 'call', _6 => _6(e => e.wrappedEntity.UserKey)]) || []
      const TeamKeys = _optionalChain([groups, 'access', _7 => _7[_entity.ENTITY_TYPES.TEAM], 'optionalAccess', _8 => _8.map, 'call', _9 => _9(e => e.wrappedEntity.TeamKey)]) || []
      const PatientKeys = _optionalChain([groups, 'access', _10 => _10[_entity.ENTITY_TYPES.PATIENT], 'optionalAccess', _11 => _11.map, 'call', _12 => _12(e => e.wrappedEntity.PatientKey)]) || []
      const ChatType = chatMethod === _chat3.DELIVERY_METHOD.SMS
        ? _chat3.CHAT_TYPE.REGULAR_PATIENT
        : PatientKeys.length ? _chat3.CHAT_TYPE.SECURE_PATIENT : _chat3.CHAT_TYPE.REGULAR

      setIsLoading(true)
      debouncedFindConversation({
        OrgKey,
        UserKeys: UserKeys.concat(RelUserKeys).filter((key) => key && key !== _uuid.EMPTY_BLUID),
        TeamKeys,
        PatientKeys,
        ChatName: chatName,
        ChatType,
      })
        .then(r => {
          setSuggestedChat(r)
          setIsLoading(false)
        })
        .catch(e => {
          if (e === cancelObj) return
          setIsLoading(false)
          setSuggestedChat(null)
        })
    },
    [senderEntity, selectedEntitiesList, chatMethod, chatName],
  )

  return { suggestedChat, isLoading }
}; exports.useSuggestedChat = useSuggestedChat
