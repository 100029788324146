"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/GenericSelect/GenericEntityOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);














const GenericEntityOption = (
  props,
) => {
  if ('isEmpty' in props) {
    return (
      _react2.default.createElement('div', {
        role: "button",
        onClick: props.onSelect,
        className: _classBuilder2.default.call(void 0, 
          "blm-generic-entity-option",
          "blm-generic-entity-option--empty",
          "blm-generic-entity-option--preview",
          props.className,
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      
        ,  props.children 
      )
    )
  }

  const {
    option, name, description, isSelected,
    isPreviewOption, isPreviewOptionFocused, onSelect, Avatar,
  } = props

  return (
    _react2.default.createElement('div', {
      role: "button",
      onClick: onSelect,
      className: _classBuilder2.default.call(void 0, 
        "blm-generic-entity-option",
        isPreviewOption && "blm-generic-entity-option--preview",
        isPreviewOption && isPreviewOptionFocused && "blm-generic-entity-option--preview-focused",
        props.className,
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
    
      , _react2.default.createElement(Avatar, {
        size: 30,
        option: option,
        className: "blm-generic-entity-option--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )
      , _react2.default.createElement('div', { className: "blm-generic-entity-option--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , _react2.default.createElement('span', { className: "blm-generic-entity-option--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}},  name )
        ,  description && (
          _react2.default.createElement('span', { className: "blm-generic-entity-option--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}},  description )
        )
      )
      ,  isSelected && !isPreviewOption && (
        _react2.default.createElement('div', { className: "blm-generic-entity-option--check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} )
      )
    )
  )
}

exports. default = GenericEntityOption
