"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/AvatarPicker/Form/AvatarUpload.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactdropzone = require('react-dropzone'); var _reactdropzone2 = _interopRequireDefault(_reactdropzone);

var _noticeBar = require('web/services/context/noticeBar');


const acceptedMIME = ['image/jpeg', 'image/png']





const AvatarUpload = (
  { onChange },
  ref,
) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  const onDropAccepted = ([file]) => {
    if (!file) return
    onChange(file)
  }

  const onDropRejected = () => showWarningBar('Failed to process file')

  return (
    _react2.default.createElement(_reactdropzone2.default, {
      ref: ref,
      className: "blmh-hide",
      accept: acceptedMIME,
      onDropAccepted: onDropAccepted,
      onDropRejected: onDropRejected, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
    )
  )
}

exports. default = _react2.default.forwardRef(AvatarUpload)
