"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _uuid = require('shared/services/uuid');
var _message = require('shared/enum/message');

 const MessageTypes = {
  META: "meta",
  TEXT: "text",
  FILE: "file",
  DATE: "date",
  MARKER: "marker",
}; exports.MessageTypes = MessageTypes

 const SenderTypes = {
  PATIENT: "patient",
  USER: "user",
  NONE: "",
}; exports.SenderTypes = SenderTypes

function getProperSenderKey (keyList) {
  for (let i = 0; i < keyList.length; i++) {
    const key = keyList[i]
    if (key && key !== _uuid.EMPTY_BLUID) {
      return key
    }
  }
}

 const baseMessagePropTypes = {
  senderAvatar: _proptypes2.default.string,
  senderType: _proptypes2.default.oneOf(Object.values(exports.SenderTypes)),
  senderName: _proptypes2.default.string,
  senderKey: _proptypes2.default.string,
  sentAt: _proptypes2.default.string, /* ISO Date */
  seenBy: _proptypes2.default.objectOf({
    SeenTime: _proptypes2.default.string, /* ISO Date */
    SubscriberKey: _proptypes2.default.string,
    IsLatestMessageSeen: _proptypes2.default.string,
  }),
  showAvatar: _proptypes2.default.bool,
}; exports.baseMessagePropTypes = baseMessagePropTypes

const Message = (props) => {
  const {
    Avatar,
    ConversationKey,
    DisplayName,
    FileName,
    FileInfo,
    FileReference,
    MessageKey,
    MessageText,
    MessageType,
    PatientKey,
    SenderType,
    SentAt,
    TeamKey,
    UserKey,
    SeenBy,
    SameSenderAsNextMessage,
    SameSenderAsPreviousMessage,
    Uploading,
    SendFailed,
    CurrentUserKey,
    Subscribers,
    renderTextMessage,
    renderFileMessage,
    renderMetaMessage,
    renderDateMarker,
    highlighted,
    onDelete,
    deleted,
    deliveryStatus,
  } = props

  const allSenderKeys = [PatientKey, UserKey, TeamKey]
  const senderKey = getProperSenderKey(allSenderKeys)

  const commonMessageProps = {
    senderAvatar: Avatar,
    senderType: SenderType,
    senderName: DisplayName,
    senderKey: senderKey,
    showAvatar: !SameSenderAsNextMessage,
    showName: !SameSenderAsPreviousMessage,
    sentAt: SentAt,
    seenBy: SeenBy,
    currentUserKey: CurrentUserKey,
    subscribers: Subscribers,
    onDelete: onDelete,
    conversationKey: ConversationKey,
    deleted,
    deliveryStatus: deliveryStatus || _message.deliveryStatus.DELIVERED,
  }

  switch (MessageType) {
    case exports.MessageTypes.TEXT:
      return renderTextMessage({
        text: MessageText,
        sendFailed: SendFailed,
        messageKey: MessageKey,
        highlighted: highlighted,
        ...commonMessageProps,
      })
    case exports.MessageTypes.FILE:
      return renderFileMessage({
        messageKey: MessageKey,
        sendFailed: SendFailed,
        fileReference: FileReference,
        fileName: FileName,
        fileSize: FileInfo ? FileInfo.Size : 0,
        filePreview: FileInfo ? FileInfo.PreviewUrl : null,
        uploading: Uploading,
        highlighted: highlighted,
        ...commonMessageProps,
      })
      /* TODO image file here */
    case exports.MessageTypes.DATE:
      return renderDateMarker({
        text: MessageText,
        messageKey: MessageKey,
        sentAt: SentAt,
      })
    case exports.MessageTypes.META:
    /* default means either old client or bad backend. For now we do our best to degrade
     * into a meta message
     */
    default:
      if (MessageText) {
        return renderMetaMessage({
          text: MessageText,
          messageKey: MessageKey,
          highlighted: highlighted,
        })
      }
      return null
  }
}

 const SeenByPropType = _proptypes2.default.objectOf(_proptypes2.default.shape({
  SeenTime: _proptypes2.default.string, /* ISO Date */
  SubscriberKey: _proptypes2.default.string,
  IsLatestMessageSeen: _proptypes2.default.string,
})); exports.SeenByPropType = SeenByPropType

Message.propTypes = {
  /* This part just comes straight from the backend (or jankily constructed on frontend) */
  Avatar: _proptypes2.default.string,
  ConversationKey: _proptypes2.default.string,
  DisplayName: _proptypes2.default.string,
  FileName: _proptypes2.default.string,
  FileReference: _proptypes2.default.string, /* GUID */
  MessageKey: _proptypes2.default.string, /* GUID */
  MessageText: _proptypes2.default.string,
  MessageType: _proptypes2.default.oneOf(Object.values(exports.MessageTypes)),
  PatientKey: _proptypes2.default.string, /* GUID */
  SenderType: _proptypes2.default.oneOf(Object.values(exports.SenderTypes)),
  SentAt: _proptypes2.default.string, /* ISO Date */
  Sequence: _proptypes2.default.number,
  TeamKey: _proptypes2.default.string, /* GUID */
  UserKey: _proptypes2.default.string, /* GUID */
  CurrentUserKey: _proptypes2.default.string,
  Subscribers: _proptypes2.default.shape({}),

  /* computed on frontend but will one day come back from backend?  (April 2019) */
  SeenBy: exports.SeenByPropType,
  SameSenderAsNextMessage: _proptypes2.default.bool,
  SendFailed: _proptypes2.default.bool,

  /* relevant for file uploads */
  Uploading: _proptypes2.default.bool,

  /* this part is defined per app env (mobile, web) */
  renderTextMessage: _proptypes2.default.func,
  renderFileMessage: _proptypes2.default.func,
  renderMetaMessage: _proptypes2.default.func,
  renderDateMarker: _proptypes2.default.func,
  renderConversationMarker: _proptypes2.default.func,

}

exports. default = Message
