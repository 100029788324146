"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _toolkit = require('@reduxjs/toolkit');


const initialState = {}

exports. default = _toolkit.createReducer.call(void 0, 
  initialState,
  builder => {
    [
      // ac.updateNotificationAction,
      _actionCreators2.default.getInboxActions.SUCCESS,
      _actionCreators2.default.getBlobjectDeepActions.SUCCESS,
      _actionCreators2.default.getUsersActions.SUCCESS,
      _actionCreators2.default.approvePendingUserActions.SUCCESS,
      _actionCreators2.default.rejectPendingUserActions.SUCCESS,
    ].forEach(t => builder.addCase(t, (s, a) => Object.assign(s, a.payload.Users)))

    ;[
      _actionCreators2.default.findUsersInOrgActions.SUCCESS,
      _actionCreators2.default.findUsersInTeamActions.SUCCESS,
      _actionCreators2.default.findUsersNotInTeamActions.SUCCESS,
    ].forEach(t => builder.addCase(t, (s, a) => {
      a.payload.Users.forEach(u => s[u.UserKey] = u)
    }))

    builder.addCase(_actionCreators2.default.setAvatarActions.SUCCESS, (s, a) => {
      const user = s[a.meta.UserKey]
      if (user) user.ProfilePicture = a.meta.Ref
    })

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)
  },
)
