"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');




var _common = require('shared/selectors/blobject/common');

 const selectConversationByKey =
  (state, k) => state.dataV2.conversations[k]; exports.selectConversationByKey = selectConversationByKey

 const selectConversationType = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  b => _optionalChain([b, 'optionalAccess', _ => _.WrappedType]) || null,
); exports.selectConversationType = selectConversationType

const EMPTY_LIST = []
 const selectMessagesByConversationKey = (state, k) =>
  state.dataV2.messages[k] || EMPTY_LIST; exports.selectMessagesByConversationKey = selectMessagesByConversationKey

 const selectVisibleConversationKey = (state) =>
  state.dataV2.session.visibleConversationKey; exports.selectVisibleConversationKey = selectVisibleConversationKey

 const selectInboxHasAlert = (state) =>
  state.dataV2.inducedValues.inboxHasAlert; exports.selectInboxHasAlert = selectInboxHasAlert
