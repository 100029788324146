"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/DesktopSetup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _AudioSetupGroup = require('web/screens/VideoCall/segments/AudioSetupGroup'); var _AudioSetupGroup2 = _interopRequireDefault(_AudioSetupGroup);
var _VideoSetupGroup = require('web/screens/VideoCall/segments/VideoSetupGroup'); var _VideoSetupGroup2 = _interopRequireDefault(_VideoSetupGroup);

const DesktopSetup = ({ gotoPreCall, onUserProblemPublish }) => (
  _react2.default.createElement('div', { className: "meeting--setup--desktop", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    , _react2.default.createElement('div', { className: "meeting--setup--heading", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
      , _react2.default.createElement('h1', { className: "meeting--setup--heading--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}, "Verify your Settings"

      )
      , _react2.default.createElement('span', { className: "meeting--setup--heading--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, "Make sure your video and audio are working."

      )
    )
    , _react2.default.createElement('div', { className: "meeting--setup--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      , _react2.default.createElement('div', { className: "meeting--setup--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , _react2.default.createElement('div', { className: "meeting--setup--content--row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
          , _react2.default.createElement(_VideoSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
        )
        , _react2.default.createElement('div', { className: "meeting--setup--content--row", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          , _react2.default.createElement(_AudioSetupGroup2.default, { onUserProblemPublish: onUserProblemPublish, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
        )
      )
      , _react2.default.createElement('div', { className: "meeting--setup--nav", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        , _react2.default.createElement('a', {
          onClick: gotoPreCall,
          className: "blmh-button meeting--btn primary purple highlight meeting--setup--proceed-btn"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
        , "Continue"

          , _react2.default.createElement('img', { src: "/assets/icons/icon-arrow-right-white.svg", alt: "->", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
        )
      )
    )
  )
)

DesktopSetup.propTypes = {
  gotoPreCall: _proptypes2.default.func.isRequired,
}

exports. default = DesktopSetup
