"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/UserTile/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Link = require('web/components/Settings/Link'); var _Link2 = _interopRequireDefault(_Link);





var _Avatar = require('web/components/Avatar');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);




















const stopPropagation = e => e.stopPropagation()

const UserTile = ({
  entity, isMe, orgKey,
  onApprove, onReject, onRemove,
  onEdit, onResendInvite, onGotoUser,
}) => {
  const pendingRel = _optionalChain([entity, 'access', _ => _.wrappedEntity, 'access', _2 => _2.PendingRelationships, 'optionalAccess', _3 => _3[orgKey]])
  const activeRel = entity.wrappedEntity.Relationships[orgKey]
  const isActivated = entity.wrappedEntity.Activated
  const isPending = !!pendingRel
  const isAdmin = _optionalChain([activeRel, 'optionalAccess', _4 => _4.RelType]) === 'admin'
  const title = _optionalChain([activeRel, 'optionalAccess', _5 => _5.Title]) || _optionalChain([pendingRel, 'optionalAccess', _6 => _6.Title])
  const userKey = entity.wrappedEntity.UserKey

  const actions = _react.useMemo.call(void 0, 
    () => [
      ...(!isActivated && onResendInvite
        ? [{ label: 'Resend invite', onClick: () => onResendInvite(userKey) }]
        : []
      ),
      ...(isActivated && !isPending && onEdit
        ? [{ label: 'Manage', onClick: () => onEdit(entity) }]
        : []
      ),
      ...(isPending && onApprove && onReject
        ? [
            { label: 'Approve', onClick: () => onApprove(entity) },
            { isDestructive: true, label: 'Reject', onClick: () => onReject(entity) },
          ]
        : []
      ),
      ...(!isPending && onRemove
        ? [{ isDestructive: true, label: 'Remove', onClick: () => onRemove(entity) }]
        : []
      ),
    ],
    [
      userKey, entity, onResendInvite, onEdit, onApprove,
      onReject, isActivated, isPending, onRemove,
    ],
  )

  return (
    _react2.default.createElement('div', {
      role: onGotoUser ? "button" : "none",
      className: _classBuilder2.default.call(void 0, 
        "settings--org-members--member",
        onGotoUser && "settings--org-members--member--interactive",
      ),
      onClick: () => _optionalChain([onGotoUser, 'optionalCall', _7 => _7(userKey)]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
    
      , _react2.default.createElement('div', { className: "settings--org-members--member-avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        , _react2.default.createElement(_Avatar.EntityAvatar, { entity: entity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}} )
      )
      , _react2.default.createElement('div', { className: "settings--org-members--member-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
        , _react2.default.createElement('span', { className: "settings--org-members--member-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
          ,  entity.name 
          ,  isMe && (
            _react2.default.createElement('span', { className: "settings--org-members--member-is-me", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "(You)")
          )
          ,  isAdmin && (
            _react2.default.createElement('span', { className: "settings--org-members--member-badge settings--org-members--member-badge--admin" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, "Admin")
          )
          ,  isPending && (
            _react2.default.createElement('span', { className: "settings--org-members--member-badge settings--org-members--member-badge--pending" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, "Pending"

            )
          )
        )
        , _react2.default.createElement('span', { className: "settings--org-members--member-email", __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
          ,  entity.wrappedEntity.Email 
        )
        , _react2.default.createElement('span', { className: "settings--org-members--member-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}},  title )
      )
      , _react2.default.createElement('div', {
        className: "settings--org-members--member-controls",
        onClick: stopPropagation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
      
        ,  actions.map((a, i) => (
          _react2.default.createElement(_Link2.default, {
            key: i,
            role: "button",
            onClick: a.onClick,
            className: a.isDestructive
              ? 'settings--org-members--member-remove'
              : ''
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
          
            ,  a.label 
          )
        )) 
      )
    )
  )
}

exports. default = UserTile
