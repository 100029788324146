"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Modal/Modal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactdom = require('react-dom'); var _reactdom2 = _interopRequireDefault(_reactdom);
var _reacttransitiongroup = require('react-transition-group');

var _View = require('./View'); var _View2 = _interopRequireDefault(_View);

const Modal = props => {
  const modal = (
    _react2.default.createElement(_reacttransitiongroup.CSSTransition, {
      appear: true,
      unmountOnExit: true,
      in: props.isOpen,
      timeout: 200,
      classNames: "anim", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}
    
      , _react2.default.createElement(_View2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
    )
  )

  const modalsRoot = document.getElementById('modals-root')
  return modalsRoot ? _reactdom2.default.createPortal(modal, modalsRoot) : modal
}

exports. default = Modal
