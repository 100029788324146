"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/BulkControls/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _broadcast = require('shared/selectors/broadcast');

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);


var _Tooltip = require('web/components/Tooltip');
var _RemoveConfirmationModal = require('./RemoveConfirmationModal'); var _RemoveConfirmationModal2 = _interopRequireDefault(_RemoveConfirmationModal);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const BulkControls = ({
  onClearAllRowsSelection,
  selectedPatientKeys,
  canRemove,
  canDequeue,
  onRemovePatients,
  onDequeuePatients,
  isCampaignArchived,
}) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )
  const {
    isOpen: isRemoveConfirmationModalOpen,
    onOpen: onOpenRemoveConfirmationModal,
    onClose: onCloseRemoveConirmationModal,
  } = _useToggle2.default.call(void 0, )

  const onConfirmRemovingPatients = _react.useCallback.call(void 0, 
    () => {
      onRemovePatients(selectedPatientKeys)
      onClearAllRowsSelection()
    },
    [selectedPatientKeys, onClearAllRowsSelection, onRemovePatients],
  )

  const handleDequeue = _react.useCallback.call(void 0, 
    () => {
      onDequeuePatients(selectedPatientKeys)
      onClearAllRowsSelection()
    },
    [selectedPatientKeys, onClearAllRowsSelection, onDequeuePatients],
  )

  const selectedRowsLabel = selectedPatientKeys.length === 1
    ? '1 patient selected'
    : `${selectedPatientKeys.length} patients selected`

  const areNoActionsAvailable = !canRemove && !canDequeue

  const content = (
    _react2.default.createElement(_Tooltip.TooltipContent, { className: "campaign-table--bulk-controls--popup", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      ,  canDequeue && (
        _react2.default.createElement(_Tooltip.TooltipMenuItem, {
          iconSrc: "/assets/icons/broadcast/dequeue.svg",
          label: "Remove from queue"  ,
          onClick: handleDequeue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
        )
      )
      ,  canRemove && (
        _react2.default.createElement(_Tooltip.TooltipMenuItem, {
          iconSrc: "/assets/icons/broadcast/remove.svg",
          disabled: isCampaignArchived,
          label: "Remove from campaign"  ,
          desc: isCampaignArchived ? "Can't remove patients from an archived campaign" : '',
          onClick: onOpenRemoveConfirmationModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        )
      )
      ,  areNoActionsAvailable && (
        _react2.default.createElement('span', { className: "campaign--table--bulk-controls--popup-no-actions", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, "No actions available"

        )
      )
    )
  )

  return (
    _react2.default.createElement('div', { className: "campaign--table--bulk-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      , _react2.default.createElement('span', { className: "campaign--table--bulk-controls--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
        ,  selectedRowsLabel 
      )
      , _react2.default.createElement(_Tooltip.ControlledTooltip, {
        isDismissibleByClickElsewhere: true,
        place: "bottom-left",
        isOpen: isOpen,
        onClose: onClose,
        content: content,
        className: "campaign--table--bulk-controls--toggle-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
      
        , _react2.default.createElement('div', {
          role: "button",
          onClick: onOpen,
          className: _classBuilder2.default.call(void 0, 
            "campaign--table--bulk-controls--toggle",
            isOpen && "campaign--table--bulk-controls--toggle-active",
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
        , "View options"

        )
      )
      , _react2.default.createElement(_RemoveConfirmationModal2.default, {
        isOpen: isRemoveConfirmationModalOpen,
        onClose: onCloseRemoveConirmationModal,
        patientsCount: selectedPatientKeys.length,
        onConfirm: onConfirmRemovingPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
      )
    )
  )
}







const mapState = (
  state,
  { selectedPatientKeys, campaignKey },
) => ({
  ..._broadcast.selectSelectedPatientsActions.call(void 0, state, selectedPatientKeys),
  isCampaignArchived: _broadcast.selectIsCampaignArchived.call(void 0, state, campaignKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(BulkControls)
