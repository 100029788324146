"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/ToolBar/AddMembersBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Form = require('web/components/Form');





const AddMembersBtn = ({ onClick }) => (
  _react2.default.createElement(_Form.Button, {
    onClick: onClick,
    className: "settings--org-members--add-member-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
  
    , _react2.default.createElement('img', { src: "/assets/icons/add-person-white.svg", alt: "+", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}} )
    , _react2.default.createElement('span', { className: "blmh-under-grid-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, "Add members" )
  )
)

exports. default = AddMembersBtn
