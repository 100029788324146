"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/BusyReplyToggleSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');
var _components = require('web/screens/Directory/TeamPage/components');






const ToggleSection = ({ isEnabled, onChange }) => (
  _react2.default.createElement(_components.GenericSection, { title: "In-Office Reply" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
    , _react2.default.createElement(_Form.Toggle, {
      isChecked: isEnabled,
      onChange: onChange,
      label: `${isEnabled ? 'Auto-reply' : 'Do not auto-reply'} to SMS messages received during business hours.`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    )
  )
)

exports. default = ToggleSection
