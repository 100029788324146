"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _uuid = require('shared/services/uuid');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

const initialState = {}

let patientConversations = {}

 function patientConversationsReducer (state = initialState, action, globalState) {
  switch (action.type) {
    case _actionCreators2.default.loginSuccessAction.type:
    /* patient-ui */
    case _actionCreators2.default.loadPatientConversationByCodeActions.SUCCESS.type:
    case _actionCreators2.default.getPatientConversationMessagesActions.SUCCESS.type: {
      const pc = action.payload.PatientConversations || {}
      const patients = action.payload.Patients || {}
      patientConversations = Object.values(pc)
        .map(c => addPatientConversationProps(c, patients))
        .reduce((acc, c) => ({ ...acc, [c.ConversationKey]: c }), {})
      return {
        ...state,
        ...patientConversations,
      }
    }

    case _actionCreators2.default.incomingPatientMessageNotificationAction.type: {
      const completed = action.payload.FromPatientKey === _uuid.EMPTY_BLUID && _optionalChain([state, 'access', _ => _[action.payload.ConversationKey], 'optionalAccess', _2 => _2.Completed]) /* patient message un-completes a convo */
      const conversation = state[action.payload.ConversationKey]
      if (!conversation) return state

      const updatedConvo = {
        ...conversation,
        Completed: completed,
        MessageSequence: action.payload.MessageSequence,
        WasLastMessageFromPatient: action.payload.FromPatientKey !== _uuid.EMPTY_BLUID,
        LastUpdatedAt: action.payload.SentTimestamp,
      }
      return {
        ...state,
        [action.payload.ConversationKey]: addPatientConversationProps(updatedConvo, globalState.patients),
      }
    }
    case _actionCreators2.default.logoutAction.type:
      return initialState
  }

  return state
} exports.default = patientConversationsReducer;

const addPatientConversationProps = (conversation, allPatients) => {
  const patient = getPatient(conversation, allPatients)
  return {
    ...conversation,
    Title: getConversationName(conversation, patient),
    Description: getConversationDescription(conversation, patient),
    Notify: !conversation.Completed && conversation.WasLastMessageFromPatient,
  }
}

const getPatient = (conversation, allPatients) => {
  if (conversation.Patients && Object.keys(conversation.Patients).length > 0) {
    const patientKey = Object.keys(conversation.Patients)[0]
    return allPatients[patientKey]
  }
}

const getConversationName = (conversation, patient) => {
  return patient && patient.Name && patient.Name.length > 0 ? patient.Name : "Unknown Patient"
}

const getConversationDescription = (conversation, patient) => {
  if (conversation.Name) {
    return conversation.Name
  }

  if (conversation.ConversationType === 'secure') {
    return "A Secure Chat"
  } else if (patient && patient.Phone) {
    return patient.Phone
  }

  return _moment2.default.call(void 0, conversation.LastUpdatedAt).calendar()
}
