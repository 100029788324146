"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _lodash = require('lodash');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _utils = require('shared/utils');
var _videoRoom = require('web/actions/videoRoom');
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);

const sessions = {}

 class VideoRoomSession {
  __init() {this.RoomKey = ''}
  __init2() {this.RoomUserKey = ''}
  __init3() {this.Approved = false}
  __init4() {this.hasSetupStarted = false}
  __init5() {this.setupPromise = new (0, _utils.Deferred)()}
  __init6() {this.pingingInterval = null}
  __init7() {this.isActive = true}
  __init8() {this.isJoined = false}

  static __initStatic() {this.findByRoomKey = roomKey => sessions[roomKey]}
  static __initStatic2() {this.resolveSetupSession = async roomKey => {
    const session = sessions[roomKey] || new VideoRoomSession(roomKey)

    if (!session.hasSetupStarted) session.startSetup()
    await session.setupPromise.promise
    return session
  }}

  constructor (RoomKey) {;VideoRoomSession.prototype.__init.call(this);VideoRoomSession.prototype.__init2.call(this);VideoRoomSession.prototype.__init3.call(this);VideoRoomSession.prototype.__init4.call(this);VideoRoomSession.prototype.__init5.call(this);VideoRoomSession.prototype.__init6.call(this);VideoRoomSession.prototype.__init7.call(this);VideoRoomSession.prototype.__init8.call(this);VideoRoomSession.prototype.__init9.call(this);VideoRoomSession.prototype.__init10.call(this);VideoRoomSession.prototype.__init11.call(this);VideoRoomSession.prototype.__init12.call(this);VideoRoomSession.prototype.__init13.call(this);VideoRoomSession.prototype.__init14.call(this);VideoRoomSession.prototype.__init15.call(this);VideoRoomSession.prototype.__init16.call(this);
    this.RoomKey = RoomKey
    sessions[RoomKey] = this
  }

  __init9() {this.startPinging = () => {
    this.pingingInterval = setInterval(this.ping, 7000)
    this.ping()
  }}

  __init10() {this.ping = () => this.pingingInterval && this.isActive && this.RoomUserKey
    ? _actions2.default.pingVideoRoom({ RoomUserKey: this.RoomUserKey }).catch(this.stopPinging)
    : this.stopPinging()}

  __init11() {this.stopPinging = () => {
    clearInterval(this.pingingInterval)
    this.pingingInterval = null
  }}

  __init12() {this.startSetup = async ({ RoomUserName } = {}) => {
    if (!this.isActive) return null

    this.hasSetupStarted = true
    const user = await _actions2.default.startVideoRoomUserSetup({
      RoomUserName,
      RoomKey: this.RoomKey,
    }).catch(() => ({}))

    // `Leave` may have been called while awaiting
    if (this.setupPromise.isRejected) return null

    this.RoomUserKey = user.RoomUserKey
    this.Approved = user.Approved
    this.startPinging()
    this.setupPromise.resolve()

    return user
  }}

  __init13() {this.joinAttempt = 0}
  __init14() {this.join = async ({ RoomUserName } = {}) => {
    await this.setupPromise.promise
    if (this.setupPromise.isRejected || !this.isActive) { return null }

    const { Approved, RoomUserKey, RoomKey } = this
    if (!Approved) {
      const [authorization, requestError] =
        await _utils.safePromise.call(void 0, _actions2.default.requestVideoRoomAuthorization)({
          RoomKey,
          RoomAuthorizationUserName: RoomUserName,
        })

      if (!this.isActive) return null
      if (requestError) {
        return Promise.reject(
          new Error(_videoRoom.videoRoomJoinErrorCodes.AUTHORIZATION_REQUEST_ERROR),
        )
      }
      this.Approved = authorization.Approved
      if (!authorization.Approved) {
        const [, approvalError] = await _utils.safePromise.call(void 0, 
          () => new Promise((resolve, reject) =>
            _bus2.default.once(
              _videoRoom.types.UPDATE_VIDEO_ROOM_PENDING_USER,
              ({ payload }) => payload.Approved
                ? resolve()
                : reject(new Error(_videoRoom.videoRoomJoinErrorCodes.ACCESS_DENIED_ERROR)),
            ),
          ),
        )()

        if (!this.isActive) return null
        if (approvalError) return Promise.reject(approvalError)
      }
    }

    const [roomMetadata, joinError] = await _utils.safePromise.call(void 0, _actions2.default.accessVideoRoom)({
      RoomKey,
      RoomUserName,
      ...(RoomUserKey && { RoomUserKey }),
    })

    if (!this.isActive) return null
    if (joinError) {
      if (this.joinAttempt >= 1) return Promise.reject(joinError)
      this.RoomUserKey = null
      this.Approved = false
      this.joinAttempt++
      return this.join({ RoomUserName })
    }
    this.joinAttempt = 0
    this.isJoined = true

    const { ExternalRoomKey, ExternalRoomToken } = roomMetadata
    return { ExternalRoomKey, ExternalRoomToken }
  }}

  __init15() {this.leave = () => {
    if (!this.isActive) return null
    this.isActive = false
    this.stopPinging()
    this.setupPromise.reject()
    this.setupPromise = new (0, _utils.Deferred)()
    if (this.RoomKey && this.RoomUserKey && this.isJoined) {
      _actions2.default.disconnectCurrentVideoRoomUser({
        RoomKey: this.RoomKey,
        RoomUserKey: this.RoomUserKey,
      })
    }
    this.RoomUserKey = ''
    delete sessions[this.RoomKey]
  }}

  __init16() {this.publishProblem = _lodash.throttle.call(void 0, e => e &&
    this.isActive &&
    this.setupPromise.promise.then(
      () =>
        _actions2.default.publishProblem({
          ProblemCode: e.origin,
          RoomKey: this.RoomKey,
          RoomUserKey: this.RoomUserKey,
        }),
    )
  , 3000,
  )}
} VideoRoomSession.__initStatic(); VideoRoomSession.__initStatic2(); exports.default = VideoRoomSession;
