"use strict";Object.defineProperty(exports, "__esModule", {value: true}); const EmailRegex = /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/; exports.EmailRegex = EmailRegex

 const PhoneRegex = /(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?/; exports.PhoneRegex = PhoneRegex

 const UrlRegex = /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/; exports.UrlRegex = UrlRegex

 const FullTextEmailRegex = new RegExp(exports.EmailRegex, 'g'); exports.FullTextEmailRegex = FullTextEmailRegex

 const FullTextPhoneRegex = new RegExp(exports.PhoneRegex, 'g'); exports.FullTextPhoneRegex = FullTextPhoneRegex

 const FullTextUrlRegex = new RegExp(exports.UrlRegex, 'g'); exports.FullTextUrlRegex = FullTextUrlRegex

 const FullTextPhoneEmailOrUrl = new RegExp('(' + exports.UrlRegex.source + '|' + exports.PhoneRegex.source + '|' + exports.EmailRegex.source + ')', 'g'); exports.FullTextPhoneEmailOrUrl = FullTextPhoneEmailOrUrl

 const USPhoneRegex = /(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}/; exports.USPhoneRegex = USPhoneRegex
 const FullTextUSPhoneRegex = new RegExp(exports.USPhoneRegex, 'g'); exports.FullTextUSPhoneRegex = FullTextUSPhoneRegex
