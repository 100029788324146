"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/VideoFeed.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _lodash = require('lodash');
var _log = require('shared/log');

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

 class VideoFeed extends _react2.default.PureComponent {constructor(...args) { super(...args); VideoFeed.prototype.__init.call(this);VideoFeed.prototype.__init2.call(this);VideoFeed.prototype.__init3.call(this);VideoFeed.prototype.__init4.call(this);VideoFeed.prototype.__init5.call(this);VideoFeed.prototype.__init6.call(this);VideoFeed.prototype.__init7.call(this);VideoFeed.prototype.__init8.call(this);VideoFeed.prototype.__init9.call(this);VideoFeed.prototype.__init10.call(this);VideoFeed.prototype.__init11.call(this); }
  __init() {this.state = { shouldCover: false }}

  __init2() {this.rootRef = null}
  __init3() {this.setRootRef = r => this.rootRef = r}

  __init4() {this.updateStreamSrc = () => {
    const { stream } = this.props
    this.cleanup()
    if (this.videoDOM && stream) {
      this.videoDOM.srcObject = stream
      const played = this.videoDOM.play()
      if (played !== undefined) {
        played.catch(e => _log.log.info("Couldn't play video", e))
      }
    }
  }}

  __init5() {this.initStreamPlayback = r => {
    this.videoDOM = r
    this.updateStreamSrc()
  }}

  __init6() {this.componentDidUpdate = nextProps => {
    this.updateShouldCover()
    if (nextProps.stream !== this.props.stream) { this.updateStreamSrc() }
  }}

  __init7() {this.updateShouldCover = () => {
    const { stream } = this.props
    if (!stream || !this.rootRef) return
    const track = stream.getTracks()[0]
    if (!track) return
    const trackSettings = track.getSettings()

    const containerBounds = this.rootRef.getBoundingClientRect()
    const containerWidth = containerBounds.right - containerBounds.left
    const containerHeight = containerBounds.bottom - containerBounds.top

    const containerRatio = containerWidth / containerHeight
    const trackRatio = trackSettings.width / trackSettings.height

    this.setState({ shouldCover: Math.abs(trackRatio - containerRatio) < 0.5 })
  }}

  __init8() {this.debouncedUpdateShouldCover = _lodash.debounce.call(void 0, this.updateShouldCover, 200)}
  __init9() {this.componentDidMount = () => {
    window.addEventListener('resize', this.debouncedUpdateShouldCover)
    this.updateShouldCover()
  }}

  __init10() {this.cleanup = () => {
    if (!this.videoDOM) return
    this.videoDOM.src = ''
    this.videoDOM.load()
  }}

  __init11() {this.componentWillUnmount = () => {
    this.cleanup()
    window.removeEventListener('resize', this.debouncedUpdateShouldCover)
  }}

  render () {
    const {
      className,
      wrapperClassName,
      isVideoOn,
      isCover,
      shouldMirror,
    } = this.props
    const { shouldCover } = this.state

    const rootClassName = _classBuilder2.default.call(void 0, 
      className,
      { cover: isCover || shouldCover },
      { mirrored: shouldMirror },
      'meeting--video-feed',
    )
    const containerClassName = _classBuilder2.default.call(void 0, 
      wrapperClassName,
      "meeting--video-feed--wrapper",
    )

    return (
      _react2.default.createElement('div', { ref: this.setRootRef, className: containerClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
        ,  isVideoOn
          ? _react2.default.createElement('video', {
              playsInline: true,
              autoPlay: true,
              muted: true,
              className: rootClassName,
              ref: this.initStreamPlayback, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
            )
          : _react2.default.createElement('div', { className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}} )
        
      )
    )
  }

  static __initStatic() {this.defaultProps = {
    isVideoOn: true,
    shouldMirror: false,
  }}

  static __initStatic2() {this.propTypes = {
    stream: _proptypes2.default.any,
    isVideoOn: _proptypes2.default.bool,
    shouldMirror: _proptypes2.default.bool,
  }}
} VideoFeed.__initStatic(); VideoFeed.__initStatic2(); exports.default = VideoFeed;
