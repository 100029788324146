"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoChat/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _opentokreact = require('opentok-react');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _log = require('shared/log');
var _reactredux = require('react-redux');
var _user = require('shared/selectors/user');
var _VideoSettingsModal = require('./VideoSettingsModal'); var _VideoSettingsModal2 = _interopRequireDefault(_VideoSettingsModal);
var _Spinner = require('../Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _detectrtc = require('detectrtc'); var _detectrtc2 = _interopRequireDefault(_detectrtc);
var _errors = require('shared/errors');
var _AnonymousPage = require('web/components/AnonymousPage');

const missingPermissionsError = "Missing Permissions"

function hasPermissions () {
  return new Promise(resolve => {
    _detectrtc2.default.load(() => {
      const hasMic = _detectrtc2.default.isWebsiteHasMicrophonePermissions
      const hasVideo = _detectrtc2.default.isWebsiteHasWebcamPermissions
      resolve({ hasMic, hasVideo })
    })
  })
}

const reconnectAction = { label: 'Reconnect', onClick: () => location.reload() }
const Hangup = () => (
  _react2.default.createElement(_AnonymousPage.PageWrapper, { className: "video-chat--error-page", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    , _react2.default.createElement(_AnonymousPage.ActionResult, {
      title: "Success",
      subtitle: "You successfully disconnected from your video chat."      ,
      action: reconnectAction, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
    )
  )
)

const BadKeyError = () => (
  _react2.default.createElement(_AnonymousPage.PageWrapper, { className: "video-chat--error-page", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
    , _react2.default.createElement(_AnonymousPage.ActionResult, {
      title: "404",
      subtitle: 
        _react2.default.createElement(_react2.default.Fragment, null, "Sorry, this link doesn't seem to lead anywhere..."

          , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} ), "Please try another link or contact us at "
                  , ' '
          , _react2.default.createElement('a', { href: "mailto:support@bloomtext.com", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, "support@bloomtext.com")
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
    )
  )
)

const PermissionError = () => (
  _react2.default.createElement(_AnonymousPage.PageWrapper, { className: "video-chat--error-page", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
    , _react2.default.createElement(_AnonymousPage.ActionResult, {
      title: "Permissions required" ,
      subtitle: 
        _react2.default.createElement(_react2.default.Fragment, null, "Sorry, we don't have permission to use your camera and microphone. Please update your browser permissions."

          , _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} ), "Contact us at "
             , _react2.default.createElement('a', { href: "mailto:support@bloomtext.com", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "support@bloomtext.com"), ' ', "or call our support line "
               , _react2.default.createElement('a', { href: "tel:+14159031200", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}, "(415) 903-1200" ), " if you have any questions."
        )
      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
    )
  )
)

class VideoChat extends _react.Component {
  constructor (props) {
    super(props);VideoChat.prototype.__init.call(this);VideoChat.prototype.__init2.call(this);VideoChat.prototype.__init3.call(this);VideoChat.prototype.__init4.call(this);VideoChat.prototype.__init5.call(this);VideoChat.prototype.__init6.call(this);VideoChat.prototype.__init7.call(this);VideoChat.prototype.__init8.call(this);VideoChat.prototype.__init9.call(this);VideoChat.prototype.__init10.call(this);VideoChat.prototype.__init11.call(this);

    this.state = {
      TokBoxSessionId: null,
      TokBoxApiKey: null,
      TokBoxToken: null,
      streams: [],
      video: true,
      audio: true,
      badKey: false,
      hangup: false,
      currentCamera: 0,
      currentMic: 0,
      availableCameras: [],
      availableMics: [],
      settingsOpen: false,
      finishedLoadingDevices: false,

      permissionsDenied: false,

    }
  }

  componentDidMount () {
    let TokBoxSessionId = null
    let TokBoxApiKey = null
    let TokBoxToken = null

    _client2.default.joinVideoChat(this.props.videoKey, this.props.currentUser.UserKey || this.props.userKey || '')
      .then((res) => {
        TokBoxSessionId = res.TokBoxSessionId
        TokBoxApiKey = res.TokBoxApiKey
        TokBoxToken = res.TokBoxToken
        this.subs = {}
        return _opentokreact.createSession.call(void 0, {
          apiKey: TokBoxApiKey,
          sessionId: TokBoxSessionId,
          token: TokBoxToken,
          onStreamsUpdated: (s) => this.setState({ streams: s }),
        })
      })
      .then((result) => {
        this.sessionHelper = result
        return this.setState({
          TokBoxApiKey: TokBoxApiKey,
          TokBoxSessionId: TokBoxSessionId,
          TokBoxToken: TokBoxToken,
        })
      })
      .catch((e) => {
        if (e === missingPermissionsError) { // TODO where is this error thrown?
          return this.setState({
            missingPermissions: true,
          })
        }
        if (e instanceof _errors.RestError && !e.response.ServerError) {
          return this.setState({
            badKey: true,
          })
        }
        _log.log.error(e)
      })

    this.getDevices()
  }

  __init() {this.getDevices = () => {
    hasPermissions().then(perms => {
      OT.getDevices((error, devices) => {
        if (error) {
          _log.log.error(error)
          return
        }

        const videoIds = []
        const audioIds = []
        devices.forEach((d) => {
          // The default one is listed twice, so just skip it
          if (d.deviceId === 'default') {
            return
          }

          if (d.kind === 'videoInput') {
            videoIds.push({
              id: d.deviceId,
              name: d.label,
            })
          } else if (d.kind === 'audioInput') {
            audioIds.push({
              id: d.deviceId,
              name: d.label,
            })
          }
        })
        this.setState({
          availableCameras: videoIds,
          availableMics: audioIds,
          finishedLoadingDevices: true,
        })
      })
    })
  }}

  __init2() {this.disconnect = () => {
    this.sessionHelper.disconnect()
  }}

  __init3() {this.hangup = () => {
    this.disconnect()
    this.setState({
      hangup: true,
    })
  }}

  componentWillUnmount () {
    this.disconnect()
  }

  __init4() {this.swapCamera = () => {
    let nextCamera = this.state.currentCamera + 1
    if (nextCamera >= this.state.availableCameras.length) {
      nextCamera = 0
    }
    this.changeVideo(nextCamera)
  }}

  __init5() {this.showSettings = () => {
    this.setState({
      settingsOpen: true,
    })
  }}

  __init6() {this.closeSettings = () => {
    this.setState({
      settingsOpen: false,
    })
  }}

  __init7() {this.toggleAudio = () => {
    this.setState({ audio: !this.state.audio })
  }}

  __init8() {this.changeAudio = (idx) => {
    this.setState({
      currentMic: idx,
      finishedLoadingDevices: false,
    })
    this.getDevices()
  }}

  __init9() {this.changeVideo = (idx) => {
    this.setState({
      currentCamera: idx,
      finishedLoadingDevices: false,
    })
    this.getDevices()
  }}

  __init10() {this.toggleVideo = () => {
    this.setState({ video: !this.state.video })
  }}

  render () {
    if (this.state.permissionsDenied) {
      return _react2.default.createElement(PermissionError, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 235}})
    }

    if (this.state.badKey) {
      return _react2.default.createElement(BadKeyError, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 239}} )
    }

    if (this.state.hangup) {
      return _react2.default.createElement(Hangup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 243}} )
    }

    if (!this.state.TokBoxSessionId || !this.state.TokBoxApiKey || !this.state.TokBoxToken || !this.sessionHelper || !this.state.finishedLoadingDevices) {
      return null
    }

    let subscribers = (
      _react2.default.createElement('div', { className: "video-chat--loading-message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 251}}
        , _react2.default.createElement(_Spinner2.default, { color: "#fff", __self: this, __source: {fileName: _jsxFileName, lineNumber: 252}}), " Waiting for other user to join"

      )
    )

    const numSubs = this.sessionHelper.streams.length
    if (numSubs > 0) {
      // Just support 1:1 chat for now
      if (numSubs === 1) {
        const stream = this.sessionHelper.streams[0]
        subscribers = _react2.default.createElement(_opentokreact.OTSubscriber, { key: stream.id, session: this.sessionHelper.session, stream: stream, properties: {
          width: '100%',
          height: '100%',
          showControls: false,
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}})
      } else {
        let h = 1
        let w = 0
        if (this.topRef) {
          h = this.topRef.clientHeight
          w = this.topRef.clientWidth
        }

        let subWidth = '100vw'
        let subHeight = '100vh'
        // Taller than high
        if (h > w) {
          subHeight = Math.floor(100 / numSubs) + 'vh'
        } else {
          subWidth = Math.floor(100 / numSubs) + 'vw'
        }

        subscribers = this.sessionHelper.streams.map((stream, i) => (
          _react2.default.createElement(_opentokreact.OTSubscriber, { key: stream.id, session: this.sessionHelper.session, stream: stream, properties: {
            width: subWidth,
            height: subHeight,
            showControls: false,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 285}})
        ))
        subscribers = (
          _react2.default.createElement('div', { className: "video-chat-subscriber-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 292}}
            ,  subscribers 
          )
        )
      }
    }

    const publishedProps = {
      showControls: false,
      width: '120px',
      height: '150px',
    }

    if (this.state.availableCameras.length > 0 && this.state.video) {
      publishedProps.publishVideo = true
      publishedProps.videoSource = this.state.availableCameras[this.state.currentCamera].id
    } else {
      publishedProps.publishVideo = false
    }

    if (this.state.availableMics.length > 0 && this.state.audio) {
      publishedProps.publishAudio = true
      publishedProps.audioSource = this.state.availableMics[this.state.currentMic].id
    } else {
      publishedProps.publishAudio = false
    }

    const publisherComponent = (
      _react2.default.createElement(_opentokreact.OTPublisher, {
         session: this.sessionHelper.session,
         properties: publishedProps,
         onError: this.onPublishedError, __self: this, __source: {fileName: _jsxFileName, lineNumber: 320}})
    )

    return (
      _react2.default.createElement('div', { className: "video-chat", ref: (e) => this.topRef = e, __self: this, __source: {fileName: _jsxFileName, lineNumber: 327}}
        , _react2.default.createElement(_VideoSettingsModal2.default, { open: this.state.settingsOpen,
                            onClose: this.closeSettings,
                            cameras: this.state.availableCameras,
                            mics: this.state.availableMics,
                            currentCamera: this.state.currentCamera,
                            currentMic: this.state.currentMic,
                            onChangeVideo: this.changeVideo,
                            onChangeAudio: this.changeAudio, __self: this, __source: {fileName: _jsxFileName, lineNumber: 328}} )
        , _react2.default.createElement('div', { className: "video-chat-controls-top", __self: this, __source: {fileName: _jsxFileName, lineNumber: 336}}
          , _react2.default.createElement('div', { className: 'video-chat-controls--swap-camera ' + (this.state.availableCameras.length > 1 ? '' : 'disabled'), onClick: this.swapCamera, __self: this, __source: {fileName: _jsxFileName, lineNumber: 337}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 338}}, "swap_horiz")
          )
          , _react2.default.createElement('div', { className: "video-chat-controls--settings", onClick: this.showSettings, __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 341}}, "settings")
          )
        )
        ,  subscribers 
        ,  publisherComponent 
        , _react2.default.createElement('div', { className: "video-chat-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}
          , _react2.default.createElement('div', { className: 'video-chat-controls--audio ' + (this.state.audio ? '' : 'disabled'), onClick: this.toggleAudio, __self: this, __source: {fileName: _jsxFileName, lineNumber: 347}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 348}}, "mic")
          )
          , _react2.default.createElement('div', { className: 'video-chat-controls--end-call', onClick: this.hangup, __self: this, __source: {fileName: _jsxFileName, lineNumber: 350}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 351}}, "call_end")
          )
          , _react2.default.createElement('div', { className: 'video-chat-controls--video ' + (this.state.video ? '' : 'disabled'), onClick: this.toggleVideo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 353}}
            , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 354}}, "videocam")
          )
        )
      )
    )
  }

  __init11() {this.onPublishedError = (err) => {
    if (err.name.indexOf("ACCESS_DENIED") > -1) {
      this.setState({
        permissionsDenied: true,
      })
    }
    _log.log.info("Published error", err)
  }}
}

VideoChat.propTypes = {
  videoKey: _proptypes2.default.string.isRequired, /* a bloom specific UUID */
}

const mapState = state => ({ currentUser: _user.selectCurrentUser.call(void 0, state) })

exports. default = _reactredux.connect.call(void 0, mapState)(_opentokreact.preloadScript.call(void 0, VideoChat))
