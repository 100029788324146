"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Section/InputSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _InputSectionWrapper = require('./InputSectionWrapper'); var _InputSectionWrapper2 = _interopRequireDefault(_InputSectionWrapper);
var _TextInput = require('web/components/Form/TextInput'); var _TextInput2 = _interopRequireDefault(_TextInput);















const InputSection = ({
  inputProps,
  inputType = 'text',
  errorMessage,
  title,
  desc,
  placeholder,
  autoFocus,
  autoComplete,
  isDisabled,
  isOptional,
  className,
}) => (
  _react2.default.createElement(_InputSectionWrapper2.default, {
    className: className,
    isDisabled: isDisabled,
    title: title,
    desc: desc,
    errorMessage: errorMessage,
    isOptional: isOptional, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
  
    , _react2.default.createElement(_TextInput2.default, {
      ...inputProps,
      className: "blm-input-section--input",
      isDisabled: isDisabled,
      hasError: !!errorMessage,
      placeholder: placeholder,
      type: inputType,
      autoFocus: autoFocus,
      autoComplete: autoComplete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
    )
  )
)

exports. default = InputSection
