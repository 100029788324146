"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');








var _broadcast = require('shared/selectors/broadcast');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _RightSide = require('web/components/RightSide');
var _Modals = require('web/screens/Broadcast/Modals');
var _Controls = require('./Controls'); var _Controls2 = _interopRequireDefault(_Controls);
var _MessagesTable = require('./MessagesTable'); var _MessagesTable2 = _interopRequireDefault(_MessagesTable);











const CampaignPageBody = ({
  campaignKey,
  orgKey,
  onStartCampaign,
  onPauseCampaign,
  onAddPatients,
  onRemovePatients,
  onQueueMessages,
  onDequeuePatients,
  isCampaignArchived,
}) => {
  const {
    isOpen: isAddPatientsModalOpen,
    onOpen: onOpenAddPatientsModal,
    onClose: onCloseAddPatientsModal,
  } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_RightSide.RightSideBody, { className: "campaign-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , _react2.default.createElement(_Controls2.default, {
        campaignKey: campaignKey,
        isCampaignArchived: isCampaignArchived,
        onStartCampaign: onStartCampaign,
        onPauseCampaign: onPauseCampaign,
        onQueueMessages: onQueueMessages,
        onAddPatients: onOpenAddPatientsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
      , _react2.default.createElement(_MessagesTable2.default, {
        campaignKey: campaignKey,
        onAddPatients: onOpenAddPatientsModal,
        onRemovePatients: onRemovePatients,
        onDequeuePatients: onDequeuePatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      )
      , _react2.default.createElement(_Modals.AddPatientsModal, {
        isOpen: isAddPatientsModalOpen && !!orgKey,
        onClose: onCloseAddPatientsModal,
        orgKey: orgKey,
        onAddPatients: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      )
    )
  )
}






const mapState = (state, { campaignKey }) => ({
  orgKey: _broadcast.selectCampaignOrgKey.call(void 0, state, campaignKey),
  isCampaignArchived: _broadcast.selectIsCampaignArchived.call(void 0, state, campaignKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CampaignPageBody)
