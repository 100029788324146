"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Super/SuperUserTeamsTable.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _SuperTableTR = require('web/components/Super/SuperTableTR'); var _SuperTableTR2 = _interopRequireDefault(_SuperTableTR);

 class SuperUserTeamsTable extends _react.Component {
  render () {
    const members = Object.values(this.props.teams).map((m) => {
      return (
        _react2.default.createElement(_SuperTableTR2.default, { key: m.TeamKey, to: `/super/teams/${m.TeamKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
          , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}}, m.TeamKey)
          , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}, m.Inboxable ? 'yes' : 'no')
          , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, m.OverrideNotificationSettings ? 'yes' : 'no')
          , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, m.VipNotifications ? 'yes' : 'no')
          , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, m.MuteForever ? 'yes' : 'no')
          , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, m.MuteUntil)
          , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, m.Removed ? 'yes' : 'no')
        )
      )
    })

    return (
      _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          , _react2.default.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "Team Key" )
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, "Inboxable")
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "Override Notifications" )
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, "VIP Notifications" )
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Mute Forever" )
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Mute Until" )
            , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Removed")
          )
        )
        , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          , members
        )
      )
    )
  }
} exports.default = SuperUserTeamsTable;
