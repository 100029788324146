"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ErrorModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

const reload = () =>
  window.location.reload()







const ErrorModal = ({
  isLoading,
  onBtnClick = reload,
  btnLabel = 'OK',
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: true,
    title: "Oh snap!" ,
    className: "error-modal",
    size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
  
    , _react2.default.createElement('div', { className: "error-modal--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      , _react2.default.createElement('span', { className: "error-modal--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, "Something went wrong while processing your request. The incident has been reported. Try again, or contact "


            , _react2.default.createElement('a', { href: "mailto:support@bloomtext.com", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "support@bloomtext.com"), "."
      )
      , _react2.default.createElement(_Form.Button, {
        isLoading: isLoading,
        isDisabled: isLoading,
        onClick: onBtnClick,
        className: "error-modal--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      
        ,  btnLabel 
      )
    )
  )
)

exports. default = ErrorModal
