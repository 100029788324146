"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/CreateNewVideo.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');
var _videoChat = require('shared/validation/createNew/videoChat');

var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);
var _Form = require('web/components/Form');






const CreateNewVideo = ({
  myUserKey,
  visible,
  hasVideoFeature,
}) => {
  const { register, handleSubmit, formState } = _reacthookform.useForm.call(void 0, {
    resolver: _videoChat.videoChatResolver,
    mode: 'onChange',
    defaultValues: { email: '', phone: '' },
  })
  const { isValid, isSubmitting, isDirty, errors } = formState

  const prepareVideoLink = _react.useCallback.call(void 0, 
    key => {
      const link = "/#/video/" + key
      return myUserKey ? link + "/" + myUserKey : link
    },
    [myUserKey],
  )

  const [videoKey, setVideoKey] = _react.useState.call(void 0, '')
  const onSubmit = _react.useCallback.call(void 0, 
    ({ email, phone }) => _actions2.default.startVideoChat()
      .then(({ VideoKey }) => {
        setVideoKey(VideoKey)
        window.open(prepareVideoLink(VideoKey), '_blank')

        if (phone) return _actions2.default.inviteVideoChatSms({ VideoKey, ToNumber: phone })
        if (email) return _actions2.default.inviteVideoChatEmail({ VideoKey, ToEmail: email })
      })
      .catch(() => null),
    [setVideoKey, prepareVideoLink],
  )

  if (!visible) return null

  if (videoKey) {
    return (
      _react2.default.createElement('div', { className: "createnew", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        , _react2.default.createElement('div', { className: "createnew-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          , _react2.default.createElement('div', { className: "create-new--description", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "We started a video chat and invited all participants."
                    , ' ', "If the video call didn't already open in a new window,"
                      , ' ', "or if you need to rejoin the call, click the button below to open it."

          )
          , _react2.default.createElement('div', { className: "create-new--btn-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
            , _react2.default.createElement('a', {
              className: "blmh-button purple highlight"  ,
              href: prepareVideoLink(videoKey),
              target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            , "Open"

            )
          )
        )
      )
    )
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  !hasVideoFeature &&
        _react2.default.createElement(_WarningBar2.default, {
          className: "directory--create-new--warning",
          text: "Request Video Telehealth feature to get full access to video features"          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
        )
      
      , _react2.default.createElement('div', { className: "create-new--description", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, "Start a video conversation and invite another to join via their email, phone, or both."

      )
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "Phone",
        placeholder: "E.g. (415) 903-1200"  ,
        inputProps: register('phone'),
        errorMessage: _optionalChain([errors, 'access', _ => _.phone, 'optionalAccess', _2 => _2.message]),
        isDisabled: !hasVideoFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      )
      , _react2.default.createElement(_Form.InputSection, {
        title: "Email",
        placeholder: "E.g. john@email.com" ,
        inputProps: register('email'),
        errorMessage: _optionalChain([errors, 'access', _3 => _3.email, 'optionalAccess', _4 => _4.message]),
        isDisabled: !hasVideoFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
      )

      , _react2.default.createElement('div', { className: "create-new--btn-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
        , _react2.default.createElement(_Form.Button, {
          onClick: handleSubmit(onSubmit),
          isDisabled: !isValid || !isDirty,
          isLoading: isSubmitting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
        , "Invite"

        )
      )
    )
  )
}





const mapState = (state) => ({ myUserKey: _user.selectCurrentUserKey.call(void 0, state) })
exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewVideo)
