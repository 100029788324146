"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/ResultsList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





var _Tooltip = require('web/components/Tooltip');
var _ResultsSection = require('./ResultsSection'); var _ResultsSection2 = _interopRequireDefault(_ResultsSection);
var _InviteContactSection = require('./InviteContactSection'); var _InviteContactSection2 = _interopRequireDefault(_InviteContactSection);
var _ResultsStatus = require('./ResultsStatus'); var _ResultsStatus2 = _interopRequireDefault(_ResultsStatus);



















const preventDefault = e => e.preventDefault()

const ResultsList = ({
  isOpen,
  internalEntitiesList,
  externalEntitiesList,
  patientEntitiesList,
  onSelectEntity,
  searchQuery,
  searchContactType,
  isLoadingResults,
  orgName,
  setContactRelType,
  canAddUsers,
  canAddPatients,
  canInviteCoworkers,
  canInviteCollaborators,
  searchInputRef,
}) => {
  const hasAnyResults = !!(
    internalEntitiesList.length +
    externalEntitiesList.length +
    patientEntitiesList.length
  )

  const hasExactContact = searchContactType && (
    internalEntitiesList.length
    || externalEntitiesList.length
    || patientEntitiesList.length
  )

  const hasInviteSection = !hasExactContact && (
    canAddPatients
    || canInviteCoworkers
    || canInviteCollaborators
  )

  if (!hasAnyResults && !isLoadingResults && !hasInviteSection) return null

  const content = (
    _react2.default.createElement('div', { className: "participants-picker--results-wrapper", onMouseDown: preventDefault, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      , _react2.default.createElement(_ResultsSection2.default, {
        entitiesList: internalEntitiesList,
        onSelectEntity: onSelectEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      )
      , _react2.default.createElement(_ResultsSection2.default, {
        title: "External collaborators" ,
        entitiesList: externalEntitiesList,
        onSelectEntity: onSelectEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
      )
      , _react2.default.createElement(_ResultsSection2.default, {
        title: "Patients",
        entitiesList: patientEntitiesList,
        onSelectEntity: onSelectEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      )
      , _react2.default.createElement(_ResultsStatus2.default, {
        searchQuery: searchQuery,
        hasAnyResults: hasAnyResults,
        isLoading: isLoadingResults, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
      )
      ,  hasAnyResults && hasInviteSection && (
        _react2.default.createElement('div', { className: "participants-picker--results--hairline", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}} )
      )
      ,  hasInviteSection && (
        _react2.default.createElement(_InviteContactSection2.default, {
          contactType: searchContactType,
          setContactRelType: setContactRelType,
          orgName: orgName,
          canAddUsers: canAddUsers,
          canInviteCoworkers: canInviteCoworkers,
          canInviteCollaborators: canInviteCollaborators,
          canAddPatients: canAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        )
      )
    )
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      isOpen: isOpen,
      type: "light",
      place: "bottom-left",
      hideArrow: true,
      domTarget: searchInputRef.current,
      className: "participants-picker--results-tooltip",
      content: content, __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
    )
  )
}

exports. default = ResultsList
