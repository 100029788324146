"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/Header.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







var _Header = require('web/components/RightSide/Header');
var _Avatar = require('web/components/Avatar');
var _Tooltip = require('web/components/Tooltip');










const Header = ({
  teamKey,
  title,
  subtitle,
  onBack,
  onStartChat,
  onDelete,
}) => (
  _react2.default.createElement(_Header.RightSideHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
    , _react2.default.createElement(_Header.HeaderMobileBackButton, { onClick: onBack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )
    , _react2.default.createElement(_Avatar.TeamAvatar, { teamKey: teamKey, size: 52, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}} )
    , _react2.default.createElement(_Header.RightSideHeaderHeading, { title: title, subtitle: subtitle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
    , _react2.default.createElement(_Header.HeaderMoreToggle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      , _react2.default.createElement(_Tooltip.TooltipMenuItem, {
        iconSrc: "/assets/icons/header/chat-dark.svg",
        label: "Create a chat"  ,
        onClick: onStartChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
      )
      ,  onDelete && (
        _react2.default.createElement(_Tooltip.TooltipMenuItem, {
          isDestructive: true,
          iconSrc: "/assets/icons/icon-delete-red.svg",
          label: "Delete team" ,
          onClick: onDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
      )
    )
  )
)

exports. default = Header
