"use strict";Object.defineProperty(exports, "__esModule", {value: true});

 const NULL_QUOTA = {}; exports.NULL_QUOTA = NULL_QUOTA

 const REGULAR_CHAT_QUOTA = {
  maxPatientsCount: 0,
}; exports.REGULAR_CHAT_QUOTA = REGULAR_CHAT_QUOTA

 const REGULAR_PATIENT_CHAT_QUOTA = {
  maxPatientsCount: 1,
  maxUsersCount: 0,
  maxTeamsCount: 0,
}; exports.REGULAR_PATIENT_CHAT_QUOTA = REGULAR_PATIENT_CHAT_QUOTA

 const USERS_ONLY_CHAT_QUOTA = {
  maxTeamsCount: 0,
  maxPatientsCount: 0,
}; exports.USERS_ONLY_CHAT_QUOTA = USERS_ONLY_CHAT_QUOTA

 const ALL_QUOTAS_LIST = [
  exports.NULL_QUOTA,
  exports.REGULAR_CHAT_QUOTA,
  exports.REGULAR_PATIENT_CHAT_QUOTA,
  exports.USERS_ONLY_CHAT_QUOTA,
]; exports.ALL_QUOTAS_LIST = ALL_QUOTAS_LIST
