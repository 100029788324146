"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/ConversationBox/hooks/scroll/useBoundaryHitbox.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');
var _guidVerifier = require('shared/services/guidVerifier');
var _lodash = require('lodash');



const BOUNDARY_OFFSET = '-20%'

const isMessageValid = (m) => _guidVerifier.isGUIDValid.call(void 0, m.MessageKey)









const useBoundaryHitbox = ({
  messages,
  onLoadBelow, onLoadAbove,
  getContainerDOM,
  hasSettledScroll,
}) => {
  const handleBoundaryHit = (e) => {
    if (!messages.length) return
    const isBottom = e.previousPosition === _reactwaypoint.Waypoint.below

    const message = isBottom
      ? _lodash.find.call(void 0, messages, isMessageValid)
      : _lodash.findLast.call(void 0, messages, isMessageValid)

    const containerDOM = getContainerDOM()
    if (!message || !containerDOM) return

    if (!isBottom) return onLoadAbove(message)

    const oldHeight = containerDOM.scrollHeight
    return onLoadBelow(message).then(() => {
      if (containerDOM.scrollTop) return

      const newHeight = containerDOM.scrollHeight
      containerDOM.scrollBy({ top: oldHeight - newHeight })
    })
  }

  const shouldRenderBoundaryWaypoint = messages.length && hasSettledScroll
  const renderTopBoundaryWaypoint = () => shouldRenderBoundaryWaypoint
    ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: handleBoundaryHit, topOffset: BOUNDARY_OFFSET, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
    : null
  const renderBottomBoundaryWaypoint = () => shouldRenderBoundaryWaypoint
    ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: handleBoundaryHit, bottomOffset: BOUNDARY_OFFSET, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
    : null

  return {
    renderTopBoundaryWaypoint,
    renderBottomBoundaryWaypoint,
  }
}

exports. default = useBoundaryHitbox
