"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _utils = require('shared/store/utils');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);






const initialState = {
  isDownloadAvailable: false,
  isOutdated: false,
}

exports. default = _utils.createReducer.call(void 0, 
  initialState,
  builder => builder
    .addCase(_actionCreators2.default.downloadAvailableAction, s => { s.isDownloadAvailable = true })
    .addCase(_actionCreators2.default.markAppOutdatedAction, s => { s.isOutdated = true })
    .addDefaultCase(s => s),
)
