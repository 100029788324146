"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');



var _Org = require('./Org'); var _Org2 = _interopRequireDefault(_Org);








const OrgPageRoute = props => {
  const match = _reactrouterdom.useRouteMatch()
  const { tabName = null, orgKey = null } = match.params

  return (
    _react2.default.createElement(_Org2.default, {
      ...props,
      tabName: tabName,
      orgKey: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    )
  )
}

exports. default = OrgPageRoute
