"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _hookForm = require('shared/services/validation/hookForm');




var _joi = require('shared/services/validation/joi'); var _joi2 = _interopRequireDefault(_joi);











const lookupOrgSchema = _joi2.default.object({
  OrgKey: _joi2.default.string(),
  Name: _joi2.default.string(),
})

 const MIN_ORG_NAME_SEARCH_LENGTH = 4; exports.MIN_ORG_NAME_SEARCH_LENGTH = MIN_ORG_NAME_SEARCH_LENGTH

const orgSchema = _joi2.default.object({
  org: _joi2.default.alternatives().try(
    lookupOrgSchema,
    _joi.requiredString.call(void 0, ).min(exports.MIN_ORG_NAME_SEARCH_LENGTH).max(100),
  ).required().messages({
    ..._joi.makeStrErrors.call(void 0, 'Organization name'),
    'string.min': 'Keep typing to search',
  }),
  title: _joi.optionalString.call(void 0, ).max(100).messages(_joi.makeStrErrors.call(void 0, 'Title')),
})


 const orgListValidationSchema = _joi2.default.object({
  orgs: _joi2.default.array().items(orgSchema),
}); exports.orgListValidationSchema = orgListValidationSchema

 const orgResolver = _hookForm.joiResolver.call(void 0, orgSchema); exports.orgResolver = orgResolver
 const orgListResolver = _hookForm.joiResolver.call(void 0, exports.orgListValidationSchema); exports.orgListResolver = orgListResolver
