"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _reactrouterdom = require('react-router-dom');







 const usePreventNavigatingAway = ({
  allowedRoute,
  shouldBlock,
  warningMessage,
}) => {
  const history = _reactrouterdom.useHistory.call(void 0, )

  _react.useEffect.call(void 0, 
    () => history.block(({ pathname }) => {
      if (shouldBlock && !pathname.startsWith(allowedRoute)) {
        return window.confirm(warningMessage) && 'ok'
      }
      return 'ok'
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [shouldBlock, warningMessage, allowedRoute],
  )
}; exports.usePreventNavigatingAway = usePreventNavigatingAway
