"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }









var _store = require('shared/store');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _helpers = require('shared/actions/helpers');
var _utils = require('shared/store/utils');

const getQuickLoginUsersActions = _utils.createAsyncActions('Get Quick Login users')
const setPasswordActions = _utils.createAsyncActions('SetPassword')
const activateQuickLoginActions = _utils.createAsyncActions('ActivateQuickLogin')
const deactivateQuickLoginActions = _utils.createAsyncActions('DeactivateQuickLogin')
const approveQuickLoginDeviceActions = _utils.createAsyncActions('ApproveDeviceForQuickLoginV2')
const disapproveQuickLoginDeviceActions = _utils.createAsyncActions('DisapproveDeviceForQuickLoginV2')

 const actionCreators = {
  getQuickLoginUsersActions,
  activateQuickLoginActions,
  deactivateQuickLoginActions,
  approveQuickLoginDeviceActions,
  disapproveQuickLoginDeviceActions,
}; exports.actionCreators = actionCreators

const setPassword = (req) =>
  _helpers.wsCommand('SetPassword', setPasswordActions, req, null)

const activateQuickLogin = ({ Pin }) =>
  _helpers.wsCommand('ActivateQuickLogin', activateQuickLoginActions, { Pin }, null)
const deactivateQuickLogin = () =>
  _helpers.wsCommand('DeactivateQuickLogin', deactivateQuickLoginActions, null, null)
const approveQuickLoginDevice = () =>
  _helpers.wsCommand('ApproveDeviceForQuickLoginV2', approveQuickLoginDeviceActions, null, null)
const disapproveQuickLoginDevice = () =>
  _helpers.wsCommand('DisapproveDeviceForQuickLoginV2', disapproveQuickLoginDeviceActions, null, null)

const getQuickLoginUsers = () =>
  (_client2.default.getQuickLoginUsers() )
    .then(r => {
      _store.dispatch.call(void 0, getQuickLoginUsersActions.SUCCESS(r, null))
      return r.Users || {}
    })

const removeQuickLoginUser = (userKey) =>
  (_client2.default.removeQuickLoginUser(userKey) )
    .then(r => {
      _store.dispatch.call(void 0, getQuickLoginUsersActions.SUCCESS(r, null))
      return r.Users
    })

exports. default = {
  setPassword,
  activateQuickLogin,
  deactivateQuickLogin,
  approveQuickLoginDevice,
  disapproveQuickLoginDevice,
  getQuickLoginUsers,
  removeQuickLoginUser,
}
