"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Notifications/Sections/MuteSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _time = require('shared/services/time');
var _noticeBar = require('web/services/context/noticeBar');
var _Form = require('web/components/Form');






const MuteSection = ({ muteUntil, onSubmit }) => {
  const now = new Date().getTime()
  const mutedUntilDate = new Date(muteUntil)
  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  const handleMuteUntil = (Until) => onSubmit({ Until })
    .then(() => showSuccessBar(Until
      ? "Successfully muted notifications."
      : "Successfully unmuted notifications.",
    ))
    .catch(() => showWarningBar(Until
      ? "Failed to mute notifications."
      : "Failed to unmute notifications.",
    ))

  const renderUnmute = () => (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('span', { className: "settings--notifications--mute-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Push notifications are muted on "
             , ' '
        , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "all devices" ), " until "  ,  _time.formatDisplayTime.call(void 0, mutedUntilDate) , "."
      )
      , _react2.default.createElement(_Form.Button, {
        isSecondary: true,
        className: "settings--notifications--unmute-btn",
        onClick: () => handleMuteUntil(0), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      , "Un-mute notifications"

      )
    )
  )

  const renderMuteUntil = () => {
    const oneHour = now + _time.msInHour
    const fourHour = now + 4 * _time.msInHour
    const eightHour = now + 8 * _time.msInHour
    const oneDay = now + 24 * _time.msInHour

    const muteButtonsList = [
      { period: oneHour, label: '1 hour' },
      { period: fourHour, label: '4 hours' },
      { period: eightHour, label: '8 hours' },
      { period: oneDay, label: '24 hours' },
    ]

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('span', { className: "settings--notifications--mute-cmd", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "Temporarily mute push notifications on "
               , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "all devices" ), " for..."
        )
        , _react2.default.createElement('div', { className: "settings--notifications--mute-btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          ,  muteButtonsList.map(({ period, label }) => (
            _react2.default.createElement(_Form.Button, {
              key: period,
              isSecondary: true,
              onClick: () => handleMuteUntil(period), __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            
              ,  label 
            )
          ))
        )
        , _react2.default.createElement('span', { className: "settings--notifications--mute-status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}, "Notifications are currently not muted."

        )
      )
    )
  }

  return (
    _react2.default.createElement('div', { className: "settings--notifications--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
      , _react2.default.createElement('h5', { className: "settings--notifications--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, "Mute Notifications" )
      ,  muteUntil && muteUntil > now ? renderUnmute() : renderMuteUntil() 
    )
  )
}

exports. default = MuteSection
