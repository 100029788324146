"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }/* services/blobject.js
 *
 *  useful services for dealing with blobjects
 *
 * TODO: get rid of this mess. Use atomic selectors instead.
 */

var _store = require('shared/store');
var _entity = require('shared/enum/entity');

/* check if the blobject we are using is "proper" */
 function isProperBlobject (blobject) {
  return blobject &&
         blobject.UserSubscribers !== undefined &&
         blobject.TeamSubscribers !== undefined &&
         blobject.UserMetadata !== undefined
} exports.isProperBlobject = isProperBlobject;

 const SubscriberTypes = {
  TEAM: "TEAM",
  USER: "USER",
  PATIENT: "PATIENT",
}; exports.SubscriberTypes = SubscriberTypes

const emptyArray = []
 const getBlobjectSubscriptionsForUser = (blobjectKey, userKey) => {
  const subscriptions = []
  const { blobjects, teams } = _optionalChain([_store.getState.call(void 0, ), 'optionalAccess', _ => _.dataV2]) || {}
  const blobject = _optionalChain([blobjects, 'optionalAccess', _2 => _2[blobjectKey]])

  if (!blobject || !teams) return emptyArray

  const teamSubscriberKeys = Object.keys(blobject.TeamSubscribers || {})

  teamSubscriberKeys.forEach(tk => {
    const team = teams[tk]
    if (!team) return

    if (team.Members[userKey]) {
      subscriptions.push({
        type: exports.SubscriberTypes.TEAM,
        key: tk,
        name: team.Name,
        entity: team,
      })
    }
  })

  if (_optionalChain([blobject, 'access', _3 => _3.UserSubscribers, 'optionalAccess', _4 => _4[userKey]])) {
    subscriptions.push({
      type: exports.SubscriberTypes.USER,
      key: userKey,
    })
  }

  return subscriptions
}; exports.getBlobjectSubscriptionsForUser = getBlobjectSubscriptionsForUser

/* this can be generalized to any clause (term, number) of any size, but we limit to what we have
 * for now.
 */
 function getDescriptionStringFromSubscriberCounts (usersNumber, teamsNumber, patientsNumber) {
  const usersWord = usersNumber > 1 ? "users" : "user"
  const teamsWord = teamsNumber > 1 ? "teams" : "team"
  const patientsWord = patientsNumber > 1 ? "patients" : "patient"

  const usersClause = `${usersNumber} ${usersWord}`
  const teamsClause = `${teamsNumber} ${teamsWord}`
  const patientsClause = `${patientsNumber} ${patientsWord}`

  const clauses = []
  if (usersNumber > 0) clauses.push(usersClause)
  if (teamsNumber > 0) clauses.push(teamsClause)
  if (patientsNumber > 0) clauses.push(patientsClause)

  if (clauses.length === 0) return ''
  if (clauses.length === 1) return clauses[0] || ''
  if (clauses.length === 2) return `${clauses[0]} and ${clauses[1]}`
  if (clauses.length === 3) return `${clauses[0]}, ${clauses[1]}, and ${clauses[2]}`

  return clauses.join(', ')
} exports.getDescriptionStringFromSubscriberCounts = getDescriptionStringFromSubscriberCounts;

 const getSubscriberEntityOrgKey = (map, e) => {
  switch (e.type) {
    case _entity.ENTITY_TYPES.USER: return _optionalChain([map, 'access', _5 => _5.users, 'access', _6 => _6[e.wrappedEntity.UserKey], 'optionalAccess', _7 => _7.OrgKey])
    case _entity.ENTITY_TYPES.TEAM: return e.wrappedEntity.OrgKey
    case _entity.ENTITY_TYPES.PATIENT: return e.wrappedEntity.OrgKey
    case _entity.ENTITY_TYPES.CONTACT: return _optionalChain([map, 'access', _8 => _8.contacts, 'access', _9 => _9[e.wrappedEntity.Credential], 'optionalAccess', _10 => _10.OrgKey])
  }
  return null
}; exports.getSubscriberEntityOrgKey = getSubscriberEntityOrgKey
