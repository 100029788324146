"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');



var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useCreateNewChat = require('./hooks/useCreateNewChat'); var _useCreateNewChat2 = _interopRequireDefault(_useCreateNewChat);

var _App = require('web/screens/App');
var _InboxList = require('./InboxList'); var _InboxList2 = _interopRequireDefault(_InboxList);
var _InboxRightSide = require('./InboxRightSide'); var _InboxRightSide2 = _interopRequireDefault(_InboxRightSide);
var _NewChatRoomNamePrompt = require('./NewChatRoomNamePrompt'); var _NewChatRoomNamePrompt2 = _interopRequireDefault(_NewChatRoomNamePrompt);






const InboxScreen = () => {
  const match = _reactrouterdom.useRouteMatch()
  const {
    blobjectKey,
    createdChatType: createdChatTypeURLToken,
  } = match.params

  const {
    isCreateChatRoomModalOpen,
    onConfirmCreateNewChatRoom,
    onCloseNewChatRoomModal,
    createdChatType,
    createdChatName,
    onCreateNewChat,
    onCloseCreateNewChat,
  } = _useCreateNewChat2.default.call(void 0, { createdChatTypeURLToken })

  return (
    _react2.default.createElement(_App.AppVertical, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
      , _react2.default.createElement(_App.NavigationMenu, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , _react2.default.createElement(_InboxList2.default, {
          onCreateNewChat: onCreateNewChat,
          isCreatingNewChat: !!createdChatType,
          createdChatName: createdChatName,
          isViewingCreateNewChat: !!createdChatTypeURLToken,
          onCloseCreateNewChat: onCloseCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        )
      )
      , _react2.default.createElement(_NewChatRoomNamePrompt2.default, {
        isOpen: isCreateChatRoomModalOpen,
        onProceed: onConfirmCreateNewChatRoom,
        onClose: onCloseNewChatRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      )
      , _react2.default.createElement(_InboxRightSide2.default, {
        blobjectKey: blobjectKey,
        createdChatName: createdChatName,
        createdChatType: createdChatType,
        gotoConversation: _actions2.default.gotoBlobject,
        onCloseCreateNewChat: onCloseCreateNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      )
    )
  )
}

exports. default = InboxScreen
