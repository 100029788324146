"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ChatScreen/ChatBox/Components.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactlinkify = require('react-linkify'); var _reactlinkify2 = _interopRequireDefault(_reactlinkify);
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _Attachment = require('web/components/Conversation/Attachment'); var _Attachment2 = _interopRequireDefault(_Attachment);
var _readMessages = require('shared/services/readMessages');
var _Tooltip = require('../../Tooltip');
var _Spinner = require('../../Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _Name = require('../../Name'); var _Name2 = _interopRequireDefault(_Name);
var _Avatar = require('web/components/Avatar'); var _Avatar2 = _interopRequireDefault(_Avatar);

 const Message = ({ message }) => {
  if (message.TextContent) { /* message */
    return (
      _react2.default.createElement(_reactlinkify2.default, { properties: { target: '_blank' }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} 
        , _react2.default.createElement('div', { className: "chatbox--content", id: message.MessageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
          ,  message.TextContent 
        )
      )
    )
  } else if (message.FileInfo && message.FileInfo.Metadata) { /* file */
    return (
      _react2.default.createElement('div', { className: "chatbox--content", id: message.MessageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        , _react2.default.createElement(_Attachment2.default, { ...message.FileInfo.Metadata, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}} )
      )
    )
  } else if (message.FileInfo && message.FileInfo.LocalMetaData) { /* file upload */
    return (
      _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        ,  message.FileInfo.LocalMetaData.hasUploadFailed
          ? _react2.default.createElement('span', { className: "chatbox--content--error-msg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, "The file upload has failed. Please, try again."

            )
          : _react2.default.createElement(_Spinner2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}} )
        
      )
    )
  }
  return null
}; exports.Message = Message
exports.Message.propTypes = {
  message: _proptypes2.default.object,
}

 const ReadMark = ({ message, participants, readSequence, currentUserKey }) => {
  const usersRead = Object.entries(readSequence)
    .filter(p => p[1] === message.sequenceNumber && p[0] !== currentUserKey)

  const seenByShortString = _readMessages.getReadString.call(void 0, usersRead, participants)

  if (!seenByShortString) {
    return null
  }

  const seenByUsers = usersRead.map((u) => {
    const userKey = u[0]
    const userFullName = participants[userKey] ? `${participants[userKey].FirstName} ${participants[userKey].LastName}` : ''
    if (userFullName === "") return null
    return (
      _react2.default.createElement(_Tooltip.TooltipHint, { key: u, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , userFullName
      )
    )
  })
  const seenbyTooltipContent = (
    _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      , _react2.default.createElement(_Tooltip.TooltipHint, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}, "Seen by:" )
      , seenByUsers
    )
  )

  return (
    _react2.default.createElement('div', { className: "chatbox--readstring--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      , _react2.default.createElement(_Tooltip.HoverTooltip, { type: "dark", place: "top", content: seenbyTooltipContent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        , _react2.default.createElement('span', { className: "chatbox--readstring", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}, seenByShortString)
      )
    )
  )
}; exports.ReadMark = ReadMark
exports.ReadMark.propTypes = {
  message: _proptypes2.default.object.isRequired,
  participants: _proptypes2.default.object,
  readSequence: _proptypes2.default.object,
}

 const MessageGroup = ({ messages, ...props }) => {
  const { participants } = props

  // If these are metadata messages, treat them differently
  if (messages[0].Type === "meta") {
    const metaMessages = messages
      .map((message) => (
        _react2.default.createElement('div', { key: message.MessageKey, className: "chatbox--metadata-message chatbox--readstring" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
          , _moment2.default.call(void 0, message.SentTimestamp).format('h:mm a') + " - " + message.TextContent
        )
      ))
    return (
      _react2.default.createElement('div', { className: "chatbox--messagegroup", key: messages[0].MessageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        , metaMessages
      )
    )
  }

  const firstHumanTime = _moment2.default.call(void 0, messages[0].SentTimestamp).format('h:mm a')
  const renderedMessages = messages.slice(1).map((message) => {
    const humanTime = _moment2.default.call(void 0, message.SentTimestamp).format('h:mm a')
    const classNames = `chatbox--message${message.highlight ? ' highlight' : ''}`
    return (
      _react2.default.createElement('div', { key: message.MessageKey, className: classNames, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
        , _react2.default.createElement('div', { className: "chatbox--messagetime", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}, humanTime)
        , _react2.default.createElement('div', { className: "chatbox--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
          , _react2.default.createElement(exports.Message, { message: message, __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}} )
          , _react2.default.createElement(exports.ReadMark, { message: message, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}} )
        )
      )
    )
  })

  let userName = "Unknown User?"
  const participant = participants[messages[0].FromUserKey]
  if (participant) {
    userName = _react2.default.createElement(_Name2.default, { user: participants[messages[0].FromUserKey], __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}} )
  }

  const classNames = `chatbox--message${messages[0].highlight ? ' highlight' : ''}`

  return (
    _react2.default.createElement('div', { className: "chatbox--messagegroup", key: messages[0].MessageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
      , _react2.default.createElement('div', { className: classNames, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
        ,  participant
          ? _react2.default.createElement(_Avatar.EntityAvatar, { className: "chatbox--avatar", entity: participant, __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}} )
          : _react2.default.createElement(_Avatar2.default, { className: "chatbox--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 133}} )
        
        , _react2.default.createElement('div', { className: "chatbox--container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
          , _react2.default.createElement('div', { className: "chatbox--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
            , _react2.default.createElement('div', { className: "chatbox--name blm--text-overflow" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 137}}, userName)
            , _react2.default.createElement('div', { className: "chatbox--time", __self: this, __source: {fileName: _jsxFileName, lineNumber: 138}}, firstHumanTime)
          )
          , _react2.default.createElement(exports.Message, { message: messages[0], __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}} )
          , _react2.default.createElement(exports.ReadMark, { message: messages[0], ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 141}} )
        )
      )
      , renderedMessages
    )
  )
}; exports.MessageGroup = MessageGroup

 const MessageDayGroup = ({ messages, ...props }) => {
  const date = messages[0][0].SentTimestamp
  const renderedMessages = messages.map((messageGroup) => _react2.default.createElement(exports.MessageGroup, { messages: messageGroup,
    key: messageGroup[0].MessageKey,
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}} ))

  return (
    _react2.default.createElement('div', { className: "chatbox--dategroup", key: "dategroup-" + date, __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}
      , _react2.default.createElement('div', { className: "chatbox--date", key: "date-" + date, __self: this, __source: {fileName: _jsxFileName, lineNumber: 157}}
        , _moment2.default.call(void 0, date).format("dddd, MMMM Do YYYY")
      )
      , renderedMessages
    )
  )
}; exports.MessageDayGroup = MessageDayGroup
