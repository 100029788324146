"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _helpers = require('./helpers');
var _utils = require('shared/store/utils');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);



const searchActions = _utils.createAsyncActions('Search')

const search = (
  TextContent,
  SearchTypes,
  DaysLimit = 14,
  UseTestAlias = false,
) => _helpers.wsCommand(
  'Search',
  searchActions,
  { TextContent, SearchTypes, DaysLimit, UseTestAlias },
  null,
)

const searchOrgs = (query) => _client2.default.searchForOrgs(query)
  .then(r => {
    if (r.Error) throw new Error(r.Error)
    return r.Orgs || []
  })

exports. default = {
  search,
  searchOrgs,
}
