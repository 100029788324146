"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/Users/Item.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');



var _entities = require('shared/transforms/entities');

var _GenericItem = require('web/screens/Directory/Sidebar/List/GenericItem'); var _GenericItem2 = _interopRequireDefault(_GenericItem);







const UserItem = ({ userEntity, orgKey, isViewed }) => (
  _react2.default.createElement(_GenericItem2.default, {
    entity: userEntity,
    orgKey: orgKey,
    isViewed: isViewed, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
  )
)





const mapState = (state, { userKey, orgKey }) => {
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgsMap = (org ? { [orgKey]: org } : {}) 
  const user = _peers.selectUserByKey.call(void 0, state, userKey)

  return {
    userEntity: user
      ? _entities.mapUserToEntity.call(void 0, 
        orgsMap,
        _entities.makeSpecificOrgTitleUserDescription.call(void 0, orgKey),
      )(user)
      : null,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(UserItem)
