"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');



var _types = require('web/screens/Signup/types');
var _utils = require('web/screens/Signup/utils');
var _amplitude = require('web/services/amplitude'); var _amplitude2 = _interopRequireDefault(_amplitude);
var _useRequestedOrgRels = require('./useRequestedOrgRels');
var _useSubmit = require('./useSubmit');









 const useIntermediateValues = ({
  token, gotoStep, welcomeUser, wasInvitedByPhone, onSubmitSetup,
}) => {
  const [personalInfo, setPersonalInfo] = _react.useState(null)
  const [password, setPassword] = _react.useState(null)
  const [requestedOrgRels, setRequestedOrgRels] = _useRequestedOrgRels.useRequestedOrgRel.call(void 0, welcomeUser)
  const { onSubmit, onFinish } = _useSubmit.useSubmit.call(void 0, token, onSubmitSetup, gotoStep)

  const onSavePersonalInfo = (personalInfo) => {
    setPersonalInfo(personalInfo)
    gotoStep(_types.SetupSteps.PASSWORD)
    _amplitude2.default.logEvent("SIGN_UP_ADD_INFO")
  }

  const onSavePassword = (password) => {
    setPassword(password)
    gotoStep(_types.SetupSteps.ORG_INFO)
    _amplitude2.default.logEvent("SIGN_UP_SET_PASSWORD")
  }

  const onSaveOrgRels = async (requestedOrgRels) => {
    setRequestedOrgRels(requestedOrgRels)
    if (requestedOrgRels.every(r => !_utils.isExistingOrgRel.call(void 0, r))) {
      return gotoStep(_types.SetupSteps.ORG_SURVEY_TYPE)
    }

    return personalInfo && password
      ? onSubmit(personalInfo, password, requestedOrgRels)
      : null
  }

  const onSaveOrgSurvey = async (survey) => {
    if (!personalInfo || !password || !requestedOrgRels.length) return
    return onSubmit(personalInfo, password, requestedOrgRels, survey)
  }

  const handleFinish = async () => {
    if (!personalInfo || !password) return
    _amplitude2.default.logEvent("SIGN_UP_COMPLETE")
    return onFinish(personalInfo, password, wasInvitedByPhone)
  }

  return {
    values: {
      personalInfo,
      password,
      requestedOrgRels,
    },
    handlers: {
      onSavePersonalInfo,
      onSavePassword,
      onSaveOrgRels,
      onSaveOrgSurvey,
      onFinish: handleFinish,
    },
  }
}; exports.useIntermediateValues = useIntermediateValues
