"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/ResultsList/ResultsSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _EntityItem = require('./EntityItem'); var _EntityItem2 = _interopRequireDefault(_EntityItem);







const ResultsSection = ({
  title,
  entitiesList,
  onSelectEntity,
}) => {
  if (!entitiesList.length) return null

  return (
    _react2.default.createElement('div', { className: "participants-picker--results-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      ,  title && (
        _react2.default.createElement('h6', { className: "participants-picker--results-section--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          ,  title 
        )
      )
      ,  entitiesList.map(e => (
        _react2.default.createElement(_EntityItem2.default, {
          key: e.entityKey,
          entity: e,
          onSelectEntity: onSelectEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
        )
      ))
    )
  )
}

exports. default = ResultsSection
