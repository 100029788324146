"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }



var _joi = require('shared/services/validation/joi'); var _joi2 = _interopRequireDefault(_joi);
var _joi3 = require('@hookform/resolvers/joi');








const schema = _joi2.default.object({
  orgName: _joi.requiredString.call(void 0, ).max(100).messages(_joi.makeStrErrors.call(void 0, 'Organization name')),
  adminEmail: _joi.requiredString.call(void 0, ).messages(_joi.makeStrErrors.call(void 0, 'Email')).contactEmail(),
  myTeamKey: _joi.requiredString.call(void 0, ).messages(_joi.makeStrErrors.call(void 0, 'Team')),
  chatName: _joi.optionalString.call(void 0, ).max(100).messages(_joi.makeStrErrors.call(void 0, 'Chat name')),
})

 const invitedOrgResolver = _joi3.joiResolver.call(void 0, schema); exports.invitedOrgResolver = invitedOrgResolver
