"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Snackbar/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

 class Snackbar extends _react2.default.Component {constructor(...args) { super(...args); Snackbar.prototype.__init.call(this);Snackbar.prototype.__init2.call(this); }
  static __initStatic() {this.propTypes = {
    iconSrc: _proptypes2.default.string,
    text: _proptypes2.default.string,
    linkText: _proptypes2.default.string,
    onLinkClick: _proptypes2.default.func,
    onAnyAction: _proptypes2.default.func,
    onClose: _proptypes2.default.func,
  }}

  static __initStatic2() {this.defaultProps = {
    onAnyAction: () => {},
    onLinkClick: () => {},
    onClose: () => {},
  }}

  __init() {this.onClose = () => {
    const { onAnyAction, onClose } = this.props
    onAnyAction()
    onClose()
  }}

  __init2() {this.onLinkClick = () => {
    const { onLinkClick, onAnyAction, onClose } = this.props
    onLinkClick()
    onAnyAction()
    onClose()
  }}

  render () {
    const { iconSrc, text, linkText } = this.props

    return (
      _react2.default.createElement('div', { className: "blmh-snackbar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        , _react2.default.createElement('div', { className: "blmh-snackbar--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
          , _react2.default.createElement('img', { src: iconSrc, alt: "!", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
        )
        , _react2.default.createElement('span', { className: "blmh-snackbar--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}},  text )
        , _react2.default.createElement('a', { className: "blmh-snackbar--link", onClick: this.onLinkClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}, linkText)
        , _react2.default.createElement('div', { className: "blmh-snackbar--close-icon", onClick: this.onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
          , _react2.default.createElement('img', { src: "/assets/icons/icon-xout-org.svg", alt: "x", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} )
        )
      )
    )
  }
} Snackbar.__initStatic(); Snackbar.__initStatic2(); exports.default = Snackbar;
