"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Header/SettingsModal/TeamItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');







const TeamItem = ({
  teamName,
  isInboxable,
  onToggle,
}) => (
  _react2.default.createElement('div', { className: "inbox-modal--team-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
    , _react2.default.createElement('img', {
      className: "inbox-modal--team-item--icon",
      src: "/assets/icons/inbox/team-badge-dark.svg",
      alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    )
    , _react2.default.createElement('span', { className: "inbox-modal--team-item--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}},  teamName )
    , _react2.default.createElement(_Form.Toggle, {
      isChecked: isInboxable,
      onChange: onToggle,
      className: "inbox-modal--team-item--toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    )
  )
)

exports. default = TeamItem
