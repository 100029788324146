"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/Modals/AddAdmins.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _user = require('shared/selectors/user');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _Props = require('web/components/Form/Props');

var _AddAdmins = require('web/components/VideoRooms/Room/Modals/AddAdmins'); var _AddAdmins2 = _interopRequireDefault(_AddAdmins);

const getInitialState = () => ({
  inputValues: { admins: '' },
  adminEntities: [],
})

class AddAdminsModalContainer extends _react2.default.PureComponent {constructor(...args) { super(...args); AddAdminsModalContainer.prototype.__init.call(this);AddAdminsModalContainer.prototype.__init2.call(this);AddAdminsModalContainer.prototype.__init3.call(this);AddAdminsModalContainer.prototype.__init4.call(this);AddAdminsModalContainer.prototype.__init5.call(this);AddAdminsModalContainer.prototype.__init6.call(this);AddAdminsModalContainer.prototype.__init7.call(this); }
  __init() {this.state = getInitialState()}

  __init2() {this.onAdminSelect = admin => {
    const { adminEntities } = this.state

    this.setState({
      inputValues: { ...this.state.inputValues, admins: '' },
      adminEntities: adminEntities.concat(admin),
    })
  }}

  __init3() {this.onAdminUpdate = updatedAdmin => {
    const { adminEntities } = this.state

    this.setState({
      adminEntities: adminEntities
        .filter(e => e.id !== updatedAdmin.id)
        .concat(updatedAdmin),
    })
  }}

  __init4() {this.onAdminRemove = id => {
    const { adminEntities } = this.state

    this.setState({
      adminEntities: adminEntities.filter(e => e.id !== id),
    })
  }}

  __init5() {this.handleAdminsInputChange = (inputId, val) =>
    this.setState({
      inputValues: {
        ...this.state.inputValues,
        [inputId]: val,
      },
    })}

  __init6() {this.onSubmit = () => {
    const { roomKey } = this.props
    const { adminEntities } = this.state

    const { AdminUsers, AdminTeamKeys } = adminEntities.reduce(
      (acc, e) => {
        if (e.type === _Props.ENTITY_TYPES.TEAM) {
          return {
            ...acc,
            AdminTeamKeys: acc.AdminTeamKeys.concat(e.id),
          }
        }
        return {
          ...acc,
          AdminUsers: acc.AdminUsers.concat({
            UserKey: e.id, OrgKey: e.orgKey,
          }),
        }
      }
      , { AdminUsers: [], AdminTeamKeys: [] },
    )

    _actions2.default.addVideoRoomAdmins({
      RoomKey: roomKey,
      AdminUsers,
      AdminTeamKeys,
    })
  }}

  __init7() {this.componentDidUpdate = prevProps =>
    prevProps.isOpen && !this.props.isOpen &&
    this.setState(getInitialState())}

  render () {
    const { isOpen, onClose, orgKey } = this.props
    const { inputValues, adminEntities } = this.state

    return (
      _react2.default.createElement(_AddAdmins2.default, {
        isOpen: isOpen,
        onClose: onClose,
        adminsInputId: "admins",
        adminsInputValue: inputValues.admins,
        onAdminsInputChange: this.handleAdminsInputChange,
        onAdminSelect: this.onAdminSelect,
        onAdminUpdate: this.onAdminUpdate,
        onAdminRemove: this.onAdminRemove,
        adminEntities: adminEntities,
        onSubmit: this.onSubmit,
        orgKey: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      )
    )
  }
}

const mapState = state => ({ orgKey: _user.selectCurrentUserV2.call(void 0, state).OrgKey })
exports. default = _reactredux.connect.call(void 0, mapState)(AddAdminsModalContainer)
