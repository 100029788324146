"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/CallControls.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _redux = require('redux');



var _CallControls = require('web/components/VideoCall/CallControls');


var _uiUtils = require('web/components/VideoCall/OnCall/uiUtils');

const tooltipWrapperClassName = _classBuilder2.default.call(void 0, 
  "meeting--on-call--tooltip",
  "video-call--on-call--controls-icon--wrapper",
)
const makeControlBtn = label => _redux.compose.call(void 0, 
  _uiUtils.withOnCallTooltip.call(void 0, 'top', label, tooltipWrapperClassName),
  _uiUtils.withTouchInterception,
)

const EnhancedSwitchCamBtn = makeControlBtn('Switch cameras')(_CallControls.SwitchCamBtn)
const EnhancedToggleCamBtn = makeControlBtn('Disable camera')(_CallControls.DisableCamBtn)
const EnhancedToggleCamBtnActive = makeControlBtn('Enable camera')(_CallControls.DisableCamBtn)
const EnhancedToggleMicBtn = makeControlBtn('Mute')(_CallControls.DisableMicBtn)
const EnhancedToggleMicBtnActive = makeControlBtn('Unmute')(_CallControls.DisableMicBtn)
const EnhancedToggleScreenBtn = makeControlBtn('End screen sharing')(_CallControls.ScreenSharingBtn)
const EnhancedToggleScreenBtnActive = makeControlBtn('Share screen')(_CallControls.ScreenSharingBtn)
const EnhancedHangupBtn = makeControlBtn('End call')(_CallControls.HangupBtn)

const CallControls = ({
  areOpen,
  isCamCycleAvailable, onCycleCam,
  isCamOn, onToggleCam,
  isMicOn, onToggleMic,
  isScreenSharingAvailable, isScreenSharingOn, onToggleScreenSharing,
  onHangup,
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, 
    "video-call--call-controls",
    { hidden: !areOpen },
  )

  const toggleMicBtnProps = {
    isActive: !isMicOn,
    onClick: onToggleMic,
    className: "video-call--on-call--controls-icon",
  }
  const toggleCamBtnProps = {
    isActive: !isCamOn,
    onClick: onToggleCam,
    className: "video-call--on-call--controls-icon",
  }
  const toggleScreenBtnProps = {
    isActive: isScreenSharingOn,
    onClick: onToggleScreenSharing,
    className: "video-call--on-call--controls-icon",
  }
  const ToggleMicBtn = isMicOn ? EnhancedToggleMicBtn : EnhancedToggleMicBtnActive
  const ToggleCamBtn = isCamOn ? EnhancedToggleCamBtn : EnhancedToggleCamBtnActive
  const ToggleScreenBtn = isScreenSharingOn ? EnhancedToggleScreenBtn : EnhancedToggleScreenBtnActive

  return (
    _react2.default.createElement('div', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
      ,  isCamCycleAvailable &&
        _react2.default.createElement(EnhancedSwitchCamBtn, {
          onClick: onCycleCam,
          className: "video-call--on-call--controls-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
        )
      
      , _react2.default.createElement(ToggleCamBtn, { ...toggleCamBtnProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}} )
      , _react2.default.createElement(ToggleMicBtn, { ...toggleMicBtnProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}} )
      ,  isScreenSharingAvailable &&
        _react2.default.createElement(ToggleScreenBtn, { ...toggleScreenBtnProps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}} )
      
      , _react2.default.createElement(EnhancedHangupBtn, {
        dbg: true,
        onClick: onHangup,
        className: "video-call--on-call--controls-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      )
    )
  )
}

CallControls.propTypes = {
  areOpen: _proptypes2.default.bool.isRequired,
  isCamCycleAvailable: _proptypes2.default.bool.isRequired,
  isScreenSharingAvailable: _proptypes2.default.bool.isRequired,
  isMicOn: _proptypes2.default.bool.isRequired,
  isCamOn: _proptypes2.default.bool.isRequired,
  isScreenSharingOn: _proptypes2.default.bool.isRequired,
  onToggleCam: _proptypes2.default.func.isRequired,
  onToggleMic: _proptypes2.default.func.isRequired,
  onCycleCam: _proptypes2.default.func.isRequired,
  onToggleScreenSharing: _proptypes2.default.func.isRequired,
  onHangup: _proptypes2.default.func.isRequired,
}

exports. default = CallControls
