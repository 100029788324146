"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ScheduleTab/ScheduleSection/EditScheduleModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');







var _schedule = require('shared/validation/team/autoReply/schedule');
var _utils = require('../utils');

var _team = require('shared/selectors/team');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');
var _TimeTable = require('./TimeTable'); var _TimeTable2 = _interopRequireDefault(_TimeTable);








const EditScheduleModal = ({
  isOpen,
  onClose,
  onSubmit,
  timezone,
  teamHours,
}) => {
  const defaultValues =
    _react.useMemo.call(void 0, () => _utils.makeDefaultValues.call(void 0, timezone, teamHours), [timezone, teamHours])

  const { control, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _schedule.autoReplyScheduleResolver,
      defaultValues,
    })
  const { isValid, isDirty, isSubmitting } = formState

  const submit = _react.useCallback.call(void 0, 
    (data) => onSubmit(data).then(onClose),
    [onSubmit, onClose],
  )

  return (
    _react2.default.createElement(_Modal2.default, {
      title: "Edit hours" ,
      isOpen: isOpen,
      onClose: onClose,
      className: "team-page--edit-schedule-modal", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
    
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Time Zone" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        , _react2.default.createElement(_Form.SimpleSelectController, {
          name: "autoReplyTimezone",
          control: control,
          options: _utils.tzOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { shouldCaptureFocus: false, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        , _react2.default.createElement(_TimeTable2.default, { control: control, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}} )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Save",
        onPrimaryBtnClick: handleSubmit(submit),
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
      )
    )
  )
}






const mapState = (state, { teamKey }) => ({
  timezone: _optionalChain([_team.selectTeamByKey.call(void 0, state, teamKey), 'optionalAccess', _ => _.AutoReplyTimeZone]) || null,
  teamHours: _team.selectTeamHoursList.call(void 0, state, teamKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(EditScheduleModal)
