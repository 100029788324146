"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Attachment/File.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const AttachmentFile = ({
  onClick,
  fileName,
  fileSize,
  small,
}) => (
  _react2.default.createElement('div', {
    onClick: onClick,
    className: _classBuilder2.default.call(void 0, "filecontainer", small && "filecontainer-small"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
  
    , _react2.default.createElement('div', { className: "filecontainer--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "insert_drive_file")
      , _react2.default.createElement('div', { className: "filecontainer--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , _react2.default.createElement('div', { className: "filecontainer--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, fileName)
        , _react2.default.createElement('div', { className: "filecontainer--subtext", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}, fileSize)
      )
    )
    , _react2.default.createElement('div', { className: "filecontainer--bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Download"

    )
  )
)

exports. default = AttachmentFile
