"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _entity = require('shared/services/entity');


const EMPTY_MAP = {}






const useSelectedEntities = ({
  initialEntitiesMap,
  initialEntitiesList,
} = {}) => {
  const [selectedEntitiesMap, setSelectedEntitiesMap] =
    _react.useState(EMPTY_MAP)

  const onSelectEntity = _react.useCallback.call(void 0, 
    (e) => setSelectedEntitiesMap(es => ({ ...es, [e.entityKey]: e })),
    [setSelectedEntitiesMap],
  )

  const onRemoveEntity = _react.useCallback.call(void 0, 
    (key) => setSelectedEntitiesMap(map => ({ ...map, [key]: null })),
    [setSelectedEntitiesMap],
  )

  const onResetEntities = _react.useCallback.call(void 0, 
    () => setSelectedEntitiesMap(EMPTY_MAP),
    [setSelectedEntitiesMap],
  )

  const selectedEntitiesList = _react.useMemo.call(void 0, 
    () => _entity.entityMapToList.call(void 0, selectedEntitiesMap),
    [selectedEntitiesMap],
  )

  _react.useEffect.call(void 0, 
    () => setSelectedEntitiesMap(map => ({
      ...map,
      ...(initialEntitiesMap || _entity.entityListToMap.call(void 0, initialEntitiesList)),
    })),
    [initialEntitiesMap, initialEntitiesList],
  )

  return {
    selectedEntitiesMap,
    selectedEntitiesList,
    onSelectEntity,
    onRemoveEntity,
    onResetEntities,
  }
}

exports. default = useSelectedEntities
