"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/OrgsList/OrgBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const renderWarning = (isAdmin, pendingUsersCount) => {
  if (!isAdmin || !pendingUsersCount) return null

  return (
    _react2.default.createElement('span', { className: "settings--orgs-list--btn-warning", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
      ,  pendingUsersCount 
    )
  )
}











const OrgBtn = ({
  label,
  iconSrc,
  shouldRenderForwardIcon,
  IsAdmin = false,
  PendingUsersCount = 0,
  onClick,
  className,
}) => (
  _react2.default.createElement('div', {
    role: "button",
    className: _classBuilder2.default.call(void 0, "settings--orgs-list--btn", className),
    onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
  
    , _react2.default.createElement('div', { className: "settings--orgs-list--btn-body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      , _react2.default.createElement('img', {
        src: iconSrc,
        className: "settings--orgs-list-btn-icon",
        alt: "+", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
      )
      , _react2.default.createElement('span', { className: "settings--orgs-list--btn-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}},  label )
      ,  renderWarning(IsAdmin, PendingUsersCount) 
      ,  shouldRenderForwardIcon &&
        _react2.default.createElement('div', { className: "settings--orgs-list--btn-next-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} )
      
    )
  )
)

exports. default = OrgBtn
