"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/DateMessage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







const DateMessage = ({ messageKey, text }) => (
  _react2.default.createElement('div', { className: "date-message", id: messageKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    , _react2.default.createElement('div', { className: "date-message--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      ,  text 
    )
  )
)

exports. default = DateMessage
