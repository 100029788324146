"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembershipTab/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _orgMember = require('shared/validation/orgMember');
var _hookForm = require('shared/services/validation/hookForm');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');

var _Form = require('web/components/Form');
var _ConfirmLeaveModal = require('./ConfirmLeaveModal'); var _ConfirmLeaveModal2 = _interopRequireDefault(_ConfirmLeaveModal);








const MembershipForm = ({ relTitle, orgName, onSubmit, onLeave }) => {
  const {
    isOpen: isLeaveModalOpen,
    onOpen: onOpenLeaveModal,
    onClose: onCloseLeaveModal,
  } = _useToggle2.default.call(void 0, )

  const { register, handleSubmit, reset, formState } =
    _hookForm.useForm({
      resolver: _orgMember.orgMemberResolver,
      mode: 'onChange',
      defaultValues: { title: relTitle, isAdmin: false },
    })
  const { isValid, isDirty, isSubmitting, errors } = formState

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  const submit = _react.useCallback.call(void 0, 
    data => onSubmit(data)
      .then(() => showSuccessBar('Your membership status has been updated.'))
      .catch(() => showWarningBar('Failed to submit your membership status'))
      .finally(() => reset(data)),
    [onSubmit, showSuccessBar, showWarningBar, reset],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        , _react2.default.createElement(_Form.InputSection, {
          autoFocus: true,
          inputProps: register('title'),
          title: "Your Title" ,
          placeholder: "E.g. Attending Physician"  ,
          errorMessage: _optionalChain([errors, 'access', _ => _.title, 'optionalAccess', _2 => _2.message]),
          className: "settings--org-membership--field", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        )
        , _react2.default.createElement('div', { className: "settings--org-membership--controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          , _react2.default.createElement(_Form.Button, {
            isSubmit: true,
            isDisabled: !isValid || !isDirty || isSubmitting,
            isLoading: isSubmitting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          , "Save"

          )
          , _react2.default.createElement(_Form.Button, { isDanger: true, onClick: onOpenLeaveModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "Leave")
        )
      )
      , _react2.default.createElement(_ConfirmLeaveModal2.default, {
        isOpen: isLeaveModalOpen,
        onClose: onCloseLeaveModal,
        orgName: orgName,
        onProceed: onLeave, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
    )
  )
}

exports. default = MembershipForm
