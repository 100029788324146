"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _types = require('web/screens/Directory/types');
var _useSearchQuery = require('shared/hooks/useSearchQuery');

var _Tabs = require('web/components/Tabs'); var _Tabs2 = _interopRequireDefault(_Tabs);
var _SearchBar = require('./SearchBar'); var _SearchBar2 = _interopRequireDefault(_SearchBar);
var _Users = require('./Users'); var _Users2 = _interopRequireDefault(_Users);
var _Patients = require('./Patients'); var _Patients2 = _interopRequireDefault(_Patients);
var _Teams = require('./Teams'); var _Teams2 = _interopRequireDefault(_Teams);







const tabOptions = [
  { value: _types.DirectoryTab.COWORKERS, label: "Coworkers" },
  { value: _types.DirectoryTab.TEAMS, label: "Teams" },
  { value: _types.DirectoryTab.PATIENTS, label: "Patients" },
]

const List = ({ selectedTab, onSelectTab, orgKey }) => {
  const [searchQuery, setSearchQuery] = _useSearchQuery.useSearchQuery.call(void 0, )
  _react.useEffect.call(void 0, () => setSearchQuery(''), [selectedTab, setSearchQuery])

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Tabs2.default, {
        type: "tabs",
        options: tabOptions,
        value: selectedTab,
        onSelectTab: onSelectTab,
        className: "directory-list--tabs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      )
      , _react2.default.createElement(_SearchBar2.default, {
        value: searchQuery,
        onChange: setSearchQuery,
        selectedTab: selectedTab, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      )
      , _react2.default.createElement('div', { className: "directory-list--items-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        ,  selectedTab === _types.DirectoryTab.COWORKERS && (
          _react2.default.createElement(_Users2.default, { orgKey: orgKey, searchQuery: searchQuery, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}} )
        )
        ,  selectedTab === _types.DirectoryTab.PATIENTS && (
          _react2.default.createElement(_Patients2.default, { orgKey: orgKey, searchQuery: searchQuery, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
        )
        ,  selectedTab === _types.DirectoryTab.TEAMS && (
          _react2.default.createElement(_Teams2.default, { orgKey: orgKey, searchQuery: searchQuery, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
        )
      )
    )
  )
}

exports. default = List
