"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _user = require('shared/selectors/user');

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _CreateNewChat = require('./CreateNewChat'); var _CreateNewChat2 = _interopRequireDefault(_CreateNewChat);
var _CreateNewVideo = require('./CreateNewVideo'); var _CreateNewVideo2 = _interopRequireDefault(_CreateNewVideo);









var CreateNewTypes; (function (CreateNewTypes) {
  const CHAT = "CHAT"; CreateNewTypes["CHAT"] = CHAT;
  const VIDEO = "VIDEO"; CreateNewTypes["VIDEO"] = VIDEO;
})(CreateNewTypes || (exports.CreateNewTypes = CreateNewTypes = {}));

const resolveModalTitle = (type) => {
  const types = {
    [CreateNewTypes.CHAT]: 'Create a chat',
    [CreateNewTypes.VIDEO]: 'Start a video chat',
  }

  return type && types[type] || 'Create New'
}

const CreateNew = ({
  open,
  onClose,
  selectedTab,
  children,
  title,
  hasVideoFeature,
}) => {
  const content = children || (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  selectedTab === CreateNewTypes.CHAT && (
        _react2.default.createElement(_CreateNewChat2.default, { onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}} )
      )
      , _react2.default.createElement(_CreateNewVideo2.default, {
        visible: selectedTab === CreateNewTypes.VIDEO,
        hasVideoFeature: hasVideoFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      )
    )
  )

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: open,
      onClose: onClose,
      title: title || resolveModalTitle(selectedTab), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
    
      ,  content 
    )
  )
}





const mapState = (state) => ({
  hasVideoFeature: _user.selectCurrentUserHasVideoV1Feature.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNew)
