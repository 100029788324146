"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/EmptyCampaignPage.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _myRelations = require('shared/selectors/myRelations');

var _TemplateEntityScreen = require('web/screens/TemplateEntityScreen'); var _TemplateEntityScreen2 = _interopRequireDefault(_TemplateEntityScreen);

const EmptyCampaignPage = ({ doIHaveAnyPhoneTeams }) => (
  _react2.default.createElement(_TemplateEntityScreen2.default, {
    iconSrc: "/assets/icons/broadcast/broadcast-lightgrey.svg",
    title: "Broadcast Campaigns" ,
    desc: doIHaveAnyPhoneTeams
      ? _react2.default.createElement(_react2.default.Fragment, null, "Your campaigns are listed on the left. "
                 , _react2.default.createElement('wbr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} ), "Choose one to see more details."

        )
      : _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, "To use this feature, you must be a member of a team with an assigned phone number."

        )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
  )
)





const mapState = (state) => ({
  doIHaveAnyPhoneTeams: _myRelations.selectDoIHaveAnyPhoneTeams.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(EmptyCampaignPage)
