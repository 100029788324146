"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _redux = require('redux');
var _store = require('shared/store');
var _helpers = require('shared/actions/helpers');
var _utils = require('shared/store/utils');









const loginSuccessAction = _utils.createAction.call(void 0, 
  'CSRF_SUCCESS',
  (r, meta) => ({
    payload: {
      ...r.ChatPreload,
      User: r.User,
      UserInfo: r.UserInfo,
    },
    meta,
  }))
const logoutAction = _utils.createAction.call(void 0, 'Logout')
const unAuthenticatedConnectAction = _utils.createAction.call(void 0, 'UNAUTHENTICATED_CONNECT')
const registerMobilePushDeviceActions = _utils.createAsyncActions('RegisterMobilePushDevice')
const enableMobilePushTokenActions = _utils.createAsyncActions('EnableMobilePushToken')
const disableMobilePushTokenActions = _utils.createAsyncActions('DisableMobilePushToken')

 const actionCreators = {
  loginSuccessAction,
  logoutAction,
  unAuthenticatedConnectAction,
  registerMobilePushDeviceActions,
  enableMobilePushTokenActions,
  disableMobilePushTokenActions,
}; exports.actionCreators = actionCreators

const loginSuccess = _redux.bindActionCreators.call(void 0, loginSuccessAction, _store.dispatch)
const logout = _redux.bindActionCreators.call(void 0, logoutAction, _store.dispatch)
const unAuthenticatedConnect = _redux.bindActionCreators.call(void 0, unAuthenticatedConnectAction, _store.dispatch)

const registerMobilePushDevice = (req) =>
  _helpers.wsCommand(
    'RegisterMobilePushDevice',
    registerMobilePushDeviceActions,
    req,
    null,
  )

const enableMobilePushToken = () => _helpers.wsCommand(
  'EnableMobilePushToken',
  enableMobilePushTokenActions,
  {},
  null,
)

const disableMobilePushToken = () => _helpers.wsCommand(
  'DisableMobilePushToken',
  disableMobilePushTokenActions,
  {},
  null,
)

exports. default = {
  loginSuccess,
  logout,
  unAuthenticatedConnect,
  registerMobilePushDevice,
  enableMobilePushToken,
  disableMobilePushToken,
}
