"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _useEntityPreload = require('shared/hooks/useEntityPreload'); var _useEntityPreload2 = _interopRequireDefault(_useEntityPreload);
var _org = require('shared/selectors/org');
var _team = require('shared/selectors/team');
var _user = require('shared/selectors/user');



var _entities = require('shared/transforms/entities');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _hooks = require('./hooks');

var _RightSide = require('web/components/RightSide');
var _EntityNotFoundScreen = require('web/screens/EntityNotFoundScreen'); var _EntityNotFoundScreen2 = _interopRequireDefault(_EntityNotFoundScreen);

var _Tabs = require('web/components/Tabs'); var _Tabs2 = _interopRequireDefault(_Tabs);
var _ConfirmDeleteTeamModal = require('./ConfirmDeleteTeamModal'); var _ConfirmDeleteTeamModal2 = _interopRequireDefault(_ConfirmDeleteTeamModal);
var _Header = require('./Header'); var _Header2 = _interopRequireDefault(_Header);
var _InfoTab = require('./InfoTab'); var _InfoTab2 = _interopRequireDefault(_InfoTab);
var _ScheduleTab = require('./ScheduleTab'); var _ScheduleTab2 = _interopRequireDefault(_ScheduleTab);
var _useBusyReplyToggle = require('./hooks/useBusyReplyToggle'); var _useBusyReplyToggle2 = _interopRequireDefault(_useBusyReplyToggle);







var TeamTab; (function (TeamTab) { const INFO = 0; TeamTab[TeamTab["INFO"] = INFO] = "INFO"; const AUTO_REPLY = INFO + 1; TeamTab[TeamTab["AUTO_REPLY"] = AUTO_REPLY] = "AUTO_REPLY"; })(TeamTab || (TeamTab = {}));
const tabOptions = [
  { value: TeamTab.INFO, label: "Overview" },
  { value: TeamTab.AUTO_REPLY, label: "Auto-Reply" },
]

const TeamPage = ({
  teamKey,
  teamEntity,
  orgKey,
  canEditTeam,
  onStartChat,
  hasPhoneNumber,
  orgName,
}) => {
  const loadTeam = _react.useCallback.call(void 0, () => _actions2.default.getTeams({ TeamKeys: [teamKey] }), [teamKey])
  const hasLoadFailed = _useEntityPreload2.default.call(void 0, loadTeam)
  const [activeTab, setActiveTab] = _react.useState(TeamTab.INFO)

  const {
    isDeleteModalOpen,
    onDelete,
    onCloseDeleteModal,
    onConfirmDelete,
  } = _hooks.useDeleteTeam.call(void 0, teamKey)
  const onUpdateInfo = _hooks.useUpdateTeam.call(void 0, teamKey)

  const [isAutoReplyEnabled, onToggleAutoReply] =
    _hooks.useAutoReplyToggle.call(void 0, teamKey, !!_optionalChain([teamEntity, 'optionalAccess', _ => _.wrappedEntity, 'access', _2 => _2.AutoReply]))

  const [isBusyReplyEnabled, onToggleBusyReply] =
    _useBusyReplyToggle2.default.call(void 0, teamKey, !!_optionalChain([teamEntity, 'optionalAccess', _3 => _3.wrappedEntity, 'access', _4 => _4.BusyReply]))

  if (hasLoadFailed) return _react2.default.createElement(_EntityNotFoundScreen2.default, { desc: "Failed to load team"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}} )
  if (!teamEntity) return null

  return (
    _react2.default.createElement(_react2.default.Fragment, { key: teamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
      , _react2.default.createElement(_Header2.default, {
        teamKey: teamKey,
        title: teamEntity.name,
        subtitle: teamEntity.description,
        onBack: _actions2.default.gotoDirectory,
        onStartChat: () => onStartChat(teamEntity),
        onDelete: canEditTeam ? onDelete : undefined, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      )
      , _react2.default.createElement(_RightSide.RightSideBody, { className: "team-page--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        ,  hasPhoneNumber && (
          _react2.default.createElement(_Tabs2.default, {
            type: "tabs",
            options: tabOptions,
            value: activeTab,
            onSelectTab: setActiveTab,
            className: "team-page--tabs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
          )
        )
        , _react2.default.createElement('div', { className: "team-page--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
          ,  activeTab === TeamTab.INFO && (
            _react2.default.createElement(_InfoTab2.default, {
              teamKey: teamKey,
              orgKey: orgKey,
              canEditTeam: canEditTeam,
              onUpdateInfo: onUpdateInfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
            )
          )
          ,  activeTab === TeamTab.AUTO_REPLY && (
            _react2.default.createElement(_ScheduleTab2.default, {
              teamKey: teamKey,
              isAutoReplyEnabled: isAutoReplyEnabled,
              onToggleAutoReply: onToggleAutoReply,
              onUpdateAutoReplyInfo: onUpdateInfo,
              isBusyReplyEnabled: isBusyReplyEnabled,
              onToggleBusyReply: onToggleBusyReply,
              onUpdateBusyReplyInfo: onUpdateInfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
            )
          )
        )
      )
      , _react2.default.createElement(_ConfirmDeleteTeamModal2.default, {
        isOpen: isDeleteModalOpen,
        onClose: onCloseDeleteModal,
        teamName: teamEntity.name,
        orgName: orgName,
        onProceed: onConfirmDelete, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
      )
    )
  )
}








const mapState = (
  state,
  { teamKey, orgKey },
) => {
  const team = _team.selectTeamByKey.call(void 0, state, teamKey)
  const hasPhoneNumber = !!_optionalChain([team, 'optionalAccess', _5 => _5.PhoneNumber])
  const teamEntity = team &&
    _entities.mapTeamToEntity.call(void 0, {}, (0, _entities.makeRawMembersCountTeamDescription))(team)
  const orgName = team && _optionalChain([_org.selectOrgByKey.call(void 0, team.OrgKey, state), 'optionalAccess', _6 => _6.Name]) || ''

  return {
    teamEntity,
    orgName,
    hasPhoneNumber,
    canEditTeam: !_optionalChain([team, 'optionalAccess', _7 => _7.IsDefaultTeam]) && _user.selectAmIAdminInOrg.call(void 0, state, orgKey),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(TeamPage)
