"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');





var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);







const schema = _joi4.default.object({
  firstName: _joi3.requiredString.call(void 0, ).max(50).messages(_joi3.makeStrErrors.call(void 0, 'First name')),
  lastName: _joi3.requiredString.call(void 0, ).max(50).messages(_joi3.makeStrErrors.call(void 0, 'Last name')),
  suffix: _joi3.optionalString.call(void 0, ).max(50).messages(_joi3.makeStrErrors.call(void 0, 'Suffix')),
})

 const userNameResolver = _joi.joiResolver.call(void 0, schema); exports.userNameResolver = userNameResolver
