"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/MessageAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _Message = require('shared/components/Message');

var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);
var _PatientAvatar = require('./PatientAvatar'); var _PatientAvatar2 = _interopRequireDefault(_PatientAvatar);
var _UserAvatar = require('./UserAvatar'); var _UserAvatar2 = _interopRequireDefault(_UserAvatar);






const MessageAvatar = ({
  senderKey,
  senderType,
  ...props
}) => {
  switch (senderType) {
    case _Message.SenderTypes.PATIENT:
      return _react2.default.createElement(_PatientAvatar2.default, { patientKey: senderKey , ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
    case _Message.SenderTypes.USER:
      return _react2.default.createElement(_UserAvatar2.default, { userKey: senderKey , ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
    default:
      return _react2.default.createElement(_BaseAvatar2.default, { ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
  }
}

exports. default = MessageAvatar
