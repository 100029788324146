"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/ResetPassword/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);
var _ErrorMessage = require('./ErrorMessage'); var _ErrorMessage2 = _interopRequireDefault(_ErrorMessage);
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _components = require('web/screens/Login/components');










const ResetPassword = ({
  token,
  onCheckToken,
  onSubmit,
  onUsernameLogin,
  gotoForgotPassword,
  homeUrl,
}) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, true)
  const [userEmail, setUserEmail] = _react.useState.call(void 0, '')
  const [errorCode, setErrorCode] = _react.useState(null)

  _react.useEffect.call(void 0, 
    () => {
      onCheckToken(token)
        .then(r => setUserEmail(r.UserEmail))
        .catch(e => setErrorCode(e.Response ))
        .finally(() => setIsLoading(false))
    },
    [token, onCheckToken],
  )

  const handleSubmitPassword = (password) => onSubmit(password, token)
    .then(() => onUsernameLogin(userEmail, password))

  const renderContent = () => {
    if (isLoading) {
      return (
        _react2.default.createElement('div', { className: "login--loader--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
          , _react2.default.createElement(_Spinner2.default, { className: "login--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
        )
      )
    }

    return errorCode
      ? _react2.default.createElement(_ErrorMessage2.default, { errorCode: errorCode, onRequestLink: gotoForgotPassword, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )
      : _react2.default.createElement(_Form2.default, { email: userEmail, onSubmit: handleSubmitPassword, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )
  }

  return (
    _react2.default.createElement(_components.FormWrapper, { title: "Reset password" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
      ,  renderContent() 
      , _react2.default.createElement('div', { className: "login--username--links-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , _react2.default.createElement('a', { href: homeUrl, target: window.ELECTRON ? '_blank' : '_self', __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}, "Back to BloomText"

        )
      )
    )
  )
}

exports. default = ResetPassword
