"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ServiceScreen/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

var _Idle = require('web/components/Idle'); var _Idle2 = _interopRequireDefault(_Idle);
var _ErrorModal = require('web/screens/ErrorModal'); var _ErrorModal2 = _interopRequireDefault(_ErrorModal);

const IDLE_TIMEOUT = 45 * 1000

const ServiceScreen = () => {
  const [isErrorModalOpen, setIsErrorModalOpen] = _react.useState.call(void 0, false)
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)

  const onLoad = _react.useCallback.call(void 0, 
    () => {
      setIsLoading(true)

      _actions2.default
        .getMe()
        .then(res => {
          const currentUser = res.Me
          const TeamKeys = Object.keys(currentUser.Teams) 
          const OrgKeys = Object.keys(currentUser.Relationships) 

          return Promise.all([
            _actions2.default.getTeams({ TeamKeys }),
            _actions2.default.getOrgs({ OrgKeys }),
          ])
        })
        .then(() => setIsErrorModalOpen(false))
        .catch(() => setIsErrorModalOpen(true))
        .then(() => setIsLoading(false))
    },
    [],
  )

  _react.useLayoutEffect.call(void 0, onLoad, [onLoad])

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Idle2.default, {
        timeout: IDLE_TIMEOUT,
        onActive: _actions2.default.userActive,
        onIdle: _actions2.default.userInactive, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      )
      ,  isErrorModalOpen && (
        _react2.default.createElement(_ErrorModal2.default, {
          isLoading: isLoading,
          btnLabel: "Retry",
          onBtnClick: onLoad, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        )
      )
    )
  )
}

exports. default = ServiceScreen
