"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);




var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);












const dayOfWeekSchema = _joi4.default.object({
  dayOfWeek: _joi4.default.number(),
  availableFrom: _joi3.optionalString.call(void 0, ),
  availableUntil: _joi3.optionalString.call(void 0, ),
}).custom(
  (value, helpers) => {
    const { availableFrom, availableUntil } = value

    if (!availableFrom && !availableUntil) return value
    if (!availableFrom || !availableUntil) return helpers.error('date.invalid')
    const from = _moment2.default.call(void 0, availableFrom, 'HH:mm:ss')
    const until = _moment2.default.call(void 0, availableUntil, 'HH:mm:ss')
    if (from.isAfter(until)) return helpers.error('date.invalidRange')
    return value
  },
)
  .messages({
    'date.invalid': 'Provide both "From" and "To" values',
    'date.invalidRange': 'Provide a valid time range',
  })

const schema = _joi4.default.object({
  autoReplyTimezone: _joi3.requiredString.call(void 0, ),
  autoReplyTeamHours: _joi4.default.array().min(7).max(7).items(dayOfWeekSchema),
})

 const autoReplyScheduleResolver = _joi.joiResolver.call(void 0, schema); exports.autoReplyScheduleResolver = autoReplyScheduleResolver
