"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _reselect = require('reselect');
var _features = require('shared/enum/features'); var _features2 = _interopRequireDefault(_features);




var _versionGuards = require('shared/services/versionGuards');
var _common = require('./common');

 const makeSelectOrgHasFeature = (featureFlag) => _reselect.createSelector.call(void 0, 
  (s, ok) => _common.selectOrgByKey.call(void 0, ok, s),
  org => !!(org
    && _versionGuards.isOrgV1.call(void 0, org)
    && org.EnabledFeatures.find(x => x === featureFlag)
  ),
); exports.makeSelectOrgHasFeature = makeSelectOrgHasFeature

 const selectOrgHasPatientMessagingFeature =
  exports.makeSelectOrgHasFeature.call(void 0, _features2.default.PATIENT_MESSAGING); exports.selectOrgHasPatientMessagingFeature = selectOrgHasPatientMessagingFeature

 const selectOrgHasAdvancedSearch =
  exports.makeSelectOrgHasFeature.call(void 0, _features2.default.ADVANCED_SEARCH); exports.selectOrgHasAdvancedSearch = selectOrgHasAdvancedSearch
