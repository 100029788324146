"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/ShareOverlay.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _ui = require('web/services/ui');

class ShareOverlay extends _react2.default.Component {constructor(...args) { super(...args); ShareOverlay.prototype.__init.call(this);ShareOverlay.prototype.__init2.call(this);ShareOverlay.prototype.__init3.call(this);ShareOverlay.prototype.__init4.call(this);ShareOverlay.prototype.__init5.call(this);ShareOverlay.prototype.__init6.call(this);ShareOverlay.prototype.__init7.call(this);ShareOverlay.prototype.__init8.call(this); }
  __init() {this.rootRef = null}
  __init2() {this.setRootRef = r => this.rootRef = r}
  __init3() {this.rootTouchHandler = e => e.stopPropagation()}

  __init4() {this.componentDidMount = () =>
    this.props.ignoredUIEvents.forEach(e =>
      this.rootRef.addEventListener(e, this.rootTouchHandler),
    )}

  __init5() {this.componentWillUnmount = () =>
    this.props.ignoredUIEvents.forEach(e =>
      this.rootRef.removeEventListener(e, this.rootTouchHandler),
    )}

  __init6() {this.linkTextRef = null}
  __init7() {this.setLinkTextRef = r => this.linkTextRef = r}
  __init8() {this.handleRoomLinkClick = async () => {
    await this.props.onRoomLinkCopy()
    return _ui.selectTextNode.call(void 0, this.linkTextRef)
  }}

  render () {
    const {
      roomLink, onRoomLinkCopy,
      onRoomShare, isNativeSharingAvailable,
    } = this.props

    const btnClassNames = [
      "blmh-button",
      "video-call--button",
      "video-call--on-call--share-btn",
    ]
    const secondaryBtnClassName = _classBuilder2.default.call(void 0, 
      btnClassNames,
      "video-call--on-call--share-btn--secondary",
    )

    return (
      _react2.default.createElement('div', { ref: this.setRootRef, className: "video-call--on-call--share-overlay blm--allow-select" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
        , _react2.default.createElement('span', { className: "video-call--on-call--share-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, "You are the only person in this room."

        )
        , _react2.default.createElement('div', {
          onClick: this.handleRoomLinkClick,
          className: "video-call--on-call--share-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        
          , _react2.default.createElement('img', { src: "/assets/icons/video/link-white.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
          , _react2.default.createElement('span', { ref: this.setLinkTextRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}},  roomLink )
        )
        , _react2.default.createElement('div', { className: "video-call--on-call--share-btns", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          , _react2.default.createElement('a', {
            className: secondaryBtnClassName,
            onClick: isNativeSharingAvailable ? onRoomShare : onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          
            ,  isNativeSharingAvailable
              ? _react2.default.createElement(_react2.default.Fragment, null
                  , _react2.default.createElement('img', { src: "/assets/icons/video/share.svg", alt: "^", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}} ), "Share link"

                )
              : _react2.default.createElement(_react2.default.Fragment, null
                  , _react2.default.createElement('img', { src: "/assets/icons/video/copy-white.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}} ), "Copy link"

                )
            
          )
        )
      )
    )
  }
}

ShareOverlay.propTypes = {
  roomLink: _proptypes2.default.string.isRequired,
  onRoomLinkCopy: _proptypes2.default.func.isRequired,
  onRoomShare: _proptypes2.default.func.isRequired,
  isNativeSharingAvailable: _proptypes2.default.bool.isRequired,
  ignoredUIEvents: _proptypes2.default.array.isRequired,
}

exports. default = ShareOverlay
