"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

 const handleUpgrade = buildInfo => {
  if (!exports.shouldAttemptUpgrade.call(void 0, buildInfo)) return
  log.info(`Update from ${VERSION} to ${buildInfo.GitHash} available`)
  _actions2.default.markAppOutdated()
}; exports.handleUpgrade = handleUpgrade

 const getActiveBuildConfig = cfgs =>
  cfgs.find(cfg => cfg.Domain === getDomain()); exports.getActiveBuildConfig = getActiveBuildConfig

 const shouldAttemptUpgrade = buildInfo =>
  BUNDLE === 'web'
  && VERSION !== 'dev'
  && buildInfo.Domain === getDomain()
  && buildInfo.GitHash !== VERSION; exports.shouldAttemptUpgrade = shouldAttemptUpgrade

const getDomain = () => {
  if (navigator.product === 'ReactNative') return 'mobile'
  if (location.hostname.startsWith('app')) return 'prod'
  if (location.hostname.startsWith('staging')) return 'staging'
  return 'dev'
}
