"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/SimpleSelect.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);














const SimpleSelect = ({
  value,
  onChange,
  options,
  className,
  disabled,
}) => {
  const handleChange = _react.useCallback.call(void 0, e => onChange(e.target.value), [onChange])

  return (
    _react2.default.createElement('select', {
      value: value,
      disabled: disabled,
      onChange: handleChange,
      className: _classBuilder2.default.call(void 0, 'blm-simple-select', className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
    
      ,  options
        .map(o => (
          _react2.default.createElement('option', {
            key: o.value,
            value: o.value,
            hidden: !o.value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
          
            ,  o.label 
          )
        ))
      
    )
  )
}

exports. default = SimpleSelect
