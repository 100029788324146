"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/ProgressBar/ProgressSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _broadcast = require('shared/enum/broadcast');

var _Tooltip = require('web/components/Tooltip');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const classNameSuffixMap = {
  [_broadcast.broadcastMsgStatus.NOT_SCHEDULED]: 'not-scheduled',
  [_broadcast.broadcastMsgStatus.QUEUED]: 'queued',
  [_broadcast.broadcastMsgStatus.PROCESSING]: 'processing',
  [_broadcast.broadcastMsgStatus.FAILED]: 'failed',
  [_broadcast.broadcastMsgStatus.COMPLETED]: 'completed',
}

const labelsMap = {
  [_broadcast.broadcastMsgStatus.NOT_SCHEDULED]: 'not scheduled',
  [_broadcast.broadcastMsgStatus.QUEUED]: 'queued',
  [_broadcast.broadcastMsgStatus.PROCESSING]: 'processing',
  [_broadcast.broadcastMsgStatus.FAILED]: 'failed',
  [_broadcast.broadcastMsgStatus.COMPLETED]: 'completed',
}








const ProgressSection = ({
  count,
  totalCount,
  type,
  onClick,
}) => {
  const style = {
    width: `${(count / totalCount) * 100}%`,
    ...(!count && { minWidth: 0, margin: 0, borderWidth: 0 }),
  }

  return (
    _react2.default.createElement('div', {
      style: style,
      onClick: onClick,
      role: "button",
      className: _classBuilder2.default.call(void 0, 
        "broadcast--progress-bar",
        `broadcast--progress-bar--${classNameSuffixMap[type]}`,
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
    
      , _react2.default.createElement(_Tooltip.HoverTooltip, {
        place: "top",
        type: "dark",
        content: 
          _react2.default.createElement('span', { className: "campaign--table--tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
            , count, " " , labelsMap[type]
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      
        , _react2.default.createElement('span', { className: "broadcast--progress-bar--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          ,  count , " "
          , _react2.default.createElement('span', { className: "broadcast--progress-bar--label-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            ,  labelsMap[type] 
          )
        )
      )
    )
  )
}

exports. default = ProgressSection
