"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/FirstTimeTooltips.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _ = require('web/components/Tooltip/');

var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _firstTimeTutorial = require('web/actions/firstTimeTutorial');
var _firstTimeTutorial3 = require('web/reducers/firstTimeTutorial');

const shouldRenderTooltip = (activeInteractable, tooltipId) =>
  activeInteractable &&
  activeInteractable.id === tooltipId &&
  activeInteractable.isPermitted

const mapState = state => ({ interactable: _firstTimeTutorial3.selectActiveInteractable.call(void 0, state) })

 const WithFirstChatTooltip = _reactredux.connect.call(void 0, mapState)(
  ({ children, interactable }) => {
    const tooltipId = _firstTimeTutorial.firstTimeTooltipIds.FIRST_CHAT
    if (!shouldRenderTooltip(interactable, tooltipId)) return children

    const hint = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_.TooltipTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, "You've just got a new message"     )
        , _react2.default.createElement(_.TooltipInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Click on it to read the conversation. All your future chats will appear in this list."               )
      )
    )

    return (
      _react2.default.createElement(_.ControlledTooltip, {
        type: "light",
        place: "right",
        isOpen: true,
        isAnimated: true,
        hasCloseButton: true,
        content: hint,
        tooltipClassName: "first-time--tooltip",
        onClose: () => _actions2.default.dismissInteractableById(_firstTimeTutorial.firstTimeTooltipIds.FIRST_CHAT), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      
        , children
      )
    )
  }); exports.WithFirstChatTooltip = WithFirstChatTooltip

 const WithCreateNewButtonTooltip = _reactredux.connect.call(void 0, mapState)(
  ({ children, interactable }) => {
    const tooltipId = _firstTimeTutorial.firstTimeTooltipIds.CREATE_NEW_BUTTON
    if (!shouldRenderTooltip(interactable, tooltipId)) return children
    const hint = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_.TooltipTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, "Send a new message"   )
        , _react2.default.createElement(_.TooltipInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}, "Your coworkers and patients are here. To send a message click this button."            )
      )
    )

    return (
      _react2.default.createElement(_.ControlledTooltip, {
        type: "light",
        place: "bottom",
        isOpen: true,
        isAnimated: true,
        hasCloseButton: true,
        content: hint,
        tooltipClassName: "first-time--tooltip",
        onClose: () => _actions2.default.dismissInteractableById(tooltipId), __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      
        , children
      )
    )
  }); exports.WithCreateNewButtonTooltip = WithCreateNewButtonTooltip

 const WithSwitchInboxTooltip = _reactredux.connect.call(void 0, mapState)(
  ({ children, interactable }) => {
    const tooltipId = _firstTimeTutorial.firstTimeTooltipIds.SWITCH_INBOX
    if (!shouldRenderTooltip(interactable, tooltipId)) return children

    const hint = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_.TooltipTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}, "Switch to another Inbox"   )
        , _react2.default.createElement(_.TooltipInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}, "You can jump to any team's inbox so it's easier to find chats between specific teams and patients."                 )
      )
    )

    return (
      _react2.default.createElement(_.ControlledTooltip, {
        type: "light",
        place: "bottom",
        isOpen: shouldRenderTooltip(interactable, tooltipId),
        isAnimated: true,
        hasCloseButton: true,
        content: hint,
        tooltipClassName: "first-time--tooltip",
        onClose: () => _actions2.default.dismissInteractableById(tooltipId), __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
      
        , children
      )
    )
  }); exports.WithSwitchInboxTooltip = WithSwitchInboxTooltip

 const WithProfileTooltip = _reactredux.connect.call(void 0, mapState)(
  ({ children, interactable }) => {
    const tooltipId = _firstTimeTutorial.firstTimeTooltipIds.PROFILE
    if (!shouldRenderTooltip(interactable, tooltipId)) return children

    const hint = (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_.TooltipTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}, "Access your Profile"  )
        , _react2.default.createElement(_.TooltipInfo, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}, "Change your profile avatar and email, add phone numbers, manage your organization members and much more in Settings."                 )
      )
    )

    return (
      _react2.default.createElement(_.ControlledTooltip, {
        type: "light",
        place: "bottom",
        isOpen: true,
        isAnimated: true,
        hasCloseButton: true,
        content: hint,
        tooltipClassName: "first-time--tooltip",
        onClose: () => _actions2.default.dismissInteractableById(tooltipId), __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
      
        , children
      )
    )
  }); exports.WithProfileTooltip = WithProfileTooltip
