"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/RightSide/RightSide.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





const RightSide = ({
  className,
  children,
  inView,
  ...props
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, 'right-side', inView && 'in-view', className)

  return (
    _react2.default.createElement('div', { className: rootClassName, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
      ,  children 
    )
  )
}

exports. default = RightSide
