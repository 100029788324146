"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/segments/RoomSidebar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }// Desktop's sidebar
var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _videoRoom = require('web/selectors/videoRoom');

var _RoomSidebar = require('web/components/VideoCall/RoomSidebar'); var _RoomSidebar2 = _interopRequireDefault(_RoomSidebar);

const RoomSidebarContainer = ({
  onRoomLinkCopy, roomName, roomLink,
}) => (
  _react2.default.createElement(_RoomSidebar2.default, {
    roomName: roomName,
    roomLink: roomLink,
    onRoomLinkCopy: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
  )
)

const mapState = state => ({
  roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
  roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(RoomSidebarContainer)
