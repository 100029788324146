"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/ParticipantsBoard/Participant.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _SoundAmplitude = require('web/components/VideoCall/SoundAmplitude'); var _SoundAmplitude2 = _interopRequireDefault(_SoundAmplitude);
var _AudioFeed = require('web/components/VideoCall/AudioFeed'); var _AudioFeed2 = _interopRequireDefault(_AudioFeed);
var _VideoFeed = require('web/components/VideoCall/VideoFeed'); var _VideoFeed2 = _interopRequireDefault(_VideoFeed);
var _Spinner = require('web/components/Spinner'); var _Spinner2 = _interopRequireDefault(_Spinner);
var _videoCall = require('web/services/videoCall');
var _Tooltip = require('web/components/Tooltip');

const noop = () => {}

 class Participant extends _react2.default.Component {constructor(...args) { super(...args); Participant.prototype.__init.call(this);Participant.prototype.__init2.call(this);Participant.prototype.__init3.call(this);Participant.prototype.__init4.call(this);Participant.prototype.__init5.call(this);Participant.prototype.__init6.call(this); }
  __init() {this.audioStream = new MediaStream()}
  __init2() {this.videoStream = new MediaStream()}

  __init3() {this.handleParticipantPick = () => {
    const { participantKey, onParticipantPick } = this.props
    onParticipantPick(participantKey)
  }}

  __init4() {this.componentDidUpdate = prevProps => {
    const { audioTrack, videoTrack } = this.props

    if (audioTrack && audioTrack !== prevProps.audioTrack) {
      this.audioStream.getTracks().forEach(t => this.audioStream.removeTrack(t))
      this.audioStream = new MediaStream([audioTrack])
      this.forceUpdate()
    }
    if (videoTrack && videoTrack !== prevProps.videoTrack) {
      this.videoStream.getTracks().forEach(t => this.videoStream.removeTrack(t))
      this.videoStream = new MediaStream([videoTrack])
      this.forceUpdate()
    }
  }}

  __init5() {this.componentDidMount = () => {
    const { audioTrack, videoTrack } = this.props

    audioTrack && this.audioStream.addTrack(audioTrack)
    videoTrack && this.videoStream.addTrack(videoTrack)
  }}

  __init6() {this.componentWillUnmount = () => [this.audioStream, this.videoStream]
    .forEach(s =>
      s.getTracks().forEach(t => s.removeTrack(t)),
    )}

  render () {
    const {
      name, isScreenParticipant,
      isMe, isActive,
      audioTrack, videoTrack,
      isLoading,
      problemCode,
    } = this.props

    const participantClassName = _classBuilder2.default.call(void 0, 
      "video-call--participant",
      { active: isActive },
    )

    const feedWrapperClassName = _classBuilder2.default.call(void 0, 
      "video-call--participant-feed-wrapper",
      { 'audio-only': !videoTrack },
    )

    const issueTooltipContent = (
      _react2.default.createElement(_Tooltip.TooltipContent, { className: "video-call--participant--problem-tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        ,  _videoCall.formatDisplayErrorMessage.call(void 0, name, problemCode, isMe) 
      )
    )

    return (
      _react2.default.createElement('div', {
        className: participantClassName,
        onClick: isLoading ? noop : this.handleParticipantPick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
      
        , _react2.default.createElement('div', { className: feedWrapperClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
          ,  !videoTrack && !isLoading &&
            _react2.default.createElement('img', {
              className: "video-call--participant-feed-stub",
              src: "/assets/icons/video/speaker.svg",
              alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            )
          
          ,  isLoading &&
            _react2.default.createElement(_Spinner2.default, {
              className: "video-call--participant-feed-stub--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            )
          
          , _react2.default.createElement(_VideoFeed2.default, {
            isCover: true,
            shouldMirror: isMe && !isScreenParticipant && _videoCall.shouldMirrorCamFeed.call(void 0, videoTrack),
            wrapperClassName: "video-call--participant-feed",
            stream: this.videoStream, __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
          )
          ,  !isMe && _react2.default.createElement(_AudioFeed2.default, { stream: this.audioStream, __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}} )
        )
        , _react2.default.createElement('div', { className: "video-call--participant-summary", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
          ,  problemCode === _videoCall.problemCodes.NETWORK &&
            _react2.default.createElement(_Tooltip.HoverTooltip, { place: "top", type: "dark", content: issueTooltipContent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
              , _react2.default.createElement('div', { className: "video-call--participant-problem", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}} )
            )
          
          , _react2.default.createElement('span', { className: "video-call--participant-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
            ,  isScreenParticipant ? `[Screen] ${name}` : name 
          )
          ,  isLoading
            ? null
            : audioTrack
              ? _react2.default.createElement(_SoundAmplitude2.default, {
                  className: "video-call--participant-sound-amp",
                  audioTrack: audioTrack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
                )
              : _react2.default.createElement('div', { className: "video-call--participant-mic-warn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}
                  , _react2.default.createElement('img', {
                    src: "/assets/icons/video/mic-off-white-no-outline.svg",
                    alt: "🔇", __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
                  )
                )
          
        )
      )
    )
  }
} exports.default = Participant;
