"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/LinkBar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);



var _ui = require('web/services/ui');

 class LinkBar extends _react2.default.Component {constructor(...args) { super(...args); LinkBar.prototype.__init.call(this);LinkBar.prototype.__init2.call(this);LinkBar.prototype.__init3.call(this); }
  __init() {this.handleClick = async () => {
    const { isNativeSharingAvailable, onShare, onCopy } = this.props
    await (isNativeSharingAvailable ? onShare() : onCopy())
    return _ui.selectTextNode.call(void 0, this.linkTextRef)
  }}

  __init2() {this.linkTextRef = null}
  __init3() {this.setLinkTextRef = r => this.linkTextRef = r}

  render () {
    const {
      link,
      className,
      iconClassName,
      isIconLabelShown,
    } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, 'meeting--link-bar', className)
    const iconWrapperClassName = _classBuilder2.default.call(void 0, 
      'meeting--link-bar--icon',
      { sharing: _ui.isNativeSharingAvailable },
      iconClassName,
    )
    const iconLabelClassName = _classBuilder2.default.call(void 0, 
      'meeting--link-bar--icon--label',
      { hidden: !isIconLabelShown },
    )

    return (
      _react2.default.createElement('div', {
        onClick: this.handleClick,
        className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
      
        , _react2.default.createElement('span', {
          ref: this.setLinkTextRef,
          className: "meeting--link-bar--link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        
          , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}, link)
        )
        , _react2.default.createElement('div', { className: iconWrapperClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
          ,  isIconLabelShown &&
            _react2.default.createElement('span', { className: iconLabelClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
              , 
                _ui.isNativeSharingAvailable
                  ? 'Share link'
                  : 'Copy link'
              
            )
          
        )
      )
    )
  }
} exports.default = LinkBar;
