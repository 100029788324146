"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }



var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);



 const initialState = {}; exports.initialState = initialState

 function userReducer (
  state = exports.initialState,
  action,
  globalState,
) {
  let myUserKey = null
  switch (action.type) {
    case _actionCreators2.default.loginSuccessAction.type:
      // Set user key (Since the global currentUser reducer isn't populated) and fallthrough
      myUserKey = action.payload.User.UserKey
    case _actionCreators2.default.removeUserFromOrgActions.SUCCESS.type:
    case _actionCreators2.default.addUsersToOrgActions.SUCCESS.type:
    case _actionCreators2.default.createPatientActions.SUCCESS.type:
    case _actionCreators2.default.updateOrgUsersNotificationAction.type:

    /* patient-ui */
    case _actionCreators2.default.loadPatientConversationByCodeActions.SUCCESS.type:
    case _actionCreators2.default.getPatientConversationMessagesActions.SUCCESS.type:
    case _actionCreators2.default.changeUserOrgRoleActions.SUCCESS.type:
    case _actionCreators2.default.changeUserOrgTitleActions.SUCCESS.type: {
      if (!myUserKey) {
        myUserKey = _nullishCoalesce((_optionalChain([globalState, 'optionalAccess', _3 => _3.currentUser, 'optionalAccess', _4 => _4.UserKey]) ), () => ( null))
      }
      const newUsers = { ...state }

      const users = (action.payload.users || action.payload.Users) 

      Object.keys(users).forEach(key => {
        const k = key 
        let defaultOrgKey = null
        const rels = _optionalChain([users, 'access', _5 => _5[k], 'optionalAccess', _6 => _6.Relationships]) || {}
        const firstOrgKey = Object.keys(rels).find((r) => {
          defaultOrgKey = r

          // TODO: Not all of these responses include "yourself," required by myUserKey on line 40, so bail out before crashing --- why do we assume this?
          if (!myUserKey || !users[myUserKey]) return r

          return Object
            .keys(rels)
            .find(myR => r === myR)
        })

        if (!firstOrgKey && !defaultOrgKey) {
          return
        }

        newUsers[k] = users[k]
      })

      return newUsers
    }
    case _actionCreators2.default.setAvatarActions.SUCCESS.type: {
      if (!_optionalChain([globalState, 'optionalAccess', _7 => _7.currentUser, 'optionalAccess', _8 => _8.UserKey])) return state
      return {
        ...state,
        [globalState.currentUser.UserKey]: {
          ...state[globalState.currentUser.UserKey ],
          ProfilePictureRef: action.meta.Ref,
        },
      }
    }
    case _actionCreators2.default.getQuickLoginUsersActions.SUCCESS.type:
      return { ...state, ...action.payload.Users }

    case _actionCreators2.default.logoutAction.type: return exports.initialState
  }

  return state
} exports.default = userReducer;
