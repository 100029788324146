"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

const useDeleteTeam = (teamKey) => {
  const {
    isOpen: isDeleteModalOpen,
    onOpen: onDelete,
    onClose: onCloseDeleteModal,
  } = _useToggle2.default.call(void 0, )

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onConfirmDelete = _react.useCallback.call(void 0, 
    () => _actions2.default.removeTeam({ TeamKey: teamKey })
      .then(_actions2.default.gotoDirectory)
      .then(() => showSuccessBar('Team has been deleted'))
      .catch(() => showWarningBar('Could not delete team')),
    [teamKey, showWarningBar, showSuccessBar],
  )

  return {
    isDeleteModalOpen,
    onDelete,
    onCloseDeleteModal,
    onConfirmDelete,
  }
}

exports. default = useDeleteTeam
