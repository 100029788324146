"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Notifications/Sections/DNDSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _noticeBar = require('web/services/context/noticeBar');
var _hooks = require('./hooks');
var _notifications = require('shared/hooks/notifications');

var _Form = require('web/components/Form');
var _time = require('shared/services/time');









const tzOptions = (Object.keys(_time.timezoneNames) )
  .map(value => ({ value, label: _time.timezoneNames[value] }))

const DNDSection = ({
  dndStartTime,
  dndEndTime,
  dndTimezone,
  onSubmitDND,
  onDisableDND,
}) => {
  const {
    dndRange,
    timezone,
    isDndEnabled,
    onChangeDNDStatus,
    onChangeStartTime,
    onChangeEndTime,
    onChangeTimezone,
    onSaveDND,
  } = _notifications.useDND.call(void 0, {
    initialStart: dndStartTime,
    initialEnd: dndEndTime,
    initialTimezone: dndTimezone,
    onSubmit: onSubmitDND,
    onDisable: onDisableDND,
  })

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const withDNDNotice = (f) => f()
    .then(() => showSuccessBar('Successfully set Do Not Disturb'))
    .catch(() => showWarningBar('Failed to set Do Not Disturb'))

  const handleSaveDND = () => withDNDNotice(onSaveDND)
  const handleSaveTZ = (tz) => withDNDNotice(() => onChangeTimezone(tz))

  const handleChangeDNDStatus = (isOn) =>
    onChangeDNDStatus(isOn)
      .then(() => showSuccessBar(`Successfully turned ${isOn ? 'on' : 'off'} Do Not Disturb`))
      .catch(() => showWarningBar(`Failed to turn ${isOn ? 'on' : 'off'} Do Not Disturb`))

  const {
    activeTimePicker,
    onCloseTimePicker,
    onOpenStartTimePicker,
    onOpenEndTimePicker,
  } = _hooks.useActivePicker.call(void 0, handleSaveDND)

  const [dndStart, dndEnd] = dndRange

  return (
    _react2.default.createElement('div', { className: "settings--notifications--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      , _react2.default.createElement('h5', { className: "settings--notifications--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, "Do Not Disturb"  )
      , _react2.default.createElement(_Form.Toggle, {
        isChecked: isDndEnabled,
        onChange: handleChangeDNDStatus,
        label: "Mute push notifications on all devices between..."      ,
        shouldCaptureFocus: !activeTimePicker, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
      )
      , _react2.default.createElement('div', { className: "settings--notifications--dnd-time-group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        , _react2.default.createElement('div', { className: "settings--notifications--dnd-time-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
          , _react2.default.createElement('div', { className: "settings--notifications--dnd-picker-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            , _react2.default.createElement(_Form.TimeInput, {
              isRequired: true,
              isOpen: activeTimePicker === 'start',
              onOpen: onOpenStartTimePicker,
              onClose: onCloseTimePicker,
              onChange: onChangeStartTime,
              className: "settings--notifications--dnd-picker",
              value: dndStart,
              disabled: !isDndEnabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
            )
          )
          , _react2.default.createElement('span', { className: "settings--notifications--dnd-picker-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, "to")
          , _react2.default.createElement('div', { className: "settings--notifications--dnd-picker-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
            , _react2.default.createElement(_Form.TimeInput, {
              isRequired: true,
              isOpen: activeTimePicker === 'end',
              onOpen: onOpenEndTimePicker,
              onClose: onCloseTimePicker,
              onChange: onChangeEndTime,
              className: "settings--notifications--dnd-picker",
              value: dndEnd,
              disabled: !isDndEnabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
            )
          )
        )
        , _react2.default.createElement('div', { className: "settings--notifications--dnd-tz-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}}
          , _react2.default.createElement(_Form.SimpleSelect, {
            options: tzOptions,
            value: timezone,
            onChange: handleSaveTZ,
            disabled: !isDndEnabled,
            className: "settings--notifications--dnd-tz-picker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
          )
        )
      )
    )
  )
}

exports. default = DNDSection
