"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Forms/Coworker.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');



var _coworker = require('shared/validation/invite/coworker');
var _org = require('shared/selectors/org');
var _user = require('shared/selectors/user');




var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');












const CoworkerForm = ({
  submitBtnLabel,
  orgName,
  amIAnAdmin,
  onCancel,
  onSubmit,
  controlsClassName,
  defaultEmail,
  defaultFirstName,
  defaultLastName,
}) => {
  const { register, control, handleSubmit, formState } =
    _reacthookform.useForm({
      resolver: _coworker.coworkerResolver,
      mode: 'onChange',
      defaultValues: {
        firstName: defaultFirstName || '',
        lastName: defaultLastName || '',
        title: '',
        isAdmin: false,
        email: defaultEmail || '',
      },
    })
  const { isValid, isSubmitting, isDirty, errors } = formState
  _react.useEffect.call(void 0, () => { register('isAdmin') }, [register])

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  orgName && (
        _react2.default.createElement('span', { className: "directory--create-new--notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "This coworker will be invited to "
                , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, orgName), "."
        )
      )
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
        , _react2.default.createElement(_Form.InputSection, {
          autoFocus: !defaultFirstName,
          title: "First Name" ,
          placeholder: "E.g. John" ,
          inputProps: register('firstName'),
          errorMessage: _optionalChain([errors, 'access', _ => _.firstName, 'optionalAccess', _2 => _2.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        )
        , _react2.default.createElement(_Form.InputSection, {
          title: "Last Name" ,
          placeholder: "E.g. Parker" ,
          inputProps: register('lastName'),
          errorMessage: _optionalChain([errors, 'access', _3 => _3.lastName, 'optionalAccess', _4 => _4.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        )
      )
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: !!(defaultFirstName && defaultLastName),
        isOptional: true,
        title: "Title",
        placeholder: "E.g. Head Nurse"  ,
        inputProps: register('title'),
        errorMessage: _optionalChain([errors, 'access', _5 => _5.title, 'optionalAccess', _6 => _6.message]),
        className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
      )
      , _react2.default.createElement(_Form.InputSection, {
        title: "Email",
        placeholder: "E.g. john@email.com" ,
        inputProps: register('email'),
        errorMessage: _optionalChain([errors, 'access', _7 => _7.email, 'optionalAccess', _8 => _8.message]),
        className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
      )
      ,  amIAnAdmin && (
        _react2.default.createElement(_Form.InputSectionWrapper, { title: "Role", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
          , _react2.default.createElement(_Form.ToggleController, {
            control: control,
            name: "isAdmin",
            label: "Administrator",
            hint: "Administrators can manage this organization's subscription, change organization settings, and manage other members."            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
          )
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: submitBtnLabel,
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        onPrimaryBtnClick: handleSubmit(onSubmit),
        isPrimaryLoading: isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onCancel,
        className: controlsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
      )
    )
  )
}






const mapState = (state, { orgKeyContext }) => ({
  orgName: orgKeyContext && _optionalChain([_org.selectOrgByKey.call(void 0, orgKeyContext, state), 'optionalAccess', _9 => _9.Name]) || '',
  amIAnAdmin: _user.selectAmIAdminInOrg.call(void 0, state, orgKeyContext),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CoworkerForm)
