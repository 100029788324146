"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _lodash = require('lodash');
var _reselect = require('reselect');




var _peers = require('shared/selectors/peers');
var _user = require('shared/selectors/user');

 const selectTeams = (state) => _optionalChain([state, 'optionalAccess', _ => _.dataV2, 'optionalAccess', _2 => _2.teams]); exports.selectTeams = selectTeams
 const selectAllTeams = exports.selectTeams; exports.selectAllTeams = selectAllTeams

 const selectTeamByKey = (state, teamKey) =>
  _nullishCoalesce(_optionalChain([state, 'optionalAccess', _3 => _3.dataV2, 'optionalAccess', _4 => _4.teams, 'optionalAccess', _5 => _5[teamKey]]), () => ( null)); exports.selectTeamByKey = selectTeamByKey

 const makeSelectIsTeamOnline = () => _reselect.createSelector.call(void 0, 
  exports.selectTeamByKey,
  _peers.selectAllUsers,
  _user.selectCurrentUserKey,
  (team, allUsers, myKey) => Object.values(_optionalChain([team, 'optionalAccess', _6 => _6.Members]) || {})
    .some(u => u.UserKey !== myKey && _optionalChain([allUsers, 'access', _7 => _7[u.UserKey], 'optionalAccess', _8 => _8.Online])),
); exports.makeSelectIsTeamOnline = makeSelectIsTeamOnline

 const selectIsTeamAutoReplyOn = (state, teamKey) =>
  !!_optionalChain([exports.selectTeamByKey.call(void 0, state, teamKey), 'optionalAccess', _9 => _9.AutoReply]); exports.selectIsTeamAutoReplyOn = selectIsTeamAutoReplyOn

 const selectTeamAutoReplyMessage = (state, teamKey) =>
  _nullishCoalesce(_optionalChain([exports.selectTeamByKey.call(void 0, state, teamKey), 'optionalAccess', _10 => _10.AutoReplyMessage]), () => ( '')); exports.selectTeamAutoReplyMessage = selectTeamAutoReplyMessage

 const selectBusyReplyMessage = (state, teamKey) =>
  _nullishCoalesce(_optionalChain([exports.selectTeamByKey.call(void 0, state, teamKey), 'optionalAccess', _11 => _11.BusyReplyMessage]), () => ( '')); exports.selectBusyReplyMessage = selectBusyReplyMessage

 const selectAreTeamHoursSet = _reselect.createSelector.call(void 0, 
  exports.selectTeamByKey,
  team => !_lodash.isEmpty.call(void 0, _optionalChain([team, 'optionalAccess', _12 => _12.TeamHours])),
); exports.selectAreTeamHoursSet = selectAreTeamHoursSet

 const selectTeamHoursList = _reselect.createSelector.call(void 0, 
  exports.selectTeamByKey,
  team => {
    const hrsMap = Object.values(_nullishCoalesce(_optionalChain([team, 'optionalAccess', _13 => _13.TeamHours]), () => ( {})))
      .reduce(
        (acc, th) => {
          acc[th.DayOfWeek] = th
          return acc
        },
        {} ,
      )

    return Array(7).fill(0).map((_d, i) => hrsMap[i] || null)
  },
); exports.selectTeamHoursList = selectTeamHoursList
