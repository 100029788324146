"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _LinkBar = require('./LinkBar'); var _LinkBar2 = _interopRequireDefault(_LinkBar);

var _AddAdmins = require('web/screens/VideoRooms/Modals/AddAdmins'); var _AddAdmins2 = _interopRequireDefault(_AddAdmins);



var _AdminPreview = require('web/screens/VideoRooms/AdminPreview');

class MeetingRoom extends _react2.default.Component {constructor(...args) { super(...args); MeetingRoom.prototype.__init.call(this);MeetingRoom.prototype.__init2.call(this);MeetingRoom.prototype.__init3.call(this); }
  __init() {this.state = { isAddAdminsModalOpen: false }}

  __init2() {this.openAddAdminsModal = () => this.setState({ isAddAdminsModalOpen: true })}
  __init3() {this.closeAddAdminsModal = () => this.setState({ isAddAdminsModalOpen: false })}

  render () {
    const {
      onRoomShare,
      onRoomLinkCopy,
      roomKey,
      roomLink,
      roomAdminsLists,
      onRemoveAdmin,
    } = this.props

    const { UserAdmins = [], TeamAdminKeys = [] } = roomAdminsLists
    const { isAddAdminsModalOpen } = this.state

    return (
      _react2.default.createElement('div', { className: "meeting-room", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        , _react2.default.createElement('div', { className: "meeting--management--section meeting--management--link-section" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
          , _react2.default.createElement('h4', { className: "meeting--management--section--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, "Meeting Link" )
          , _react2.default.createElement(_LinkBar2.default, {
            link: roomLink,
            className: "meeting--management--link-bar",
            onShare: onRoomShare,
            onCopy: onRoomLinkCopy, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          )
        )
        , _react2.default.createElement('div', { className: "meeting--management--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          , _react2.default.createElement('h4', { className: "meeting--management--section--title meeting--management--section--title--admins" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}, "Hosts ("
             ,  UserAdmins.length + TeamAdminKeys.length , ")"
          )
          , _react2.default.createElement('div', { className: "meeting--management--admins-list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
            , 
              UserAdmins.map(({ UserKey, OrgKey }) => (
                _react2.default.createElement(_AdminPreview.AdminUserPreview, {
                  key: UserKey,
                  userKey: UserKey,
                  orgKey: OrgKey,
                  onRemove: () => onRemoveAdmin(UserKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
                )
              ))
            
            , 
              TeamAdminKeys.map(teamKey => (
                _react2.default.createElement(_AdminPreview.AdminTeamPreview, {
                  key: teamKey,
                  teamKey: teamKey,
                  onRemove: () => onRemoveAdmin(teamKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
                )
              ))
            
            , _react2.default.createElement('span', {
              onClick: this.openAddAdminsModal,
              className: "meeting--management--admins-add", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
            , "Add hosts"

            )
          )
          , _react2.default.createElement('span', { className: "meeting--management--note", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}, "Hosts approve requests to join a meeting, and can remove anyone from an ongoing meeting."

          )
        )
        , _react2.default.createElement(_AddAdmins2.default, {
          isOpen: isAddAdminsModalOpen,
          onClose: this.closeAddAdminsModal,
          roomKey: roomKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
        )
      )
    )
  }
}

MeetingRoom.propTypes = {
  onRoomShare: _proptypes2.default.func.isRequired,
  onRoomLinkCopy: _proptypes2.default.func.isRequired,
  roomKey: _proptypes2.default.string.isRequired,
  roomLink: _proptypes2.default.string.isRequired,
  roomAdminsLists: _proptypes2.default.exact({
    UserAdmins: _proptypes2.default.array.isRequired,
    TeamAdminKeys: _proptypes2.default.array.isRequired,
  }),
  onRemoveAdmin: _proptypes2.default.func.isRequired,
}

exports. default = MeetingRoom
