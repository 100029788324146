"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');



var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

const useConfirm = (
  setSelectedUser,
  onConfirm,
) => {
  const {
    isOpen: isConfirmModalOpen,
    onOpen: onOpenConfirmModal,
    onClose: onCloseConfirmModal,
  } = _useToggle2.default.call(void 0, )

  const onRequest = _react.useCallback.call(void 0, 
    (u) => { setSelectedUser(u); onOpenConfirmModal() },
    [onOpenConfirmModal, setSelectedUser],
  )

  return {
    isConfirmModalOpen,
    onCloseConfirmModal,
    onRequest,
    onConfirm,
  }
}

exports. default = useConfirm
