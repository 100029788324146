"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Feedback/Modals/Rating.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _RatingMeter = require('web/components/VideoCall/Feedback/RatingMeter'); var _RatingMeter2 = _interopRequireDefault(_RatingMeter);
var _LowRatingReason = require('./LowRatingReason'); var _LowRatingReason2 = _interopRequireDefault(_LowRatingReason);
var _withFeedbackController = require('web/screens/VideoCall/Feedback/withFeedbackController'); var _withFeedbackController2 = _interopRequireDefault(_withFeedbackController);
var _helpers = require('web/components/VideoCall/Feedback/helpers');

class RatingModal extends _react2.default.Component {constructor(...args) { super(...args); RatingModal.prototype.__init.call(this);RatingModal.prototype.__init2.call(this);RatingModal.prototype.__init3.call(this);RatingModal.prototype.__init4.call(this);RatingModal.prototype.__init5.call(this); }
  __init() {this.state = {
    currentRating: 0,
    isLowRatingReasonModalOpen: false,
  }}

  __init2() {this.handleRatingUpdate = n => !this.props.isRatingSubmitted &&
    this.setState({ currentRating: n })}

  __init3() {this.onProceed = () => {
    const { submitRating, onClose } = this.props
    submitRating(this.state.currentRating)
      .then(onClose)
      .catch(this.openLowRatingReasonModal)
  }}

  __init4() {this.openLowRatingReasonModal = () => this.setState({
    isLowRatingReasonModalOpen: true,
  })}

  __init5() {this.closeLowRatingReasonModal = () => this.setState({
    isLowRatingReasonModalOpen: false,
  })}

  render () {
    const { currentRating, isLowRatingReasonModalOpen } = this.state
    const {
      isOpen, onClose,
      lowRatingReasons,
      isRatingSubmitted,
      submitLowRatingReason,
      submitLowRatingComment,
      sessionDuration,
    } = this.props

    const backBtnClassName = _classBuilder2.default.call(void 0, 
      "meeting--btn", "secondary",
      "meeting--feedback--rating-modal--back-btn",
      { single: isRatingSubmitted },
    )
    const proceedBtnClassName = _classBuilder2.default.call(void 0, 
      "meeting--btn",
      "meeting--feedback--rating-modal--proceed-btn",
      { hidden: isRatingSubmitted },
      { disabled: currentRating === 0 },
    )
    const noteClassName = _classBuilder2.default.call(void 0, 
      "meeting--feedback--rating-modal--note",
      { hidden: !isRatingSubmitted },
    )

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Modal2.default, {
          isOpen: isOpen && !isLowRatingReasonModalOpen,
          onClose: onClose,
          className: "meeting--feedback--rating-modal",
          title: "Call finished" ,
          size: "xs", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        
          , _react2.default.createElement('span', { className: "meeting--feedback--rating-modal--duration", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            ,  _helpers.formatSessionDuration.call(void 0, sessionDuration) 
          )
          , _react2.default.createElement('h3', { className: "meeting--feedback--rating-modal--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}, "How was your call?"

          )
          , _react2.default.createElement(_RatingMeter2.default, {
            currentRating: currentRating,
            onRatingUpdate: this.handleRatingUpdate,
            className: "meting--feedback--rating-modal--meter",
            itemClassName: "meting--feedback--rating-modal--meter-item",
            onRatingSubmit: this.onProceed,
            isSubmitted: isRatingSubmitted, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
          )
          , _react2.default.createElement('span', { className: noteClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}, "Your feedback was submitted."

          )
          , _react2.default.createElement('div', { className: "meeting--feedback--rating-modal--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            , _react2.default.createElement('a', { onClick: onClose, className: backBtnClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, "Close")
            , _react2.default.createElement('a', { onClick: this.onProceed, className: proceedBtnClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "Rate call" )
          )
        )
        , _react2.default.createElement(_LowRatingReason2.default, {
          isOpen: isLowRatingReasonModalOpen,
          onClose: this.closeLowRatingReasonModal,
          onReasonSubmit: submitLowRatingReason,
          onCommentSubmit: submitLowRatingComment,
          lowRatingReasons: lowRatingReasons, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
        )
      )
    )
  }
}

exports. default = _withFeedbackController2.default.call(void 0, RatingModal)
