"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Sidebar/BroadcastListHeader/TeamPicker/Content.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _myRelations = require('shared/selectors/myRelations');






const TeamPickerContent = ({
  onSelectTeam,
  teamGroups,
}) => (
  _react2.default.createElement('div', { className: "broadcast--list-header--team-picker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
    , _react2.default.createElement('div', { className: "broadcast--list-header--team-picker-group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      , _react2.default.createElement('span', {
        role: "button",
        className: "broadcast--list-header--team-picker-option",
        onClick: () => onSelectTeam(null), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , "All Campaigns"

      )
    )
    ,  teamGroups.map(g => (
      _react2.default.createElement('div', { key: g.orgKey, className: "broadcast--list-header--team-picker-group", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , _react2.default.createElement('h5', { className: "broadcast--list-header--team-picker-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
          ,  g.orgName 
        )
        ,  g.teamsList.map(t => (
          _react2.default.createElement('span', {
            key: t.TeamKey,
            role: "button",
            className: "broadcast--list-header--team-picker-option",
            onClick: () => onSelectTeam(t.TeamKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
          
            ,  t.Name 
          )
        ))
      )
    ))
  )
)











const mapState = (state) => ({
  teamGroups: _myRelations.selectMyPhoneTeamGroupsList.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(TeamPickerContent)
