"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/TeamPickerController/TeamOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);






var _GenericSelect = require('web/components/Form/GenericSelect');
var _Avatar = require('web/components/Avatar');





const OptionAvatar = ({ option, ...props }) => (
  _react2.default.createElement(_Avatar.TeamAvatar, { teamKey: option.wrappedEntity.TeamKey, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
)

const TeamOption = props => (
  _react2.default.createElement(_GenericSelect.GenericEntityOption, {
    Avatar: OptionAvatar,
    name: props.option.name,
    description: props.option.description,
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
  )
)

exports. default = TeamOption
