"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/OrgsList/OrgsControlsSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _OrgBtn = require('web/components/Settings/OrgsList/OrgBtn'); var _OrgBtn2 = _interopRequireDefault(_OrgBtn);
var _JoinOrgModal = require('./JoinOrgModal'); var _JoinOrgModal2 = _interopRequireDefault(_JoinOrgModal);
var _InviteOrgModal = require('web/screens/Settings/OrgsList/InviteOrgModal'); var _InviteOrgModal2 = _interopRequireDefault(_InviteOrgModal);






const OrgsControlsSection = ({
  onJoinOrCreateOrg,
  hasCurrentUserAnyTeams,
}) => {
  const {
    isOpen: isJoinModalOpen,
    onOpen: onOpenJoinModal,
    onClose: onCloseJoinModal,
  } = _useToggle2.default.call(void 0, )

  const {
    isOpen: isInviteModalOpen,
    onOpen: onOpenInviteModal,
    onClose: onCloseInviteModal,
  } = _useToggle2.default.call(void 0, )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_OrgBtn2.default, {
        className: "settings--orgs-list--join-btn",
        onClick: onOpenJoinModal,
        label: "Join or create an organization"    ,
        iconSrc: "/assets/icons/icon-add-thin-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
      )
      ,  hasCurrentUserAnyTeams && (
        _react2.default.createElement(_OrgBtn2.default, {
          className: "settings--orgs-list--join-btn",
          onClick: onOpenInviteModal,
          label: "Invite an organization to BloomText"    ,
          iconSrc: "/assets/icons/icon-add-thin-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
      )
      , _react2.default.createElement(_JoinOrgModal2.default, {
        isOpen: isJoinModalOpen,
        onClose: onCloseJoinModal,
        onSubmit: onJoinOrCreateOrg, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
      , _react2.default.createElement(_InviteOrgModal2.default, {
        isOpen: isInviteModalOpen,
        onClose: onCloseInviteModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      )
    )
  )
}

exports. default = OrgsControlsSection
