"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');



var _array = require('shared/services/array');

const selectAllDrafts = (state) => state.dataV2.session.conversationDrafts
 const selectDraftByConversationKey = (
  state,
  conversationKey,
) =>
  _optionalChain([selectAllDrafts, 'call', _ => _(state), 'optionalAccess', _2 => _2[conversationKey]]) || ''; exports.selectDraftByConversationKey = selectDraftByConversationKey

 const selectHasAnyDrafts = _toolkit.createSelector.call(void 0, 
  selectAllDrafts,
  drafts => Object
    .values(drafts || {})
    .filter(_array.truthy)
    .length > 0,
); exports.selectHasAnyDrafts = selectHasAnyDrafts
