"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/ExtraPhoneNumber/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _cost = require('shared/services/cost');
var _upgradePlans = require('shared/constants/upgradePlans');

var _components = require('../components');
var _PhoneNumbersSection = require('./PhoneNumbersSection'); var _PhoneNumbersSection2 = _interopRequireDefault(_PhoneNumbersSection);









const ExtraPhoneNumber = ({
  isActive,
  baseCost,
  phoneNumbersCount,
  hasUnclaimedPhoneNumbers,
  onAssignPhoneNumber,
}) => (
  _react2.default.createElement(_components.FeatureItem, {
    title: _upgradePlans.extraPhoneNumberPlan.name,
    desc: "Assign multiple phone numbers to different teams within BloomText"        ,
    iconSrc: _upgradePlans.extraPhoneNumberPlan.iconSrc,
    extra: isActive && hasUnclaimedPhoneNumbers && (
      _react2.default.createElement(_PhoneNumbersSection2.default, { onPickPhone: onAssignPhoneNumber, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
  
    ,  isActive
      ? _react2.default.createElement(_components.PaymentReport, {
          usageCount: phoneNumbersCount,
          title: "number(s)",
          subtitle: `${_cost.formatDisplayCost.call(void 0, baseCost)}/number/month`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
        )
      : null
    
  )
)

exports. default = ExtraPhoneNumber
