"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/HintToggle.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Tooltip = require('web/components/Tooltip');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);










const HintToggle = ({ place, hint, offset, className }) => (
  _react2.default.createElement(_Tooltip.HoverTooltip, {
    type: "light",
    place: place,
    offset: offset,
    content: 
      _react2.default.createElement(_Tooltip.TooltipContent, { className: "blm-hint-toggle--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        ,  hint 
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  
    , _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, "blm-hint-toggle", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "?")
  )
)

exports. default = HintToggle
