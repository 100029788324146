"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/PinInput/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);











 const COVER_CHAR = '●'; exports.COVER_CHAR = COVER_CHAR

const mapValueToState = (value, pinLength) =>
  Array(pinLength).fill('').map((x, i) => value[i] || x)

const PinInput = ({
  value = '',
  isDisabled,
  autoFocus,
  pinLength,
  onChange,
  enableFakePrefill,
  className,
}) => {
  const defaultValues = _react.useMemo.call(void 0, () => mapValueToState(value, pinLength), [value, pinLength])
  const inputRefs = _react.useRef([])
  const [isPrefilled, setIsPrefilled] = _react.useState.call(void 0, enableFakePrefill)
  const [values, setValues] = _react.useState.call(void 0, defaultValues)

  const isMounted = _react.useRef.call(void 0, false)
  _react.useEffect.call(void 0, 
    () => {
      if (isMounted.current) return
      if (autoFocus) _optionalChain([inputRefs, 'access', _ => _.current, 'access', _2 => _2[0], 'optionalAccess', _3 => _3.focus, 'call', _4 => _4()])
      isMounted.current = true
    },
    [autoFocus],
  )

  _react.useLayoutEffect.call(void 0, 
    () => {
      if (isDisabled || !isMounted.current) return
      const inputIdxToFocus = Math.min(value.length, pinLength - 1)
      _optionalChain([inputRefs, 'access', _5 => _5.current, 'access', _6 => _6[inputIdxToFocus], 'optionalAccess', _7 => _7.focus, 'call', _8 => _8()])
      setValues(mapValueToState(value, pinLength))
    },
    [value, isDisabled, pinLength],
  )

  const handleInput = (i, event) => {
    const focusInput = (i) =>
      i >= 0 && i < pinLength && _optionalChain([inputRefs, 'access', _9 => _9.current, 'access', _10 => _10[i], 'optionalAccess', _11 => _11.focus, 'call', _12 => _12()])

    const isBackspace = event.key === "Backspace"
    const isLeftKey = event.key === "ArrowLeft"
    const isRightKey = event.key === "ArrowRight"

    if (isLeftKey) {
      return focusInput(i - 1)
    } else if (isRightKey) {
      return focusInput(i + 1)
    } else if (isBackspace) {
      if (values[i]) values[i] = ''
      else if (i > 0) values[i - 1] = ''
    } else if (event.key.length === 1 && event.key >= '0' && event.key <= '9') {
      values[i] = event.key
    } else {
      event.preventDefault()
    }

    const strValue = values.join('')
    setValues(mapValueToState(strValue, pinLength))
    onChange(strValue)
  }

  const handleFocus = e => {
    setIsPrefilled(false)
    if (!value && e.target !== inputRefs.current[0]) _optionalChain([inputRefs, 'access', _13 => _13.current, 'access', _14 => _14[0], 'optionalAccess', _15 => _15.focus, 'call', _16 => _16()])
  }

  const handleBlur = () => {
    if (!enableFakePrefill) return

    // Need to wait for the nextTick in case blur was
    // initiated by focusing on another pin field
    requestAnimationFrame(() => {
      const isAnyFocused = inputRefs.current
        .some(x => _optionalChain([x, 'optionalAccess', _17 => _17.id]) === _optionalChain([document, 'access', _18 => _18.activeElement, 'optionalAccess', _19 => _19.id]))

      if (!isAnyFocused) setIsPrefilled(true)
    })
  }

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "pin-input--wrapper", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
      , 
        // If only there was a way to prevent Chrome
        // from displaying it's password manager for
        // type='password' fields...
        values.map((v, i) => (
          _react2.default.createElement('input', {
            className: "pin-input",
            autoComplete: "off",
            tabIndex: -1,
            type: "text",
            inputMode: "numeric",
            id: `pin-input--${i}`,
            key: i,
            ref: r => { if (r) inputRefs.current[i] = r },
            onKeyDown: e => handleInput(i, e),
            onFocus: handleFocus,
            onBlur: handleBlur,
            onChange: () => null,
            size: 1,
            value: v || isPrefilled ? exports.COVER_CHAR : '',
            disabled: isDisabled, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
          )
        ))
      
    )
  )
}

exports. default = PinInput
