"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _notifications = require('shared/services/notifications');
var _log = require('shared/log');

const usePlaySoundEffect = () => {
  const audio = _react.useRef(null)

  return _react.useCallback.call(void 0, 
    (fileName) => {
      if (audio.current) audio.current.pause()
      audio.current = new Audio(_notifications.webSoundFileLocation.call(void 0, fileName))
      const played = audio.current.play()
      if (played !== undefined) {
        played.catch(e => _log.log.info("Couldn't play audio", fileName, e))
      }
    },
    [],
  )
}

exports. default = usePlaySoundEffect
