"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');




var _types = require('web/screens/Signup/types');

var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _noticeBar = require('web/services/context/noticeBar');


















 const useSubmit = (token, onSubmitSetup, gotoStep) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const [survey, setSurvey] = _react.useState()

  const onSubmit = _react.useCallback.call(void 0, 
    (personalInfo, password, orgs, orgSurvey) => onSubmitSetup({
      token,
      firstName: personalInfo.firstName,
      lastName: personalInfo.lastName,
      email: personalInfo.email || '',
      phone: personalInfo.phone || '',
      password,
      orgs,
      orgSurvey,
    })
      .then(() => setSurvey(orgSurvey))
      .then(() => gotoStep(_types.SetupSteps.FINISH))
      .catch(err => {
        switch (_optionalChain([err, 'optionalAccess', _ => _.response, 'optionalAccess', _2 => _2.Error])) {
          case "weak password":
            showWarningBar('Please choose a stronger password')
            return gotoStep(_types.SetupSteps.PASSWORD)
          case "invalid phone number":
          case "unsupported region":
            showWarningBar("Your phone number is not supported.")
            return gotoStep(_types.SetupSteps.PERSONAL_INFO)
          case "Token used":
            showWarningBar("Your account was already created.")
            return
          default:
            log.error(err)
            showWarningBar("There was an error setting up your account. Please contact support@bloomtext.com")
        }
      }),
    [token, onSubmitSetup, gotoStep, showWarningBar],
  )

  const onFinish = _react.useCallback.call(void 0, 
    async (personalInfo, password, wasInvitedByPhone) => {
      const loginCredential = wasInvitedByPhone
        ? personalInfo.phone
        : personalInfo.email

      return _client2.default.login(loginCredential, password, false)
        .then(r => {
          const meta = survey ? { survey } : undefined
          _actions2.default.loginSuccess(r, meta)
          _actions2.default.gotoInbox()
        })
        .catch(err => {
          showWarningBar('There was an error logging in. Please contact support@bloomtext.com')
          log.error(err)
        })
    },
    [showWarningBar, survey],
  )

  return { onSubmit, onFinish }
}; exports.useSubmit = useSubmit
