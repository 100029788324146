"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/Modals/Netstats.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modals = require('web/components/VideoCall/Modals');

const formatBits = (rawBits, decimals = 2) => {
  const bits = rawBits || 0
  if (bits === 0) return '0 bit'

  const k = 1024
  const dm = Math.max(decimals, 0)
  const sizes = ['bit', 'kbit', 'Mbit', 'Gbit']

  const i = Math.floor(Math.log(bits) / Math.log(k))

  return `${parseFloat((bits / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

const formatLoss = l => ((l || 0) * 100).toFixed(2)

exports. default = ({ isOpen, onClose, stats, participants }) => {
  const {
    latest = {},
    quality,
    worstVideoRecvPacketLoss,
    worstVideoSendPacketLoss,
  } = stats

  const bpsUp = formatBits(latest.sendBitsPerSecond)
  const bpsDown = formatBits(latest.recvBitsPerSecond)
  const lossUp = formatLoss(latest.videoSendPacketLoss)
  const lossDown = formatLoss(latest.videoRecvPacketLoss)
  const worstLossUp = formatLoss(worstVideoSendPacketLoss)
  const worstLossDown = formatLoss(worstVideoRecvPacketLoss)

  const videoTracksStats = Object
    .values(participants)
    .map(({ name, videoTrack }) => {
      const { width, height, frameRate } = videoTrack
        ? videoTrack.getSettings()
        : { width: 0, height: 0, frameRate: 0 }

      return { name, width, height, frameRate }
    })

  return (
    _react2.default.createElement(_Modals.NetstatsModal, {
      isOpen: isOpen,
      onClose: onClose,
      quality: quality,
      bpsUp: bpsUp,
      bpsDown: bpsDown,
      lossUp: lossUp,
      lossDown: lossDown,
      worstLossUp: worstLossUp,
      worstLossDown: worstLossDown,
      videoTracksStats: videoTracksStats, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
    )
  )
}
