"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/History/Mobile/ParticipantDetails.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

exports. default = ({
  participantName,
  startedAt,
  duration,
  rating,
}) => (
  _react2.default.createElement('div', { className: "meeting-room--mobile-history--details-session", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
    , _react2.default.createElement('div', { className: "meeting-room--mobile-history--session-main-col", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
      , _react2.default.createElement('div', { className: "meeting-room--mobile-history--session-participant-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
        , _react2.default.createElement('span', { className: "meeting-room--mobile-history--session-participant-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
          ,  participantName || 'Unknown' 
        )
        , _react2.default.createElement('span', { className: "meeting-room--mobile-history--session-time", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
          ,  startedAt 
        )
      )
    )
    , _react2.default.createElement('div', { className: "meeting-room--mobile-history--session-sub-col", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
      , _react2.default.createElement('span', { className: "meeting-room--mobile-history--session-duration", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}},  duration )
      , _react2.default.createElement('div', { className: "meeting-room--mobile-history--session-feedback", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , 
          Array(5).fill(0).map((_, i) => (
            _react2.default.createElement('div', {
              key: i,
              className: _classBuilder2.default.call(void 0, 
                "meeting-room--mobile-history--session-feedback--point",
                { active: i + 1 <= rating },
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
            )
          ))
        
      )
    )
  )
)
