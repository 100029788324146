"use strict";Object.defineProperty(exports, "__esModule", {value: true});/* this reducer is different. Instead of listening in on actions we want to evaluate the total
 * global state every time. This is only useful in marginal cases and should be used sparingly
 * since it'll be run after EVERY CHANGE.
 */

const initialState = {
  inboxHasAlert: false,
}

 function inducedValuesReducer (state = initialState, action, globalState) {
  /* try to separate every induced value into a function */

  function doesInboxHaveAlert () {
    const { currentUser, blobjects, teams } = globalState

    if (!currentUser || !blobjects || !teams) {
      return false
    }

    /* we pre-calculate for each team whether it's inboxable */
    const teamToCurrentUserInboxable = Object.keys(currentUser.Teams || {}).reduce((acc, tk) => {
      acc[tk] = currentUser.Teams[tk] && currentUser.Teams[tk].Inboxable
      return acc
    }, {})

    for (const bkey in blobjects) {
      const blobject = blobjects[bkey]
      if (!blobject) {
        continue
      }

      const currentUserMetadata = blobject.UserMetadata[currentUser.UserKey]

      const lastTimeUpdated = blobject.InboxSortTime
      const lastTimeUserSeen = currentUserMetadata ? currentUserMetadata.SeenTime : 0
      if (new Date(lastTimeUpdated).getTime() <= new Date(lastTimeUserSeen).getTime()) {
        continue
      }

      if (currentUserMetadata && currentUserMetadata.Completed) {
        continue
      }

      /* finally check inboxability */
      if (blobject.UserSubscribers && blobject.UserSubscribers[currentUser.UserKey]) {
        /* NOTE: there might be more stuff here? idk? */
        return true
      } else {
        /* does one of our inboxable teams subscribe? */
        for (const teamSubscriberKey in blobject.TeamSubscribers) {
          /* we have an inboxable team */
          if (teamToCurrentUserInboxable[teamSubscriberKey]) {
            return true
          }
        }
      }
    }

    return false
  }

  return {
    inboxHasAlert: doesInboxHaveAlert(),
  }
} exports.default = inducedValuesReducer;
