"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');







var _uuid = require('shared/services/uuid');

var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _chat = require('shared/enum/chat');
var _entity = require('shared/enum/entity');



var _entity3 = require('shared/services/entity');











 const onCreateChat = async ({
  senderEntity,
  selectedEntitiesList,
  gotoConversation,
  gotoInbox,
  suggestedChat,
  chatMethod,
  chatName = '',
}) => {
  const allEntities = selectedEntitiesList.concat(senderEntity)
  if (!allEntities.length) return

  if (suggestedChat) {
    const { ConversationKey, Type, BlobjectKey } = suggestedChat
    gotoConversation(Type, ConversationKey, BlobjectKey)
    return ConversationKey
  }

  const groups = _entity3.groupEntities.call(void 0, allEntities)
  const UserKeys = _optionalChain([groups, 'access', _ => _[_entity.ENTITY_TYPES.USER], 'optionalAccess', _2 => _2.map, 'call', _3 => _3(e => e.wrappedEntity.UserKey)]) || []
  const RelUserKeys = _optionalChain([groups, 'access', _4 => _4[_entity.ENTITY_TYPES.RELATIONSHIP], 'optionalAccess', _5 => _5.map, 'call', _6 => _6(e => e.wrappedEntity.UserKey)]) || []
  const TeamKeys = _optionalChain([groups, 'access', _7 => _7[_entity.ENTITY_TYPES.TEAM], 'optionalAccess', _8 => _8.map, 'call', _9 => _9(e => e.wrappedEntity.TeamKey)]) || []
  const PatientKeys = _optionalChain([groups, 'access', _10 => _10[_entity.ENTITY_TYPES.PATIENT], 'optionalAccess', _11 => _11.map, 'call', _12 => _12(e => e.wrappedEntity.PatientKey)]) || []

  const createChat = async () => {
    if (chatMethod === _chat.DELIVERY_METHOD.SMS) {
      const teamKey =
          _entity3.isEntityTeam.call(void 0, senderEntity) && senderEntity.wrappedEntity.TeamKey
      const [patient] = groups[_entity.ENTITY_TYPES.PATIENT] || []
      const patientKey = patient && patient.wrappedEntity.PatientKey

      if (!teamKey || !patientKey) {
        throw new Error(`Invalid participants for an SMS conversation - team:${teamKey}, patient:${patientKey}`)
      }

      return _actions2.default.createSmsConversation({
        TeamKey: teamKey,
        PatientKey: patientKey,
      })
    }

    return _actions2.default.createConversation({
      OrgKey: senderEntity.wrappedEntity.OrgKey,
      UserKeys: UserKeys.concat(RelUserKeys).filter((key) => key && key !== _uuid.EMPTY_BLUID),
      TeamKeys,
      PatientKeys,
      ChatName: chatName,
    })
  }

  const { Type, ConversationKey, BlobjectKey } = await createChat()
    .catch(e => {
      gotoInbox()
      throw e
    })

  if (!BlobjectKey) return gotoInbox()
  gotoConversation(Type, ConversationKey, BlobjectKey)
  return ConversationKey
}; exports.onCreateChat = onCreateChat

 const useSend = (props) => _react.useCallback.call(void 0, 
  async (message, files) => {
    const conversationKey = await exports.onCreateChat.call(void 0, props)
    if (!conversationKey) return
    if (files) {
      return Promise.all(
        files.map(f => _actions2.default.sendConversationFile(conversationKey, f)),
      )
    }
    if (message) return _actions2.default.sendConversationMessage(conversationKey, message)
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  Object.values(props),
); exports.useSend = useSend
