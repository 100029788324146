"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _lodash = require('lodash');

var _videoRoom = require('web/actions/videoRoom');

const sortRoomsList = rs => rs.sort((a, b) =>
  (new Date(b.UpdatedAt)) - (new Date(a.UpdatedAt)),
)

 const selectRelevantRoomsList = state => {
  const { searchContext, allRooms } = state.videoRoom
  const { areArchived, matchingRoomKeys } = searchContext

  const allRoomsList = _lodash.values.call(void 0, allRooms)
  const searchRelevantRoomsList = allRoomsList
    .filter(x => matchingRoomKeys.includes(x.RoomKey))
    .filter(x => x.Archived === areArchived)

  return sortRoomsList(searchRelevantRoomsList)
}; exports.selectRelevantRoomsList = selectRelevantRoomsList

 const selectHasRelevantRoomsListDrained = state =>
  state.videoRoom.searchContext.hasDrained; exports.selectHasRelevantRoomsListDrained = selectHasRelevantRoomsListDrained
 const selectCurrentSearchContext = state =>
  state.videoRoom.searchContext; exports.selectCurrentSearchContext = selectCurrentSearchContext

 const selectCurrentVideoRoomKey = state =>
  state.videoRoom.currentVideoRoomKey; exports.selectCurrentVideoRoomKey = selectCurrentVideoRoomKey

 const selectVideoRoomByKey = (state, roomKey) =>
  state.videoRoom.allRooms[roomKey]; exports.selectVideoRoomByKey = selectVideoRoomByKey

 const selectCurrentVideoRoomName = state => {
  const room = exports.selectVideoRoomByKey.call(void 0, state, exports.selectCurrentVideoRoomKey.call(void 0, state))
  return room && (room.DisplayName || room.Name) || 'Video Room'
}; exports.selectCurrentVideoRoomName = selectCurrentVideoRoomName

 const selectVideoRoomLink = (state, roomKey) => {
  const room = exports.selectVideoRoomByKey.call(void 0, state, roomKey) || {}
  return room.RoomLink || ''
}; exports.selectVideoRoomLink = selectVideoRoomLink

 const selectCurrentVideoRoomLink = state => {
  const roomKey = exports.selectCurrentVideoRoomKey.call(void 0, state)
  return exports.selectVideoRoomLink.call(void 0, state, roomKey)
}; exports.selectCurrentVideoRoomLink = selectCurrentVideoRoomLink
 const selectAllVideoRoomsCount = state =>
  Object.keys(state.videoRoom.allRooms).length; exports.selectAllVideoRoomsCount = selectAllVideoRoomsCount

 const selectVideoRoomNameByKey = (state, roomKey) =>
  exports.selectVideoRoomByKey.call(void 0, state, roomKey) &&
  exports.selectVideoRoomByKey.call(void 0, state, roomKey).DisplayName; exports.selectVideoRoomNameByKey = selectVideoRoomNameByKey

 const selectVideoRoomIsApprovalRequired = (state, roomKey) =>
  exports.selectVideoRoomByKey.call(void 0, state, roomKey) &&
  exports.selectVideoRoomByKey.call(void 0, state, roomKey).RequireGuestApproval; exports.selectVideoRoomIsApprovalRequired = selectVideoRoomIsApprovalRequired

 const selectVideoRoomAdminsLists = (state, roomKey) => {
  const room = exports.selectVideoRoomByKey.call(void 0, state, roomKey)
  const users = room && _lodash.values.call(void 0, room.UserAdmins) || []
  const teams = room && _lodash.values.call(void 0, room.TeamAdminKeys) || []

  return {
    UserAdmins: users.filter(u => !u.isDeleted),
    TeamAdminKeys: teams,
  }
}; exports.selectVideoRoomAdminsLists = selectVideoRoomAdminsLists

 const selectAnonymousUserKey = state => state.videoRoom.anonymousUser.userKey; exports.selectAnonymousUserKey = selectAnonymousUserKey
 const selectAnonymousUserName = state => state.videoRoom.anonymousUser.userName; exports.selectAnonymousUserName = selectAnonymousUserName

 const selectAllVideoRoomUsers = state => state.videoRoom.roomUsers; exports.selectAllVideoRoomUsers = selectAllVideoRoomUsers
 const selectVideoRoomPendingUsersList = state =>
  _lodash.values.call(void 0, state.videoRoom.pendingAuthorizations).filter(a => a && !a.isResolved); exports.selectVideoRoomPendingUsersList = selectVideoRoomPendingUsersList

 const selectCurrentVideoRoomUsers = state => {
  const currentVideoRoomKey = exports.selectCurrentVideoRoomKey.call(void 0, state)
  if (!currentVideoRoomKey) return {}
  const currentRoom = exports.selectVideoRoomByKey.call(void 0, state, currentVideoRoomKey)
  if (!currentRoom) return {}
  return currentRoom.CurrentRoomUsers || {}
}; exports.selectCurrentVideoRoomUsers = selectCurrentVideoRoomUsers
 const selectVideoRoomActiveParticipantsCount = (state, roomKey) => {
  const room = exports.selectVideoRoomByKey.call(void 0, state, roomKey)
  if (!room || !room.CurrentRoomUsers) return 0
  return Object
    .values(room.CurrentRoomUsers)
    .filter(u => u.Status === _videoRoom.videoRoomUserStatus.ACTIVE)
    .length
}; exports.selectVideoRoomActiveParticipantsCount = selectVideoRoomActiveParticipantsCount

 const selectVideoRoomUser = (roomUserKey, roomKey, state) => {
  const room = exports.selectVideoRoomByKey.call(void 0, state, roomKey) || {}
  const users = room.CurrentRoomUsers || {}
  return users[roomUserKey]
}; exports.selectVideoRoomUser = selectVideoRoomUser

 const selectCurrentVideoRoomUser = state => {
  const { RoomKey, RoomUserKey } = state.videoRoom.currentVideoRoomUser

  return exports.selectVideoRoomUser.call(void 0, RoomUserKey, RoomKey, state)
}; exports.selectCurrentVideoRoomUser = selectCurrentVideoRoomUser

 const selectIsCurrentVideoRoomUserApproved = state => {
  const user = exports.selectCurrentVideoRoomUser.call(void 0, state)
  if (!user) return false
  return user.Approved
}; exports.selectIsCurrentVideoRoomUserApproved = selectIsCurrentVideoRoomUserApproved

 const selectIsCurrentVideoRoomUserAdmin = state => {
  const user = exports.selectCurrentVideoRoomUser.call(void 0, state)
  if (!user) return false
  return user.IsAdmin
}; exports.selectIsCurrentVideoRoomUserAdmin = selectIsCurrentVideoRoomUserAdmin

 const selectCurrentVideoRoomSessionDuration = state =>
  state.videoRoom.currentVideoRoomSessionDuration; exports.selectCurrentVideoRoomSessionDuration = selectCurrentVideoRoomSessionDuration
