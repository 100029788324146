"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/FirstTimeInteractables/EnableQuickLoginPopup/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);






const EnableQuickLoginModal = ({ isOpen, onClose }) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    className: "enable-quick-login-modal",
    bodyClassName: "enable-quick-login-modal--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
  
    , _react2.default.createElement(_Form2.default, { onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}} )
  )
)

exports. default = EnableQuickLoginModal
