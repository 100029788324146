"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _base64 = require('base-64');


 const makeRestBaseURL = ({ secure, host, port }) => {
  const protocol = secure ? 'https' : 'http'
  return `${protocol}://${host}:${port}`
}; exports.makeRestBaseURL = makeRestBaseURL

 const buildAuthProtocol = (auth) =>
  _base64.encode.call(void 0, JSON.stringify(auth)).replace(/=/g, ''); exports.buildAuthProtocol = buildAuthProtocol

 const makeExponentialDelay = (attempt) => {
  const base = Math.min(120, attempt ** 2) * 1000
  const jitter = (Math.random() * 100 - 50) * Math.min(1, attempt)

  return base + jitter
}; exports.makeExponentialDelay = makeExponentialDelay
