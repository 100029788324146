"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }// This file is meant for managing state of video rooms
var _store = require('shared/store');
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _helpers = require('shared/actions/helpers');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);
var _anonymousVideoRoomUserCache = require('web/services/anonymousVideoRoomUserCache'); var _anonymousVideoRoomUserCache2 = _interopRequireDefault(_anonymousVideoRoomUserCache);
var _videoCall = require('web/services/videoCall');

 const videoRoomJoinErrorCodes = {
  AUTHORIZATION_REQUEST_ERROR: 'AUTHORIZATION_REQUEST_ERROR',
  ACCESS_DENIED_ERROR: 'ACCESS_DENIED_ERROR',
}; exports.videoRoomJoinErrorCodes = videoRoomJoinErrorCodes

 const videoRoomUserStatus = {
  PENDING: 'pending',
  ACTIVE: 'active',
  DISCONNECTED: 'disconnected',
}; exports.videoRoomUserStatus = videoRoomUserStatus

 const types = {
  SET_CURRENT_VIDEO_ROOM_KEY: 'SET_CURRENT_VIDEO_ROOM_KEY',
  GET_VIDEO_ROOMS: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'GET_VIDEO_ROOMS'),
  RENAME_VIDEO_ROOM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'RENAME_VIDEO_ROOM'),
  ARCHIVE_VIDEO_ROOM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'ARCHIVE_VIDEO_ROOM'),
  UNARCHIVE_VIDEO_ROOM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'UNARCHIVE_VIDEO_ROOM'),
  SAVE_SEARCH_MATCHING_ROOM_KEYS: 'SAVE_SEARCH_MATCHING_ROOM_KEYS',
  REINDEX_MATCHING_ROOM_KEYS: 'REINDEX_MATCHING_ROOM_KEYS',
  CREATE_VIDEO_ROOM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'CREATE_VIDEO_ROOM'),
  REMOVE_VIDEO_ROOM_ADMIN_USER: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'REMOVE_VIDEO_ROOM_ADMIN_USER'),
  REMOVE_VIDEO_ROOM_ADMIN_TEAM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'REMOVE_VIDEO_ROOM_ADMIN_TEAM'),
  ADD_VIDEO_ROOM_ADMINS: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'ADD_VIDEO_ROOM_ADMINS'),
  SAVE_VIDEO_ROOMS: 'SAVE_VIDEO_ROOMS',
  UPDATE_VIDEO_ROOM: 'UPDATE_VIDEO_ROOM',
  JOIN_VIDEO_ROOM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'JOIN_VIDEO_ROOM'),
  UPDATE_ANONYMOUS_USER: 'UPDATE_ANONYMOUS_USER',
  START_USER_SETUP: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'START_USER_SETUP'),
  UPDATE_USER_NAME: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'UPDATE_USER_NAME'),
  SAVE_PENDING_JOIN_REQUEST: 'SAVE_PENDING_JOIN_REQUEST',
  RESPOND_VIDEO_ROOM_JOIN_REQUEST: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'RESPOND_VIDEO_ROOM_JOIN_REQUEST'),
  UPDATE_VIDEO_ROOM_PENDING_USER: 'UPDATE_VIDEO_ROOM_PENDING_USER',
  FETCH_VIDEO_ROOM: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'FETCH_VIDEO_ROOM'),
  REMOVE_VIDEO_ROOM_USER: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'REMOVE_VIDEO_ROOM_USER'),
  REMOVE_CURRENT_VIDEO_ROOM_USER: 'REMOVE_CURRENT_VIDEO_ROOM_USER',
  SAVE_VIDEO_ROOM_USER: 'SAVE_VIDEO_ROOM_USER',
  SAVE_CURRENT_VIDEO_ROOM_USER: 'SAVE_CURRENT_VIDEO_ROOM_USER',
  DISCONNECT_CURRENT_VIDEO_ROOM_USER: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'DISCONNECT_CURRENT_VIDEO_ROOM_USER'),
  VIDEO_ROOM_AUTHORIZATION_REQUEST: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'VIDEO_ROOM_AUTHORIZATION_REQUEST'),
  VIDEO_ROOM_RATE: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'VIDEO_ROOM_RATE'),
  UPDATE_CURRENT_VIDEO_ROOM_USER: 'UPDATE_CURRENT_VIDEO_ROOM_USER',
  VIDEO_ROOM_USER_PROBLEM_PUBLISH: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'VIDEO_ROOM_USER_PROBLEM_PUBLISH'),
  VIDEO_ROOM_USER_PROBLEM_ENCOUNTERED: 'VIDEO_ROOM_USER_PROBLEM_ENCOUNTERED',
  VIDEO_ROOM_LOAD_SESSIONS_LIST: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'VIDEO_ROOM_LOAD_SESSIONS_LIST'),
  VIDEO_ROOM_SESSION_FETCH: _helpers.AsyncAction.call(void 0, 'WEBSOCKET', 'VIDEO_ROOM_SESSION_FETCH'),
}; exports.types = types

const setCurrentVideoRoomKey = roomKey => _store.dispatch.call(void 0, {
  type: exports.types.SET_CURRENT_VIDEO_ROOM_KEY,
  payload: { roomKey },
})

const updateVideoRoom = payload =>
  _store.dispatch.call(void 0, { type: exports.types.UPDATE_VIDEO_ROOM, payload })

const onVideoRoomArchive = RoomKey =>
  updateVideoRoom({ RoomKey, Archived: true })
const onVideoRoomUnarchive = RoomKey =>
  updateVideoRoom({ RoomKey, Archived: false })

const saveAdmins = ({ Teams = {}, Users = {}, Orgs = {} } = {}) =>
  _actions2.default.handleUpdateNotification({ Teams, Users, Orgs })

const saveVideoRooms = payload =>
  _store.dispatch.call(void 0, { type: exports.types.SAVE_VIDEO_ROOMS, payload })

const pingVideoRoom = async ({ RoomUserKey } = {}) => {
  try { await _client2.default.send('VideoRoomUserPing', { RoomUserKey }) } catch (e) { log.error(e); throw e }
}

const reindexMatchingRoomKeys = () =>
  _store.dispatch.call(void 0, { type: exports.types.REINDEX_MATCHING_ROOM_KEYS })

const saveVideoRoomWithAdmins = ({ VideoRoom, ...Admins }) => {
  saveVideoRooms([VideoRoom])
  saveAdmins(Admins)
  reindexMatchingRoomKeys()
}

const fetchVideoRoom = RoomKey => _helpers.wsSend.call(void 0, 
  'VideoRoomFetch',
  exports.types.FETCH_VIDEO_ROOM,
  { RoomKey },
).then(saveVideoRoomWithAdmins)

const loadVideoRooms = async ({
  Archived = false, Query = null, Skip = 0, Take = 10,
} = {}) => {
  const { VideoRooms, Teams, Users, Orgs } = await _helpers.wsSend.call(void 0, 
    'VideoRoomList',
    exports.types.GET_VIDEO_ROOMS,
    { Archived, Query, Skip, Take },
  ).catch(() => ({ VideoRooms: [], Teams: {}, Users: {}, Orgs: {} }))

  saveAdmins({ Teams, Users, Orgs })
  saveVideoRooms(VideoRooms)
  _store.dispatch.call(void 0, {
    type: exports.types.SAVE_SEARCH_MATCHING_ROOM_KEYS,
    payload: {
      query: Query,
      areArchived: Archived,
      matchingRoomKeys: VideoRooms.map(x => x.RoomKey),
    },
  })

  return VideoRooms
}

const renameVideoRoom = ({ RoomKey, RoomName } = {}) =>
  _helpers.wsSend.call(void 0, 'VideoRoomNameUpdate', exports.types.RENAME_VIDEO_ROOM, { RoomKey, RoomName })
    .then(() => updateVideoRoom({ RoomKey, DisplayName: RoomName }))
    .catch(e => { log.error(e); throw e })

const archiveVideoRoom = ({ RoomKey } = {}) => {
  onVideoRoomArchive(RoomKey)
  return _helpers.wsSend.call(void 0, 'VideoRoomArchive', exports.types.ARCHIVE_VIDEO_ROOM, { RoomKey })
    .catch(e => {
      onVideoRoomUnarchive(RoomKey)
      log.error(e)
      throw e
    })
}

const unarchiveVideoRoom = ({ RoomKey } = {}) => {
  onVideoRoomUnarchive(RoomKey)
  return _helpers.wsSend.call(void 0, 'VideoRoomUnarchive', exports.types.UNARCHIVE_VIDEO_ROOM, { RoomKey })
    .catch(e => {
      onVideoRoomArchive(RoomKey)
      log.error(e)
      throw e
    })
}

const createVideoRoom = ({
  OrgKey,
  RoomName,
  RequireGuestApproval = true,
  AdminUsers,
  AdminTeamKeys,
  RoomListable = false,
  GuestUsers = [],
  GuestTeamKeys = [],
  GuestPatientKeys = [],
  GuestContacts = [],
} = {}) =>
  _helpers.wsSend.call(void 0, 'VideoRoomCreate', exports.types.CREATE_VIDEO_ROOM, {
    OrgKey,
    RoomName,
    RequireGuestApproval,
    AdminUsers,
    AdminTeamKeys,
    RoomListable,
    GuestUsers,
    GuestTeamKeys,
    GuestPatientKeys,
    GuestContacts,
  }).then(({ RoomKey }) => fetchVideoRoom(RoomKey))

const addVideoRoomAdmins = ({
  RoomKey, AdminUsers, AdminTeamKeys,
} = {}) => {
  const payload = { RoomKey, AdminUsers, AdminTeamKeys }
  return _helpers.wsSend.call(void 0, 
    'VideoRoomAdminsAdd',
    exports.types.ADD_VIDEO_ROOM_ADMINS,
    payload,
    payload,
  ).then(() => fetchVideoRoom(RoomKey))
}

const removeVideoRoomAdminUser = ({ RoomKey, UserKey } = {}) =>
  _helpers.wsSend.call(void 0, 
    'VideoRoomAdminUserRemove',
    exports.types.REMOVE_VIDEO_ROOM_ADMIN_USER,
    { RoomKey, UserKey },
    { RoomKey, UserKey },
  )

const removeVideoRoomAdminTeam = ({ RoomKey, TeamKey } = {}) =>
  _helpers.wsSend.call(void 0, 
    'VideoRoomAdminTeamRemove',
    exports.types.REMOVE_VIDEO_ROOM_ADMIN_TEAM,
    { RoomKey, TeamKey },
    { RoomKey, TeamKey },
  )

const accessVideoRoom = ({ RoomKey, RoomUserKey, RoomUserName }) =>
  _helpers.wsSend.call(void 0, 
    'VideoRoomUserJoin',
    exports.types.JOIN_VIDEO_ROOM,
    { RoomKey, RoomUserKey, RoomUserName: RoomUserName || '' },
  )
    .then(data => {
      saveVideoRooms([data.Room])
      return data
    })
    .catch(e => { log.error(e); throw e })

const updateAnonymousUser = payload => {
  payload.userKey && _anonymousVideoRoomUserCache2.default.setKey(payload.userKey)
  payload.userSecret && _anonymousVideoRoomUserCache2.default.setSecret(payload.userSecret)
  _anonymousVideoRoomUserCache2.default.setName(payload.userName || payload.userName === ''
    ? payload.userName
    : _anonymousVideoRoomUserCache2.default.getName(),
  )
  _store.dispatch.call(void 0, { type: exports.types.UPDATE_ANONYMOUS_USER, payload })
}

const requestVideoRoomAuthorization = ({ RoomKey, RoomAuthorizationUserName } = {}) =>
  _helpers.wsSend.call(void 0, 
    'VideoRoomAuthorizationRequest',
    exports.types.VIDEO_ROOM_AUTHORIZATION_REQUEST,
    { RoomKey, RoomAuthorizationUserName },
  ).catch(e => { log.error(e); throw e })

const savePendingJoinRequest = ({
  RoomAuthorizationKey, RoomAuthorizationUserName, RoomKey, RoomName,
} = {}) =>
  _store.dispatch.call(void 0, {
    type: exports.types.SAVE_PENDING_JOIN_REQUEST,
    payload: {
      RoomKey, RoomName, RoomAuthorizationUserName, RoomAuthorizationKey,
    },
  })

const updateVideoRoomPendingUser = payload => _store.dispatch.call(void 0, {
  type: exports.types.UPDATE_VIDEO_ROOM_PENDING_USER,
  payload,
})

const respondVideoRoomJoinRequest = ({ RoomKey, RoomAuthorizationKey, Approved }) => {
  updateVideoRoomPendingUser({ RoomKey, RoomAuthorizationKey, Approved })
  return _helpers.wsSend.call(void 0, 
    'VideoRoomAuthorizationRequestRespond',
    exports.types.RESPOND_VIDEO_ROOM_JOIN_REQUEST,
    { RoomKey, RoomAuthorizationKey, Approved },
    { RoomKey, RoomAuthorizationKey, Approved },
  ).catch(log.error)
}

const saveCurrentVideoRoomUser = payload =>
  _store.dispatch.call(void 0, { type: exports.types.SAVE_CURRENT_VIDEO_ROOM_USER, payload })

const startVideoRoomUserSetup = ({ RoomKey, RoomUserName } = {}) => _helpers.wsSend.call(void 0, 
  'VideoRoomUserSetupStart',
  exports.types.START_USER_SETUP,
  { RoomKey, RoomUserName },
)
  .then(r => {
    saveVideoRoomUser(r)
    saveCurrentVideoRoomUser({
      RoomKey: r.RoomKey,
      RoomUserKey: r.RoomUserKey,
    })
    return r
  })
  .catch(e => { log.error(e); throw e })

const updateVideoRoomUserName = ({ RoomUserKey, RoomUserName } = {}) => {
  updateCurrentVideoRoomUser({ RoomUserName })
  return _helpers.wsSend.call(void 0, 
    'VideoRoomUserNameUpdate',
    exports.types.UPDATE_USER_NAME,
    { RoomUserKey, RoomUserName },
  ).catch(log.error)
}

const removeVideoRoomUser = ({ RoomKey, RoomUserKey }) => _helpers.wsSend.call(void 0, 
  'VideoRoomUserRemove',
  exports.types.REMOVE_VIDEO_ROOM_USER,
  { RoomKey, RoomUserKey },
).catch(log.error)

const removeCurrentVideoRoomUser = () =>
  _store.dispatch.call(void 0, { type: exports.types.REMOVE_CURRENT_VIDEO_ROOM_USER })

const disconnectCurrentVideoRoomUser = ({ RoomKey, RoomUserKey } = {}) => {
  saveVideoRoomUser({
    RoomKey,
    RoomUserKey,
    Status: exports.videoRoomUserStatus.DISCONNECTED,
  })
  return _helpers.wsSend.call(void 0, 
    'VideoRoomUserDisconnect',
    exports.types.DISCONNECT_CURRENT_VIDEO_ROOM_USER,
    { RoomKey, RoomUserKey },
  ).catch(log.error)
}

const handleAdminUserRemoved = ({ RoomKey, UserKey }) =>
  _store.dispatch.call(void 0, {
    type: exports.types.REMOVE_VIDEO_ROOM_ADMIN_USER.SUCCESS,
    context: { RoomKey, UserKey },
  })
const handleAdminTeamRemoved = ({ RoomKey, TeamKey }) =>
  _store.dispatch.call(void 0, {
    type: exports.types.REMOVE_VIDEO_ROOM_ADMIN_TEAM.REQUEST,
    context: { RoomKey, TeamKey },
  })

const saveVideoRoomUser = payload => _store.dispatch.call(void 0, {
  type: exports.types.SAVE_VIDEO_ROOM_USER, payload,
})

const updateCurrentVideoRoomUser = ({ Approved } = {}) => _store.dispatch.call(void 0, {
  type: exports.types.UPDATE_CURRENT_VIDEO_ROOM_USER,
  payload: { Approved },
})

const videoRoomRate = ({
  RoomKey, RoomUserKey, StarRating, StarRatingCode, StarRatingDescription,
}) => _helpers.wsSend.call(void 0, 
  'VideoRoomUserRate',
  exports.types.VIDEO_ROOM_RATE,
  {
    RoomKey,
    RoomUserKey,
    StarRating,
    StarRatingCode: StarRatingCode || '',
    StarRatingDescription: StarRatingDescription || '',
  },
).catch(log.error)

const publishProblem = ({ ProblemCode, RoomKey, RoomUserKey }) => _helpers.wsSend.call(void 0, 
  'VideoRoomUserProblemPublish',
  exports.types.VIDEO_ROOM_USER_PROBLEM_PUBLISH,
  { ProblemCode, RoomKey, RoomUserKey },
)

const handleRoomUserProblem = ({ ProblemCode, RoomKey, RoomUserKey }) => {
  const isNetworkRestored = ProblemCode === _videoCall.problemCodes.NETWORK_RESTORED

  _store.dispatch.call(void 0, {
    type: exports.types.VIDEO_ROOM_USER_PROBLEM_ENCOUNTERED,
    payload: {
      ProblemCode: isNetworkRestored ? null : ProblemCode,
      ProblemReportedAt: isNetworkRestored ? null : Date.now(),
      RoomKey,
      RoomUserKey,
    },
  })
}

const loadVideoRoomSessionsList = ({ RoomKey, Skip = 0, Take = 10, StartedAfter } = {}) =>
  _helpers.wsSend.call(void 0, 
    'VideoRoomSessionList',
    exports.types.VIDEO_ROOM_LOAD_SESSIONS_LIST,
    { RoomKey, Skip, Take, StartedAfter },
  ).catch(log.error)

const fetchVideoRoomSession = ({ RoomKey, RoomSessionKey } = {}) =>
  _helpers.wsSend.call(void 0, 
    'VideoRoomSessionFetch',
    exports.types.VIDEO_ROOM_SESSION_FETCH,
    { RoomKey, RoomSessionKey },
  ).catch(log.error)

const updateAuthInfo = ({ AnonymousUserKey, AnonymousUserSecret }) =>
  updateAnonymousUser({
    userKey: AnonymousUserKey,
    userSecret: AnonymousUserSecret,
  })

const handleInbound = ({ Command, Data }) => {
  const handlers = {
    AuthInfo: () => updateAuthInfo(Data),
    VideoRoomArchived: () => onVideoRoomArchive(Data.RoomKey),
    VideoRoomUnarchive: () => onVideoRoomUnarchive(Data.RoomKey),
    VideoRoomNameUpdated: () => updateVideoRoom(Data),
    VideoRoomCreatedOrAdded: () => saveVideoRoomWithAdmins(Data),
    VideoRoomAuthorizationRequested: () => savePendingJoinRequest(Data),
    VideoRoomAuthorizationRequestResponded: () => updateVideoRoomPendingUser(Data),
    VideoRoomUserNameUpdated: () => saveVideoRoomUser(Data),
    VideoRoomUserSetupStarted: () => saveVideoRoomUser({ ...Data, Status: exports.videoRoomUserStatus.PENDING }),
    VideoRoomUserJoined: () => saveVideoRoomUser({ ...Data, Status: exports.videoRoomUserStatus.ACTIVE }),
    VideoRoomUserDisconnected: () => saveVideoRoomUser({ ...Data, Status: exports.videoRoomUserStatus.DISCONNECTED }),
    VideoRoomAdminsAdded: () => fetchVideoRoom(Data.RoomKey),
    VideoRoomAdminUserRemoved: () => handleAdminUserRemoved(Data),
    VideoRoomAdminTeamRemoved: () => handleAdminTeamRemoved(Data),
    VideoRoomUserRemoved: removeCurrentVideoRoomUser,
    VideoRoomUserProblemEncountered: () => handleRoomUserProblem(Data),
  }

  handlers[Command] && handlers[Command]()
}

const setVideoRoomWSHandlers = isLoggedIn => {
  const setHandlers = () => _client2.default.on('inbound', handleInbound)

  if (!isLoggedIn && !_client2.default.isConnected()) {
    const AnonymousUserKey = _anonymousVideoRoomUserCache2.default.getKey()
    const AnonymousUserSecret = _anonymousVideoRoomUserCache2.default.getSecret()
    _client2.default.open({
      auth: {
        Type: "anonymous",
        ...(AnonymousUserKey && { AnonymousUserKey }),
        ...(AnonymousUserSecret && { AnonymousUserSecret }),
      },
    })
  }

  setHandlers()

  // Client handlers get reset on each login.
  // Need to make sure video room handlers
  // are set back up on each CSRF_SUCCESS.
  _bus2.default.on('CLIENT_OPENED', () => setTimeout(setHandlers, 0))
}

const unsetVideoRoomWSHandlers = () => {
  _client2.default.off('inbound', handleInbound)
}

exports. default = {
  setCurrentVideoRoomKey,
  loadVideoRooms,
  renameVideoRoom,
  archiveVideoRoom,
  unarchiveVideoRoom,
  createVideoRoom,
  addVideoRoomAdmins,
  removeVideoRoomAdminUser,
  removeVideoRoomAdminTeam,
  setVideoRoomWSHandlers,
  unsetVideoRoomWSHandlers,
  startVideoRoomUserSetup,
  updateVideoRoomUserName,
  updateAnonymousUser,
  respondVideoRoomJoinRequest,
  fetchVideoRoom,
  removeVideoRoomUser,
  pingVideoRoom,
  requestVideoRoomAuthorization,
  disconnectCurrentVideoRoomUser,
  videoRoomRate,
  publishProblem,
  accessVideoRoom,
  loadVideoRoomSessionsList,
  fetchVideoRoomSession,
}
