"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/components/SignupStepWrapper/NavItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);










const SignupStepNavItem = ({
  name, lowerBound, upperBound, currentStep, gotoStep,
}) => {
  const isCompleted = currentStep > upperBound
  const isActive = currentStep >= lowerBound && currentStep <= upperBound

  return (
    _react2.default.createElement('div', {
      role: gotoStep && isCompleted ? "button" : "presentation",
      onClick: () => isCompleted && _optionalChain([gotoStep, 'optionalCall', _ => _(lowerBound)]),
      className: _classBuilder2.default.call(void 0, 
        "signup-step--nav-item",
        isCompleted && "signup-step--nav-item--completed",
        isActive && "signup-step--nav-item--active",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    
      ,  name 
    )
  )
}

exports. default = SignupStepNavItem
