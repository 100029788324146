"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _entity = require('shared/enum/entity');





const noop = () => ''

 const mapOrgToEntity =
  (makeDescription = noop) =>
    (org) => ({
      entityKey: org.OrgKey,
      type: _entity.ENTITY_TYPES.ORG,
      name: org.Name,
      description: makeDescription(org),
      wrappedEntity: org,
    }); exports.mapOrgToEntity = mapOrgToEntity
