"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Setup/MobileSetup/Camera.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _VideoFeed = require('web/components/VideoCall/VideoFeed'); var _VideoFeed2 = _interopRequireDefault(_VideoFeed);
var _CallControls = require('web/components/VideoCall/CallControls');

const CameraSetup = ({
  videoStream, areCamIssuesPresent,
  isCamCycleAvailable, onCycleCam,
  gotoNextStep,
}) => (
  _react2.default.createElement('div', { className: "meeting--setup-camera", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
    , _react2.default.createElement('h2', { className: "meeting--setup--mobile-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, "Verify your camera"  )
    , _react2.default.createElement('span', { className: "meeting--setup--mobile-subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, "Can you see yourself?"   )
    , _react2.default.createElement('div', { className: "meeting--setup-camera--feed-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , _react2.default.createElement(_VideoFeed2.default, {
        stream: videoStream,
        className: "meeting--setup-camera--feed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      )
      , _react2.default.createElement('div', { className: "meeting--setup-camera--controls-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        ,  isCamCycleAvailable &&
          _react2.default.createElement(_CallControls.SwitchCamBtn, {
            onClick: onCycleCam,
            className: "meeting--setup-camera--control-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          )
        
      )
    )
    , _react2.default.createElement('div', { className: "meeting--setup-camera--issues-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      ,  areCamIssuesPresent &&
        _react2.default.createElement('span', { className: "meeting--issue--warn-sign", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "Your camera might not be working."

        )
      
      ,  areCamIssuesPresent &&
        _react2.default.createElement('div', { className: "meeting--issue--underline meeting--setup-mobile--issue-underline" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}} )
      
    )
    , _react2.default.createElement('a', {
      onClick: gotoNextStep,
      className: "blmh-button meeting--btn purple primary highlight meeting--setup-camera--proceed-btn"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
    , "Continue"

    )
  )
)

CameraSetup.propTypes = {
  isCamCycleAvailable: _proptypes2.default.bool.isRequired,
  onCycleCam: _proptypes2.default.func.isRequired,
  areCamIssuesPresent: _proptypes2.default.bool.isRequired,
  gotoNextStep: _proptypes2.default.func.isRequired,
}

exports. default = CameraSetup
