"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Security/PasswordSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');
var _PasswordInput = require('web/components/PasswordInput'); var _PasswordInput2 = _interopRequireDefault(_PasswordInput);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);













const PasswordSection = ({
  title,
  onValidated,
  inputId,
  inputClassName,
  value,
  onChange,
  placeholder,
  autoComplete,
  maxLength,
}) => (
  _react2.default.createElement(_Form.InputSectionWrapper, { title: title, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
    , _react2.default.createElement(_PasswordInput2.default, {
      inputClassName: _classBuilder2.default.call(void 0, "settings--input", inputClassName),
      onValidated: onValidated,
      inputId: inputId,
      value: value,
      onChange: onChange,
      placeholder: placeholder,
      autoComplete: autoComplete,
      maxLength: maxLength, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
    )
  )
)

exports. default = PasswordSection
