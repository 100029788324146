"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/Org.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _user = require('shared/selectors/user');
var _org = require('shared/selectors/org');
var _myRelations = require('shared/selectors/myRelations');

var _PageLayout = require('web/components/Settings/PageLayout'); var _PageLayout2 = _interopRequireDefault(_PageLayout);
var _Org = require('web/components/Settings/Org');
var _InfoTab = require('./InfoTab'); var _InfoTab2 = _interopRequireDefault(_InfoTab);
var _MembersTab = require('./MembersTab'); var _MembersTab2 = _interopRequireDefault(_MembersTab);
var _MembershipTab = require('./MembershipTab'); var _MembershipTab2 = _interopRequireDefault(_MembershipTab);
var _SubscriptionsTab = require('./SubscriptionsTab'); var _SubscriptionsTab2 = _interopRequireDefault(_SubscriptionsTab);











const OrgPage = ({
  numberOfMembers,
  amIAdmin,
  gotoOrgInfo,
  gotoOrgMembers,
  gotoOrgMembership,
  gotoOrgSubscriptions,
  gotoOrgsList,
  orgName,
  orgHasAdvancedSearch,
  hasPendingUsers,
  orgKey,
  tabName,
}) => {
  _react.useLayoutEffect.call(void 0, 
    () => {
      if (
        amIAdmin
        || !(tabName === 'members' || tabName === 'plan')
        || !orgKey
      ) return
      gotoOrgInfo(orgKey)
    },
    [tabName, amIAdmin, gotoOrgInfo, orgKey],
  )

  if (!tabName || !orgKey) return null

  return (
    _react2.default.createElement(_PageLayout2.default, {
      title: orgName,
      bodyClassName: "settings--page--org",
      isBackBtnVisibleOnDesktop: true,
      onBack: gotoOrgsList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
    
      , _react2.default.createElement(_Org.TabsHeader, {
        gotoOrgInfo: gotoOrgInfo,
        gotoOrgMembers: amIAdmin ? gotoOrgMembers : null,
        gotoOrgMembership: !amIAdmin ? gotoOrgMembership : null,
        gotoOrgSubscriptions: amIAdmin ? gotoOrgSubscriptions : null,
        currentOrgKey: orgKey,
        activeTab: tabName,
        numberOfMembers: numberOfMembers, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      )
      ,  tabName === 'info' && (
        _react2.default.createElement(_InfoTab2.default, { orgKey: orgKey, isEditable: amIAdmin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} )
      )
      ,  tabName === 'members' && amIAdmin && (
        _react2.default.createElement(_MembersTab2.default, {
          orgKey: orgKey,
          orgName: orgName,
          hasPendingUsers: hasPendingUsers,
          canExportRelationships: orgHasAdvancedSearch, __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
        )
      )
      ,  tabName === 'membership' && !amIAdmin && (
        _react2.default.createElement(_MembershipTab2.default, { orgKey: orgKey, gotoOrgInfo: gotoOrgInfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}} )
      )
      ,  tabName === 'plan' && amIAdmin && (
        _react2.default.createElement(_SubscriptionsTab2.default, { orgKey: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )
      )
    )
  )
}









const mapState = (state, { orgKey }) => {
  const org = orgKey && _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgV1 = orgKey && _org.selectOrgV1.call(void 0, state, orgKey)
  return {
    numberOfMembers: _nullishCoalesce(_optionalChain([orgV1, 'optionalAccess', _ => _.NumberOfMembers]), () => ( 0)),
    orgName: _nullishCoalesce(_optionalChain([org, 'optionalAccess', _2 => _2.Name]), () => ( '')),
    amIAdmin: !!orgKey && _user.selectAmIAdminInOrg.call(void 0, state, orgKey),
    orgHasAdvancedSearch: !!orgKey && _org.selectOrgHasAdvancedSearch.call(void 0, state, orgKey),
    hasPendingUsers: !!orgKey && _myRelations.selectPendingUsersInOrg.call(void 0, state, orgKey).length > 0,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(OrgPage)
