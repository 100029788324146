"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/GroupAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);
var _utils = require('./utils');






const GroupAvatar = ({
  frontAvatar,
  backAvatar,
  size = _utils.DEFAULT_AVATAR_SIZE,
  className,
  borderColor,
  isOnline,
}) => {
  const eachSize = size * 0.68

  const containerStyle = {
    width: size,
    height: size,
  }

  const frontAvatarComp = frontAvatar || _react2.default.createElement(_BaseAvatar2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
  const backAvatarComp = backAvatar || _react2.default.createElement(_BaseAvatar2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "avatarV2-group", className), style: containerStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      ,  _react2.default.cloneElement(
        frontAvatarComp,
        {
          size: eachSize,
          className: "avatarV2-group--front-avatar",
          borderColor,
        },
      )
      ,  _react2.default.cloneElement(
        backAvatarComp,
        {
          size: eachSize,
          className: "avatarV2-group--back-avatar",
          borderColor,
        },
      )
      ,  isOnline && (
        _react2.default.createElement('img', {
          src: "/assets/icons/avatar/online-badge.svg",
          className: "avatarV2--online-badge", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        )
      )
    )
  )
}

exports. default = GroupAvatar
