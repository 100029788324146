"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Modals/MoreOptionsMenu.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

const MoreMenuOption = ({
  iconSrc, title, onClick,
}) => (
  _react2.default.createElement('div', { className: "video-call--more-option", onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    , _react2.default.createElement('img', {
      src: iconSrc,
      className: "video-call--more-option--icon",
      alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
    )
    , _react2.default.createElement('span', { className: "video-call--more-option--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, title)
  )
)

const MoreOptionsMenu = ({
  onClose,
  onRoomLinkCopy,
  onOpenSettings, onOpenNetstats,
  isSettingsLinkEnabled, isNetstatsLinkEnabled,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement('h3', { className: "video-call--more--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "Choose an option..."  )
    , _react2.default.createElement('div', { className: "video-call--more--list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , _react2.default.createElement(MoreMenuOption, {
        iconSrc: "/assets/icons/video/link-black.svg",
        title: "Copy meeting link"  ,
        onClick: () => { onClose(); onRoomLinkCopy() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
      ,  isSettingsLinkEnabled &&
        _react2.default.createElement(MoreMenuOption, {
          iconSrc: "/assets/icons/video/settings.svg",
          title: "Check audio/video settings"  ,
          onClick: () => { onClose(); onOpenSettings() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        )
      
      ,  isNetstatsLinkEnabled &&
        _react2.default.createElement(MoreMenuOption, {
          iconSrc: "/assets/icons/video/network-black.svg",
          title: "Get network stats"  ,
          onClick: () => { onClose(); onOpenNetstats() }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        )
      
    )
  )
)

MoreOptionsMenu.defaultProps = {
  isSettingsLinkEnabled: true,
  isNetstatsLinkEnabled: false,
}

MoreOptionsMenu.propTypes = {
  onClose: _proptypes2.default.func.isRequired,
  onRoomLinkCopy: _proptypes2.default.func.isRequired,
  isSettingsLinkEnabled: _proptypes2.default.bool,
  isNetstatsLinkEnabled: _proptypes2.default.bool,
}

exports. default = MoreOptionsMenu
