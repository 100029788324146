"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/ResultsList/InviteContactSection/InviteOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const InviteOption = ({
  name,
  desc,
  iconSrc,
  onChoose,
  isDisabled,
}) => {
  return (
    _react2.default.createElement('div', {
      role: "button",
      onClick: onChoose,
      className: _classBuilder2.default.call(void 0, 
        "participants-picker--invite-option",
        isDisabled && "participants-picker--invite-option--disabled",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    
      , _react2.default.createElement('div', { className: "participants-picker--invite-option--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , _react2.default.createElement('img', { src: iconSrc, alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )
      )
      , _react2.default.createElement('div', { className: "participants-picker--invite-option--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        , _react2.default.createElement('span', { className: "participants-picker--invite-option--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          ,  name 
        )
        , _react2.default.createElement('span', { className: "participants-picker--invite-option--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          ,  desc 
        )
      )
    )
  )
}

exports. default = InviteOption
