"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Modals/VerifyPhone/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');




var _contact = require('shared/validation/user/contact');
var _phone = require('shared/services/phone');
var _noticeBar = require('web/services/context/noticeBar');

var _Form = require('web/components/Form');









const VerifyPhoneModal = ({
  onClose,
  phoneNumber,
  onSubmit,
  onRequestVerification,
}) => {
  const { register, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _contact.phoneVerificationResolver,
    })
  const { errors, isValid, isSubmitting, isDirty } = formState

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  _react.useEffect.call(void 0, 
    () => {
      if (!phoneNumber) return
      onRequestVerification(phoneNumber)
        .catch(() => {
          onClose()
          showWarningBar(`Failed to send a verification code to ${_phone.formatPhoneNumber.call(void 0, phoneNumber)}.`)
        })
    },
    [phoneNumber, onRequestVerification, onClose, showWarningBar],
  )

  const submit = async (data, e) => {
    _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])
    if (!phoneNumber) return

    return onSubmit(phoneNumber, data.verificationCode)
      .then(() => {
        onClose()
        showSuccessBar(`Successfully verified ${_phone.formatPhoneNumber.call(void 0, phoneNumber)}.`)
      })
      .catch(e => {
        showWarningBar(
          e.Error === 'Invalid token'
            ? "The code you entered is invalid."
            : "Failed to verify this phone number.",
        )
      })
  }

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      , _react2.default.createElement('span', { className: "settings--profile--phone-ver-modal--notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}, "We just sent a text message to"
              , ' '
        , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 70}},  phoneNumber && _phone.formatPhoneNumber.call(void 0, phoneNumber) ), ' ', "containing a code to verify your phone number."

      )
      , _react2.default.createElement('span', { className: "settings--profile--phone-ver-modal--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}, "Please give it a few minutes to arrive."

      )
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "Verification Code" ,
        errorMessage: _optionalChain([errors, 'access', _3 => _3.verificationCode, 'optionalAccess', _4 => _4.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      
        , _react2.default.createElement(_Form.TextInput, {
          inputMode: "numeric",
          autoComplete: "one-time-code",
          autoFocus: true, ...register('verificationCode'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        )
      )
      , _react2.default.createElement('div', { className: "settings--modal-nav", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, "Close")
        , _react2.default.createElement(_Form.Button, { isSubmit: true, isDisabled: !isValid || !isDirty || isSubmitting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, "Save")
      )
    )
  )
}

exports. default = VerifyPhoneModal
