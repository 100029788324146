"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _ui = require('web/services/ui');






const useScrollToBottom = ({
  setLatestBatch,
  getContainerDOM,
}) => _react.useCallback.call(void 0, 
  (options = {}) => {
    const containerDOM = getContainerDOM()
    if (!containerDOM) return

    setLatestBatch()
    requestAnimationFrame(() => {
      _ui.scrollElem.call(void 0, containerDOM, {
        top: containerDOM.scrollHeight,
        behavior: options.shouldAnimate ? 'smooth' : 'auto',
      })
    })
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [setLatestBatch],
)

exports. default = useScrollToBottom
