"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');
var _common = require('shared/selectors/blobject/common');



var _array = require('shared/services/array');

 const selectInboxBlobjects = _reselect.createSelector.call(void 0, 
  _common.selectBlobjects,
  (_, keys) => keys,
  (blobjects, keys) => keys
    .map(k => blobjects[k])
    .filter(_array.truthy)
    .sort((a, b) =>
      +new Date(_optionalChain([b, 'optionalAccess', _2 => _2.InboxSortTime]) || 0) -
      +new Date(_optionalChain([a, 'optionalAccess', _3 => _3.InboxSortTime]) || 0),
    ),
); exports.selectInboxBlobjects = selectInboxBlobjects
