"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
var _notifications = require('shared/actions/notifications');
var _helpers = require('./helpers');
var _utils = require('shared/store/utils');
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);







const exportPhoneLogsActions = _utils.createAsyncActions('ExportPhoneLogs')
const exportOrgRelationshipHistoryActions =
  _utils.createAsyncActions('ExportOrgRelationshipHistory')
const exportChatActions = _utils.createAsyncActions('ExportChatV2')

const withExportFlow = (
  cmd,
  completeActionType,
) =>
  new Promise((resolve, reject) => {
    const ttl = 30 * 1000 // 30 seconds
    const timeout = setTimeout(() => reject(new Error('Export has timed out')), ttl)

    const handleComplete = (action) => {
      clearTimeout(timeout)
      return _client2.default.getFileLink(action.payload.FileKey)
        .then(r => resolve(r.Link))
        .catch(reject)
    }

    _bus2.default.once(completeActionType, handleComplete)

    cmd.catch(e => {
      clearTimeout(timeout)
      _bus2.default.off(completeActionType, handleComplete)
      reject(e)
    })
  })

const exportChat = (req) => withExportFlow(
  _helpers.wsCommand('ExportChatV2', exportChatActions, req, null),
  _notifications.actionCreators.exportChatCompleteNotificationAction.type,
)

const exportPhoneLogs = (req) =>
  withExportFlow(
    _helpers.wsCommand('ExportPhoneLogs', exportPhoneLogsActions, req, null),
    _notifications.actionCreators.exportPhoneLogsCompleteNotificationAction.type,
  )

 const getOrgRelationshipsLogsURL = async (req) =>
  _helpers.wsCommand(
    'ExportOrgRelationshipHistory',
    exportOrgRelationshipHistoryActions,
    req,
    null,
  )
    .then(r => _client2.default.getFileLink(r))
    .then(r => r.Link); exports.getOrgRelationshipsLogsURL = getOrgRelationshipsLogsURL

const actions = {
  exportChat,
  exportPhoneLogs,
  getOrgRelationshipsLogsURL: exports.getOrgRelationshipsLogsURL,
}

exports. default = actions
