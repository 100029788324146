"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/RemoveMember/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);





var _noticeBar = require('web/services/context/noticeBar');
var _Modal = require('web/components/Modal');










const RemoveMemberModal = ({
  onClose, entity, onProceed, orgName,
}) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = () => {
    setIsLoading(true)
    onProceed()
      .then(() => showSuccessBar("Successfully removed user from the organization."))
      .then(onClose)
      .catch(() => {
        showWarningBar("Failed to remove user from the organization.")
        setIsLoading(false)
      })
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('span', { className: "settings--org-members--remove-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}, "You are about to remove "
             , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}},  entity.name ), " from "  , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 39}},  orgName ), "."
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnTheme: "danger",
        primaryBtnLabel: "Remove",
        isPrimaryLoading: isLoading,
        onPrimaryBtnClick: onSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
    )
  )
}

exports. default = RemoveMemberModal
