"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/ManageMember/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);







var _hookForm = require('shared/services/validation/hookForm');
var _orgMember = require('shared/validation/orgMember');

var _noticeBar = require('web/services/context/noticeBar');
var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');









const ManageMemberModal = ({
  onClose,
  entity,
  orgKey,
  onSubmit,
}) => {
  const isAdmin = _optionalChain([entity, 'access', _ => _.wrappedEntity, 'access', _2 => _2.Relationships, 'access', _3 => _3[orgKey], 'optionalAccess', _4 => _4.RelType]) === 'admin'
  const title = _nullishCoalesce(_optionalChain([entity, 'access', _5 => _5.wrappedEntity, 'access', _6 => _6.Relationships, 'access', _7 => _7[orgKey], 'optionalAccess', _8 => _8.Title]), () => ( ''))

  const { control, register, reset, handleSubmit, formState } =
    _hookForm.useForm({
      resolver: _orgMember.orgMemberResolver,
      mode: 'onChange',
      defaultValues: { title, isAdmin },
    })
  _react.useEffect.call(void 0, () => reset({ title, isAdmin }), [reset, title, isAdmin])
  const { isValid, isDirty, isSubmitting, errors } = formState

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const submit = handleSubmit((data) =>
    onSubmit(entity.wrappedEntity.UserKey, data.title, data.isAdmin ? 'admin' : 'user')
      .then(onClose)
      .then(() => showSuccessBar("Member details updated"))
      .catch(() => showWarningBar("Couldn't update member details")),
  )

  return (
    _react2.default.createElement('form', { onSubmit: submit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        inputProps: register('title'),
        title: "Title",
        placeholder: "E.g. Attending Physician"  ,
        errorMessage: _optionalChain([errors, 'access', _9 => _9.title, 'optionalAccess', _10 => _10.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Role", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
        , _react2.default.createElement(_Form.ToggleController, {
          name: "isAdmin",
          control: control,
          label: "Administrator",
          hint: `Administrators can manage this organization's subscription, change organization settings, and manage other members.`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        isPrimarySubmit: true,
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        isPrimaryLoading: isSubmitting,
        primaryBtnLabel: "Save",
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
    )
  )
}

exports. default = ManageMemberModal
