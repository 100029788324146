"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Attachment/Image.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _ModalView = require('web/components/Conversation/Message/FileMessage/FileImage/ModalView'); var _ModalView2 = _interopRequireDefault(_ModalView);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);











const AttachmentImage = ({
  onView,
  onClose,
  onDownload,
  fileName,
  fileLink,
  showingLightBox,
  small,
}) => {
  const imagePreviewStyle = _react.useMemo.call(void 0, 
    () => ({ background: `no-repeat center / cover url('${fileLink || ''}')` }),
    [fileLink],
  )

  const modalGateway = showingLightBox && fileLink
    ? _react2.default.createElement(_Modal2.default, {
        isOpen: showingLightBox && !!fileLink,
        fullScreen: true,
        onClose: onClose,
        className: "file-image--preview-modal",
        bodyClassName: "file-image--preview-modal--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      
        , _react2.default.createElement(_ModalView2.default, {
          imageSrc: fileLink,
          imageCaption: fileName,
          onClose: onClose,
          onDownload: onDownload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        )
      )
    : null

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', {
        onClick: onView,
        className: _classBuilder2.default.call(void 0, "imagecontainer", small && "imagecontainer-small"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      
        , _react2.default.createElement('div', { className: "imagecontainer--imagepreview", style: imagePreviewStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}} )
        , _react2.default.createElement('div', { className: "imagecontainer--bottom", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
          , _react2.default.createElement('a', { className: "imagecontainer--bottom--link", onClick: onView, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}, "View")
          , _react2.default.createElement('a', { className: "imagecontainer--bottom--link", onClick: onDownload, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, "Download")
        )
      )
      ,  modalGateway 
    )
  )
}

exports. default = AttachmentImage
