"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Feedback/RatingMeter/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _MeterItem = require('./MeterItem'); var _MeterItem2 = _interopRequireDefault(_MeterItem);

 class RatingMeter extends _react2.default.Component {constructor(...args) { super(...args); RatingMeter.prototype.__init.call(this); }
  __init() {this.handleRatingUpdate = n =>
    this.props.onRatingUpdate(n)}

  render () {
    const { className, itemClassName, currentRating, onRatingSubmit, isSubmitted } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, className,
      "meeting--feedback--meter-wrapper")
    const pointClassName = _classBuilder2.default.call(void 0, itemClassName,
      "meeting--feedback--meter--item")

    return (
      _react2.default.createElement('div', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
        , 
          Array(5).fill(null).map((_, i) => (
            _react2.default.createElement(_MeterItem2.default, {
              key: i,
              idx: i,
              className: pointClassName,
              onRatingUpdate: this.handleRatingUpdate,
              onRatingSubmit: onRatingSubmit,
              isActive: i < currentRating,
              isSubmitted: isSubmitted, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
            )
          ))
        
      )
    )
  }

  static __initStatic() {this.propTypes = {
    className: _proptypes2.default.string,
    itemClassName: _proptypes2.default.string,
    onRatingUpdate: _proptypes2.default.func.isRequired,
    currentRating: _proptypes2.default.number.isRequired,
  }}
} RatingMeter.__initStatic(); exports.default = RatingMeter;
