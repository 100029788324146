"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/AdminPreview.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Avatar = require('web/components/Avatar');

const AdminPreview = ({
  onRemove,
  isMe,
  adminEntity,
  orgName,
}) => {
  const adminNameClassName = _classBuilder2.default.call(void 0, 
    "meeting--management--admin-name",
    { 'is-me': isMe },
  )

  return (
    _react2.default.createElement('div', { className: "meeting--management--admin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , _react2.default.createElement('div', { className: "meeting--management--admin-avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , _react2.default.createElement(_Avatar.EntityAvatar, { entity: adminEntity, __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}} )
      )
      , _react2.default.createElement('div', { className: "meeting--management--admin-desc-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        , _react2.default.createElement('div', { className: "meeting--management--admin-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
          , _react2.default.createElement('span', { className: adminNameClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}},  adminEntity.Name )
          ,  orgName &&
            _react2.default.createElement('span', { className: "meeting--management--admin-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
              ,  orgName 
            )
          
        )
        ,  !isMe &&
          _react2.default.createElement('span', {
            onClick: onRemove,
            className: "meeting--management--admin-remove-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
          , "Remove"

          )
        
        , _react2.default.createElement('div', { className: "meeting--management--admin-more-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}} )
      )
    )
  )
}

AdminPreview.defaultProps = {
  orgName: '',
  adminEntity: {},
  isMe: false,
  onRemove: () => {},
}

AdminPreview.propTypes = {
  onRemove: _proptypes2.default.func.isRequired,
  isMe: _proptypes2.default.bool,
  adminEntity: _proptypes2.default.object.isRequired,
  orgName: _proptypes2.default.string.isRequired,
}

exports. default = AdminPreview
