"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ArchiveModal/Form/TeamOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Form = require('web/components/Form');







const Option = ({ team, isChecked, onChange }) => (
  _react2.default.createElement('li', { className: "archive-modal--option-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    , _react2.default.createElement('label', { className: "archive-modal--option", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , _react2.default.createElement('span', { className: "archive-modal--option-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}},  team.Name )
      , _react2.default.createElement(_Form.Toggle, {
        isChecked: isChecked,
        onChange: onChange,
        className: "archive-modal--option-toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
      )
    )
  )
)

exports. default = Option
