"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Notifications/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _user = require('shared/selectors/user');
var _noticeBar = require('web/services/context/noticeBar');

var _PageLayout = require('web/components/Settings/PageLayout'); var _PageLayout2 = _interopRequireDefault(_PageLayout);
var _Headings = require('web/components/Settings/Headings');
var _Sections = require('./Sections');
var _Divider = require('web/components/Settings/Divider'); var _Divider2 = _interopRequireDefault(_Divider);






const NotificationsPage = ({
  me, gotoSettingsRoot, profileSettingsUrl,
}) => {
  const { showNoticeBar } = _noticeBar.useNoticeBar.call(void 0, )
  const isCurrentUserLoading = me.NumberOfLogins === -1
  const [notificationSound, setNotificationSound] = _react.useState.call(void 0, me.Notification_Sound)

  return (
    _react2.default.createElement(_PageLayout2.default, { title: "Notifications", onBack: gotoSettingsRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Push Notifications" )
      , _react2.default.createElement(_Sections.DeviceSection, {
        userKey: me.UserKey,
        showNoticeBar: showNoticeBar,
        currentSoundUrl: notificationSound,
        onSoundUrlChange: setNotificationSound, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
      )
      , _react2.default.createElement(_Sections.MuteSection, {
        onSubmit: _actions2.default.setUserMuteUntil,
        muteUntil: me.Notification_MuteUntil, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )
      ,  !isCurrentUserLoading &&
        _react2.default.createElement(_Sections.DNDSection, {
          onSubmitDND: _actions2.default.setGlobalNotificationDND,
          onDisableDND: _actions2.default.unsetGlobalNotificationDND,
          dndStartTime: me.Notification_DND_StartTime || null,
          dndEndTime: me.Notification_DND_EndTime || null,
          dndTimezone: me.Notification_DND_TimeZone || null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        )
      
      , _react2.default.createElement(_Divider2.default, { className: "settings--notifications--divider", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} )
      , _react2.default.createElement(_Headings.Subheader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Offline Notifications" )
      , _react2.default.createElement(_Sections.EmailSection, {
        hasEmail: !!me.Email,
        hasPhone: !!me.PrimaryPhone,
        profileSettingsUrl: profileSettingsUrl,
        isSMSNotificationsOn: me.Notification_SMS,
        isEmailNotificationsOn: me.Notification_Emails,
        onTurnOnSMSNotifications: _actions2.default.enableNotificationSMS,
        onTurnOffSMSNotifications: _actions2.default.disableNotificationSMS,
        onTurnOnEmailNotifications: _actions2.default.enableNotificationEmails,
        onTurnOffEmailNotifications: _actions2.default.disableNotificationEmails, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
      )
    )
  )
}





const mapState = (state) => ({
  me: _user.selectCurrentUser.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(NotificationsPage)
