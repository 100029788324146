"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Feedback/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _RatingMeter = require('web/components/VideoCall/Feedback/RatingMeter'); var _RatingMeter2 = _interopRequireDefault(_RatingMeter);
var _withFeedbackController = require('web/screens/VideoCall/Feedback/withFeedbackController'); var _withFeedbackController2 = _interopRequireDefault(_withFeedbackController);
var _Modals = require('web/components/VideoCall/Feedback/Modals');
var _helpers = require('web/components/VideoCall/Feedback/helpers');

class Feedback extends _react2.default.PureComponent {constructor(...args) { super(...args); Feedback.prototype.__init.call(this);Feedback.prototype.__init2.call(this);Feedback.prototype.__init3.call(this);Feedback.prototype.__init4.call(this);Feedback.prototype.__init5.call(this);Feedback.prototype.__init6.call(this); }
  __init() {this.state = {
    currentRating: 0,
    isLowRatingReasonModalOpen: false,
  }}

  __init2() {this.handleRatingUpdate = n => !this.props.isRatingSubmitted &&
    this.setState({ currentRating: n })}

  __init3() {this.openLowRatingReasonModal = () => this.setState({
    isLowRatingReasonModalOpen: true,
  })}

  __init4() {this.closeLowRatingReasonModal = () => this.setState({
    isLowRatingReasonModalOpen: false,
  })}

  __init5() {this.handleProceed = () =>
    this.props.submitRating(this.state.currentRating)}

  __init6() {this.componentDidUpdate = props =>
    !props.isSubmittedRatingLow && this.props.isSubmittedRatingLow &&
    this.openLowRatingReasonModal()}

  render () {
    const {
      lowRatingReasons,
      isRatingSubmitted,
      submitRating,
      submitLowRatingReason,
      submitLowRatingComment,
      sessionDuration,
    } = this.props

    const {
      currentRating, isLowRatingReasonModalOpen,
    } = this.state

    const noteClassName = _classBuilder2.default.call(void 0, 
      "meeting--feedback--note",
      { hidden: !isRatingSubmitted },
    )
    const proceedBtnClassName = _classBuilder2.default.call(void 0, 
      "meeting--btn",
      "meeting--feedback--proceed-btn",
      { disabled: currentRating === 0 },
      { hidden: isRatingSubmitted },
    )

    return (
      _react2.default.createElement('div', { className: "meeting--feedback", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        , _react2.default.createElement('div', { className: "meeting--feedback--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          , _react2.default.createElement('div', { className: "meeting--feedback--title-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
            , _react2.default.createElement('h3', { className: "meeting--feedback--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "Call finished" )
            , _react2.default.createElement('span', { className: "meeting--feedback--call-duration", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              ,  _helpers.formatSessionDuration.call(void 0, sessionDuration) 
            )
          )
          , _react2.default.createElement('div', { className: "meeting--feedback--rating-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
            , _react2.default.createElement('h4', { className: "meeting--feedback--rating-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "How was your call?"   )
            , _react2.default.createElement(_RatingMeter2.default, {
              currentRating: currentRating,
              onRatingUpdate: this.handleRatingUpdate,
              onRatingSubmit: submitRating,
              isSubmitted: isRatingSubmitted,
              className: "meeting--feedback--stars-wrapper",
              itemClassName: "meeting--feedback--star", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            )
            , _react2.default.createElement('span', { className: noteClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}, "Your feedback was submitted."

            )
            , _react2.default.createElement('div', { className: "meeting--feedback--buttons-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
              , _react2.default.createElement('a', { className: proceedBtnClassName, onClick: this.handleProceed, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}, "Rate call"

              )
            )
          )
          , _react2.default.createElement(_Modals.LowRatingReasonModal, {
            isOpen: isLowRatingReasonModalOpen,
            onClose: this.closeLowRatingReasonModal,
            onReasonSubmit: submitLowRatingReason,
            onCommentSubmit: submitLowRatingComment,
            lowRatingReasons: lowRatingReasons, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
          )
        )
      )
    )
  }

  static __initStatic() {this.propTypes = {
    roomLink: _proptypes2.default.string.isRequired,
    roomName: _proptypes2.default.string.isRequired,
    gotoCall: _proptypes2.default.func.isRequired,
  }}
} Feedback.__initStatic();

exports. default = _withFeedbackController2.default.call(void 0, Feedback)
