"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxRightSide/NoChat.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _RightSide = require('web/components/RightSide');
var _EmptyConversation = require('web/screens/ConversationScreen/EmptyConversation'); var _EmptyConversation2 = _interopRequireDefault(_EmptyConversation);

const NoChat = () => (
  _react2.default.createElement(_RightSide.RightSide, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}
    , _react2.default.createElement(_RightSide.RightSideBody, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 8}}
      , _react2.default.createElement(_EmptyConversation2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 9}} )
    )
  )
)

exports. default = NoChat
