"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upsells/Page.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Form = require('web/components/Form');
var _ImgSrcSet = require('web/components/ImgSrcSet'); var _ImgSrcSet2 = _interopRequireDefault(_ImgSrcSet);










const UpsellsPage = ({
  title,
  desc,
  hint,
  imgUrl,
  onRequest,
  className,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "upsells--page", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    , _react2.default.createElement(_ImgSrcSet2.default, {
      className: "directory--patients--upsells-artwork",
      src: imgUrl,
      alt: "Illustration", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    )
    , _react2.default.createElement('h1', { className: "upsells--page--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}},  title )
    , _react2.default.createElement('span', { className: "upsells--page--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}},  desc )
    ,  onRequest && (
      _react2.default.createElement('div', { className: "upsells--page--sub", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
      , _react2.default.createElement('span', { className: "upsells--page--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}},  hint )
        , _react2.default.createElement(_Form.Button, { isInverted: true, className: "blm-btn upsells--page--btn" , onClick: onRequest, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Upgrade"

        )
      )
    )
  )
)

exports. default = UpsellsPage
