"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/RecentChatsSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactwaypoint = require('react-waypoint');


var _usePagination = require('shared/hooks/usePagination'); var _usePagination2 = _interopRequireDefault(_usePagination);
var _noticeBar = require('web/services/context/noticeBar');

var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _Item = require('web/screens/Inbox/InboxList/Item'); var _Item2 = _interopRequireDefault(_Item);
var _AddBtn = require('web/screens/Directory/AddBtn'); var _AddBtn2 = _interopRequireDefault(_AddBtn);







const keyExtractor = (b) => b.Key
const CHAT_LOAD_LIMIT = 10

const RecentChatsSection = ({
  loadItemsList,
  onNewChat,
  entityName,
}) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handledLoadItemsList = _react.useCallback.call(void 0, 
    (...ps) => loadItemsList(...ps)
      .catch(e => {
        showWarningBar('Failed to load chats')
        throw e
      }),
    [loadItemsList, showWarningBar],
  )
  const {
    isLoading, hasDrained, itemKeysList, onLoadMore,
  } = _usePagination2.default.call(void 0, {
    loadItemsList: handledLoadItemsList,
    keyExtractor,
    limit: CHAT_LOAD_LIMIT,
  })

  const hasResults = !!itemKeysList.length

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "directory-page--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}, "Recent Chats" )
      , _react2.default.createElement('div', { className: "directory-page--section--no-border", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        ,  itemKeysList.map(k => _react2.default.createElement(_Item2.default, { key: k, blobjectKey: k, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} ))
        ,  hasResults && !hasDrained ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: onLoadMore, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} ) : null 
        , _react2.default.createElement(_ListResultsStatus2.default, {
          isLoading: isLoading,
          resultsCount: itemKeysList.length,
          labelClassName: "directory-page--section--results-label",
          noResultsLabel: entityName
            ? `You have no chats with ${entityName} yet.`
            : 'No chats found.'
          , __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        )
      )
      ,  onNewChat && _react2.default.createElement(_AddBtn2.default, { label: "Create a chat"  , onClick: onNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} ) 
    )
  )
}

exports. default = RecentChatsSection
