"use strict";Object.defineProperty(exports, "__esModule", {value: true});






 const teamCommunicationPlan = {
  name: "Team Chat",
  features: [
    'Send messages, files, and images',
    'Unlock unlimited advanced search',
    'Audit any user conversation',
    'On-demand support',
    'Cancel anytime',
  ],
  theme: 'purple',
  iconSrc: "/assets/icons/pricing/team-communication-badge.svg",
}; exports.teamCommunicationPlan = teamCommunicationPlan

 const SMSMessagingPlan = {
  name: 'SMS Messaging',
  features: [
    'Your own phone number',
    'Send messages and files via SMS',
    'Create bulk message campaigns',
    'Export & audit conversations',
    '7-day money back guarantee',
  ],
  theme: 'yellow',
  iconSrc: "assets/icons/pricing/sms-messaging-badge.svg",
}; exports.SMSMessagingPlan = SMSMessagingPlan

 const videoTelehealthPlan = {
  name: 'Video & Telehealth',
  features: [],
  theme: 'green',
  iconSrc: "assets/icons/pricing/video-badge.svg",
}; exports.videoTelehealthPlan = videoTelehealthPlan

 const extraPhoneNumberPlan = {
  name: 'Additional SMS Numbers',
  features: [],
  theme: 'green',
  iconSrc: 'assets/icons/pricing/extra-phone-badge.svg',
}; exports.extraPhoneNumberPlan = extraPhoneNumberPlan
