"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _TokenLoader = require('./TokenLoader'); var _TokenLoader2 = _interopRequireDefault(_TokenLoader);





const onValidateToken = _client2.default.checkWelcomeToken.bind(_client2.default)
const onSubmitSetup = _client2.default.submitSetup.bind(_client2.default)

const Setup = () => {
  const match = _reactrouterdom.useRouteMatch()
  const token = _optionalChain([match, 'optionalAccess', _ => _.params, 'access', _2 => _2.token]) || null

  return (
    _react2.default.createElement(_TokenLoader2.default, {
      token: token,
      onValidateToken: onValidateToken,
      onSubmitSetup: onSubmitSetup, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    )
  )
}

exports. default = Setup
