"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _Props = require('web/components/Form/Props');
var _validators = require('shared/services/validators');

 function objectEmpty (obj) {
  return Object.keys(obj).length === 0
} exports.objectEmpty = objectEmpty;

/* Take server user object and map to response */
 const mapUserResultsToEntities = (resp, userOrgKeys) => {
  const orgKeysMap = userOrgKeys
    .reduce((acc, v) => ({ ...acc, [v]: v }), {})
  const usersList = Object.values(resp.Users || {})

  return usersList.map(u => {
    const userRelationships = Object.values(u.Relationships)
    const orgRealtionships = userRelationships.map(r => ({
      title: r.Title,
      orgKey: r.OrgKey,
      orgName: resp.Orgs[r.OrgKey].Name,
    }))

    return ({
      id: u.UserKey,
      avatar: {
        userKey: u.UserKey,
        profilePicture: u.ProfilePicture,
        isOnline: u.Online,
      },

      Teams: Object.keys(u.Teams),
      Relationships: u.Relationships,
      inNetwork: userRelationships
        .some(r => !r.Pending && orgKeysMap[r.OrgKey]),

      entityOrgRelationships: orgRealtionships || [],
      title: u.Name,
      subtitle: generateUserSubtitle(orgRealtionships),

      type: _Props.ENTITY_TYPES.USER,
    })
  })
}; exports.mapUserResultsToEntities = mapUserResultsToEntities

 const mapTeamResultsToEntities = resp => {
  const teamsList = Object.values(resp.Teams || {})

  return teamsList.map(t => ({
    ...t,
    id: t.TeamKey,
    avatar: { teamKey: t.TeamKey, teamAvatar: t.Avatar },

    title: t.Name,
    subtitle: generateTeamSubtitle(t, resp.Orgs[t.OrgKey]),
    type: _Props.ENTITY_TYPES.TEAM,
  }))
}; exports.mapTeamResultsToEntities = mapTeamResultsToEntities

 const mapPatientResultsToEntities = resp => {
  const patientsList = Object.values(resp.Patients || {})
  return patientsList.map(p => ({
    ...p,
    id: p.PatientKey,
    phone: p.Phone,
    avatar: { patientKey: p.PatientKey, patientName: p.Name },

    title: p.Name,
    subtitle: resp.Orgs[p.OrgKey].Name,
    type: _Props.ENTITY_TYPES.PATIENT,
  }))
}; exports.mapPatientResultsToEntities = mapPatientResultsToEntities

 function generateUserSubtitle (orgRelationshipsArr) {
  if (orgRelationshipsArr.length === 0) {
    return "Member, BloomText Network"
  } else if (orgRelationshipsArr.length === 1 && orgRelationshipsArr[0].title) {
    return `${orgRelationshipsArr[0].title}, ${orgRelationshipsArr[0].orgName}`
  } else if (orgRelationshipsArr.length <= 2) {
    return orgRelationshipsArr.map(r => r.orgName).join(", ")
  }
  return `Member of ${orgRelationshipsArr.length} organizations`
} exports.generateUserSubtitle = generateUserSubtitle;

 function generateTeamSubtitle (team, org) {
  const members = Object.values(team.Members)
  if (members.length === 0) {
    return org.Name
  } else if (members.length === 1) {
    return org.Name + " - 1 member"
  } else {
    return org.Name + ` - ${members.length} members`
  }
} exports.generateTeamSubtitle = generateTeamSubtitle;

 function isThisAPhoneNumber (value) {
  return _validators.phoneValidator.call(void 0, value) === null
} exports.isThisAPhoneNumber = isThisAPhoneNumber;

 function isThisAnEmail (value) {
  return _validators.emailValidator.call(void 0, value) === null
} exports.isThisAnEmail = isThisAnEmail;

 function stripSpecialCharactersFromValue (value) {
  return value.replace(/[&/\\#,+()$~\-%.'":*?<>{}]/g, '')
} exports.stripSpecialCharactersFromValue = stripSpecialCharactersFromValue;
