"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/OrgSurvey/ReferrerForm.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');






var _orgSurvey = require('shared/validation/org/orgSurvey');
var _Form = require('web/components/Form');

var _components = require('web/screens/Signup/components');







const OrgTypeForm = ({ onBack, defaultValues, onSubmit }) => {
  const { register, trigger, control, watch, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _orgSurvey.referrerResolver,
      shouldUnregister: true,
      defaultValues: defaultValues || { response: _orgSurvey.referrerOptions[0].value },
    })

  const isOther = watch('response') === _orgSurvey.OTHER_OPTION_KEY
  const { isValid, errors } = formState

  _react.useEffect.call(void 0, () => { trigger() }, []) // eslint-disable-line react-hooks/exhaustive-deps

  const submit = (data, e) => {
    _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])
    onSubmit(data)
  }

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "org",
        title: "How did you find us?"    ,
        subtitle: "How did you find out about BloomText?"      , __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "How did you find us?"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
        , _react2.default.createElement(_Form.SimpleSelectController, {
          name: "response",
          control: control,
          options: _orgSurvey.referrerOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        )
      )
      ,  isOther && (
        _react2.default.createElement(_Form.InputSection, {
          autoFocus: true,
          title: "Please specify" ,
          inputProps: register('responseOther'),
          errorMessage: _optionalChain([errors, 'access', _3 => _3.responseOther, 'optionalAccess', _4 => _4.message]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        )
      )
      , _react2.default.createElement(_components.SignupStepControls, { onBack: onBack, isNextDisabled: !isValid, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}} )
    )
  )
}

exports. default = OrgTypeForm
