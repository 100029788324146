"use strict";Object.defineProperty(exports, "__esModule", {value: true});/* eslint-disable no-console */
var _CommandError = require('./CommandError');
var _errors = require('../errors');





const noopLogger = {
  error: () => console.warn('Logger is not initialized!'),
}

class Logger {constructor() { Logger.prototype.__init.call(this);Logger.prototype.__init2.call(this);Logger.prototype.__init3.call(this);Logger.prototype.__init4.call(this); }
  __init() {this.logger = noopLogger}

  __init2() {this.register = (logger) => this.logger = logger}

  __init3() {this.info = console.log}

  __init4() {this.error = (e) => {
    console.warn(e)

    if (e instanceof String) {
      return this.logger.error(new Error(String(e)))
    }

    if (!(e instanceof Error)) {
      return this.logger.error(new Error(JSON.stringify(e)))
    }

    if (e instanceof _errors.RestError) {
      if (e.response.ServerError || e.response.Transient) return
      return this.logger.error(e, { extra: e.response })
    }

    if (e instanceof _CommandError.CommandError) {
      if (e.extra.ServerError) return
      return this.logger.error(e, { extra: e.extra })
    }

    return this.logger.error(e)
  }}
}

 const log = new Logger(); exports.log = log
