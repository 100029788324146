"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/NotificationPreferenceModal/Form/MuteSection.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _time = require('shared/services/time');
var _Form = require('web/components/Form');

const MuteSection = ({
  isMutedForever,
  mutedUntil,
  isVIP,
  onMuteUntil,
  onMuteForever,
  onUnmute,
}) => {
  const mutedUntilDate = new Date(mutedUntil)
  const willUnmuteLater = mutedUntilDate.getTime() > Date.now()

  const renderDescription = () => {
    if (isVIP) return 'Cannot mute a priority chat.'
    if (isMutedForever) return 'Notifications for this chat are muted forever.'
    if (willUnmuteLater) {
      return `Notifications for this chat are muted until ${_time.formatDisplayTime.call(void 0, mutedUntilDate)}`
    }
    return null
  }

  const renderContent = () => {
    if (isVIP) return null
    if (isMutedForever || willUnmuteLater) {
      return (
        _react2.default.createElement('button', {
          onClick: onUnmute,
          className: "chat--notification-settings--unmute-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        , "Un-mute notifications"

        )
      )
    }

    const now = new Date().getTime()
    const msInHour = 60 * 60 * 1000

    const oneHour = now + msInHour
    const fourHour = now + 4 * msInHour
    const eightHour = now + 8 * msInHour
    return (
      _react2.default.createElement('div', { className: "chat--notification-settings--mute-btns", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: () => onMuteUntil(oneHour), __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, "1 hour" )
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: () => onMuteUntil(fourHour), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, "4 hours" )
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: () => onMuteUntil(eightHour), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "8 hours" )
        , _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onMuteForever, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "Forever")
      )
    )
  }

  const description = renderDescription()
  return (
    _react2.default.createElement('div', { className: "chat--notification-settings--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
      , _react2.default.createElement('div', { className: "chat--notification-settings--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}, "Mute Chat"

      )
      ,  description && (
        _react2.default.createElement('div', { className: "chat--notification-settings--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
          ,  description 
        )
      )
      ,  renderContent() 
    )
  )
}

MuteSection.propTypes = {
  isMutedForever: _proptypes2.default.bool,
  mutedUntil: _proptypes2.default.string, /* ISO Date */
  isVIP: _proptypes2.default.bool,

  onMuteUntil: _proptypes2.default.func,
  onMuteForever: _proptypes2.default.func,
  onUnmute: _proptypes2.default.func,
}

exports. default = MuteSection
