"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/AlertComponent.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _alertReducer = require('shared/reducers/alertReducer');

const AlertTypesMap = {
  [_alertReducer.AlertTypes.SUCCESS]: {
    class: 'success',
    icon: 'check_circle',
  },
  [_alertReducer.AlertTypes.INFORMATION]: {
    class: 'information',
    icon: 'priority_high',
  },
  [_alertReducer.AlertTypes.DANGER]: {
    class: 'danger',
    icon: 'warning',
  },
  [_alertReducer.AlertTypes.ERROR]: {
    class: 'error',
    icon: 'error',
  },
}

const ALERT_TIME = 3500

 class AlertComponent extends _react.Component {
  constructor (props) {
    super(props);AlertComponent.prototype.__init.call(this);AlertComponent.prototype.__init2.call(this);AlertComponent.prototype.__init3.call(this);AlertComponent.prototype.__init4.call(this);AlertComponent.prototype.__init5.call(this);AlertComponent.prototype.__init6.call(this);AlertComponent.prototype.__init7.call(this);
    this.state = {
      xpressed: false,
      applyPressure: false,
      slideOut: false,
    }
  }

  componentDidMount () {
    this.resetComponent()
  }

  __init() {this.resetComponent = () => {
    this.restartComponentLifeSpan()
    this.setState({
      xpressed: false,
      applyPressure: false,
      slideOut: false,
    })
  }}

  __init2() {this.onClose = (time) => {
    if (this.clearTimeout) {
      window.clearTimeout(this.clearTimeout)
    }
    this.clearTimeout = setTimeout(() => {
      // wait for animation to complete
      this.props.onClose()
    }, time)
  }}

  __init3() {this.restartComponentLifeSpan = () => {
    if (this.alertLifeTimer) {
      window.clearTimeout(this.alertLifeTimer)
    }
    this.alertLifeTimer = setTimeout(() => {
      this.setState({ slideOut: true })
      this.onClose(800) // this should match 'top' and 'left' animation durations in _alert.scss
    }, ALERT_TIME)
  }}

  __init4() {this.mouseDown = () => {
    this.setState({ applyPressure: true })
  }}

  __init5() {this.mouseUp = () => {
    if (this.state.applyPressure) {
      // user started click on x icon, but mouse may have drifed off icon, still close box
      this.setState({ xpressed: true })
      this.onClose(100) // this should match 'opacity' animation duration in _alert.scss
    }
  }}

  __init6() {this.mouseOverComponent = () => {
    // user is probably reading component, don't close it
    if (this.alertLifeTimer) {
      window.clearTimeout(this.alertLifeTimer)
    }
  }}

  __init7() {this.mouseLeaveComponent = () => {
    this.setState({ applyPressure: false })
    this.restartComponentLifeSpan()
  }}

  componentWillUnmount () {
    window.clearTimeout(this.clearTimeout)
    window.clearTimeout(this.alertLifeTimer)
  }

  render () {
    const { title, body, type } = this.props
    const { xpressed, applyPressure, slideOut } = this.state

    const alertClasses = `blm-alert
      ${xpressed ? ' fade' : ''} 
      ${applyPressure ? ' pressure' : ''}
      ${slideOut ? ' slideout' : ''}`

    const typeMap = AlertTypesMap[type]
    return (
      _react2.default.createElement('div', { className: alertClasses, onMouseLeave: this.mouseLeaveComponent, onMouseOver: this.mouseOverComponent, onMouseUp: this.mouseUp, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
        , _react2.default.createElement('i', { className: `material-icons ${typeMap.class}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}, typeMap.icon)
        , _react2.default.createElement('div', { className: "blm-alert--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
          , _react2.default.createElement('div', { className: "blm-alert-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}, title)
          , _react2.default.createElement('div', { className: "blm-alert-message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}, body)
        )
        , _react2.default.createElement('i', { className: "material-icons", onMouseDown: this.mouseDown, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}, "close")
      )
    )
  }
} exports.default = AlertComponent;

AlertComponent.propTypes = {
  title: _proptypes2.default.string,
  body: _proptypes2.default.string,
  type: _proptypes2.default.string,
  onClose: _proptypes2.default.func,
}

AlertComponent.defaultProps = {
  type: _alertReducer.AlertTypes.INFORMATION,
}
