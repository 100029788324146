"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Modals/NoticeBar.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

 class NoticeBar extends _react2.default.Component {constructor(...args) { super(...args); NoticeBar.prototype.__init.call(this);NoticeBar.prototype.__init2.call(this);NoticeBar.prototype.__init3.call(this);NoticeBar.prototype.__init4.call(this);NoticeBar.prototype.__init5.call(this); }
  __init() {this.hideTimeout = null}

  __init2() {this.refreshTimeout = () => {
    clearTimeout(this.hideTimeout)
    this.hideTimeout = setTimeout(this.props.onClose, 3000)
  }}

  __init3() {this.componentDidUpdate = () =>
    this.props.isOpen && this.refreshTimeout()}

  __init4() {this.componentDidMount = () => this.props.isOpen && this.refreshTimeout()}

  __init5() {this.componentWillUnmount = () => clearTimeout(this.hideTimeout)}

  render () {
    const { isOpen, text, type } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, 
      'video-call--notice-bar--wrapper',
      { hidden: !isOpen },
    )

    return (
      _react2.default.createElement('div', { className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        , _react2.default.createElement('div', { className: "video-call--notice-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
          ,  type === "success" &&
            _react2.default.createElement('img', { src: "/assets/icons/tick-green.svg", alt: "&check;", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}} )
          
          ,  type === "warning" &&
            _react2.default.createElement('img', { src: "/assets/icons/alert-orange.svg", alt: "<!>", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
          
          , _react2.default.createElement('span', { className: "video-call--notice-bar--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}},  text )
        )
      )
    )
  }
} exports.default = NoticeBar;
