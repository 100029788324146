"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/CampaignAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _BaseAvatar = require('./BaseAvatar'); var _BaseAvatar2 = _interopRequireDefault(_BaseAvatar);





const CampaignAvatar = ({ campaignKey, ...props }) => (
  _react2.default.createElement(_BaseAvatar2.default, {
    backgroundColorKey: campaignKey,
    icon: "/assets/icons/avatar/broadcast.svg",
    ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
  )
)

exports. default = CampaignAvatar
