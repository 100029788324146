"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _hookForm = require('shared/services/validation/hookForm');


var _joi = require('shared/services/validation/joi'); var _joi2 = _interopRequireDefault(_joi);
var _uuid = require('shared/services/uuid');






const defaultOrgTypes = [
  'Specialty practice',
  'Hospital or health system',
  'ABA (Applied Behavioral Analysis)',
  'Addiction treatment',
  'Behavioral health',
  'Clinic or solo practice',
  'Post-acute or long-term care',
  'Home health',
]

const defaultReferrers = [
  'Internet search',
  'Invitation from friend',
  'A colleague referred me',
  'Online community group',
]

 const OTHER_OPTION_KEY = 'other'; exports.OTHER_OPTION_KEY = OTHER_OPTION_KEY
const optionNone = { value: '', label: '-- None --' }
const optionOther = { value: exports.OTHER_OPTION_KEY, label: 'Other (please specify)' }

const makeForm = (labels, fieldDisplayName) => {
  const actualOptions = labels.map(o => ({ value: _uuid.uuidv4.call(void 0, ), label: o }))
  const options = [optionNone]
    .concat(actualOptions)
    .concat(optionOther)

  const schema = _joi2.default.object({
    response: _joi.requiredString.call(void 0, ),
    responseOther: _joi2.default.when(
      'response', {
        is: exports.OTHER_OPTION_KEY,
        then: _joi.optionalString.call(void 0, ),
        otherwise: _joi2.default.forbidden(),
      },
    ).messages(_joi.makeStrErrors.call(void 0, fieldDisplayName)),
  })

  const resolver = _hookForm.joiResolver.call(void 0, schema, {}, { mode: 'sync' })
  return [options, resolver] 
}

const [orgTypeOptions, orgTypeResolver] = makeForm(defaultOrgTypes, 'Organization type')
const [referrerOptions, referrerResolver] = makeForm(defaultReferrers, 'Referrer')

 const parseResponse = (
  data,
  formType,
) => {
  const options = formType === 'orgType'
    ? orgTypeOptions
    : referrerOptions

  const response = data.response === exports.OTHER_OPTION_KEY
    ? data.responseOther
    : _optionalChain([options, 'access', _ => _.find, 'call', _2 => _2(o => o.value === data.response), 'optionalAccess', _3 => _3.label])

  return response || ''
}; exports.parseResponse = parseResponse






exports.orgTypeOptions = orgTypeOptions; exports.orgTypeResolver = orgTypeResolver; exports.referrerOptions = referrerOptions; exports.referrerResolver = referrerResolver;
