"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/patientResponse/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactrouterdom = require('react-router-dom');
var _reactdropzone = require('react-dropzone'); var _reactdropzone2 = _interopRequireDefault(_reactdropzone);
var _ChatBox = require('web/components/ChatScreen/ChatBox/ChatBox');
var _ExpandingInput = require('web/components/Conversation/ExpandingInput'); var _ExpandingInput2 = _interopRequireDefault(_ExpandingInput);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _store = require('web/store');

var _uuid = require('shared/services/uuid');
var _AnonymousPage = require('web/components/AnonymousPage');
var _patientConversation = require('shared/selectors/patientConversation');
var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');

/* underlying assumption: we have the patients, users, conversation, and messages in the store, and
 * one user, one patient, and one conversation there's only.
 */
const ChatBoxComponent = _reactredux.connect.call(void 0, (state, props) => {
  const conversation = Object.values(state.patientConversations)[0]
  const conversationMessages = Object.values(state.patientConversationMessages)[0]

  const patientKeys = {}
  const userKeys = {}
  const messages = conversationMessages.map(m => {
    const { FromPatientKey, FromUserKey, ...message } = m
    if (FromPatientKey !== _uuid.EMPTY_BLUID) {
      patientKeys[FromPatientKey] = true
    }

    if (FromUserKey !== _uuid.EMPTY_BLUID) {
      userKeys[FromUserKey] = true
    }
    return {
      ...message,
      FromUserKey: FromPatientKey !== _uuid.EMPTY_BLUID ? FromPatientKey : FromUserKey,
      sequenceNumber: -1,
    }
  })

  let participants = {}
  participants = Object.keys(userKeys).reduce((acc, userKey) => {
    acc[userKey] = state.users[userKey]
    return acc
  }, participants)

  participants = Object.keys(patientKeys).reduce((acc, patientKey) => {
    acc[patientKey] = { ...state.patients[patientKey] }
    if (acc[patientKey]) {
      acc[patientKey].showTextAvatar = true
    }
    return acc
  }, participants)

  return {
    ...props,
    participants,
    messages,
    loadMessages: () => {},
    chatBoxKey: conversation.ConversationKey,
    readSequence: {},
  }
})(_ChatBox.ChatBox)

class PatientConversation extends _react.Component {
  constructor (props) {
    super(props);PatientConversation.prototype.__init.call(this);PatientConversation.prototype.__init2.call(this);PatientConversation.prototype.__init3.call(this);PatientConversation.prototype.__init4.call(this);PatientConversation.prototype.__init5.call(this);PatientConversation.prototype.__init6.call(this);PatientConversation.prototype.__init7.call(this);

    this.state = {
      initialLoadComplete: false,
      message: "",
      isCodeInvalid: false,
      isCodeExpired: false,
      hasLoadFailed: false,
      isDisconnected: false,
    }
  }

  __init() {this.markSeen = conversationKey => {
    const key = conversationKey || _optionalChain([this, 'access', _4 => _4.props, 'access', _5 => _5.conversation, 'optionalAccess', _6 => _6.ConversationKey])
    if (!key) return

    _actions2.default.markPatientConversationSeen({ ConversationKey: key }).catch(() => null)
  }}

  componentDidMount () {
    const conversationCode = _store.history.location.pathname.match(/conversation\/([a-zA-Z0-9]{12}).?/)[1]
    if (!conversationCode) {
      return
    }

    _client2.default.on('inbound', (message) => {
      switch (message.Command) {
        case 'IncomingPatientMessage': {
          _actions2.default.handleIncomingPatientMessageNotification(message.Data)
          this.markSeen()
          break
        }
        case 'DeletedPatientMessageNotification':
          _actions2.default.handleDeletedPatientMessageNotification(message.Data)
          break
      }
    })

    _client2.default.on('authentication-success', () => {
      _actions2.default.loadPatientConversationByCode({ ConversationCode: conversationCode })
        .then(r => {
          const conversationKey = _optionalChain([Object, 'access', _7 => _7.values, 'call', _8 => _8(r.PatientConversations), 'access', _9 => _9[0], 'optionalAccess', _10 => _10.ConversationKey])
          this.markSeen(conversationKey)
        })
        .then(() => this.setState({ initialLoadComplete: true }))
        .catch(() => this.setState({ hasLoadFailed: true }))
    })

    _client2.default.on('authentication-error', ({ Error }) =>
      this.setState({
        isCodeInvalid: Error === "Invalid Code",
        isCodeExpired: Error === "Expired Code",
      }),
    )

    _client2.default.on('unexpected-closed', () =>
      this.setState({
        isDisconnected: true,
      }),
    )

    /* TODO: Move this into a more general place with client upgrade */
    _client2.default.open({
      auth: {
        Type: 'patient_code',
        Code: conversationCode,
      },
    })
  }

  __init2() {this.sendMessage = () =>
    _actions2.default.sendPatientConversationMessage({
      ConversationKey: this.props.conversation.ConversationKey,
      PatientKey: this.props.patient.PatientKey,
      Text: this.state.message,
    }).catch(() => null)}

  __init3() {this.sendFile = file => _actions2.default.sendPatientConversationFile({
    ConversationKey: this.props.conversation.ConversationKey,
    PatientKey: this.props.patient.PatientKey,
    file,
  }).catch(() => null)}

  __init4() {this.onDrop = (accepted) => {
    accepted.forEach((f) => {
      this.sendFile(f)
    }, this)
  }}

  __init5() {this.onChangeInputValue = (v) => {
    this.setState({
      message: v,
    })
  }}

  __init6() {this.loadPriorChats = () => {
    if (this.props.messages && this.props.conversation) {
      let [{ SentTimestamp }] = this.props.messages.slice(-1)
      SentTimestamp = new Date(SentTimestamp).getTime()

      _actions2.default.getPatientConversationMessages({
        ConversationKey: this.props.conversation.ConversationKey,
        Limit: 30,
        BeforeTimestamp: SentTimestamp,
      })
        .catch(() => null)
    }
  }}

  __init7() {this.setDropzoneRef = r => this.dropzone = r}

  render () {
    const { user, conversation, orgName } = this.props
    const { isCodeExpired, isCodeInvalid, hasLoadFailed, isDisconnected } = this.state
    const canRespond = conversation ? conversation.PatientCanRespond : false

    if (isCodeInvalid || isCodeExpired || hasLoadFailed || isDisconnected) {
      return (
        _react2.default.createElement(_AnonymousPage.PageWrapper, { className: "patient-conversation patient-conversation--error" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 186}}
          , _react2.default.createElement('div', { className: "patient-conversation--error-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 187}}
            ,  isCodeInvalid && "Your chat link is invalid" 
            ,  isCodeExpired && "Your chat link has expired" 
            ,  hasLoadFailed && "Your chat failed to load" 
            ,  isDisconnected && "Your chat was disconnected" 
          )
          , _react2.default.createElement('div', { className: "patient-conversation--error-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 193}}
            ,  isCodeInvalid && "Please request a new link from your provider." 
            ,  isCodeExpired && "Please request a new link from your provider." 
            ,  hasLoadFailed && "Please try again, or request a new link from your provider." 
            ,  isDisconnected && "Please reload to reconnect." 
          )
        )
      )
    }

    return (
      _react2.default.createElement(_reactrouterdom.Router, { history: _store.history, __self: this, __source: {fileName: _jsxFileName, lineNumber: 204}}
        , _react2.default.createElement(_AnonymousPage.PageWrapper, {
          className: "patient-conversation",
          wrapperClassName: "patient-conversation--wrapper",
          footerClassName: "patient-conversation--footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
        
          ,  user && (
            _react2.default.createElement('div', { className: "patient-conversation--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 211}}
              , _react2.default.createElement('span', { className: "patient-conversation--metadata-from", __self: this, __source: {fileName: _jsxFileName, lineNumber: 212}}, "From:")
              , ' '
              , _react2.default.createElement('span', { className: "patient-conversation--metadata-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 214}}
                ,  orgName 
              )
            )
          )
          , _react2.default.createElement(_reactdropzone2.default, {
            disableClick: true,
            onDrop: this.onDrop,
            ref: this.setDropzoneRef,
            className: "patient-conversation--dropzone", __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}}
          
            ,  this.state.initialLoadComplete
              ? _react2.default.createElement(ChatBoxComponent, { topWaypointEnter: this.loadPriorChats, __self: this, __source: {fileName: _jsxFileName, lineNumber: 226}} )
              : null
            
          )
          , _react2.default.createElement('div', { className: "patient-conversation--input-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 230}}
            ,  canRespond && (
              _react2.default.createElement(_ExpandingInput2.default, {
                className: "patient-conversation--input",
                onChange: (_e, value) => this.onChangeInputValue(value),
                onAttachmentClick: () => this.dropzone.open(),
                onSendFile: this.sendFile,
                onSend: this.sendMessage,
                value: this.state.message, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
              )
            )
          )
        )
      )
    )
  }
}

const mapState = state => {
  const patientConversationsList = Object.values(_patientConversation.selectPatientConversations.call(void 0, state))
  if (!patientConversationsList.length) return {}

  const [conversation] = patientConversationsList
  const [user] = Object.values(_peers.selectAllV1Users.call(void 0, state))
  const [patient] = Object.values(_peers.selectAllV1Patients.call(void 0, state))
  const messages = Object.values(_patientConversation.selectPatientConversationMessages.call(void 0, state, conversation.ConversationKey))
  const orgKey = _optionalChain([conversation, 'optionalAccess', _11 => _11.OrgKey])
  const orgName = orgKey && _optionalChain([_org.selectOrgV1.call(void 0, state, orgKey), 'optionalAccess', _12 => _12.Name]) || ''

  return { user, patient, conversation, messages, orgName }
}

exports. default = _reactredux.connect.call(void 0, mapState)(PatientConversation)
