"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');
var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);

 const PIN_LENGTH = 4; exports.PIN_LENGTH = PIN_LENGTH






const pinSchema = _joi3.requiredString.call(void 0, ).min(exports.PIN_LENGTH).max(exports.PIN_LENGTH)

const quickLoginSchema = _joi4.default.object({
  isEnabled: _joi4.default.bool(),
  pin: _joi4.default.when('isEnabled', { then: pinSchema }),
})

 const quickLoginResolver = _joi.joiResolver.call(void 0, quickLoginSchema); exports.quickLoginResolver = quickLoginResolver







 const quickLoginWithConfirmationSchema =
  _joi4.default.object({
    isEnabled: _joi4.default.bool(),
    pin: pinSchema,
    pinConfirmation: _joi4.default.ref('pin'),
  }).with('pin', 'pinConfirmation'); exports.quickLoginWithConfirmationSchema = quickLoginWithConfirmationSchema

 const quickLoginWithConfirmationResolver = _joi.joiResolver.call(void 0, exports.quickLoginWithConfirmationSchema); exports.quickLoginWithConfirmationResolver = quickLoginWithConfirmationResolver
