"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/RichMessageWrapper/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);








var _sort = require('shared/services/sort');
var _message = require('shared/enum/message');
var _Avatar = require('web/components/Avatar');
var _time = require('shared/services/time');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Tooltip = require('web/components/Tooltip');
var _ContextMenu = require('./ContextMenu'); var _ContextMenu2 = _interopRequireDefault(_ContextMenu);
var _DeliveryStatus = require('./DeliveryStatus'); var _DeliveryStatus2 = _interopRequireDefault(_DeliveryStatus);























const RichMessageWrapper = (props) => {
  const {
    highlighted,
    messageKey,
    senderType,
    senderName,
    senderKey,
    showAvatar,
    showName,
    sentAt,
    seenBy,

    subscribers,
    currentUserKey,
    onDelete,
    onCopyMessageLink,
    onCopy,
    onRetry,
    conversationKey,
    deleted,
    deliveryStatus,
    isLastMessage,
  } = props
  const [isHover, setIsHover] = _react.useState.call(void 0, false)
  const [isContextMenuOpen, setIsContextMenuOpen] = _react.useState.call(void 0, false)
  const openContextMenu = () => setIsContextMenuOpen(true)
  const closeContextMenu = () => setIsContextMenuOpen(false)

  _react.useEffect.call(void 0, 
    () => { !isHover && closeContextMenu() },
    [isHover],
  )

  const isFromOtherUser = senderKey !== currentUserKey
  const isFromMe = !isFromOtherUser

  const isDelivered = deliveryStatus === _message.deliveryStatus.DELIVERED
  const hasFailed = deliveryStatus === _message.deliveryStatus.FAILED

  const handleDelete = (isFromMe && !deleted && isDelivered && onDelete)
    ? () => onDelete(messageKey, conversationKey)
    : null
  const handleCopyMessageLink = () => onCopyMessageLink(messageKey)

  const contextMenuContent = (
    _react2.default.createElement(_ContextMenu2.default, {
      onClose: closeContextMenu,
      onCopy: onCopy,
      onDelete: handleDelete,
      onCopyMessageLink: handleCopyMessageLink, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
    )
  )

  const seenBySubscribers = _react.useMemo.call(void 0, 
    () => Object.values(seenBy || {})
      .filter(s => s.IsLatestMessage)
      .sort((a, b) => _sort.sortByString.call(void 0, b.SeenTime, a.SeenTime))
      .reduce((acc, sb) => {
        const s = subscribers[sb.SubscriberKey]
        if (!s) return acc
        return s.SubscriberKey !== currentUserKey && s.SubscriberKey !== senderKey
          ? acc.concat([s])
          : acc
      }, [] ),
    [seenBy, subscribers, senderKey, currentUserKey],
  )

  return (
    _react2.default.createElement('div', {
      className: _classBuilder2.default.call(void 0, 
        "rich-message", {
          "rich-message--me": isFromMe,
          "rich-message--them": isFromOtherUser,
          "rich-message--failed": hasFailed,
          highlighted,
        },
      ),
      id: messageKey,
      onMouseEnter: () => setIsHover(true),
      onMouseLeave: () => setIsHover(false), __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
    
      , _react2.default.createElement('div', { className: "rich-message--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
        ,  isFromOtherUser && (
          _react2.default.createElement('div', { className: "rich-message--avatar-column", __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}
            ,  showAvatar && (
              _react2.default.createElement(_Avatar.MessageAvatar, {
                size: 32,
                senderKey: senderKey,
                senderType: senderType, __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}
              )
            )
          )
        )
        ,  !isFromOtherUser && (
          _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
            !deleted && "no-margin",
            "rich-message--massage--hidden-timestamp",
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
            ,  !showName ? _time.getDayTimeString.call(void 0, sentAt) : "" 
          )
        )
        , _react2.default.createElement('div', { className: "rich-message--message", __self: this, __source: {fileName: _jsxFileName, lineNumber: 142}}
          , showName &&
            _react2.default.createElement('div', { className: "rich-message--message--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
              , senderName
              , _react2.default.createElement('span', {
                className: "rich-message--message--name--timestamp",
                title: _time.formatLongDisplayDate.call(void 0, sentAt), __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
              
                , _time.getDayTimeString.call(void 0, sentAt)
              )
            )
          , _react2.default.createElement('div', { className: "rich-message--message-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
            ,  !deleted && (
              _react2.default.createElement('div', {
                onClick: openContextMenu,
                className: _classBuilder2.default.call(void 0, 
                  "rich-message--message--menu",
                  !isHover && "hidden",
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
              
                , _react2.default.createElement(_Tooltip.ControlledTooltip, {
                  place: isFromMe ? 'left' : 'right',
                  isOpen: isContextMenuOpen,
                  onClose: closeContextMenu,
                  content: contextMenuContent,
                  className: "inbox-participants--options", __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
                
                  , _react2.default.createElement('img', {
                    className: "rich-message--message--menu-icon",
                    src: "/assets/icons/header/ellipsis-dark.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
                  )
                )
              )
            )
            ,  props.children 
          )
        )
        ,  isFromOtherUser && (
          _react2.default.createElement('div', { className: "rich-message--massage--hidden-timestamp", __self: this, __source: {fileName: _jsxFileName, lineNumber: 180}}
            ,  !showName ? _time.getDayTimeString.call(void 0, sentAt) : ""
          )
        )
      )
      , _react2.default.createElement(_DeliveryStatus2.default, {
        status: deliveryStatus,
        seenBySubscribers: seenBySubscribers,
        shouldShowDeliveredIndicator: isLastMessage && isFromMe,
        isLastMessage: isLastMessage,
        onRetry: onRetry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 185}}
      )
    )
  )
}

exports. default = RichMessageWrapper
