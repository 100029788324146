"use strict";Object.defineProperty(exports, "__esModule", {value: true});const disallowedRanges = [
  '\x00-\x08',
  '\x0B-\x1F',
  '\x7F',
  '\u202A-\u202D',
  '\u2066-\u2069',
].join('')
 const sanitizationRegExp = new RegExp(`[${disallowedRanges}]`, 'g'); exports.sanitizationRegExp = sanitizationRegExp

 const cleanupStr = (str) => str.replace(exports.sanitizationRegExp, ""); exports.cleanupStr = cleanupStr
 const sanitizeStr = (str) => exports.cleanupStr.call(void 0, str.trim()); exports.sanitizeStr = sanitizeStr
 const sanitizeFileName = (str) => exports.sanitizeStr.call(void 0, str)
  .replace(/[^A-Za-z0-9!$()-+=_., ]/g, '_'); exports.sanitizeFileName = sanitizeFileName

 const compareStr = (a, b) => {
  a = a ? String(a).toLowerCase() : ''
  b = b ? String(b).toLowerCase() : ''
  return a.localeCompare(b)
}; exports.compareStr = compareStr

 const isSubstring = (needle, haystack) =>
  haystack.toLowerCase().includes(needle.toLowerCase()); exports.isSubstring = isSubstring
