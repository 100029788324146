"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);

 const useResetPassword = () => {
  const parseResponse = (r) => {
    if (r.Response !== "Success" && r.Response !== "Valid Token") { throw r }
    return r
  }

  const onRequestPasswordReset = (email) =>
    _client2.default.sendPasswordResetEmail(email).then(parseResponse)

  const onResetPassword = (password, token) =>
    _client2.default.setPassword(password, token).then(parseResponse)

  const onCheckResetToken = (token) =>
    _client2.default.checkResetToken(token).then(parseResponse)

  return {
    onRequestPasswordReset,
    onResetPassword,
    onCheckResetToken,
  }
}; exports.useResetPassword = useResetPassword
