"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Icon.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

const Icon = (compProps) => {
  const { src, name, size, ...props } = compProps
  let iconSrc
  if (src) {
    iconSrc = src
  } else if (name) {
    iconSrc = `/assets/icons/icon-${name}.svg`
  }

  const style = {}
  const parentStyle = {}
  if (size) {
    style.width = size + "px"
    style.height = size + "px"
    style.top = "calc(-1em/6)"
    style.position = "relative"

    parentStyle.minWidth = size + "px"
  }

  if (props.onClick) {
    style.cursor = "pointer"
  }

  return (
    _react2.default.createElement('span', { className: "blmh-icon", style: parentStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      , _react2.default.createElement('img', { src: iconSrc, ...props, style: style, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}})
    )
  )
}

exports. default = Icon
