"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/NotificationPreferenceModal/Form/VIPSection.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _Form = require('web/components/Form');

const VIPSection = ({ isMuted, isVIP, onChangeVip }) => (
  _react2.default.createElement('div', { className: "chat--notification-settings--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
    , _react2.default.createElement('div', { className: "chat--notification-settings--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 7}}, "Priority Chat" )
    ,  isMuted
      ? (
          _react2.default.createElement('div', { className: "chat--notification-settings--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}, "Cannot change the priority of a muted chat."

          )
        )
      : (
          _react2.default.createElement(_Form.Toggle, {
            isChecked: isVIP,
            onChange: onChangeVip,
            className: "chat--notification-settings--vip-toggle",
            label: "Receive push notifications even when other chats are muted"        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
          )
        )
    
  )
)

VIPSection.propTypes = {
  isMuted: _proptypes2.default.bool,
  isVIP: _proptypes2.default.bool,
  onChangeVip: _proptypes2.default.func.isRequired,
}

exports. default = VIPSection
