"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _store = require('shared/store');
var _redux = require('redux');
var _utils = require('shared/store/utils');















const updateNotificationAction = _utils.createAction.call(void 0, 'Update', (payload) => ({ payload }))
const purgeNotificationAction = _utils.createAction('Purge')
const toastNotificationAction = _utils.createAction('Toast')
const exportChatCompleteNotificationAction =
  _utils.createAction('ExportChatCompleteNotification')
const exportPhoneLogsCompleteNotificationAction =
  _utils.createAction('ExportPhoneLogsCompleteNotification')
const patientImportCompleteNotificationAction =
  _utils.createAction('PatientImportCompleteNotification')
const updateOrgUsersNotificationAction =
  _utils.createAction('UpdateOrgUsersNotification')
const campaignProgressNotificationAction =
  _utils.createAction('CampaignProgressNotification')
const orgFeaturesChangedNotificationAction =
  _utils.createAction('OrgFeaturesChangedNotification')
const incomingPatientMessageNotificationAction =
  _utils.createAction('IncomingPatientMessageNotification')
const deletedPatientMessageNotificationAction =
  _utils.createAction('DeletedPatientMessageNotification')

 const actionCreators = {
  updateNotificationAction,
  purgeNotificationAction,
  toastNotificationAction,
  exportChatCompleteNotificationAction,
  exportPhoneLogsCompleteNotificationAction,
  patientImportCompleteNotificationAction,
  updateOrgUsersNotificationAction,
  campaignProgressNotificationAction,
  orgFeaturesChangedNotificationAction,
  incomingPatientMessageNotificationAction,
  deletedPatientMessageNotificationAction,
}; exports.actionCreators = actionCreators

exports. default = _redux.bindActionCreators.call(void 0, {
  handleUpdateNotification: updateNotificationAction,
  handlePurgeNotification: purgeNotificationAction,
  handleToastNotification: toastNotificationAction,
  handleExportChatCompleteNotification: exportChatCompleteNotificationAction,
  handleExportPhoneLogsCompleteNotification: exportPhoneLogsCompleteNotificationAction,
  handlePatientImportCompleteNotification: patientImportCompleteNotificationAction,
  handleUpdateOrgUsersNotification: updateOrgUsersNotificationAction,
  handleCampaignProgressNotification: campaignProgressNotificationAction,
  handleOrgFeaturesChangedNotification: orgFeaturesChangedNotificationAction,
  handleIncomingPatientMessageNotification: incomingPatientMessageNotificationAction,
  handleDeletedPatientMessageNotification: deletedPatientMessageNotificationAction,
}, _store.dispatch)
