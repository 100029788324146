"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Permissions/PermissionsRequest.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const PermissionsRequest = ({ arePermsBlocked, triggerPermsPrompt }) => {
  const requestClassName = _classBuilder2.default.call(void 0, 
    "meeting--perms--request", {
      "equal-spacings": arePermsBlocked,
    })

  return (
    _react2.default.createElement('div', { className: requestClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      , _react2.default.createElement('div', { className: "video-call--perm-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
        , _react2.default.createElement('div', { className: "video-call--perm-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
          , _react2.default.createElement('img', { src: "/assets/icons/video/call-audio.svg", alt: "mic", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}})
        )
        , _react2.default.createElement('div', { className: "video-call--perm-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
          , _react2.default.createElement('img', { src: "/assets/icons/video/call-video.svg", alt: "videocam", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}})
        )
      )
      , _react2.default.createElement('div', { className: "meeting--perms--desktop-info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        , _react2.default.createElement('h1', { className: "meeting--perms--desktop-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
          ,  arePermsBlocked
            ? "Allow Microphone & Camera Access"
            : "Microphone & Camera Access"
          
        )
        , _react2.default.createElement('span', { className: "meeting--perms--desktop-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          ,  arePermsBlocked
            ? _react2.default.createElement(_react2.default.Fragment, null, "You haven't allowed access to your microphone and camera. Please check your browser settings and allow access to continue."


              )
            : _react2.default.createElement(_react2.default.Fragment, null, "Please select "
                  , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Allow"), " when your browser asks for permission, so you can join the meeting."
              )
          
        )
        ,  arePermsBlocked &&
          _react2.default.createElement('a', {
            onClick: triggerPermsPrompt,
            className: "blmh-button video-call--button purple primary highlight meeting--perms--desktop-btn"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          , "Try Again"

          )
        
      )
      , _react2.default.createElement('div', { className: "meeting--perms--mobile-info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , _react2.default.createElement('span', { className: "meeting--perms--mobile-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, "Before joining this video call, you need to set up your device by allowing access to your microphone and camera."

        )
        , _react2.default.createElement('a', {
          onClick: triggerPermsPrompt,
          className: "blmh-button video-call--button purple primary video-call--perm-button"    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        , "Continue"

        )
      )
    )
  )
}

PermissionsRequest.propTypes = {
  arePermsBlocked: _proptypes2.default.bool.isRequired,
  triggerPermsPrompt: _proptypes2.default.func.isRequired,
}

exports. default = PermissionsRequest
