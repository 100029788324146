"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Forms/Team.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _team = require('shared/selectors/team');
var _org = require('shared/selectors/org');

var _teamInfo = require('shared/validation/team/teamInfo');
var _hookForm = require('shared/services/validation/hookForm');
var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');

const makeDefaultValues = (team) => ({
  name: _nullishCoalesce(_optionalChain([team, 'optionalAccess', _ => _.Name]), () => ( '')),
})
const preventDefault = e => e.preventDefault()











const TeamForm = ({
  team,
  teamKey,
  orgName,
  onCancel,
  onSubmit,
  submitBtnLabel,
  className,
  controlsClassName,
}) => {
  const { register, handleSubmit, formState } = _hookForm.useForm({
    resolver: _teamInfo.teamResolver,
    mode: 'onChange',
    defaultValues: makeDefaultValues(team),
  })

  const { isValid, isSubmitting, isDirty, errors } = formState

  return (
    _react2.default.createElement('form', { onSubmit: preventDefault, className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      ,  !teamKey && orgName && (
        _react2.default.createElement('span', { className: "directory--create-new--notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "This team will be created in "
                , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}, orgName), "."
        )
      )
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "Team Name" ,
        placeholder: "E.g. Front Desk"  ,
        inputProps: register('name'),
        errorMessage: _optionalChain([errors, 'access', _2 => _2.name, 'optionalAccess', _3 => _3.message]),
        className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: submitBtnLabel,
        isPrimaryEnabled: isValid && isDirty && !isSubmitting,
        onPrimaryBtnClick: handleSubmit(onSubmit),
        isPrimaryLoading: isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onCancel,
        className: controlsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
      )
    )
  )
}






const mapState = (state, { teamKey, orgKeyContext }) => ({
  team: teamKey && _team.selectTeamByKey.call(void 0, state, teamKey) || null,
  orgName: orgKeyContext && _optionalChain([_org.selectOrgByKey.call(void 0, orgKeyContext, state), 'optionalAccess', _4 => _4.Name]) || '',
})

exports. default = _reactredux.connect.call(void 0, mapState)(TeamForm)
