"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _org = require('shared/selectors/org');
var _team = require('shared/selectors/team');

var _InfoSection = require('./InfoSection'); var _InfoSection2 = _interopRequireDefault(_InfoSection);
var _MembersSection = require('./MembersSection'); var _MembersSection2 = _interopRequireDefault(_MembersSection);








const TeamInfoTab = ({
  teamKey,
  teamName,
  phoneNumber,
  orgKey,
  orgName,
  canEditTeam,
  onUpdateInfo,
  membersCount,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_InfoSection2.default, {
      teamKey: teamKey,
      teamName: teamName,
      phoneNumber: phoneNumber,
      orgKey: orgKey,
      orgName: orgName,
      canEditTeam: canEditTeam,
      canExportPhoneLogs: canEditTeam && !!phoneNumber,
      onUpdateInfo: onUpdateInfo, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
    )
    , _react2.default.createElement(_MembersSection2.default, {
      teamKey: teamKey,
      teamName: teamName,
      orgKey: orgKey,
      canEditTeam: canEditTeam,
      membersCount: membersCount, __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
    )
  )
)








const mapState = (
  state,
  { teamKey, orgKey },
) => {
  const team = _team.selectTeamByKey.call(void 0, state, teamKey)
  const orgName = _nullishCoalesce(_optionalChain([_org.selectOrgByKey.call(void 0, orgKey, state), 'optionalAccess', _ => _.Name]), () => ( ''))
  const teamName = _nullishCoalesce(_optionalChain([team, 'optionalAccess', _2 => _2.Name]), () => ( ''))
  const phoneNumber = _nullishCoalesce(_optionalChain([team, 'optionalAccess', _3 => _3.PhoneNumber]), () => ( ''))
  const membersCount = team ? Object.keys(team.Members).length : 0

  return {
    orgName,
    teamName,
    phoneNumber,
    membersCount,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(TeamInfoTab)
