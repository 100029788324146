"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignStatus.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');








var _broadcast = require('shared/selectors/broadcast');
var _time = require('shared/services/time');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);






const CampaignStatus = ({
  stats, totalPatientsCount, isActive, createdAt, className,
}) => {
  const dateSuffix = _time.formatDisplayDate.call(void 0, createdAt)

  if (isActive) {
    return (
      _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, "campaign-status campaign-status--active", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, "Running ("
         ,  stats.completed , "/",  totalPatientsCount , ")"
      )
    )
  }

  return (
    _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, "campaign-status campaign-status--inactive", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, "Stopped ("
       ,  stats.completed , "/",  totalPatientsCount , ") · "  ,  dateSuffix 
    )
  )
}








const mapState = (state, { campaignKey }) => ({
  isActive: _broadcast.selectIsCampaignActive.call(void 0, state, campaignKey),
  totalPatientsCount: _broadcast.selectCampaignTotalPatientsCount.call(void 0, state, campaignKey),
  stats: _broadcast.selectCampaignStats.call(void 0, state, campaignKey),
  createdAt: _nullishCoalesce(_optionalChain([_broadcast.selectCampaignByKey.call(void 0, state, campaignKey), 'optionalAccess', _ => _.CreatedAt]), () => ( '')),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CampaignStatus)
