"use strict"; function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { newObj[key] = obj[key]; } } } newObj.default = obj; return newObj; } } function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _pushjs = require('push.js'); var _pushjs2 = _interopRequireDefault(_pushjs);
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _notification = require('web/services/notification');
var _wizard = require('web/services/wizard');
var _store = require('web/store');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _log = require('shared/log');
var _firstTimeTutorial = require('web/actions/firstTimeTutorial');
var _react = require('@sentry/react'); var Sentry = _interopRequireWildcard(_react);
var _app = require('web/services/app');
var _conversation = require('shared/selectors/conversation');



var _user = require('shared/selectors/user');
var _download = require('shared/services/download');
var _upgrade = require('shared/services/upgrade');
var _cookies = require('web/services/cookies');
var _signup = require('shared/enum/signup');

// Sentry
if (SENTRY_DSN) {
  _bus2.default.on("SERVER_ACK_SOCKET_CONNECTED", (action) =>
    Sentry.setUser({
      id: action.userInfo.UserKey,
    }),
  )

  _bus2.default.on(_actionCreators2.default.logoutAction.type, () => Sentry.setUser({}))
}

_bus2.default.on('SESSION_EXPIRED', () => {
  _actions2.default.gotoLogin()
  _pushjs2.default.create("BloomText", {
    body: 'Your session has timed out.',
    icon: "assets/icons/logo-256x256.png",
    silent: true,
    onClick: function () {
      window.focus()
      this.close()
    },
  }).catch(() => null)
})

// Notifications
_bus2.default.on(_actionCreators2.default.toastNotificationAction.type, (action, state) => {
  const currentUserKey = _user.selectCurrentUserKey.call(void 0, state)
  if (!_notification.hasBrowserPermission.call(void 0, ) || !_notification.getCookiePermission.call(void 0, currentUserKey)) return

  const { Type, Message, Sound, ExtraData } = action.payload
  const { blobjectKey, wrappedKey, wrappedType } = ExtraData

  if (Type !== "new_message") return

  if (Sound) playSound(Sound)

  const isCurrentUserActive = _user.selectIsCurrentUserActive.call(void 0, state)
  const visibleConversationKey = _conversation.selectVisibleConversationKey.call(void 0, state)
  if (visibleConversationKey === wrappedKey && isCurrentUserActive) return

  _pushjs2.default.create("BloomText", {
    body: Message,
    icon: "assets/icons/logo-256x256.png",
    silent: true,
    onClick: function () {
      window.focus()
      _actions2.default.gotoBlobject(wrappedType, wrappedKey, blobjectKey)
      this.close()
    },
  }).catch(() => null)
})

const updateAppOnce = () => {
  const cookieName = 'autoUpdateCooldown'

  if (_cookies.readCookie.call(void 0, cookieName)) {
    _log.log.info("Ignoring this update because we updated recently")
    return
  }
  _cookies.createCookie.call(void 0, cookieName, true, { seconds: 300 })
  _app.updateApp.call(void 0, )
}

_bus2.default.on('SERVER_ACK_SOCKET_CONNECTED', (action, state) => {
  _log.log.info(`Checking for a desktop update...`)
  if (_download.shouldDownloadUpgrade.call(void 0, )) {
    _log.log.info('Found a desktop update')
    _actions2.default.downloadAvailable()
    return
  }
  _log.log.info(`Checking for an update for ${VERSION}...`)
  if (!_upgrade.shouldAttemptUpgrade.call(void 0, action.buildConfig)) {
    _log.log.info("No update is available")
    return
  }
  _log.log.info(`Found an update to ${action.buildConfig.GitHash}`)
  if (_conversation.selectHasAnyDrafts.call(void 0, state)) {
    _log.log.info("Ignoring this update because we have drafts")
    _actions2.default.markAppOutdated()
    return
  }
  updateAppOnce()
})

_bus2.default.once(_actionCreators2.default.loginSuccessAction.type, action => { // Successful login
  const { UserKey } = action.payload.User
  const isInterestedInPatientMessaging = !!(_optionalChain([action, 'access', _ => _.meta, 'optionalAccess', _2 => _2.survey, 'access', _3 => _3.features]) || [])
    .find(x => x === _signup.OrgSurveyFeature.SMS_MESSAGING)

  const isFirstLoginEver = action.payload.User.NumberOfLogins === 0
  const isOneChatOnly = Object.keys(action.payload.Chats).length === 1
  const isQuickLoginEnabled = action.payload.User.QuickLogin
  const hasQuickLoginBeenConsidered = _wizard.isUserAwareOfQuickLogin.call(void 0, UserKey)

  const tutorialQueue = []
  if (isFirstLoginEver) {
    tutorialQueue.push({
      type: 'POPUP',
      id: isInterestedInPatientMessaging
        ? 'SMS_MESSAGING_POPUP'
        : 'FIRST_TIME_POPUP',
    })

    if (isOneChatOnly) { tutorialQueue.push({ type: 'TOOLTIP', id: _firstTimeTutorial.firstTimeTooltipIds.FIRST_CHAT }) }
    tutorialQueue.push({ type: 'TOOLTIP', id: _firstTimeTutorial.firstTimeTooltipIds.CREATE_NEW_BUTTON })
    tutorialQueue.push({ type: 'TOOLTIP', id: _firstTimeTutorial.firstTimeTooltipIds.SWITCH_INBOX })
    tutorialQueue.push({ type: 'TOOLTIP', id: _firstTimeTutorial.firstTimeTooltipIds.PROFILE })
  }

  if (_notification.hasBrowserPermission.call(void 0, ) === null) { // Not explicitly blocked
    tutorialQueue.push({ type: 'SNACKBAR', id: 'ENABLE_NOTIFICATIONS_SUGGESTION' })
  }

  if (!isQuickLoginEnabled && !hasQuickLoginBeenConsidered) {
    tutorialQueue.push({ type: 'SNACKBAR', id: 'ENABLE_QUICK_LOGIN_SUGGESTION' })
  }

  _actions2.default.pushInteractablesQueue(tutorialQueue)
})

_bus2.default.once("SERVER_ACK_SOCKET_CONNECTED", (_action, state) => {
  _wizard.addUserToPreviousLoginsList.call(void 0, state.currentUser.UserKey)
})

_bus2.default.on("LEAVE_CHAT", () => _store.history.push('/chats'))

function playSound (sound) {
  let notificationURL = sound || 'assets/sounds/notifications/ping.wav'
  if (!notificationURL.includes('assets')) {
    notificationURL = 'assets/sounds/notifications/' + notificationURL
  }
  const audio = new Audio(notificationURL)
  const played = audio.play()
  if (played !== undefined) {
    played.catch(e => _log.log.info("Couldn't play audio", notificationURL, e))
  }
}
