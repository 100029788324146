"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/PlanPreview.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _cost = require('shared/services/cost');

var _Controls = require('./Controls'); var _Controls2 = _interopRequireDefault(_Controls);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);












const PlanPreview = ({
  title,
  subtitle,
  plan,
  onContinue,
  demoVideoUrl,
  baseCost,
  pricePrefix,
  priceDesc,
}) => {
  const [isWatchingDemo, setIsWatchingDemo] = _react.useState.call(void 0, false)

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('h1', { className: "plan-modal--plan-preview--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}},  title )
      , _react2.default.createElement('h4', { className: "plan-modal--plan-preview--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}},  subtitle )
      , _react2.default.createElement('div', { className: "plan-modal--plan-preview--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        ,  isWatchingDemo
          ? _react2.default.createElement('div', { className: "plan-modal--plan-preview--video-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
              , _react2.default.createElement('iframe', {
                className: "plan-modal--plan-preview--video",
                allow: "autoplay; fullscreen; picture-in-picture"  ,
                src: demoVideoUrl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
              )
            )
          : _react2.default.createElement(_react2.default.Fragment, null
              , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
                "plan-modal--plan-preview--desc",
                `plan-modal--plan-preview--desc--${plan.theme}`,
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
                , _react2.default.createElement('img', { className: "plan-modal--plan-preview--icon", src: plan.iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}} )
                , _react2.default.createElement('h2', { className: "plan-modal--plan-preview--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}},  plan.name )
                , _react2.default.createElement('span', { className: "plan-modal--plan-preview--price-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
                  ,  pricePrefix && (
                    _react2.default.createElement('span', { className: "plan-modal--plan-preview--price-prefix", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
                      ,  pricePrefix 
                    )
                  )
                  , _react2.default.createElement('span', { className: "plan-modal--plan-preview--price", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
                    ,  _cost.formatDisplayCost.call(void 0, baseCost) 
                  ), "/"
                  ,  priceDesc 
                )
              )
              , _react2.default.createElement('div', {
                className: _classBuilder2.default.call(void 0, 
                  "plan-modal--plan-preview--divider",
                  `plan-modal--plan-preview--divider--${plan.theme}`,
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
              )
              , _react2.default.createElement('div', { className: "plan-modal--plan-preview--features", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
                , _react2.default.createElement('h3', { className: "plan-modal--plan-preview--features--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}, "What's included:"

                )
                , _react2.default.createElement('ul', { className: _classBuilder2.default.call(void 0, 
                  "plan-modal--plan-preview--features--list",
                  `plan-modal--plan-preview--features--list--${plan.theme}`,
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
                  ,  plan.features.map((f, i) => _react2.default.createElement('li', { key: i, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}},  f )) 
                )
              )
            )
        
      )
      ,  demoVideoUrl
        ? _react2.default.createElement(_Controls2.default, {
            secondaryLabel: isWatchingDemo ? "Back" : "Watch demo",
            secondaryIcon: demoVideoUrl && !isWatchingDemo && _react2.default.createElement('img', { src: "/assets/icons/broadcast/play-outlined.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}} ),
            onSecondaryClick: () => setIsWatchingDemo(x => !x),
            primaryLabel: "Continue",
            onPrimaryClick: onContinue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
          )
        : _react2.default.createElement(_Controls2.default, {
            primaryLabel: "Continue",
            onPrimaryClick: onContinue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
          )
      
    )
  )
}

exports. default = PlanPreview
