"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/OrgPicker/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _user = require('shared/selectors/user');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);

var _Tooltip = require('web/components/Tooltip');
var _OrgOption = require('./OrgOption'); var _OrgOption2 = _interopRequireDefault(_OrgOption);






const OrgPicker = ({
  orgs, selectedOrgKey, onSelectOrg,
}) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )
  const selectedOrg = _react.useMemo.call(void 0, 
    () => orgs.find(org => org.OrgKey === selectedOrgKey),
    [orgs, selectedOrgKey],
  )

  const displayOption = selectedOrg
    ? _react2.default.createElement(_OrgOption2.default, { iconName: "org", label: selectedOrg.Name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
    : _react2.default.createElement(_OrgOption2.default, {
        iconName: "network",
        label: orgs.length
          ? "Pick an organization"
          : "No organization"
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      )

  if (orgs.length < 2 && selectedOrgKey) {
    return _react2.default.createElement('div', { className: "directory--picker--display", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}},  displayOption )
  }

  const content = (
    _react2.default.createElement(_Tooltip.TooltipContent, { 'aria-roledescription': "Organization picker" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      ,  orgs.map(o => (
        _react2.default.createElement(_Tooltip.TooltipItem, {
          role: "option",
          'aria-selected': selectedOrgKey === o.OrgKey,
          key: o.OrgKey,
          onClick: () => { onClose(); onSelectOrg(o.OrgKey) }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        
          , _react2.default.createElement(_OrgOption2.default, {
            label: o.Name,
            iconName: "org",
            isSelected: selectedOrgKey === o.OrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          )
        )
      ))
    )
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      hideArrow: true,
      isDismissibleByClickElsewhere: true,
      place: "bottom-left",
      isOpen: isOpen,
      onClose: onClose,
      content: content, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
    
      , _react2.default.createElement('div', { role: "button", className: "directory--picker--display", onClick: onOpen, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        ,  displayOption , ' '
        , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}, "keyboard_arrow_down")
      )
    )
  )
}





const mapState = (state) => ({
  orgs: _user.selectMyActiveOrgsList.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(OrgPicker)
