"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/PlanSummary.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Header = require('./Header'); var _Header2 = _interopRequireDefault(_Header);
var _Controls = require('./Controls'); var _Controls2 = _interopRequireDefault(_Controls);









const PlanSummary = ({
  title,
  subtitle,
  onBack,
  onNext,
  isNextLoading,
  children,
}) => (
  _react2.default.createElement(_react2.default.Fragment, null
    , _react2.default.createElement(_Header2.default, { title: title, subtitle: subtitle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}} )
    ,  children 
    , _react2.default.createElement(_Controls2.default, {
      isPrimaryLoading: isNextLoading,
      primaryLabel: "Continue",
      onPrimaryClick: onNext,
      secondaryLabel: "Previous",
      onSecondaryClick: onBack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    )
  )
)

exports. default = PlanSummary
