"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Forms/Patient.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');
var _reactredux = require('react-redux');




var _peers = require('shared/selectors/peers');
var _org = require('shared/selectors/org');
var _time = require('shared/services/time');
var _patient = require('shared/validation/patient');




var _Form = require('web/components/Form');
var _Modal = require('web/components/Modal');

const preventDefault = e => e.preventDefault()
















const makeDefaultValues = (
  patient,
  defaultEmail,
  defaultPhone,
  defaultFirstName,
  defaultLastName,
) => ({
  firstName: _optionalChain([patient, 'optionalAccess', _ => _.FirstName]) || defaultFirstName || '',
  lastName: _optionalChain([patient, 'optionalAccess', _2 => _2.LastName]) || defaultLastName || '',
  dob: _optionalChain([patient, 'optionalAccess', _3 => _3.DateOfBirth]) || '',
  email: _optionalChain([patient, 'optionalAccess', _4 => _4.Email]) || defaultEmail || '',
  phone: _optionalChain([patient, 'optionalAccess', _5 => _5.Phone]) || defaultPhone || '',
  foreignID: _optionalChain([patient, 'optionalAccess', _6 => _6.ForeignID]) || '',
})

const PatientForm = ({
  patient,
  patientKey,
  orgName,
  isDisabled,
  className,
  controlsClassName,
  submitBtnLabel,
  onSubmit,
  onCancel,
  defaultEmail,
  defaultPhone,
  defaultFirstName,
  defaultLastName,
}) => {
  const { register, control, handleSubmit, formState, reset } =
    _reacthookform.useForm({
      resolver: _patient.patientResolver,
      mode: 'onChange',
      defaultValues: makeDefaultValues(
        patient,
        defaultEmail,
        defaultPhone,
        defaultFirstName,
        defaultLastName,
      ),
    })
  const { isValid, isSubmitting, errors } = formState

  _react.useEffect.call(void 0, 
    () => { if (patient) reset(makeDefaultValues(patient)) },
    [reset, patient],
  )

  return (
    _react2.default.createElement('form', { onSubmit: preventDefault, className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      ,  orgName && !patientKey && !isDisabled && (
        _react2.default.createElement('span', { className: "directory--create-new--notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, "This patient will be added to "
                , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, orgName), "."
        )
      )
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
        , _react2.default.createElement(_Form.InputSection, {
          autoFocus: !defaultFirstName,
          title: "First Name" ,
          placeholder: "E.g. John" ,
          inputProps: register('firstName'),
          isDisabled: isDisabled,
          isOptional: true,
          errorMessage: _optionalChain([errors, 'access', _7 => _7.firstName, 'optionalAccess', _8 => _8.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
        )
        , _react2.default.createElement(_Form.InputSection, {
          title: "Last Name" ,
          placeholder: "E.g. Parker" ,
          inputProps: register('lastName'),
          isDisabled: isDisabled,
          isOptional: true,
          errorMessage: _optionalChain([errors, 'access', _9 => _9.lastName, 'optionalAccess', _10 => _10.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
        , _react2.default.createElement(_Form.InputSection, {
          title: "Email",
          autoFocus: !!(defaultFirstName && defaultLastName && !defaultEmail),
          placeholder: "E.g. john@email.com" ,
          inputProps: register('email'),
          isDisabled: isDisabled,
          errorMessage: _optionalChain([errors, 'access', _11 => _11.email, 'optionalAccess', _12 => _12.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        )
        , _react2.default.createElement(_Form.InputSection, {
          title: "Phone",
          autoFocus: !!(defaultFirstName && defaultLastName && defaultEmail && !defaultPhone),
          placeholder: "E.g. 415-903-1200" ,
          inputProps: register('phone'),
          isDisabled: isDisabled,
          errorMessage: _optionalChain([errors, 'access', _13 => _13.phone, 'optionalAccess', _14 => _14.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
        )
      )
      , _react2.default.createElement(_Form.InputSectionGroup, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 133}}
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Date of Birth"  , isOptional: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
          , _react2.default.createElement(_Form.DateInputController, {
            name: "dob",
            control: control,
            placeholder: `E.g. ${_time.TODAY_DISPLAY_DATE}`,
            isDisabled: isDisabled,
            className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
          )
        )
        , _react2.default.createElement(_Form.InputSection, {
          title: "MRN",
          placeholder: "E.g. 0000001" ,
          inputProps: register('foreignID'),
          isOptional: true,
          isDisabled: isDisabled,
          errorMessage: _optionalChain([errors, 'access', _15 => _15.foreignID, 'optionalAccess', _16 => _16.message]),
          className: "directory--create-new--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: submitBtnLabel,
        isPrimaryEnabled: isValid && !isSubmitting && !isDisabled,
        onPrimaryBtnClick: handleSubmit(onSubmit),
        isPrimaryLoading: isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onCancel,
        className: controlsClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}
      )
    )
  )
}






const mapState = (state, { orgKeyContext, patientKey }) => ({
  patient: patientKey && _peers.selectPatientByKey.call(void 0, state, patientKey) || null,
  orgName: orgKeyContext && _optionalChain([_org.selectOrgByKey.call(void 0, orgKeyContext, state), 'optionalAccess', _17 => _17.Name]) || '',
})

exports. default = _reactredux.connect.call(void 0, mapState)(PatientForm)
