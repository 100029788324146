"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/FilteredTable.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useBus = require('shared/hooks/useBus'); var _useBus2 = _interopRequireDefault(_useBus);

var _broadcast3 = require('shared/selectors/broadcast');

var _Table = require('./components/Table'); var _Table2 = _interopRequireDefault(_Table);
var _Row = require('./Row'); var _Row2 = _interopRequireDefault(_Row);

const LOAD_LIMIT = 100












const FilteredTable = ({
  selectedRowsMap,
  onSetRowSelection,
  onSetRowsBatchSelection,
  areAnyRowsSelected,
  onAddPatients,
  campaignKey,
  keyActionTypes,
  filter,
  patientKeys,
}) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)

  const onReset = _react.useCallback.call(void 0, 
    async () => {
      setIsLoading(true)
      await _actions2.default.findCampaignMessages(campaignKey, 0, LOAD_LIMIT, filter)
      setIsLoading(false)
    },
    [campaignKey, filter, setIsLoading],
  )

  _useBus2.default.call(void 0, keyActionTypes, onReset)
  _react.useEffect.call(void 0, () => { onReset() }, [onReset])

  const onSetAllRowsSelection = _react.useCallback.call(void 0, 
    isSelected => onSetRowsBatchSelection(patientKeys, isSelected),
    [patientKeys, onSetRowsBatchSelection],
  )

  return (
    _react2.default.createElement(_Table2.default, {
      isFiltered: true,
      onSetAllRowsSelection: onSetAllRowsSelection,
      areAnyRowsSelected: areAnyRowsSelected,
      hasItems: patientKeys.length > 0,
      isLoading: isLoading,
      onAddPatients: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
    
      ,  patientKeys.map(patientKey => (
        _react2.default.createElement(_Row2.default, {
          key: patientKey,
          patientKey: patientKey,
          isSelected: !!selectedRowsMap[patientKey],
          onToggleSelection: b => onSetRowSelection(patientKey, b), __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
        )
      ))
    )
  )
}





const mapState = (state, { filter }) => ({
  patientKeys: _broadcast3.selectBroadcastMessageKeysByState.call(void 0, state, filter),
})

exports. default = _reactredux.connect.call(void 0, mapState)(FilteredTable)
