"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Modals/JoinCall.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _VideoFeed = require('web/components/VideoCall/VideoFeed'); var _VideoFeed2 = _interopRequireDefault(_VideoFeed);


var _CallControls = require('web/components/VideoCall/CallControls');

var _LinkBar = require('web/components/VideoRooms/Room/LinkBar'); var _LinkBar2 = _interopRequireDefault(_LinkBar);
var _JoinMeetingBtnContent = require('web/components/VideoCall/JoinMeetingBtnContent'); var _JoinMeetingBtnContent2 = _interopRequireDefault(_JoinMeetingBtnContent);
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);
var _videoCall = require('web/services/videoCall');

 class JoinCallModal extends _react2.default.Component {constructor(...args) { super(...args); JoinCallModal.prototype.__init.call(this);JoinCallModal.prototype.__init2.call(this); }
  __init() {this.renderStream = () => {
    const {
      stream,
      isMicOn, isCamOn,
      isCamCycleAvailable,
      onCycleCam,
      onToggleCam, onToggleMic,
    } = this.props

    return (
      _react2.default.createElement('div', { className: "meeting-room--join-modal--feed-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        , _react2.default.createElement(_VideoFeed2.default, {
          stream: stream,
          isVideoOn: isCamOn,
          shouldMirror: _videoCall.shouldMirrorCamFeed.call(void 0, stream),
          className: "meeting-room--join-modal--feed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        )
        , _react2.default.createElement('div', { className: "meeting-room--join-modal--controls-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
          ,  isCamCycleAvailable &&
            _react2.default.createElement(_CallControls.SwitchCamBtn, {
              onClick: onCycleCam,
              className: "meeting-room--join-modal--control-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
            )
          
          , _react2.default.createElement(_CallControls.DisableCamBtn, {
            onClick: onToggleCam,
            className: "meeting-room--join-modal--control-button",
            isActive: !isCamOn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
          )
          , _react2.default.createElement(_CallControls.DisableMicBtn, {
            onClick: onToggleMic,
            className: "meeting-room--join-modal--control-button",
            isActive: !isMicOn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
          )
        )
      )
    )
  }}

  __init2() {this.renderStreamWarning = () => {
    const { isBrowserCompatible, arePermsGranted } = this.props

    const warnings = {
      incompatibleBrowser: {
        message: "Your current browser does not support video calls.",
      },
      noPerms: {
        message: "Please allow access to your microphone and camera.",
      },
      unknown: {
        message: "An unknown error has occurred.",
      },
    }

    const warning =
      !isBrowserCompatible && warnings.incompatibleBrowser ||
      !arePermsGranted && warnings.noPerms ||
      warnings.unknown

    return (
      _react2.default.createElement('div', { className: "meeting-room--join-modal--stream-warning-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        , _react2.default.createElement(_WarningBar2.default, {
          className: "meeting-room--join-modal--stream-warning",
          text: warning.message, __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
        )
      )
    )
  }}

  render () {
    const {
      isOpen,
      onClose,
      roomName,
      roomLink,
      onRoomShare,
      onRoomLinkCopy,
      isMicOn,
      isCamOn,
      gotoMeeting,
      gotoSettings,
      isBrowserCompatible,
      arePermsGranted,
    } = this.props

    const isStreamAvailable = isBrowserCompatible && arePermsGranted
    const buildBtnClassName = (...cs) =>
      _classBuilder2.default.call(void 0, { disabled: !isStreamAvailable }, cs)

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: isOpen,
        onClose: onClose,
        className: "meeting-room--join-modal",
        title: "Meeting Room" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}
      
        , _react2.default.createElement('div', { className: "meeting-room--join-modal--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 113}}
          , _react2.default.createElement('h1', { className: "meeting-room--join-modal--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}},  roomName )
          , _react2.default.createElement('span', { className: "meeting-room--join-modal--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}, "You are ready to join the meeting."      )
        )
        ,  isStreamAvailable
          ? this.renderStream()
          : this.renderStreamWarning()
        
        ,  isBrowserCompatible &&
          _react2.default.createElement('div', { className: "meeting-room--join-modal--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
            , _react2.default.createElement('a', {
              onClick: gotoMeeting,
              className: buildBtnClassName(
                "meeting--btn", "meeting-room--join-modal--proceed-btn",
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
            
              , _react2.default.createElement(_JoinMeetingBtnContent2.default, {
                isMicOn: isMicOn,
                isCamOn: isCamOn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
              )
            )
            , _react2.default.createElement('a', {
              onClick: gotoSettings,
              className: buildBtnClassName(
                "meeting--btn", "secondary",
                "meeting-room--join-modal--back-btn",
              ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
            , "Check settings"

            )
          )
        
        , _react2.default.createElement('div', { className: "meeting-room--join-modal--sharing", __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
          , _react2.default.createElement(_LinkBar2.default, {
            link: roomLink,
            onShare: onRoomShare,
            onCopy: onRoomLinkCopy,
            className: "lg",
            iconClassName: "purple",
            isIconLabelShown: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 146}}
          )
          , _react2.default.createElement('span', { className: "meeting-room--join-modal--note", __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}, "Share this link with people you want in the meeting."

          )
        )
      )
    )
  }

  static __initStatic() {this.defaultProps = { stream: null }}
  static __initStatic2() {this.propTypes = {
    isOpen: _proptypes2.default.bool.isRequired,
    onClose: _proptypes2.default.func.isRequired,
    roomName: _proptypes2.default.string.isRequired,
    roomLink: _proptypes2.default.string.isRequired,
    onRoomShare: _proptypes2.default.func.isRequired,
    onRoomLinkCopy: _proptypes2.default.func.isRequired,
    gotoSettings: _proptypes2.default.func.isRequired,
    gotoMeeting: _proptypes2.default.func.isRequired,
    isMicOn: _proptypes2.default.bool.isRequired,
    isCamOn: _proptypes2.default.bool.isRequired,
    isCamCycleAvailable: _proptypes2.default.bool.isRequired,
    onCycleCam: _proptypes2.default.func.isRequired,
    onToggleCam: _proptypes2.default.func.isRequired,
    onToggleMic: _proptypes2.default.func.isRequired,
    stream: _proptypes2.default.any,
    isBrowserCompatible: _proptypes2.default.bool.isRequired,
    arePermsGranted: _proptypes2.default.bool.isRequired,
  }}
} JoinCallModal.__initStatic(); JoinCallModal.__initStatic2(); exports.default = JoinCallModal;
