"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }


var _authReducer = require('./authReducer'); var _authReducer2 = _interopRequireDefault(_authReducer);

// Server Data Stores
var _settingsReducer = require('./settingsReducer'); var _settingsReducer2 = _interopRequireDefault(_settingsReducer);
var _userReducer = require('./userReducer'); var _userReducer2 = _interopRequireDefault(_userReducer);
var _currentUserReducer = require('./currentUserReducer'); var _currentUserReducer2 = _interopRequireDefault(_currentUserReducer);
var _loginCookiesReducer = require('./loginCookiesReducer'); var _loginCookiesReducer2 = _interopRequireDefault(_loginCookiesReducer);
var _activityReducer = require('./activityReducer'); var _activityReducer2 = _interopRequireDefault(_activityReducer);
var _broadcastReducer = require('./broadcastReducer'); var _broadcastReducer2 = _interopRequireDefault(_broadcastReducer);
var _broadcastMessagesReducer = require('./broadcastMessagesReducer'); var _broadcastMessagesReducer2 = _interopRequireDefault(_broadcastMessagesReducer);
var _alertReducer = require('./alertReducer'); var _alertReducer2 = _interopRequireDefault(_alertReducer);
var _orgsReducer = require('./orgsReducer'); var _orgsReducer2 = _interopRequireDefault(_orgsReducer);
var _patientsReducer = require('./patientsReducer'); var _patientsReducer2 = _interopRequireDefault(_patientsReducer);
var _patientConversationsReducer = require('./patientConversationsReducer'); var _patientConversationsReducer2 = _interopRequireDefault(_patientConversationsReducer);
var _patientConversationMessagesReducer = require('./patientConversationMessagesReducer'); var _patientConversationMessagesReducer2 = _interopRequireDefault(_patientConversationMessagesReducer);
var _callReducer = require('./callReducer'); var _callReducer2 = _interopRequireDefault(_callReducer);
var _appReducer = require('./appReducer'); var _appReducer2 = _interopRequireDefault(_appReducer);
var _V2 = require('./V2'); var _V22 = _interopRequireDefault(_V2);

 const allReducers = {
  app: _appReducer2.default,
  auth: _authReducer2.default,
  alerts: _alertReducer2.default,
  activity: _activityReducer2.default,
  broadcasts: _broadcastReducer2.default,
  broadcastMessages: _broadcastMessagesReducer2.default,
  settings: _settingsReducer2.default,
  call: _callReducer2.default,
  users: _userReducer2.default,
  currentUser: _currentUserReducer2.default,
  loginCookies: _loginCookiesReducer2.default,
  orgs: _orgsReducer2.default,
  patients: _patientsReducer2.default,
  patientConversations: _patientConversationsReducer2.default,
  patientConversationMessages: _patientConversationMessagesReducer2.default,
  dataV2: _V22.default,
}; exports.allReducers = allReducers






















 const allFields = Object.keys(exports.allReducers) ; exports.allFields = allFields

const createRootReducer = (
  extraReducersMap = {} ,
) => {
  const reducers = { ...exports.allReducers, ...extraReducersMap }

  return (state, action) => {
    const intermediateState = { ...state }

    for (const k in reducers) {
      const key = k 
      // eslint-disable-next-line
      // @ts-ignore:next-line
      intermediateState[key] = reducers[key](_optionalChain([state, 'optionalAccess', _ => _[key]]), action, state)
    }

    return intermediateState 
  }
}

exports. default = createRootReducer
