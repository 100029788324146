"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _lodash = require('lodash');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

var _team = require('shared/selectors/team');

const initialState = {
  conversationDrafts: {},
  visibleConversationKey: null,

  selectedTeamKey: null,
  selectedOrgKey: null,
}

 function sessionReducer (state = initialState, action, globalState) {
  switch (action.type) {
    case _actionCreators2.default.sendMessageToConversationActions.REQUEST.type: {
      const { ConversationKey } = action.payload
      if (!state.conversationDrafts[ConversationKey]) return state

      return {
        ...state,
        conversationDrafts: _lodash.omit.call(void 0, 
          state.conversationDrafts,
          action.payload.ConversationKey,
        ),
      }
    }

    case _actionCreators2.default.saveConversationDraftAction.type: {
      const { conversationKey, message } = action.payload

      if (!message) {
        return {
          ...state,
          conversationDrafts: _lodash.omit.call(void 0, state.conversationDrafts, conversationKey),
        }
      }

      return {
        ...state,
        conversationDrafts: {
          ...state.conversationDrafts,
          [conversationKey]: message,
        },
      }
    }

    case _actionCreators2.default.markConversationVisibleAction.type: {
      const { conversationKey, isVisible } = action.payload
      return {
        ...state,
        visibleConversationKey: isVisible ? conversationKey : null,
      }
    }

    case _actionCreators2.default.setSelectedOrgAction.type:
      return { ...state, selectedOrgKey: action.payload }

    case _actionCreators2.default.setSelectedTeamAction.type: {
      const selectedTeamKey = action.payload
      const allTeams = _team.selectTeams.call(void 0, { dataV2: globalState })
      const team = allTeams[selectedTeamKey]

      return {
        ...state,
        selectedTeamKey,
        selectedOrgKey: _optionalChain([team, 'optionalAccess', _ => _.OrgKey]) || null,
      }
    }
  }

  return state
} exports.default = sessionReducer;
