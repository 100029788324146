"use strict";Object.defineProperty(exports, "__esModule", {value: true});

 const passError = (cb) => (e) => {
  cb(e)
  throw e
}; exports.passError = passError

 const isWSError = (e) =>
  !!e && !(e instanceof Error); exports.isWSError = isWSError

 const isUnauthorizedError = (e) =>
  exports.isWSError.call(void 0, e) && e.Status === 'unauthorized'; exports.isUnauthorizedError = isUnauthorizedError
