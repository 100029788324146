"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/OrgPicker/OrgOption.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const PickerOption = ({ iconName, label, isSelected }) => {
  const iconColor = isSelected ? 'purple' : 'grey'
  const iconSrc = `/assets/icons/tabs/${iconName}-${iconColor}.svg`

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "directory--picker--option", isSelected && "selected"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , _react2.default.createElement('img', { src: iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}} )
      , _react2.default.createElement('span', { className: "directory--picker--option--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}},  label )
    )
  )
}

exports. default = PickerOption
