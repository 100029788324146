"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/PreCall/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _CallControls = require('web/components/VideoCall/CallControls');
var _JoinMeetingBtnContent = require('web/components/VideoCall/JoinMeetingBtnContent'); var _JoinMeetingBtnContent2 = _interopRequireDefault(_JoinMeetingBtnContent);
var _VideoFeed = require('web/components/VideoCall/VideoFeed'); var _VideoFeed2 = _interopRequireDefault(_VideoFeed);
var _videoCall = require('web/services/videoCall');

const PreCall = ({
  gotoCallSetup, gotoCall,
  onCycleCam, onToggleCam, onToggleMic,
  isCamOn, isMicOn, isCamCycleAvailable,
  areCamIssuesPresent, areMicIssuesPresent,
  localStream, openNamePromptModal,
  userName, currentRoomUserKey,
}) => (
  _react2.default.createElement('div', { className: "meeting--precall", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    , _react2.default.createElement('div', { className: "meeting--precall--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      , _react2.default.createElement('div', { className: "meeting--precall--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        , _react2.default.createElement('div', { className: "meeting--precall--heading", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
          , _react2.default.createElement('h3', { className: "meeting--precall--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}, "Join the Meeting"  )
          , _react2.default.createElement('span', { className: "meeting--precall--heading-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "You are ready to join the meeting."

          )
        )
        , _react2.default.createElement('div', { className: "meeting--precall--name-bar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
          , _react2.default.createElement('div', { className: "meeting--precall--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
            , _react2.default.createElement('img', { src: "/assets/icons/video/patient-grey.svg", alt: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
          )
          , _react2.default.createElement('span', { className: "meeting--precall--username", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}},  userName || 'Unknown' )
          ,  currentRoomUserKey &&
            _react2.default.createElement('a', {
              onClick: openNamePromptModal,
              className: "meeting--help-link meeting--precall--username-hint" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
            , "Not you?"

            )
          
        )
        , _react2.default.createElement('div', { className: "meeting--precall--feed-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
          , _react2.default.createElement(_VideoFeed2.default, {
            shouldMirror: _videoCall.shouldMirrorCamFeed.call(void 0, localStream),
            stream: localStream,
            isVideoOn: isCamOn,
            className: "meeting--precall--feed", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
          )
          , _react2.default.createElement('div', { className: "meeting--precall--controls-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
            ,  isCamCycleAvailable &&
              _react2.default.createElement(_CallControls.SwitchCamBtn, {
                onClick: onCycleCam,
                className: "meeting--precall--control-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
              )
            
            , _react2.default.createElement(_CallControls.DisableCamBtn, {
              onClick: onToggleCam,
              className: "meeting--precall--control-button",
              isActive: !isCamOn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
            )
            , _react2.default.createElement(_CallControls.DisableMicBtn, {
              onClick: onToggleMic,
              className: "meeting--precall--control-button",
              isActive: !isMicOn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
            )
          )
        )
        , _react2.default.createElement('div', { className: "meeting--precall--main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
          , _react2.default.createElement('div', { className: "meeting--precall--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
            , _react2.default.createElement('a', {
              onClick: gotoCall,
              className: "blmh-button meeting--btn primary purple highlight meeting--precall--proceed-btn"     , __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
            
              , _react2.default.createElement(_JoinMeetingBtnContent2.default, {
                isMicOn: isMicOn,
                isCamOn: isCamOn, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
              )
            )
            , _react2.default.createElement('a', {
              onClick: gotoCallSetup,
              className: "blmh-button meeting--btn meeting--btn--secondary meeting--precall--back-btn"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , "Check settings"

            )
          )
          , _react2.default.createElement('div', { className: "meeting--precall--issues-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
            ,  areCamIssuesPresent || areMicIssuesPresent
              ? _react2.default.createElement('span', { className: "meeting--issue--warn-sign meeting--precall--issue" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, "There's a problem with your "
                       ,  areCamIssuesPresent ? "camera" : "audio", "."
                )
              : _react2.default.createElement('span', { className: "meeting--no-issues-tick meeting--precall--no-issues" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, "You are ready to join."

                )
            
            , _react2.default.createElement('div', { className: "meeting--issue--underline meeting--precall--issue-underline" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}} )
            , _react2.default.createElement('span', { className: "meeting--precall--issues-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
              , _react2.default.createElement('a', { className: "meeting--help-link", onClick: gotoCallSetup, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, "Check your settings"

              ), "before joining the meeting to avoid any issues."

            )
          )
        )
      )
    )
  )
)

PreCall.propTypes = {
  isCamOn: _proptypes2.default.bool.isRequired,
  isMicOn: _proptypes2.default.bool.isRequired,
  isCamCycleAvailable: _proptypes2.default.bool.isRequired,
  onToggleMic: _proptypes2.default.func.isRequired,
  onToggleCam: _proptypes2.default.func.isRequired,
  onCycleCam: _proptypes2.default.func.isRequired,
  areMicIssuesPresent: _proptypes2.default.bool.isRequired,
  areCamIssuesPresent: _proptypes2.default.bool.isRequired,
  localStream: _proptypes2.default.any,
  gotoCallSetup: _proptypes2.default.func.isRequired,
  gotoCall: _proptypes2.default.func.isRequired,
  userName: _proptypes2.default.string.isRequired,
}

exports. default = PreCall
