"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/DirectoryCreateNewModal/CreateNewTeam.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _session = require('shared/selectors/session');
var _noticeBar = require('web/services/context/noticeBar');

var _Forms = require('web/screens/Directory/Forms');
var _WarningBar = require('web/components/WarningBar'); var _WarningBar2 = _interopRequireDefault(_WarningBar);






const CreateNewTeam = ({ orgKey, onClose }) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    async ({ name }) => orgKey &&
      _actions2.default.createTeam({ OrgKey: orgKey, TeamName: name })
        .then(({ Teams }) => {
          const [teamKey] = Object.keys(Teams) 
          if (!teamKey) throw new Error('No created teams found')
          return _actions2.default.gotoTeamPage(orgKey, teamKey)
        })
        .then(onClose)
        .catch(() => showWarningBar('Failed to create a team')),
    [onClose, orgKey, showWarningBar],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  !orgKey && (
        _react2.default.createElement(_WarningBar2.default, {
          className: "directory--create-new--warning",
          text: "You need to join an organization before creating a team."         , __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        )
      )
      , _react2.default.createElement(_Forms.TeamForm, {
        submitBtnLabel: "Create",
        orgKeyContext: orgKey,
        onSubmit: onSubmit,
        onCancel: onClose,
        controlsClassName: "invite-contact--form-controls", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
      )
    )
  )
}





const mapState = (state, { orgKeyContext }) => ({
  orgKey: orgKeyContext || _session.selectSelectedOrgKey.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CreateNewTeam)
