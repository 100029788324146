"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');

var _chat = require('shared/enum/chat');




var _chatParticipantQuotas = require('shared/constants/chatParticipantQuotas');

const useParticipantQuotaSwitch = chatType => _react.useMemo.call(void 0, 
  () => {
    if (chatType === _chat.CHAT_TYPE.REGULAR_PATIENT) return _chatParticipantQuotas.REGULAR_PATIENT_CHAT_QUOTA
    if (chatType === _chat.CHAT_TYPE.REGULAR) return _chatParticipantQuotas.REGULAR_CHAT_QUOTA
    return _chatParticipantQuotas.NULL_QUOTA
  },
  [chatType],
)

exports. default = useParticipantQuotaSwitch
