"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/Teams/Item.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _team = require('shared/selectors/team');
var _org = require('shared/selectors/org');



var _entities = require('shared/transforms/entities');

var _GenericItem = require('web/screens/Directory/Sidebar/List/GenericItem'); var _GenericItem2 = _interopRequireDefault(_GenericItem);







const TeamItem = ({ teamEntity, orgKey, isViewed }) => (
  _react2.default.createElement(_GenericItem2.default, {
    entity: teamEntity,
    orgKey: orgKey,
    isViewed: isViewed, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
  )
)





const mapState = (state, { teamKey, orgKey }) => {
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgsMap = (org ? { [orgKey]: org } : {}) 
  const team = _team.selectTeamByKey.call(void 0, state, teamKey)

  return {
    teamEntity: team
      ? _entities.mapTeamToEntity.call(void 0, 
        orgsMap,
        _entities.makeRawMembersCountTeamDescription,
      )(team)
      : null,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(TeamItem)
