"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/Message/FileMessage/FilePlain.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _fileSize = require('shared/utils/fileSize');
var _message = require('shared/enum/message');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const withNoBubbling = (cb) => (e) => {
  e.stopPropagation()
  cb()
}










const FilePlain = ({
  deliveryStatus,
  onDownload, onRetry, onAbort,
  fileName, fileSize,
}) => {
  const isLoading = deliveryStatus === _message.deliveryStatus.SENT
  const isCanceled = deliveryStatus === _message.deliveryStatus.CANCELED
  const hasFailed = deliveryStatus === _message.deliveryStatus.FAILED
  const isDelivered = deliveryStatus === _message.deliveryStatus.DELIVERED

  const fileExtention = fileName.split('.').pop()

  return (
    _react2.default.createElement('div', {
      onClick: isDelivered ? onDownload : undefined,
      className: _classBuilder2.default.call(void 0, 
        "file-message--plain",
        isDelivered && 'file-message--plain--clickable',
        hasFailed && 'file-message--plain--failed',
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
    
      , _react2.default.createElement('div', { className: "file-message--plain--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
        ,  _nullishCoalesce(_optionalChain([fileExtention, 'optionalAccess', _ => _.length]), () => ( 0 < 4)) ? fileExtention : null 
      )
      , _react2.default.createElement('div', { className: "file-message--plain--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
        , _react2.default.createElement('span', { className: "file-message--plain--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}},  fileName )
        , _react2.default.createElement('span', { className: "file-message--plain--size", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          ,  _fileSize.getDisplaySize.call(void 0, fileSize) 
        )
      )
      ,  (isLoading || isCanceled) && (
        _react2.default.createElement('div', {
          role: "button",
          onClick: withNoBubbling(onAbort),
          className: "file-message--btn file-message--btn-loading" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        )
      )
      ,  hasFailed && (
        _react2.default.createElement('div', {
          role: "button",
          onClick: withNoBubbling(onRetry),
          className: "file-message--btn file-message--btn-retry" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
        )
      )
      ,  isDelivered && (
        _react2.default.createElement('div', { className: "file-message--btn file-message--btn-noop" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
      )
    )
  )
}

exports. default = FilePlain
