"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/components/InfoItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const InfoItem = ({
  name,
  value,
  className,
  titleClassName,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "team-page--info-item", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    , _react2.default.createElement('span', { className: _classBuilder2.default.call(void 0, "team-page--info-item--name", titleClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      ,  name 
    )
    , _react2.default.createElement('span', { className: "team-page--info-item--value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}},  value )
  )
)

exports. default = InfoItem
