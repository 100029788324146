"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');




var _common = require('shared/selectors/blobject/common');







const EMPTY_SUBSCRIBERS = {
  userSubscribersList: [],
  teamSubscribersList: [],
  patientSubscribersList: [],
}

 const selectAllBlobjectSubscribers = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  (blobject) => {
    if (!blobject) return EMPTY_SUBSCRIBERS
    const {
      TeamSubscribers,
      UserSubscribers,
      PatientSubscribers,
    } = blobject

    const userSubscribersList = Object.values(UserSubscribers)
    const teamSubscribersList = Object.values(TeamSubscribers)
    const patientSubscribersList = Object.values(PatientSubscribers)

    return {
      userSubscribersList,
      teamSubscribersList,
      patientSubscribersList,
    }
  },
); exports.selectAllBlobjectSubscribers = selectAllBlobjectSubscribers
