"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/components/SignupStepWrapper/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _types = require('web/screens/Signup/types');
var _supportUrls = require('shared/data/supportUrls');
var _Form = require('web/components/Form');
var _NavItem = require('./NavItem'); var _NavItem2 = _interopRequireDefault(_NavItem);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const SignupStepWrapper = ({
  currentStep = _types.SetupSteps.PERSONAL_INFO,
  title,
  className,
  gotoStep,
  size = 'sm',
  children,
}) => (
  _react2.default.createElement('div', { className: "signup-step-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
    , _react2.default.createElement('div', { className: "signup-step", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      , _react2.default.createElement('header', { className: "signup-step--main-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        , _react2.default.createElement('img', {
          className: "signup-step--main-header--logo",
          src: "/assets/images/bloomtext-logo.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        )
        , _react2.default.createElement(_reactrouterdom.Link, { to: _supportUrls.homepageUrl, target: window.ELECTRON ? '_blank' : '_self', __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
          , _react2.default.createElement(_Form.Button, { isMinimalistic: true, className: "signup-step--main-header--btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
            , _react2.default.createElement('span', { className: "blmh-mobile-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "Go to homepage"  )
            , _react2.default.createElement('span', { className: "blmh-desktop-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}, "Go to home"  )
            , _react2.default.createElement('img', { src: "/assets/icons/icon-arrow-right-white.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}} )
          )
        )
      )
      , _react2.default.createElement('h1', { className: "signup-step--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}},  title )
      , _react2.default.createElement('nav', { className: "signup-step--nav", __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
        , _react2.default.createElement(_NavItem2.default, {
          name: "Account",
          gotoStep: gotoStep,
          lowerBound: _types.SetupSteps.PERSONAL_INFO,
          upperBound: _types.SetupSteps.PASSWORD,
          currentStep: currentStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
        )
        , _react2.default.createElement(_NavItem2.default, {
          name: "Organization",
          gotoStep: gotoStep,
          lowerBound: _types.SetupSteps.ORG_INFO,
          upperBound: _types.SetupSteps.ORG_SURVEY_FEATURES,
          currentStep: currentStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        )
        , _react2.default.createElement(_NavItem2.default, {
          name: "Ready to go"  ,
          gotoStep: gotoStep,
          lowerBound: _types.SetupSteps.FINISH,
          upperBound: _types.SetupSteps.FINISH,
          currentStep: currentStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        )
      )
      , _react2.default.createElement('div', {
        className: _classBuilder2.default.call(void 0, 
          "signup-step--content",
          `signup-step--content--${size}`,
          className,
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      
        ,  children 
      )
      , _react2.default.createElement('footer', { className: "signup-step--footer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}, "© "
         , new Date().getFullYear(), " BloomAPI Inc."
      )
    )
  )
)

exports. default = SignupStepWrapper
