"use strict";Object.defineProperty(exports, "__esModule", {value: true});// TODO DEPRECATED REMOVE ONCE CONVERSATION IS SHIPPED
 function getReadString (userList, participants) {
  if (!userList) {
    return
  }

  const users = userList.filter((u) => !!participants[u[0]])

  if (users.length === 0) {
    return null
  }

  // users is: [[userKey, lastReadMessage], [userKey2, lastReadMessage]] -- yes it's dumb but I don't want to change the caller
  if (users.length === 1) {
    return "Seen by " + participants[users[0][0]].FirstName
  } else if (users.length === 2) {
    return "Seen by " + participants[users[0][0]].FirstName + " and " + participants[users[1][0]].FirstName
  } else if (users.length === 3) {
    return "Seen by " + participants[users[0][0]].FirstName + ", " + participants[users[1][0]].FirstName + ", and " + participants[users[2][0]].FirstName
  } else {
    return "Seen by " + participants[users[0][0]].FirstName + ", " + participants[users[1][0]].FirstName + ", " + participants[users[2][0]].FirstName + ", and " + (users.length - 3) + " more"
  }
} exports.getReadString = getReadString;

 function getReadStringNew (users) {
  if (!users || users.length === 0) {
    return null
  }
  if (users.length === 1) {
    return "Seen by " + users[0].FirstName
  } else if (users.length === 2) {
    return "Seen by " + users[0].FirstName + " and " + users[1].FirstName
  } else if (users.length === 3) {
    return "Seen by " + users[0].FirstName + ", " + users[1].FirstName + ", and " + users[2].FirstName
  } else if (users.length > 3) {
    return "Seen by " + users[0].FirstName + ", " + users[1].FirstName + ", " + users[2].FirstName + ", and " + (users.length - 3) + " more"
  }
} exports.getReadStringNew = getReadStringNew;
