"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Notifications/Sections/DeviceSection.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);



var _notification = require('web/services/notification');
var _notifications = require('shared/services/notifications');
var _log = require('shared/log');
var _Link = require('web/components/Settings/Link'); var _Link2 = _interopRequireDefault(_Link);
var _NotificationSoundPicker = require('web/components/NotificationSoundPicker'); var _NotificationSoundPicker2 = _interopRequireDefault(_NotificationSoundPicker);
var _Form = require('web/components/Form');

const DEFAULT_SOUND = 'ping.wav'

const resolvePermissions = userKey => {
  const browserPerm = _notification.hasBrowserPermission.call(void 0, )
  const cookiePerm = _notification.getCookiePermission.call(void 0, userKey)

  return {
    browserPermission: browserPerm,
    cookiePermissions: cookiePerm,
    areNotificationsEnabled: browserPerm !== null && cookiePerm,
  }
}

 class DeviceSection extends _react2.default.Component {constructor(...args) { super(...args); DeviceSection.prototype.__init.call(this);DeviceSection.prototype.__init2.call(this);DeviceSection.prototype.__init3.call(this);DeviceSection.prototype.__init4.call(this);DeviceSection.prototype.__init5.call(this);DeviceSection.prototype.__init6.call(this);DeviceSection.prototype.__init7.call(this);DeviceSection.prototype.__init8.call(this);DeviceSection.prototype.__init9.call(this);DeviceSection.prototype.__init10.call(this);DeviceSection.prototype.__init11.call(this);DeviceSection.prototype.__init12.call(this); }
  __init() {this.audio = null}
  __init2() {this.state = {
    ...resolvePermissions(this.props.userKey),
    isSoundPickerModalOpen: false,
  }}

  __init3() {this.updatePermissions = () =>
    this.setState(resolvePermissions(this.props.userKey))}

  __init4() {this.addToCookie = () => {
    const { userKey, showNoticeBar } = this.props
    _log.log.info("Adding current user to notification cookie.")
    _notification.addUserCookiePermission.call(void 0, userKey)
    showNoticeBar({
      type: "success",
      text: "Notifications are now enabled for this device.",
    })
    this.updatePermissions()
  }}

  __init5() {this.turnOnNotifications = () => {
    const { browserPermission } = this.state
    const { showNoticeBar } = this.props

    // We haven't asked yet on this browser
    if (browserPermission === null) {
      _log.log.info("Browser Permissions NULL - Asking to turn on browser notifications.")
      return _notification.askForPermissionFunction.call(void 0, 
        this.addToCookie,
        () => showNoticeBar({
          type: "warning",
          text: "Please enable browser notifications.",
        }),
      )
    }
    // We have permissions on this browser
    if (browserPermission) return this.addToCookie()

    // Someone denied it on the browser
    showNoticeBar({
      type: "warning",
      text: "Browser notifications are disabled.",
    })
  }}

  __init6() {this.turnOffNotifications = () => {
    const { userKey, showNoticeBar } = this.props
    _log.log.info("Removing current user from notification cookie.")
    _notification.removeUserCookiePermission.call(void 0, userKey)
    showNoticeBar({
      type: "success",
      text: "You will no longer receive notifications on this device.",
    })
    this.updatePermissions()
  }}

  __init7() {this.handleChange = val => val
    ? this.turnOnNotifications()
    : this.turnOffNotifications()}

  __init8() {this.openSoundPickerModal = () => this.setState({ isSoundPickerModalOpen: true })}
  __init9() {this.closeSoundPickerModal = () => this.setState({ isSoundPickerModalOpen: false })}

  __init10() {this.playSound = sound => {
    const filePath = _notifications.webSoundFileLocation.call(void 0, sound)
    this.audio && this.audio.pause()
    this.audio = new Audio(filePath)
    const played = this.audio.play()
    if (played !== undefined) {
      played.catch(e => _log.log.info("Couldn't play audio", filePath, e))
    }
  }}

  __init11() {this.playCurrentSound = () => {
    if (this.props.currentSoundUrl) {
      this.playSound(this.props.currentSoundUrl)
    }
  }}

  __init12() {this.handleSubmitSound = soundUrl => {
    const { showNoticeBar } = this.props
    _actions2.default.setGlobalNotificationSound(soundUrl)
      .then(() => showNoticeBar({
        type: "success",
        text: "Successfully set notification sound.",
      }))
      .then(() => this.props.onSoundUrlChange(soundUrl))
      .catch(() => {
        showNoticeBar({
          type: "warning",
          text: "Failed to set notification sound.",
        })
      })
    this.closeSoundPickerModal()
  }}

  render () {
    const { areNotificationsEnabled, isSoundPickerModalOpen } = this.state
    const { currentSoundUrl } = this.props

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "settings--notifications--device-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
          , _react2.default.createElement(_Form.Toggle, {
            isChecked: areNotificationsEnabled,
            onChange: this.handleChange,
            label: "Enable push notifications on this device"     ,
            hint: `You will ${!areNotificationsEnabled ? 'not' : ''} receive push notifications for new messages on this device.`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
          )
        )
        ,  areNotificationsEnabled &&
          _react2.default.createElement('div', { className: "settings--notifications--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 139}}
            , _react2.default.createElement('h5', { className: "settings--notifications--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 140}}, "Notification Sound"

            )
            , _react2.default.createElement('div', { className: "settings--notifications--sound-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 143}}
              , _react2.default.createElement('div', {
                className: "settings--notifications--sound-btn",
                onClick: this.playCurrentSound, __self: this, __source: {fileName: _jsxFileName, lineNumber: 144}}
              
                , _react2.default.createElement('span', { className: "settings--notifications--sound-play-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 148}}
                  , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 149}}, "play_arrow")
                )
                , _react2.default.createElement('span', { className: "settings--notifications--sound-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
                  ,  _notifications.getSoundName.call(void 0, currentSoundUrl, "None") 
                )
              )
              , _react2.default.createElement(_Link2.default, {
                className: "settings--notifications--sound-change-btn",
                onClick: this.openSoundPickerModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 155}}
              , "Change sound"

              )
            )
            , _react2.default.createElement(_NotificationSoundPicker2.default, {
              key: currentSoundUrl,
              isOpen: isSoundPickerModalOpen,
              onClose: this.closeSoundPickerModal,
              defaultChosenSound: currentSoundUrl,
              noneLabel: "None",
              onSubmit: this.handleSubmitSound, __self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
            )
          )
        
      )
    )
  }

  static __initStatic() {this.defaultProps = {
    currentSoundUrl: DEFAULT_SOUND,
  }}

  static __initStatic2() {this.propTypes = {
    showNoticeBar: _proptypes2.default.func.isRequired,
    userKey: _proptypes2.default.string.isRequired,
    currentSoundUrl: _proptypes2.default.string,
    onSoundUrlChange: _proptypes2.default.func,
  }}
} DeviceSection.__initStatic(); DeviceSection.__initStatic2(); exports.default = DeviceSection;
