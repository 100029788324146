"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/GenericUserAction.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _UserTile = require('web/screens/Settings/Org/MembersTab/components/UserTile'); var _UserTile2 = _interopRequireDefault(_UserTile);










const GenericUserModal = ({
  isOpen,
  onClose,
  title,
  orgKey,
  entity,
  children,
  className,
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    title: title,
    className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
  
    , _react2.default.createElement(_UserTile2.default, { entity: entity, orgKey: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
    ,  children 
  )
)

exports. default = GenericUserModal
