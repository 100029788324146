"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _urls = require('web/services/urls');

var _AnonymousPage = require('web/components/AnonymousPage');
var _ExpiredPasswordNotice = require('web/screens/Login/ExpiredPasswordNotice'); var _ExpiredPasswordNotice2 = _interopRequireDefault(_ExpiredPasswordNotice);
var _ForgotPassword = require('web/screens/Login/ForgotPassword'); var _ForgotPassword2 = _interopRequireDefault(_ForgotPassword);
var _Quick = require('web/screens/Login/Quick'); var _Quick2 = _interopRequireDefault(_Quick);
var _ResetPassword = require('web/screens/Login/ResetPassword'); var _ResetPassword2 = _interopRequireDefault(_ResetPassword);
var _Username = require('web/screens/Login/Username'); var _Username2 = _interopRequireDefault(_Username);
var _components = require('./components');






var _hooks = require('./hooks');









var LoginMethod; (function (LoginMethod) { const QUICK = 1; LoginMethod[LoginMethod["QUICK"] = QUICK] = "QUICK"; const USERNAME = QUICK + 1; LoginMethod[LoginMethod["USERNAME"] = USERNAME] = "USERNAME"; })(LoginMethod || (LoginMethod = {}));

const Login = ({ onLoginSuccess }) => {
  const prefilledEmail = _optionalChain([_reactrouterdom.useRouteMatch, 'call', _ => _(), 'access', _2 => _2.params, 'optionalAccess', _3 => _3.email])

  const { currentLoginMethod, switchToUsernameLogin, switchToQuickLogin } =
    _hooks.useLoginMethod.call(void 0, )

  const { isQuickLoginListLoading, isQuickLoginAvailable } =
    _hooks.useQuickLoginUsers.call(void 0, { switchToUsernameLogin, switchToQuickLogin })

  const { gotoUsernameLogin, gotoQuickLogin, gotoForgotPassword, gotoExpiredPassword } =
    _hooks.useRouting.call(void 0, { switchToQuickLogin, switchToUsernameLogin })

  const { onRequestPasswordReset, onCheckResetToken, onResetPassword } =
    _hooks.useResetPassword.call(void 0, )

  const { handleQuickLogin, handleUsernameLogin } = _hooks.useLogin.call(void 0, { onLoginSuccess })

  const renderUsernameLogin = (prefilledEmail) => (
    _react2.default.createElement(_Username2.default, {
      prefilledEmail: prefilledEmail,
      isQuickLoginAvailable: isQuickLoginAvailable,
      forgotPasswordRoute: _routes2.default.LOGIN.FORGOT_PASSWORD,
      gotoQuickLogin: gotoQuickLogin,
      gotoExpiredPassword: gotoExpiredPassword,
      onUsernameLogin: handleUsernameLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
    )
  )

  const renderQuickLogin = () => (
    _react2.default.createElement(_Quick2.default, {
      gotoUsernameLogin: gotoUsernameLogin,
      onQuickLogin: handleQuickLogin,
      onRemoveUser: _actions2.default.removeQuickLoginUser, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
    )
  )

  const renderForgotPassword = () => (
    _react2.default.createElement(_ForgotPassword2.default, {
      gotoUsernameLogin: gotoUsernameLogin,
      onSubmit: onRequestPasswordReset, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
    )
  )

  const renderExpiredPassword = () => (
    _react2.default.createElement(_ExpiredPasswordNotice2.default, {
      gotoUsernameLogin: gotoUsernameLogin, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
    )
  )

  const renderResetPassword = (token = '') => (
    _react2.default.createElement(_ResetPassword2.default, {
      homeUrl: _urls.homepageUrl,
      gotoForgotPassword: gotoForgotPassword,
      onCheckToken: onCheckResetToken,
      onSubmit: onResetPassword,
      onUsernameLogin: handleUsernameLogin,
      token: token, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
    )
  )

  const renderLoginMethod = () => {
    if (isQuickLoginListLoading) return (_react2.default.createElement(_components.Loader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} ))
    if (
      !prefilledEmail
      && isQuickLoginAvailable
      && currentLoginMethod === LoginMethod.QUICK
    ) return renderQuickLogin()
    return renderUsernameLogin(prefilledEmail)
  }

  return (
    _react2.default.createElement(_AnonymousPage.PageWrapper, { title: "Welcome to BloomText"  , signupRoute: _routes2.default.WELCOME, __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}}
      , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 105}}
        , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.LOGIN.FORGOT_PASSWORD, render: renderForgotPassword, __self: this, __source: {fileName: _jsxFileName, lineNumber: 106}} )
        , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.LOGIN.EXPIRED_PASSWORD, render: renderExpiredPassword, __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}} )
        , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.LOGIN.RESET_PASSWORD, render: ({ match }) => renderResetPassword(match.params.token), __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}} )
        ,  renderLoginMethod() 
      )
    )
  )
}

exports. default = Login
