"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoRooms/Room/Modals/AddAdmins.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

var _withParticipantsLookup = require('web/screens/CreateNew/withParticipantsLookup'); var _withParticipantsLookup2 = _interopRequireDefault(_withParticipantsLookup);
var _DropdownParticipantsPicker = require('web/components/DropdownParticipantsPicker'); var _DropdownParticipantsPicker2 = _interopRequireDefault(_DropdownParticipantsPicker);

const ParticipantsLookupPicker =
  _withParticipantsLookup2.default.call(void 0, _DropdownParticipantsPicker2.default)

const AddAdminsModal = ({
  isOpen, onClose,
  adminsInputId,
  adminsInputValue,
  onAdminsInputChange,
  onAdminSelect,
  onAdminUpdate,
  onAdminRemove,
  adminEntities,
  onSubmit,
  orgKey,
}) => {
  const onProceed = () => {
    onSubmit()
    onClose()
  }

  return (
    _react2.default.createElement(_Modal2.default, {
      isOpen: isOpen,
      onClose: onClose,
      className: "meeting--invite--modal",
      title: "Add hosts" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
    
      , _react2.default.createElement('div', { className: "meeting--invite-modal--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        , _react2.default.createElement(ParticipantsLookupPicker, {
          inputId: adminsInputId,
          onInputChange: onAdminsInputChange,
          inputValue: adminsInputValue,
          onOptionSelected: onAdminSelect,
          onOptionUpdated: onAdminUpdate,
          onOptionRemoved: onAdminRemove,
          selectedOptions: adminEntities,
          orgKeyContext: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        )
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Add",
        isPrimaryEnabled: adminEntities.length > 0,
        onPrimaryBtnClick: onProceed,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose,
        className: "meeting--invite-modal--btns-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
      )
    )
  )
}

AddAdminsModal.propTypes = {
  isOpen: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
}

exports. default = AddAdminsModal
