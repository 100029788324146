"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Navigation/NavSubItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const NavSubItem = ({
  label,
  gotoRoute,
  isActive,
  warningCount = 0,
}) => (
  _react2.default.createElement('div', {
    role: "menuitem",
    'aria-selected': isActive,
    onClick: gotoRoute,
    className: _classBuilder2.default.call(void 0, 
      "settings--nav-subitem",
      isActive && "active",
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
  
    , _react2.default.createElement('span', { className: "settings--nav-subitem--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}},  label )
    ,  warningCount > 0 && (
      _react2.default.createElement('span', { className: "settings--nav-subitem--warning-label", role: "suggestion", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        ,  warningCount 
      )
    )
  )
)

exports. default = NavSubItem
