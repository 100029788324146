"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Phones/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _GenericSection = require('../GenericSection'); var _GenericSection2 = _interopRequireDefault(_GenericSection);
var _PhoneEntry = require('./PhoneEntry'); var _PhoneEntry2 = _interopRequireDefault(_PhoneEntry);
var _Form = require('web/components/Form');









const PhonesSection = ({
  phonesList,
  onRemovePhone,
  onVerifyPhone,
  onMakePhonePrimary,
  onAddPhone,
}) => {
  const sortedPhonesList = _react.useMemo.call(void 0, 
    () => phonesList.sort((a, b) => +b.IsPrimary - +a.IsPrimary),
    [phonesList],
  )

  const canRemoveLastPhone = phonesList.length === 1

  return (
    _react2.default.createElement(_GenericSection2.default, { classNameSuffix: "contact", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
      , _react2.default.createElement('span', { className: "settings--profile--section-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}, "Phone Numbers"

      )
      , _react2.default.createElement('div', { className: "settings--profile--section-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        ,  sortedPhonesList.map(x => (
          _react2.default.createElement(_PhoneEntry2.default, {
            key: x.PhoneNumber,
            phone: x.PhoneNumber,
            isPrimary: x.IsPrimary,
            isVerified: x.IsVerified,
            canRemove: !x.IsPrimary || canRemoveLastPhone,
            onRemovePhone: onRemovePhone,
            onVerifyPhone: onVerifyPhone,
            onMakePhonePrimary: onMakePhonePrimary, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
          )
        ))
        , _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: onAddPhone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
          , _react2.default.createElement('img', { src: "/assets/icons/icon-add-thin-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}} ), "Add phone"

        )
      )
    )
  )
}

exports. default = PhonesSection
