"use strict";Object.defineProperty(exports, "__esModule", {value: true});


let store

const getStore = () => store
 const setStore = (newStore) => store = newStore; exports.setStore = setStore
 const dispatch = body => store.dispatch(body); exports.dispatch = dispatch
 const getState = () => store && store.getState(); exports.getState = getState

exports. default = getStore
