"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) { newObj[key] = obj[key]; } } } newObj.default = obj; return newObj; } } function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _loglevel = require('loglevel'); var _loglevel2 = _interopRequireDefault(_loglevel);
var _errors = require('shared/errors');
var _react = require('@sentry/react'); var Sentry = _interopRequireWildcard(_react);

var _log = require('shared/log');

_log.log.register({ error: Sentry.captureException })

/**
 * @deprecated - Use `shared/log`
 */
exports. default = {
  ..._loglevel2.default,
  error: (e) => {
    if (!e) {
      return
    }

    _loglevel2.default.error(e)

    if (e.ServerError) {
      return
    } else if (e instanceof _errors.RestError && (e.response.ServerError || e.response.Transient)) {
      return
    }

    if (e instanceof _errors.BlmError) {
      Sentry.captureException(e, { extra: e.extra })
    } else if (e instanceof _errors.RestError) {
      Sentry.captureException(e, { extra: e.response })
    } else if (e instanceof Error) {
      Sentry.captureException(e)
    } else if (e instanceof String) {
      Sentry.captureException(new Error(e))
    } else {
      Sentry.captureException(new Error(JSON.stringify(e)))
    }
  },
}
