"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/DesktopMoreOptionsModal.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

var _Modals = require('web/components/VideoCall/Modals');

class DesktopMoreOptionsModal extends _react2.default.Component {constructor(...args) { super(...args); DesktopMoreOptionsModal.prototype.__init.call(this);DesktopMoreOptionsModal.prototype.__init2.call(this);DesktopMoreOptionsModal.prototype.__init3.call(this);DesktopMoreOptionsModal.prototype.__init4.call(this);DesktopMoreOptionsModal.prototype.__init5.call(this); }
  __init() {this.rootRef = null}
  __init2() {this.touchHandler = e => e.stopPropagation()}
  __init3() {this.setRootRef = r => this.rootRef = r}

  __init4() {this.componentDidMount = () =>
    this.props.ignoredUIEvents.forEach(e =>
      this.rootRef.addEventListener(e, this.touchHandler),
    )}

  __init5() {this.componentWillUnmount = () =>
    this.props.ignoredUIEvents.forEach(e =>
      this.rootRef.removeEventListener(e, this.touchHandler),
    )}

  render () {
    const {
      isOpen, onClose,
      onRoomLinkCopy,
      onOpenSettings, onOpenNetstats,
    } = this.props

    const rootClassName = _classBuilder2.default.call(void 0, 
      "meeting--on-call--desktop-more-modal",
      { hidden: !isOpen },
    )

    return (
      _react2.default.createElement('div', { className: rootClassName, ref: this.setRootRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        , _react2.default.createElement(_Modals.MoreOptionsMenu, {
          onClose: onClose,
          onRoomLinkCopy: onRoomLinkCopy,
          onOpenSettings: onOpenSettings,
          isNetstatsLinkEnabled: true,
          onOpenNetstats: onOpenNetstats, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        )
        , _react2.default.createElement('div', { onClick: onClose, className: "meeting--modal--close-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}} )
      )
    )
  }

  static __initStatic() {this.defaultProps = {
    ignoredUIEvents: [],
  }}

  static __initStatic2() {this.propTypes = {
    ignoredUIEvents: _proptypes2.default.array,
    isOpen: _proptypes2.default.bool.isRequired,
    onClose: _proptypes2.default.func.isRequired,
    onRoomLinkCopy: _proptypes2.default.func.isRequired,
  }}
} DesktopMoreOptionsModal.__initStatic(); DesktopMoreOptionsModal.__initStatic2();

exports. default = DesktopMoreOptionsModal
