"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');
var _reactrouter = require('react-router');






var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _types = require('web/screens/Directory/types');













const typePrefixMap = {
  u: _types.DirectoryTab.COWORKERS,
  p: _types.DirectoryTab.PATIENTS,
  t: _types.DirectoryTab.TEAMS,
}

const resolveVirtualOrgKey = (
  allowSelectedOrgFallback,
  selectedOrgKey,
  explicitlySelectedOrgKey,
) => {
  if (allowSelectedOrgFallback === null) return null
  return allowSelectedOrgFallback ? selectedOrgKey : explicitlySelectedOrgKey
}

 const useRouting = ({
  myActiveOrgsMap,
  selectedOrgKey,
  explicitlySelectedOrgKey,
}) => {
  const match = _reactrouter.useRouteMatch(_routes2.default.DIRECTORY.ENTITY)
  const { typePrefix, orgKey: routeOrgKey, entityKey } = _optionalChain([match, 'optionalAccess', _ => _.params]) || {}

  const [selectedTab, setSelectedTab] =
    _react.useState.call(void 0, typePrefix && typePrefixMap[typePrefix] || _types.DirectoryTab.COWORKERS)

  const isRouteOrgMine = !!(routeOrgKey && myActiveOrgsMap[routeOrgKey])
  const [allowSelectedOrgFallback, setAllowSelectedOrgFallback] =
    _react.useState(null)

  const virtualOrgKey = resolveVirtualOrgKey(
    allowSelectedOrgFallback,
    selectedOrgKey,
    explicitlySelectedOrgKey,
  )

  _react.useEffect.call(void 0, 
    () => {
      const canFallback = !routeOrgKey || isRouteOrgMine
      setAllowSelectedOrgFallback(canFallback)
      if (!canFallback) _actions2.default.setSelectedOrg(null)
      else if (isRouteOrgMine) _actions2.default.setSelectedOrg(routeOrgKey)
    },
    [
      routeOrgKey, isRouteOrgMine,
      setAllowSelectedOrgFallback,
    ],
  )

  _react.useEffect.call(void 0, 
    () => { typePrefix && setSelectedTab(typePrefixMap[typePrefix]) },
    [typePrefix, setSelectedTab],
  )

  return {
    routeOrgKey,
    entityKey,
    virtualOrgKey,
    selectedTab,
    setSelectedTab,
    isInPage: !!typePrefix,
  }
}; exports.useRouting = useRouting
