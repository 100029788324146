"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }




var _OneToOneChat = require('./OneToOneChat'); var _OneToOneChat2 = _interopRequireDefault(_OneToOneChat);
var _PatientChat = require('./PatientChat'); var _PatientChat2 = _interopRequireDefault(_PatientChat);
var _CompositeChat = require('./CompositeChat'); var _CompositeChat2 = _interopRequireDefault(_CompositeChat);




















const InboxAvatar = ({
  subscribers,

  currentUserKey,
  size,
  borderColor,

  renderPatientAvatar,
  renderTeamAvatar,
  renderUserAvatar,
  renderGroupAvatar,
  renderFallbackAvatar,
}) => {
  const layoutProps = { size, borderColor }

  const {
    userSubscribersList = [],
    teamSubscribersList = [],
    patientSubscribersList = [],
  } = subscribers

  const usersCount = userSubscribersList.length
  const teamsCount = teamSubscribersList.length
  const patientsCount = patientSubscribersList.length

  // special case: 1-1 chat between users
  if (usersCount < 3 && !teamsCount && !patientsCount) {
    return _OneToOneChat2.default.call(void 0, {
      layoutProps,
      currentUserKey,
      userSubscribersList,
      renderUserAvatar,
      renderFallbackAvatar,
    })
  }

  // special case: teams or users to Patient
  if (patientSubscribersList[0] && patientsCount === 1) {
    return _PatientChat2.default.call(void 0, {
      patientKey: patientSubscribersList[0].PatientKey,

      userSubscribersList,
      teamSubscribersList,

      layoutProps,
      renderUserAvatar,
      renderTeamAvatar,
      renderPatientAvatar,
      renderGroupAvatar,
    })
  }

  // special case: unnamed chat with only 1 team
  const otherUserSubscribersList = userSubscribersList
    .filter(u => u.UserKey !== currentUserKey)

  if (
    !(otherUserSubscribersList.length + patientsCount) &&
    teamsCount === 1
  ) {
    return teamSubscribersList[0]
      ? renderTeamAvatar({ teamKey: teamSubscribersList[0].TeamKey, ...layoutProps })
      : renderFallbackAvatar(layoutProps)
  }

  return _CompositeChat2.default.call(void 0, {
    userSubscribersList,
    teamSubscribersList,
    patientSubscribersList,

    currentUserKey,
    otherUserSubscribersList,

    layoutProps,
    renderPatientAvatar,
    renderUserAvatar,
    renderTeamAvatar,
    renderGroupAvatar,
  })
}

exports. default = InboxAvatar

exports.InboxAvatar = InboxAvatar;
