"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Button/SplitButton.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Tooltip = require('web/components/Tooltip');
var _SplitMenu = require('./SplitMenu'); var _SplitMenu2 = _interopRequireDefault(_SplitMenu);

const tooltipOffset = { top: -10 }








const SplitButton = ({
  children,
  onClick,
  className,
  splitClassName,
  options,
}) => {
  const [isOpen, setIsOpen] = _react.useState.call(void 0, false)
  const splitBtnRef = _react.useRef.call(void 0, null)

  const handleOpen = e => {
    e.stopPropagation()
    setIsOpen(true)
  }
  const handleClose = () => setIsOpen(false)

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('button', { onClick: onClick, className: _classBuilder2.default.call(void 0, 'blm-btn', className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        ,  children 
        , _react2.default.createElement('div', {
          'aria-label': "Expand",
          role: "button",
          ref: splitBtnRef,
          onClick: handleOpen,
          className: _classBuilder2.default.call(void 0, "blm-btn--split", splitClassName, isOpen && 'active'), __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        )
      )
      , _react2.default.createElement(_Tooltip.ControlledTooltip, {
        type: "light",
        place: "top",
        content: _react2.default.createElement(_SplitMenu2.default, { options: options, onClose: handleClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} ),
        isOpen: isOpen,
        onClose: handleClose,
        offset: tooltipOffset,
        isDismissibleByClickElsewhere: true,
        domTarget: splitBtnRef.current,
        className: "blm-btn--split-tooltip", __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      )
    )
  )
}

exports. default = SplitButton
