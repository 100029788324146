"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/VideoTelehealth.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _upgradePlans = require('shared/constants/upgradePlans');
var _cost = require('shared/services/cost');

var _components = require('./components');







const VideoTelehealth = ({ isActive, baseCost, seatCount }) => (
  _react2.default.createElement(_components.FeatureItem, {
    title: _upgradePlans.videoTelehealthPlan.name,
    desc: "Host secure video calls with your patients, clients, and staff."         ,
    iconSrc: _upgradePlans.videoTelehealthPlan.iconSrc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  
    ,  isActive
      ? _react2.default.createElement(_components.PaymentReport, {
          usageCount: seatCount,
          title: "users",
          subtitle: `${_cost.formatDisplayCost.call(void 0, baseCost)}/user/month`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        )
      : _react2.default.createElement(_components.UpgradePrompt, { label: `${_cost.formatDisplayCost.call(void 0, baseCost)}/user/month`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
    
  )
)

exports. default = VideoTelehealth
