"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

var _user = require('shared/selectors/user');

 const selectSelectedTeamKey = (state) =>
  state.dataV2.session.selectedTeamKey || null; exports.selectSelectedTeamKey = selectSelectedTeamKey

 const selectExplicitlySelectedOrgKey = (state) =>
  state.dataV2.session.selectedOrgKey; exports.selectExplicitlySelectedOrgKey = selectExplicitlySelectedOrgKey

 const selectSelectedOrgKey = (state) => {
  const explicitlySelectedOrgKey = exports.selectExplicitlySelectedOrgKey.call(void 0, state)
  if (explicitlySelectedOrgKey) return explicitlySelectedOrgKey

  const myActiveRels = _user.selectMyActiveRelationshipsList.call(void 0, state)
  return (_optionalChain([myActiveRels, 'access', _ => _[0], 'optionalAccess', _2 => _2.OrgKey]) ) || null
}; exports.selectSelectedOrgKey = selectSelectedOrgKey
