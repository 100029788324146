"use strict";Object.defineProperty(exports, "__esModule", {value: true}); const formatPhoneNumber = (phoneStr) => {
  if (!phoneStr) return ''
  const digits = phoneStr
    .toString()
    .replace(/\D/g, '')

  const phoneDigits = digits.slice(-10)
  const countryCodeLength = digits.length - phoneDigits.length
  const countryCodeDigits = digits.slice(0, countryCodeLength)

  const match = phoneDigits.match(/^(\d{3})(\d{3})(\d{4})$/)

  if (!match) return phoneStr
  const [, areaCode, threeDigits, fourDigits] = match
  const displayCountryCode = !countryCodeDigits || countryCodeDigits === '1'
    ? ''
    : `+${countryCodeDigits} `

  return `${displayCountryCode}(${areaCode}) ${threeDigits}-${fourDigits}`
}; exports.formatPhoneNumber = formatPhoneNumber
