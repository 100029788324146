"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/GenericSelect/GenericSelect.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _Tooltip = require('web/components/Tooltip');

const tooltipOffset = { top: 5 }
const idKeyExtractor = (x) => x 



















const GenericSelect = ({
  options,
  onChange,
  selectedOption,
  OptionComponent,
  contentFooter = null,
  keyExtractor = idKeyExtractor,
  className,
}) => {
  const { isOpen, onOpen, onClose } = _useToggle2.default.call(void 0, )
  const selectedOptionKey = keyExtractor(selectedOption)

  const content = (
    _react2.default.createElement(_Tooltip.TooltipContent, { className: "blm-generic-select--dropdown", __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
      ,  options.filter(option => !!option).map(
        option => (
          _react2.default.createElement(OptionComponent, {
            key: keyExtractor(option),
            option: option,
            isSelected: keyExtractor(option) === selectedOptionKey,
            isPreviewOption: false,
            isPreviewOptionFocused: isOpen,
            onSelect: () => { onClose(); onChange(option) }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
          )
        ),
      )
      ,  contentFooter 
    )
  )

  return (
    _react2.default.createElement(_Tooltip.ControlledTooltip, {
      hideArrow: true,
      fullContentWidth: true,
      isOpen: isOpen,
      offset: tooltipOffset,
      place: "bottom-left",
      onClose: onClose,
      content: content,
      className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
    
      , _react2.default.createElement(OptionComponent, {
        onSelect: onOpen,
        isSelected: true,
        isPreviewOption: true,
        isPreviewOptionFocused: isOpen,
        option: selectedOption, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      )
    )
  )
}

exports. default = GenericSelect
