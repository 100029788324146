"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Notice.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _app = require('shared/selectors/app');
var _app3 = require('web/services/app');

var _Notice = require('web/components/Settings/Notice'); var _Notice2 = _interopRequireDefault(_Notice);





const Notice = ({
  isAppOutdated,
  className,
}) => (
  _react2.default.createElement(_Notice2.default, {
    className: className,
    isAppOutdated: isAppOutdated,
    onUpdate: _app3.updateApp, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
  )
)





const mapState = (state) => ({
  isAppOutdated: _app.selectIsAppOutdated.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(Notice)
