"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/ConversationScreen/RejoinModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');






var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _entity = require('shared/services/entity');
var _noticeBar = require('web/services/context/noticeBar');
var _myRelations = require('shared/selectors/myRelations');
var _user = require('shared/selectors/user');
var _session = require('shared/selectors/session');
var _createNew = require('shared/hooks/createNew');

var _Modal = require('web/components/Modal');
var _SenderSelect = require('web/components/SenderSelect'); var _SenderSelect2 = _interopRequireDefault(_SenderSelect);






const RejoinModal = ({
  onClose,
  conversationKey,
  defaultSenderKey,
  myEntitiesMap,
  myUserKey,
  myTeamEntitiesList,
  myOrgEntitiesList,
}) => {
  const {
    setSenderKey,
    senderEntity,
    senderOrgKey,
  } = _createNew.useSender.call(void 0, { defaultSenderKey, myEntitiesMap })

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onSubmit = _react.useCallback.call(void 0, 
    () => _actions2.default.rejoinConversation({
      ConversationKey: conversationKey,
      OrgKey: senderOrgKey,
      ...(senderEntity && _entity.isEntityTeam.call(void 0, senderEntity) && { TeamKey: senderEntity.wrappedEntity.TeamKey }),
      UserKey: myUserKey,
    })
      .catch(() => showWarningBar('Failed to join chat. Please try again later')),
    [conversationKey, senderEntity, senderOrgKey, myUserKey, showWarningBar],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_SenderSelect2.default, {
        shouldShowEntityDesc: true,
        senderEntity: senderEntity,
        onSelectSenderKey: setSenderKey,
        myOrgEntitiesList: myOrgEntitiesList,
        myTeamEntitiesList: myTeamEntitiesList,
        entityClassName: "create-new--modal--sender-entity", __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Confirm",
        isPrimaryEnabled: !!senderEntity,
        onPrimaryBtnClick: onSubmit,
        onSecondaryBtnClick: onClose,
        secondaryBtnLabel: "Cancel", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      )
    )
  )
}










const mapState = (state) => {
  const {
    orgsList: myOrgEntitiesList = [],
    teamsList: myTeamEntitiesList = [],
    entitiesMap: myEntitiesMap = {},
  } = _myRelations.selectMyEntities.call(void 0, state)

  const myUserKey = _user.selectCurrentUserKey.call(void 0, state)
  const selectedOrgKey = _session.selectSelectedOrgKey.call(void 0, state)
  const selectedTeamKey = _session.selectSelectedTeamKey.call(void 0, state)

  const defaultSenderKey =
    selectedTeamKey && _optionalChain([myEntitiesMap, 'access', _ => _[selectedTeamKey], 'optionalAccess', _2 => _2.entityKey]) ||
    selectedOrgKey && _optionalChain([myEntitiesMap, 'access', _3 => _3[selectedOrgKey], 'optionalAccess', _4 => _4.entityKey]) ||
    _optionalChain([myOrgEntitiesList, 'access', _5 => _5[0], 'optionalAccess', _6 => _6.entityKey]) ||
    _optionalChain([myTeamEntitiesList, 'access', _7 => _7[0], 'optionalAccess', _8 => _8.entityKey])

  return {
    orgKeyContext: selectedOrgKey,
    myOrgEntitiesList,
    myTeamEntitiesList,
    myEntitiesMap,
    defaultSenderKey,
    myUserKey,
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(RejoinModal)
