"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/CallControls/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const buildIconClassName = (
  className, type, isActive, ...classNames
) => _classBuilder2.default.call(void 0, 
  "video-call--call-controls--icon",
  `video-call--call-controls--icon--${type}`,
  className,
  { active: isActive },
  ...classNames,
)

const activeIconClassName = isActive => _classBuilder2.default.call(void 0, { hidden: !isActive })
const inactiveIconClassName = isActive => _classBuilder2.default.call(void 0, { hidden: isActive })

 const SwitchCamBtn = _react2.default.forwardRef(
  ({ onClick, className }, ref) => (
    _react2.default.createElement('div', {
      ref: ref,
      className: buildIconClassName(className, 'switch-cam'),
      onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    
      , _react2.default.createElement('img', { src: "/assets/icons/video/switch-cam.svg", alt: "Switch cam" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
    )
  ),
); exports.SwitchCamBtn = SwitchCamBtn

 const DisableCamBtn = _react2.default.forwardRef(
  ({ onClick, className, isActive }, ref) => (
    _react2.default.createElement('div', {
      ref: ref,
      className: buildIconClassName(className, 'toggle-cam', isActive),
      onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
    
      , _react2.default.createElement('img', {
        src: "/assets/icons/video/cam-off-black.svg",
        alt: "Disable cam" ,
        className: activeIconClassName(isActive), __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )
      , _react2.default.createElement('img', {
        src: "/assets/icons/video/cam-off-white.svg",
        alt: "Enable cam" ,
        className: inactiveIconClassName(isActive), __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
      )
    )
  ),
); exports.DisableCamBtn = DisableCamBtn

 const DisableMicBtn = _react2.default.forwardRef(
  ({ onClick, className, isActive }, ref) => (
    _react2.default.createElement('div', {
      ref: ref,
      className: buildIconClassName(className, 'toggle-mic', isActive),
      onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
    
      , _react2.default.createElement('img', {
        src: "/assets/icons/video/mic-off-black.svg",
        alt: "Disable mic" ,
        className: activeIconClassName(isActive), __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
      )
      , _react2.default.createElement('img', {
        src: "/assets/icons/video/mic-off-white.svg",
        alt: "Enable mic" ,
        className: inactiveIconClassName(isActive), __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      )
    )
  ),
); exports.DisableMicBtn = DisableMicBtn

 const ScreenSharingBtn = _react2.default.forwardRef(
  ({ onClick, className, isActive }, ref) => (
    _react2.default.createElement('div', {
      ref: ref,
      className: buildIconClassName(
        className,
        'toggle-screen',
        isActive,
        "video-call--call-controls--screen-sharing-icon",
      ),
      onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
    
      , _react2.default.createElement('img', {
        src: "/assets/icons/video/screen-share-black.svg",
        alt: "Disable scr" ,
        className: activeIconClassName(isActive), __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
      )
      , _react2.default.createElement('img', {
        src: "/assets/icons/video/screen-share-white.svg",
        alt: "Enable scr" ,
        className: inactiveIconClassName(isActive), __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
      )
    )
  ),
); exports.ScreenSharingBtn = ScreenSharingBtn

 const GenericBtn = _react2.default.forwardRef(
  ({ onClick, className, iconSrc, iconAlt }, ref) => {
    const rootClassName = buildIconClassName(className)
    return (
      _react2.default.createElement('div', { ref: ref, onClick: onClick, className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
        ,  iconSrc &&
          _react2.default.createElement('img', { src: iconSrc, alt: iconAlt, __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}} )
        
      )
    )
  },
); exports.GenericBtn = GenericBtn

 const MinimizeCallBtn = _react2.default.forwardRef(
  ({ onClick }, ref) => (
    _react2.default.createElement('div', {
      ref: ref,
      onClick: onClick,
      className: "meeting--on-call--minimize-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 112}}
    )
  ),
); exports.MinimizeCallBtn = MinimizeCallBtn

 const PinActiveSpeakerBtn = _react2.default.forwardRef(
  ({ onClick, isActive }, ref) => {
    const rootClassName = _classBuilder2.default.call(void 0, 
      "meeting--on-call--pin-btn",
      { active: isActive },
    )
    return (
      _react2.default.createElement('div', { ref: ref, onClick: onClick, className: rootClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 127}} )
    )
  },
); exports.PinActiveSpeakerBtn = PinActiveSpeakerBtn

 const InviteParticipantsBtn = _react2.default.forwardRef(
  ({ onClick, className }, ref) => (
    _react2.default.createElement(exports.GenericBtn, {
      ref: ref,
      onClick: onClick,
      className: className,
      iconAlt: "+",
      iconSrc: "/assets/icons/video/add-participant-white.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}
    )
  ),
); exports.InviteParticipantsBtn = InviteParticipantsBtn

 const HangupBtn = _react2.default.forwardRef(
  ({ onClick, className }, ref) => {
    const rootClassName = _classBuilder2.default.call(void 0, 
      buildIconClassName(className),
      "video-call--call-controls--icon-hangup",
    )
    return (
      _react2.default.createElement('div', {
        ref: ref,
        className: rootClassName,
        onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}
      
        , _react2.default.createElement('img', { src: "/assets/icons/video/hang-up.svg", alt: "Hang up" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}} )
      )
    )
  },
); exports.HangupBtn = HangupBtn
