"use strict";Object.defineProperty(exports, "__esModule", {value: true});














var SetupSteps; (function (SetupSteps) {
  const PERSONAL_INFO = 1; SetupSteps[SetupSteps["PERSONAL_INFO"] = PERSONAL_INFO] = "PERSONAL_INFO";
  const PASSWORD = PERSONAL_INFO + 1; SetupSteps[SetupSteps["PASSWORD"] = PASSWORD] = "PASSWORD";
  const ORG_INFO = PASSWORD + 1; SetupSteps[SetupSteps["ORG_INFO"] = ORG_INFO] = "ORG_INFO";
  const ORG_SURVEY_TYPE = ORG_INFO + 1; SetupSteps[SetupSteps["ORG_SURVEY_TYPE"] = ORG_SURVEY_TYPE] = "ORG_SURVEY_TYPE";
  const ORG_SURVEY_REFERRER = ORG_SURVEY_TYPE + 1; SetupSteps[SetupSteps["ORG_SURVEY_REFERRER"] = ORG_SURVEY_REFERRER] = "ORG_SURVEY_REFERRER";
  const ORG_SURVEY_FEATURES = ORG_SURVEY_REFERRER + 1; SetupSteps[SetupSteps["ORG_SURVEY_FEATURES"] = ORG_SURVEY_FEATURES] = "ORG_SURVEY_FEATURES";
  const FINISH = ORG_SURVEY_FEATURES + 1; SetupSteps[SetupSteps["FINISH"] = FINISH] = "FINISH";
})(SetupSteps || (exports.SetupSteps = SetupSteps = {}));












var WelcomeUserValidStatus; (function (WelcomeUserValidStatus) {
  const COLD = 'Cold'; WelcomeUserValidStatus["COLD"] = COLD;
  const ORG_INVITE = 'Org Invite'; WelcomeUserValidStatus["ORG_INVITE"] = ORG_INVITE;
})(WelcomeUserValidStatus || (exports.WelcomeUserValidStatus = WelcomeUserValidStatus = {}));

var WelcomeUserInvalidStatus; (function (WelcomeUserInvalidStatus) {
  const INVALID_TOKEN = 'Invalid Token'; WelcomeUserInvalidStatus["INVALID_TOKEN"] = INVALID_TOKEN;
  const TOKEN_EXPIRED = 'Token Expired'; WelcomeUserInvalidStatus["TOKEN_EXPIRED"] = TOKEN_EXPIRED;
  const TOKEN_USED = 'Token Used'; WelcomeUserInvalidStatus["TOKEN_USED"] = TOKEN_USED;
  const ACTIVE = 'Active'; WelcomeUserInvalidStatus["ACTIVE"] = ACTIVE;
  const UNVERIFIED_EMAIL = 'Unverified Email'; WelcomeUserInvalidStatus["UNVERIFIED_EMAIL"] = UNVERIFIED_EMAIL;
  const SERVER_ERROR = 'Server Error'; WelcomeUserInvalidStatus["SERVER_ERROR"] = SERVER_ERROR;
})(WelcomeUserInvalidStatus || (exports.WelcomeUserInvalidStatus = WelcomeUserInvalidStatus = {}));










