"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');

var _noticeBar = require('web/services/context/noticeBar');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);






 const useQuickLoginUsers = ({
  switchToUsernameLogin,
  switchToQuickLogin,
}) => {
  const [isQuickLoginAvailable, setIsQuickLoginAvailable] = _react.useState.call(void 0, false)
  const [isQuickLoginListLoading, setIsQuickLoginListLoading] = _react.useState.call(void 0, true)

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  _react.useEffect.call(void 0, 
    () => {
      _actions2.default.logout()
      _actions2.default.getQuickLoginUsers()
        .then(users => {
          const isQuickLoginAvailable = Object.keys(users).length > 0
          setIsQuickLoginAvailable(isQuickLoginAvailable)
          isQuickLoginAvailable
            ? switchToQuickLogin()
            : switchToUsernameLogin()
        })
        .catch(() => showWarningBar('Please check your network connection'))
        .finally(() => setIsQuickLoginListLoading(false))
    },
    [showWarningBar, switchToQuickLogin, switchToUsernameLogin],
  )

  return { isQuickLoginAvailable, isQuickLoginListLoading }
}; exports.useQuickLoginUsers = useQuickLoginUsers
