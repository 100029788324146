"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Notifications/Sections/EmailSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _useSafeSwitch = require('shared/hooks/useSafeSwitch'); var _useSafeSwitch2 = _interopRequireDefault(_useSafeSwitch);
var _noticeBar = require('web/services/context/noticeBar');

var _Form = require('web/components/Form');













const EmailSection = ({
  hasEmail,
  hasPhone,
  isEmailNotificationsOn,
  isSMSNotificationsOn,
  profileSettingsUrl,
  onTurnOnEmailNotifications,
  onTurnOffEmailNotifications,
  onTurnOnSMSNotifications,
  onTurnOffSMSNotifications,
}) => {
  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )

  const [isSMSNotificationsEnabled, setIsSMSNotificationsEnabled] = _useSafeSwitch2.default.call(void 0, 
    isSMSNotificationsOn,
    isOn => isOn ? onTurnOnSMSNotifications() : onTurnOffSMSNotifications(),
  )

  const [isEmailNotificationsEnabled, setIsEmailNotificationsEnabled] = _useSafeSwitch2.default.call(void 0, 
    isEmailNotificationsOn,
    isOn => isOn ? onTurnOnEmailNotifications() : onTurnOffEmailNotifications(),
  )

  const toggleSMSNotifications = () => {
    const isOn = !isSMSNotificationsEnabled
    setIsSMSNotificationsEnabled(isOn)
      .then(() => showSuccessBar(`SMS notifications are ${isOn ? 'enabled' : 'disabled'}`))
      .catch(() => showWarningBar(`Failed to ${isOn ? 'enable' : 'disable'} SMS notifications`))
  }

  const toggleEmailNotifications = () => {
    const isOn = !isEmailNotificationsEnabled
    setIsEmailNotificationsEnabled(isOn)
      .then(() => showSuccessBar(`Email notifications are ${isOn ? 'enabled' : 'disabled'}`))
      .catch(() => showWarningBar(`Failed to ${isOn ? 'enable' : 'disable'} email notifications`))
  }

  const renderSMSWarning = () => (
    _react2.default.createElement(_react2.default.Fragment, null, "You need to"
        , ' '
      , _react2.default.createElement(_reactrouterdom.Link, { to: profileSettingsUrl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}, "validate your phone number"   ), ' ', "before receiving text messages."

    )
  )

  const renderEmailWarning = () => (
    _react2.default.createElement(_react2.default.Fragment, null, "You need to"
        , ' '
      , _react2.default.createElement(_reactrouterdom.Link, { to: profileSettingsUrl, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "validate your email address"   ), ' ', "before receiving email notifications."

    )
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.Toggle, {
        className: "settings--notifications--email-label",
        isChecked: isSMSNotificationsEnabled,
        isDisabled: !hasPhone,
        onChange: toggleSMSNotifications,
        label: "Text Message (SMS)"  ,
        hint: `You will ${!isSMSNotificationsEnabled ? 'not' : ''} receive a text message when you have unread messages.`,
        warning: !hasPhone && renderSMSWarning(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
      )
      , _react2.default.createElement(_Form.Toggle, {
        className: "settings--notifications--email-label",
        isChecked: isEmailNotificationsEnabled,
        isDisabled: !hasEmail,
        onChange: toggleEmailNotifications,
        label: "Email",
        hint: `You will ${!isEmailNotificationsEnabled ? 'not' : ''} receive an email when you have unread messages.`,
        warning: !hasEmail && renderEmailWarning(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
      )
    )
  )
}

exports. default = EmailSection
