"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upgrade/UpgradeModal/components/PlanModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const PlanModal = ({
  isOpen,
  onClose,
  className,
  children,
}) => (
  _react2.default.createElement(_Modal2.default, {
    isOpen: isOpen,
    onClose: onClose,
    className: "plan-modal",
    bodyClassName: _classBuilder2.default.call(void 0, 'plan-modal--body', className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
  
    , _react2.default.createElement('div', { role: "button", className: "plan-modal--close-icon", onClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
    ,  children 
  )
)

exports. default = PlanModal
