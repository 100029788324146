"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _store = require('shared/store');

// Optimistically assume connection will be good (to delay showing disconnected message to user)
// Only can have one of these at a time
let optimisticReportTimeout, optimisticOpenHandler

exports. default = {
  assumeGoodInitialConnectionMS: (ms) => {
    _store.dispatch.call(void 0, { type: 'OPTIMISTIC_INITIAL_SOCKET_CONNECTED' })

    if (optimisticReportTimeout) {
      clearTimeout(optimisticReportTimeout)
    }

    if (ms == null || _client2.default.isConnected()) return

    optimisticOpenHandler = () => {
      clearTimeout(optimisticReportTimeout)
      optimisticReportTimeout = null
      _client2.default.off('open', optimisticOpenHandler)
    }

    optimisticReportTimeout = setTimeout(() => {
      _store.dispatch.call(void 0, { type: 'FAILED_OPTIMISTIC_SOCKET_CONNECTED' })
      _client2.default.off('open', optimisticOpenHandler)
    }, ms)

    _client2.default.on('open', optimisticOpenHandler)
  },
}
