"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/OrgSurvey/FeaturesForm.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _signup = require('shared/enum/signup');
var _components = require('web/screens/Signup/components');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);













const features = [
  {
    key: _signup.OrgSurveyFeature.SMS_MESSAGING,
    iconSrc: '/assets/icons/pricing/sms-messaging-badge.svg',
    name: 'SMS Messaging',
    desc: 'Secure texting with patients, staff, and clients',
  },
  {
    key: _signup.OrgSurveyFeature.TEAM_CHAT,
    iconSrc: '/assets/icons/pricing/team-communication-badge.svg',
    name: 'Team Chat',
    desc: 'Chat with your coworkers',
  },
  {
    key: _signup.OrgSurveyFeature.EXTERNAL_CHAT,
    iconSrc: '/assets/icons/pricing/team-badge.svg',
    name: 'External Chat',
    desc: 'Chat with other healthcare organizations',
  },
]

const INITIAL_MAP = {}

const FeaturesForm = ({ onBack, onSubmit }) => {
  const [isLoading, setIsLoading] = _react.useState.call(void 0, false)
  const [selectedFeaturesMap, setSelectedFeaturesMap] = _react.useState.call(void 0, INITIAL_MAP)

  const onToggleFeature = (key) =>
    setSelectedFeaturesMap(m => ({ ...m, [key]: !m[key] }))

  const handleSubmit = e => {
    e.preventDefault()
    setIsLoading(true)
    const selectedFeaturesList = Object.entries(selectedFeaturesMap)
      .reduce(
        (acc, [k, v]) => {
          if (v) acc.push(k)
          return acc
        },
        [] ,
      )
    onSubmit(selectedFeaturesList)
      .catch(() => setIsLoading(false))
  }

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "org",
        title: "Why are you signing up for BloomText?"      ,
        subtitle: "Select your primary interests"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
      )
      ,  features.map(f => (
        _react2.default.createElement('div', {
          key: f.key,
          role: "button",
          className: _classBuilder2.default.call(void 0, 
            "signup--org-survey--feature",
            selectedFeaturesMap[f.key] && "signup--org-survey--feature-selected",
          ),
          onClick: () => onToggleFeature(f.key), __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
        
          , _react2.default.createElement('img', { src: f.iconSrc, className: "signup--org-survey--feature--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}} )
          , _react2.default.createElement('div', { className: "signup--org-survey--feature--info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
            , _react2.default.createElement('h3', { className: "signup--org-survey--feature--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}},  f.name )
            , _react2.default.createElement('span', { className: "signup--org-survey--feature--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 84}},  f.desc )
          )
          ,  selectedFeaturesMap[f.key] && (
            _react2.default.createElement('img', {
              src: "/assets/icons/tick-dark-grey.svg",
              className: "signup--org-survey--feature--toggle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            )
          )
        )
      ))
      , _react2.default.createElement(_components.SignupStepControls, { onBack: onBack, isNextLoading: isLoading, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} )
    )
  )
}

exports. default = FeaturesForm
