"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Item/ActionBtn.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Spinner = require('web/components/Spinner');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const stopPropagation = e => e.stopPropagation()

const ActionBtn = ({
  isCompleted,
  isBeingArchived,
  onToggle,
}) => (
  _react2.default.createElement('div', { className: "list-item--actions", onClick: stopPropagation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
    ,  isBeingArchived
      ? _react2.default.createElement(_Spinner.SimpleSpinner, { className: "list-item--actions--loader", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
      : _react2.default.createElement('div', {
          role: "button",
          onClick: onToggle,
          className: _classBuilder2.default.call(void 0, 
            'list-item--actions-item',
            'list-item--action',
            isCompleted ? 'sticky' : 'mobile-hide',
          ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        
          , _react2.default.createElement('i', { className: "material-icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}, "check_circle")
          , _react2.default.createElement('span', { className: "list-item--actions-item--description", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
            ,  isCompleted ? "Unarchive" : "Archive" 
          )
        )
    
  )
)

exports. default = ActionBtn
