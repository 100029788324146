"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _types = require('web/screens/Directory/types');
var _org = require('shared/selectors/org');
var _user = require('shared/selectors/user');

var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);





var _List = require('web/screens/App/List');
var _OrgPicker = require('./OrgPicker'); var _OrgPicker2 = _interopRequireDefault(_OrgPicker);
var _DirectoryCreateNewModal = require('web/screens/Directory/DirectoryCreateNewModal'); var _DirectoryCreateNewModal2 = _interopRequireDefault(_DirectoryCreateNewModal);
var _List3 = require('./List'); var _List4 = _interopRequireDefault(_List3);








const DirectorySidebar = ({
  onSelectTab,
  selectedTab,
  orgKey,
  amIAdmin,
  hasPatientsFeature,
  hasAnyActiveOrgs,
  onRequestPatientsFeature,
}) => {
  const {
    isOpen: isCreateNewModalOpen,
    onOpen: onOpenCreateNewModal,
    onClose: onCloseCreateNewModal,
  } = _useToggle2.default.call(void 0, )

  const resolveAddHandler = () => {
    if (
      !hasAnyActiveOrgs
      || (!amIAdmin && selectedTab === _types.DirectoryTab.TEAMS)
    ) return null

    if (!hasPatientsFeature && selectedTab === _types.DirectoryTab.PATIENTS) {
      return amIAdmin ? onRequestPatientsFeature : null
    }

    return onOpenCreateNewModal
  }

  const onAddPress = resolveAddHandler()

  return (
    _react2.default.createElement(_List.List, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
      , _react2.default.createElement(_DirectoryCreateNewModal2.default, {
        isOpen: isCreateNewModalOpen,
        onClose: onCloseCreateNewModal,
        directoryTypeHint: selectedTab,
        orgKeyContext: orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
      )
      , _react2.default.createElement(_List.ListHeaderWrapper, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
        , _react2.default.createElement(_List.ListHeaderWithAddButton, { onAddPress: onAddPress, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
          , _react2.default.createElement(_List.ListHeaderTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Directory")
          , _react2.default.createElement(_OrgPicker2.default, { selectedOrgKey: orgKey, onSelectOrg: _actions2.default.setSelectedOrg, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}} )
        )
      )
      ,  orgKey && (
        _react2.default.createElement(_List4.default, {
          orgKey: orgKey,
          selectedTab: selectedTab,
          onSelectTab: onSelectTab, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        )
      )
    )
  )
}







const mapState = (state, { orgKey }) => ({
  amIAdmin: !!(orgKey && _user.selectAmIAdminInOrg.call(void 0, state, orgKey)),
  hasAnyActiveOrgs: _user.selectHasAnyActiveOrgs.call(void 0, state),
  hasPatientsFeature: !!(orgKey && _org.selectOrgHasPatientMessagingFeature.call(void 0, state, orgKey)),
})

exports. default = _reactredux.connect.call(void 0, mapState)(DirectorySidebar)
