"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/MembersTab/components/Modals/AddMembers/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);



var _createNew = require('shared/hooks/createNew');
var _chatParticipantQuotas = require('shared/constants/chatParticipantQuotas');
var _array = require('shared/services/array');
var _entity = require('shared/services/entity');
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _ParticipantsPicker = require('web/components/ParticipantsPicker'); var _ParticipantsPicker2 = _interopRequireDefault(_ParticipantsPicker);
var _OrgLimitNotice = require('web/components/Upgrade/OrgLimitNotice'); var _OrgLimitNotice2 = _interopRequireDefault(_OrgLimitNotice);









const AddMembersForm = ({
  onClose,
  orgKey,
  onSubmit,
}) => {
  const {
    selectedEntitiesList,
    onSelectEntity,
    onRemoveEntity,
  } = _createNew.useSelectedEntities.call(void 0, )

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const [isSubmitting, setIsSubmitting] = _react.useState.call(void 0, false)
  const handleSubmit = _react.useCallback.call(void 0, 
    () => {
      setIsSubmitting(true)

      const userKeys = selectedEntitiesList
        .map(e => _entity.isEntityUser.call(void 0, e) && e.wrappedEntity.UserKey)
        .filter(_array.truthy)

      return onSubmit(userKeys)
        .then(onClose)
        .then(() => showSuccessBar('Users have been invited'))
        .catch(() => {
          showWarningBar('Failed to invite users')
          setIsSubmitting(false)
        })
    },
    [onSubmit, onClose, showWarningBar, showSuccessBar, selectedEntitiesList],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_OrgLimitNotice2.default, {
        orgKey: orgKey,
        addedUsersCount: selectedEntitiesList.length, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
      )
      , _react2.default.createElement(_ParticipantsPicker2.default, {
        orgKeyContext: orgKey,
        selectedEntitiesList: selectedEntitiesList,
        onSelectEntity: onSelectEntity,
        onRemoveEntity: onRemoveEntity,
        canInviteCollaborators: false,
        participantQuotas: _chatParticipantQuotas.USERS_ONLY_CHAT_QUOTA,
        className: "participants-picker--field",
        inputClassName: "participants-picker--field--input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Add",
        onPrimaryBtnClick: handleSubmit,
        isPrimaryLoading: isSubmitting,
        isPrimaryEnabled: selectedEntitiesList.length > 0 && !isSubmitting,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      )
    )
  )
}

exports. default = AddMembersForm
