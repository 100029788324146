"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperOrganizationsScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _alert = require('shared/actions/alert'); var _alert2 = _interopRequireDefault(_alert);
var _log = require('shared/log');
var _features = require('shared/enum/features'); var _features2 = _interopRequireDefault(_features);
var _alertReducer = require('shared/reducers/alertReducer');
var _useSearchQuery = require('shared/hooks/useSearchQuery');
var _Form = require('web/components/Form');
var _SuperTableTR = require('web/components/Super/SuperTableTR'); var _SuperTableTR2 = _interopRequireDefault(_SuperTableTR);

const OrgResult = (props) => {
  return (
    _react2.default.createElement(_SuperTableTR2.default, { to: `/super/orgs/${props.org.OrgKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
      , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, props.org.OrgKey)
      , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, props.org.Name)
      , _react2.default.createElement('td', { className: "numeric", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, props.org.NumberOfMembers)
      , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}, props.org.Verified ? 'yes' : 'no')
      , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}, props.org.EnabledFeatures.includes(_features2.default.ADVANCED_SEARCH) > 0 ? 'yes' : 'no')
    )
  )
}

 class SuperOrganizationsScreen extends _react.Component {
  constructor (props) {
    super(props);SuperOrganizationsScreen.prototype.__init.call(this);SuperOrganizationsScreen.prototype.__init2.call(this);SuperOrganizationsScreen.prototype.__init3.call(this);
    this.state = {
      results: [],
      queryValue: "",
    }
  }

  __init() {this.queryChange = (query) => {
    this.setState({ queryValue: query })
    clearTimeout(this.bounceTimeout)
    this.bounceTimeout = setTimeout(() => {
      _client2.default.send('SuperSearchOrgs', { Query: _useSearchQuery.sanitizeSearchQuery.call(void 0, query), limit: 50 })
        .then((results) => {
          this.setState({
            results: Object.values(results),
          })
        })
        .catch(_log.log.error)
    }, 300)
  }}

  componentDidMount () {
    this.queryChange("")
  }

  __init2() {this.updateOrgList = (evt) => this.orgList = evt.target.files[0]}
  __init3() {this.bulkCreate = (evt) => {
    evt.preventDefault()
    _log.log.info(this.orgList)
    _client2.default.uploadFile(this.orgList)
      .then((Metadata) => {
        _client2.default.send('SuperBulkCreateOrgs', {
          FileKey: Metadata.FileKey,
        }).then(() => {
          _alert2.default.pushAlert("Success", "Successfully created orgs", _alertReducer.AlertTypes.SUCCESS)
        }).catch(_log.log.error)
      })
  }}

  render () {
    const results = this.state.results.map((org) => _react2.default.createElement(OrgResult, { org: org, key: org.OrgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} ))

    return (
      _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
        , _react2.default.createElement('div', { className: "super--search-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
          , _react2.default.createElement(_Form.TextInput, {
            placeholder: "Search for orgs..."  ,
            wrapperClassName: "super--search-bar",
            onChange: e => this.queryChange(e.target.value),
            value: this.state.queryValue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          )
        )
        , _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
          , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}
            , _react2.default.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}, "Org Key" )
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, "Name")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}, "Members")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}, "Verified")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}, "Paid")
            )
          )
          , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
            , results
          )
        )
        , _react2.default.createElement('form', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
          , _react2.default.createElement('input', { type: "file", id: "orgList", onChange: this.updateOrgList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}} )
          , _react2.default.createElement('button', { onClick: this.bulkCreate, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}, "Bulk Create Orgs"  )
        )
      )
    )
  }
} exports.default = SuperOrganizationsScreen;
