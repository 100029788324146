"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/InfoTab/Form/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _existingOrg = require('shared/validation/org/existingOrg');
var _org = require('shared/selectors/org');

var _hookForm = require('shared/services/validation/hookForm');
var _noticeBar = require('web/services/context/noticeBar');
var _Section = require('./Section'); var _Section2 = _interopRequireDefault(_Section);
var _Form = require('web/components/Form');







const makeDefaultValues = (org) => ({
  name: _nullishCoalesce(_optionalChain([org, 'optionalAccess', _ => _.Name]), () => ( '')),
  address: _nullishCoalesce(_optionalChain([org, 'optionalAccess', _2 => _2.Address]), () => ( '')),
  phone: _nullishCoalesce(_optionalChain([org, 'optionalAccess', _3 => _3.Phone]), () => ( '')),
  email: _nullishCoalesce(_optionalChain([org, 'optionalAccess', _4 => _4.Email]), () => ( '')),
})

const OrgInfoForm = ({
  org, onSubmit, isEditable,
}) => {
  const { register, reset, handleSubmit, formState } = _hookForm.useForm.call(void 0, {
    mode: 'onChange',
    resolver: _existingOrg.existingOrgResolver,
    defaultValues: makeDefaultValues(org),
  })
  const { isValid, isDirty, isSubmitting, errors } = formState

  _react.useEffect.call(void 0, () => { reset(makeDefaultValues(org)) }, [reset, org])

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const submit = _react.useCallback.call(void 0, 
    data => onSubmit(data)
      .then(() => showSuccessBar("Organization info updated"))
      .catch(() => showWarningBar("Failed to update organization info")),
    [showWarningBar, showSuccessBar, onSubmit],
  )

  if (!org) return null

  return (
    _react2.default.createElement('form', {
      className: "settings--org-info--form",
      onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
    
      , _react2.default.createElement(_Section2.default, {
        title: "Organization Name" ,
        placeholder: "E.g. Central Hospital"  ,
        errorMessage: _optionalChain([errors, 'access', _5 => _5.name, 'optionalAccess', _6 => _6.message]),
        inputProps: register('name'),
        isEditable: isEditable,
        defaultValue: org.Name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
      )
      , _react2.default.createElement(_Section2.default, {
        title: "Address",
        isEditable: isEditable,
        placeholder: "E.g. 3170 W. 53 Rd. 35"     ,
        errorMessage: _optionalChain([errors, 'access', _7 => _7.address, 'optionalAccess', _8 => _8.message]),
        inputProps: register('address'),
        defaultValue: org.Address, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      )
      , _react2.default.createElement(_Section2.default, {
        title: "Phone Contact" ,
        isEditable: isEditable,
        placeholder: "E.g. (415) 903-1200"  ,
        errorMessage: _optionalChain([errors, 'access', _9 => _9.phone, 'optionalAccess', _10 => _10.message]),
        inputProps: register('phone'),
        inputType: "tel",
        defaultValue: org.Phone, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      )
      , _react2.default.createElement(_Section2.default, {
        title: "Email Contact" ,
        isEditable: isEditable,
        placeholder: "E.g. your@organization.com" ,
        errorMessage: _optionalChain([errors, 'access', _11 => _11.email, 'optionalAccess', _12 => _12.message]),
        inputProps: register('email'),
        inputType: "email",
        defaultValue: org.Email, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
      ,  isEditable && (
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement('span', { className: "settings--org-info--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}, "This information will be publicly displayed to help users find your organization on BloomText."

          )
          , _react2.default.createElement(_Form.Button, {
            isSubmit: true,
            isDisabled: !isValid || !isDirty || isSubmitting,
            isLoading: isSubmitting,
            className: "settings--org-info--submit-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
          , "Save"

          )
        )
      )
    )
  )
}





const mapState = (state, { orgKey }) => ({
  org: _org.selectOrgByKey.call(void 0, orgKey, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(OrgInfoForm)
