"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/components/SignupStepControls.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);










const OrgSurveyControls = ({
  onBack,
  onNext,
  isNextDisabled,
  isNextLoading,
  className,
  nextBtnLabel = 'Continue',
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "signup-step--controls", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    ,  onBack && _react2.default.createElement(_Form.Button, { isSecondary: true, onClick: onBack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "Back") 
    , _react2.default.createElement(_Form.Button, {
      isSubmit: !onNext,
      isLoading: isNextLoading,
      isDisabled: isNextDisabled,
      className: "signup-step--controls--next-btn",
      onClick: onNext, __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    
      ,  nextBtnLabel , ' '
      , _react2.default.createElement('img', { src: "/assets/icons/icon-arrow-right-white.svg", className: "icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
    )
  )
)

exports. default = OrgSurveyControls
