"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tooltip/common.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

 const propTypes = {
  type: _proptypes2.default.oneOf(['dark', 'light']),
  place: _proptypes2.default.oneOf(['top', 'left', 'bottom', 'bottom-left', 'right']),
  fixedPosition: _proptypes2.default.shape({
    top: _proptypes2.default.number,
    left: _proptypes2.default.number,
    right: _proptypes2.default.number,
    bottom: _proptypes2.default.number,
  }),
  content: _proptypes2.default.element,
  hideArrow: _proptypes2.default.bool,
  onOpen: _proptypes2.default.func,
  onClose: _proptypes2.default.func,
}; exports.propTypes = propTypes

 const defaultProps = {
  type: 'light',
  place: 'left',
  content: _react2.default.createElement('div', { style: { color: 'red' }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}, "EMPTY"),
  hideArrow: false,
  onClose: () => {},
  onOpen: () => {},
}; exports.defaultProps = defaultProps

 const getTooltipPosition = (
  tooltipTargetDOM, tooltipContentDOM, place, hideArrow, offset = {},
) => {
  if (!tooltipTargetDOM || !tooltipContentDOM) return {}

  const tooltipTargetRect = tooltipTargetDOM.getBoundingClientRect()
  const tooltipContentRect = tooltipContentDOM.getBoundingClientRect()

  const {
    left: targetX, top: targetY,
    width: targetWidth, height: targetHeight,
  } = tooltipTargetRect
  const { width: contentWidth, height: contentHeight } = tooltipContentRect
  let baseOffset = 10

  if (window.innerWidth <= 560 || hideArrow) baseOffset = 0

  const requestedOffset = {
    top: offset.top || 0,
    right: offset.right || 0,
    bottom: offset.bottom || 0,
    left: offset.left || 0,
  }
  const verticalOffset = requestedOffset.top - requestedOffset.bottom
  const horizontalOffset = requestedOffset.left - requestedOffset.right

  const position = ({
    "top": {
      top: targetY - contentHeight - baseOffset + verticalOffset,
      left: targetX - contentWidth / 2 + targetWidth / 2 + horizontalOffset,
    },
    "bottom": {
      top: targetY + targetHeight + baseOffset + verticalOffset,
      left: targetX - contentWidth / 2 + targetWidth / 2 + horizontalOffset,
    },
    "bottom-left": {
      top: targetY + targetHeight + baseOffset + verticalOffset,
      left: targetX + horizontalOffset,
    },
    "left": {
      top: targetY - contentHeight / 2 + targetHeight / 2 + verticalOffset,
      left: targetX - contentWidth - baseOffset + horizontalOffset,
    },
    "right": {
      top: targetY - contentHeight / 2 + targetHeight / 2 + verticalOffset,
      left: targetX + targetWidth + baseOffset + horizontalOffset,
    },
  })[place]

  const screenPadding = 15
  if (position.left + contentWidth > window.innerWidth) { /* too far right */
    position.left = window.innerWidth - contentWidth - screenPadding
  } else if (position.left < 0) { /* too far left */
    position.left = screenPadding
  }

  if (position.top + contentHeight > window.innerHeight) {
    position.top = window.innerHeight - contentHeight - screenPadding
  } else if (position.top < 0) {
    position.top = screenPadding
  }
  return position
}; exports.getTooltipPosition = getTooltipPosition

 const getToolTipArrowPosition = (tooltipTargetDOM, tooltipPosition, place) => {
  const targetRect = tooltipTargetDOM.getBoundingClientRect()
  const targetHalfWidth = (targetRect.right - targetRect.left) / 2
  const targetHalfHeight = (targetRect.bottom - targetRect.top) / 2
  return place.includes('top') || place.includes('bottom')
    ? { left: Math.abs(targetRect.left + targetHalfWidth - tooltipPosition.left) }
    : { top: Math.abs(targetRect.top + targetHalfHeight - tooltipPosition.top) }
}; exports.getToolTipArrowPosition = getToolTipArrowPosition

 const getTooltipTypeClass = ({ type, place }) => {
  const colorClassName = ({
    dark: 'blm-tooltip--dark',
    light: 'blm-tooltip--light',
  })[type]

  const placeClassName = ({
    "top": 'blm-tooltip--top',
    "bottom": 'blm-tooltip--bottom',
    "bottom-left": 'blm-tooltip--bottom',
    "right": 'blm-tooltip--right',
    "left": 'blm-tooltip--left',
  })[place]

  return _classBuilder2.default.call(void 0, colorClassName, placeClassName)
}; exports.getTooltipTypeClass = getTooltipTypeClass
