"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reactrouterdom = require('react-router-dom');



var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _user = require('shared/selectors/user');

var _RightSide = require('web/components/RightSide');
var _App = require('web/screens/App');
var _CampaignPage = require('./CampaignPage'); var _CampaignPage2 = _interopRequireDefault(_CampaignPage);
var _EmptyCampaignPage = require('./EmptyCampaignPage'); var _EmptyCampaignPage2 = _interopRequireDefault(_EmptyCampaignPage);
var _Sidebar = require('./Sidebar'); var _Sidebar2 = _interopRequireDefault(_Sidebar);
var _UpsellCampaignPage = require('./UpsellCampaignPage'); var _UpsellCampaignPage2 = _interopRequireDefault(_UpsellCampaignPage);

const BroadcastScreen = ({ hasPatientMessaging }) => {
  const match = _reactrouterdom.useRouteMatch(_routes2.default.BROADCAST.CAMPAIGN_PAGE)
  const campaignKey = _nullishCoalesce(_optionalChain([match, 'optionalAccess', _ => _.params, 'optionalAccess', _2 => _2.campaignKey]), () => ( null))

  return (
    _react2.default.createElement(_App.AppVertical, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
      , _react2.default.createElement(_App.NavigationMenu, { className: "broadcast--nav-menu", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , _react2.default.createElement(_Sidebar2.default, { hasFeature: hasPatientMessaging, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}} )
      )
      , _react2.default.createElement(_RightSide.RightSide, { className: "broadcast--right-side", inView: !!campaignKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        ,  hasPatientMessaging
          ? (
              _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
                , _react2.default.createElement(_reactrouterdom.Route, {
                  exact: true,
                  path: _routes2.default.BROADCAST.CAMPAIGN_PAGE,
                  render: () => campaignKey
                    ? _react2.default.createElement(_CampaignPage2.default, { key: campaignKey, campaignKey: campaignKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}} )
                    : null
                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}})
                , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.BROADCAST.ROOT, component: _EmptyCampaignPage2.default, __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}} )
              )
            )
          : _react2.default.createElement(_UpsellCampaignPage2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 40}} )
        
      )
    )
  )
}





const mapState = (state) => ({
  hasPatientMessaging: _user.selectCurrentUserHasPatientMessagingFeature.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(BroadcastScreen)
