"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Navigation/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _hooks = require('web/screens/Settings/hooks');

var _App = require('web/screens/App');
var _Notice = require('web/screens/Settings/Notice'); var _Notice2 = _interopRequireDefault(_Notice);
var _NavItem = require('./NavItem'); var _NavItem2 = _interopRequireDefault(_NavItem);






const Navigation = ({ memberOrgs, pathname }) => {
  const navOptions = _hooks.useMenu.call(void 0, { memberOrgs, pathname })

  return (
    _react2.default.createElement(_App.NavigationMenu, { className: "settings--nav-menu", __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
      , _react2.default.createElement('div', { className: "settings--nav-header--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
        , _react2.default.createElement('h1', { className: "settings--nav-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "Settings")
      )
      , _react2.default.createElement('div', { className: "settings--nav-body", role: "menu", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        ,  navOptions.map(l => (
          _react2.default.createElement(_NavItem2.default, {
            key: l.route,
            label: l.label,
            iconClassName: l.icon,
            isActive: !!l.isActive,
            gotoRoute: l.gotoRoute,
            subroutes: l.subroutes, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
          )
        ))
        , _react2.default.createElement(_NavItem2.default, {
          gotoRoute: _actions2.default.logout,
          iconClassName: "logout",
          label: "Log out" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
        )
      )
      , _react2.default.createElement(_Notice2.default, { className: "settings--nav-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}} )
    )
  )
}

exports. default = Navigation
