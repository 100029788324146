"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/ProgressBar/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _broadcast = require('shared/enum/broadcast');
var _broadcast3 = require('shared/selectors/broadcast');
var _array = require('shared/services/array');

var _FilterSelect = require('./FilterSelect'); var _FilterSelect2 = _interopRequireDefault(_FilterSelect);
var _ProgressSection = require('./ProgressSection'); var _ProgressSection2 = _interopRequireDefault(_ProgressSection);







const calcTotal = (stats) =>
  stats.completed + stats.failed +
  stats.processing + stats.queued +
  stats.not_scheduled

const emptyStats =
  { completed: 0, failed: 0, processing: 0, queued: 0, not_scheduled: 0 }

const ProgressBar = ({
  stats, activeFilter, onSetFilter,
}) => {
  const apparentStats = _react.useMemo.call(void 0, 
    () => activeFilter
      ? ({ ...emptyStats, [activeFilter]: stats[activeFilter] })
      : stats,
    [activeFilter, stats],
  )
  const filterOptions = _react.useMemo.call(void 0, 
    () => [
      stats.not_scheduled && _broadcast.broadcastMsgStatus.NOT_SCHEDULED,
      stats.queued && _broadcast.broadcastMsgStatus.QUEUED,
      stats.processing && _broadcast.broadcastMsgStatus.PROCESSING,
      stats.completed && _broadcast.broadcastMsgStatus.COMPLETED,
      stats.failed && _broadcast.broadcastMsgStatus.FAILED,
    ].filter(_array.truthy),
    [stats],
  )

  const totalCount = calcTotal(stats)
  const apparentTotalCount = calcTotal(apparentStats)

  _react.useEffect.call(void 0, 
    () => { activeFilter && !apparentStats[activeFilter] && onSetFilter(null) },
    [apparentStats, activeFilter, onSetFilter],
  )

  if (!totalCount || (!activeFilter && totalCount === stats.not_scheduled)) {
    return (
      _react2.default.createElement('div', { className: "broadcast--progress-bar--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
        , _react2.default.createElement('div', { className: "broadcast--progress-bar--track broadcast--progress-bar--track-empty" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          , _react2.default.createElement('span', { className: "broadcast--progress-bar--no-progress", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
            ,  totalCount ? `${totalCount} Patients` : 'No patients yet.' 
          )
        )
      )
    )
  }

  return (
    _react2.default.createElement('div', { className: "broadcast--progress-bar--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      , _react2.default.createElement(_FilterSelect2.default, {
        value: activeFilter,
        onChange: onSetFilter,
        options: filterOptions,
        className: "broadcast--progress-bar--filter", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      )
      , _react2.default.createElement('div', { className: "broadcast--progress-bar--track", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
        , _react2.default.createElement(_ProgressSection2.default, {
          count: apparentStats.completed,
          totalCount: apparentTotalCount,
          type: _broadcast.broadcastMsgStatus.COMPLETED,
          onClick: () => onSetFilter(activeFilter ? null : _broadcast.broadcastMsgStatus.COMPLETED), __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}
        )
        , _react2.default.createElement(_ProgressSection2.default, {
          count: apparentStats.failed,
          totalCount: apparentTotalCount,
          type: _broadcast.broadcastMsgStatus.FAILED,
          onClick: () => onSetFilter(activeFilter ? null : _broadcast.broadcastMsgStatus.FAILED), __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
        )
        , _react2.default.createElement(_ProgressSection2.default, {
          count: apparentStats.processing,
          totalCount: apparentTotalCount,
          type: _broadcast.broadcastMsgStatus.PROCESSING,
          onClick: () => onSetFilter(activeFilter ? null : _broadcast.broadcastMsgStatus.PROCESSING), __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
        )
        , _react2.default.createElement(_ProgressSection2.default, {
          count: apparentStats.queued,
          totalCount: apparentTotalCount,
          type: _broadcast.broadcastMsgStatus.QUEUED,
          onClick: () => onSetFilter(activeFilter ? null : _broadcast.broadcastMsgStatus.QUEUED), __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}
        )
        , _react2.default.createElement(_ProgressSection2.default, {
          count: apparentStats.not_scheduled,
          totalCount: apparentTotalCount,
          type: _broadcast.broadcastMsgStatus.NOT_SCHEDULED,
          onClick: () => onSetFilter(activeFilter ? null : _broadcast.broadcastMsgStatus.NOT_SCHEDULED), __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
        )
      )
    )
  )
}





const mapState = (state, { campaignKey }) => ({
  stats: _broadcast3.selectCampaignStats.call(void 0, state, campaignKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(ProgressBar)
