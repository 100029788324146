"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reselect = require('reselect');

var _selectPopulatedBlobjectSubscribers = require('./selectPopulatedBlobjectSubscribers');
var _org = require('shared/selectors/org');








var _entities = require('shared/transforms/entities');
var _entity = require('shared/services/entity');

 const makeSelectBlobjectSubscriberEntitiesMap = () =>
  _reselect.createSelector.call(void 0, 
    _selectPopulatedBlobjectSubscribers.makeSelectPopulatedBlobjectSubscribers.call(void 0, ),
    _org.selectOrgs,
    (subscribers, allOrgs) => {
      const {
        userSubscribersList,
        teamSubscribersList,
        patientSubscribersList,
      } = subscribers

      const users = userSubscribersList
        .map(_entities.mapUserToEntity.call(void 0, allOrgs, _entities.makeOrgTitleUserDescription))

      const teams = teamSubscribersList
        .map(_entities.mapTeamToEntity.call(void 0, allOrgs, _entities.makeMembersCountTeamDescription))

      const patients = patientSubscribersList
        .map(_entities.mapPatientToEntity.call(void 0, allOrgs, _entities.makeOrgNamePatientDescription))

      return {
        ..._entity.entityListToMap.call(void 0, users),
        ..._entity.entityListToMap.call(void 0, teams),
        ..._entity.entityListToMap.call(void 0, patients),
      }
    },
  ); exports.makeSelectBlobjectSubscriberEntitiesMap = makeSelectBlobjectSubscriberEntitiesMap
