"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _noticeBar = require('web/services/context/noticeBar');




var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _errors = require('shared/errors');





 const useLogin = ({ onLoginSuccess }) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )

  const handleLoginSuccess = (r) => {
    _actions2.default.loginSuccess(r)
    onLoginSuccess()
  }

  const handleUsernameLogin = (login, password) =>
    _client2.default.login(login, password, false)
      .then(handleLoginSuccess)
      .catch(e => {
        if (_optionalChain([e, 'access', _ => _.response, 'optionalAccess', _2 => _2.Transient])) {
          showWarningBar('Please try again later')
        } else if (e instanceof _errors.RestError) {
          throw e
        } else {
          showWarningBar('Please check your network connection')
        }
      })

  const handleQuickLogin = (userKey, pin) =>
    _client2.default.pinLogin(userKey, pin)
      .then(handleLoginSuccess)

  return {
    handleUsernameLogin,
    handleQuickLogin,
  }
}; exports.useLogin = useLogin
