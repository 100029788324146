"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/segments/MobileCameraSetup.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _videoCall = require('web/selectors/videoCall');
var _videoCall3 = require('web/services/videoCall');
var _Camera = require('web/components/VideoCall/Setup/MobileSetup/Camera'); var _Camera2 = _interopRequireDefault(_Camera);

class CameraSetupContainer extends _react2.default.Component {constructor(...args) { super(...args); CameraSetupContainer.prototype.__init.call(this);CameraSetupContainer.prototype.__init2.call(this);CameraSetupContainer.prototype.__init3.call(this);CameraSetupContainer.prototype.__init4.call(this);CameraSetupContainer.prototype.__init5.call(this);CameraSetupContainer.prototype.__init6.call(this); }
  __init() {this.state = { stream: null }}
  __init2() {this.streamManager = null}
  __init3() {this.updateStream = s => this.setState({ stream: s })}

  __init4() {this.componentDidUpdate = prevProps => {
    const {
      onUserProblemPublish,
      currentCamDeviceId: camDeviceId,
    } = this.props

    if (prevProps.currentCamDeviceId !== camDeviceId) {
      this.streamManager
        .update({ camDeviceId })
        .then(this.updateStream)
        .catch(onUserProblemPublish)
    }
  }}

  __init5() {this.componentDidMount = () => {
    const {
      onUserProblemPublish,
      currentCamDeviceId: camDeviceId,
    } = this.props

    this.streamManager = new (0, _videoCall3.StreamManager)({ camDeviceId, useCam: true })
    this.streamManager.update()
      .then(this.updateStream)
      .catch(onUserProblemPublish)
  }}

  __init6() {this.componentWillUnmount = () => this.streamManager.stop()}

  render () {
    const {
      onCycleCam,
      isCamCycleAvailable,
      areCamIssuesPresent,
      gotoNextStep,
    } = this.props
    const { stream } = this.state

    return (
      _react2.default.createElement(_Camera2.default, {
        videoStream: stream,
        onCycleCam: onCycleCam,
        isCamCycleAvailable: isCamCycleAvailable,
        areCamIssuesPresent: areCamIssuesPresent,
        gotoNextStep: gotoNextStep, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      )
    )
  }
}

const mapState = state => ({
  currentCamDeviceId: _videoCall.selectCurrentCamDeviceId.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(CameraSetupContainer)
