"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

const initialState = {
  currentUser: null,
  deviceKey: null,
  loginSession: null,
  url: null,
}

 function loginCookiesReducer (state = initialState, action, globalState) {
  switch (action.type) {
    case 'SET_LOGIN_COOKIES': {
      const deviceKeyCookieMatches = action.setCookieHeader.match(/device-key=([^(;|,)]+)/)
      const deviceKeyCookie = deviceKeyCookieMatches != null ? deviceKeyCookieMatches[1] : globalState.loginCookies.deviceKey

      const currentUserCookieMatches = action.setCookieHeader.match(/current-user=([^(;|,)]+)/)
      const currentUserCookie = currentUserCookieMatches != null ? currentUserCookieMatches[1] : globalState.loginCookies.currentUser

      const loginSessionCookieMatches = action.setCookieHeader.match(/login-session=([^(;|,)]+)/)
      const loginSessionCookie = loginSessionCookieMatches != null ? loginSessionCookieMatches[1] : globalState.loginCookies.loginSession

      const url = action.url.match(/http(s?):\/\/[^/]+/)[0]

      return {
        ...state,
        currentUser: currentUserCookie,
        deviceKey: deviceKeyCookie,
        loginSession: loginSessionCookie,
        url: url,
      }
    }
    case 'CLEAR_LOGIN_COOKIES':
    case _actionCreators2.default.logoutAction.type:
    case 'SESSION_EXPIRED':
      return {
        ...state,
        currentUser: null,
        loginSession: null,
      }
    case 'CLEAR_DEVICE_COOKIE':
      return {
        ...state,
        deviceKey: null,
      }
  }

  return state
} exports.default = loginCookiesReducer;
