"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoCall/IncompatibleBrowser.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');


var _videoRoom = require('web/selectors/videoRoom');

var _VideoCall = require('web/components/VideoCall');
var _RoomHeader = require('web/components/VideoCall/RoomHeader'); var _RoomHeader2 = _interopRequireDefault(_RoomHeader);

class IncompatibleBrowserScreen extends _react2.default.Component {constructor(...args) { super(...args); IncompatibleBrowserScreen.prototype.__init.call(this); }
  __init() {this.handleOpenMobileMoreOptionsModal = () =>
    this.props.openMobileMoreOptionsModal({ isSettingsLinkEnabled: false })}

  render () {
    const {
      roomName, roomLink,
      onRoomLinkCopy,
    } = this.props

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_RoomHeader2.default, {
          roomName: roomName,
          roomLink: roomLink,
          onRoomLinkCopy: onRoomLinkCopy,
          openMobileMoreOptionsModal: this.handleOpenMobileMoreOptionsModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        )
        , _react2.default.createElement(_VideoCall.IncompatibleBrowser, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
      )
    )
  }
}

const mapState = state => ({
  roomName: _videoRoom.selectCurrentVideoRoomName.call(void 0, state),
  roomLink: _videoRoom.selectCurrentVideoRoomLink.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(IncompatibleBrowserScreen)
