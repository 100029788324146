"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');


const NEW_MESSAGE_MARKER_OFFSET_PERCENT = 0.3








const useInitialScroll = ({
  getContainerDOM,
  hasMessages,
  relevantMessageKey,
  scrollToMessage,
}) => {
  const [hasSettledScroll, setHasSettledScroll] = _react.useState.call(void 0, false)

  _react.useEffect.call(void 0, 
    () => {
      if (
        hasSettledScroll ||
        !hasMessages ||
        !getContainerDOM() ||
        !relevantMessageKey
      ) return

      scrollToMessage(
        relevantMessageKey,
        { offsetPercent: NEW_MESSAGE_MARKER_OFFSET_PERCENT },
      )
        .then(() => setHasSettledScroll(true))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [hasMessages, relevantMessageKey, scrollToMessage],
  )

  return hasSettledScroll
}

exports. default = useInitialScroll
