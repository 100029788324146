"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoMeeting/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactrouterdom = require('react-router-dom');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _videoRoom = require('web/actions/videoRoom');
var _reactredux = require('react-redux');
var _VideoCall = require('web/screens/VideoCall'); var _VideoCall2 = _interopRequireDefault(_VideoCall);
var _VideoRooms = require('web/screens/VideoRooms'); var _VideoRooms2 = _interopRequireDefault(_VideoRooms);
var _VideoCallOverlay = require('web/screens/VideoMeeting/VideoCallOverlay'); var _VideoCallOverlay2 = _interopRequireDefault(_VideoCallOverlay);
var _RoomModalsScreen = require('web/screens/VideoMeeting/RoomModalsScreen'); var _RoomModalsScreen2 = _interopRequireDefault(_RoomModalsScreen);
var _videoCall = require('web/selectors/videoCall');




var _videoRoom3 = require('web/selectors/videoRoom');
var _ui = require('web/services/ui');





var _videoCall3 = require('web/services/videoCall');
var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);

class VideoMeeting extends _react2.default.Component {
  constructor (props) {
    super(props);VideoMeeting.prototype.__init.call(this);VideoMeeting.prototype.__init2.call(this);VideoMeeting.prototype.__init3.call(this);VideoMeeting.prototype.__init4.call(this);VideoMeeting.prototype.__init5.call(this);VideoMeeting.prototype.__init6.call(this);VideoMeeting.prototype.__init7.call(this);VideoMeeting.prototype.__init8.call(this);VideoMeeting.prototype.__init9.call(this);VideoMeeting.prototype.__init10.call(this);VideoMeeting.prototype.__init11.call(this);VideoMeeting.prototype.__init12.call(this);VideoMeeting.prototype.__init13.call(this);VideoMeeting.prototype.__init14.call(this);VideoMeeting.prototype.__init15.call(this);VideoMeeting.prototype.__init16.call(this);VideoMeeting.prototype.__init17.call(this);VideoMeeting.prototype.__init18.call(this);VideoMeeting.prototype.__init19.call(this);VideoMeeting.prototype.__init20.call(this);VideoMeeting.prototype.__init21.call(this);VideoMeeting.prototype.__init22.call(this);VideoMeeting.prototype.__init23.call(this);VideoMeeting.prototype.__init24.call(this);VideoMeeting.prototype.__init25.call(this);VideoMeeting.prototype.__init26.call(this);VideoMeeting.prototype.__init27.call(this);
    this.state = {
      isMobileMoreOptionsModalOpen: false,
      isMoreOptionsSettingsEnabled: false,
      isMoreOptionsNetstatsEnabled: false,
      isSettingsModalOpen: false,
      isNetstatsModalOpen: false,
      isJoinCallModalOpen: false,
      isFeedbackRatingModalOpen: false,

      isVideoOverlayMinimized: false,
    }

    // Switching between V1/V2 clients before initial render.
    _actions2.default.setVideoRoomWSHandlers(props.isLoggedIn)
  }

  __init() {this.handleVideoOverlayMinimization = isVideoOverlayMinimized =>
    this.setState({ isVideoOverlayMinimized })}

  __init2() {this.openMobileMoreOptionsModal = ({ isSettingsLinkEnabled = true, isNetstatsLinkEnabled = false } = {}) =>
    this.setState({
      isMoreOptionsSettingsEnabled: isSettingsLinkEnabled,
      isMoreOptionsNetstatsEnabled: isNetstatsLinkEnabled,
      isMobileMoreOptionsModalOpen: true,
    })}

  __init3() {this.closeMobileMoreOptionsModal = () => this.setState({ isMobileMoreOptionsModalOpen: false })}
  __init4() {this.openSettingsModal = cb => {
    this.settingsModalContext.cb = cb
    this.setState({ isSettingsModalOpen: true })
  }}

  __init5() {this.closeSettingsModal = () => this.setState({ isSettingsModalOpen: false })}
  __init6() {this.openNetstatsModal = () => this.setState({ isNetstatsModalOpen: true })}
  __init7() {this.closeNetstatsModal = () => this.setState({ isNetstatsModalOpen: false })}
  __init8() {this.openFeedbackRatingModal = () => this.setState({ isFeedbackRatingModalOpen: true })}
  __init9() {this.closeFeedbackRatingModal = () => this.setState({ isFeedbackRatingModalOpen: false })}

  __init10() {this.showCallOverlay = () => _actions2.default.setIsOnCall(true)}
  __init11() {this.hideCallOverlay = () => _actions2.default.setIsOnCall(false)}

  __init12() {this.handleGracefulCallExit = () => {
    this.hideCallOverlay()
    this.openFeedbackRatingModal()
  }}

  __init13() {this.handleForcefulCallExit = () => this.hideCallOverlay()}

  __init14() {this.handleRoomLinkCopy = () => _ui.copyToClipboard.call(void 0, this.props.roomLink)
    .then(() => this.props.showNoticeBar({
      type: 'success', text: 'Link copied to clipboard.',
    }))
    .catch(e => {
      log.error(e)
      this.props.showNoticeBar({
        type: 'warning', text: 'Failed to copy link.',
      })
    })}

  __init15() {this.handleRoomShare = () => _ui.nativeShare.call(void 0, {
    url: this.props.roomLink,
    title: 'Call invitation',
    text: 'Join the call',
  }).catch(this.handleRoomLinkCopy)}

  __init16() {this.componentDidMount = () => {
    _videoCall3.refreshDevicesList.call(void 0, )
    _videoCall3.bindDevicesListListener.call(void 0, )
    _bus2.default.on(
      _videoRoom.types.VIDEO_ROOM_USER_PROBLEM_ENCOUNTERED,
      this.handleUserProblemEncountered,
    )
  }}

  __init17() {this.componentWillUnmount = () => {
    _actions2.default.unsetVideoRoomWSHandlers(this.props.isLoggedIn)
    _videoCall3.unbindDevicesListListener.call(void 0, )
    _bus2.default.off(
      _videoRoom.types.VIDEO_ROOM_USER_PROBLEM_ENCOUNTERED,
      this.handleUserProblemEncountered,
    )
  }}

  __init18() {this.twoMinutesInMs = 2 * 60 * 1000}
  __init19() {this.displayedNetworkProblems = { }}
  __init20() {this.handleUserProblemEncountered = ({ payload }) => {
    const { resolveVideoRoomUser } = this.props
    const { RoomUserKey, RoomKey, ProblemCode, ProblemReportedAt } = payload

    if (!ProblemCode) return
    const user = resolveVideoRoomUser(RoomUserKey, RoomKey) || {}

    // Don't display a network problem from a single
    // user more often than once every 2 minutes.
    if (ProblemCode === _videoCall3.problemCodes.NETWORK) {
      if (ProblemReportedAt - this.displayedNetworkProblems[RoomKey + RoomUserKey] < this.twoMinutesInMs) { return }
      this.displayedNetworkProblems[RoomKey + RoomUserKey] = Date.now()
    }

    this.displayUserProblem({
      userName: user.RoomUserName,
      problemCode: ProblemCode,
    })
  }}

  __init21() {this.displayUserProblem = ({ userName, problemCode }) =>
    this.props.showNoticeBar({
      type: 'warning',
      text: _videoCall3.formatDisplayErrorMessage.call(void 0, userName, problemCode),
    })}

  __init22() {this.renderVideoCall = ({ match }) => (
    _react2.default.createElement(_VideoCall2.default, {
      roomKey: match.params.roomKey,
      onRoomShare: this.handleRoomShare,
      onRoomLinkCopy: this.handleRoomLinkCopy,
      openMobileMoreOptionsModal: this.openMobileMoreOptionsModal,
      openSettingsModal: this.openSettingsModal,
      isNetstatsModalOpen: this.state.isNetstatsModalOpen,
      openNetstatsModal: this.openNetstatsModal,
      closeNetstatsModal: this.closeNetstatsModal,
      showNoticeBar: this.props.showNoticeBar,
      onCallJoinFailed: this.handleCallJoinFailed, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
    )
  )}

  __init23() {this.gotoRoomsList = () =>
    this.props.history.push('/vd/')}

  __init24() {this.gotoRoom = roomId => {
    _actions2.default.setCurrentVideoRoomKey(roomId)
    this.props.history.push(`/vd/${roomId}`)
  }}

  __init25() {this.gotoMeeting = () => {
    const { isOnCall, openJoinCallModal } = this.props
    if (!isOnCall) return openJoinCallModal()

    if (this.state.isVideoOverlayMinimized) { return this.handleVideoOverlayMinimization(false) }
    log.error("User attempted to join a video call during another call and their feed wasn't minimized o_O.")
  }}

  __init26() {this.renderMeetingRoom = ({ match }) => (
    _react2.default.createElement(_VideoRooms2.default, {
      roomKey: match.params.roomKey,
      gotoMeeting: this.gotoMeeting,
      gotoRoom: this.gotoRoom,
      gotoRoomsList: this.gotoRoomsList,
      onRoomShare: this.handleRoomShare,
      onRoomLinkCopy: this.handleRoomLinkCopy,
      showNoticeBar: this.props.showNoticeBar,
      isMobileNewRoomModalOpen: this.props.isMobileNewRoomModalOpen,
      isDesktopNewRoomModalOpen: this.props.isDesktopNewRoomModalOpen,
      openMobileNewRoomModal: this.props.openMobileNewRoomModal,
      openDesktopNewRoomModal: this.props.openDesktopNewRoomModal,
      closeNewRoomModal: this.props.closeNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}
    )
  )}

  __init27() {this.settingsModalContext = {}}

  render () {
    const {
      isLoggedIn, isOnCall,
      showNoticeBar,
      isJoinCallModalOpen,
      openJoinCallModal,
      closeJoinCallModal,
      isMobileNewRoomModalOpen,
      isDesktopNewRoomModalOpen,
      closeNewRoomModal,
    } = this.props
    const {
      isMobileMoreOptionsModalOpen,
      isMoreOptionsSettingsEnabled,
      isMoreOptionsNetstatsEnabled,
      isSettingsModalOpen,
      isNetstatsModalOpen,
      isFeedbackRatingModalOpen,
      isVideoOverlayMinimized,
    } = this.state

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        ,  isOnCall &&
          _react2.default.createElement(_VideoCallOverlay2.default, {
            showNoticeBar: showNoticeBar,
            openMobileMoreOptionsModal: this.openMobileMoreOptionsModal,
            openSettingsModal: this.openSettingsModal,
            isNetstatsModalOpen: isNetstatsModalOpen,
            openNetstatsModal: this.openNetstatsModal,
            closeNetstatsModal: this.closeNetstatsModal,
            onRoomLinkCopy: this.handleRoomLinkCopy,
            onRoomShare: this.handleRoomShare,
            onGracefulExit: this.handleGracefulCallExit,
            onForcefulExit: this.handleForcefulCallExit,
            isMinimizable: isLoggedIn,
            isMinimized: isVideoOverlayMinimized,
            handleMinimization: this.handleVideoOverlayMinimization,
            gotoFeedback: this.openFeedbackRatingModal,
            onCallJoinFailed: this.handleCallJoinFailed, __self: this, __source: {fileName: _jsxFileName, lineNumber: 214}}
          )
        
        , _react2.default.createElement(_RoomModalsScreen2.default, {
          onRoomShare: this.handleRoomShare,
          onRoomLinkCopy: this.handleRoomLinkCopy,
          isMobileMoreOptionsModalOpen: isMobileMoreOptionsModalOpen,
          isMoreOptionsSettingsEnabled: isMoreOptionsSettingsEnabled,
          isMoreOptionsNetstatsEnabled: isMoreOptionsNetstatsEnabled,
          closeMobileMoreOptionsModal: this.closeMobileMoreOptionsModal,

          isSettingsModalOpen: isSettingsModalOpen,
          settingsModalContext: this.settingsModalContext,
          openSettingsModal: this.openSettingsModal,
          closeSettingsModal: this.closeSettingsModal,

          openNetstatsModal: this.openNetstatsModal,

          isJoinCallModalOpen: isJoinCallModalOpen,
          openJoinCallModal: openJoinCallModal,
          closeJoinCallModal: closeJoinCallModal,
          gotoMeeting: this.showCallOverlay,

          isMobileNewRoomModalOpen: isMobileNewRoomModalOpen,
          isDesktopNewRoomModalOpen: isDesktopNewRoomModalOpen,
          closeNewRoomModal: closeNewRoomModal,

          isFeedbackRatingModalOpen: isFeedbackRatingModalOpen,
          closeFeedbackRatingModal: this.closeFeedbackRatingModal,

          showNoticeBar: showNoticeBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 232}}
        )
        , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 261}}
          , _react2.default.createElement(_reactrouterdom.Route, {
            path: "/vd/:roomKey?",
            render: isLoggedIn
              ? this.renderMeetingRoom
              : this.renderVideoCall
            , __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}}
          )
        )
      )
    )
  }

  static __initStatic() {this.defaultProps = { isLoggedIn: false }}
  static __initStatic2() {this.propTypes = { isLoggedIn: _proptypes2.default.bool }}
} VideoMeeting.__initStatic(); VideoMeeting.__initStatic2();

const mapState = state => ({
  roomKey: _videoRoom3.selectCurrentVideoRoomKey.call(void 0, state),
  roomLink: _videoRoom3.selectCurrentVideoRoomLink.call(void 0, state),
  isOnCall: _videoCall.selectIsOnCall.call(void 0, state),
  resolveVideoRoomUser: (roomUserKey, roomKey) =>
    _videoRoom3.selectVideoRoomUser.call(void 0, roomUserKey, roomKey, state),
})

exports. default = _reactrouterdom.withRouter.call(void 0, _reactredux.connect.call(void 0, mapState)(VideoMeeting))
