"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');


var _chat = require('shared/enum/chat');






 const useAddParticipantsQuotas = ({
  conversationType,
  currentPatientsCount,
}) => _react.useMemo.call(void 0, 
  () => {
    const limits = {
      [_chat.CHAT_TYPE.REGULAR_PATIENT]: +!currentPatientsCount,
      [_chat.CHAT_TYPE.REGULAR]: 0,
      [_chat.CHAT_TYPE.SECURE_PATIENT]: Infinity,
    }
    return {
      maxPatientsCount: conversationType ? limits[conversationType] : 0,
    }
  },
  [currentPatientsCount, conversationType],
); exports.useAddParticipantsQuotas = useAddParticipantsQuotas
