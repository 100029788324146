"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');

var _bus = require('shared/services/bus'); var _bus2 = _interopRequireDefault(_bus);

const useBus = (
  relevantActionTypes,
  listener,
) => {
  _react.useEffect.call(void 0, 
    () => {
      relevantActionTypes.forEach(t => _bus2.default.on(t, listener))
      return () => relevantActionTypes.forEach(t => _bus2.default.off(t, listener))
    },
    [relevantActionTypes, listener],
  )
}

exports. default = useBus
