"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');

var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _array = require('shared/services/array');


















const useUpdateTeam = (teamKey) => _react.useCallback.call(void 0, 
  async (data) => {
    const teamHours = _optionalChain([data, 'optionalAccess', _ => _.autoReplyTeamHours, 'optionalAccess', _2 => _2.map, 'call', _3 => _3(th => (
      !th.availableFrom || !th.availableUntil
        ? null
        : {
            DayOfWeek: th.dayOfWeek,
            AvailableFrom: th.availableFrom,
            AvailableUntil: th.availableUntil,
          }
    ))
, 'access', _4 => _4.filter, 'call', _5 => _5(_array.truthy)])

    return _actions2.default.updateTeam({
      TeamKey: teamKey,
      TeamName: data.name,
      AutoReply: data.isAutoReplyEnabled,
      AutoReplyTimeZone: _optionalChain([data, 'optionalAccess', _6 => _6.autoReplyTimezone]) ,
      AutoReplyMessage: data.autoReplyMessage,
      TeamHours: teamHours,
      BusyReply: data.isBusyReplyEnabled,
      BusyReplyMessage: data.BusyReplyMessage,
    })
  },
  [teamKey],
)

exports. default = useUpdateTeam
