"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/ParticipantsBoard/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _Participant = require('web/screens/VideoCall/Participant'); var _Participant2 = _interopRequireDefault(_Participant);

 class ParticipantsBoard extends _react2.default.Component {constructor(...args) { super(...args); ParticipantsBoard.prototype.__init.call(this);ParticipantsBoard.prototype.__init2.call(this);ParticipantsBoard.prototype.__init3.call(this);ParticipantsBoard.prototype.__init4.call(this);ParticipantsBoard.prototype.__init5.call(this);ParticipantsBoard.prototype.__init6.call(this);ParticipantsBoard.prototype.__init7.call(this); }
  __init() {this.state = { isParticipantsListOpen: true }}

  __init2() {this.toggleParticipantsList = () => this.setState({
    isParticipantsListOpen: !this.state.isParticipantsListOpen,
  })}

  __init3() {this.rootRef = null}
  __init4() {this.touchHandler = e => e.stopPropagation()}
  __init5() {this.setRootRef = r => this.rootRef = r}
  __init6() {this.componentDidMount = () =>
    this.props.ignoredUIEvents.forEach(e =>
      this.rootRef.addEventListener(e, this.touchHandler),
    )}

  __init7() {this.componentWillUnmount = () =>
    this.props.ignoredUIEvents.forEach(e =>
      this.rootRef.removeEventListener(e, this.touchHandler),
    )}

  render () {
    const {
      isHidden,
      roomKey, activeParticipantId,
      participants, onParticipantPick,
    } = this.props

    const { isParticipantsListOpen } = this.state

    const participantsList = Object.values(participants)

    const rootClassName = _classBuilder2.default.call(void 0, 
      "video-call--participants-board",
      { hidden: !isParticipantsListOpen },
      { "mobile-hidden": isHidden },
    )
    const listClassName = _classBuilder2.default.call(void 0, 
      "meeting--participants--list",
      { hidden: !isParticipantsListOpen },
    )
    const listToggleClassName = _classBuilder2.default.call(void 0, 
      "meeting--participants--more-toggle",
      { hidden: !isParticipantsListOpen },
    )

    const participantsCountLabel = `${participantsList.length} Participants`

    return (
      _react2.default.createElement('div', { className: rootClassName, ref: this.setRootRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        , _react2.default.createElement('div', { className: listClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
          , _react2.default.createElement('div', { className: "meeting--participants--list-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            ,  participantsList.map(p =>
                _react2.default.createElement(_Participant2.default, {
                  key: p.id,
                  roomKey: roomKey,
                  roomUserKey: p.RoomUserKey,
                  participantKey: p.id,
                  name: p.name,
                  isScreenParticipant: p.isScreenParticipant,
                  isActive: p.id === activeParticipantId,
                  isMe: p.local,
                  isLoading: p.isLoading,
                  onPick: onParticipantPick,
                  audioTrack: p.audioTrack,
                  videoTrack: p.videoTrack, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
                ),
            )
            
          )
        )
        , _react2.default.createElement('div', {
          className: listToggleClassName,
          onClick: this.toggleParticipantsList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
        
          , _react2.default.createElement('div', { className: "meeting--participants--more-toggle--inner inner--open" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
            , _react2.default.createElement('span', { className: "meeting--participants--more-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
              ,  participantsCountLabel 
            )
            , _react2.default.createElement('div', { className: "meeting--participants--more-spacer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}} )
            , _react2.default.createElement('div', { className: "meeting--participants--more-arrow", __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}} )
          )
          , _react2.default.createElement('div', { className: "meeting--participants--more-toggle--inner inner--collapsed" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
            , _react2.default.createElement('div', { className: "meeting--participants--more-arrow", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}} )
            , _react2.default.createElement('div', { className: "meeting--participants--more-spacer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}} )
            , _react2.default.createElement('div', { className: "meeting--participants--more-icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}} )
            , _react2.default.createElement('span', { className: "meeting--participants--more-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}, "Show ("
               ,  participantsList.length , ")"
            )
          )
        )
      )
    )
  }

  static __initStatic() {this.defaultProps = {
    ignoredUIEvents: [],
  }}

  static __initStatic2() {this.propTypes = {
    isHidden: _proptypes2.default.bool.isRequired,
    ignoredUIEvents: _proptypes2.default.array,
    roomKey: _proptypes2.default.string.isRequired,
    participants: _proptypes2.default.object.isRequired,
    onParticipantPick: _proptypes2.default.func.isRequired,
  }}
} ParticipantsBoard.__initStatic(); ParticipantsBoard.__initStatic2(); exports.default = ParticipantsBoard;
