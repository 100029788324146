"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');







 const useExcludedEntities = ({
  selectedEntitiesMap,
  senderEntity,
}) => _react.useMemo.call(void 0, 
  () => ({
    ...selectedEntitiesMap,
    ...(_optionalChain([senderEntity, 'optionalAccess', _ => _.entityKey])
      ? { [_optionalChain([senderEntity, 'optionalAccess', _2 => _2.entityKey])]: senderEntity }
      : null
    ),
  }),
  [selectedEntitiesMap, senderEntity],
); exports.useExcludedEntities = useExcludedEntities
