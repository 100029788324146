"use strict";Object.defineProperty(exports, "__esModule", {value: true});









var BlobjectFilter; (function (BlobjectFilter) {
  const COMPLETED = "COMPLETED"; BlobjectFilter["COMPLETED"] = COMPLETED;
  const ACTIVE = "ACTIVE"; BlobjectFilter["ACTIVE"] = ACTIVE;
})(BlobjectFilter || (exports.BlobjectFilter = BlobjectFilter = {}));
