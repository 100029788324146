"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _uuid = require('shared/services/uuid');
var _string = require('shared/services/string');

const initialState = {}

let message

const combineOldAndNewMessages = (oldMessages, newMessages, ck) => {
  const messageDedupeMap = {}

  if (oldMessages[ck]) {
    oldMessages[ck].forEach((m) => messageDedupeMap[m.MessageKey] = m)
  }
  if (newMessages) {
    newMessages.forEach((m) => messageDedupeMap[m.MessageKey] = m)
  }

  const uniqueMessages = Object.values(messageDedupeMap)

  oldMessages[ck] = uniqueMessages.sort((a, b) => _string.compareStr.call(void 0, b.SentTimestamp, a.SentTimestamp))
  return oldMessages
}

 function patientConversationMessagesReducer (state = initialState, action, globalState) {
  switch (action.type) {
    /* patient-ui */
    case _actionCreators2.default.loadPatientConversationByCodeActions.SUCCESS.type:
    case _actionCreators2.default.getPatientConversationMessagesActions.SUCCESS.type:
      return combineOldAndNewMessages(
        state,
        action.payload.PatientConversationMessages,
        Object.keys(action.payload.PatientConversations)[0],
      )

    case _actionCreators2.default.sendPatientConversationMessageActions.REQUEST.type: {
      const { Text, ConversationKey, PatientKey } = action.payload
      if (!state[ConversationKey]) return state

      message = {
        TextContent: Text,
        SentTimestamp: Date.now(),
        MessageKey: `temp-${_uuid.uuidv4.call(void 0, )}`,
        LocalKey: action.meta.lid,
      }

      if (globalState.currentUser.UserKey) message.FromUserKey = globalState.currentUser.UserKey
      else if (PatientKey) message.FromPatientKey = PatientKey

      return {
        ...state,
        [ConversationKey]: [
          message,
          ...state[ConversationKey],
        ],
      }
    }
    case _actionCreators2.default.sendPatientConversationFileActions.SUCCESS.type:
    case _actionCreators2.default.sendPatientConversationMessageActions.SUCCESS.type: {
      const message = action.payload.Message
      const conversationKey = message.ConversationKey
      const localKey = action.meta.lid
      const currentMessageIndex = state[conversationKey].findIndex(m => m.LocalKey === localKey)

      const newMessages = [...state[conversationKey]]
      newMessages[currentMessageIndex] = message

      return {
        ...state,
        [message.ConversationKey]: newMessages,
      }
    }
    case _actionCreators2.default.incomingPatientMessageNotificationAction.type: {
      const newMessage = action.payload
      const messages = state[newMessage.ConversationKey] || []
      const sentTime = newMessage.SentTimestamp
      let insertPos = 0
      if (messages != null) {
        while (messages[insertPos] && messages[insertPos].SentTimestamp > sentTime) {
          insertPos++
        }
      }

      /* Prevent double insert */
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].MessageKey === newMessage.MessageKey) {
          return state
        }
      }

      messages.splice(insertPos, 0, newMessage)
      const newState = {
        ...state,
        [newMessage.ConversationKey]: messages,
      }

      return newState
    }
    case _actionCreators2.default.deletedPatientMessageNotificationAction.type: {
      const deletedMessage = action.payload
      if (!(deletedMessage.ConversationKey in state)) {
        return state
      }
      return {
        ...state,
        [deletedMessage.ConversationKey]: state[deletedMessage.ConversationKey].filter(msg => msg.MessageKey !== deletedMessage.MessageKey),
      }
    }
    case _actionCreators2.default.uploadPatientFileActions.REQUEST.type: {
      const { ConversationKey, PatientKey } = action.payload

      const FileInfo = {
        LocalMetaData: {
          ...action.payload.file,
        },
      }
      const newMessage = {
        ConversationKey,
        MessageKey: `temp-${action.meta.lid}`,
        LocalKey: action.meta.lid,
        SentTimestamp: Date.now(),
        FileInfo,
      }

      if (globalState.currentUser.UserKey) {
        newMessage.FromUserKey = globalState.currentUser.UserKey
      } else if (PatientKey) {
        newMessage.FromPatientKey = PatientKey
      }

      const newMessages = state[ConversationKey] ? [newMessage, ...state[ConversationKey]] : [newMessage]
      return {
        ...state,
        [ConversationKey]: newMessages,
      }
    }
    case _actionCreators2.default.uploadPatientFileActions.FAILURE.type: {
      const ConversationKey = action.meta.ConversationKey
      const localKey = action.meta.lid

      return {
        ...state,
        [ConversationKey]: state[ConversationKey].map(msg => msg.LocalKey === localKey
          ? {
              ...msg,
              FileInfo: {
                ...msg.FileInfo,
                LocalMetaData: {
                  ...msg.FileInfo.LocalMetaData,
                  hasUploadFailed: true,
                },
              },
            }
          : msg,
        ),
      }
    }
    case _actionCreators2.default.sendPatientConversationFileActions.REQUEST.type: {
      const conversationKey = action.payload.ConversationKey
      const localKey = action.meta.lid
      const Metadata = action.meta.Metadata
      const currentMessageIndex = state[conversationKey].findIndex(m => m.LocalKey === localKey)

      const newMessages = state[conversationKey]
      newMessages[currentMessageIndex].FileInfo.Metadata = Metadata

      return {
        ...state,
        [conversationKey]: newMessages,
      }
    }
    case _actionCreators2.default.sendPatientConversationFileActions.FAILURE: {
      const { ConversationKey } = action.payload
      const { lid } = action.meta

      return {
        ...state,
        [ConversationKey]: state[ConversationKey].map(msg => msg.LocalKey === lid
          ? {
              ...msg,
              FileInfo: {
                ...msg.FileInfo,
                LocalMetaData: {
                  ...msg.FileInfo.LocalMetaData,
                  hasUploadFailed: true,
                },
              },
            }
          : msg,
        ),
      }
    }
  }

  return state
} exports.default = patientConversationMessagesReducer;
