"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _notifications = require('shared/services/notifications');
var _log = require('shared/log');

const effectPaths = {
  askToJoin: 'ask_to_join.mp3',
  connecting: 'connecting.mp3',
  participantJoining: 'participant_joining.mp3',
  participantLeaving: 'participant_leaving.mp3',
  startScreenSharing: 'start_screen_sharing.mp3',
  soundCheck: 'testing_audio.mp3',
  fallback: 'testing_audio.mp3',
}

const resolvePath = fileName => _notifications.webSoundFileLocation.call(void 0, 
  `video_call/${fileName || effectPaths.fallback}`,
)

const playSoundEffect = fileName => {
  const audio = new Audio(resolvePath(fileName))
  const played = audio.play()
  if (played !== undefined) {
    played.catch(e => _log.log.info("Couldn't play audio", fileName, e))
  }
}

 const playAskToJoin = () => playSoundEffect(effectPaths.askToJoin); exports.playAskToJoin = playAskToJoin
 const playConnecting = () => playSoundEffect(effectPaths.connecting); exports.playConnecting = playConnecting
 const playParticipantJoining = () => playSoundEffect(effectPaths.participantJoining); exports.playParticipantJoining = playParticipantJoining
 const playParticipantLeaving = () => playSoundEffect(effectPaths.participantLeaving); exports.playParticipantLeaving = playParticipantLeaving
 const playStartScreenSharing = () => playSoundEffect(effectPaths.startScreenSharing); exports.playStartScreenSharing = playStartScreenSharing
 const playSoundCheck = () => playSoundEffect(effectPaths.soundCheck); exports.playSoundCheck = playSoundCheck
