"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _store = require('shared/store');
var _uuid = require('shared/services/uuid');

 const types = {
  PUSH_INTERACTABLES_QUEUE: 'PUSH_INTERACTABLES_QUEUE',
  DISMISS_INTERACTABLE_BY_ID: 'DISMISS_INTERACTABLE_BY_ID',
  SET_INTERACTABLE_PERMISSION: 'SET_INTERACTABLE_PERMISSION',
}; exports.types = types

 const firstTimeTooltipIds = {
  FIRST_CHAT: 'FIRST_CHAT_TOOLTIP',
  CREATE_NEW_BUTTON: 'CREATE_NEW_BUTTON_TOOLTIP',
  SWITCH_INBOX: 'SWITCH_INBOX_TOOLTIP',
  PROFILE: 'PROFILE_TOOLTIP',
}; exports.firstTimeTooltipIds = firstTimeTooltipIds

exports. default = {
  pushInteractablesQueue: (payload) => {
    const interactables = Array.isArray(payload) ? payload : [payload]
    _store.dispatch.call(void 0, {
      type: exports.types.PUSH_INTERACTABLES_QUEUE,
      payload: interactables.map(x => ({ ...x, id: x.id || _uuid.uuidv4.call(void 0, ) })),
    })
  },
  dismissInteractableById: (id) =>
    _store.dispatch.call(void 0, {
      type: exports.types.DISMISS_INTERACTABLE_BY_ID,
      payload: { id },
    }),
  permitInteractable: (id) =>
    _store.dispatch.call(void 0, {
      type: exports.types.SET_INTERACTABLE_PERMISSION,
      payload: { id, isPermitted: true },
    }),
}
