"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/TimeInput.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _rctimepicker = require('rc-time-picker'); var _rctimepicker2 = _interopRequireDefault(_rctimepicker);

var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);













const TimeInput = ({
  isOpen,
  onOpen,
  onClose,
  defaultValue = _moment2.default.call(void 0, ).hour(0).minute(0).second(0),
  value,
  onChange,
  disabled,
  className,
  isRequired,
}) => (
  _react2.default.createElement(_rctimepicker2.default, {
    use12Hours: true,
    inputReadOnly: true,
    allowEmpty: !isRequired,
    disabled: disabled,
    format: "h:mm A" ,
    defaultValue: defaultValue,
    showSecond: false,
    minuteStep: 15,
    value: value,
    placeholder: "None",
    onChange: onChange,
    open: isOpen,
    onOpen: onOpen,
    onClose: onClose,
    className: _classBuilder2.default.call(void 0, className, 'blm-time-input', disabled && 'blm-time-input--disabled'),
    popupClassName: "blm-time-input--popup",
    clearIcon: 
      _react2.default.createElement('img', {
        className: "blm-time-input--clear-icon",
        src: "/assets/icons/icon-cross-grey.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
      )
    ,
    inputIcon: 
      _react2.default.createElement('img', {
        className: "blm-time-input--dropdown-icon",
        src: "/assets/icons/dropdown-down-black.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
  )
)

exports. default = TimeInput
