"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _reselect = require('reselect');

var _array = require('shared/services/array');
var _user = require('shared/selectors/user');

 const selectMyInboxableTeamKeys = _reselect.createSelector.call(void 0, 
  _user.selectCurrentUserV2,
  me => _array.safeMapReduce.call(void 0, 
    Object.values(me.Teams),
    t => t.Inboxable ? t.TeamKey : null,
  ),
); exports.selectMyInboxableTeamKeys = selectMyInboxableTeamKeys
