"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Body/MessagesTable/components/Table.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _TableHeader = require('./TableHeader'); var _TableHeader2 = _interopRequireDefault(_TableHeader);
var _Spinner = require('web/components/Spinner');










const Table = ({
  onSetAllRowsSelection,
  areAnyRowsSelected,
  isLoading,
  isFiltered = false,
  hasItems,
  onAddPatients,
  children,
}) => (
  _react2.default.createElement('div', { className: "campaign--table--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
    , _react2.default.createElement('table', { className: "campaign--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
      , _react2.default.createElement(_TableHeader2.default, {
        onSetAllRowsSelection: onSetAllRowsSelection,
        areAnyRowsSelected: areAnyRowsSelected,
        isSticky: isFiltered, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      )
      , _react2.default.createElement('tbody', { className: "campaign--table--body", __self: this, __source: {fileName: _jsxFileName, lineNumber: 31}}
        ,  children 
      )
    )
    ,  !hasItems && (
      _react2.default.createElement('div', { className: "campaign--table--empty", __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
        ,  isLoading
          ? _react2.default.createElement(_Spinner.SimpleSpinner, { className: "campaign--table--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
          : _react2.default.createElement(_react2.default.Fragment, null, "To get started, "

              , _react2.default.createElement('span', { role: "button", onClick: onAddPatients, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}, "add patients" ), "."
            )
        
      )
    )
  )
)

exports. default = Table
