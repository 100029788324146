"use strict";Object.defineProperty(exports, "__esModule", {value: true});var Features; (function (Features) {
  const VIDEO_CHAT = 'VIDEO_CHAT'; Features["VIDEO_CHAT"] = VIDEO_CHAT;
  const PATIENT_MESSAGING = 'PATIENT_MESSAGING'; Features["PATIENT_MESSAGING"] = PATIENT_MESSAGING;
  const ADVANCED_SEARCH = 'ADVANCED_SEARCH'; Features["ADVANCED_SEARCH"] = ADVANCED_SEARCH;
  const TEST_SEARCH_ITERATORS = "TEST_SEARCH_ITERATORS"; Features["TEST_SEARCH_ITERATORS"] = TEST_SEARCH_ITERATORS;
  const BLOOM_VIDEO_V2 = 'BLOOM_VIDEO_V2'; Features["BLOOM_VIDEO_V2"] = BLOOM_VIDEO_V2;
  const CREATE_NEW = 'CREATE_NEW'; Features["CREATE_NEW"] = CREATE_NEW;
})(Features || (Features = {}));

exports. default = Features
