"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Item/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _inbox = require('shared/hooks/inbox');
var _store = require('web/store');
var _time = require('shared/services/time');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);




var _blobject = require('shared/selectors/blobject');
var _conversation = require('shared/selectors/conversation');

var _InboxItemAvatar = require('../../InboxItemAvatar'); var _InboxItemAvatar2 = _interopRequireDefault(_InboxItemAvatar);
var _ActionBtn = require('./ActionBtn'); var _ActionBtn2 = _interopRequireDefault(_ActionBtn);
var _FirstTimeTooltips = require('web/components/FirstTimeTooltips');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const InboxListItem = ({
  hasTooltip,
  blobjectKey,
  activeFilter,
  name,
  description,
  myTeams,
  isUnread,
  isMuted,
  isCompleted: isCompletedByUser,
  isBeingArchived = false,
  onToggleArchived,
  lastUpdated,
  wrappedKey,
  wrappedType,
  draftMessage,
}) => {
  const isCompleted = activeFilter === _inbox.BlobjectFilter.COMPLETED

  const onInboxItemClick = _react.useCallback.call(void 0, 
    () => wrappedType && wrappedKey &&
      _actions2.default.gotoBlobject(wrappedType, wrappedKey, blobjectKey),
    [wrappedKey, wrappedType, blobjectKey],
  )

  const isSelected = _store.history.location.pathname.indexOf(blobjectKey) > -1

  const rootClassName = _classBuilder2.default.call(void 0, 
    "list-item list-item--has-action",
    isCompleted && "archived",
    isSelected && "active",
    isUnread && "list-item--bolded",
  )

  const shouldShowActionBtn = onToggleArchived
    && (isCompleted ? isCompletedByUser || isBeingArchived : true)

  const content = (
    _react2.default.createElement('div', { className: rootClassName, onClick: onInboxItemClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      , _react2.default.createElement(_InboxItemAvatar2.default, {
        bkey: blobjectKey,
        borderColor: isSelected ? "#efeef6" : "#f9f9fc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      )
      , _react2.default.createElement('div', { className: "list-item--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        , _react2.default.createElement('div', { className: "list-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 75}}
          , _react2.default.createElement('span', { className: "list-item--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}},  name )
          , _react2.default.createElement('span', { className: "list-item--timestamp", __self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            ,  _time.getTimeString.call(void 0, lastUpdated) 
          )
        )
        , _react2.default.createElement('div', { className: "list-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
          , _react2.default.createElement('span', { className: "list-item--detail", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
            ,  !isUnread && draftMessage
              ? _react2.default.createElement(_react2.default.Fragment, null
                  , _react2.default.createElement('i', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, "Draft:"), ' '
                  ,  draftMessage 
                )
              : description
            
          )
          , _react2.default.createElement('span', { className: "list-item--icons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
            ,  isMuted && (
              _react2.default.createElement('img', {
                className: "list-item--icons--mute-icon",
                src: "/assets/icons/inbox/mute-icon.svg",
                alt: "Mute", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
              )
            )
            ,  isUnread && _react2.default.createElement('span', { className: "list-item--icons--bolded-circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}) 
          )

        )
        , _react2.default.createElement('div', { className: "list-item--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          ,  myTeams.map(t => (
            _react2.default.createElement('span', { key: t.TeamKey, className: "list-item--team", __self: this, __source: {fileName: _jsxFileName, lineNumber: 105}},  t.Name )
          ))
        )
      )
      ,  shouldShowActionBtn && (
        _react2.default.createElement(_ActionBtn2.default, {
          isCompleted: isCompleted,
          isBeingArchived: isBeingArchived,
          onToggle: () => onToggleArchived(blobjectKey), __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
        )
      )
    )
  )

  return hasTooltip
    ? _react2.default.createElement(_FirstTimeTooltips.WithFirstChatTooltip, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 120}},  content )
    : content
}






const mapState = (state, { blobjectKey }) => {
  const metadata = _blobject.selectBlobjectMetadata.call(void 0, state, blobjectKey)

  return {
    ...metadata,
    draftMessage: _conversation.selectDraftByConversationKey.call(void 0, state, _optionalChain([metadata, 'optionalAccess', _ => _.wrappedKey])),
    myTeams: _blobject.selectMyPopulatedBlobjectTeams.call(void 0, state, blobjectKey),
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(InboxListItem)
