"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/index.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);



var _videoRoom = require('web/selectors/videoRoom');
var _user = require('shared/selectors/user');
var _App = require('web/screens/App');
var _RightSide = require('web/components/RightSide');
var _Header = require('web/components/RightSide/Header');
var _RoomBody = require('./RoomBody'); var _RoomBody2 = _interopRequireDefault(_RoomBody);
var _RoomHeader = require('web/screens/VideoRooms/RoomHeader'); var _RoomHeader2 = _interopRequireDefault(_RoomHeader);
var _List = require('./List'); var _List2 = _interopRequireDefault(_List);
var _EmptyRoom = require('web/components/VideoRooms/Room/EmptyRoom'); var _EmptyRoom2 = _interopRequireDefault(_EmptyRoom);
var _VideoRooms = require('web/components/Upsells/VideoRooms'); var _VideoRooms2 = _interopRequireDefault(_VideoRooms);

class VideoRooms extends _react2.default.PureComponent {constructor(...args) { super(...args); VideoRooms.prototype.__init.call(this);VideoRooms.prototype.__init2.call(this);VideoRooms.prototype.__init3.call(this);VideoRooms.prototype.__init4.call(this);VideoRooms.prototype.__init5.call(this);VideoRooms.prototype.__init6.call(this); }
  __init() {this.state = { isUpsellsModalOpen: false }}
  __init2() {this.openUpsellsModal = () => this.setState({ isUpsellsModalOpen: true })}
  __init3() {this.closeUpsellsModal = () => this.setState({ isUpsellsModalOpen: false })}

  __init4() {this.componentDidMount = () => {
    const { roomKey, isRoomLoaded } = this.props
    if (!roomKey) return

    _actions2.default.setCurrentVideoRoomKey(roomKey)
    if (!isRoomLoaded) _actions2.default.fetchVideoRoom(roomKey)
  }}

  __init5() {this.renderRoomBody = () => {
    const {
      onRoomShare, onRoomLinkCopy,
      gotoMeeting,
      gotoRoomsList,
      roomKey,
      showNoticeBar,
    } = this.props

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_Header.RightSideHeader, { className: "meeting-rooms--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          , _react2.default.createElement(_RoomHeader2.default, {
            roomKey: roomKey,
            onBack: gotoRoomsList,
            onRoomShare: onRoomShare,
            showNoticeBar: showNoticeBar,
            onRoomLinkCopy: onRoomLinkCopy,
            gotoMeeting: gotoMeeting, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          )
        )
        , _react2.default.createElement(_RightSide.RightSideBody, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          , _react2.default.createElement(_RoomBody2.default, {
            key: roomKey,
            roomKey: roomKey,
            onRoomShare: onRoomShare,
            onRoomLinkCopy: onRoomLinkCopy,
            showNoticeBar: showNoticeBar, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
          )
        )
      )
    )
  }}

  __init6() {this.renderEmptyRoom = () => (
    _react2.default.createElement(_RightSide.RightSideBody, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      , _react2.default.createElement(_EmptyRoom2.default, {
        areRoomsAvailable: this.props.areAnyRoomsListed,
        openDesktopNewRoomModal: this.props.openDesktopNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
      )
    )
  )}

  render () {
    const {
      roomKey,
      openMobileNewRoomModal, openDesktopNewRoomModal,
      gotoRoom, isRoomLoaded,
      hasVideoFeature,
    } = this.props
    const isInRoom = !!roomKey && isRoomLoaded

    return (
      _react2.default.createElement(_App.AppVertical, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}
        , _react2.default.createElement(_App.NavigationMenu, { className: "meeting-rooms--list-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
          , _react2.default.createElement(_List2.default, {
            hasVideoFeature: hasVideoFeature,
            onRequestVideoFeature: this.openUpsellsModal,
            gotoRoom: gotoRoom,
            currentRoomKey: roomKey,
            openMobileNewRoomModal: openMobileNewRoomModal,
            openDesktopNewRoomModal: openDesktopNewRoomModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
          )
        )
        , _react2.default.createElement(_RightSide.RightSide, {
          className: "meeting-room--right-side",
          inView: isInRoom && hasVideoFeature, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
        
          ,  !hasVideoFeature
            ? _react2.default.createElement(_VideoRooms2.default, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 100}} )
            : isInRoom
              ? this.renderRoomBody()
              : this.renderEmptyRoom()
          
        )
      )
    )
  }
}

const mapState = (state, { roomKey }) => ({
  isRoomLoaded: !!_videoRoom.selectVideoRoomByKey.call(void 0, state, roomKey),
  areAnyRoomsListed: !!_videoRoom.selectAllVideoRoomsCount.call(void 0, state),
  hasVideoFeature: _user.selectCurrentUserHasVideoV2Feature.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(VideoRooms)
