"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Profile/Sections/Emails/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _EmailEntry = require('./EmailEntry'); var _EmailEntry2 = _interopRequireDefault(_EmailEntry);
var _GenericSection = require('../GenericSection'); var _GenericSection2 = _interopRequireDefault(_GenericSection);
var _Form = require('web/components/Form');









const EmailsSection = ({
  emailsList,
  onRemoveEmail,
  onVerifyEmail,
  onMakeEmailPrimary,
  onAddEmail,
}) => {
  const sortedEmailsList = _react.useMemo.call(void 0, 
    () => emailsList.slice(0).sort((a, b) => +b.IsPrimary - +a.IsPrimary),
    [emailsList],
  )

  return (
    _react2.default.createElement(_GenericSection2.default, { classNameSuffix: "contact", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
      , _react2.default.createElement('span', { className: "settings--profile--section-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Email Addresses"

      )
      , _react2.default.createElement('div', { className: "settings--profile--section-content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
        ,  sortedEmailsList.map(x => (
          _react2.default.createElement(_EmailEntry2.default, {
            key: x.Email,
            email: x.Email,
            isPrimary: x.IsPrimary,
            isVerified: x.IsVerified,
            onRemoveEmail: onRemoveEmail,
            onVerifyEmail: onVerifyEmail,
            onMakeEmailPrimary: onMakeEmailPrimary, __self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}
          )
        ))
        , _react2.default.createElement(_Form.Button, { isMinimalistic: true, onClick: onAddEmail, __self: this, __source: {fileName: _jsxFileName, lineNumber: 45}}
          , _react2.default.createElement('img', { src: "/assets/icons/icon-add-thin-purple.svg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} ), "Add email"

        )
      )
    )
  )
}

exports. default = EmailsSection
