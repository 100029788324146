"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/CampaignForm/Form/MessageTypeController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');


var _event = require('shared/services/event');

var _SimpleSelect = require('web/components/Form/SimpleSelect'); var _SimpleSelect2 = _interopRequireDefault(_SimpleSelect);









const MessageTypeController = ({ name, control, options }) => (
  _react2.default.createElement(_reacthookform.Controller, {
    control: control,
    name: name,
    render: ({ field }) => (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement(_SimpleSelect2.default, {
          value: field.value.toString(),
          onChange: t => field.onChange(_event.makeChangeEvent.call(void 0, t)),
          options: options, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        )
        , _react2.default.createElement('span', { className: "broadcast--campaign-form--field-hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          ,  _optionalChain([options, 'access', _ => _.find, 'call', _2 => _2(o => o.value === field.value), 'optionalAccess', _3 => _3.desc]) 
        )
      )
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
  )
)

exports. default = MessageTypeController
