"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _toolkit = require('@reduxjs/toolkit');


var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);

const initialState = {}

exports. default = _toolkit.createReducer.call(void 0, 
  initialState,
  builder => {
    [
      _actionCreators2.default.getOrgsActions.SUCCESS,
      _actionCreators2.default.getInboxActions.SUCCESS,
      _actionCreators2.default.getBlobjectDeepActions.SUCCESS,
      _actionCreators2.default.getUsersActions.SUCCESS,
      _actionCreators2.default.findUsersNotInTeamActions.SUCCESS,
    ].forEach(t => builder.addCase(t, (s, a) => ({ ...s, ...a.payload.Orgs })))

    builder.addCase(_actionCreators2.default.logoutAction, () => initialState)
    builder.addDefaultCase(s => s)
  },
)
