"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/App/List/ListHeaderTitle.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const ListHeaderTitle =
  ({ className, children, ...props }) => (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 'list-header--title--text', className), ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 6}}
      ,  children 
    )
  )

exports. default = ListHeaderTitle
