"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/ForgotPassword/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reacthookform = require('react-hook-form');




var _forgotPassword = require('shared/validation/forgotPassword');

var _components = require('web/screens/Login/components');
var _Form = require('web/components/Form');






const errorMsgs = {
  'Invalid Email': "Invalid email - please make sure you're using the email address you registered with BloomText when requesting a new password.",
  'Account Disabled': "This account has been disabled. Please contact support@bloomtext.com.",
  'generic': "Sorry, BloomText has encountered an error.",
}

const ForgotPassword = ({ gotoUsernameLogin, onSubmit }) => {
  const [hasRequestedRecovery, setHasRequestedRecovery] = _react.useState.call(void 0, false)

  const { register, setError, handleSubmit, formState } =
    _reacthookform.useForm({
      mode: 'onChange',
      resolver: _forgotPassword.forgotPasswordResolver,
    })
  const { errors, isSubmitting, isValid } = formState

  const submit = _react.useCallback.call(void 0, 
    async (data, e) => {
      _optionalChain([e, 'optionalAccess', _ => _.preventDefault, 'call', _2 => _2()])

      return onSubmit(data.email)
        .then(() => setHasRequestedRecovery(true))
        .catch(e => {
          setError('email', { message: errorMsgs[e.Response] || errorMsgs.generic })
        })
    },
    [onSubmit, setError],
  )

  const renderSuccessMessage = () => (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('span', { className: "login--forgot-pwd--success-msg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}, "We've sent you a message with instructions on how to reset your password."

      )
      , _react2.default.createElement(_Form.Button, { onClick: gotoUsernameLogin, className: "login--forgot-pwd--success-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}, "Done"

      )
    )
  )

  const renderResetForm = () => (
    _react2.default.createElement('form', { onSubmit: handleSubmit(submit), __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
      , _react2.default.createElement(_Form.InputSection, {
        autoFocus: true,
        title: "Email",
        autoComplete: "email",
        inputType: "email",
        inputProps: register('email'),
        placeholder: "E.g. john@email.com" ,
        errorMessage: _optionalChain([errors, 'access', _3 => _3.email, 'optionalAccess', _4 => _4.message]),
        className: "login--input-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
      )
      , _react2.default.createElement('span', { className: "login--forgot-pwd--hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "Enter your work email so we can send you a message to reset your password."

      )
      , _react2.default.createElement(_Form.Button, {
        isSubmit: true,
        isLoading: isSubmitting,
        isDisabled: !isValid || isSubmitting,
        className: "login--forgot-pwd-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
      , "Continue"

      )
      , _react2.default.createElement('div', { className: "login--forgot-pwd--link-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        , _react2.default.createElement('span', { role: "button", onClick: gotoUsernameLogin, className: "login--forgot-pwd--link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, "Go back"

        )
      )
    )
  )

  return (
    _react2.default.createElement(_components.FormWrapper, { title: "Reset password" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      ,  hasRequestedRecovery ? renderSuccessMessage() : renderResetForm() 
    )
  )
}

exports. default = ForgotPassword
