"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');





var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);

var _chat = require('shared/enum/chat');

 const MAX_NAME_LENGTH = 100; exports.MAX_NAME_LENGTH = MAX_NAME_LENGTH









const schema = _joi4.default.object({
  name: _joi3.optionalString.call(void 0, ).max(exports.MAX_NAME_LENGTH).messages(_joi3.makeStrErrors.call(void 0, 'Chat name')),
  senderKey: _joi3.requiredString.call(void 0, ),
  deliveryMethod: _joi4.default.string().valid(...Object.values(_chat.DELIVERY_METHOD)),
})

 const newChatResolver = _joi.joiResolver.call(void 0, schema); exports.newChatResolver = newChatResolver
