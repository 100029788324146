"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _joi = require('@hookform/resolvers/joi');
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);






const MAX_DAYS_RANGE = 30
const schema = _joi4.default.object({
  startDate: _joi3.requiredString.call(void 0, )
    .custom((value, helpers) =>
      _moment2.default.call(void 0, value, _moment2.default.HTML5_FMT.DATE).isBefore()
        ? value
        : helpers.error('date.min'),
    )
    .messages({ 'date.min': 'Please select a past date' }),
  endDate: _joi3.requiredString.call(void 0, )
    .custom((value, helpers) => {
      const startDate = _optionalChain([helpers, 'access', _ => _.state, 'access', _2 => _2.ancestors, 'optionalAccess', _3 => _3[0], 'optionalAccess', _4 => _4.startDate])
      const startMoment = _moment2.default.call(void 0, startDate, _moment2.default.HTML5_FMT.DATE)
      const endMoment = _moment2.default.call(void 0, value, _moment2.default.HTML5_FMT.DATE)
      const diffDays = endMoment.diff(startMoment, 'days')

      if (diffDays < 0) return helpers.error('date.invalid')
      if (diffDays > MAX_DAYS_RANGE) return helpers.error('date.size')
      return value
    })
    .messages({
      'date.invalid': 'Please enter a valid date range',
      'date.size': `Please select ${MAX_DAYS_RANGE} days or less`,
    }),
})

 const teamPhoneLogsExportResolver = _joi.joiResolver.call(void 0, schema); exports.teamPhoneLogsExportResolver = teamPhoneLogsExportResolver
