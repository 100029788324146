"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _qs = require('qs'); var _qs2 = _interopRequireDefault(_qs);






const MS_IN_SECOND = 1000
const MS_IN_YEAR = 365 * 24 * 60 * 60 * MS_IN_SECOND

const getExpirationDate = (duration) => {
  if (!duration) return null
  const date = new Date()
  const { years = 0, seconds = 0 } = duration
  date.setTime(date.getTime() + years * MS_IN_YEAR + seconds * MS_IN_SECOND)
  return date.toUTCString()
}

 const createCookie = (name, value, duration) => {
  const cfg = { delimiter: ';', encode: false }
  const cookie = _qs2.default.stringify({
    [name]: value,
    expires: getExpirationDate(duration),
    path: '/',
    SameSite: 'Strict',
  }, cfg)

  document.cookie = cookie + ';Secure'
}; exports.createCookie = createCookie

 const readCookie = (name) => {
  const nameEQ = name + "="
  const ca = document.cookie.split(';')
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i] || ''
    while (c.charAt(0) === ' ') c = c.substring(1, c.length)
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length)
  }
  return null
}; exports.readCookie = readCookie

 const eraseCookie = (name) => exports.createCookie.call(void 0, name, "", { seconds: -1 }); exports.eraseCookie = eraseCookie
