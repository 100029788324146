"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ParticipantsPicker/SearchInput/Pill.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Avatar = require('web/components/Avatar');






const Pill = ({ entity, onRemove }) => (
  _react2.default.createElement('div', { className: "participants-picker--entity-pill", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
    , _react2.default.createElement(_Avatar.EntityAvatar, {
      size: 28,
      entity: entity,
      className: "participants-picker--entity-pill--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    )
    , _react2.default.createElement('span', { className: "participants-picker--entity-pill--name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
      ,  entity.name 
    )
    , _react2.default.createElement('div', {
      role: "button",
      'aria-label': `Remove ${entity.name}`,
      onClick: () => onRemove(entity.entityKey),
      className: "participants-picker--entity-pill--remove-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    )
  )
)

exports. default = Pill
