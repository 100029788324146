"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _string = require('shared/services/string');

 const MAX_SEARCH_QUERY_LENGTH = 100; exports.MAX_SEARCH_QUERY_LENGTH = MAX_SEARCH_QUERY_LENGTH

 const sanitizeSearchQuery = (v) => {
  const sanitizedValue = _string.cleanupStr.call(void 0, v)
  return sanitizedValue.length < exports.MAX_SEARCH_QUERY_LENGTH
    ? sanitizedValue
    : sanitizedValue.slice(0, exports.MAX_SEARCH_QUERY_LENGTH)
}; exports.sanitizeSearchQuery = sanitizeSearchQuery

 const useSearchQuery = () => {
  const [searchQuery, setSearchQuery] = _react.useState.call(void 0, '')
  const onChange = _react.useCallback.call(void 0, 
    (v) => setSearchQuery(exports.sanitizeSearchQuery.call(void 0, v)),
    [],
  )

  return [searchQuery, onChange]
}; exports.useSearchQuery = useSearchQuery
