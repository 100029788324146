"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/Org/SubscriptionsTab/Features/components/UpgradePrompt.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');






const UpgradePrompt = ({ label, onCheckout }) => (
  _react2.default.createElement('div', { className: "settings--org-subs--feat--upgrade-prompt", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
    , _react2.default.createElement('span', { className: "settings--org-subs--feat--upgrade-prompt-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      ,  label 
    )
    , _react2.default.createElement(_Form.Button, {
      isDisabled: !onCheckout,
      onClick: onCheckout,
      className: "settings--org-subs--feat--upgrade-prompt-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    , "Activate"

    )
  )
)

exports. default = UpgradePrompt
