"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/OnCall/uiUtils.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Tooltip = require('web/components/Tooltip');

 const withTouchInterception = Component => {
  class WithTouchInterception extends _react2.default.Component {constructor(...args) { super(...args); WithTouchInterception.prototype.__init.call(this);WithTouchInterception.prototype.__init2.call(this);WithTouchInterception.prototype.__init3.call(this);WithTouchInterception.prototype.__init4.call(this); }
    __init() {this.touchStartHandler = null}
    __init2() {this.touchEndHandler = null}
    __init3() {this.elementRef = null}

    __init4() {this.setRef = r => {
      if (!r) return
      const { innerRef } = this.props
      innerRef && innerRef(r)
      this.elementRef = r

      const halt = e => {
        e.preventDefault()
        e.stopPropagation()
      }
      this.touchStartHandler = e => {
        const { onClick } = this.props
        halt(e)
        if (onClick) onClick()
      }
      this.touchEndHandler = halt
      this.elementRef.addEventListener('touchstart', this.touchStartHandler)
      this.elementRef.addEventListener('touchend', this.touchEndHandler)
    }}

    componentWillUnmount () {
      if (!this.elementRef) return
      this.elementRef.removeEventListener('touchstart', this.touchStartHandler)
      this.elementRef.removeEventListener('touchend', this.touchEndHandler)
    }

    render () {
      return (
        _react2.default.createElement(Component, {
          ref: this.setRef,
          ...this.props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        )
      )
    }
  }
  return _react2.default.forwardRef((props, ref) => (
    _react2.default.createElement(WithTouchInterception, { ...props, innerRef: ref, __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}} )
  ))
}; exports.withTouchInterception = withTouchInterception

 const withOnCallTooltip = (place, hintText, className) => Component => _react2.default.forwardRef((props, ref) => (
  _react2.default.createElement(_Tooltip.HoverTooltip, {
    place: place,
    type: "dark",
    content: _react2.default.createElement(_Tooltip.TooltipContent, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}},  hintText ),
    className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
  
    , _react2.default.createElement(Component, { ...props, ref: ref, __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}} )
  )
)); exports.withOnCallTooltip = withOnCallTooltip
