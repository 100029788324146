"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxRightSide/CreateNewChat/Body/ChatboxInput.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _reactdropzone = require('react-dropzone'); var _reactdropzone2 = _interopRequireDefault(_reactdropzone);

var _ExpandingInput = require('web/components/Conversation/ExpandingInput'); var _ExpandingInput2 = _interopRequireDefault(_ExpandingInput);

const ChatboxInput = ({ onSend, value = '', onChange }) => {
  const handleChange = _react.useCallback.call(void 0, (_err, v) => onChange(v), [onChange])

  const dropzoneRef = _react.useRef.call(void 0, null)
  const handleSendMessage = _react.useCallback.call(void 0, () => onSend(value), [onSend, value])
  const handleSendFiles = _react.useCallback.call(void 0, files => onSend(null, files), [onSend])
  const handleSendFile = _react.useCallback.call(void 0, f => handleSendFiles([f]), [handleSendFiles])
  const handlePickAttachment = _react.useCallback.call(void 0, () => _optionalChain([dropzoneRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.open, 'call', _3 => _3()]), [])

  return (
    _react2.default.createElement(_reactdropzone2.default, {
      className: "create-new--chatbox-dropzone",
      disableClick: true,
      onDrop: handleSendFiles,
      ref: dropzoneRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
    
      , _react2.default.createElement('div', { className: "create-new--chatbox-input", __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
        , _react2.default.createElement(_ExpandingInput2.default, {
          value: value,
          onChange: handleChange,
          onSend: handleSendMessage,
          onSendFile: handleSendFile,
          onAttachmentClick: handlePickAttachment, __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}
        )
      )
    )
  )
}

ChatboxInput.propTypes = {
  onSend: _proptypes2.default.func.isRequired,
  onChange: _proptypes2.default.func.isRequired,
  value: _proptypes2.default.string.isRequired,
}

exports. default = ChatboxInput
