"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/Controllers/CheckboxController.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _hookForm = require('shared/services/validation/hookForm');
var _event = require('shared/services/event');

var _Checkbox = require('web/components/Form/Checkbox'); var _Checkbox2 = _interopRequireDefault(_Checkbox);







const CheckboxController = (
  { name, control, className },
) => (
  _react2.default.createElement(_hookForm.Controller, {
    name: name,
    control: control,
    render: 
      ({ field }) => (
        _react2.default.createElement(_Checkbox2.default, {
          isChecked: !!field.value,
          onChange: v => field.onChange(_event.makeChangeEvent.call(void 0, v)),
          className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
        )
      )
    , __self: this, __source: {fileName: _jsxFileName, lineNumber: 17}}
  )
  )

exports. default = CheckboxController
