"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/UserPage/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');





var _uuid = require('shared/services/uuid');


var _entities = require('shared/transforms/entities');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _org = require('shared/selectors/org');
var _user = require('shared/selectors/user');
var _peers = require('shared/selectors/peers');
var _useEntityPreload = require('shared/hooks/useEntityPreload'); var _useEntityPreload2 = _interopRequireDefault(_useEntityPreload);






var _Header = require('web/components/RightSide/Header');
var _EntityNotFoundScreen = require('web/screens/EntityNotFoundScreen'); var _EntityNotFoundScreen2 = _interopRequireDefault(_EntityNotFoundScreen);
var _NameBadge = require('web/components/NameBadge'); var _NameBadge2 = _interopRequireDefault(_NameBadge);
var _Avatar = require('web/components/Avatar');
var _RecentChatsSection = require('web/screens/Directory/RecentChatsSection'); var _RecentChatsSection2 = _interopRequireDefault(_RecentChatsSection);
var _RightSide = require('web/components/RightSide');
var _DirectoryPage = require('../DirectoryPage');







const UserPage = ({
  orgKey,
  orgName,
  userKey,
  userEntity,
  onStartChat,
}) => {
  const loadUser = _react.useCallback.call(void 0, 
    () => _actions2.default.getUsers({ UserKeys: [userKey] })
      .then(r => {
        if (!r.Users) throw new Error(`Failed to load user ${userKey}`)
      }),
    [userKey],
  )
  const hasLoadFailed = _useEntityPreload2.default.call(void 0, loadUser)

  const loadRecentChats = _react.useCallback.call(void 0, 
    (_query, Offset, Limit) => orgKey !== _uuid.EMPTY_BLUID
      ? _actions2.default.getInboxForUser({ OrgKey: orgKey, UserKey: userKey, Offset, Limit })
      : Promise.resolve([]),
    [userKey, orgKey],
  )

  const onNewChat = _react.useCallback.call(void 0, 
    () => userEntity && onStartChat(userEntity),
    [onStartChat, userEntity],
  )

  if (hasLoadFailed) return _react2.default.createElement(_EntityNotFoundScreen2.default, { desc: "Failed to load user"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}} )
  if (!userEntity) return null
  const isAdmin = _user.isUserAdminInOrg.call(void 0, userEntity.wrappedEntity, orgKey)

  return (
    _react2.default.createElement(_react2.default.Fragment, { key: userKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
      , _react2.default.createElement(_Header.RightSideHeader, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
        , _react2.default.createElement(_Header.HeaderMobileBackButton, { onClick: _actions2.default.gotoDirectory, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}} )
        , _react2.default.createElement(_Avatar.UserAvatar, { userKey: userKey, size: 46, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}} )
        , _react2.default.createElement(_Header.RightSideHeaderHeading, {
          title: userEntity.name,
          titleBadge: isAdmin ? _react2.default.createElement(_NameBadge2.default, { label: "Admin", __self: this, __source: {fileName: _jsxFileName, lineNumber: 76}} ) : null,
          subtitle: userEntity.description, __self: this, __source: {fileName: _jsxFileName, lineNumber: 74}}
        )
        , _react2.default.createElement(_Header.HeaderItem, {
          iconName: "chat",
          className: "blmh-mobile-hide",
          onClick: onNewChat, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
        )
      )
      , _react2.default.createElement(_RightSide.RightSideBody, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
        , _react2.default.createElement(_DirectoryPage.DirectoryPage, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
          , _react2.default.createElement('div', { className: "directory-page--section-title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, "General Information"

          )
          , _react2.default.createElement('div', { className: "directory-page--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
            , _react2.default.createElement('div', { className: "directory-page--info-section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 91}}
              , _react2.default.createElement('div', { className: "directory-page--info-section--metadata", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
                , _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}
                  , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}, "Name")
                  , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}},  userEntity.name )
                )
                , _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 97}}
                  , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}, "Organization")
                  , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}},  orgName )
                )
                ,  userEntity.description && (
                  _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}
                    , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}, "Title")
                    , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 104}},  userEntity.description )
                  )
                )
                ,  userEntity.wrappedEntity.Email && (
                  _react2.default.createElement('div', { className: "directory-page--info-section--metadata-item", __self: this, __source: {fileName: _jsxFileName, lineNumber: 108}}
                    , _react2.default.createElement('span', { className: "name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}, "Email")
                    , _react2.default.createElement('span', { className: "value", __self: this, __source: {fileName: _jsxFileName, lineNumber: 110}}
                      ,  userEntity.wrappedEntity.Email 
                    )
                  )
                )
              )
              , _react2.default.createElement(_Avatar.UserAvatar, {
                userKey: userKey,
                size: 100,
                className: "directory-page--avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}
              )
            )
          )
          , _react2.default.createElement(_RecentChatsSection2.default, {
            loadItemsList: loadRecentChats,
            onNewChat: onNewChat,
            entityName: userEntity.name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 123}}
          )
        )
      )
    )
  )
}






const mapState = (state, { orgKey, userKey }) => {
  const user = _peers.selectUserByKey.call(void 0, state, userKey)

  const userEntity = user &&
    _entities.mapUserToEntity.call(void 0, {}, _entities.makeSpecificOrgTitleUserDescription.call(void 0, orgKey))(user)
  const org = _org.selectOrgByKey.call(void 0, orgKey, state)
  const orgName = _optionalChain([org, 'optionalAccess', _ => _.Name]) || ''

  return { orgName, userEntity }
}

exports. default = _reactredux.connect.call(void 0, mapState)(UserPage)
