"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/VideoRooms/List/RoomPreview.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _RoomPreview = require('web/components/VideoRooms/List/RoomPreview'); var _RoomPreview2 = _interopRequireDefault(_RoomPreview);
var _videoRoom = require('web/selectors/videoRoom');

const RoomPreviewContainer = ({
  activeParticipantsCount,
  roomKey,
  roomName,
  isActive,
  isArchived,
  onPick,
  onToggleArchivation,
}) => (
  _react2.default.createElement(_RoomPreview2.default, {
    activeParticipantsCount: activeParticipantsCount,
    roomKey: roomKey,
    roomName: roomName,
    isActive: isActive,
    isArchived: isArchived,
    onPick: onPick,
    onToggleArchivation: onToggleArchivation, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
  )
)

const mapState = (state, ownProps) => ({
  activeParticipantsCount:
    _videoRoom.selectVideoRoomActiveParticipantsCount.call(void 0, state, ownProps.roomKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(RoomPreviewContainer)
