"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/Sidebar/List/Teams/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');
var _reactwaypoint = require('react-waypoint');



var _error = require('shared/services/error');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _entity = require('shared/enum/entity');
var _usePagination = require('shared/hooks/usePagination'); var _usePagination2 = _interopRequireDefault(_usePagination);
var _directory = require('shared/hooks/directory');
var _noticeBar = require('web/services/context/noticeBar');

var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _ListResultsStatus = require('web/components/ListResultsStatus'); var _ListResultsStatus2 = _interopRequireDefault(_ListResultsStatus);
var _Item = require('./Item'); var _Item2 = _interopRequireDefault(_Item);










const TEAM_LOAD_LIMIT = 30
const keyExtractor = (t) => t.TeamKey

const TeamsList = ({ orgKey, searchQuery }) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const loadItemsList = _react.useCallback.call(void 0, 
    (query, offset, limit) => _actions2.default
      .findTeamsInOrg({
        OrgKey: orgKey,
        Query: query,
        Offset: offset,
        Limit: limit,
      })
      .then(r => r.Teams)
      .catch(_error.passError.call(void 0, () => showWarningBar('Failed to load teams'))),
    [orgKey, showWarningBar],
  )

  const { isLoading, hasDrained, itemKeysList, onLoadMore, onReset } =
    _usePagination2.default.call(void 0, {
      loadItemsList,
      keyExtractor,
      searchQuery,
      limit: TEAM_LOAD_LIMIT,
    })
  _directory.useDirectoryListListener.call(void 0, _entity.ENTITY_TYPES.TEAM, onReset)

  const match = _reactrouterdom.useRouteMatch(_routes2.default.DIRECTORY.TEAM_PAGE)
  const viewedTeamKey = _optionalChain([match, 'optionalAccess', _ => _.params, 'optionalAccess', _2 => _2.teamKey])

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  itemKeysList.map(teamKey => (
        _react2.default.createElement(_Item2.default, {
          key: teamKey,
          teamKey: teamKey,
          orgKey: orgKey,
          isViewed: viewedTeamKey === teamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        )
      ))
      ,  itemKeysList.length && !hasDrained
        ? _react2.default.createElement(_reactwaypoint.Waypoint, { onEnter: onLoadMore, bottomOffset: -100, __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}} )
        : null
      
      , _react2.default.createElement(_ListResultsStatus2.default, {
        isLoading: isLoading,
        resultsCount: itemKeysList.length,
        noResultsLabel: "No teams found."  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
      )
    )
  )
}

exports. default = TeamsList
