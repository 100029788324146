"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _chat = require('shared/enum/chat');
var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);

var ChatTypeURLToken; (function (ChatTypeURLToken) {
  const REGULAR = 'chat'; ChatTypeURLToken["REGULAR"] = REGULAR;
  const REGULAR_PATIENT = 'patient-chat'; ChatTypeURLToken["REGULAR_PATIENT"] = REGULAR_PATIENT;
  const SECURE_PATIENT = 'sms-chat'; ChatTypeURLToken["SECURE_PATIENT"] = SECURE_PATIENT;
})(ChatTypeURLToken || (exports.ChatTypeURLToken = ChatTypeURLToken = {}));

 const mapChatTypeToURLToken = (chatType) => {
  const tokens = {
    [_chat.CHAT_TYPE.REGULAR]: ChatTypeURLToken.REGULAR,
    [_chat.CHAT_TYPE.REGULAR_PATIENT]: ChatTypeURLToken.REGULAR_PATIENT,
    [_chat.CHAT_TYPE.SECURE_PATIENT]: ChatTypeURLToken.SECURE_PATIENT,
  }
  return tokens[chatType] || tokens[_chat.CHAT_TYPE.REGULAR]
}; exports.mapChatTypeToURLToken = mapChatTypeToURLToken

 const mapURLTokenToChatType = (token) => {
  const chatTypes = {
    [ChatTypeURLToken.REGULAR]: _chat.CHAT_TYPE.REGULAR,
    [ChatTypeURLToken.REGULAR_PATIENT]: _chat.CHAT_TYPE.REGULAR_PATIENT,
    [ChatTypeURLToken.SECURE_PATIENT]: _chat.CHAT_TYPE.SECURE_PATIENT,
  }
  return chatTypes[token] || null
}; exports.mapURLTokenToChatType = mapURLTokenToChatType

 const gotoCreateNew = _actions2.default.gotoCreateNew; exports.gotoCreateNew = gotoCreateNew
