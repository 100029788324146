"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Avatar/BaseAvatar/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





var _utils = require('../utils');
var _hooks = require('./hooks');

const getDisplayParticipantNumber = (n) => n < 10 ? `+${n}` : '9+'
















const BaseAvatar = ({
  size = _utils.DEFAULT_AVATAR_SIZE,
  backgroundColorKey,
  icon,
  iconAltText = 'Avatar',
  profilePicture,
  profilePictureAltText = 'Avatar',
  isOnline,
  isPatient,
  borderColor,
  participantNumber = 0,
  style,
  className,
}) => {
  const rootClassName = _classBuilder2.default.call(void 0, "avatarV2", className)

  const {
    avatarStyle,
    wrapperStyle,
    badgeStyle,
    participantNumberStyle,
  } = _hooks.useStlyles.call(void 0, {
    backgroundColorKey,
    size,
    borderColor,
    profilePicture,
    style,
  })

  const renderContent = () => {
    if (profilePicture) {
      return (
        _react2.default.createElement('img', {
          src: _utils.normalizeUrl.call(void 0, profilePicture),
          className: "avatarV2--profile-picture",
          alt: profilePictureAltText, __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
        )
      )
    }
    if (icon) {
      return (
        _react2.default.createElement('img', {
          src: _utils.normalizeUrl.call(void 0, icon),
          className: "avatarV2--icon",
          alt: iconAltText, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
        )
      )
    }
    return null
  }

  const renderParticipantNumber = () => participantNumber > 1
    ? _react2.default.createElement('div', { className: "avatarV2--participant-number", style: participantNumberStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        ,  getDisplayParticipantNumber(participantNumber) 
      )
    : null

  const patientBadge = (
    _react2.default.createElement('span', { className: "avatarV2--patient-badge", style: badgeStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}
      , _react2.default.createElement('img', {
        src: "/assets/icons/avatar/icon-bandaid.svg",
        alt: _utils.PATIENT_BADGE_ICON_ALT_TEXT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
      )
    )
  )

  return (
    _react2.default.createElement('div', { className: rootClassName, style: avatarStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
      , _react2.default.createElement('div', { className: "avatarV2--wrapper", style: wrapperStyle, __self: this, __source: {fileName: _jsxFileName, lineNumber: 96}}
        ,  renderContent() 
        ,  renderParticipantNumber() 
      )
      ,  isOnline && !participantNumber && (
        _react2.default.createElement('img', {
          src: "/assets/icons/avatar/online-badge.svg",
          className: "avatarV2--online-badge",
          alt: _utils.ONLINE_BADGE_ICON_ALT_TEXT, __self: this, __source: {fileName: _jsxFileName, lineNumber: 101}}
        )
      )
      ,  isPatient && patientBadge 
    )
  )
}

exports. default = BaseAvatar
