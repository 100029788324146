"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _broadcastCampaign = require('shared/validation/broadcastCampaign');

 const messageTypeOptions = [
  {
    value: _broadcastCampaign.MessageType.SecureSMS,
    label: 'Secure Text with Link',
    desc: "We'll send your patients an SMS with a secure link to your message.",
  },
  {
    value: _broadcastCampaign.MessageType.InsecureSMS,
    label: 'SMS (Text Message)',
    desc: "We'll send your patients an SMS with your message.",
  },
]; exports.messageTypeOptions = messageTypeOptions
