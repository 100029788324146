"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Settings/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');
var _reactredux = require('react-redux');


var _routes = require('web/chat/routes'); var _routes2 = _interopRequireDefault(_routes);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);



var _myRelations = require('shared/selectors/myRelations');
var _hooks = require('./hooks');
var _noticeBar = require('web/services/context/noticeBar');

var _App = require('web/screens/App');
var _RightSide = require('web/components/RightSide');
var _Navigation = require('./Navigation'); var _Navigation2 = _interopRequireDefault(_Navigation);
var _Profile = require('web/screens/Settings/Profile'); var _Profile2 = _interopRequireDefault(_Profile);
var _OrgsList = require('web/screens/Settings/OrgsList'); var _OrgsList2 = _interopRequireDefault(_OrgsList);
var _Org = require('web/screens/Settings/Org'); var _Org2 = _interopRequireDefault(_Org);
var _Security = require('web/screens/Settings/Security'); var _Security2 = _interopRequireDefault(_Security);
var _Notifications = require('web/screens/Settings/Notifications'); var _Notifications2 = _interopRequireDefault(_Notifications);

const SettingsPage = ({ memberOrgs }) => {
  const { pathname } = _reactrouterdom.useLocation.call(void 0, )
  const isInPage = !_reactrouterdom.useRouteMatch.call(void 0, ).isExact

  const {
    gotoSettingsRoot,
    gotoOrgsList,
    gotoOrgInfo,
    gotoOrgMembers,
    gotoOrgMembership,
    gotoOrgSubscriptions,
  } = _hooks.useNav.call(void 0, )

  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  _react.useEffect.call(void 0, 
    () => {
      _actions2.default.getQuickLoginUsers()
        .catch(() => showWarningBar('Failed to load Quick Login users'))
    },
    [showWarningBar],
  )

  return (
    _react2.default.createElement(_App.AppVertical, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 47}}
      , _react2.default.createElement(_Navigation2.default, { memberOrgs: memberOrgs, pathname: pathname, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}} )
      ,  isInPage &&
        _react2.default.createElement(_RightSide.RightSide, { className: "settings-page", inView: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
          , _react2.default.createElement(_reactrouterdom.Switch, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
            , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.SETTINGS.PROFILE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
              , _react2.default.createElement(_Profile2.default, { gotoSettingsRoot: gotoSettingsRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}} )
            )
            , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.SETTINGS.SECURITY, __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
              , _react2.default.createElement(_Security2.default, { gotoSettingsRoot: gotoSettingsRoot, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}} )
            )
            , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.SETTINGS.NOTIFICATIONS, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
              , _react2.default.createElement(_Notifications2.default, {
                gotoSettingsRoot: gotoSettingsRoot,
                profileSettingsUrl: _routes2.default.SETTINGS.PROFILE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
              )
            )
            , _react2.default.createElement(_reactrouterdom.Route, { exact: true, path: _routes2.default.SETTINGS.ORGS, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              , _react2.default.createElement(_OrgsList2.default, {
                gotoOrg: gotoOrgMembers,
                gotoSettingsRoot: gotoSettingsRoot,
                memberOrgs: memberOrgs, __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
              )
            )
            , _react2.default.createElement(_reactrouterdom.Route, { path: _routes2.default.SETTINGS.ORG_PAGE, __self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
              , _react2.default.createElement(_Org2.default, {
                gotoOrgInfo: gotoOrgInfo,
                gotoOrgMembers: gotoOrgMembers,
                gotoOrgMembership: gotoOrgMembership,
                gotoOrgSubscriptions: gotoOrgSubscriptions,
                gotoOrgsList: gotoOrgsList, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
              )
            )
            , _react2.default.createElement(_reactrouterdom.Redirect, { exact: true,
              from: _routes2.default.SETTINGS.ORG_PAGE_NO_TAB,
              to: _routes2.default.SETTINGS.ORG_PAGE_DEFAULT_TAB, __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
            )
          )
        )
      
    )
  )
}





const mapState = (state) => ({
  memberOrgs: _myRelations.selectMyRelationshipGroups.call(void 0, state).memberOrgs,
})

exports. default = _reactredux.connect.call(void 0, mapState)(SettingsPage)
