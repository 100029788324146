"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Modals/AddPatients/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactdropzone = require('react-dropzone'); var _reactdropzone2 = _interopRequireDefault(_reactdropzone);



var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _supportUrls = require('shared/data/supportUrls');

var _Modal = require('web/components/Modal');
var _Form = require('web/components/Form');
var _Spinner = require('web/components/Spinner');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








var UPLOAD_STEPS; (function (UPLOAD_STEPS) {
  const UPLOADING_FILE = 'UPLOADING_FILE'; UPLOAD_STEPS["UPLOADING_FILE"] = UPLOADING_FILE;
  const IMPORTING_PATIENTS = 'IMPORTING_PATIENTS'; UPLOAD_STEPS["IMPORTING_PATIENTS"] = IMPORTING_PATIENTS;
  const ADDING_PATIENTS = 'ADDING_PATIENTS'; UPLOAD_STEPS["ADDING_PATIENTS"] = ADDING_PATIENTS;
})(UPLOAD_STEPS || (UPLOAD_STEPS = {}));



const AddPatientsForm = ({ onClose, orgKey, onAddPatients }) => {
  const dropzoneRef = _react.useRef(null)
  const onOpenFilePicker = () => _optionalChain([dropzoneRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.open, 'call', _3 => _3()])

  const [rejectedFileName, setRejectedFileName] = _react.useState(null)
  const [acceptedFile, setAcceptedFile] = _react.useState(null)
  const [uploadError, setUploadError] = _react.useState(false)
  const [uploadStep, setUploadStep] = _react.useState(null)

  const isUploading = !!uploadStep && !uploadError

  const onDropAccepted = ([file]) => {
    if (!file) return
    const fileName = file.name
    if (fileName.split('.').pop() !== 'csv') {
      setUploadStep(null)
      setUploadError(true)
      setRejectedFileName(fileName)
      setAcceptedFile(null)
    } else {
      setUploadStep(null)
      setUploadError(false)
      setRejectedFileName(null)
      setAcceptedFile(file)
    }
  }

  const onSubmit = () => {
    if (!orgKey || !acceptedFile) return
    setUploadStep(UPLOAD_STEPS.UPLOADING_FILE)

    _client2.default.uploadFile(acceptedFile)
      .then(({ FileKey }) => {
        setUploadStep(UPLOAD_STEPS.IMPORTING_PATIENTS)
        return _actions2.default.patientImport({ OrgKey: orgKey, FileKey })
      })
      .then(({ PatientKeys }) => {
        setUploadStep(UPLOAD_STEPS.ADDING_PATIENTS)
        return onAddPatients(PatientKeys)
      })
      .then(onClose)
      .catch(() => {
        setUploadError(true)
      })
  }

  const renderLoadingMessage = (msg) => (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Spinner.SimpleSpinner, { className: "campaign--add-patients--label--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 78}} )
      ,  msg 
    )
  )

  const renderLabel = () => {
    if (uploadError) {
      if (rejectedFileName) {
        return (
            _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, "Failed to upload "
             , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}},  rejectedFileName ), ".", _react2.default.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}), "Please select a valid *.csv file"

        )
        )
      }
      const fileName = _optionalChain([acceptedFile, 'optionalAccess', _4 => _4.name])
      if (uploadStep === UPLOAD_STEPS.UPLOADING_FILE) {
        return `Failed to upload "${fileName}". Please try again.`
      } else if (uploadStep === UPLOAD_STEPS.IMPORTING_PATIENTS) {
        return `Failed to import any patients from ${fileName}. Please check your file.`
      } else if (uploadStep === UPLOAD_STEPS.ADDING_PATIENTS) {
        return `Failed to add imported patients to campaign. Please try again.`
      } else {
        return `Something went wrong. Please try again.`
      }
    }
    if (uploadStep === UPLOAD_STEPS.UPLOADING_FILE) {
      return renderLoadingMessage('Uploading file...')
    }
    if (uploadStep === UPLOAD_STEPS.IMPORTING_PATIENTS) {
      return renderLoadingMessage('Importing patients...')
    }
    if (uploadStep === UPLOAD_STEPS.ADDING_PATIENTS) {
      return renderLoadingMessage('Adding patients to campaign...')
    }

    if (_optionalChain([acceptedFile, 'optionalAccess', _5 => _5.name])) return acceptedFile.name
    return (
      _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 116}}, "Click to"
         , ' '
        , _react2.default.createElement('span', { className: "campaign--add-patients--label--highlight", __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}, "add a CSV file"

        ), ' ', "or drag your file here."

      )
    )
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "Patients list" ,
        className: "campaign--add-patients--desc-wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 128}}
      
        , _react2.default.createElement(_Modal.ModalDesc, { className: "campaign--add-patients--desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 132}}, "Upload a CSV file with a list of patient contacts you with your message to be sent to."

        )
      )
      , _react2.default.createElement('div', {
        role: "button",
        onClick: isUploading ? undefined : onOpenFilePicker,
        className: _classBuilder2.default.call(void 0, 
          "campaign--add-patients--dropzone-area",
          uploadError && "campaign--add-patients--dropzone-area--error",
          isUploading && "campaign--add-patients--dropzone-area--disabled",
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 136}}
      
        , _react2.default.createElement(_reactdropzone2.default, {
          className: "campaign--add-patients--dropzone",
          onDropAccepted: onDropAccepted,
          disabled: isUploading,
          ref: dropzoneRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
        )
        , _react2.default.createElement('span', { className: "campaign--add-patients--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}},  renderLabel() )
      )
      , _react2.default.createElement('span', { className: "campaign--add-patients--extra-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 153}}, "For information on how to structure your file, "
                , _react2.default.createElement('a', { href: _supportUrls.broadcastSupportUrl, target: "_blank", __self: this, __source: {fileName: _jsxFileName, lineNumber: 154}}, "check our guide"  ), "."
      )
      , _react2.default.createElement(_Modal.ModalControls, {
        className: "campaign--add-patients--modal-controls",
        primaryBtnLabel: "Confirm",
        isPrimaryEnabled: !uploadError && !!acceptedFile && !isUploading,
        onPrimaryBtnClick: onSubmit,
        secondaryBtnLabel: "Cancel",
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 156}}
      )
    )
  )
}

exports. default = AddPatientsForm
