"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _time = require('shared/services/time');

const initialState = {
  CreatedAt: _time.EPOCH,
  UpdatedAt: _time.EPOCH,
  Verified: false,
  VerifiedAt: _time.EPOCH,
  NumberOfLogins: -1,
  InvitedByOrgAdminKey: "",
  InvitedByOrgAdminWhen: _time.EPOCH,
  FeaturesMask: 0,
  QuickLoginEnabled: false,
  AcceptedTermsOfService: false,
  AcceptedTermsOfServiceWhen: _time.EPOCH,
  Notification_Emails: false,
  Notification_MuteUntil: _time.EPOCH,
  Notification_SMS: false,
  Notification_Sound: "",

  Relationships: {},
  PendingRelationships: {},
  Emails: {},
  Phones: {},
  Teams: {},

  Email: "",
  PrimaryPhone: "",
  CombinedFeaturesMask: [],
  EnabledFeatures: [],
}

function combineTeams (stateTeams = {}, updateTeams = {}) {
  const teamObject = { ...stateTeams }
  Object.keys(updateTeams).forEach(tk => {
    if (!(tk in teamObject)) {
      teamObject[tk] = updateTeams[tk]
    } else if (new Date(stateTeams[tk].UpdatedAt).getTime() < new Date(updateTeams[tk].UpdatedAt).getTime()) {
      teamObject[tk] = updateTeams[tk]
    }
  })
  return teamObject
}

 function currentUserReducer (state = initialState, action) {
  let teamKey, inboxable
  switch (action.type) {
    case _actionCreators2.default.loginSuccessAction.type:
      return { ...state, UserKey: action.payload.User.UserKey }
    case _actionCreators2.default.updateNotificationAction.type:
    case _actionCreators2.default.getMeActions.SUCCESS.type:
      if (!action.payload.Me) {
        return state
      }
      return {
        ...state,
        ...action.payload.Me,
        Teams: combineTeams(state.Teams, action.payload.Me.Teams),
      }
    case _actionCreators2.default.setTeamInboxSettingsActions.REQUEST.type:
      teamKey = action.payload.TeamKey
      inboxable = action.payload.OnOrOff

      return {
        ...state,
        Teams: {
          ...state.Teams,
          [teamKey]: {
            ...state.Teams[teamKey],
            Inboxable: inboxable,
            UpdatedAt: new Date().toISOString(),
          },
        },
      }

    case _actionCreators2.default.removePhoneActions.SUCCESS.type:
    case _actionCreators2.default.setPrimaryEmailActions.SUCCESS.type:
    case _actionCreators2.default.setPrimaryPhoneActions.SUCCESS.type:
    case _actionCreators2.default.verifyPhoneActions.SUCCESS.type:
    case _actionCreators2.default.addEmailActions.SUCCESS.type:
    case _actionCreators2.default.addPhoneActions.SUCCESS.type:
    case _actionCreators2.default.removeEmailActions.SUCCESS.type:
    case _actionCreators2.default.changeUserOrgRoleActions.SUCCESS.type:
    case _actionCreators2.default.changeUserOrgTitleActions.SUCCESS.type:
    case _actionCreators2.default.updateOrgInfoActions.SUCCESS.type:
    case _actionCreators2.default.createAndJoinOrgActions.SUCCESS.type:
    case _actionCreators2.default.leaveOrgActions.SUCCESS.type:
    case _actionCreators2.default.updateOrgUsersNotificationAction.type: {
      const users = action.payload.users || action.payload.Users
      const currentUser = _optionalChain([users, 'optionalAccess', _ => _[state.UserKey]]) || null
      return { ...state, ...currentUser }
    }

    case _actionCreators2.default.requestJoinOrgActions.SUCCESS.type: {
      const users = action.payload.users || action.payload.Users
      return {
        ...state,
        Relationships: users[state.UserKey].Relationships,
      }
    }

    case _actionCreators2.default.logoutAction.type:
      return initialState
  }
  return state
} exports.default = currentUserReducer;
