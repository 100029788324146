"use strict";Object.defineProperty(exports, "__esModule", {value: true});

var _string = require('shared/services/string');
var _phoneVerifier = require('shared/services/phoneVerifier');
var _emailVerifier = require('shared/services/emailVerifier');

 const sanitizeExtension = (joi) => ({
  type: 'string',
  base: joi.string(),
  coerce: (v) => ({ value: _string.sanitizeStr.call(void 0, v) }),
  rules: {
    sanitize: {
      convert: true,
      method () { return this.$_addRule('sanitize') },
    },
  },
}); exports.sanitizeExtension = sanitizeExtension

 const contactPhoneExtension = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'contactPhone.invalid': "Provide a valid phone number",
  },
  rules: {
    contactPhone: {
      method () { return this.$_addRule('contactPhone') },
      validate: (value, helpers) => {
        if (!_phoneVerifier.isPhoneValid.call(void 0, value)) return helpers.error('contactPhone.invalid')
        return value
      },
    },
  },
}); exports.contactPhoneExtension = contactPhoneExtension

 const contactEmailExtension = (joi) => ({
  type: 'string',
  base: joi.string(),
  messages: {
    'contactEmail.invalid': "Provide a valid email",
    'contactEmail.max': "Email is too long",
  },
  rules: {
    contactEmail: {
      method () { return this.$_addRule('contactEmail') },
      validate: (value, helpers) => {
        if (_emailVerifier.isEmailTooLong.call(void 0, value)) return helpers.error('contactEmail.max')
        if (!_emailVerifier.isEmailValid.call(void 0, value)) return helpers.error('contactEmail.invalid')
        return value
      },
    },
  },
}); exports.contactEmailExtension = contactEmailExtension
