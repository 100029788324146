"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/CreateNew/CreateNewChat/ChatTypeToggle.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _reacthookform = require('react-hook-form');






var _chat = require('shared/enum/chat');
var _event = require('shared/services/event');
var _entity = require('shared/services/entity');
var _user = require('shared/selectors/user');

var _RadioInput = require('web/components/Form/RadioInput');
var _Form = require('web/components/Form');

const chatTypeOptions = [
  { value: _chat.DELIVERY_METHOD.SECURE, label: "Secure Chat" },
  { value: _chat.DELIVERY_METHOD.SMS, label: "SMS (Text Message)" },
]

const resolveWarningMessage = (
  hasPatientMessaging,
  isSenderAPhoneTeam,
  selectedEntitiesCount,
) => {
  if (!hasPatientMessaging) return 'You need to have SMS Messaging enabled to use this feature'
  if (!isSenderAPhoneTeam) return 'You can only send SMS on behalf of a team with a phone number assigned'
  if (selectedEntitiesCount > 1) return 'You can only send SMS to a single patient'
  return 'Patient SMS is unavailable'
}








const ChatTypeToggle = ({
  control,
  setValue,
  senderEntity,
  selectedEntitiesList,
  hasPatientMessaging,
}) => {
  const isSenderAPhoneTeam = !!(
    senderEntity
    && _entity.isEntityTeam.call(void 0, senderEntity)
    && senderEntity.wrappedEntity.PhoneNumber
  )

  const arePatientsSelected = _react.useMemo.call(void 0, 
    () => selectedEntitiesList.some(_entity.isEntityPatient),
    [selectedEntitiesList],
  )

  const isSecureSMSToggleAvailable =
    isSenderAPhoneTeam
    && arePatientsSelected
    && selectedEntitiesList.length === 1

  _react.useEffect.call(void 0, 
    () => {
      if (!isSecureSMSToggleAvailable) setValue('deliveryMethod', _chat.DELIVERY_METHOD.SECURE)
    },
    [setValue, isSecureSMSToggleAvailable],
  )

  if (!arePatientsSelected) return null

  const desc = !isSecureSMSToggleAvailable
    ? resolveWarningMessage(
      hasPatientMessaging,
      isSenderAPhoneTeam,
      selectedEntitiesList.length,
    )
    : ''

  return (
    _react2.default.createElement(_Form.InputSectionWrapper, { title: "Send Via..." , desc: desc, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}
      , _react2.default.createElement(_reacthookform.Controller, {
        name: "deliveryMethod",
        control: control,
        render: ({ field }) => (
          _react2.default.createElement(_RadioInput.ControlledRadioInput, {
            disabled: !isSecureSMSToggleAvailable,
            options: chatTypeOptions,
            onChange: 
              (_id, _err, v) => {
                if (v === _chat.DELIVERY_METHOD.SMS) setValue('name', '')
                field.onChange(_event.makeChangeEvent.call(void 0, v))
              }
            ,
            value: field.value,
            inputId: "chatType", __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
          )
        ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
      )
    )
  )
}





const mapState = (state) => ({
  hasPatientMessaging: _user.selectCurrentUserHasPatientMessagingFeature.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(ChatTypeToggle)
