"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/AnonymousPage/Wrapper.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactrouterdom = require('react-router-dom');

var _urls = require('web/services/urls');
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const currentYear = (new Date()).getFullYear()









const PageWrapper = ({
  children,
  signupRoute,
  title,
  className,
  wrapperClassName,
  footerClassName,
}) => (
  _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "anonymous-page", wrapperClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 25}}
    , _react2.default.createElement('div', { className: "anonymous--header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
      , _react2.default.createElement('a', {
        href: _urls.homepageUrl,
        target: window.ELECTRON ? '_blank' : '_self',
        className: "anonymous--header--logo", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
      
        , _react2.default.createElement('img', {
          src: "/assets/images/bloomtext-logo.svg",
          alt: "BloomText", __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        )
      )
      , _react2.default.createElement('div', { className: "anonymous--header--help", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
        , _react2.default.createElement('a', {
          href: _urls.supportUrl,
          target: "_blank",
          className: "anonymous--header--help-link", __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}}
        , "Need help?"

        )
      )
    )

    ,  title && (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "anonymous--title--spacer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}} )
        , _react2.default.createElement('h2', { className: "anonymous--title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}},  title )
      )
    )

    , _react2.default.createElement('div', { className: "anonymous--content--spacer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}} )
    , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "anonymous--content", className), __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
      ,  children 
    )

    , _react2.default.createElement('div', { className: "anonymous--signup-notice--spacer", __self: this, __source: {fileName: _jsxFileName, lineNumber: 60}} )
    ,  signupRoute && (
      _react2.default.createElement('div', { className: "anonymous--signup-notice", __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , _react2.default.createElement('span', { className: "anonymous--signup-notice-label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "Don't have an account? "
              , _react2.default.createElement(_reactrouterdom.Link, { to: signupRoute, __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "Sign up" ), "."
        )
      )
    )
    , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "anonymous--footer", footerClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
      , _react2.default.createElement('span', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}, "© " ,  currentYear , " BloomAPI Inc."  )
    )
  )
)

exports. default = PageWrapper
