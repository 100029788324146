"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');


var _selectMyBlobjectTeamSubscribers = require('./selectMyBlobjectTeamSubscribers');



 const selectMyTeamSubscribersArchivedStatus = _toolkit.createSelector.call(void 0, 
  _selectMyBlobjectTeamSubscribers.selectMyBlobjectTeamSubscribers,
  (subs) => subs
    .reduce(
      (acc, ts) => {
        acc[ts.TeamKey] = ts.Completed
        return acc
      },
      {} ,
    ),
); exports.selectMyTeamSubscribersArchivedStatus = selectMyTeamSubscribersArchivedStatus
