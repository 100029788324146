"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxItemAvatar.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');













var _blobject = require('shared/selectors/blobject');
var _user = require('shared/selectors/user');
var _Avatar = require('shared/components/Avatar');





var _Avatar3 = require('web/components/Avatar'); var _Avatar4 = _interopRequireDefault(_Avatar3);

const Patient = (ps) => _react2.default.createElement(_Avatar3.PatientAvatar, { ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}} )
const Team = (ps) => _react2.default.createElement(_Avatar3.TeamAvatar, { ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}} )
const User = (ps) => _react2.default.createElement(_Avatar3.UserAvatar, { ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}} )
const Group = (ps) => _react2.default.createElement(_Avatar3.GroupAvatar, { ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}} )
const Fallback = (ps) => _react2.default.createElement(_Avatar4.default, { icon: "/assets/icons/inbox/inbox.svg", ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}} )







const InboxItemAvatar = ({
  subscribers,
  currentUserKey,
  ...props
}) => (
  _react2.default.createElement(_Avatar.InboxAvatar, {
    ...props,

    currentUserKey: currentUserKey,
    subscribers: subscribers,

    renderUserAvatar: User,
    renderTeamAvatar: Team,
    renderPatientAvatar: Patient,
    renderGroupAvatar: Group,
    renderFallbackAvatar: Fallback, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
  )
)






const makeMapState = () => {
  const selectSubscribers = _blobject.makeSelectUnsafePopulatedBlobjectSubscribers.call(void 0, )

  return (state, props) => ({
    subscribers: selectSubscribers(state, props.bkey),
    currentUserKey: _user.selectCurrentUserKey.call(void 0, state),
  })
}

exports. default = _reactredux.connect.call(void 0, makeMapState)(InboxItemAvatar)
