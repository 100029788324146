"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }



var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);



const initialState = {
  Activated: false,
  ActivatedAt: -1,
  Active: false,
  CreatedAt: -1,
  VerifiedAtTime: "0001-01-01T00:00:00Z",
  Deleted: false,
  Email: "",
  Emails: {},
  EnabledFeatures: [],
  FirstName: "",
  InvitedByOrgAdminKey: "",
  InvitedByOrgAdminWhen: -1,
  LastName: "",
  MobilePushToken: null,
  Name: "",
  Notification_DND_TimeZone: null,
  Notification_DND_StartTime: null,
  Notification_DND_EndTime: null,
  Notification_Emails: false,
  Notification_MuteUntil: -1,
  Notification_SMS: false,
  Notification_Sound: "", /* TODO: sound type here */
  NumberOfLogins: -1,
  Online: false,
  LastActivityPing: "0001-01-01T00:00:00Z",
  LastOnlinePing: "0001-01-01T00:00:00Z",
  Phones: {},
  PostNominals: "",
  PrimaryPhone: "",
  ProfilePictureRef: "",
  QuickLogin: false,
  Relationships: {},
  UserKey: "" ,
  Verified: false,
  WasInvitedByOrgAdmin: false,
}

 function currentUserReducer (state = initialState, action) {
  switch (action.type) {
    case _actionCreators2.default.loginSuccessAction.type: {
      return action.payload.User
    }
    case "SERVER_ACK_SOCKET_CONNECTED":
      return { ...state, MobilePushToken: action.mobilePushToken }
    case _actionCreators2.default.registerMobilePushDeviceActions.SUCCESS.type:
      return {
        ...state,
        MobilePushToken: action.payload.MobilePushToken,
      }
    case _actionCreators2.default.deactivateQuickLoginActions.SUCCESS.type:
      return { ...state, QuickLogin: false }
    case _actionCreators2.default.activateQuickLoginActions.SUCCESS.type:
      return { ...state, QuickLogin: true }
    case _actionCreators2.default.setAvatarActions.SUCCESS.type:
      return {
        ...state,
        ProfilePictureRef: action.meta.Ref,
      }
    case _actionCreators2.default.requestJoinOrgActions.SUCCESS.type:
    case _actionCreators2.default.cancelJoinOrgRequestActions.SUCCESS.type: {
      const users = action.payload.users || action.payload.Users
      return {
        ...state,
        Relationships: users[state.UserKey].Relationships,
      }
    }
    case _actionCreators2.default.setUserMuteUntilActions.SUCCESS.type:
      return { ...state, Notification_MuteUntil: action.payload.Until }

    case _actionCreators2.default.setGlobalNotificationSoundActions.SUCCESS.type:
      return { ...state, Notification_Sound: action.payload.Notification_Sound }

    case _actionCreators2.default.setGlobalNotificationDNDActions.SUCCESS.type:
      return {
        ...state,
        Notification_DND_StartTime: action.payload.StartTime,
        Notification_DND_EndTime: action.payload.EndTime,
        Notification_DND_TimeZone: action.payload.TimeZone,
      }
    case _actionCreators2.default.unsetGlobalNotificationDNDActions.SUCCESS.type:
      return {
        ...state,
        Notification_DND_StartTime: null,
        Notification_DND_EndTime: null,
        Notification_DND_TimeZone: null,
      }
    case _actionCreators2.default.enableMobilePushTokenActions.SUCCESS.type:
      return {
        ...state,
        MobilePushToken: {
          ...state.MobilePushToken,
          Enabled: true,
        },
      }
    case _actionCreators2.default.disableMobilePushTokenActions.SUCCESS.type:
      return {
        ...state,
        MobilePushToken: {
          ...state.MobilePushToken,
          Enabled: false,
        },
      }
    case _actionCreators2.default.getMeActions.SUCCESS.type:
    case _actionCreators2.default.updateUserInfoActions.SUCCESS.type:
    case _actionCreators2.default.addPhoneActions.SUCCESS.type:
    case _actionCreators2.default.addEmailActions.SUCCESS.type:
    case _actionCreators2.default.removePhoneActions.SUCCESS.type:
    case _actionCreators2.default.setPrimaryEmailActions.SUCCESS.type:
    case _actionCreators2.default.setPrimaryPhoneActions.SUCCESS.type:
    case _actionCreators2.default.verifyPhoneActions.SUCCESS.type:
    case _actionCreators2.default.removeEmailActions.SUCCESS.type:
    case _actionCreators2.default.changeUserOrgRoleActions.SUCCESS.type:
    case _actionCreators2.default.changeUserOrgTitleActions.SUCCESS.type:
    case _actionCreators2.default.createAndJoinOrgActions.SUCCESS.type:
    case _actionCreators2.default.updateOrgInfoActions.SUCCESS.type:
    case _actionCreators2.default.leaveOrgActions.SUCCESS.type:
    case _actionCreators2.default.updateOrgUsersNotificationAction.type: {
      const users = action.payload.users || action.payload.Users
      const currentUserObject = users ? users[state.UserKey] : null
      if (!currentUserObject) {
        return state
      }

      return {
        ...state,
        ...currentUserObject,
      }
    }
    case _actionCreators2.default.enableNotificationEmailsActions.SUCCESS.type:
      return { ...state, Notification_Emails: true }
    case _actionCreators2.default.disableNotificationEmailsActions.SUCCESS.type:
      return { ...state, Notification_Emails: false }
    case _actionCreators2.default.enableNotificationSMSActions.SUCCESS.type:
      return { ...state, Notification_SMS: true }
    case _actionCreators2.default.disableNotificationSMSActions.SUCCESS.type:
      return { ...state, Notification_SMS: false }
    case _actionCreators2.default.logoutAction.type:
      return initialState
  }

  return state
} exports.default = currentUserReducer;
