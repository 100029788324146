"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _react = require('react');
var _lodash = require('lodash');
var _ui = require('web/services/ui');

const events = ['mousemove', 'touchend', 'keydown']

 const ACTIVITY_REPORT_DEBOUNCE_MS = 1000; exports.ACTIVITY_REPORT_DEBOUNCE_MS = ACTIVITY_REPORT_DEBOUNCE_MS







const noop = () => null
const Idle = ({ onIdle = noop, onActive = noop, timeout }) => {
  const activityTimer = _react.useRef(null)
  const isActive = _react.useRef.call(void 0, true)

  const handleTimeout = _react.useCallback.call(void 0, 
    () => { isActive.current = false; onIdle() },
    [onIdle],
  )

  const startTimer = _react.useCallback.call(void 0, 
    () => { activityTimer.current = setTimeout(handleTimeout, timeout) },
    [handleTimeout, timeout],
  )
  const killTimer = _react.useCallback.call(void 0, 
    () => { if (activityTimer.current) clearTimeout(activityTimer.current) },
    [],
  )

  const resetTimer = _react.useCallback.call(void 0, 
    () => { killTimer(); startTimer() },
    [startTimer, killTimer],
  )

  const onEvent = _react.useCallback.call(void 0, () => {
    if (!_ui.isWindowVisible.call(void 0, )) return
    if (!isActive.current) onActive()
    isActive.current = true

    resetTimer()
  }, [resetTimer, onActive])

  const handleEvent = _react.useMemo.call(void 0, 
    () => _lodash.debounce.call(void 0, onEvent, exports.ACTIVITY_REPORT_DEBOUNCE_MS, { leading: true }),
    [onEvent],
  )

  const handleVisibilityChange = _react.useCallback.call(void 0, 
    () => {
      if (_ui.isWindowVisible.call(void 0, )) {
        resetTimer()
        onActive()
      } else {
        killTimer()
        handleTimeout()
      }
    },
    [resetTimer, killTimer, onActive, handleTimeout],
  )

  _react.useEffect.call(void 0, 
    () => {
      startTimer()
      window.addEventListener('visibilitychange', handleVisibilityChange)
      events.forEach(e => window.addEventListener(e, handleEvent))

      return () => {
        killTimer()
        window.removeEventListener('visibilitychange', handleVisibilityChange)
        events.forEach(e => window.removeEventListener(e, handleEvent))
      }
    },
    [], // eslint-disable-line react-hooks/exhaustive-deps
  )

  return null
}

exports. default = Idle
