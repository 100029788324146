"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _reselect = require('reselect');

var _features = require('shared/enum/features'); var _features2 = _interopRequireDefault(_features);
var _common = require('./common');
var _upgradeNotices = require('shared/constants/upgradeNotices');

 const selectAvailableMemberSeatsInOrg = _reselect.createSelector.call(void 0, 
  _common.selectOrgV1,
  (org) => {
    const membersCount = _nullishCoalesce(_optionalChain([org, 'optionalAccess', _ => _.NumberOfMembers]), () => ( 0))
    const isFreeOrg = !_optionalChain([org, 'optionalAccess', _2 => _2.EnabledFeatures, 'access', _3 => _3.includes, 'call', _4 => _4(_features2.default.ADVANCED_SEARCH)])
    return isFreeOrg
      ? _upgradeNotices.OVER_LIMIT_MEMBERS_THRESHOLD - membersCount
      : Infinity
  },
); exports.selectAvailableMemberSeatsInOrg = selectAvailableMemberSeatsInOrg

 const isOrgOverLimit = (org) => (
  !org.EnabledFeatures.includes(_features2.default.ADVANCED_SEARCH)
  && org.NumberOfMembers > _upgradeNotices.OVER_LIMIT_MEMBERS_THRESHOLD
); exports.isOrgOverLimit = isOrgOverLimit
