"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/RadioInput.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);

var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

 const RadioOption = ({ label, selected, onClick, className }) => {
  const rootClassName = _classBuilder2.default.call(void 0, 
    "blm-form--radio-input--option",
    selected && "selected",
    className,
  )

  return (
    _react2.default.createElement('div', { className: rootClassName, onClick: onClick, __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
      , _react2.default.createElement('span', { className: "blm-form--radio-input--option--circle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}})
      , _react2.default.createElement('span', { className: "blm-form--radio-input--option--label", __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}, label)
    )
  )
}; exports.RadioOption = RadioOption

 class RadioInput extends _react.Component {
  constructor (props) {
    super(props);RadioInput.prototype.__init.call(this);RadioInput.prototype.__init2.call(this);
    this.state = {
      errorMessage: null,
      value: this.props.value || '',
    }
  }

  __init() {this.onChange = (evt) => {
    const v = evt.target.value
    this.setValue(v)
  }}

  __init2() {this.setValue = (v) => {
    this.setState({ value: v })
    this.props.onChange(this.props.inputId, null, v)
  }}

  render () {
    let { inputId, onChange, className, value, options, label, ...props } = this.props
    className = 'blm-form--input blm-form--radio-input ' +
                (props.disabled ? 'blm-form--disabled ' : '') +
                className
    const optionsElements = options.map((o) => (
      _react2.default.createElement(exports.RadioOption, {
        key: o.value,
        value: o.value,
        label: o.label,
        selected: this.state.value === o.value,
        onClick: () => !props.disabled && this.setValue(o.value), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
      )
    ))

    return (
      _react2.default.createElement('div', { className: className, __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
        , label ? _react2.default.createElement('label', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}, label) : null
        , _react2.default.createElement('div', { className: "blm-form--radio-input--options-container", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
          , optionsElements
        )
      )
    )
  }
} exports.default = RadioInput;

RadioInput.propTypes = {
  // options: PropTypes.array.isRequired,
  options: _proptypes2.default.arrayOf(_proptypes2.default.shape({
    label: _proptypes2.default.string,
    value: _proptypes2.default.any,
  })).isRequired,
  value: _proptypes2.default.any,

  onChange: _proptypes2.default.func.isRequired,
  inputId: _proptypes2.default.string.isRequired,
}

 class ControlledRadioInput extends RadioInput {constructor(...args) { super(...args); ControlledRadioInput.prototype.__init3.call(this);ControlledRadioInput.prototype.__init4.call(this); }
  __init3() {this.setValue = v => {
    const { inputId, onChange } = this.props
    onChange(inputId, null, v)
  }}

  __init4() {this.componentDidUpdate = props => {
    const { value } = this.props
    if (props.value !== value) this.setState({ value })
  }}
} exports.ControlledRadioInput = ControlledRadioInput;
