"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperUsersScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _useSearchQuery = require('shared/hooks/useSearchQuery');
var _Name = require('web/components/Name'); var _Name2 = _interopRequireDefault(_Name);
var _Form = require('web/components/Form');
var _SuperTableTR = require('web/components/Super/SuperTableTR'); var _SuperTableTR2 = _interopRequireDefault(_SuperTableTR);

const UserResult = (props) => {
  return (
    _react2.default.createElement(_SuperTableTR2.default, { to: `/super/users/${props.user.UserKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
      , _react2.default.createElement('td', { className: "uuid", __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}, props.user.UserKey)
      , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}, _react2.default.createElement(_Name2.default, { user: props.user, __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}} ))
      , _react2.default.createElement('td', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}, props.user.Email)
      , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}, props.user.Activated ? 'yes' : 'no')
      , _react2.default.createElement('td', { className: "bool", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}, props.user.Deleted ? 'yes' : 'no')
    )
  )
}

 class SuperUsersScreen extends _react.Component {
  constructor (props) {
    super(props);SuperUsersScreen.prototype.__init.call(this);
    this.state = {
      results: [],
      queryValue: "",
    }
  }

  __init() {this.queryChange = (query) => {
    this.setState({ queryValue: query })
    clearTimeout(this.bounceTimeout)
    this.bounceTimeout = setTimeout(() => {
      _client2.default.send('SuperSearchUsers', { Query: _useSearchQuery.sanitizeSearchQuery.call(void 0, query) })
        .then((results) => {
          this.setState({
            results: Object.values(results),
          })
        }).catch(log.error)
    }, 300)
  }}

  componentDidMount () {
    this.queryChange("")
  }

  render () {
    const results = this.state.results.map((user) => _react2.default.createElement(UserResult, { user: user, key: user.UserKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 47}} ))

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "super--search-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          , _react2.default.createElement(_Form.TextInput, {
            placeholder: "Search for users..."  ,
            wrapperClassName: "super--search-bar",
            onInput: e => this.queryChange(e.target.value),
            value: this.state.queryValue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
          )
        )
        , _react2.default.createElement('table', { className: "super--table", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          , _react2.default.createElement('thead', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
            , _react2.default.createElement('tr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}, "User Key" )
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}, "Name")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}, "Email")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}, "Activated")
              , _react2.default.createElement('th', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}, "Deleted")
            )
          )
          , _react2.default.createElement('tbody', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
            , results
          )
        )
      )
    )
  }
} exports.default = SuperUsersScreen;
