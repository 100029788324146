"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');

var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);
var _utils = require('../utils');

const useCreateNewChat = ({ createdChatTypeURLToken }) => {
  const currentCreatedChatType = _utils.mapURLTokenToChatType.call(void 0, createdChatTypeURLToken)
  const [createdChatType, setCreatedChatType] = _react.useState.call(void 0, currentCreatedChatType)

  const [createdChatRoomType, setCreatedChatRoomType] = _react.useState.call(void 0, null)
  const [createdChatName, setCreatedChatName] = _react.useState.call(void 0, '')

  _react.useEffect.call(void 0, 
    () => {
      if (!currentCreatedChatType) return
      setCreatedChatType(currentCreatedChatType)
    },
    [currentCreatedChatType],
  )

  const onCreateNewChat = _react.useCallback.call(void 0, 
    (type = createdChatRoomType, isNamed) => {
      if (isNamed) setCreatedChatRoomType(type)
      else _utils.gotoCreateNew.call(void 0, type)
    },
    [createdChatRoomType],
  )

  const onCloseCreateNewChat = _react.useCallback.call(void 0, 
    () => {
      setCreatedChatType(null)
      if (currentCreatedChatType) _actions2.default.gotoInbox()
    },
    [currentCreatedChatType, setCreatedChatType],
  )

  const onCloseNewChatRoomModal = _react.useCallback.call(void 0, 
    () => setCreatedChatRoomType(null),
    [setCreatedChatRoomType],
  )

  const onConfirmCreateNewChatRoom = _react.useCallback.call(void 0, 
    chatName => {
      setCreatedChatName(chatName)
      _utils.gotoCreateNew.call(void 0, createdChatRoomType)
      onCloseNewChatRoomModal()
    },
    [setCreatedChatName, createdChatRoomType, onCloseNewChatRoomModal],
  )

  return {
    isCreateChatRoomModalOpen: !!createdChatRoomType,
    onConfirmCreateNewChatRoom,
    onCloseNewChatRoomModal,
    createdChatType,
    createdChatName,
    onCreateNewChat,
    onCloseCreateNewChat,
  }
}

exports. default = useCreateNewChat
