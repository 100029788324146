"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ArchiveModal/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);

var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('./Form'); var _Form2 = _interopRequireDefault(_Form);







const ArchiveModal = ({ isOpen, onClose, blobjectKey }) => (
  _react2.default.createElement(_Modal2.default, {
    title: "Archive for..." ,
    size: "xs",
    isOpen: isOpen,
    onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 16}}
  
    , _react2.default.createElement(_Form2.default, {
      blobjectKey: blobjectKey,
      onSubmit: _actions2.default.setBlobjectCompletedForTeams,
      onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
    )
  )
)

exports. default = ArchiveModal
