"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _helpers = require('shared/actions/helpers');






















var _uuid = require('shared/services/uuid');

const findUsersInOrgActions = _utils.createAsyncActions('FindUsersInOrg')
const findTeamsInOrgActions = _utils.createAsyncActions('FindTeamsInOrg')
const findPatientsInOrgActions = _utils.createAsyncActions('FindPatientsInOrg')
const findUsersInTeamActions = _utils.createAsyncActions('FindUsersInTeam')
const findUsersNotInTeamActions = _utils.createAsyncActions('FindUsersNotInTeam')
const updateTeamActions = _utils.createAsyncActions('UpdateTeam')
const getUsersActions = _utils.createAsyncActions('GetUsers_V5')
const getTeamsActions = _utils.createAsyncActions('GetTeams')
const getPatientActions = _utils.createAsyncActions('GetPatient')
const getOrgsActions = _utils.createAsyncActions('GetOrgs')
const directoryLookupActions = _utils.createAsyncActions('DirectoryLookupV2')
const createTeamActions = _utils.createAsyncActions('CreateTeam')
const removeTeamActions = _utils.createAsyncActions('RemoveTeam')
const addUsersToTeamActions = _utils.createAsyncActions('AddUsersToTeam')
const removeUserFromTeamActions = _utils.createAsyncActions('RemoveUserFromTeam')
const createPatientActions = _utils.createAsyncActions('NewPatient')
const updatePatientActions = _utils.createAsyncActions('UpdatePatientInfo')
const inviteUserToMyOrgActions = _utils.createAsyncActions('InviteUserToMyOrg')
const inviteUserNoOrgActions = _utils.createAsyncActions('InviteUserNoOrg')

 const actionCreators = {
  updateTeamActions,
  findUsersInOrgActions,
  findTeamsInOrgActions,
  findPatientsInOrgActions,
  findUsersInTeamActions,
  findUsersNotInTeamActions,
  getTeamsActions,
  getUsersActions,
  getPatientActions,
  getOrgsActions,
  directoryLookupActions,
  createTeamActions,
  removeTeamActions,
  addUsersToTeamActions,
  removeUserFromTeamActions,
  createPatientActions,
  updatePatientActions,
  inviteUserToMyOrgActions,
  inviteUserNoOrgActions,
}; exports.actionCreators = actionCreators

const findUsersInOrg = (req) =>
  req.OrgKey && req.OrgKey !== _uuid.EMPTY_BLUID
    ? _helpers.wsCommand('FindUsersInOrg', findUsersInOrgActions, req, null)
    : Promise.resolve({ Users: [] })

const findTeamsInOrg = (req) =>
  req.OrgKey && req.OrgKey !== _uuid.EMPTY_BLUID
    ? _helpers.wsCommand('FindTeamsInOrg', findTeamsInOrgActions, req, null)
    : Promise.resolve({ Teams: [] })

const findPatientsInOrg = (req) =>
  req.OrgKey && req.OrgKey !== _uuid.EMPTY_BLUID
    ? _helpers.wsCommand('FindPatientsInOrg', findPatientsInOrgActions, req, null)
    : Promise.resolve({ Patients: [] })

const findUsersInTeam = (req) =>
  _helpers.wsCommand('FindUsersInTeam', findUsersInTeamActions, req, null)

const findUsersNotInTeam = (req) =>
  _helpers.wsCommand('FindUsersNotInTeam', findUsersNotInTeamActions, req, null)

const getUsers = async (req) =>
  req.UserKeys.length
    ? _helpers.wsCommand('GetUsers_V5', getUsersActions, req, null)
    : { Orgs: {}, Users: {} }

const getTeams = async (req) =>
  req.TeamKeys.length
    ? _helpers.wsCommand('GetTeams', getTeamsActions, req, null)
    : { Teams: {} }

const getPatient = (req) =>
  _helpers.wsCommand('GetPatient_V2', getPatientActions, req, null)

const getOrgs = async (req) =>
  req.OrgKeys.length
    ? _helpers.wsCommand('GetOrgs', getOrgsActions, req, null)
    : { Orgs: {} }

const directoryLookup = (req) =>
  _helpers.wsCommand('DirectoryLookupV2', directoryLookupActions, req, null)

const createTeam = (req) =>
  _helpers.wsCommand('CreateTeamAtOrg', createTeamActions, req, null)

const updateTeam = (req) =>
  _helpers.wsCommand('UpdateTeam', updateTeamActions, req, null)

const removeTeam = (payload) =>
  _helpers.wsCommand('RemoveTeam', removeTeamActions, payload, payload)

const addUsersToTeam = (req) =>
  _helpers.wsCommand('AddUsersToTeam', addUsersToTeamActions, req, null)

const removeUserFromTeam = (req) =>
  _helpers.wsCommand('RemoveUserFromTeam', removeUserFromTeamActions, req, null)

const createPatient = (req) =>
  _helpers.wsCommand('NewPatient', createPatientActions, req, null)

const updatePatient = (req) =>
  _helpers.wsCommand('UpdatePatientInfo', updatePatientActions, req, null)

const inviteUserToMyOrg = (req) =>
  _helpers.wsCommand('InviteUserToMyOrg', inviteUserToMyOrgActions, req, null)

const inviteUserNoOrg = (req) =>
  _helpers.wsCommand('InviteUserNoOrg', inviteUserNoOrgActions, req, null)

exports. default = {
  findUsersInOrg,
  findTeamsInOrg,
  findPatientsInOrg,
  findUsersInTeam,
  findUsersNotInTeam,
  getUsers,
  getTeams,
  getPatient,
  getOrgs,
  directoryLookup,
  createTeam,
  updateTeam,
  removeTeam,
  addUsersToTeam,
  removeUserFromTeam,
  createPatient,
  updatePatient,
  inviteUserToMyOrg,
  inviteUserNoOrg,
}
