"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react');


var _useInbox = require('shared/hooks/inbox/useInbox');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);








const EMPTY_MAP = {}

 const useArchiveTransaction = ({
  activeFilter,
  onReset,
  showSuccessBar,
  showWarningBar,
}) => {
  const [archivingBlobjectKeys, setArchivingBlobjectKeys] = _react.useState.call(void 0, EMPTY_MAP)

  const isBlobjectBeingArchived = _react.useCallback.call(void 0, 
    (blobjectKey) => !!archivingBlobjectKeys[blobjectKey],
    [archivingBlobjectKeys],
  )

  const onToggleBlobjectArchived = _react.useCallback.call(void 0, 
    async (blobjectKey) => {
      const shouldArchive = activeFilter === _useInbox.BlobjectFilter.ACTIVE
      const act = async () => {
        const f = shouldArchive
          ? _actions2.default.completeBlobject
          : _actions2.default.uncompleteBlobject

        return f(blobjectKey).then(() => true).catch(() => false)
      }

      const markBlobject = (b) =>
        setArchivingBlobjectKeys(m => ({ ...m, [blobjectKey]: b }))

      markBlobject(true)
      const hasArchivedSuccessfully = await act()
      if (hasArchivedSuccessfully) {
        await onReset().catch(() => null)
        showSuccessBar(`Successfully ${shouldArchive ? 'archived' : 'unarchived'} conversation`)
      } else showWarningBar(`Failed to ${shouldArchive ? 'archive' : 'unarchive'} conversation`)

      markBlobject(false)
    },
    [activeFilter, onReset, showSuccessBar, showWarningBar],
  )

  return {
    onToggleBlobjectArchived,
    isBlobjectBeingArchived,
  }
}; exports.useArchiveTransaction = useArchiveTransaction
