"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

var _reselect = require('reselect');
var _common = require('./common');

var _phone = require('shared/services/phone');








const normalizeEmailConfig = (c) => ({
  isPrimary: c.IsPrimary,
  isVerified: c.IsVerified,
  contact: c.Email,
  displayContact: c.Email,
})

const normalizePhoneConfig = (p) => ({
  isPrimary: p.IsPrimary,
  isVerified: p.IsVerified,
  contact: p.PhoneNumber,
  displayContact: _phone.formatPhoneNumber.call(void 0, p.PhoneNumber),
})

const sortContactConfigs = (a, b) =>
  (+b.isPrimary) - (+a.isPrimary) ||
  (+b.isVerified) - (+a.isVerified)

 const selectMyEmailsList = _reselect.createSelector.call(void 0, 
  _common.selectCurrentUser,
  me => Object
    .values(_optionalChain([me, 'optionalAccess', _ => _.Emails]) || {})
    .map(normalizeEmailConfig)
    .sort(sortContactConfigs),
); exports.selectMyEmailsList = selectMyEmailsList

 const selectMyPhonesList = _reselect.createSelector.call(void 0, 
  _common.selectCurrentUser,
  me => Object
    .values(_optionalChain([me, 'optionalAccess', _2 => _2.Phones]) || {})
    .map(normalizePhoneConfig)
    .sort(sortContactConfigs),
); exports.selectMyPhonesList = selectMyPhonesList
