"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/ConfirmDeleteTeamModal.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _phone = require('shared/services/phone');
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);










const ConfirmDeleteTeamModal = ({
  isOpen, onClose, onProceed,
  teamName, orgName, phoneNumber,
}) => (
  _react2.default.createElement(_Modal2.default, {
    title: "Delete team" ,
    isOpen: isOpen,
    onClose: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
  
    ,  phoneNumber
      ?
        _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}, "This team is associated with phone number "
                   , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 27}},  _phone.formatPhoneNumber.call(void 0, phoneNumber) ), " and can't be deleted."
          )
          , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}, "Please contact "
              , _react2.default.createElement('a', { href: "mailto:support@bloomtext.com", __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "support@bloomtext.com"), " if you want to re-assign or release this phone number."
          )
        )
      : _react2.default.createElement(_react2.default.Fragment, null
          , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}, "Are you sure you want to delete this team?"        )
          , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 35}}, "You and anyone in "
                , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}},  orgName ), " will lose access to chats they participated in on behalf of "            , _react2.default.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 36}},  teamName ), " team."
          )
        )
    
    , _react2.default.createElement(_Modal.ModalControls, {
      primaryBtnLabel: "Delete",
      primaryBtnTheme: "danger",
      isPrimaryEnabled: !phoneNumber,
      onPrimaryBtnClick: onProceed,
      secondaryBtnLabel: "Cancel",
      onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
    )
  )
)

exports. default = ConfirmDeleteTeamModal
