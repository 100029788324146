"use strict";Object.defineProperty(exports, "__esModule", {value: true});/* These functions transform server eshelper server responses into client-ready view models.
Currently built for handling responses for client.Search() but could be
generalized to handle responses related to chats, messages, avatars, etc.  */

/* chat or file types */
const transformConversationItem = (itemResp, currentUser, navFunc) => {
  return Object.values(itemResp)
    .sort((a, b) => b.TimeStamp.localeCompare(a.TimeStamp))
    .map(item => ({
      id: `${item.ConversationKey}-${item.MessageKey}`,
      title: item.Title,
      subtitle: item.Subtitle,
      timestamp: item.TimeStamp,
      clickActionProps: {
        bKey: item.BlobKey,
        cKey: item.ConversationKey,
        type: "chat", /* TODO: Fix this */
        mKey: item.MessageKey,
      },
      bKey: item.BlobKey,
    }))
}

const transformConversation = (convResp, currentUser, navFunc) => {
  return Object.values(convResp)
    .sort((a, b) => b.InboxSortTime.localeCompare(a.InboxSortTime))
    .map(c => ({
      id: `${c.Key}-${c.WrappedKey}`,
      title: c.Name,
      subtitle: c.Description,
      timestamp: c.UpdatedAt,
      clickActionProps: {
        bKey: c.Key,
        cKey: c.WrappedKey,
        type: c.WrappedType,
        mKey: "",
      },
      bKey: c.Key,
    }))
}

// transform eshelper.Message model to file item
const transformToFileItem = (fileResp, myKey) => {
  return Object.keys(fileResp).map(k => fileResp[k])
    .sort((a, b) => b.TimeStamp.localeCompare(a.TimeStamp))
    .map(f => ({
      messageKey: f.MessageKey,
      chatKey: f.ChatKey,
      chatName: f.ChatName,
      fileName: f.FileName,
      timestamp: f.SentTimestamp,
      image: 'assets/images/file.png',
    }))
}

const transformToConversationItem = (resp, myKey) => {
  return Object.values(resp)
    .sort((a, b) => b.TimeStamp.localeCompare(a.TimeStamp))
    .map(b => {
      return ({
        bKey: b.Key,
        cKey: b.WrappedKey,
        name: b.Name,
        description: generateConverationDescription(b),
        timestamp: b.UpdatedAt,
        image: selectConversationImage(b),
      })
    })
}

/* todo: align this with header descriptions */
const generateConverationDescription = (blobject) => {
  if (blobject.Description.length > 0) {
    return blobject.Description
  }
}

const selectConversationImage = (blobject, myUserKey) => {
  const totalSubscribers = Object.values(blobject.UserSubscribers).length
    + Object.values(blobject.TeamSubscribers).length
    + Object.values(blobject.PatientSubscribers).length

  if (totalSubscribers > 1 || Object.values(blobject.TeamSubscribers).length > 0) {
    return "assets/images/group.png"
  }
  return "assets/avatars/default.png"
  /* todo grab users's personal image */
}






exports.transformToConversationItem = transformToConversationItem; exports.transformToFileItem = transformToFileItem; exports.transformConversation = transformConversation; exports.transformConversationItem = transformConversationItem;
