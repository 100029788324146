"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/AlertsScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');
var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _AlertComponent = require('web/components/AlertComponent'); var _AlertComponent2 = _interopRequireDefault(_AlertComponent);

class AlertsScreen extends _react.Component {constructor(...args) { super(...args); AlertsScreen.prototype.__init.call(this); }
  __init() {this.onClose = () => {
    _actions2.default.clearAlerts()
  }}

  render () {
    const { alerts } = this.props

    if (alerts.length === 0) {
      return null
    }

    const alert = alerts[0]

    return (
      _react2.default.createElement(_AlertComponent2.default, {
        key: alert.key,
        title: alert.title,
        body: alert.body,
        type: alert.type,
        onClose: () => this.onClose(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}})
    )
  }
}

const mapState = state => ({ alerts: state.alerts })
exports. default = _reactredux.connect.call(void 0, mapState)(AlertsScreen)
