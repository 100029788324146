"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Signup/Setup/Sections/Password/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Form = require('web/components/Form');
var _PasswordInput = require('web/components/PasswordInput'); var _PasswordInput2 = _interopRequireDefault(_PasswordInput);
var _components = require('web/screens/Signup/components');
var _app = require('shared/constants/app');







const passwordInputId = 'password'
const passwordConfirmationInputId = 'passwordConfirmation'

const PasswordSection = ({ password: presetPassword, onSubmit, onBack }) => {
  const [password, setPassword] = _react.useState.call(void 0, presetPassword || '')
  const [passwordConfirmation, setPasswordConfirmation] = _react.useState.call(void 0, presetPassword || '')
  const [isPasswordStrong, setIsPasswordStrong] = _react.useState.call(void 0, false)

  const isConfirmationCorrect = password === passwordConfirmation
  const shouldShowMismatchWarning = passwordConfirmation && !isConfirmationCorrect
  const canContinue = password && isPasswordStrong && isConfirmationCorrect

  const handleChange = _react.useCallback.call(void 0, 
    (id, val) => {
      if (id === passwordInputId) setPassword(val)
      if (id === passwordConfirmationInputId) setPasswordConfirmation(val)
    },
    [],
  )

  const handleSubmit = _react.useCallback.call(void 0, 
    e => {
      e.preventDefault()
      onSubmit(password)
    },
    [onSubmit, password],
  )

  return (
    _react2.default.createElement('form', { onSubmit: handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
      , _react2.default.createElement(_components.SignupStepHeader, {
        iconType: "lock",
        title: "Set up your access"   ,
        subtitle: 
          _react2.default.createElement(_react2.default.Fragment, null
            , _react2.default.createElement('span', { className: "blmh-desktop-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}, "Let's setup a password"   )
            , _react2.default.createElement('span', { className: "blmh-mobile-hide", __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}, "Let's set up a password for your account."       )
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 44}}
      )
      , _react2.default.createElement(_Form.InputSectionWrapper, { title: "New Password" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}
        , _react2.default.createElement(_PasswordInput2.default, {
          autoFocus: true,
          value: password,
          inputId: passwordInputId,
          onChange: handleChange,
          onValidated: setIsPasswordStrong,
          placeholder: "Enter a new password"   ,
          autoComplete: "new-password", __self: this, __source: {fileName: _jsxFileName, lineNumber: 55}}
        )
      )

      , _react2.default.createElement(_Form.InputSectionWrapper, {
        title: "Confirm password" ,
        errorMessage: shouldShowMismatchWarning
          ? "Your passwords don't match. Make sure you entered your new password correctly."
          : ''
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 66}}
      
        , _react2.default.createElement(_PasswordInput2.default, {
          value: passwordConfirmation,
          inputId: passwordConfirmationInputId,
          onChange: handleChange,
          placeholder: "Confirm password" ,
          autoComplete: "new-password",
          maxLength: _app.MAX_PASSWORD_LENGTH, __self: this, __source: {fileName: _jsxFileName, lineNumber: 73}}
        )
      )
      , _react2.default.createElement(_components.SignupStepControls, { onBack: onBack, isNextDisabled: !canContinue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} )
    )
  )
}

exports. default = PasswordSection
