"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _toolkit = require('@reduxjs/toolkit');

var _user = require('shared/selectors/user');
var _common = require('./common');
var _time = require('shared/services/time');

 const selectMyBlobjectLastSeenTime = _toolkit.createSelector.call(void 0, 
  _common.selectBlobjectByKey,
  _user.selectCurrentUserKey,
  (blobject, userKey) => {
    if (!blobject) return _time.EPOCH

    const myMetadata = blobject.UserMetadata[userKey]
    return myMetadata ? myMetadata.SeenTime : _time.EPOCH
  },
); exports.selectMyBlobjectLastSeenTime = selectMyBlobjectLastSeenTime
