"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ArchiveModal/Form/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');









var _blobject = require('shared/selectors/blobject');
var _noticeBar = require('web/services/context/noticeBar');

var _Modal = require('web/components/Modal');
var _TeamGroup = require('./TeamGroup'); var _TeamGroup2 = _interopRequireDefault(_TeamGroup);
var _TeamOption = require('./TeamOption'); var _TeamOption2 = _interopRequireDefault(_TeamOption);









const ArchiveForm = ({
  blobjectKey,
  teamsArchiveStatus,
  myChatTeamGroups,
  onClose,
  onSubmit,
}) => {
  const [statusMap, setStatusMap] = _react.useState.call(void 0, teamsArchiveStatus)
  const [isSubmitting, setIsSubmitting] = _react.useState.call(void 0, false)
  _react.useEffect.call(void 0, () => setStatusMap(teamsArchiveStatus), [teamsArchiveStatus])

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const handleSubmit = () => {
    setIsSubmitting(true)
    onSubmit({ BKey: blobjectKey, TeamKeys: statusMap })
      .then(onClose)
      .then(() => showSuccessBar('Successfully applied archive settings'))
      .catch(() => showWarningBar('Failed to apply archive settings'))
      .finally(() => setIsSubmitting(false))
  }

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      ,  myChatTeamGroups.map(g => (
        _react2.default.createElement(_TeamGroup2.default, { key: g.orgKey, orgName: g.orgName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
          ,  g.teams.map(t => (
            _react2.default.createElement(_TeamOption2.default, {
              key: t.TeamKey,
              team: t,
              isChecked: !!statusMap[t.TeamKey],
              onChange: b => setStatusMap(m => ({ ...m, [t.TeamKey]: b })), __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
            )
          ))
        )
      ))
      , _react2.default.createElement(_Modal.ModalControls, {
        primaryBtnLabel: "Save",
        secondaryBtnLabel: "Cancel",
        isPrimaryLoading: isSubmitting,
        onPrimaryBtnClick: handleSubmit,
        onSecondaryBtnClick: onClose, __self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
      )
    )
  )
}






const mapState = (state, { blobjectKey }) => ({
  teamsArchiveStatus: _blobject.selectMyTeamSubscribersArchivedStatus.call(void 0, state, blobjectKey),
  myChatTeamGroups: _blobject.selectMyChatTeamGroups.call(void 0, state, blobjectKey),
})

exports. default = _reactredux.connect.call(void 0, mapState)(ArchiveForm)
