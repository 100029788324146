"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _joi = require('@hookform/resolvers/joi');

var _joi3 = require('shared/services/validation/joi'); var _joi4 = _interopRequireDefault(_joi3);





const anonymousNameValidationSchema = _joi4.default.object({
  name: _joi3.requiredString.call(void 0, ).max(100).messages(_joi3.makeStrErrors.call(void 0, 'Name')),
})

 const anonymousNameResolver = _joi.joiResolver.call(void 0, anonymousNameValidationSchema); exports.anonymousNameResolver = anonymousNameResolver
