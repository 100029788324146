"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Welcome/FirstTimeModal/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);








const FirstTimeModal = ({ onClose, onAddPeople }) => {
  const handleAddPeople = _react.useCallback.call(void 0, 
    () => { onAddPeople(); onClose() },
    [onAddPeople, onClose],
  )

  return (
    _react2.default.createElement(_react2.default.Fragment, null
      , _react2.default.createElement('div', { className: "ftue-modal--main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 18}}
        , _react2.default.createElement('img', {
          className: "ftue-modal--icon",
          src: "/assets/images/welcome/coworkers-icon.svg",
          alt: "Co-workers", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
        )
        , _react2.default.createElement('span', { className: "ftue-modal--text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "BloomText lets you communicate with coworkers and patients from any health organization through a fully HIPAA compliant service."                 )
      )
      , _react2.default.createElement('div', { className: "ftue-modal--sub", __self: this, __source: {fileName: _jsxFileName, lineNumber: 26}}
        , _react2.default.createElement('h5', { className: "ftue-modal--subtitle", __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}, "What's next?" )
        , _react2.default.createElement('span', { className: "ftue-modal--subtext", __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}, "Let's bring your team together."    )
        , _react2.default.createElement('div', { className: "ftue-modal--buttons", __self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
          , _react2.default.createElement('a', { onClick: handleAddPeople, className: "blmh-button purple highlight"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}, "Add coworkers"

          )
          , _react2.default.createElement('a', { onClick: onClose, className: "blmh-button purple secondary"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}, "Not now"

          )
        )
      )
    )
  )
}

exports. default = FirstTimeModal
