"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Pagination/PageNumber.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);








const PageNumber = ({ number, currentPage, onClick, className }) => {
  const isActive = currentPage === number

  return (
    _react2.default.createElement('button', {
      'aria-current': isActive,
      'aria-label': `${isActive ? "Current page, " : ''}Page #${number}`,
      className: _classBuilder2.default.call(void 0, className, isActive && "blm-pagination--btn--active"),
      onClick: !isActive ? onClick : undefined, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
    
      ,  number + 1 
    )
  )
}

exports. default = PageNumber
