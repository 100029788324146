"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);

const padDuration = x => x.toString().padStart(2, '0')

 const formatTimingLabels = (rawStartedAt, rawEndedAt) => {
  const startedAt = rawStartedAt
    ? _moment2.default.call(void 0, rawStartedAt).calendar()
    : null
  const durationValue = rawStartedAt && rawEndedAt
    ? _moment2.default.duration(_moment2.default.call(void 0, rawEndedAt).diff(rawStartedAt))
    : null
  const duration = durationValue
    ? `${padDuration(durationValue.hours())}:${padDuration(durationValue.minutes())}:${padDuration(durationValue.seconds())}`
    : null

  return {
    startedAt: startedAt || 'N/A',
    duration: duration || 'N/A',
  }
}; exports.formatTimingLabels = formatTimingLabels
