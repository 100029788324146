"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Upsells/Broadcasts.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Page = require('web/components/Upsells/Page'); var _Page2 = _interopRequireDefault(_Page);





const BroadcastsUpsells = ({ onRequest }) => (
  _react2.default.createElement(_Page2.default, {
    className: "upsells--video",
    title: "Schedule appointments, send clinical updates and more!"      ,
    desc: "Send HIPAA-compliant SMS messages or emails. Receive patient responses via private conversations."           ,
    hint: "Want to get full access to broadcast campaigns?"       ,
    imgUrl: "assets/images/upsells/patients-illustration.png",
    onRequest: onRequest, __self: this, __source: {fileName: _jsxFileName, lineNumber: 10}}
  )
)

exports. default = BroadcastsUpsells
