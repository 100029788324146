"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/VideoCall/Modals/InviteParticipants.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);

class ParticipantPill extends _react2.default.Component {constructor(...args) { super(...args); ParticipantPill.prototype.__init.call(this);ParticipantPill.prototype.__init2.call(this); }
  __init() {this.handleRemove = () => this.props.onRemove(this.props.idx)}
  __init2() {this.render = () => (
    _react2.default.createElement('div', { className: "meeting--bulk-invite-modal--form-pill", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      , _react2.default.createElement('span', { className: "meeting--bulk-invite-modal--form-pill-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
        , this.props.name
      )
      , _react2.default.createElement('div', {
        className: "meeting--bulk-invite-modal--form-pill-remove",
        onClick: this.handleRemove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}}
      
        , _react2.default.createElement('img', { src: "/assets/icons/video/cross-grey.svg", alt: "x", __self: this, __source: {fileName: _jsxFileName, lineNumber: 15}} )
      )
    )
  )}
}

 class InviteParticipantsModal extends _react2.default.Component {constructor(...args2) { super(...args2); InviteParticipantsModal.prototype.__init3.call(this);InviteParticipantsModal.prototype.__init4.call(this);InviteParticipantsModal.prototype.__init5.call(this);InviteParticipantsModal.prototype.__init6.call(this);InviteParticipantsModal.prototype.__init7.call(this); }
  __init3() {this.state = {
    participants: [],
    participantInputValue: '',
  }}

  __init4() {this.handleInput = e => {
    const { participantInputValue, participants } = this.state

    if (e.key === 'Enter' && participantInputValue) {
      this.setState({
        participantInputValue: '',
        participants: participants.concat([participantInputValue]),
      })
    }
  }}

  __init5() {this.handleChange = e => this.setState({
    participantInputValue: e.target.value,
  })}

  __init6() {this.handleRemove = idx => this.setState({
    participants: this.state.participants.filter((_, i) => i !== idx),
  })}

  __init7() {this.handleSubmit = () => this.props.onClose()}

  render () {
    const { isOpen, onClose } = this.props
    const { participants, participantInputValue } = this.state

    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: isOpen,
        onClose: onClose,
        title: "Invite Participants" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}
      
        , _react2.default.createElement(_Modal.ModalDesc, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}, "Invite participants to this meeting room by entering their email address or phone number."

        )
        , _react2.default.createElement('div', { className: "meeting--bulk-invite-modal--form", __self: this, __source: {fileName: _jsxFileName, lineNumber: 61}}
          ,  participants.map((name, idx) => (
            _react2.default.createElement(ParticipantPill, {
              onRemove: this.handleRemove,
              key: idx,
              name: name,
              idx: idx, __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
            )
          ))
          , _react2.default.createElement('input', {
            autoFocus: true,
            className: "meeting--bulk-invite-modal--form-input",
            placeholder: "Email or phone number"   ,
            type: "text",
            value: participantInputValue,
            onKeyPress: this.handleInput,
            onChange: this.handleChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
          )
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: onClose,
          isPrimaryEnabled: !!participants.length,
          onPrimaryBtnClick: this.handleSubmit,
          primaryBtnLabel: "Add", __self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}
        )
      )
    )
  }
} exports.default = InviteParticipantsModal;
