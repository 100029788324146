"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Settings/Security/DeviceSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _Form = require('web/components/Form');






const DeviceSection = ({
  isDeviceApproved,
  onToggleDeviceApproval,
}) => (
  _react2.default.createElement('div', { className: "settings--login--device", __self: this, __source: {fileName: _jsxFileName, lineNumber: 13}}
    , _react2.default.createElement(_Form.Toggle, {
      isChecked: isDeviceApproved,
      onChange: onToggleDeviceApproval,
      label: "Approve this device"  ,
      hint: "Approve your current device to enable Quick Login. You can disapprove this device at any time in the future."                  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 14}}
    )
  )
)

exports. default = DeviceSection
