"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Header/CampaignSettings.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Tooltip = require('web/components/Tooltip');
var _Header = require('web/components/RightSide/Header');








const noop = () => null
const CampaignSettings = ({
  isCampaignArchived,
  onEditCampaign,
  onExportCampaign,
  onQueueMessages,
}) => (
  _react2.default.createElement(_Header.HeaderMoreToggle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
    , _react2.default.createElement(_Tooltip.TooltipMenuItem, {
      iconSrc: "/assets/icons/broadcast/waiting.svg",
      label: "Queue messages" ,
      disabled: isCampaignArchived || !onQueueMessages,
      desc: isCampaignArchived ? "Can't start an archived campaign" : '',
      onClick: onQueueMessages || noop, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    )
    , _react2.default.createElement(_Tooltip.TooltipMenuItem, {
      iconSrc: "/assets/icons/inbox/send-message.svg",
      disabled: isCampaignArchived,
      label: "Edit this campaign"  ,
      desc: isCampaignArchived ? "Can't edit an archived campaign" : '',
      onClick: onEditCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
    )
    ,  onExportCampaign && (
      _react2.default.createElement(_Tooltip.TooltipMenuItem, {
        iconSrc: "/assets/icons/icon-download-dark-grey.svg",
        label: "Export this campaign"  ,
        onClick: onExportCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
      )
    )
  )
)

exports. default = CampaignSettings
