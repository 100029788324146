"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react');


var _upgrade = require('shared/enum/upgrade');
var _map = require('shared/services/map');





 const useRelevantItems = ({ items }) => {
  const [selectedSmsTier, setSelectedSmsTier] = _react.useState(null)

  const {
    teamItem, smsItem, phoneNumberItem, enabledSmsItem, smsItemsList, smsItemsMap,
  } = _react.useMemo.call(void 0, 
    () => {
      const teamItem = items.find(i => i.Type === _upgrade.SubscriptionItemType.TEAM)
      const smsItemsList = items.filter(i => i.Type === _upgrade.SubscriptionItemType.SMS)
      const phoneNumberItem = items.find(i => i.Type === _upgrade.SubscriptionItemType.PHONE_NUMBER)
      const enabledSmsItem = smsItemsList.find(i => i.Enabled)
      const smsItem =
        smsItemsList.find(i => i.Units.toString() === selectedSmsTier)
        || enabledSmsItem

      const smsItemsMap = _map.listToMap.call(void 0, smsItemsList, i => i.Units)
      return {
        teamItem,
        smsItem,
        phoneNumberItem,
        enabledSmsItem,
        smsItemsList,
        smsItemsMap,
      }
    },
    [items, selectedSmsTier],
  )

  const smsItemMinCost = _optionalChain([smsItemsList, 'access', _ => _[0], 'optionalAccess', _2 => _2.BaseCost]) || 0 

  _react.useEffect.call(void 0, 
    () => {
      if (!selectedSmsTier) {
        const preselectedItem = smsItemsList.find(i => i.Enabled) || smsItemsList[0]
        setSelectedSmsTier(_optionalChain([preselectedItem, 'optionalAccess', _3 => _3.Units, 'access', _4 => _4.toString, 'call', _5 => _5()]) || null)
      }
    },
    [smsItemsList, selectedSmsTier],
  )

  return {
    teamItem,
    smsItem,
    phoneNumberItem,
    enabledSmsItem,
    smsItemsList,
    smsItemMinCost,
    smsItemsMap,
    selectedSmsTier,
    setSelectedSmsTier,
  }
}; exports.useRelevantItems = useRelevantItems
