"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Conversation/LoadingIndicator.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);







const LoadingIndicator = ({ style, isTop, isBottom }) => (
  _react2.default.createElement('div', {
    style: style,
    className: _classBuilder2.default.call(void 0, 
      "conversation-box--spinner--wrapper",
      isTop && "conversation-box--spinner--wrapper--top",
      isBottom && "conversation-box--spinner--wrapper--bottom",
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
  
    , _react2.default.createElement('div', { className: "conversation-box--spinner", __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}} )
  )
)

exports. default = LoadingIndicator
