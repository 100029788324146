"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/ImgSrcSet.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);






// eslint-disable-next-line
const ImgSrcSet = ({ src, x2 = false, x3 = false, ...props }) => {
  // const filename = src.split('.')
  // const name = filename.slice(0, -1)
  // const ext = filename.slice(-1)

  const srcSet = [
    `${src} 1x`,
    // x2 && `${name}@2x.${ext} 2x`, TODO consider re-enabling x2 and x3 after scaling down all images a bit
    // x3 && `${name}@3x.${ext} 3x`
  ].filter(x => !!x).join(', ')

  return (
    _react2.default.createElement('img', { src: src, srcSet: srcSet, ...props, __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}} )
  )
}

exports. default = ImgSrcSet
