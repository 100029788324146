"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Super/SuperTeamScreen.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _client = require('shared/services/client'); var _client2 = _interopRequireDefault(_client);
var _SuperTeamMembers = require('web/components/Super/SuperTeamMembers'); var _SuperTeamMembers2 = _interopRequireDefault(_SuperTeamMembers);
var _Modal = require('web/components/Modal'); var _Modal2 = _interopRequireDefault(_Modal);
var _Form = require('web/components/Form');
var _proptypes = require('prop-types'); var _proptypes2 = _interopRequireDefault(_proptypes);
var _log = require('shared/log');

 class SuperTeamScreen extends _react.Component {
  constructor (props) {
    super(props);SuperTeamScreen.prototype.__init.call(this);SuperTeamScreen.prototype.__init2.call(this);SuperTeamScreen.prototype.__init3.call(this);
    this.state = {
      team: {},
      loading: true,
      error: '',
      modifyUsersModalOpen: false,
    }
  }

  componentDidMount () {
    this.refreshTeamData()
  }

  __init() {this.refreshTeamData = () => {
    _client2.default.send('SUPER_GetTeams', { TeamKeys: [this.props.match.params.teamKey] }).then((r) => {
      this.setState({
        team: r.Teams[this.props.match.params.teamKey],
        loading: false,
      })
    }).catch((err) => {
      _log.log.error(err)
      this.setState({
        loading: false,
        error: err,
      })
    })
  }}

  __init2() {this.openModifyUsersModal = () => {
    this.setState({
      modifyUsersModalOpen: true,
    })
  }}

  __init3() {this.closeModifyUsersModal = () => {
    this.setState({
      modifyUsersModalOpen: false,
    })
  }}

  render () {
    if (this.state.loading) {
      return (
        _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 54}}, "Loading..."

        )
      )
    } else if (!this.state.loading && this.state.error.length > 0) {
      return (
        _react2.default.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 60}}
          ,  this.state.error 
        )
      )
    }

    return (
      _react2.default.createElement(_react2.default.Fragment, null
        , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 68}}
          , this.state.modifyUsersModalOpen ? (
            _react2.default.createElement(SuperTeamUserModal, {
              team: this.state.team,
              open: this.state.modifyUsersModalOpen,
              onClose: this.closeModifyUsersModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 70}}
            )
          ) : null 
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 76}}, "Properties")
          , _react2.default.createElement('dl', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 77}}
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 78}}, "Team Key" )
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}, this.state.team.TeamKey))
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 80}}, "Org Key" )
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, _react2.default.createElement('tt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, _react2.default.createElement('a', { href: `#/super/orgs/${this.state.team.OrgKey}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}, this.state.team.OrgKey)))
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}, "Name")
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}, this.state.team.Name)
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 84}}, "Deleted")
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}, this.state.team.Deleted ? 'yes' : 'no')
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 86}}, "Created At" )
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}, this.state.team.CreatedAt)
            , _react2.default.createElement('dt', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}, "Updated At" )
            , _react2.default.createElement('dd', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}, this.state.team.UpdatedAt)
          )
        )
        , _react2.default.createElement('div', { className: "superuser--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}}
          , _react2.default.createElement('h4', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 93}}, "Members")
          ,  Object.keys(this.state.team.Members).length > 0 ? _react2.default.createElement(_SuperTeamMembers2.default, { members: this.state.team.Members, __self: this, __source: {fileName: _jsxFileName, lineNumber: 94}} ) : _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 94}}, "None") 
          , _react2.default.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, _react2.default.createElement('button', { onClick: this.openModifyUsersModal, className: "blmh-button", __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}, "Modify"))
        )
      )
    )
  }
} exports.default = SuperTeamScreen;

class SuperTeamUserModal extends _react.Component {
  constructor (props) {
    super(props);SuperTeamUserModal.prototype.__init4.call(this);SuperTeamUserModal.prototype.__init5.call(this);SuperTeamUserModal.prototype.__init6.call(this);SuperTeamUserModal.prototype.__init7.call(this);
    this.state = {
      addedByKey: '',
      userKeys: [],
      addOrRemove: 'add',
    }
  }

  __init4() {this.onUserKeysChange = (_id, _err, val) => {
    const userKeysArray = val.split("\n")
    this.setState({
      userKeys: userKeysArray,
    })
  }}

  __init5() {this.onChange = (id, _err, val) => {
    this.setState({
      [id]: val,
    })
  }}

  __init6() {this.canSend = () => {
    if (this.state.addedByKey === ''
    || this.state.userKeys.length === 0
    || (this.state.addOrRemove !== 'add' && this.state.addOrRemove !== 'remove')) {
      return false
    }

    return true
  }}

  __init7() {this.onSubmit = () => {
    if (this.state.addOrRemove === 'add') {
      /* adding users to team */
      _client2.default.send('SuperAddUsersToTeam', {
        AddedByUserKey: this.state.addedByKey,
        UserKeys: this.state.userKeys,
        TeamKey: this.props.team.TeamKey,
      }).then(() => {
        _log.log.info('initiated AddUsersToTeamAction')
        this.props.onClose()
      }).catch(_log.log.error)
    } else if (this.state.addOrRemove === 'remove') {
      /* adding a user */
      _client2.default.send('SuperRemoveUsersFromTeam', {
        RemovedByUserKey: this.state.addedByKey,
        UserKeys: this.state.userKeys,
        TeamKey: this.props.team.TeamKey,
      }).then(() => {
        this.props.onClose()
        _log.log.info('initiated RemoveUsersFromTeam')
      }).catch(_log.log.error)
    } else {
      _log.log.info(`Error: addOrRemove must be 'add' or 'remove' but got:`, this.state.addOrRemove)
    }
  }}

  render () {
    const addOrRemoveOptions = [
      { value: "add", label: "Add Users" },
      { value: "remove", label: "Remove Users" },
    ]

    const canSend = this.canSend()
    return (
      _react2.default.createElement(_Modal2.default, {
        isOpen: this.props.open,
        onClose: this.props.onClose,
        title: `Modify Team - ${this.props.team.Name}`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 169}}
      
        , _react2.default.createElement(_Modal.ModalSubtitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 174}}, "Create a bulk patient campaign for this org"       )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Modified by User Key"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 175}}
          , _react2.default.createElement(_Form.TextInput, {
            onChange: e => this.onChange('addedByKey', null, e.target.value),
            placeholder: "User must be part of organization"     ,
            value: this.state.addedByKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}
          )
        )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "User Keys" , __self: this, __source: {fileName: _jsxFileName, lineNumber: 182}}
          , _react2.default.createElement(_Form.SimpleTextArea, {
            onChange: v => this.onUserKeysChange('userKeys', null, v),
            label: "User Keys" ,
            placeholder: "One UserKey per line"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 183}}
          )
        )
        , _react2.default.createElement(_Form.InputSectionWrapper, { title: "Add or Remove Users"   , __self: this, __source: {fileName: _jsxFileName, lineNumber: 189}}
          , _react2.default.createElement(_Form.RadioInput, {
            options: addOrRemoveOptions,
            onChange: this.onChange,
            inputId: "addOrRemove",
            value: this.state.addOrRemove, __self: this, __source: {fileName: _jsxFileName, lineNumber: 190}}
          )
        )
        , _react2.default.createElement(_Modal.ModalControls, {
          secondaryBtnLabel: "Cancel",
          onSecondaryBtnClick: this.props.onClose,
          primaryBtnLabel: "Submit",
          isPrimaryEnabled: canSend,
          onPrimaryBtnClick: this.onSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 197}}
        )
      )
    )
  }
}

SuperTeamUserModal.propTypes = {
  open: _proptypes2.default.bool.isRequired,
  onClose: _proptypes2.default.func.isRequired,
  team: _proptypes2.default.object.isRequired,
}
