"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/Sidebar/CampaignsList/CampaignItem.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _types = require('./types');
var _broadcast = require('shared/selectors/broadcast');
var _team = require('shared/selectors/team');

var _noticeBar = require('web/services/context/noticeBar');
var _Avatar = require('web/components/Avatar');
var _CampaignStatus = require('web/screens/Broadcast/CampaignStatus'); var _CampaignStatus2 = _interopRequireDefault(_CampaignStatus);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);










const CampaignItem = ({
  campaignKey,
  campaignName,
  activeFilter,
  teamName,
  isViewed,
  onClick,
  isCampaignActive,
  isCampaignArchived,
  onArchive,
  onUnarchive,
}) => {
  const { showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  if (activeFilter === _types.Filter.active
    ? isCampaignArchived
    : !isCampaignArchived
  ) return null

  return (
    _react2.default.createElement('div', {
      onClick: onClick,
      role: "button",
      className: _classBuilder2.default.call(void 0, 
        "broadcast-list--campaign",
        isViewed && "broadcast-list--campaign--viewed",
      ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
    
      , _react2.default.createElement('div', { className: "broadcast-list--campaign--content", __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        , _react2.default.createElement(_Avatar.CampaignAvatar, {
          size: 44,
          campaignKey: campaignKey,
          className: "broadcast-list--campaign-avatar", __self: this, __source: {fileName: _jsxFileName, lineNumber: 52}}
        )
        , _react2.default.createElement('div', { className: "broadcast-list--campaign-info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
          , _react2.default.createElement('span', { className: "broadcast-list--campaign-name", __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}},  campaignName )
          , _react2.default.createElement(_CampaignStatus2.default, {
            campaignKey: campaignKey,
            className: "broadcast-list--campaign-desc", __self: this, __source: {fileName: _jsxFileName, lineNumber: 59}}
          )
          ,  teamName && (
            _react2.default.createElement('div', { className: "broadcast-list--campaign--team-badge--wrapper", __self: this, __source: {fileName: _jsxFileName, lineNumber: 64}}
              , _react2.default.createElement('span', { className: "broadcast-list--campaign--team-badge", __self: this, __source: {fileName: _jsxFileName, lineNumber: 65}}
                ,  teamName 
              )
            )
          )
        )
        ,  (isCampaignArchived || !isCampaignActive) && (
          _react2.default.createElement('div', {
            role: "button",
            className: "broadcast-list--campaign-action",
            onClick: e => {
              e.stopPropagation()
              isCampaignArchived
                ? onUnarchive(campaignKey).catch(() => showWarningBar(`Failed to unarchive ${campaignName}`))
                : onArchive(campaignKey).catch(() => showWarningBar(`Failed to archive ${campaignName}`))
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
          
            , _react2.default.createElement('div', { className: "broadcast-list--campaign-action--icon", __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}} )
            ,  isCampaignArchived ? 'Unarchive' : 'Archive' 
          )
        )
      )
    )
  )
}








const mapState = (state, { campaignKey }) => {
  const campaign = _broadcast.selectCampaignByKey.call(void 0, state, campaignKey)

  return {
    campaignName: _nullishCoalesce(_optionalChain([campaign, 'optionalAccess', _ => _.Name]), () => ( '')),
    isCampaignActive: !!_optionalChain([campaign, 'optionalAccess', _2 => _2.Active]),
    isCampaignArchived: !!_optionalChain([campaign, 'optionalAccess', _3 => _3.Archived]),
    teamName: _optionalChain([campaign, 'optionalAccess', _4 => _4.TeamKey])
      ? _nullishCoalesce(_optionalChain([_team.selectTeamByKey.call(void 0, state, campaign.TeamKey), 'optionalAccess', _5 => _5.Name]), () => ( ''))
      : '',
  }
}

exports. default = _reactredux.connect.call(void 0, mapState)(CampaignItem)
