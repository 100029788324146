"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/DateInput/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _moment = require('moment'); var _moment2 = _interopRequireDefault(_moment);


var _time = require('shared/services/time');
var _moment3 = require('react-day-picker/moment'); var _moment4 = _interopRequireDefault(_moment3);

var _DayPickerInput = require('react-day-picker/DayPickerInput'); var _DayPickerInput2 = _interopRequireDefault(_DayPickerInput);
var _Overlay = require('./Overlay'); var _Overlay2 = _interopRequireDefault(_Overlay);
var _Input = require('./Input'); var _Input2 = _interopRequireDefault(_Input);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);

const FORMAT = 'MM/DD/YYYY'
const TODAY = new Date()













const DateInput = ({
  value,
  disabledDays,
  placeholder,
  onChange,
  isRequired,
  isDisabled,
  className,
  inputClassName,
  hasError,
}) => {
  const dayPickerRef = _react.useRef(null)

  const handleChange = _react.useCallback.call(void 0, 
    (date, modifiers) => {
      if (!modifiers.disabled) {
        onChange(_moment2.default.call(void 0, date).format(_moment2.default.HTML5_FMT.DATE))
        _optionalChain([dayPickerRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.hideDayPicker, 'call', _3 => _3()])
      }
    },
    [onChange],
  )

  const [displayValue, dateValue] = _react.useMemo.call(void 0, 
    () => {
      if (!value || _time.isDateNull.call(void 0, value)) return ['', TODAY]
      const m = _moment2.default.call(void 0, value, _moment2.default.HTML5_FMT.DATE)
      return [m.format(FORMAT), m.toDate()]
    },
    [value],
  )

  const handleClear = value && !isRequired
    ? () => onChange('')
    : null

  const inputComponent


 =
    (ps, ref) => (
      _react2.default.createElement(_Input2.default, {
        ref: ref,
        ...ps,
        disabled: isDisabled,
        onClear: handleClear,
        hasError: hasError,
        className: inputClassName,
        value: displayValue, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
      )
    )

  const overlayComponent = ps => (
    _react2.default.createElement(_Overlay2.default, {
      ...ps,
      value: dateValue,
      disabledDays: disabledDays,
      onChange: handleChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 81}}
    )
  )

  return (
    _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, 
      'blm-date-picker',
      isDisabled && 'blm-date-picker--disabled',
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      , _react2.default.createElement(_DayPickerInput2.default, {
        ref: dayPickerRef,
        value: value,
        placeholder: placeholder,
        formatDate: _moment4.default.formatDate,
        format: FORMAT,
        parseDate: _moment4.default.parseDate,
        component: _react2.default.forwardRef(inputComponent),
        overlayComponent: overlayComponent, __self: this, __source: {fileName: _jsxFileName, lineNumber: 95}}
      )
    )
  )
}

exports. default = DateInput
