"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');



var _selectMyBlobjectTeamSubscribers = require('./selectMyBlobjectTeamSubscribers');
var _org = require('shared/selectors/org');
var _team = require('shared/selectors/team');
var _string = require('shared/services/string');







const compareTeams = (a, b) => _string.compareStr.call(void 0, a.Name, b.Name)
const compareGroups = (a, b) => _string.compareStr.call(void 0, a.orgName, b.orgName)

 const selectMyChatTeamGroups = _toolkit.createSelector.call(void 0, 
  _selectMyBlobjectTeamSubscribers.selectMyBlobjectTeamSubscribers,
  _team.selectAllTeams,
  _org.selectOrgs,
  (myTeamSubscribers, allTeams, allOrgs) => {
    const groupsMap = myTeamSubscribers.reduce(
      (acc, ts) => {
        const team = allTeams[ts.TeamKey]
        if (!team) return acc

        const group = acc[team.OrgKey] || {
          orgKey: team.OrgKey,
          orgName: _optionalChain([allOrgs, 'access', _ => _[team.OrgKey], 'optionalAccess', _2 => _2.Name]) || '',
          teams: [],
        }

        group.teams.push(team)
        acc[team.OrgKey] = group

        return acc
      },
      {} ,
    )

    const groupsList = Object.values(groupsMap)
    groupsList.forEach(g => g.teams.sort(compareTeams))

    return groupsList.sort(compareGroups)
  },
); exports.selectMyChatTeamGroups = selectMyChatTeamGroups
