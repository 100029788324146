"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Tooltip/ClickTooltip.js";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);





var _common = require('web/components/Tooltip/common');

 class ClickTooltip extends _react2.default.Component {constructor(...args) { super(...args); ClickTooltip.prototype.__init.call(this);ClickTooltip.prototype.__init2.call(this);ClickTooltip.prototype.__init3.call(this);ClickTooltip.prototype.__init4.call(this);ClickTooltip.prototype.__init5.call(this);ClickTooltip.prototype.__init6.call(this); }
  __init() {this.eventHandlers = {}}
  __init2() {this.state = {
    isOpen: false,
    tooltipPosition: {},
    tooltipArrowPosition: {},
  }}

  __init3() {this.handleClickAnywhere = e => this.setState({ isOpen: false })}

  __init4() {this.toggleTooltip = (ref) => {
    const { fixedPosition, place, hideArrow } = this.props
    const { isOpen } = this.state

    const tooltipPosition = fixedPosition || _common.getTooltipPosition.call(void 0, 
      this.refs[ref],
      this.tooltip,
      place,
      hideArrow,
    )

    const tooltipArrowPosition = _common.getToolTipArrowPosition.call(void 0, 
      this.target,
      tooltipPosition,
      place,
    )

    this.setState({ isOpen: !isOpen, tooltipPosition, tooltipArrowPosition })
  }}

  componentDidMount () {
    Object.keys(this.refs).forEach(ref => {
      this.eventHandlers[ref] = e => {
        e.preventDefault()
        e.stopPropagation()
        this.toggleTooltip(ref)
      }
      this.refs[ref].addEventListener('click', this.eventHandlers[ref])
    })
    window.addEventListener('click', this.handleClickAnywhere)
  }

  componentWillUnmount () {
    Object.keys(this.refs).forEach((ref) => {
      this.refs[ref].removeEventListener('click', this.eventHandlers[ref])
    })
    window.removeEventListener('click', this.handleClickAnywhere)
  }

  __init5() {this.setTargetRef = r => this.target = r}
  __init6() {this.setTooltipRef = r => this.tooltip = r}

  render () {
    const { children, content, hideArrow, className } = this.props
    const { isOpen, tooltipPosition, tooltipArrowPosition } = this.state

    const childrenWithRefs = _react2.default.Children.map(
      children,
      (child, i) => _react2.default.cloneElement(child, { ref: `ref-${i}` }),
    )

    const containerClassName = _classBuilder2.default.call(void 0, 
      'blm-tooltip--container',
      className,
    )
    const tooltipClassName = _classBuilder2.default.call(void 0, 
      'blm-tooltip',
      'blm-tooltip--click',
      _common.getTooltipTypeClass.call(void 0, this.props),
      { hidden: !isOpen },
    )

    return (
      _react2.default.createElement('div', { ref: this.setTargetRef, className: containerClassName, __self: this, __source: {fileName: _jsxFileName, lineNumber: 83}}
        , childrenWithRefs
        , _react2.default.createElement('div', {
          className: tooltipClassName,
          style: tooltipPosition,
          ref: this.setTooltipRef, __self: this, __source: {fileName: _jsxFileName, lineNumber: 85}}
        
          ,  hideArrow
            ? null
            : _react2.default.createElement('div', { className: "blm-tooltip--arrow", style: tooltipArrowPosition, __self: this, __source: {fileName: _jsxFileName, lineNumber: 92}})
          
          , content
        )
      )
    )
  }

  static __initStatic() {this.propTypes = _common.propTypes}
  static __initStatic2() {this.defaultProps = _common.defaultProps}
} ClickTooltip.__initStatic(); ClickTooltip.__initStatic2(); exports.default = ClickTooltip;
