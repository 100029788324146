"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _toolkit = require('@reduxjs/toolkit');

var _chat = require('shared/enum/chat');

var _blobject = require('shared/selectors/blobject');
var _org = require('shared/selectors/org');
var _team = require('shared/selectors/team');
var _selectMyOrgKeyInConvo = require('./selectMyOrgKeyInConvo');

 const selectShouldShowUpgradeNotice = _toolkit.createSelector.call(void 0, 
  _blobject.selectBlobjectByKey, _org.selectOrgs, _team.selectTeams, _selectMyOrgKeyInConvo.selectMyOrgKeyInConvo,
  (blobject, allOrgs, allTeams, myOrgKey) => {
    if (!blobject) return false

    if (_optionalChain([blobject, 'optionalAccess', _ => _.WrappedType]) !== _chat.CHAT_TYPE.REGULAR) return false

    const {
      UserSubscribers: users = {},
      TeamSubscribers: teams = {},
    } = blobject

    const hasExternalUsers = Object.values(users)
      .some(u => u.OrgKey !== myOrgKey)
    if (hasExternalUsers) return false

    const hasExternalTeams = Object.keys(teams)
      .some(tk => _optionalChain([allTeams, 'access', _2 => _2[tk ], 'optionalAccess', _3 => _3.OrgKey]) !== myOrgKey)
    if (hasExternalTeams) return false

    const myOrg = myOrgKey && allOrgs[myOrgKey]

    return !!myOrg && _org.isOrgOverLimit.call(void 0, myOrg)
  },
); exports.selectShouldShowUpgradeNotice = selectShouldShowUpgradeNotice
