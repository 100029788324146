"use strict";Object.defineProperty(exports, "__esModule", {value: true});









var _array = require('shared/services/array');

 const EMPTY_SUBSCRIBERS_LIST = {
  userSubscribersList: [],
  teamSubscribersList: [],
  patientSubscribersList: [],
}; exports.EMPTY_SUBSCRIBERS_LIST = EMPTY_SUBSCRIBERS_LIST

 const populateSubscribers = 





 (
    subscribers,
    allEntitiesMap,
  ) =>
    Object.keys(subscribers)
      .map(k => allEntitiesMap[k ])
      .filter(_array.truthy); exports.populateSubscribers = populateSubscribers

 const unsafePopulateSubscribers = 









 (
    subscribers,
    allEntitiesMap,
    makeBaseSubscriber,
  ) =>
    Object.keys(subscribers)
      .map(k => {
        const key = k 
        return allEntitiesMap[key] || makeBaseSubscriber(key)
      })
      .filter(_array.truthy); exports.unsafePopulateSubscribers = unsafePopulateSubscribers
