"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }






var _entity = require('shared/enum/entity');
var _array = require('shared/services/array');
var _string = require('shared/services/string');


 const entityListToMap = (es) =>
  _optionalChain([es, 'optionalAccess', _ => _.reduce, 'call', _2 => _2(
    (acc, entity) => {
      if (entity) acc[entity.entityKey] = entity
      return acc
    },
    {} ,
  )]) || {}; exports.entityListToMap = entityListToMap

 const entityMapToList = (es) =>
  Object.values(es)
    .reduce(
      (acc, entity) => {
        if (entity) acc.push(entity)
        return acc
      },
      [] ,
    ); exports.entityMapToList = entityMapToList

 const compareDisplayEntities = (a, b) => {
  if (!a || !b) return 0

  return (
    _string.compareStr.call(void 0, a.name, b.name) ||
    _string.compareStr.call(void 0, a.description, b.description)
  )
}; exports.compareDisplayEntities = compareDisplayEntities

 const groupEntities = (es) =>
  _array.groupBy.call(void 0, 
    es,
    e => e.type,
  ); exports.groupEntities = groupEntities

 const isEntityInternal = (e, myOrgKey) =>
  (e.wrappedEntity ).OrgKey === myOrgKey ||
  !!_optionalChain([(e.wrappedEntity ), 'access', _3 => _3.Relationships, 'optionalAccess', _4 => _4[myOrgKey]]); exports.isEntityInternal = isEntityInternal

 const makePatientNameSafe = (e) => ({ ...e, name: e.wrappedEntity.Name }); exports.makePatientNameSafe = makePatientNameSafe

 const isTeamNamed = (e) => !!_optionalChain([(_optionalChain([e, 'optionalAccess', _5 => _5.wrappedEntity]) ), 'optionalAccess', _6 => _6.Name]); exports.isTeamNamed = isTeamNamed

 const isEntityUser = (e) =>
  e.type === _entity.ENTITY_TYPES.USER; exports.isEntityUser = isEntityUser

 const isEntityRelationship = (e) =>
  e.type === _entity.ENTITY_TYPES.RELATIONSHIP; exports.isEntityRelationship = isEntityRelationship

 const isEntityTeam = (e) =>
  e.type === _entity.ENTITY_TYPES.TEAM; exports.isEntityTeam = isEntityTeam

 const isEntityPatient = (e) =>
  e.type === _entity.ENTITY_TYPES.PATIENT; exports.isEntityPatient = isEntityPatient

 const isEntityOrg = (e) =>
  e.type === _entity.ENTITY_TYPES.ORG; exports.isEntityOrg = isEntityOrg
