"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/ChatParticipantsList/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');












var _chat = require('shared/enum/chat');





var _blobject = require('shared/selectors/blobject');
var _conversation = require('shared/selectors/conversation');
var _noticeBar = require('web/services/context/noticeBar');
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _utils = require('./utils');

var _AddParticipantsModal = require('web/screens/Inbox/AddParticipantsModal'); var _AddParticipantsModal2 = _interopRequireDefault(_AddParticipantsModal);
var _Form = require('web/components/Form');
var _Section = require('./Section'); var _Section2 = _interopRequireDefault(_Section);

const FAILURE_MSG = 'Failed to remove participant'
const SUCCESS_MSG = 'Participant has been removed'






const nullEntityOptions = []

const ChatParticipantsListView = ({
  blobjectKey,
  conversationKey,
  conversationType,
  myChatSubscriberKey,
  internalUsersList,
  externalUsersList,
  teamsList,
  patientsList,
  allSubscribersMap,
}) => {
  const {
    isOpen: isAddModalOpen,
    onOpen: onOpenAddModal,
    onClose: onCloseAddModal,
  } = _useToggle2.default.call(void 0, )
  const [openedOptionsEntityKey, setOpenedOptionsEntityKey] = _react.useState(null)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onGotoEntityPage = _react.useCallback.call(void 0, 
    _utils.gotoParticipantPage.call(void 0, allSubscribersMap),
    [allSubscribersMap],
  )

  const closeEntityOptions = _react.useCallback.call(void 0, 
    () => setOpenedOptionsEntityKey(null),
    [setOpenedOptionsEntityKey],
  )

  const { showWarningBar, showSuccessBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onRemoveParticipant = _react.useCallback.call(void 0, 
    (e) => _utils.removeChatParticipant.call(void 0, e, blobjectKey, conversationKey, myChatSubscriberKey)
      .then(() => showSuccessBar(SUCCESS_MSG))
      .catch(() => showWarningBar(FAILURE_MSG)),
    [
      showWarningBar, showSuccessBar,
      blobjectKey, conversationKey, myChatSubscriberKey,
    ],
  )

  const entityOptions = _react.useMemo.call(void 0, 
    () => [
      ['Remove from chat', onRemoveParticipant],
    ],
    [onRemoveParticipant],
  )

  return (
    _react2.default.createElement('div', { className: "inbox-participants", __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}
      , _react2.default.createElement(_Section2.default, {
        title: "Teams",
        entitiesList: teamsList,
        options: entityOptions,
        openedOptionsEntityKey: openedOptionsEntityKey,
        onGotoEntityPage: onGotoEntityPage,
        onOpenOptions: setOpenedOptionsEntityKey,
        onCloseOptions: closeEntityOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 90}}
      )
      , _react2.default.createElement(_Section2.default, {
        title: "Patients",
        entitiesList: patientsList,
        options: conversationType !== _chat.CHAT_TYPE.REGULAR_PATIENT
          ? entityOptions
          : nullEntityOptions
        ,
        openedOptionsEntityKey: openedOptionsEntityKey,
        onGotoEntityPage: onGotoEntityPage,
        onOpenOptions: setOpenedOptionsEntityKey,
        onCloseOptions: closeEntityOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
      )
      , _react2.default.createElement(_Section2.default, {
        title: "Coworkers",
        entitiesList: internalUsersList,
        options: entityOptions,
        openedOptionsEntityKey: openedOptionsEntityKey,
        onGotoEntityPage: onGotoEntityPage,
        onOpenOptions: setOpenedOptionsEntityKey,
        onCloseOptions: closeEntityOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}
      )
      , _react2.default.createElement(_Section2.default, {
        title: "External Collaborators" ,
        entitiesList: externalUsersList,
        options: entityOptions,
        openedOptionsEntityKey: openedOptionsEntityKey,
        onGotoEntityPage: onGotoEntityPage,
        onOpenOptions: setOpenedOptionsEntityKey,
        onCloseOptions: closeEntityOptions, __self: this, __source: {fileName: _jsxFileName, lineNumber: 120}}
      )
      , _react2.default.createElement(_Form.Button, {
        className: "inbox-participants--add",
        onClick: onOpenAddModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
      , "Add participants"

      )
      , _react2.default.createElement(_AddParticipantsModal2.default, {
        isOpen: isAddModalOpen,
        onClose: onCloseAddModal,
        blobjectKey: blobjectKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
      )
    )
  )
}












const makeMapState = () => {
  const selectChatParticipants = _blobject.makeSelectChatParticipants.call(void 0, )
  return (state, { blobjectKey }) => {
    const myChatSubscriber = _conversation.selectMyChatSubscriber.call(void 0, state, blobjectKey)
    const conversationKey = _blobject.selectBlobjectWrappedKey.call(void 0, state, blobjectKey)
    const conversationType = _blobject.selectBlobjectWrappedType.call(void 0, state, blobjectKey)
    const myChatSubscriberKey = myChatSubscriber && (
      'UserKey' in myChatSubscriber
        ? myChatSubscriber.UserKey
        : myChatSubscriber.TeamKey
    )

    return {
      ...selectChatParticipants(state, blobjectKey),
      allSubscribersMap: _blobject.selectBlobjectSubscribersMap.call(void 0, state, blobjectKey),
      myChatSubscriberKey,
      conversationKey,
      conversationType,
    }
  }
}

exports. default = _reactredux.connect.call(void 0, makeMapState)(ChatParticipantsListView)
