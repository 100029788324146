"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _utils = require('shared/store/utils');
var _store = require('shared/store');
var _redux = require('redux');

const downloadAvailableAction = _utils.createAction.call(void 0, 'Download available')
const downloadAvailable = _redux.bindActionCreators.call(void 0, downloadAvailableAction, _store.dispatch)

const markAppOutdatedAction = _utils.createAction.call(void 0, 'Mark app outdated')
const markAppOutdated = _redux.bindActionCreators.call(void 0, markAppOutdatedAction, _store.dispatch)

 const actionCreators = { downloadAvailableAction, markAppOutdatedAction }; exports.actionCreators = actionCreators

exports. default = { downloadAvailable, markAppOutdated }
