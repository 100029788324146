"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Sidebar/TitleTooltip.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);

var _Tooltip = require('web/components/Tooltip');





const offset = { left: 8 }
const TitleTooltip = ({ title, children }) => title
  ? (
      _react2.default.createElement(_Tooltip.HoverTooltip, {
        type: "dark",
        place: "right",
        offset: offset,
        content: _react2.default.createElement(_Tooltip.TooltipHint, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 16}},  title ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
      
        ,  children 
      )
    )
  : _react2.default.createElement(_react2.default.Fragment, null,  children )

exports. default = TitleTooltip
