"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/components/Form/DateInput/Overlay.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);
var _reactdaypicker = require('react-day-picker'); var _reactdaypicker2 = _interopRequireDefault(_reactdaypicker);

var _lodash = require('lodash');

var _YearMonthForm = require('./YearMonthForm'); var _YearMonthForm2 = _interopRequireDefault(_YearMonthForm);

const TODAY = new Date()











const navbarElement = () => null

const Overlay = ({
  classNames,
  onChange,
  value,
  disabledDays,
  ...props
}) => {
  const datePickerRef = _react.useRef(null)
  const [month, setMonth] = _react.useState.call(void 0, value || TODAY)
  const captionElement = (ps) => (
    _react2.default.createElement(_YearMonthForm2.default, {
      onChange: setMonth,
      onPrevMonth: () => _optionalChain([datePickerRef, 'access', _ => _.current, 'optionalAccess', _2 => _2.showPreviousMonth, 'call', _3 => _3()]),
      onNextMonth: () => _optionalChain([datePickerRef, 'access', _4 => _4.current, 'optionalAccess', _5 => _5.showNextMonth, 'call', _6 => _6()]),
      ...ps, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
    )
  )

  return (
    _react2.default.createElement('div', {
      onClick: e => e.preventDefault(),
      className: _classBuilder2.default.call(void 0, 
        'blm-date-picker--overlay',
        classNames.overlayWrapper,
      ),
      ..._lodash.omit.call(void 0, props, ["selectedDay", "month", "input"]), __self: this, __source: {fileName: _jsxFileName, lineNumber: 42}}
    
      , _react2.default.createElement('div', { className: classNames.overlay, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
        , _react2.default.createElement(_reactdaypicker2.default, {
          ref: datePickerRef,
          onDayClick: onChange,
          month: month,
          selectedDays: [value],
          captionElement: captionElement,
          navbarElement: navbarElement,
          disabledDays: disabledDays, __self: this, __source: {fileName: _jsxFileName, lineNumber: 51}}
        )
      )
    )
  )
}

exports. default = Overlay
