"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _createAction = require('./createAction'); var _createAction2 = _interopRequireDefault(_createAction);








const createAsyncActions =  (label) => ({
  REQUEST: _createAction2.default.call(void 0, 
    `REQUEST ${label}`,
    (payload, meta) => ({ payload, meta }),
  ),
  SUCCESS: _createAction2.default.call(void 0, 
    `SUCCESS ${label}`,
    (payload, meta) => ({ payload, meta }),
  ),
  FAILURE: _createAction2.default.call(void 0, 
    `FAILURE ${label}`,
    (payload, meta) => ({ payload, meta }),
  ),
})

exports. default = createAsyncActions
