"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Inbox/InboxList/Header/TeamPicker/PickerContent.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');




var _myRelations = require('shared/selectors/myRelations');

var _Tooltip = require('web/components/Tooltip');






const PickerContent = ({
  selectedTeam,
  teamGroups,
  onSelectTeam,
}) => (
  _react2.default.createElement(_Tooltip.TooltipContent, { className: "inbox-picker", __self: this, __source: {fileName: _jsxFileName, lineNumber: 21}}
    , _react2.default.createElement(_Tooltip.TooltipTitle, { key: "title", __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}, "Jump to..." )
    , _react2.default.createElement(_Tooltip.TooltipItem, {
      key: "all",
      className: "inbox-picker--item",
      onClick: () => onSelectTeam(null),
      selected: selectedTeam === null, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}
    , "My Inbox"

    )
    ,  teamGroups.map(g => (
      _react2.default.createElement(_react2.default.Fragment, { key: g.orgKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
        , _react2.default.createElement('hr', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 33}} )
        , _react2.default.createElement(_Tooltip.TooltipTitle, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 34}},  g.orgName )
        ,  g.teamsList.map(t => (
          _react2.default.createElement(_Tooltip.TooltipItem, {
            key: t.TeamKey,
            className: "inbox-picker--item",
            onClick: () => onSelectTeam(t.TeamKey),
            selected: selectedTeam === t.TeamKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 36}}
          
            ,  t.Name 
          )
        ))
      )
    ))
  )
)





const mapState = (state) => ({
  teamGroups: _myRelations.selectMyTeamGroupsList.call(void 0, state),
})

exports. default = _reactredux.connect.call(void 0, mapState)(PickerContent)
