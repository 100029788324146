"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Broadcast/CampaignPage/Header/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; } function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _reactredux = require('react-redux');



var _actions = require('web/actions'); var _actions2 = _interopRequireDefault(_actions);




var _broadcast = require('shared/selectors/broadcast');





var _Header = require('web/components/RightSide/Header');
var _CampaignSettings = require('./CampaignSettings'); var _CampaignSettings2 = _interopRequireDefault(_CampaignSettings);
var _CampaignStatus = require('web/screens/Broadcast/CampaignStatus'); var _CampaignStatus2 = _interopRequireDefault(_CampaignStatus);








const CampaignPageHeader = ({
  campaignName,
  isCampaignArchived,
  campaignKey,
  onEditCampaign,
  onExportCampaign,
  onQueueMessages,
  canQueueMessages,
}) => (
  _react2.default.createElement(_Header.RightSideHeader, { className: "campaign-header", __self: this, __source: {fileName: _jsxFileName, lineNumber: 37}}
    , _react2.default.createElement(_Header.HeaderMobileBackButton, { onClick: _actions2.default.gotoDirectory, __self: this, __source: {fileName: _jsxFileName, lineNumber: 38}} )
    , _react2.default.createElement(_Header.RightSideHeaderHeading, {
      title: campaignName,
      subtitle: _react2.default.createElement(_CampaignStatus2.default, { campaignKey: campaignKey, __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
    )
    , _react2.default.createElement(_CampaignSettings2.default, {
      isCampaignArchived: isCampaignArchived,
      onQueueMessages: canQueueMessages ? onQueueMessages : null,
      onEditCampaign: onEditCampaign,
      onExportCampaign: onExportCampaign, __self: this, __source: {fileName: _jsxFileName, lineNumber: 43}}
    )
  )
)







const mapState = (state, { campaignKey }) => ({
  campaignName: _nullishCoalesce(_broadcast.selectCampaignName.call(void 0, state, campaignKey), () => ( '')),
  isCampaignArchived: !!_broadcast.selectIsCampaignArchived.call(void 0, state, campaignKey),
  canQueueMessages: _broadcast.selectCampaignNotScheduledCount.call(void 0, state, campaignKey) > 0,
})

exports. default = _reactredux.connect.call(void 0, mapState)(CampaignPageHeader)
