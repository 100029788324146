"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }

var _reselect = require('reselect');
var _authorization = require('shared/services/authorization');

 const selectCurrentUser = (state) => _optionalChain([state, 'optionalAccess', _ => _.currentUser]); exports.selectCurrentUser = selectCurrentUser
 const selectCurrentUserV2 = (state) => _optionalChain([state, 'optionalAccess', _2 => _2.dataV2, 'optionalAccess', _3 => _3.currentUser]); exports.selectCurrentUserV2 = selectCurrentUserV2
 const selectCurrentUserKey = (state) => {
  const user = exports.selectCurrentUserV2.call(void 0, state)
  return _optionalChain([user, 'optionalAccess', _4 => _4.UserKey])
}; exports.selectCurrentUserKey = selectCurrentUserKey

 const selectIsCurrentUserActive = (state) => state.activity.active; exports.selectIsCurrentUserActive = selectIsCurrentUserActive

 const selectIsUserLoggedIn = (state) => {
  const u = exports.selectCurrentUserV2.call(void 0, state)
  return !!(u && u.UserKey)
}; exports.selectIsUserLoggedIn = selectIsUserLoggedIn

 const selectAmISuperUser = _reselect.createSelector.call(void 0, 
  exports.selectCurrentUser,
  me => Object.values(_optionalChain([me, 'optionalAccess', _5 => _5.Relationships]) || {}).some(o => _authorization.superUserOrgKeys[o.OrgKey]),
); exports.selectAmISuperUser = selectAmISuperUser
