"use strict";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _videoRoom = require('web/actions/videoRoom');
var _actionCreators = require('shared/actions/actionCreators'); var _actionCreators2 = _interopRequireDefault(_actionCreators);
var _lodash = require('lodash');

const initialState = {
  allRooms: {},
  currentVideoRoomKey: '',
  currentVideoRoomSessionDuration: 0,
  currentVideoRoomUser: {
    RoomKey: '',
    RoomUserKey: '',
  },
  anonymousUser: {
    userKey: '',
    userName: '',
    userSecret: '',
  },
  pendingAuthorizations: {},
  searchContext: {
    query: '',
    areArchived: false,
    matchingRoomKeys: [],
    hasDrained: false,
  },
}

exports. default = (state = initialState, action) => {
  const { type, payload, context } = action
  switch (type) {
    case _videoRoom.types.FETCH_VIDEO_ROOM.SUCCESS: return {
      ...state,
      searchContext: {
        ...state.searchContext,
        matchingRoomKeys: _lodash.union.call(void 0, 
          state.searchContext.matchingRoomKeys,
          [payload.VideoRoom.RoomKey],
        ),
      },
    }
    case _videoRoom.types.SAVE_VIDEO_ROOMS: {
      const normalizeRoom = r => ({
        ...r,
        UserAdmins: _lodash.keyBy.call(void 0, r.UserAdmins, 'UserKey'),
        CurrentRoomUsers: _lodash.keyBy.call(void 0, r.CurrentRoomUsers, 'RoomUserKey'),
        RoomLink: `${VIDEO_ROOM_BASE_URL}/#/vd/${r.RoomKey}`,
      })

      const aggregatedAuthorizations = _lodash.flatten.call(void 0, 
        payload.map(room => (room.PendingAuthorizations || []).map(
          a => ({ ...a, RoomName: room.DisplayName || room.Name }),
        )),
      )
      const pendingAuthorizations =
        _lodash.keyBy.call(void 0, aggregatedAuthorizations, 'RoomAuthorizationKey')

      return {
        ...state,
        allRooms: _lodash.merge.call(void 0, 
          _lodash.keyBy.call(void 0, payload.map(normalizeRoom), 'RoomKey'),
          state.allRooms,
        ),
        pendingAuthorizations: _lodash.merge.call(void 0, 
          pendingAuthorizations,
          state.pendingAuthorizations,
        ),
      }
    }
    case _videoRoom.types.SAVE_VIDEO_ROOM_USER:
    case _videoRoom.types.VIDEO_ROOM_USER_PROBLEM_ENCOUNTERED:
    case _videoRoom.types.VIDEO_ROOM_USER_PROBLEM_PUBLISH.REQUEST: {
      const room = state.allRooms[payload.RoomKey] || {}
      room.CurrentRoomUsers = room.CurrentRoomUsers || {}
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [payload.RoomKey]: {
            ...room,
            CurrentRoomUsers: {
              ...room.CurrentRoomUsers,
              [payload.RoomUserKey]: {
                ...room.CurrentRoomUsers[payload.RoomUserKey],
                ...payload,
              },
            },
          },
        },
      }
    }
    case _videoRoom.types.SET_CURRENT_VIDEO_ROOM_KEY: return {
      ...state, currentVideoRoomKey: payload.roomKey,
    }
    case _videoRoom.types.REINDEX_MATCHING_ROOM_KEYS: return {
      ...state,
      searchContext: {
        ...state.searchContext,
        matchingRoomKeys: state.searchContext.query
          ? state.searchContext.matchingRoomKeys
          : _lodash.values.call(void 0, state.allRooms)
            .reduce((acc, r) =>
              r.Archived === state.searchContext.areArchived
                ? acc.concat(r.RoomKey)
                : acc
            , []),
      },
    }
    case _videoRoom.types.SAVE_SEARCH_MATCHING_ROOM_KEYS: return {
      ...state,
      searchContext: {
        query: payload.query,
        areArchived: payload.areArchived,
        hasDrained: payload.matchingRoomKeys.length === 0,
        matchingRoomKeys:
          (payload.query === state.searchContext.query &&
          payload.areArchived === state.searchContext.areArchived)
            ? state.searchContext.matchingRoomKeys.concat(payload.matchingRoomKeys)
            : payload.matchingRoomKeys,
      },
    }
    case _videoRoom.types.UPDATE_VIDEO_ROOM: return {
      ...state,
      allRooms: {
        ...state.allRooms,
        [payload.RoomKey]: {
          ...state.allRooms[payload.RoomKey],
          ...payload,
        },
      },
    }
    case _videoRoom.types.ADD_VIDEO_ROOM_ADMINS.SUCCESS: {
      const { UserAdmins = {}, TeamAdminKeys = [] } = state.allRooms[context.RoomKey]
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [context.RoomKey]: {
            ...state.allRooms[context.RoomKey],
            UserAdmins: {
              ...UserAdmins,
              ..._lodash.keyBy.call(void 0, context.UserAdmins, 'UserKey'),
            },
            TeamAdminKeys: _lodash.union.call(void 0, context.TeamAdminKeys, TeamAdminKeys),
          },
        },
      }
    }
    case _videoRoom.types.REMOVE_VIDEO_ROOM_ADMIN_USER.REQUEST:
    case _videoRoom.types.REMOVE_VIDEO_ROOM_ADMIN_USER.FAILURE: {
      const room = state.allRooms[context.RoomKey] || { UserAdmins: {} }
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [context.RoomKey]: {
            ...room,
            UserAdmins: {
              ...room.UserAdmins,
              [context.UserKey]: {
                ...room.UserAdmins[context.UserKey],
                isDeleted: type === _videoRoom.types.REMOVE_VIDEO_ROOM_ADMIN_USER.REQUEST,
              },
            },
          },
        },
      }
    }
    case _videoRoom.types.REMOVE_VIDEO_ROOM_ADMIN_USER.SUCCESS: {
      const room = state.allRooms[context.RoomKey] || { UserAdmins: {} }
      const { [context.UserKey]: removed, ...UserAdmins } = room.UserAdmins
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [context.RoomKey]: { ...room, UserAdmins },
        },
      }
    }
    case _videoRoom.types.REMOVE_VIDEO_ROOM_ADMIN_TEAM.REQUEST: {
      const room = state.allRooms[context.RoomKey] || { TeamAdminKeys: [] }
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [context.RoomKey]: {
            ...room,
            TeamAdminKeys: room.TeamAdminKeys.filter(t => t !== context.TeamKey),
          },
        },
      }
    }
    case _videoRoom.types.REMOVE_VIDEO_ROOM_ADMIN_TEAM.FAILURE: {
      const room = state.allRooms[context.RoomKey] || { TeamAdminKeys: [] }
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [context.RoomKey]: {
            ...room,
            TeamAdminKeys: _lodash.union.call(void 0, room.TeamAdminKeys, context.TeamKey),
          },
        },
      }
    }
    case _videoRoom.types.UPDATE_ANONYMOUS_USER: return {
      ...state,
      anonymousUser: { ...state.anonymousUser, ...payload },
    }
    case _videoRoom.types.SAVE_PENDING_JOIN_REQUEST: return {
      ...state,
      pendingAuthorizations: {
        ...state.pendingAuthorizations,
        [payload.RoomAuthorizationKey]: payload,
      },
    }
    case _videoRoom.types.UPDATE_CURRENT_VIDEO_ROOM_USER: {
      const { RoomKey, RoomUserKey } = state.currentVideoRoomUser
      const room = state.allRooms[RoomKey] || { CurrentRoomUsers: {} }
      return {
        ...state,
        allRooms: {
          ...state.allRooms,
          [RoomKey]: {
            ...room,
            CurrentRoomUsers: {
              ...room.CurrentRoomUsers,
              [RoomUserKey]: {
                ...room.CurrentRoomUsers[RoomUserKey],
                ...payload,
              },
            },
          },
        },
      }
    }
    case _videoRoom.types.SAVE_CURRENT_VIDEO_ROOM_USER: return {
      ...state,
      currentVideoRoomUser: {
        RoomKey: payload.RoomKey,
        RoomUserKey: payload.RoomUserKey,
      },
    }
    case _videoRoom.types.UPDATE_VIDEO_ROOM_PENDING_USER: return {
      ...state,
      pendingAuthorizations: {
        ...state.pendingAuthorizations,
        [payload.RoomAuthorizationKey]: {
          ...state.pendingAuthorizations[payload.RoomAuthorizationKey],
          Approved: payload.Approved,
          isResolved: true,
        },
      },
    }
    case _videoRoom.types.DISCONNECT_CURRENT_VIDEO_ROOM_USER.SUCCESS:
      return {
        ...state,
        currentVideoRoomSessionDuration: (new Date(payload.LastDisconnectedAt) - new Date(payload.InitialJoinedAt) || 0) / 1000,
      }
    case _actionCreators2.default.logoutAction.type: return initialState
  }
  return state
}
