"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Login/ResetPassword/Form.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);


var _Form = require('web/components/Form');
var _PasswordInput = require('web/components/PasswordInput'); var _PasswordInput2 = _interopRequireDefault(_PasswordInput);
var _app = require('shared/constants/app');

 const validationErrorMsgs = {
  weakPassword: "Password isn't strong enough",
  passwordMismatch: "Passwords don't match",
}; exports.validationErrorMsgs = validationErrorMsgs



 const errorMsgs = {
  "Invalid Token": "This link is invalid. Please request a new password reset.",
  "Token Previously Used": "This link has already been used. Please request a new password reset.",
  "Token Expired": "This link has expired. Please request a new password reset.",
  "weak password": "Please choose a stronger password: Include a mix of upper- and lowercase letters, numbers, punctuation, and avoid names (including 'bloom') and dates.",
  "generic": "Couldn't set a new password. Please try again later or contact support@bloomtext.com.",
}; exports.errorMsgs = errorMsgs















const passwordInputId = 'password'
const passwordConfirmationInputId = 'passwordConfirmation'

 class ResetPasswordForm extends _react2.default.PureComponent {constructor(...args) { super(...args); ResetPasswordForm.prototype.__init.call(this);ResetPasswordForm.prototype.__init2.call(this);ResetPasswordForm.prototype.__init3.call(this);ResetPasswordForm.prototype.__init4.call(this);ResetPasswordForm.prototype.__init5.call(this); }
  __init() {this.state = {
    password: "",
    passwordConfirmation: "",
    errorMessage: null,
    isLoading: false,
    isAnyFieldTouched: false,
    isPasswordStrong: false,
  }}

  __init2() {this.handleChange = (inputId, value) => this.setState({
    [inputId ]: value,
    isAnyFieldTouched: true,
  })}

  __init3() {this.setPasswordStrength = (isPasswordStrong) => this.setState({ isPasswordStrong })}

  __init4() {this.validatePasswords = () => {
    const { password, passwordConfirmation, isPasswordStrong } = this.state

    if (!isPasswordStrong) return exports.validationErrorMsgs.weakPassword
    if (password !== passwordConfirmation) return exports.validationErrorMsgs.passwordMismatch
    return null
  }}

  __init5() {this.handleSubmit = (e) => {
    e.preventDefault()
    const { password } = this.state
    const { onSubmit } = this.props

    const errorMessage = this.validatePasswords()
    if (errorMessage) return this.setState({ errorMessage })

    this.setState({
      isLoading: true,
      isAnyFieldTouched: false,
      errorMessage: null,
    })

    onSubmit(password)
      .catch((e) => {
        const errorMessage = exports.errorMsgs[e.Response] || exports.errorMsgs.generic
        this.setState({ errorMessage, isLoading: false })
      })
  }}

  render () {
    const {
      password, passwordConfirmation,
      isLoading, isAnyFieldTouched, errorMessage,
    } = this.state
    const { email } = this.props

    const validationErrorMessage = this.validatePasswords()
    const isSubmitBtnEnabled = !isLoading &&
      (isAnyFieldTouched ? !validationErrorMessage : errorMessage)

    return (
      _react2.default.createElement('form', { onSubmit: this.handleSubmit, __self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}
        , _react2.default.createElement('input', {
          readOnly: true,
          type: "text",
          name: "email",
          autoComplete: "username email" ,
          className: "blmh-hide",
          value: email, __self: this, __source: {fileName: _jsxFileName, lineNumber: 99}}
        )
        , _react2.default.createElement('label', { className: "login--username--field", __self: this, __source: {fileName: _jsxFileName, lineNumber: 107}}, "Password"

          , _react2.default.createElement(_PasswordInput2.default, {
            inputId: passwordInputId,
            inputClassName: "login--input",
            value: password,
            autoComplete: "new-password",
            onChange: this.handleChange,
            onValidated: this.setPasswordStrength,
            maxLength: _app.MAX_PASSWORD_LENGTH, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}
          )
        )
        , _react2.default.createElement('label', { className: "login--username--field", __self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, "Confirm password"

          , _react2.default.createElement(_PasswordInput2.default, {
            inputId: passwordConfirmationInputId,
            inputClassName: "login--input",
            autoComplete: "new-password",
            value: passwordConfirmation,
            onChange: this.handleChange,
            maxLength: _app.MAX_PASSWORD_LENGTH, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
          )
        )
        ,  (isAnyFieldTouched && validationErrorMessage || errorMessage) && (
          _react2.default.createElement('span', { className: "login--error-msg", __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
            ,  validationErrorMessage || errorMessage 
          )
        )
        , _react2.default.createElement(_Form.Button, {
          isSubmit: true,
          isDisabled: !isSubmitBtnEnabled,
          className: "login--username-btn", __self: this, __source: {fileName: _jsxFileName, lineNumber: 135}}
        , "Save"

        )
      )
    )
  }
} exports.default = ResetPasswordForm;
