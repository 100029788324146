"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/InfoTab/MembersSection/index.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);




var _actions = require('shared/actions'); var _actions2 = _interopRequireDefault(_actions);
var _useToggle = require('shared/hooks/useToggle'); var _useToggle2 = _interopRequireDefault(_useToggle);
var _noticeBar = require('web/services/context/noticeBar');
var _error = require('shared/services/error');

var _TeamMembersList = require('./TeamMembersList'); var _TeamMembersList2 = _interopRequireDefault(_TeamMembersList);
var _AddMembersModal = require('./AddMembersModal'); var _AddMembersModal2 = _interopRequireDefault(_AddMembersModal);
var _components = require('web/screens/Directory/components');


var _components3 = require('web/screens/Directory/TeamPage/components');









const MembersSection = ({
  teamKey,
  teamName,
  orgKey,
  canEditTeam,
  membersCount,
}) => {
  const {
    isOpen: isAddMembersModalOpen,
    onOpen: onOpenAddMembersModal,
    onClose: onCloseAddMembersModal,
  } = _useToggle2.default.call(void 0, )

  const onLookupMembers = _react.useCallback.call(void 0, 
    (Query, Limit) =>
      _actions2.default.findUsersNotInTeam({
        TeamKey: teamKey, OrgKey: orgKey, Query, Limit, Offset: 0,
      }),
    [teamKey, orgKey],
  )

  const { showSuccessBar, showWarningBar } = _noticeBar.useNoticeBar.call(void 0, )
  const onAddMembersToTeam = _react.useCallback.call(void 0, 
    (UserKeys) => _actions2.default.addUsersToTeam({ TeamKey: teamKey, UserKeys })
      .then(() => showSuccessBar("Successfully added members to team"))
      .catch(_error.passError.call(void 0, () => showWarningBar("Couldn't add members to team"))),
    [teamKey, showWarningBar, showSuccessBar],
  )

  return (
    _react2.default.createElement(_components3.GenericSection, {
      title: `Members (${membersCount})`,
      titleClassName: "directory-page--team-members--title",
      bodyClassName: "directory-page--team-members--section-body",
      className: "directory-page--team-members--section", __self: this, __source: {fileName: _jsxFileName, lineNumber: 56}}
    
      , _react2.default.createElement(_components3.SectionCol, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 62}}
        , _react2.default.createElement(_TeamMembersList2.default, {
          orgKey: orgKey,
          teamKey: teamKey,
          canEditParticipants: canEditTeam,
          className: "directory-page--team-members--list", __self: this, __source: {fileName: _jsxFileName, lineNumber: 63}}
        )
      )
      ,  canEditTeam && (
        _react2.default.createElement(_components3.SectionControls, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 71}}
          , _react2.default.createElement(_components.InfoBtn, {
            iconSrc: "/assets/icons/add-participant-purple.svg",
            label: "Add members" ,
            onClick: onOpenAddMembersModal, __self: this, __source: {fileName: _jsxFileName, lineNumber: 72}}
          )
        )
      )
      , _react2.default.createElement(_AddMembersModal2.default, {
        isOpen: isAddMembersModalOpen,
        onClose: onCloseAddMembersModal,
        orgKey: orgKey,
        teamName: teamName,
        onLookupMembers: onLookupMembers,
        onSubmit: onAddMembersToTeam, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
    )
  )
}

exports. default = MembersSection
