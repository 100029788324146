"use strict";Object.defineProperty(exports, "__esModule", {value: true});var _proptypes = require('prop-types');

 const DEFAULT_FORM_AVATAR_SIZE = 36; exports.DEFAULT_FORM_AVATAR_SIZE = DEFAULT_FORM_AVATAR_SIZE

 const ENTITY_TYPES = {
  USER: 'USER',
  PATIENT: 'PATIENT',
  TEAM: 'TEAM',
  ORG: 'ORG',
  NEWPATIENT: 'NEWPATIENT',
  NEWUSER: 'NEWUSER',
  ADD: 'ADD',
  UNKNOWN: 'UNKNOWN',
}; exports.ENTITY_TYPES = ENTITY_TYPES

 const VALIDATION_STATES = {
  ERROR: "ERROR",
  SUCCESS: "SUCCESS",
  NONE: "NONE",
}; exports.VALIDATION_STATES = VALIDATION_STATES

 const UserAvatarPropType = _proptypes.PropTypes.shape({
  userKey: _proptypes.PropTypes.string.isRequired,
}); exports.UserAvatarPropType = UserAvatarPropType

 const TeamAvatarPropType = _proptypes.PropTypes.shape({
  teamKey: _proptypes.PropTypes.string.isRequired,
}); exports.TeamAvatarPropType = TeamAvatarPropType

 const OrgAvatarPropType = _proptypes.PropTypes.shape({
  orgKey: _proptypes.PropTypes.string.isRequired,
}); exports.OrgAvatarPropType = OrgAvatarPropType

 const PatientAvatarPropType = _proptypes.PropTypes.shape({
  patientKey: _proptypes.PropTypes.string.isRequired,
  patientName: _proptypes.PropTypes.string.isRequired,
}); exports.PatientAvatarPropType = PatientAvatarPropType

 const AddAvatarPropType = _proptypes.PropTypes.shape({
  isAdd: _proptypes.PropTypes.oneOf([true]), /* just has this as true */
}); exports.AddAvatarPropType = AddAvatarPropType

 const EntityAvatarPropType = _proptypes.PropTypes.oneOfType([
  exports.UserAvatarPropType,
  exports.TeamAvatarPropType,
  exports.PatientAvatarPropType,
  exports.OrgAvatarPropType,
  exports.AddAvatarPropType,
]); exports.EntityAvatarPropType = EntityAvatarPropType

 const EntityPropType = _proptypes.PropTypes.shape({
  id: _proptypes.PropTypes.string.isRequired,
  avatar: exports.EntityAvatarPropType,
  type: _proptypes.PropTypes.oneOf(Object.values(exports.ENTITY_TYPES)),
  title: _proptypes.PropTypes.string.isRequired,
  subtitle: _proptypes.PropTypes.string,
  isInNetwork: _proptypes.PropTypes.bool, /* default: true */
  hasPhoneNumner: _proptypes.PropTypes.bool,
}); exports.EntityPropType = EntityPropType

 const SectionPropType = _proptypes.PropTypes.shape({
  entities: _proptypes.PropTypes.arrayOf(exports.EntityPropType),
  title: _proptypes.PropTypes.string,
}); exports.SectionPropType = SectionPropType

 const ActionPropType = _proptypes.PropTypes.shape({
  id: _proptypes.PropTypes.string.isRequired,
  text: _proptypes.PropTypes.string.isRequired,
}); exports.ActionPropType = ActionPropType

 const validationStates = _proptypes.PropTypes.oneOf(Object.values(exports.VALIDATION_STATES)); exports.validationStates = validationStates
