"use strict";const _jsxFileName = "/home/runner/work/bloomtext-frontend/bloomtext-frontend/webChat/src/screens/Directory/TeamPage/components/GenericSection.tsx";Object.defineProperty(exports, "__esModule", {value: true}); function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }var _react = require('react'); var _react2 = _interopRequireDefault(_react);
var _classBuilder = require('shared/services/classBuilder'); var _classBuilder2 = _interopRequireDefault(_classBuilder);









const GenericSection = ({
  title,
  titleClassName,
  isDisabled,
  children,
  className,
  bodyClassName,
}) => (
  _react2.default.createElement('div', {
    className: _classBuilder2.default.call(void 0, 
      "team-page--section",
      isDisabled && "team-page--section--disabled",
      className,
    ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 20}}
  
    ,  title && (
      _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "team-page--section-title", titleClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
        ,  title 
      )
    )
    , _react2.default.createElement('div', { className: _classBuilder2.default.call(void 0, "team-page--section-body", bodyClassName), __self: this, __source: {fileName: _jsxFileName, lineNumber: 32}}
      ,  children 
    )
  )
)

exports. default = GenericSection
